import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import * as route from '../../services/routeConst'
import AppLayout from '../AppLayout'
import '../Jobs/jobs.scss'
import { JOBS_TITLE } from '../../services/titleConst'
import LoginModal from '../LoginModal/LoginModal'
import { Helmet } from 'react-helmet'

class Jobs extends Component {
  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {
    return (
      <AppLayout routeActive={this.props.location.pathname}>
        <Helmet>
          <title>{JOBS_TITLE}</title>
        </Helmet>
        <section className='bg-grey'>
          <div className='container'>
            <h3 className='varela mb-3'>Jobs</h3>
            <div className='white-pane green_line jobs'>
              <p>iTRUSTit is hiring!</p>
              <p>
                Please{' '}
                <Link className='blue' to={route.CONTACT_ROUTE}>
                  Contact Us here.
                </Link>
              </p>
            </div>
          </div>
        </section>
        <LoginModal
          isLogin={(e) =>
            function (data) {
              this.props.isLogin(data)
            }
          }
          {...this.props}
        />
      </AppLayout>
    )
  }
}

export default Jobs
