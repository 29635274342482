import React, { useState } from 'react'
import AppLayout from '../AppLayout'
import '../Message/message.scss'
import LoginModal from '../LoginModal/LoginModal'
import { Helmet } from 'react-helmet'
import './MarketingCenter.scss'
import Card from './Components/Card'
import { Link } from 'react-router-dom'
import { MARKETING_CENTER_GUIDE_ROUTE } from '../../services/routeConst'
import { ItiText } from '../About/About'
import { SITE_ADDRESS } from '../../services/environment'
import { getUser, toaster } from '../../services/customFunction'

const pageTitles = {
  home: 'Marketing Center',
  brand_assets: 'Marketing Center - Brand Asset Downloads',
}

const MarketingCenter = (props) => {
  const [currentPage, setCurrentPage] = useState('home')
  const [user, setUser] = useState(getUser())
  const loginHandler = (data) => {
    props?.isLogin?.(data)
  }

  const getPageTitle = () => {
    return pageTitles[currentPage] ?? 'Marketing Center'
  }

  const copyToClipboard = async (text) => {
    if ('clipboard' in navigator) {
      await navigator.clipboard.writeText(text)
      toaster('success', 'URL copied successfully')
    } else {
      return document.execCommand('copy', true, text)
    }
  }
  return (
    <AppLayout routeActive={props.location.pathname}>
      <Helmet>
        <title>iTRUSTit Marketing Center</title>
      </Helmet>
      <section className='bg-grey'>
        <div className='container-fluid mt-3 px-2 px-md-5'>
          <h3 className='varela mb-2'>{getPageTitle()}</h3>
          <div className='white-pane green_line'>
            <div className='marketing-center mb-5 position-relative'>
              <div className='position-absolute' style={{ top: 8, left: 8, zIndex: 2000 }}>
                {currentPage !== 'home' ? (
                  <button className='promotion-back-button' onClick={() => setCurrentPage('home')}>
                    <div className='d-flex align-items-center' style={{ gap: 6 }}>
                      <i className='fas fa-arrow-left' aria-hidden='true'></i>
                      <p
                        className='varela mb-0 fw-bold mc-back-button'
                        style={{ fontSize: 16, cursor: 'pointer' }}
                      >
                        Back
                      </p>
                    </div>
                  </button>
                ) : null}
              </div>
              {currentPage === 'brand_assets' ? (
                <div className='d-flex flex-column mt-3'>
                  <h3 className='mont fw-bold'>
                    Downloading the <ItiText /> Logos
                  </h3>
                  <h6 className='mont'>
                    When linking to or referring to your iTRUSTit page, you can use the "iTi" bug
                    logos or the full iTRUSTit logos.
                  </h6>
                  <h6 className='mont mb-4'>Each logo comes in EPS, PNG, and SVG.</h6>

                  <Link
                    to={MARKETING_CENTER_GUIDE_ROUTE}
                    target='_blank'
                    className='mont mb-4'
                    style={{ fontSize: 16 }}
                  >
                    Click Here for the iTRUSTit Brand Assets Rules Manual
                  </Link>

                  <h3 className='mont fw-bold' style={{ color: '#0877bd' }}>
                    Here is your page link to go with the <ItiText /> and <ItiText short /> logos:
                  </h3>
                  <div className='d-flex'>
                    <h3 className='mont fw-bold mc-site-address'>{`${SITE_ADDRESS}${user?.slug}`}</h3>
                    <i
                      onClick={() => copyToClipboard(`${SITE_ADDRESS}${user?.slug}`)}
                      className='fa fa-copy ms-1'
                      aria-hidden='true'
                      style={{
                        color: 'lightgrey',
                        fontSize: 16,
                        cursor: 'pointer',
                      }}
                    ></i>
                  </div>
                  <div className='d-flex mt-4'>
                    <a
                      href={process.env.PUBLIC_URL + '/assets/iti_brand_logos.zip'}
                      download
                      className='iti-submit-btn'
                    >
                      Download Logos
                    </a>
                  </div>

                  <div className='row flex-column mt-4 mc-logos-container align-self-center align-self-md-start'>
                    {['color', 'grey', 'black', 'white'].map((base) => (
                      <div className='col row justify-content-center align-items-center mc-logos-wrapper px-0'>
                        {['itrustit-$-tagline', 'itrustit-$', 'iti-$-box', 'iti-$'].map(
                          (variant) => {
                            const logo = variant.replace('$', base)
                            return (
                              <div className='col text-center'>
                                <img
                                  src={`${process.env.PUBLIC_URL}/img/iti_brand_assets/${logo}/${logo}.svg`}
                                  alt={logo.replaceAll('-', ' ')}
                                  width='100%'
                                  style={variant.includes('iti') ? { maxWidth: 75 } : {}}
                                  className={
                                    base === 'white' && variant !== 'iti-$-box' ? 'mc-bg-grey' : ''
                                  }
                                />
                              </div>
                            )
                          },
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                <div className='row' style={{ rowGap: '1.5rem' }}>
                  <Card
                    onClick={() => setCurrentPage('brand_assets')}
                    className='col'
                    header='Brand Assets'
                    body={['Logos', 'Icons', 'Links', 'etc.', null]}
                  />
                  <Card
                    // onClick={() => setCurrentPage('self_print')}
                    className='col'
                    header='Self Print'
                    body={['Flyers', 'Signs', 'Business Cards', 'QR signs', 'and more...']}
                  />
                  <Card className='col' header={null} body={[null, null, null, null, null]} />
                  <Card className='col' header={null} body={[null, null, null, null, null]} />
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
      <LoginModal isLogin={loginHandler} {...props} />
    </AppLayout>
  )
}

export default MarketingCenter
