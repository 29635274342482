import log from 'loglevel'
import remote from 'loglevel-plugin-remote'
import * as environemnt from '../services/environment'

const customJSON = (log) => ({
  source: 'Frontend',
  message: log.message,
  level: log.level.label,
  stacktrace: log.stacktrace,
  description: log.description,
  componentStack: log.componentStack,
  stack: log.stack,
})

const apiPath = environemnt.BASE_API + environemnt.LOGGER

const defaults = {
  url: apiPath,
  method: 'POST',
  headers: { 'Content-Type': 'application/json' },
  token: '',
  onUnauthorized: (failedToken) => {},
  timeout: 0,
  interval: 1000,
  level: 'trace',
  backoff: {
    multiplier: 2,
    jitter: 0.1,
    limit: 30000,
  },
  capacity: 500,
  stacktrace: {
    levels: ['trace', 'warn', 'error'],
    depth: 3,
    excess: 0,
  },
  timestamp: () => new Date().toISOString(),
  format: customJSON,
}

log.enableAll()
remote.apply(log, defaults)

export default log
