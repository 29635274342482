import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { userStore } from '../actions/auth'
import { DEFAULT_PROFILE_PICTURE } from '../services/const'
import { getUser, isAdmin, setUser, toaster } from '../services/customFunction'
import { galleryCreateCall, validateGalleryFileType } from '../services/galleryApiService'
import { updateUserProfilePicture } from '../services/userApiService'

const useProfilePictureUpdate = (user) => {
  const dispatch = useDispatch()
  const [userImgData, setUserImgData] = useState({
    userImg: DEFAULT_PROFILE_PICTURE,
    userImgFile: '',
  })
  const [validation, setValidation] = useState({})

  useEffect(() => {
    if (user?.user_detail?.profile_picture_large)
      setUserImgData({ userImg: user.user_detail.profile_picture_large })

    // if (user?.user_detail?.cover_picture_medium)
    //   userObj['userCoverImg'] = user.user_detail.cover_picture_medium

    // userObj['userDetail'] = user
  }, [user?.user_detail?.profile_picture_large])

  const userProfileImg = (data, imgFile) => {
    const { error, isFormValid, fileType } = validateGalleryFileType(imgFile, true)
    setValidation({ errors: error, isFormValid, fileType })
    setUserImgData({ userImg: data, userImgFile: imgFile })
  }

  const setImgFile = (imgFile) => {
    const { error, isFormValid, fileType } = validateGalleryFileType(imgFile, true)
    setValidation({ errors: error, isFormValid, fileType })
    setUserImgData((st) => ({ ...st, userImgFile: imgFile }))
  }

  const updateProfilePicture = async () => {
    const { error, isFormValid, fileType } = validateGalleryFileType(userImgData.userImgFile, true)
    setValidation({ errors: error, isFormValid, fileType })
    if (fileType !== '' && error === '' && isFormValid) {
      const response = await updateUserProfilePicture({
        profile_picture: userImgData.userImg,
        user_id: user.id, //For admin requests
      })
      // console.log({ response })
      if (response.status === true) {
        toaster('success', response.message)

        if (!isAdmin() || getUser()?.id === user.id) {
          setUser(response.profileInfo)
        }

        setUserImgData((st) => ({
          ...st,
          userImg: response.profileInfo.user_detail.profile_picture_large,
        }))
        const formData = new FormData()
        formData.append('file_type', fileType)
        formData.append('file', userImgData.userImgFile)
        formData.append('user_id', user.id)
        galleryCreateCall(formData)
        setUserImgData((st) => ({ ...st, userImgFile: '' }))

        if (!isAdmin() || getUser()?.id === user.id) {
          dispatch(userStore({ user: response.profileInfo }))
        }
      } else {
        if (response?.status === 403 && !!response.data.message) {
          toaster('error', response.data.message)
        } else if (response?.status === 422) {
          toaster('error', 'An unexpected error occurred attempting to update the profile picture')
        } else {
          toaster('error', 'Something went wrong')
        }
      }
    }
  }

  return {
    validation,
    userImg: userImgData?.userImg,
    handleImageChange: userProfileImg,
    updateProfilePicture,
    setImgFile,
  }
}

export default useProfilePictureUpdate
