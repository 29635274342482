import React from 'react'
import { isMobile } from 'react-device-detect'
import { Link } from 'react-router-dom'
import {
  FacebookMessengerShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from 'react-share'
import { toaster } from '../../../services/customFunction'
import { SITE_ADDRESS } from '../../../services/environment'
import { Cta } from '../VCard/Components/Components'
import { hideShareModal } from './ShareModal'

const ShareOptions = ({
  loginUserData,
  user,
  setModalState,
  shareLink,
  type = 'profile',
  isAdd,
  isInviteToClaim,
  modalId = '0',
}) => {
  const linkToShare = isInviteToClaim
    ? `${SITE_ADDRESS}invite-claim/${loginUserData?.imatrix_id ?? loginUserData?.slug}/${user?.id}`
    : shareLink
  const copyToClipboard = async (text) => {
    if ('clipboard' in navigator) {
      await navigator.clipboard.writeText(text)
      toaster('success', 'Link copied successfully')
    } else {
      return document.execCommand('copy', true, text)
    }
  }

  const formattedUserType = user?.user_type === 'business' ? 'Business' : 'Organization'
  const messages = {
    default: {
      sms:
        linkToShare +
        (type === 'vcard'
          ? '\r\n\r\nSign up on iTRUSTit and you can get a vCard like this too!'
          : ''),
      email: {
        subject: user?.fullname + `'s ${type === 'vcard' ? 'vCard' : 'Profile'} on iTRUSTit!`,
        body: `Hi,\r\nClick here for ${user?.fullname}'s iTRUSTit ${
          type === 'vcard' ? 'vCard' : 'Profile'
        }:\r\n${linkToShare}`,
      },
    },
    add: {
      sms: `Claim your ${formattedUserType} on iTRUSTit\r\n\r\nHi,\r\n\r\n${loginUserData?.fullname} added your ${formattedUserType} to the iTRUSTit referral network.\r\n\r\nClick on the link below to Claim your ${formattedUserType} for free:\r\n${linkToShare}\r\n\r\nLet's get started`,
      email: {
        subject: `Claim your ${formattedUserType} on iTRUSTit`,
        body: `Hi,\r\n\r\n${loginUserData?.fullname} added your ${formattedUserType} to the iTRUSTit referral network.\r\n\r\nClick on the link below to Claim your ${formattedUserType} for free:\r\n${linkToShare}\r\n\r\nLet's get started`,
      },
    },
    inviteClaim: {
      sms: `Hi ${
        user?.business_organization_name
      },\r\n\r\nI found your ${formattedUserType} on iTRUSTit and it${
        user?.total_friends > 0 ? ' already' : ''
      } has ${user?.total_friends} friend${
        user?.total_friends !== 1 ? 's' : ''
      } and is trusted by ${user?.total_trusted_by} user${
        user?.total_trusted_by !== 1 ? 's' : ''
      }!\r\n\r\nClaim your free listing here:\r\n${linkToShare}\r\n\r\nHappy to answer any questions.\r\n\r\n${
        loginUserData?.fullname
      }\r\nMy Sponsor iTeam ID# is: ${
        loginUserData?.imatrix_id
      }\r\n\r\nClaim your page today, and let's get started promoting your ${formattedUserType}\r\n`,
      email: {
        subject: `I found your ${formattedUserType} on iTRUSTit`,
        body: `Hi ${
          user?.business_organization_name
        },\r\n\r\nI found your ${formattedUserType} on iTRUSTit and it${
          user?.total_friends > 0 ? ' already' : ''
        } has ${user?.total_friends} friend${
          user?.total_friends !== 1 ? 's' : ''
        } and is trusted by ${user?.total_trusted_by} user${
          user?.total_trusted_by !== 1 ? 's' : ''
        }!\r\n\r\niTRUSTit is a cool free way to promote your business to my friends and family and it is proving to be an incredibly convenient tool for sharing my favorite businesses and services.\r\n\r\nClaim your free listing here:\r\n${linkToShare}\r\n\r\nThe magic of iTRUSTit is that it provides one place for me and my friends, family, and colleagues to share and promote the businesses and services we trust and value.\r\nPlus, iTRUSTit offers a ton of great features and will even donate a portion of its profits to the charity of your choice.\r\n\r\niTRUSTit is an easy way to get more personal referrals. Once you join, you can invite your network to promote you to all their connections too!\r\n\r\nHappy to answer any questions.\r\n\r\n${
          loginUserData?.fullname
        }\r\nMy Sponsor iTeam ID# is: ${
          loginUserData?.imatrix_id
        }\r\n\r\nClaim your page today, and let's get started promoting your ${formattedUserType}\r\n`,
      },
    },
  }
  const messageType = isInviteToClaim
    ? messages.inviteClaim
    : isAdd
    ? messages.add
    : messages.default
  return (
    <div
      className={`d-flex flex-column align-items-center mx-auto p-3 ${isMobile ? 'w-100' : ''}`}
      style={{
        minWidth: 260,
        maxWidth: 275,
        border: isAdd ? 'none' : '2px solid rgba(33, 37, 41, 0.7)',
        borderRadius: 5,
      }}
    >
      {!isAdd ? (
        <span className='vcard-cta-text'>
          {isInviteToClaim ? (
            'Invite by:'
          ) : (
            <>Share {type === 'vcard' ? 'vCard' : 'Profile Page'}:</>
          )}
        </span>
      ) : null}
      <div className='d-flex flex-column w-100'>
        {!isAdd && !isInviteToClaim ? (
          <Cta
            className={`my-3 ${user?.is_business_claimed !== '1' ? 'disabled' : ''}`}
            text='QR Code'
            onClickCallback={() =>
              user?.is_business_claimed === '1' && setModalState(`qr_code_${type}`)
            }
          />
        ) : null}
        {!isAdd && !isInviteToClaim ? (
          <Link
            className='vcard-cta vcard-cta-text my-3'
            to={{
              pathname: process.env.PUBLIC_URL + '/message',
              state: { messageContent: linkToShare },
            }}
            onClick={() => hideShareModal(modalId)}
          >
            <img
              className='me-2'
              width='25'
              src={process.env.PUBLIC_URL + '/img/new_icons/message.svg'}
              alt='logo'
            />
            iTi Message
          </Link>
        ) : null}
        {isMobile ? (
          <a
            className='vcard-cta vcard-cta-text my-3'
            href={`sms:?&body=${encodeURIComponent(messageType.sms)}`}
          >
            <img className='me-2' width='20' src={process.env.PUBLIC_URL + '/img/sms.svg'} alt='' />
            Text
          </a>
        ) : null}
        <a
          className='vcard-cta vcard-cta-text my-3'
          href={`mailto:?subject=${encodeURIComponent(
            messageType.email.subject,
          )}&body=${encodeURIComponent(messageType.email.body)}`}
        >
          <img
            className='me-2'
            width='20'
            src={process.env.PUBLIC_URL + '/img/email_invite.svg'}
            alt=''
          />
          e-mail
        </a>
        <Cta
          className='mt-3 mb-4'
          text={
            <>
              <img
                className='me-2'
                width='20'
                src={process.env.PUBLIC_URL + '/img/copy.svg'}
                alt=''
              />
              <span>Copy {isInviteToClaim ? 'Invite' : 'Link'}</span>
            </>
          }
          onClickCallback={() =>
            copyToClipboard(messageType.email.subject + '\r\n\r\n' + messageType.email.body)
          }
        />
        <div
          className={`d-flex ${
            !isAdd && !isInviteToClaim ? 'justify-content-evenly' : 'flex-column'
          }`}
        >
          {!isAdd && !isInviteToClaim ? (
            <>
              <FacebookShareButton url={linkToShare} quote={linkToShare} hashtag='#itrustit'>
                <i className='fab fa-facebook m-1' style={{ color: '#1877f2', fontSize: 42 }}></i>
              </FacebookShareButton>
              <FacebookMessengerShareButton url={shareLink} appId='236906341480256'>
                <i
                  className='fab fa-facebook-messenger m-1'
                  style={{ color: '#1877f2', fontSize: 42 }}
                ></i>
              </FacebookMessengerShareButton>
              <LinkedinShareButton
                url={linkToShare}
                title={linkToShare}
                summary={linkToShare}
                source='iTRUSTit'
              >
                <i className='fab fa-linkedin m-1' style={{ color: '#0077B5', fontSize: 42 }}></i>
              </LinkedinShareButton>
              <TwitterShareButton
                url={linkToShare}
                hashtags={['itrustit']}
                // title={linkToShare}
              >
                <i
                  className='fab fa-twitter-square m-1'
                  style={{ color: '#1d9bf0', fontSize: 42 }}
                ></i>
              </TwitterShareButton>
            </>
          ) : (
            <>
              <FacebookMessengerShareButton url={linkToShare} appId='236906341480256'>
                <Cta
                  className='mt-2'
                  textStyles={{ display: 'flex', alignItems: 'center', paddingRight: '0.25rem' }}
                  text={
                    <>
                      <i
                        className='fab fa-facebook-messenger m-1'
                        style={{ color: '#1877f2', fontSize: 28 }}
                      ></i>
                      <span style={{ fontSize: 16 }}>Facebook Messenger</span>
                    </>
                  }
                  onClickCallback={() => {}}
                />
              </FacebookMessengerShareButton>
              <span className='varela ms-2' style={{ lineHeight: 1 }}>
                <small>
                  HINT - To show &amp; edit invite text in Facebook Messenger, use "Copy Invite" and
                  then paste it into the Facebook Message.
                </small>
              </span>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default ShareOptions
