import React, { Component, Fragment } from 'react'

import AppLayout from '../../AppLayout'
import './signupbusiness.scss'
import ClaimBusiness1 from './Step1/ClaimBusiness1'
import ClaimBusiness2 from './Step2/ClaimBusiness2'
// import { SIGNUP_BUSINESS_TITLE } from "../../../services/titleConst";
import ClaimOrganization1 from './Step1/ClaimOrganization1'
import ClaimOrganization2 from './Step2/ClaimOrganization2'

class Form extends Component {
  constructor(props) {
    super(props)
    //document.title = SIGNUP_BUSINESS_TITLE;
    // console.log("props", props);
    this.state = {
      isDisplayBusiness2: false,
      isDisplayOrganization2: false,
      formData: [],
      user_type: props.location.state.user.user_type,
    }
  }

  isForm2 = (data) => {
    this.setState({ isDisplayBusiness2: data })
  }
  isFormOrganization2 = (data) => {
    this.setState({ isDisplayOrganization2: data })
  }
  formSubmitHandler = (data) => {
    this.setState({ formData: data })
  }

  componentWillUnmount() {
    this.state.formData = []
  }
  render() {
    //console.log("state", this.state.user_type);
    if (this.state.user_type === 'business') {
      this.form = (
        <Fragment>
          {!this.state.isDisplayBusiness2 ? (
            <ClaimBusiness1
              isDisplayForm2={this.isForm2}
              formData={this.formSubmitHandler}
              user={this.props.location.state.user}
            />
          ) : (
            <ClaimBusiness2
              isDisplayForm2={this.isForm2}
              form1Data={this.state.formData}
              user={this.props.location.state.user}
            />
          )}
        </Fragment>
      )
    } else if (this.state.user_type === 'organization') {
      this.form = (
        <Fragment>
          {!this.state.isDisplayBusiness2 ? (
            <ClaimOrganization1
              isDisplayForm2={this.isForm2}
              formData={this.formSubmitHandler}
              user={this.props.location.state.user}
            />
          ) : (
            <ClaimOrganization2
              isDisplayForm2={this.isForm2}
              form1Data={this.state.formData}
              user={this.props.location.state.user}
            />
          )}
        </Fragment>
      )
    }
    return <AppLayout isDisplayFooter={false}>{this.form}</AppLayout>
  }
}

export default Form
