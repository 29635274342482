import AppLayout from '../../../AppLayout'
import LoginModal from '../../../LoginModal/LoginModal'
import * as route from '../../../../services/routeConst'
import '../Reports/Report.scss'
import { Link } from 'react-router-dom'
import { PDF_PATH } from '../../../../services/const'

const CopyRightInstructionsHTML = (props) => {
  window.scrollTo(0, 0)

  const Pdf = PDF_PATH + 'iTRUSTit Notice of Copyright Infringement Reporting FORM.pdf'

  return (
    <AppLayout routeActive={props.location.pathname}>
      <section className='bg-grey it-privacy-terms-page it-terms-page'>
        <div className='container'>
          <div className='white-pane privacy'>
            <h4 className='colorblue'>Copyright Infringement Reporting Instructions</h4>
            <br></br>
            <h4 className='colorblue'>Instructions:</h4>
            <p>
              You should only use and send us a Notice if you believe in good faith someone on
              iTRUSTit is infringing your copyright.
            </p>
            <p>
              For more information on our copyright policy and contact information, please see our{' '}
              <Link style={{ color: '#6684f2' }} to={route.FINDOUTMOREHTML_ROUTE} target='_blank'>
                Content on iTRUSTit,
              </Link>{' '}
              <Link style={{ color: '#6684f2' }} to={route.TERMS_ROUTE} target='_blank'>
                Terms of Service
              </Link>{' '}
              and
              <Link style={{ color: '#6684f2' }} to={route.COPYRIGHTHTML_ROUTE} target='_blank'>
                Copyright Policy.
              </Link>{' '}
            </p>
            <p>
              We encourage iTRUSTit users to amicably resolve these maters first by contacting the
              user that submitted the claim directly to discuss a resolution.
            </p>
            <p>
              <a style={{ color: '#6684f2' }} href={Pdf} rel='noreferrer' target='_blank'>
                Click here for the Copyright Infringement Reporting Form (PDF)
              </a>
            </p>
          </div>
        </div>
      </section>
      <LoginModal
        isLogin={(e) =>
          function (data) {
            props.isLogin(data)
          }
        }
        {...props}
      />
    </AppLayout>
  )
}

export default CopyRightInstructionsHTML
