import React from 'react'
import { DEFAULT_PROFILE_PICTURE } from '../../services/const'
import ChatListContent from './ChatListContent'

const ChatListGroup = ({ groupChat, groupChatId, onGroupChatChange }) => {
  let nameToDisplay = groupChat?.users?.map((u) => u?.fullname)?.join?.(', ')
  return (
    <li
      className={
        'message_item ' + (groupChat.group_chat_id === groupChatId ? ' message_item_open ' : '')
      }
      onClick={() => onGroupChatChange(groupChat.group_chat_id)}
    >
      <div className='user_image'>
        <img src={DEFAULT_PROFILE_PICTURE} alt='' />
      </div>
      <ChatListContent chat={groupChat} displayName={nameToDisplay} />
    </li>
  )
}

export default ChatListGroup
