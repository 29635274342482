import React from 'react'
import { reduxForm } from 'redux-form'
import { removeTrustCall } from '../../../services/trustApiService'
import $J from 'jquery'
import './UntrustPopup.scss'

const ConfirmUntrustPopupModal = ({ username, data, handler }) => {
  const untrustUser = async () => {
    await removeTrustCall({ id: data.id })
    handler?.(data)
    $J('#confirm_untrust_popup_modal').modal('hide')
  }

  return (
    <div
      className='modal fade login_blk reset_password_blk it-member-modal'
      id='confirm_untrust_popup_modal'
      role='dialog'
    >
      <div className='modal-dialog'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h2 className='text-start'>{username}</h2>
            <button type='button' className='btn-close' data-bs-dismiss='modal'></button>
          </div>
          <div className='modal-body loginblk'>
            <p className='text-center' style={{ marginBottom: 0 }}>
              Are you sure you want to untrust this user?
            </p>

            <form
              id='login_form'
              name='login_form'
              className='reset_password'
              method='post'
              style={{ display: 'flex', justifyContent: 'space-between' }}
            >
              <input
                className='resetbtn'
                type='button'
                value='Cancel'
                data-bs-dismiss='modal'
                style={{ marginRight: '1rem' }}
              />
              <input
                className='resetbtn'
                type='button'
                value='UnTrust'
                onClick={untrustUser}
                style={{ marginLeft: '1rem' }}
              />
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default reduxForm({
  form: 'confirm_untrust_popup_modal',
})(ConfirmUntrustPopupModal)
