import React from 'react'
import { Link } from 'react-router-dom'
import { SEARCH_ROUTE } from '../../../services/routeConst'
import parse from 'html-react-parser'
import SearchSuggestionUser from './SearchSuggestionUser'

const SearchSuggestions = ({
  searchData,
  setSearchData,
  suggestions,
  setStateData,
  setShowSuggestions,
  setIsSearching,
  isChatSuggestion,
  handleChatSuggestionClick = () => {},
}) => {
  return Object.keys(suggestions ?? {}).filter((key) => suggestions[key]?.length)?.length ? (
    <div className='dropdown i_drop_suggetion' style={{ width: 'auto', minWidth: 'auto', top: 48 }}>
      <div className='dropdown-content'>
        {Object.keys(suggestions)
          .map((key) => suggestions[key])
          ?.reduce((acc, curr) => {
            return (acc = [...(acc || []), ...(curr || [])])
          }, [])
          ?.map((user) => {
            let categoryORUsernameDisplay = null
            const userData = user._source
            const fullname = ['business', 'organization'].includes(userData?.user_type)
              ? userData.business_organization_name
              : userData.name
            let addressToDisplay = ''
            if (userData?.address_line_1?.length > 0) {
              addressToDisplay += '' + userData.address_line_1
            }
            if (userData?.address_line_2?.length > 0) {
              addressToDisplay += ' ' + userData.address_line_2
            }
            if (userData?.city?.length > 0) {
              addressToDisplay += ' ' + userData.city
            }
            if (userData?.state?.length > 0) {
              addressToDisplay += ' ' + userData.state
            }
            if (userData?.zipcode?.length > 0) {
              addressToDisplay += ' ' + userData.zipcode
            }

            if (user['_index'] !== 'live-categories') {
              categoryORUsernameDisplay = (
                <SearchSuggestionUser
                  userData={userData}
                  user={user}
                  addressToDisplay={addressToDisplay}
                  isChatUser={isChatSuggestion}
                />
              )
            } else {
              categoryORUsernameDisplay = (
                <Link
                  className='i_suggetion_link_category'
                  name='category-suggestion'
                  id={user['_source']?.name}
                  to={{
                    pathname: SEARCH_ROUTE,
                    state: {
                      category_id: user['_source'].search_id,
                      category_name: fullname,
                    },
                  }}
                  onClick={() => {
                    setIsSearching(true)
                    setSearchData?.({ ...searchData, isNewSearch: true })
                    setStateData?.((st) => ({ ...st, searchStr: fullname }))
                    setShowSuggestions(false)
                  }}
                >
                  <div className='i_info'>
                    <div className='i_name varela purple'>
                      {parse(user?.highlight?.name?.[0] ?? fullname ?? '')}
                    </div>
                  </div>
                </Link>
              )
            }

            return (
              <div
                key={user['_source'].id}
                className='i_suggestion_list'
                onClick={() => handleChatSuggestionClick(user)}
              >
                {categoryORUsernameDisplay}
              </div>
            )
          })}
      </div>
    </div>
  ) : null
}

export default SearchSuggestions
