import React from 'react'
import { Link } from 'react-router-dom'
import { kFormatter } from '../../../../services/customFunction'
import { PROFILE_ROUTE } from '../../../../services/routeConst'

const ITeamTreeIcon = ({ readNotification, user, iteamTotalCount, className = '' }) => {
  return (
    <li className={'nav-item' + className}>
      <Link
        to={PROFILE_ROUTE + '#itrustittree'}
        onClick={() => readNotification('iteam')}
        className='nav-link position-relative'
      >
        <span
          className={`${user?.checked_iteam_count ? 'clicked-new' : ''} ${
            iteamTotalCount > 0 ? 'iteam-notif-count' : ''
          }`}
        >
          {kFormatter(iteamTotalCount ?? 0)}
        </span>
        <img src={process.env.PUBLIC_URL + '/img/new_icons/iti.svg'} alt='logo' />
      </Link>
    </li>
  )
}

export default ITeamTreeIcon
