import React, { Component } from 'react'
import AppLayout from '../AppLayout'
import { COMMUNITY_TITLE } from '../../services/titleConst'
import LoginModal from '../LoginModal/LoginModal'
import * as route from '../../services/routeConst'
import $ from 'jquery'
import { Helmet } from 'react-helmet'
class Community extends Component {
  componentDidMount() {
    window.scrollTo(0, 0)
  }

  showTrustDirectoryTabIndividual = (data) => (event) => {
    event.preventDefault()
    $('html, body').animate(
      {
        scrollTop: $(data).offset().top - 60,
      },
      'slow',
    )
  }

  render() {
    return (
      <AppLayout routeActive={this.props.location.pathname}>
        <Helmet>
          <title>{COMMUNITY_TITLE}</title>
        </Helmet>
        <section className='bg-grey it-community'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-12 mb-5' id='back-top'>
                <h3 className='varela mb-3 it-blue'>COMMUNITY</h3>
                <div className='white-pane green_line'>
                  <div className='white_pane_content'>
                    <ul className='it-co-page-link'>
                      <li>
                        <a href='' onClick={this.showTrustDirectoryTabIndividual('#beta-launch')}>
                          - Beta Launch
                        </a>
                      </li>
                      <li>
                        <a href='' onClick={this.showTrustDirectoryTabIndividual('#person')}>
                          - Person
                        </a>
                      </li>
                      <li>
                        <a href='' onClick={this.showTrustDirectoryTabIndividual('#business')}>
                          - Business
                        </a>
                      </li>
                      <li>
                        <a href='' onClick={this.showTrustDirectoryTabIndividual('#organization')}>
                          - Organization
                        </a>
                      </li>
                      <li>
                        <a
                          href=''
                          onClick={this.showTrustDirectoryTabIndividual('#charter-member')}
                        >
                          - Charter Member
                        </a>
                      </li>
                      <li>
                        <a href='' onClick={this.showTrustDirectoryTabIndividual('#iTeam')}>
                          - iPartner Affiliate / iTeam
                        </a>
                      </li>
                      <li>
                        <a href='' onClick={this.showTrustDirectoryTabIndividual('#revenue-share')}>
                          - Revenue Share
                        </a>
                      </li>
                      <li>
                        <a href='' onClick={this.showTrustDirectoryTabIndividual('#next-steps')}>
                          - Next Steps
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className='col-md-12 mb-5' id='beta-launch'>
                <h3 className='varela mb-3 it-blue'>BETA LAUNCH</h3>
                <div className='white-pane green_line'>
                  <div className='white_pane_content'>
                    <p>
                      Thanks for being a part of the iTRUSTit Beta roll-out! <br />
                      During this phase we will address any issues and add functionality.{' '}
                    </p>
                    <div className='row'>
                      <div className='col-md-5'>
                        <p>
                          As a thank you for helping us perfect the user experience before we go
                          live, you will be given special{' '}
                          <a
                            href=''
                            onClick={this.showTrustDirectoryTabIndividual('#charter-member')}
                          >
                            Charter Member
                          </a>{' '}
                          status.
                        </p>
                        <p> We hope you enjoy iTRUSTit and share it with your friends.</p>
                        <a href={route.SIGNUP_ROUTE} className='it-co-btn it-blue-btn'>
                          {' '}
                          Join iTRUSTit
                        </a>
                      </div>
                      <div className='col-md-7'>
                        <div className='it-co-img'>
                          <img
                            src={process.env.PUBLIC_URL + '/img/welcome-img.png'}
                            alt='welcome'
                          />
                        </div>
                      </div>
                    </div>
                    <div className='text-center mt-5'>
                      <a
                        href=''
                        className='it-co-back it-blue'
                        onClick={this.showTrustDirectoryTabIndividual('.navbar')}
                      >
                        Back to top
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-md-12 mb-5' id='person'>
                <h3 className='varela mb-3 it-green'>JOIN AS A PERSON</h3>
                <div className='white-pane green_line'>
                  <div className='white_pane_content'>
                    <h3 className='it-blue'>Your circle of trust... online.</h3>
                    <p>
                      You trust your friends to steer you right, whether you're looking for a great
                      restaurant, rockstar accountant or a trustworthy contractor.
                    </p>
                    <div className='row'>
                      <div className='col-md-7'>
                        <p>
                          {' '}
                          <span className='it-blue'>Search</span> - for businesses ranked according
                          to YOUR FRIENDS' recommendations.
                        </p>
                        <p>
                          {' '}
                          <span className='it-blue'>Find</span> - friends and businesses associated
                          with your organizations.
                        </p>
                        <p>
                          {' '}
                          <span className='it-blue'> Get trusted answers</span> - even when your
                          friends aren't around to ask.
                        </p>
                      </div>
                      <div className='col-md-5'>
                        <div className='it-co-img'>
                          <img src={process.env.PUBLIC_URL + '/img/co-person.png'} alt='person' />
                        </div>
                      </div>
                    </div>
                    <div className='text-center mt-3'>
                      <div>
                        <a
                          href={route.SIGNUP_ROUTE}
                          className='it-co-btn it-green-btn d-inline-block'
                        >
                          {' '}
                          Join iTRUSTit
                        </a>
                      </div>

                      <a
                        href=''
                        className='it-co-back it-blue mt-5'
                        onClick={this.showTrustDirectoryTabIndividual('.navbar')}
                      >
                        Back to top
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-md-12 mb-5' id='business'>
                <h3 className='varela mb-3 it-blue'>JOIN AS A BUSINESS</h3>
                <div className='white-pane green_line'>
                  <div className='white_pane_content'>
                    <h3 className='it-blue'>Word of mouth... amplified.</h3>
                    <div className='row'>
                      <div className='col-md-7'>
                        <p>
                          Word of Mouth Marketing is the most powerful advertising there is.
                          iTRUSTit allows you to leverage your friends and loyal customers to help
                          their friends find YOU!
                        </p>
                        <p>
                          {' '}
                          Invite your family, friends and customers to iTRUSTit. As they join and
                          start building their own networks, they'll be promoting your business to
                          an ever-expanding circle of friends.
                        </p>
                        <div className='text-md-right text-start  ms-sm-5 ms-md-0 mt-5 mt-md-0'>
                          <a
                            href={route.SIGNUP_ROUTE}
                            className='it-co-btn it-blue-btn d-inline-block  mt-4 mt-md-2 ms-3'
                          >
                            {' '}
                            Join iTRUSTit
                          </a>
                        </div>
                      </div>
                      <div className='col-md-5'>
                        <div className='it-co-img it-buss-img'>
                          <img
                            src={process.env.PUBLIC_URL + '/img/co-business.png'}
                            alt='business'
                          />
                        </div>
                      </div>
                    </div>
                    <div className='text-center mt-3'>
                      <a
                        href=''
                        className='it-co-back it-blue mt-5'
                        onClick={this.showTrustDirectoryTabIndividual('.navbar')}
                      >
                        Back to top
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-md-12 mb-5' id='organization'>
                <h3 className='varela mb-3 it-orange'>JOIN AS AN ORGANIZATION</h3>
                <div className='white-pane green_line'>
                  <div className='white_pane_content'>
                    <h3 className='it-blue'>Membership... enhanced.</h3>
                    <p>
                      Whether you’re a charity, alumni association, chamber, church, fraternity,
                      Rotary club or chess club, your organization can reap the benefits on
                      iTRUSTit.{' '}
                    </p>
                    <div className='row'>
                      <div className='col-md-12'>
                        <p>
                          {' '}
                          <span className='it-blue'>Recruit</span>-your existing members generate
                          massive awareness and become a potential source of new members for your
                          organization.
                        </p>
                        <p>
                          {' '}
                          <span className='it-blue'>Provide added value </span>- with our "Member
                          Business Directory".
                        </p>
                        <p>
                          {' '}
                          <span className='it-blue'>Increase Non-Dues Revenue </span>- in our{' '}
                          <a href='' onClick={this.showTrustDirectoryTabIndividual('#iTeam')}>
                            iPartner
                          </a>{' '}
                          program.
                        </p>
                        <p>
                          {' '}
                          <span className='it-blue'>Receive contributions </span>- with iTRUSTit’s
                          iGive program.
                        </p>
                      </div>
                    </div>
                    <div className='text-center mt-3'>
                      <div>
                        <a
                          href={route.SIGNUP_ROUTE}
                          className='it-co-btn it-orange-btn d-inline-block'
                        >
                          {' '}
                          Join iTRUSTit
                        </a>
                      </div>

                      <a
                        href=''
                        className='it-co-back it-blue mt-5'
                        onClick={this.showTrustDirectoryTabIndividual('.navbar')}
                      >
                        Back to top
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-md-12 mb-5' id='charter-member'>
                <h3 className='varela mb-3 it-blue'>CHARTER MEMBER</h3>
                <div className='white-pane green_line'>
                  <div className='white_pane_content'>
                    <h3 className='it-orange'>Congratulations!</h3>

                    <div className='row'>
                      <div className='col-md-7'>
                        <p>iTRUSTit will soon be going live, and we need your help.</p>
                        <p>By signing up now, you will be a “Charter Member” of iTRUSTit! </p>
                        <p>
                          To thank you for being one of our Beta Testers and helping us work out the
                          kinks, you will have permanent{' '}
                          <a
                            href=''
                            onClick={this.showTrustDirectoryTabIndividual('#revenue-share')}
                          >
                            iPartner PRO
                          </a>{' '}
                          status.{' '}
                        </p>
                        <a
                          href={route.SIGNUP_ROUTE}
                          className='it-co-btn it-blue-btn d-inline-block'
                        >
                          {' '}
                          Join iTRUSTit
                        </a>
                      </div>
                      <div className='col-md-5'>
                        <div className='it-co-img'>
                          <img src={process.env.PUBLIC_URL + '/img/co-partner.png'} alt='partner' />
                        </div>
                      </div>
                    </div>
                    <div className='text-center mt-5'>
                      <a
                        href=''
                        className='it-co-back it-blue'
                        onClick={this.showTrustDirectoryTabIndividual('.navbar')}
                      >
                        Back to top
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-md-12 mb-5' id='iTeam'>
                <h3 className='varela mb-3 it-blue'>iPARTNER AFFILIATE</h3>
                <div className='white-pane green_line'>
                  <div className='white_pane_content'>
                    <h3 className='it-orange'>Take our Thanks to the Bank!</h3>
                    <div className='row'>
                      <div className='col-md-12'>
                        <p>
                          All the best companies have various rewards/points programs for their
                          clients. You’re a valuable part of our team. To show our appreciation we
                          created a side hustle for you.
                        </p>
                        <p>
                          When you opt-in to be an iPartner affiliate, we give a{' '}
                          <a
                            href=''
                            onClick={this.showTrustDirectoryTabIndividual('#revenue-share')}
                          >
                            share of our revenue
                          </a>{' '}
                          back to you – the users who make it all possible.{' '}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-md-12 mb-5'>
                <h3 className='varela mb-3 it-blue'>BUILD YOUR iTEAM</h3>
                <div className='white-pane green_line'>
                  <div className='white_pane_content'>
                    <h3 className='it-orange'>Here’s how it works:</h3>
                    <div className='row'>
                      <div className='col-md-12'>
                        <p>
                          Everyone you invite who joins iTRUSTit with you as their sponsor (and
                          anyone they sponsor… out to seven degrees of separation) is part of your
                          iTeam.
                        </p>
                        <p>
                          After you opt-in, any affiliate in your iTeam (whether they are a person,
                          business or organization) that purchases advertising or upgraded services
                          on iTRUSTit, you share in that revenue.
                        </p>
                        <h3 className='it-orange'>It’s that simple. No Strings Attached!</h3>
                      </div>
                    </div>
                    <div className='text-center mt-5'>
                      <a
                        href=''
                        className='it-co-back it-blue'
                        onClick={this.showTrustDirectoryTabIndividual('.navbar')}
                      >
                        Back to top
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-md-12 mb-5' id='revenue-share'>
                <h3 className='varela mb-3 it-blue'>REVENUE SHARE</h3>
                <div className='white-pane green_line'>
                  <div className='white_pane_content'>
                    <div className='row'>
                      <div className='col-md-12'>
                        <div className='it-co-img it-co-img-revenue'>
                          <img src={process.env.PUBLIC_URL + '/img/co-revenue.png'} alt='revenue' />
                        </div>
                      </div>
                    </div>
                    <div className='text-center mt-5'>
                      <a
                        href=''
                        className='it-co-back it-blue'
                        onClick={this.showTrustDirectoryTabIndividual('.navbar')}
                      >
                        Back to top
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-md-12 mb-5' id='next-steps'>
                <h3 className='varela mb-3 it-blue'>NEXT STEPS</h3>
                <div className='white-pane green_line'>
                  <div className='white_pane_content'>
                    <h3 className='it-blue'>Congratulations! You just joined iTRUSTit...</h3>
                    <h4 className='it-orange'>So... now what?</h4>
                    <h5>
                      {' '}
                      <span>Step 1 – </span>Complete Your Profile
                    </h5>
                    <div>
                      <h5>
                        {' '}
                        <span>Step 2 – </span>+Friend, +iTRUSTit and +Member
                      </h5>
                      <p>
                        You already have friends on iTRUSTit. There's the person who invited you...
                        and probably more! To get the best results from your searches grow your
                        community and +FRIEND them all.
                      </p>
                      <p>
                        +TRUST businesses and organizations you trust to recommend to your friends.
                      </p>
                      <p>
                        +MEMBER yourself to any organizations you are a member of to be part of
                        their community on iTRUSTit.
                      </p>
                    </div>
                    <div>
                      <h5>
                        {' '}
                        <span>Step 3 – </span>Start using iTRUSTit
                      </h5>
                      <p>
                        SEARCH for people, businesses and organizations using the "Main Search” at
                        the top of the page.
                      </p>
                      <p>
                        CLAIM your business or organization if you are an authorized representative
                        for a listed business or organization.
                      </p>
                      <p>
                        ADD a business or organization to the iTRUSTit system if it is not already
                        listed.
                      </p>
                      <p>
                        REVIEW businesses and organizations to share your experiences with your
                        friends.
                      </p>
                    </div>
                    <div>
                      <h5>
                        {' '}
                        <span>Step 4 – </span>Invite Friends, Businesses and Organizations to Join
                      </h5>
                      <p>
                        Click on your Dashboard's "Invite Center" tab, and use the invitations with
                        your unique iTRUSTit code.
                      </p>
                      <p>
                        This is the only way you can sponsor other users to be a part of your iTeam
                        affiliate network and get compensated for helping us grow.
                      </p>
                    </div>
                    <div>
                      <h5>
                        {' '}
                        <span>Step 5 – </span>for Businesses
                      </h5>
                      <p>Grow your ranking and amplify your referral marketing for free!</p>
                      <p>SEARCH and CONNECT with your friends and clients.</p>
                      <p>ASK them to Trust your Business.</p>
                      <p>
                        INVITE your friends and clients who are not in our system to Trust your
                        business in iTRUSTit.
                      </p>
                      <p>
                        {' '}
                        Hint: Be sure to invite them with your unique invite link so you get credit
                        for them joining.
                      </p>
                    </div>
                    <div>
                      <h5>
                        {' '}
                        <span>Step 6 – </span>for Organizations
                      </h5>
                      <p>Connect your Members in a powerful way that will keep them coming back.</p>
                      <p>
                        INVITE BUSINESS MEMBERS to add their business to your Organization's Member
                        Directory on iTRUSTit where they can easily be found by all your other
                        members.
                      </p>
                      <p>
                        INVITE PEOPLE MEMBERS to find businesses and service affiliated with your
                        organization. When these members +FRIEND and +TRUST your organization, they
                        will be passively promoting your organization to all their friends.
                      </p>
                      <p>
                        Be sure to invite them using your organizations’ unique invite link to build
                        your iTeam and Non-Dues Revenue!
                      </p>
                    </div>
                    <div className='text-center mt-5'>
                      <a
                        href=''
                        className='it-co-back it-blue'
                        onClick={this.showTrustDirectoryTabIndividual('.navbar')}
                      >
                        Back to top
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <LoginModal
          isLogin={(e) =>
            function (data) {
              this.props.isLogin(data)
            }
          }
          {...this.props}
        />
      </AppLayout>
    )
  }
}
export default Community
