import React from 'react'

export default function NoSearchResultsSuggestion() {
  return (
    <div className='search-section mt-0'>
      <div className='no_search_result'>
        <p>Suggestions for improving the results:</p>
        <p>Try a different location.</p>
        <p>Check the spelling or try alternate spellings.</p>
        <p>Try a more general search. e.g. "pizza" instead of "pepperoni"</p>
      </div>
    </div>
  )
}
