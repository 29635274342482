import axios from 'axios'
import { customLogger } from '../../utils/CustomLogger'
import React, { Component } from 'react'
import GoogleAutocomplete from 'react-google-autocomplete'
import { Link } from 'react-router-dom'
import {
  DEFAULT_PROFILE_PICTURE,
  REQUIRED_MESSAGE_FOR_SEARCH,
  REQUIRED_MESSAGE_FOR_SEARCH_NO_ORG,
} from '../../services/const'
import { userToken, validateSearchInput } from '../../services/customFunction'
import {
  GOOGLE_GEOCODING_API_KEY,
  PROFILE_PICTURE_SMALL_URL_PATH,
} from '../../services/environment'
import * as route from '../../services/routeConst'
import { ADD_BUSINESS_TITLE } from '../../services/titleConst'
import AppLayout from '../AppLayout'
import './addbusiness.scss'
import { runElasticSearch } from '../../utils/elasticSearch'
import { Helmet } from 'react-helmet'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { signupType } from '../../actions/auth'

var cityObj = []

class AddBusiness extends Component {
  constructor(props) {
    super(props)
    const locationState = props?.location?.state ?? {}
    this.state = {
      searchAddress: '',
      individualBusinessOrganization: '',
      isIndividualBusinessOrganizationDisplay: false,
      individualBusinessOrganizationData: [],
      user: {},
      imatrix_id: props.location.state ? props.location.state.imatrix_id : '',
      showSignUpButton: false,
      searchButtonEnabled: false,
      isLoading: false,
      address_component: [],
      signupType: locationState?.signupType,
      isAdd: locationState?.isAdd,
      loginUserData: locationState?.loginUserData,
    }
  }
  async componentDidMount() {
    this.getCurrentLocationString()
    window.scrollTo(0, 0)
  }
  getCurrentLocationString = async () => {
    this.setState({
      searchAddress: '',
    })
    if (navigator.geolocation) {
      return navigator.geolocation.getCurrentPosition(
        (position) => {
          const pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          }
          let REVERSE_GEOCODING_API_URL = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${pos.lat},${pos.lng}&result_type=political&key=${GOOGLE_GEOCODING_API_KEY}`
          axios
            .get(REVERSE_GEOCODING_API_URL)
            .then((res) => {
              if (
                res.data.results &&
                res.data.results[0] &&
                res.data.results[0].formatted_address
              ) {
                this.setState({
                  searchAddress: res.data.results[0].formatted_address,
                })
              }
            })
            .catch((error) => {
              customLogger(error)
            })
        },
        () => {
          //Geolocator service failed
          this.setState({ searchAddress: '' })
        },
      )
    } else {
      // Browser doesn't support Geolocation
      this.setState({ searchAddress: '' })
    }
  }

  handleChangeGoogle = (event) => {
    this.setState({
      searchButtonEnabled: true,
      showSignUpButton: false,
    })
    let address_component = event.address_components

    let zipcode = address_component.filter((address) => {
      return address.types[0] === 'postal_code'
    })

    let cityName = address_component.filter((address) => {
      return address.types[0] === 'locality'
    })

    let stateName = address_component.filter((address) => {
      return address.types[0] === 'administrative_area_level_1'
    })

    cityObj = []
    if (zipcode.length > 0) {
      cityObj.push({ match: { zipcode: zipcode[0].long_name } })
    }
    if (cityName.length > 0) {
      cityObj.push({ match: { city: cityName[0].long_name } })
    }
    if (stateName.length > 0) {
      cityObj.push({ match: { state: stateName[0].long_name } })
    }

    this.setState({
      searchAddress: event.formatted_address ? event.formatted_address : event.name,
      individualBusinessOrganizationData: [],
      address_component: address_component,
    })
  }

  changeHandler = (event) => {
    let inputName = event.target.name
    let inputValue = event.target.value
    this.setState({
      [inputName]: inputValue,
      individualBusinessOrganizationData: [],
      showSignUpButton: false,
    })
  }

  searchBusiness = async () => {
    if (
      !validateSearchInput(this.state.searchAddress, REQUIRED_MESSAGE_FOR_SEARCH) ||
      !validateSearchInput(
        this.state.individualBusinessOrganization,
        REQUIRED_MESSAGE_FOR_SEARCH_NO_ORG,
      )
    ) {
      this.setState({
        individualBusinessOrganizationData: [],
      })
      return
    }
    this.setState({
      showSignUpButton: false,
      isLoading: true,
    })

    //rerun the elastic search if address changed
    if (this.state.individualBusinessOrganizationData.length === 0) {
      this.elasticSearch(this.state.individualBusinessOrganization).then((response) => {
        if (response.status !== 'failed' && response.status !== 'CANCELLED') {
          this.setState({ individualBusinessOrganizationData: response })
        }
        this.setState({
          showSignUpButton: true,
          isLoading: false,
        })
      })
    } else {
      this.setState({
        showSignUpButton: true,
        isLoading: false,
      })
    }
  }

  addBusinessHandler = (event) => {
    this.props.signupType('business')
    this.props.history.push({
      pathname: route.SIGNUP_NEW,
      state: {
        signupType: 'business',
        isAdd: this.state.isAdd,
        loginUserData: this.state.loginUserData,
        imatrix_id: this.state.imatrix_id,
      },
    })
  }
  contactPageHandler = (event) => {
    this.props.history.push({
      pathname: route.CONTACT_ROUTE,
    })
  }

  individualBusinessOrganizationChangeHandler = (event) => {
    let individualBusinessOrganization = event.target.value
    localStorage.setItem('bus_org_name_from_presearch', individualBusinessOrganization)
    this.setState({ individualBusinessOrganization: individualBusinessOrganization })
    this.setState({
      individualBusinessOrganizationData: [],
    })

    this.elasticSearch(individualBusinessOrganization).then((response) => {
      if (response.status !== 'failed' && response.status !== 'CANCELLED') {
        this.setState({ individualBusinessOrganizationData: response })
      }
    })
  }
  async getGeometryFromSearchAddress(city) {
    let GEOCODING_API_URL = `https://maps.googleapis.com/maps/api/geocode/json?address=${city}&key=${GOOGLE_GEOCODING_API_KEY}`
    try {
      const res = await axios.get(GEOCODING_API_URL)
      return (
        res.data.results &&
        res.data.results[0] &&
        res.data.results[0].geometry &&
        res.data.results[0].geometry.location
      )
    } catch (error) {
      customLogger(error)
    }
  }

  elasticSearch = async (searchStr) => {
    if (searchStr.length >= 2) {
      searchStr = searchStr.replace(/[\u2018\u2019]/g, "'").replace(/[\u201C\u201D]/g, '"')
      searchStr = searchStr.replace(/(')\1{1,}/, "'")
      searchStr = searchStr.replace(/(")\1{1,}/, `"`)
      //   searchStr = searchStr.trim();
    }
    if (searchStr !== '') {
      this.setState({
        searchButtonEnabled: true,
      })
    } else if (searchStr === '' && this.state.searchAddress === '') {
      this.setState({
        searchButtonEnabled: false,
      })
    }

    return runElasticSearch(undefined, {
      search_string: searchStr,
      search_city_string: this.state.searchAddress,
      must_not: [
        {
          match: {
            user_type: 'individual',
          },
        },
      ],
      maxResults: 200,
    })
  }

  onIndividualBusinessOrganizationFocus = (event) => {
    this.setState({
      isIndividualBusinessOrganizationDisplay: true,
    })
    //rerun the elastic search if address changed
    if (this.state.individualBusinessOrganizationData.length === 0) {
      this.elasticSearch(this.state.individualBusinessOrganization).then((response) => {
        if (response.status !== 'failed' && response.status !== 'CANCELLED') {
          this.setState({
            individualBusinessOrganizationData: response,
          })
        }
      })
    }
  }

  onIndividualBusinessOrganizationBlur = (event) => {
    var self = this
    setTimeout(function () {
      self.setState({
        isIndividualBusinessOrganizationDisplay: false,
      })
    }, 150)
  }

  individualBusinessOrganizationClicked = (data) => (event) => {
    this.setState(
      {
        individualBusinessOrganization: data.name,
        businessOrganizationNameSearch: data.name,
        user: data,
      },
      () => {
        this.props.history.push({
          pathname: route.CLAIM_BUSINESS_PROFILE_ROUTE,
          state: {
            user: this.state.user,
          },
        })
      },
    )
  }
  componentWillUnmount() {}

  render() {
    const {
      isIndividualBusinessOrganizationDisplay,
      individualBusinessOrganizationData,
      individualBusinessOrganization,
      showSignUpButton,
      isLoading,
    } = this.state
    return (
      <AppLayout isDisplayFooter={false}>
        <Helmet>
          <title>{ADD_BUSINESS_TITLE}</title>
        </Helmet>
        {/* <AddBusinessOrOrganizationFooter />      */}
        <section className='upsection text-center it-auth-page'>
          <div className='container'>
            <h1>Business Sign Up - Presearch</h1>
            <p className='check'>First, search to see if your business already has a profile.</p>
            {/* <p className="entersubtitle">
                            Enter the name and location of your business below and click it.
                        </p> */}
            <div className='it-auth-part'>
              <form className='bannerform' method='post' action='javascript:;'>
                <div className='bannerinner'>
                  <div className='form-group'>
                    <input
                      type='text'
                      name='individualBusinessOrganization'
                      className='form-control zip1'
                      //component={renderInputField}
                      onChange={this.individualBusinessOrganizationChangeHandler}
                      onFocus={this.onIndividualBusinessOrganizationFocus}
                      onBlur={this.onIndividualBusinessOrganizationBlur}
                      value={individualBusinessOrganization}
                      label='Firstname'
                      placeholder='Enter your business name'
                      autoComplete='off'
                    />
                    {isIndividualBusinessOrganizationDisplay &&
                      individualBusinessOrganizationData.length > 0 && (
                        <div className='dropdown i_drop_suggetion'>
                          <div className='dropdown-content'>
                            {individualBusinessOrganizationData.map((user, index) => {
                              let profile_picture = null
                              if (
                                typeof user['_source'].image !== 'undefined' &&
                                user['_source'].image !== '' &&
                                user['_source'].image
                              ) {
                                profile_picture = (
                                  <img
                                    className='imageTag'
                                    alt=''
                                    src={PROFILE_PICTURE_SMALL_URL_PATH + user['_source'].image}
                                  />
                                )
                              } else {
                                profile_picture = (
                                  <img className='imageTag' alt='' src={DEFAULT_PROFILE_PICTURE} />
                                )
                              }

                              let fullname = null
                              if (user['_source'].user_type === 'business') {
                                fullname = user['_source'].business_organization_name
                              } else if (user['_source'].user_type === 'organization') {
                                fullname = user['_source'].business_organization_name
                              } else {
                                fullname = user['_source'].name
                              }

                              let addressToDisplay = ''
                              if (
                                typeof user['_source'].address_line_1 !== 'undefined' &&
                                user['_source'].address_line_1
                              ) {
                                addressToDisplay += '' + user['_source'].address_line_1
                              }
                              if (
                                typeof user['_source'].address_line_2 !== 'undefined' &&
                                user['_source'].address_line_2
                              ) {
                                addressToDisplay += ' ' + user['_source'].address_line_2
                              }
                              if (
                                typeof user['_source'].city !== 'undefined' &&
                                user['_source'].city
                              ) {
                                addressToDisplay += ' ' + user['_source'].city
                              }
                              if (
                                typeof user['_source'].state !== 'undefined' &&
                                user['_source'].state
                              ) {
                                addressToDisplay += ' ' + user['_source'].state
                              }
                              if (
                                typeof user['_source'].zipcode !== 'undefined' &&
                                user['_source'].zipcode
                              ) {
                                addressToDisplay += ' ' + user['_source'].zipcode
                              }

                              return (
                                <div
                                  key={user['_source'].id}
                                  className='i_suggestion_list'
                                  onClick={this.individualBusinessOrganizationClicked(
                                    user['_source'],
                                  )}
                                >
                                  <a href={null} className='i_suggetion_link'>
                                    <div className='i_photu'>{profile_picture}</div>
                                    <div className='i_info'>
                                      <div className='i_name varela purple'>{fullname}</div>
                                      <div className='i_address'>
                                        {user['_source'].user_type !== 'individual'
                                          ? addressToDisplay
                                          : 'Click to view profile'}
                                      </div>
                                    </div>
                                  </a>
                                </div>
                              )
                            })}
                          </div>
                        </div>
                      )}
                  </div>
                  <div className='form-group'>
                    <GoogleAutocomplete
                      type='text'
                      autoComplete='off'
                      className='form-control zip2'
                      name='searchAddress'
                      onChange={this.changeHandler}
                      value={this.state.searchAddress}
                      placeholder='City, State, or Zip Code...'
                      onPlaceSelected={(place) => {
                        this.handleChangeGoogle(place)
                      }}
                      onFocus={(event) => {
                        event.target.setAttribute('autocomplete', 'off')
                      }}
                      options={{ types: ['geocode'] }}
                      //componentRestrictions={{ country: "in" }}
                    />
                  </div>
                </div>

                <div className='search-btn m-0'>
                  <button
                    onClick={this.searchBusiness}
                    //disabled={!this.state.searchButtonEnabled}
                    className='search m-0'
                  >
                    <i className='fa fa-search' aria-hidden='true'></i>
                  </button>
                </div>
              </form>
            </div>
            {!isIndividualBusinessOrganizationDisplay && showSignUpButton ? (
              <div>
                {this.state.individualBusinessOrganizationData.map((user, index) => (
                  <div className='claim_steps it-auth-search' key={user['_source'].id}>
                    <div className='claim_frnd_detail_box'>
                      <div className='image_blk'>
                        {typeof user['_source'].image !== 'undefined' &&
                        user['_source'].image !== '' &&
                        user['_source'].image ? (
                          <img
                            className='imageTag'
                            alt=''
                            src={PROFILE_PICTURE_SMALL_URL_PATH + user['_source'].image}
                          />
                        ) : (
                          <img className='imageTag' alt='' src={DEFAULT_PROFILE_PICTURE} />
                        )}
                      </div>
                      <div className='claim_frnd_right'>
                        <div className='it-auth-right'>
                          <Link to={process.env.PUBLIC_URL + '/' + user['_source'].slug}>
                            <h5 className='varela'>{user['_source'].business_organization_name}</h5>
                          </Link>
                          <p className='address'>
                            {user['_source'].city != null && user['_source'].city + ', '}
                            {user['_source'].state_name != null && user['_source'].state_name + ' '}
                            {user['_source'].zipcode != null && user['_source'].zipcode}
                          </p>
                        </div>

                        {user['_source'].is_business_claimed === '0' && (
                          <button
                            onClick={this.individualBusinessOrganizationClicked(user['_source'])}
                            className='it-sign-claim mt-3'
                          >
                            Claim Now
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              ''
            )}
            {!userToken() && showSignUpButton && (
              <div className='dont mt-4 mb-4'>
                <p>
                  {/* Asanan 08032-lostid*/}
                  If your business is listed below Claim it or click on it to go to the profile
                  page.
                </p>
                <p className='mb-0'>Don't see your business listed? Continue to signup.</p>
                <a href='javascript:void(0)' onClick={this.addBusinessHandler}>
                  Sign Up Business
                </a>
              </div>
            )}
            {isLoading ? (
              <div className='search-section'>
                <div className='no_search_result'>
                  <div className='search-page-loading-icon'>
                    <small className='fade-in-text'>Searching for businesses</small>
                    <i />{' '}
                  </div>
                </div>
              </div>
            ) : (
              ''
            )}
          </div>
        </section>
      </AppLayout>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ signupType }, dispatch)
}

export default connect(null, mapDispatchToProps)(AddBusiness)
