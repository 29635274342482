import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { DEFAULT_PROFILE_PICTURE } from '../../services/const'
import Linkify from 'react-linkify'
import {
  createGroupMessageCall,
  createMessageCall,
  getAllGroupMessagesCall,
  getAllMessagesCall,
} from '../../services/messageApiService'
import SendIcon from './SendIcon'
import useChatUserSuggestions from '../../hooks/useChatUserSuggestions'
import SearchSuggestions from '../UserHeader/Components/SearchSuggestions'
import NewMessageUserSearch from './NewMessageUserSearch'
import GroupChatHeader from './GroupChatHeader'
import UserChatHeader from './UserChatHeader'
import { CDN_URL } from '../../services/environment'
import { isMobile } from 'react-device-detect'
import { toaster } from '../../services/customFunction'

const ChatMessageBox = ({
  user,
  chat_user,
  group_chat_selected,
  chat_user_title,
  chat_messages_list,
  is_chat_user_typing,
  is_message_fetching,
  chat_message,
  setChatMessage,
  setChatMessagesList,
  scrollToBottom,
  isNewMessage = false,
  chat_users_list,
  newMessageUsers,
  setNewMessageUsers,
  setGroupChatId,
  setChatUserId,
}) => {
  const [showSuggestions, setShowSuggestions] = useState(false)
  const [chatMessageUserData, setChatMessageUserData] = useState(false)
  const messageFormRef = useRef()
  const textInput = useRef()

  const { isSearching, setIsSearching, searchData, setSearchData, fetchUserSuggestions } =
    useChatUserSuggestions(user.id)

  useEffect(() => {
    !!newMessageUsers?.chatUsers?.length ? getAllMessages() : setChatMessagesList([])
  }, [newMessageUsers?.chatUsers?.length])

  const getAllMessages = async () => {
    let request
    let response
    const users = newMessageUsers?.chatUsers
    if (users?.length === 1) {
      request = { chat_user_id: users[0]?.['_id'] }
      response = await getAllMessagesCall(request)
    } else if (users?.length > 1) {
      request = { group_chat_id: 0, friend_ids: users?.map((u) => u['_id']) }
      response = await getAllGroupMessagesCall(request)
    }

    if (response?.status === 200 && response?.data?.chats) {
      setChatMessageUserData(response.data.userData)
      if (
        Array.isArray(response.data.userData) &&
        response.data.userData?.some?.(
          (u) => u?.user_settings?.allow_messages_from === 'Friends' && u?.is_friend_user !== 1,
        )
      ) {
        // setNewMessageUsers((st) => ({
        //   ...st,
        //   chatUsers: st.chatUsers?.filter?.((chatUser) => {
        //     const ud = response.data.userData.find((u) => u?.['_id'] === chatUser.id)
        //     return ud?.user_settings?.allow_messages_from !== 'Friends' && ud?.is_friend_user !== 1
        //   }),
        // }))
        toaster(
          'error',
          'You must be friends with all of the users in a group chat to send them a private group message',
        )
        setChatMessagesList([], () => {}, true)
      } else if (
        response.data.userData?.user_settings?.allow_messages_from === 'Friends' &&
        response.data.userData?.is_friend_user !== 1
      ) {
        // setNewMessageUsers((st) => ({
        //   ...st,
        //   chatUsers: st.chatUsers?.filter?.(
        //     (chatUser) => chatUser?.['_id'] !== response?.userData?.id,
        //   ),
        // }))
        toaster('error', 'You must be friends with this user to send them a private message')
        setChatMessagesList([], () => {}, true)
      } else {
        setChatMessagesList(response.data.chats, () => {}, true)
      }
    }
  }

  const updateMessageBoxHeight = (event) => {
    // Reset field height
    const messageBox = textInput.current
    messageBox.style.height = 'inherit'

    // Get the computed styles for the element
    const computed = window.getComputedStyle(messageBox)

    // Calculate the height
    const height =
      messageBox.scrollHeight - parseInt(computed.getPropertyValue('padding-bottom'), 10)

    messageBox.style.height = `${Math.min(height, 151)}px`
  }

  const onChangeMessageHandler = (event) => {
    updateMessageBoxHeight(event)
    setChatMessage(event.target.value)
  }

  const onKeyUpMessageHandler = (event) => {
    window.Echo.private('Chat.' + user.id + '.' + chat_user?.id).whisper('typing', {
      name: user.fullname,
    })
    // if (event.keyCode === 13 && event.shiftKey === false) {
    //   sendMessage();
    // }
  }

  const sendMessage = async (event, user) => {
    let chatMessage = chat_message.trim()

    setChatMessage('', () => updateMessageBoxHeight(event))
    if (chatMessage !== '') {
      let createMessageReqData = {
        friend_id: user?.id ?? chat_user.id,
        chat: chat_message,
      }

      let createMessageReqheaders = {
        'X-Socket-ID': window.Echo.socketId(),
      }

      const response = await createMessageCall(createMessageReqData, createMessageReqheaders)
      if (response?.status === 200 && !!response?.data?.chat) {
        setChatMessagesList([...chat_messages_list, response.data.chat], () => {
          !!isNewMessage && setChatUserId(response.data.chat.friend_id)
          scrollToBottom()
        })
      }
    }
  }

  const sendMessageToGroup = async (event, users) => {
    let chatMessage = chat_message.trim()

    setChatMessage('', () => updateMessageBoxHeight(event))
    if (chatMessage !== '') {
      let createMessageReqData = {
        group_chat_id: group_chat_selected?.group_chat_id,
        friend_ids: users?.map((user) => user['_id']),
        chat: chat_message,
      }

      let createMessageReqheaders = {
        'X-Socket-ID': window.Echo.socketId(),
      }

      const response = await createGroupMessageCall(createMessageReqData, createMessageReqheaders)
      if (response?.status === 200 && !!response?.data?.chat) {
        setChatMessagesList([...chat_messages_list, response.data.chat], () => {
          !!isNewMessage && setGroupChatId(response.data.chat.group_chat_id)
          scrollToBottom()
        })
      }
    }
  }

  const sendMessages = async (event) => {
    if (newMessageUsers.chatUsers?.length > 1 || group_chat_selected) {
      await sendMessageToGroup(event, newMessageUsers?.chatUsers)
    } else {
      await sendMessage(event, newMessageUsers.chatUsers?.[0]?.['_source'])
    }
  }

  const handleUserSearchChange = (event, newValue) => {
    setSearchData((st) => ({ searchStr: newValue, results: st.results }))
  }

  const setChatUsers = (users) => {
    setNewMessageUsers({ ...newMessageUsers, chatUsers: users })
  }

  return (
    <div
      className={
        'message_right ' + (chat_user || group_chat_selected || isNewMessage ? '' : 'd-none')
      }
    >
      <div
        className='chat_panel_header'
        id='chat_panel_header'
        style={group_chat_selected ? { gap: 8 } : {}}
      >
        {chat_user ? (
          <UserChatHeader chat_user={chat_user} chat_user_title={chat_user_title} />
        ) : group_chat_selected ? (
          <GroupChatHeader group_chat_selected={group_chat_selected} />
        ) : (
          <div className='d-flex flex-column w-100'>
            <div
              className='d-flex align-items-center new-message-user-search-wrapper position-relative'
              style={{ gap: 8 }}
            >
              <NewMessageUserSearch
                options={searchData?.results ?? []}
                setOptions={setSearchData}
                searchData={searchData}
                handleUserSearchChange={handleUserSearchChange}
                isSearching={isSearching}
                setChatUsers={setChatUsers}
              />
            </div>
            {chat_messages_list?.length ? (
              <div className='chat_panel_header' style={{ paddingBottom: 0, borderBottom: 0 }}>
                {newMessageUsers?.chatUsers?.length === 1 ? (
                  <UserChatHeader
                    chat_user={{
                      ...newMessageUsers.chatUsers[0]['_source'],
                      profile_picture_small: `${CDN_URL}profile_picture/small/${newMessageUsers.chatUsers[0]['_source'].image}`,
                    }}
                    chat_user_title={
                      newMessageUsers.chatUsers[0]['_source'].user_type === 'individual'
                        ? newMessageUsers.chatUsers[0]['_source'].fullname ||
                          newMessageUsers.chatUsers[0]['_source'].name
                        : newMessageUsers.chatUsers[0]['_source'].business_organization_name
                    }
                  />
                ) : newMessageUsers?.chatUsers?.length > 1 ? (
                  <GroupChatHeader
                    group_chat_selected={{
                      users: newMessageUsers?.chatUsers?.map((u) => ({
                        ...u['_source'],
                        fullname: u['_source'].sortname,
                      })),
                    }}
                  />
                ) : null}
              </div>
            ) : null}
          </div>
        )}
      </div>
      <div id='chat_panel_area' className='chat_panel_area'>
        {chat_messages_list?.length ? (
          <>
            {chat_messages_list.map((message, index) => {
              const usr = group_chat_selected
                ? group_chat_selected.users?.find((u) => u.id === message.user_id)
                : chat_users_list.find((u) => u.id === message.user_id)

              return (
                <div key={message.id} className={message.user_id === user.id ? 'you' : 'other'}>
                  {/* <div className="img_blk"></div> */}
                  {message.user_id !== user.id ? (
                    <div className='avatar-base chat-tag-avatar' title={user?.fullname}>
                      <img
                        src={usr?.user_detail?.profile_picture_small ?? DEFAULT_PROFILE_PICTURE}
                        alt=''
                      />
                    </div>
                  ) : null}
                  <div className='txt_blk'>
                    <div className='txt'>
                      <p style={{ whiteSpace: 'pre-wrap' }}>
                        <Linkify
                          componentDecorator={(decoratedHref, decoratedText, key) => (
                            <a
                              target='blank'
                              rel='noopener noreferrer'
                              href={decoratedHref}
                              key={key}
                            >
                              {decoratedText}
                            </a>
                          )}
                        >
                          {message.chat?.replaceAll?.('<br />', '')}
                        </Linkify>
                      </p>
                    </div>
                  </div>
                  {message.user_id === user.id ? (
                    <div className='avatar-base chat-tag-avatar' title={user?.fullname}>
                      <img
                        src={usr?.user_detail?.profile_picture_small ?? DEFAULT_PROFILE_PICTURE}
                        alt=''
                      />
                    </div>
                  ) : null}
                </div>
              )
            })}
            {chat_user && !!is_chat_user_typing ? (
              <div className='other'>
                {/* <div className="img_blk"></div> */}
                <div className='txt_blk'>
                  <div className='txt'>
                    <p style={{ minWidth: 52, paddingTop: 5 }}>
                      <div className='dot-flashing'></div>
                    </p>
                  </div>
                </div>
              </div>
            ) : null}
          </>
        ) : !isNewMessage && is_message_fetching ? (
          <div className='search-page-loading-icon h-100'>
            <i className='h-100' />
          </div>
        ) : chat_user && !!is_chat_user_typing ? (
          <div className='other'>
            {/* <div className="img_blk"></div> */}
            <div className='txt_blk'>
              <div className='txt'>
                <p>
                  <div className='dot-flashing'></div>
                </p>
              </div>
            </div>
          </div>
        ) : null}
      </div>
      <div className='user-typing'>
        {chat_user && is_chat_user_typing === true && <div>{chat_user.fullname} is typing... </div>}
      </div>
      <div className='chat_panel_type px-2'>
        <form
          name='messageForm'
          // onSubmit={messageFormSubmitHandler}
          ref={messageFormRef}
          style={{ position: 'relative' }}
        >
          <textarea
            ref={textInput}
            name='message_txt_input'
            placeholder='Enter a message...'
            onChange={onChangeMessageHandler}
            onKeyUp={onKeyUpMessageHandler}
            value={chat_message}
            id='newMsg-Input'
            disabled={!!is_message_fetching}
            style={{
              borderRadius: '2rem',
              resize: 'none',
              backgroundColor: '#f0f0f0',
              // backgroundColor: 'grey',
              position: 'relative',
            }}
          />
          {/* <label
                            style={{
                              position: 'absolute',
                              // top: '28.5%',
                              opacity: chat_message === '' ? 1 : 0,
                            }}
                            htmlFor='newMsg-Input'
                            className='text-muted'
                          >
                            Enter a message...
                          </label> */}
          <SendIcon
            sendMessage={sendMessages}
            disabled={
              (chat_user?.user_settings?.allow_messages_from === 'Friends' &&
                chat_user?.is_friend_user !== 1) ||
              (chatMessageUserData?.user_settings?.allow_messages_from === 'Friends' &&
                chatMessageUserData?.is_friend_user !== 1) ||
              chatMessageUserData?.some?.(
                (u) =>
                  u?.user_settings?.allow_messages_from === 'Friends' && u?.is_friend_user !== 1,
              )
            }
          />
        </form>
      </div>
    </div>
  )
}

export default ChatMessageBox
