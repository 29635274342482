import React from 'react'
import AppLayout from '../AppLayout'
import '../Message/message.scss'
import LoginModal from '../LoginModal/LoginModal'
import { Helmet } from 'react-helmet'
import './MarketingCenterGuide.scss'
import { ItiText } from '../About/About'
import { Link } from 'react-router-dom'
import {
  MARKETING_CENTER_CTA_EXAMPLES_ROUTE,
  PRIVACY_ROUTE,
  TERMS_ROUTE,
} from '../../services/routeConst'

const MarketingCenterGuide = (props) => {
  const loginHandler = (data) => {
    props?.isLogin?.(data)
  }
  return (
    <AppLayout routeActive={props.location.pathname}>
      <Helmet>
        <title>iTRUSTit Marketing Center Guide</title>
      </Helmet>
      <section className='bg-grey'>
        <div className='container mt-3'>
          <h3 className='varela mb-2'>Marketing Center - Brand Asset Use Manual</h3>
          <p className='mb-2'>Last Updated: 8/9/2022</p>
          <div className='white-pane green_line'>
            <div className='marketing-center-guide mb-5'>
              <div className='d-flex flex-column mt-3'>
                <h3 className='mont fw-bold mb-4'>
                  Rules for using the <ItiText /> Logos
                </h3>
                <h6 className='mont'>
                  When linking to or referring to your iTRUSTit page, you can use the "iTi" bug
                  logos or the full iTRUSTit logos.
                </h6>
                <h6 className='mont mb-4'>Each logo comes in EPS, PNG, and SVG.</h6>
                <h6 className='mont mb-4'>
                  When used next to other social icons, the "iTRUSTit" and "iTi" logos should be
                  equial size to the other icons.
                </h6>
                <h6 className='mont mb-4'>
                  You should keep the original shape and proportional dimensions of our logos.
                </h6>
                <h6 className='mont mb-4'>Our logos should be used upright and not rotated.</h6>
                <h6 className='mont mb-4'>
                  The colors should only be our approved colors. The black, grey or white should
                  only be used when surrounding logos are the same color.
                </h6>
                <h6 className='mont mb-4'>
                  When using the iTRUSTit or iTi logos or names, you should always use the &trade;
                  or &reg; symbol with it
                </h6>
                <h6 className='mont mb-1'>
                  iTRUSTit should always be written with a lowercase 'i', uppercase 'TRUST', and
                  lowercase 'it'.
                </h6>
                <h6 className='mont mb-4'>
                  The iTi bug logo should always be shown as lowercase 'i', uppercase 'T', and
                  lowercase 'i'.
                </h6>
                <h6 className='mont mt-4 mb-1'>
                  Be sure to have a call to action that shows your iTRUSTit use like,
                </h6>
                <h6 className='mont mb-1'>
                  "Trust Us on iTRUSTit" or "See the Businesses and Organizations I Trust to
                  Recommend to My Friends on iTRUSTit"
                </h6>
                <Link
                  to={MARKETING_CENTER_CTA_EXAMPLES_ROUTE}
                  target='_blank'
                  className='mont mb-5'
                  style={{ fontSize: 16 }}
                >
                  Click here for more Call-To-Action examples
                </Link>
                <h6 className='mont mt-4 mb-2'>
                  By using the iTRUSTit logos, you agree to the rules and the iTRUSTit{' '}
                  <Link to={PRIVACY_ROUTE} target='_blank'>
                    Privacy Policy
                  </Link>
                  &nbsp;and{' '}
                  <Link target='_blank' to={TERMS_ROUTE}>
                    Terms of Service
                  </Link>
                </h6>
              </div>
            </div>
          </div>
        </div>
      </section>
      <LoginModal isLogin={loginHandler} {...props} />
    </AppLayout>
  )
}

export default MarketingCenterGuide
