import axios from 'axios'
import { customLogger } from '../utils/CustomLogger'
import * as environemnt from './environment'
import { userToken, toaster } from './customFunction'

export const friendCreateCall = (request) => {
  return axios
    .post(
      environemnt.BASE_API + 'friends/' + request.user_id + '/create',
      {},
      {
        headers: {
          Authorization: userToken(),
          Accept: 'application/x.iTRUSTit.v1+json',
        },
      },
    )
    .then(function (response) {
      // console.log(response);
      return response.data
    })
    .catch(function (error) {
      if (
        typeof error.response !== 'undefined' &&
        typeof error.response.data !== 'undefined' &&
        typeof error.response.data.message !== 'undefined' &&
        error.response.data.message
      ) {
        toaster('error', error.response.data.message)
      }
      customLogger(error)
      return error.response
    })
}

export const friendChangeStatusCall = (request) => {
  return axios
    .post(
      environemnt.BASE_API + 'friends/' + request.friendship_id + '/change-status',
      {
        status: request.status,
      },
      {
        headers: {
          Authorization: userToken(),
          Accept: 'application/x.iTRUSTit.v1+json',
        },
      },
    )
    .then(function (response) {
      // console.log(response);
      return response.data
    })
    .catch(function (error) {
      if (
        typeof error.response !== 'undefined' &&
        typeof error.response.data !== 'undefined' &&
        typeof error.response.data.message !== 'undefined' &&
        error.response.data.message
      ) {
        toaster('error', error.response.data.message)
      }
      customLogger(error)
      return error.response
    })
}

export const friendListCall = (request) => {
  let api_url = environemnt.BASE_API + 'friends'
  if (typeof request.other_user_id !== 'undefined' && request.other_user_id > 0) {
    api_url = environemnt.BASE_API + 'friends/' + request.other_user_id
  }

  return axios
    .post(api_url, request, {
      headers: {
        Authorization: userToken(),
        Accept: 'application/x.iTRUSTit.v1+json',
      },
    })
    .then(function (response) {
      return response.data
    })
    .catch(function (error) {
      if (
        typeof error.response !== 'undefined' &&
        typeof error.response.data !== 'undefined' &&
        typeof error.response.data.message !== 'undefined' &&
        error.response.data.message
      ) {
        toaster('error', error.response.data.message)
      }
      customLogger(error)
      return error.response
    })
}

export const friendListCallNew = async (request) => {
  let api_url = environemnt.BASE_API + 'friends-list/' + (request.user_id ? request.user_id : '')
  if (typeof request.other_user_id !== 'undefined' && request.other_user_id >= 0) {
    api_url = environemnt.BASE_API + 'friends-list/' + request.other_user_id
  }
  // console.log(api_url);
  try {
    const response = await axios.post(api_url, request, {
      headers: {
        Authorization: userToken(),
        Accept: 'application/x.iTRUSTit.v1+json',
      },
    })
    return response.data
  } catch (error) {
    if (
      typeof error.response !== 'undefined' &&
      typeof error.response.data !== 'undefined' &&
      typeof error.response.data.message !== 'undefined' &&
      error.response.data.message
    ) {
      toaster('error', error.response.data.message)
    }
    customLogger(error)
    return error.response
  }
}

export const friendListCallNewFromIDs = async (request) => {
  let api_url = environemnt.BASE_API + 'get-friends-list-from-ids'
  // console.log(api_url);
  // console.log(request);
  try {
    const response = await axios.post(api_url, request, {
      headers: {
        Authorization: userToken(),
        Accept: 'application/x.iTRUSTit.v1+json',
      },
    })
    // console.log(response);
    return response.data
  } catch (error) {
    if (
      typeof error.response !== 'undefined' &&
      typeof error.response.data !== 'undefined' &&
      typeof error.response.data.message !== 'undefined' &&
      error.response.data.message
    ) {
      toaster('error', error.response.data.message)
    }
    customLogger(error)
    return error.response
  }
}
