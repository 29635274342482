import React, { Fragment, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import * as route from '../../../services/routeConst'
import { DEFAULT_PROFILE_PICTURE } from '../../../services/const'
import ShareModal, { showShareModal } from '../../CommonComponents/ShareModal/ShareModal'
import PromotionButton from '../../Promotion/PromotionButton'
import { getCurrentPromotion } from '../../../services/promotionService'
import Cropperjs from '../../Cropper/Cropper'
import useProfilePictureUpdate from '../../../hooks/useProfilePictureUpdate'
import ISupport from '../../CommonComponents/ISupport/ISupport'
import { isMobile } from 'react-device-detect'

const ProfileHeader = ({ user, setSettingsType = () => {}, refreshUser }) => {
  const [promotionData, setPromotionData] = useState(false)

  const { validation, userImg, handleImageChange, updateProfilePicture, setImgFile } =
    useProfilePictureUpdate(user)

  useEffect(() => {
    const getPromotionData = async () => {
      const promotions = await getCurrentPromotion({ userId: user?.id })
      const promotionId = promotions?.data?.id
      if (promotionId) {
        setPromotionData(promotions?.data)
      }
    }
    getPromotionData()
  }, [])

  const editProfileRoute =
    route[
      `EDIT_${
        user.user_type === 'individual' ? '' : user.user_type?.toUpperCase() + '_'
      }PROFILE_ROUTE`
    ]
  return (
    <Fragment>
      <ShareModal user={user} />
      <div className={'pro_name_detail d-flex position-relative ' + user.user_type + '_profile'}>
        {promotionData?.id ? <PromotionButton userId={user?.id} /> : null}
        {/* <img src={user.user_detail?.profile_picture_medium ?? DEFAULT_PROFILE_PICTURE} alt='' /> */}
        <label
          className='image-cropper-image my-0'
          data-bs-toggle='modal'
          data-bs-target='#cropperModal'
        >
          {/* <input type="file" className="file" name="zip_file_import" /> */}
          <div className={'pro_1'}>
            <span>Update profile picture</span>
            <img src={userImg ?? DEFAULT_PROFILE_PICTURE} alt='' />
          </div>
        </label>
        <Cropperjs
          srcData={handleImageChange}
          imgsrc={userImg}
          updateProfile={updateProfilePicture}
          errors={validation?.errors}
          setImgFile={setImgFile}
        />
        <div className='pro_name d-flex flex-column position-relative'>
          <h3 style={{ wordBreak: 'break-word' }} className='blue'>
            {user.fullname}
          </h3>
          <p style={{ wordBreak: 'break-word' }} className='mb-1'>
            {user.user_detail?.tagline ?? (
              <Link className='' to={editProfileRoute}>
                add tagline
              </Link>
            )}
          </p>
          <div className='d-flex flex-column'>
            <div className='d-flex mb-2'>
              <Link
                style={{ width: 160 }}
                className='text-uppercase text-center edit_profile me-2'
                to={editProfileRoute}
              >
                Edit Profile
              </Link>
              <Link
                style={{ width: 160 }}
                className='text-uppercase text-center edit_profile vcard-settings'
                onClick={() => {
                  setSettingsType('vcard')
                }}
                to={route.PROFILE_ROUTE + '?type=vcard#privacy-settings'}
              >
                vCard Settings
              </Link>
            </div>
            <div className='d-flex'>
              <button
                style={{ width: 160, background: '#fff' }}
                className='text-uppercase text-center edit_profile me-2'
                onClick={() => showShareModal()}
                // to={activeTab}
              >
                <i>
                  <img src={process.env.PUBLIC_URL + '/img/share.png'} alt=' ' className='me-2' />
                </i>{' '}
                Share
              </button>
              <Link
                style={{ width: 160 }}
                className='text-uppercase text-center edit_profile'
                to={`/vcard/${user?.slug}`}
                target='_blank'
                rel='noopener noreferrer'
              >
                <i>
                  <img
                    src={process.env.PUBLIC_URL + '/img/vcard-black.svg'}
                    width={21}
                    alt=' '
                    className='me-2'
                  />
                </i>{' '}
                VCard
              </Link>
            </div>
          </div>
          {!isMobile ? <ISupport user={user} isProfilePage refreshUser={refreshUser} /> : null}
        </div>
      </div>
    </Fragment>
  )
}

export default ProfileHeader
