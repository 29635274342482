import React from 'react'
import { formatMembersName } from '../../../services/formatService'

const MemberButton = ({ confirmAddRemove, memberAddRemove, user, isLoggedIn = true }) => {
  const membersName =
    formatMembersName(
      user?.user_settings?.members_name,
      user?.user_settings?.custom_members_name,
    ) ?? 'Members'

  const confirmMemberAddRemove = async (needsConfirmation = false) => {
    const data = {
      is_member_user: user.is_member_user,
      id: user.id,
    }
    needsConfirmation
      ? confirmAddRemove(needsConfirmation, data, 'header')
      : await memberAddRemove?.(data)
  }

  const buttonProps = isLoggedIn
    ? {
        onClick: () => confirmMemberAddRemove([1, 2].includes(user?.is_member_user)),
      }
    : {
        onClick: () => {},
        'data-bs-toggle': 'modal',
        'data-bs-target': '#login_modal',
      }

  return (
    <button
      {...buttonProps}
      key='member-btn'
      className={`orange
        ${
          user?.is_member_user === 2
            ? ' orange-pending'
            : user?.is_member_user === 1
            ? ' member-fill'
            : ''
        }`}
      style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}
    >
      <i
        aria-hidden='true'
        className={`fas fa-${
          user?.is_member_user === 1
            ? 'check'
            : user?.is_member_user === 2
            ? 'parking-circle'
            : 'plus'
        } me-1`}
      />
      {formatMembersName(membersName, null, false).toUpperCase()}
    </button>
  )
}

export default MemberButton
