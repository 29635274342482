//THIS FILE SEEMS TO BE OBSOLETED
import React, { Component } from 'react'
import AppLayout from '../AppLayout'
import { Field, reduxForm } from 'redux-form'
import classNames from 'classnames'
import '../AddOrganization/addorgannization.scss'
import '../Claimbusiness/Claimbusiness.scss'
import renderInputField from '../Form/Input'
import * as validation from '../../services/formValidation'
import * as api from '../../services/businessService'
import ReactSelect from '../../services/ReactSelect'

class Claimbusiness extends Component {
  constructor(props) {
    super(props)
    this.state = {
      countryList: [],
      stateList: [],
      cityList: [],
      stateValue: null,
      cityValue: null,
      isDisplayForm2: false,
      formData: [],
    }
  }
  componentDidMount() {
    window.scrollTo(0, 0)
    this.country()
  }

  singupHandler = (event) => {
    let inputName = event.target.name
    let inputValue = event.target.value
    if (inputName === 'country') this.stateHandler(inputValue)
    if (inputName === 'state') this.city(inputValue)
  }

  countrySelectHandler = (event) => {
    this.setState({ stateValue: null, cityValue: null })
    this.stateHandler(event.value)
    this.setState({ countryObject: event })
    /*210824-1*/
    this.updateCountryDepencency()
  }
  /**
   * Function that return nothing and when country is selected reset all: state,zip, * city (area) and mark them as tocuhed for error. address may be included
   * @author   Amalia
   * @task     210824-1
   */
  updateCountryDepencency = () => {
    this.props.change('state_id', null)
    this.props.touch('state_id', null)
    this.props.change('zipcode', null)
    this.props.touch('zipcode', null)
    this.props.change('city_id', null)
    this.props.touch('city_id', null)
    this.props.change('area', null)
    this.props.touch('area', null)
  }
  stateSelectHandler = (event) => {
    this.setState({ stateValue: event.value, cityValue: null })
    this.city(event.value)
    /*210824-1 when state is selected reset city and mark isas touched for error*/
    this.props.change('city_id', null)
    this.props.touch('city_id')
  }

  citySelectHandler = (event) => {
    this.setState({ cityValue: event.value })
  }

  country = () => {
    let self = this
    let stateCopy = { ...this.state }
    api.countryList().then(function (response) {
      //console.log("response",response);

      if (response.status === true) {
        response.countries.map((country) => {
          let objContry = { value: country.id, label: country.name }
          stateCopy['countryList'].push(objContry)
        })
        self.setState(stateCopy)
      }
    })
  }

  stateHandler(id) {
    var self = this
    this.setState({
      stateList: [],
      cityList: [],
      stateValue: null,
      cityValue: null,
    })

    api.stateList(id).then(function (response) {
      if (response.status === true) {
        let stateCopy = { ...self.state }
        response.states.map((state, index) => {
          if (index === 0) stateCopy['stateList'].push({ value: 0, label: 'Select...' })

          let objState = { value: state.id, label: state.name }
          stateCopy['stateList'].push(objState)
        })
        self.setState(stateCopy, () => {
          if (self.state.stateList.length > 0)
            self.setState({ stateValue: self.state.stateList[0].value })
        })
      }
    })
  }

  city(id) {
    let self = this
    this.setState({ cityList: [] })

    api.cityList(id).then(function (response) {
      if (response.status === true) {
        let stateCopy = { ...self.state }
        response.cities.map((city, index) => {
          if (index === 0) stateCopy['cityList'].push({ value: 0, label: 'Select...' })
          let objState = { value: city.id, label: city.name }
          stateCopy['cityList'].push(objState)
        })
        self.setState(stateCopy, () => {
          if (self.state.cityList.length > 0)
            self.setState({ cityValue: self.state.cityList[0].value })
        })
      }
    })
  }
  signupOrganizationSubmitHandler = (data) => {
    data.country_id = data.country_id.value
    data.state_id = data.state_id.value
    data.city_id = data.city_id.value
    this.props.isDisplayForm2(true)
    this.props.formData(data)
  }
  render() {
    const { invalid } = this.props
    const { countryList, stateList, cityList, countryObject } = this.state
    return (
      <AppLayout>
        <div className='container'>
          {/* -------------------------------------------- step 1 ---------------------------------------------------- */}
          <div className='claim_steps'>
            <h3 className='add_title'>
              <span className='other_txt'> Claim Your Business</span>
            </h3>
            <p className='text-center mt-3 mb-3'>
              To protect you and your business we need to verify your association with this
              business.{' '}
            </p>
            <div className='claim_frnd_detail_box'>
              <div className='image_blk'>
                <img src={process.env.PUBLIC_URL + '/img/img_frnd.png'} alt='food' />
              </div>
              <div className='claim_frnd_right'>
                <h5 className='varela'>Mangrove Realty</h5>
                <p className='address'>150 Briarwood Drive Goldsboro, NC 27530</p>
              </div>
            </div>
            <div className='text-center mt-3 mb-3'>
              Not your business?
              <a href={null} className='blue'>
                {' '}
                Search for a different business{' '}
              </a>
            </div>
            <div className='add_bottom'>
              <div className='text-center'>
                <a href={null} className='continue'>
                  Continue
                </a>
              </div>
            </div>
          </div>
          {/* -------------------------------------------- step 1 over ---------------------------------------------------- */}
          <h3 className='add_title'>
            <span className='other_txt'>Claim Your Business - Your Info</span>
          </h3>
          <div className='row'>
            <div className='loginblk signupblk'>
              <div className='panel panel-default'>
                <div className='panel-body'>
                  <form method='post' id='signupOrganizationForm1' name='signupOrganizationForm1'>
                    <fieldset>
                      <div className='row fieldflex form-group'>
                        <div className='col-md-3'>
                          <label className='control-required'>Your iTeam ID#:</label>
                        </div>

                        <div className='col-md-9'>
                          <Field
                            name='zipcode'
                            type='text'
                            className='form-control'
                            disabled={true}
                            component={renderInputField}
                            validate={[validation.required]}
                            label='zipcode'
                            placeholder='For no sponsor please leave this blank'
                            autoComplete='off'
                          />
                        </div>
                      </div>
                      <div className='row fieldflex form-group'>
                        <div className='col-md-3'>
                          <label className='control-required'>Your First Name:</label>
                        </div>
                        <div className='col-md-9'>
                          <Field
                            name='zipcode'
                            type='text'
                            className='form-control'
                            component={renderInputField}
                            validate={[validation.required]}
                            label='zipcode'
                            placeholder='Enter first name'
                            autoComplete='off'
                          />
                        </div>
                      </div>
                      <div className='row fieldflex form-group'>
                        <div className='col-md-3'>
                          <label className='control-required'>Your Last Name:</label>
                        </div>
                        <div className='col-md-9'>
                          <Field
                            name='zipcode'
                            type='text'
                            className='form-control'
                            component={renderInputField}
                            validate={[validation.required]}
                            label='zipcode'
                            placeholder='Enter last name'
                            autoComplete='off'
                          />
                        </div>
                      </div>
                      <div className='row fieldflex form-group'>
                        <div className='col-md-3'>
                          <label className='control-required'>Relationship to Business:</label>
                        </div>
                        <div className='col-md-9'>
                          <div className='for-select'>
                            <Field
                              type='text'
                              component={ReactSelect}
                              name='country_id'
                              options={countryList}
                              onChange={this.countrySelectHandler}
                              validate={[validation.required]}
                              isSelect='false'
                            />
                          </div>
                        </div>
                      </div>
                      <div className='row fieldflex form-group'>
                        <div className='col-md-3'>
                          <label className='control-required'>Email Address:</label>
                        </div>
                        <div className='col-md-9'>
                          <Field
                            name='zipcode'
                            type='text'
                            className='form-control'
                            component={renderInputField}
                            validate={[validation.required]}
                            label='zipcode'
                            placeholder='Enter email address'
                            autoComplete='off'
                          />
                        </div>
                      </div>

                      <div className='row fieldflex form-group'>
                        <div className='col-md-3'>
                          <label className='control-required'>Confirm Email Address:</label>
                        </div>
                        <div className='col-md-9'>
                          <Field
                            name='zipcode'
                            type='text'
                            className='form-control'
                            component={renderInputField}
                            validate={[validation.required]}
                            label='zipcode'
                            placeholder='Enter email address'
                            autoComplete='off'
                          />
                        </div>
                      </div>
                      <div className='row fieldflex form-group'>
                        <div className='col-md-3'>
                          <label className='control-required'>Business Username:</label>
                        </div>
                        <div className='col-md-9'>
                          <Field
                            name='zipcode'
                            type='text'
                            className='form-control'
                            component={renderInputField}
                            validate={[validation.required]}
                            label='zipcode'
                            placeholder='Enter user name'
                            autoComplete='off'
                          />
                        </div>
                      </div>

                      <div className='row fieldflex form-group'>
                        <div className='col-md-3'>
                          <label className='control-required'>Business iTRUSTit Web Site:</label>
                        </div>
                        <div className='col-md-9 text-start'>
                          <a className='' href={null}>
                            www.itrustit.com/username
                          </a>
                        </div>
                      </div>
                      <div className='row fieldflex form-group'>
                        <div className='col-md-3'>
                          <label className='control-required'>Password:</label>
                        </div>
                        <div className='col-md-9'>
                          <Field
                            name='address_line_2'
                            type='text'
                            className='form-control'
                            component={renderInputField}
                            validate={[validation.required]}
                            label='address_line_2'
                            placeholder='Enter password'
                            autoComplete='off'
                          />
                        </div>
                      </div>
                      <div className='row fieldflex form-group'>
                        <div className='col-md-3'>
                          <label className='control-required'>Re-Type Password:</label>
                        </div>
                        <div className='col-md-9'>
                          <Field
                            name='address_line_2'
                            type='text'
                            className='form-control'
                            component={renderInputField}
                            validate={[validation.required]}
                            label='address_line_2'
                            placeholder='Enter password'
                            autoComplete='off'
                          />
                        </div>
                      </div>

                      <div className='row fieldflex form-group'>
                        <div className='col-md-3'>
                          <label className='control-required'>Gender:</label>
                        </div>
                        <div className='col-md-9'>
                          <div className='choose_1'>
                            <div className='radio1'>
                              <label className='radioblk'>
                                <input type='radio' name='radio' />
                                <span className='checkmark'></span>
                                Male
                              </label>
                            </div>
                            <div className='radio1'>
                              <label className='radioblk'>
                                <input type='radio' name='radio' />
                                <span className='checkmark'></span>
                                Female
                              </label>
                            </div>
                            <div className='radio1'>
                              <label className='radioblk'>
                                <input type='radio' name='radio' />
                                <span className='checkmark'></span>
                                Other
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </fieldset>

                    <div className='add_bottom'>
                      <div>
                        <input
                          type='submit'
                          value='Continue'
                          className={classNames(
                            invalid && 'disabled',
                            'btn',
                            'btn-lg',
                            'btn-success',
                            'btn-block',
                            'loginbtn',
                            'continuebtn',
                          )}
                          disabled={this.state.is_username_exists === '1' || invalid}
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <h3 className='add_title'>
            <span className='other_txt'>Claim Your Business - Address</span>
          </h3>
          <div className='row'>
            <div className='loginblk signupblk'>
              <div className='panel panel-default'>
                <div className='panel-body'>
                  <form method='post' id='signupOrganizationForm1' name='signupOrganizationForm1'>
                    <fieldset>
                      <div className='row fieldflex form-group'>
                        <div className='col-md-3'>
                          <label className='control-required'>Country:</label>
                        </div>
                        <div className='col-md-9'>
                          <div className='for-select'>
                            <Field
                              type='text'
                              component={ReactSelect}
                              name='country_id'
                              options={countryList}
                              onChange={this.countrySelectHandler}
                              validate={[validation.required]}
                              isSelect='false'
                            />
                          </div>
                        </div>
                      </div>
                      {countryObject ? (
                        <div className='row fieldflex form-group'>
                          <div className='col-md-3'>
                            <label className='control-required'>Zip / Postal Code:</label>
                          </div>
                          <div className='col-md-9'>
                            <Field
                              name='zipcode'
                              type='text'
                              className='form-control'
                              component={renderInputField}
                              validate={[validation.required]}
                              label='zipcode'
                              placeholder='Enter your zipcode'
                              autoComplete='off'
                            />
                          </div>
                        </div>
                      ) : (
                        ''
                      )}
                      {stateList.length > 0 ? (
                        <div className='row fieldflex form-group'>
                          <div className='col-md-3'>
                            <label className='control-required'>State/Province:</label>
                          </div>
                          <div className='col-md-9'>
                            <div className='for-select'>
                              <Field
                                type='text'
                                className='form-control'
                                component={ReactSelect}
                                name='state_id'
                                options={stateList}
                                value={this.state.stateValue}
                                valueKey={this.state.stateValue}
                                onChange={this.stateSelectHandler}
                                validate={[validation.required]}
                                isSelect='true'
                              />
                            </div>
                          </div>
                        </div>
                      ) : (
                        ''
                      )}{' '}
                      {cityList.length > 0 ? (
                        <div className='row fieldflex form-group'>
                          <div className='col-md-3 control-required'>City:</div>
                          <div className='col-md-9'>
                            <div className='for-select'>
                              <Field
                                type='text'
                                className='form-control'
                                component={ReactSelect}
                                name='city_id'
                                options={cityList}
                                value={this.state.cityValue}
                                onChange={this.citySelectHandler}
                                valueKey={this.state.cityValue}
                                isSelect='true'
                                validate={[validation.required]}
                              />
                            </div>
                          </div>
                        </div>
                      ) : this.state.cityFlag ? (
                        <div className='row fieldflex form-group'>
                          <div className='col-md-3'>
                            <label className='control-required'>City/Area:</label>
                          </div>
                          <div className='col-md-9'>
                            <Field
                              name='area'
                              type='text'
                              className='form-control'
                              component={renderInputField}
                              label='City/Area'
                              placeholder='Enter city/crea'
                              autoComplete='off'
                              onChange={this.areaChangedHandler}
                            />
                          </div>
                        </div>
                      ) : (
                        ''
                      )}
                      <div className='row fieldflex form-group'>
                        <div className='col-md-3'>
                          <label className='control-required'>Address 1</label>
                        </div>
                        <div className='col-md-9'>
                          <Field
                            name='address_line_1'
                            type='text'
                            className='form-control'
                            component={renderInputField}
                            validate={[validation.required]}
                            label='address_line_1'
                            placeholder='Enter your address1'
                            autoComplete='off'
                          />
                        </div>
                      </div>
                      <div className='row fieldflex form-group'>
                        <div className='col-md-3'>
                          <label className='control-required'>Address 2</label>
                        </div>
                        <div className='col-md-9'>
                          <Field
                            name='address_line_2'
                            type='text'
                            className='form-control'
                            component={renderInputField}
                            validate={[validation.required]}
                            label='address_line_2'
                            placeholder='Enter your address2'
                            autoComplete='off'
                          />
                        </div>
                      </div>
                    </fieldset>

                    <div className='add_bottom'>
                      <div className='radio1'>
                        <label className='radioblk'>
                          <p> Confirm that you are the Authorized Representative of the business</p>
                          <input type='radio' name='radio' />
                          <span className='checkmark'></span>
                        </label>
                      </div>
                      <div>
                        {console.log(
                          this.props,
                          this.props.submitFailed,
                          this.state.is_username_exists,
                          this.props.invalid,
                        )}
                        {/*210824-1*/}
                        {validation.isFormValid(this.props.submitFailed, '0', this.props.invalid)}
                        <input
                          type='submit'
                          value='Claim Business'
                          className={classNames(
                            'btn',
                            'btn-lg',
                            'btn-success',
                            'btn-block',
                            'loginbtn',
                            'continuebtn',
                          )}
                          disabled={this.state.is_username_exists === '1' || invalid}
                        />
                      </div>

                      <div className='after_add'>
                        By registering you confirm that you accept the
                        <a target='_blank' href={null}>
                          Terms and Conditions
                        </a>{' '}
                        and
                        <a target='_blank' href={null}>
                          Privacy Policy
                        </a>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>

          {/* -------------------------------------------- step 1 ---------------------------------------------------- */}
          <div className='claim_steps'>
            <div className='success_blk'>
              <h3 className='add_title'>
                <span className='other_txt'>Success!</span>
              </h3>
              <p className='text-center mt-3 mb-0'>You’re almost done!</p>
              <p className='text-center mt-1 mb-5'>
                We just sent an email with a link to activate your account to:
              </p>
              <div className='success_text'>
                <p>
                  You’re almost done!
                  <br />
                  We just sent an email with a link to activate your account to: <br />
                  {this.props.form1Data.email ? this.props.form1Data.email : ''}
                </p>
              </div>
              <div className='strong mt-2 mb-3'>
                <p>1. Check your email now and follow the easy instructions.</p>
                <p>
                  2. Click on the activation link in the email to verify your email address and
                  activate your account!
                </p>
              </div>
              <div className='success_text note'>
                <p>NOTE:</p>
                <p className='mb-4'>
                  Be sure to check your “junk” and “spam” folders, since your email provider
                  probably will not recognize email from iTRUSTit yet.
                </p>
                <p className='mb-4'>
                  If you locate the email in your junk or spam folder, please remember to
                  “white-list” us by adding us (the sender) to your “safe senders list”. This will
                  insure that your message and notification emails get delivered to your inbox in
                  the future.
                </p>
                <p className='mb-4'>
                  {' '}
                  Attention Gmail users! if you cannot find the iTRUSTit Activation email click here
                  for a short video to find your GMail Junk-spam.
                </p>
                <p className='mb-4 danger'>
                  {' '}
                  Important: If you do not receive your activation email within 90 seconds, double
                  check that your email is correct above, then
                </p>
              </div>

              <div className='add_bottom'>
                <div className='text-center'>
                  <a href={null} className='mt-3 mb-3 continue_linear'>
                    Click here to resend activation link
                  </a>
                </div>
              </div>

              <div className='success_text note'>
                <p>NOTE:</p>
                <p>
                  After clicking the above button a few times, if you still have not received the
                  activation email, your mail system or server is probably blocking us so you will
                  need to WHITELIST iTRUSTit. To do this you need to contact your email provider or
                  host and get them to add the following information to their whitelist for your
                  account:
                </p>
                <p>mg.itrustit.com</p>
                <p>209.61.151.226</p>
                <p>209.61.151.250</p>
                <p className='mt-4 mb-4 danger'>
                  IMPORTANT: DO NOT close this page until after you receive your activation email.
                </p>
              </div>

              <div className='strong'>
                <p>3. After you complete steps 1 and 2 then...Welcome to the iTRUSTit community!</p>
              </div>
              <div className='add_bottom'>
                <div className='text-center'>
                  <a href={null} className='continue'>
                    Finish
                  </a>
                </div>
              </div>
            </div>
          </div>
          {/* -------------------------------------------- step 1 over ---------------------------------------------------- */}
        </div>
      </AppLayout>
    )
  }
}

Claimbusiness = reduxForm({
  form: 'signupOrganizationForm1',
})(Claimbusiness)

export default Claimbusiness
