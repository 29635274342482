import React, { Component } from 'react'
import AppLayout from '../AppLayout'
import { Field, reduxForm } from 'redux-form'
import classNames from 'classnames'
import '../AddOrganization/addorgannization.scss'
import renderInputField from '../Form/Input'
import * as validation from '../../services/formValidation'
import * as api from '../../services/businessService'
import ReactSelect from '../../services/ReactSelect'

class AddOrganizationAfterLogin extends Component {
  constructor(props) {
    super(props)
    this.state = {
      countryList: [],
      stateList: [],
      cityList: [],
      stateValue: null,
      cityValue: null,
      isDisplayForm2: false,
      formData: [],
    }
  }
  componentDidMount() {
    window.scrollTo(0, 0)
    this.country()
  }

  singupHandler = (event) => {
    let inputName = event.target.name
    let inputValue = event.target.value
    if (inputName === 'country') this.stateHandler(inputValue)
    if (inputName === 'state') this.city(inputValue)
  }

  countrySelectHandler = (event) => {
    this.setState({ stateValue: null, cityValue: null })
    this.stateHandler(event.value)
    this.setState({ countryObject: event })
    /*210824-1*/
    this.updateCountryDepencency()
  }
  /**
   * Function that return nothing and when country is selected reset all: state,zip, * city (area) and mark them as tocuhed for error. address may be included
   * @author   Amalia
   * @task     210824-1
   */
  updateCountryDepencency = () => {
    this.props.change('state_id', null)
    this.props.touch('state_id', null)
    this.props.change('zipcode', null)
    this.props.touch('zipcode', null)
    this.props.change('city_id', null)
    this.props.touch('city_id', null)
    this.props.change('area', null)
    this.props.touch('area', null)
  }
  stateSelectHandler = (event) => {
    this.setState({ stateValue: event.value, cityValue: null })
    this.city(event.value)
    /*210824-1 when state is selected reset city and mark isas touched for error*/
    this.props.change('city_id', null)
    this.props.touch('city_id')
  }

  citySelectHandler = (event) => {
    this.setState({ cityValue: event.value })
  }

  country = () => {
    let self = this
    let stateCopy = { ...this.state }
    api.countryList().then(function (response) {
      if (response.status === true) {
        stateCopy['countryList'] = response.countries.map((country) => {
          return { value: country.id, label: country.name }
        })
        self.setState(stateCopy)
      }
    })
  }

  stateHandler(id) {
    var self = this
    this.setState({
      stateList: [],
      cityList: [],
      stateValue: null,
      cityValue: null,
    })

    api.stateList(id).then(function (response) {
      if (response.status === true) {
        let stateCopy = { ...self.state }
        response.states.map((state, index) => {
          if (index === 0) stateCopy['stateList'].push({ value: 0, label: 'Select...' })

          let objState = { value: state.id, label: state.name }
          stateCopy['stateList'].push(objState)
        })
        self.setState(stateCopy, () => {
          if (self.state.stateList.length > 0)
            self.setState({ stateValue: self.state.stateList[0].value })
        })
      }
    })
  }

  city(id) {
    let self = this
    this.setState({ cityList: [] })

    api.cityList(id).then(function (response) {
      if (response.status === true) {
        let stateCopy = { ...self.state }
        response.cities.map((city, index) => {
          if (index === 0) stateCopy['cityList'].push({ value: 0, label: 'Select...' })
          let objState = { value: city.id, label: city.name }
          stateCopy['cityList'].push(objState)
        })
        self.setState(stateCopy, () => {
          if (self.state.cityList.length > 0)
            self.setState({ cityValue: self.state.cityList[0].value })
        })
      }
    })
  }
  signupOrganizationSubmitHandler = (data) => {
    data.country_id = data.country_id.value
    data.state_id = data.state_id.value
    data.city_id = data.city_id.value
    this.props.isDisplayForm2(true)
    this.props.formData(data)
  }
  render() {
    const { invalid } = this.props
    const { countryList, stateList, cityList, countryObject } = this.state
    return (
      <AppLayout>
        <div className='container'>
          <h3 className='add_title'>
            <span className='orange'>Add an Organization</span>
            <span className='other_txt'> - Name & Address</span>
          </h3>
          <div className='row'>
            <div className='loginblk signupblk'>
              <div className='panel panel-default'>
                <div className='panel-body'>
                  <form method='post' id='signupOrganizationForm1' name='signupOrganizationForm1'>
                    <fieldset>
                      <div className='row fieldflex form-group'>
                        <div className='col-md-3'>
                          <label className='control-required'>Organization Name</label>
                        </div>
                        <div className='col-md-9'>
                          <Field
                            name='sponsors_iteam_id'
                            type='text'
                            className='form-control'
                            component={renderInputField}
                            validate={[validation.required]}
                            placeholder='Enter Organization Name'
                            autoComplete='off'
                          />
                        </div>
                      </div>
                      <div className='row fieldflex form-group'>
                        <div className='col-md-3'>
                          <label className='control-required'>Country:</label>
                        </div>
                        <div className='col-md-9'>
                          <div className='for-select'>
                            <Field
                              type='text'
                              component={ReactSelect}
                              name='country_id'
                              options={countryList}
                              onChange={this.countrySelectHandler}
                              validate={[validation.required]}
                              isSelect='false'
                            />
                          </div>
                        </div>
                      </div>
                      {countryObject ? (
                        <div className='row fieldflex form-group'>
                          <div className='col-md-3'>
                            <label className='control-required'>Zip / Postal Code:</label>
                          </div>
                          <div className='col-md-9'>
                            <Field
                              name='zipcode'
                              type='text'
                              className='form-control'
                              component={renderInputField}
                              validate={[validation.required]}
                              label='zipcode'
                              placeholder='Enter your zipcode'
                              autoComplete='off'
                            />
                          </div>
                        </div>
                      ) : (
                        ''
                      )}
                      {stateList.length > 0 ? (
                        <div className='row fieldflex form-group'>
                          <div className='col-md-3'>
                            <label className='control-required'>State/Province:</label>
                          </div>
                          <div className='col-md-9'>
                            <div className='for-select'>
                              <Field
                                type='text'
                                className='form-control'
                                component={ReactSelect}
                                name='state_id'
                                options={stateList}
                                value={this.state.stateValue}
                                valueKey={this.state.stateValue}
                                onChange={this.stateSelectHandler}
                                validate={[validation.required]}
                                isSelect='true'
                              />
                            </div>
                          </div>
                        </div>
                      ) : (
                        ''
                      )}
                      {cityList.length > 0 ? (
                        <div className='row fieldflex form-group'>
                          <div className='col-md-3'>
                            <label className='control-required'>City:</label>
                          </div>
                          <div className='col-md-9'>
                            <div className='for-select'>
                              <Field
                                type='text'
                                className='form-control'
                                component={ReactSelect}
                                name='city_id'
                                options={cityList}
                                onChange={this.citySelectHandler}
                                value={this.state.cityValue}
                                valueKey={this.state.cityValue}
                                validate={[validation.required]}
                                isSelect='true'
                              />
                            </div>
                          </div>
                        </div>
                      ) : this.state.cityFlag ? (
                        <div className='row fieldflex form-group'>
                          <div className='col-md-3'>
                            <label className='control-required'>City/Area:</label>
                          </div>
                          <div className='col-md-9'>
                            <Field
                              name='area'
                              type='text'
                              className='form-control'
                              component={renderInputField}
                              label='City/Area'
                              placeholder='Enter city/area'
                              autoComplete='off'
                              validate={[validation.required]}
                              onChange={this.areaChangedHandler}
                            />
                          </div>
                        </div>
                      ) : (
                        ''
                      )}
                      <div className='row fieldflex form-group'>
                        <div className='col-md-3'>
                          <label className='control-required'>Address 1</label>
                        </div>
                        <div className='col-md-9'>
                          <Field
                            name='address_line_1'
                            type='text'
                            className='form-control'
                            component={renderInputField}
                            validate={[validation.required]}
                            label='address_line_1'
                            placeholder='Enter your address1'
                            autoComplete='off'
                          />
                        </div>
                      </div>
                      <div className='row fieldflex form-group'>
                        <div className='col-md-3'>
                          <label className='control-required'>Address 2</label>
                        </div>
                        <div className='col-md-9'>
                          <Field
                            name='address_line_2'
                            type='text'
                            className='form-control'
                            component={renderInputField}
                            validate={[validation.required]}
                            label='address_line_2'
                            placeholder='Enter your address2'
                            autoComplete='off'
                          />
                        </div>
                      </div>
                    </fieldset>
                    {console.log(
                      this.props,
                      this.props.submitFailed,
                      this.state.is_username_exists,
                      this.props.invalid,
                    )}
                    {/*210824-1*/}
                    {validation.isFormValid(
                      this.props.submitFailed,
                      this.state.is_username_exists,
                      this.props.invalid,
                    )}

                    <input
                      type='submit'
                      value='Continue'
                      className={classNames(
                        'btn',
                        'btn-lg',
                        'btn-success',
                        'btn-block',
                        'loginbtn',
                        'continuebtn',
                      )}
                      disabled={this.state.is_username_exists === '1' || invalid}
                    />
                  </form>
                </div>
              </div>
            </div>
          </div>
          {/*   -------------------------------------------- step 2 ----------------------------------------------------  */}
          <h3 className='add_title'>
            <span className='orange'>Add an Organization</span>
            <span className='other_txt'> - Search</span>
          </h3>
          <div className='norecords'>
            <p className='norecords_txt'> No recored found</p>
            <a href={null} className='continue'>
              Can’t find it? Click here to continue
            </a>
          </div>
          {/*   -------------------------------------------- step 3 ----------------------------------------------------  */}
          <h3 className='add_title'>
            <span className='orange'>Add an Organization</span>
            <span className='other_txt'> - Category - Owner / Manager info</span>
          </h3>
          <div className='row'>
            <div className='loginblk signupblk'>
              <div className='panel panel-default'>
                <div className='panel-body'>
                  <form method='post' id='signupOrganizationForm1' name='signupOrganizationForm1'>
                    <fieldset>
                      <div className='row fieldflex form-group'>
                        <div className='col-md-3'>
                          <label className='control-required'>Category:</label>
                        </div>
                        <div className='col-md-9'>
                          <div className='for-select'>
                            <Field
                              type='text'
                              component={ReactSelect}
                              name='country_id'
                              options={countryList}
                              onChange={this.countrySelectHandler}
                              validate={[validation.required]}
                              isSelect='false'
                            />
                          </div>
                        </div>
                      </div>
                      <div className='row fieldflex form-group'>
                        <div className='col-md-3'>
                          <label>Sub Category:</label>
                        </div>
                        <div className='col-md-9'>
                          <div className='for-select'>
                            <Field
                              type='text'
                              component={ReactSelect}
                              name='country_id'
                              options={countryList}
                              onChange={this.countrySelectHandler}
                              //210824-1 -not required
                              //validate={[validation.required]}
                              isSelect='false'
                            />
                          </div>
                        </div>
                      </div>
                      <div className='row fieldflex form-group'>
                        <div className='col-md-3'>
                          <label>Sub sub Category:</label>
                        </div>
                        <div className='col-md-9'>
                          <div className='for-select'>
                            <Field
                              type='text'
                              component={ReactSelect}
                              name='country_id'
                              options={countryList}
                              onChange={this.countrySelectHandler}
                              //210824-1 -not required
                              //validate={[validation.required]}
                              isSelect='false'
                            />
                          </div>
                        </div>
                      </div>
                      <div className='row fieldflex form-group'>
                        <div className='col-md-3'>
                          <label>Owner/Manager First Name</label>
                        </div>
                        <div className='col-md-9'>
                          <Field
                            name='sponsors_iteam_id'
                            type='text'
                            className='form-control'
                            component={renderInputField}
                            validate={[validation.required]}
                            placeholder='Enter Organization Name'
                            autoComplete='off'
                          />
                        </div>
                      </div>
                      <div className='row fieldflex form-group'>
                        <div className='col-md-3'>
                          <label>Owner/Manager Last Name</label>
                        </div>
                        <div className='col-md-9'>
                          <Field
                            name='sponsors_iteam_id'
                            type='text'
                            className='form-control'
                            component={renderInputField}
                            validate={[validation.required]}
                            placeholder='Enter Organization Name'
                            autoComplete='off'
                          />
                        </div>
                      </div>
                      <div className='row fieldflex form-group'>
                        <div className='col-md-3'>
                          <label>Owner/Manager Email Address</label>
                        </div>
                        <div className='col-md-9'>
                          <Field
                            name='address_line_1'
                            type='text'
                            className='form-control'
                            component={renderInputField}
                            validate={[validation.required]}
                            label='address_line_1'
                            placeholder='Enter your address1'
                            autoComplete='off'
                          />
                        </div>
                      </div>
                    </fieldset>
                    <div className='add_bottom'>
                      <div className='radio1'>
                        <label className='radioblk'>
                          <p>
                            {' '}
                            Check this to send this Organization an invitation from you to join
                            iTRUSTit
                          </p>
                          <input type='radio' name='radio' />
                          <span className='checkmark' />
                        </label>
                      </div>
                      <div>
                        <a href={null} className='continue'>
                          Click here to complete and add organization"
                        </a>
                      </div>

                      <div className='after_add'>
                        By registering you confirm that you accept the
                        <a target='_blank' href={null}>
                          Terms and Conditions
                        </a>{' '}
                        and{' '}
                        <a target='_blank' href={null}>
                          Privacy Policy
                        </a>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AppLayout>
    )
  }
}

AddOrganizationAfterLogin = reduxForm({
  form: 'signupOrganizationForm1',
})(AddOrganizationAfterLogin)

export default AddOrganizationAfterLogin
