import React from 'react'

const MenuTypeTabs = ({
  label,
  menuType,
  handleChange,
  menus,
  wrapperClassName = '',
  viewsClass = '',
  buttonClass = '',
}) => {
  return (
    <div className={`menu-type-tabs ${wrapperClassName}`}>
      {label ? <div>{label}</div> : null}
      <div className={`views ${viewsClass}`}>
        {menus?.map?.((menu) => (
          <div onClick={() => handleChange(menu.type)}>
            <button
              className={`btn menu-type-tab ${
                menuType === menu.type ? 'active' : ''
              } ${buttonClass}`}
            >
              {menu?.icon ? <i className={`fa ${menu.icon} ${menu.iconClass}`} /> : null}
              {menu.label}
            </button>
          </div>
        ))}
      </div>
    </div>
  )
}

export default MenuTypeTabs
