import React from 'react'
import { Link } from 'react-router-dom'
import { DEFAULT_PROFILE_PICTURE } from '../../services/const'

const GroupChatHeader = ({ group_chat_selected }) => {
  return (
    <>
      <div className='user_image'>
        <div>
          <img src={DEFAULT_PROFILE_PICTURE} alt='' />
        </div>
      </div>
      <div className='user_content'>
        <div className='d-flex'>
          <div className='username'>
            {group_chat_selected?.users?.map((user, idx) => (
              <React.Fragment key={user?.id}>
                <Link to={'/' + user.slug}>{user.fullname}</Link>
                {idx < group_chat_selected?.users?.length - 1 ? ', ' : ''}
              </React.Fragment>
            ))}
          </div>
        </div>
      </div>
    </>
  )
}

export default GroupChatHeader
