import React, { Component } from 'react'
import AppLayout from '../../AppLayout'
import { Link } from 'react-router-dom'
import { LOGIN_ROUTE, PROFILE_ROUTE } from '../../../services/routeConst'
import * as customFunction from '../../../services/customFunction'
import LoginModal from '../../LoginModal/LoginModal'
import LoginActivationModal from '../../LoginActivationModal/LoginActivationModal'
import $J from 'jquery'
import ResendVerificationEmailModal, {
  showResendVerificationEmailModal,
} from '../../CommonComponents/ResendVerificationEmailModal/ResendVerificationEmailModal'
class ClaimSuccess extends Component {
  constructor(props) {
    super(props)
    // console.log("props", props);
    this.state = {
      isClaimloginText: false,
      emailConfirmError: props?.location?.state?.emailConfirmError,
    }
  }

  loginHandler = (data) => {
    //this.props.isLogin(data);
  }

  openLoginPopup = (event) => {
    //this.setState({ isClaimloginText: true });
    $J('#login_modal').modal('show')
  }
  showResendVerificationModal = (event) => {
    //this.setState({ isClaimloginText: true });
    showResendVerificationEmailModal()
  }

  render() {
    let self = this
    $J('#login_modal').on('hidden.bs.modal', function (e) {
      self.setState({ isClaimloginText: false })
    })

    return (
      <div>
        <LoginActivationModal
          isLogin={this.loginHandler}
          {...this.props}
          isClaimloginText={this.state.isClaimloginText}
        />
        <ResendVerificationEmailModal />
        <header className='it-header userheader'>
          <nav className='navbar navbar-expand-lg'>
            <a className='navbar-brand' href='/'>
              <img src='img/logo.png' alt='' />
            </a>
          </nav>
        </header>
        <section
          className={`bg-grey it-welcome ${this.state.emailConfirmError ? 'invalid-auth' : ''}`}
        >
          {this.state.emailConfirmError ? (
            <div className='container col-md-10'>
              <h3 className='varela mb-3'>INVALID VERIFICATION</h3>
              <div className='white-pane orange_line'>
                <div className='white_pane_content about'>
                  <div className='row'>
                    <div className='col-md-6'>
                      <p style={{ lineHeight: 'initial', fontSize: 16 }}>
                        The link you clicked in the verification email is no longer valid.
                      </p>
                      <p style={{ lineHeight: 'initial', fontSize: 16 }}>
                        Please check your inbox (and Spam/Junk Mail) to verify you are using the
                        most recent Verification Email Link
                      </p>
                      <p style={{ lineHeight: 'initial', fontSize: 16 }}>
                        Click ACTIVATE in the email to complete your Sign Up.
                      </p>
                      <p style={{ lineHeight: 'initial', fontSize: 16 }}>
                        If you can not find the verification email we sent to your email
                        {/* <span
                          className='d-block'
                          style={{ color: '#6684f2', fontWeight: 700, fontSize: 16 }}
                        >
                          austinadam42@gmail.com
                        </span> */}
                      </p>

                      <button
                        type='button'
                        className='d-block it-welcome-btn'
                        onClick={this.showResendVerificationModal}
                      >
                        Click to Resend Verification Email
                      </button>
                    </div>
                    <div className='col'>
                      <div className='row'>
                        <div className='col d-flex justify-content-center'>
                          <img
                            style={{ width: 220 }}
                            src={process.env.PUBLIC_URL + '/img/warning-icon.png'}
                            alt='warning'
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className='container col-md-10'>
              <h3 className='varela mb-3'>WELCOME</h3>
              <div className='white-pane green_line'>
                <div className='white_pane_content about'>
                  <div className='row'>
                    <div className='col-md-5'>
                      <p>Congratulations and welcome to the iTRUSTit invite only Beta Launch! </p>
                      <p>
                        We are trusting YOU to help us perfect the user experience before it’s
                        offered to the public. During this phase we will address any issues and add
                        functionality.{' '}
                      </p>
                      <p>
                        Click CONTACT in the footer to send us a message about anything and
                        everything you find, want or would like to see.
                      </p>
                    </div>
                    <div className='col-md-7'>
                      <div className='it-welcome-img'>
                        <img src={process.env.PUBLIC_URL + '/img/welcome-img.png'} alt='welcome' />
                      </div>
                    </div>
                    <div className='col-md-12'>
                      <p>
                        For taking part in the beta launch you will be a Charter Member of iTRUSTit.
                        This gives you special status that the general public will not receive,
                        including lifetime iPartner Pro, the highest revenue share payout for
                        helping us grow.
                      </p>
                      <p>
                        After you Log In, click BETA README and COMMUNITY in the footer to learn
                        more.
                      </p>
                      <p>Let’s get started! The iTRUSTit Team.</p>
                    </div>
                    <div className='col-md-12 text-center'>
                      {customFunction.isUserLogin() && customFunction.userToken() ? (
                        <Link to={PROFILE_ROUTE} className='it-welcome-btn'>
                          Home
                        </Link>
                      ) : (
                        <button
                          type='button'
                          className='it-welcome-btn'
                          onClick={this.openLoginPopup}
                        >
                          Log In
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </section>
      </div>
    )
  }
}

export default ClaimSuccess
