import React, { Component } from 'react'
import AppLayout from '../AppLayout'
import '../Contact/contact.scss'
// import renderInputField from "../Form/Input";
// import { Field, reduxForm } from "redux-form";
// import renderTextAreaField from "../Form/Textarea";
import { CONTACT_TITLE } from '../../services/titleConst'
import LoginModal from '../LoginModal/LoginModal'
import { toaster } from '../../services/customFunction'
import { sendContactEmail } from '../../services/userApiService'
import { Helmet } from 'react-helmet'
var $ = window.$
class Contact extends Component {
  constructor(props) {
    super(props)
    this.state = {
      sharedFields: {},
      sharedErrors: {},
    }
  }

  // componentDidMount() {
  //   window.scrollTo(0, 0)
  //   const script = document.createElement('script')
  //   script.src = '//server10.clickandchat.com/include.js?domain=https://itrustit.com/'
  //   script.async = true
  //   script.onload = this.scriptOnLoad
  //   document.getElementById('click_and_chat_it').append(script)
  // }
  // scriptOnLoad = () => {
  //   if (window.sWOTrackPage) {
  //     window.sWOTrackPage()
  //   }
  // }
  contactSubmitHandler = (e) => {
    e.preventDefault()

    let params = this.state.sharedFields

    if (this.validateForm()) {
      sendContactEmail(params).then(function (response) {
        if (response.status === 200) {
          toaster('success', response.data.message)
          $('#contactForm').get(0).reset()
        } else {
          toaster('error', 'Something wrong! please try again later.')
        }
      })
    } else {
      if (this.state.sharedErrors.useremailvalid) {
        toaster('error', 'Please enter valid email-ID')
      } else {
        toaster('error', 'Please fill all fields')
      }
    }
  }

  validateForm = () => {
    let fields = this.state.sharedFields
    let errors = {}
    let formIsValid = true

    if (!fields['name']) {
      formIsValid = false
      errors['name'] = 'Please enter your name.'
    }
    if (!fields['user_email']) {
      formIsValid = false
      errors['useremail'] = 'Please enter your email.'
    }

    if (!fields['subject']) {
      formIsValid = false
      errors['subject'] = 'Please enter your subject.'
    }

    if (typeof fields['user_email'] !== 'undefined') {
      //regular expression for email validation
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i,
      )
      if (!pattern.test(fields['user_email'])) {
        formIsValid = false
        errors['useremail'] = 'Please enter valid email-ID.'
        errors['useremailvalid'] = 'Please enter valid email-ID.'
      }
    }

    if (!fields['message']) {
      formIsValid = false
      errors['tomessage'] = 'Please enter your message.'
    }

    this.setState({
      sharedErrors: errors,
    })
    return formIsValid
  }

  shareHandleChange = (e) => {
    let sharedFields = this.state.sharedFields
    sharedFields[e.target.name] = e.target.value
    this.setState({
      sharedFields,
    })
  }

  render() {
    return (
      <AppLayout routeActive={this.props.location.pathname}>
        <Helmet>
          <title>{CONTACT_TITLE}</title>
        </Helmet>
        <section className='bg-grey'>
          <div className='container'>
            <h3 className='varela mb-3'>Contact</h3>
            {/* <div className="white-pane green_line contact"> */}
            <div className='white-pane contact'>
              <div className='contact_blk'>
                {/* <div id="click_and_chat_it" className="live_chat"> */}
                {/* <img src={process.env.PUBLIC_URL + "/img/live_chat_photo.png"} alt="" /> */}
                {/* </div> */}
                {/* <div className="sub_title">
                                    Contact us
                                </div>
                                <h2>
                                    We always here to help you
                                </h2> */}
                <ul className='contact_list'>
                  <li className='col-md-4 phone_blk'>
                    <div className='blocks_title'>Phone</div>
                    <div className='content_inner'>
                      <p>888-535-7847</p>
                    </div>
                  </li>
                  <li className='col-md-4 address_blk'>
                    <div className='blocks_title'>Address</div>
                    <div className='content_inner'>
                      <p>
                        703 Pier Ave Ste B691
                        <br /> Hermosa Beach, CA 90254
                      </p>
                    </div>
                  </li>
                  <li className='col-md-4'>
                    {/* <div id='click_and_chat_it' className='live_chat'> */}
                    {/* <img src={process.env.PUBLIC_URL + "/img/live_chat_photo.png"} alt="" /> */}
                    {/* </div> */}
                  </li>
                  {/* <li className="col-md-4 email_blk">
                                        <a href='mailto:info@itrustit.com'>
                                            <div className="blocks_title">Email</div>
                                        </a>
                                    </li> */}
                </ul>
              </div>

              <div className='contact_blk'>
                <div className='sub_title'>SEND US A MESSAGE</div>
                {/* <h2>
                                    Get in touch with us
                                </h2> */}
                <form
                  className='contact_form'
                  onSubmit={this.contactSubmitHandler}
                  id='contactForm'
                >
                  <div className='row'>
                    <div className='col-md-4'>
                      <div className='from-group'>
                        <input
                          name='name'
                          type='text'
                          className='form-control'
                          placeholder='Name'
                          onChange={this.shareHandleChange}
                        />
                        {/* <span style={{ color: "red" }}>
                                                    {this.state.sharedErrors && this.state.sharedErrors.name}
                                                </span> */}
                      </div>
                    </div>
                    <div className='col-md-4'>
                      <div className='from-group'>
                        <input
                          name='user_email'
                          type='text'
                          className='form-control'
                          placeholder='Email'
                          onChange={this.shareHandleChange}
                        />
                        {/* <span style={{ color: "red" }}>
                                                    {this.state.sharedErrors && this.state.sharedErrors.useremail}
                                                </span> */}
                      </div>
                    </div>
                    <div className='col-md-4'>
                      <div className='from-group'>
                        <input
                          name='subject'
                          type='text'
                          className='form-control'
                          placeholder='Subject'
                          onChange={this.shareHandleChange}
                        />
                        {/* <span style={{ color: "red" }}>
                                                    {this.state.sharedErrors && this.state.sharedErrors.subject}
                                                </span> */}
                      </div>
                    </div>
                    <div className='col-md-12'>
                      <div className='from-group'>
                        <textarea
                          name='message'
                          className='form-control'
                          placeholder='Message'
                          onChange={this.shareHandleChange}
                        ></textarea>
                        {/* <span style={{ color: "red" }}>
                                                    {this.state.sharedErrors && this.state.sharedErrors.tomessage}
                                                </span> */}
                      </div>
                    </div>
                    <div className='col-md-12'>
                      <div className='from-group'>
                        <input type='submit' value='Send' />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
        <LoginModal
          isLogin={(e) =>
            function (data) {
              this.props.isLogin(data)
            }
          }
          {...this.props}
        />
      </AppLayout>
    )
  }
}
export default Contact
