import axios from 'axios'
import { customLogger } from '../utils/CustomLogger'
import * as environemnt from './environment'
import { toaster } from './customFunction'

export const elasticSearch = async ({ ids, searchStr }) => {
  searchStr = searchStr.replace(/([\!\*\+\&\|\(\)\[\]\{\}\^\~\?\-\_\:\"])/g, '')
  searchStr = searchStr.replace(/  +/g, ' ')
  searchStr = searchStr.replace(/[\u2018\u2019]/g, "'").replace(/[\u201C\u201D]/g, '"')
  searchStr = searchStr.replace(/(')\1{1,}/, "'")
  searchStr = searchStr.replace(/(")\1{1,}/, `"`)
  searchStr = searchStr.trim()

  var queryString = ''
  var searchStringArray = searchStr.split(' ')
  var searchStringArrayLength = searchStringArray.length

  for (var i = 0; i < searchStringArrayLength; i++) {
    queryString += '(*' + searchStringArray[i] + '*)'
    if (i < searchStringArrayLength - 1) {
      queryString += ' AND ' // AND OR
    }
  }
  let postData = {
    query: {
      bool: {
        must: [
          {
            query_string: {
              fields: [
                'fullname',
                'name',
                'slug',
                'business_organization_name',
                'categories.category_name',
              ],
              query: queryString,
            },
          },
        ],
        filter: [
          {
            ids: {
              values: ids,
            },
          },
        ],
      },
    },
    size: 10000,
    highlight: {
      pre_tags: ['<strong>'],
      post_tags: ['</strong>'],
      fields: {
        name: {},
      },
    },
  }
  // console.log(postData);
  var elastic_search_api =
    environemnt.ELASTIC_SEARCH_BASE_URL +
    environemnt.ELASTIC_SEARCH_USERS +
    '/' +
    environemnt.ELASTIC_SEARCH_TYPE +
    '/_search?pretty=true'
  try {
    const response = await axios.post(elastic_search_api, postData, {
      headers: { 'Content-Type': 'application/json' },
    })
    if (response?.status === 200) {
      return response.data.hits.hits
    } else console.log(response)
  } catch (error) {
    if (error?.response?.data?.message) {
      toaster('error', error.response.data.message)
    }
    customLogger(error)
    return error.response
  }
}
