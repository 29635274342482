import React, { useState } from 'react'
import $ from 'jquery'
import { toaster } from '../../../services/customFunction'
import { Field, reduxForm } from 'redux-form'
import * as validation from '../../../services/formValidation'
import renderInputField from '../../Form/Input'
import { checkEmail } from '../../../services/userApiService'
import { useHistory } from 'react-router'
import { sendEmailConfirmationCall } from '../../../services/authService'
import { customLogger } from '../../../utils/CustomLogger'
export const showResendVerificationEmailModal = (type) => {
  $('#resend_verification_email_modal').modal('show').attr('type', type)
}

const ResendVerificationEmailModal = (props) => {
  const [status, setStatus] = useState('idle')
  const [apiError, setApiError] = useState('')
  const [email, setEmail] = useState('')
  const history = useHistory()

  const handleChange = (e) => {
    !!apiError && setApiError('')
    setEmail(e?.target?.value ?? '')
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    setStatus('submitting')
    const postData = {
      email,
    }
    try {
      const { data } = await checkEmail(postData)
      const { is_email_exists, user } = data
      if (
        is_email_exists &&
        is_email_exists !== '0' &&
        (!user?.is_email_confirmed || user?.is_email_confirmed === '0') &&
        user?.slug
      ) {
        resendActivationLink(user)
        $('#resend_verification_email_modal').modal('hide')
        $('body').removeClass('modal-open')
        $('.modal-backdrop').remove()
        $('body').css({ 'padding-right': '' })
        setEmail('')
      } else if (data?.data?.error || data?.error) {
        setApiError(data.message)
        setStatus('idle')
      } else if (data?.user?.is_email_confirmed === '1') {
        setApiError('Your email address has already been confirmed. Please login.')
        setStatus('idle')
      } else {
        setApiError('Email does not match our records.')
        setStatus('idle')
      }
    } catch (error) {
      customLogger(error)
      console.log(error)
      setStatus('idle')
      toaster(
        'error',
        'Something went wrong. Please try again or contact an Admin if the issue persists.',
      )
    }
  }

  const resendActivationLink = async (user) => {
    if (!user.id) return
    const { data, status } = await sendEmailConfirmationCall({ id: user?.id })
    setStatus('idle')
    if (status === 200) {
      toaster('success', data?.message ?? 'Activation link resent successfully')
      history.push({
        pathname: process.env.PUBLIC_URL + '/register-success/' + user?.slug,
      })
    } else {
      toaster(
        'error',
        'Something went wrong. Please try again or contact an Admin if the issue persists.',
      )
    }
  }

  const isLoading = status === 'submitting'
  const isDisabled = status === 'submitting' || !!apiError || !email || props?.invalid
  return (
    <div
      className='modal fade resend_verification_email_modal'
      id='resend_verification_email_modal'
      role='dialog'
    >
      <div className='modal-dialog'>
        <div className='modal-content'>
          <div className='modal-header align-items-start pb-0'>
            <h5>Resend Activation Email To:</h5>
            <button type='button' className='btn-close' data-bs-dismiss='modal'></button>
          </div>
          <div className='modal-body' style={{ textAlign: 'center' }}>
            <h6 className='text-start'>
              You've asked us to resend an activation email to you to complete your signup. Please
              provide the email address that you used to create your iTRUSTit account so we can send
              it there.
            </h6>
            <div
              className='que4 mt-3'
              style={{
                cursor: isDisabled ? 'not-allowed' : 'pointer',
              }}
            >
              <div>
                <Field
                  name='otp'
                  type='text'
                  className='form-control password1'
                  component={renderInputField}
                  customError={apiError}
                  onChange={handleChange}
                  value={email}
                  valueKey={email}
                  validate={[validation.required, validation.email]}
                  label='Email Address'
                  placeholder='Enter your email address'
                  autoComplete='off'
                />
                <button
                  onClick={isDisabled ? () => {} : handleSubmit}
                  className='btn btn-lg btn-success submit_btn mt-2'
                  disabled={isDisabled}
                  style={{
                    background: '#544cf9',
                    color: '#fff',
                    borderRadius: 30,
                    opacity: isDisabled ? 0.5 : 1,
                    cursor: isDisabled ? 'not-allowed' : 'pointer',
                    fontWeight: 600,
                    lineHeight: 0,
                    maxWidth: '100%',
                    fontSize: 14,
                    padding: '15px 20px',
                    height: 48,
                  }}
                >
                  {isLoading ? 'Loading...' : 'Submit'}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default reduxForm({
  form: 'resendVerificationEmailForm',
  enableReinitialize: true,
})(ResendVerificationEmailModal)
