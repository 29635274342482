import React from 'react'
import { makeTrustCall, removeTrustCall } from '../../../services/trustApiService'

const TrustButton = ({
  itrustitTrustHandle,
  confirmitrustitAddRemove,
  user,
  isLoggedIn = true,
}) => {
  const confirmItrustitAddRemove = async (needsConfirmation = false) => {
    const data = {
      is_trusted_user: user.is_trusted_user,
      id: user.id,
    }
    needsConfirmation
      ? confirmitrustitAddRemove(needsConfirmation, data, 'header')
      : await itrustitAddRemove(data)
  }

  const itrustitAddRemove = async (data) => {
    let request = { id: data.id }
    if (data.is_trusted_user === 0) {
      await makeTrustCall(request)
      itrustitTrustHandle(data)
    } else if (data.is_trusted_user === 1) {
      await removeTrustCall(request)
      itrustitTrustHandle(data)
    }
  }

  const buttonProps = isLoggedIn
    ? {
        onClick: () => confirmItrustitAddRemove(user?.is_trusted_user === 1),
      }
    : {
        onClick: () => {},
        'data-bs-toggle': 'modal',
        'data-bs-target': '#login_modal',
      }

  return (
    <button
      {...buttonProps}
      key='trust-btn'
      className={'blue-trust ' + (user?.is_trusted_user ? 'trust-fill' : '')}
    >
      <i aria-hidden='true' className={`fas fa-${user?.is_trusted_user ? 'check' : 'plus'} me-1`} />{' '}
      iTRUSTit
    </button>
  )
}

export default TrustButton
