import React, { Component } from 'react'
// import AppLayout from "../AppLayout";
// import { Field, reduxForm } from 'redux-form'

import * as route from '../../services/routeConst'
import * as customFunction from '../../services/customFunction'
import $J from 'jquery'
import { withRouter } from 'react-router'
import LogoutPopup from '../AddBusOrgNew/LogoutPopup'
import AddBusOrgModal from './AddBusOrgModal'

class AddBusinessOrOrganizationFooter extends Component {
  constructor(props) {
    super(props)
    //document.title = SIGNUP_BUSINESS_TITLE;
    // console.log("props", props);
    this.state = {
      business: {
        user_type: 'Customer',
      },
      organization: {
        user_type: 'Customer',
      },
      user_type: 'Business',
      add_business_or_organization: '',
      isRedirect: false,
      isRedirectSignupBusiness: false,
      isRedirectSignupOrganization: false,
      imatrix_id: customFunction.getInviteMatrixId(),
      content: false,
      signUpFlag: false,
    }
  }
  contentShow = (event) => {
    this.setState({ content: true })
  }
  componentDidMount = () => {
    let self = this
    $J('#add_business_or_organization_footer_modal_0').on('hide.bs.modal', function () {
      self.setState({ content: false })
      // self.setState({ user_type: "Business" });
    })
  }

  changeUserTypeHandler = (event) => {
    this.setState({ user_type: event.target.value })
  }

  changeBusinessUserTypeHandler = (event) => {
    this.setState({ business: { user_type: event.target.value } })
  }

  changeOrganizationUserTypeHandler = (event) => {
    this.setState({ organization: { user_type: event.target.value } })
  }

  handleSubmit = (userType) => {
    this.setState({ user_type: userType }, () => {
      if (this.state.user_type?.toLowerCase() === 'business') {
        $J('#add_business_or_organization_footer_modal_0').modal('hide')
        setTimeout(() => {
          $J('#add_business_footer_modal').modal('show')
        }, 100)
      }

      if (this.state.user_type?.toLowerCase() === 'organization') {
        $J('#add_business_or_organization_footer_modal_0').modal('hide')
        setTimeout(() => {
          $J('#add_organization_footer_modal').modal('show')
        }, 100)
      }
    })
  }

  continueOrganizationHandler = () => {
    $J('#add_organization_footer_modal').modal('hide')
    if (this.state.organization.user_type === 'Customer') {
      // this.setState({ isRedirect: true });
      this.setState(
        {
          add_business_or_organization: 'Customer',
        },
        this.redirectToReg,
      )
    }

    if (this.state.organization.user_type === 'Business') {
      // this.setState({ isRedirect: true });

      $J('#add_as_owner_logout_popup_0').modal('show')
      // this.setState(
      //   {
      //     signUpFlag: true,
      //   },
      //   this.redirectToReg,
      // )
    }
  }

  continueBusinessHandler = () => {
    $J('#add_business_footer_modal').modal('hide')
    if (this.state.business.user_type === 'Customer') {
      // this.setState({ isRedirect: true });
      this.setState(
        {
          add_business_or_organization: 'Customer',
        },
        this.redirectToReg,
      )
    }

    if (this.state.business.user_type === 'Business') {
      // this.setState({ isRedirect: true });
      // this.setState({ signUpFlag: true });

      $J('#add_as_owner_logout_popup_0').modal('show')
      // this.setState(
      //   {
      //     signUpFlag: true,
      //   },
      //   this.redirectToReg,
      // )
    }
  }
  redirectToReg = () => {
    this.props.history.push({
      pathname:
        this.state.user_type.toLowerCase() === 'business'
          ? route.ADD_BUSINESS_ROUTE
          : route.ADD_ORGANIZATION_ROUTE,
      state: {
        imatrix_id: this.state.imatrix_id,
        user_type: this.state.user_type.toLowerCase(),
        signupType: this.state.user_type?.toLowerCase(),
        isAdd: true,
        addType: this.state[this.state.user_type.toLowerCase()].user_type?.toLowerCase(), //Always Customer, because Owner takes a different route
        loginUserData: customFunction.getUser(),
      },
    })
  }
  render() {
    return (
      // <AppLayout isDisplayFooter={false}>
      <div>
        <LogoutPopup user_type={this.state.user_type} />
        <AddBusOrgModal handleSubmit={this.handleSubmit} />

        <div
          className='modal fade login_blk reset_password_blk it-add-business'
          id='add_business_footer_modal'
          role='dialog'
        >
          <div className='modal-dialog'>
            <div className='modal-content'>
              <div className='modal-header'>
                <div className='claim_steps'>
                  <h3 className='add_title'>
                    <span className='other_txt'>ADD a Business</span>
                  </h3>
                  <p className=' mt-3 mb-3'>
                    {/* I want to add a: */}
                    Are you a customer or the owner/manager/authorized representative of the
                    business you'd like to add?
                  </p>

                  <div className=' mt-3 mb-3'>
                    <input
                      type='radio'
                      name='business_user_type'
                      id='bus_customer'
                      value='Customer'
                      checked={this.state.business.user_type === 'Customer'}
                      onChange={this.changeBusinessUserTypeHandler}
                    />{' '}
                    <label htmlFor='bus_customer'>I am a customer</label>
                  </div>
                  <div className=' mt-3 mb-3'>
                    <input
                      type='radio'
                      name='business_user_type'
                      id='bus_business'
                      value='Business'
                      checked={this.state.business.user_type === 'Business'}
                      onChange={this.changeBusinessUserTypeHandler}
                    />{' '}
                    <label htmlFor='bus_business'>
                      I am the Owner or Authorized Representative of this business
                    </label>
                  </div>

                  <div className='add_bottom'>
                    <div className='text-center'>
                      <a href={null} onClick={this.continueBusinessHandler} className='continue'>
                        Continue
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className='modal fade login_blk reset_password_blk it-add-business'
          id='add_organization_footer_modal'
          role='dialog'
        >
          <div className='modal-dialog'>
            <div className='modal-content'>
              <div className='modal-header'>
                <div className='claim_steps'>
                  <h3 className='add_title'>
                    <span className='other_txt'>ADD an Organization</span>
                  </h3>
                  <p className=' mt-3 mb-3'>
                    {/* I want to add a: */}
                    Are you Authorized Representative of the Organization you'd like to add?
                  </p>

                  <div className='mb-md-2 mb-2'>
                    <input
                      type='radio'
                      name='organization_user_type'
                      id='org_customer'
                      value='Customer'
                      checked={this.state.organization.user_type === 'Customer'}
                      onChange={this.changeOrganizationUserTypeHandler}
                    />{' '}
                    <label htmlFor='org_customer'>
                      I am not the Authorized Representative of the organization
                    </label>
                  </div>
                  <div className='mb-md-2 mb-2'>
                    <input
                      type='radio'
                      name='organization_user_type'
                      id='org_business'
                      value='Business'
                      checked={this.state.organization.user_type === 'Business'}
                      onChange={this.changeOrganizationUserTypeHandler}
                    />{' '}
                    <label htmlFor='org_business'>This is my Organization</label>
                  </div>

                  <div className='add_bottom'>
                    <div className='text-center'>
                      <a
                        href={null}
                        onClick={this.continueOrganizationHandler}
                        className='continue'
                      >
                        Continue
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      // </AppLayout>
    )
  }
}

export default withRouter(AddBusinessOrOrganizationFooter)
