import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import useChatUserSuggestions from '../../../hooks/useChatUserSuggestions'
import { toaster } from '../../../services/customFunction'
import { updateISupportUser } from '../../../services/userApiService'
import NewMessageUserSearch from '../../Message/NewMessageUserSearch'
import $J from 'jquery'

const ISupport = ({ user, isProfilePage, refreshUser, className = '' }) => {
  const { isSearching, searchData, setSearchData } = useChatUserSuggestions(
    user.id,
    'isupport-suggestions',
    { match: { user_type: 'individual' } },
  )
  const [showISupportSearch, setShowISupportSearch] = useState(false)
  const handleUserSearchChange = (event, newValue) => {
    setSearchData((st) => ({ searchStr: newValue, results: st.results }))
  }

  const handleUserSelect = async (selectedUser) => {
    const selectedUserId = selectedUser?.['_source']?.id
    if (selectedUserId) {
      const response = await updateISupportUser({ isupport_user_id: selectedUserId })
      if (response?.status === true) {
        setShowISupportSearch(false)
        refreshUser?.()
        toaster('success', 'iSupport user successfully updated')
      }
    } else if (selectedUser?.['_id'] === 'add_org') {
      $J('#add_business_or_organization_footer_modal_0').modal('show')
    }
  }

  const handleClick = (e) => {
    e?.target?.id === 'isupport-dropdown-arrow' && setShowISupportSearch((st) => !st)
  }
  return (
    <div
      className={`d-flex ${
        isProfilePage ? 'mt-4 mt-xl-auto' : 'mt-auto'
      } isupport-wrapper ${className}`}
    >
      <img src={process.env.PUBLIC_URL + '/img/isupport.svg'} alt='isupport-logo' width={75} />
      {user?.isupport?.user?.slug ? (
        <Link to={`/${user?.isupport?.user?.slug}`} target='blank' rel='noopener noreferrer'>
          {user?.isupport?.user?.business_organization_name}
        </Link>
      ) : null}
      {isProfilePage ? (
        <i
          id='isupport-dropdown-arrow'
          className={`fa fa-caret-${showISupportSearch ? 'up' : 'down'} position-relative`}
          title='Click to change'
          aria-hidden='true'
          onClick={handleClick}
          style={{
            color: '#4741d4',
            fontSize: 16,
            cursor: 'pointer',
            zIndex: 1000,
          }}
        >
          {showISupportSearch ? (
            <div className='position-absolute fw-normal isupport-search-wrapper'>
              <div
                className='d-flex align-items-center new-message-user-search-wrapper position-relative w-100'
                style={{ gap: 8 }}
              >
                <NewMessageUserSearch
                  options={[
                    {
                      _id: 'add_org',
                      _source: { sortname: 'Add Organization' },
                      isStatic: true,
                      label: "Don't see your Organization?",
                      subtext: 'Click here to add it to our system',
                    },
                    ...(searchData?.results ?? []),
                  ]}
                  setOptions={setSearchData}
                  searchData={searchData}
                  handleUserSearchChange={handleUserSearchChange}
                  isSearching={isSearching}
                  setChatUsers={handleUserSelect}
                  multiple={false}
                  placeholder='Search...'
                  isISupportSearch
                />
              </div>
            </div>
          ) : null}
        </i>
      ) : null}
    </div>
  )
}

export default ISupport
