import React, { useEffect } from 'react'
import { useState } from 'react'
import $J from 'jquery'

const AddBusOrgModal = ({ handleSubmit, isClaim, id = '0' }) => {
  const [userType, setUserType] = useState()
  const [showContent, setShowContent] = useState(false)

  const handleUserTypeChange = (event) => {
    setUserType(event.target.value)
  }

  useEffect(() => {
    return () => {
      $J('#add_business_or_organization_footer_modal_' + id).modal('hide')
    }
  }, [id])

  return (
    <div
      className='modal fade login_blk reset_password_blk it-add-business '
      id={'add_business_or_organization_footer_modal_' + id}
      role='dialog'
    >
      <div className='modal-dialog'>
        <div className='modal-content'>
          <div className='modal-header'>
            <div className='claim_steps'>
              <h3 className='add_title'>
                <span className='other_txt'>
                  {isClaim ? 'Sign Up' : 'Add'} a Business or Organization {isClaim ? 'on' : 'to'}{' '}
                  iTRUSTit
                </span>
              </h3>
              <p className=' mt-3 mb-3'>I want to {isClaim ? 'sign up as' : 'add'} a:</p>

              <div className='mt-md-4 mt-2 mb-1'>
                <input
                  type='radio'
                  name='userType'
                  id={'bus_' + isClaim}
                  value='Business'
                  checked={userType === 'Business'}
                  onChange={handleUserTypeChange}
                  style={{ cursor: 'pointer' }}
                />{' '}
                <label htmlFor={'bus_' + isClaim} style={{ cursor: 'pointer' }}>
                  Business
                </label>
              </div>
              <div className='mb-md-2 mb-2'>
                <input
                  type='radio'
                  name='userType'
                  id={'org_' + isClaim}
                  value='Organization'
                  checked={userType === 'Organization'}
                  onChange={handleUserTypeChange}
                  style={{ cursor: 'pointer' }}
                />{' '}
                <label htmlFor={'org_' + isClaim} style={{ cursor: 'pointer' }}>
                  Organization
                </label>
              </div>

              <p className=' mt-3 mb-3'>
                <button
                  onClick={() => setShowContent((st) => !st)}
                  className='btn'
                  style={{ outline: 'none', border: 'none', boxShadow: 'none' }}
                >
                  What's the difference?
                </button>
              </p>
              {showContent ? (
                <div className=' mt-3 mb-3'>
                  Organizations can have 'members', like Churches, Clubs, Alumni Association,
                  Rotary, Kiwanis, Charities, Fraternities, Associations, Groups, etc.. where
                  iTRUSTit can function as their 'Members Business Directory'. Other than that you
                  probably adding a Business.
                </div>
              ) : null}

              <div className='add_bottom'>
                <div className='text-center'>
                  <button
                    disabled={!userType}
                    onClick={!userType ? () => {} : () => handleSubmit(userType?.toLowerCase())}
                    className={'continue btn' + (!userType ? ' disabled' : '')}
                  >
                    Continue
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddBusOrgModal
