import React, { useEffect, useRef, useState } from 'react'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { formValueSelector, reduxForm } from 'redux-form'
import { getUser, toaster, userToken } from '../../services/customFunction'
import { SEARCH_TITLE } from '../../services/titleConst'
import AppLayout from '../AppLayout'
import LoginModal from '../LoginModal/LoginModal'
import SearchResultsMap from '../Search/SearchResultsMap'
import SearchResultsSortOptions from './Components/SortOptions/SearchResultsSortOptions'
import SearchHeader from './Components/SearchHeader'
import SearchPageLoadingIcon from '../Search/SearchPageLoadingIcon'
import { setSearchData, setIsSearching, setSearchDistance } from '../../actions/search'
import {
  getGeometryFromSearchAddress,
  runElasticSearch,
  runElasticSearchCategories,
} from '../../utils/elasticSearch'
import { profileInfoList, sortSearchResults } from '../../services/userApiService'
import { REQUIRED_MESSAGE_FOR_SEARCH_NO_ORG, SEARCH_AMT } from '../../services/const'
import { toTitleCase } from '../../services/formatService'
import NoSearchResultsSuggestion from '../Search/NoSearchResultsSuggestion'
import SearchResults from './Components/SearchResults'
import { isMobile } from 'react-device-detect'
import { AnimatePresence, motion } from 'framer-motion'
import UserHeaderSearch from '../UserHeader/Components/UserHeaderSearch'
import Categories from './Components/Categories'
import SearchSuggestions from './Components/SearchSuggestions'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import CategoriesList from './Components/CategoriesList'
import { singleCategoryList } from '../../services/businessService'
import { useHistory, useLocation } from 'react-router-dom'
import { useSearchSuggestions } from '../../hooks/useSearchSuggestions'
import TrustedUserList from '../CommonComponents/TrustedUserList/TrustedUserList'
import $J from 'jquery'
import ConfirmUntrustPopupModal from '../ProfileOtherUser/ConfirmUntrustModal/ConfirmUntrustPopupModal'
import ConfirmUnfriendPopupModal from '../ProfileOtherUser/ConfirmUnfriendModal/ConfirmUnfriendPopupModal'
import ConfirmRemoveFromOrgPopupModal from '../ProfileOtherUser/ConfirmRemoveFromOrgModal/ConfirmRemoveFromOrgPopupModal'
import InvitePopupModal from '../ProfileOtherUser/InvitePopupModal/InvitePopupModal'
import { removeMemberCall } from '../../services/memberApiService'

let MainSearch = (props) => {
  const { searchData, searchDistance } = props

  const usedLocationStateRef = useRef()
  const location = useLocation()
  const searchStr =
    !!location?.state?.category_name &&
    location?.state?.category_name !== usedLocationStateRef?.current
      ? location?.state?.category_name
      : searchData?.searchStr

  const history = useHistory()
  const isGlobalSearch = !searchData?.searchAddress
  const [stateData, setStateData] = useState({
    login_user_id: getUser()?.id,
    sortOrder: 'desc',
  })
  const [searchStatus, setSearchStatus] = useState(
    !isMobile || searchData?.fromLanding ? 'search_results' : 'categories',
  )
  const [searchResultsData, setSearchResultsData] = useState()
  const [categoryData, setCategoryData] = useState()
  const [friendRemoveData, setFriendRemoveData] = useState()
  const [itrustitRemoveData, setItrustitRemoveData] = useState()
  const [modalPropsData, setModalPropsData] = useState()
  const [hideUserSearchHeader, setHideUserSearchHeader] = useState(false)
  const [trustedUserData, setTrustedUserData] = useState({
    trusteUserData: {
      type: '',
      id: 0,
      user_type: '',
      total_common_members: 0,
    },
  })
  const {
    searchForSuggestions,
    searchingForSuggestions,
    searchSuggestions,
    setSearchSuggestions,
    inputLengthRef,
    searchData: mobileSearchData,
    setSearchData: setMobileSearchData,
  } = useSearchSuggestions({ searchStr, searchAddress: searchData?.searchAddress })
  const clickedLocSuggestionRef = useRef(false)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (
      !!location?.state?.category_name &&
      location?.state?.category_name !== usedLocationStateRef?.current
    ) {
      !props?.isSearching && props?.setIsSearching(true)
      props?.setSearchData({ ...props?.searchData, searchStr, isNewSearch: false })
      setSearchStatus('search_results')
      handleSearch({ isNewSearch: true })
      usedLocationStateRef.current = location?.state?.category_name
    }
  }, [searchStr])

  useEffect(() => {
    if (searchStatus === 'search_results' && (!!searchStr || !!searchData?.searchAddress)) {
      handleSearch({ isNewSearch: true })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchStr, searchData?.searchAddress, searchStatus])

  useEffect(() => {
    !!searchResultsData?.ranInitialSearch && !mobileSearchData?.blockSearch && handleSearch({})
  }, [
    stateData?.sortOrder,
    props?.searchDistance?.value,
    props?.sortOptions?.showBy?.value,
    props?.sortOptions?.sortBy?.value,
    mobileSearchData?.searchAddress,
    searchData?.isNewSearch,
  ])

  useEffect(() => {
    window.onscroll = () => {
      if (!searchResultsData?.loadingData && searchResultsData?.hasMoreData) {
        const winScroll = document.body.scrollTop || document.documentElement.scrollTop
        const height = document.documentElement.scrollHeight - document.documentElement.clientHeight
        const scrolled = winScroll / height

        if (scrolled >= 0.75) {
          setSearchResultsData((st) => ({ ...st, loadingData: true, hasMoreData: false }))
        }
      }
    }
    return () => {
      window.onscroll = () => {}
    }
  }, [searchResultsData?.hasMoreData, searchResultsData?.loadingData])

  useEffect(() => {
    !!searchResultsData?.loadingData &&
      handleResults({
        ids: searchResultsData?.userIDs?.slice(
          searchResultsData?.userIDsOffset,
          searchResultsData?.userIDsOffset + SEARCH_AMT,
        ),
        userIDsOffset: searchResultsData?.userIDsOffset + SEARCH_AMT,
      })
  }, [searchResultsData?.loadingData])

  const getSortedElasticResults = async ({ userIDs }) => {
    console.log('sortingElasticResults', {
      sortType: props?.sortOptions?.sortBy?.value,
      sortOrder: stateData.sortOrder,
    })
    const { data } = await sortSearchResults({
      ids: userIDs,
      orderBy: props?.sortOptions?.sortBy?.value,
      orderType: stateData.sortOrder,
    })
    return data?.profileInfo?.map((user) => user?.id)
  }

  const handleSearch = async ({ isNewSearch, canRetry = true, cityOverride }) => {
    !props.isSearching && props.setIsSearching(true)
    let business = isMobile ? mobileSearchData?.searchStr : searchStr ?? ''
    let citySearch = cityOverride ?? searchData?.searchAddress ?? ''
    localStorage.setItem('searchString', business)
    localStorage.setItem('searchAddress', citySearch)
    setSearchResultsData({ ranInitialSearch: true, cityOverride })
    let request = {
      view_type: 'full_view',
      sort_by: props.sortOptions?.sortBy?.value,
      user_type: props.sortOptions?.showBy?.value,
      sort_order: stateData.sortOrder,
      // category_id: state.category_id,
      // category_name: state.category_name,
      search_city_string: citySearch,
      search_string: business,
      searchDistance,
    }

    if (isGlobalSearch) request.search_city_string = 'Global'
    // else if (searchData?.searchAddress && !cityOverride)
    //   request.search_city_string = searchData?.searchAddress

    // if (searchStr) request.search_string = searchStr

    // if (state.latitude) request.user_latitude = state.latitude

    // if (state.longitude) request.user_longitude = state.longitude
    let searchResults

    const response =
      !isNewSearch &&
      !!searchResultsData?.originalElasticResults &&
      ['number_of_total_trusted', 'number_of_friends_trusted'].includes(
        props?.sortOptions?.sortBy?.value,
      )
        ? searchResultsData?.originalElasticResults
        : await runElasticSearch(
            (formatted_address) =>
              props.setSearchData({
                ...props.searchData,
                searchStr,
                searchAddress: formatted_address,
                fromLanding: false,
                isNewSearch: false,
              }),
            request,
            canRetry,
          )
    response?.error && toaster('error', response?.error)
    if (!['failed', 'CANCELLED'].includes(response?.status) && !response?.error) {
      searchResults =
        canRetry && response?.potentialZipCode
          ? await handleSearch({ canRetry: false, cityOverride: response?.potentialZipCode })
          : response
      if (cityOverride) return response

      let userIDs = searchResults.map((res) => res['_id']) || []
      if (
        ['number_of_total_trusted', 'number_of_friends_trusted'].includes(
          props?.sortOptions?.sortBy?.value,
        )
      ) {
        if (props?.sortOptions?.showBy?.value !== '') {
          userIDs =
            searchResults
              .filter((res) => res?.['_source']?.user_type === props?.sortOptions?.showBy?.value)
              ?.map((res) => res['_id']) || []
        }
        userIDs = userIDs?.length ? await getSortedElasticResults({ userIDs }) : userIDs
      }
      const userIDsOffset = Math.min(userIDs?.length, SEARCH_AMT)
      let searchedIds = userIDs.slice(0, userIDsOffset)

      handleResults({
        ids: searchedIds,
        userIDs,
        userIDsOffset,
        originalElasticResults: response,
        cityOverride,
      })
    } else if (response?.status !== 'CANCELLED') {
      props.setIsSearching(false)
    }
  }

  const handleResults = async (searchResults = {}) => {
    let usersList = { total_records: 0, total_pages: 1, user_list: [] }
    const ids = searchResults?.ids
    if (ids?.length) {
      const response = await profileInfoList({
        ids,
        orderBy: props.sortOptions?.sortBy?.value,
        orderType: stateData.sortOrder,
      })

      let user_list = response?.data?.profileInfo
      usersList = { total_records: user_list?.length, total_pages: 1, user_list }
      let markerArr = []
      await Promise.allSettled(
        usersList.user_list.map(async (user, index) => {
          if (user.categories.length === 0 && user.category_name) {
            usersList.user_list[index].categories = {
              category_name: toTitleCase(user.category_name, true),
            }
          }
          let lat = user.latitude
          let lng = user.longitude
          let latLongObj = {
            latitude: parseFloat(lat),
            longitude: parseFloat(lng),
            isOpen: false,
            userDetail: user,
            id: user.id,
          }
          if (!lat || !lng || lat === '' || lng === '') {
            const userWithoutLoc = (
              searchResults?.originalElasticResults ?? searchResultsData?.originalElasticResults
            ).find((u) => u['_source'].id === user.id)
            const { loc } = userWithoutLoc['_source']
            if (loc && loc.length > 1 && loc[0] !== 0 && loc[1] !== 0) {
              ;[lng, lat] = loc
            } else {
              const { full_address } = user ?? {}
              const locationDetails = await getGeometryFromSearchAddress(full_address)
              const { geometry } = locationDetails ?? {}
              if (!!geometry?.location) {
                lat = geometry.location.lat
                lng = geometry.location.lng
              }
              usersList.user_list[index].latitude = lat
              usersList.user_list[index].longitude = lng
            }
            if (!!lat && !!lng && lat !== '' && lng !== '') {
              const latLng = { latitude: lat, longitude: lng }
              latLongObj = { ...latLongObj, ...latLng, userDetail: { ...user, ...latLng } }
            }
          }
          markerArr.push(latLongObj)
        }),
      )

      props.setIsSearching(false)
      setSearchResultsData((st) => {
        const cityOverride = searchResults?.cityOverride ?? st?.cityOverride
        const userIDsOffset = searchResults?.userIDsOffset ?? st?.userIDsOffset
        const originalElasticResults =
          searchResults?.originalElasticResults ?? st?.originalElasticResults
        const totalRecords = searchResults?.userIDs?.length ?? st?.totalRecords
        return {
          ranInitialSearch: st.ranInitialSearch,
          originalElasticResults,
          totalRecords,
          userIDsOffset,
          cityOverride,
          from: 1,
          to: Math.min(userIDsOffset, totalRecords),
          usersList: [...(st.usersList ?? []), ...usersList?.user_list],
          hasMoreData: usersList?.user_list?.length >= 1,
          userIDs: searchResults?.userIDs ?? st?.userIDs,
          loadingData: false,
          markerArr,
        }
      })
    }
    props.setIsSearching(false)
  }

  const searchAgain = () => {
    setSearchResultsData((st) => ({ ...st, loadingData: true }))
  }

  const handleSortOrderChange = (type) => {
    setStateData((st) => ({ ...st, sortOrder: type }))
  }

  const handleMobileSearchSubmit = (searchStr = mobileSearchData?.searchStr) => {
    if (searchStr === '' || searchStr?.length < 2) {
      toaster('error', REQUIRED_MESSAGE_FOR_SEARCH_NO_ORG)
    } else {
      props.setSearchData({
        searchStr: searchStr,
        searchAddress: mobileSearchData?.searchAddress,
        searchAddressComponents: mobileSearchData?.searchAddressComponents,
      })
      setSearchStatus('search_results')
    }
  }

  const handleClickOnSuggestion = async (suggestion, source = 'search') => {
    // console.log(state, this.props);
    if (source === 'categoryList') setSearchStatus('categories')
    if (!!suggestion?.slug) {
      history.push({ pathname: process.env.PUBLIC_URL + '/' + suggestion.slug })
      return
    }
    setMobileSearchData((st) => ({
      ...st,
      searchStr: suggestion,
      clickedSuggestion: source === 'suggestion',
    }))
    handleMobileSearchSubmit(suggestion)
  }

  const showCatsList = async ({
    id = 0,
    label = 'More Categories',
    previousCategories: prevCats = [],
  }) => {
    const { data } = await singleCategoryList(id)
    if (data) {
      let categories = data.categories
      let categoryList = []
      categories.forEach((category) => {
        let objContry = { value: category.id, label: category.category_name }
        categoryList.push(objContry)
      })
      const previousCategories = prevCats ? prevCats : categoryData.previousCategories ?? []
      let catData = {
        categoryList,
        viewingCategory: id,
        categoryLabel: label,
        previousCategories: [...previousCategories, { id, label }],
      }
      // console.log({ catData });

      setCategoryData(catData)
      setSearchStatus('categoriesList')
    }
  }

  const returnFromCategoriesList = () => {
    const previousCategories = categoryData.previousCategories ?? []
    if (!previousCategories.length || previousCategories.length === 1) {
      setSearchStatus('categories')
    } else {
      let previousCategory = previousCategories.pop()
      previousCategory = previousCategories.pop()
      showCatsList({
        id: previousCategory.id,
        label: previousCategory.label,
        previousCategories,
      })
    }
  }

  const onHoverTrustBy = (
    id,
    type,
    user_type,
    total_common_members,
    updateFunc = null,
    loadingFunc = null,
  ) => {
    if (stateData.login_user_id || type === 'total') {
      setTrustedUserData({
        isTrustedUserModalShow: true,
        trusteUserData: {
          type: type,
          id: id,
          user_type: user_type,
          total_common_members: total_common_members,
        },
        updateFunc,
        loadingFunc,
      })
    } else {
      setTrustedUserData((st) => ({ ...st, isTrustedUserModalShow: false }))
    }
  }

  const friendAddRemoveHandle = (data) => {
    setSearchResultsData((st) => ({
      ...st,
      usersList:
        st.usersList?.map?.((user) =>
          user.id === data?.user_id
            ? { ...user, is_friend_user: data?.is_friend_user, friendship_id: data?.friendship_id }
            : user,
        ) ?? [],
    }))
  }

  const confirmFriendAddRemove = (needsConfirmation = false, data, tab) => {
    // console.log(needsConfirmation, data);
    setFriendRemoveData(data)
    needsConfirmation && $J('#confirm_unfriend_popup_modal').modal('show')
  }

  const itrustitHandle = (data) => {
    setSearchResultsData((st) => ({
      ...st,
      usersList:
        st.usersList?.map?.((user) =>
          user.id === data?.id ? { ...user, is_trusted_user: data?.is_trusted_user } : user,
        ) ?? [],
    }))
  }

  const confirmitrustitAddRemove = (needsConfirmation = false, data, tab) => {
    // console.log(needsConfirmation, data);
    setItrustitRemoveData(data)
    needsConfirmation && $J('#confirm_untrust_popup_modal').modal('show')
  }

  const setModalProps = (modalProps, needsConfirmation, cleanup = () => {}) => {
    setModalPropsData((st) => ({
      hasData: true,
      triggerUpdate: !st?.triggerUpdate ?? true,
      confirmModalProps: modalProps.confirm,
      inviteModalProps: modalProps.invite,
      cleanup,
      needsConfirmation,
    }))
  }
  useEffect(() => {
    if (!!modalPropsData?.hasData) {
      modalPropsData?.needsConfirmation
        ? $J('#confirm_remove_from_org').modal('show')
        : memberAddRemoveYour(
            {
              is_your_member_user: modalPropsData?.confirmModalProps?.dataYour?.is_your_member_user,
              id: modalPropsData?.confirmModalProps?.user_id,
              flag: 'member_of_your_organization',
            },
            modalPropsData?.cleanup,
          )
    }
  }, [modalPropsData?.triggerUpdate])

  const memberAddRemoveYour = async (data, cleanup) => {
    let memberAddRemoveReqData = {
      id: data.id,
      member_type: data.flag,
    }
    if (data.is_your_member_user !== 0) {
      const res = await removeMemberCall(memberAddRemoveReqData)
      if (res?.status === 200) {
        cleanup?.()
        // let apiResponseData = response.data
        // self.setState({ is_your_member_user: 0 })
      }
    } else if (data.is_your_member_user !== 2) {
      $J('#invite_modal').modal('show')
    }
  }

  const setStatusOfMemberUserYour = (id, status) => {
    setSearchResultsData((st) => ({
      ...st,
      usersList:
        st.usersList?.map?.((user) =>
          user.id === id
            ? {
                ...user,
                is_your_member_user: status === 'pending' ? 2 : status === 'accepted' ? 1 : 0,
              }
            : user,
        ) ?? [],
    }))
  }

  const addBusinessHandler = (event) => {
    $J('#add_business_or_organization_footer_modal_0').modal('show')
  }

  const openLoginPopup = (event) => {
    $J('#login_modal').modal('show')
  }
  return (
    <span>
      <AppLayout routeActive={props.location.pathname}>
        <Helmet>
          <title>{SEARCH_TITLE}</title>
        </Helmet>
        <section className='listing_block search_blk'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-8' style={{ minHeight: 440 }}>
                {isMobile && !hideUserSearchHeader ? (
                  <UserHeaderSearch
                    searchStr={mobileSearchData?.searchStr}
                    searchAddress={mobileSearchData?.searchAddress}
                    stateData={mobileSearchData}
                    setStateData={setMobileSearchData}
                    handleSubmit={handleMobileSearchSubmit}
                    isMobileSearch
                    searchStatus={searchStatus}
                    setSearchStatus={setSearchStatus}
                    searchData={searchData}
                    setSearchData={props.setSearchData}
                    clickedLocSuggestionRef={clickedLocSuggestionRef}
                    inputLengthRef={inputLengthRef}
                    searchForSuggestions={searchForSuggestions}
                    setSearchSuggestions={setSearchSuggestions}
                    searchDistance={props.searchDistance}
                    setSearchDistance={props.setSearchDistance}
                  />
                ) : null}

                <SwitchTransition mode='out-in'>
                  <CSSTransition
                    unmountOnExit
                    key={searchStatus}
                    addEndListener={(node, done) =>
                      node.addEventListener('transitionend', done, false)
                    }
                    className='d-flex flex-column search-suggestions position-relative'
                    style={{ minHeight: 310 }}
                  >
                    <div className='h-100'>
                      {searchStatus === 'search_results' ? (
                        <>
                          <SearchHeader
                            isGlobalSearch={isGlobalSearch}
                            searchStr={searchStr}
                            searchAddress={searchData.searchAddress}
                            isSearching={props?.isSearching}
                            searchResultsData={searchResultsData}
                          />
                          <SearchResultsSortOptions
                            sortOrder={stateData.sortOrder}
                            handleSortOrderChange={handleSortOrderChange}
                          />
                          {searchResultsData?.totalRecords > 0 && !props.isSearching ? (
                            <SearchResults
                              searchResultsData={searchResultsData}
                              login_user_id={stateData.login_user_id}
                              searchAgain={searchAgain}
                              onHoverTrustBy={onHoverTrustBy}
                              friendAddRemoveHandle={friendAddRemoveHandle}
                              itrustitHandle={itrustitHandle}
                              confirmFriendAddRemove={confirmFriendAddRemove}
                              confirmitrustitAddRemove={confirmitrustitAddRemove}
                              setStatusOfMemberUserYour={setStatusOfMemberUserYour}
                              setModalProps={setModalProps}
                            />
                          ) : !props.isSearching ? (
                            <NoSearchResultsSuggestion />
                          ) : (
                            <SearchPageLoadingIcon className='mb-5' />
                          )}

                          <section className='footer_top_blk'>
                            <div className='container'>
                              <div className='footer_container flex-column align-items-center'>
                                <p className='varela p-0 text-center mb-2'>
                                  Didn't find the business or organization you're looking for?
                                </p>
                                <button
                                  className='blue_btn it-home-add-bus-btn m-0'
                                  onClick={userToken() ? addBusinessHandler : openLoginPopup}
                                >
                                  ADD BUSINESS/ORG
                                </button>
                              </div>
                            </div>
                          </section>
                          <TrustedUserList
                            isBlockShowSetFalse={(e) => {
                              setTrustedUserData((st) => ({ ...st, isTrustedUserModalShow: false }))
                            }}
                            isBlockShow={trustedUserData?.isTrustedUserModalShow}
                            trusteUserData={trustedUserData.trusteUserData}
                            updateFunc={trustedUserData?.updateFunc}
                            loadingFunc={trustedUserData?.loadingFunc}
                          />
                        </>
                      ) : searchStatus === 'categoriesList' ? (
                        <CategoriesList
                          categoryData={categoryData}
                          goBack={returnFromCategoriesList}
                          showCategoriesList={showCatsList}
                          viewingCategory={categoryData.viewingCategory}
                          categoryLabel={categoryData.categoryLabel}
                          searchFunc={handleClickOnSuggestion}
                          setHideUserSearchHeader={setHideUserSearchHeader}
                        />
                      ) : searchStatus === 'categories' ? (
                        <Categories
                          animate={searchStatus === 'categories' ? 'open' : 'closed'}
                          handleClickOnSuggestion={handleClickOnSuggestion}
                          showCategoriesList={showCatsList}
                        />
                      ) : searchStatus === 'suggestions' ? (
                        <SearchSuggestions
                          animate={searchStatus === 'suggestions' ? 'open' : 'closed'}
                          suggestions={searchSuggestions}
                          handleClickOnSuggestion={handleClickOnSuggestion}
                          searchingForSuggestions={searchingForSuggestions}
                        />
                      ) : null}
                    </div>
                  </CSSTransition>
                </SwitchTransition>
              </div>

              {searchStatus === 'search_results' ? (
                <SearchResultsMap markers={searchResultsData?.markerArr ?? []} isSearchPage />
              ) : null}
            </div>
          </div>
        </section>
      </AppLayout>
      <LoginModal isLogin={() => {}} {...props} />
      <ConfirmUntrustPopupModal
        username={itrustitRemoveData?.username}
        data={itrustitRemoveData}
        handler={() =>
          itrustitHandle({
            id: itrustitRemoveData?.id,
            is_trusted_user: 0,
          })
        }
      />
      <ConfirmUnfriendPopupModal
        username={friendRemoveData?.username}
        data={friendRemoveData}
        handler={() =>
          friendAddRemoveHandle({
            user_id: friendRemoveData?.user_id,
            is_friend_user: 0,
            friendship_id: null,
          })
        }
      />{' '}
      <ConfirmRemoveFromOrgPopupModal {...(modalPropsData?.confirmModalProps ?? {})} />
      <InvitePopupModal {...(modalPropsData?.inviteModalProps ?? {})} />
    </span>
  )
}

MainSearch = reduxForm({
  form: 'searchForm',
  enableReinitialize: true,
})(MainSearch)

const selector = formValueSelector('searchForm')

function mapStateToProps(state) {
  const sortBy = selector(state, 'sortBy') ?? { value: 'relevance', label: 'Relevance' }
  const showBy = selector(state, 'showBy') ?? { value: '', label: 'All' }
  const searchDistance = state?.SearchReducer?.searchDistance

  return {
    sortOptions: { sortBy, showBy },
    searchDistance,
    searchData: state.SearchReducer.searchData,
    isSearching: state.SearchReducer.isSearching,
    initialValues: {
      sortBy: { value: 'relevance', label: 'Relevance' },
      showBy: { value: '', label: 'All' },
      searchDistance,
    },
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ setSearchData, setIsSearching, setSearchDistance }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(MainSearch)
