import './Dialogs.scss'

const Dialogs = ({ title, question, onYesCloseDialog, onNoCloseDialog }) => {
  return (
    <div className='modal dialogmodal overlay-top-layer' id='discard_post_modal' role='dialog'>
      <div className='modal-dialog modal-sm'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h4 className='title'>{title}</h4>
          </div>
          <div className='modal-body'>
            <p className=' mt-3 mb-3'>{question}</p>
          </div>
          <div className='modal-footer'>
            <div className='btn btn-no'>
              <button className='btn-no' onClick={onNoCloseDialog}>
                No
              </button>
              <div className='btn btn-no'>
                <button onClick={onYesCloseDialog} className='btn-yes' data-bs-dismiss='modal'>
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Dialogs
