import React, { useState } from 'react'
import GoogleAutocomplete from 'react-google-autocomplete'
import { Link, useHistory } from 'react-router-dom'
import { DEFAULT_PROFILE_PICTURE, REQUIRED_MESSAGE_FOR_SEARCH_NO_ORG } from '../../services/const'
import { toaster, userToken, validateSearchInput } from '../../services/customFunction'
import { PROFILE_PICTURE_SMALL_URL_PATH } from '../../services/environment'
import * as route from '../../services/routeConst'
import {
  ADD_BUSINESS_TITLE_PS,
  ADD_ORGANIZATION_TITLE_PS,
  CLAIM_BUS_ORG_TITLE,
  SIGNUP_BUSINESS_TITLE_PS,
  SIGNUP_ORGANIZATION_TITLE_PS,
} from '../../services/titleConst'
import AppLayout from '../AppLayout'
import '../AddBusiness/addbusiness.scss'
import { runElasticSearch } from '../../utils/elasticSearch'
import { Helmet } from 'react-helmet'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { signupType } from '../../actions/auth'
import { useEffect } from 'react'
import { getCurrentLocationString } from '../../services/externalServices'
import AddBusOrgModal from '../AddBusinessOrOrganizationFooter/AddBusOrgModal'
import $J from 'jquery'
import LogoutPopup from './LogoutPopup'
import parse from 'html-react-parser'
import $ from 'jquery'

const AddBusOrg = (props) => {
  const history = useHistory()
  const locationState = props?.promotionData ?? props?.location?.state ?? {}
  const [stateData, setStateData] = useState({
    searchAddress: '',
    searchStr: '',
    searchResults: [],
    imatrix_id: locationState?.imatrix_id ?? '',
    isLoading: false,
    didSearch: false,
    signupType: locationState?.signupType,
    isAdd: locationState?.isAdd,
    isClaim: locationState?.isClaim,
    addType: locationState?.addType,
    loginUserData: locationState?.loginUserData,
  })
  const [signupUserType, setSignupUserType] = useState()

  const setLocationToCurrentLoc = () => {
    getCurrentLocationString((loc) => setStateData((st) => ({ ...st, searchAddress: loc })))
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (!!signupUserType)
      userToken() ? $J('#add_as_owner_logout_popup_1').modal('show') : addBusinessHandler()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signupUserType])

  const handlePlaceSelected = (event) => {
    setStateData((st) => ({
      ...st,
      searchAddress: event.formatted_address ? event.formatted_address : event.name,
    }))
  }

  const handleAddressChange = (event) => {
    let inputValue = event.target.value
    setStateData((st) => ({ ...st, searchAddress: inputValue }))
  }

  const searchBusiness = async () => {
    if (
      // !validateSearchInput(stateData.searchAddress, REQUIRED_MESSAGE_FOR_SEARCH) ||
      !validateSearchInput(stateData.searchStr, REQUIRED_MESSAGE_FOR_SEARCH_NO_ORG)
    ) {
      return
    }

    if (stateData.searchStr?.length) {
      setStateData((st) => ({ ...st, scrolled: false, didSearch: true, isLoading: true }))
      const response = await elasticSearch()
      response?.error && toaster('error', response?.error)
      setStateData((st) => ({
        ...st,
        searchResults:
          !response?.error && response?.status !== 'failed' && response?.status !== 'CANCELLED'
            ? response
            : st.searchResults,
        isLoading: false,
      }))
    }
  }

  useEffect(() => {
    if (showSignUpButton && !stateData.scrolled) {
      window.scrollTo({
        top: $('#search-results').offset().top - 100,
        behavior: 'smooth',
      })
      setStateData((st) => ({ ...st, scrolled: true }))
    }
  }, [stateData?.searchResults?.length])

  const addBusinessHandler = () => {
    if (isClaim && !signupType && !signupUserType) {
      $J('#add_business_or_organization_footer_modal_1').modal('show')
    } else {
      props.signupType(stateData.signupType)
      props?.handleSubmit
        ? props?.handleSubmit(stateData.signupType?.toLowerCase() ?? signupUserType?.toLowerCase())
        : history.push({
            pathname: route.SIGNUP_NEW,
            state: {
              signupType: stateData.signupType?.toLowerCase() ?? signupUserType?.toLowerCase(),
              isAdd: stateData.isAdd,
              isClaim: stateData.isClaim,
              addType: stateData.addType,
              loginUserData: stateData.loginUserData,
              imatrix_id: stateData.imatrix_id,
            },
          })
    }
  }

  const searchStrChangeHandler = (event) => {
    let searchStr = event.target.value
    localStorage.setItem('bus_org_name_from_presearch', searchStr)
    setStateData((st) => ({ ...st, searchStr }))
  }

  const elasticSearch = async () => {
    const elasticSearchRequest = {
      search_string: stateData.searchStr,
      search_city_string: stateData.searchAddress,
      must_not: [{ match: { user_type: 'individual' } }],
      maxResults: 200,
    }
    if (stateData?.isClaim) {
      elasticSearchRequest.extraMust = { match: { is_business_claimed: '0' } }
    }
    return runElasticSearch(undefined, elasticSearchRequest)
  }

  const suggestionClicked = (data) => (event) => {
    setStateData((st) => ({
      ...st,
      searchStr: data.name,
      businessOrganizationNameSearch: data.name,
    }))
    history.push({
      pathname: route.CLAIM_BUSINESS_PROFILE_ROUTE,
      state: { user: data, loginUserData: stateData?.loginUserData },
    })
  }

  const handleSubmitUserType = (userType) => {
    setSignupUserType(userType)
    $J('#add_business_or_organization_footer_modal_1').modal('hide')
  }

  const handleKeyUp = (e) => {
    e.key === 'Enter' && searchBusiness()
  }

  const { isClaim, isAdd, addType, signupType, searchStr, isLoading, didSearch } = stateData
  const formattedUserType = signupType === 'business' ? 'Business' : 'Organization'
  const showSignUpButton = didSearch && !isLoading

  const content = (
    <>
      <Helmet>
        <title>
          {isClaim
            ? CLAIM_BUS_ORG_TITLE
            : isAdd
            ? signupType === 'business'
              ? ADD_BUSINESS_TITLE_PS
              : ADD_ORGANIZATION_TITLE_PS
            : signupType === 'business'
            ? SIGNUP_BUSINESS_TITLE_PS
            : SIGNUP_ORGANIZATION_TITLE_PS}
        </title>
      </Helmet>
      <LogoutPopup
        user_type={signupUserType}
        isAdd={false}
        redirectTo={route.SIGNUP_NEW}
        modalId='1'
      />
      <AddBusOrgModal handleSubmit={handleSubmitUserType} isClaim={isClaim} id='1' />
      <section className='upsection text-center it-auth-page'>
        <div className='container'>
          <h1 style={props?.onPromotionScreen ? { fontSize: 28 } : {}}>
            {isClaim ? (
              'Claim Business / Organization'
            ) : (
              <>
                {isAdd ? 'Add' : ''} {formattedUserType} {isAdd ? '' : 'Sign Up'}
              </>
            )}{' '}
            - Presearch
          </h1>
          <p className='check' style={props?.onPromotionScreen ? { fontSize: 16 } : {}}>
            First, search to see if {!isClaim && addType === 'customer' ? 'this ' : 'your '}
            {isClaim ? 'business or organization' : signupType ?? 'business'} already has a profile.
          </p>
          <div
            className={
              'it-auth-part ' + (props?.onPromotionScreen ? 'promotion-bus-org-search' : '')
            }
          >
            <div className='bannerform'>
              <div className='bannerinner'>
                <div className='form-group'>
                  <input
                    type='text'
                    name='searchStr'
                    className='form-control zip1'
                    onChange={searchStrChangeHandler}
                    onKeyUp={handleKeyUp}
                    value={searchStr}
                    placeholder={
                      (isClaim ? 'Business or Organization' : formattedUserType) + ' name'
                    }
                    autoComplete='off'
                  />
                </div>
                <div className='form-group'>
                  <GoogleAutocomplete
                    type='text'
                    autoComplete='off'
                    className='form-control zip2 position-relative'
                    name='searchAddress'
                    onChange={handleAddressChange}
                    value={stateData.searchAddress}
                    placeholder='City, State, or Zip Code...'
                    onKeyUp={handleKeyUp}
                    onPlaceSelected={handlePlaceSelected}
                    onFocus={(event) => {
                      event.target.setAttribute('autocomplete', 'off')
                    }}
                    options={{ types: ['geocode'] }}
                  />
                  <i
                    onClick={setLocationToCurrentLoc}
                    title='Click to fetch your current location'
                    className='fa fa-location'
                    aria-hidden='true'
                    style={{
                      cursor: 'pointer',
                      zIndex: 2000,
                      position: 'absolute',
                      top: '50%',
                      right: '2.5%',
                      marginTop: -10,
                      color: '#544cf9',
                      fontSize: 20,
                    }}
                  ></i>
                </div>
              </div>

              <div className='search-btn m-0'>
                <button onClick={searchBusiness} className='search m-0'>
                  <i className='fa fa-search' aria-hidden='true'></i>
                </button>
              </div>
            </div>
          </div>
          {showSignUpButton ? (
            <div>
              {stateData.searchResults?.length ? (
                <div className='dont mt-3' id='search-results'>
                  <p>
                    If {!isClaim && addType === 'customer' ? 'the ' : 'your '}{' '}
                    {isClaim ? 'business or organization' : signupType} is listed below, you can
                    Claim it or click on it to view their profile page.
                  </p>
                </div>
              ) : null}
              {stateData.searchResults.map((user) => {
                const {
                  id,
                  image,
                  slug,
                  business_organization_name,
                  city,
                  state,
                  zipcode,
                  country,
                  is_business_claimed,
                } = user['_source'] ?? {}
                return (
                  <div className='claim_steps it-auth-search mt-2' key={id}>
                    <div className='claim_frnd_detail_box'>
                      <div className='image_blk'>
                        {typeof image !== 'undefined' && image !== '' && image ? (
                          <img
                            className='imageTag'
                            alt=''
                            src={PROFILE_PICTURE_SMALL_URL_PATH + image}
                          />
                        ) : (
                          <img className='imageTag' alt='' src={DEFAULT_PROFILE_PICTURE} />
                        )}
                      </div>
                      <div className='claim_frnd_right'>
                        <div className='it-auth-right'>
                          <Link to={process.env.PUBLIC_URL + '/' + slug}>
                            <h5 className='varela'>
                              {user?.highlight
                                ? parse(user?.highlight.name[0])
                                : business_organization_name}
                            </h5>
                          </Link>
                          {city || state || country || zipcode ? (
                            <>
                              <p className='address'>
                                {city ? city + (state || zipcode ? ', ' : '') : null}
                                {state ? state + ' ' : null}
                                {zipcode ? zipcode : null}
                              </p>
                              {country ? <p className='address mb-0'>{country}</p> : null}
                            </>
                          ) : (
                            <p className='address'>No address data</p>
                          )}
                        </div>

                        {is_business_claimed === '0' && (
                          <button
                            onClick={suggestionClicked(user['_source'])}
                            className='it-sign-claim mt-3'
                          >
                            Claim Now
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          ) : (
            ''
          )}
          {showSignUpButton && (
            <>
              <div className='dont mt-3 mb-4' id='search-results'>
                {stateData.searchResults?.length ? (
                  <>
                    <p className='mb-0'>
                      Don't see {!isClaim && addType === 'customer' ? 'the ' : 'your '}{' '}
                      {isClaim ? 'business or organization' : signupType} listed? Continue to{' '}
                      {isAdd ? 'the next step to add it to our system' : 'signup'}.
                    </p>
                  </>
                ) : (
                  <p style={{ maxWidth: 500 }} className='mx-auto mb-0'>
                    We couldn't find a{signupType === 'organization' ? 'n' : ''}{' '}
                    {isClaim ? 'business or organization' : signupType} that matched your search.
                    Please continue to the next step to add it to our system.
                  </p>
                )}
                <button
                  onClick={addBusinessHandler}
                  className={props?.onPromotionScreen ? 'promotion-bus-org-search-btn' : ''}
                >
                  {isAdd ? 'Add' : 'Sign Up'}{' '}
                  {isClaim ? 'Business or Organization' : formattedUserType}
                </button>
              </div>
            </>
          )}
          {isLoading ? (
            <div className='search-section'>
              <div className='no_search_result'>
                <div className='search-page-loading-icon'>
                  <small className='fade-in-text'>
                    Searching for{' '}
                    {isClaim
                      ? 'businesses and organizations'
                      : signupType === 'business'
                      ? 'businesses'
                      : 'organizations'}
                  </small>
                  <i />{' '}
                </div>
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
      </section>
    </>
  )

  return !props?.hideAppLayout ? (
    <AppLayout isDisplayFooter={false}>{content}</AppLayout>
  ) : (
    <>{content}</>
  )
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ signupType }, dispatch)
}

export default connect(null, mapDispatchToProps)(AddBusOrg)
