import React, { Component } from 'react'

import AppLayout from '../AppLayout'
import './signupbusiness.scss'
//import SignupBusiness1 from "./Step1/SignupBusiness1";
//import SignupBusiness2 from "./Step2/SignupBusiness2";
import SignupBusiness3 from './Step3/SignupBusiness3'
import { SIGNUP_BUSINESS_TITLE } from '../../services/titleConst'
import { Helmet } from 'react-helmet'

class SignupBusiness extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isDisplayBusiness2: false,
      formData: [],
      imatrix_id: props.location.state ? props.location.state.imatrix_id : '',
      fillData: {},
    }
  }

  isForm2 = (data) => {
    this.setState({ isDisplayBusiness2: data })
  }
  formSubmitHandler = (data) => {
    this.setState({ formData: data })
  }
  componentDidMount() {
    let self = this
    if (this.props.location) {
      self.setState({ fillData: this.props.location.state.form_data })
      // console.log("dataOnMount", this.props.location.state.form_data);
    }
  }

  componentWillUnmount() {
    /*Asana 210824-1*/
    this.setState((prevState) => {
      let formData = Object.assign({}, prevState.formData) // creating copy of state variable formData
      formData = []
      return { formData } // return new object jasper object
    })
  }
  render() {
    return (
      <AppLayout isDisplayFooter={false}>
        <Helmet>
          <title>{SIGNUP_BUSINESS_TITLE}</title>
        </Helmet>
        {/* {!this.state.isDisplayBusiness2 ? (
                    <SignupBusiness1
                        isDisplayForm2={this.isForm2}
                        formData={this.formSubmitHandler}
                        imatrix_id={this.state.imatrix_id}
                        fillData={this.state.fillData}
                    />
                ) : (
                    <SignupBusiness2 isDisplayForm2={this.isForm2} form1Data={this.state.formData} />
                )} */}

        <SignupBusiness3
          isDisplayForm2={this.isForm2}
          imatrix_id={this.state.imatrix_id}
          fillData={this.state.fillData}
        />
      </AppLayout>
    )
  }
}

export default SignupBusiness
