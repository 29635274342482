import axios from 'axios'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import { singleCategoryList } from '../../../services/businessService'
import { REQUIRED_MESSAGE_FOR_SEARCH } from '../../../services/const'
import { toaster } from '../../../services/customFunction'
import { GOOGLE_GEOCODING_API_KEY } from '../../../services/environment'
import { customLogger } from '../../../utils/CustomLogger'
import CategoriesList from '../../CommonComponents/CategoriesList/CategoriesList'
import Categories from './Categories'
import SearchSuggestions from './SearchSuggestions'

const sugVariants = {
  open: {
    transition: { staggerChildren: 0.07, delayChildren: 0 },
  },
  closed: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 },
  },
}
const catVariants = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  closed: {
    y: 0,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
}

export default function AnimatedSearchSection({
  suggestions,
  searchStatus,
  categoryData,
  showCategoriesList,
  resetSearch,
  hideCategoriesList,
  headerSearchBarClicked,
  citySearch,
  searchForSuggestion,
  useGlobalSearch,
  children,
}) {
  const history = useHistory()

  const getCurrentLocationString = async (callback, suggestion) => {
    if (navigator.geolocation) {
      return navigator.geolocation.getCurrentPosition(
        (position) => {
          const pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          }
          let REVERSE_GEOCODING_API_URL = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${pos.lat},${pos.lng}&result_type=political&key=${GOOGLE_GEOCODING_API_KEY}`
          axios
            .get(REVERSE_GEOCODING_API_URL)
            .then((res) => {
              // console.log(res);
              if (
                res.data.results &&
                res.data.results[0] &&
                res.data.results[0].formatted_address
              ) {
                callback(suggestion, res.data.results[0].formatted_address)
              }
            })
            .catch((error) => {
              customLogger(error)
            })
        },
        () => {
          //Geolocator service failed
          toaster(
            'error',
            REQUIRED_MESSAGE_FOR_SEARCH +
              ' or enable Location Services for iTRUSTit in your browser settings',
          )
        },
      )
    } else {
      // Browser doesn't support Geolocation
      callback(suggestion, '')
    }
  }

  const handleClickOnSuggestion = async (suggestion, source = 'search') => {
    // console.log(state, this.props);
    if (source === 'categoryList') hideCategoriesList()
    if (!!suggestion?.slug) {
      history.push({
        pathname: process.env.PUBLIC_URL + '/' + suggestion.slug,
      })
      return
    }
    localStorage.setItem('businessOrganizationNameSearch', suggestion)
    // console.log('CLICKED ON SUGGESTION');
    headerSearchBarClicked({ useGlobalSearch, value: false, search: suggestion })
    !citySearch
      ? getCurrentLocationString(searchForSuggestion, suggestion)
      : searchForSuggestion(suggestion, citySearch)
  }

  const showCatsList = async ({
    id = 0,
    label = 'More Categories',
    previousCategories: prevCats = [],
  }) => {
    headerSearchBarClicked({ useGlobalSearch, value: false })

    const { data } = await singleCategoryList(id)
    if (data) {
      let categories = data.categories
      let categoryList = []
      categories.forEach((category) => {
        let objContry = { value: category.id, label: category.category_name }
        categoryList.push(objContry)
      })
      const previousCategories = prevCats ? prevCats : categoryData.previousCategories ?? []
      let catData = {
        categoryList,
        viewingCategory: id,
        categoryLabel: label,
        previousCategories: [...previousCategories, { id, label }],
      }
      // console.log({ catData });

      showCategoriesList(catData)
    }
  }

  const returnFromCategoriesList = () => {
    const previousCategories = categoryData.previousCategories ?? []
    if (!previousCategories.length || previousCategories.length === 1) {
      hideCategoriesList()
    } else {
      let previousCategory = previousCategories.pop()
      previousCategory = previousCategories.pop()
      showCatsList({
        id: previousCategory.id,
        label: previousCategory.label,
        previousCategories,
      })
    }
  }
  return (
    <SwitchTransition mode='out-in'>
      <CSSTransition
        unmountOnExit
        key={searchStatus}
        addEndListener={(node, done) => node.addEventListener('transitionend', done, false)}
        className='search-suggestions'
      >
        <div>
          {searchStatus === 'categoriesList' ? (
            <CategoriesList
              categoryData={categoryData}
              goBack={returnFromCategoriesList}
              showCategoriesList={showCategoriesList}
              viewingCategory={categoryData.viewingCategory}
              categoryLabel={categoryData.categoryLabel}
              searchFunc={handleClickOnSuggestion}
            />
          ) : searchStatus === 'suggestions' ? (
            <SearchSuggestions
              animate={searchStatus === 'suggestions' ? 'open' : 'closed'}
              sugVariants={sugVariants}
              suggestions={suggestions}
              catVariants={catVariants}
              handleClickOnSuggestion={handleClickOnSuggestion}
              useGlobalSearch={useGlobalSearch}
            />
          ) : searchStatus === 'categories' ? (
            <React.Fragment>
              <Categories
                animate={searchStatus === 'categories' ? 'open' : 'closed'}
                catVariants={catVariants}
                handleClickOnSuggestion={handleClickOnSuggestion}
                showCategoriesList={showCategoriesList}
              />

              <div className='py-5'>
                {/* <h4 className='text-center'>Ad &amp; Update Space...</h4> */}
                <h4 className='text-center'> </h4>
              </div>
            </React.Fragment>
          ) : searchStatus === 'searchResults' && !resetSearch ? (
            children
          ) : null}
        </div>
      </CSSTransition>
    </SwitchTransition>
  )
}
