import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'

import AppLayout from '../AppLayout'
import { SEARCH_TITLE } from '../../services/titleConst'
import { profileInfoList, sortSearchResults } from '../../services/userApiService'
import {
  hideUserHeader,
  headerSearchBarSet,
  headerSearchBarReset,
  headerSearchBarValue,
  headerSearchBarLocChange,
  headerSearchBarClicked,
  headerSearchBarSuggestionClicked,
} from '../../actions/profile'
import $ from 'jquery'
import { customLogger } from '../../utils/CustomLogger'
import { runElasticSearch, getGeometryFromSearchAddress } from '../../utils/elasticSearch'
import { toTitleCase } from '../../services/formatService'
import { getUser, toaster } from '../../services/customFunction'
import CachedSearch from './CachedSearch'
import { showSearchLocationModal } from '../CommonComponents/SearchLocationModal/SearchLocationModal'
import SearchResultsSection from './SearchResultsSection'
import AnimatedSearchSection from './MobileSearch/AnimatedSearchSection'
import LoginModal from '../LoginModal/LoginModal'
import { SEARCH_AMT } from '../../services/const'
import { Helmet } from 'react-helmet'
import ConfirmRemoveFromOrgPopupModal from '../ProfileOtherUser/ConfirmRemoveFromOrgModal/ConfirmRemoveFromOrgPopupModal'
import InvitePopupModal from '../ProfileOtherUser/InvitePopupModal/InvitePopupModal'
import $J from 'jquery'
import { removeMemberCall } from '../../services/memberApiService'

const sortOrderOptions = [
  { value: 'asc', label: 'ASC' },
  { value: 'desc', label: 'DESC' },
]

const sortByOptions = [
  { value: 'relevance', label: 'Relevance' },
  { value: 'name', label: 'Name' },
  { value: 'number_of_friends_trusted', label: '# Friend Trusts' },
  {
    value: 'number_of_friends_of_friends_trusted',
    label: '# Friends of Friends Trusts',
  },
  { value: 'number_of_total_trusted', label: 'Total Trusts' },
]

const showByOptions = [
  { value: '', label: 'All' },
  { value: 'individual', label: 'People' },
  { value: 'business', label: 'Business' },
  { value: 'organization', label: 'Organizations' },
]

class Search extends Component {
  constructor(props) {
    super(props)
    //console.log("props", props);

    this.state = {
      login_user_id: getUser()?.id,
      viewType: 'full_view', // full_view | list_view
      activePage: 1,
      sortBy: 'relevance',
      showBy: '',
      sortOrder: 'asc', // asc | desc
      totalPages: 0,
      totalRecords: 0,
      originalElasticResults: [],
      usersList: [],
      userIDs: [],
      userIDsOffset: 0,
      sortBySelectedOption: { value: 'relevance', label: 'Relevance' },
      showBySelectedOption: { value: '', label: 'All' },
      sortOrderSelectedOption: sortOrderOptions[0],
      businessOrganizationNameSearch: localStorage.getItem('businessOrganizationNameSearch'),
      citySearch: '',
      markers: [],
      loadingData: false,
      isSearching: false,
      isPageLoading: false,
      latitude: '',
      longitude: '',
      isClickOrder: false,
      category_id: '',
      category_name: '',
      isRemoveCategory: false,
      isTrustedUserModalShow: false,
      categoryData: {},
      trusteUserData: {
        type: '',
        id: 0,
        user_type: '',
        total_common_members: 0,
      },
      scrolled: 0,
      hasMoreData: false,
      updateFunc: null,
      loadingFunc: null,
      showSearchResults: localStorage.getItem('businessOrganizationNameSearch'),
      screenWidth: window.innerWidth, //Used to check for isMobile to avoid duplicated AppLayout component
      useGlobalSearch: this.props?.searchbar?.useGlobalSearch,
      confirmModalProps: null,
      inviteModalProps: null,
    }
    this.handleResults = this.handleResults.bind(this)
    this.getSearchResults = this.getSearchResults.bind(this)
    this.CachedSearch = new CachedSearch(
      this.getSearchResults,
      this.handleResults,
      JSON.parse(localStorage.getItem('searchCache')) || {},
    )

    window.onscroll = () => {
      this.infiniteScroll()
    }
  }

  confirmFriendAddRemove = (needsConfirmation = false, data, tab) => {
    // console.log(needsConfirmation, data);
    this.setState({
      friendRemoveData: data,
    })
    needsConfirmation && $('#confirm_unfriend_popup_modal').modal('show')
  }

  friendAddRemoveHandle = (data) => {
    this.setState({
      usersList: this.state.usersList.map((user) =>
        user.id === data.user_id
          ? { ...user, is_friend_user: data?.is_friend_user, friendship_id: data?.friendship_id }
          : user,
      ),
    })
  }

  itrustitHandle = (data) => {
    this.setState({
      usersList: this.state.usersList.map((user) =>
        user.id === data.id ? { ...user, is_trusted_user: data?.is_trusted_user } : user,
      ),
    })
  }

  confirmitrustitAddRemove = (needsConfirmation = false, data, tab) => {
    // console.log(needsConfirmation, data);
    this.setState({
      itrustitRemoveData: data,
    })
    needsConfirmation && $('#confirm_untrust_popup_modal').modal('show')
  }

  handleWindowSizeChange = () => {
    this.setState({ screenWidth: window.innerWidth })
  }

  componentWillUnmount = () => {
    window.onscroll = null
    window.removeEventListener('resize', this.handleWindowSizeChange)
    this.props.hideUserHeader(false)
    try {
      localStorage.setItem('searchCache', JSON.stringify(this.CachedSearch.cache))
    } catch (error) {
      customLogger(error)
      console.log('Local Storage is full, Please empty data')
      // fires When localstorage gets full
      // you can handle error here or empty the local storage
    }
    this.props.headerSearchBarValue({})
    this.props.headerSearchBarClicked({ value: false, search: '' })
  }

  resetSearch() {
    this.props.headerSearchBarClicked({ value: false, search: '' })
    this.props.headerSearchBarValue({})
    this.props.headerSearchBarReset({
      businessOrganizationNameSearch: true,
    })
    this.setState(
      {
        citySearch: '',
        businessOrganizationNameSearch: '',
        isSearching: false,
        showSearchResults: '',
      },
      () => this.resetHistory(false),
    )
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleWindowSizeChange)
    window.scrollTo(0, 0)
    //        console.log("category_id", this.state.category_id);
    let { resetSearch } = this.props.location.state ? this.props.location.state : false
    // console.log(categoryState);
    if (resetSearch || this.props.searchbarclicked.reset) {
      this.resetSearch()
      return
    }
    console.log(this.props, this.state)
    let business = localStorage.getItem('businessOrganizationNameSearch')
    let citySearch = localStorage.getItem('citySearch') ?? ''
    this.props.headerSearchBarValue({})
    if (citySearch === 'Global' && !this.state.useGlobalSearch) {
      localStorage.removeItem('citySearch')
      citySearch = ''
    }
    if (!this.state.useGlobalSearch && !navigator.geolocation && !citySearch) {
      // console.log('citySearch', this.state.citySearch);
      if (!citySearch) {
        this.setState({ isSearching: true }, () => {
          try {
            showSearchLocationModal()
          } catch (error) {
            showSearchLocationModal()
          }
        })
      } else
        navigator.geolocation.getCurrentPosition(
          (position) => {
            this.setState(
              { latitude: position.coords.latitude, longitude: position.coords.longitude },
              () => {
                if (business) {
                  this.setState(
                    {
                      businessOrganizationNameSearch: business,
                      showSearchResults: business,
                      citySearch: citySearch,
                    },
                    () => {
                      business && citySearch && this.allUsersListRender({})
                    },
                  )
                }
              },
            )
          },
          (error) => {
            this.setState({ isSearching: true }, () => showSearchLocationModal())
          },
        )
    } else {
      if (!this.state.useGlobalSearch && !citySearch) {
        this.setState({ isSearching: true }, () => showSearchLocationModal())
      } else if (business) {
        this.setState(
          {
            businessOrganizationNameSearch: business,
            showSearchResults: business,
            citySearch: citySearch,
          },
          () => {
            business && (this.state.useGlobalSearch || citySearch) && this.allUsersListRender({})
          },
        )
      }
    }
  }

  allUsersListRender = ({ cityOverride, canRetry = true }) => {
    let business = localStorage.getItem('businessOrganizationNameSearch')
    let citySearch = cityOverride ?? localStorage.getItem('citySearch')
    console.log('AllUsersListRender', business, citySearch)
    this.setState(
      {
        originalElasticResults: [],
        usersList: [],
        userIDs: [],
        isSearching: true,
        totalRecords: 0,
        totalPages: 0,
        userIDsOffset: 0,
        markers: [],
        cityOverride,
        searchedAddress: null,
      },
      () =>
        this.CachedSearch.changeQuery({
          business,
          citySearch,
          sortBy: this.state.sortBy,
          userType: this.state.showBy,
          sortOrder:
            this.state.sortBy !== 'name' && !this.state.isClickOrder
              ? 'desc'
              : this.state.sortOrder,
          canRetry,
          cityOverride,
          useGlobalSearch: this.state.useGlobalSearch,
        }),
    )
  }

  async getSearchResults({
    busSearch = '',
    addrSearch = '',
    canRetry,
    cityOverride,
    useGlobalSearch,
  }) {
    let business = busSearch || localStorage.getItem('businessOrganizationNameSearch')
    let citySearch = addrSearch || localStorage.getItem('citySearch')
    // console.log({ business, citySearch });
    // console.log('getSearchResults')
    let allUsersListReqData = {
      view_type: this.state.viewType,
      page: this.state.activePage,
      sort_by: this.state.sortBy,
      user_type: this.state.showBy,
      sort_order: this.state.sortOrder,
      category_id: this.state.category_id,
      category_name: this.state.category_name,
      search_city_string: citySearch ? citySearch : '',
      search_string: business ? business : '',
    }
    if (this.state.sortBy !== 'name' && !this.state.isClickOrder) {
      allUsersListReqData.sort_order = 'desc'
      this.setState({ sortOrder: 'desc' })
    }

    if (useGlobalSearch) allUsersListReqData.search_city_string = 'Global'
    else if (this.state.citySearch && !cityOverride)
      allUsersListReqData.search_city_string = this.state.citySearch

    if (this.state.businessOrganizationNameSearch)
      allUsersListReqData.search_string = this.state.businessOrganizationNameSearch

    if (this.state.latitude) allUsersListReqData.user_latitude = this.state.latitude

    if (this.state.longitude) allUsersListReqData.user_longitude = this.state.longitude
    const self = this
    return runElasticSearch(
      (formatted_address) =>
        this.setState({ searchedAddress: formatted_address, isSearching: true }),
      allUsersListReqData,
      canRetry,
    ).then((response) => {
      // console.log('*******', response.status);
      response?.error && toaster('error', response?.error)
      if (response.status !== 'failed' && response.status !== 'CANCELLED' && !response?.error) {
        console.log('Elastic Search: ', response)
        return canRetry && response?.potentialZipCode
          ? this.allUsersListRender({ cityOverride: response?.potentialZipCode, canRetry: false })
          : response
      } else if (response.status !== 'CANCELLED') {
        console.log('CANCELLED REQUEST!!!')
        self.setState({
          isSearching: false,
          isPageLoading: false,
        })
      }
    })
  }

  handleResults = async ({ response, fromCache = false, cache }) => {
    let userIDs = response.map((res) => res['_id']) || []
    if (
      !fromCache &&
      ['number_of_total_trusted', 'number_of_friends_trusted'].includes(this.state.sortBy)
    ) {
      userIDs = userIDs?.length ? await this.getSortedElasticResults({ userIDs }) : userIDs
    }

    const { cachedBusiness, cachedAddress, cachedUsersList, cachedMarkerArr, cachedInitial } = cache
    this.setState(
      {
        userIDs,
        showSearchResults: cachedBusiness ? cachedBusiness : true,
        originalElasticResults: response,
        userIDsOffset:
          fromCache && cachedUsersList
            ? cachedUsersList.user_list.length
            : userIDs.length < SEARCH_AMT
            ? userIDs.length
            : SEARCH_AMT,
      },
      () => {
        if (fromCache && cachedUsersList) {
          this.updateSearchState({
            usersList: cachedUsersList,
            markerArr: cachedMarkerArr,
            initial: cachedInitial,
            fromCache,
            elasticResults: response,
            loop: response.length !== cachedUsersList.total_records,
          })
        } else {
          let markerArr = []
          let searchedIds = {
            ids: userIDs.slice(0, userIDs.length < SEARCH_AMT ? userIDs.length : SEARCH_AMT),
            orderBy: this.state.sortBy,
            orderType: this.state.sortOrder,
          }
          this.searchForResults({
            searchedIds,
            markerArr,
            initial: true,
            business: cachedBusiness,
            citySearch: cachedAddress,
          })
        }
      },
    )
  }

  searchForResults = async ({
    searchedIds,
    markerArr,
    initial = false,
    business = this.state.businessOrganizationNameSearch,
    citySearch = this.state.citySearch,
    loop = false,
  }) => {
    let usersList = { total_records: 0, total_pages: 1, user_list: [] }
    const { sortBy, showBy, sortOrder, originalElasticResults } = this.state

    if (searchedIds?.ids?.length) {
      let prevSortOptions = { sortBy, sortOrder }
      const response = await profileInfoList(searchedIds)
      const { sortBy: prevSortBy, sortOrder: prevSortOrder } = prevSortOptions

      if (prevSortBy !== sortBy || prevSortOrder !== sortOrder) return

      let user_list = response.data.profileInfo
      usersList = { total_records: user_list.length, total_pages: 1, user_list }

      await Promise.allSettled(
        usersList.user_list.map(async (user, index) => {
          if (user.categories.length === 0 && user.category_name) {
            usersList.user_list[index].categories = {
              category_name: toTitleCase(user.category_name, true),
            }
          }
          let lat = user.latitude
          let lng = user.longitude
          let latLongObj = {
            latitude: parseFloat(lat),
            longitude: parseFloat(lng),
            isOpen: false,
            userDetail: user,
            id: user.id,
          }
          if (!lat || !lng || lat === '' || lng === '') {
            const userWithoutLoc = originalElasticResults.find((u) => u['_source'].id === user.id)
            const { loc } = userWithoutLoc['_source']
            if (loc && loc.length > 1 && loc[0] !== 0 && loc[1] !== 0) {
              ;[lng, lat] = loc
            } else {
              const { full_address } = user ?? {}
              const locationDetails = await getGeometryFromSearchAddress(full_address)
              const { geometry } = locationDetails ?? {}
              if (!!geometry?.location) {
                lat = geometry.location.lat
                lng = geometry.location.lng
              }
              usersList.user_list[index].latitude = lat
              usersList.user_list[index].longitude = lng
            }
            if (!!lat && !!lng && lat !== '' && lng !== '') {
              const latLng = { latitude: lat, longitude: lng }
              latLongObj = { ...latLongObj, ...latLng, userDetail: { ...user, ...latLng } }
            }
          }
          markerArr.push(latLongObj)
        }),
      )
    }
    const options = { sortBy, userType: showBy, sortOrder }
    const data = { business, searchLoc: citySearch, usersList, markerArr, initial }
    this.CachedSearch.updateCache({ data, options })
    this.updateSearchState({ usersList, markerArr, initial, loop })
  }

  updateSearchState = ({
    usersList,
    markerArr,
    initial,
    fromCache = false,
    elasticResults,
    loop = false,
  }) => {
    let searchObj = {
      totalPages: usersList.total_pages,
      totalRecords: usersList.total_records,
      usersList: usersList.user_list,
      markers: markerArr,
      isSearching: false,
      isPageLoading: false,
      hasMoreData: fromCache
        ? elasticResults.length !== usersList.total_records
        : usersList.total_records >= 1,
      searchedAddress: fromCache ? this.state.citySearch : this.state.searchedAddress,
    }
    this.setState(
      (st) => {
        return {
          ...searchObj,
          loadingData: false,
          totalRecords: initial
            ? searchObj.totalRecords
            : (st.totalRecords || 0) + searchObj.totalRecords,
          usersList: initial ? [...searchObj.usersList] : [...st.usersList, ...searchObj.usersList],
        }
      },
      () => {
        if (initial || loop) {
          // console.log('searchingAgain');
          // this.searchAgain({ loop: initial ? searchObj.hasMoreData : this.state.hasMoreData })
        }
      },
    )
  }

  getSortedElasticResults = async ({ userIDs }) => {
    console.log('sortingElasticResults', {
      sortType: this.state.sortBy,
      sortOrder: this.state.sortOrder,
    })
    let searchedIds = {
      ids: userIDs,
      orderBy: this.state.sortBy,
      orderType: this.state.sortOrder,
    }
    const { data } = await sortSearchResults(searchedIds)
    return data.profileInfo.map((user) => user.id)
  }

  sortByChangeHandler = (data) => {
    this.setState(
      {
        sortBySelectedOption: { value: data.value, label: data.label },
        sortBy: data.value,
        isSearching: true,
        activePage: 1,
      },
      () => {
        this.allUsersListRender({})
      },
    )
  }

  showByChangeHandler = (data) => {
    this.setState(
      {
        showBySelectedOption: { value: data.value, label: data.label },
        showBy: data.value,
        isSearching: true,
        activePage: 1,
      },
      () => {
        this.allUsersListRender({})
      },
    )
  }

  sortOrderChangeHandler = (data) => (event) => {
    this.setState(
      {
        sortOrder: data,
        isClickOrder: true,
        isSearching: true,
        activePage: 1,
      },
      () => {
        this.allUsersListRender({})
      },
    )
  }

  componentWillReceiveProps = (nextProps) => {
    let categoryState = nextProps.location.state ? nextProps.location.state : {}
    console.log('will', nextProps, this.state)
    if (Object.keys(nextProps?.searchbarclicked)?.length) {
      this.setState({ useGlobalSearch: nextProps.searchbarclicked.useGlobalSearch })
    }
    let searchedCategory
    if (categoryState.resetSearch || this.props.searchbarclicked.reset) {
      this.resetSearch()
    } else if (categoryState !== undefined && Object.keys(categoryState).length > 0) {
      if (
        categoryState.category_id !== undefined &&
        categoryState.category_id !== this.state.category_id &&
        (!!this.state.citySearch || !!nextProps.searchbarset)
      ) {
        searchedCategory = true
        window.scrollTo(0, 0)
        localStorage.setItem('businessOrganizationNameSearch', categoryState.category_name)
        this.setState(
          {
            citySearch: localStorage.getItem('citySearch') ?? this.state.citySearch,
            category_id: categoryState.category_id,
            category_name: categoryState.category_name,
            isSearching: true,
          },
          () => {
            this.allUsersListRender({})
          },
        )
      } else if (
        nextProps.searchbar.businessOrganizationNameSearch !== categoryState.category_name &&
        !!this.state.citySearch
      ) {
        this.resetHistory()
      }
    }
    if (Object.keys(nextProps.searchbar).length > 0) {
      console.log('search bar length > 0')
      this.props.headerSearchBarValue({})
      nextProps.searchbar.isSearch &&
        this.setState(
          {
            citySearch: nextProps.searchbar.citySearch,
            businessOrganizationNameSearch: nextProps.searchbar.businessOrganizationNameSearch,
            isSearching: nextProps.searchbar.isSearch,
            useGlobalSearch: nextProps.searchbar.useGlobalSearch,
          },
          () => {
            this.props.headerSearchBarValue({
              businessOrganizationNameSearch: nextProps.searchbar.businessOrganizationNameSearch,
              citySearch: nextProps.searchbar.citySearch,
              useGlobalSearch: nextProps.searchbar.useGlobalSearch,
              isSearch: false,
            })
            // console.log('RENDER USERS');
            this.allUsersListRender({})
          },
        )
    }
    if (
      (!searchedCategory &&
        !!nextProps.searchbarset &&
        nextProps.searchbarset !== this.state.citySearch) ||
      nextProps.searchbarlocchanged
    ) {
      this.setState(
        {
          citySearch: !!nextProps.searchbarset
            ? nextProps.searchbarset
            : nextProps.searchbarlocchanged,
        },
        () => {
          console.log('RENDERING USERS', !!nextProps.searchbarset, !!nextProps.searchbarlocchanged)
          !!nextProps.searchbarset && this.allUsersListRender({})
          !!nextProps.searchbarlocchanged && this.props.headerSearchBarLocChange('')
        },
      )
    }
  }

  searchForSuggestion = (suggestion, citySearchStr) => {
    localStorage.setItem('citySearch', citySearchStr)
    this.setState(
      {
        businessOrganizationNameSearch: suggestion,
        showSearchResults: suggestion,
        citySearch: citySearchStr,
        isSearching: true,
      },
      () => {
        this.props.headerSearchBarValue({
          businessOrganizationNameSearch: suggestion,
          citySearch: citySearchStr,
          useGlobalSearch: this.state.useGlobalSearch,
          isSearch: true,
        })
        this.allUsersListRender({})
      },
    )
  }

  onHoverTrustBy = (
    id,
    type,
    user_type,
    total_common_members,
    updateFunc = null,
    loadingFunc = null,
  ) => {
    if (this.state.login_user_id || type === 'total') {
      this.setState({
        isTrustedUserModalShow: true,
        trusteUserData: {
          type: type,
          id: id,
          user_type: user_type,
          total_common_members: total_common_members,
        },
        updateFunc,
        loadingFunc,
      })
    } else {
      this.setState({
        isTrustedUserModalShow: false,
      })
    }
  }

  resetHistory = (shouldSearch = false) => {
    this.props.history.replace({
      pathname: '/search',
      state: {},
    })
    let resetObj = { category_id: '', category_name: '', isSearching: shouldSearch }

    this.setState(resetObj, () => {
      shouldSearch && this.allUsersListRender({})
    })
  }

  infiniteScroll = () => {
    if (this.state.hasMoreData) {
      const winScroll = document.body.scrollTop || document.documentElement.scrollTop

      const height = document.documentElement.scrollHeight - document.documentElement.clientHeight

      const scrolled = winScroll / height
      // console.log(scrolled);
      if (scrolled >= 0.75) {
        // console.log('searchingAgainFromScroll');
        this.searchAgain({ loop: false })
      }
    }
  }

  searchAgain = ({ loop = false }) => {
    this.setState({ hasMoreData: false })
    let searchedIds = {
      ids: this.state.userIDs.slice(
        this.state.userIDsOffset,
        this.state.userIDsOffset + SEARCH_AMT,
      ),
      orderBy: this.state.sortBy,
      orderType: this.state.sortOrder,
    }
    console.log('searchAgain', loop)
    this.setState(
      (st) => ({
        // scrolled,
        loadingData: true,
        userIDsOffset: this.state.userIDsOffset + SEARCH_AMT,
      }),
      () => {
        this.searchForResults({ searchedIds, markerArr: this.state.markers, initial: false, loop })
      },
    )
  }

  setModalProps = (modalProps, needsConfirmation, cleanup = () => {}) => {
    this.setState(
      { confirmModalProps: modalProps.confirm, inviteModalProps: modalProps.invite },
      () => {
        needsConfirmation
          ? $J('#confirm_remove_from_org').modal('show')
          : this.memberAddRemoveYour(
              {
                is_your_member_user: modalProps.confirm.dataYour?.is_your_member_user,
                id: modalProps.confirm.user_id,
                flag: 'member_of_your_organization',
              },
              cleanup,
            )
      },
    )
  }

  memberAddRemoveYour = async (data, cleanup) => {
    let memberAddRemoveReqData = {
      id: data.id,
      member_type: data.flag,
    }
    if (data.is_your_member_user !== 0) {
      const res = await removeMemberCall(memberAddRemoveReqData)
      if (res?.status === 200) {
        cleanup?.()
        // let apiResponseData = response.data
        // self.setState({ is_your_member_user: 0 })
      }
    } else if (data.is_your_member_user !== 2) {
      $J('#invite_modal').modal('show')
    }
  }

  setStatusOfMemberUserYour = (id, status) => {
    this.setState(
      (st) => {
        let usersList = st.usersList
        usersList.filter((user) => user.id === id)[0]['is_your_member_user'] =
          status === 'pending' ? 2 : status === 'accepted' ? 1 : 0
        return {
          usersList,
        }
      },
      () => {},
    )
  }

  render() {
    let isMobile = this.state.screenWidth <= 768
    let resetSearch = this.props.location.state
      ? this.props.location.state.resetSearch || this.props.searchbarclicked.reset
      : false
    // console.log(this.props.location.state.resetSearch, this.props.searchbarclicked.reset);
    if (resetSearch) {
      this.resetSearch()
    }

    const suggestions = this.props?.searchbarelasticresults?.length
      ? this.props.searchbarelasticresults
      : this.props.searchbarval
      ? []
      : ['Business Services', 'Restaurants', 'Education', 'Construction', 'Automotive']
    const { totalRecords, markers, isSearching, loadingData, showSearchResults } = this.state
    let showingFrom = 1
    let showingTo = this.state.userIDsOffset
    if (showingTo > this.state.totalRecords) {
      showingTo = this.state.totalRecords
    }
    const totalUsers = this.state.userIDs.length || 0

    const searchStatus = this.state.showCategoriesList
      ? 'categoriesList'
      : this.props.searchbarclicked.value
      ? 'suggestions'
      : resetSearch ||
        !this.state.citySearch ||
        (!this.props.searchbarclicked.value &&
          !showSearchResults &&
          !isSearching &&
          !this.state.showCategoriesList)
      ? 'categories'
      : (showSearchResults || isSearching) &&
        this.state.citySearch &&
        this.state.businessOrganizationNameSearch &&
        !this.props.searchbarclicked.value
      ? 'searchResults'
      : 'error'
    const totalFilteredRecords = this.state.originalElasticResults.filter((user) =>
      this.state.showBy !== '' ? user['_source'].user_type === this.state.showBy : user['_source'],
    ).length

    const SearchSection = (
      <SearchResultsSection
        isMobile={!!isMobile}
        totalRecords={totalRecords}
        totalFilteredRecords={totalFilteredRecords}
        isSearching={isSearching}
        showingFrom={showingFrom}
        showingTo={showingTo}
        businessOrganizationNameSearch={this.state.businessOrganizationNameSearch}
        citySearch={this.state.citySearch}
        searchedAddress={this.state.searchedAddress}
        userIDs={this.state.userIDs}
        useGlobalSearch={this.state.useGlobalSearch}
        cityOverride={this.state.cityOverride}
        showByOptions={showByOptions}
        sortByOptions={sortByOptions}
        showBySelectedOption={this.state.showBySelectedOption}
        sortBySelectedOption={this.state.sortBySelectedOption}
        sortOrder={this.state.sortOrder}
        showByChangeHandler={this.showByChangeHandler}
        sortOrderChangeHandler={this.sortOrderChangeHandler}
        sortByChangeHandler={this.sortByChangeHandler}
        isPageLoading={this.state.isPageLoading}
        usersList={this.state.usersList}
        userIDsOffset={this.state.userIDsOffset}
        login_user_id={this.state.login_user_id}
        isTrustedUserModalShow={this.state.isTrustedUserModalShow}
        trusteUserData={this.state.trusteUserData}
        updateFunc={this.state.updateFunc}
        loadingFunc={this.state.loadingFunc}
        refresh={() => this.setState()}
        confirmitrustitAddRemove={this.confirmitrustitAddRemove}
        confirmFriendAddRemove={this.confirmFriendAddRemove}
        loadingFriendStatusChange={this.loadingFriendStatusChange}
        loadingitrustitStatusChange={this.loadingitrustitStatusChange}
        onHoverTrustBy={this.onHoverTrustBy}
        trustedUserListBlockShow={(e) => {
          this.setState({ isTrustedUserModalShow: false })
        }}
        itrustitRemoveData={this.state.itrustitRemoveData}
        friendRemoveData={this.state.friendRemoveData}
        totalUsers={totalUsers}
        loadingData={loadingData}
        markers={markers}
        friendAddRemoveHandle={this.friendAddRemoveHandle}
        itrustitHandle={this.itrustitHandle}
        setModalProps={this.setModalProps}
        setStatusOfMemberUserYour={this.setStatusOfMemberUserYour}
      />
    )

    return (
      <span>
        <AppLayout
          routeActive={this.props.location.pathname}
          isDisplayFooter={!(isMobile && searchStatus !== 'searchResults')}
          hideFooter={isMobile && searchStatus !== 'searchResults'}
          hideHeader={isMobile && searchStatus === 'categoriesList'}
        >
          <Helmet>
            <title>{SEARCH_TITLE}</title>
          </Helmet>
          {isMobile ? (
            <div style={{ backgroundColor: '#f5f5f7' }}>
              <AnimatedSearchSection
                suggestions={suggestions}
                searchStatus={searchStatus}
                categoryData={this.state.categoryData}
                showCategoriesList={(catData) =>
                  this.setState({ showCategoriesList: true, categoryData: catData })
                }
                resetSearch={resetSearch}
                hideCategoriesList={() => this.setState({ showCategoriesList: false })}
                headerSearchBarClicked={(data) => this.props.headerSearchBarClicked(data)}
                citySearch={this.state.citySearch}
                searchForSuggestion={this.searchForSuggestion}
                useGlobalSearch={this.state.useGlobalSearch}
              >
                {SearchSection}
              </AnimatedSearchSection>
            </div>
          ) : (
            SearchSection
          )}
          <ConfirmRemoveFromOrgPopupModal {...this.state.confirmModalProps} />
          <InvitePopupModal {...this.state.inviteModalProps} />
        </AppLayout>
        {!this.state.login_user_id ? <LoginModal {...this.props} /> : null}
      </span>
    )
  }
}

Search = reduxForm({
  form: 'searchForm',
  enableReinitialize: true,
})(Search)

function mapStateToProps(state) {
  return {
    searchbar: state.UserReducer.searchbar,
    // searchbarval: state.UserReducer.searchbarval,
    searchbarset: state.UserReducer.searchbarset,
    searchbarlocchanged: state.UserReducer.searchbarlocchanged,
    searchbarclicked: state.UserReducer.searchbarclicked,
    searchbarelasticresults: state.UserReducer.searchbarelasticresults,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      hideUserHeader,
      headerSearchBarSet,
      headerSearchBarReset,
      headerSearchBarValue,
      headerSearchBarClicked,
      headerSearchBarLocChange,
      headerSearchBarSuggestionClicked,
    },
    dispatch,
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Search)
