import React, { useEffect, useState } from 'react'
import { Field, formValueSelector, reduxForm } from 'redux-form'

import * as validation from '../../services/formValidation'
import renderInputField from '../Form/Input'
import { connect } from 'react-redux'
import {
  addUnclaimedBusinessOrOrganization,
  singleCategoryList,
} from '../../services/businessService'
import classNames from 'classnames'
import { toaster } from '../../services/customFunction'
import LoadingOverlay from '../CommonComponents/LoadingOverlay'
import { NETWORK_ERROR_MSG } from '../../services/const'
import AddBusOrgSuccessModal, {
  showAddBusOrgSuccessModal,
} from '../AddBusOrgNew/AddBusOrgSuccessModal'
import AddBusOrgAddressSection from './AddBusOrgAddressSection'
import InviteOwnerToClaim from './InviteOwnerToClaim'
import { SITE_ADDRESS } from '../../services/environment'
import { ROOT_ROUTE } from '../../services/routeConst'
import { useHistory } from 'react-router-dom'
import ReactSelect from '../../services/ReactSelect'

let AddBusOrgAsCustomer = (props) => {
  const {
    busOrgName,
    signupType,
    invalid,
    anyTouched,
    submitFailed,
    formValues,
    currentStep,
    setCurrentStep,
    loginUserData,
  } = props
  const [status, setStatus] = useState('idle')
  const [userData, setUserData] = useState()
  const [nameAddressData, setNameAddressData] = useState()
  const [showOptionalFields, setShowOptionFields] = useState(false)
  const [categoryData, setCategoryData] = useState({
    categoryList: [],
    subCategoryList: [],
    subOfSubCategoryList: [],
  })
  const history = useHistory()

  useEffect(() => {
    if (busOrgName) {
      props.change('business_organization_name', busOrgName)
    }
    categoryListById('category', '0')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    // !!userData?.slug && showAddBusOrgSuccessModal()
    if (!!userData?.slug) {
      toaster(
        'success',
        (signupType === 'business' ? 'Business' : 'Organization') +
          ' was successfully added to the system',
      )
      setCurrentStep('invite_owner')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData?.slug])

  useEffect(() => {
    !!formValues?.category_main_id &&
      categoryListById('subcategory', formValues?.category_main_id?.value)
  }, [formValues?.category_main_id])

  useEffect(() => {
    !!formValues?.sub_category_id &&
      categoryListById('subofsubcategory', formValues?.sub_category_id?.value)
  }, [formValues?.sub_category_id])

  const setCategoryValue = (categories, type) => {
    let matched
    // if (userDetail?.categories?.length > 0) {
    //   userDetail?.categories?.every((cat) => {
    //     matched = categories?.find((c) => c?.value === cat?.category_id)
    //     if (matched) {
    //       props.change(type, { value: cat.category_id, label: cat.category_name })
    //       return false //exits loop
    //     } else return true
    //   })
    // }
    return matched
  }

  const categoryListById = async (type, id = 0) => {
    const response = await singleCategoryList(id)
    if (response?.data?.categories) {
      const categories = response.data?.categories?.map(({ id: value, category_name: label }) => ({
        value,
        label,
      }))
      let obj = {}
      if (type === 'category') {
        setCategoryValue(categories, 'category_main_id')
        obj.categoryList = categories
        obj.subCategoryList = []
        obj.subOfSubCategoryList = []
        setCategoryData((st) => ({ ...st, ...obj }))
        props.change('sub_category_id', null)
        props.change('sub_of_sub_category_id', null)
      } else if (type === 'subcategory') {
        const hasValue = setCategoryValue(categories, 'sub_category_id')
        obj.subCategoryList = categories
        obj.subOfSubCategoryList = []
        setCategoryData((st) => ({ ...st, ...obj }))
        !hasValue && props.change('sub_category_id', null)
        props.change('sub_of_sub_category_id', null)
      } else if (type === 'subofsubcategory') {
        setCategoryValue(categories, 'sub_of_sub_category_id')
        obj.subOfSubCategoryList = categories
        setCategoryData((st) => ({ ...st, ...obj }))
      }
    }
  }

  const handleSubmit = async () => {
    setStatus('submitting')
    if (currentStep === 'name_address') {
      setNameAddressData(formValues)
      setCurrentStep('contact_info')
    } else if (currentStep === 'contact_info') {
      const request = {
        ...nameAddressData,
        country_id: nameAddressData?.country_id?.value,
        state_id: nameAddressData?.state_id?.value,
        city_id: nameAddressData?.city_id?.value,
        phone_no_1: formValues?.phone_no_1,
        website_1: formValues?.website_1,
        user_type: signupType,
        promotion_sponsor_user_id: localStorage.getItem('promotion_sponsor_user_id'),
        isPromotionSignup: props?.onPromotionScreen,
        mobile_no: formValues?.mobile_no,
        email_2: formValues?.email_2,
        facebook: formValues?.facebook,
        fiverr: formValues?.fiverr,
        instagram: formValues?.instagram,
        linkedin: formValues?.linkedin,
        pinterest: formValues?.pinterest,
        tiktok: formValues?.tiktok,
        twitter: formValues?.twitter,
        youtube: formValues?.youtube,
        whatsapp: formValues?.whatsapp,
        paypal: formValues?.paypal,
        venmo: formValues?.venmo,
      }
      const cats = [
        formValues.category_main_id?.value,
        formValues.sub_category_id?.value,
        formValues.sub_of_sub_category_id?.value,
      ]?.filter(Boolean)
      if (cats?.length) request.category_id = cats
      console.log({ request })
      const response = await addUnclaimedBusinessOrOrganization(request)
      if (!response) {
        toaster('error', NETWORK_ERROR_MSG)
      } else if (response?.status === 422) {
      } else if (response?.status === 200 && response?.data?.data?.user) {
        setUserData(response.data.data.user)
      } else {
        toaster('error', response?.data?.message ?? NETWORK_ERROR_MSG)
      }
    } else if (currentStep === 'invite_owner') {
      history.push({ pathname: ROOT_ROUTE + userData?.slug })
    }
    setStatus('idle')
  }

  const canSubmit =
    currentStep === 'invite_owner' ||
    (status !== 'submitting' &&
      (currentStep === 'name_address'
        ? formValues?.business_organization_name && formValues?.country_id
        : currentStep === 'contact_info'
        ? nameAddressData?.business_organization_name && nameAddressData?.country_id
        : false) &&
      !invalid &&
      !(submitFailed && invalid))
  const buttonText =
    currentStep === 'invite_owner'
      ? 'Skip'
      : currentStep === 'contact_info'
      ? formValues?.phone_no_1 || formValues?.website_1
        ? 'Continue'
        : 'Skip'
      : `Continue`
  console.log({ currentStep, status, invalid, submitFailed })
  return (
    <>
      <LoadingOverlay show={status === 'submitting'} />
      <AddBusOrgSuccessModal user={userData} />
      {currentStep === 'name_address' ? (
        <>
          <div className='row fieldflex form-group'>
            <div className='col-md-12'>
              <Field
                name='business_organization_name'
                type='text'
                className='form-control'
                component={renderInputField}
                validate={[validation.required]}
                placeholder={(signupType === 'business' ? 'Business' : 'Organization') + ' Name*'}
                autoComplete='off'
              />
            </div>
          </div>
          <AddBusOrgAddressSection {...props} />
        </>
      ) : currentStep === 'contact_info' ? (
        <>
          <div className='row fieldflex form-group'>
            <div className='col-md-12'>
              <Field
                name='phone_no_1'
                type='text'
                className='form-control'
                component={renderInputField}
                placeholder='Work Phone number'
                autoComplete='off'
              />
            </div>
          </div>
          <div className='row fieldflex form-group'>
            <div className='col-md-12'>
              <Field
                name='mobile_no'
                type='text'
                className='form-control'
                component={renderInputField}
                placeholder='Mobile Phone number'
                autoComplete='off'
              />
            </div>
          </div>
          <div className='row fieldflex form-group'>
            <div className='col-md-12'>
              <Field
                name='email_2'
                type='text'
                className='form-control'
                component={renderInputField}
                placeholder='Email'
                autoComplete='off'
              />
            </div>
          </div>
          <div className='row fieldflex form-group'>
            <div className='col-md-12'>
              <Field
                name='website_1'
                type='text'
                className='form-control'
                component={renderInputField}
                placeholder='Website'
                autoComplete='off'
              />
            </div>
          </div>
          <div className='d-flex ms-3 mb-3'>
            <button
              className='btn itbtn varela p-0 align-self-start fw-bold'
              style={{ color: '#6684f2' }}
              onClick={() => setShowOptionFields((st) => !st)}
            >
              {showOptionalFields ? 'Less...' : 'More...'}
            </button>
          </div>
          {showOptionalFields ? (
            <>
              <div className='row fieldflex form-group'>
                <div className='col-md-12'>
                  <Field
                    name='facebook'
                    type='text'
                    className='form-control'
                    component={renderInputField}
                    placeholder='Facebook'
                    autoComplete='off'
                  />
                </div>
              </div>
              <div className='row fieldflex form-group'>
                <div className='col-md-12'>
                  <Field
                    name='fiverr'
                    type='text'
                    className='form-control'
                    component={renderInputField}
                    placeholder='Fiverr'
                    autoComplete='off'
                  />
                </div>
              </div>
              <div className='row fieldflex form-group'>
                <div className='col-md-12'>
                  <Field
                    name='instagram'
                    type='text'
                    className='form-control'
                    component={renderInputField}
                    placeholder='Instagram'
                    autoComplete='off'
                  />
                </div>
              </div>
              <div className='row fieldflex form-group'>
                <div className='col-md-12'>
                  <Field
                    name='linkedin'
                    type='text'
                    className='form-control'
                    component={renderInputField}
                    placeholder='Linkedin'
                    autoComplete='off'
                  />
                </div>
              </div>
              <div className='row fieldflex form-group'>
                <div className='col-md-12'>
                  <Field
                    name='pinterest'
                    type='text'
                    className='form-control'
                    component={renderInputField}
                    placeholder='Pinterest'
                    autoComplete='off'
                  />
                </div>
              </div>
              <div className='row fieldflex form-group'>
                <div className='col-md-12'>
                  <Field
                    name='tiktok'
                    type='text'
                    className='form-control'
                    component={renderInputField}
                    placeholder='TikTok'
                    autoComplete='off'
                  />
                </div>
              </div>
              <div className='row fieldflex form-group'>
                <div className='col-md-12'>
                  <Field
                    name='twitter'
                    type='text'
                    className='form-control'
                    component={renderInputField}
                    placeholder='Twitter'
                    autoComplete='off'
                  />
                </div>
              </div>
              <div className='row fieldflex form-group'>
                <div className='col-md-12'>
                  <Field
                    name='youtube'
                    type='text'
                    className='form-control'
                    component={renderInputField}
                    placeholder='YouTube'
                    autoComplete='off'
                  />
                </div>
              </div>
              {/* <div className='bg-white blue-upper-line '> */}
              <div className='row fieldflex form-group'>
                <div className='col-md-12'>
                  <div className='for-select'>
                    <Field
                      type='text'
                      component={ReactSelect}
                      name='category_main_id'
                      options={categoryData?.categoryList}
                      placeholder='Main Category >>'
                      isSelect='false'
                    />
                  </div>
                </div>
              </div>
              {categoryData?.subCategoryList?.length > 0 ? (
                <div className='row fieldflex form-group'>
                  <div className='col-md-12'>
                    <div className='for-select'>
                      <Field
                        type='text'
                        component={ReactSelect}
                        name='sub_category_id'
                        options={categoryData?.subCategoryList}
                        placeholder='Sub Category >>'
                        isSelect='false'
                      />
                    </div>
                  </div>
                </div>
              ) : null}
              {categoryData?.subOfSubCategoryList?.length > 1 ? (
                <div className='row fieldflex form-group'>
                  <div className='col-md-12'>
                    <div className='for-select'>
                      <Field
                        type='text'
                        component={ReactSelect}
                        name='sub_of_sub_category_id'
                        options={categoryData?.subOfSubCategoryList}
                        placeholder='Sub-sub Category >>'
                        isSelect='false'
                      />
                    </div>
                  </div>
                </div>
              ) : null}
              {/* </div> */}
            </>
          ) : null}
        </>
      ) : currentStep === 'invite_owner' ? (
        <InviteOwnerToClaim
          loginUserData={loginUserData}
          user={userData}
          profileLink={`${SITE_ADDRESS}invite-claim/${
            loginUserData?.imatrix_id ?? loginUserData?.slug
          }/${userData?.id}`}
        />
      ) : null}
      {/* </AnimateSharedLayout> */}
      {submitFailed && invalid ? (
        <small className='text-danger text-center mb-0'>Please correct the errors above.</small>
      ) : props.anyTouched && invalid ? (
        <small className='text-danger text-center mb-0'>
          Please fill in required fields above.
        </small>
      ) : null}
      <button
        disabled={!canSubmit}
        onClick={canSubmit ? handleSubmit : () => {}}
        style={{ fontSize: 18 }}
        className={classNames(
          'btn',
          'btn-lg',
          'btn-success',
          'btn-block',
          'loginbtn',
          'signup-submit',
          'mt-1',
          !canSubmit ? 'disabled' : '',
        )}
      >
        {buttonText}
      </button>
    </>
  )
}

AddBusOrgAsCustomer = reduxForm({
  form: 'addBusOrgAsCustomerForm',
})(AddBusOrgAsCustomer)

const selector = formValueSelector('addBusOrgAsCustomerForm')
AddBusOrgAsCustomer = connect((state) => {
  const business_organization_name = selector(state, 'business_organization_name')
  const country_id = selector(state, 'country_id')
  const zipcode = selector(state, 'zipcode')
  const state_id = selector(state, 'state_id')
  const city_id = selector(state, 'city_id')
  const area = selector(state, 'area')
  const address_line_1 = selector(state, 'address_line_1')
  const address_line_2 = selector(state, 'address_line_2')
  const phone_no_1 = selector(state, 'phone_no_1')
  const website_1 = selector(state, 'website_1')
  const mobile_no = selector(state, 'mobile_no')
  const email_2 = selector(state, 'email_2')
  const facebook = selector(state, 'facebook')
  const fiverr = selector(state, 'fiverr')
  const instagram = selector(state, 'instagram')
  const linkedin = selector(state, 'linkedin')
  const pinterest = selector(state, 'pinterest')
  const tiktok = selector(state, 'tiktok')
  const twitter = selector(state, 'twitter')
  const youtube = selector(state, 'youtube')
  const whatsapp = selector(state, 'whatsapp')
  const paypal = selector(state, 'paypal')
  const venmo = selector(state, 'venmo')
  const category_main_id = selector(state, 'category_main_id')
  const sub_category_id = selector(state, 'sub_category_id')
  const sub_of_sub_category_id = selector(state, 'sub_of_sub_category_id')
  return {
    formValues: {
      phone_no_1,
      website_1,
      business_organization_name,
      country_id,
      zipcode,
      state_id,
      city_id,
      area,
      address_line_1,
      address_line_2,
      mobile_no,
      email_2,
      facebook,
      fiverr,
      instagram,
      linkedin,
      pinterest,
      tiktok,
      twitter,
      youtube,
      whatsapp,
      paypal,
      venmo,
      category_main_id,
      sub_category_id,
      sub_of_sub_category_id,
    },
    initialValues: {
      business_organization_name: localStorage.getItem('bus_org_name_from_presearch') ?? '',
    },
  }
})(AddBusOrgAsCustomer)

export default AddBusOrgAsCustomer
