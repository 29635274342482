import React from 'react'
import { isMobile } from 'react-device-detect'

const RadioInput = ({
  className = '',
  name,
  value,
  checked,
  onChange,
  type = '',
  disabled,
  disabledText = 'Disabled if no value is set on your profile for this setting',
}) => (
  <div
    className={'d-flex align-items-center justify-content-between ' + className}
    style={{ userSelect: 'none', minHeight: 21 }}
    title={disabled ? disabledText : ''}
  >
    <input
      className='m-0 me-1'
      type='radio'
      id={`${name}_${type}`}
      onChange={onChange}
      name={name}
      value={value}
      checked={checked}
      style={{ cursor: 'pointer' }}
      disabled={disabled}
    />{' '}
    {type !== '1_tap' ? (
      <label htmlFor={`${name}_${type}`} style={{ cursor: 'pointer', opacity: disabled ? 0.5 : 1 }}>
        {type}
      </label>
    ) : null}
  </div>
)

const SettingsSection = ({
  user,
  type,
  settings,
  labels,
  settingNames,
  handleChange,
  oneTap,
  disabled,
  wrapperClass = 'mb-5',
}) => {
  // console.log({ user })
  const hasCount = (names, count = 1) =>
    names?.filter(
      (setting) =>
        settings?.[setting + '_show'] === '1' && !!user?.user_detail?.[setting?.replace('vc_', '')],
    )?.length > count

  const maxSocialsReached =
    ['social_media_sites', 'money/banking'].includes(type) &&
    hasCount(
      [
        'vc_facebook',
        'vc_fiverr',
        'vc_instagram',
        'vc_linkedin',
        'vc_pinterest',
        'vc_tiktok',
        'vc_twitter',
        'vc_youtube',
        'vc_whatsapp',
        'vc_paypal',
        'vc_venmo',
      ],
      7,
    )

  const getSettingVal = (name) => {
    switch (name) {
      case 'vc_city_state_zip':
        return (
          user?.address?.city.name?.length ||
          user?.address?.state.name?.length ||
          user?.address?.zipcode?.length
        )
      case 'vc_address_1':
        return user?.address?.address_line_1?.length
      case 'vc_address_2':
        return user?.address?.address_line_2?.length
      case 'vc_country':
        return user?.address?.country?.name?.length
      default:
        return false
    }
  }

  return (
    <div className={`d-flex ${wrapperClass}`}>
      <div className='d-flex flex-column'>
        <div className='d-flex mb-2 align-items-center ' style={{ lineHeight: 1 }}>
          <h6 className='mb-0'>
            {type.toUpperCase()?.replaceAll('_', ' ').replaceAll('/', ' / ')}
          </h6>
          {type === 'address' ? (
            <>
              <span className='ms-1' style={{ fontSize: 14 }}>
                {' '}
                - {!!isMobile ? 'Show' : 'Show Address'}
              </span>
              <RadioInput
                className='mx-4'
                name='vc_address_show'
                onChange={handleChange}
                checked={settings?.vc_address_show === '1'}
                value='1'
                type='Yes'
              />
              <RadioInput
                className='me-4'
                name='vc_address_show'
                onChange={handleChange}
                checked={settings?.vc_address_show === '0'}
                value='0'
                type='No'
              />
            </>
          ) : null}
        </div>
        <div className='d-flex align-items-center ms-1 ms-md-3'>
          <div className={`d-flex flex-column ${type === 'url' ? 'pe-5 me-1' : 'pe-4'}`}>
            {labels.map((label, idx) => {
              const val =
                settingNames[idx] === 'vc_email'
                  ? user?.email
                  : type === 'address'
                  ? getSettingVal(settingNames[idx])
                  : user?.user_detail?.[settingNames[idx].replace('vc_', '')]
              return (
                <label
                  key={label}
                  style={{
                    opacity:
                      disabled || (settingNames[idx] !== 'vc_email' && !val)
                        ? // || (maxSocialsReached && settings?.[`${settingNames[idx]}_show`] !== '1')
                          0.5
                        : 1,
                  }}
                >
                  {label}
                </label>
              )
            })}
          </div>
          <div className='d-flex flex-column'>
            {settingNames.map((name) => {
              const val =
                name === 'vc_email'
                  ? user?.email
                  : type === 'address'
                  ? getSettingVal(name)
                  : user?.user_detail?.[name.replace('vc_', '')]
              return (
                <RadioInput
                  key={name}
                  className='me-4'
                  name={`${name}_show`}
                  onChange={handleChange}
                  checked={val && settings?.[`${name}_show`] === '1'}
                  value='1'
                  type='Yes'
                  disabledText='Disabled if no value is set on your profile for this setting or if you selected 8 options.'
                  disabled={
                    disabled || (name !== 'vc_email' && !val)
                    // || (maxSocialsReached && settings?.[`${name}_show`] !== '1')
                  }
                />
              )
            })}
          </div>
          <div className='d-flex flex-column'>
            {settingNames.map((name) => {
              const val =
                name === 'vc_email'
                  ? user?.email
                  : type === 'address'
                  ? getSettingVal(name)
                  : user?.user_detail?.[name.replace('vc_', '')]
              return (
                <RadioInput
                  key={name}
                  name={`${name}_show`}
                  onChange={handleChange}
                  checked={!val || settings?.[`${name}_show`] === '0'}
                  value='0'
                  type='No'
                  disabledText='Disabled if no value is set on your profile for this setting or if you selected 8 options.'
                  disabled={
                    disabled || (name !== 'vc_email' && !val)
                    // || (maxSocialsReached && settings?.[`${name}_show`] !== '1')
                  }
                />
              )
            })}
          </div>
        </div>
      </div>
      {!!oneTap ? (
        <div className='d-flex flex-column justify-content-center'>
          <div className='d-flex mb-2'>
            <h6 className='mb-0 text-center'>
              1 tap {type === 'phone' ? 'dial' : type}
              <span className='d-none d-sm-inline'> on your VCard</span>
            </h6>
          </div>
          <div className='d-flex justify-content-center'>
            <div className='d-flex flex-column align-items-center'>
              {settingNames.map((name) => {
                const val =
                  name === 'vc_email' ? user?.email : user?.user_detail?.[name.replace('vc_', '')]
                return (
                  <RadioInput
                    key={name}
                    name={name}
                    onChange={(e) => handleChange(e, type)}
                    checked={!!val && settings?.[`vc_${type}_one_tap`] === name.replace('vc_', '')}
                    type='1_tap'
                    value='1'
                    disabled={disabled || (name !== 'vc_email' && !val)}
                  />
                )
              })}
            </div>
          </div>
        </div>
      ) : null}
    </div>
  )
}

const VCardSettings = ({ user, settings, handleChange }) => {
  // console.log({ user, settings })
  return (
    <>
      <h6 className='mb-4'>Show and Share these on my VCard</h6>
      <SettingsSection
        user={user}
        type='address'
        settings={settings}
        handleChange={handleChange}
        labels={['Street address 1', 'Street address 2', 'City, State, Zip', 'Country']}
        settingNames={['vc_address_1', 'vc_address_2', 'vc_city_state_zip', 'vc_country']}
        disabled={settings.vc_address_show !== '1'}
      />
      <SettingsSection
        user={user}
        type='phone'
        settings={settings}
        handleChange={handleChange}
        labels={['Phone Day', 'Phone Evening', 'Mobile', 'Fax']}
        settingNames={['vc_phone_no_1', 'vc_phone_no_2', 'vc_mobile_no', 'vc_fax_no']}
        oneTap
      />
      <SettingsSection
        user={user}
        type='email'
        settings={settings}
        handleChange={handleChange}
        labels={[`Email${!!isMobile ? '' : ' Address'}`, 'Email 2', 'Email 3', 'Email 4']}
        settingNames={['vc_email', 'vc_email_2', 'vc_email_3', 'vc_email_4']}
        oneTap
      />
      <SettingsSection
        user={user}
        type='url'
        settings={settings}
        handleChange={handleChange}
        labels={['Website 1', 'Website 2']}
        settingNames={['vc_website_1', 'vc_website_2']}
      />
      <SettingsSection
        user={user}
        type='social_media_sites'
        settings={settings}
        handleChange={handleChange}
        labels={[
          'Facebook',
          'Fiverr',
          'Instagram',
          'LinkedIn',
          'Pinterest',
          'Tiktok',
          'Twitter',
          'YouTube',
          'WhatsApp',
        ]}
        settingNames={[
          'vc_facebook',
          'vc_fiverr',
          'vc_instagram',
          'vc_linkedin',
          'vc_pinterest',
          'vc_tiktok',
          'vc_twitter',
          'vc_youtube',
          'vc_whatsapp',
        ]}
      />
      <SettingsSection
        user={user}
        type='money/banking'
        settings={settings}
        handleChange={handleChange}
        labels={['PayPal', 'Venmo']}
        settingNames={['vc_paypal', 'vc_venmo']}
        wrapperClass='mb-3'
      />
      <small style={{ fontSize: 12, fontWeight: 'bold', fontStyle: 'italic' }}>
        NOTE: Your Name, iTRUSTit URL, Invite Link, and Profile Picture will always show
      </small>
    </>
  )
}

export default VCardSettings
