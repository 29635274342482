import React from 'react'
import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import * as route from '../../services/routeConst'
import './Footer.scss'
const Footer = () => {
  useEffect(() => {
    const addClickAndChat = (id) => {
      const script = document.createElement('script')
      script.src = '//server10.clickandchat.com/include.js?domain=https://itrustit.com/'
      script.async = true
      script.onload = scriptOnLoad
      document.getElementById(id)?.append(script)
    }
    if (
      !!document.getElementById('click_and_chat_it_footer') &&
      !document.getElementById('click_and_chat_it_footer')?.children?.length &&
      !document.getElementById('click_and_chat_chat_link')?.children?.length
    ) {
      addClickAndChat('click_and_chat_it_footer')
    } else if (!!document.getElementById('click_and_chat_chat_link')) {
      document
        .getElementById('click_and_chat_it_footer')
        ?.append(document.getElementById('click_and_chat_chat_link'))
    }
  }, [])

  const scriptOnLoad = () => {
    if (window.sWOTrackPage) {
      window.sWOTrackPage()
    }
  }

  return (
    <footer>
      <div className='container'>
        <div className='row footer-top'>
          <div className='col-md-6'>
            <Link className='navbar-brand' to={route.ROOT_ROUTE}>
              <img src={process.env.PUBLIC_URL + '/img/logo.png'} alt='logo' />
            </Link>
          </div>
          <div className='col-md-6 total'>
            {/* <p>
                                <span className="blue">2,342,233 </span>
                                COMMUNITY MEMBERS
                            </p>
                            <p>
                                <span className="blue">15,342,216 </span> TOTAL LISTINGS
                            </p> */}
          </div>
        </div>
        <div className='row  f_links'>
          <div className='col-md-6 d-flex'>
            <div className=''>
              {/* <h4>iTRUSTit Links</h4> */}

              <ul>
                <li>
                  <Link to={route.ABOUT_ROUTE}>About</Link>
                </li>
                <li>
                  <Link to={route.COMMUNITY_ROUTE}>Community</Link>
                </li>

                {/* <li>
                                        <Link to={route.JOBS_ROUTE}>Jobs</Link>
                                    </li> */}
                <li>
                  <Link to={route.FAQ_ROUTE}>FAQ's</Link>
                </li>
                {/* <li>
                                        <a href="javascript:void(0)">Feedback</a>
                                    </li> */}
              </ul>
            </div>
            <div className='sec2'>
              {/* <h4>iTRUSTit for Business Owners</h4> */}
              <ul>
                <li>
                  <Link to={route.CONTACT_ROUTE}>Contact</Link>
                </li>
                {/* <li>
                                        <Link to={route.ADD_BUSINESS_ROUTE}>Claim Your Business</Link>
                                    </li> */}
                <li>
                  <Link to={route.ADVERTISE_ROUTE}> Advertise </Link>
                </li>
                <li>
                  <Link to={route.BETAREADME_ROUTE}>Beta README</Link>
                </li>
                {/* <li>
                                        <Link to={route.VIDEOS_ROUTE}> Videos </Link>
                                    </li> */}
              </ul>
            </div>
          </div>
          {/*
                        <div className="col-md-6">
                            <form>
                                <h4>Email Newsletters</h4>
                                <p className="grey">
                                    Keep me up to date with content, updates, and offers from iTRUSTit
                                </p>
                                <div className="form-group email_sec">
                                    <input type="email" placeholder="Email address" className="form-control" />
                                    <input type="submit" className="submit1" value="Subscribe" />
                                </div>
                            </form>
                        </div>
                            */}
        </div>
        <div className='footer-bottom'>
          <div className='d-flex btm-top justify-content-between'>
            <ul className='d-flex align-items-center w-100 flex-wrap'>
              <li>
                <Link to={route.PRIVACY_ROUTE}> Privacy Policy </Link>
              </li>
              <li>
                <Link to={route.TERMS_ROUTE}> Terms and Conditions </Link>
              </li>
              <li className='ms-auto mt-2 mt-sm-0 me-0'>
                <div id='click_and_chat_it_footer' key='click-and-chat' className='live_chat'>
                  {/* <img src={process.env.PUBLIC_URL + "/img/live_chat_photo.png"} alt="" /> */}
                </div>
              </li>
            </ul>
            <div className='language d-none'>
              <div className='dropup'>
                <span className='grey v_align text-uppercase'>Language:</span>
                <button
                  className='btn btn-default dropdown-toggle'
                  type='button'
                  data-bs-toggle='dropdown'
                >
                  English
                  <span className='caret' />
                </button>
                <ul className='dropdown-menu'>
                  <li>
                    <a href='javascript:void(0)'>Engilsh</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className='btm-btm'>
            <div className='d-flex justify-content-between align-items-center'>
              <p>
                &copy; 2022{' '}
                {/* <div className="blue d-inline-block">
                                        iTRUSTit
                                    </div> */}
                {/* <a href="javascript:void(0)" className="blue">
                                        {" "}
                                        iTRUSTit{" "}
                                    </a> */}
                iTRUSTit Social, Inc. - All Rights Reserved.
              </p>
              <ul>
                <li>
                  <a
                    href='https://www.facebook.com/iTRUSTitSocial'
                    target='_blank'
                    rel='noreferrer'
                  >
                    <i className='fab fa-facebook-f' />
                  </a>
                </li>
                <li>
                  <a href='https://twitter.com/iTRUSTit' target='_blank' rel='noreferrer'>
                    <i className='fab fa-twitter' />
                  </a>
                </li>
                <li>
                  <a
                    href='https://www.youtube.com/channel/UCPNm18iMK8tZEZJHPEMLPJQ'
                    target='_blank'
                    rel='noreferrer'
                  >
                    <i className='fab fa-youtube' />
                  </a>
                </li>
                <li>
                  <a
                    href='https://www.linkedin.com/company/itrustit'
                    target='_blank'
                    rel='noreferrer'
                  >
                    {/* <i className="fab fa-google-plus-g" /> */}
                    <i className='fab fa-linkedin' aria-hidden='true'></i>
                  </a>
                </li>
                <li>
                  <a
                    href='https://www.instagram.com/itrustit.social/'
                    target='_blank'
                    rel='noreferrer'
                  >
                    <i className='fab fa-instagram' />
                  </a>
                </li>
              </ul>
            </div>
            <span style={{ color: '#7d7d7d' }}>v{process.env.REACT_APP_VERSION}</span>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default React.memo(Footer)
