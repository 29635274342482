import { AnimatePresence, motion } from 'framer-motion'
import React, { useEffect, useRef, useState } from 'react'
import { isMobile } from 'react-device-detect'
import ReactGoogleAutocomplete from 'react-google-autocomplete'
import { Field } from 'redux-form'
import { useCurrentLocation } from '../../../hooks/useCurrentLocation'
import ReactSelect from '../../../services/ReactSelect'
import SearchSuggestions from './SearchSuggestions'
import { AnimateSharedLayout } from 'framer-motion'
import PACCurrentLocation from '../../CommonComponents/PACCurrentLocation/PACCurrentLocation'

const SearchInput = React.forwardRef(
  ({ handleChange, searchStr, handleKeyUp, inputProps = {}, className = '' }, ref) => (
    <input
      type='text'
      name='searchStr'
      className={'form-control header-search-input ' + className}
      onChange={handleChange}
      value={searchStr || ''}
      placeholder='Search iTRUSTit…'
      autoComplete='off'
      onKeyUp={handleKeyUp}
      {...inputProps}
      ref={ref}
    />
  ),
)

const LocInput = React.forwardRef(
  (
    {
      onBlur,
      setStateData,
      searchData,
      setSearchData,
      handleChange,
      searchAddress,
      handleKeyUp,
      inputProps = {},
      className = '',
      searchStr,
      stateData,
    },
    inputRef,
  ) => {
    const hasValRef = useRef(!!searchStr)

    useEffect(() => {
      hasValRef.current = searchStr
    }, [searchStr])

    const handlePlaceSelected = (event) => {
      !hasValRef.current && inputRef?.current && inputRef.current?.focus()
      setSearchData?.({
        ...searchData,
        searchStr,
        searchAddress: event?.formatted_address ?? event?.name,
        searchAddressComponents: event?.address_components ?? event?.name, //optional, just to have in case needed
        clickedSuggestion: false,
      })
      setStateData((st) => ({
        ...st,
        searchAddress: event?.formatted_address ?? event?.name,
        searchAddressSelected: event?.formatted_address ?? event?.name,
        searchAddressComponents: event?.address_components ?? event?.name, //optional, just to have in case needed
        clickedSuggestion: false,
      }))
    }

    return (
      <ReactGoogleAutocomplete
        type='text'
        className={'form-control header-search-input ' + className}
        name='searchAddress'
        onChange={handleChange}
        value={searchAddress || ''}
        placeholder='City, State, or Zip Code...'
        autoComplete='off'
        onPlaceSelected={(place, input, autocomplete) => {
          console.log(place, input, autocomplete)
          handlePlaceSelected(place, false)
        }}
        onFocus={(event) => {
          setStateData((st) => ({ ...st, clickedSuggestion: false }))
          event.target.setAttribute('autocomplete', 'off')
        }}
        onBlur={onBlur}
        options={{ types: ['geocode'] }}
        onKeyUp={handleKeyUp}
        {...inputProps}
        // ref={ref}
      />
    )
  },
)

let UserHeaderSearch = (props) => {
  const {
    searchStr,
    searchAddress,
    stateData,
    setStateData,
    handleSubmit,
    isMobileSearch,
    searchStatus,
    setSearchStatus,
    searchData,
    setSearchData,
    inputLengthRef,
    searchSuggestions,
    setSearchSuggestions,
    searchForSuggestions,
    setIsSearching,
    searchDistance,
    setSearchDistance,
  } = props
  const [showLocationField, setShowLocationField] = useState(false)
  const [showSuggestions, setShowSuggestions] = useState(false)
  const searchInputRef = useRef()

  const { currentLocation, getCurrentLocation } = useCurrentLocation()

  useEffect(() => {
    if (!!currentLocation) {
      setStateData((st) => ({ ...st, searchAddress: currentLocation }))
    }
  }, [currentLocation])

  useEffect(() => {
    isMobile && !!stateData?.searchAddressSelected && !!searchStr && handleSearch()
  }, [stateData?.searchAddressSelected])

  const handleChange = (e) => {
    if (inputLengthRef?.current && e?.target?.name === 'searchStr')
      inputLengthRef.current = e.target?.value?.length
    setStateData((st) => ({
      ...st,
      [e.target.name]: e.target.value,
      clickedSuggestion: false,
      blockSearch: true,
    }))
  }

  const handleKeyUp = (e) => {
    if (e.key === 'Enter') {
      handleSearch()
    }
  }

  const handleSearch = () => {
    !!showLocationField && setShowLocationField(false)
    handleSubmit()
  }

  const searchGoBack = () => {
    setSearchData({ searchStr: '', searchAddress: '' })
    setShowLocationField(false)
    setSearchStatus('categories')
    setStateData({ searchStr: '', searchAddress: '', clickedSuggestion: false })
  }

  const handleSearchFocus = () => {
    if (searchStr?.length >= 2) {
      setSearchSuggestions((st) => ({ ...st, fetchedCategories: null }))
      searchForSuggestions(true)
    }
    setSearchStatus('suggestions')
    setShowLocationField(true)
  }

  const handleSearchBlur = (e) => {
    const clickedLocField = e?.relatedTarget?.name === 'searchAddress'
    if (!searchAddress && !clickedLocField) {
      searchStatus !== 'search_results' &&
        !stateData?.clickedSuggestion &&
        setSearchStatus('categories')
      setShowLocationField(false)
    }
  }

  const handleLocFieldBlur = (e) => {
    const clickedSearchField = e?.relatedTarget?.name === 'searchStr'
    if (!searchAddress && !clickedSearchField) {
      searchStatus !== 'search_results' &&
        !stateData?.clickedSuggestion &&
        setSearchStatus('categories')
      setShowLocationField(false)
    }
  }

  return (
    <AnimateSharedLayout>
      <motion.div
        layout
        className={`header-search-section ${
          isMobileSearch
            ? 'mobile-header-search-section d-flex flex-column mb-3'
            : 'd-none d-lg-flex position-relative'
        }`}
      >
        {isMobileSearch ? (
          <>
            <motion.div layout className='position-relative'>
              <SearchInput
                handleChange={handleChange}
                searchStr={searchStr}
                handleKeyUp={handleKeyUp}
                inputProps={{
                  style: { paddingLeft: '2.25rem', zIndex: 1001 },
                  onFocus: handleSearchFocus,
                  onBlur: handleSearchBlur,
                }}
                className='mb-1'
                ref={searchInputRef}
              />
              <div
                className='d-flex align-items-center justify-content-center'
                style={{
                  position: 'absolute',
                  top: 2,
                  left: 2,
                  width: '2.25rem',
                  height: '2.25rem',
                }}
                onClick={searchGoBack}
              >
                <i
                  className={`fa fa-${
                    showLocationField || searchStatus === 'search_results'
                      ? 'chevron-left'
                      : 'search'
                  }`}
                  aria-hidden='true'
                  style={{
                    color: '#544CF9',
                    fontSize: 16,
                  }}
                ></i>
              </div>
              <AnimatePresence>
                {showLocationField ? (
                  <motion.div
                    initial={{ scale: 0 }}
                    animate={{ scale: 1 }}
                    exit={{ scale: 0 }}
                    className='d-flex align-items-center justify-content-center'
                    style={{
                      position: 'absolute',
                      top: 2,
                      right: 2,
                      width: '2.25rem',
                      height: '2.25rem',
                    }}
                    onClick={!!searchStr ? () => handleSearch() : () => {}}
                  >
                    <i
                      className={`fa fa-search`}
                      aria-hidden='true'
                      style={{
                        color: '#544CF9',
                        fontSize: 16,
                      }}
                    ></i>
                  </motion.div>
                ) : null}
              </AnimatePresence>
            </motion.div>
            <AnimatePresence>
              {showLocationField ? (
                <motion.div
                  layout
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  className='position-relative'
                >
                  <LocInput
                    setSearchData={setSearchData}
                    searchData={searchData}
                    setStateData={setStateData}
                    handleChange={handleChange}
                    searchAddress={searchAddress}
                    handleKeyUp={handleKeyUp}
                    onBlur={handleLocFieldBlur}
                    inputProps={{ style: { paddingLeft: '2.25rem' } }}
                    ref={searchInputRef}
                    searchStr={stateData?.searchStr}
                    stateData={stateData}
                  />

                  <i
                    className='fa fa-map-marker-alt'
                    aria-hidden='true'
                    style={{
                      position: 'absolute',
                      top: 12.5,
                      left: 15,
                      color: '#544CF9',
                      fontSize: 16,
                    }}
                  ></i>
                </motion.div>
              ) : null}
            </AnimatePresence>

            <motion.div
              layout
              className='sel1 mt-1'
              style={{ paddingLeft: '0.85rem' }}
              transition={{ delay: 0 }}
            >
              <div
                className={`sort_sel d-flex justify-content-end align-items-center`}
                style={{ gap: 8 }}
              >
                <span
                  className='varela d-block d-sm-inline-block'
                  style={isMobile ? { fontSize: 12 } : {}}
                >
                  Distance:
                </span>
                <Field
                  type='text'
                  className='sel_box for-select search-distance-dropdown'
                  component={ReactSelect}
                  isSelect='false'
                  name='searchDistance'
                  options={[0.5, 1, 2, 5, 10, 15, 25].map((distance) => ({
                    value: `${distance}mi`,
                    label: `${distance} mile${distance === 1 ? '' : 's'}`,
                  }))}
                  onChange={(value) => {
                    setSearchDistance(value)
                  }}
                  valueKey={searchDistance}
                  disabled={!searchAddress}
                />
              </div>
            </motion.div>
          </>
        ) : (
          <>
            <div className='d-flex w-100 position-relative'>
              <SearchInput
                handleChange={handleChange}
                searchStr={searchStr}
                handleKeyUp={handleKeyUp}
                ref={searchInputRef}
                inputProps={{
                  onFocus: () => {
                    if (
                      !Object.keys(searchSuggestions ?? {}).filter(
                        (key) => searchSuggestions[key]?.length,
                      )?.length &&
                      searchStr?.length >= 2
                    ) {
                      searchForSuggestions(true)
                    }
                    setShowSuggestions(true)
                  },
                  onBlur: (e) => {
                    if (
                      !['user-suggestion', 'category-suggestion']?.includes(e?.relatedTarget?.name)
                    ) {
                      setShowSuggestions(false)
                    }
                    // else if (e?.relatedTarget?.name === 'category-suggestion') {
                    //   setStateData((st) => ({
                    //     ...st,
                    //     searchStr: e?.relatedTarget?.id,
                    //     clickedSuggestion: true,
                    //   }))
                    //   handleSubmit()
                    // }
                  },
                }}
              />
              {showSuggestions ? (
                <SearchSuggestions
                  suggestions={searchSuggestions}
                  setStateData={setStateData}
                  setShowSuggestions={setShowSuggestions}
                  setIsSearching={setIsSearching}
                  searchData={searchData}
                  setSearchData={setSearchData}
                />
              ) : null}
            </div>
            <div className='d-flex-flex-column w-100'>
              <div className='d-flex w-100 position-relative mb-1'>
                <LocInput
                  setStateData={setStateData}
                  handleChange={handleChange}
                  searchAddress={searchAddress}
                  handleKeyUp={handleKeyUp}
                  className='user-header-loc-input'
                  searchStr={searchStr}
                />
                <i
                  onClick={
                    !!currentLocation
                      ? () => setStateData((st) => ({ ...st, searchAddress: currentLocation }))
                      : getCurrentLocation
                  }
                  className='fa fa-map-marker-alt'
                  aria-hidden='true'
                  style={{
                    cursor: 'pointer',
                    position: 'absolute',
                    top: 12,
                    right: 16,
                    color: '#544CF9',
                    fontSize: 20,
                  }}
                ></i>
              </div>
              <div className='sel1' style={{ paddingLeft: '0.85rem' }}>
                <div className={`sort_sel d-flex align-items-center`} style={{ gap: 8 }}>
                  <span
                    className='varela d-block d-sm-inline-block'
                    style={isMobile ? { fontSize: 12 } : {}}
                  >
                    Distance:
                  </span>
                  <Field
                    type='text'
                    className='sel_box for-select search-distance-dropdown'
                    component={ReactSelect}
                    isSelect='false'
                    name='searchDistance'
                    options={[0.5, 1, 2, 5, 10, 15, 25].map((distance) => ({
                      value: `${distance}mi`,
                      label: `${distance} mile${distance === 1 ? '' : 's'}`,
                    }))}
                    onChange={(value) => {
                      setSearchDistance(value)
                    }}
                    valueKey={searchDistance}
                    disabled={!searchAddress}
                  />
                </div>
              </div>
            </div>
            <button
              className='form-control header-search-btn'
              onClick={handleSubmit}
              style={{ flexBasis: '8%', minWidth: 60, minHeight: 46, maxHeight: 46 }}
            >
              <i className='fa fa-search' aria-hidden='true'></i>
            </button>
          </>
        )}
      </motion.div>
    </AnimateSharedLayout>
  )
}

export default UserHeaderSearch
