import React, { Component } from 'react'
import { connect } from 'react-redux'
import CompanyDetails from './CompanyDetails/CompanyDetails'
import Gallery from './Gallery/Gallery'
import Reviews from './Reviews/Reviews'

class OverviewTab extends Component {
  constructor(props) {
    super(props)
    //user_id, user

    this.overviewTabCompanyDetailsSectionRef = React.createRef()
    this.overviewTabReviewSectionRef = React.createRef()
    this.overviewTabGallerySectionRef = React.createRef()
  }
  //comment remove
  profileHeader_overview_tab_click_Handle() {
    this.overviewTabCompanyDetailsSectionRef.current.profileHeader_overview_tab_click_Handle()
    this.overviewTabReviewSectionRef.current.profileHeader_overview_tab_click_Handle()
    this.overviewTabGallerySectionRef.current.profileHeader_overview_tab_click_Handle()
  }

  //comment remove
  profileHeader_overview_tab_review_section_Handle() {
    this.overviewTabReviewSectionRef.current.profileHeader_overview_tab_click_Handle()
  }

  showReviewTab2 = (data) => {
    this.props.showReviewTab2(data)
  }

  render() {
    // console.log(this.props.user)
    return (
      <div
        id='overview'
        className={'tab-pane ' + (this.props.activeTab === '#overview' ? 'active' : 'fade')}
      >
        <div className='bg-white'>
          <CompanyDetails
            ref={this.overviewTabCompanyDetailsSectionRef}
            user_id={this.props.user?.id}
            user_settings={this.props?.user?.user_settings}
          />
          <Gallery ref={this.overviewTabGallerySectionRef} user_id={this.props.user.id} />
          <Reviews
            {...this.props}
            ref={this.overviewTabReviewSectionRef}
            user_id={this.props.user?.id}
            user={this.props.user}
            showReviewTab2={this.showReviewTab2}
          />
        </div>
      </div>
    )
  }
}

export default connect(null, null, null, { forwardRef: true })(OverviewTab)
