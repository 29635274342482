import React, { Component } from 'react'
import './Map.scss'
import { GoogleMap, Marker, withGoogleMap, InfoWindow } from 'react-google-maps'
import { MarkerClusterer } from 'react-google-maps/lib/components/addons/MarkerClusterer'
import updateObject from 'immutability-helper'
import { DEFAULT_LONGITUDE, DEFAULT_LATITUDE, GOOGLE_MAP_SEARCH_URL } from '../../services/const'

class MapWithAMarker extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false,
      markerIndex: 0,
      markers: [],
      latMarker: DEFAULT_LATITUDE,
      longMarker: DEFAULT_LONGITUDE,
    }
  }

  onMarkerClustererClick = (markerClusterer) => {
    // console.log("markerClusterer", markerClusterer);
  }

  onToggleOpen = (isOpen, id) => (event) => {
    let markerIndex = this.props.markers.findIndex((obj) => obj.id === id)
    let updatedMarker = updateObject(this.state.markers[markerIndex], {
      isOpen: { $set: !isOpen },
    })

    let newUpdateMarker = updateObject(this.state.markers, {
      $splice: [[markerIndex, 1, updatedMarker]],
    })

    this.setState({ markers: newUpdateMarker })
  }

  componentWillReceiveProps = (nextProps) => {
    this.setState({
      markers: nextProps.markers,
      latMarker:
        nextProps?.markers?.length > 0 && !isNaN(nextProps.markers[0].latitude)
          ? nextProps.markers[0].latitude
          : DEFAULT_LATITUDE,
      longMarker:
        nextProps?.markers?.length > 0 && !isNaN(nextProps.markers[0].longitude)
          ? nextProps.markers[0].longitude
          : DEFAULT_LONGITUDE,
    })
  }

  addressClickHandle = (google_map_full_address) => (event) => {
    // console.log('in addressClickHandle : ', google_map_full_address);
    if (google_map_full_address) {
      window.open(GOOGLE_MAP_SEARCH_URL + google_map_full_address)
    }
  }

  render() {
    const { markers, latMarker, longMarker } = this.state

    return (
      <div className='test111'>
        <GoogleMap
          defaultOptions={this.props.defaultOptions}
          zoom={markers?.length > 0 ? (this.props.isSearchPage ? 11 : 18) : 5}
          defaultZoom={5}
          center={{ lat: latMarker, lng: longMarker }}
        >
          <MarkerClusterer
            onClick={this.onMarkerClustererClick}
            averageCenter
            enableRetinaIcons
            gridSize={60}
          >
            {markers?.length > 0 &&
              markers.map((marker, index) => (
                <Marker
                  key={marker.id}
                  position={{ lat: marker.latitude, lng: marker.longitude }}
                  onClick={this.onToggleOpen(marker.isOpen, marker.id)}
                  defaultIcon={process.env.PUBLIC_URL + '/img/map_marker.png'}
                >
                  {marker.isOpen && (
                    <InfoWindow onCloseClick={this.onToggleOpen(marker.isOpen, marker.id)}>
                      <div className='inner_block'>
                        <div className='map_info_right map_mobile'>
                          <div className='i_username varela mb-0'>
                            {marker.userDetail.user_type === 'individual'
                              ? marker.userDetail.fullname
                              : marker.userDetail.business_organization_name}
                          </div>
                        </div>
                        {/* <div
                                                    className={classNames(
                                                        "map_info_left",
                                                        marker.userDetail.user_type == "organization" && "organization",
                                                        marker.userDetail.user_type == "business" && "business",
                                                        marker.userDetail.user_type == "individual" && "individual"
                                                    )}
                                                >
                                                    <img src={marker.userDetail.profile_picture_medium} />
                                                </div> */}
                        <div className='map_info_right p-0'>
                          {/* <div className="i_review">review</div> */}
                          <div
                            className='i_address'
                            onClick={this.addressClickHandle(marker.userDetail.full_address)}
                          >
                            {marker.userDetail.full_address}
                          </div>
                          {/* <div className="i_number">{marker.userDetail.mobile_no}</div> */}
                          {marker?.userDetail?.phone_no_1 ? (
                            <a className='i_number' href={'tel:' + marker.userDetail.phone_no_1}>
                              {marker.userDetail.phone_no_1} (Phone Day)
                            </a>
                          ) : null}
                          {marker?.userDetail?.phone_no_2 ? (
                            <a className='i_number' href={'tel:' + marker.userDetail.phone_no_2}>
                              {marker.userDetail.phone_no_2} (Phone Evening)
                            </a>
                          ) : null}
                          {marker?.userDetail?.mobile_no ? (
                            <a className='i_number' href={'tel:' + marker.userDetail.mobile_no}>
                              {marker.userDetail.mobile_no} (Mobile)
                            </a>
                          ) : null}
                        </div>
                      </div>
                    </InfoWindow>
                  )}
                </Marker>
              ))}
          </MarkerClusterer>
        </GoogleMap>
      </div>
    )
  }
}

export default withGoogleMap(MapWithAMarker)
