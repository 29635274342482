import React from 'react'

const SocialButton = ({
  href,
  icon,
  disabled,
  disabledTitle = 'This feature is not enabled by the vCard owner for this device.',
  buttonStyles,
  onClick = () => {},
}) => {
  const content =
    typeof icon === 'string' ? (
      <i aria-hidden='true' className={`vcard-social-icon-icon fab fa-${icon}`}></i>
    ) : (
      icon
    )

  const wrapperProps = {
    className: `vcard-social-icon mx-3 ${disabled ? 'is-disabled' : ''}`,
    style: buttonStyles,
  }

  return disabled ? null : href ? (
    <a target='_blank' rel='noopener noreferrer' {...wrapperProps} href={href}>
      {content}
    </a>
  ) : (
    <div {...wrapperProps} onClick={onClick}>
      {content}
    </div>
  )
}

export default SocialButton
