import axios from 'axios'
import { customLogger } from '../utils/CustomLogger'
import * as environemnt from './environment'
import { toaster } from './customFunction'
import { NETWORK_ERROR_MSG } from './const'

export const authenticate = (request) => {
  return axios
    .post(environemnt.BASE_API + 'authenticate', request)
    .then(function (response) {
      return response.data
    })
    .catch(function (error) {
      return error.response
    })
}

export const login = (request) => {
  return axios
    .post(environemnt.BASE_API + environemnt.LOGIN, request)
    .then(function (response) {
      return response.data
    })
    .catch(function (error) {
      customLogger(error)
      return error.response
    })
}

export const linkedInAuth = async (request) => {
  try {
    const response = await axios.post(environemnt.BASE_API + environemnt.LINKEDIN_AUTH, request)
    return response?.data
  } catch (error) {
    customLogger(error)
    return error.response
  }
}

export const socialLogin = (request) => {
  return axios
    .post(environemnt.BASE_API + environemnt.SOCIAL_LOGIN, request)
    .then(function (response) {
      return response.data
    })
    .catch(function (error) {
      customLogger(error)
      return error.response
    })
}

export const signup = (request) => {
  return axios
    .post(environemnt.BASE_API + environemnt.SIGNUP, request)
    .then(function (response) {
      return response.data
    })
    .catch(function (error) {
      customLogger(error)
      return error.response
    })
}

export const forgotPassword = (request) => {
  return axios
    .post(environemnt.BASE_API + environemnt.FORGOT_PASSWORD, request)
    .then(function (response) {
      return response.data
    })
    .catch(function (error) {
      customLogger(error)
      return error.response
    })
}

export const resetPassword = (request) => {
  return axios
    .post(environemnt.BASE_API + environemnt.RESET_PASSWORD + request.resetLink, request)
    .then(function (response) {
      return response.data
    })
    .catch(function (error) {
      customLogger(error)
      return error.response
    })
}

export const updateAddress = async (request) => {
  try {
    const response = await axios.post(environemnt.BASE_API + environemnt.UPDATE_ADDRESS, request, {
      headers: { Authorization: request.token, Accept: 'application/x.iTRUSTit.v1+json' },
    })
    toaster('success', response?.data?.message ?? 'Success!')
    return response
  } catch (error) {
    console.log({ error })
    if (!error?.response === undefined) {
      toaster('error', NETWORK_ERROR_MSG)
    } else if ([401, 403, 500].includes(error?.response?.status)) {
      toaster(
        'error',
        error?.response?.data?.message ??
          'An unexpected error occured. Please try again, and if the issue persists, contact an iTRUSTit Administrator.',
      )
    }
    customLogger(error)
    return { ...error.response, hasError: true }
  }
}

export const userInfo = (token) => {
  return axios
    .get(environemnt.BASE_API + environemnt.PROFILE_INFO, {
      headers: { Authorization: token, Accept: 'application/x.iTRUSTit.v1+json' },
    })
    .then(function (response) {
      return response.data
    })
    .catch(function (error) {
      customLogger(error)
      return error.response
    })
}

export const userProfile = (token) => {
  return axios
    .get(environemnt.BASE_API + environemnt.USER_PROFILE, {
      headers: { Authorization: token, Accept: 'application/x.iTRUSTit.v1+json' },
    })
    .then(function (response) {
      return response.data
    })
    .catch(function (error) {
      customLogger(error)
      return error.response
    })
}

export const updateUserProfile = (request) => {
  return axios
    .post(environemnt.BASE_API + environemnt.UPDATE_USER_PROFILE, request, {
      headers: { Authorization: request.token, Accept: 'application/x.iTRUSTit.v1+json' },
    })
    .then(function (response) {
      return response.data
    })
    .catch(function (error) {
      customLogger(error)
      return error.response
    })
}

export const updateUserCoverImage = (request) => {
  return axios
    .post(environemnt.BASE_API + environemnt.UPDATE_USER_COVER_IMAGE, request, {
      headers: { Authorization: request.token, Accept: 'application/x.iTRUSTit.v1+json' },
    })
    .then(function (response) {
      return response.data
    })
    .catch(function (error) {
      customLogger(error)
      return error.response
    })
}

export const sendEmailConfirmationCall = (request) => {
  let api_url = environemnt.BASE_API + 'send-email-confirmation-link/' + request.id

  return axios
    .post(
      api_url,
      {},
      {
        headers: { Accept: 'application/x.iTRUSTit.v1+json' },
      },
    )
    .then(function (response) {
      return response
    })
    .catch(function (error) {
      if (error === undefined || error.response === undefined) {
        toaster('error', NETWORK_ERROR_MSG)
      } else if ([401, 403, 500].includes(error.response.status)) {
        if (
          typeof error.response !== 'undefined' &&
          typeof error.response.data !== 'undefined' &&
          typeof error.response.data.message !== 'undefined' &&
          error.response.data.message
        ) {
          toaster('error', error.response.data.message)
        } else {
          toaster('error', 'Something went wrong!!!')
        }
      }
      customLogger(error)
      return error.response
    })
}

export const verifyEmailConfirmationTokenCall = (request, handleError) => {
  let api_url = environemnt.BASE_API + 'verify-email-confirmation-token/' + request.token

  return axios
    .post(
      api_url,
      {},
      {
        headers: { Accept: 'application/x.iTRUSTit.v1+json' },
      },
    )
    .then(function (response) {
      return response
    })
    .catch(function (error) {
      if (error === undefined || error.response === undefined) {
        toaster('error', NETWORK_ERROR_MSG)
      } else if ([401, 403, 500].includes(error.response.status)) {
        if (!!error?.response?.data?.message) {
          if (handleError) {
            return error
          }
          toaster('error', error.response.data.message)
        } else {
          toaster('error', 'Something went wrong!!!')
        }
      }
      customLogger(error)
      return error.response
    })
}

export const changePassword = (request) => {
  return axios
    .post(environemnt.BASE_API + 'change-password', request, {
      headers: { Authorization: request.token, Accept: 'application/x.iTRUSTit.v1+json' },
    })
    .then(function (response) {
      return response.data
    })
    .catch(function (error) {
      customLogger(error)
      return error.response
    })
}

export const sendOtp = (request) => {
  const { user_id, type } = request
  return axios
    .post(
      'https://admindev.itrustit.com/api/sendOtp/' + user_id,
      { type },
      {
        headers: { Accept: 'application/x.iTRUSTit.v1+json' },
      },
    )
    .then(function (response) {
      return response.data
    })
    .catch(function (error) {
      return error.response
    })
}

export const verifyOtp = (request) => {
  const { user_id, code, type } = request
  return axios
    .post(
      'https://admindev.itrustit.com/api/verifyOtp/' + user_id,
      { code, type },
      {
        headers: { Accept: 'application/x.iTRUSTit.v1+json' },
      },
    )
    .then(function (response) {
      return response.data
    })
    .catch(function (error) {
      return error.response
    })
}

export const changeSignupEmail = async (request) => {
  const { user_id, ...rest } = request
  let api_url = environemnt.BASE_API + 'change-signup-email/' + user_id

  try {
    const response = await axios.post(api_url, rest, {
      headers: { Accept: 'application/x.iTRUSTit.v1+json' },
    })
    return response
  } catch (error) {
    if (!error?.response) {
      toaster('error', NETWORK_ERROR_MSG)
    } else if ([401, 403, 422, 500].includes(error?.response?.status)) {
      toaster('error', error?.response?.data?.message ?? 'Something went wrong!!!')
    }
    customLogger(error)
    return error.response
  }
}
