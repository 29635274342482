import React from 'react'

const ITeamId = ({ iTeamId, hidden }) => (
  <div className='d-flex flex-column' style={hidden ? { visibility: 'hidden' } : {}}>
    <span className='vcard-iteam-id'>iTeam ID:</span>
    <span className='vcard-iteam-id'>#{iTeamId}</span>
  </div>
)

export default React.memo(ITeamId)
