//not used yet
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import log from './logger'
import * as route from '../services/routeConst'
import { withRouter } from 'react-router-dom'

class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = {
      error: '',
      errorInfo: '',
      hasError: false,
      routeToRedirect: props.routeToRedirect ? props.routeToRedirect : route.HOME_ROUTE,
    }
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error }
  }
  componentDidCatch(error, errorInfo) {
    log.error(error)
    //log.error(errorInfo)
    // eslint-disable-next-line no-console
    console.log({ error, errorInfo })
    this.setState({ errorInfo })
  }
  onClickHandler = () => {
    this.setState({ hasError: false })
    this.props.history.push('/')
  }

  render() {
    const { hasError, errorInfo } = this.state
    if (hasError) {
      return (
        <div className='card my-5'>
          <div className='card-header'>
            <h3 className='varela mb-3'>Oops Something went wrong</h3>
            <div className='white-pane green_line faq d-flex flex-column' style={{ gap: 16 }}>
              <p>There was an error in loading this page.</p>
              <h5 className='mb-0 fw-normal' style={{ color: '#89828c' }}>
                Thank you for helping us find these little bugs! We have alerted our team and we'll
                fix it ASAP!
              </h5>
              <h5 className='fw-bold mb-0' style={{ color: '#57d371' }}>
                What to do now...
              </h5>
              <div className='d-flex flex-column'>
                <h5 className='mb-0 fw-normal' style={{ color: '#89828c' }}>
                  1 - Please try{' '}
                  <span className='fw-bold' style={{ color: '#d85a27' }}>
                    reloading/refreshing
                  </span>{' '}
                  this page.
                </h5>
                <h5 className='mb-0 fw-normal' style={{ color: '#89828c' }}>
                  That usually works to get you where you want to go!
                </h5>
              </div>
              <div className='d-flex flex-column' style={{ gap: 8 }}>
                <h5 className='mb-0 fw-normal' style={{ color: '#89828c' }}>
                  If that does not work:
                </h5>
                <h5 className='mb-0 fw-normal' style={{ color: '#89828c' }}>
                  2 - Click here to go to the{' '}
                  <span
                    style={{ cursor: 'pointer', color: '#0077FF', textDecoration: 'underline' }}
                    onClick={this.onClickHandler}
                  >
                    iTRUSTit home page.
                  </span>
                </h5>
              </div>
            </div>
          </div>
          <div className='card-body'>
            <details className='error-details'>
              <summary>Click for error details</summary>
              {errorInfo && errorInfo.componentStack.toString()}
            </details>
          </div>
        </div>
      )
    }
    return this.props.children
  }
}
ErrorBoundary.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
}

export default withRouter(ErrorBoundary)
