import React from 'react'
import { Link } from 'react-router-dom'
import { kFormatter } from '../../../../services/customFunction'
import { LOGOUT_ROUTE } from '../../../../services/routeConst'
import CoverPhotoLink from './CoverPhotoLink'

const UserDropdownWrapper = ({
  isMobile,
  readNotification,
  userNotificationCount,
  userName,
  userProfileAvatar,
  userCoverImg,
  children,
}) => {
  const content = (
    <ul className='profile_links mt-0'>
      {children}
      <li>
        <Link className='logout' to={LOGOUT_ROUTE}>
          Log out
        </Link>
      </li>
    </ul>
  )

  const dropdownProps = {
    'data-bs-toggle': 'dropdown',
    'aria-haspopup': 'true',
    'aria-expanded': 'false',
  }

  return (
    <>
      <li className={`nav-item dropdown d-lg-none ms-2 p-0`}>
        <div
          className={'nav-link blue_dot_blk dropdown-toggle'}
          id='navbarDropdownMenuLink'
          onClick={readNotification}
          {...dropdownProps}
        >
          <span className={userNotificationCount > 0 ? 'iteam-notif-count' : ''}>
            {kFormatter(userNotificationCount ?? 0)}
          </span>
          <img src={process.env.PUBLIC_URL + '/img/menu-mobile.svg'} alt='logo' />
        </div>

        <div
          className={`dropdown-menu profiledropdown notify header-dropdown-content`}
          aria-labelledby='navbarDropdownMenuLink'
        >
          {content}
        </div>
      </li>
      <li className={`nav-item pe-0 user-avatar-dropdown-large`}>
        <div className='nav-link blue_dot_blk'>
          <div
            className={`user-photo dropdown-toggle`}
            id='navbarDropdownMenuLinkprofile'
            style={{
              background: 'url(' + userProfileAvatar + ') no-repeat',
              cursor: 'pointer',
            }}
            {...dropdownProps}
          ></div>

          <div
            className={`dropdown-menu profiledropdown`}
            aria-labelledby='navbarDropdownMenuLinkprofile'
          >
            <CoverPhotoLink
              coverImg={userCoverImg}
              avatarImg={userProfileAvatar}
              label={userName}
            />
            {content}
          </div>
        </div>
      </li>
    </>
  )
}

export default UserDropdownWrapper
