import axios from 'axios'
import { customLogger } from '../utils/CustomLogger'
import * as environemnt from './environment'
import { userToken, toaster } from './customFunction'

export const makeTrustCall = (request) => {
  return axios
    .post(
      environemnt.BASE_API + 'make-trust/' + request.id,
      {},
      {
        headers: {
          Authorization: userToken(),
          Accept: 'application/x.iTRUSTit.v1+json',
        },
      },
    )
    .then(function (response) {
      return response.data
    })
    .catch(function (error) {
      if (
        typeof error.response !== 'undefined' &&
        typeof error.response.data !== 'undefined' &&
        typeof error.response.data.message !== 'undefined' &&
        error.response.data.message
      ) {
        toaster('error', error.response.data.message)
      }
      customLogger(error)
      return error.response
    })
}

export const removeTrustCall = (request) => {
  return axios
    .post(
      environemnt.BASE_API + 'remove-trust/' + request.id,
      {},
      {
        headers: {
          Authorization: userToken(),
          Accept: 'application/x.iTRUSTit.v1+json',
        },
      },
    )
    .then(function (response) {
      return response.data
    })
    .catch(function (error) {
      if (
        typeof error.response !== 'undefined' &&
        typeof error.response.data !== 'undefined' &&
        typeof error.response.data.message !== 'undefined' &&
        error.response.data.message
      ) {
        toaster('error', error.response.data.message)
      }
      customLogger(error)
      return error.response
    })
}

export const trustListCall = (request) => {
  let api_url = environemnt.BASE_API + 'trust-list'
  if (typeof request.other_user_id !== 'undefined' && request.other_user_id > 0) {
    api_url = environemnt.BASE_API + 'trust-list/' + request.other_user_id
  }

  return axios
    .post(api_url, request, {
      headers: {
        Authorization: userToken(),
        Accept: 'application/x.iTRUSTit.v1+json',
      },
    })
    .then(function (response) {
      return response.data
    })
    .catch(function (error) {
      if (
        typeof error.response !== 'undefined' &&
        typeof error.response.data !== 'undefined' &&
        typeof error.response.data.message !== 'undefined' &&
        error.response.data.message
      ) {
        toaster('error', error.response.data.message)
      }
      customLogger(error)
      return error.response
    })
}

export const trustListCallNew = (request) => {
  let api_url = environemnt.BASE_API + 'trust-list-new'
  if (typeof request.other_user_id !== 'undefined' && request.other_user_id > 0) {
    api_url = environemnt.BASE_API + 'trust-list-new/' + request.other_user_id
  }

  return axios
    .post(api_url, request, {
      headers: {
        Authorization: userToken(),
        Accept: 'application/x.iTRUSTit.v1+json',
      },
    })
    .then(function (response) {
      return response.data
    })
    .catch(function (error) {
      if (
        typeof error.response !== 'undefined' &&
        typeof error.response.data !== 'undefined' &&
        typeof error.response.data.message !== 'undefined' &&
        error.response.data.message
      ) {
        toaster('error', error.response.data.message)
      }
      customLogger(error)
      return error.response
    })
}

export const getTrustListFromIDs = async (request) => {
  let api_url = environemnt.BASE_API + 'trust-list-from-ids'

  try {
    const response = await axios.post(api_url, request, {
      headers: {
        Authorization: userToken(),
        Accept: 'application/x.iTRUSTit.v1+json',
      },
    })
    return response.data
  } catch (error) {
    if (
      typeof error.response !== 'undefined' &&
      typeof error.response.data !== 'undefined' &&
      typeof error.response.data.message !== 'undefined' &&
      error.response.data.message
    ) {
      toaster('error', error.response.data.message)
    }
    customLogger(error)
    return error.response
  }
}

export const makeCommonMemberCall = (id, type, page) => {
  var data = {
    type: type,
    page: page,
  }
  return axios
    .post(environemnt.BASE_API + 'get-common-users-list-by-profile/' + id, data, {
      headers: {
        Authorization: userToken(),
        Accept: 'application/x.iTRUSTit.v1+json',
      },
    })
    .then(function (response) {
      return response.data
    })
    .catch(function (error) {
      customLogger(error)
      return error.response
    })
}
