import React, { useEffect, useState } from 'react'
import $ from 'jquery'
import { reduxForm } from 'redux-form'
import ReactGoogleAutocomplete from 'react-google-autocomplete'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { headerSearchBarLocChange, headerSearchBarSet } from '../../../actions/profile'
import { ROOT_ROUTE } from '../../../services/routeConst'
import { useHistory } from 'react-router'

export const showSearchLocationModal = (type) => {
  $('#search_location_modal')?.modal?.('show')?.attr?.('type', type)
}

const SearchLocationModal = (props) => {
  const history = useHistory()
  const [citySearch, setCitySearch] = useState({ value: '', submitted: false })

  const goBack = React.useCallback(() => {
    console.log('closed modal!', citySearch)
    const hasSubmitted = citySearch.submitted
    setCitySearch({ value: '', submitted: hasSubmitted })
    if (!hasSubmitted) {
      history.push({
        pathname: ROOT_ROUTE,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [citySearch.value, citySearch.submitted])

  useEffect(() => {
    if (citySearch.submitted) {
      localStorage.setItem('citySearch', citySearch.value)
      console.log('CLOSING MODAL FROM SUBMIT')
      props.headerSearchBarSet(citySearch.value, true)
      $('#search_location_modal').modal('hide')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [citySearch.submitted])

  useEffect(() => {
    $('#search_location_modal').off()
    $('#search_location_modal').on('hidden.bs.modal', goBack)
    return () => $('#search_location_modal').off()
  }, [goBack])

  const handleChange = (e) => {
    setCitySearch({ value: e?.target?.value ?? '', submitted: false })
  }

  const handleChangeGoogle = (event) => {
    setCitySearch({ value: event.formatted_address ?? '', submitted: false })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setCitySearch((st) => ({ ...st, submitted: true }))
  }

  const submitButtonDisabled = citySearch?.value.length < 2
  return (
    <div className='modal fade search_location_modal' id='search_location_modal' role='dialog'>
      <div className='modal-dialog'>
        <div className='modal-content'>
          <div className='modal-header align-items-start pb-0'>
            <h5>Location Required</h5>
            <button type='button' className='btn-close' data-bs-dismiss='modal'></button>
          </div>
          <div className='modal-body' style={{ textAlign: 'center' }}>
            <h6 className='text-start'>
              Please enter a location for us to narrow down the search results.
            </h6>
            {/* <h6 className='text-start'>Enter any location</h6> */}
            <div className='que4 mt-3'>
              <div className='form-group me-2 ms-2'>
                <ReactGoogleAutocomplete
                  type='text'
                  className='form-control zip1'
                  name='modal_location'
                  onChange={handleChange}
                  value={citySearch.value || ''}
                  placeholder='City, State, or Zip Code...'
                  onPlaceSelected={handleChangeGoogle}
                  onFocus={(event) => {
                    event.target.setAttribute('autocomplete', 'off')
                  }}
                  options={{ types: ['geocode'] }}
                />
              </div>
            </div>
            <button
              onClick={submitButtonDisabled ? () => {} : handleSubmit}
              className='btn btn-lg btn-success submit_btn mt-2'
              disabled={submitButtonDisabled}
              style={{
                background: '#544cf9',
                color: '#fff',
                borderRadius: 30,
                opacity: submitButtonDisabled ? 0.5 : 1,
                cursor: submitButtonDisabled ? 'not-allowed' : 'pointer',
                fontWeight: 600,
                lineHeight: 0,
                maxWidth: '100%',
                fontSize: 14,
                padding: '15px 20px',
                height: 48,
              }}
            >
              Search
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      headerSearchBarLocChange,
      headerSearchBarSet,
    },
    dispatch,
  )
}

export default reduxForm({
  form: 'searchLocationModalForm',
  enableReinitialize: true,
})(connect(null, mapDispatchToProps)(SearchLocationModal))
