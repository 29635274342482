import React from 'react'
import { reduxForm } from 'redux-form'
import { followChangeStatusCall } from '../../../services/followApiService'
import $J from 'jquery'
import './UnfollowModal.scss'

const ConfirmUnfollowModal = ({ username, data, setLoadingFollowStatusChange, handler }) => {
  const unfollowUser = async () => {
    const request = { follow_id: data.follow_id, status: 'unfollow' }
    setLoadingFollowStatusChange?.(true)

    await followChangeStatusCall(request)
    handler?.({ ...data, id: data.user_id })

    $J('#confirm_unfollow').modal('hide')
  }

  return (
    <div
      className='modal fade login_blk reset_password_blk it-member-modal'
      id='confirm_unfollow'
      role='dialog'
    >
      <div className='modal-dialog'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h2 className='text-start'>{username}</h2>
            <button type='button' className='btn-close' data-bs-dismiss='modal'></button>
          </div>
          <div className='modal-body loginblk'>
            <p className='text-center' style={{ marginBottom: 0 }}>
              Are you sure you want to unfollow this user?
            </p>

            <form
              id='login_form'
              name='login_form'
              className='reset_password'
              method='post'
              style={{ display: 'flex', justifyContent: 'space-between' }}
            >
              <input
                className='resetbtn'
                type='button'
                value='Cancel'
                data-bs-dismiss='modal'
                style={{ marginRight: '1rem' }}
              />
              <input
                className='resetbtn'
                type='button'
                value='Unfollow'
                onClick={unfollowUser}
                style={{ marginLeft: '1rem' }}
              />
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default reduxForm({
  form: 'confirm_unfollow',
})(ConfirmUnfollowModal)
