import React from 'react'
import { reduxForm } from 'redux-form'
import { friendChangeStatusCall } from '../../../services/friendApiService'
import $J from 'jquery'
import './UnfriendPopup.scss'

const ConfirmUnfriendPopupModal = ({ username, data, setLoadingFriendStatusChange, handler }) => {
  const unfriendUser = async () => {
    const request = { friendship_id: data.friendship_id, status: 'unfriend' }

    await friendChangeStatusCall(request)
    setLoadingFriendStatusChange?.(true)
    handler?.({ ...data, id: data.user_id })

    $J('#confirm_unfriend_popup_modal').modal('hide')
  }

  return (
    <div
      className='modal fade login_blk reset_password_blk it-member-modal'
      id='confirm_unfriend_popup_modal'
      role='dialog'
    >
      <div className='modal-dialog'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h2 className='text-start'>{username}</h2>
            <button type='button' className='btn-close' data-bs-dismiss='modal'></button>
          </div>
          <div className='modal-body loginblk'>
            <p className='text-center' style={{ marginBottom: 0 }}>
              Are you sure you want to unfriend this user?
            </p>

            <form
              id='login_form'
              name='login_form'
              className='reset_password'
              method='post'
              style={{ display: 'flex', justifyContent: 'space-between' }}
            >
              <input
                className='resetbtn'
                type='button'
                value='Cancel'
                data-bs-dismiss='modal'
                style={{ marginRight: '1rem' }}
              />
              <input
                className='resetbtn'
                type='button'
                value='UnFriend'
                onClick={unfriendUser}
                style={{ marginLeft: '1rem' }}
              />
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default reduxForm({
  form: 'confirm_unfriend_popup_modal',
})(ConfirmUnfriendPopupModal)
