import React, { Component } from 'react'

import AppLayout from '../AppLayout'
//import SignupOrganization1 from "./Step1/SignupOrganization1";
//import SignupOrganization2 from "./Step2/SignupOrganization2";
import SignupOrganization3 from './Step3/SignupOrganization3'
import { SIGNUP_ORGANIZATION_TITLE } from '../../services/titleConst'
import { Helmet } from 'react-helmet'

class SignupOrganization extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isDisplayOrganization2: false,
      formData: [],
      imatrix_id: props.location.state ? props.location.state.imatrix_id : '',
      fillData: {},
    }
  }
  componentDidMount() {
    let self = this
    if (this.props.location) {
      self.setState({ fillData: this.props.location.state.form_data })
    }
  }
  isForm2 = (data) => {
    this.setState({ isDisplayOrganization2: data })
  }
  formSubmitHandler = (data) => {
    this.setState({ formData: data })
  }

  componentWillUnmount() {
    /*Asana 210824-1*/
    this.setState((prevState) => {
      let formData = Object.assign({}, prevState.formData) // creating copy of state variable formData
      formData = []
      return { formData } // return new object jasper object
    })
  }
  render() {
    return (
      <AppLayout isDisplayFooter={false}>
        <Helmet>
          <title>{SIGNUP_ORGANIZATION_TITLE}</title>
        </Helmet>
        {/* {!this.state.isDisplayOrganization2 ? (
                    <SignupOrganization1
                        isDisplayForm2={this.isForm2}
                        formData={this.formSubmitHandler}
                        imatrix_id={this.state.imatrix_id}
                        fillData={this.state.fillData}
                    />
                ) : (
                    <SignupOrganization2 isDisplayForm2={this.isForm2} form1Data={this.state.formData} />
                )} */}

        <SignupOrganization3
          isDisplayForm2={this.isForm2}
          imatrix_id={this.state.imatrix_id}
          fillData={this.state.fillData}
        />
      </AppLayout>
    )
  }
}

export default SignupOrganization
