import React, { useEffect, useState } from 'react'
import ReactTooltip from 'react-tooltip'
import { IMG_PATH } from '../../../services/const'
import { reactionCreateCall } from '../../../services/socialMediaApiService'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { updateCommentType, updateShareType } from '../../../actions/socialmedia'
import './ReactionTypes.scss'
import { isMobile } from 'react-device-detect'

const ReactionTypes = ({
  handleReaction,
  loginUserReaction,
  reactionData,
  request,
  loginModalProps,
  ...props
}) => {
  //console.log('reactionData:', reactionData)
  const [isHovering, setIsHovering] = useState(false)
  const [isChecked, setIsChecked] = useState(!!reactionData)
  const [currentReactionName, setCurrentReactionName] = useState(
    props?.reactiontypes?.filter((r) => r.id === 1)[0]?.type,
  )
  const [currentReactionIcon, setCurrentReactionIcon] = useState(
    props?.reactiontypes?.filter((r) => r.id === 1)[0]?.icon,
  )
  const isLoggedUser = loginModalProps ? Object.keys(loginModalProps)?.length === 0 : false
  useEffect(() => {
    setCurrentReactionName(
      !!reactionData
        ? props?.reactiontypes?.filter((r) => r.id === reactionData?.reaction_type_id)[0]?.type
        : props?.reactiontypes?.filter((r) => r.id === 1)[0]?.type,
    )
    setCurrentReactionIcon(
      !!reactionData
        ? props?.reactiontypes?.filter((r) => r.id === reactionData?.reaction_type_id)[0]?.icon
        : props?.reactiontypes?.filter((r) => r.id === 1)[0]?.icon,
    )
    setIsChecked(!!reactionData ? true : false)
  }, [reactionData, props?.reactiontypes])

  const createReaction = async (reaction_type_id = 1) => {
    request.reaction_type_id = reaction_type_id
    let response = await reactionCreateCall(request)
    if (response?.status === 200) {
      let data = response?.data?.data
      handleReaction(data)
    }
  }

  //hoovering
  const handleMouseOver = () => {
    setIsHovering(true)
  }

  const handleMouseOut = () => {
    //setIsHovering(false)
  }
  const handleHooverReaction = (data) => {
    createReaction(data?.id)
  }

  const handleMainReaction = (event) => {
    createReaction(reactionData?.reaction_type_id) //deleteReaction(loginUserReaction?.id)
  }

  ///Hover
  const handleHover = () => {
    return (
      <div id='toolTip' className='reactionToolTip'>
        {props?.reactiontypes?.map((reaction, index) => {
          return (
            <button className='reactionButton'>
              <img
                data-tip={reaction?.type}
                data-for='reaction'
                src={IMG_PATH + reaction?.icon}
                className='likeBigIcon'
                onClick={() => handleHooverReaction(reaction)}
                alt=''
              />
            </button>
          )
        })}
        <ReactTooltip
          //data-class='reactionButton'
          id='reaction'
          type='dark'
          effect='float'
          //          getContent={(datatip) => console.log(datatip)}
        />
      </div>
    )
  }

  let hoverClassname = 'hoverButton'
  if (isChecked) hoverClassname = hoverClassname + ' checkedButton'
  return (
    <>
      <div className='hoverReaction'>
        <div className='hoverElement'>{isHovering && handleHover()} </div>

        <div className={hoverClassname}>
          <button
            className='postButtonColumn'
            {...loginModalProps}
            onMouseOver={() => isLoggedUser && handleMouseOver()}
            onMouseOut={() => isLoggedUser && handleMouseOut()}
            onClick={() => isLoggedUser && handleMainReaction()}
          >
            {isChecked ? (
              <img src={IMG_PATH + currentReactionIcon} className='likeIcon link' alt='' />
            ) : (
              <img src={IMG_PATH + 'likeGreyH.svg'} className='likeIcon link' alt='' />
            )}
            <span className='buttonSpan'>{currentReactionName}</span>
          </button>
        </div>
      </div>
    </>
  )
}
function mapStateToProps(state) {
  return {
    reactiontypes: state?.SocialMediaReducer?.metadata?.reaction_types,
  }
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateCommentType,
      updateShareType,
    },
    dispatch,
  )
}
export default connect(mapStateToProps, mapDispatchToProps)(ReactionTypes)
