import React from 'react'
import { SEARCH_AMT } from '../../services/const'
import TrustedUserList from '../CommonComponents/TrustedUserList/TrustedUserList'
import UserListDetail from '../CommonComponents/UserListDetail/UserListDetail'
import SearchPageLoadingIcon from './SearchPageLoadingIcon'

export default function SearchResults({
  totalUsers,
  loadingData,
  isPageLoading,
  usersList,
  userIDsOffset,
  login_user_id,
  isTrustedUserModalShow,
  trusteUserData,
  updateFunc,
  loadingFunc,
  refresh,
  confirmitrustitAddRemove,
  confirmFriendAddRemove,
  loadingFriendStatusChange,
  loadingitrustitStatusChange,
  onHoverTrustBy,
  trustedUserListBlockShow,
  friendAddRemoveHandle,
  itrustitHandle,
  setModalProps,
  setStatusOfMemberUserYour,
}) {
  return (
    <div className='search-section'>
      {!isPageLoading ? (
        usersList.map((user, index) => {
          if (index < userIDsOffset) {
            return (
              <React.Fragment key={user.id}>
                <UserListDetail
                  setModalProps={setModalProps}
                  setStatusOfMemberUserYour={setStatusOfMemberUserYour}
                  index={index}
                  user={user}
                  login_user_id={login_user_id}
                  friendAddRemoveHandle={friendAddRemoveHandle}
                  itrustitHandle={() =>
                    itrustitHandle({
                      id: user?.id,
                      is_trusted_user: 1,
                    })
                  }
                  memberAddRemoveHandle={refresh}
                  confirmitrustitAddRemove={confirmitrustitAddRemove}
                  confirmFriendAddRemove={confirmFriendAddRemove}
                  loadingFriendStatusChange={loadingFriendStatusChange}
                  loadingitrustitStatusChange={loadingitrustitStatusChange}
                  onHoverTrustBy={onHoverTrustBy}
                />
                {index === totalUsers - 1 ? (
                  <h6
                    className='text-center text-muted back-to-top'
                    onClick={() =>
                      window.scrollTo({
                        top: 0,
                        behavior: 'smooth',
                      })
                    }
                  >
                    Back to top
                  </h6>
                ) : null}
              </React.Fragment>
            )
          } else return null
        })
      ) : (
        <SearchPageLoadingIcon className='pagination-loading' />
      )}
      {loadingData && totalUsers !== userIDsOffset - SEARCH_AMT ? (
        <SearchPageLoadingIcon
          label={
            <h4>
              Loading more results <div className='dot-flashing'></div>
            </h4>
          }
        />
      ) : null}
      <TrustedUserList
        isBlockShowSetFalse={trustedUserListBlockShow}
        isBlockShow={isTrustedUserModalShow}
        trusteUserData={trusteUserData}
        updateFunc={updateFunc}
        loadingFunc={loadingFunc}
      ></TrustedUserList>
    </div>
  )
}
