import React, { useEffect, useRef, useState } from 'react'
import AppLayout from '../AppLayout'

import { toaster } from '../../services/customFunction'
import { CONTACT_ROUTE } from '../../services/routeConst'
import { getProfileFromSlug_v2 } from '../../services/userApiService'
import { sendEmailConfirmationCall } from '../../services/authService'
import { Link } from 'react-router-dom'
import ChangeSignupEmailModal, {
  hideChangeSignupEmailModal,
  showChangeSignupEmailModal,
} from '../CommonComponents/ChangeSignupEmailModal/ChangeSignupEmailModal'

const RegisterSuccess = (props) => {
  const [stateData, setStateData] = useState({
    user: props?.location?.state?.user,
    isClaim: props?.location?.state?.isClaim,
    slug: props.match.params.slug,
    isLoading: false,
    loadingText: 'Loading...',
    resendLinkText: 'Click to Resend Verification Email',
    resendTimer: 90,
  })
  const intervalIdRef = useRef()

  useEffect(() => {
    const getUserFromSlug = async () => {
      const response = await getProfileFromSlug_v2({
        slug: stateData.slug,
      })

      response?.status === 200 &&
        setStateData((st) => ({
          ...st,
          user: { ...response?.data?.data?.user_details, ...(st?.isClaim ? st.user : {}) }, //in this order because for claims, user does not have an email, so it comes from location.state
        }))
    }

    window.scrollTo(0, 0)
    getUserFromSlug()
    startTimer()
    return () => {
      hideChangeSignupEmailModal()
      clearInterval(intervalIdRef.current)
    }
  }, [])

  const refreshUserData = async () => {
    const response = await getProfileFromSlug_v2({
      slug: stateData.slug,
    })

    response?.status === 200 &&
      setStateData((st) => ({ ...st, user: response?.data?.data?.user_details }))
  }

  const startTimer = () => {
    intervalIdRef.current = setInterval(timer, 1000)
  }

  const timer = () => {
    setStateData((st) => ({ ...st, resendTimer: st.resendTimer - 1 }))
    if (stateData.resendTimer < 1) {
      clearInterval(intervalIdRef.current)
    }
  }

  const resendActivationLink = async () => {
    setStateData((st) => ({ ...st, isLoading: true }))

    const response = await sendEmailConfirmationCall({
      id: stateData.user.id,
    })
    setStateData((st) => ({ ...st, isLoading: false, resendTimer: 90 }))
    startTimer()

    response?.status === 200 && toaster('success', response.data.message)
  }

  const getResendButtonText = () => {
    const { isLoading, loadingText, resendLinkText } = stateData

    return isLoading
      ? loadingText
      : stateData.resendTimer > 0
      ? `Resend email in ${stateData.resendTimer} ${
          stateData.resendTimer === 1 ? 'second' : 'seconds'
        }`
      : resendLinkText
  }

  const changeEmail = () => {
    showChangeSignupEmailModal()
  }

  const { user, isLoading, resendTimer } = stateData
  const resendBtnDisabled = isLoading || resendTimer > 0
  const isClaim = stateData?.isClaim ?? user?.is_business_claimed === '0'

  return (
    <AppLayout isDisplayFooter={false}>
      <ChangeSignupEmailModal user={user} refreshUserData={refreshUserData} />
      <div className='container'>
        <div className='claim_steps'>
          <div className='success_blk'>
            <p className='strong text-center mt-5 mb-5'>
              <h4 className='fw-bold'>{isClaim ? 'Claim' : 'Sign Up'} Almost Complete</h4>
            </p>
            <div className='mt-3 mb-3'>
              <p>
                We just sent your verification email to: <strong>{user?.email ?? ''}</strong>
              </p>
              <p>
                From: <strong>noreply@mg.itrustitmail.com</strong>
              </p>
              <p className='mb-3'>
                with the subject line{' '}
                <strong>{isClaim ? 'iTRUSTit - Verify Claim' : 'Verify Sign Up'}</strong>
              </p>
              <p>
                (If this is the wrong email{' '}
                <u style={{ color: 'red' }}>
                  <strong style={{ cursor: 'pointer' }} onClick={changeEmail}>
                    click here to change your email
                  </strong>
                </u>
                )
              </p>
            </div>
            <div className='strong my-3'>
              <strong className='d-block mb-3' style={{ fontSize: 16 }}>
                Check your Inbox, Spam and Junk for <u>noreply@mg.itrustitmail.com</u> and be sure
                to whitelist us!
              </strong>
              <strong className='d-block' style={{ fontSize: 16 }}>
                Click ACTIVATE in the email to complete your {isClaim ? 'Claim' : 'Sign Up.'}
              </strong>
            </div>
            <div className='success_text note'>
              <p className='mb-4'>
                <strong>
                  If you do not receive our email within 90 seconds double check your email address
                  above is correct
                </strong>
              </p>
            </div>
            <div className='add_bottom'>
              <div className='text-center'>
                <button
                  onClick={resendBtnDisabled ? () => {} : resendActivationLink}
                  className='mt-3 mb-3 continue resend-btn'
                  style={{
                    border: 'none',
                    outline: 'none',
                    cursor: resendBtnDisabled ? 'not-allowed' : 'pointer',
                    opacity: resendBtnDisabled ? 0.5 : 1,
                  }}
                >
                  {getResendButtonText()}
                </button>
              </div>
            </div>
            <div className='success_text note'>
              <p className='mt-4 mb-4'>
                If you try resending a couple of times and still do not receive the email then
                <Link to={CONTACT_ROUTE} className='ms-1 strong btn blue p-0'>
                  CONTACT US
                </Link>
              </p>
            </div>
            <div className='success_text note'>
              <strong className='mt-4 mb-4 danger'>
                IMPORTANT: DO NOT close this page until after you receive your verification email.
              </strong>
            </div>
            <div className='strong success_text note'>
              <p className='mt-4 mb-4'>
                <strong>Welcome to the iTRUSTit community!</strong>
              </p>
            </div>
          </div>
        </div>
      </div>
    </AppLayout>
  )
}

export default RegisterSuccess
