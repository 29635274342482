export const USER_AUTHENTICATE = 'USER_AUTHENTICATE'
export const USER_DETAIL = 'USER_DETAIL'
export const USER_EXISTS = 'USER_EXISTS'
export const NETWORK_ERROR = 'NETWORK_ERROR'
export const ERROR_MSG = 'ERROR_MSG'
export const AUTH_RESPONSE = 'AUTH_RESPONSE'
export const SIGNUP_STORE = 'SIGNUP_STORE'
export const SIGNUP_TYPE = 'SIGNUP_TYPE'
export const REMOVE_ERROR_MSG = 'REMOVE_ERROR_MSG'
export const IS_LOADING = 'IS_LOADING'
export const EDIT_USER_ACTION = 'EDIT_USER_ACTION'
export const COVER_IMAGE = 'COVER_IMAGE'
export const HEADER_SEARCHBAR = 'HEADER_SEARCHBAR'
export const HIDE_HEADER = 'HIDE_HEADER'
export const HEADER_SEARCHBAR_VAL = 'HEADER_SEARCHBAR_VAL'
export const HEADER_SEARCHBAR_LOC = 'HEADER_SEARCHBAR_LOC'
export const HEADER_SEARCHBAR_CLICKED = 'HEADER_SEARCHBAR_CLICKED'
export const HEADER_SEARCHBAR_SUGGESTION_CLICKED = 'HEADER_SEARCHBAR_SUGGESTION_CLICKED'
export const HEADER_SEARCHBAR_ELASTIC = 'HEADER_SEARCHBAR_ELASTIC'
export const SET_ECHO_CONNECTED = 'SET_ECHO_CONNECTED'
export const LOGIN_HISTORY_EMAIL = 'LOGIN_HISTORY_EMAIL'
export const SOCIAL_LOGIN_FILL_FORM = 'SOCIAL_LOGIN_FILL_FORM'
export const HEADER_SEARCHBAR_RESET = 'HEADER_SEARCHBAR_RESET'
export const HEADER_SEARCHBAR_SET = 'HEADER_SEARCHBAR_SET'
export const REVIEW_USER = 'REVIEW_USER'
//social media
export const SET_METADATA = 'SET_METADATA'
export const SET_REPORTDATA = 'SET_REPORTDATA'
export const RESET_SOCIAL_MEDIA_DATA = 'RESET_SOCIAL_MEDIA_DATA'

export const UPDATE_POST_COMMENT_TYPE = 'UPDATE_POST_COMMENT_TYPE'
export const UPDATE_POST_SHARE_TYPE = 'UPDATE_POST_SHARE_TYPE'
export const UPDATE_POST_SHARE_GROUP = 'UPDATE_POST_SHARE_GROUP'

export const UPDATE_POST_BODY = 'UPDATE_POST_BODY'
export const UPDATE_POST_ATTACHMENTS = 'UPDATE_POST_ATTACHMENTS'

export const UPDATE_SELECTION_TITLE = 'UPDATE_SELECTION_TITLE'

export const SET_POSTS = 'SET_POSTS'
export const POST_POST = 'POST_POST'
export const DELETE_POST = 'DELETE_POST'
export const UPDATE_POST = 'UPDATE_POST'
export const UPDATE_POST_SETTINGS = 'UPDATE_POST_SETTINGS'

export const SET_CURRENT_POST = 'SET_CURRENT_POST'
export const RESET_CURRENT_POST = 'RESET_CURRENT_POST'
export const SET_ISUPDATE = 'SET_ISUPDATE'

export const UPDATE_POST_REACTION = 'UPDATE_POST_REACTION'
export const UPDATE_POST_COMMENT = 'UPDATE_POST_COMMENT'
export const UPDATE_POST_USER_SETTINGS = 'UPDATE_POST_USER_SETTINGS'

export const SET_COMMENTS = 'SET_COMMENTS'
export const DELETE_COMMENT = 'DELETE_COMMENT'
export const POST_COMMENT = 'POST_COMMENT'
export const UPDATE_COMMENT = 'UPDATE_COMMENT'
export const UPDATE_COMMENT_REACTION = 'UPDATE_COMMENT_REACTION'

export const SET_REPLIES = 'SET_REPLIES'
export const DELETE_REPLY = 'DELETE_REPLY'
export const POST_REPLY = 'POST_REPLY'
export const UPDATE_REPLY = 'UPDATE_REPLY'
export const UPDATE_REPLY_REACTION = 'UPDATE_REPLY_REACTION'

// UI reducer types
export const SET_ERRORS = 'SET_ERRORS'
export const LOADING_UI = 'LOADING_UI'
export const CLEAR_ERRORS = 'CLEAR_ERRORS'
export const LOADING_DATA = 'LOADING_DATA'
export const STOP_LOADING_UI = 'STOP_LOADING_UI'

export const SET_SEARCH_DATA = 'SET_SEARCH_DATA'
export const SET_IS_SEARCHING = 'SET_IS_SEARCHING'
export const SET_SEARCH_DISTANCE = 'SET_SEARCH_DISTANCE'
