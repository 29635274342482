import React from 'react'
import ReactDOM from 'react-dom'
import * as $ from 'jquery'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle.min.js'
import 'react-toastify/dist/ReactToastify.css'

import App from './App'
import { unregister } from './registerServiceWorker'

import { Provider } from 'react-redux'
import store from './store'
import { Helmet } from 'react-helmet'
// import moment from 'moment'
window['jQuery'] = window['$'] = $

// const onUpdate = () => {
//   window.localStorage.setItem('onUpdate', moment().format('YYYY-DD-MM HH:mm:ss'))
// }
ReactDOM.render(
  <Provider store={store}>
    <Helmet>
      <title>iTRUSTit</title>
    </Helmet>
    <App />
  </Provider>,
  document.getElementById('root'),
)
unregister()
