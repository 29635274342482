import 'cropperjs/dist/cropper.css'
import './AddPhotosOrVideos.scss'
import React, { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { updatePostAttachments } from '../../../actions/socialmedia'
import { DefaultPlayer as Video } from 'react-html5video'
import 'react-html5video/dist/styles.css'
import { reduxForm } from 'redux-form'

import ErrorMsgRenderer from '../../ErrorMsgRenderer/ErrorMsgRenderer'

import ImageGallery from 'react-image-gallery'

const AddPhotosOrVideos = ({
  title,
  onCloseButtonClick,
  onBackButtonClick,
  postAttachmentFormErrProp = { file: [] },
  postAttachmentsProp = [],
  ...props
}) => {
  const [postAttachmentFormErr, setPostAttachmentFormErr] = useState(postAttachmentFormErrProp)
  const [postAttachments, setPostAttachments] = useState(postAttachmentsProp)
  const [status, setStatus] = useState('idle')
  const [currentIndex, setCurrentIndex] = useState(0)
  const _imageGallery = useRef(null)
  const isVideo = title.includes('video')
  const _onSlide = (index) => {
    setCurrentIndex(index)
  }

  useEffect(() => {
    console.log('useeffects AddPhoto')

    if (postAttachmentsProp?.length === 0) setPostAttachments(props?.postData?.attachments)
  }, [props?.postData?.attachments, postAttachmentsProp])

  const galleryMediaDeleteHandler = () => {
    let attachments = postAttachments
    attachments?.splice(currentIndex, 1)
    setPostAttachments(attachments)
    if (attachments?.length > 0)
      _imageGallery?.current?.slideToIndex(currentIndex === 0 ? currentIndex : currentIndex - 1)
  }

  const addFormSubmitHandler = (event) => {
    event?.preventDefault()
    setPostAttachmentFormErr({ file: [] })
    props?.updatePostAttachments(postAttachments)
    onCloseButtonClick()
  }

  const backButtonHandler = (event) => {
    onBackButtonClick()
  }

  let submitBtnText = 'Done'
  let cancelBtntext = 'Back'
  let attachmentsSize = postAttachments?.length
  let hasContain = attachmentsSize > 0

  return (
    <div
      className='modal  modal-fullscreen overlay-top-layer createpostmodal addphotovideomodal'
      id='wall_add_image_video_modal'
      role='dialog'
    >
      <div className='modal-dialog'>
        <div className='modal-content'>
          <form name='postAttachmentForm' className='choose_file' onSubmit={addFormSubmitHandler}>
            <div className='modal-header'>
              <div className='modalHeaderLeft'>
                <h5 className='modal-title'> {title}</h5>
                {postAttachments?.length > 1 ? (
                  <h6
                    style={{ marginBottom: 0, marginRight: '0.5rem' }}
                    onClick={(e) => {
                      e.preventDefault()
                      galleryMediaDeleteHandler()
                    }}
                  >
                    Delete this image
                  </h6>
                ) : null}
              </div>
              <button type='button' className='btn-close' onClick={backButtonHandler}></button>
            </div>
            <div className='modal-body'>
              {isVideo ? (
                <div className='videoGallery'>
                  <Video
                    controls={['PlayPause', 'Seek', 'Time', 'Volume', 'Fullscreen']}
                    //poster={files[0].vidSrc_thumb}
                    onCanPlayThrough={() => {
                      // Do stuff
                    }}
                    muted={true}
                  >
                    <source src={postAttachments[0]?.original} type='video/mp4' />
                  </Video>
                </div>
              ) : (
                <div className='postAttachmentCont'>
                  <ImageGallery
                    ref={_imageGallery}
                    items={postAttachments}
                    lazyLoad={false}
                    infinite={true}
                    //onClick={_onImageClick}
                    onSlide={_onSlide}
                    //onPause={_onPause}
                    //onImageLoad={_onImageLoad}
                    additionalClass='app-image-postAttachment'
                    useWindowKeyDown={true}
                    slideOnThumbnailOver={true}
                    showBullets={false}
                    showFullscreenButton={true}
                    showPlayButton={false}
                    showThumbnails={false}
                    showIndex={false}
                    showNav={true}
                    isRTL={false}
                    startIndex={0}
                  />
                </div>
              )}
              <ErrorMsgRenderer
                errors={
                  typeof postAttachmentFormErr?.file !== 'undefined' &&
                  Array.isArray(postAttachmentFormErr?.file) &&
                  postAttachmentFormErr?.file?.length > 0
                    ? postAttachmentFormErr?.file
                    : []
                }
              />
            </div>
            <div className='modal-footer justify-content-end'>
              <button
                type='button'
                class='btn btn-orange'
                data-dismiss='modal'
                onClick={backButtonHandler}
              >
                {cancelBtntext}
              </button>

              <button
                type='submit'
                class='btn btn btn-primary'
                data-dismiss='modal'
                disabled={status === 'loading' || !hasContain}
              >
                {submitBtnText}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
function mapStateToProps(state) {
  return {
    postData: state?.SocialMediaReducer?.post,
  }
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updatePostAttachments,
    },
    dispatch,
  )
}
export default reduxForm({
  form: 'wall_add_image_video_modal',
  enableReinitialize: true,
})(connect(mapStateToProps, mapDispatchToProps)(AddPhotosOrVideos))
