import heic2any from 'heic2any'

export const getObjectUrl = async (file) => {
  const isHeicFormat = new RegExp(/\.(heic|heif)$/, 'i')
  let imageSource = URL.createObjectURL(file)
  let imageFile = file
  if (isHeicFormat.test(file.name)) {
    const convertedImage = await heic2any({
      blob: file,
      toType: 'image/png',
    })
    imageSource = URL.createObjectURL(convertedImage)
    imageFile = convertBlobToFile(convertedImage, file.name)
  }
  return { imageFile, imageSource }
}

export const convertBlobToFile = (blob, fileName) => {
  //A Blob() is almost a File() - it's just missing the two properties below which we will add
  blob.lastModifiedDate = new Date()
  blob.name = fileName
  return blob
}

export const getHeightAndWidthFromDataUrl = (dataURL) =>
  new Promise((resolve) => {
    const img = new Image()
    img.onload = () => {
      resolve({
        height: img.height,
        width: img.width,
      })
    }
    img.src = dataURL
  })
