import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import Login from '../../components/Login'
import {
  authenticateAction,
  resetAuthDataAction,
  loginAction,
  socialLoginAction,
  linkedInLoginAction,
  errorMsgAction,
  authResponse,
  authResponseAction,
  isLoadingAction,
  loginHistoryEmail,
  socialLoginFillForm,
  signupAction,
} from '../../actions/auth'

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      authenticateAction,
      resetAuthDataAction,
      loginAction,
      socialLoginAction,
      linkedInLoginAction,
      errorMsgAction,
      authResponse,
      authResponseAction,
      isLoadingAction,
      loginHistoryEmail,
      socialLoginFillForm,
      signupAction,
    },
    dispatch,
  )
  // return {
  //     loginAction: bindActionCreators(loginAction, dispatch),
  // };
}

function mapStateToProps(state) {
  let social_login = state.UserReducer.social_login_form
  return {
    user: state.UserReducer,
    networkerror: state.UserReducer.networkerror,
    errormsg: state.UserReducer.errormsg,
    authresponse: state.UserReducer.authresponse,
    isLoading: state.UserReducer.isLoading,
    social_login_form: social_login,
    initialValues: {
      email: state.UserReducer?.email?.length > 0 ? state.UserReducer?.email : '',
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)
