import React, { Component, Fragment } from 'react'

import * as activityApiService from '../../../services/activityApiService'
import {
  NOTIFICATION_ITEMS_PER_PAGE,
  NOTIFICATION_PAGE_RANGE,
  DEFAULT_PROFILE_PICTURE,
} from '../../../services/const'
import { getUser } from '../../../services/customFunction'
import Pagination from 'react-js-pagination'
import { Link } from 'react-router-dom'
import * as customFunction from '../../../services/customFunction'
import * as route from '../../../services/routeConst'
import { friendChangeStatusCall } from '../../../services/friendApiService'
import { followChangeStatusCall } from '../../../services/followApiService'

class NotificationSection extends Component {
  constructor(props) {
    super(props)

    this.state = {
      login_user_id: getUser().id,
      no_notifications_message: 'Loading...',
      section: this.props.sectionData.section,
      total_records: this.props.sectionData.total_records,
      total_pages: this.props.sectionData.total_pages,
      user_notifications: this.props.sectionData.user_notifications,
      user_settings: this.props.sectionData.user_settings,
      activePage: 1,
    }

    this.handlePageChange = this.handlePageChange.bind(this)
  }

  componentDidMount() {
    // this.notificationsListRender();
  }

  notificationsListRender() {
    let notificationsListReqData = {
      page: this.state.activePage ? this.state.activePage : 1,
      section_type: this.state.section ? this.state.section : 'other',
    }

    const self = this
    activityApiService
      .notificationListBySectionCall(notificationsListReqData)
      .then(function (response) {
        if (
          response &&
          typeof response !== 'undefined' &&
          typeof response.status !== 'undefined' &&
          response.status === 200
        ) {
          let apiResponseData = response.data.data
          let stateData = {
            total_pages: apiResponseData.total_pages,
            total_records: apiResponseData.total_records,
            user_notifications: apiResponseData.user_notifications,
          }

          if (self.state.activePage <= 0) {
            stateData['activePage'] = 1
          }

          self.setState(stateData, () => {})
        } else if (response.status === 201) {
          self.setState({ no_notifications_message: 'No Notifications' })
          self.setState({ user_notifications: [] })
        }
      })
  }

  handlePageChange(pageNumber) {
    this.setState(
      {
        activePage: pageNumber,
      },
      () => {
        this.notificationsListRender()
      },
    )
  }

  handleMemberAccept = (membership_id, action) => (event) => {
    const self = this
    let requestData = { membership_id: membership_id, action: action }
    activityApiService.acceptMemberRequestCall(requestData).then(function (response) {
      if (
        response &&
        typeof response !== 'undefined' &&
        typeof response.status !== 'undefined' &&
        response.status === 200
      ) {
        self.notificationsListRender()
      }
    })
  }

  handleMemberDecline = (membership_id, action) => (event) => {
    const self = this
    let requestData = { membership_id: membership_id, action: action }
    activityApiService.declineMemberRequestCall(requestData).then(function (response) {
      if (
        response &&
        typeof response !== 'undefined' &&
        typeof response.status !== 'undefined' &&
        response.status === 200
      ) {
        self.notificationsListRender()
      }
    })
  }

  handleMemberOkBtn = (notification_id, type) => (event) => {
    const self = this
    let requestData = { notification_id: notification_id, type: type }
    activityApiService.deleteMemberNotificationCall(requestData).then(function (response) {
      if (
        response &&
        typeof response !== 'undefined' &&
        typeof response.status !== 'undefined' &&
        response.status === 200
      ) {
        self.notificationsListRender()
      }
    })
  }

  handleFriendAccept = (friendship_id, action) => (event) => {
    const self = this

    if (friendship_id !== null && friendship_id !== '') {
      let friendChangeStatusReqData = {
        friendship_id: friendship_id,
        status: 'accepted',
      }

      friendChangeStatusCall(friendChangeStatusReqData).then(function (response) {
        if (typeof response.status === 'undefined' || response.status === 200) {
          self.notificationsListRender()
        }
      })
    }
  }

  handleFriendDecline = (friendship_id, action) => (event) => {
    const self = this

    if (friendship_id !== null && friendship_id !== '') {
      let friendChangeStatusReqData = {
        friendship_id: friendship_id,
        status: 'rejected',
      }

      friendChangeStatusCall(friendChangeStatusReqData).then(function (response) {
        if (typeof response.status === 'undefined' || response.status === 200) {
          self.notificationsListRender()
        }
      })
    }
  }

  handleFollowAccept = (follow_id, action) => (event) => {
    const self = this
    console.log({ follow_id })
    if (follow_id !== null && follow_id !== '') {
      let followChangeStatusReqData = {
        follow_id: follow_id,
        status: 'accepted',
      }

      followChangeStatusCall(followChangeStatusReqData).then(function (response) {
        if (typeof response.status === 'undefined' || response.status === 200) {
          self.notificationsListRender()
        }
      })
    }
  }

  handleFollowDecline = (follow_id, action) => (event) => {
    const self = this

    if (follow_id !== null && follow_id !== '') {
      let followChangeStatusReqData = {
        follow_id: follow_id,
        status: 'rejected',
      }

      followChangeStatusCall(followChangeStatusReqData).then(function (response) {
        if (typeof response.status === 'undefined' || response.status === 200) {
          self.notificationsListRender()
        }
      })
    }
  }

  render() {
    const { activePage, total_pages, total_records, user_notifications } = this.state
    let showingFrom = this.props.itemsCountPerPage * (activePage - 1) + 1
    let showingTo = this.props.itemsCountPerPage * activePage
    if (showingFrom > total_records) {
      showingFrom = total_records
    }
    if (showingTo > total_records) {
      showingTo = total_records
    }

    if (total_pages < activePage && total_pages !== 0) {
      this.setState(
        {
          activePage: total_pages,
        },
        () => {
          this.activityListRender()
        },
      )
    }

    return (
      <div>
        {this.state.section === 'member' ? (
          <h4 className='varela'>Join Their Organization Request</h4>
        ) : this.state.section === 'member_request' ? (
          <h4 className='varela'>Membership Requests</h4>
        ) : this.state.section === 'friends' ? (
          <h4 className='varela'>Friend Requests</h4>
        ) : this.state.section === 'follows' ? (
          <h4 className='varela'>Follow Requests</h4>
        ) : this.state.section === 'trust' ? (
          <h4 className='varela'>Trusts</h4>
        ) : this.state.section === 'reviewed' ? (
          <h4 className='varela'>Reviews</h4>
        ) : this.state.section === 'new_iteam_members' ? (
          <h4 className='varela'>New iTeam Members</h4>
        ) : (
          <h4 className='varela'>General Notifications</h4>
        )}

        <div className='bg-white'>
          <div className='activity_blk'>
            {user_notifications.length === 0 ? (
              <div className='text-center'>
                <li>{this.state.no_notifications_message}</li>
              </div>
            ) : (
              <Fragment>
                <div className='i-showing'>
                  Showing {showingFrom} - {showingTo} of {total_records}
                </div>

                <ul>
                  {user_notifications.map((user_notification, index) => {
                    let profileUrl = null
                    let activityListDetail = null
                    let nameToDisplay =
                      user_notification.actor_user.user_type === 'individual'
                        ? user_notification.actor_user.fullname
                        : user_notification.actor_user.business_organization_name
                    let loginUserTypeStr =
                      getUser().user_type === 'business' ? 'Business' : 'Organization'

                    let okButtonClass =
                      ((user_notification.action === 'member' ||
                        user_notification.action === 'member_accepted' ||
                        user_notification.action === 'invite_member_accepted' ||
                        user_notification.action === 'invite_member') &&
                        user_notification.membership_id >= 0) ||
                      user_notification.action === 'trust' ||
                      user_notification.action === 'reviewed' ||
                      user_notification.action === 'new_iteam_member' ||
                      user_notification.action === 'friends' ||
                      user_notification.action === 'follows' ||
                      user_notification.action === 'friend_accepted' ||
                      user_notification.action === 'follow_accepted'
                        ? 'it-ok-show-btn'
                        : ''

                    profileUrl = '' + user_notification.actor_user.slug
                    if (user_notification.action === 'friends') {
                      activityListDetail = (
                        <p>
                          {/* {nameToDisplay}  */}
                          Added you as a friend.
                        </p>
                      )
                    } else if (user_notification.action === 'follows') {
                      activityListDetail = (
                        <p>
                          {/* {nameToDisplay}  */}
                          Followed you.
                        </p>
                      )
                    } else if (user_notification.action === 'friend_pending') {
                      activityListDetail = (
                        <p>
                          {/* {nameToDisplay}  */}
                          Wants to be your friend.
                        </p>
                      )
                    } else if (user_notification.action === 'follow_pending') {
                      activityListDetail = (
                        <p>
                          {/* {nameToDisplay}  */}
                          Wants to follow you.
                        </p>
                      )
                    } else if (user_notification.action === 'friend_accepted') {
                      activityListDetail = (
                        <p>
                          {/* {nameToDisplay}  */}
                          accepted your friend request.
                        </p>
                      )
                    } else if (user_notification.action === 'follow_accepted') {
                      activityListDetail = (
                        <p>
                          {/* {nameToDisplay}  */}
                          accepted your follow request.
                        </p>
                      )
                    } else if (user_notification.action === 'new_iteam_member') {
                      activityListDetail = (
                        <p>
                          {/* {nameToDisplay}  */}
                          Congratulations! You have a new 1st tier iTeam Member.
                          <br />
                          <Link to={profileUrl}>{nameToDisplay}</Link> just joined iTRUSTit with you
                          as their sponsor.
                          <br />
                          Reach out, welcome them to iTRUSTit and show them around!
                          <br />
                          A few key things to show them is how to:
                          <br />
                          <ul>
                            <li style={{ fontSize: 13 }}>
                              Find and <em>Friend</em> other users they know.
                            </li>
                            <li style={{ fontSize: 13 }}>
                              <em>Trust</em> businesses they want to share with their friends.
                            </li>
                            <li style={{ fontSize: 13 }}>Invite others to join.</li>
                            <li style={{ fontSize: 13 }}>
                              Share iTRUSTit on Facebook, LinkedIn and Twitter.
                            </li>
                            <li style={{ fontSize: 13 }}>
                              Find and become a <em>Member</em> of the organizations they are a part
                              of on iTRUSTit.
                            </li>
                          </ul>
                        </p>
                      )
                    } else if (user_notification.action === 'reviewed') {
                      activityListDetail = (
                        <p>
                          {/* {nameToDisplay}  */}
                          reviewed your {loginUserTypeStr}.
                          <Link to={route.PROFILE_ROUTE + '#review'}>Click here</Link> to see the
                          review.
                        </p>
                      )
                    } else if (user_notification.action === 'reviewed_update') {
                      activityListDetail = <p>Record not available. ( Review update )</p>
                    } else if (user_notification.action === 'trust') {
                      activityListDetail = (
                        <p>
                          {/* {nameToDisplay}  */}
                          Trusted your {loginUserTypeStr}.
                        </p>
                      )
                    } else if (user_notification.action === 'member_pending') {
                      activityListDetail = <p>Is requesting to join your organization.</p>
                    } else if (user_notification.action === 'invite_member_pending') {
                      activityListDetail = (
                        <p>
                          {/* {nameToDisplay}  */}
                          is inviting you to be a member of their organization on iTRUSTit.
                        </p>
                      )
                    } else if (user_notification.action === 'member_accepted') {
                      activityListDetail = (
                        <p>
                          {/* {nameToDisplay}  */}
                          has accepted your member request.
                        </p>
                      )
                    } else if (user_notification.action === 'invite_member_accepted') {
                      activityListDetail = (
                        <p>
                          {/* {nameToDisplay}  */}
                          has accepted your member request and is now a member of your Organization
                          on iTRUSTit.
                        </p>
                      )
                    } else if (user_notification.action === 'member') {
                      activityListDetail = (
                        <p>
                          joined your organization on iTRUSTit. They answered{' '}
                          {user_notification.membership !== null &&
                          user_notification.membership.is_outside_member === '1'
                            ? 'Yes'
                            : 'No'}{' '}
                          when asked if they are already affiliated with your organization.
                        </p>
                      )
                    } else if (user_notification.action === 'invite_member') {
                      // activityListDetail = <p>You have been added as a member of {nameToDisplay} on iTRUSTit.</p>
                      activityListDetail = <p>added you as a member.</p>
                    } else {
                      activityListDetail = <p>Data not valid</p>
                    }

                    return (
                      <li
                        key={user_notification.id}
                        className={'it-noti ' + user_notification.actor_user.user_type + '_profile'}
                      >
                        <div href={null} className={'msg_item ' + okButtonClass}>
                          <div className='it-noti-left'>
                            <div className='img_blk' style={{ flexShrink: 0 }}>
                              <img
                                src={
                                  user_notification &&
                                  user_notification.actor_user &&
                                  user_notification.actor_user.user_detail &&
                                  user_notification.actor_user.user_detail.profile_picture_small
                                    ? user_notification.actor_user.user_detail.profile_picture_small
                                    : DEFAULT_PROFILE_PICTURE
                                }
                                alt=''
                              />
                            </div>
                            <h4 className='d-md-none'>
                              <Link to={profileUrl}>{nameToDisplay}</Link>
                            </h4>
                          </div>

                          <div className='msg_content it-profile-noti-part'>
                            <h4 className='d-none d-md-block'>
                              <Link to={profileUrl}>{nameToDisplay}</Link>
                            </h4>
                            <div>
                              <div>
                                {activityListDetail}
                                <p className='time'>
                                  {customFunction.dateStringToTimeSince(
                                    user_notification.created_at,
                                  )}
                                </p>
                                {((user_notification.action === 'member' &&
                                  this.state.user_settings
                                    .is_allow_automatically_accept_membership_requests === '1') ||
                                  (user_notification.action === 'invite_member' &&
                                    this.state.user_settings
                                      .can_add_as_member_of_their_organization === '1' &&
                                    user_notification.membership_id >= 0)) && (
                                  <div>
                                    <p>
                                      To stop automatically accepting membership request change your{' '}
                                      <Link to={route.PROFILE_ROUTE + '#privacy-settings'}>
                                        Settings
                                      </Link>
                                      .
                                    </p>
                                  </div>
                                )}
                                {((user_notification.action === 'member_pending' &&
                                  this.state.user_settings
                                    .is_allow_automatically_accept_membership_requests === '0') ||
                                  (user_notification.action === 'invite_member_pending' &&
                                    this.state.user_settings
                                      .can_add_as_member_of_their_organization === '0' &&
                                    user_notification.membership_id >= 0)) && (
                                  <div>
                                    <p>
                                      To automatically accept membership request change your{' '}
                                      <Link to={route.PROFILE_ROUTE + '#privacy-settings'}>
                                        Settings
                                      </Link>
                                      .
                                    </p>
                                  </div>
                                )}
                                {user_notification.action === 'friends' &&
                                  this.state.user_settings
                                    .is_allow_automatically_accept_friend_requests === '1' &&
                                  user_notification.friendship_id >= 0 && (
                                    <div>
                                      <p>
                                        To stop automatically accepting friend requests, change your{' '}
                                        <Link to={route.PROFILE_ROUTE + '#privacy-settings'}>
                                          Settings
                                        </Link>
                                        .
                                      </p>
                                    </div>
                                  )}
                                {user_notification.action === 'follows' &&
                                  this.state.user_settings
                                    .is_allow_automatically_accept_follow_requests === '1' &&
                                  user_notification.follow_id >= 0 && (
                                    <div>
                                      <p>
                                        To stop automatically accepting follow requests, change your{' '}
                                        <Link to={route.PROFILE_ROUTE + '#privacy-settings'}>
                                          Settings
                                        </Link>
                                        .
                                      </p>
                                    </div>
                                  )}
                                {user_notification.action === 'friend_pending' &&
                                  this.state.user_settings
                                    .is_allow_automatically_accept_friend_requests === '0' &&
                                  user_notification.friendship_id >= 0 && (
                                    <div>
                                      <p>
                                        To automatically accept friend requests change your{' '}
                                        <Link to={route.PROFILE_ROUTE + '#privacy-settings'}>
                                          Settings
                                        </Link>
                                        .
                                      </p>
                                    </div>
                                  )}
                                {user_notification.action === 'follow_pending' &&
                                  this.state.user_settings
                                    .is_allow_automatically_accept_follow_requests === '0' &&
                                  user_notification.follow_id >= 0 && (
                                    <div>
                                      <p>
                                        To automatically accept follow requests, change your{' '}
                                        <Link to={route.PROFILE_ROUTE + '#privacy-settings'}>
                                          Settings
                                        </Link>
                                        .
                                      </p>
                                    </div>
                                  )}
                                {/* { (user_notification.action === "trust" || user_notification.action === "reviewed" || user_notification.action === "friends" || user_notification.action === "friend_accepted") && (
                                                                <div>
                                                                    <p>Clears the notification when the user clicks ‘ok’</p>
                                                                </div>
                                                            )} */}
                              </div>
                              <div>
                                {(user_notification.action === 'member' ||
                                  user_notification.action === 'member_accepted' ||
                                  user_notification.action === 'invite_member_accepted' ||
                                  user_notification.action === 'invite_member') &&
                                  user_notification.membership_id >= 0 && (
                                    <div>
                                      <a
                                        className='btn btn-danger it-ok'
                                        onClick={this.handleMemberOkBtn(
                                          user_notification.id,
                                          user_notification.action,
                                        )}
                                        href='javascript:void(0);'
                                      >
                                        OK
                                      </a>
                                    </div>
                                  )}

                                {(user_notification.action === 'member_pending' ||
                                  user_notification.action === 'invite_member_pending') &&
                                  user_notification.membership_id >= 0 && (
                                    <div>
                                      <a
                                        className='btn btn-success it-accept'
                                        onClick={this.handleMemberAccept(
                                          user_notification.membership_id,
                                          user_notification.action,
                                        )}
                                        href='javascript:void(0);'
                                      >
                                        Accept
                                      </a>
                                      <a
                                        className='btn btn-danger it-decline'
                                        onClick={this.handleMemberDecline(
                                          user_notification.membership_id,
                                          user_notification.action,
                                        )}
                                        href='javascript:void(0);'
                                      >
                                        Decline
                                      </a>
                                    </div>
                                  )}

                                {(user_notification.action === 'trust' ||
                                  user_notification.action === 'reviewed' ||
                                  user_notification.action === 'new_iteam_member' ||
                                  user_notification.action === 'friends' ||
                                  user_notification.action === 'friend_accepted' ||
                                  user_notification.action === 'follows' ||
                                  user_notification.action === 'follow_accepted') && (
                                  <div>
                                    <a
                                      className='btn btn-danger it-ok'
                                      onClick={this.handleMemberOkBtn(
                                        user_notification.id,
                                        user_notification.action,
                                      )}
                                      href='javascript:void(0);'
                                    >
                                      OK
                                    </a>
                                  </div>
                                )}

                                {user_notification.action === 'friend_pending' && (
                                  <div>
                                    <a
                                      className='btn btn-success it-accept'
                                      onClick={this.handleFriendAccept(
                                        user_notification.friendship_id,
                                        user_notification.action,
                                      )}
                                      href='javascript:void(0);'
                                    >
                                      Accept
                                    </a>
                                    <a
                                      className='btn btn-danger it-decline'
                                      onClick={this.handleFriendDecline(
                                        user_notification.friendship_id,
                                        user_notification.action,
                                      )}
                                      href='javascript:void(0);'
                                    >
                                      Decline
                                    </a>
                                  </div>
                                )}
                                {user_notification.action === 'follow_pending' && (
                                  <div>
                                    <a
                                      className='btn btn-success it-accept'
                                      onClick={this.handleFollowAccept(
                                        user_notification.follow_id,
                                        user_notification.action,
                                      )}
                                      href='javascript:void(0);'
                                    >
                                      Accept
                                    </a>
                                    <a
                                      className='btn btn-danger it-decline'
                                      onClick={this.handleFollowDecline(
                                        user_notification.follow_id,
                                        user_notification.action,
                                      )}
                                      href='javascript:void(0);'
                                    >
                                      Decline
                                    </a>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    )
                  })}
                </ul>
                <div>
                  <Pagination
                    activePage={activePage}
                    itemsCountPerPage={this.props.itemsCountPerPage}
                    totalItemsCount={total_records}
                    pageRangeDisplayed={this.props.pageRangeDisplayed}
                    onChange={this.handlePageChange}
                  />
                </div>
              </Fragment>
            )}
          </div>
        </div>
      </div>
    )
  }
}

NotificationSection.defaultProps = {
  itemsCountPerPage: NOTIFICATION_ITEMS_PER_PAGE,
  pageRangeDisplayed: NOTIFICATION_PAGE_RANGE,
}

export default NotificationSection
