import React from 'react'
import { Link } from 'react-router-dom'
import { DEFAULT_PROFILE_PICTURE } from '../../../../services/const'
import { dateStringToTimeSince, kFormatter } from '../../../../services/customFunction'

const MessagesDropdown = ({ chat_users_list, chat_users_online_list }) => {
  const totalUnreadMessages = chat_users_list?.reduce?.(
    (acc, curr) => (acc += curr?.total_unread),
    0,
  )
  return (
    <li className='nav-item'>
      <div
        className='nav-link dropdown-toggle'
        href={null}
        data-bs-toggle='dropdown'
        aria-haspopup='true'
        id='navbarDropdownMenuLink'
        aria-expanded='false'
      >
        <span className={totalUnreadMessages !== 0 ? 'iteam-notif-count' : ''}>
          {totalUnreadMessages !== 0 ? kFormatter(totalUnreadMessages) : ''}
        </span>
        <img src={process.env.PUBLIC_URL + '/img/new_icons/message.svg'} width='25' alt='logo' />
      </div>
      <div
        className='dropdown-menu header-dropdown-content'
        aria-labelledby='navbarDropdownMenuLink'
      >
        <div className='dropdown-header'>MESSAGES</div>
        <ul className='msg_list mb-5'>
          {chat_users_list.map(
            ({
              group_chat_id,
              id,
              user_type,
              fullname,
              business_organization_name,
              user_detail,
              last_chat,
              last_chat_at,
              users,
              total_unread,
            }) => {
              let nameToDisplay = users
                ? users.map((u) => u?.fullname).join(', ')
                : user_type === 'individual'
                ? fullname
                : business_organization_name

              return (
                <li key={group_chat_id ? `gc-${group_chat_id}` : id}>
                  <Link
                    to={
                      process.env.PUBLIC_URL +
                      '/message/' +
                      (group_chat_id ? `g/${group_chat_id}` : id)
                    }
                    className='msg_item'
                  >
                    <div className='img_blk'>
                      <img
                        src={user_detail?.profile_picture_small ?? DEFAULT_PROFILE_PICTURE}
                        alt=''
                      />
                    </div>
                    <div
                      className={
                        'bluedot-width ' +
                        (chat_users_online_list.indexOf(id) !== -1 ? ' bluedot ' : '')
                      }
                    />
                    <div className='msg_content'>
                      <h4
                        className='chat-list-content-name'
                        style={{ fontWeight: total_unread > 0 ? 'bold' : 'normal' }}
                      >
                        {nameToDisplay}
                      </h4>
                      <p>{last_chat}</p>
                      <p className='time'>{dateStringToTimeSince(last_chat_at)}</p>
                    </div>
                  </Link>
                </li>
              )
            },
          )}

          {chat_users_list.length === 0 && <div className='it-no-data'>No messages</div>}

          <Link to={process.env.PUBLIC_URL + '/message'} className='more-data'>
            See All
          </Link>
        </ul>
      </div>
    </li>
  )
}

export default MessagesDropdown
