import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { reviewUser } from '../../../actions/profile'
import $ from 'jquery'
import ReviewAvatar from './ReviewAvatar'
import ReviewStats from './ReviewStats'
import ReviewDetail from './ReviewDetail'
import ReviewUsername from './ReviewUsername'
import MenuTypeTabs from '../MenuTypeTabs.jsx/MenuTypeTabs.jsx'
import { getUser } from '../../../services/customFunction'

const DeleteReviewButton = ({ review, handleDelete, label = 'Delete Review' }) =>
  review?.user_id === getUser()?.id && !!handleDelete ? (
    <div className='d-flex mt-1'>
      <MenuTypeTabs
        menus={[{ type: 'delete', label, icon: 'fa-trash' }]}
        menuType={'delete'}
        handleChange={handleDelete}
        wrapperClassName='mt-2 mt-lg-0'
        viewsClass='justify-content-end'
        buttonClass='btn-sm no-color btn-danger'
      />
    </div>
  ) : null

const ReviewListDetail = (props) => {
  const reviewUserHandler = (e) => {
    !e?.target?.className?.includes?.('see-more-btn') && props.reviewUser(props.review)
  }

  $('#reviewCreateModal').on('hidden.bs.modal', function (e) {
    props.reviewUser({})
  })

  useEffect(() => {
    return () => {
      props.reviewUser({})
    }
  }, [])

  const handleDelete = () => {
    props?.deleteReview?.(review?.id)
  }

  const { review } = props
  let nameToDisplay = review?.fullname

  return (
    <div>
      <div className={'d-none d-sm-flex review_blk ' + review?.user_type + '_profile'}>
        <div className='d-flex flex-column w-100'>
          <div className='d-flex'>
            <ReviewAvatar review={review} />
            <div className='name_blk'>
              <ReviewUsername review={review} name={nameToDisplay} className='d-xl-block d-none' />
              <ReviewStats review={review} />
            </div>
            <div className='comment_blk'>
              <div className='name_blk p-0 d-none d-sm-block d-xl-none'>
                <ReviewUsername review={review} name={nameToDisplay} />
              </div>
              <ReviewDetail review={review} reviewUserHandler={reviewUserHandler} />
            </div>
          </div>
          <DeleteReviewButton review={review} handleDelete={handleDelete} />
        </div>
      </div>
      <div className={'d-sm-none it-mobile-review review_blk  ' + review?.user_type + '_profile'}>
        <div className='name_blk'>
          <ReviewUsername
            review={review}
            name={nameToDisplay}
            className={!review?.id ? 'text-start' : ''}
            skeletonWidth='60%'
          />
        </div>
        <div className='it-mobile-review-img'>
          <ReviewAvatar review={review} />
          <div className='it-review-right'>
            <div className={`name_blk ${!review?.id ? 'text-start' : ''}`}>
              <ReviewStats review={review} isMobile />
            </div>
            <div className={`comment_blk ${!review?.id ? 'text-start' : ''}`}>
              <ReviewDetail review={review} reviewUserHandler={reviewUserHandler} isMobile />
            </div>
          </div>
        </div>
        <div className='d-flex justify-content-end'>
          <DeleteReviewButton review={review} handleDelete={handleDelete} label='Delete' />
        </div>
      </div>
    </div>
  )
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ reviewUser }, dispatch)
}

export default connect('', mapDispatchToProps)(ReviewListDetail)
