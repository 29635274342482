import React from 'react'

const HeaderLogo = ({ className = 'mb-2', width = 125, src = '/img/logo-lg.png' }) => (
  <img
    src={`${process.env.PUBLIC_URL}${src}`}
    className={className}
    width={width}
    height='auto'
    alt=''
  />
)

export default React.memo(HeaderLogo)
