import React, { Component, Fragment } from 'react'

import * as activityApiService from '../../../services/activityApiService'
import { ACTIVITY_ITEMS_PER_PAGE, ACTIVITY_PAGE_RANGE } from '../../../services/const'
import { getUser } from '../../../services/customFunction'
import Pagination from 'react-js-pagination'
import ActivityListDetail from '../../CommonComponents/ActivityListDetail/ActivityListDetail'

class ActivityTab extends Component {
  constructor(props) {
    super(props)

    this.state = {
      login_user_id: getUser().id,

      activePage: 1,
      total_pages: 0,
      total_records: 0,

      user_activities: [],
    }

    this.handlePageChange = this.handlePageChange.bind(this)
  }

  componentDidMount() {
    this.activityListRender()
  }

  activityListRender() {
    let activityListReqData = {
      page: this.state.activePage ? this.state.activePage : 1,
    }

    const self = this
    activityApiService.activityListCall(activityListReqData).then(function (response) {
      if (typeof response?.status !== 'undefined' && response?.status === 200) {
        let apiResponseData = response?.data?.data
        let stateData = {
          total_pages: apiResponseData.total_pages,
          total_records: apiResponseData.total_records,
          user_activities: apiResponseData.user_activities,
        }

        if (self.state.activePage <= 0) {
          stateData['activePage'] = 1
        }

        self.setState(stateData, () => {})
      }
    })
  }

  handlePageChange(pageNumber) {
    this.setState(
      {
        activePage: pageNumber,
      },
      () => {
        this.activityListRender()
      },
    )
  }

  profileHeader_activity_tab_click_Handle() {
    this.activityListRender()
  }

  render() {
    const { activePage, total_pages, total_records, user_activities } = this.state
    let showingFrom = this.props.itemsCountPerPage * (activePage - 1) + 1
    let showingTo = this.props.itemsCountPerPage * activePage
    if (showingFrom > total_records) {
      showingFrom = total_records
    }
    if (showingTo > total_records) {
      showingTo = total_records
    }

    if (total_pages < activePage && total_pages !== 0) {
      this.setState(
        {
          activePage: total_pages,
        },
        () => {
          this.activityListRender()
        },
      )
    }

    return (
      <div
        id='activity'
        className={'tab-pane ' + (this.props.activeTab === '#activity' ? 'active' : 'fade')}
      >
        <h4 className='varela'>Recent activity</h4>
        <div className='bg-white'>
          <div className='activity_blk'>
            {/* <ul>
                            <li> You accepted <a href={null}>Crossroads Motor Lodge</a> as a friend.</li>
                        </ul> */}

            {total_records > 0 ? (
              <Fragment>
                <div className='i-showing'>
                  Showing {showingFrom} - {showingTo} of {total_records}
                </div>

                <ul>
                  {user_activities.map((activity, index) => (
                    <ActivityListDetail
                      key={activity.id}
                      index={index}
                      activity={activity}
                      login_user_id={this.state.login_user_id}
                    />
                  ))}
                </ul>
                <div>
                  <Pagination
                    activePage={activePage}
                    itemsCountPerPage={this.props.itemsCountPerPage}
                    totalItemsCount={total_records}
                    pageRangeDisplayed={this.props.pageRangeDisplayed}
                    onChange={this.handlePageChange}
                  />
                </div>
              </Fragment>
            ) : (
              <li>No activity</li>
              // <div className="search-section">
              //     <div className="no_search_result">
              //         <img src={process.env.PUBLIC_URL + "/img/no_reviews.png"} className="pt-5 pb-5 m-auto d-block"  alt="" />
              //     </div>
              // </div>
            )}
          </div>
        </div>
      </div>
    )
  }
}

ActivityTab.defaultProps = {
  itemsCountPerPage: ACTIVITY_ITEMS_PER_PAGE,
  pageRangeDisplayed: ACTIVITY_PAGE_RANGE,
}

export default ActivityTab
