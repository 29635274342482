import * as type from './const'
export const setMetaData = (data) => {
  return {
    type: type.SET_METADATA,
    data: data,
  }
}
export const setReportData = (data) => {
  return {
    type: type.SET_REPORTDATA,
    data: data,
  }
}
export const setPosts = (data) => {
  return {
    type: type.SET_POSTS,
    data: data,
  }
}

export const deletePost = (data) => {
  return {
    type: type.DELETE_POST,
    data: data,
  }
}
export const updatePost = (data) => {
  return {
    type: type.UPDATE_POST,
    data: data,
  }
}
export const postPost = (data) => {
  return {
    type: type.POST_POST,
    data: data,
  }
}
export const setCurrentPost = (data) => {
  return {
    type: type.SET_CURRENT_POST,
    data: data,
  }
}
export const resetCurrentPost = (data) => {
  return {
    type: type.RESET_CURRENT_POST,
    data: data,
  }
}
export const updatePostSettings = (data) => {
  return {
    type: type.UPDATE_POST_SETTINGS,
    data: data,
  }
}
export const updatePostReaction = (data) => {
  return {
    type: type.UPDATE_POST_REACTION,
    data: data,
  }
}

export const setIsUpdate = (data) => {
  return {
    type: type.SET_ISUPDATE,
    data: data,
  }
}
//handle one post
export const updateCommentType = (data) => {
  return {
    type: type.UPDATE_POST_COMMENT_TYPE,
    data: data,
  }
}
export const updateShareType = (data) => {
  return {
    type: type.UPDATE_POST_SHARE_TYPE,
    data: data,
  }
}
export const updateShareGroupType = (data) => {
  return {
    type: type.UPDATE_POST_SHARE_GROUP,
    data: data,
  }
}

export const updateSelectionTitle = (data) => {
  return {
    type: type.UPDATE_SELECTION_TITLE,
    data: data,
  }
}

export const updatePostBody = (data) => {
  return {
    type: type.UPDATE_POST_BODY,
    data: data,
  }
}

export const updatePostAttachments = (data) => {
  return {
    type: type.UPDATE_POST_ATTACHMENTS,
    data: data,
  }
}
//comments
export const setPostComments = (data) => {
  return {
    type: type.SET_COMMENTS,
    data: data,
  }
}
export const deleteComment = (data) => {
  return {
    type: type.DELETE_COMMENT,
    data: data,
  }
}
export const postComment = (data) => {
  return {
    type: type.POST_COMMENT,
    data: data,
  }
}
export const updateComment = (data) => {
  return {
    type: type.UPDATE_COMMENT,
    data: data,
  }
}
export const updateCommentReaction = (data) => {
  return {
    type: type.UPDATE_COMMENT_REACTION,
    data: data,
  }
}
//replies
export const setCommentReplies = (data) => {
  return {
    type: type.SET_REPLIES,
    data: data,
  }
}
export const deleteReply = (data) => {
  return {
    type: type.DELETE_REPLY,
    data: data,
  }
}
export const postReply = (data) => {
  return {
    type: type.POST_REPLY,
    data: data,
  }
}
export const updateReply = (data) => {
  return {
    type: type.UPDATE_REPLY,
    data: data,
  }
}
export const updateReplyReaction = (data) => {
  return {
    type: type.UPDATE_REPLY_REACTION,
    data: data,
  }
}
export const resetSocialMediaData = (data) => {
  return {
    type: type.RESET_SOCIAL_MEDIA_DATA,
    data: data,
  }
}
export const updatePostUserSettings = (data) => {
  console.log(data)
  return {
    type: type.UPDATE_POST_USER_SETTINGS,
    data: data,
  }
}
