import React from 'react'
import { Link } from 'react-router-dom'
import ReactStars from 'react-stars'
import {
  STAR_RATING_PROFILE_HEADER_SIZE,
  STAR_RATING_TRUST_ME_ACTIVE_COLOR,
  STAR_RATING_TRUST_ME_INACTIVE_COLOR,
} from '../../../services/const'
import { PROFILE_ROUTE } from '../../../services/routeConst'
import TotalGivenReviews from './TotalGivenReviews'

const BusOrgBadge = ({ userDetail }) => {
  return (
    <div className='badge_blk editprofile1'>
      <div className='badge_content '>
        <div className='badge yellow'>
          <div className='b-triangle' />
          <div className='b-inner'>
            <h4>Trusted By</h4>
            <div className='d1'>
              <div className='digits_cat'>
                <div className='digit'>{userDetail?.total_trust_me}</div>
              </div>
            </div>
          </div>
        </div>

        <div className='badge_star'>
          <ul className='nav nav-review-tab'>
            <Link to={PROFILE_ROUTE + '?type=received#review'} className='nav-link'>
              {userDetail?.total_review} Reviews Received
            </Link>
          </ul>
          <ul className='d-flex'>
            <ReactStars
              edit={false}
              size={STAR_RATING_PROFILE_HEADER_SIZE}
              color1={STAR_RATING_TRUST_ME_INACTIVE_COLOR}
              color2={STAR_RATING_TRUST_ME_ACTIVE_COLOR}
              value={userDetail?.average_review}
            />
          </ul>
        </div>
      </div>
      <div className='badge_content'>
        <div className='badge'>
          <div className='b-triangle' />
          <div className='b-inner'>
            <h4>iTRUST</h4>
            <div className='d1'>
              <div className='digits_cat'>
                <div className='digit'>{userDetail?.total_trusted}</div>
              </div>
            </div>
          </div>
        </div>
        <TotalGivenReviews userDetail={userDetail} />
      </div>
    </div>
  )
}

export default BusOrgBadge
