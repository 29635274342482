import axios from 'axios'
import { customLogger } from '../utils/CustomLogger'
import * as environemnt from './environment'
import { userToken, toaster } from './customFunction'
import { MAX_FILE_SIZE_MB, MAX_VIDEO_SIZE_MB, NETWORK_ERROR_MSG } from './const'

export const galleryCreateCall = (request) => {
  console.log('request : ', request)

  return axios
    .post(environemnt.BASE_API + 'gallery/create', request, {
      headers: {
        Authorization: userToken(),
        Accept: 'application/x.iTRUSTit.v1+json',
        'content-type': 'multipart/form-data',
      },
    })
    .then(function (response) {
      return response
    })
    .catch(function (error) {
      if (error === undefined || error.response === undefined) {
        toaster('error', NETWORK_ERROR_MSG)
      } else if ([401, 403, 500].indexOf(error.response.status) >= 0) {
        if (
          typeof error.response !== 'undefined' &&
          typeof error.response.data !== 'undefined' &&
          typeof error.response.data.message !== 'undefined' &&
          error.response.data.message
        ) {
          toaster('error', error.response.data.message)
        } else {
          toaster('error', 'Something went wrong!!!')
        }
      }
      customLogger(error)
      return error.response
    })
}

export const galleryDeleteCall = (request) => {
  //console.log('request : ', request);

  return axios
    .post(environemnt.BASE_API + `gallery/${request}/delete`, request, {
      headers: {
        Authorization: userToken(),
        Accept: 'application/x.iTRUSTit.v1+json',
        'content-type': 'multipart/form-data',
      },
    })
    .then(function (response) {
      return response
    })
    .catch(function (error) {
      if (error === undefined || error.response === undefined) {
        toaster('error', NETWORK_ERROR_MSG)
      } else if ([401, 403, 500].indexOf(error.response.status) >= 0) {
        if (
          typeof error.response !== 'undefined' &&
          typeof error.response.data !== 'undefined' &&
          typeof error.response.data.message !== 'undefined' &&
          error.response.data.message
        ) {
          toaster('error', error.response.data.message)
        } else {
          toaster('error', 'Something went wrong!!!')
        }
      }
      customLogger(error)
      return error.response
    })
}

export const galleryListCall = (request) => {
  let api_url = environemnt.BASE_API + 'gallery'
  if (typeof request.other_user_id !== 'undefined' && request.other_user_id > 0) {
    api_url = environemnt.BASE_API + 'gallery/' + request.other_user_id
  }

  return axios
    .post(api_url, request, {
      headers: {
        Authorization: userToken(),
        Accept: 'application/x.iTRUSTit.v1+json',
      },
    })
    .then(function (response) {
      return response.data
    })
    .catch(function (error) {
      if (
        typeof error.response !== 'undefined' &&
        typeof error.response.data !== 'undefined' &&
        typeof error.response.data.message !== 'undefined' &&
        error.response.data.message
      ) {
        toaster('error', error.response.data.message)
      }
      customLogger(error)
      return error.response
    })
}

//Only used for profile/cover photo upload, so video checks are not necessary
export const validateGalleryFileType = (file, imageOnly = false) => {
  let error = ''
  let isFormValid = true
  let fileType = ''
  if (!file) {
    //let stateCopy = { ...this.state };
    error = ['file is required']
    isFormValid = false
  } else if (
    imageOnly &&
    typeof file?.name !== 'undefined' &&
    !file.name.toLowerCase().match(/(\.heic|\.heif|\.jpg|\.png|\.JPG|\.PNG|\.jpeg|\.JPEG)$/)
  ) {
    error = ['File must be an image']
    isFormValid = false
  } else if (
    typeof file.name !== 'undefined' &&
    !file.name
      .toLowerCase()
      .match(
        /(\.gif|\.heic|\.heif|\.jpg|\.png|\.JPG|\.PNG|\.jpeg|\.JPEG|\.mp4|\.MP4|\.flv|\.FLV|\.mkv|\.MKV|\.m4v|\.M4V|\.avi|\.AVI|\.3gp|\.3GP|\.mpeg|\.MPEG|\.mov|\.MOV)$/,
      )
  ) {
    // /(\.jpg|\.png|\.JPG|\.PNG|\.jpeg|\.JPEG|\.mp4|\.MP4|\.flv|\.FLV|\.mkv|\.MKV)$/
    // |\.m4v|\.M4V
    // |\.avi|\.AVI
    // |\.3gp|\.3GP
    // |\.mpeg|\.MPEG
    // |\.mov|\.MOV
    error = ['file is invalid']
    isFormValid = false
  }
  if (
    file &&
    typeof file.name !== 'undefined' &&
    file.name.toLowerCase().match(/(\.gif|\.heic|\.heif|\.jpg|\.png|\.JPG|\.PNG|\.jpeg|\.JPEG)$/)
  ) {
    if (file?.size / 1024 / 1024 > MAX_FILE_SIZE_MB || file?.size <= 0) {
      error = [`File size cannot exceed ${MAX_FILE_SIZE_MB}MB`]
      isFormValid = false
    } else {
      fileType = 'image'
    }
  } else if (
    file &&
    typeof file.name !== 'undefined' &&
    file.name
      .toLowerCase()
      .match(
        /(\.mp4|\.MP4|\.flv|\.FLV|\.mkv|\.MKV|\.m4v|\.M4V|\.avi|\.AVI|\.3gp|\.3GP|\.mpeg|\.MPEG|\.mov|\.MOV)$/,
      )
  ) {
    if (file?.size / 1024 > MAX_VIDEO_SIZE_MB || file?.size <= 0) {
      error = [`File size cannot exceed ${MAX_VIDEO_SIZE_MB / 1000}GB`]
      isFormValid = false
    } else {
      fileType = 'video'
    }
  }
  return { error, isFormValid, fileType }
}

export const submitGalleryReport = async (request) => {
  let api_url = environemnt.BASE_API + 'submit-gallery-report'
  try {
    const { data } = await axios.post(api_url, request, {
      headers: {
        Authorization: userToken(),
        Accept: 'application/x.iTRUSTit.v1+json',
      },
    })
    return data
  } catch (error) {
    if (
      typeof error.response !== 'undefined' &&
      typeof error.response.data !== 'undefined' &&
      typeof error.response.data.message !== 'undefined' &&
      error.response.data.message
    ) {
      toaster('error', error.response.data.message)
    }
    customLogger(error)
    return error.response
  }
}
