import AppLayout from '../../../AppLayout'
import LoginModal from '../../../LoginModal/LoginModal'
import * as route from '../../../../services/routeConst'
import '../Reports/Report.scss'
import { Link } from 'react-router-dom'

const GeneralGuidelinesHTML = (props) => {
  window.scrollTo(0, 0)

  return (
    <AppLayout routeActive={props.location.pathname}>
      <section className='bg-grey it-privacy-terms-page it-terms-page'>
        <div className='container'>
          <div className='white-pane privacy'>
            <b>
              <h1>
                <span className='colorblue'>
                  General Guidelines for using the iTRUSTit platform{' '}
                </span>
              </h1>
            </b>
            <br></br>
            <p>
              iTRUSTit seeks to be a place where people, businesses, bands, teams, stars, charities,
              clubs, groups, communities, associations and organizations, etc. connect to share and
              promote what they trust and care about with their friends, family, clients, members,
              fans and associates.
            </p>
            <p>
              iTRUSTit's platform is a great place to build and strengthen your community goodwill
              and image by sharing your knowledge of, and supporting the businesses and
              organizations you trust both in and outside of your communities.
            </p>
            <p>
              We see iTRUSTit as a place where personal rights are respected. As you make use of the
              platform, keep the following criteria in mind:
              <ul>
                <li>
                  Your work is protected when it is shared on the iTRUSTit platform. Any articles
                  you write and publish under your own name are yours.
                </li>
                <li>At any moment, you can request that your material be removed from our site.</li>
                <li>
                  There are several ways iTRUSTit will be able to help you with the distribution and
                  annotation of your material as well as the sale of advertising on the sites where
                  your content appears.
                </li>
                <li>
                  You should be prepared for your content to be shared and made accessible to the
                  general audience.
                </li>
                <li>
                  Avoid posting anything that is deceptive, fraudulent, vulgar or threatening. Also
                  avoid posting anything that is discriminating, threatening, or unlawful.
                </li>
                <li>
                  You are solely responsible for the content of your articles, posts, content, etc.,
                  including any damage you cause to others or injury you suffer as a result of using
                  this service.
                </li>
                <li>
                  For any infringement of the Terms of Service or Acceptable Use Policy, iTRUSTit
                  may limit, suspend, or delete your iTRUSTit account and/or deactivate your
                  articles, posts, content, etc.,. For further information, please see our{' '}
                  <Link
                    style={{ color: '#6684f2' }}
                    to={route.FINDOUTMOREHTML_ROUTE}
                    target='_blank'
                  >
                    Notices Regarding Content,
                  </Link>{' '}
                  <Link style={{ color: '#6684f2' }} to={route.TERMS_ROUTE} target='_blank'>
                    Terms of Service or Acceptable Use Policy,
                  </Link>{' '}
                </li>
                <li>Infringing material will be removed from iTRUSTit accounts.</li>
              </ul>
            </p>
          </div>
        </div>
      </section>
      <LoginModal
        isLogin={(e) =>
          function (data) {
            props.isLogin(data)
          }
        }
        {...props}
      />
    </AppLayout>
  )
}

export default GeneralGuidelinesHTML
