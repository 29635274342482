import React, { Component, Fragment } from 'react'
import ReactDOM from 'react-dom'
import { bindActionCreators } from 'redux'
import { Field, reduxForm } from 'redux-form'
import classNames from 'classnames'
import renderInputField from '../Form/Input'
import * as validation from '../../services/formValidation'
import * as customFunction from '../../services/customFunction'
import { connect } from 'react-redux'
import ErrorMsgRenderer from '../ErrorMsgRenderer/ErrorMsgRenderer'
// import { forgotPassword } from "../../services/authService";
// import { toaster } from "../../services/customFunction";
// import { NETWORK_ERROR_MSG } from "../../services/const";
import $ from 'jquery'
import SocialLogin from '../SocialLogin/SocialLogin'
import { DEFAULT_PROFILE_PICTURE } from '../../services/const'
import { SIGNUP_ROUTE } from '../../services/routeConst'
import {
  loginAction,
  socialLoginAction,
  errorMsgAction,
  authResponse,
  authResponseAction,
  isLoadingAction,
  loginHistoryEmail,
  socialLoginFillForm,
} from '../../actions/auth'
import * as route from '../../services/routeConst'
import { Redirect } from 'react-router-dom'
import '../LoginModal/LoginModal.scss'
import ForgotPassword from '../ForgotPassword/ForgotPassword'

class LoginActivationModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      forgotPasswordFormErr: {
        email: [],
      },
      isLoading: false,
      submitBtnText: 'Log In',
      socialSingup: {},
      isRedirectIndividualSignup: false,
      isDisplayLoginErrorMsg: false,
    }
  }

  loginSubmitHandler = (data) => {
    this.setState({
      isLoading: true,
      submitBtnText: 'Loading...',
      isDisplayLoginErrorMsg: false,
    })
    this.props.loginAction(data)
  }
  componentWillMount() {
    //console.log("isLoadingAction");

    this.props.isLoadingAction(false)
  }
  componentWillReceiveProps = (nextProps) => {
    //console.log("nextProps",nextProps);
    let resetText = 'Log In'
    this.setState({
      submitBtnText: resetText,
      isLoading: false,
      isDisplayLoginErrorMsg: false,
    })

    if (Object.keys(nextProps.user.userdetail).length > 0 || nextProps.user.userdetail.length > 0) {
      customFunction.setUser(nextProps.user.userdetail)
      if (nextProps.user.token === undefined) {
        customFunction.setToken(customFunction.userToken())
      } else {
        customFunction.setToken(nextProps.user.token)
      }

      let profile_history = JSON.parse(window.localStorage.getItem('profile_history'))
      let history = {}

      history = {
        id: nextProps.user.userdetail.id,
        profile_img: nextProps.user.userdetail.profile_picture_medium
          ? nextProps.user.userdetail.profile_picture_medium
          : DEFAULT_PROFILE_PICTURE,
        email: nextProps.user.userdetail.email,
        type: nextProps.user.userdetail.login_type,
        user_type: nextProps.user.userdetail.user_type,
      }
      if (nextProps.user.userdetail.user_type === 'individual')
        history.name = nextProps.user.userdetail.fullname
      else history.name = nextProps.user.userdetail.business_organization_name

      if (profile_history == null) {
        window.localStorage.setItem('profile_history', JSON.stringify([history]))
      } else {
        let userFind = profile_history.filter((profile) => {
          return profile.id === nextProps.user.userdetail.id
        })
        if (userFind.length === 0) {
          profile_history.unshift(history)
          window.localStorage.setItem('profile_history', JSON.stringify(profile_history))
        } else {
          const profileIndex = profile_history.findIndex(
            (profile) => profile.id === nextProps.user.userdetail.id,
          )
          profile_history[profileIndex] = history
          profile_history.push(...profile_history.splice(0, profileIndex))

          window.localStorage.setItem('profile_history', JSON.stringify(profile_history))
        }
      }
      $('#login_modal').modal('hide')
      //nextProps.isLogin(true);

      this.props.history.push({
        pathname: route.PROFILE_ROUTE,
      })
    }
    if (Object.keys(nextProps.user.errormsg).length > 0 || nextProps.user.errormsg.length > 0) {
      if (Object.keys(this.state.socialSingup).length > 0) {
        this.props.history.push({
          pathname: route.SIGNUP_ROUTE,
        })
        // this.setState({ isRedirectIndividualSignup: true }, () => {
        //     this.props.socialLoginFillForm(this.state.socialSingup);
        // });
      }
      this.setState({ isDisplayLoginErrorMsg: true })
    }
  }

  loginHandler = (event) => {
    this.setState({ isDisplayLoginErrorMsg: false })
  }

  socialLoginHandler = (registerData) => {
    this.setState({ socialSingup: registerData }, () => {
      this.props.socialLoginAction(registerData)
    })
  }
  signUpCalled = () => {
    //console.log("signUpCalled");
    $('#login_modal').modal('hide')
    this.props.history.push({
      pathname: SIGNUP_ROUTE,
    })
  }

  componentDidMount() {}

  render() {
    const { invalid, handleSubmit } = this.props
    let self = this
    $('#login_modal').on('hidden.bs.modal', function (e) {
      self.setState({ isDisplayLoginErrorMsg: false })
    })

    $('#login_modal').on('shown.bs.modal', function (e) {
      // ReactDOM.findDOMNode(self.refs.forgotPasswordEmail).focus();
    })

    return (
      <div>
        <div className='modal fade login_blk reset_password_blk' id='login_modal' role='dialog'>
          <div className='modal-dialog'>
            <div className='modal-content'>
              <div className='modal-header'>
                {this.props.isClaimloginText ? (
                  <Fragment>
                    <p>You must be Logged In to Claim your business.</p>
                    <p>
                      Login below or{' '}
                      <a href={null} onClick={this.signUpCalled} className='purple'>
                        <u>
                          <b>Create a Personal Account Here so You Claim Your Business.</b>
                        </u>
                      </a>
                    </p>
                    <a className='nav-item' href={null} onClick={this.signUpCalled}></a>
                  </Fragment>
                ) : (
                  <Fragment>
                    <p>You must be Logged In to use these functions.</p>
                    <p>
                      Login below or{' '}
                      <a href={null} onClick={this.signUpCalled} className='purple'>
                        <u>
                          <b>Click Here to Join</b>
                        </u>
                      </a>
                    </p>
                    <a className='nav-item' href={null} onClick={this.signUpCalled}></a>
                  </Fragment>
                )}
                <button type='button' className='btn-close' data-bs-dismiss='modal'></button>
              </div>
              <div className='modal-body loginblk'>
                <div className='text-start'>
                  <SocialLogin {...this.state} socialLogin={this.socialLoginHandler} />
                </div>
                <h4 className='modal-title'>LOG IN</h4>
                {this.state.isDisplayLoginErrorMsg ? (
                  <span className='text-danger it-login-error-msg'>
                    The username, email or password <br />
                    you entered is incorrect.
                  </span>
                ) : (
                  ''
                )}
                <form
                  id='login_form'
                  name='login_form'
                  className='reset_password'
                  method='post'
                  onSubmit={handleSubmit(this.loginSubmitHandler).bind(this)}
                >
                  <div className='form-group'>
                    <Field
                      ref='forgotPasswordEmail'
                      name='email'
                      type='text'
                      className='form-control reset_pwd'
                      component={renderInputField}
                      validate={[validation.required]}
                      label='Email'
                      placeholder='Username or Email '
                      onChange={this.loginHandler}
                    />
                  </div>
                  <div className='form-group'>
                    <Field
                      name='password'
                      type='password'
                      className='form-control reset_pwd'
                      component={renderInputField}
                      validate={[validation.required]}
                      placeholder='Password'
                      autoComplete='off'
                      onChange={this.loginHandler}
                    />
                    <ErrorMsgRenderer
                      errors={
                        typeof this.state.forgotPasswordFormErr.email !== 'undefined' &&
                        Array.isArray(this.state.forgotPasswordFormErr.email) &&
                        this.state.forgotPasswordFormErr.email.length > 0
                          ? this.state.forgotPasswordFormErr.email
                          : []
                      }
                    />
                  </div>
                  <div className='d-flex remember'>
                    <div className='radio1'></div>
                    <a
                      className='link'
                      data-bs-toggle='modal'
                      data-bs-target='#reset_password_email_modal'
                    >
                      Forgot password?
                    </a>
                  </div>
                  <input
                    type='submit'
                    value={this.state.submitBtnText}
                    className={classNames(invalid && 'disabled', 'form-control resetbtn')}
                    disabled={invalid || this.state.isLoading}
                  />
                  {/* <p className="f_14">
                                        Don't have an account?
                                        <a href={null} onClick={this.signUpCalled} className="purple">
                                            Sign up now!
                                        </a>
                                    </p> */}

                  {/* <a className="nav-item" href={null} onClick={this.signUpCalled}>

                                        Join

                                    </a> */}
                </form>
              </div>
            </div>
          </div>
        </div>

        <ForgotPassword />
      </div>
    )
  }
}

LoginActivationModal = reduxForm({
  form: 'login_form',
})(LoginActivationModal)

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      loginAction,
      socialLoginAction,
      errorMsgAction,
      authResponse,
      authResponseAction,
      isLoadingAction,
      loginHistoryEmail,
      socialLoginFillForm,
    },
    dispatch,
  )
  // return {
  //     loginAction: bindActionCreators(loginAction, dispatch),
  // };
}

function mapStateToProps(state) {
  return {
    user: state.UserReducer,
    networkerror: state.UserReducer.networkerror,
    errormsg: state.UserReducer.errormsg,
    isLoading: state.UserReducer.isLoading,
    initialValues: {
      email: state.UserReducer.email.length > 0 ? state.UserReducer.email : '',
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginActivationModal)
