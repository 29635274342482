import React, { Component } from 'react'
import { FacebookShareButton, TwitterShareButton } from 'react-share'
import { connect } from 'react-redux'
import Friends from '../Friends/Friends'
import PostsTab from '../PostsTab/PostsTab'
import OverviewTab from '../OverviewTab/OverviewTab'
import { relatedProfileOther, sharedProfile } from '../../../services/userApiService'
import { PROFILE_ROUTE_CONST } from '../../../services/const'
import { Link } from 'react-router-dom'
import { CLAIM_BUSINESS_PROFILE_ROUTE } from '../../../services/routeConst'
import { SITE_ADDRESS } from '../../../services/environment'
import { toaster } from '../../../services/customFunction'
import $J from 'jquery'
import { formatMemberDirectoryName, formatMembersName } from '../../../services/formatService'
import ShareModal, { showShareModal } from '../../CommonComponents/ShareModal/ShareModal'
import { saveToContacts } from '../../CommonComponents/VCard_New/VCard'
var $ = window.$

class OverviewGenericTab extends Component {
  constructor(props) {
    super(props)

    this.overviewTabFriendSectionRef = React.createRef()
    this.overviewTabRef = React.createRef()
    this.postsTabRef = React.createRef()
    this.state = {
      relatedUser: [],
      isDisplayError: false,
      sharedFields: {},
      sharedErrors: {},
      membersName:
        formatMembersName(
          props?.user_settings?.members_name,
          props?.user_settings?.custom_members_name,
        ) ?? 'Members',
      memberDirectoryName:
        formatMemberDirectoryName(
          props?.user_settings?.member_directory_name,
          props?.user_settings?.custom_member_directory_name,
        ) ?? 'Member Directory',
    }
  }

  claimLoginModal = () => {
    this.props.loginModalOpen('true')
    $J('#login_modal').modal('show')
  }

  profileHeader_overview_tab_click_Handle() {
    this.overviewTabFriendSectionRef.current.profileHeader_overview_tab_click_Handle()
    this.overviewTabRef?.current?.profileHeader_overview_tab_click_Handle()
  }

  showReviewTab2 = (data) => {
    this.props.showReviewTab2(data)
  }

  componentDidMount() {
    // this.relatedProfileOthers();
  }

  relatedProfileOthers = () => {
    let self = this
    // console.log("overviewtab=>", this.props);

    relatedProfileOther(this.props.user_id).then(function (response) {
      if (response.status === 200) {
        let users = response.data.data.related_users
        let obj = {
          relatedUser: users,
        }
        if (response.data.data.related_users.length === 0) obj.isDisplayError = true

        self.setState(obj)
      } else self.setState({ isDisplayError: true })
    })
  }

  shareHandleChange = (e) => {
    let sharedFields = this.state.sharedFields
    sharedFields[e.target.name] = e.target.value
    this.setState({
      sharedFields,
    })
  }

  submitShareForm = (e) => {
    e.preventDefault()
    if (this.validateForm()) {
      let params = this.state.sharedFields
      params.url = SITE_ADDRESS + PROFILE_ROUTE_CONST + this.props.user.slug

      sharedProfile(params).then(function (response) {
        if (response.status === 200) {
          toaster('success', response.data.message)
          $('#sharedForm').get(0).reset()
          $J('#profileInfoShareModal').modal('hide')
        }
      })
    }
  }

  validateForm = () => {
    let fields = this.state.sharedFields
    let errors = {}
    let formIsValid = true

    if (!fields['name']) {
      formIsValid = false
      errors['name'] = 'Please enter your username.'
    }
    if (!fields['to_emails']) {
      formIsValid = false
      errors['toemail'] = 'Please enter To email.'
    }

    if (!fields['from_email']) {
      formIsValid = false
      errors['fromemail'] = 'Please enter your email-ID.'
    }

    if (typeof fields['from_email'] !== 'undefined') {
      //regular expression for email validation
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i,
      )
      if (!pattern.test(fields['from_email'])) {
        formIsValid = false
        errors['fromemail'] = 'Please enter valid email-ID.'
      }
    }

    this.setState({
      sharedErrors: errors,
    })
    return formIsValid
  }
  render() {
    return (
      <div
        id='overviewgeneric'
        className={
          'tab-pane ' +
          (this.props.activeTab === '#overview' || this.props.activeTab === '#posts'
            ? 'active'
            : 'fade')
        }
      >
        <div className='row'>
          <div className='col-md-4 overview-left'>
            <div className='bg-white curvey mb-4 p-15'>
              <div className='d-flex buttons'>
                <div className='col-4 col-md-4'>
                  <a
                    href={null}
                    data-bs-toggle='modal'
                    data-bs-target='#login_modal'
                    className='sendmsg'
                  >
                    <i>
                      <img src={process.env.PUBLIC_URL + '/img/chat_icon_green.png'} alt=' ' />
                    </i>
                    iTi Message
                  </a>
                </div>
                <div className='col-4 col-md-4'>
                  <ShareModal user={this.props.user} />
                  <a
                    href={null}
                    className='share'
                    onClick={() => showShareModal()}
                    // data-bs-toggle='modal'
                    // data-bs-target='#login_modal'
                    style={{ boxShadow: 'none' }}
                  >
                    <i>
                      <img src={process.env.PUBLIC_URL + '/img/share.png'} alt=' ' />
                    </i>
                    Share
                  </a>
                </div>
                <div className='col-4 col-md-4'>
                  <a
                    href={
                      this.props.user?.is_business_claimed === '1'
                        ? `/vcard/${this.props.user.slug}`
                        : null
                    }
                    className={`share ${
                      this.props.user?.is_business_claimed === '0' ? 'disabled' : ''
                    }`}
                    target='_blank'
                    rel='noopener noreferrer'
                    style={{ boxShadow: 'none' }}
                  >
                    <i>
                      <img
                        src={process.env.PUBLIC_URL + '/img/vcard-black.svg'}
                        width={21}
                        alt=' '
                      />
                    </i>
                    VCard
                  </a>
                </div>
              </div>
              <div className='alllinks'>
                <a href={null} className='d-block mb-3'>
                  <i>
                    <img src={process.env.PUBLIC_URL + '/img/follow_icon.png'} alt=' ' />
                  </i>{' '}
                  Follow
                </a>
                <a
                  href={null}
                  className='d-block mb-3'
                  onClick={() => saveToContacts(this.props.user)}
                >
                  <i>
                    <img src={process.env.PUBLIC_URL + '/img/download-vcard.png'} alt=' ' />
                  </i>{' '}
                  Save vCard Contact Info
                </a>
                <a href={null} className='d-block'>
                  <i>
                    <img src={process.env.PUBLIC_URL + '/img/bookmark.png'} alt=' ' />
                  </i>{' '}
                  Bookmark
                </a>
              </div>
            </div>
            {/* <Friends ref={this.overviewTabFriendSectionRef} user_id={this.props.user_id} /> */}
            <Friends
              ref={this.overviewTabFriendSectionRef}
              user_id={this.props.user_id}
              changeActiveTab={this.props.changeActiveTab}
              showFriendsTab={this.props.showFriendsTab}
            />

            {this.props.user.user_type !== 'individual' &&
              this.props.user.is_business_claimed === '0' && (
                <div className='bg-white curvey mb-4 p-15'>
                  <div className='listing_blk'>
                    <h4 className='varela'>Is This Your Listing?</h4>
                    <p className='f_14'>
                      If you own or manage this business claim your listing for free to respond to
                      reviews, update your profile and much more.
                    </p>
                    <Link
                      to={{
                        pathname: CLAIM_BUSINESS_PROFILE_ROUTE,
                        state: { user: this.props.user },
                      }}
                      className='claim_link'
                    >
                      Claim Your Listing
                    </Link>
                    {/* <a data-bs-toggle="modal" data-bs-target="#login_modal" className="claim_link">Claim Your Listing</a> */}
                    {/* <Link
                                        to={{
                                            pathname: CLAIM_BUSINESS_PROFILE_ROUTE,
                                            state: { user: this.props.user },
                                        }}
                                        className="claim_link"
                                    >
                                        Claim Your Listing
                                    </Link> */}
                  </div>
                </div>
              )}

            <div className='bg-white curvey mb-4 p-15 related-listings-show'>
              <img src={process.env.PUBLIC_URL + '/img/image.png'} className='img-fluid' alt=' ' />
            </div>

            {/* Related listings go here */}
          </div>
          {/* ------------------------------------------- overview left over-------------------------------------------  */}
          <div className='col-md-8 overview-right tab-content'>
            <OverviewTab
              user_id={this.props.user_id}
              user={this.props.user}
              activeTab={this.props.activeTab}
              ref={this.overviewGenericTabRef}
              showReviewTab2={this.showReviewTab2}
              changeActiveTab={this.props.changeActiveTab}
              showFriendsTab={this.props.showFriendsTab}
              loginModalOpen={this.props.loginModalOpenTab}
              user_settings={this.props.user_settings}
            />

            <PostsTab
              activeTab={this.props.activeTab}
              ref={this.postsTabRef}
              user={this.props.user}
              changeActiveTab={this.props.changeActiveTab}
              showFriendsTab={this.props.showFriendsTab}
              post_id={this.props.post_id}
            />
          </div>
          {/* ------------------------------------------- overview right over------------------------------------------- */}
        </div>
        <div
          className='modal fade it-sendmodal'
          id='sendMsgModal'
          tabIndex='-1'
          role='dialog'
          aria-labelledby='exampleModalCenterTitle'
          aria-hidden='true'
        >
          <div className='modal-dialog modal-dialog-centered' role='document'>
            <div className='modal-content'>
              <button
                type='button'
                className='btn-close'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <span aria-hidden='true'>&times;</span>
              </button>
              <div className='modal-body'>
                <h5>Send Message to Manfrove Reality</h5>
                <form>
                  <div className='form-group'>
                    <input type='text' className='form-control' placeholder='Subject' />
                  </div>
                  <div className='form-group'>
                    <textarea className='form-control' rows='3' placeholder='Send message...' />
                  </div>
                  <button type='button' className='btn btn-primary'>
                    Send iTi Message
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>

        <div
          className='modal fade it-sendmodal it-sharemodal'
          id='profileInfoShareModal'
          tabIndex='-1'
          role='dialog'
          aria-labelledby='exampleModalCenterTitle'
          aria-hidden='true'
        >
          <div className='modal-dialog modal-dialog-centered' role='document'>
            <div className='modal-content'>
              <button
                type='button'
                className='btn-close'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                {/* <span aria-hidden='true'>&times;</span> */}
              </button>
              <div className='modal-body'>
                <h5>Share {this.props.user.fullname}'s Page</h5>
                <div className='mt-2 mb-4'>
                  {/* <button type='button' className='btn fb-btn'> */}
                  <FacebookShareButton
                    url={SITE_ADDRESS + PROFILE_ROUTE_CONST + this.props.user.slug}
                  >
                    Share on facebook
                  </FacebookShareButton>
                  {/* </button> */}
                  {/* <button type='button' className='btn tw-btn'> */}
                  <TwitterShareButton
                    url={SITE_ADDRESS + PROFILE_ROUTE_CONST + this.props.user.slug}
                  >
                    Share on Twitter
                  </TwitterShareButton>
                  {/* </button> */}
                </div>
                <form method='post' onSubmit={this.submitShareForm} id='sharedForm'>
                  <div className='form-group'>
                    <input
                      type='text'
                      className='form-control'
                      value={SITE_ADDRESS + PROFILE_ROUTE_CONST + this.props.user.slug}
                      disabled
                    />
                  </div>
                  <div className='it-or-form'>OR </div>

                  <div className='form-group'>
                    <label>Your Name</label>
                    <input
                      type='text'
                      name='name'
                      className='form-control'
                      onChange={this.shareHandleChange}
                    />
                    <span style={{ color: 'red' }}>
                      {this.state.sharedErrors && this.state.sharedErrors.name}
                    </span>
                  </div>
                  <div className='form-group'>
                    <label>Your Email</label>
                    <input
                      type='email'
                      className='form-control'
                      name='from_email'
                      onChange={this.shareHandleChange}
                    />
                    <span style={{ color: 'red' }}>
                      {this.state.sharedErrors && this.state.sharedErrors.fromemail}
                    </span>
                  </div>
                  <div className='form-group'>
                    <label>
                      To <span>Email address(separate with a comma)</span>
                    </label>
                    <input
                      type='email'
                      className='form-control'
                      name='to_emails'
                      multiple
                      onChange={this.shareHandleChange}
                    />
                    <span style={{ color: 'red' }}>
                      {this.state.sharedErrors && this.state.sharedErrors.toemail}
                    </span>
                  </div>

                  <button type='submit' className='btn btn-primary'>
                    Share
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(null, null, null, { forwardRef: true })(OverviewGenericTab)
