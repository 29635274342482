import React, { Component } from 'react'
import { makeMemberCall } from '../../../services/memberApiService'

import { reduxForm } from 'redux-form'
import $J from 'jquery'

import './InvitePopupModal.scss'
import { formatMembersName } from '../../../services/formatService'

class InvitePopupModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      member_note: '',
    }
    //console.log(props.user_id)
  }
  componentWillMount() {}
  componentDidMount() {}
  changeUserTypeHandler = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }
  submitMember = (event) => {
    this.props.setLoadingMemberChangeResponse(true)
    let memberAddRemoveReqData = {
      id: this.props.user_id,
      member_type: this.props.memberType,
      member_note: this.state.member_note,
    }
    let self = this
    $J('#invite_modal').modal('hide')
    makeMemberCall(memberAddRemoveReqData).then(function (response) {
      if (typeof response.status !== 'undefined' && response.status === 200) {
        let apiResponseData = response.data
        self.props.setStatusOfMemberUserYour(apiResponseData.data.member_status)
        self.setState({
          member_note: '',
        })
        self.props.setLoadingMemberChangeResponse(false)
      }
    })
  }
  render() {
    //console.log({ props: this.props })
    return this.props.user_id ? (
      <div
        className='modal fade login_blk reset_password_blk it-member-modal'
        id='invite_modal'
        role='dialog'
      >
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h2 style={{ width: '80%', marginRight: 'auto' }}>
                INVITE {this.props.Orgname} TO BE A{' '}
                {formatMembersName(this.props?.membersName, null, false).toUpperCase()} OF YOUR
                ORGANIZATION
              </h2>
              <button
                type='button'
                className='btn-close'
                data-bs-dismiss='modal'
                style={{ color: 'white' }}
              ></button>
            </div>
            <div className='modal-body loginblk'>
              <form id='login_form' name='login_form' className='reset_password' method='post'>
                <div className='form-group'>
                  <label> Comments:</label>
                  <textarea name='member_note' onChange={this.changeUserTypeHandler}></textarea>
                </div>
                <input
                  className='resetbtn'
                  type='button'
                  value='Submit'
                  onClick={this.submitMember}
                />
              </form>
            </div>
          </div>
        </div>
      </div>
    ) : null
  }
}

InvitePopupModal = reduxForm({
  form: 'invite_modal',
})(InvitePopupModal)

export default InvitePopupModal

// export default connect(
//     mapStateToProps,
//     mapDispatchToProps
// )(MemberPopupModal);
