import React, { useEffect, useState } from 'react'
import { DEFAULT_PROFILE_PICTURE, IMG_PATH } from '../../../services/const'
import { reactionListCall } from '../../../services/socialMediaApiService'
import './Reactions.scss'
import SearchPageLoadingIcon from '../../Search/SearchPageLoadingIcon'
import { connect } from 'react-redux'

const Reactions = ({ request, onCloseButtonClick, ...props }) => {
  const default_apiData = {
    currentPage: 1,
    reactionsList: [],
    reactionTypesList: {},
    reactionTypesTotal: [],
    lastPage: 1,
    order_type: 1,
  }
  const [apiData, setApiData] = useState(default_apiData)
  const [status, setStatus] = useState('idle')
  const [reactiontype, setReactionType] = useState('')

  useEffect(() => {
    reactionListRender()
  }, [])

  const closeReactions = () => {
    setApiData(default_apiData)
    onCloseButtonClick()
  }

  //reaction API's calls
  const reactionListRender = React.useCallback(async () => {
    setStatus('loading')
    const response = await reactionListCall(request)
    if (response?.status === 200) {
      setApiData((st) => ({
        currentPage: response?.data?.data?.current_page + 1,
        lastPage: response?.data?.data?.last_page,
        reactionsList: [...st?.reactionsList, ...response?.data?.data?.reactions],
        reactionTypesTotal: response?.data?.data?.reaction_totals,
      }))
    }
    setStatus('idle')
  }, [apiData?.currentPage])
  const onClickHandler = (data) => {
    setReactionType(data)
  }
  let reactionList =
    reactiontype === ''
      ? apiData?.reactionsList
      : apiData?.reactionsList?.filter((reaction) => reaction?.reaction_type_id === reactiontype)
  return (
    <div className='postDialogs'>
      <div
        className='modal overlay-top-layer reactionmodal'
        role='dialog'
        id='wall_reactions_modal'
      >
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div name='postForm' className='choose_file mt-4'>
              <div className='modal-header'>
                <h5>Reactions</h5>
                <button type='button' className='btn-close' onClick={closeReactions}></button>
              </div>
              <div className='modal-body overflow-auto'>
                <div>
                  {status === 'loading' ? (
                    <SearchPageLoadingIcon
                      className='pagination-loading'
                      styles={{
                        height: 'auto',
                      }}
                      iconStyles={{ height: 38, transform: 'scale(1.5)' }}
                    />
                  ) : (
                    ''
                  )}
                  <>
                    {apiData?.reactionTypesTotal?.length > 0 && (
                      <div className='modalReactionTypes'>
                        <li className='nav-item'>
                          <button classname='reactiontypebtn' onClick={() => onClickHandler('')}>
                            {'All ' + apiData?.reactionsList?.length}
                          </button>
                        </li>
                        {apiData?.reactionTypesTotal?.map((reactiontype) => (
                          <li className='nav-item'>
                            <button
                              classname='reactiontypebtn'
                              onClick={() => onClickHandler(reactiontype?.reaction_type_id)}
                            >
                              <img src={IMG_PATH + reactiontype.icon} className='likeIcon' alt='' />
                              {reactiontype?.total_reactions}
                            </button>
                          </li>
                        ))}
                      </div>
                    )}

                    <ul className='modalReactions'>
                      <div>
                        {reactionList?.map((reaction) => (
                          <li className='modalReaction'>
                            <div className='postTopLeft'>
                              <div className='postSmallPicture'>
                                <img
                                  className='img_block'
                                  src={reaction?.profile_picture_small ?? DEFAULT_PROFILE_PICTURE}
                                  alt=''
                                />
                                <img
                                  src={
                                    IMG_PATH +
                                    props?.reactiontypes?.filter(
                                      (r) => r?.id === reaction?.reaction_type_id,
                                    )[0]?.icon
                                  }
                                  className='reactionTypeImg'
                                  alt=''
                                />
                              </div>
                              <div className='wallFlexColumnLeft overFlowHidden'>
                                <a
                                  className='postUsername'
                                  href={process.env.PUBLIC_URL + '/' + reaction?.slug}
                                >
                                  {reaction?.fullname}
                                </a>
                              </div>
                            </div>
                          </li>
                        ))}
                      </div>
                    </ul>
                  </>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
function mapStateToProps(state) {
  return {
    reactiontypes: state?.SocialMediaReducer?.metadata?.reaction_types,
  }
}
export default connect(mapStateToProps, null)(Reactions)
