import React, { Component } from 'react'
import classNames from 'classnames'
import { Field, reduxForm } from 'redux-form'
import { Redirect } from 'react-router-dom'

import * as customFunction from '../../services/customFunction'
import * as validation from '../../services/formValidation'
import renderInputField from '../Form/Input'
import * as route from '../../services/routeConst'
import AppLayout from '../AppLayout'
import '../Login/Login.scss'
import { resetPassword } from '../../services/authService'
import { RESET_PASSWORD_TITLE } from '../../services/titleConst'
import { Helmet } from 'react-helmet'

class ResetPassword extends Component {
  constructor(props) {
    super(props)

    this.state = {
      resetText: 'Reset Password',
      resetLink: props.match.params.resetlink,
      isRedirect: false,
      isDisabled: false,
      isPasswordPopupDisplay: false,
      validation: {
        isSpaceValidation: false,
        isMaxLength: false,
        isUppercase: false,
        isLowercase: false,
        isNumber: false,
      },
    }
  }

  resetHandler = (event) => {
    let inputValue = event.target.value
    let stateCopy = { ...this.state }

    if (/^[^\s]+(\s+[^\s]+)*$/.test(inputValue)) {
      stateCopy.validation['isSpaceValidation'] = true
      this.setState(stateCopy)
    } else {
      stateCopy.validation['isSpaceValidation'] = false
      this.setState(stateCopy)
    }

    if (inputValue.length > 7 && inputValue.length < 21) {
      stateCopy.validation['isMaxLength'] = true
      this.setState(stateCopy)
    } else {
      stateCopy.validation['isMaxLength'] = false
      this.setState(stateCopy)
    }

    if (/[A-Z]/.test(inputValue)) {
      stateCopy.validation['isUppercase'] = true
      this.setState(stateCopy)
    } else {
      stateCopy.validation['isUppercase'] = false
      this.setState(stateCopy)
    }

    if (/[a-z]/.test(inputValue)) {
      stateCopy.validation['isLowercase'] = true
      this.setState(stateCopy)
    } else {
      stateCopy.validation['isLowercase'] = false
      this.setState(stateCopy)
    }

    if (inputValue.match(/\d+/g)) {
      stateCopy.validation['isNumber'] = true
      this.setState(stateCopy)
    } else {
      stateCopy.validation['isNumber'] = false
      this.setState(stateCopy)
    }
  }

  resetPasswordSubmitHandler = (data) => {
    this.setState({ resetText: 'Loading...', isDisabled: true })
    delete data.confirmPassword
    data.resetLink = this.state.resetLink
    let self = this
    resetPassword(data).then(function (response) {
      self.setState({ resetText: 'Reset Password', isDisabled: false })

      if (response.status === true) {
        customFunction.toaster('success', response.message)

        self.props.history.push({
          pathname: route.LOGIN_ROUTE,
        })
      } else customFunction.toaster('error', response.data.message)
    })
  }

  passwordPopup = () => {
    setTimeout(() => {
      this.setState({ isPasswordPopupDisplay: !this.state.isPasswordPopupDisplay })
    }, 200)
  }

  render() {
    let self = this
    let validationKey = Object.keys(this.state.validation)
    let validationFilter = validationKey.filter(function (key) {
      return self.state.validation[key]
    })

    let validationPercentage = validationFilter.length * 20

    const { invalid, handleSubmit } = this.props
    return (
      <AppLayout>
        <Helmet>
          <title>{RESET_PASSWORD_TITLE}</title>
        </Helmet>
        <div className='container'>
          <div className='row'>
            <div className='loginblk'>
              <div className='panel panel-default'>
                <div className='panel-heading'>
                  <h3 className='panel-title'>Reset Password</h3>
                </div>
                <div className='panel-body'>
                  <form
                    method='post'
                    id='ResetPasswordForm'
                    onSubmit={handleSubmit(this.resetPasswordSubmitHandler).bind(this)}
                  >
                    <fieldset>
                      <div className='form-group password_criteria'>
                        <Field
                          name='password'
                          type='password'
                          className='form-control'
                          component={renderInputField}
                          validate={[
                            validation.required,
                            validation.minValue8,
                            validation.alphaNumeric,
                            validation.maxLength20,
                            validation.noSpace,
                          ]}
                          label='Email'
                          placeholder='Enter Password'
                          autoComplete='off'
                          onFocus={this.passwordPopup}
                          onBlur={this.passwordPopup}
                          onChange={this.resetHandler}
                        />
                        {this.state.isPasswordPopupDisplay && (
                          <div className='criteria'>
                            <div className='progress'>
                              <div
                                className='progress-bar'
                                role='progressbar'
                                aria-valuenow='0'
                                aria-valuemin='0'
                                aria-valuemax='100'
                                style={{ width: validationPercentage + '%' }}
                              >
                                <span className='sr-only'>70% Complete</span>
                              </div>
                            </div>
                            <h3> Criteria: </h3>
                            <ul>
                              <li
                                className={this.state.validation.isSpaceValidation ? 'success' : ''}
                              >
                                No Spaces, cannot start or end with a space
                              </li>
                              <li className={this.state.validation.isLowercase ? 'success' : ''}>
                                Include a lower case letter
                              </li>
                              <li className={this.state.validation.isUppercase ? 'success' : ''}>
                                Include a upper case letter
                              </li>
                              <li className={this.state.validation.isNumber ? 'success' : ''}>
                                Include number
                              </li>
                              <li className={this.state.validation.isMaxLength ? 'success' : ''}>
                                Be 8-20 characters long
                              </li>
                            </ul>
                          </div>
                        )}
                      </div>
                      <div className='form-group'>
                        <Field
                          name='confirmpassword'
                          type='password'
                          className='form-control'
                          component={renderInputField}
                          validate={[
                            validation.required,
                            validation.minValue8,
                            validation.alphaNumeric,
                            validation.maxLength20,
                            validation.noSpace,
                            validation.confirmPassword,
                          ]}
                          label='Password'
                          placeholder='Enter Confirm Password'
                          autoComplete='off'
                        />
                      </div>
                      <input
                        type='submit'
                        value={this.state.resetText}
                        className={classNames(
                          invalid && 'disabled',
                          'btn',
                          'btn-lg',
                          'btn-success',
                          'btn-block',
                          'loginbtn',
                        )}
                        disabled={invalid && this.state.isDisabled}
                      />
                    </fieldset>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AppLayout>
    )
  }
}
ResetPassword = reduxForm({
  form: 'ResetPasswordForm',
})(ResetPassword)

export default ResetPassword
