import React, { Component } from 'react'
import AppLayout from '../AppLayout'
import './privacy.scss'
import { PRIVACY_TITLE } from '../../services/titleConst'
import LoginModal from '../LoginModal/LoginModal'
import { Helmet } from 'react-helmet'

class Privacy extends Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {
    return (
      <AppLayout routeActive={this.props.location.pathname}>
        <Helmet>
          <title>{PRIVACY_TITLE}</title>
        </Helmet>
        <section className='bg-grey it-privacy-terms-page'>
          <div className='container'>
            <h3 className='varela mb-3'>Privacy Policy</h3>
            <div className='white-pane privacy'>
              {/* <p>By browsing, accessing or registering with the iTRUSTit website (“Web Site”) and/.or Service (as further described in the iTRUSTit Terms of Service (“TOS”), you agree to be bound by the terms of this iTRUSTit Privacy Policy (“Privacy Policy”).  iTRUSTit Social Inc., a/k/a iTRUSTit (“iTRUSTit” or “Company,” also referred to herein as “we,” “us,” “our” or “ourselves”) operates and maintains the Web Site and/or Services accessible thereon, or otherwise. Any capitalized terms not defined in this Privacy Policy are defined in the TOS. The terms of this Privacy Policy are subject to all applicable laws.  If you have questions about this Privacy Policy, please contact us via the customer service contact information on the Web Site, or as set forth below.</p>
                <p>This Privacy Policy describes how we collect, use and share (if any) (i) personally identifiable information (“PII”) you provide and/or gathered about you by iTRUSTit in connection with your use of the Web Site and/or Services, including such PII as your name, address, e-mail address, credit card information, date of birth, telephone number, and, for Company’s revenue sharing program, social security number, employment  identification number, and other tax related information, including, but not limited to information you provide on or for tax forms, such as a W-4 or W-9), and (ii) any other information you may submit to the Web Site or iTRUSTit, or that that we may gather about you in connection with the Web Site and/or Services.  Please note your photo and trademarks/logos may be displayed on the Website and as part of the Service.  </p>
                <h3>Collection, Retention, and Use of Information</h3>
                <p>We may collect and store information about you when you use the Web Site and/or Service.  We use the information to (i) fulfill your requests, (ii) provide the Web Site’s and Service’s functionality, (iii) improve the Web Site’s and Service’s quality, (iv) personalize your experience, (v) track usage of the Website and Service, (vi) provide feedback to third party businesses that are listed on the Service, (vii) display relevant advertising, (viii) market the Service, (ix) provide customer support, (x) message you, (xi) back up our systems, (xii) share information with our corporate affiliates for their everyday business purposes, (xiii) provide services as part of our participation in joint ventures or with partner sites, (xiv) share information with third party service providers who help us operate our business or provide services on our behalf, such as analytics, advertising, promotions, or revenue sharing with you, and (xv)  for the other purposes set forth in this Privacy Policy. </p>
                <p>We collect your PII during your interaction with the Web Site, including, amongst other processes, during your registration, use of the Services and/or Web Site, if you are participating in our revenue sharing program, and/or your responding to surveys or submitting queries to iTRUSTit customer support.  You may from time to time update your PII associated with your registration, however, such information previously submitted by you to us may be retained by us for technical, legal or other reasons.</p>
                <p>We may also collect non-PII information during your use of the Web Site, and/or Services, such as tracking the frequency of Users’ visits to pages on the Web Site.  </p>
                <p>To facilitate the display of targeted or other advertising, we may share identifiable information from or about you with third parties, such as location data, advertising identifiers, or a cryptographic hash of a common account identifier (such as an email address). You may be able to limit our sharing of some of this information through your mobile device settings.</p>
                <p> In addition, we may use your PII or non-PII to set-up your account.  (If you believe that someone has created an unauthorized account depicting you or your likeness, you can request its removal at <a href="mailto:admin@itrustit.com" target="_top">admin@itrustit.com</a>. </p>
                <p>Furthermore, you can invite your friends and/or colleagues to join the Service by providing us with their contact information, or by allowing us to access your contacts from your computer, mobile device, or third party sites to select which friends or colleagues you want to invite.  If you allow us to access your contacts, we may transmit that information to us to process your invitations.</p>
                    <p>
                    In addition, information you contribute through the Web Site and Service may be intended for public consumption, including, without limitation, your reviews, tips, photos, videos, comments, likes, friends, lists, compliments, trusts, and profile. We may display this information through the Web Site or Service, and further distribute it to a wider audience through third party sites and services.
                    </p>
                    <p>When using the Web Site and/or Service you are opting to receive messages from other Users and us.  We may track your actions in response to the messages you receive from us or through the Service, such as whether you deleted, opened, or forwarded such messages. If you exchange messages with others through the Service, we may store them in order to process and deliver them, allow you to manage them, and we may review and disclose them in connection with investigations related to the operation and use of the Service.  In our sole discretion, we may not deliver messages that we believe are objectionable, such as spam messages or requests to exchange reviews for compensation.  If you send or receive messages through the Service via SMS text message, we may log phone numbers, phone carriers, and the date and time that the messages were processed. Carriers may charge recipients a fee for texts that they receive. </p>
                    <p>We may also store information that you provide through communications to us, including from phone calls, letters, emails and other electronic messages, or in person.  We may contact you, including by phone or email, using the contact information you provide us, make publicly available, or that we have on record for your business.</p>
                    <p>If you conduct a transaction through the Web Site and/or Service, we may, solely at our discretion, display (publicly, or otherwise) that fact.</p>
                    <p>  In addition, we may store information about your use of the Web Site and/or Service, such as, without limitation, your search activity, the pages you view, and the other Users you interact with.</p>
                    <p>We also may store information that your computer or mobile device provides to us in connection with your use of the Web Site and/or Service, such as your browser type, type of computer or mobile device, browser language, IP address, mobile carrier, phone number, unique device identifier, advertising identifier, location (including geolocation, beacon based location, and GPS location), and requested and referring URLs.  In addition to other items, these could tell us the pages you visit, the features you use, dates and times, if you navigated from or navigate to another website, the address of that website; and information regarding your internet service provider.  Once you log into the Web Site on your device, we may recognize your device to provide you with a personalized experience, independent of your device settings. You may be able to disallow our use of certain location data through your device or browser settings, for example by disabling “Location Services” for the iTRUSTit application in iOS privacy settings.</p>
                    <p>We may also use “cookies” and/or “web beacons” to track and analyze your use of the Web Site. "Cookies" are small data files with a unique identifier that we transfer to your computer's hard drive or web browser and which collect and store data about your visit to the Web Site, which allows identification when you return to the Web Site.  It may be possible to disable some (but not all) Cookies through your device or browser settings, but doing so may affect the functionality of the Web Site or Service for you.  The method for disabling Cookies may vary by device and browser, but can usually be found in preferences or security settings.</p>
                    <p>"Web beacons" are small graphic files with unique identifiers contained in our Web Site that we use to track Web Site usage and traffic.)  In each case, we may combine such information collected with your PII in our data collection.</p>
                    <p>In addition, we may collect information about your activities on the Service through the use of clear GIFs, local shared objects, Flash cookies, or through other identifiers or technologies, including similar technologies as they may evolve over time.</p>
                    <p>You may use social networks or other online services to access our Service. When you do so, information from those services may be made available to us. By associating a social network account with our Service, you agree that we may access and retain that information in accordance with the policies of the social network or other online service and this Privacy Policy. For example, we may be able to access account or profile information that you have provided to the social network or information about your interactions with the social network to make information available to us (such as when commenting on a blog post or using a sign-on service, such as Facebook Connect).
                    </p>
                    <p>Your personal information may be transferred to countries other than your own to process and store data in accordance with our Privacy Policy and to provide you with products and services. Some of these countries may not have the same data protection safeguards as the country where you reside. By using our products and services, you consent to us transferring your data to these countries. We are committed to ensuring your information is protected and apply safeguards in accordance with applicable law.</p>
                    <h3>Additional Uses and Sharing of PII</h3>
                    <p>We also reserve the right to send you certain communications relating to the Service, such as Service announcements, administrative messages and any newsletters, without offering you the opportunity to opt out of receiving them.</p>
                    <p>Except as described below, we do not share your PII with any third party, except in aggregate, non-personally identifiable form.  This prohibition is specifically excluded from any collection and/or use of your PII from or by any third parties, such as third party Web Sites, for or to which a link is provided on the Web Site (for which you specifically consent to the provision of your PII), or any PII that you elect to display or make accessible to third parties via the privacy settings on your account, or otherwise.   </p>
                    <p>We do engage companies to help us provide products and services or information, such as credit card transaction processing companies for your account charges,  companies that help us either gather your information or communicate with you, and for our revenue sharing program(s) (which companies may receive, in addition to other PII, your social security number, employment identification number, and other tax related information, including, but not limited to information you provide on or for tax forms, such as a W-4 or W-9); all with whom we share your PII as required.  Except as otherwise permitted by this Privacy Policy or the TOS, such companies are only allowed to collect and use your PII for the purposes for which we have engaged them or as required or permitted by applicable law or your account settings. </p>
                    <p>In addition, we may disclose your personal information to persons and entities who provide customer or technical support, but only for such purposes.</p>
                    <p> You may elect to share certain of your own PII by entering the information into your account profile and selecting the privacy settings that you desire for the posting and sharing of any such information that may be accessible on your profile page to Users.  You should review the privacy settings in your account profile to ensure that you select the appropriate privacy settings for the information you post to your profile.  The input, posting and sharing of your PII by you on your profile are your responsibility and not the responsibility of Company, are not covered by this Privacy Policy, and Company shall not be liable for any of same.    </p>
                    <p>You can close your account by _contacting us at <a href="mailto:admin@itrustit.com" target="_top">admin@itrustit.com</a>. We will remove your public posts from view and/or dissociate them from your account profile, but we may retain information about you for the purposes authorized under this Privacy Policy unless prohibited by law. For example, we may retain information, including, without limitation, PII, to prevent, investigate, or identify possible wrongdoing in connection with the Web Site and/or Service or to comply with legal obligations.  Please note that businesses or Users cannot remove their business listings, trusts, friends, comments, ratings, or reviews by closing their accounts.</p>
                    <h3>Other Disclosures</h3>
                    <p>We reserve the right to also disclose PII when we determine in good faith that such disclosures are required by law (for example, to comply with a court order), to enforce our TOS or the rules of our Services, to protect the safety and security of the Web Site and/or the Services, and/or to protect the safety, security and rights of our Users, and/or iTRUSTit, its affiliates, and its and their owners, directors, officers, representatives, contractors and/or agents. </p>
                    <p>Furthermore, we may disclose and transfer any PII and non-PII collected hereunder in the sales, acquisition, merger or other change in the structure of all or part of Company, its affiliates, and/ or its business.  In any such event, we will request that the acquirer or other applicable third party remains obligated to treat your PII in the manner required by this Privacy Policy.</p>
                    <h3>Security of Information</h3>
                    <p>We endeavor to maintain the security and confidentiality of your PII in accordance with applicable industry standards, including administrative, digital and physical security measures to protect your information from unauthorized access and improper use. From time to time, we may review our security procedures in order to update or implement such technology and methodology. That said, no security measures are impenetrable and so-called hackers or other third parties may intercept and misuse your PII, including during transmission of same to and from the Web Site.</p>
                    <h3>Minors</h3>
                    <p>The Web Site and Service is intended for general audiences and is not directed to minors (children) under the age of 18. We do not knowingly collect personal information from minors under the age of 18.  If you become aware that a child has provided us with personal information without parental consent, please contact us at <a href="mailto:admin@itrustit.com" target="_top">admin@itrustit.com</a>. If we become aware that a minor under the age of18 has provided us with personal information without parental consent, we take steps to remove such information and terminate the minor’s account.</p>
                    <h3>California Residents:  Your California Privacy Rights</h3>
                    <p>If you are a California resident, California Civil Code Section 1798.83 permits you to request information regarding the disclosure of your personal information by Company to third parties for the third party’s direct marketing purposes.  Pursuant to California Civil Code Section 1798.83 (c)(2), Company does not share User’ personal information with third parties for those third party direct marketing use, unless a User elects the Company do so.  As a User of the Web Site and Service, you agree that some of your PII may be posted on the Web Site or through the Service so that other Users may contact you.  To make a request regarding California Civil Code 1708.83 or to receive further information regarding what is set forth in this paragraph, please contact us at:</p>
                    <address>
                    iTRUSTit Social Inc. <br/>
                    California Privacy Rights<br/>
                    703 Pier Avenue<br/>
                    Suite B691<br/>
                    Hermosa Beach, CA 90254
                    </address>
                    <h3>Changes to this Privacy Policy</h3>
                    <p>Any changes or revisions this Privacy Policy will be posted on the Web Site and/or emailed to your current email address in your account.  Any such changes/revisions will apply only to PII collected after the effective date of such updated Privacy Policy (as noted in the posting/email), unless otherwise clearly stated in such update.  It is your responsibility to frequently check this Privacy Policy for any updates  </p>
                    <h3>Contact us Regarding Privacy Issues.</h3>
                    <p>You can contact us with questions or comments about our privacy practices or this Privacy Policy by emailing us at <a href="mailto:admin@itrustit.com" target="_top">admin@itrustit.com</a>  , or you can contact us at:</p>
                    <address>
                    iTRUSTit Social Inc.<br/>
                    Privacy Policy<br/>
                    703 Pier Avenue<br/>
                    Suite B691<br/>
                    Hermosa Beach, CA 90254
                    </address>
                    <p>Updated: 01/03/2019</p>
                    <h5>© 2016 . iTRUSTit Social Inc.  All rights reserved, Patent Pending</h5> */}
              <p>
                iTRUSTit Social Inc. (“iTRUSTit,” “we,” “us,” or “our”) operates and maintains the
                iTRUSTit website (the “Web Site”) and the Services (further described in the
                iTRUSTit Terms of Service). If you (“you” or “your”) have questions about this
                Privacy Policy, please contact us via the customer service contact information on
                the Web Site, or as set forth below.
              </p>
              <p>
                This Privacy Policy describes how we collect, use and share Personal Information
                collected by iTRUSTit when you use the Web Site and/or Services.
              </p>
              <h3>What is Personal Information?</h3>
              <p>
                <strong>“Personal Information”</strong> is information that, either alone or in
                combination with other information, identifies you. Examples of Personal Information
                include, among others, name, email address, phone number, and mailing address. When
                we combine Personal Information with other information, we treat the combined
                information as Personal Information.
              </p>
              <h3>Collection and Use of Information</h3>
              <p>
                <strong>Information You Provide</strong>
              </p>
              <p>
                In various places on the Web Site (including when you use the Services), we request
                information (including Personal Information) from you via forms or otherwise. The
                exact information we need to collect will depend on the purpose of the form or the
                Services. For example, if you register to use the Service, we collect information
                such as your name, email address, date of birth, and title. If you participate in
                our revenue sharing program, we use a secure third-party service provider to collect
                Personal Information (e.g., Social Security number, employment identification
                number) necessary to pay you for referrals and to provide you with a tax form 1099.
                We will indicate on our forms whether a particular field of information is mandatory
                or optional. If you choose not to provide certain information, we may not be able to
                provide requested Services.
              </p>
              <p>
                In addition, information contributed through the Web Site and Service may be
                intended for public consumption, including, without limitation, reviews, tips,
                photos, videos, comments, likes, friends, lists, compliments, trusts, and profile.
                We may display this information through the Web Site or Service, and further
                distribute it to a wider audience through third-party sites and services.
              </p>
              <p>
                <strong>Information We Automatically Collect</strong>
              </p>
              <p>
                We may also collect information automatically during your use of the Web Site and
                Services:
              </p>
              <p className='ms-3'>
                <strong>1. Log File Information</strong>
              </p>
              <p>
                When you use our Web Site, our servers automatically record information, including
                your Internet Protocol address (“IP Address”), browser type, referring URLs (e.g.,
                the site you visited before coming to our Web Site), number of clicks, search
                activity, how you interact with links on the Site, domain names associated with your
                internet service provider, pages viewed, and other such information (collectively,
                “Log File Information”). We may also collect similar information from emails sent to
                you which then help us track which emails are opened and which links are clicked by
                recipients. We use Log File Information to further our legitimate interests in
                securing the Web Site and Services by identifying potential threats and
                vulnerabilities, and in analyzing the effectiveness of our Web Site and Services to
                improve their respective function and content.
              </p>
              <p className='ms-3'>
                <strong>2. Cookies, Device Identifiers, and Similar technologies</strong>
              </p>
              <p>
                When you use or access the Web Site and Services, we and our service providers use
                cookies, device identifiers, and similar technologies such as pixels, web beacons,
                and local storage to collect information about how you use the Web Site and
                Services. We process the information collected through such technologies to help
                operate certain features of the Web Site and Services, to enhance your experience
                through personalization (including, without limitation, providing you with
                advertisements relevant to your interests), and to help us better understand the
                features of the Web Site and Services that you and other users are most interested
                in.
              </p>
              <p>
                Some of our service providers may use cookies or other methods to gather information
                regarding your use of the Web Site, and may combine the information in these cookies
                with any Personal Information about you that they may have. The use of such tracking
                information by a third party depends on the privacy policy of that third party. At
                this time we do not respond to Do Not Track (“DNT”) signals sent to us by your
                browser.
              </p>
              <p>
                Should you visit our Web Site or Services using your mobile device to facilitate the
                display of advertising that may be of interest to you, we may authorize advertising
                networks to collect certain information about you or your device, such as location
                data or unique identifiers associated with your device.
              </p>
              <p>
                You may be able to limit our sharing of some of this information through your mobile
                device settings. For example, you may be able to disallow our use of certain
                location data through your device or browser settings, for example by disabling
                “Location Services” for the iTRUSTit application in iOS, Android or your mobile
                platform privacy settings.
              </p>
              <p>
                <strong>Information We Receive from Others</strong>
              </p>
              <p>
                We may receive Personal Information about you from your friends and/or colleagues
                who invite you to join the Service by providing us with your contact information, or
                by allowing us to access their contacts.
              </p>
              <p>
                You may use social networks or other online services to access our Services. When
                you do so, Personal Information (e.g., your profile information) from those services
                may be made available to us, depending on your privacy settings on that social
                network. Except as otherwise specified in this Privacy Policy, we will use
                information received via integrated social media services only to facilitate your
                use of the Services.
              </p>
              <h3>How We Use Personal Information</h3>
              <p>
                We use Personal Information for limited purposes, depending on the context in which
                we collect it. In other words, we process Personal Information with your consent, as
                necessary to provide the Web Site, the Services, or for our legitimate interests,
                such as:
              </p>
              <p className='ms-3'>Fulfilling your requests;</p>
              <p className='ms-3'>Enabling secure use of the Web Site and Services;</p>
              <p className='ms-3'>
                Providing, analyzing, administering, and improving the Web Site and Services;
              </p>
              <p className='ms-3'>
                Personalizing your experience, including, without limitation through relevant
                advertising;
              </p>
              <p className='ms-3'>
                Providing your feedback to third-party businesses through the Services;
              </p>
              <p className='ms-3'>
                Enabling you and other users to communicate via messaging features of the Services;
              </p>
              <p className='ms-3'>Marketing the Services;</p>
              <p className='ms-3'>
                Communicating with you in accordance with your communication preferences;
              </p>
              <p className='ms-3'>Responding to inquiries;</p>
              <p className='ms-3'>Protecting our rights or our property;</p>
              <p className='ms-3'>Meeting legal obligations; and</p>
              <p className='ms-3'>
                For other purposes related to the reasons for which you provide Personal
                Information.
              </p>
              <h3>Disclosure of Personal Information</h3>
              <p>
                Except as described below or with your consent, we do not share your Personal
                Information with any third party. This section describes to whom we disclose
                Personal Information, and for what purposes:
              </p>
              <p className='ms-3'>
                <strong>1. Our Service Providers.</strong> We employ service providers to perform
                tasks on our behalf and to assist us in providing the Website and Services, to help
                us market our Services through various communication and social media channels, and
                to serve advertisements. For example, we engage Google LLC to assist in analyzing
                website traffic through cookies and similar technologies and to provide maps (see{' '}
                <a href='https://www.google.com/policies/privacy/partners/' target='_blank'>
                  “How Google uses data when you use our partners' Services or apps”
                </a>
                 ); and iMatrixTM (from Internet Brands) to process information needed to pay you if
                you participate in our revenue sharing program (see the{' '}
                <a
                  href='https://www.internetbrands.com/privacy/privacy-highlights.html?site=www.imatrix.com'
                  target='_blank'
                >
                  “Internet Brands Privacy Policy”
                </a>
                ). We take commercially reasonable steps to help ensure our service providers
                provide at least the same level of protection for Personal Information as we do.
              </p>
              <p className='ms-3'>
                <strong>2. Other Users.</strong>You may elect to share certain of your own Personal
                Information by entering the information into your account profile and selecting the
                privacy settings that you desire for the posting and sharing of any such information
                that may be accessible on your profile page to users.  You should review the privacy
                settings in your account profile to ensure that you select the appropriate privacy
                settings for the information you post to your profile.
              </p>
              <p className='ms-3'>
                <strong>3. Companies Involved in Mergers and Acquisitions Transactions.</strong>If
                we sell or otherwise transfer part or the whole of our business or assets to another
                organization (e.g., in the course of a transaction like a merger, acquisition,
                bankruptcy, dissolution, or liquidation), any Personal Information collected through
                the Web Site or Services may be among the items sold or transferred.
              </p>
              <p className='ms-3'>
                <strong>4. Law Enforcement, Government Agencies, and Courts:</strong>We may disclose
                Personal Information at the request of law enforcement or government agencies or in
                response to subpoenas, court orders, or other legal process to establish, protect,
                or exercise our rights or to defend against a legal claim or as otherwise required
                or allowed by law, or to protect the rights, property, or safety of any other
                person. We may also disclose Personal Information to investigate or prevent (i) a
                violation by you of any contractual (including the TOS) or other relationship with
                us, or (ii) your illegal or harmful activity.
              </p>
              <h3>Communications</h3>
              <p>
                With your permission, we may send notifications, promotions, or other information
                via email or SMS text message (“<strong>Communications</strong>”). You may choose to
                stop receiving Communications by indicating your preference in your account profile
                or settings. You may also follow the unsubscribe instructions in any email or text
                message you receive. You may also send a global unsubscribe request to{' '}
                <a href='mailto:info@itrustit.com' target='_top'>
                  info@itrustit.com
                </a>
                . Please note that certain Service-related Communications are necessary for the
                proper functioning and use of the Services (e.g., announcements and administrative
                messages) and you may not have the ability to opt out of those Communications.
              </p>
              <h3>Security of Information</h3>
              <p>
                We endeavor to maintain the security and confidentiality of your Personal
                Information in accordance with applicable industry standards, including
                administrative, digital and physical security measures to protect your information
                from unauthorized access and improper use. That said, no security measures are
                impenetrable and so-called hackers or other third parties may intercept and misuse
                your Personal Information, including, without limitation, during transmission of
                same to and from the Web Site or Services. We do not represent or warrant that
                Personal Information about you will be protected against loss, misuse, or alteration
                by third parties.
              </p>
              <h3>Retention of Personal Information</h3>
              <p>
                We will retain your Personal Information for the period necessary to fulfill the
                purposes for which your Personal Information has been collected as outlined in this
                Privacy Policy, unless a longer retention period is required by law.
              </p>
              <h3>Transfers of Personal Information from Outside the U.S.</h3>
              <p>
                If you access the Web Site or Services from outside the U.S., your Personal
                Information may be transferred to the U.S. to process and store data in accordance
                with our Privacy Policy. The U.S. may not have the same data protection safeguards
                as the country where you reside. By using our Web Site or Services, you consent to
                us transferring Personal Information to the U.S. Regardless of location, we will
                implement measures to protect Personal Information in accordance with applicable
                law.
              </p>
              <h3>Minors</h3>
              <p>
                The Web Site and Services are intended for general audiences and are not directed to
                minors (children) under the age of 18. We do not knowingly collect Personal
                Information from minors under the age of 18. If you become aware that a child has
                provided us with Personal Information, please contact us at{' '}
                <a href='mailto:info@itrustit.com' target='_top'>
                  info@itrustit.com
                </a>
                . If we become aware that a minor under the age of 18 has provided us with Personal
                Information, we take steps to remove such information and terminate the minor’s
                account in accordance with applicable law.
              </p>
              <h3>Your Rights Concerning Personal Information</h3>
              <p>
                If you would like to access, amend, erase, export, or object to or restrict the
                processing of Personal Information about you, you may submit a request to{' '}
                <a href='mailto:info@itrustit.com' target='_top'>
                  info@itrustit.com
                </a>
                . We will promptly review all such requests in accordance with applicable laws.
              </p>
              <p>
                You can close your account by contacting us at{' '}
                <a href='mailto:info@itrustit.com' target='_top'>
                  info@itrustit.com
                </a>
                . We will remove your public posts from view and/or dissociate them from your
                account profile, but we may retain information about you for the purposes authorized
                under this Privacy Policy unless prohibited by law. Please note that we cannot
                remove business listings, trusts, friends, comments, ratings, or reviews associated
                with businesses and users, even if the associated account(s) is closed.
              </p>
              <p>
                If you believe that someone has created an unauthorized account depicting you or
                your likeness, you can request its removal at{' '}
                <a href='mailto:info@itrustit.com' target='_top'>
                  info@itrustit.com
                </a>
                .
              </p>
              <p>
                Depending on where you live, you may have a right to lodge a complaint with a
                regulatory or other governmental authority if you believe that we have violated any
                of your rights concerning Personal Information. We encourage you to first reach out
                to us at{' '}
                <a href='mailto:info@itrustit.com' target='_top'>
                  info@itrustit.com
                </a>
                , so we have an opportunity to address your concerns before you do so.
              </p>
              <h3>California Residents: Your California Privacy Rights</h3>
              <p>
                If you are a California resident, California Civil Code Section 1798.83 permits you
                to request information regarding the disclosure of your “Personal Information” (as
                defined by California law) by iTRUSTit to third parties for the third party’s direct
                marketing purposes. To make such a request or to receive further information
                regarding what is set forth in this paragraph, please contact us at:
              </p>
              <address>
                TRUSTit Social Inc.
                <br />
                California Privacy Rights
                <br />
                703 Pier Avenue
                <br />
                Suite B691
                <br />
                Hermosa Beach, CA 90254
              </address>
              <h3>OTHER WEBSITES AND SERVICES, INCLUDING SOCIAL MEDIA</h3>
              <p>
                We are not responsible for the practices employed by any websites or services linked
                to or from our Web Site or Services, including the information or content contained
                within them. A link to a third party’s website should not be construed as an
                endorsement. We encourage you to investigate and ask questions before disclosing
                Personal Information to third parties.
              </p>
              <h3>Changes to this Privacy Policy</h3>
              <p>
                Any changes or revisions this Privacy Policy will be posted on the Web Site or
                emailed to your current email address in your account.  Any such changes/revisions
                will apply only to Personal Information collected after the effective date of such
                updated Privacy Policy (as noted in the posting/email), unless otherwise clearly
                stated in such update. You should frequently check this Privacy Policy for any
                updates.
              </p>
              <h3>Contact us Regarding Privacy Issues.</h3>
              <p>
                You can contact us with questions or comments about our privacy practices or this
                Privacy Policy by emailing us at 
                <a href='mailto:info@itrustit.com' target='_top'>
                  info@itrustit.com
                </a>
                , or you can contact us at:
              </p>
              <address>
                iTRUSTit Social Inc.
                <br />
                Privacy Policy
                <br />
                703 Pier Avenue
                <br />
                Suite B691
                <br />
                Hermosa Beach, CA 90254
              </address>
            </div>
          </div>
        </section>
        <LoginModal
          isLogin={(e) =>
            function (data) {
              this.props.isLogin(data)
            }
          }
          {...this.props}
        />
      </AppLayout>
    )
  }
}

export default Privacy
