import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { updatePostUserSettings } from '../../actions/socialmedia'
import PrivacySettings from '../../components/Profile/PrivacySettings/PrivacySettings'

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ updatePostUserSettings }, dispatch)
}

function mapStateToProps(state) {
  return {
    metadata: state.SocialMediaReducer.metadata,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PrivacySettings)
