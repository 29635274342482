import { combineReducers } from 'redux'
import UserReducer from './UserReducer'
import SocialMediaReducer from './SocialMediaReducer'
import SearchReducer from './SearchReducer'
import { reducer as formReducer } from 'redux-form'

const rootReducer = combineReducers({
  UserReducer,
  SocialMediaReducer,
  SearchReducer,
  form: formReducer,
})

export default rootReducer
