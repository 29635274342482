import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import Cropper from 'react-cropper'
import 'cropperjs/dist/cropper.css'
import defaultImg from './no-image.jpg'
import $ from 'jquery'
import './cropper.scss'
import { toaster } from '../../services/customFunction'
import { getObjectUrl } from './cropperHelpers'
import { customLogger } from '../../utils/CustomLogger'
import ErrorMsgRenderer from '../ErrorMsgRenderer/ErrorMsgRenderer'
class Cropperjs extends Component {
  constructor(props) {
    super(props)
    this.state = {
      src: defaultImg,
      imgBlob: '',
      isDisable: true,
      croppertext: 'Crop',
      minContainerWidth: 465,
      imgFile: '',
    }
    this.cropperRef = React.createRef()
  }

  _crop = () => {
    this.setState({ croppertext: 'Loading...', isDisable: true })
    try {
      let srcdata
      if (this.state.imgFile?.type === 'image/gif') {
        srcdata = this.state.src
      } else {
        srcdata = this.cropperRef?.current?.cropper?.getCroppedCanvas()?.toDataURL()
      }
      this.props.srcData(srcdata, this.state.imgFile)
      $('#cropperModal').modal('hide')
      this.setState({ imgBlob: srcdata }, () => {
        this.props.updateProfile && this.props.updateProfile()
      })
    } catch (error) {
      customLogger(error)
      toaster('error', 'Failed to crop image')
      // this.setState({ croppertext: 'Crop', isDisable: false })
      setTimeout(() => {
        $('#cropperModal').modal('hide')
      }, 1000)
    }
  }

  onChange = (e) => {
    var files,
      self = this
    if (e.dataTransfer) {
      files = e.dataTransfer.files
    } else if (e.target) {
      files = e.target.files
    }
    if (files[0]?.type) {
      var reader = new FileReader()
      reader.onload = function () {
        self.setState({
          src: reader.result,
          isDisable: false,
        })
      }
      self.setImgFile({ imgFile: files[0] })
      reader.readAsDataURL(files[0])
    } else {
      getObjectUrl(files[0]).then(({ imageFile, imageSource }) =>
        self.setImgFile({
          src: imageSource,
          imgFile: imageFile,
          isDisable: false,
        }),
      )
    }
  }

  setImgFile = (data) => {
    this.props?.setImgFile(data?.imgFile)
    this.setState(data)
  }

  componentWillMount() {
    if (window.innerWidth <= 320) {
      this.setState({ minContainerWidth: 270 })
    } else if (window.innerWidth > 320 && window.innerWidth <= 360) {
      this.setState({ minContainerWidth: 310 })
    } else if (window.innerWidth > 360 && window.innerWidth <= 380) {
      this.setState({ minContainerWidth: 325 })
    } else if (window.innerWidth > 380 && window.innerWidth <= 410) {
      this.setState({ minContainerWidth: 340 })
    } else if (window.innerWidth > 410 && window.innerWidth <= 500) {
      this.setState({ minContainerWidth: 365 })
    } else if (window.innerWidth > 500) {
      this.setState({ minContainerWidth: 465 })
    }
  }

  render() {
    let self = this

    $('#cropperModal').on('show.bs.modal', function (e) {
      self.setState({ src: self.props.imgsrc })
    })

    $('#cropperModal').on('hidden.bs.modal', function (e) {
      ReactDOM.findDOMNode(self.refs.inputFile).value = ''
      self.setState({
        src: defaultImg,
        imgBlob: '',
        croppertext: 'Crop',
        isDisable: false,
      })
    })

    return (
      <div
        className='modal fade'
        id='cropperModal'
        tabIndex='-1'
        role='dialog'
        aria-labelledby='cropperModalLabel'
      >
        <div className='modal-dialog' role='document'>
          <div className='modal-content'>
            <div className='modal-body round-circle-profile'>
              <Cropper
                ref={this.cropperRef}
                src={this.state.src}
                style={{ height: 300, width: '100%' }}
                dragMode='move'
                minContainerWidth={this.state.minContainerWidth}
                minContainerHeight={300}
                checkOrientation={false}
                checkCrossOrigin={false}
                aspectRatio={1 / 1}
                viewMode={0}
                cropBoxMovable={false}
                cropBoxResizable={false}
                zoomOnTouch={true}
                zoomOnWheel={true}
                guides={false}
                highlight={false}
                responsive={true}
              />
              <p
                className='varela'
                style={{ textAlign: 'center', fontSize: 14, marginTop: '0.4rem' }}
              >
                Upload an image from your device and fit it within the area above. Scroll to resize
                the image and then click 'Crop' when you are finished.
              </p>
              <div className='crop-btn'>
                <input
                  type='file'
                  className='file'
                  id='photo'
                  onChange={this.onChange}
                  ref='inputFile'
                />
                <ErrorMsgRenderer errors={this.props.errors?.length > 0 ? this.props.errors : []} />
                <br />
                <button
                  type='button'
                  className='btn btn-primary'
                  disabled={this.state.isDisable}
                  onClick={this._crop}
                >
                  {this.state.croppertext}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Cropperjs
