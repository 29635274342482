import React from 'react'
import Skeleton from 'react-loading-skeleton'
import { Link } from 'react-router-dom'

const ReviewUsername = ({ review, name, className = '', skeletonWidth = '75%' }) => {
  return (
    <h5 className={`varela ${className}`} style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
      {review?.id ? (
        <Link className='' to={process.env.PUBLIC_URL + '/' + review?.slug}>
          {name}
        </Link>
      ) : (
        <Skeleton width={skeletonWidth} />
      )}
    </h5>
  )
}

export default ReviewUsername
