import { useEffect, useState } from 'react'
import { toaster } from '../services/customFunction'
import { runElasticSearchUsers } from '../utils/elasticSearch'

const useChatUserSuggestions = (userId, source = 'chat-suggestions', extraMustNot) => {
  const [isSearching, setIsSearching] = useState(false)
  const [searchData, setSearchData] = useState({ searchStr: '', results: null })
  useEffect(() => {
    if (searchData?.searchStr?.length >= 2) {
      fetchUserSuggestions()
    } else {
      setIsSearching(false)
      // fetchUserSuggestions({ defaults: defaultSuggestions })
    }
  }, [searchData?.searchStr])

  const fetchUserSuggestions = async (searchStr = searchData?.searchStr) => {
    // if (!isSearching) {
    setIsSearching(true)
    const response = await runElasticSearchUsers({
      request: {
        search_string: searchStr,
        must_not: [
          { match: { is_business_claimed: '0' } },
          { match: { id: userId } },
          extraMustNot,
        ],
      },
      source,
    })
    console.log({ response })
    response?.error && toaster('error', response?.error)
    response?.status !== 'CANCELLED' && setIsSearching(false)

    // if (!isSearching) {
    setSearchData((st) =>
      response?.status !== 'failed' && !response?.error && response?.status !== 'CANCELLED'
        ? { searchStr: st.searchStr, results: response }
        : { searchStr: st.searchStr, results: null },
    )
    // }
    // }

    // if ((!isSearching || searchSuggestions?.whileSearching) && inputLengthRef?.current >= 2) {
    //   setSearchSuggestions((st) =>
    //     response?.status === 'failed' && !response?.error
    //       ? { defaults: defaultSuggestions }
    //       : response?.status === 'CANCELLED' && !response?.error
    //       ? { categories: st?.categories ?? [], defaults: [] }
    //       : {
    //           ...st,
    //           whileSearching: null,
    //           fetchedCategories: null,
    //           defaults: !response?.length && !st?.categories?.length ? defaultSuggestions : [],
    //           users: response ?? [],
    //         } ?? {
    //           defaults: defaultSuggestions,
    //         },
    //   )
    // } else if (inputLengthRef?.current < 2) {
    //   setIsSearching(false)
    //   setSearchSuggestions({ defaults: defaultSuggestions })
    // }
  }

  return { isSearching, setIsSearching, searchData, setSearchData, fetchUserSuggestions }
}

export default useChatUserSuggestions
