import React from 'react'
import QRCode from '../../QRCodeModal/QRCode'

const VCardQRCode = ({
  vcardLink,
  size = 300,
  label = 'vCard',
  labelClassName = 'mb-4',
  className = '',
}) => {
  return (
    <div className={`d-flex flex-column align-items-center ${className}`}>
      <span className={`vcard-cta-text ${labelClassName ?? ''}`}>{label}</span>
      <QRCode
        value={vcardLink}
        logoImage={process.env.PUBLIC_URL + '/img/iti_qr_logo.png'}
        size={size}
        qrStyle='dots'
        // fgColor='#006699'
        fgColor='black'
        ecLevel='H'
        eyeRadius={[
          { outer: 10 }, // top/left eye
          { outer: 10 }, // top/right eye
          { outer: 10 }, // bottom/left eye
        ]}
      />
    </div>
  )
}

export default VCardQRCode
