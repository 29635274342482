import React, { Fragment, useEffect, useState } from 'react'
import AppLayout from '../AppLayout'
import { Redirect, useHistory } from 'react-router-dom'
import { HOME_ROUTE, ADD_CLAIM_ROUTE, SIGNUP_NEW } from '../../services/routeConst'
import { userProfileById } from '../../services/userApiService'
import { DEFAULT_PROFILE_PICTURE } from '../../services/const'
import LoginModal from '../LoginModal/LoginModal'
import * as customFunction from '../../services/customFunction'
import $ from 'jquery'
import LogoutPopup from '../AddBusOrgNew/LogoutPopup'
import $J from 'jquery'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { signupType } from '../../actions/auth'
import { Helmet } from 'react-helmet'
import { CLAIM_BUSINESS_TITLE, CLAIM_ORGANIZATION_TITLE } from '../../services/titleConst'

const Claim = (props) => {
  const history = useHistory()
  const [stateData, setStateData] = useState({
    user: props.location.state ? props.location.state.user : {},
    user_type: (props?.location?.state?.user ?? {})?.user_type ?? '',
    is_click: false,
    isClaimloginText: false,
    isUserDetailsReceived: false,
    loginUserData: props?.location?.state?.loginUserData,
  })

  useEffect(() => {
    window.scrollTo(0, 0)
    !!stateData?.user?.id && profile()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const profile = () => {
    userProfileById(stateData.user.id).then(function (response) {
      // console.log("response.data.data.user_details", response.data.data.user_details);

      if (response.status === 200) {
        setStateData((st) => ({
          ...st,
          user: response.data.data.user_details,
          isUserDetailsReceived: true,
        }))
      }
    })
  }

  const changeUserTypeHandler = (event) => {
    setStateData((st) => ({ ...st, user_type: event.target.value }))
  }

  const continueHandler = () => {
    if (!!customFunction.userToken()) {
      $J('#add_as_owner_logout_popup_2').modal('show')
    } else {
      props.signupType(stateData.user_type?.toLowerCase())
      history.push({
        pathname: SIGNUP_NEW,
        state: {
          signupType: stateData?.user_type?.toLowerCase(),
          isClaim: true,
          idToClaim: stateData?.user?.id,
          loginUserData: stateData?.loginUserData,
          busOrgName: stateData?.user?.business_organization_name,
        },
      })
    }
  }

  const loginHandler = (data) => {
    props.isLogin(data)
  }

  const goToClaimRoute = () =>
    history.push({
      pathname: ADD_CLAIM_ROUTE,
      state: { isClaim: true },
    })

  const { user, user_type, is_click } = stateData

  $('#login_modal').on('hidden.bs.modal', function (e) {
    setStateData((st) => ({ ...st, isClaimloginText: false }))
  })

  if (Object.keys(user).length === 0) {
    return <Redirect to={HOME_ROUTE} />
  }

  return (
    <AppLayout isDisplayFooter={false}>
      <Helmet>
        <title>
          {stateData?.user_type === 'business' ? CLAIM_BUSINESS_TITLE : CLAIM_ORGANIZATION_TITLE}
        </title>
      </Helmet>
      <LoginModal isLogin={loginHandler} {...props} isClaimloginText={stateData.isClaimloginText} />
      <LogoutPopup
        user_type={stateData?.user_type}
        busOrgName={stateData?.user?.business_organization_name}
        isAdd={false}
        isClaim
        idToClaim={stateData?.user?.id}
        redirectTo={SIGNUP_NEW}
        modalId='2'
      />
      <div className='container'>
        <div className='claim_steps'>
          <h3 className='add_title'>
            <span className='other_txt'>
              Claim Your
              {user.user_type === 'business' && ' Business'}
              {user.user_type === 'organization' && ' Organization'}
            </span>
          </h3>
          <p className='text-center mt-3 mb-3'>
            {user.user_type === 'business' &&
              'To protect you and your business we need to verify your association with this business.'}

            {user.user_type === 'organization' &&
              'To protect you and your organization we need to verify your association with this organization.'}
          </p>
          <div className='claim_frnd_detail_box'>
            <div className='image_blk'>
              {user.profile_picture_medium != null ? (
                <img src={user.profile_picture_medium} alt='food' />
              ) : (
                <img src={DEFAULT_PROFILE_PICTURE} alt='food' />
              )}
            </div>
            <div className='claim_frnd_right'>
              <h5 className='varela'>{user.fullname}</h5>

              {!user.address_line_1 &&
              !user.address_line_2 &&
              !user.city_name &&
              !user.state_short_name &&
              !user.state_name &&
              !user.zipcode &&
              !user?.country_name ? (
                <p className='address'>No address data</p>
              ) : (
                <>
                  <p className='address'>
                    {user.address_line_1 != null && <Fragment>{user.address_line_1}, </Fragment>}

                    {user.address_line_2 != null && <Fragment>{user.address_line_2},</Fragment>}

                    {user.city_name != null && user.city_name + ', '}
                    {user.state_short_name != null
                      ? user.state_short_name + ' '
                      : user.state_name != null && user.state_name + ' '}
                    {user.zipcode != null && user.zipcode}
                  </p>
                  {user?.country_name !== null ? (
                    <p className='address'>{user?.country_name}</p>
                  ) : null}
                </>
              )}
            </div>
          </div>
          <div className='text-center mt-2 mb-4'>
            {user.user_type === 'business' && (
              <Fragment>
                Not your business?{' '}
                <button
                  onClick={goToClaimRoute}
                  className='blue btn btn-sm p-0 m-0'
                  style={{ border: 'none', outline: 'none' }}
                >
                  {' '}
                  Search for a different business{' '}
                </button>
              </Fragment>
            )}
            {user.user_type === 'organization' && (
              <Fragment>
                Not your organization?{' '}
                <button
                  onClick={goToClaimRoute}
                  className='blue btn btn-sm p-0 m-0'
                  style={{ border: 'none', outline: 'none' }}
                >
                  {' '}
                  Search for a different organization{' '}
                </button>
              </Fragment>
            )}
          </div>
          <div className='text-center mb-2'>
            Please select if this is a Business or Organization.
          </div>
          <div className='text-center mb-3'>
            <label style={{ cursor: 'pointer' }}>
              <input
                style={{ cursor: 'pointer' }}
                type='radio'
                name='user_type_claim'
                value='business'
                checked={stateData.user_type === 'business'}
                onChange={changeUserTypeHandler.bind(this)}
              />{' '}
              Business &nbsp;
            </label>
            <label style={{ cursor: 'pointer' }}>
              <input
                style={{ cursor: 'pointer' }}
                type='radio'
                name='user_type_claim'
                value='organization'
                checked={stateData.user_type === 'organization'}
                onChange={changeUserTypeHandler.bind(this)}
              />{' '}
              Organization
            </label>
            {user_type.length === 0 && is_click && (
              <p className='text-danger'>this field is required</p>
            )}
            <p className='mt-3'>
              What's the difference?{' '}
              <a href={null} className='blue' data-bs-toggle='modal' data-bs-target='#claim_modal'>
                Click here.
              </a>
            </p>
          </div>

          <div className='add_bottom'>
            <div className='text-center'>
              {customFunction.isUserLogin() && customFunction.userToken() ? (
                stateData.isUserDetailsReceived ? (
                  <button
                    href={null}
                    disabled={!stateData?.user_type}
                    onClick={!stateData?.user_type ? () => {} : () => continueHandler()}
                    className={'continue btn' + (!stateData?.user_type ? ' disabled' : '')}
                  >
                    Continue
                  </button>
                ) : (
                  <button
                    href={null}
                    disabled={!stateData?.user_type}
                    className={'continue btn' + (!stateData?.user_type ? ' disabled' : '')}
                  >
                    Loading
                  </button>
                )
              ) : (
                <button
                  href={null}
                  disabled={!stateData?.user_type}
                  onClick={!stateData?.user_type ? () => {} : () => continueHandler()}
                  className={'continue btn' + (!stateData?.user_type ? ' disabled' : '')}
                >
                  Continue
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* ACTIVATION MODAL */}
      <div className='modal fade reset_password_blk' id='claim_modal' role='dialog'>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h4 className='modal-title'>Business vs. Organization</h4>
              <button type='button' className='btn-close' data-bs-dismiss='modal'></button>
            </div>
            <div className='modal-body pt-0'>
              <p className='text-start'>
                Pick 'Organization' if you are a Charity, Church, Alumni Association, Club,
                Fraternity, 501C-3, or if you have 'Members'
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* ACTIVATION MODAL OVER */}
    </AppLayout>
  )
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ signupType }, dispatch)
}

export default connect(null, mapDispatchToProps)(Claim)
