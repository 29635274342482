import React, { useEffect, useRef, useState } from 'react'
import $ from 'jquery'
import { toaster } from '../../../services/customFunction'
import { Field, reduxForm } from 'redux-form'
import * as validation from '../../../services/formValidation'
import renderInputField from '../../Form/Input'
import { sendOtp, verifyOtp } from '../../../services/authService'

export const showVerifyOtpModal = (type) => {
  $('#verify_otp_modal').modal('show').attr('type', type)
}

const VerifyOtpModal = ({ authUserData, type, onVerify, otpSentTo, invalid }) => {
  const [status, setStatus] = useState('idle')
  const [resendTimer, setResendTimer] = useState(90)
  const [apiError, setApiError] = useState('')
  const [otpCode, setOtpCode] = useState('')
  const [changedType, setChangedType] = useState()
  const intervalIdRef = useRef()

  useEffect(() => {
    // console.log(authUserData)
    authUserData?.user_id && startTimer()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type])

  useEffect(() => {
    if (!authUserData?.user_id) return
    if (resendTimer === 90) {
      intervalIdRef.current = setInterval(timer, 1000)
    }
    ;(resendTimer < 1 || status === 'change_method') && clearInterval(intervalIdRef.current)
  }, [resendTimer, authUserData, status])

  const startTimer = () => {
    setResendTimer(90)
  }

  const timer = () => {
    setResendTimer((st) => st - 1)
  }

  const handleChange = (e) => {
    !!apiError && setApiError('')
    setOtpCode(e?.target?.value ?? '')
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    setStatus('submitting')
    const postData = {
      user_id: authUserData.user_id,
      type: type,
      code: otpCode,
    }
    try {
      const { data } = await verifyOtp(postData)
      // console.log(data)
      if (data?.confirmed) {
        $('#verify_otp_modal').modal('hide')
        $('body').removeClass('modal-open')
        $('.modal-backdrop').remove()
        $('body').css({ 'padding-right': '' })
        setOtpCode(e?.target?.value ?? '')

        setTimeout(() => {
          setStatus('idle')
          onVerify(data?.otp_token)
        }, 150)
        // toaster(
        //   'success',
        //   'Your report has been submitted. It is currently being reviewed the the Admins.',
        // )
      } else if (data?.data?.error || data?.error) {
        setApiError(data.message)
        setStatus('idle')
      }
    } catch (error) {
      console.log(error)
      setStatus('idle')
      toaster(
        'error',
        'Something went wrong. Please try again or contact an Admin if the issue persists.',
      )
    }
  }

  const getResendButtonText = () => {
    return status === 'submitting'
      ? 'Loading...'
      : resendTimer > 0
      ? `Resend code in ${resendTimer} ${resendTimer === 1 ? 'second' : 'seconds'}`
      : 'Resend code'
  }

  const getMaskedEmail = (email) => {
    const parts = email?.split('@')
    const emailName = parts?.[0]
    const maskedEmailName =
      emailName?.substring(0, Math.min(4, Math.floor((emailName?.length ?? 0) / 2))) + '*****@'
    return maskedEmailName + parts?.[1] ?? ''
  }

  const getMaskedPhone = (phone) => {
    return '+1 XXX-XXX-XX' + phone?.replace(/[^0-9]/g, '')?.slice(-2) ?? 'XX'
  }

  // const getMaskedOtpTo = () => {
  //   if (type === 'email' && !!otpSentTo) {
  //     return getMaskedEmail()
  //   } else if (type === 'phone' && !!otpSentTo) {
  //     return getMaskedPhone()
  //   }
  // }

  const resendOtp = async (otpType = type) => {
    startTimer()
    const { data } = await sendOtp({ user_id: authUserData.user_id, type: otpType })
    if (data?.sent_to) {
      toaster('success', `A new OTP has been sent to your ${type === 'sms' ? 'phone' : 'email'}`)
    }
  }

  const changeOtpMethod = (type) => {
    setChangedType(type)
    !!authUserData?.user_id && resendOtp(type)
    setStatus('idle')
  }

  const isLoading = status === 'submitting'
  // const otpTo = getMaskedOtpTo(changedType ?? type)
  const isDisabled = status === 'submitting' || !!apiError || !otpCode || invalid
  const isResendButtonDisabled = isLoading || resendTimer > 0
  const otpType = changedType ?? type
  return (
    <div className='modal fade verify_otp_modal' id='verify_otp_modal' role='dialog'>
      <div className='modal-dialog'>
        <div className='modal-content'>
          <div className='modal-header align-items-start pb-0'>
            <h5>Two-Factor Authentication Required</h5>
            <button type='button' className='btn-close' data-bs-dismiss='modal'></button>
          </div>
          <div className='modal-body' style={{ textAlign: 'center' }}>
            <h6 className='text-start'>
              You've asked us to require a 6-digit login code when anyone tries to access your
              account from a new device or browser.
            </h6>
            {status === 'change_method' ? (
              <>
                <h6 className='text-start mt-3' style={{ fontWeight: 700 }}>
                  Try another way to sign in
                </h6>
                <div className='row flex-column mx-4'>
                  {authUserData?.phone ? (
                    <div
                      className='text-start col mt-2 verifyOtpModal-chooseMethod'
                      onClick={() => changeOtpMethod('phone')}
                    >
                      <p className='mb-0'>
                        <span style={{ fontWeight: 700 }}>Phone</span> - Get a verification code at{' '}
                        <span style={{ fontWeight: 700 }}>
                          {getMaskedPhone(authUserData?.phone)}
                        </span>
                      </p>
                    </div>
                  ) : null}

                  <div
                    className='text-start col my-2 verifyOtpModal-chooseMethod'
                    onClick={() => changeOtpMethod('email')}
                  >
                    <p className='mb-0'>
                      <span style={{ fontWeight: 700 }}>Email</span> - Get a verification code at{' '}
                      <span style={{ fontWeight: 700 }}>{getMaskedEmail(authUserData?.email)}</span>
                    </p>
                  </div>
                </div>
              </>
            ) : (
              <>
                <h6 className='text-start'>
                  Enter the 6-digit code we sent to{' '}
                  {otpType === 'sms' || otpType === 'phone'
                    ? getMaskedPhone(authUserData?.phone)
                    : getMaskedEmail(authUserData?.email)}
                </h6>
                <p
                  onClick={isResendButtonDisabled ? () => {} : resendOtp}
                  className='text-start mt-1 mb-1'
                  style={{
                    fontSize: 12,
                    color: isResendButtonDisabled ? '#65757d' : '#6684f2',
                    opacity: isResendButtonDisabled ? 0.5 : 1,
                    fontWeight: 700,
                    cursor: isResendButtonDisabled ? 'not-allowed' : 'pointer',
                  }}
                >
                  {getResendButtonText()}
                </p>
                <div className='que4 mt-3'>
                  <div>
                    <Field
                      name='otp'
                      type='text'
                      className='form-control password1'
                      component={renderInputField}
                      customError={apiError}
                      onChange={handleChange}
                      value={otpCode}
                      valueKey={otpCode}
                      validate={[validation.required, validation.otp]}
                      label='Login code'
                      placeholder='Enter your login code'
                      autoComplete='off'
                    />
                  </div>
                </div>
                <p
                  onClick={() => setStatus('change_method')}
                  className='text-start ms-3 mt-2'
                  style={{ fontSize: 12, color: '#6684f2', fontWeight: 700, cursor: 'pointer' }}
                >
                  Try another way
                </p>
                <button
                  onClick={isDisabled ? () => {} : handleSubmit}
                  className='btn btn-lg btn-success submit_btn mt-2'
                  disabled={isDisabled}
                  style={{
                    background: '#544cf9',
                    color: '#fff',
                    borderRadius: 30,
                    opacity: isDisabled ? 0.5 : 1,
                    cursor: isDisabled ? 'not-allowed' : 'pointer',
                    fontWeight: 600,
                    lineHeight: 0,
                    maxWidth: '100%',
                    fontSize: 14,
                    padding: '15px 20px',
                    height: 48,
                  }}
                >
                  {isLoading ? 'Loading...' : 'Submit'}
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default reduxForm({
  form: 'verifyOtpForm',
  enableReinitialize: true,
})(VerifyOtpModal)
