import { motion } from 'framer-motion'
import React from 'react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import LoadingSuggestion from './LoadingSuggestion'
import Suggestion from './Suggestion'

const sugVariants = {
  open: {
    transition: { staggerChildren: 0.07, delayChildren: 0 },
  },
  closed: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 },
  },
}

const SearchSuggestions = ({
  animate,
  suggestions,
  handleClickOnSuggestion,
  searchingForSuggestions,
}) => {
  const { fetchedCategories, whileSearching, ...sugs } = suggestions ?? {}

  return (
    <div
      style={{
        width: '100%',
        // paddingTop: !useGlobalSearch ? '2.5rem' : 0,
        minHeight: 340,
      }}
    >
      <motion.div
        className='row m-0 justify-content-center flex-column'
        animate={animate}
        initial='closed'
        variants={sugVariants}
      >
        {searchingForSuggestions && !sugs?.categories?.length && !sugs?.defaults?.length
          ? [...Array(sugs?.users?.length ? 1 : 3)].map((val, idx) => (
              <LoadingSuggestion key={'loading-suggestion-skeleton-' + idx} isCat />
            ))
          : null}
        {Object.keys(sugs ?? {}).filter((key) => sugs[key]?.length)?.length
          ? Object.keys(sugs)
              .map((key) => sugs[key])
              ?.reduce((acc, curr) => {
                return (acc = [...(acc || []), ...(curr || [])])
              }, [])
              ?.map((suggestion) => (
                <Suggestion
                  key={suggestion?._id ?? suggestion}
                  suggestion={suggestion}
                  handleClick={handleClickOnSuggestion}
                />
              ))
          : null}
        {searchingForSuggestions && !sugs?.users?.length
          ? [...Array(5)].map((val, idx) => (
              <LoadingSuggestion key={'loading-suggestion-skeleton-' + idx} />
            ))
          : null}
      </motion.div>
    </div>
  )
}

export default SearchSuggestions
