import React from 'react'
import MapWithAMarker from '../Map'

const SearchResultsMap = ({ markers, isSearchPage }) => {
  return (
    <div className='col-md-4'>
      <div className='map'>
        <div className='map_blk'>
          <h4 className='varela'>Map</h4>
          <div id='googlemap'>
            <MapWithAMarker
              markers={markers}
              defaultOptions={{ mapTypeControl: false }}
              loadingElement={<div style={{ height: `100%` }} />}
              containerElement={<div style={{ height: `500px` }} />}
              mapElement={<div style={{ height: `100%` }} />}
              isSearchPage={isSearchPage}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default SearchResultsMap
