import { Link } from 'react-router-dom'
import { DEFAULT_PROFILE_PICTURE, IMG_PATH } from '../../../services/const'
import CreatePost from '../CreatePost'
import { getUser, setOverFlow } from '../../../services/customFunction'
import './CurrentUser.scss'
import React, { useState } from 'react'
import ManagePhotosOrVideos from '../ManagePhotosOrVideos'

const CurrentUser = () => {
  const login_user = getUser()
  // console.log('render:', login_user)
  let profile_picture_small = login_user?.user_detail?.profile_picture_small
    ? login_user?.user_detail?.profile_picture_small
    : DEFAULT_PROFILE_PICTURE
  const [showCreatePostModal, setShowCreatePostModal] = useState(false)
  const onCloseButtonClick = () => {
    setShowCreatePostModal(false)
    setShowAddPhoto(false)
    setShowAddVideo(false)
    setOverFlow(false)
  }
  const onOpenCreatePostButtonClick = () => {
    setShowCreatePostModal(true)
  }
  const [showAddPhoto, setShowAddPhoto] = useState(false)
  const [showAddVideo, setShowAddVideo] = useState(false)
  return (
    <div>
      <div className='createUserMain'>
        <div className='userTopHeader'>
          <div className='img_block orange_border userTopImg'>
            <Link className='' to={process.env.PUBLIC_URL + '/' + login_user?.slug}>
              <img src={profile_picture_small} alt='' />
            </Link>
          </div>

          <input
            className='input border border-light createUserInput'
            type='button'
            value='Start a post'
            onClick={() => {
              onOpenCreatePostButtonClick(true)
            }}
          />
        </div>
        <ManagePhotosOrVideos showCreatePostModalProp={true}></ManagePhotosOrVideos>
      </div>
      {showCreatePostModal ? (
        <CreatePost
          showDirectAddPhoto={showAddPhoto}
          showDirectAddVideo={showAddVideo}
          onCloseButtonClick={onCloseButtonClick}
        />
      ) : (
        ''
      )}
    </div>
  )
}
export default CurrentUser
