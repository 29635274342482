import React from 'react'

const PromotionButton = ({ userId }) => {
  return (
    <a
      className='profile-header-promotion-button-wrapper'
      href={`/promotion/${userId}`}
      target='_blank'
      rel='noopener noreferrer'
    >
      <img
        className='img-fluid'
        src={process.env.PUBLIC_URL + '/img/promotions/fiesta_hermosa/promotion-button.png'}
        alt=''
      />
      {/* <div className='profile-header-promotion-button d-flex flex-column align-items-center position-relative'>
            <span className='ellipse-overlay'></span>
            <ItiText className='bordered-white-text' />
            <span className='shadowed-text' style={{ color: 'white' }}>
              Memorial Week
            </span>
            <span
              className='shadowed-text'
              style={{ lineHeight: 1, fontSize: 20, textTransform: 'uppercase', color: '#f8c735' }}
            >
              Giveaway!
            </span>
            <span className='bordered-white-text' style={{ color: '#DF0000' }}>
              Enter Here
            </span>
          </div> */}
    </a>
  )
}

export default PromotionButton
