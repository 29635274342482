import React, { Component } from 'react'
import AppLayout from '../AppLayout'
import './privacy.scss'
import { PRIVACY_TITLE } from '../../services/titleConst'
import LoginModal from '../LoginModal/LoginModal'
import { Helmet } from 'react-helmet'

class PromotionPrivacy extends Component {
  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {
    return (
      <AppLayout routeActive={this.props.location.pathname}>
        <Helmet>
          <title>{PRIVACY_TITLE}</title>
        </Helmet>
        <section className='bg-grey it-privacy-terms-page'>
          <div className='container'>
            <h3 className='varela mb-3'>Privacy Policy</h3>
            <div className='white-pane privacy promotion-privacy'>
              <h2>Introduction</h2>
              <p>
                iTRUSTit Social, Inc. ("iTRUSTit", "ITRUSTIT", "WE" or "US") provides software that
                helps businesses and organizations (our "CUSTOMERS") run marketing campaigns that
                are promoted to customers ("CAMPAIGN USERS").
              </p>
              <p>
                Security &amp; Privacy is an important part of our platform that we take very
                seriously. Therefore, this Promotion Privacy Policy governs the manner in which
                iTRUSTit collects, uses, maintains and discloses information collected from users
                (each, a "User") of the https://iTRUSTit.com website ("Site"). This privacy policy
                applies to the Site and all products or services offered by iTRUSTit.
              </p>
              <p>
                In order to ensure confidentiality and lawful processing of its, Visitors, Customers
                and Campaign Users personal data, iTRUSTit in its capacity of a data controller and
                of a processor, conducts its activities in strict compliance with the requirements
                set in the Council on the protection of natural persons with regard to the
                processing of personal data and on the free movement of this data (GDPR) and the
                California Consumer Privacy Act (CCPA).
              </p>
              <p>
                This Promotion Privacy Policy applies to all Customers, Campaign Users and Visitors
                of our Site and Services.
              </p>
              <h2>What Information We Collect and How We Use It</h2>
              <p>We may collect the following types of information about you on our Website</p>
              <h3>Customers and Visitors of our Site</h3>
              <p>
                We may collect your personal data in a variety of ways, including, but not limited
                to, when you visit our Site, register on the Site, subscribe to our newsletter, fill
                out a form, or in connection with other activities, services, features or resources
                we make available.
              </p>
              <p>
                <strong>Registration and Contact Information:</strong> As appropriate and depending
                on the Services you would like to use, Customers and Visitors may be asked to
                provide us with full name, username, email, address, credit card, phone number or
                billing information.
              </p>
              <p>
                <strong>Payment Information:</strong> When you purchase the Services, we will also
                collect transaction information, which may include your credit card information,
                billing and mailing address, and other payment-related information.
              </p>
              <p>
                <strong>Technical, Usage and Location Information:</strong> We automatically collect
                information on how you interact with the Site, such as the IP address from which you
                access the Site, date and time, information about your browser, operating system and
                computer or device, pages viewed and items clicked. We may also collect location
                information, including location information automatically provided by your computer
                or device. We may use cookies and similar technologies to collect some of this
                information and persist your login to our Site.
              </p>
              <p>
                <strong>Third Party Platforms.</strong> We may collect information when you interact
                with our advertisements and other content on third-party sites or platforms, such as
                social networking sites. This may include information such as Facebook Likes,
                profile information gathered from social networking sites during signup or the fact
                that you viewed or interacted with our content.
              </p>
              <p>
                <strong>Other Information.</strong> We may collect other information from you that
                is not specifically listed here. We may use any such information in accordance with
                this Promotion Privacy Policy or as otherwise permitted by you.
              </p>
              <h3>iTRUSTit Campaign Users</h3>
              <p>
                Companies that use iTRUSTit to run campaigns ("Campaign") may require Campaign Users
                to supply certain information as part of a Campaign. iTRUSTit processes this data in
                accordance with our Promotion Privacy Policy.
              </p>
              <p>
                iTRUSTit is a consent-based marketing platform, this means that in order for us to
                process data on behalf of users they must provide it via explicit consent first.
                This might be as simple as filling out a form or something more complex like
                connecting Facebook to their iTRUSTit account.
              </p>
              <p>
                iTRUSTit also does not collect, retain or share end user information including IP
                addresses, unique user identifiers, or personally identifiable information gathered
                on sites or apps not owned by iTRUSTit, except for the limited purpose of
                determining conversion rates &amp; detecting fraud, in which case all personally
                identifiable information remains anonymous until you explicitly consent by entering
                a campaign.
              </p>
              <p>
                iTRUSTit does not track or provide any personal information to Companies that run
                Campaigns until you explicitly provide consent. This means that you do not expose
                any personal information to either iTRUSTit or Companies until you actively
                participate in their Campaign (even if you are still logged into iTRUSTit or not).
              </p>
              <p>
                <strong>IP Addresses:</strong> IP addresses may be collected Anonymously (last octet
                removed) for reporting and usage purposes. When you consent to a specific campaign
                your IP address may be linked to the associated record and allows iTRUSTit to filter
                records that share an IP address. Your IP address is never shared publicly within
                the app and always remains hidden.
              </p>
              <p>
                <strong>Name, Email &amp; Form Fields:</strong> By default, each Campaign may
                collect basic login information from users that includes their full name and email
                address. By entering campaigns you are accepting that iTRUSTit will have access to
                the information you provide.
              </p>
              <p>
                <strong>Social Logins:</strong> iTRUSTit also allows Campaign Users to connect
                social accounts to your profile. Companies that have social logins enabled on their
                Campaigns will be able to see basic information related to accounts that you
                connect. This includes but is not limited to your name, email address, social
                profile URL's and associated profile photos.
              </p>
              <ul>
                <li>
                  <p>
                    iTRUSTit will never use or post to these accounts without your permission and
                    will always require your interaction with the widget to execute any specific
                    actions
                  </p>
                </li>
                <li>
                  <p>
                    iTRUSTit will only ever use information that you have made public or
                    specifically allowed us to access when you accept permissions
                  </p>
                </li>
                <li>
                  <p>
                    iTRUSTit does not know or store any passwords associated with your social
                    accounts
                  </p>
                </li>
                <li>
                  <p>
                    If you wish to remove the link between iTRUSTit and any social network, you can
                    either remove the iTRUSTit Competitions app inside that specific social network
                    or unlink it from the Edit tab inside the Campaign
                  </p>
                </li>
              </ul>
              <p>
                <strong>Persistent Logins:</strong> iTRUSTit is a distributed platform, which means
                that if a Campaign User has previously logged into our widget or to iTRUSTit.com
                they will continue to stay logged in via third party Cookies until they specifically
                log out. This is designed to make it easy for users to enter the campaign or
                itrustit.com without having to re-enter their details again.
              </p>
              <h2>Cookies</h2>
              <p>
                Our Site may use both first and third party Cookies to enhance User experience. A
                User's web browser places a cookie on their hard drive for record-keeping purposes
                and sometimes to track information about them. Users may choose to set their web
                browser to refuse cookies, or to alert when cookies are being sent. If they do so,
                please note that some parts of the Site and Campaigns may cease to function.
              </p>
              <p>
                iTRUSTit Campaigns may use Cookies to track information about users in order to
                serve them content. This includes but is not limited to:
              </p>
              <ul>
                <li>
                  <p>Persist Customers sessions that are logged into iTRUSTit</p>
                </li>
                <li>
                  <p>
                    Persist Campaign Users sessions that are logged into campaigns (either on
                    iTRUSTit.com or embedded via iFrames)
                  </p>
                </li>
                <li>
                  <p>Tracking and awarding credit for referring users </p>
                </li>
                <li>
                  <p>Tracking browser behaviors to show Capture campaigns</p>
                </li>
                <li>
                  <p>Tracked users referred by our Referral Program</p>
                </li>
              </ul>
              <h2 className='mt-2'>Device Fingerprinting</h2>
              <p>
                Device fingerprinting is a process by which a fingerprint of a device is captured
                when visiting a website.
              </p>
              <p>
                iTRUSTit may use 3rd party services to gather a number of data points from a
                iTRUSTit Campaign Users computer, such as operating system version, browser version,
                screen resolution, plug-ins &amp; language. This unique ID is then transmitted when
                iTRUSTit Campaign Users consent by providing their details when entering a campaign.
              </p>
              <p>
                The information collected via Device Fingerprinting is used to identify patterns of
                fraudulent behavior by iTRUSTit Campaign Users that violate our Terms of Service.
                This includes trying to cheat by creating multiple accounts, referring your own
                devices or accounts into a Campaign or attempting to redeem a Reward that is limited
                to one per person.
              </p>
              <p>
                iTRUSTit does not use this information to track or identify users on sites or apps
                not owned by iTRUSTit or for any other purpose than to detect fraud &amp; protect
                the integrity of Campaigns, nor do we use the itrustit.com domain to fingerprint on
                3rd party domains.
              </p>
              <h2>How We Use Collected Information</h2>
              <p>
                iTRUSTit may collect and use User's personal information for the following purposes:
              </p>
              <h3>iTRUSTit Website Users</h3>
              <ul>
                <li>
                  <p>
                    To administer our Website and for internal operations, including
                    troubleshooting, data analysis, testing, research, statistical and survey
                    purposes;
                  </p>
                </li>
                <li>
                  <p>
                    To write or display content such as case studies or customer testimonials on our
                    Site;
                  </p>
                </li>
                <li>
                  <p>
                    To improve iTRUSTit to ensure that content is presented in the most effective
                    manner for you and for your Device;
                  </p>
                </li>
                <li>
                  <p>
                    To allow you to participate in interactive features of our service, when you
                    choose to do so;
                  </p>
                </li>
                <li>
                  <p>
                    To send periodic emails: We may use the email address to send User information
                    and updates pertaining to their subscription. It may also be used to respond to
                    their inquiries, questions, and/or other requests.
                  </p>
                </li>
              </ul>
              <h3>iTRUSTit Campaign Users</h3>
              <p>
                iTRUSTit does not own or use any identifiable data provided by Campaign Users for
                any reason other than to:
              </p>
              <ul>
                <li>
                  <p>
                    Ensure that your specific request is carried out and assigned to the correct
                    campaign that you provided consent for
                  </p>
                </li>
                <li>
                  <p>
                    Identify and detect fraudulent behavior or Campaign Users that are violating our
                    Terms of Service
                  </p>
                </li>
                <li>
                  <p>Provide support to Customers regarding specific Campaign User queries</p>
                </li>
                <li>
                  <p>
                    Analyze anonymized statistics or usage to help improve the iTRUSTit platform
                  </p>
                </li>
              </ul>
              <h2 className='mt-2'>Sharing Information With Third Parties</h2>
              <p>
                We do not access, sell, trade, or rent Users personal identification information to
                others. Once you provide access to specific data it belongs to the Customer running
                that specific Campaign and only that Customer. We urge you to review the Terms &amp;
                Conditions and Promotion Privacy Policy of the Campaign Owner to understand how they
                may use your data once it leaves iTRUSTit.
              </p>
              <p>
                To guarantee the legality of any transfer of personal data of EEA or Swiss citizens
                to sub-processors located outside the EEA or Switzerland, iTRUSTit applies
                additional terms via our <a href='/promotion-terms'>Data Processing Agreement.</a>
              </p>
              <h3>Customers and Visitors of our Site</h3>
              <p>
                iTRUSTit may use third party service providers to help us operate our business or
                administer activities on our behalf, such as sending out newsletters or collecting
                Website analytics. We may share your information with these third parties for those
                limited purposes provided that you have given us your permission.
              </p>
              <p>
                iTRUSTit may engage certain onward subprocessors that may process personal data
                submitted to iTRUSTit's services. These subprocessors are listed below, and may be
                updated by iTRUSTit from time to time:-
              </p>
              <ul>
                <li>
                  <p>Amazon Web Services - Privacy Shield Certified </p>
                </li>
                <li>
                  <p>Google Analytics - Privacy Shield Certified </p>
                </li>
                <li>
                  <p>Mailgun - Privacy Shield Certified </p>
                </li>
              </ul>
              <h3>iTRUSTit Campaign Owners</h3>
              <p>
                iTRUSTit offers a number of integrations with 3rd party service providers that
                enable Campaign Owners to send Campaign Users data from iTRUSTit for processing:
              </p>
              <p>These providers include:</p>
              <ul>
                <li>
                  <p>Amazon Web Services - Privacy Shield Certified</p>
                </li>
                <li>
                  <p>AWeber - Privacy Shield Certified</p>
                </li>
                <li>
                  <p>Bit.ly - Privacy Shield Certified</p>
                </li>
                <li>
                  <p>Google Analytics - Privacy Shield Certified</p>
                </li>
                <li>
                  <p>MailChimp - Privacy Shield Certified</p>
                </li>
                <li>
                  <p>Mailgun - Privacy Shield Certified</p>
                </li>
              </ul>
              <h3>Third Party Websites</h3>
              <p>
                Users may find advertising or other content on our Site that link to the sites and
                services of our partners, suppliers, advertisers, sponsors, licensors and other
                third parties. We do not control the content or links that appear on these sites and
                are not responsible for the practices employed by websites linked to or from our
                Site. In addition, these sites or services, including their content and links, may
                be constantly changing. These sites and services may have their own privacy policies
                and customer service policies. Browsing and interaction on any other website,
                including websites which have a link to our Site, is subject to that website's own
                terms and policies.
              </p>
              <h3>Legal Disclosures</h3>
              <p>
                In certain limited circumstances, we may also have to disclose your personal data to
                public authorities and other third parties, if the disclosure is in response to
                lawful requests made by such public authorities, including to conform with national
                security or law enforcement requirements. Your personal information may also be
                disclosed to other third parties when compelled to do so by government authorities
                or required by law or regulation including, but not limited to, in response to court
                orders and subpoenas.
              </p>
              <p>
                iTRUSTit may also share personal data with third parties to prevent, investigate or
                take action regarding illegal activities, suspected fraud, situations involving
                potential threats to the physical safety of any person, violations of our Terms of
                Service or any other agreement related to the Services, or as otherwise required by
                law.
              </p>
              <h2>Information Security</h2>
              <p>
                We use appropriate technical and organizational security measures to protect any
                personal information we process about visitors to our Website against unauthorized
                access, disclosure, alteration, and destruction. However, please note that no
                Internet transmission can ever be guaranteed 100% secure, and so we encourage you to
                take care when disclosing personal information online and to use readily available
                tools, such as Internet firewalls, secure e-mail and similar technologies to protect
                yourself online.
              </p>
              <p>
                In case of an unauthorized security intrusion that materially affects you or the
                people on your mailing list iTRUSTit will notify you as soon as possible and will
                within reasonable time report the action we took in response.
              </p>
              <h2>EU and EEA residents</h2>
              <p>
                The servers where iTRUSTit stores all personal data are located in the United
                States. If you are located in a country member of either the EU or the EEA, please
                be aware that any information provided to us, including personal information, will
                be transferred from your country of origin to the United States. Except in the case
                of data transfers under the EU-US Privacy Shield and the Swiss-US Privacy Shield, we
                may ask for your express consent to provide such data to us or allow us to collect
                such data.
              </p>
              <h2>International Transfer of Personal Data</h2>
              <p>
                All personal data we process is stored directly, without any subsequent transfers,
                on US-based servers.
              </p>
              <p>
                To additionally guarantee to our Customers and their European Campaign users (data
                subjects) the legality of our processing services and the international transfers of
                the personal data, iTRUSTit has undertaken GDPR compliant contractual commitments,
                binding us, as a data processor, to protect the data privacy and to ensure the most
                adequate level of data security.
              </p>
              <p>
                Please note that the country where we operate may have privacy and data protection
                laws that differ from, and are potentially less protective than, the laws of your
                country. You agree to this risk when you create an account with Us and you sign up,
                login or enter the promotion or site and acknowledge and agree to the "Terms &amp;
                Conditions, Privacy Policy, Terms of Service and/or Promotion Privacy Policy" to
                data transfers, irrespective of which country you live in. If you later wish to
                withdraw your consent, you can delete your iTRUSTit account as described in the
                "Your Rights" section.
              </p>
              <h3>Data Processing Agreement</h3>
              <p>
                If you are our Customer and your company is either located in the European Economic
                Area (EEA) or Switzerland, or your company, by using our services, is processing the
                data of anyone who is in the EEA or Switzerland, then you can request our GDPR
                compliant Data Processing Agreement by submitting a support ticket from the Support
                tab inside your account.
              </p>
              <h3>Data Retention Periods</h3>
              <p>
                iTRUSTit will not retain data longer than is necessary to fulfill the purposes for
                which it was collected or as required by applicable laws or regulations. For
                Campaign data, iTRUSTit's Customers have control of the purpose for collecting data,
                and the duration for which the Personal Data may be kept. When a User's account is
                terminated or expired, all Personal Data collected through the platform will be
                deleted, as required by applicable law.
              </p>
              <p>
                If a Customer or User account has been suspended for a Terms of Service violation,
                iTRUSTit will retain the information necessary to continue to enforce this
                suspension for up to 10 years.
              </p>
              <h3>Privacy Controls &amp; Choices</h3>
              <p>
                <strong>iTRUSTit Website Communication:</strong> Our Customers, Site Visitors and
                Users have a choice about how we use their personal data to get in touch with them
                and may choose to opt-out at any time by unsubscribing or changing their account
                settings.
              </p>
              <p>
                <strong>Campaign Users Notifications:</strong>We provide an easy mechanism for
                opt-ing out of any communication from campaigns inside the Post Entry Email. You can
                simply select the Unsubscribe or "Turn them off" links in your settings.
              </p>
              <p>
                <strong>Campaign Users Social Accounts:</strong> We provide a mechanism for every
                Campaign User to remove any linked social accounts from iTRUSTit via the Edit panel
                inside our campaign widget.
              </p>
              <p>
                <strong>Your Rights: </strong> We provide all Customers, Visitors and Users of our
                Site with the opportunity to request access, correction, restriction, deletion, data
                portability or oppose to any personal information that has previously been provided
                to us in connection with the use of our Website, as required by law. You can send us
                an email to <a href='mailto:info@itrustit.com'>info@itrustit.com</a>. We may request
                specific information from you to confirm your identity.
              </p>
              <h3>iTRUSTit's Data Protection Officer</h3>
              <p>
                iTRUSTit has a Data Protection Officer who is responsible for matters relating to
                privacy and data protection. This Data Protection Officer can be reached at the
                following address:
              </p>
              <div className='d-flex-flex-column mb-5'>
                <div>iTRUSTit Social, Inc.</div>
                <div>Attn: Data Protection </div>
                <div>703 Pier Ave Ste. B691</div>
                <div>Hermosa Beach, CA 90254</div>
                <a href='mailto:info@itrustit.com'>info@itrustit.com</a>
              </div>
              <h2>California Consumer Privacy Act</h2>
              <p>
                For our users or customers living or doing business in California, iTRUSTit is
                subject to the California Consumer Privacy Act ("CCPA").
              </p>
              <ul>
                <li>
                  <p>
                    iTRUSTit Does Not Sell Your Personal Information. You can read more about this
                    in our Sharing Information With Third Parties section.
                  </p>
                </li>
                <li>
                  <p>Cookie Policy. You can learn about this in our Cookies section.</p>
                </li>
                <li>
                  <p>
                    Accuracy and Access To Your Personal Information. If you believe that Personal
                    Information iTRUSTit holds about you is inaccurate, you may modify or correct
                    your Personal Information the Edit tab for iTRUSTit Campaign Users, the User
                    Settings tab for iTRUSTit Customers or by contacting us at:{' '}
                    <a href='mailto:info@itrustit.com'>info@itrustit.com</a>. We may request
                    specific information from you to confirm your identity.{' '}
                  </p>
                </li>
                <li>
                  <p>
                    Deleting Your Personal Information. You can learn about this in our Privacy
                    Controls &amp; Choices section.
                  </p>
                </li>
              </ul>
              <h2 className='mt-2'>Compliance With Children's Online Privacy Protection Act</h2>
              <p>
                Because the nature of our Site and Services does not appeal to children under the
                age of 13, iTRUSTit does not knowingly acquire or receive personal data from
                children under 13. We do not intentionally process any information, including
                Personal Data, from children or other individuals who are not legally able to use
                our Site and Services. If we later learn that any user of our Service is under the
                age of 13 and that we have obtained his/her Personal Data, we will promptly delete
                it from our database and will take further steps to restrict that individual from
                future access to our Services, unless we are legally obligated to retain such data.
              </p>
              <p>
                If you are a parent or legal guardian of a child under 13 and believe that we might
                have any information from or about such child, please contact us at the email or
                mailing address provided at the end of this Privacy Policy.
              </p>
              <h2>Business Transfers</h2>
              <p>
                In some cases, we may choose to buy or sell assets. In these types of transactions,
                customer information is typically one of the business assets that are transferred.
                Moreover, if iTRUSTit, or substantially all of its assets were to be acquired, or in
                the unlikely event that iTRUSTit goes out of business or enters bankruptcy, customer
                information would be one of the assets that is transferred or acquired by a third
                party. You acknowledge that such transfers may occur, and that any acquire of
                iTRUSTit or its assets may continue to use your Personal Information as set forth in
                this policy.
              </p>
              <h2>Changes To This Privacy Policy</h2>
              <p>
                iTRUSTit has the discretion to update this privacy policy at any time. When we do,
                we will revise the updated date at the bottom of this page. We encourage Users to
                frequently check this page for any changes to stay informed about how we are helping
                to protect the personal information we collect. You acknowledge and agree that it is
                your responsibility to review this privacy policy periodically and become aware of
                modifications.
              </p>
              <h2>Your Acceptance Of These Terms</h2>
              <p>
                By using this Site, you signify your acceptance of this policy and terms &amp;
                conditions of use. If you do not agree to this policy, please do not use our Site.
                Your continued use of the Site following the posting of changes to this policy will
                be deemed your acceptance of those changes.
              </p>
              <h2>Contacting Us</h2>
              <p>
                If you have any questions about this Promotion Privacy Policy, the practices of this
                site, or your dealings with this site, please contact us at{' '}
                <a href='mailto:info@itrustit.com'>info@itrustit.com</a>
              </p>
              <small>Updated on May 24, 2022</small>
            </div>
          </div>
        </section>
        <LoginModal
          isLogin={(e) =>
            function (data) {
              this.props.isLogin(data)
            }
          }
          {...this.props}
        />
      </AppLayout>
    )
  }
}

export default PromotionPrivacy
