import React from 'react'
import { showResendVerificationEmailModal } from '../ResendVerificationEmailModal/ResendVerificationEmailModal'

const DuplicateClaimRequestEmailError = () => {
  return (
    <span>
      There is already a claim in process with this email address. Each iTRUSTit account must have a
      unique email address. Please try a different email or{' '}
      <strong
        className='fw-bold'
        style={{ textDecoration: 'underline', textTransform: 'uppercase', cursor: 'pointer' }}
        onClick={showResendVerificationEmailModal}
      >
        Click Here
      </strong>{' '}
      to re-send the account activation email.
    </span>
  )
}

export default DuplicateClaimRequestEmailError
