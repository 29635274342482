import axios from 'axios'
import { customLogger } from '../utils/CustomLogger'
import * as environemnt from './environment'
import { userToken } from './customFunction'

export const countryList = () => {
  return axios
    .get(environemnt.BASE_API + environemnt.COUNTRY_LIST)
    .then(function (response) {
      return response.data
    })
    .catch(function (error) {
      customLogger(error)
      return error.response
    })
}

export const stateList = (id) => {
  return axios
    .get(environemnt.BASE_API + environemnt.STATE_LIST + id)
    .then(function (response) {
      return response.data
    })
    .catch(function (error) {
      customLogger(error)
      return error.response
    })
}

export const cityList = (id) => {
  return axios
    .get(environemnt.BASE_API + environemnt.CITY_LIST + id)
    .then(function (response) {
      return response.data
    })
    .catch(function (error) {
      customLogger(error)
      return error.response
    })
}
export const categoryList = () => {
  return axios
    .get(environemnt.BASE_API + environemnt.CATEGORY_LIST)
    .then(function (response) {
      return response.data
    })
    .catch(function (error) {
      customLogger(error)
      return error.response
    })
}
export const singleCategoryList = (id) => {
  return (
    axios
      .get(environemnt.BASE_API + environemnt.CATEGORY_BY_PARENT_ID + id)
      //.get(environemnt.BASE_API + environemnt.SINGLE_CATEGORY_LIST)
      .then(function (response) {
        return response.data
      })
      .catch(function (error) {
        customLogger(error)
        return error.response
      })
  )
}

export const businessSignup = (request) => {
  return axios
    .post(environemnt.BASE_API + environemnt.BUSINESS_SIGNUP, request)
    .then(function (response) {
      return response.data
    })
    .catch(function (error) {
      customLogger(error)
      return error.response
    })
}

export const organizationSignup = (request) => {
  return axios
    .post(environemnt.BASE_API + environemnt.ORGANIZATION_SIGNUP, request)
    .then(function (response) {
      return response.data
    })
    .catch(function (error) {
      customLogger(error)
      return error.response
    })
}

export const categoryListSelected = (token) => {
  return axios
    .get(environemnt.BASE_API + environemnt.UPDATE_USER_CATEGORY, {
      headers: { Authorization: token, Accept: 'application/x.iTRUSTit.v1+json' },
    })
    .then(function (response) {
      return response.data
    })
    .catch(function (error) {
      customLogger(error)
      return error.response
    })
}

export const claimBusiness = (request) => {
  return axios
    .post(environemnt.BASE_API + environemnt.CLAIM_BUSINESS + request.user_id, request, {
      headers: { Authorization: userToken(), Accept: 'application/x.iTRUSTit.v1+json' },
    })
    .then(function (response) {
      return response
    })
    .catch(function (error) {
      customLogger(error)
      return error.response
    })
}

export const claimActivationLinkResend = (request) => {
  return axios
    .post(
      environemnt.BASE_API +
        environemnt.CLAIM_BUSINESS +
        request.user_id +
        environemnt.ACTIVATION_LINK_RESEND,
      request,
      {
        headers: { Authorization: userToken(), Accept: 'application/x.iTRUSTit.v1+json' },
      },
    )
    .then(function (response) {
      return response
    })
    .catch(function (error) {
      customLogger(error)
      return error.response
    })
}

export const findBusinessOrOrganization = (request) => {
  return axios
    .post(environemnt.BASE_API + environemnt.FIND_BUSINESS_OR_ORGANIZATION, request, {
      headers: { Authorization: userToken(), Accept: 'application/x.iTRUSTit.v1+json' },
    })
    .then(function (response) {
      return response
    })
    .catch(function (error) {
      customLogger(error)
      return error.response
    })
}

export const addUnclaimedBusinessOrOrganization = (request) => {
  return axios
    .post(environemnt.BASE_API + environemnt.ADD_UNCLAIMED_BUSINESS_OR_ORGANIZATION, request, {
      headers: { Authorization: userToken(), Accept: 'application/x.iTRUSTit.v1+json' },
    })
    .then(function (response) {
      return response
    })
    .catch(function (error) {
      customLogger(error)
      return error.response
    })
}
