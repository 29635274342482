import React, { Component } from 'react'
import { getTrustedUserListByStatus } from '../../../services/userApiService'
import { Link } from 'react-router-dom'
import './TrustedUserList.scss'
import $ from 'jquery'
import { getUser } from '../../../services/customFunction'

class TrustedUserList extends Component {
  constructor(props) {
    super(props)
    this.state = props.savedData || {
      login_user_id: getUser()?.id,
      isBlockShow: false,
      trusteUserData: [],
      totalPage: 0,
      currentPage: 1,
      total_records: 0,
      id: props.trusteUserData.id,
      type: props.trusteUserData.type,
      user_type: props.trusteUserData.user_type,
      loadingMoreData: false,
      hasMoreData: false,
    }
  }
  scrollToTop = () => {
    $('#trusted-user-list').animate(
      {
        scrollTop: 0,
      },
      500,
    )
  }
  componentDidMount() {
    // const self = this;
  }
  componentWillReceiveProps(nextProps) {
    var self = this
    // if modal no show than open it and show data
    if (nextProps.isBlockShow && !this.state.isBlockShow) {
      const id = nextProps.trusteUserData.id
      const type = nextProps.trusteUserData.type
      const user_type = nextProps.trusteUserData.user_type
      nextProps.loadingFunc && nextProps.loadingFunc(true)
      getTrustedUserListByStatus(id, type, 1).then(function (response) {
        // console.log(response);
        if (response.data.user_list.length > 0) {
          self.setState(
            {
              trusteUserData: response.data.user_list,
              isBlockShow: true,
              totalPage: response.data.total_pages,
              total_records: response.data.total_records,
              id: id,
              type: type,
              user_type: user_type,
              hasMoreData: response.data.total_records >= 12,
              currentPage: 1,
            },
            () => self.props.updateFunc && self.props.updateFunc(response.data.total_records),
          )
        } else {
          self.setState(
            {
              trusteUserData: response.data.user_list,
              isBlockShow: false,
            },
            () => self.props.updateFunc && self.props.updateFunc(0),
          )
        }
      })
    }
    // if modal already open and hover in different palace than update data
    else if (
      nextProps.isBlockShow &&
      (nextProps.trusteUserData.id !== this.state.id ||
        nextProps.trusteUserData.type !== this.state.type)
    ) {
      const id = nextProps.trusteUserData.id
      const type = nextProps.trusteUserData.type
      const user_type = nextProps.trusteUserData.user_type
      nextProps.loadingFunc && nextProps.loadingFunc(true)
      getTrustedUserListByStatus(id, type, 1).then(function (response) {
        console.log(response)
        if (response.data.user_list.length === 0) {
          self.setState(
            {
              trusteUserData: response.data.user_list,
              isBlockShow: false,
            },
            () => self.props.updateFunc && self.props.updateFunc(0),
          )
        } else {
          self.setState(
            {
              trusteUserData: response.data.user_list,
              totalPage: response.data.total_pages,
              total_records: response.data.total_records,
              id: id,
              type: type,
              user_type: user_type,
              hasMoreData: response.data.total_records >= 12,
              currentPage: 1,
            },
            () => self.props.updateFunc && self.props.updateFunc(response.data.total_records),
          )
        }
      })
    }
    if (!nextProps.isBlockShow && this.state.isBlockShow) {
      this.setState({
        isBlockShow: false,
      })
    }
  }

  loadMore = () => {
    var self = this
    var nextPage = this.state.currentPage + 1
    this.setState({
      loadingMoreData: true,
      hasMoreData: false,
      currentPage: nextPage,
    })
    getTrustedUserListByStatus(this.state.id, this.state.type, nextPage).then(function (response) {
      var joined = self.state.trusteUserData.concat(response.data.user_list)
      self.setState({
        trusteUserData: joined,
        loadingMoreData: false,
        hasMoreData: response.data.user_list.length >= 12,
      })
    })
  }
  closeDetails = () => {
    this.setState({
      isBlockShow: false,
    })
    this.props.isBlockShowSetFalse()
  }
  changeCommonMemberList = () => {
    var self = this
    const type = 'common_member'
    getTrustedUserListByStatus(this.state.id, type, 1).then(function (response) {
      if (response.data.user_list.length === 0) {
        self.setState({
          trusteUserData: response.data.user_list,
          totalPage: response.data.total_pages,
        })
      } else {
        self.setState({
          trusteUserData: response.data.user_list,
          totalPage: response.data.total_pages,
          type: type,
          hasMoreData: response.data.total_records >= 12,
          currentPage: 1,
        })
      }
    })
  }
  changeMemberList = () => {
    var self = this
    const type = this.props.trusteUserData.type ? this.props.trusteUserData.type : 'total'
    getTrustedUserListByStatus(this.state.id, type, 1).then(function (response) {
      if (response.data.user_list.length === 0) {
        self.setState({
          trusteUserData: response.data.user_list,
        })
      } else {
        self.setState({
          trusteUserData: response.data.user_list,
          totalPage: response.data.total_pages,
          total_records: response.data.total_records,
          type: type,
          hasMoreData: response.data.total_records >= 12,
          currentPage: 1,
        })
      }
    })
  }

  handleScroll = (e) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight
    if (bottom && this.state.hasMoreData) {
      this.loadMore()
    }
  }
  render() {
    var popupTitileTxt = 'Total Users'
    if (this.state.type === 'friends' || this.props.trusteUserData.type === 'friends') {
      popupTitileTxt = 'Friends'
    } else if (
      this.state.type === 'friends_of_friends' ||
      this.props.trusteUserData.type === 'friends_of_friends'
    ) {
      popupTitileTxt = 'Friends of Friends'
    }

    return (
      <div className={(!this.state.isBlockShow ? 'hiddenBlock' : '') + ' it-trust-user-hover'}>
        {this.state.isBlockShow && (
          <div className='dropdown-menu profiledropdown show it-person-hover'>
            {this.props.trusteUserData.type !== 'common_member' && (
              <h5
                className={this.state.type !== 'common_member' ? 'active' : ''}
                onClick={this.changeMemberList}
              >
                {' '}
                {this.state.total_records} {popupTitileTxt}
                <br />
                Trust this {this.state.user_type === 'business' ? 'Business' : 'Organizaion'}
              </h5>
            )}

            {this.props.trusteUserData.total_common_members > 0 && (
              <h5
                className={this.state.type === 'common_member' ? 'active' : ''}
                onClick={this.changeCommonMemberList}
              >
                {this.props.trusteUserData.total_common_members}
                {/* Organizations in Common */}
                &nbsp;Mutual Organizations
              </h5>
            )}
            <button onClick={this.closeDetails} className='close btn-close'></button>
            <div
              className='it-trust-list-height'
              id='trusted-user-list'
              onScroll={this.handleScroll}
            >
              {this.state.trusteUserData.map((obj, index) => (
                <Link
                  to={process.env.PUBLIC_URL + '/' + obj.slug}
                  key={obj.id}
                  className='it-trust-list'
                >
                  <div className='image_blk'>
                    <img
                      alt='Profile'
                      src={
                        obj.profile_picture_medium != null
                          ? obj.profile_picture_medium
                          : process.env.PUBLIC_URL + '/img/default_profile.png'
                      }
                    />
                  </div>
                  <span className='it-trust-link'>
                    {obj.user_type === 'individual'
                      ? obj.firstname + ' ' + obj.lastname
                      : obj.business_organization_name}
                  </span>
                </Link>
              ))}
            </div>
            {this.state.loadingMoreData ? (
              <div className='no_search_result' style={{ padding: 0, margin: 0 }}>
                <div className='search-page-loading-icon'>
                  {' '}
                  <i />{' '}
                </div>
              </div>
            ) : (
              !this.state.hasMoreData &&
              this.state.total_records > 12 && (
                <div onClick={this.scrollToTop} className='no_search_result back-to-top text-muted'>
                  Back to Top
                </div>
              )
            )}
            {!this.state.login_user_id ? (
              <a
                style={{ color: '#2c7dbc', paddingLeft: 20 }}
                className='mt-2 d-block'
                href={null}
                data-bs-toggle='modal'
                data-bs-target='#login_modal'
              >
                Log In or Sign Up here to see which friends trust this {this.state.user_type}
              </a>
            ) : null}
            {/* {this.state.totalPage > this.state.currentPage && (
              <button
                onClick={this.loadMore}
                type='button'
                className='load-more'
              >
                Load more
              </button>
            )} */}
          </div>
        )}
      </div>
    )
  }
}

export default TrustedUserList
