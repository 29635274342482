import React, { Component } from 'react'
import './Landing.scss'
import AppLayout from '../AppLayout'
import PropTypes from 'prop-types'
import { Redirect, Link } from 'react-router-dom'

import { INDEX_TITLE } from '../../services/titleConst'
import { toaster } from '../../services/customFunction'
import LoginModal from '../LoginModal/LoginModal'
import * as route from '../../services/routeConst'
// import * as customFunction from "../../services/customFunction";
import {
  PROFILE_PICTURE_SMALL_URL_PATH,
  GOOGLE_GEOCODING_API_KEY,
} from '../../services/environment'
import {
  DEFAULT_PROFILE_PICTURE,
  REQUIRED_MESSAGE_FOR_SEARCH,
  REQUIRED_MESSAGE_FOR_SEARCH_NO_ORG,
} from '../../services/const'
import GoogleAutocomplete from 'react-google-autocomplete'
import axios from 'axios'
import { customLogger } from '../../utils/CustomLogger'
import { runElasticSearch, runElasticSearchCategories } from '../../utils/elasticSearch'
import { Helmet } from 'react-helmet'
import parse from 'html-react-parser'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { setSearchData } from '../../actions/search'

/*eslint-disable no-script-url*/
var cityObj = []

class Landing extends Component {
  constructor(props) {
    super(props) //210930_aa-01 -read props

    this.state = {
      city: '',
      individualBusinessOrganization: '',
      isRedirectClaim: false,
      isIndividualBusinessOrganizationDisplay: false,
      individualBusinessOrganizationData: [],
      user: {},
    }
  }

  componentDidMount() {
    this.getCurrentLocationString()
    window.scrollTo(0, 0)
  }

  individualBusinessOrganizationChangeHandler = (event) => {
    let searchStr = event.target.value
    if (searchStr.length >= 2) {
      searchStr = searchStr.replace(/[\u2018\u2019]/g, "'").replace(/[\u201C\u201D]/g, '"')
      searchStr = searchStr.replace(/(')\1{1,}/, "'")
      searchStr = searchStr.replace(/(")\1{1,}/, `"`)
      //   searchStr = searchStr.trim();
    }
    //console.log('searchStr : ', searchStr);
    this.setState({ individualBusinessOrganization: searchStr }, () => {
      // clearTimeout(elasticSearchTimer);
      // elasticSearchTimer = setTimeout(this.getSearchResult, 500, this);
      //this.getSearchResult();
      this.getSearchResultForCategory()
    })
  }

  handleChangeGoogle = (event) => {
    if (event.address_components) {
      let address_component = event.address_components

      let zipcode = address_component.filter((address) => {
        return address.types[0] === 'postal_code'
      })

      let cityName = address_component.filter((address) => {
        return address.types[0] === 'locality'
      })

      let stateName = address_component.filter((address) => {
        return address.types[0] === 'administrative_area_level_1'
      })

      cityObj = []
      if (zipcode.length > 0) {
        cityObj.push({ match: { zipcode: zipcode[0].long_name } })
      }
      if (cityName.length > 0) {
        cityObj.push({ match: { city: cityName[0].long_name } })
      }
      if (stateName.length > 0) {
        cityObj.push({ match: { state: stateName[0].long_name } })
      }
    }
    this.setState({
      city: event.formatted_address ? event.formatted_address : event.name,
      // individualBusinessOrganization: "",
      isIndividualBusinessOrganizationDisplay: false,
      individualBusinessOrganizationData: [],
    })
  }

  getSearchResultForCategory = async () => {
    runElasticSearchCategories(this.state.individualBusinessOrganization, (results) => {
      this.setState({ individualBusinessOrganizationData: results }, () => this.getSearchResult())
    })
  }

  getSearchResult = async () => {
    var searchStr = this.state.individualBusinessOrganization
    if (this.state.city.length === 0) cityObj = []

    if (searchStr.length >= 2) {
      const response = await runElasticSearch(
        undefined,
        { search_string: searchStr, search_city_string: this.state.city, maxResults: 25 },
        false,
        this.state.individualBusinessOrganizationData,
      )
      response?.error && toaster('error', response?.error)
      this.setState({
        individualBusinessOrganizationData:
          response?.status === 'failed' && !response?.error ? [] : response ?? [],
      })
    } else {
      this.setState({ individualBusinessOrganizationData: [] })
    }
  }

  onIndividualBusinessOrganizationFocus = (event) => {
    //console.log('in onIndividualBusinessOrganizationFocus');
    this.setState({
      isIndividualBusinessOrganizationDisplay: true,
    })
  }

  onIndividualBusinessOrganizationBlur = (event) => {
    //console.log('in onIndividualBusinessOrganizationBlur');
    var self = this
    setTimeout(function () {
      self.setState({
        isIndividualBusinessOrganizationDisplay: false,
      })
    }, 150)
  }

  setRedirect = (routeToRedirect) => (e) => {
    if (this.state.individualBusinessOrganization !== '') {
      this.props.setSearchData({
        searchStr: this.state.individualBusinessOrganization,
        searchAddress: this.state.city,
        fromLanding: true,
      })
      setTimeout(() => {
        localStorage.setItem(
          'businessOrganizationNameSearch',
          this.state.individualBusinessOrganization,
        )
        localStorage.setItem('citySearch', this.state.city)

        this.setState({
          routeToRedirect: routeToRedirect,
        })
      }, 1000)
    } else {
      if (this.state.city === '') {
        toaster('error', REQUIRED_MESSAGE_FOR_SEARCH)
      } else if (this.state.individualBusinessOrganization === '') {
        toaster('error', REQUIRED_MESSAGE_FOR_SEARCH_NO_ORG)
      }
    }
  }

  renderRedirect = () => {
    if (this.state.routeToRedirect) {
      return <Redirect to={this.state.routeToRedirect} />
    }
  }

  individualBusinessOrganizationClicked = (data) => (event) => {
    //console.log('in individualBusinessOrganizationClicked: ', data);
    //console.log("data.name", data);
    this.setState({ individualBusinessOrganization: data.name, user: data }, () => {
      this.getSearchResult()
    })
  }
  changeHandler = (e) => {
    this.setState({ city: e.target.value })
  }
  loginHandler = (data) => {
    this.props.isLogin(data)
  }

  handleClickOnCategoryList = (data) => (event) => {
    let self = this
    this.setState({ businessOrganizationNameSearch: data.name })
    localStorage.setItem('businessOrganizationNameSearch', data.name)
    console.log(this.state.city)
    localStorage.setItem('citySearch', this.state.city)
  }

  getCurrentLocationString = async () => {
    this.setState({
      city: '',
    })
    if (navigator.geolocation) {
      return navigator.geolocation.getCurrentPosition(
        (position) => {
          const pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          }
          let REVERSE_GEOCODING_API_URL = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${pos.lat},${pos.lng}&result_type=political&key=${GOOGLE_GEOCODING_API_KEY}`
          axios
            .get(REVERSE_GEOCODING_API_URL)
            .then((res) => {
              if (
                res.data.results &&
                res.data.results[0] &&
                res.data.results[0].formatted_address
              ) {
                this.setState({
                  city: res.data.results[0].formatted_address,
                  currentLocation: res.data.results[0].formatted_address,
                })
              }
            })
            .catch((error) => {
              customLogger(error)
            })
        },
        () => {
          //Geolocator service failed
          this.setState({ city: '' })
        },
      )
    } else {
      // Browser doesn't support Geolocation
      this.setState({ city: '' })
    }
  }

  render = () => {
    const {
      isIndividualBusinessOrganizationDisplay,
      individualBusinessOrganizationData,
      individualBusinessOrganization,
    } = this.state

    return (
      <AppLayout routeActive={this.props.location.pathname}>
        <Helmet>
          <title>{INDEX_TITLE}</title>
        </Helmet>
        {this.renderRedirect()}
        <section className='banner text-center position-relative'>
          <div
            className='d-flex d-lg-none flex-column'
            style={{ position: 'absolute', top: 15, right: 25, gap: 8, zIndex: 2 }}
          >
            <div className='nav-item iti-login login_btn'>
              <Link className='nav-link ' to={route.LOGIN_ROUTE}>
                Log in
              </Link>
            </div>

            <div className='nav-item iti-signup login_btn signup'>
              <Link className='nav-link' to={route.SIGNUP_ROUTE}>
                Sign Up
              </Link>
            </div>
          </div>
          <div className='container'>
            <h1>Your Circle of Trust</h1>
            <p>iTRUSTit gives you all your friends recommendations in just one click!</p>

            <form
              name='searchFormHome'
              className='bannerform it-header-search-box'
              action='javascript:;'
            >
              <div className='bannerinner'>
                {/* <div className="form-group">
                                    <input
                                        type="text"
                                        className="form-control zip1"
                                        placeholder="Search for person, business, or organization..."
                                    />
                                </div> */}
                <div className='form-group in_dropdown'>
                  <input
                    type='text'
                    name='individualBusinessOrganization'
                    className='form-control zip2'
                    onChange={this.individualBusinessOrganizationChangeHandler}
                    onFocus={this.onIndividualBusinessOrganizationFocus}
                    onBlur={this.onIndividualBusinessOrganizationBlur}
                    value={individualBusinessOrganization}
                    label='Firstname'
                    placeholder='Search iTRUSTit…'
                    autoComplete='off'
                  />
                  {isIndividualBusinessOrganizationDisplay &&
                    individualBusinessOrganizationData.length > 0 && (
                      <div className='dropdown i_drop_suggetion'>
                        <div className='dropdown-content'>
                          {individualBusinessOrganizationData.map((user, index) => {
                            let categoryORUsernameDisplay = null

                            let businessOrOrganization = null

                            const fullname = ['business', 'organization'].includes(
                              user['_source']?.user_type,
                            )
                              ? user['_source'].business_organization_name
                              : user['_source'].name
                            // let fullname = null
                            // if (user['_source'].user_type === 'business') {
                            //   // businessOrOrganization = (
                            //   //     <div className="i_business">
                            //   //         <strong>Business Name:</strong>{" "}
                            //   //         {user["_source"].business_organization_name}
                            //   //     </div>
                            //   // );
                            //   fullname = user['_source'].business_organization_name
                            // } else if (user['_source'].user_type === 'organization') {
                            //   // businessOrOrganization = (
                            //   //     <div className="i_business">
                            //   //         <strong>Organization Name:</strong>{" "}
                            //   //         {user["_source"].business_organization_name}
                            //   //     </div>
                            //   // );
                            //   fullname = user['_source'].business_organization_name
                            // } else {
                            //   // fullname = <div className="i_name varela purple">
                            //   //     <div
                            //   //         dangerouslySetInnerHTML={{
                            //   //             __html: user["_source"].name,
                            //   //         }}
                            //   //     />
                            //   // </div>
                            //   fullname = user['_source'].name
                            // }

                            let addressToDisplay = ''
                            if (
                              typeof user['_source'].address_line_1 !== 'undefined' &&
                              user['_source'].address_line_1
                            ) {
                              addressToDisplay += '' + user['_source'].address_line_1
                            }
                            if (
                              typeof user['_source'].address_line_2 !== 'undefined' &&
                              user['_source'].address_line_2
                            ) {
                              addressToDisplay += ' ' + user['_source'].address_line_2
                            }
                            if (
                              typeof user['_source'].city !== 'undefined' &&
                              user['_source'].city
                            ) {
                              addressToDisplay += ' ' + user['_source'].city
                            }
                            if (
                              typeof user['_source'].state !== 'undefined' &&
                              user['_source'].state
                            ) {
                              addressToDisplay += ' ' + user['_source'].state
                            }
                            if (
                              typeof user['_source'].zipcode !== 'undefined' &&
                              user['_source'].zipcode
                            ) {
                              addressToDisplay += ' ' + user['_source'].zipcode
                            }

                            if (user['_index'] !== 'live-categories') {
                              let profileUrl = process.env.PUBLIC_URL + user['_source'].slug
                              let profile_picture = null
                              if (
                                typeof user['_source'].image !== 'undefined' &&
                                user['_source'].image !== '' &&
                                user['_source'].image
                              ) {
                                profile_picture = (
                                  <img
                                    className='imageTag'
                                    alt=''
                                    src={PROFILE_PICTURE_SMALL_URL_PATH + user['_source'].image}
                                  />
                                )
                              } else {
                                profile_picture = (
                                  <img className='imageTag' alt='' src={DEFAULT_PROFILE_PICTURE} />
                                )
                              }

                              categoryORUsernameDisplay = (
                                <Link
                                  to={profileUrl}
                                  className='i_suggetion_link'
                                  onClick={this.individualBusinessOrganizationClicked(
                                    user['_source'],
                                  )}
                                >
                                  <div className='i_photu'>{profile_picture}</div>
                                  <div className='i_info'>
                                    <div className='i_name varela purple'>
                                      {parse(user?.highlight?.name?.[0] ?? '')}
                                    </div>
                                    <div className='i_address'>
                                      {user['_source'].user_type !== 'individual'
                                        ? addressToDisplay
                                        : 'Click to view profile'}
                                    </div>
                                  </div>
                                </Link>
                              )
                            } else {
                              categoryORUsernameDisplay = (
                                <Link
                                  className='i_suggetion_link_category'
                                  to={{
                                    pathname: route.SEARCH_ROUTE,
                                    state: {
                                      category_id: user['_source'].search_id,
                                      category_name: fullname,
                                    },
                                  }}
                                  onClick={this.handleClickOnCategoryList(user['_source'])}
                                >
                                  <div className='i_info'>
                                    <div className='i_name varela purple'>
                                      {parse(user?.highlight?.name?.[0] ?? '')}
                                    </div>
                                  </div>
                                </Link>
                              )
                            }

                            return (
                              <div key={user['_source'].id} className='i_suggestion_list'>
                                {categoryORUsernameDisplay}
                              </div>
                            )
                          })}
                        </div>
                      </div>
                    )}
                </div>
                <div className='form-group position-relative'>
                  {/* <input
                                        type="text"
                                        className="form-control zip2"
                                        placeholder="City, State, or Zip Code..."
                                    /> */}
                  <GoogleAutocomplete
                    type='text'
                    className='form-control zip1'
                    name='city'
                    onChange={this.changeHandler}
                    value={this.state.city}
                    placeholder='City, State, or Zip Code...'
                    onPlaceSelected={(place) => {
                      this.handleChangeGoogle(place)
                    }}
                    onFocus={(event) => {
                      event.target.setAttribute('autocomplete', 'off')
                    }}
                    options={{ types: ['geocode'] }}
                    //componentRestrictions={{ country: "in" }}
                  />
                  <i
                    onClick={
                      !!this.state.currentLocation
                        ? () =>
                            this.setState({
                              city: this.state.currentLocation,
                            })
                        : this.getCurrentLocationString
                    }
                    className='fa fa-map-marker-alt'
                    aria-hidden='true'
                    style={{
                      cursor: 'pointer',
                      position: 'absolute',
                      top: 13,
                      right: 13,
                      color: '#544CF9',
                      fontSize: 20,
                    }}
                  ></i>
                </div>
              </div>
              <div className='form-group submitblk it-header-search'>
                <button
                  type='submit'
                  //onClick={this.setRedirect(route.CLAIM_BUSINESS_PROFILE_ROUTE)}
                  onClick={this.setRedirect(route.SEARCH_ROUTE)}
                  className='form-control search'
                >
                  <i className='fa fa-search' aria-hidden='true'></i>
                </button>
              </div>
            </form>
          </div>
        </section>

        <section className='catagory'>
          <ul>
            <li>
              <Link
                to={{
                  pathname: route.SEARCH_ROUTE,
                  state: {
                    category_id: '150000',
                    category_name: 'Restaurants',
                  },
                }}
                onClick={this.handleClickOnCategoryList({
                  id: '150000',
                  name: 'Restaurants',
                })}
              >
                <span>
                  <img src={process.env.PUBLIC_URL + '/img/food.png'} alt='food' />
                </span>
                Restaurants
              </Link>
            </li>
            <li>
              <Link
                to={{
                  pathname: route.SEARCH_ROUTE,
                  state: {
                    category_id: '250600',
                    category_name: 'Contractors',
                  },
                }}
                onClick={this.handleClickOnCategoryList({
                  id: '250600',
                  name: 'Contractors',
                })}
              >
                <span>
                  <img src={process.env.PUBLIC_URL + '/img/painter.png'} alt='Contractors' />
                </span>
                Contractors
              </Link>
            </li>
            <li>
              <Link
                to={{
                  pathname: route.SEARCH_ROUTE,
                  state: {
                    category_id: '140223',
                    category_name: 'Real Estate Agents',
                  },
                }}
                onClick={this.handleClickOnCategoryList({
                  id: '140223',
                  name: 'Real Estate Agents',
                })}
              >
                <span>
                  <img src={process.env.PUBLIC_URL + '/img/realtor.png'} alt='Real Estate Agents' />
                </span>
                Real Estate Agents
              </Link>
            </li>
            <li>
              <Link
                to={{
                  pathname: route.SEARCH_ROUTE,
                  state: { category_id: '180200', category_name: 'Hotels' },
                }}
                onClick={this.handleClickOnCategoryList({
                  id: '180200',
                  name: 'Hotels',
                })}
              >
                <span>
                  <img src={process.env.PUBLIC_URL + '/img/hotel.png'} alt='Hotels' />
                </span>
                Hotels
              </Link>
            </li>
            <li>
              <Link
                to={{
                  pathname: route.SEARCH_ROUTE,
                  state: { category_id: '251500', category_name: 'Landscaper' },
                }}
                onClick={this.handleClickOnCategoryList({
                  id: '251500',
                  name: 'Beauty Salons',
                })}
              >
                <span>
                  <img src={process.env.PUBLIC_URL + '/img/salon.png'} alt='Beauty Salons' />
                </span>
                Beauty Salons
              </Link>
            </li>
            <li>
              <Link
                to={{
                  pathname: route.SEARCH_ROUTE,
                  state: { category_id: '250700', category_name: 'Plumber' },
                }}
                onClick={this.handleClickOnCategoryList({
                  id: '250700',
                  name: 'Plumber',
                })}
              >
                <span>
                  <img src={process.env.PUBLIC_URL + '/img/plumber.png'} alt='Plumber' />
                </span>
                Plumber
              </Link>
            </li>
            <li>
              <Link
                to={{
                  pathname: route.SEARCH_ROUTE,
                  state: {
                    category_id: '110000',
                    category_name: 'Florists',
                  },
                }}
                onClick={this.handleClickOnCategoryList({
                  id: '110000',
                  name: 'Florists',
                })}
              >
                <span>
                  <img src={process.env.PUBLIC_URL + '/img/florists.png'} alt='Florists' />
                </span>
                Florists
              </Link>
            </li>
            <li>
              <Link
                to={{
                  pathname: route.SEARCH_ROUTE,
                  state: { category_id: '152508', category_name: 'Bars' },
                }}
                onClick={this.handleClickOnCategoryList({
                  id: '152508',
                  name: 'Bars',
                })}
              >
                <span>
                  <img src={process.env.PUBLIC_URL + '/img/nightlife.png'} alt='Bars' />
                </span>
                Bars
              </Link>
            </li>
          </ul>
        </section>
        <section className='oranizations'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-4'>
                <div className='organi_blk'>
                  <div>
                    <div className='imageblk'>
                      <img src={process.env.PUBLIC_URL + '/img/individual.png'} alt='individual' />
                    </div>
                    <div className='orgni_title'>Personal</div>
                  </div>
                  <div className='orgni_content'>
                    <p>See what businesses and services your friends recommend.</p>
                    <Link to={route.SIGNUP_INDIVIDUAL_ROUTE} className='signup_blk'>
                      Sign up
                    </Link>
                  </div>
                </div>
              </div>
              <div className='col-md-4'>
                <div className='organi_blk'>
                  <div>
                    <div className='imageblk'>
                      <img src={process.env.PUBLIC_URL + '/img/business.png'} alt='business' />
                    </div>
                    <div className='orgni_title'>Business</div>
                  </div>
                  <div className='orgni_content'>
                    <p>Realtors, Corporations, Restaurants, Contractors.</p>
                    <Link to={route.ADD_BUSINESS_ROUTE} className='signup_blk'>
                      Sign up
                    </Link>
                  </div>
                </div>
              </div>
              <div className='col-md-4'>
                <div className='organi_blk'>
                  <div>
                    <div className='imageblk'>
                      <img
                        src={process.env.PUBLIC_URL + '/img/organization.png'}
                        alt='organization'
                      />
                    </div>
                    <div className='orgni_title'>Organization</div>
                  </div>
                  <div className='orgni_content'>
                    <p>
                      Clubs, Groups, Associations, Chambers, Fraternities, Churches, if you have
                      ‘Members’.
                    </p>
                    <Link to={route.ADD_ORGANIZATION_ROUTE} className='signup_blk'>
                      Sign up
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className='path'>
          <div className='container'>
            <h2>How it Works</h2>
            <p className='w_80'>
              FREE and EASY to use, find the best places your friends trust to eat, shop and play.
            </p>
            <div className='pathimg'>
              <img src={process.env.PUBLIC_URL + '/img/path.jpg'} alt='path' />
            </div>
            <p className='w_90'>Connections you TRUST!</p>
            <Link to={route.SIGNUP_ROUTE} className='signup_blk'>
              Sign Up Today
            </Link>
          </div>
        </section>
        <LoginModal isLogin={this.loginHandler} {...this.props} />
      </AppLayout>
    )
  }
}

Landing.propTypes = {
  networkerror: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  user: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ setSearchData }, dispatch)
}

export default connect(null, mapDispatchToProps)(Landing)
