import React, { Component, Fragment } from 'react'
// import { PROFILE_FRIEND_TITLE } from "../../../services/titleConst";
import * as activityApiService from '../../../services/activityApiService'
import { LIST_VIEW_ITEMS_PER_PAGE, LIST_VIEW_PAGE_RANGE } from '../../../services/const'
import { getUser } from '../../../services/customFunction'
import Pagination from 'react-js-pagination'
// import UserListDetail from "../../CommonComponents/UserListDetail/UserListDetail";
import $ from 'jquery'
class ItrustittreeTab extends Component {
  constructor(props) {
    super(props)

    this.state = {
      login_user_id: getUser().id,
      imatrixId: '',
      imatrixSessionId: '',
      iframe_text: 'Loading..',
      iteamTotalCount: 0,
    }
  }

  componentDidMount() {
    this.getImatrixSessionId()
    this.getTotalIteamCount()

    window.scrollTo({
      top: $('#itrustittree').offset().top - 125,
      behavior: 'smooth',
    })
  }

  getImatrixSessionId() {
    let getImatrixSessionIdListReqData = {}
    let self = this
    let iframe_text_res
    activityApiService
      .getImatrixSessionIdCall(getImatrixSessionIdListReqData)
      .then(function (response) {
        if (
          response &&
          typeof response !== 'undefined' &&
          typeof response.status !== 'undefined' &&
          response.status === 200
        ) {
          //console.log("id ==" + response.data.data.imatrix_id);
          if (response.data.data.imatrix_session_id == '') {
            iframe_text_res = 'Not found !'
          } else {
            iframe_text_res = 'Loading..'
          }
          let stateData = {
            imatrixId: response.data.data.imatrix_id,
            imatrixSessionId: response.data.data.imatrix_session_id,
            iframe_text: iframe_text_res,
          }
          self.setState(stateData, () => {})
        } else {
          let stateData = {
            iframe_text: 'Not found !',
          }
          self.setState(stateData, () => {})
        }
      })
  }

  getTotalIteamCount() {
    let getTotalIteamCountListReqData = {}
    let self = this
    activityApiService
      .totalIteamCountListCall(getTotalIteamCountListReqData)
      .then(function (response) {
        if (
          response &&
          typeof response !== 'undefined' &&
          typeof response.status !== 'undefined' &&
          response.status === 200
        ) {
          //console.log("total count ==",response.data.data.total_iteam_count);
          let stateData = {}
          if (response.data.data.total_iteam_count > 0) {
            stateData = {
              iteamTotalCount: response.data.data.total_iteam_count - 1,
            }
          } else {
            stateData = {
              iteamTotalCount: response.data.data.total_iteam_count,
            }
          }

          self.setState(stateData, () => {})
        }
      })
  }

  render() {
    let { imatrixId, imatrixSessionId, iframe_text } = this.state
    let imatrix_src

    if (imatrixSessionId !== '') {
      imatrix_src =
        'https://backoffice.office2office.com/IMX_TM/IMX_accounts/adv_gene_plus_report.asp?session=' +
        imatrixSessionId +
        '&user=' +
        imatrixId +
        '&pinfo=/office&cl=bzt&lc=28859&rs=Y&RCR=N&REQLEV=999'

      if (imatrix_src !== '') {
        iframe_text = (
          <iframe width='100%' height='1500' title='iMatrix Tree' src={imatrix_src}></iframe>
        )
      }
    }

    return (
      <div
        id='itrustittree'
        className={
          'it-profile-noti tab-pane ' +
          (this.props.activeTab === '#itrustittree' ? 'active' : 'fade')
        }
      >
        <h4 className='varela'>
          {this.state.iteamTotalCount > 0 ? this.state.iteamTotalCount : ''} iTeam Affiliates - Tree
          View
        </h4>
        <div className='bg-white'>
          <div className='activity_blk'>{iframe_text}</div>
        </div>
      </div>
    )
  }
}

ItrustittreeTab.defaultProps = {
  itemsCountPerPage: LIST_VIEW_ITEMS_PER_PAGE,
  pageRangeDisplayed: LIST_VIEW_PAGE_RANGE,
}

export default ItrustittreeTab
