import React, { Component } from 'react'
import OverviewTab from '../OverviewTab/OverviewTab'
import MyPostsTab from '../MyPostsTab/MyPostsTab'
import PostsTab from '../PostsTab/PostsTab'
import Friends from '../Friends/Friends'
class OverviewGenericTab extends Component {
  constructor(props) {
    super(props)

    this.overviewTabFriendSectionRef = React.createRef()
    this.overviewTabRef = React.createRef()
    this.postsTabRef = React.createRef()
    this.mypostsTabRef = React.createRef()
  }

  profileHeader_overview_tab_click_Handle() {
    this.overviewTabFriendSectionRef.current.profileHeader_overview_tab_click_Handle()
    this.overviewTabRef.current.profileHeader_overview_tab_click_Handle()
  }

  showReviewTab2 = (data) => {
    this.props.showReviewTab2(data)
  }

  render() {
    return (
      <div
        id='overviewgeneric'
        className={
          'tab-pane ' +
          (this.props.activeTab === '#overview' ||
          this.props.activeTab === '#posts' ||
          this.props.activeTab === '#myposts'
            ? 'active'
            : 'fade')
        }
      >
        <div className='row'>
          <div className='col-md-4 overview-left'>
            <Friends
              ref={this.overviewTabFriendSectionRef}
              changeActiveTab={this.props.changeActiveTab}
              showFriendsTab={this.props.showFriendsTab}
            />
            <div className='bg-white curvey mb-4 p-15 related-listings-show'>
              <img src={process.env.PUBLIC_URL + '/img/image.png'} className='img-fluid' alt='' />
            </div>
          </div>

          <div className='col-md-8 overview-right tab-content'>
            <OverviewTab
              activeTab={this.props.activeTab}
              ref={this.overviewTabRef}
              showReviewTab2={this.showReviewTab2}
              user={this.props.user}
              changeActiveTab={this.changeActiveTab}
              showFriendsTab={this.showFriendsTab}
            />
            <PostsTab
              activeTab={this.props.activeTab}
              ref={this.postsTabRef}
              user={this.props.user}
              changeActiveTab={this.props.changeActiveTab}
              showFriendsTab={this.showFriendsTab}
            />
            <MyPostsTab
              activeTab={this.props.activeTab}
              ref={this.mypostsTabRef}
              user={this.props.user}
              changeActiveTab={this.props.changeActiveTab}
              showFriendsTab={this.showFriendsTab}
            />
          </div>
        </div>
      </div>
    )
  }
}

export default OverviewGenericTab
