import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom'
import * as customFunction from '../../../services/customFunction'

class ActivityListDetail extends Component {
  render() {
    const { activity, login_user_id } = this.props
    let profileUrl = null
    let activityListDetail = null

    if (activity.actor_user_id === login_user_id) {
      /* login user is actor */
      // console.log(activity, activity.reactor_user);
      profileUrl = process.env.PUBLIC_URL + '/' + activity.reactor_user.slug
      let nameToDisplay =
        activity.reactor_user.user_type === 'individual'
          ? activity.reactor_user.fullname
          : activity.reactor_user.business_organization_name

      if (activity.action === 'friends') {
        activityListDetail = (
          <li>
            You are friends with <Link to={profileUrl}>{nameToDisplay}</Link>. {/*( Friend ) */}
            <span className='it-date-space'>
              {customFunction.dateStringToFormatForActivity(activity.created_at)}
            </span>
          </li>
        )
      } else if (activity.action === 'follows') {
        activityListDetail = (
          <li>
            <Link to={profileUrl}>{nameToDisplay}</Link> is now following you. {/*( Follow ) */}
            <span className='it-date-space'>
              {customFunction.dateStringToFormatForActivity(activity.created_at)}
            </span>
          </li>
        )
      } else if (activity.action === 'reviewed') {
        activityListDetail = (
          <li>
            You reviewed <Link to={profileUrl}>{nameToDisplay}</Link>. {/*( Reviewed )*/}
            <span className='it-date-space'>
              {customFunction.dateStringToFormatForActivity(activity.created_at)}
            </span>
          </li>
        )
      } else if (activity.action === 'reviewed_update') {
        activityListDetail = <li>Record not available. ( Review update )</li>
      } else if (activity.action === 'trust') {
        activityListDetail = (
          <li>
            You Added <Link to={profileUrl}>{nameToDisplay}</Link> to your Trust List.{' '}
            {/*( Trust )*/}
            <span className='it-date-space'>
              {customFunction.dateStringToFormatForActivity(activity.created_at)}
            </span>
          </li>
        )
      } else if (activity.action === 'member') {
        activityListDetail = (
          <li>
            You are a member of <Link to={profileUrl}>{nameToDisplay}</Link>. {/*( Member )*/}
            <span className='it-date-space'>
              {customFunction.dateStringToFormatForActivity(activity.created_at)}
            </span>
          </li>
        )
      } else {
        activityListDetail = <li>Data not valid</li>
      }
    } else {
      /* login user is reactor */

      profileUrl = process.env.PUBLIC_URL + '/' + activity.actor_user.slug
      let nameToDisplay =
        activity.actor_user.user_type === 'individual'
          ? activity.actor_user.fullname
          : activity.actor_user.business_organization_name

      let userTypeName = 'business'
      if (activity.actor_user.user_type === 'business') {
        userTypeName = 'business'
      } else if (activity.actor_user.user_type === 'organization') {
        userTypeName = 'organization'
      }
      //console.log({ activity })
      if (activity.action === 'friends') {
        activityListDetail = (
          <li>
            You are friends with <Link to={profileUrl}>{nameToDisplay}</Link>. {/*( Friend )*/}
            <span className='it-date-space'>
              {customFunction.dateStringToFormatForActivity(activity.created_at)}
            </span>
          </li>
        )
      } else if (activity.action === 'follows') {
        activityListDetail = (
          <li>
            <Link to={profileUrl}>{nameToDisplay}</Link> is now following you. {/*( Follow )*/}
            <span className='it-date-space'>
              {customFunction.dateStringToFormatForActivity(activity.created_at)}
            </span>
          </li>
        )
      } else if (activity.action === 'reviewed') {
        activityListDetail = (
          <li>
            {/* You have been reviewed by <Link to={profileUrl}>{nameToDisplay}</Link>.  */}
            {/*( Reviewed )*/}
            <Link to={profileUrl}>{nameToDisplay}</Link> Reviewed your {userTypeName}.
            <span className='it-date-space'>
              {customFunction.dateStringToFormatForActivity(activity.created_at)}
            </span>
          </li>
        )
      } else if (activity.action === 'new_iteam_member') {
        activityListDetail = (
          <li>
            {/* You have been reviewed by <Link to={profileUrl}>{nameToDisplay}</Link>.  */}
            {/*( Reviewed )*/}
            <Link to={profileUrl}>{nameToDisplay}</Link> Signed up with your Sponsor iTeam ID.
            <span className='it-date-space'>
              {customFunction.dateStringToFormatForActivity(activity.created_at)}
            </span>
          </li>
        )
      } else if (activity.action === 'reviewed_update') {
        activityListDetail = <li>Record not available. ( Review update )</li>
      } else if (activity.action === 'trust') {
        if (activity.actor_user.user_type === 'organization') {
          activityListDetail = (
            <li>
              You have been Trusted by <Link to={profileUrl}>{nameToDisplay}</Link>.{' '}
              <span className='it-date-space'>
                {customFunction.dateStringToFormatForActivity(activity.created_at)}
              </span>
            </li>
          )
        } else {
          activityListDetail = (
            <li>
              {/* You have be Added by <Link to={profileUrl}>{nameToDisplay}</Link> to his/her Trust List.{" "} */}
              {/*( Trust )*/}
              <Link to={profileUrl}>{nameToDisplay}</Link> Trusts your {userTypeName}.{' '}
              <span className='it-date-space'>
                {customFunction.dateStringToFormatForActivity(activity.created_at)}
              </span>
            </li>
          )
        }
      } else if (activity.action === 'member') {
        activityListDetail = (
          <li>
            <Link to={profileUrl}>{nameToDisplay}</Link> is now member of your organization.{' '}
            {/*( Member )*/}
            <span className='it-date-space'>
              {customFunction.dateStringToFormatForActivity(activity.created_at)}
            </span>
          </li>
        )
      } else {
        activityListDetail = <li>Data not valid</li>
      }
    }

    return <Fragment>{activityListDetail}</Fragment>
  }
}

export default ActivityListDetail
