import React from 'react'
import { Field } from 'redux-form'
import ReactSelect from '../../../services/ReactSelect'
import SortOptionsButtons from './SortOptionsButtons'

export default function SearchResultsSortOptions({
  isMobile,
  showByOptions,
  sortByOptions,
  showBySelectedOption,
  sortBySelectedOption,
  sortOrder,
  showByChangeHandler,
  sortOrderChangeHandler,
  sortByChangeHandler,
}) {
  const SortByField = (
    <div className={`sort_sel ${isMobile ? 'me-1' : ''}`}>
      <span className='varela d-block d-sm-inline-block' style={isMobile ? { fontSize: 12 } : {}}>
        Sort by:
      </span>
      <Field
        type='text'
        className='sel_box for-select'
        component={ReactSelect}
        name='sort_by'
        options={sortByOptions}
        isSelect='false'
        onChange={sortByChangeHandler}
        valueKey={sortBySelectedOption}
      />
    </div>
  )
  return (
    <div className='all_selection'>
      <div className='sel1'>
        <div className={`sort_sel ${isMobile ? 'me-0' : ''}`}>
          <span
            className='varela d-block d-sm-inline-block'
            style={isMobile ? { fontSize: 12 } : {}}
          >
            Show by:
          </span>
          <Field
            type='text'
            className='sel_box for-select'
            component={ReactSelect}
            name='user_type'
            options={showByOptions}
            validate={[]}
            isSelect='false'
            isSearchable='false'
            valueKey={showBySelectedOption}
            onChange={showByChangeHandler}
          />
        </div>
        {isMobile ? (
          <>
            {SortByField}
            <SortOptionsButtons
              isMobile={isMobile}
              sortOrderChangeHandler={sortOrderChangeHandler}
              sortOrder={sortOrder}
            />
          </>
        ) : null}
      </div>
      {!isMobile ? (
        <>
          <div className='sel1'>{SortByField}</div>
          <div className='sel1 sort-buttons flex-column align-items-start'>
            <span className='varela'>Order:</span>
            <div className='d-flex'>
              <SortOptionsButtons
                isMobile={isMobile}
                sortOrderChangeHandler={sortOrderChangeHandler}
                sortOrder={sortOrder}
              />
            </div>
          </div>
        </>
      ) : null}
    </div>
  )
}
