import axios from 'axios'
import { customLogger } from '../utils/CustomLogger'
import * as environemnt from './environment'
import { userToken, toaster } from './customFunction'

export const makeMemberCall = (request) => {
  return axios
    .post(environemnt.BASE_API + 'make-member/' + request.id, request, {
      headers: {
        Authorization: userToken(),
        Accept: 'application/x.iTRUSTit.v1+json',
      },
    })
    .then(function (response) {
      return response
    })
    .catch(function (error) {
      if (
        typeof error.response !== 'undefined' &&
        typeof error.response.data !== 'undefined' &&
        typeof error.response.data.message !== 'undefined' &&
        error.response.data.message
      ) {
        toaster('error', error.response.data.message)
      }
      customLogger(error)
      return error.response
    })
}

export const removeMemberCall = (request) => {
  return axios
    .post(environemnt.BASE_API + 'remove-member/' + request.id, request, {
      headers: {
        Authorization: userToken(),
        Accept: 'application/x.iTRUSTit.v1+json',
      },
    })
    .then(function (response) {
      return response
    })
    .catch(function (error) {
      if (
        typeof error.response !== 'undefined' &&
        typeof error.response.data !== 'undefined' &&
        typeof error.response.data.message !== 'undefined' &&
        error.response.data.message
      ) {
        toaster('error', error.response.data.message)
      }
      customLogger(error)
      return error.response
    })
}

export const memberListCall = (request) => {
  let api_url = environemnt.BASE_API + 'member-list'
  if (typeof request.other_user_id !== 'undefined' && request.other_user_id > 0) {
    api_url = environemnt.BASE_API + 'member-list/' + request.other_user_id
  }

  return axios
    .post(api_url, request, {
      headers: {
        Authorization: userToken(),
        Accept: 'application/x.iTRUSTit.v1+json',
      },
    })
    .then(function (response) {
      return response
    })
    .catch(function (error) {
      if (
        typeof error.response !== 'undefined' &&
        typeof error.response.data !== 'undefined' &&
        typeof error.response.data.message !== 'undefined' &&
        error.response.data.message
      ) {
        toaster('error', error.response.data.message)
      }
      customLogger(error)
      return error.response
    })
}

export const getMemberListFromIDs = async (request) => {
  let api_url = environemnt.BASE_API + 'member-list-from-ids'
  try {
    const response = await axios.post(api_url, request, {
      headers: {
        Authorization: userToken(),
        Accept: 'application/x.iTRUSTit.v1+json',
      },
    })
    return response
  } catch (error) {
    if (
      typeof error.response !== 'undefined' &&
      typeof error.response.data !== 'undefined' &&
      typeof error.response.data.message !== 'undefined' &&
      error.response.data.message
    ) {
      toaster('error', error.response.data.message)
    }
    customLogger(error)
    return error.response
  }
}

export const orgsCommonListCall = (request) => {
  let api_url = environemnt.BASE_API + 'orgs-common-list'
  if (typeof request.other_user_id !== 'undefined' && request.other_user_id > 0) {
    api_url = environemnt.BASE_API + 'orgs-common-list/' + request.other_user_id
  }

  return axios
    .post(api_url, request, {
      headers: {
        Authorization: userToken(),
        Accept: 'application/x.iTRUSTit.v1+json',
      },
    })
    .then(function (response) {
      return response
    })
    .catch(function (error) {
      if (
        typeof error.response !== 'undefined' &&
        typeof error.response.data !== 'undefined' &&
        typeof error.response.data.message !== 'undefined' &&
        error.response.data.message
      ) {
        toaster('error', error.response.data.message)
      }
      customLogger(error)
      return error.response
    })
}

export const orgsTotalListCall = (request) => {
  let api_url = environemnt.BASE_API + 'orgs-total-list'
  if (typeof request.other_user_id !== 'undefined' && request.other_user_id > 0) {
    api_url = environemnt.BASE_API + 'orgs-total-list/' + request.other_user_id
  }

  return axios
    .post(api_url, request, {
      headers: {
        Authorization: userToken(),
        Accept: 'application/x.iTRUSTit.v1+json',
      },
    })
    .then(function (response) {
      return response
    })
    .catch(function (error) {
      if (
        typeof error.response !== 'undefined' &&
        typeof error.response.data !== 'undefined' &&
        typeof error.response.data.message !== 'undefined' &&
        error.response.data.message
      ) {
        toaster('error', error.response.data.message)
      }
      customLogger(error)
      return error.response
    })
}
