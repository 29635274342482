import React, { useState } from 'react'
import './SharedProfile.scss'
import AppLayout from '../AppLayout'
import PropTypes from 'prop-types'
import { Link, Redirect } from 'react-router-dom'
// import Swiper from 'react-id-swiper';
import { SHAERD_PROFILE, SHARED_CLAIM } from '../../services/titleConst'
import * as route from '../../services/routeConst'
import { getProfileFromSlugAndImatrix } from '../../services/userApiService'
import { toaster, setInviteMatrixId, isUserLogin, userToken } from '../../services/customFunction'
import LoginModal from '../LoginModal/LoginModal'
import { Helmet } from 'react-helmet'
import { DEFAULT_PROFILE_PICTURE } from '../../services/const'
import { useEffect } from 'react'

const SharedProfile = (props) => {
  const [stateData, setStateData] = useState({
    user: {},
    slug: props.match.params.slug,
    idToClaim: props.match.params.idToClaim,
    userToClaim: null,
  })

  useEffect(() => {
    userDetail()
    window.scrollTo(0, 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const userDetail = async () => {
    const response = await getProfileFromSlugAndImatrix({
      slug: stateData.slug,
      idToClaim: stateData.idToClaim,
    })
    if (!response?.data?.user_details) toaster('error', 'User not found')
    else {
      setStateData((st) => ({
        ...st,
        user: response.data?.user_details,
        userToClaim: response?.data?.userToClaim,
      }))
      response?.data?.user_details?.imatrix_id &&
        setInviteMatrixId(response?.data?.user_details.imatrix_id)
    }
  }

  const loginHandler = (data) => {
    props.isLogin(data)
  }

  const { user } = stateData
  const isInviteToClaim = !!stateData?.userToClaim
  return (
    <>
      <Helmet>
        <title>{isInviteToClaim ? SHARED_CLAIM : SHAERD_PROFILE}</title>
        <meta name='title' content={isInviteToClaim ? SHARED_CLAIM : SHAERD_PROFILE} />
        <meta
          name='description'
          content={`An invite from ${user?.fullname ? user?.fullname + ' on' : ''} iTRUSTit`}
        />
        <meta name='og:title' content={isInviteToClaim ? SHARED_CLAIM : SHAERD_PROFILE} />
        <meta
          name='og:description'
          content={`An invite from ${user?.fullname ? user?.fullname + ' on' : ''} iTRUSTit`}
        />
      </Helmet>
      {props.isLoggedIn && user?.slug && !stateData?.idToClaim && !isInviteToClaim ? (
        <Redirect
          to={{
            pathname: '/' + stateData.user?.slug,
          }}
        />
      ) : !props.isLoggedIn || isInviteToClaim ? (
        <AppLayout routeActive={props.location.pathname} imatrix_id={stateData.user?.imatrix_id}>
          <section className='banner text-center it-banner-link it-user-invite'>
            <div className='container'>
              <div className={'it-banner-img ' + user?.user_type + '_profile'}>
                <img
                  src={user?.profile_picture_medium ?? DEFAULT_PROFILE_PICTURE}
                  alt=''
                  onError={(e) => (e.target.src = DEFAULT_PROFILE_PICTURE)}
                />
              </div>
              <h1>
                {user?.user_type === 'individual'
                  ? user?.fullname
                  : user?.business_organization_name}{' '}
                has invited you to{' '}
                {isInviteToClaim
                  ? `claim ${
                      stateData?.userToClaim?.business_organization_name ??
                      `your ${stateData?.userToClaim?.user_type}`
                    }  on`
                  : 'join'}{' '}
                iTRUSTit
              </h1>
              <p>iTRUSTit is like calling all your friends for advice in just one click!</p>
              <Link
                to={{
                  pathname: isInviteToClaim
                    ? route.CLAIM_BUSINESS_PROFILE_ROUTE
                    : route.SIGNUP_ROUTE,
                  state: isInviteToClaim
                    ? { user: stateData?.userToClaim, loginUserData: user }
                    : { imatrix_id: stateData.user?.imatrix_id },
                }}
                className='signup_blk'
              >
                {isInviteToClaim ? 'Claim Now' : 'Sign up'}
              </Link>
            </div>
          </section>

          <section className='catagory'>
            <ul>
              <li>
                <Link
                  to={{
                    pathname: route.SEARCH_ROUTE,
                    state: {
                      category_id: '150000',
                      category_name: 'Restaurants',
                    },
                  }}
                >
                  <span>
                    <img src={process.env.PUBLIC_URL + '/img/food.png'} alt='food' />
                  </span>
                  Restaurants
                </Link>
              </li>
              <li>
                <Link
                  to={{
                    pathname: route.SEARCH_ROUTE,
                    state: {
                      category_id: '250600',
                      category_name: 'Contractors',
                    },
                  }}
                >
                  <span>
                    <img src={process.env.PUBLIC_URL + '/img/painter.png'} alt='Contractors' />
                  </span>
                  Contractors
                </Link>
              </li>
              <li>
                <Link
                  to={{
                    pathname: route.SEARCH_ROUTE,
                    state: {
                      category_id: '140223',
                      category_name: 'Real Estate Agents',
                    },
                  }}
                >
                  <span>
                    <img
                      src={process.env.PUBLIC_URL + '/img/realtor.png'}
                      alt='Real Estate Agents'
                    />
                  </span>
                  Real Estate Agents
                </Link>
              </li>
              <li>
                <Link
                  to={{
                    pathname: route.SEARCH_ROUTE,
                    state: { category_id: '180200', category_name: 'Hotels' },
                  }}
                >
                  <span>
                    <img src={process.env.PUBLIC_URL + '/img/hotel.png'} alt='Hotels' />
                  </span>
                  Hotels
                </Link>
              </li>
              <li>
                <Link
                  to={{
                    pathname: route.SEARCH_ROUTE,
                    state: { category_id: '251500', category_name: 'Landscaper' },
                  }}
                >
                  <span>
                    <img src={process.env.PUBLIC_URL + '/img/landscaper.png'} alt='landscaper' />
                  </span>
                  Landscaper
                </Link>
              </li>
              <li>
                <Link
                  to={{
                    pathname: route.SEARCH_ROUTE,
                    state: { category_id: '250700', category_name: 'Plumber' },
                  }}
                >
                  <span>
                    <img src={process.env.PUBLIC_URL + '/img/plumber.png'} alt='Plumber' />
                  </span>
                  Plumber
                </Link>
              </li>
              <li>
                <Link
                  to={{
                    pathname: route.SEARCH_ROUTE,
                    state: {
                      category_id: '110000',
                      category_name: 'Auto Repair',
                    },
                  }}
                >
                  <span>
                    <img src={process.env.PUBLIC_URL + '/img/automative.png'} alt='Auto Repair' />
                  </span>
                  Auto Repair
                </Link>
              </li>
              <li>
                <Link
                  to={{
                    pathname: route.SEARCH_ROUTE,
                    state: { category_id: '152508', category_name: 'Bars' },
                  }}
                >
                  <span>
                    <img src={process.env.PUBLIC_URL + '/img/nightlife.png'} alt='Bars' />
                  </span>
                  Bars
                </Link>
              </li>
            </ul>
          </section>
          <section className='oranizations'>
            <div className='container'>
              <div className='row'>
                <div className='col-md-4'>
                  <div className='organi_blk'>
                    <div className='imageblk'>
                      <img src={process.env.PUBLIC_URL + '/img/individual.png'} alt='individual' />
                    </div>
                    <div className='orgni_title'>Individual</div>
                    <div className='orgni_content'>
                      <p>See what businesses and services your friends recommend.</p>
                      <Link
                        to={{
                          pathname: route.SIGNUP_INDIVIDUAL_ROUTE,
                          state: { imatrix_id: stateData.user?.imatrix_id },
                        }}
                        className='signup_blk'
                      >
                        Sign up
                      </Link>
                    </div>
                  </div>
                </div>
                <div className='col-md-4'>
                  <div className='organi_blk'>
                    <div className='imageblk'>
                      <img src={process.env.PUBLIC_URL + '/img/business.png'} alt='business' />
                    </div>
                    <div className='orgni_title'>Business</div>
                    <div className='orgni_content'>
                      <p>
                        Realtors, Contractors, Restaurants, Service Providers, Corporations,
                        Independent Sales Reps.
                      </p>
                      <Link
                        to={{
                          pathname: route.ADD_BUSINESS_ROUTE,
                          state: { imatrix_id: stateData.user?.imatrix_id },
                        }}
                        className='signup_blk'
                      >
                        Sign up
                      </Link>
                    </div>
                  </div>
                </div>
                <div className='col-md-4'>
                  <div className='organi_blk'>
                    <div className='imageblk'>
                      <img
                        src={process.env.PUBLIC_URL + '/img/organization.png'}
                        alt='organization'
                      />
                    </div>
                    <div className='orgni_title'>Organization</div>
                    <div className='orgni_content'>
                      <p>
                        Clubs, Groups, Associations, Chambers, Fraternities, Churches, if you have
                        'Members'.
                      </p>
                      <Link
                        to={{
                          pathname: route.ADD_ORGANIZATION_ROUTE,
                          state: { imatrix_id: stateData.user?.imatrix_id },
                        }}
                        className='signup_blk'
                      >
                        Sign up
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className='path'>
            <div className='container'>
              <h2>It’s easy and free to get started</h2>
              <p className='w_80'>
                iTRUSTit is the easiest way to learn about all the places your friends eat, shop and
                play, and what they think of them!
              </p>
              <div className='pathimg'>
                <img src={process.env.PUBLIC_URL + '/img/path.jpg'} alt='path' />
              </div>
              <p className='w_90'>
                iTRUSTit is an online business directory tailored exclusively for YOU!
              </p>
              <Link
                to={{
                  pathname: route.SIGNUP_ROUTE,
                  state: { imatrix_id: stateData.user?.imatrix_id },
                }}
                className='signup_blk'
              >
                Sign Up Today
              </Link>
            </div>
          </section>

          {isUserLogin() && userToken() ? (
            <div></div>
          ) : (
            <LoginModal
              imatrix_id={stateData?.user?.imatrix_id}
              isLogin={loginHandler}
              {...props}
            />
          )}
        </AppLayout>
      ) : null}
    </>
  )
}

SharedProfile.propTypes = {
  networkerror: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  user: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
}
export default SharedProfile
