import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'
import classNames from 'classnames'
// import PropTypes from "prop-types";
import { Link } from 'react-router-dom'

import { toaster, removeInviteMatrixId, getInviteMatrixId } from '../../../services/customFunction'
import '../signupbusiness.scss'
import renderInputField from '../../Form/Input'
// import TreeSelect from "../../Form/TreeSelect";
import ReactSelect from '../../../services/ReactSelect'
import * as api from '../../../services/businessService'
import * as validation from '../../../services/formValidation'
import { TERMS_ROUTE } from '../../../services/routeConst'
import ErrorMsgRenderer from '../../ErrorMsgRenderer/ErrorMsgRenderer'
import { relationshipList, DUPLICATE_USER, DUPLICATE_EMAIL } from '../../../services/const'
import { checkUsername, checkSponsorId, checkEmail } from '../../../services/userApiService'
import * as environemnt from '../../../services/environment'
import { withRouter } from 'react-router'
import axios from 'axios'
import { customLogger } from '../../../utils/CustomLogger'
import { connect } from 'react-redux'
import * as customFunction from '../../../services/customFunction' // #914 Distinct between 1st and redirect SignUp
class SignupBusiness3 extends Component {
  constructor(props) {
    super(props)
    this.state = {
      categoryList: [],
      subCategoryList: [],
      subOfSubCategoryList: [],
      categoryIndex: null,
      subCategoryValue: null,
      subOfSubCategoryValue: null,
      formData: [],
      relationship: {},
      relationshipIndex: null,
      isLoading: false,
      signupText: 'Sign Up',
      email: '',
      confirmemail: '',
      confirmpassword: '',
      isPasswordPopupDisplay: false,
      isUsernamePopupDisplay: false,
      validation: {
        isSpaceValidation: false,
        isMaxLength: false,
        isUppercase: false,
        isLowercase: false,
        isNumber: false,
      },
      usernameValidation: {
        isMaxLength: false,
        isBeginLatter: false,
        isCharacter: false,
        isDashed: false,
        isSpaceValidation: false,
      },
      is_username_exists: '0',
      is_email_exists: '0',
      error: {
        email: [],
        username: [],
        sponsorId: [],
        relationship_type: [],
        category: [],
      },
      username: '',
      redirectUrl: null,
      newsletters_type: '',
      is_click: false,
      countryList: [],
      stateList: [],
      cityList: [],
      countryIndex: null,
      countryObject: '',
      stateValue: null,
      cityValue: null,
      zipValue: '',
      imatrix_id: props?.location?.state?.imatrix_id || customFunction.getDefaultSponsor(),
      sponsorOwnerName: '',
      sponsorErrorMsg: '',
      cityFlag: false,
      areaFlag: false,
      fillData: {
        address_line_1: '',
        address_line_2: '',
        business_organization_name: '',
        city_id: '',
        country_id: '',
        state_id: '',
        user_type: '',
        zipcode: '',
      },
    }
  }

  consoleLog = () => {
    if (this.state.fillData) {
      setTimeout(() => {
        let country = this.state.countryList
        let countryIndex = country.findIndex((x) => x.value === this.state.fillData.country_id)
        this.setState(
          { countryIndex: countryIndex },
          () => {
            this.stateHandler(this.state.fillData.country_id)
            this.city(this.state.fillData.state_id)
          },
          1000,
        )
      })
    }
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState(
      {
        fillData: props.fillData,
      },
      this.consoleLog,
    )
  }

  componentDidMount = () => {
    window.scrollTo(0, 0)
    this.setState(
      {
        sponsors_iteam_id: this.state.imatrix_id,
      },
      () => this.checkSponsoridOnLoad(),
    )

    this.country()
    this.categoryListById('category', '0')
  }

  componentWillMount = () => {}
  areaChangedHandler = (event) => {
    this.setState({ areaFlag: false })
  }
  onSponsorChangeHandler = (event) => {
    let value = event.target.value
    this.setState(
      {
        imatrix_id: value,
        sponsors_iteam_id: value,
        sponsorOwnerName: '',
        sponsorErrorMsg: 'Sponsor id is invalid',
      },
      () => {
        this.validateSponsorid(value)
      },
    )
  }

  singupHandler = (event) => {
    let inputName = event.target.name
    let inputValue = event.target.value
    if (inputName === 'country') this.stateHandler(inputValue)
    if (inputName === 'state') this.city(inputValue)
  }

  zipcodeChange = (event) => {
    if (
      event.target.value !== '' &&
      this.state.countryObject !== '' &&
      this.state.countryObject.label === environemnt.UNITED_STATE_TEXT
    ) {
      this.setState(
        {
          zipValue: event.target.value,
        },
        this.getDataUsingZip,
      )
    }
  }
  countrySelectHandler = (event) => {
    let countryIndex = this.state.countryList.findIndex((x) => x.value === event.value)

    this.setState(
      {
        stateList: [],
        cityList: [],
        stateValue: null,
        cityValue: null,
        countryIndex: countryIndex,
        stateLoadingFirstTime: false,
        countryObject: event,
      },
      () => {
        this.stateHandler(event.value)
      },
    )
    if (
      event.value !== '' &&
      this.state.zipValue !== '' &&
      event.label === environemnt.UNITED_STATE_TEXT
    ) {
      this.getDataUsingZip()
    }
    /*210824-1*/
    // this.updateCountryDepencency()
  }
  /**
   * Function that return nothing and when country is selected reset all: state,zip, * city (area) and mark them as tocuhed for error. address may be included
   * @author   Amalia
   * @task     210824-1
   */
  updateCountryDepencency = () => {
    this.props.change('state_id', null)
    this.props.touch('state_id', null)
    this.props.change('zipcode', null)
    this.props.touch('zipcode', null)
    this.props.change('city_id', null)
    this.props.touch('city_id', null)
    this.props.change('area', null)
    this.props.touch('area', null)
  }
  // get city and state data using zip code when country is united state
  getDataUsingZip = () => {
    let api_url = environemnt.BASE_API + 'state-cities-from-zipcode'
    var params = {
      zipcode: this.state.zipValue,
      country_id: this.state.countryObject.value,
    }
    var self = this
    axios
      .post(api_url, params, {
        headers: { 'Content-Type': 'application/json' },
      })
      .then(function (response) {
        // set option in state dropdown and select first option as a state value
        var resultData = response.data.data
        if (resultData.state_list.length === 0) {
          self.stateHandler(self.state.countryObject.value)
        } else {
          var stateCopy = []
          stateCopy = resultData.state_list.map((state, index) => {
            return { value: state.id, label: state.name }
          })
          self.setState(
            {
              stateList: stateCopy,
            },
            () => {
              if (self.state.stateList.length > 0) {
                self.setState({
                  stateValue: self.state.stateList[0].value,
                })
                self.props.change('state_id', self.state.stateList[0])
              }
            },
          )

          var cityCopy = []
          cityCopy = resultData.city_list.map((state, index) => {
            return { value: state.id, label: state.name }
          })
          self.setState(
            {
              cityList: cityCopy,
            },
            () => {
              if (self.state.cityList.length > 0) {
                self.setState({
                  cityValue: self.state.cityList[0].value,
                  cityFlag: true,
                  areaFlag: true,
                })
                // for redux form state update
                self.props.change('city_id', self.state.cityList[0])
              } else {
                self.setState({ cityFlag: false })
              }
            },
          )
        }
      })
      .catch(function (error) {
        customLogger(error)
        //return error.response;
      })
  }
  stateSelectHandler = (event) => {
    this.setState({ stateValue: event.value, cityValue: null })
    this.city(event.value)
    /*210824-1 when state is selected reset city and mark isas touched for error*/
    this.props.change('city_id', null)
    this.props.touch('city_id')
  }

  citySelectHandler = (event) => {
    this.setState({ cityValue: event.value, areaFlag: true })
  }

  areaChangedHandler = (event) => {
    this.setState({ areaFlag: false })
  }

  country = () => {
    let self = this
    //let stateCopy = { ...this.state };
    let stateCopyCountry = []
    api.countryList().then(function (response) {
      if (response.status === true) {
        stateCopyCountry = response.countries.map((country) => {
          return { value: country.id, label: country.name }
        })
        self.setState({ countryList: stateCopyCountry }, function () {
          if (self.state.fillData) {
            let countryIndex = self.state.countryList.findIndex(
              (x) => x.value === self.state.fillData.country_id,
            )

            self.setState({
              countryIndex: countryIndex,
            })
          }
        })
      }
    })
  }

  stateHandler(id) {
    var self = this
    this.setState({
      stateList: [],
      cityList: [],
      stateValue: null,
      cityValue: null,
    })

    api.stateList(id).then(function (response) {
      if (response.status === true) {
        let stateCopy = { ...self.state }
        stateCopy['stateList'] = response.states.map((state, index) => {
          if (index === 0) return { value: 0, label: 'Select...' }
          return { value: state.id, label: state.name }
        })
        self.setState(stateCopy, () => {
          if (self.state.stateList.length > 0)
            self.setState({ stateValue: self.state.stateList[0].value })
        })
        if (self.state.fillData) {
          self.setState({
            stateValue: self.state.fillData.state_id,
          })
        }
      }
    })
  }

  city(id) {
    let self = this
    this.setState({ cityList: [] })

    api.cityList(id).then(function (response) {
      if (response.status === true) {
        let stateCopy = { ...self.state }
        stateCopy['cityList'] = response.cities.map((city, index) => {
          if (index === 0) return { value: 0, label: 'Select...' }
          return { value: city.id, label: city.name }
        })
        self.setState(stateCopy, () => {
          if (self.state.cityList.length > 0)
            self.setState({ cityValue: self.state.cityList[0].value })
        })

        if (response.cities.length === 0) {
          self.setState({ cityFlag: true })
        } else {
          self.setState({ cityFlag: false })
        }
        if (self.state.fillData) {
          self.setState({
            cityValue: self.state.fillData.city_id,
          })
        }
      }
    })
  }
  checkSponsoridOnLoad = () => {
    let inviteMatrixId = this.state.sponsors_iteam_id
    if (inviteMatrixId !== '' && inviteMatrixId != null) {
      this.validateSponsorid(inviteMatrixId)
    }
    //else this.setState({ sponsorErrorMsg: "", sponsorOwnerName: "" });
  }

  validateSponsorid = (value) => {
    let self = this
    if (value.length > 6) {
      let sponsorIdFound = false
      checkSponsorId({ sponser_id: value }).then(function (response) {
        if (response.data) {
          if (response.data.is_sponser_id_found === '1') {
            sponsorIdFound = true
            self.setState({
              sponsorOwnerName:
                response.data.user.user_type === 'individual'
                  ? response.data.user.fullname
                  : response.data.user.business_organization_name,
              sponsorErrorMsg: '',
            })
          }
        }
        if (!sponsorIdFound) {
          self.setState({
            sponsorOwnerName: '',
            sponsorErrorMsg: 'Sponsor id is invalid',
          })
        }
      })
    } else if (value !== '') {
      self.setState({
        sponsorOwnerName: '',
        sponsorErrorMsg: 'Sponsor id is invalid',
      })
    } else {
      self.setState({
        sponsorOwnerName: '',
        sponsorErrorMsg: '',
      })
    }
  }

  changeNewsTypeHandler = (event) => {
    this.setState({ newsletters_type: event.target.value })
  }

  signupBusinessSubmitHandler = (data) => {
    if (this.state.newsletters_type === '') {
      this.setState({ is_click: true })
      return
    }
    if (this.state.sponsorErrorMsg.length > 0) {
      window.scrollTo(0, 0)
      return
    }

    this.setState({
      isLoading: true,
      signupText: 'Loading...',
      newsletters_type: this.state.newsletters_type,
    })
    let formData = Object.assign({}, data)
    if (!formData.is_receive_newsletters) formData.is_receive_newsletters = '1'
    formData.relationship_type = data.relationship_type.value
    formData.country_id =
      data.country_id.value === undefined ? this.state.fillData.country_id : data.country_id.value
    formData.state_id = data.state_id
      ? data.state_id.value === undefined
        ? this.state.fillData.state_id
        : data.state_id.value
      : ''
    if (window.innerWidth < 992 && this.state.cityValue && this.state.cityValue > 0) {
      formData.city_id = this.state.cityValue
    } else {
      formData.city_id = data.city_id
        ? data.city_id.value === undefined
          ? this.state.fillData.city_id
          : data.city_id.value
        : ''
    }

    if (!this.state.areaFlag) {
      formData.city_id = ''
    }
    if (this.state.areaFlag) {
      formData.area = ''
    }

    if (!data.sponsors_iteam_id && this.state.imatrix_id.length > 0)
      formData.sponsors_iteam_id = this.state.imatrix_id

    formData.category_id = [data.category_id.value]

    if (window.innerWidth < 992) {
      if (this.state.subCategoryValue && this.state.subCategoryValue > 0) {
        formData.category_id.push(this.state.subCategoryValue)
        formData.sub_category_id = null
        delete formData.sub_category_id
      }

      if (this.state.subOfSubCategoryValue && this.state.subOfSubCategoryValue > 0) {
        formData.category_id.push(this.state.subOfSubCategoryValue)
        formData.sub_of_sub_category_id = null
        delete formData.sub_of_sub_category_id
      }
    } else {
      if (data.sub_category_id) {
        formData.category_id.push(data.sub_category_id.value)
        delete formData.sub_category_id
      }

      if (data.sub_of_sub_category_id) {
        formData.category_id.push(data.sub_of_sub_category_id.value)
        delete formData.sub_of_sub_category_id
      }
    }

    let self = this
    api.businessSignup(formData).then(function (response) {
      self.setState({ isLoading: false, signupText: 'Sign Up' })
      if (response.status !== 200) {
        data.relationship_type = self.state.relationship
        data.confirmpassword = self.state.confirmpassword
        data.confirmemail = self.state.confirmemail
      }

      if (response.status === 422) {
        self.setState({ error: response.data.errors })
        console.log('response.data.errors', response.data.errors)
        if (response.data.errors.email)
          self.setState({
            is_email_exists: '1',
          })
        if (response.data.errors.username)
          self.setState({
            is_username_exists: '1',
          })
        window.scrollTo(0, 0)
      }

      if (response.status !== true && response.status !== 422)
        toaster('error', 'Something went wrong')

      if (response.status === true) {
        toaster('success', response.message)

        if (getInviteMatrixId() && getInviteMatrixId() !== undefined) removeInviteMatrixId()

        self.props.history.push({
          pathname: process.env.PUBLIC_URL + '/register-success/' + response.slug,
        })
      }
    })
  }
  categoryList = () => {
    let self = this

    api.categoryList().then(function (response) {
      if (response.status === true) {
        self.setState({ categoryList: response.categories })
      }
    })
  }

  relationshipHandler = (event) => {
    this.setState({
      is_relationship_type: '0',
      error: {
        ...this.state.error,
        relationship_type: [],
      },
    })

    let relationshipIndex = relationshipList.findIndex((x) => x.label === event.label)
    this.setState({
      relationship: { value: event.value, label: event.label },
      relationshipIndex: relationshipIndex,
    })
  }

  businessChangeHandler = (event) => {
    let inputName = event.target.name
    let inputValue = event.target.value
    this.setState({
      [inputName]: inputValue,
    })
    /* 210922_em-01take out the not empty condition for the validation to work when the user delete his input */
    if (inputName === 'password') {
      this.setState({
        validation: validation.passwordValidation(inputValue, this.state.validation),
      })
    } else if (inputName === 'username') {
      this.setState({
        is_username_exists: '0',
        error: {
          ...this.state.error,
          username: [],
        },
        usernameValidation: validation.userNameValidation(
          inputValue,
          this.state.usernameValidation,
        ),
      })
    } else if (inputName === 'email') {
      this.setState({
        is_email_exists: '0',
        error: {
          ...this.state.error,
          email: [],
        },
      })
    }
  }
  passwordPopup = () => {
    setTimeout(() => {
      this.setState({
        isPasswordPopupDisplay: !this.state.isPasswordPopupDisplay,
      })
    }, 20)
  }
  usernamePopup = () => {
    setTimeout(() => {
      this.setState({
        isUsernamePopupDisplay: !this.state.isUsernamePopupDisplay,
      })
    }, 20)
  }

  checkUser = () => {
    let self = this
    if (validation.percentageOfValidation(this.state.usernameValidation) === 100) {
      checkUsername({ username: this.state.username }).then(function (response) {
        if (response) self.setState({ is_username_exists: response.data.is_username_exists })
      })
    }
  }
  checkEmail = () => {
    let self = this
    if (this.state.email.length > 0) {
      checkEmail({ email: this.state.email }).then(function (response) {
        if (response) self.setState({ is_email_exists: response.data.is_email_exists })
      })
    }
  }
  categoryChangeHandler = (event) => {
    let categoryIndex = this.state.categoryList.findIndex((x) => x.value === event.value)

    this.setState({
      categoryIndex: categoryIndex,
      subCategoryValue: null,
      subOfSubCategoryValue: null,
    })
    this.categoryListById('subcategory', event.value, 'sub_category_id')
  }
  subCategoryChangeHandler = (event) => {
    this.setState({
      subCategoryValue: event.value,
      subOfSubCategoryValue: null,
    })
    this.categoryListById('subofsubcategory', event.value, 'sub_of_sub_category_id')
  }
  subOfSubCategoryChangeHandler = (event) => {
    this.setState({ subOfSubCategoryValue: event.value })
  }

  categoryListById = (type, id, subtype = null) => {
    let self = this

    api.singleCategoryList(id).then(function (response) {
      if (response.data) {
        let categories = response.data.categories
        let categorylist = []
        categorylist = categories.map((category) => {
          return { value: category.id, label: category.category_name }
        })

        let obj = {}
        if (type === 'category') {
          obj.categoryList = categorylist
          obj.subCategoryList = []
          obj.subOfSubCategoryList = []
          obj.subCategoryValue = null
          obj.subOfSubCategoryValue = null
        } else if (type === 'subcategory') {
          categorylist.unshift({ value: 0, label: 'Select...' })
          obj.subCategoryList = categorylist
          obj.subOfSubCategoryList = []
          obj.subCategoryValue = categorylist[0].value
          obj.subOfSubCategoryValue = null
        } else if (type === 'subofsubcategory') {
          categorylist.unshift({ value: 0, label: 'Select...' })
          obj.subOfSubCategoryList = categorylist
          obj.subOfSubCategoryValue = categorylist[0].value
        }

        self.setState(obj)
      }
    })
    if (subtype) {
      this.props.change(subtype, null)
      this.props.touch(subtype, null)
    }
  }

  render() {
    const { handleSubmit } = this.props
    const {
      categoryList,
      subCategoryList,
      subOfSubCategoryList,
      subCategoryValue,
      subOfSubCategoryValue,
      newsletters_type,
      is_click,
      countryList,
      stateList,
      cityList,
      sponsorOwnerName,
      sponsorErrorMsg,
      countryObject,
    } = this.state
    //210824-1performed validation on sponsor if already entered
    if (this.state.imatrix_id) {
      this.props.change('sponsors_iteam_id', this.state.imatrix_id)
      this.props.touch('sponsors_iteam_id')
    }

    let validationPercentage = validation.percentageOfValidation(this.state.validation)

    let usernameValidationPercentage = validation.percentageOfValidation(
      this.state.usernameValidation,
    )
    /* #914 Distinct between First SignUp redirect SignUp*/
    let signUpLabels = customFunction.getSignUpLabels(
      this.props.location.state.imatrix_id,
      'Business',
    )
    return (
      <div className='container'>
        <div className='row'>
          <div className='loginblk signupblk'>
            <div className='panel panel-default'>
              <div className='panel-heading'>
                {/* <p className="f_14 mt-3">
                                    Already part of the iTRUSTit Community?
                                    <Link className="purple" to={LOGIN_ROUTE}>Log In</Link>
                                </p> */}
                <h3 className='panel-title'>{signUpLabels.title}</h3>
              </div>
              <div className='panel-body'>
                <form
                  method='post'
                  id='SignupBusiness3Form'
                  name='SignupBusiness3Form'
                  formkey='SignupBusiness3Form'
                  onSubmit={handleSubmit(this.signupBusinessSubmitHandler).bind(this)}
                >
                  <fieldset>
                    <div className='row fieldflex form-group'>
                      <div className='col-md-3'>
                        <label className='control'>{signUpLabels.iTeamId} iTeam ID#:</label>
                      </div>
                      <div className='col-md-9'>
                        <Field
                          name='sponsors_iteam_id'
                          type='text'
                          className='form-control'
                          component={renderInputField}
                          valueKey={this.state.imatrix_id}
                          validate={[
                            validation.noSpace,
                            validation.minLength(6),
                            // validation.sponsorRequired,
                          ]}
                          onChange={this.onSponsorChangeHandler}
                          placeholder={`Enter ${signUpLabels.iTeamId} iTeam ID# Here`}
                          autoComplete='off'
                        />
                        {sponsorOwnerName.length > 0 && (
                          <span
                            className='text-success'
                            style={{ margin: '7px 0', display: 'block' }}
                          >
                            {signUpLabels.iTeamId}: {sponsorOwnerName}
                          </span>
                        )}
                        {this.state.imatrix_id?.length >= 6 && sponsorErrorMsg.length > 0 && (
                          <span className='text-danger'>{sponsorErrorMsg}</span>
                        )}
                      </div>
                    </div>
                    {/* #914 hide business name not sure why*/}
                    <div className='row fieldflex form-group'>
                      <div className='col-md-3'>
                        <label className='control-required'>Business Name:</label>
                      </div>
                      <div className='col-md-9'>
                        <Field
                          name='business_organization_name'
                          type='text'
                          className='form-control'
                          component={renderInputField}
                          validate={[validation.required]}
                          placeholder='Enter Business Name'
                          autoComplete='off'
                          onChange={this.businessChangeHandler}
                        />
                      </div>
                    </div>
                    <div className='row fieldflex form-group'>
                      <div className='col-md-3'>
                        <label className='control-required'>Business Username:</label>
                      </div>
                      <div className='col-md-9 password_criteria'>
                        <Field
                          name='username'
                          type='text'
                          className='form-control password1'
                          component={renderInputField}
                          onFocus={this.usernamePopup}
                          onChange={this.businessChangeHandler}
                          onBlur={(event) => {
                            this.usernamePopup()
                            this.checkUser()
                          }}
                          validate={[
                            validation.required,
                            validation.minValue1,
                            validation.noUsernameSpace,
                            validation.onylCharacter,
                            validation.maxLength20,
                            validation.isBeginLatter,
                            validation.isDashed,
                            validation.isDashedStart,
                          ]}
                          label='Username'
                          placeholder='Enter business username'
                          autoComplete='new-password'
                        />
                        {this.state.is_username_exists === '1' && (
                          <span className='input_error text-danger'>{DUPLICATE_USER}</span>
                        )}
                        {this.state.isUsernamePopupDisplay && (
                          <div className='criteria'>
                            <div className='progress'>
                              <div
                                className='progress-bar'
                                role='progressbar'
                                aria-valuenow='0'
                                aria-valuemin='0'
                                aria-valuemax='100'
                                style={{
                                  width: usernameValidationPercentage + '%',
                                }}
                              >
                                <span className='sr-only'>70% Complete</span>
                              </div>
                            </div>
                            <h3> Criteria: </h3>
                            <ul>
                              <li
                                className={
                                  this.state.usernameValidation.isSpaceValidation ? 'success' : ''
                                }
                              >
                                No Spaces, cannot start or end with a space
                              </li>
                              <li
                                className={
                                  this.state.usernameValidation.isMaxLength ? 'success' : ''
                                }
                              >
                                Username MUST be between 1 and 20 characters
                              </li>
                              <li
                                className={
                                  this.state.usernameValidation.isBeginLatter ? 'success' : ''
                                }
                              >
                                Username MUST begin with a LETTER
                              </li>
                              <li
                                className={
                                  this.state.usernameValidation.isCharacter ? 'success' : ''
                                }
                              >
                                Only Characters A-Z a-z and 0-9 are allowed
                              </li>
                              <li
                                className={this.state.usernameValidation.isDashed ? 'success' : ''}
                              >
                                Dash is allowed BUT MUST be surrounded by letters
                              </li>
                            </ul>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className='row fieldflex form-group'>
                      <div className='col-md-4 text-start'>
                        <label>Business iTRUSTit Web Site:</label>
                      </div>
                      <div className='col-md-8 text-start it-user-url'>
                        <label> {environemnt.SITE_ADDRESS + this.state.username}</label>
                      </div>
                    </div>
                    <div className='row fieldflex form-group'>
                      <div className='col-md-3'>
                        <label className='control-required'>Country:</label>
                      </div>
                      <div className='col-md-9'>
                        <div className='for-select'>
                          <Field
                            type='text'
                            component={ReactSelect}
                            name='country_id'
                            options={countryList}
                            onChange={this.countrySelectHandler}
                            validate={[validation.required]}
                            isSelect='false'
                            valueKey={
                              countryList.length > 0 && countryList[this.state.countryIndex]
                            }
                          />
                        </div>
                      </div>
                    </div>
                    {countryObject ? (
                      <div className='row fieldflex form-group'>
                        <div className='col-md-3'>
                          <label className='control-required'>Zip / Postal Code:</label>
                        </div>
                        <div className='col-md-9'>
                          <Field
                            name='zipcode'
                            type='text'
                            className='form-control'
                            component={renderInputField}
                            validate={[validation.required]}
                            label='zipcode'
                            placeholder='Enter your zipcode'
                            autoComplete='off'
                            onChange={(event) =>
                              event.target.value.length >= 5 && this.zipcodeChange(event)
                            }
                            onBlur={this.zipcodeChange}
                          />
                        </div>
                      </div>
                    ) : (
                      ''
                    )}{' '}
                    {stateList.length > 0 ? (
                      <div className='row fieldflex form-group'>
                        <div className='col-md-3'>
                          <label className='control-required'>State/Province:</label>
                        </div>
                        <div className='col-md-9'>
                          <div className='for-select'>
                            <Field
                              type='text'
                              className='form-control'
                              component={ReactSelect}
                              name='state_id'
                              options={stateList}
                              value={this.state.stateValue}
                              valueKey={this.state.stateValue}
                              onChange={this.stateSelectHandler}
                              validate={[validation.required]}
                              isSelect='true'
                            />
                          </div>
                        </div>
                      </div>
                    ) : (
                      ''
                    )}
                    {cityList.length > 0 ? (
                      <div className='row fieldflex form-group'>
                        <div className='col-md-3'>
                          <label className='control-required'>City:</label>
                        </div>
                        <div className='col-md-9'>
                          <div className='for-select'>
                            <Field
                              type='text'
                              className='form-control'
                              component={ReactSelect}
                              name='city_id'
                              options={cityList}
                              onChange={this.citySelectHandler}
                              value={this.state.cityValue}
                              valueKey={this.state.cityValue}
                              validate={[validation.required]}
                              isSelect='true'
                            />
                          </div>
                        </div>
                      </div>
                    ) : this.state.cityFlag ? (
                      <div className='row fieldflex form-group'>
                        <div className='col-md-3'>
                          <label className='control-required'>City/Area:</label>
                        </div>
                        <div className='col-md-9'>
                          <Field
                            name='area'
                            type='text'
                            className='form-control'
                            component={renderInputField}
                            validate={[validation.required]}
                            label='City/Area'
                            placeholder='Enter city/area'
                            autoComplete='off'
                            onChange={this.areaChangedHandler}
                          />
                        </div>
                      </div>
                    ) : (
                      ''
                    )}
                    <div className='row fieldflex form-group'>
                      <div className='col-md-3'>
                        <label className='control-required'>Address 1:</label>
                      </div>
                      <div className='col-md-9'>
                        <Field
                          name='address_line_1'
                          type='text'
                          className='form-control'
                          component={renderInputField}
                          validate={[validation.required]}
                          label='address_line_1'
                          placeholder='Enter your address1'
                          autoComplete='off'
                        />
                      </div>
                    </div>
                    <div className='row fieldflex form-group'>
                      <div className='col-md-3'>
                        <label>Address 2:</label>
                      </div>
                      <div className='col-md-9'>
                        <Field
                          name='address_line_2'
                          type='text'
                          className='form-control'
                          component={renderInputField}
                          // validate={[validation.required]}
                          label='address_line_2'
                          placeholder='Enter your address2'
                          autoComplete='off'
                        />
                      </div>
                    </div>
                    <div className='row fieldflex form-group'>
                      <div className='col-md-3'>
                        <label className='control-required'>Category:</label>
                      </div>
                      <div className='col-md-9'>
                        <div className='for-select'>
                          <Field
                            type='text'
                            component={ReactSelect}
                            name='category_id'
                            options={categoryList}
                            valueKey={
                              categoryList.length > 0 && categoryList[this.state.categoryIndex]
                            }
                            validate={[validation.required]}
                            isSelect='false'
                            onChange={this.categoryChangeHandler}
                          />
                        </div>
                      </div>
                    </div>
                    {subCategoryList.length > 0 ? (
                      <div className='row fieldflex form-group'>
                        <div className='col-md-3'></div>
                        <div className='col-md-9'>
                          <div className='for-select'>
                            <Field
                              type='text'
                              component={ReactSelect}
                              name='sub_category_id'
                              options={subCategoryList}
                              valueKey={subCategoryValue}
                              //210824-1 -not required
                              //validate={[validation.required]}
                              isSelect='true'
                              onChange={this.subCategoryChangeHandler}
                            />
                          </div>
                        </div>
                      </div>
                    ) : (
                      ''
                    )}
                    {subOfSubCategoryList.length > 1 ? (
                      <div className='row fieldflex form-group'>
                        <div className='col-md-3'></div>
                        <div className='col-md-9'>
                          <div className='for-select text-start'>
                            <Field
                              type='text'
                              component={ReactSelect}
                              name='sub_of_sub_category_id'
                              valueKey={subOfSubCategoryValue}
                              options={subOfSubCategoryList}
                              //210824-1 -not required
                              //validate={[validation.required]}
                              isSelect='true'
                              onChange={this.subOfSubCategoryChangeHandler}
                            />
                          </div>
                        </div>
                      </div>
                    ) : (
                      ''
                    )}
                    <div className='row fieldflex form-group'>
                      <div className='col-md-3'>
                        <label className='control-required'>Your First Name:</label>
                      </div>
                      <div className='col-md-9'>
                        <Field
                          name='firstname'
                          type='text'
                          className='form-control'
                          component={renderInputField}
                          validate={[validation.required]}
                          label='Firstname'
                          placeholder='Enter your first name'
                          autoComplete='off'
                          onChange={this.businessChangeHandler}
                        />
                      </div>
                    </div>
                    <div className='row fieldflex form-group'>
                      <div className='col-md-3'>
                        <label className='control-required'>Your Last Name:</label>
                      </div>
                      <div className='col-md-9'>
                        <Field
                          name='lastname'
                          type='text'
                          className='form-control'
                          component={renderInputField}
                          validate={[validation.required]}
                          label='Lastname'
                          placeholder='Enter your last name'
                          autoComplete='off'
                          onChange={this.businessChangeHandler}
                        />
                      </div>
                    </div>
                    <div className='row fieldflex form-group'>
                      <div className='col-md-3'>
                        <label className='control-required'>Relationship to Business:</label>
                      </div>
                      <div className='col-md-9'>
                        <div className='for-select'>
                          <Field
                            type='text'
                            className='form-control'
                            component={ReactSelect}
                            name='relationship_type'
                            options={relationshipList}
                            validate={[validation.required]}
                            isSelect='false'
                            onChange={this.relationshipHandler}
                            valueKey={
                              relationshipList.length > 0 &&
                              relationshipList[this.state.relationshipIndex]
                            }
                          />
                          <ErrorMsgRenderer errors={this.state.error.relationship_type} />
                        </div>
                      </div>
                    </div>
                    <div className='row fieldflex form-group'>
                      <div className='col-md-3'>
                        <label className='control-required'>Email Address:</label>
                      </div>
                      <div className='col-md-9'>
                        <Field
                          name='email'
                          type='text'
                          className='form-control'
                          component={renderInputField}
                          validate={[validation.required, validation.email]}
                          label='Email'
                          placeholder='Enter your email address'
                          autoComplete='off'
                          onChange={this.businessChangeHandler}
                          onBlur={(event) => {
                            this.checkEmail()
                          }}
                        />
                        {this.state.is_email_exists === '1' && (
                          <span className='input_error text-danger'>{DUPLICATE_EMAIL}</span>
                        )}{' '}
                      </div>
                    </div>
                    <div className='row fieldflex form-group'>
                      <div className='col-md-3'>
                        <label className='control-required'>Confirm Email Address:</label>
                      </div>
                      <div className='col-md-9'>
                        <Field
                          name='confirmemail'
                          type='text'
                          className='form-control'
                          component={renderInputField}
                          validate={[validation.required, validation.confirmEmail]}
                          label='Email'
                          placeholder='Enter your email address'
                          autoComplete='off'
                          onChange={this.businessChangeHandler}
                        />
                      </div>
                    </div>
                    <div className='row fieldflex form-group'>
                      <div className='col-md-3'>
                        <label className='control-required'>Password:</label>
                      </div>
                      <div className='col-md-9 password_criteria'>
                        <Field
                          name='password'
                          type='password'
                          className='form-control'
                          component={renderInputField}
                          onFocus={this.passwordPopup}
                          onBlur={this.passwordPopup}
                          validate={[
                            validation.required,
                            validation.minValue8,
                            validation.alphaNumeric,
                            validation.maxLength20,
                            validation.noSpace,
                          ]}
                          label='Password'
                          placeholder='Enter your password'
                          autoComplete='new-password'
                          onChange={this.businessChangeHandler}
                        />
                        {this.state.isPasswordPopupDisplay && (
                          <div className='criteria'>
                            <div className='progress'>
                              <div
                                className='progress-bar'
                                role='progressbar'
                                aria-valuenow='0'
                                aria-valuemin='0'
                                aria-valuemax='100'
                                style={{
                                  width: validationPercentage + '%',
                                }}
                              >
                                <span className='sr-only'>70% Complete</span>
                              </div>
                            </div>
                            <h3> Criteria: </h3>
                            <ul>
                              <li
                                className={this.state.validation.isSpaceValidation ? 'success' : ''}
                              >
                                No Spaces, cannot start or end with a space
                              </li>
                              <li className={this.state.validation.isLowercase ? 'success' : ''}>
                                Include a lower case letter
                              </li>
                              <li className={this.state.validation.isUppercase ? 'success' : ''}>
                                Include a upper case letter
                              </li>
                              <li className={this.state.validation.isNumber ? 'success' : ''}>
                                Include number
                              </li>
                              <li className={this.state.validation.isMaxLength ? 'success' : ''}>
                                Be 8-20 characters long
                              </li>
                            </ul>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className='row fieldflex form-group'>
                      <div className='col-md-3'>
                        <label className='control-required'>Re-enter Password:</label>
                      </div>
                      <div className='col-md-9'>
                        <Field
                          name='confirmpassword'
                          type='password'
                          className='form-control'
                          component={renderInputField}
                          validate={[validation.required, validation.confirmPassword]}
                          label='Password'
                          placeholder='Re-enter your password'
                          autoComplete='off'
                          onChange={this.businessChangeHandler}
                        />
                      </div>
                    </div>
                    <div className='text-start signupbottom'>
                      {/* <div className="encrypted">
                                                Your passwords at iTRUSTit are encrypted and secured
                                            </div> */}
                      <div className='d-flex remember'>
                        <div className='radio1'>
                          <label className='radioblk it-small-auth'>
                            By clicking Sign Up I acknowledge that I am an Authorized Representative
                            of this Business and I have read and agree to the
                            <Link className='purple' target='_blank' to={TERMS_ROUTE}>
                              {' '}
                              Terms of Service{' '}
                            </Link>
                            <input
                              type='radio'
                              name='is_receive_newsletters'
                              value='1'
                              checked={this.state.newsletters_type === '1'}
                              onChange={this.changeNewsTypeHandler.bind(this)}
                            />
                            <span className='checkmark' />
                            {newsletters_type.length === 0 && is_click && (
                              <span className='text-danger'>
                                Your Acceptance Required to Sign Up
                              </span>
                            )}
                          </label>
                        </div>
                      </div>
                    </div>
                    {console.log(
                      this.props,
                      this.props.submitFailed,
                      this.state.is_username_exists,
                      this.props.invalid,
                      sponsorErrorMsg,
                      this.state.is_email_exists,
                    )}
                    {/*210824-1*/}
                    {validation.isFormValidWithError(
                      this.props.submitFailed,
                      this.state.is_username_exists,
                      this.props.invalid,
                      ['text-danger'],
                      sponsorErrorMsg,
                      this.state.is_email_exists,
                    )}
                    <input
                      type='submit'
                      value={this.state.signupText}
                      className={classNames(
                        'btn',
                        'btn-lg',
                        'btn-success',
                        'btn-block',
                        'loginbtn',
                      )}
                    />
                  </fieldset>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state, props) {
  if (props.fillData) {
    let businessData = props.fillData
    return {
      initialValues: {
        business_organization_name:
          Object.keys(businessData).length > 0 ? businessData.business_organization_name : '',
        address_line_1: Object.keys(businessData).length > 0 ? businessData.address_line_1 : '',
        address_line_2: Object.keys(businessData).length > 0 ? businessData.address_line_2 : '',
        country_id: Object.keys(businessData).length > 0 ? businessData.country_id : null,
        city_id: Object.keys(businessData).length > 0 ? businessData.city_id : '',
        state_id: Object.keys(businessData).length > 0 ? businessData.state_id : '',
        zipcode: Object.keys(businessData).length > 0 ? businessData.zipcode : '',
      },
    }
  }
}

SignupBusiness3 = reduxForm({
  form: 'SignupBusiness3Form',
  enableReinitialize: true,
  touchOnBlur: true,
  touchOnChange: true,
})(SignupBusiness3)

export default withRouter(connect(mapStateToProps)(SignupBusiness3))
