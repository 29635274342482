import React, { useState, useRef, useEffect } from 'react'

import {
  AUTHOR,
  DEFAULT_PROFILE_PICTURE,
  IMG_PATH,
  WALL_DELETE_REPLY,
} from '../../../services/const'

import { getUser, dateStringToTimeSince } from '../../../services/customFunction'
import { replyUpdateCall } from '../../../services/socialMediaApiService'

import '../Comment/Comment.scss'

import ReactionTypes from '../ReactionTypes'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { updateReply, updateReplyReaction } from '../../../actions/socialmedia'
import Reactions from '../Reactions'
import ReportContent from '../ReportContent'
import NewComment from '../NewComment'
import SocialShowMoreText from '../SocialShowMoreText'
import { DISABLE_SOCIAL_MEDIA_REPORT } from '../../../services/environment'
import { setOverFlow } from '../../../services/customFunction'
import Dialogs from '../Dialogs'

const Reply = ({ entry, post_user_id, deleteFunc, post_id = 0, ...props }) => {
  const login_user_id = getUser()?.id

  const [editComment, setEditComment] = useState(false)
  const [showReactionsModal, setShowReactionsModal] = useState(false)
  const [showReportDataModal, setShowReportDataModal] = useState(false)
  const [showDeleteReplyDialog, setShowDeleteReplyDialog] = useState(false)
  const textInput = useRef(null)
  const [isLoggedUser, setIsLoggedUser] = useState(login_user_id !== undefined)
  const [loginModalProps, setLoginModalProps] = useState(
    !isLoggedUser
      ? {
          'data-bs-toggle': 'modal',
          'data-bs-target': '#login_modal',
        }
      : {},
  )
  const request = {
    user_id: login_user_id,
    entry_id: entry?.id,
    entry_type: 'reply',
  }
  const reactionTotals = entry?.reaction_data?.reaction_totals
  const [loginUserReaction, setLoginUserReaction] = useState(
    entry?.reaction_data?.relevant_reactions?.your_reaction,
  )
  const convertReply = entry?.comment_body

  useEffect(() => {
    if (textInput.current) textInput.current.focus()
  }, [textInput])

  const onCloseReactionButtonClick = () => {
    setShowReactionsModal(false)
  }
  const onOpenReactionButtonClick = () => {
    setShowReactionsModal(true)
  }
  const onCloseReportContentButtonClick = () => {
    setShowReportDataModal(false)
  }
  const onOpenReportContentButtonClick = () => {
    setShowReportDataModal(true)
  }
  const onNoModifyReplyDialog = () => {
    setShowDeleteReplyDialog(false)
  }

  const onYesModifyReplyDialog = () => {
    deleteFunc(entry?.id)
    setShowDeleteReplyDialog(false)
  }
  const updateCommentHandler = async (comment_body) => {
    const response = await replyUpdateCall({
      id: entry?.id,
      comment_body: comment_body,
    })
    if (response?.status === 200) {
      //console.log('updatereply:', response?.data?.data)
      props?.updateReply({ id: post_id, data: response?.data?.data })
      entry.comment_body = comment_body
      let reply = response?.data?.data?.reply
      entry.comment_body = comment_body
      entry.updated_at = reply?.updated_at
    }
    setEditComment(false)
  }

  const clickReactionHandler = async (data) => {
    //console.log('data:', data)
    props.updateReplyReaction({
      post_id: post_id,
      comment_id: entry?.comment_id,
      id: entry?.id,
      data,
    })
    setLoginUserReaction(entry?.reaction_data?.relevant_reactions?.your_reaction)
  }
  const editCommentHandler = () => {
    setEditComment(true)
  }
  const cancelSubmitHandler = () => {
    setEditComment(false)
  }
  setOverFlow(showReactionsModal || showReportDataModal || showDeleteReplyDialog)
  return (
    <div>
      <div className='commentTop'>
        <div className='d-flex'>
          {' '}
          <div className='postSmallPicture'>
            <img
              className='img_block'
              src={entry?.profile_picture_small ?? DEFAULT_PROFILE_PICTURE}
              alt=''
            />
          </div>
          <div className='wallFlexColumnLeft overFlowHidden'>
            <a className='postUsername' href={process.env.PUBLIC_URL + '/' + entry?.slug}>
              {entry?.fullname}
            </a>
            <span className='postDate' style={{ marginLeft: 10 }}>
              {dateStringToTimeSince(entry?.updated_at, true)?.replace('ago', '')}
              {entry?.created_at === entry?.updated_at ? '' : 'Edited'}
            </span>
          </div>
        </div>

        {entry?.user_id === post_user_id ? (
          <span className='postAuthor'>{AUTHOR}</span>
        ) : (
          <div></div>
        )}
        <div class='commentActionButton'>
          <button
            class='btn dropdown-toggle '
            type='button'
            id='dropdownCommentMenu'
            data-bs-toggle='dropdown'
            aria-expanded='false'
          >
            ...
          </button>
          <ul class='dropdown-menu' aria-labelledby='dropdownCommentMenu'>
            {/*we can report any entry except ours*/}
            {login_user_id !== entry?.user_id && (
              <li>
                <button
                  class='dropdown-item'
                  {...loginModalProps}
                  onClick={() => isLoggedUser && onOpenReportContentButtonClick()}
                  disabled={DISABLE_SOCIAL_MEDIA_REPORT}
                >
                  <img src={IMG_PATH + 'report.svg'} className='likeIcon link' alt='' />
                  Report this reply
                </button>
              </li>
            )}
            {/*if is the login user reply, s/he can edit it*/}
            {login_user_id === entry?.user_id && (
              <li>
                <button class='dropdown-item' onClick={() => editCommentHandler()}>
                  <img src={IMG_PATH + 'edit.svg'} className='likeIcon link' alt='' />
                  Edit
                </button>
              </li>
            )}
            {/*if is login user's post he can delete the reply or if it is login user's reply*/}
            {(login_user_id === post_user_id || login_user_id === entry?.user_id) && (
              <li>
                <button class='dropdown-item' onClick={() => setShowDeleteReplyDialog(true)}>
                  <img src={IMG_PATH + 'delete.svg'} className='likeIcon link' alt='' />
                  Delete
                </button>
              </li>
            )}
          </ul>
        </div>
      </div>
      <div className='commentText'>
        {editComment ? (
          <>
            <div className='updateComment'>
              <div className='updateCommentText'>
                <NewComment
                  entry={entry}
                  addHandler={updateCommentHandler}
                  cancelSubmitHandler={cancelSubmitHandler}
                ></NewComment>
              </div>
            </div>
          </>
        ) : (
          <div className='grayContainer'>
            <SocialShowMoreText textToShow={convertReply}></SocialShowMoreText>
          </div>
        )}
      </div>
      <div className='commentBottom'>
        <div className='postBottomLeft'>
          {isLoggedUser ? (
            <ReactionTypes
              handleReaction={clickReactionHandler}
              loginUserReaction={loginUserReaction}
              loginModalProps={loginModalProps}
              reactionData={entry?.reaction_data?.relevant_reactions?.your_reaction}
              request={request}
              post_id={post_id}
            />
          ) : (
            ''
          )}
          {showReactionsModal && (
            <Reactions request={request} onCloseButtonClick={onCloseReactionButtonClick} />
          )}
          {showReportDataModal && (
            <ReportContent request={request} onCloseButtonClick={onCloseReportContentButtonClick} />
          )}
          {entry?.total_reactions > 0 ? (
            <div className='postBottomCenter'>
              <button
                className='postButton'
                type='button'
                onClick={() => {
                  onOpenReactionButtonClick()
                }}
              >
                {reactionTotals?.map((r) => {
                  let img = IMG_PATH + r.icon
                  return <img src={img} className='likeSmallIcon link ' alt='' />
                })}
                {entry?.total_reactions > 0 ? (
                  <span className='postLikeCounter'>{entry?.total_reactions}</span>
                ) : (
                  ''
                )}
              </button>
            </div>
          ) : (
            <div></div>
          )}
        </div>
      </div>
      {showDeleteReplyDialog && (
        <Dialogs
          title={'Delete comment'}
          question={WALL_DELETE_REPLY}
          onYesCloseDialog={onYesModifyReplyDialog}
          onNoCloseDialog={onNoModifyReplyDialog}
        />
      )}
    </div>
  )
}
/* const areStatesEqual = (nextStore, previousStore) => {
  let result = nextStore.post === previousStore.post
  //console.log('Reply areStatesEqual:', result)
  return result
} */
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateReply,
      updateReplyReaction,
    },
    dispatch,
  )
}
export default connect(null, mapDispatchToProps)(Reply)
