// import FacebookLogin from "react-facebook-login";
// import LinkedinSDK from "react-linkedin-sdk";
import axios from 'axios'
import { customLogger } from '../../../utils/CustomLogger'
import $ from 'jquery'
import React, { Component } from 'react'
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton } from 'react-share'
// import TwitterLogin from "react-twitter-auth";
import { PROFILE_OTHER_ROUTE_CONST, PROFILE_ROUTE_CONST } from '../../../services/const'
import * as customFunction from '../../../services/customFunction'
import { toaster } from '../../../services/customFunction'
import * as environemnt from '../../../services/environment'
import { SITE_ADDRESS, SOCIAL_SHARE_URL } from '../../../services/environment'
import * as route from '../../../services/routeConst'
import { showQRCode } from '../../CommonComponents/QRCodeModal/QRCodeModal'
import InviteEnvelope from './InviteEnvelope'
class InviteCenterTab extends Component {
  constructor(props) {
    super(props)
    this.state = {
      user: customFunction.getUser(),
      imatrix_id: customFunction.getInviteMatrixId(),
      shareContent: '',
      // clickedEnvelopeType: 'your_friends',
      // clickedEnvelopeText: 'Your Friends',
      // onTemplatePage: true,
      clickedEnvelopeType: '',
      clickedEnvelopeText: '',
      onTemplatePage: false,
    }
    this.FB = window.FB
  }

  componentDidMount() {
    let user = customFunction.getUser()
    let self = this
    if (user.user_type === 'individual') {
      self.setState({
        shareContent:
          'Join me on iTRUSTit to see all the businesses and organizations I trust to refer to my friends!',
      })
    }
    if (user.user_type === 'organization') {
      self.setState({
        shareContent: "Join us on iTRUSTit to check out our members' businesses and services",
      })
    }
    if (user.user_type === 'business') {
      self.setState({ shareContent: 'Review and Trust us on iTRUSTit ' })
    }
  }

  facebookLoginResponse(response) {
    //  console.log("response", response);
  }

  profileHeader_invite_center_tab_click_Handle = () => {
    this.setState({
      clickedEnvelopeType: '',
      clickedEnvelopeText: '',
      onTemplatePage: false,
    })
  }

  connectionApi(token) {
    // console.log("token", token);
    window.IN.API.Raw('/people/~')
      .result(function (data) {
        //console.log("res", data);
      })
      .error()
    axios
      .get('https://api.linkedin.com/v2/connections?q=viewer&count=50&state=' + token)
      .then(function (response) {
        //console.log("res", response);
      })
      .catch(function (error) {
        customLogger(error)
        //console.log("error", error);
      })
  }

  gmailUsingMailToLink = (data) => (event) => {
    let userType = this.state.user.user_type
    //let setLink = environemnt.SITE_ADDRESS + "invite/" + this.state.user.username;
    let setLink = environemnt.SITE_ADDRESS + 'invite/' + this.state.user.imatrix_id
    let formattedBody = ''
    let subject = ''
    if (userType === 'organization') {
      subject = 'See Businesses and Services associated with our Organization'
      formattedBody =
        "Hi there – \n\nWe've found a cool, free way to connect our members businesses to fellow members. \nThe site is called iTRUSTit and it is proving to be an incredibly convenient and useful member service. \n\nIt's easy to join us on iTRUSTit, just click on the link below to set up a free account: \n" +
        setLink +
        ' \n\nThe magic behind iTRUSTit is that we can share all the businesses and services associated with our members and organization in one easy place. \n\nAnd if you have a business you can add it to our directory, so that all our members can easily find you.\n\nView our profile on iTRUSTit:\n' +
        `https://www.itrustit.com/${this.state.user.username}` +
        '\n\nSign up today!'
    }
    if (userType === 'business') {
      subject = 'Review and Trust us on iTRUSTit.'
      formattedBody =
        "Hi there – \n\nWe need your help! We've found a cool new way for you to help us grow our social media. \nThe site is called iTRUSTit and it is proving to be an incredibly convenient and reliable way for our clients and friends to help promote us to their friends and for us to share other businesses and services we trust with you. \n\nPlease trust us on iTRUSTit by clicking on the link below: \n" +
        setLink +
        " \n\nThe magic behind iTRUSTit is that the results you see are from the people whose opinions you trust — your friends, family and colleagues. It's like calling all of them for advice…in just a one click!\n\nView our profile on iTRUSTit:\n" +
        `https://www.itrustit.com/${this.state.user.username}` +
        '\n\nLet me know if you have any questions. \nSign up today!'
    }
    if (userType === 'individual') {
      subject = 'See all the businesses and services that I trust and recommend. '
      formattedBody =
        "Hi there – \n\nI've found a cool free way to share all the local businesses and services that I trust with friends and family. \nThe site is called iTRUSTit and it is proving to be an incredibly convenient and reliable resource for everything from accountants to restaurants to contractors. \n\nTo check it out, simply join me on iTRUSTit by clicking on the link below: \n" +
        setLink +
        "\n\nThe magic behind iTRUSTit is that the results are from people whose opinions we trust — our friends, family and colleagues. It's like calling all of them for advice…in just a one click!\n\nView my profile on iTRUSTit:\n" +
        `https://www.itrustit.com/${this.state.user.username}` +
        '\n\nLet me know if you have any questions. \nSign up today! '
    }
    let mailToLink = 'mailto:?subject=' + subject + '&body=' + encodeURIComponent(formattedBody)
    document.getElementById(data).href = mailToLink
  }

  getMailToLinkNew = (data, email = false, copyingTemplate = false, id = 0) => {
    const socialShareText = SITE_ADDRESS + PROFILE_OTHER_ROUTE_CONST + this.props.user.imatrix_id
    const userProfileUrlDirect = SITE_ADDRESS + PROFILE_ROUTE_CONST + this.props.user.username
    let subject = ''
    let textToCopy = copyingTemplate
      ? document
          .querySelector(email ? '#email_template' : `#social_media_template-${id}`)
          .innerHTML.replaceAll(':</p>', '\r\n')
          .replaceAll('</p>', '\r\n\r\n')
          .replaceAll('<p class="mb-0">', '')
          .replace(`<a href="${socialShareText}">${socialShareText}</a>`, socialShareText)
          .replace(
            `<a href="${userProfileUrlDirect}">${userProfileUrlDirect}</a>`,
            userProfileUrlDirect,
          )
          .split('<p>')
      : null
    if (textToCopy[0] === '') {
      textToCopy = textToCopy.splice(1, textToCopy.length + 1)
    }
    //#926 subject and body mixed up for text email
    //    if (email) {
    subject = textToCopy[0]
    textToCopy = textToCopy.splice(1, textToCopy.length + 1)
    subject = subject.replace('\r\n\r\n', '')
    //    }
    textToCopy = textToCopy.join('')
    if (copyingTemplate) {
      const idx = textToCopy.lastIndexOf('\r\n')
      if (idx > -1) {
        textToCopy = textToCopy.substring(0, idx)
      }
    }
    //#926 subject and body mixed up for text email
    /*     if (!email) {
      textToCopy = textToCopy.replaceAll('\r\n\r\n', '\r\n');
    } */
    let mailToLink =
      'mailto:?subject=' + encodeURIComponent(subject) + '&body=' + encodeURIComponent(textToCopy)
    document.getElementById(data).href = mailToLink
  }

  copyToClipboard = (data, email = false, copyingTemplate = false, id = 0) => {
    const socialShareText = SITE_ADDRESS + PROFILE_OTHER_ROUTE_CONST + this.props.user.imatrix_id
    const userProfileUrlDirect = SITE_ADDRESS + PROFILE_ROUTE_CONST + this.props.user.username
    var textToCopy = copyingTemplate
      ? document
          .querySelector(email ? `#email_template` : `#social_media_template-${id}`)
          .innerHTML.replaceAll(':</p>', '\r\n')
          .replaceAll('</p>', '\r\n\r\n')
          .replaceAll('<p class="mb-0">', '')
          .replace(`<a href="${socialShareText}">${socialShareText}</a>`, socialShareText)
          .replace(
            `<a href="${userProfileUrlDirect}">${userProfileUrlDirect}</a>`,
            userProfileUrlDirect,
          )
          .split('<p>')
          .join('')
      : null
    if (copyingTemplate) {
      const idx = textToCopy.lastIndexOf('\r\n')
      if (idx > -1) {
        textToCopy = textToCopy.substring(0, idx)
      }
    }
    if (!email) {
      textToCopy = textToCopy.replaceAll('\r\n\r\n', '\r\n')
    }
    var textField = document.createElement('textarea')
    textField.className = 'it-ie-invite-copy-box'
    textField.value = copyingTemplate ? textToCopy : data
    document.body.appendChild(textField)
    textField.select()
    document.execCommand('copy')
    if (textField) {
      textField.remove()
    }
    toaster('success', 'Copied successfully')
  }

  setOnTemplatePage = (value = false, type, text) => {
    value &&
      window.scrollTo({
        top: $('#invite_center').offset().top - 100,
        behavior: 'smooth',
      })
    this.setState((st) => ({
      onTemplatePage: value,
      clickedEnvelopeText: value && text ? text : st.clickedEnvelopeText,
      clickedEnvelopeType: value && type ? type : st.clickedEnvelopeType,
    }))
  }

  getQuoteFromTemplate = (socialMediaTemplateBase) => {
    let socialShareQuoteShort = ''
    let socialShareQuoteTwitter = ''
    socialShareQuoteShort = socialMediaTemplateBase.props.children.map((el, idx) =>
      el.props.children.props
        ? el.props.children.props.children + '\r\n\r\n'
        : el.props.children +
          (el.props.className === 'mb-0' ||
          idx === socialMediaTemplateBase.props.children.length - 1
            ? '\r\n'
            : '\r\n\r\n'),
    )
    socialShareQuoteTwitter = socialShareQuoteShort[0].replace('\r\n', '')
    socialShareQuoteShort = socialShareQuoteShort.join('').replaceAll('\r\n\r\n', '')
    return { twitter: socialShareQuoteTwitter, short: socialShareQuoteShort }
  }

  // shareOnFacebook = () => {
  //   if (!this.FB) this.FB = window.FB

  //   this.FB?.ui?.({
  //     method: 'share',
  //     href: SITE_ADDRESS + PROFILE_OTHER_ROUTE_CONST + this.props?.user?.imatrix_id,
  //     redirect_uri: `${
  //       window.location.origin.includes('localhost')
  //         ? 'https://www.itrustit.com'
  //         : window.location.origin
  //     }`,
  //     mobile_iframe: true,
  //   })
  // }

  render() {
    const envelopes = {
      individual: [
        { type: 'your_friends', text: 'Your\nFriends' },
        { type: 'real_estate_agents', text: 'Real Estate Agents' },
        { type: 'businesses', text: 'Businesses' },
        { type: 'organizations', text: 'Organizations' },
      ],
      business: [
        { type: 'clients_and_friends', text: 'Clients & Friends' },
        { type: 'businesses', text: 'Businesses' },
        { type: 'organizations', text: 'Organizations' },
        {
          type: 'your_agents',
          text: 'Brokers:\nInvite Your Agents',
          headerText: 'Brokers: Invite Your Agents',
        },
        {
          type: 'agents_clients_and_friends',
          text: 'Agents:\nInvite Clients & Friends',
          headerText: 'Agents: Invite Clients & Friends',
        },
        {
          type: 'agent_businesses',
          text: 'Agents:\nInvite Businesses',
          headerText: 'Agents: Invite Businesses',
        },
        {
          type: 'other_agents',
          text: 'Agents:\nInvite Other Agents',
          headerText: 'Agents: Invite Other Agents',
        },
        {
          type: 'preferred_providers',
          text: 'Brokers:\nInvite Preferred\nProviders',
          headerText: 'Brokers: Invite Preferred Providers',
        },
      ],
      organization: [
        { type: 'your_members', text: 'Your Members' },
        { type: 'businesses_members', text: 'Businesses Members' },
        { type: 'associated_chapters', text: 'Associated Chapters' },
        { type: 'other_organizations', text: 'Other Organizations' },
      ],
    }
    const { onTemplatePage, clickedEnvelopeText } = this.state
    // const responseFacebook = (response) => {
    //   this.facebookLoginResponse(response);
    // };

    // const responseLinkedin = (response) => {
    //   // console.log(response);
    //   this.connectionApi(response.oauthToken);
    // };

    // const responseonSuccess = (response) => {
    //   //console.log("responseonSuccess", response);
    // };

    // const responseonFailed = (response) => {
    //   //console.log("responseonFailed", response);
    // };

    const { user } = this.props

    let userProfileUrl = SITE_ADDRESS + PROFILE_OTHER_ROUTE_CONST + user.imatrix_id
    let userProfileUrlDirect = SITE_ADDRESS + PROFILE_ROUTE_CONST + user.username

    let socialShareText =
      // 'Join me on iTRUSTit to see all the businesses and organizations I trust to refer to my friends! ' +
      userProfileUrl

    let emailTemplate
    let socialMediaTemplate

    if (this.state.user.user_type === 'individual') {
      switch (this.state.clickedEnvelopeType) {
        case 'your_friends':
          emailTemplate = (
            <React.Fragment>
              <p>See all the businesses and services I trust and recommend.</p>
              <p>You're Invited!</p>
              <p>
                I've found a cool, free way to share local businesses and services I trust. The site
                is called iTRUSTit and it is proving to be an easy and reliable referral source for
                everything from accountants to contractors to restaurants.
              </p>
              <p className='mb-0'>Join me on iTRUSTit by clicking here&#58;</p>
              <p>
                <a href={socialShareText}>{socialShareText}</a>
              </p>
              <p>
                The magic of iTRUSTit is the recommendations are from friends, family and
                colleagues, the people whose opinions we trust.
              </p>
              {/*210716-8*/}
              {customFunction.getSponsorDetails(user)}
              <p>Sign up today!</p>
            </React.Fragment>
          )
          socialMediaTemplate = (
            <React.Fragment>
              <p className='mb-0'>
                Join me on iTRUSTit to see businesses and services I recommend to my friends!
              </p>
              <p>
                <a href={socialShareText}>{socialShareText}</a>
              </p>
            </React.Fragment>
          )
          break
        case 'real_estate_agents':
          emailTemplate = (
            <React.Fragment>
              <p>Be my trusted real estate agent on iTRUSTit</p>
              <p>Hi there –</p>
              <p>
                I've found a cool free way to promote you as my trusted real estate agent to all my
                friends and family. The site is called iTRUSTit and it is proving to be an
                incredibly convenient way to share my favorite businesses and services with the
                people who often turn to me for referrals.
              </p>
              <p className='mb-0'>Join here so I can add you as my trusted Realtor&#58;</p>
              <p>
                <a href={socialShareText}>{socialShareText}</a>
              </p>
              <p>
                The magic behind iTRUSTit is that it provides one central place for me and my
                friends, family and colleagues to easily share and promote the businesses and
                services we trust and value most. It's like calling everyone we know for advice…in
                one click.
              </p>
              <p>
                iTRUSTit is an easy way to get more personal referrals. Once you join, you can
                invite your network of clients, friends and family to promote you to all of their
                connections too!
              </p>
              <p>Hint: Join as a Business so I can trust you!</p>
              {/*210716-8*/}
              {customFunction.getSponsorDetails(user)}
              <p>Sign up today!</p>
            </React.Fragment>
          )
          socialMediaTemplate = (
            <React.Fragment>
              <p className='mb-0'>
                Join me on iTRUSTit so I can promote you to my friends as my trusted Realtor!
              </p>
              <p>
                <a href={socialShareText}>{socialShareText}</a>
              </p>
            </React.Fragment>
          )
          break
        case 'businesses':
          emailTemplate = (
            <React.Fragment>
              <p>Be one of my trusted local businesses on iTRUSTit</p>
              <p>Hi there –</p>
              <p>
                I've found a cool, free way to promote your business to my friends and family. The
                site is called iTRUSTit and it is proving to be an incredibly convenient tool for
                sharing my favorite businesses and services.
              </p>
              <p className='mb-0'>Join me on iTRUSTit by clicking here&#58;</p>
              <p>
                <a href={socialShareText}>{socialShareText}</a>
              </p>
              <p>
                The magic of iTRUSTit is that it provides one place for me and my friends, family,
                and colleagues to share and promote the businesses and services we trust and value.
                Plus, iTRUSTit offers a ton of great features and will even donate a portion of its
                profits to the charity of your choice.
              </p>
              <p>
                iTRUSTit is an easy way to get more personal referrals. Once you join, you can
                invite your network to promote you to all their connections too!
              </p>
              {/*210716-8*/}
              {customFunction.getSponsorDetails(user)}
              <p>Sign up today, and let's get started promoting your business</p>
            </React.Fragment>
          )
          socialMediaTemplate = (
            <React.Fragment>
              <p className='mb-0'>
                Join me on iTRUSTit so I can promote you to my friends & family as a trusted
                business!
              </p>
              <p>
                <a href={socialShareText}>{socialShareText}</a>
              </p>
            </React.Fragment>
          )
          break
        case 'organizations':
          emailTemplate = (
            <React.Fragment>
              <p>Great opportunity to build awareness and add value for our members</p>
              <p>Hi there –</p>
              <p>
                Knowing that it's challenging for organizations to increase member value and
                engagement, I thought you might find this opportunity interesting.
              </p>
              <p>
                The site is called iTRUSTit and it is proving to be an incredibly convenient,
                powerful, and free way for organizations to share and promote their members'
                businesses.
              </p>
              <p className='mb-0'>
                The best way to introduce you to iTRUSTit is for you to set-up a free account by
                clicking here&#58;
              </p>
              <p>
                <a href={socialShareText}>{socialShareText}</a>
              </p>
              <p>
                The magic of iTRUSTit is that it provides one place for our members to share and
                promote the businesses and services they trust and value.
              </p>
              <p>
                Plus, iTRUSTit enables members to build awareness for our organization and promote
                us to all their friends. The heart of iTRUSTit is a perfect fit with the sharing,
                growing spirit of organizations.
              </p>
              <p>I'm happy to answer any questions.</p>
              {/*210716-8*/}
              {customFunction.getSponsorDetails(user)}
              <p>Sign up today!</p>
            </React.Fragment>
          )
          break

        default:
          break
      }
    } else if (this.state.user.user_type === 'business') {
      switch (this.state.clickedEnvelopeType) {
        case 'clients_and_friends':
          emailTemplate = (
            <React.Fragment>
              <p>Trust us on iTRUSTit.</p>
              <p>Hi there –</p>
              <p>
                We need your help! We've found a cool new way for you to help us grow our social
                media. The site is called iTRUSTit and it is proving to be an incredibly convenient
                and reliable way for clients and friends to share us with their friends and for us
                to share other businesses and services we trust with you.
              </p>
              <p className='mb-0'>Please trust us on iTRUSTit by clicking here&#58;</p>
              <p>
                <a href={socialShareText}>{socialShareText}</a>
              </p>
              <p>
                The magic behind iTRUSTit is that you see results from people whose opinions you
                trust — your friends, family and colleagues. It's like calling all of them for
                advice… in just a one click!
              </p>
              <p>
                Once you sign-up, click +Trust to trust me and then share other businesses you trust
                with your friends. Let me know if you have any questions!
              </p>
              {/*210716-8*/}
              {customFunction.getSponsorDetails(user)}
              <p>Sign up today!</p>
            </React.Fragment>
          )
          socialMediaTemplate = (
            <React.Fragment>
              <p className='mb-0'>Trust us on iTRUSTit</p>
              <p>
                <a href={socialShareText}>{socialShareText}</a>
              </p>
            </React.Fragment>
          )
          break
        case 'agents_clients_and_friends':
          emailTemplate = (
            <React.Fragment>
              <p>Trust me on iTRUSTit.</p>
              <p>Hi there –</p>
              <p>
                Join the fun! I've found a cool new way for you to help me grow my social media. The
                site is called iTRUSTit and it is proving to be an incredibly convenient and
                reliable way for my clients and friends to promote me to their friends and for me to
                share businesses and services I trust with you!
              </p>
              <p className='mb-0'>Please trust me on iTRUSTit by clicking here&#58;</p>
              <p>
                <a href={socialShareText}>{socialShareText}</a>
              </p>
              <p>
                The magic behind iTRUSTit is that you see results from people whose opinions you
                trust — your friends, family and colleagues. It's like calling all of them for
                advice…in just a one click!
              </p>
              <p>
                Once you sign-up, click +Trust to trust me and then share other businesses you trust
                with your friends. Let me know if you have any questions!
              </p>
              <p className='mb-0'>View my profile here&#58;</p>
              <p>
                <a href={userProfileUrlDirect}>{userProfileUrlDirect}</a>
              </p>
              {/*210716-8*/}
              {customFunction.getSponsorDetails(user)}
              <p>Sign up today!</p>
            </React.Fragment>
          )
          socialMediaTemplate = (
            <React.Fragment>
              <p className='mb-0'>
                Hi, I need your help to grow my social media. Trust me on iTRUSTit
              </p>
              <p>
                <a href={socialShareText}>{socialShareText}</a>
              </p>
            </React.Fragment>
          )
          break
        case 'businesses':
          emailTemplate = (
            <React.Fragment>
              <p>Be one of our trusted businesses on iTRUSTit.</p>
              <p>Hi there –</p>
              <p>
                We've found a cool, free way to promote your business to our clients, friends, and
                family. The site is called iTRUSTit and it is proving to be an incredibly convenient
                and powerful way for us to share the businesses and services we trust.
              </p>
              <p className='mb-0'>Simply join us on iTRUSTit by clicking here&#58;</p>
              <p>
                <a href={socialShareText}>{socialShareText}</a>
              </p>
              <p>
                The magic behind iTRUSTit is that we share the businesses and services we trust with
                our clients and friends. In turn, our clients, friends, and family share us as a
                trusted business with all their friends and family. iTRUSTit is exactly what you've
                been waiting for… easy word of mouth marketing and personal referrals online.
              </p>
              <p>
                Once you join, you can invite your clients, friends and family to promote you to all
                of their friends as well.
              </p>
              {/*210716-8*/}
              {customFunction.getSponsorDetails(user)}
              <p>It's quick and easy! Sign up today!</p>
            </React.Fragment>
          )
          socialMediaTemplate = [
            <React.Fragment>
              <p className='mb-0'>
                Join us on iTRUSTit so we can promote you as one of our trusted local businesses
              </p>
              <p>
                <a href={socialShareText}>{socialShareText}</a>
              </p>
            </React.Fragment>,
            <React.Fragment>
              <p className='mb-0'>Check out our page on iTRUSTit.</p>
              <p>
                <a href={userProfileUrlDirect}>{userProfileUrlDirect}</a>
              </p>
            </React.Fragment>,
          ]
          break
        case 'agent_businesses':
          emailTemplate = (
            <React.Fragment>
              <p>I want to promote you as a trusted business to my friends and clients.</p>
              <p>Hi there –</p>
              <p>
                I've found a cool free way to promote your business to all my clients, friends, and
                family.
              </p>
              <p>
                The site is called iTRUSTit and it is proving to be an incredibly convenient and
                powerful way for me to share the businesses and services I trust
              </p>
              <p className='mb-0'>Join me on iTRUSTit by clicking here&#58;</p>
              <p>
                <a href={socialShareText}>{socialShareText}</a>
              </p>
              <p>
                The magic of iTRUSTit is that I share the businesses and services I trust with my
                clients and friends. In turn, my clients, friends, and family trust me as a real
                estate agent with their friends and family. It's exactly what I've been waiting for…
                simple word-of-mouth marketing and personal referrals online.
              </p>
              <p className='mb-0'>View my profile here&#58;</p>
              <p>
                <a href={userProfileUrlDirect}>{userProfileUrlDirect}</a>
              </p>
              <p>
                Once you join, you can invite your clients, friends and family to promote you to all
                of their friends too.
              </p>
              {/*210716-8*/}
              {customFunction.getSponsorDetails(user)}
              <p>It's quick and easy. Sign up today!</p>
            </React.Fragment>
          )
          socialMediaTemplate = (
            <React.Fragment>
              <p className='mb-0'>
                Join me on iTRUSTit so I can promote you as one of my trusted local businesses to
                all my clients
              </p>
              <p>
                <a href={socialShareText}>{socialShareText}</a>
              </p>
            </React.Fragment>
          )
          break
        case 'your_agents':
          emailTemplate = (
            <React.Fragment>
              <p>Join our agent directory on iTRUSTit</p>
              <p>Hi there –</p>
              <p>
                We've found a highly effective, free way to promote our agents and build lasting and
                valuable connections with our clients. The site is called iTRUSTit and it is proving
                to be an incredibly convenient and powerful marketing and engagement tool.
              </p>
              <p className='mb-0'>
                To join our directory on iTRUSTit, sign up as a "business" (not a person)&#58;
              </p>
              <p className='mb-0'>
                Hint&#58; You may want to sign up a personal account first, then add your Realtor
                Business account&#58;
              </p>
              <p>
                <a href={socialShareText}>{socialShareText}</a>
              </p>
              <p>
                iTRUSTit is exactly what we need… easy word-of-mouth marketing and personal
                referrals online. Once you join, you'll share the businesses and services that you
                trust with your clients and friends. In turn, your clients will promote you as their
                trusted real estate agent to all their connections!
              </p>
              <p>
                The concept is simple and builds on the reality that clients often turn to their
                agent for referrals and recommendations for everything from contractors to dry
                cleaners and restaurants, and we count on our clients to refer us to their friends,
                iTRUSTit makes it easy!
              </p>
              <p>
                The heart of iTRUSTit is a perfect fit with the spirit of realtors sharing and
                building connections. We're shooting for full participation so that we deliver the
                best possible experience to our agents and clients.
              </p>
              {/*210716-8*/}
              {customFunction.getSponsorDetails(user)}
              <p>Thank you. Sign up today!</p>
            </React.Fragment>
          )
          socialMediaTemplate = (
            <React.Fragment>
              <p className='mb-0'>
                Join us on iTRUSTit so we can promote you as one of our trusted agents to potential
                clients
              </p>
              <p>
                <a href={socialShareText}>{socialShareText}</a>
              </p>
            </React.Fragment>
          )
          break
        case 'preferred_providers':
          emailTemplate = (
            <React.Fragment>
              <p>
                We want to promote you as a trusted preferred provider to our agents and clients
              </p>
              <p>Hi there –</p>
              <p>
                We've found a cool free way to promote you to all our agents and clients as one of
                our trusted, preferred providers. The site is called iTRUSTit and it is proving to
                be an incredibly convenient and powerful way for us to share the businesses and
                services we trust.
              </p>
              <p className='mb-0'>Join us on iTRUSTit by clicking here&#58;</p>
              <p>
                <a href={socialShareText}>{socialShareText}</a>
              </p>
              <p>
                The magic behind iTRUSTit is that we share the businesses and services we trust with
                our agents and clients.
              </p>
              {/*210716-8*/}
              {customFunction.getSponsorDetails(user)}
              <p>Sign up today so we can start promoting you!</p>
            </React.Fragment>
          )
          socialMediaTemplate = (
            <React.Fragment>
              <p className='mb-0'>
                Join us on iTRUSTit so we can promote you as one of our preferred providers to our
                agents and clients
              </p>
              <p>
                <a href={socialShareText}>{socialShareText}</a>
              </p>
            </React.Fragment>
          )
          break
        case 'organizations':
          emailTemplate = (
            <React.Fragment>
              <p>Great opportunity to build awareness and add value for our members</p>
              <p>Hi there –</p>
              <p>
                Knowing that it's challenging for organizations to increase member value and
                engagement, I thought you might find this opportunity interesting.
              </p>
              <p>
                The site is called iTRUSTit and it is proving to be an incredibly convenient,
                powerful, and free way for organizations to share and promote their members'
                businesses.
              </p>
              <p className='mb-0'>
                The best way to introduce you to iTRUSTit is for you to sign-up a free account by
                clicking here&#58;
              </p>
              <p>
                <a href={socialShareText}>{socialShareText}</a>
              </p>
              <p>
                The magic of iTRUSTit is that it provides one place for our members to share and
                promote the businesses and services they trust and value.
              </p>
              <p>
                Plus, iTRUSTit enables members to build awareness for our organization and promote
                us to all their friends.
              </p>
              {/*210716-8*/}
              {customFunction.getSponsorDetails(user)}
              <p>Happy to discuss further or to answer any questions.</p>
            </React.Fragment>
          )
          break
        case 'other_agents':
          emailTemplate = (
            <React.Fragment>
              <p>Build lasting and valuable connections with your clients</p>
              <p>Hi there –</p>
              <p>
                I've found a highly effective, free way to promote ourselves as trusted agents and
                build lasting and valuable connections with our clients. The site is called iTRUSTit
                and it is proving to be an incredibly convenient and powerful marketing and
                engagement tool.
              </p>
              <p className='mb-0'>To join iTRUSTit, sign up as a "business" (not a person)&#58;</p>
              <p className='mb-0'>
                Hint&#58; You may want to sign up a personal account first, then add your Business
                account&#58;
              </p>
              <p>
                <a href={socialShareText}>{socialShareText}</a>
              </p>
              <p>
                iTRUSTit is exactly what we need … easy word-of-mouth marketing and personal
                referrals online. Once you join, you can share businesses and services you trust
                with your clients and friends. In turn, your clients will promote you as their
                trusted real estate agent to all their connections!
              </p>
              <p>
                The concept is simple and builds on the fact that clients often turn to their agent
                for referrals and recommendations for everything from contractors to dry cleaners
                and restaurants, and we count on our clients to refer us to their friends, iTRUSTit
                makes it easy!
              </p>
              <p className='mb-0'>Thank you,</p>
              {/*210716-8*/}
              {customFunction.getSponsorDetails(user)}
              <p>Sign up today!</p>
            </React.Fragment>
          )
          socialMediaTemplate = (
            <React.Fragment>
              <p className='mb-0'>Get referrals from your clients&#58;</p>
              <p>
                <a href={socialShareText}>{socialShareText}</a>
              </p>
            </React.Fragment>
          )
          break

        default:
          break
      }
    } else if (this.state.user.user_type === 'organization') {
      switch (this.state.clickedEnvelopeType) {
        case 'your_members':
          emailTemplate = (
            <React.Fragment>
              <p>See Businesses and Services associated with our Organization</p>
              <p>Hi there –</p>
              <p>
                We've found a cool, free way to connect our members to fellow members businesses and
                services. The site is called iTRUSTit and it is proving to be an incredibly
                convenient and useful member service.
              </p>
              <p className='mb-0'>It's easy to join us on iTRUSTit, just click here&#58;</p>
              <p>
                <a href={socialShareText}>{socialShareText}</a>
              </p>
              <p>
                The magic of iTRUSTit is we can share all the businesses and services associated
                with our organization in one easy place.
              </p>
              <p>
                Once you join, click '+Member of This Organization' to be added to our member
                directory. If you have a business you can add it to our directory as well, so that
                all our members can easily find you.
              </p>
              {/*210716-8*/}
              {customFunction.getSponsorDetails(user)}
              <p>Sign up today!</p>
            </React.Fragment>
          )
          socialMediaTemplate = [
            <React.Fragment>
              <p className='mb-0'>Help support our organization by Friending and Trusting us!</p>
              <p>
                <a href={userProfileUrlDirect}>{userProfileUrlDirect}</a>
              </p>
            </React.Fragment>,
            <React.Fragment>
              <p className='mb-0'>
                Join us on iTRUSTit to check out our members' businesses and services
              </p>
              <p>
                <a href={socialShareText}>{socialShareText}</a>
              </p>
            </React.Fragment>,
          ]
          break
        case 'businesses_members':
          emailTemplate = (
            <React.Fragment>
              <p>Great way to help our members promote their businesses</p>
              <p>Hi there –</p>
              <p>
                We've found a cool, free way to promote our members' businesses to our entire
                membership and beyond. The site is called iTRUSTit and it is proving to be an
                incredibly convenient and valuable member service.
              </p>
              <p className='mb-0'>It's easy to join us on iTRUSTit, just click here&#58;</p>
              <p>
                <a href={socialShareText}>{socialShareText}</a>
              </p>
              <p>
                The magic behind iTRUSTit is that we can share all the businesses and services
                associated with our members and organization in one easy place.
              </p>
              <p>
                Once you join, click '+Member of This Organization' to be added to our member
                directory.
              </p>
              {/*210716-8*/}
              {customFunction.getSponsorDetails(user)}
              <p>Sign up today!</p>
            </React.Fragment>
          )
          socialMediaTemplate = (
            <React.Fragment>
              <p className='mb-0'>
                Join our business directory on iTRUSTit so all our members can find you!
              </p>
              <p>
                <a href={socialShareText}>{socialShareText}</a>
              </p>
            </React.Fragment>
          )
          break
        case 'other_organizations':
          emailTemplate = (
            <React.Fragment>
              <p>Great opportunity to build awareness and add value for your members</p>
              <p>Hi there –</p>
              <p>
                It's challenging for organizations to increase member value and engagement without
                incurring additional costs. Given that reality we thought you would find this
                opportunity interesting.
              </p>
              <p>
                The site is called iTRUSTit and it is proving to be an incredibly convenient and
                powerful way for organizations to share and promote their members' businesses
              </p>
              <p className='mb-0'>
                The best way to introduce you to iTRUSTit is for you to set-up a free account by
                clicking here&#58;
              </p>
              <p>
                <a href={socialShareText}>{socialShareText}</a>
              </p>
              <p>
                The magic behind iTRUSTit is that you can share all the businesses and services
                associated with your members and organization in one easy place.
              </p>
              <p>
                Once you set up an account for your organization, you can offer your members a great
                way to find associated businesses and services! I'm happy to discuss and answer any
                questions.
              </p>
              {/*210716-8*/}
              {customFunction.getSponsorDetails(user)}
              <p>Wishing you continued success!</p>
            </React.Fragment>
          )
          break
        case 'associated_chapters':
          emailTemplate = (
            <React.Fragment>
              <p>Great opportunity to build awareness and add value for our members</p>
              <p>Hi there –</p>
              <p>
                I'm sure we agree that it's challenging for our organization to increase member
                value and engagement without incurring additional costs. With that in mind, I
                thought you might find this opportunity interesting.
              </p>
              <p>
                The site is called iTRUSTit and it is proving to be an incredibly convenient and
                powerful way for us to promote and share our members' businesses
              </p>
              <p className='mb-0'>
                The best way to introduce you to iTRUSTit is for you to set-up a free account by
                clicking here&#58;
              </p>
              <p>
                <a href={socialShareText}>{socialShareText}</a>
              </p>
              <p>
                The magic of iTRUSTit is that we can share all the businesses and services
                associated with our members and organization in one easy place.
              </p>
              <p>
                Once you set up an Organization account, you can offer members a great way to find
                associated businesses and services. I'm pretty excited about this and think we can
                do a lot with it.
              </p>
              {/*210716-8*/}
              {customFunction.getSponsorDetails(user)}
              <p>I'm happy to answer any questions. Look forward to hearing from you.</p>
            </React.Fragment>
          )
          break
        default:
          break
      }
    }
    let socialShareQuote = ''
    let socialShareQuoteTitle = ''
    if (emailTemplate) {
      socialShareQuote = emailTemplate.props.children.map((el, idx) =>
        el.props.children.props
          ? el.props.children.props.children + '\r\n\r\n'
          : el.props.children?.[0]?.props?.children
          ? el.props.children
              ?.map((child) => child?.props?.children?.join?.('') ?? child?.props?.children)
              ?.join('\r\n') + '\r\n\r\n'
          : el.props.children +
            (el.props.className === 'mb-0' || idx === emailTemplate.props.children.length - 1
              ? '\r\n'
              : '\r\n\r\n'),
      )

      socialShareQuoteTitle = socialShareQuote.shift().replace('\r\n\r\n', '')
      socialShareQuote = socialShareQuote.join('')
    }
    if (socialMediaTemplate) {
      let socialMediaTemplateBase = socialMediaTemplate
      if (socialMediaTemplate.length) {
        socialMediaTemplate = socialMediaTemplate.map((template) => {
          socialMediaTemplateBase = template
          const { twitter, short } = this.getQuoteFromTemplate(socialMediaTemplateBase)
          return {
            ...template,
            socialShareQuoteShort: short,
            socialShareQuoteTwitter: twitter,
          }
        })
      } else {
        const { twitter, short } = this.getQuoteFromTemplate(socialMediaTemplateBase)
        socialMediaTemplate = [
          {
            ...socialMediaTemplate,
            socialShareQuoteShort: short,
            socialShareQuoteTwitter: twitter,
          },
        ]
      }
      // const { twitter, short } = this.getQuoteFromTemplate(
      //   socialMediaTemplateBase
      // );
      // socialShareQuoteShort = short;
      // socialShareQuoteTwitter = twitter;
      // console.log({
      //   ...socialMediaTemplate[0],
      //   socialShareQuoteShort,
      //   socialShareQuoteTwitter,
      // });
    }

    return (
      <div
        id='invite_center'
        className={'tab-pane ' + (this.props.activeTab === '#invite_center' ? 'active' : 'fade')}
      >
        <h4 className='varela'>Invite Center</h4>
        <div className='bg-white'>
          {!onTemplatePage ? (
            <div className='invite_blk'>
              <small
                style={{
                  fontFamily: "'Varela Round', sans-serif",
                }}
              >
                iTRUSTit is better with your friends!
              </small>
              <div className='mt-3 social_blk_invite'>
                <div className='invite_list d-flex align-items-center mb-3'>
                  <h4 className='mb-0 varela pe-1 align-self-end'>Invite</h4>
                  <span className='px-1'>
                    <img width='20' src={process.env.PUBLIC_URL + '/img/email_invite.svg'} alt='' />
                  </span>
                  <span className='px-1'>
                    <img width='20' src={process.env.PUBLIC_URL + '/img/sms.svg'} alt='' />
                  </span>
                  <span className='px-1'>
                    <img width='20' src={process.env.PUBLIC_URL + '/img/fb_invite.svg'} alt='' />
                  </span>
                  <span className='px-1'>
                    <img
                      width='20'
                      src={process.env.PUBLIC_URL + '/img/linkedin_invite.svg'}
                      alt=''
                    />
                  </span>
                  <span className='px-1'>
                    <img
                      width='20'
                      src={process.env.PUBLIC_URL + '/img/twitter_share.svg'}
                      alt=''
                    />
                  </span>
                </div>
                <div className='invite_list'>
                  <div className='list_of_social row m-0 justify-content-start'>
                    {envelopes[this.state.user.user_type].map((invite, idx) => (
                      <InviteEnvelope
                        key={idx}
                        invite={invite}
                        handleClick={this.setOnTemplatePage}
                      />
                    ))}
                  </div>
                </div>
                <div className='invite_list mb-5'>
                  <p className='mb-1'>Get your Invite/Profile page QR code here.</p>
                  <ul className='list_of_social row'>
                    <li className='col-md-12'>
                      <div className='social_item bottom'>
                        {/* <p id="socialShareTextSection">
                                                Join me on iTRUSTit to see all the businesses and organizations I trust
                                                to refer to my friends! {userProfileUrl}
                                            </p> */}
                        <p>{socialShareText}</p>
                        <div>
                          <a href={null} className='blue_btn' onClick={showQRCode}>
                            Click for QR Code
                          </a>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className='invite_list mb-5'>
                  <p className='mb-1'>Or copy and paste this text.</p>
                  <ul className='list_of_social row'>
                    <li className='col-md-12'>
                      <div className='social_item bottom'>
                        {/* <p id="socialShareTextSection">
                                                Join me on iTRUSTit to see all the businesses and organizations I trust
                                                to refer to my friends! {userProfileUrl}
                                            </p> */}
                        <p>{socialShareText}</p>
                        <div>
                          <a
                            href={null}
                            className='blue_btn'
                            onClick={() => this.copyToClipboard(userProfileUrl, true)}
                          >
                            Click to copy
                          </a>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className='invite_list mb-5'>
                  <p className='mb-1'>
                    Invite others to your iTRUSTit profile page with this link.
                  </p>
                  <ul className='list_of_social row'>
                    <li className='col-md-12'>
                      <div className='social_item bottom'>
                        {/* <p id="socialShareTextSection">
                                                Join me on iTRUSTit to see all the businesses and organizations I trust
                                                to refer to my friends! {userProfileUrl}
                                            </p> */}
                        <p>{userProfileUrlDirect}</p>
                        <div>
                          <a
                            href={null}
                            className='blue_btn'
                            onClick={() => this.copyToClipboard(userProfileUrlDirect, true)}
                          >
                            Click to copy
                          </a>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <small className='text-muted'>
                  By using the invite center, you agree to our
                  <a href={route.TERMS_ROUTE}> Terms and Conditions</a>
                </small>
              </div>
            </div>
          ) : (
            <div className='invite_blk'>
              <div className='row mb-1  align-items-center'>
                <h4 className='col-11 big_1 varela'>For {clickedEnvelopeText}</h4>
                <button
                  type='button'
                  className='col-1 btn-close pe-4 varela text-end'
                  style={{ marginBottom: '0.5rem' }}
                  onClick={() => this.setOnTemplatePage(false)}
                ></button>
              </div>
              <div className='px-3 varela'>
                <h5 className='varela' style={{ color: '#544cf9' }}>
                  Email / Social Media suggested invitation
                </h5>
                <em className='fw-bold'>Suggested Subject Line&#58;</em>
                <div id='email_template'>{emailTemplate}</div>
                <div className='invite_list d-flex align-items-center'>
                  <a
                    href={null}
                    id='mailto_invite'
                    className='pe-2'
                    role='button'
                    onClick={() => this.getMailToLinkNew('mailto_invite', true, true)}
                  >
                    <img width='36' src={process.env.PUBLIC_URL + '/img/email_invite.svg'} alt='' />
                  </a>
                  <a
                    className='px-2'
                    href={`sms:?&body=${
                      !!socialShareQuote ? encodeURIComponent(socialShareQuote) : ''
                    }`}
                  >
                    <img width='36' src={process.env.PUBLIC_URL + '/img/sms.svg'} alt='' />
                  </a>
                  <div
                    className='px-2'
                    role='button'
                    onClick={() => this.copyToClipboard(emailTemplate, true, true)}
                  >
                    <img width='36' src={process.env.PUBLIC_URL + '/img/copy.svg'} alt='' />
                  </div>
                  {/* <div className='px-2' role='button'>
                    <img
                      width='36'
                      src={process.env.PUBLIC_URL + '/img/fb_invite.svg'}
                      alt=''
                    />
                  </div> */}
                  <FacebookShareButton
                    url={socialShareText}
                    quote={!!socialShareQuote ? socialShareQuote : ''}
                    hashtag='#itrustit'
                  >
                    {/* url={SOCIAL_SHARE_URL}     */}
                    <div className='px-2' role='button'>
                      <img width='36' src={process.env.PUBLIC_URL + '/img/fb_invite.svg'} alt='' />
                    </div>
                  </FacebookShareButton>
                  {/* <button onClick={this.shareOnFacebook}>Facebook</button> */}
                  {/* <div className='px-2' role='button'>
                    <img
                      width='36'
                      src={process.env.PUBLIC_URL + '/img/linkedin_invite.svg'}
                      alt=''
                    />
                  </div> */}
                  <LinkedinShareButton
                    url={socialShareText}
                    title={!!socialShareQuoteTitle ? socialShareQuoteTitle : ''}
                    // description={!!socialShareQuote ? socialShareQuote : ''}
                    // text={!!socialShareQuote ? socialShareQuote : ''}
                    summary={!!socialShareQuote ? socialShareQuote : ''}
                    source='iTRUSTit'
                  >
                    <div className='px-2' role='button'>
                      <img
                        width='36'
                        src={process.env.PUBLIC_URL + '/img/linkedin_invite.svg'}
                        alt=''
                      />
                    </div>
                  </LinkedinShareButton>
                  {/* <div className='px-2' role='button'>
                    <img
                      width='36'
                      src={process.env.PUBLIC_URL + '/img/twitter_share.svg'}
                      alt=''
                    />
                  </div> */}
                </div>
                <hr
                  style={{
                    border: 0,
                    height: 0,
                    borderTop: '4px solid #f6f6f6',
                    borderBottom: '4px solid rgba(255, 255, 255, 0.3)',
                  }}
                />
              </div>
              {socialMediaTemplate &&
                socialMediaTemplate.map((template, idx) => (
                  <div className='px-3 varela' key={idx}>
                    <h5 className='varela' style={{ color: '#544cf9' }}>
                      Text / Twitter / Social Media suggested invitation
                    </h5>
                    <div id={`social_media_template-${idx}`}>{template}</div>
                    <div className='invite_list d-flex align-items-center'>
                      <a
                        href={null}
                        id={`social_mailto_invite-${idx}`}
                        className='pe-2'
                        role='button'
                        onClick={() =>
                          this.getMailToLinkNew(`social_mailto_invite-${idx}`, false, true, idx)
                        }
                      >
                        <img
                          width='36'
                          src={process.env.PUBLIC_URL + '/img/email_invite.svg'}
                          alt=''
                        />
                      </a>
                      <a
                        className='px-2'
                        href={`sms:?&body=${
                          template.socialShareQuoteShort !== ''
                            ? encodeURIComponent(template.socialShareQuoteShort)
                            : ''
                        }`}
                      >
                        <img width='36' src={process.env.PUBLIC_URL + '/img/sms.svg'} alt='' />
                      </a>
                      <div
                        className='px-2'
                        role='button'
                        onClick={() => this.copyToClipboard(template, false, true, idx)}
                      >
                        <img width='36' src={process.env.PUBLIC_URL + '/img/copy.svg'} alt='' />
                      </div>
                      {/* <div className='px-2' role='button'>
                      <img
                        width='36'
                        src={process.env.PUBLIC_URL + '/img/fb_invite.svg'}
                        alt=''
                      />
                    </div> */}
                      <FacebookShareButton
                        url={socialShareText}
                        hashtag='#itrustit'
                        quote={
                          template.socialShareQuoteShort !== ''
                            ? template.socialShareQuoteShort
                            : ''
                        }
                      >
                        {/* url={SOCIAL_SHARE_URL}     */}
                        <div className='px-2' role='button'>
                          <img
                            width='36'
                            src={process.env.PUBLIC_URL + '/img/fb_invite.svg'}
                            alt=''
                          />
                        </div>
                      </FacebookShareButton>
                      {/* <div className='px-2' role='button'>
                      <img
                        width='36'
                        src={
                          process.env.PUBLIC_URL + '/img/linkedin_invite.svg'
                        }
                        alt=''
                      />
                    </div> */}
                      <LinkedinShareButton
                        url={socialShareText}
                        title={!!socialShareQuoteTitle ? socialShareQuoteTitle : 'nope'}
                        summary={
                          template.socialShareQuoteShort !== ''
                            ? template.socialShareQuoteShort
                            : ''
                        }
                        source='iTRUSTit'
                      >
                        <div className='px-2' role='button'>
                          <img
                            width='36'
                            src={process.env.PUBLIC_URL + '/img/linkedin_invite.svg'}
                            alt=''
                          />
                        </div>
                      </LinkedinShareButton>
                      {/* <div className='px-2' role='button'>
                      <img
                        width='36'
                        src={process.env.PUBLIC_URL + '/img/twitter_share.svg'}
                        alt=''
                      />
                    </div> */}
                      <TwitterShareButton
                        url={socialShareText}
                        hashtags={['itrustit']}
                        title={
                          template.socialShareQuoteTwitter !== ''
                            ? template.socialShareQuoteTwitter
                            : ''
                        }
                      >
                        <div className='px-2' role='button'>
                          <img
                            width='36'
                            src={process.env.PUBLIC_URL + '/img/twitter_share.svg'}
                            alt=''
                          />
                        </div>
                      </TwitterShareButton>
                    </div>
                    <hr
                      style={{
                        border: 0,
                        height: 0,
                        borderTop: '4px solid #f6f6f6',
                        borderBottom: '4px solid rgba(255, 255, 255, 0.3)',
                      }}
                    />
                  </div>
                ))}
              <small className='text-muted'>
                By using the invite center, you agree to our
                <a href={route.TERMS_ROUTE}> Terms and Conditions</a>
              </small>
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default InviteCenterTab
