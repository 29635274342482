import 'cropperjs/dist/cropper.css'
import './ManagePhotosOrVideos.scss'
import AddPhotosOrVideos from '../AddPhotosOrVideos'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import 'react-html5video/dist/styles.css'
import CreatePost from '../CreatePost'
import { IMG_PATH } from '../../../services/const'

import { FILE_MAX, FILE_MAX_MSG } from '../../../services/const'
import ReactTooltip from 'react-tooltip'
import { setOverFlow } from '../../../services/customFunction'
const ManagePhotosOrVideos = ({
  isUpdate = false,
  isRepost = false,
  showCreatePostModalProp = false,
  ...props
}) => {
  const [postAttachmentFormErr, setPostAttachmentFormErr] = useState({
    file: [],
  })
  const [postAttachments, setPostAttachments] = useState([])
  const [isVideo, setIsVideo] = useState(false)
  const [showAddPhoto, setShowAddPhoto] = useState(false)
  const [showAddVideo, setShowAddVideo] = useState(false)
  const [status, setStatus] = useState('idle')
  const [showCreatePostModal, setShowCreatePostModal] = useState(false)

  /*   useEffect(() => {
    setPostAttachments(props?.postData?.attachments)
  }, [props?.postData?.attachments]) */

  const onCloseAddPhotoAndVideos = () => {
    setPostAttachmentFormErr({ file: [] })
    setPostAttachments([])
    setShowAddPhoto(false)
    setShowAddVideo(false)
    if (showCreatePostModalProp) {
      setOverFlow(showCreatePostModal)
    }
  }
  const onBackAddPhotoAndVideos = () => {
    onCloseAddPhotoAndVideos()
    setShowCreatePostModal(false)
    if (showCreatePostModalProp) {
      setOverFlow(false)
    }
  }
  const onAddPhtosVideoClick = (isVideo) => {
    setIsVideo(isVideo)
  }
  const onAddEvenClick = () => {
    //to do coming soon
  }
  const onCloseCreatePostButtonClick = () => {
    setShowCreatePostModal(false)
    setOverFlow(false)
  }
  const validateFile = (file) => {
    let validateObj = {}
    //check Type
    if (
      !isVideo &&
      file &&
      typeof file?.name !== 'undefined' &&
      file?.name?.toLowerCase().match(/(\.heic|\.heif|\.jpg|\.png|\.JPG|\.PNG|\.jpeg|\.JPEG)$/)
    ) {
      validateObj = { errorFile: '', fileType: 'image' }
    } else if (
      isVideo &&
      file &&
      typeof file?.name !== 'undefined' &&
      file?.name
        ?.toLowerCase()
        .match(
          /(\.mp4|\.MP4|\.flv|\.FLV|\.mkv|\.MKV|\.m4v|\.M4V|\.avi|\.AVI|\.3gp|\.3GP|\.mpeg|\.MPEG|\.mov|\.MOV)$/,
        )
    )
      validateObj = { fileType: 'video' }
    else {
      let error = ''
      if (!isVideo) error = 'One of the selected files is an invalid image'
      else error = 'The selected file is an invalid video format'
      setPostAttachmentFormErr({ file: [error] })
      validateObj = { fileType: 'invalid' }
    }
    return validateObj
  }

  const handleGalleryChange = async (event) => {
    setStatus('loading')

    event?.preventDefault()
    setPostAttachmentFormErr({ file: [] })
    let files = event?.target?.files
    let validateObj = {}
    let error = ''
    let validationResult = {}
    if (!files?.length) {
      error = 'File is required'
      validateObj = { file: [error] }
      setPostAttachmentFormErr(validateObj)
    } else if (files?.length > FILE_MAX) {
      error = FILE_MAX_MSG
      validateObj = { file: [error] }
      setPostAttachmentFormErr(validateObj)
    }
    if (error?.length === 0) {
      let finalFiles = await Promise.all(
        [...event?.target?.files].map((file, index) => {
          validationResult = validateFile(file)
          let fileObject = {}
          /* if (file?.type) {*/
          fileObject = {
            file,
            fileType: validationResult?.fileType,
            original: URL.createObjectURL(file),
            thumbnail: URL.createObjectURL(file),
            id: index,
          }
          return fileObject
        }),
      )
      finalFiles = finalFiles?.filter((file) => file?.fileType !== 'invalid')
      if (finalFiles?.length > 0 && error.length === 0) {
        setPostAttachments(finalFiles)
      }
      setStatus('idle')
    }
    isVideo ? setShowAddVideo(true) : setShowAddPhoto(true)
    setShowCreatePostModal(showCreatePostModalProp)
  }
  let disableButtons =
    props?.postData?.attachments?.length > 0 || props?.postData?.original_post_id > 0
  let title = (isUpdate ? 'Modify ' : 'Add ') + (isVideo ? 'video' : 'photos')
  let disableAddEvent = true
  let buttonsClassName = disableButtons
    ? 'input-group-btn manageButtons grayout'
    : 'input-group-btn manageButtons'
  return (
    <>
      <div className={buttonsClassName}>
        <label
          className='choosefile pt-0'
          data-tip={
            isRepost ? 'Inactive' : disableButtons ? 'Delete photos/videos first' : 'Add photo'
          }
          data-for='createButtons'
        >
          <img className='likeIcon' src={IMG_PATH + 'photo.svg'} alt='' />
          <input
            type='file'
            name='postAttachment'
            accept='heic,.heif,.jpg,.png,.jpeg'
            multiple
            style={{ width: 1, height: 1 }}
            onChange={handleGalleryChange}
            onClick={() => onAddPhtosVideoClick(false)}
            disabled={disableButtons}
          />
        </label>
        <label
          className='choosefile pt-0'
          data-tip={
            isRepost ? 'Inactive' : disableButtons ? 'Delete photos/videos first' : 'Add video'
          }
          data-for='createButtons'
          //readonly={disableButtons ? 'readonly' : ''}
        >
          <img src={IMG_PATH + 'video.svg'} className='likeIcon link' alt='' />
          <input
            type='file'
            name='postAttachment'
            accept='.mp4,.flv,.mkv,.m4v,.avi,.3gp,.3g2,.mpeg,.mpg,.mov,mp4, mpeg4'
            style={{ width: 1, height: 1 }}
            onChange={handleGalleryChange}
            disabled={disableButtons}
            onClick={() => onAddPhtosVideoClick(true)}
          />
        </label>
        <label
          data-tip={isRepost ? 'Inactive' : disableAddEvent ? 'Add event-coming soon' : 'Add event'}
          data-for='createButtons'
        >
          <button className='postButtonColumn' onClick={() => onAddEvenClick()}>
            <img src={IMG_PATH + 'event.svg'} className='likeIcon link' alt='' />
          </button>
        </label>
      </div>
      <ReactTooltip
        //data-class='reactionButton'
        id='createButtons'
        type='dark'
        effect='float'
      />
      {showCreatePostModal ? (
        <CreatePost onCloseButtonClick={() => onCloseCreatePostButtonClick()} />
      ) : (
        ''
      )}
      {showAddPhoto && (
        <AddPhotosOrVideos
          title={title}
          onCloseButtonClick={onCloseAddPhotoAndVideos}
          onBackButtonClick={onBackAddPhotoAndVideos}
          postAttachmentFormErrProp={postAttachmentFormErr}
          postAttachmentsProp={postAttachments}
        />
      )}
      {showAddVideo && (
        <AddPhotosOrVideos
          title={title}
          onCloseButtonClick={onCloseAddPhotoAndVideos}
          onBackButtonClick={onBackAddPhotoAndVideos}
          postAttachmentFormErrProp={postAttachmentFormErr}
          postAttachmentsProp={postAttachments}
        />
      )}
    </>
  )
}
function mapStateToProps(state) {
  return {
    postData: state?.SocialMediaReducer?.post,
  }
}
export default connect(mapStateToProps, null)(ManagePhotosOrVideos)
