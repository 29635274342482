import React from 'react'

const ShowPasswordButton = ({ togglePassword, top = 22 }) => {
  return (
    <span
      onClick={togglePassword}
      style={{
        position: 'absolute',
        top: top + '%',
        right: '5%',
        cursor: 'pointer',
        color: '#544cf9',
      }}
    >
      <i className='fa fa-eye'></i>
    </span>
  )
}

export default ShowPasswordButton
