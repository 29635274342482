import AppLayout from '../../../AppLayout'
import LoginModal from '../../../LoginModal/LoginModal'
import * as route from '../../../../services/routeConst'
import '../Reports/Report.scss'
import { Link } from 'react-router-dom'
const ItiText = () => (
  <span className='fw-bold'>
    <span style={{ color: '#14954b' }}>i</span>
    <span style={{ color: '#0877bd' }}>TRUST</span>
    <span style={{ color: '#d85a27' }}>it</span>
  </span>
)
const FindOutMoreHTML = (props) => {
  window.scrollTo(0, 0)

  return (
    <AppLayout routeActive={props.location.pathname}>
      <section className='bg-grey it-privacy-terms-page it-terms-page'>
        <div className='container'>
          <div className='white-pane privacy'>
            <h3>
              Notices regarding content uploaded on <ItiText />{' '}
            </h3>
            <h3>
              Thank you for joining <ItiText />{' '}
            </h3>
            <h3>
              <ItiText /> seeks to be a place where people, businesses, bands, teams, stars,
              charities, clubs, groups, communities, associations and organizations, etc. connect to
              share and promote what they trust and care about with their friends, family, clients,
              members, fans and associates.{' '}
            </h3>
            <p>
              We see iTRUSTit as a place where personal rights are respected and where people are
              kind to one another. Our{' '}
              <Link
                style={{ color: '#6684f2' }}
                to={route.GENERALGUIDELINESHTML_ROUTE}
                target='_blank'
              >
                General Guidelines,
              </Link>{' '}
              <Link style={{ color: '#6684f2' }} to={route.TERMS_ROUTE} target='_blank'>
                Terms of Service and Acceptable Use Policy
              </Link>{' '}
              requires that content on our site is not in violation of another user’s personal
              rights. It also requires that the information shared is legitimate, appropriate, and
              truthful. In order to ensure users are following these policies, iTRUSTit has
              implemented a system for users to flag instances where content may violate these
              policies. We welcome users to use this system to give us notice or address the
              situation with the individual who shared the content for a faster resolution.{' '}
            </p>

            <p>
              iTRUSTit may or may not take action after content is flagged. Regardless of the action
              taken, iTRUSTit may share the information about the notice – such as contact
              information and/or the written complaint – in good faith with the user responsible for
              the reported post. They may also receive a general notice that they were reported as
              allegedly violating our Terms of Service and Acceptable Use policy. As part of these
              policies, it is within iTRUSTit’s rights to take action on a user’s account –
              including but not limited to account suspension or deletion of reported parties or
              groups – especially when there are repeated instances of violations.
            </p>

            <p>
              Please keep in mind that any notices submitted through our system must be truthful, as
              submitting false information may result in personal liability. If you are unsure of
              whether or not you should submit a notice, you may want to consult with your legal
              counsel.
            </p>

            <h3>
              <b>To file a claim about defamation</b>
            </h3>

            <p>
              The meaning of defamation or reputational harm and the way laws address these concerns
              vary across different countries. For this reason, the process will be different for
              each individual case. For example, the United States often requires a court order
              before content can be removed. Because of the time it takes to address each unique
              case given the differing processes, iTRUSTit welcomes users to address the situation
              with the individual who shared the content for a faster resolution. While we may not
              be able to take action on defamation claims without a court order, if you, in good
              faith, believe that content is untruthful in a way that damages your reputation,
              please provide our agent with this information along with the information as outlined
              in our{' '}
              <Link
                style={{ color: '#6684f2' }}
                to={route.GENERALGUIDELINESHTML_ROUTE}
                target='_blank'
              >
                General Guidelines,
              </Link>{' '}
              <Link style={{ color: '#6684f2' }} to={route.TERMS_ROUTE} target='_blank'>
                Terms of Service and Acceptable Use Policy
              </Link>{' '}
              .
            </p>

            <h3>
              <b>To file a claim about copyright infringement</b>
            </h3>

            <p>
              iTRUSTit has set up a way to get written notice of copyright infringement claims. In
              addition, iTRUSTit has established an agent to accept reports of alleged copyright
              infringement. If you, in good faith, have reason to suspect that your copyright has
              been violated, please provide our agent with the information as outlined in our{' '}
              <Link style={{ color: '#6684f2' }} to={route.COPYRIGHTHTML_ROUTE} target='_blank'>
                Copyright Policy.
              </Link>{' '}
            </p>
            <p>
              iTRUSTit has implemented a system for users to flag instances of copyright
              infringements via our designated Agent for Notice of Claims of Copyright or Other
              Intellectual Property Infringement. If you believe in good faith that someone has
              infringed your copyright, please provide our agent with the information as outlined in
              our{' '}
              <Link style={{ color: '#6684f2' }} to={route.COPYRIGHTHTML_ROUTE} target='_blank'>
                Copyright Policy.
              </Link>{' '}
            </p>
            <h3>
              <b>To file a claim about trademark infringement</b>
            </h3>

            <p>
              It's up to you to tell us if content on our site infringes your trademark rights.
              iTRUSTit has set up a way to get written notice of trademark infringement claims. In
              addition, iTRUSTit has established an agent to accept reports of alleged trademark
              infringement or other intellectual property infringement. If you, in good faith,
              believe that content infringes your trademark, please provide our agent with the
              information as outlined in our{' '}
              <Link style={{ color: '#6684f2' }} to={route.TRADEMARKHTML_ROUTE} target='_blank'>
                Trademark Policy.
              </Link>{' '}
            </p>

            <p>
              For other claims, please see the information outlined in the Copyright or Intellectual
              Property Complaints section of our{' '}
              <Link style={{ color: '#6684f2' }} to={route.TERMS_ROUTE} target='_blank'>
                Terms of Service and Acceptable Use Policy.
              </Link>{' '}
            </p>
          </div>
        </div>
      </section>
      <LoginModal
        isLogin={(e) =>
          function (data) {
            props.isLogin(data)
          }
        }
        {...props}
      />
    </AppLayout>
  )
}

export default FindOutMoreHTML
