import React from 'react'
import { DEFAULT_PROFILE_PICTURE } from '../../../../services/const'

const MutualOrgs = ({ user, login_user_id }) => {
  const loginModalProps = !login_user_id
    ? {
        'data-bs-toggle': 'modal',
        'data-bs-target': '#login_modal',
      }
    : {}
  return (
    <div className='d-flex justify-content-center'>
      <div className='it-person-list-new it-person-list-main'>
        <p
          className={`mutual-orgs-detail ${!login_user_id ? 'mb-0' : 'mb-1'}`}
          style={{
            color: 'white',
            fontSize: 12,
            fontFamily: 'Poppins',
            fontWeight: 500,
          }}
        >
          Mutual Organizations
        </p>
        <div className='it-person'>
          {login_user_id ? (
            user?.total_common_members_list?.map(
              (obj, index) =>
                index < 3 && (
                  <a
                    key={obj.id}
                    className='it-person-img'
                    style={{ width: 24, height: 24 }}
                    href={`/${obj.slug}`}
                    target='_blank'
                    rel='noreferrer'
                  >
                    <img
                      className='it-person-img-avatar'
                      src={
                        obj.profile_picture_medium !== null
                          ? obj.profile_picture_medium
                          : DEFAULT_PROFILE_PICTURE
                      }
                      width='24'
                      alt=''
                    />
                  </a>
                ),
            )
          ) : (
            <div className='digit'>
              <a
                {...loginModalProps}
                href={null}
                className='digit d-none d-sm-block'
                style={{
                  color: '#fbb56c',
                }}
              >
                Login or Sign up to see
              </a>
              <a
                {...loginModalProps}
                href={null}
                className='digit d-block d-sm-none'
                style={{
                  color: '#fbb56c',
                }}
              >
                Login or Sign Up to see
              </a>
            </div>
          )}
          {user?.total_common_members_list?.length > 3 ? (
            <div className='it-person-number' style={{ width: 24, height: 24 }}>
              <span style={{ fontSize: 12, padding: 2 }}>
                +{user.total_common_members_list.length - 3}
              </span>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  )
}

export default MutualOrgs
