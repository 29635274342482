import React, { Component } from 'react'
import AppLayout from '../AppLayout'

class CharterMember extends Component {
  render() {
    return (
      <AppLayout>
        <section className='bg-grey'>
          <div className='container'>
            <h3 className='varela mb-3'>Charter Member</h3>
            <div className='white-pane green_line'>
              <div className='white_pane_content'>
                <p className='mb-2 orange'>Congratulations!</p>
                <p>
                  iTRUSTit will soon be going "live and worldwide" but in the meantime we need your
                  help! Become a iTRUSTit "Charter Member" and Help Us work out the kinks as you
                  start using the system.
                </p>

                <p>
                  Simply sign up NOW during our Beta Phase, invite a few friends (they can be
                  People, Businesses or Organizations), start searching for and rating
                  businesses...and let us know if you run into any glitches. You'll get a head start
                  building your "iTeam" which means a head start earning through our iPartner
                  program.
                </p>

                <p>
                  Plus, as our way of saying thanks for being one of our cool new Beta testers,
                  you'll be enrolled in our iPartner program as a permanent! iPartner Pro... meaning
                  you'll be sharing in iTRUSTit revenue at a higher rate.
                </p>

                <p>How's that for gratitude?</p>
                <div className='text-center'>
                  <a href={null} className='mt-4 mb-4 blue_btn_join'>
                    {' '}
                    Join iTRUSTit
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </AppLayout>
    )
  }
}

export default CharterMember
