import React from 'react'

const ViewTypeTabs = ({ viewType, handleChange }) => {
  return (
    <div className='menu-type-tabs'>
      <div>View:</div>
      <div className='views'>
        <div onClick={() => handleChange('list_view')}>
          <button className={`btn menu-type-tab ${viewType === 'list_view' ? 'active' : ''}`}>
            <i className='list-view' />
            List
          </button>
        </div>
        <div onClick={() => handleChange('full_view')}>
          <button className={`btn menu-type-tab ${viewType === 'full_view' ? 'active' : ''}`}>
            <i className='full-view' />
            Full
          </button>
        </div>
      </div>
    </div>
  )
}

export default ViewTypeTabs
