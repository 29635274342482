import React from 'react'
import $ from 'jquery'
import { toaster } from '../../../services/customFunction'

export const showWhatsAppNumberModal = (type) => {
  $('#whatsapp_number_modal').modal('show')
}

export const hideWhatsAppNumberModal = (type) => {
  $('#whatsapp_number_modal').modal('hide')
}

const WhatsAppNumberModal = (props) => {
  const copyToClipboard = async () => {
    if ('clipboard' in navigator) {
      await navigator.clipboard.writeText(props?.whatsapp)
      toaster('success', 'Number copied successfully')
    } else {
      return document.execCommand('copy', true, props?.whatsapp)
    }
  }

  return (
    <div className='modal fade whatsapp_number_modal' id='whatsapp_number_modal' role='dialog'>
      <div className='modal-dialog'>
        <div className='modal-content'>
          <div className='modal-header align-items-start pb-0'>
            <button type='button' className='btn-close' data-bs-dismiss='modal'></button>
          </div>
          <div className='modal-body d-flex flex-column align-items-center'>
            <h3 className='text-center varela fw-bold mb-4' style={{ color: '#544cf9' }}>
              WhatsApp Number
            </h3>
            <h3 className='text-center varela fw-bold' style={{ color: '#544cf9' }}>
              {props?.whatsapp}
            </h3>
            <button className='blue_btn copy-btn mt-3' onClick={copyToClipboard}>
              Click to copy
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default WhatsAppNumberModal
