import React from 'react'

export default function SearchPageLoadingIcon({ label, className = '', styles, iconStyles }) {
  return (
    <div className={'search-page-loading-icon ' + className} style={styles}>
      {label}
      <i style={iconStyles} />
    </div>
  )
}
