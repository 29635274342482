import React from 'react'

export const CtaGroup = ({ children }) => (
  <div className='d-flex justify-content-around'>{children}</div>
)

export const ItiBug = React.memo(({ width = 36 }) => (
  <img width={width} src={process.env.PUBLIC_URL + '/img/iti_bug.png'} alt='' />
))

export const CtaWithLogo = React.memo(
  ({ className = '', text, reverse, path, onClick, logoWidth = 36, textStyles, Logo = ItiBug }) => {
    return (
      <Cta
        className={`${className} flex-center-center ${!!reverse ? 'flex-row-reverse' : ''}`}
        text={text}
        path={path}
        onClickCallback={onClick}
        textStyles={textStyles}
      >
        <Logo width={logoWidth} />
      </Cta>
    )
  },
)

export const Cta = React.memo(
  ({ basic, className = '', text, path, onClickCallback, textStyles, children }) => {
    const onClick = (e) => {
      !!path && window.open(path, '_blank')
      onClickCallback?.(e)
    }
    return (
      <div
        className={basic ? `vcard-cta-basic ${className}` : `vcard-cta my-2 ${className}`}
        onClick={onClick}
        style={{ backgroundColor: 'white' }}
      >
        {children}
        <span className='vcard-cta-text' style={textStyles}>
          {text}
        </span>
      </div>
    )
  },
)
