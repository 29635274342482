import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

// import SignupIndividual from '../../components/SignupIndividual'
import {
  signupAction,
  socialLoginAction,
  linkedInLoginAction,
  signupStore,
  socialLoginFillForm,
  errorMsgAction,
  authResponse,
  authResponseAction,
  resetAuthDataAction,
  userExistsPromptAction,
} from '../../actions/auth'
import Signup from '../../components/SignupNew/Signup'

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      signupAction,
      socialLoginAction,
      linkedInLoginAction,
      signupStore,
      socialLoginFillForm,
      errorMsgAction,
      authResponse,
      authResponseAction,
      resetAuthDataAction,
      userExistsPromptAction,
    },
    dispatch,
  )
}

function mapStateToProps(state) {
  let social_login = state.UserReducer.social_login_form

  return {
    user: state.UserReducer,
    networkerror: state.UserReducer.networkerror,
    errormsg: state.UserReducer.errormsg,
    signupmsg: state.UserReducer.signupmsg,
    slug: state.UserReducer.slug,
    isLoading: state.UserReducer.isLoading,
    social_login_form: social_login,
    initialValues: {
      email: Object.keys(social_login).length > 0 ? social_login.email : '',
      firstname: Object.keys(social_login).length > 0 ? social_login.firstname : '',
      lastname: Object.keys(social_login).length > 0 ? social_login.lastname : '',
      sponsors_iteam_id: localStorage.getItem('invite_profile_imatrix_id'),
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Signup)
