import React, { useEffect, useState } from 'react'
import { Field, formValueSelector, reduxForm } from 'redux-form'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import * as validation from '../../services/formValidation'
import renderInputField from '../Form/Input'

import * as route from '../../services/routeConst'
import { checkEmail } from '../../services/userApiService'
import { DUPLICATE_EMAIL } from '../../services/const'
import { connect } from 'react-redux'
import { AnimatePresence, motion } from 'framer-motion'
import DuplicateClaimRequestEmailError from '../CommonComponents/DuplicateClaimRequestEmailError/DuplicateClaimRequestEmailError'
import { toaster } from '../../services/customFunction'

let SignupWithEmail = (props) => {
  const { isClaim, busOrgName, isAdd, loginUserData, submitFailed, invalid, formValues } = props
  const [stateData, setStateData] = useState({
    is_email_exists: 0,
    is_claim_request_email: false,
  })
  const [showPasswordPopup, setShowPasswordPopup] = useState(false)
  const [touchedEmail, setTouchedEmail] = useState(false)
  const [validationState, setValidationState] = useState({
    isSpaceValidation: false,
    isMaxLength: false,
    isUppercase: false,
    isLowercase: false,
    isNumber: false,
  })

  useEffect(() => {
    if (loginUserData?.id) {
      props.change('firstname', loginUserData?.firstname)
      props.change('lastname', loginUserData?.lastname)
    }
    if (busOrgName) {
      props.change('business_organization_name', busOrgName)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    !!formValues?.password &&
      setValidationState((st) => validation.passwordValidation(formValues?.password, st))
  }, [formValues?.password])

  let validationPercentage = validation.percentageOfValidation(validationState)
  const passwordPopup = () => {
    setTimeout(() => {
      setShowPasswordPopup((st) => !st)
    }, 100)
  }

  const checkEmailExists = async () => {
    if (formValues?.email?.length > 0) {
      setTouchedEmail(true)
      if (formValues?.email?.includes('@')) {
        try {
          const { data } = await checkEmail({ email: formValues.email })
          setStateData((st) => ({
            ...st,
            is_email_exists: parseInt(data.is_email_exists),
            is_claim_request_email: data.is_claim_request_email,
          }))
          return parseInt(data?.is_email_exists)
        } catch (error) {
          toaster('error', 'An unexpected error occurred.')
          console.log({ error })
        }
      }
    }
  }

  const handleSubmit = async () => {
    const exists = await checkEmailExists()
    if (!exists && canSubmit) {
      props.handleSubmit(formValues)
    }
  }

  const canSubmit =
    props?.status !== 'submitting' &&
    props.anyTouched &&
    !invalid &&
    !(submitFailed && invalid) &&
    !stateData.is_email_exists
  return (
    <>
      {/* <p className='socialtitle'>With Your Email Address</p> */}
      {!isAdd && !loginUserData?.id ? (
        <>
          <div className='row fieldflex form-group'>
            <div className={`col-md-${props?.onPromotionScreen ? '12' : '8'}`}>
              <Field
                name='firstname'
                type='text'
                className='form-control'
                component={renderInputField}
                validate={[validation.required]}
                label='Firstname'
                placeholder='First Name*'
                autoComplete='off'
              />
            </div>
          </div>
          <div className='row fieldflex form-group'>
            <div className={`col-md-${props?.onPromotionScreen ? '12' : '8'}`}>
              <Field
                name='lastname'
                type='text'
                className='form-control'
                component={renderInputField}
                validate={[validation.required]}
                label='Lastname'
                placeholder='Last Name*'
                autoComplete='off'
              />
            </div>
          </div>
        </>
      ) : null}
      {!isClaim && ['business', 'organization'].includes(props?.signupType) ? (
        <div className='row fieldflex form-group'>
          <div className='col-md-12'>
            <Field
              name='business_organization_name'
              type='text'
              className='form-control'
              component={renderInputField}
              validate={[validation.required]}
              placeholder={
                (props.signupType === 'organization' ? 'Organization' : 'Business') + ' Name*'
              }
              autoComplete='off'
            />
          </div>
        </div>
      ) : null}
      <div className='row fieldflex form-group'>
        <div className='col-md-12'>
          <Field
            name='email'
            type='text'
            className='form-control'
            component={renderInputField}
            validate={[validation.required, validation.email]}
            label='Email'
            placeholder='Email Address*'
            autoComplete='off'
            onBlur={checkEmailExists}
          />
          {!!stateData.is_email_exists ? (
            <span className='input_error text-danger'>
              {stateData?.is_claim_request_email ? (
                <DuplicateClaimRequestEmailError />
              ) : (
                DUPLICATE_EMAIL
              )}
            </span>
          ) : null}
        </div>
      </div>
      {/* <AnimatePresence> */}
      <div
        // initial={{ opacity: 0 }}
        // animate={{ opacity: 1 }}
        // exit={{ opacity: 0 }}
        className='row fieldflex form-group'
      >
        <div className='col-md-12'>
          <Field
            name='confirmemail'
            type='text'
            className='form-control'
            component={renderInputField}
            validate={[validation.required, validation.confirmEmail]}
            label='Email'
            placeholder='Confirm Email Address*'
            autoComplete='off'
          />
        </div>
      </div>
      {/* </AnimatePresence> */}
      <div className='row fieldflex form-group'>
        <div className='col-md-12 password_criteria'>
          <Field
            name='password'
            type='password'
            className='form-control password1'
            component={renderInputField}
            onFocus={passwordPopup}
            onBlur={passwordPopup}
            validate={[
              validation.required,
              validation.minValue8,
              validation.alphaNumeric,
              validation.maxLength20,
              validation.noSpace,
            ]}
            label='Password'
            placeholder='Password*'
            autoComplete='new-password'
          />
          {!props?.onPromotionScreen && showPasswordPopup ? (
            <div className='criteria' style={{ zIndex: 2000 }}>
              <div className='progress'>
                <div
                  className='progress-bar'
                  role='progressbar'
                  aria-valuenow='0'
                  aria-valuemin='0'
                  aria-valuemax='100'
                  style={{ width: validationPercentage + '%' }}
                >
                  <span className='sr-only'>70% Complete</span>
                </div>
              </div>
              <h3> Criteria: </h3>
              <ul>
                <li className={validationState?.isSpaceValidation ? 'success' : ''}>
                  No Spaces, cannot start or end with a space
                </li>
                <li className={validationState?.isLowercase ? 'success' : ''}>
                  Include a lower case letter
                </li>
                <li className={validationState?.isUppercase ? 'success' : ''}>
                  Include a upper case letter
                </li>
                <li className={validationState?.isNumber ? 'success' : ''}>Include number</li>
                <li className={validationState?.isMaxLength ? 'success' : ''}>
                  Be 8-20 characters long
                </li>
              </ul>
            </div>
          ) : null}
        </div>
      </div>
      <div className='text-start signupbottom ms-0 mt-2'>
        <div className='d-flex remember m-0 justify-content-center'>
          <div className='radio1'>
            <label className='radioblk text-center p-0' style={{ maxWidth: 420 }}>
              By clicking Agree
              {isClaim ? ', Sign Up & Claim' : `${isAdd ? ', Sign Up & Add' : ' & Sign Up'}`}, I
              acknowledge that I{' '}
              {['business', 'organization']?.includes(props?.signupType)
                ? `am the Authorized Representative of this ${
                    props.signupType === 'organization' ? 'Organization' : 'Business'
                  } and I`
                : ''}{' '}
              have read and agree to the
              <Link className='purple' target='_blank' to={route.TERMS_ROUTE}>
                Terms of Service
              </Link>
              &nbsp;and
              <Link className='purple' to={route.PRIVACY_ROUTE} target='_blank'>
                Privacy Policy
              </Link>
              .
              {/* <input
                type='radio'
                name='is_receive_newsletters'
                value={true}
                checked={data.newsletters_type}
                onClick={handleNewletterChange}
              />
              <span className='checkmark' /> */}
            </label>
          </div>
        </div>
      </div>
      {(submitFailed && invalid) || stateData.is_email_exists ? (
        <small className='text-danger text-center mb-0'>Please correct the errors above.</small>
      ) : props.anyTouched && invalid ? (
        <small className='text-danger text-center mb-0'>
          Please fill in required fields above.
        </small>
      ) : null}
      <button
        disabled={!canSubmit}
        onClick={canSubmit ? () => handleSubmit() : () => {}}
        style={{ fontSize: 18 }}
        className={classNames(
          'btn',
          'btn-lg',
          'btn-success',
          'btn-block',
          'loginbtn',
          'signup-submit',
          'mt-1',
          !canSubmit ? 'disabled' : '',
        )}
      >
        Agree{isClaim ? ', Sign Up & Claim' : `${isAdd ? ', Sign Up & Add' : ' & Sign Up'}`}
      </button>
    </>
  )
}

// Decorate with redux-form
SignupWithEmail = reduxForm({
  form: 'signupForm',
  enableReinitialize: true,
})(SignupWithEmail)

// Decorate with connect to read form values
const selector = formValueSelector('signupForm') // <-- same as form name
SignupWithEmail = connect((state) => {
  // can select values individually
  const sponsors_iteam_id = selector(state, 'sponsors_iteam_id')
  const firstname = selector(state, 'firstname')
  const lastname = selector(state, 'lastname')
  const email = selector(state, 'email')
  const business_organization_name = selector(state, 'business_organization_name')
  const password = selector(state, 'password')

  return {
    formValues: {
      sponsors_iteam_id,
      firstname,
      lastname,
      email,
      business_organization_name,
      password,
    },
    initialValues: {
      business_organization_name: localStorage.getItem('bus_org_name_from_presearch') ?? '',
      // sponsors_iteam_id: '',
      // firstname: '',
      // lastname: '',
      // email: '',
      // password: '',
    },
  }
})(SignupWithEmail)

export default SignupWithEmail
