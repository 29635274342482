import React, { useRef, useState } from 'react'
import { formValueSelector, reduxForm } from 'redux-form'
import AppLayout from '../AppLayout'
import {
  SIGNUP_BUSINESS_TITLE,
  SIGNUP_ORGANIZATION_TITLE,
  SIGNUP_TITLE,
} from '../../services/titleConst'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import classNames from 'classnames'
import { getUser, isUserLogin, setUser, userToken } from '../../services/customFunction'
import { Redirect, useHistory } from 'react-router-dom'
import { HOME_ROUTE, LOGIN_ROUTE, PROFILE_ROUTE } from '../../services/routeConst'
import { updateAddress, userProfile } from '../../services/authService'
import LoadingOverlay from '../CommonComponents/LoadingOverlay'
import AddBusOrgAddressSection from './AddBusOrgAddressSection'

let AddAddress = (props) => {
  const { invalid, anyTouched, submitFailed, formValues } = props
  const [status, setStatus] = useState('idle')
  const history = useHistory()
  const userDataRef = useRef(getUser())

  const fetchUserData = async () => {
    let token = userToken()
    if (isUserLogin() && !!token) {
      const response = await userProfile(token)
      if (response.status === true) {
        const { profileInfo } = response
        setUser(profileInfo)
        localStorage.setItem('show_signup_disclaimer', !!profileInfo?.social_id)
        history.push({ pathname: PROFILE_ROUTE })
      } else {
        history.push({ pathname: LOGIN_ROUTE })
      }
      setStatus('idle')
    } else {
      setStatus('idle')
    }
  }

  const handleSubmit = async () => {
    const request = {
      token: userToken(),
      country_id: formValues?.country_id?.value,
      state_id: formValues?.state_id?.value,
      city_id: formValues?.city_id?.value,
      address_line_1: formValues?.address_line_1,
      address_line_2: formValues?.address_line_2,
      zipcode: formValues?.zipcode,
      area: formValues?.area,
    }
    // console.log({ request })
    setStatus('submitting')
    const response = await updateAddress(request)
    if (response?.status === 200) {
      fetchUserData()
    } else {
      setStatus('idle')
    }
  }

  const canSubmit = status !== 'submitting' && anyTouched && !invalid && !(submitFailed && invalid)

  return userDataRef?.current?.address ||
    !['business', 'organization'].includes(userDataRef?.current?.user_type) ? (
    <Redirect to={{ pathname: HOME_ROUTE }} />
  ) : (
    <AppLayout isDisplayFooter={false}>
      <Helmet>
        <title>
          {userDataRef?.current?.user_type === 'business'
            ? SIGNUP_BUSINESS_TITLE
            : userDataRef?.current?.user_type === 'organization'
            ? SIGNUP_ORGANIZATION_TITLE
            : SIGNUP_TITLE}
        </title>
      </Helmet>
      <LoadingOverlay show={status === 'submitting'} />
      <div className='container' style={{ minHeight: 600 }}>
        <div className='row'>
          <div className='loginblk signupblk'>
            <div className='panel panel-default'>
              <div className='panel-heading'>
                <h3 className='panel-title mb-0'>
                  {userDataRef?.current?.user_type === 'organization'
                    ? 'Organization'
                    : userDataRef?.current?.user_type === 'business'
                    ? 'Business'
                    : 'Personal'}{' '}
                  Address
                </h3>
                <h5 className='panel-title mb-4 varela d-block' style={{ color: '#959595' }}>
                  Help people find you easier
                </h5>
              </div>
              <div className='panel-body'>
                <AddBusOrgAddressSection {...props} />
                {submitFailed && invalid ? (
                  <small className='text-danger text-center mb-0'>
                    Please correct the errors above.
                  </small>
                ) : props.anyTouched && invalid ? (
                  <small className='text-danger text-center mb-0'>
                    Please fill in required fields above.
                  </small>
                ) : null}
                <button
                  disabled={!canSubmit}
                  onClick={canSubmit ? handleSubmit : () => {}}
                  style={{ fontSize: 18 }}
                  className={classNames(
                    'btn',
                    'btn-lg',
                    'btn-success',
                    'btn-block',
                    'loginbtn',
                    'signup-submit',
                    'mt-1',
                    !canSubmit ? 'disabled' : '',
                  )}
                >
                  Continue
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AppLayout>
  )
}

AddAddress = reduxForm({
  form: 'signupFormAddress',
  enableReinitialize: true,
})(AddAddress)

const selector = formValueSelector('signupFormAddress')
AddAddress = connect((state) => {
  const country_id = selector(state, 'country_id')
  const zipcode = selector(state, 'zipcode')
  const state_id = selector(state, 'state_id')
  const city_id = selector(state, 'city_id')
  const area = selector(state, 'area')
  const address_line_1 = selector(state, 'address_line_1')
  const address_line_2 = selector(state, 'address_line_2')
  return {
    formValues: { country_id, zipcode, state_id, city_id, area, address_line_1, address_line_2 },
  }
})(AddAddress)

export default AddAddress
