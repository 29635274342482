import React, { Fragment } from 'react'
import { GoogleLogin } from 'react-google-login'
import FacebookLogin from 'react-facebook-login'
import AppleSignin from 'react-apple-signin-auth'
import * as environment from '../../services/environment'
import LinkedInLogin from './LinkedInLogin'

const SocialLogin = ({
  socialLogin,
  linkedInLogin,
  excludeApple,
  showSeparator = true,
  hideAppLayout = false,
  btnPreText,
}) => {
  const googleLoginResponse = (response) => {
    if (!response) return

    let userGoogle = response.profileObj

    let registerData = {
      firstname: userGoogle.givenName,
      lastname: userGoogle.familyName,
      email: userGoogle.email,
      login_type: 'google',
      social_login_type: 'google',
      social_id: userGoogle.googleId,
      avatar: userGoogle?.imageUrl,
    }

    socialLogin(registerData)
  }

  const facebookLoginResponse = (response) => {
    // console.log("facebookLoginResponse", response);
    //if (!response.status) {

    if (response && response.id) {
      let registerData = {}

      let fbName = response.name.split(' ')

      registerData.firstname = fbName[0]

      if (fbName.length > 1) registerData.lastname = fbName[1]

      registerData.email = response.email
      registerData.login_type = 'facebook'
      registerData.social_login_type = 'facebook'
      registerData.social_id = response.userID
      registerData.avatar = response.picture?.data?.url

      socialLogin(registerData)
    }
  }

  const appleLoginResponse = (response) => {
    if (!response) return
    // console.log({ response })

    let userApple = response?.user ?? {}

    let registerData = {
      firstname: userApple?.name?.firstName,
      lastname: userApple?.name?.lastName,
      email: userApple?.email,
      login_type: 'apple',
      social_login_type: 'apple',
      social_id: 'placeholder',
      apple_jwt: response?.authorization?.id_token,
    }
    socialLogin(registerData)
  }

  const authResponseError = (error) => {
    console.log({ error })
  }

  const getAppleRedirectUri = () =>
    window.location.host.includes('itrustit.com') ? window.location.host : 'itrustit.com'
  const preText = btnPreText ?? (hideAppLayout ? 'Login' : 'Continue')
  return (
    <Fragment>
      <div className='col-12'>
        <div className='btnss'>
          <FacebookLogin
            appId={environment.FACEBOOK_KEY}
            fields='name,email,picture'
            callback={facebookLoginResponse}
            cssClass='facebook apple-auth-btn'
            className='facebook apple-auth-btn'
            textButton={`${preText} with Facebook`}
            containerStyle={{ opacity: 1 }}
            disableMobileRedirect={true}
            buttonStyle={{
              backgroundColor: 'rgb(255, 255, 255)',
              display: 'inline-flex',
              alignItems: 'center',
              color: 'rgba(0, 0, 0, 0.54)',
              boxShadow: 'rgb(0 0 0 / 24%) 0px 2px 2px 0px, rgb(0 0 0 / 24%) 0px 0px 1px 0px',
              padding: 0,
              border: '1px solid #cbd4db',
              fontSize: 14,
              fontWeight: 500,
              fontFamily: "'Roboto', 'sans-serif'",
              width: '100%',
              margin: '0 0 12px',
              borderRadius: 30,
              minHeight: 43,
              paddingLeft: 49,
            }}
          />
        </div>
      </div>
      <div className='col-12'>
        <div className='btnss'>
          <GoogleLogin
            clientId={environment.GOOGLE_KEY}
            onSuccess={googleLoginResponse}
            onFailure={authResponseError}
            disabledStyle
            className='google'
          >
            {preText} with Google+
          </GoogleLogin>
        </div>
      </div>
      <div className='col-12'>
        <div className='btnss'>
          <LinkedInLogin linkedInLoginAction={linkedInLogin} preText={preText} />
        </div>
      </div>
      {!excludeApple ? (
        <div className='col-12'>
          <div className='btnss'>
            <AppleSignin
              authOptions={{
                clientId: 'com.itrustitsocial.signin',
                scope: 'email name',
                redirectURI: `https://${getAppleRedirectUri()}/auth/login`,
                state: 'state',
                nonce: 'nonce',
                usePopup: true,
              }}
              uiType='light'
              className='apple-auth-btn'
              buttonExtraChildren={`${preText} with Apple`}
              onSuccess={appleLoginResponse}
              onError={authResponseError}
              style={{
                backgroundColor: 'rgb(255, 255, 255)',
                display: 'inline-flex',
                alignItems: 'center',
                color: 'rgba(0, 0, 0, 0.54)',
                boxShadow:
                  'rgba(0, 0, 0, 0.24) 0px 2px 2px 0px, rgba(0, 0, 0, 0.24) 0px 0px 1px 0px',
                border: ' 1px solid rgb(203, 212, 219)',
                fontSize: '14px',
                fontWeight: 500,
                fontFamily: `Roboto, "sans-serif"`,
                width: '100%',
                margin: '0px 0px 12px',
                borderRadius: '30px',
              }}
            />
          </div>
        </div>
      ) : null}
      {showSeparator ? (
        <fieldset className='hr-line'>
          <legend align='center'>OR</legend>
        </fieldset>
      ) : null}
    </Fragment>
  )
}

export default SocialLogin

// {
//     "authorization": {
//         "code": "c9aef3d41f8d34ba9820d459105758d55.0.rrvrz.f88VevpKzB4A81ARbVYu5Q",
//         "id_token": "eyJraWQiOiJZdXlYb1kiLCJhbGciOiJSUzI1NiJ9.eyJpc3MiOiJodHRwczovL2FwcGxlaWQuYXBwbGUuY29tIiwiYXVkIjoiY29tLml0cnVzdGl0c29jaWFsLnNpZ25pbiIsImV4cCI6MTYzNTExMzQ3MSwiaWF0IjoxNjM1MDI3MDcxLCJzdWIiOiIwMDE1MTkuZTJmMmRlMjMzODcyNDUwZDkxOTc2MWJlNTc2MjIzMmEuMjE0NiIsIm5vbmNlIjoibm9uY2UiLCJjX2hhc2giOiJ2WkR1V3JFWXZLUTJoSDFrT3hjZ2hBIiwiZW1haWwiOiJBdXN0aW5AaVRSVVNUaXQuY29tIiwiZW1haWxfdmVyaWZpZWQiOiJ0cnVlIiwiYXV0aF90aW1lIjoxNjM1MDI3MDcxLCJub25jZV9zdXBwb3J0ZWQiOnRydWV9.PHeyNRWs-Xfisi8EbQQOX5slxjePNbZ7L4qvonS3hIZsMna_Y5GzavRJhWCcltOtoD3cWhLzpl1DpRC23TFLl-4K-8FRZArgoJNtgK2s31uAFoJKv-R5ChQCbD9JXVOqgm9RyYT86zae9HVBVCuD-vhT8KSSnHkAjvThaW9W5E5ip-az5XeAPR7u4_LReZCyDeeFWpVN80n1Um3pUVUd_etdR0KyFhB6_3IWyQ0pAmlE02BBu9KaWkLF1W5nzdCubK-GsLJ-xJx6i2fi1T5aZmUaW0hcK-rFv7TOWVxg2SRoWNA3dKkTbtV04vQXwTCN8d5j28Bwtqs9wdQuv0rllw",
//         "state": "state"
//     },
//     "user": {
//         "name": {
//             "firstName": "Austin",
//             "lastName": "Adam"
//         },
//         "email": "Austin@iTRUSTit.com"
//     }
// }
