import React from 'react'
import TotalGivenReviews from './TotalGivenReviews'

const IndividualBadge = ({ userDetail }) => {
  return (
    <div className='badge_blk personal_profile'>
      <div className='badge_content'>
        <div className='badge'>
          <div className='b-triangle' />
          <div className='b-inner'>
            <h4>iTRUST</h4>
            <div className='d-flex d1'>
              <div className='col-6 digits_cat'>
                <div className='digit'>{userDetail?.total_trusted_business}</div>
                <div className='digit_name'>business</div>
              </div>
              <div className='col-6 digits_cat'>
                <div className='digit'>{userDetail?.total_trusted_organizations}</div>
                <div className='digit_name'>organization</div>
              </div>
            </div>
          </div>
        </div>
        <TotalGivenReviews userDetail={userDetail} />
      </div>
    </div>
  )
}

export default IndividualBadge
