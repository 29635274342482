import React, { Component, Fragment } from 'react'
// import { PROFILE_FRIEND_TITLE } from "../../../services/titleConst";
import * as friendApiService from '../../../services/friendApiService'
import * as profileSearchService from '../../../services/profileSearchService'
import { LIST_VIEW_ITEMS_PER_PAGE, LIST_VIEW_PAGE_RANGE, SEARCH_AMT } from '../../../services/const'
import { getUser } from '../../../services/customFunction'

import UserListDetail from '../../CommonComponents/UserListDetail/UserListDetail'
import $ from 'jquery'

const parseUrlSearchParams = (search) => {
  return new URLSearchParams(search)
}
class FriendsTab extends Component {
  constructor(props) {
    super(props)
    //document.title = PROFILE_FRIEND_TITLE;

    const urlSearchParams = parseUrlSearchParams(window.location.search)
    this.state = {
      login_user_id: getUser().id,

      user_id: this.props.user_id,
      viewType: 'list_view',
      userType: urlSearchParams.get('userType') ?? '', //individual, organization, business
      activePage: 1,
      totalPages: 0,
      totalRecords: 0,

      friendList: [],
      searchedIDs: [],
      searchString: '',
      is_mutual: 0,

      overall: {
        total_friends: 0,
        total_business: 0,
        total_individual: 0,
        total_organization: 0,
      },
      total_with_search: {
        total_friends: 0, //contains the total of the filter Ids - string search or/and usertype
        total_business: 0,
        total_individual: 0,
        total_organization: 0,
      },
      mutual_friends: {
        individual: 0,
        business: 0,
        organization: 0,
      },

      totalFriendIDs: [], //all the IDS queried
      totalFilterIDs: [],

      isSettingToShowFriendTab: false,
      isSearching: true,
      endOfPage: SEARCH_AMT,
      hasMoreData: false,
      loadingMoreData: false,
      noSearchResults: false,
      elasticSearchTotal: 0,
    }

    this.handlePageChange = this.handlePageChange.bind(this)
    this.itrustitHandle = this.itrustitHandle.bind(this)
    this.friendAddRemoveHandle = this.friendAddRemoveHandle.bind(this)
  }

  componentDidMount() {
    //console.log('component did mount friends other')
    this.friendListRender(true)
  }

  componentWillReceiveProps(nextProps) {
    //console.log('componentWillReceiveProps called:', nextProps)
    let user_id_new = nextProps.user_id
    let user_id_old = this.state.user_id

    if (user_id_new !== user_id_old) {
      this.setState({ user_id: user_id_new }, () => {
        //console.log('here 2')
        this.friendListRender()
      })
    }
    // condition for show hide friend tab depends on user setting
    //console.log('user_settings.friends_view_by', nextProps.user_settings.friends_view_by)
    if (
      nextProps.user_settings.friends_view_by === 'Everybody including non-logged in users' ||
      (nextProps.user_settings.friends_view_by === 'All logged in users' &&
        this.state.login_user_id) ||
      (nextProps.user_settings.friends_view_by === 'Friends and friends of friends' &&
        (nextProps.user_settings.is_friend_user ||
          nextProps.user_settings.is_friend_of_friends_user)) ||
      (nextProps.user_settings.friends_view_by === 'Friends' &&
        nextProps.user_settings.is_friend_user) ||
      (nextProps.user_settings.friends_view_by === 'Members only' &&
        nextProps.user_settings.is_member_user)
    ) {
      if (!this.state.isSettingToShowFriendTab) {
        this.setState(
          {
            isSettingToShowFriendTab: true,
          },
          () => {
            //console.log('here 3')
            this.friendListRender()
          },
        )
      }
    } else {
      if (this.state.isSettingToShowFriendTab) {
        this.setState({
          isSettingToShowFriendTab: false,
        })
      }
    }
  }

  componentWillUnmount() {
    //console.log('other friends unmount')
  }
  async friendListRender() {
    let searchString = this.state.searchString
    //console.log('Loading friends list...', this.state.isSettingToShowFriendTab)
    //don't include the type and string search on the DB rquest
    let friendListReqData = {
      other_user_id: this.state.user_id,
      page: 1,
      firstCall: true,
      endOfPage: this.state.endOfPage || SEARCH_AMT,
      //please don't include the user_type o the request( info is filter later using elastic search)
      user_type: '', //this.state.userType,
      searchString: '', //this.state.searchString,
    }
    //console.log(',this.state.userType:', this.state.userType)
    const self = this
    if (this.state.isSettingToShowFriendTab) {
      //console.log('calling api 3')
      const response = await friendApiService.friendListCallNew(friendListReqData)
      //console.log(response.data)
      //get all IDs, no filter, no search
      let totalFriendAllIDs = response.data.ids || []
      let overall = response.data.overall ? response.data.overall : self.state.overall
      let totalMutualFriendIDs = response.data.total_with_search.mutual_friends_list || []
      //choose which set of data to filter
      let totalFriendIDs = self.state.is_mutual ? totalMutualFriendIDs : totalFriendAllIDs

      //filter based on type
      let totalFilterIDs = totalFriendIDs
      if (self.state.userType !== '')
        totalFilterIDs = totalFriendIDs.filter((user) => user.user_type === self.state.userType)
      //console.log('totalFilterIDs:', totalFilterIDs, searchString)
      let elasticSearchTotal = totalFilterIDs.length
      if (searchString !== '') {
        const idsToSearch = totalFilterIDs.map((user) => user.friend_user_id)
        const res = await profileSearchService.elasticSearch({
          ids: idsToSearch,
          searchStr: searchString,
        })

        if (res.length) {
          totalFilterIDs = res.map((searchedUser) => ({
            friend_user_id: searchedUser['_id'],
            user_type: searchedUser['_source'].user_type,
          }))
          elasticSearchTotal = res.length
          // friendResponse.total_records = res.length;
        } else {
          self.setState({ isSearching: false, resetSearch: false, noSearchResults: true })
          // //console.log(self.state);
          return
        }
      }
      let totalFilterFriends = totalFilterIDs.length
      if (self.state.userType !== '')
        totalFilterFriends = totalFilterIDs.filter(
          (user) => user.user_type === self.state.userType,
        ).length

      let total_with_search = {
        total_friends: totalFilterFriends,
      }
      const endOfPage = totalFilterFriends < SEARCH_AMT ? totalFilterFriends : SEARCH_AMT

      let stateData = {
        totalFriendIDs,
        totalMutualFriendIDs,
        totalFilterIDs,
        endOfPage,
        total_with_search,
        overall,
        mutual_friends:
          totalFriendAllIDs.length > 0
            ? {
                ...(response?.data?.total_with_search?.mutual_friends ?? []),
              }
            : self.state.mutual_friends,
        noSearchResults: totalFilterIDs.length <= 0,
        resetSearch: false,
      }
      //console.log(stateData)

      const currentIDs = totalFilterIDs.slice(0, endOfPage).map((user) => user.friend_user_id)

      //console.log(currentIDs)
      self.setState(stateData, () => {
        if (currentIDs.length) {
          self.searchForFriends(currentIDs)
        } else {
          self.setState({ isSearching: false })
        }
      })
    }
  }

  searchForFriends = (currentIDs) => {
    //console.log(currentIDs)
    const self = this
    if (this.state.resetSearch) return

    const searchID = this.state.searchID
    friendApiService
      .friendListCallNewFromIDs({
        other_user_id: self.state.user_id,
        // firstCall: true,
        endOfPage: self.state.endOfPage || SEARCH_AMT,
        user_type: self.state.userType,
        ids: currentIDs,
      })
      .then(function (response) {
        //console.log(response)
        if (self.state.resetSearch || searchID !== self.state.searchID) return
        self.setState(
          (st) => ({
            friendList: [...st.friendList, ...(response?.data?.friends_list ?? [])],
            hasMoreData: st.endOfPage < st.total_with_search.total_friends,
            isSearching: false,
            loadingMoreData: false,
          }),
          () =>
            self.state.hasMoreData &&
            !self.state.resetSearch &&
            searchID === self.state.searchID &&
            self.searchAgain(),
        )
      })
  }

  handlePageChange(pageNumber) {
    $('html, body').animate(
      {
        scrollTop: $('#friend').offset().top - 100,
      },
      1000,
    )
    this.setState(
      {
        activePage: pageNumber,
        isSearching: true,
        friendList: [],
      },
      () => {
        //console.log('here 4')
        this.friendListRender()
      },
    )
  }

  itrustitHandle(data) {
    this.setState(
      (st) => {
        let friendList = st.friendList
        friendList.filter((user) => user.id === data.id)[0]['is_trusted_user'] =
          data.is_trusted_user === 1 ? 0 : 1
        return {
          friendList,
        }
      },
      () => {
        //console.log(this.state.friendList)
      },
    )
  }
  setStatusOfMemberUserYour = (id, status) => {
    this.setState(
      (st) => {
        let friendList = st.friendList
        friendList.filter((user) => user.id === id)[0]['is_your_member_user'] =
          status === 'pending' ? 2 : status === 'accepted' ? 1 : 0
        return {
          friendList,
        }
      },
      () => {},
    )
  }
  memberAddRemoveHandle = (data) => {
    let friendList = this.state.friendList
    friendList.filter((user) => user.id === data.id)[0]['is_member_user'] =
      data.is_member_user === 1 ? 0 : 1

    this.setState({
      friendList: friendList,
    })
  }
  /*910 - update the search value on change, so the right value is use when other tabs are clicked*/
  handleFriendSearchChange = (event) => {
    event.preventDefault()
    this.setState({ searchString: event.target.value })
  }
  handleFriendSearchSubmit = (event) => {
    event.preventDefault()
    this.setState(
      {
        searchString: event.target.elements.friendSearchTxt.value,
        isSearching: true,
        resetSearch: true,
        friendList: [],
        searchID: `${event.target.elements.friendSearchTxt.value ?? ''}_${
          this.state.total_with_search.total_friends ?? 0
        }`,
      },
      () => {
        //console.log('here ')
        this.friendListRender()
      },
    )
  }

  changeFriendActiveTab = (data) => (event) => {
    let userType = ''
    let is_mutual = 0
    let tmpActivePage = this.state.activePage
    if (data !== '') {
      if (data.includes('mutual')) is_mutual = 1
      if (data.includes('individual')) userType = 'individual'
      else if (data.includes('business')) userType = 'business'
      else if (data.includes('organization')) userType = 'organization'
      else userType = ''
    }
    if (userType !== '' && is_mutual === 1) tmpActivePage = 1

    this.setState(
      {
        userType: userType,
        is_mutual: is_mutual,
        activePage: tmpActivePage,
        isSearching: true,
        endOfPage: SEARCH_AMT,
        friendList: [],
        //searchedIDs: [],
      },
      () => {
        //console.log('here 6')
        this.friendListRender()
      },
    )
  }

  profileHeader_friend_tab_click_Handle(data = '', mutual = '') {
    //document.title = PROFILE_FRIEND_TITLE;
    //console.log('profile header friend:', data)
    if (data !== '') {
      $('.nav-tabs a[href="' + data + '"]').tab('show')
    }
    let userType = this.state.userType
    let is_mutual = this.state.is_mutual
    let tmpActivePage = this.state.activePage
    if (data !== '') {
      if (data.includes('mutual')) is_mutual = 1
      if (data.includes('individual')) userType = 'individual'
      else if (data.includes('business')) userType = 'business'
      else if (data.includes('organization')) userType = 'organization'
      else userType = ''
    }
    if (userType !== '' && is_mutual === 1) tmpActivePage = 1

    this.setState(
      {
        userType: userType,
        is_mutual: is_mutual,
        activePage: tmpActivePage,
        isSearching: true,
        endOfPage: SEARCH_AMT,
        friendList: [],
        //searchedIDs: [],
      },
      () => {
        //console.log('here 6')
        this.friendListRender()
      },
    )
  }

  friendAddRemoveHandle(data) {
    // let friendList = this.state.friendList;
    // friendList.filter((user) => user.id === data.id)[0]['is_friend_user'] =
    //   data.is_friend_user === 1 ? 0 : 1;
    //console.log(data)
    this.setState(
      (st) => {
        let friendList = st.friendList
        friendList.filter((user) => user.id === data.id)[0]['is_friend_user'] =
          data.is_friend_user === 1 ? 0 : 1
        friendList.filter((user) => user.id === data.id)[0]['friendship_id'] =
          data.is_friend_user === 1 ? null : data.friendship_id
        return {
          friendList,
        }
      },
      () => {
        this.props.setLoadingFriendStatusChange(false)
        //console.log(this.state.friendList)
      },
    )
  }

  infiniteScroll() {
    if (this.state.hasMoreData) {
      const winScroll = document.body.scrollTop || document.documentElement.scrollTop

      const height = document.documentElement.scrollHeight - document.documentElement.clientHeight

      const scrolled = winScroll / height
      //console.log(scrolled)
      if (scrolled >= 0.7) {
        this.searchAgain()
      }
    }
  }

  searchAgain() {
    this.setState(
      (st) => ({
        hasMoreData: false,
        endOfPage: st.endOfPage + SEARCH_AMT,
        loadingMoreData: true,
      }),
      () => {
        //console.log('searchagain endofpge:', this.state.endOfPage)
        let currentIDs = this.state.totalFilterIDs
          .slice(this.state.endOfPage - SEARCH_AMT, this.state.endOfPage)
          .map((user) => user.friend_user_id)
        this.searchForFriends(currentIDs)
      },
    )
  }

  render() {
    const { friendList, isSearching, userType, noSearchResults = false } = this.state
    let listToShow =
      userType === '' ? friendList : friendList.filter((user) => user.user_type === userType)
    listToShow = listToShow.filter(
      (thing, index, self) => self.findIndex((t) => t.id === thing.id) === index,
    )
    //console.log(listToShow)
    let totalRecords = listToShow.length
    let showingFrom = this.props.itemsCountPerPage * (this.state.activePage - 1) + 1
    let showingTo = listToShow.length || showingFrom
    if (showingFrom > totalRecords) {
      showingFrom = totalRecords
    }
    if (showingTo > this.state.totalRecords) {
      showingTo = this.state.totalRecords
    }

    let total_with_search_count = 0
    let total_with_search_pages = 0

    total_with_search_count = this.state.total_with_search.total_friends
    total_with_search_pages = Math.ceil(
      this.state.total_with_search.total_friends / this.props.itemsCountPerPage,
    )

    return (
      <div
        id='friend'
        className={'tab-pane ' + (this.props.activeTab === '#friend' ? 'active' : 'fade')}
      >
        {this.state.isSettingToShowFriendTab ? (
          <div>
            <h4 className='varela'>Friends ({this.state.overall.total_friends})</h4>
            <div className='bg-white'>
              <div className='friend_list_block'>
                <div className='f-left'>
                  <ul className='inner-navs nav nav-tabs'>
                    <li className='nav-item'>
                      <a
                        className={`nav-link ${this.state.userType === '' ? 'active' : ''}`}
                        data-bs-toggle='tab'
                        href='#friend_all'
                        onClick={this.changeFriendActiveTab('friend_all')}
                      >
                        <span>{this.state.overall.total_friends} FRIENDS</span>
                      </a>
                      {/* <span className="mutual"> */}
                      <a
                        className={`nav-link  ${this.state.userType === '' ? 'active' : ''}`}
                        data-bs-toggle='tab'
                        href='#friend_all_mutual'
                        onClick={this.changeFriendActiveTab('friend_all_mutual')}
                      >
                        {this.state.mutual_friends.individual +
                          this.state.mutual_friends.business +
                          this.state.mutual_friends.organization}{' '}
                        mutual{' '}
                      </a>
                      {/* </span> */}
                    </li>
                    <li className='nav-item'>
                      <a
                        className={`nav-link ${
                          this.state.userType === 'individual' ? 'active' : ''
                        }`}
                        data-bs-toggle='tab'
                        href='#friend_individual'
                        onClick={this.changeFriendActiveTab('friend_individual')}
                      >
                        <span>{this.state.overall.total_individual} PEOPLE</span>
                      </a>
                      {/* <span className="mutual"> */}
                      <a
                        className={`nav-link ${
                          this.state.userType === 'individual' && this.state.is_mutual
                            ? 'active'
                            : ''
                        }`}
                        data-bs-toggle='tab'
                        href='#friend_individual_mutual'
                        onClick={this.changeFriendActiveTab('friend_individual_mutual')}
                      >
                        {' '}
                        {this.state.mutual_friends.individual} mutual{' '}
                      </a>
                      {/* </span> */}
                    </li>
                    <li className='nav-item'>
                      <a
                        className={`nav-link ${this.state.userType === 'business' ? 'active' : ''}`}
                        data-bs-toggle='tab'
                        href='#friend_business'
                        onClick={this.changeFriendActiveTab('friend_business')}
                      >
                        <span>{this.state.overall.total_business} BUSINESSES </span>
                      </a>
                      {/* <span className="mutual"> */}
                      <a
                        className={`nav-link ${
                          this.state.userType === 'business' && this.state.is_mutual ? 'active' : ''
                        }`}
                        data-bs-toggle='tab'
                        href='#friend_business_mutual'
                        onClick={this.changeFriendActiveTab('friend_business_mutual')}
                      >
                        {this.state.mutual_friends.business} mutual{' '}
                      </a>
                      {/* </span> */}
                    </li>
                    <li className='nav-item'>
                      <a
                        className={`nav-link ${
                          this.state.userType === 'organization' ? 'active' : ''
                        }`}
                        data-bs-toggle='tab'
                        href='#friend_organization'
                        onClick={this.changeFriendActiveTab('friend_organization')}
                      >
                        <span>{this.state.overall.total_organization} ORGANIZATIONS</span>
                      </a>
                      {/* <span className="mutual"> */}
                      <a
                        className={`nav-link ${
                          this.state.userType === 'organization' && this.state.is_mutual
                            ? 'active'
                            : ''
                        }`}
                        data-bs-toggle='tab'
                        href='#friend_organization_mutual'
                        onClick={this.changeFriendActiveTab('friend_organization_mutual')}
                      >
                        {' '}
                        {this.state.mutual_friends.organization} mutual{' '}
                      </a>
                      {/* </span> */}
                    </li>
                  </ul>
                </div>
                <div className='f-right'>
                  <form onSubmit={this.handleFriendSearchSubmit}>
                    <input
                      type='text'
                      name='friendSearchTxt'
                      className='input1 form-control'
                      placeholder='Search for a person,  business, or organization...'
                      /*910 - update the search value on change, so the right value is use when other tabs are clicked*/
                      onChange={this.handleFriendSearchChange}
                    />
                    <input type='submit' className='search' value='' />
                  </form>
                </div>
              </div>

              <div className='inner-tab tab-content'>
                {total_with_search_count > 0 && !isSearching && !noSearchResults ? (
                  <Fragment>
                    <div className='row frnd_1'>
                      {listToShow.map(
                        (user, index) =>
                          index < this.state.endOfPage && (
                            <UserListDetail
                              key={user.id}
                              index={index}
                              confirmitrustitAddRemove={this.props.confirmitrustitAddRemove}
                              confirmFriendAddRemove={this.props.confirmFriendAddRemove}
                              loadingFriendStatusChange={this.props.loadingFriendStatusChange}
                              loadingitrustitStatusChange={this.props.loadingitrustitStatusChange}
                              tab={'friend'}
                              login_user_id={this.state.login_user_id}
                              user={user}
                              viewType={this.state.viewType}
                              itrustitHandle={this.itrustitHandle}
                              friendAddRemoveHandle={this.friendAddRemoveHandle}
                              memberAddRemoveHandle={this.memberAddRemoveHandle}
                              setModalProps={this.props.setModalProps}
                              setStatusOfMemberUserYour={this.setStatusOfMemberUserYour}
                            />
                          ),
                      )}
                    </div>
                    {this.state.loadingMoreData ? (
                      <div className='no_search_result'>
                        <div className='search-page-loading-icon'>
                          {' '}
                          <i />{' '}
                        </div>
                      </div>
                    ) : (
                      !this.state.hasMoreData && (
                        <div
                          onClick={() => {
                            window.scrollTo({
                              top: $('#friend').offset().top - 100,
                              behavior: 'smooth',
                            })
                          }}
                          className='no_search_result back-to-top text-muted'
                        >
                          Back to Top
                        </div>
                      )
                    )}
                    {/* <div>
                      <Pagination
                        activePage={activePage}
                        itemsCountPerPage={this.props.itemsCountPerPage}
                        totalItemsCount={total_with_search_count}
                        pageRangeDisplayed={this.props.pageRangeDisplayed}
                        onChange={this.handlePageChange}
                      />
                    </div> */}
                  </Fragment>
                ) : noSearchResults || isSearching === false ? (
                  <div className='search-section'>
                    <div className='no_search_result'>
                      <img
                        src={process.env.PUBLIC_URL + '/img/no_friends.png'}
                        className='pt-5 pb-5 m-auto d-block'
                        alt=''
                      />
                    </div>
                  </div>
                ) : (
                  <div className='search-section'>
                    <div className='no_search_result'>
                      <div className='search-page-loading-icon'>
                        {' '}
                        <i />{' '}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className='it-no-access'>
            <h4 className='varela'>Friends</h4>
            <div className='bg-white'>
              <div className=' inner-tab tab-content'>
                <div className='search-section friend_list_block'>
                  <div className='no_search_result'>
                    <img src='/img/hide-frd.svg' className='pt-5 m-auto d-block' alt='' />
                    <h2>You Don't Have Access</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    )
  }
}

FriendsTab.defaultProps = {
  itemsCountPerPage: LIST_VIEW_ITEMS_PER_PAGE,
  pageRangeDisplayed: LIST_VIEW_PAGE_RANGE,
  user_settings: {},
}

export default FriendsTab
