import React, { Component } from 'react'
import AppLayout from '../AppLayout'
import { Field, reduxForm } from 'redux-form'
import ReactSelect from '../../services/ReactSelect'
import * as api from '../../services/businessService'
import * as validation from '../../services/formValidation'
import renderInputField from '../Form/Input'
import { relationshipList } from '../../services/const'
import * as customFunction from '../../services/customFunction'
import ErrorMsgRenderer from '../ErrorMsgRenderer/ErrorMsgRenderer'
import { toaster } from '../../services/customFunction'
import * as route from '../../services/routeConst'
import { Redirect } from 'react-router-dom'
import { NETWORK_ERROR_MSG } from '../../services/const'
import $J from 'jquery'
let categoryIds = []

class AddBusinessOrOrganizationCategory extends Component {
  constructor(props) {
    super(props)
    categoryIds = []
    this.state = {
      business_organization_name: '',
      isUsernamePopupDisplay: false,
      countryList: [],
      stateList: [],
      cityList: [],
      categoryList: [],
      subCategoryValue: null,
      subOfSubCategoryValue: null,
      subCategoryList: [],
      subOfSubCategoryList: [],
      categoryIndex: null,
      imatrix_id: this.props.location.state ? this.props.location.state.imatrix_id : '',
      sponsorOwnerName: '',
      sponsorErrorMsg: '',
      category_array: [],
      category: {
        // category_id: '',
        // sub_category_id: '',
        // sub_of_sub_category_id: '',
        firstname: '',
        lastname: '',
        relationship_type: '',
        email: '',
        // username:'',
        is_send_invitation_email: '',
        category_id: [],
      },
      validation: {
        isSpaceValidation: false,
        isMaxLength: false,
        isUppercase: false,
        isLowercase: false,
        isNumber: false,
      },
      usernameValidation: {
        isMaxLength: false,
        isBeginLatter: false,
        isCharacter: false,
        isDashed: false,
        //isSpaceValidation: false,
      },
      error: {
        email: [],
        username: [],
      },
      is_send_invitation_email: 0,
      slug: '',
    }
  }

  componentDidMount = () => {
    window.scrollTo(0, 0)
    this.categoryListById('category', '0')
  }

  changedInvitation = (event) => {
    let self = this
    if (this.state.is_send_invitation_email === 1) {
      self.setState({
        is_send_invitation_email: 0,
      })
    }
    if (this.state.is_send_invitation_email === 0) {
      self.setState({
        is_send_invitation_email: 1,
      })
    }
  }

  usernamePopup = () => {
    // this.resetPasswordEmailFormHandler();
    setTimeout(() => {
      this.setState({ isUsernamePopupDisplay: !this.state.isUsernamePopupDisplay })
    }, 200)
  }

  componentWillMount = () => {}

  categoryHandler = (currentNode, selectedNodes) => {
    // this.setState({ categoryIds: this.state.categoryIds.concat([data.value]) });
    categoryIds = []

    categoryIds = selectedNodes.map((category) => category.value)
    if (categoryIds.length > 0) {
      if (this.state.error.category) this.setState({ error: { category: [''] } })
    }
  }

  changeHandler = (e) => {
    this.setState({ imatrix_id: e.target.value })
  }

  categoryChangeHandler = (event) => {
    console.log(event)
    let categoryIndex = this.state.categoryList.findIndex((x) => x.value === event.value)

    this.setState({
      categoryIndex: categoryIndex,
      subCategoryValue: null,
      subOfSubCategoryValue: null,
    })
    this.categoryListById('subcategory', event.value, 'sub_category_id')
  }

  subCategoryChangeHandler = (event) => {
    this.setState({ subCategoryValue: event.value, subOfSubCategoryValue: null })
    this.categoryListById('subofsubcategory', event.value, 'sub_of_sub_category_id')
  }

  subOfSubCategoryChangeHandler = (event) => {
    this.setState({ subOfSubCategoryValue: event.value })
  }

  categoryListById = (type, id, subtype = null) => {
    let self = this

    api.singleCategoryList(id).then(function (response) {
      if (response.data) {
        let categories = response.data.categories
        let categorylist = []
        categorylist = categories.map((category) => {
          return { value: category.id, label: category.category_name }
        })

        let obj = {}
        if (type === 'category') {
          obj.categoryList = categorylist
          obj.subCategoryList = []
          obj.subOfSubCategoryList = []
          obj.subCategoryValue = null
          obj.subOfSubCategoryValue = null
        } else if (type === 'subcategory') {
          categorylist.unshift({ value: 0, label: 'Select...' })
          obj.subCategoryList = categorylist
          obj.subOfSubCategoryList = []
          obj.subCategoryValue = categorylist[0].value
          obj.subOfSubCategoryValue = null
        } else if (type === 'subofsubcategory') {
          categorylist.unshift({ value: 0, label: 'Select...' })
          obj.subOfSubCategoryList = categorylist
          obj.subOfSubCategoryValue = categorylist[0].value
        }

        self.setState(obj)
      }
    })
    if (subtype) {
      this.props.change(subtype, null)
      this.props.touch(subtype, null)
    }
  }

  continueHandler = (data) => {
    let datas = this.props.location.state.form_data

    this.setState({ business_organization_name: datas.business_organization_name })

    this.state.category.category_id.push(data.category_id.value)
    if (window.innerWidth < 992) {
      if (this.state.subCategoryValue && this.state.subCategoryValue > 0) {
        this.state.category.category_id.push(this.state.subCategoryValue)
      }
      if (this.state.subOfSubCategoryValue && this.state.subOfSubCategoryValue > 0) {
        this.state.category.category_id.push(this.state.subOfSubCategoryValue)
      }
    } else {
      if (data.sub_category_id && data.sub_category_id.value) {
        this.state.category.category_id.push(data.sub_category_id.value)
      }
      if (data.sub_of_sub_category_id && data.sub_of_sub_category_id.value) {
        this.state.category.category_id.push(data.sub_of_sub_category_id.value)
      }
    }
    this.setState((prevState) => {
      let category = Object.assign({}, prevState.category) // creating copy of state variable category
      category.firstname = data.firstname ? data.firstname : ''
      category.lastname = data.lastname ? data.lastname : ''
      category.relationship_type = data.relationship_type ? data.relationship_type.value : ''
      category.email = data.email ? data.email : ''
      // this.state.category.username = data.username ? data.username : "";
      category.is_send_invitation_email = this.state.is_send_invitation_email
      return { category } // return new object category object
    })

    let formData = { ...datas, ...this.state.category }
    if (this.state.is_send_invitation_email !== 1) {
      delete formData.email
    }

    let self = this

    api.addUnclaimedBusinessOrOrganization(formData).then(function (response) {
      if (response === undefined) {
        toaster('error', NETWORK_ERROR_MSG)
      } else if (response.status === 422) {
        console.log('addbusorg:', response.data.data)
        self.setState({
          error: response.data.data,
        })
      } else if (response.status === 200) {
        let slug = response.data.data.user.username
        self.setState({ slug: slug })
        $J('#add_business_or_organization_footer_added').modal('show')
      } else {
        toaster('error', response.data.message)
      }
    })
  }

  viewProfile = () => {
    $J('#add_business_or_organization_footer_added').modal('hide')
    let setPath = route.ROOT_ROUTE + this.state.slug
    this.props.history.push({
      pathname: setPath,
    })
  }
  onEmailChange = () => {
    this.setState(
      {
        error: { ...this.state.error, email: [] },
      },
      () => {
        console.log(this.state.error)
      },
    )
  }
  render() {
    if (!this.props.location.state) {
      return (
        <Redirect
          to={{
            pathname: route.ROOT_ROUTE,
          }}
        />
      )
    }
    if (this.props.history.action === 'POP') {
      return (
        <Redirect
          to={{
            pathname: route.ROOT_ROUTE,
          }}
        />
      )
    }

    const {
      categoryList,
      subCategoryList,
      subOfSubCategoryList,
      subCategoryValue,
      subOfSubCategoryValue,
    } = this.state

    const { handleSubmit } = this.props

    //$914 - no Owner should arrive here, but just in case :-)!
    const userTypeLabels = customFunction.getUserLabels(
      this.props.location.state.form1.add_business_or_organization,
    )

    return (
      <AppLayout isDisplayFooter={false}>
        <div>
          <div
            className='modal fade login_blk reset_password_blk it-add-business '
            id='add_business_or_organization_footer_added'
            role='dialog'
            data-bs-backdrop='static'
            data-bs-keyboard='false'
          >
            <div className='modal-dialog'>
              <div className='modal-content'>
                <div className='modal-header'>
                  <div className='claim_steps'>
                    <h3 className='add_title'>
                      <span className='other_txt'>ADDED</span>
                    </h3>

                    <div className='mt-md-4 mt-2 mb-1'>Thank You!</div>

                    <div className='mt-md-4 mt-2 mb-1'>
                      {this.state.business_organization_name} has been added to the iTRUSTit system.
                    </div>

                    <div className='add_bottom'>
                      <div className='text-center'>
                        <a href={null} onClick={this.viewProfile} className='continue'>
                          Ok
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <form
            method='post'
            id='login'
            name='AddBusinessOrOrganizationNameAndAddress'
            onSubmit={handleSubmit(this.continueHandler).bind(this)}
          >
            <div className='container it-before-login'>
              <div className='claim_steps loginblk signupblk'>
                <h3 className='add_title'>
                  <span className='other_txt'>
                    Add {this.props.location.state.user_type} as {userTypeLabels.type} Step-4
                  </span>
                  <span className='other_txt d-block'>Category / Owner info</span>
                </h3>
                <div className='row fieldflex form-group'>
                  <div className='col-md-3'>
                    <label className='control-required'>Category:</label>
                  </div>
                  <div className='col-md-9'>
                    <div className='for-select'>
                      <Field
                        type='text'
                        component={ReactSelect}
                        name='category_id'
                        options={categoryList}
                        valueKey={categoryList.length > 0 && categoryList[this.state.categoryIndex]}
                        validate={[validation.required]}
                        isSelect='false'
                        onChange={this.categoryChangeHandler}
                      />
                    </div>
                  </div>
                </div>

                {this.state.subCategoryList.length > 0 ? (
                  <div className='row fieldflex form-group'>
                    <div className='col-md-3'></div>
                    <div className='col-md-9'>
                      <div className='for-select'>
                        <Field
                          type='text'
                          component={ReactSelect}
                          name='sub_category_id'
                          options={subCategoryList}
                          valueKey={subCategoryValue}
                          //210824-1 -not required
                          //validate={[validation.required]}
                          isSelect='true'
                          onChange={this.subCategoryChangeHandler}
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  ''
                )}
                {this.state.subOfSubCategoryList.length > 1 ? (
                  <div className='row fieldflex form-group'>
                    <div className='col-md-3'></div>
                    <div className='col-md-9'>
                      <div className='for-select text-start'>
                        <Field
                          type='text'
                          component={ReactSelect}
                          name='sub_of_sub_category_id'
                          valueKey={subOfSubCategoryValue}
                          options={subOfSubCategoryList}
                          //210824-1 -not required
                          //validate={[validation.required]}
                          isSelect='true'
                          onChange={this.subOfSubCategoryChangeHandler}
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  ''
                )}

                <div className='row fieldflex form-group'>
                  <div className='col-md-3'>
                    <input
                      type='checkbox'
                      id='is_send_invitation_email'
                      checked={this.state.is_send_invitation_email}
                      onChange={this.changedInvitation}
                    />
                  </div>
                  <div className='col-md-9 text-start'>
                    <label className='text-start' htmlFor='is_send_invitation_email'>
                      If you have the contact info for the owner/rep of this{' '}
                      {this.props.location.state.user_type} click here to send them an invitation to
                      claim their {this.props.location.state.user_type}
                    </label>
                  </div>
                </div>

                {this.state.is_send_invitation_email === 1 && (
                  <div>
                    <div className='row fieldflex form-group'>
                      <div className='col-md-3'>
                        <label className='control-required'>Owner/Manager First Name:</label>
                      </div>
                      <div className='col-md-9'>
                        <Field
                          name='firstname'
                          type='text'
                          className='form-control'
                          component={renderInputField}
                          validate={[validation.required]}
                          // validate={[validation.required]}
                          label='Lastname'
                          placeholder='Enter owner/manager first name'
                          autoComplete='off'
                        />
                      </div>
                    </div>

                    <div className='row fieldflex form-group'>
                      <div className='col-md-3'>
                        <label className='control-required'>Owner/Manager Last Name:</label>
                      </div>
                      <div className='col-md-9'>
                        <Field
                          name='lastname'
                          type='text'
                          className='form-control'
                          component={renderInputField}
                          validate={[validation.required]}
                          label='Lastname'
                          placeholder='Enter owner/manager last name'
                          autoComplete='off'
                        />
                      </div>
                    </div>
                    <div className='row fieldflex form-group'>
                      <div className='col-md-3'>
                        <label className='control-required'>
                          Relationship to {this.props.location.state.user_type}:
                        </label>
                      </div>
                      <div className='col-md-9'>
                        <div className='for-select'>
                          <Field
                            type='text'
                            className='form-control'
                            component={ReactSelect}
                            name='relationship_type'
                            options={relationshipList}
                            validate={[validation.required]}
                            isSelect='false'
                            onChange={this.relationshipHandler}
                            value={this.state.relationshipValue}
                          />
                        </div>
                      </div>
                    </div>

                    <div className='row fieldflex form-group'>
                      <div className='col-md-3'>
                        <label className='control-required'>Owner/Manager Email Address:</label>
                      </div>
                      <div className='col-md-9'>
                        <Field
                          name='email'
                          type='text'
                          className='form-control'
                          component={renderInputField}
                          validate={[validation.required, validation.email]}
                          label='Lastname'
                          placeholder='Enter owner/manager email address'
                          autoComplete='off'
                          onChange={this.onEmailChange}
                        />
                        <ErrorMsgRenderer
                          errors={
                            typeof this.state.error.email !== 'undefined' &&
                            Array.isArray(this.state.error.email) &&
                            this.state.error.email.length > 0
                              ? this.state.error.email
                              : []
                          }
                        />
                      </div>
                    </div>
                  </div>
                )}
                <div className='add_bottom'>
                  <div className='text-center'>
                    {/* <a href={null} onClick={this.continueHandler} className="continue"> */}
                    {console.log(
                      this.props,
                      this.props.submitFailed,
                      this.state.is_username_exists,
                      this.props.invalid,
                    )}
                    {/*210824-1*/}
                    {validation.isFormValid(
                      this.props.submitFailed,
                      this.state.is_username_exists,
                      this.props.invalid,
                    )}
                    <input
                      type='submit'
                      className='loginbtn'
                      value={`Click here to complete and add ${this.props.location.state.user_type}`}
                    />
                    {/* </a> */}
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </AppLayout>
    )
  }
}

AddBusinessOrOrganizationCategory = reduxForm({
  form: 'AddBusinessOrOrganizationCategory',
})(AddBusinessOrOrganizationCategory)
export default AddBusinessOrOrganizationCategory
// export default AddBusinessOrOrganizationNameAndAddress;
