import $ from 'jquery'
import React, { useEffect, useState, useRef } from 'react'
import { reduxForm } from 'redux-form'
import { DEFAULT_PROFILE_PICTURE, TINYMCE_KEY, POST_TEXT_SIZE } from '../../../services/const'
import { getUser } from '../../../services/customFunction'
import SearchPageLoadingIcon from '../../Search/SearchPageLoadingIcon'
import { Editor } from '@tinymce/tinymce-react'
import './NewComment.scss'

const NewComment = ({ id, submitBtnText, addHandler, cancelSubmitHandler, entry }) => {
  const login_user = getUser()
  let profile_picture_small = login_user?.user_detail?.profile_picture_small
    ? login_user?.user_detail?.profile_picture_small
    : DEFAULT_PROFILE_PICTURE
  const [status, setStatus] = useState('idle')
  const [commentBody, setCommentBody] = useState('')
  const editorRef = useRef(null)
  const initCommentBody = entry ? entry?.comment_body : ''
  const [textSize, setTextSize] = useState(0)
  //console.log('entry:', entry)
  useEffect(() => {
    $('#comment_body').on('input', function () {
      //console.log('scrollheight before')
      var scroll_height = $('#comment_body').get(0).scrollHeight
      //console.log('scrollheight', scroll_height)
      $('comment_body').css('height', scroll_height + '10px')
    })
  }, [])

  const createSubmitHandler = async () => {
    setStatus('loading')
    addHandler(commentBody)
    setStatus('idle')
  }

  const onChangeHandler = (value) => {
    //console.log(value)
    setTextSize(editorRef?.current.getContent({ format: 'text' }).trim().length)
    if (editorRef?.current) {
      //console.log('TEXT length:', textSize, 'HTML length:', value.length)
      setCommentBody(value)
    }
  }
  let hasContain = commentBody?.length > 0
  let isMaximSize = POST_TEXT_SIZE - textSize < 0
  return (
    <div name='newCommentForm' className='newcomment container'>
      <div className='newcomment-top'>
        <img className='img_block' src={profile_picture_small} alt='' />{' '}
        <div className='newcomment_text'>
          <Editor
            //apiKey='9c02npowy44212esjfteh330l48ouwzskadadziu3e4vurgv'
            apiKey={TINYMCE_KEY}
            selector='textarea'
            onInit={(evt, editor) => {
              editorRef.current = editor
              editor.focus()
            }}
            initialValue={initCommentBody}
            init={{
              height: 100,
              menubar: false,
              plugins: 'autolink autoresize link emoticons',
              statusbar: false,
              toolbar: 'emoticons',
              skin: 'outside',
              toolbar_location: 'bottom',
              default_link_target: '_blank',
              content_style:
                'body { font-family:Helvetica,Arial,sans-serif; font-size:14px } p{margin-bottom:0;margin-top:0}',
            }}
            onEditorChange={(newText) => onChangeHandler(newText)}
          />
          {isMaximSize && (
            <div class='alert alert-danger' role='alert'>
              You have exceeded the maximum character limit:
              {POST_TEXT_SIZE - textSize}
            </div>
          )}
        </div>
      </div>

      <div className='updateCommentBottom'>
        {status === 'loading' ? (
          <SearchPageLoadingIcon
            className='pagination-loading'
            styles={{
              height: 'auto',
            }}
            iconStyles={{ height: 38, transform: 'scale(1.5)' }}
          />
        ) : entry ? (
          <>
            <button type='submit' class='btn btn btn-primary' onClick={() => cancelSubmitHandler()}>
              {'Cancel'}
            </button>
            <button
              type='submit'
              class='btn btn btn-primary'
              onClick={() => createSubmitHandler()}
              disabled={!hasContain || isMaximSize}
            >
              {'Update'}
            </button>
          </>
        ) : (
          <>
            <button type='submit' class='btn btn btn-primary' onClick={() => cancelSubmitHandler()}>
              {'Cancel'}
            </button>
            <button
              type='submit'
              class='btn btn btn-primary'
              onClick={() => createSubmitHandler()}
              disabled={!hasContain}
            >
              {submitBtnText}
            </button>
          </>
        )}
      </div>
    </div>
  )
}
export default reduxForm({
  form: 'newCommentForm',
})(NewComment)
