import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import {
  profileInfo,
  editUserStore,
  coverImageAction,
  profileInfoReset,
} from '../../actions/profile'
import { userStoreAction } from '../../actions/auth'
import cleanDeep from 'clean-deep'

import EditBusinessProfile from '../../components/EditBusinessProfile/EditBusinessProfile'

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { profileInfo, editUserStore, userStoreAction, coverImageAction, profileInfoReset },
    dispatch,
  )
}

function mapStateToProps(state) {
  let user = cleanDeep(state.UserReducer.editUser)

  return {
    editUser: state.UserReducer.editUser,
    initialValues: {
      business_organization_name:
        Object.keys(user).length > 0 ? user.business_organization_name : '',
      sponsors_iteam_id: Object.keys(user).length > 0 ? user.imatrix_id : '',
      username: Object.keys(user).length > 0 ? user.username : '',
      email: Object.keys(user).length > 0 ? user.email : '',
      relationship_type: Object.keys(user).length > 0 ? user.relationship_type : null,
      address_line_1: Object.keys(user).length > 0 ? user.address.address_line_1 : '',
      address_line_2: Object.keys(user).length > 0 ? user.address.address_line_2 : '',
      country_id: Object.keys(user).length > 0 ? user.address.country_id : null,
      state_id: Object.keys(user).length > 0 ? user.address.state_id : null,
      city_id: Object.keys(user).length > 0 ? user.address.city_id : null,
      zipcode: Object.keys(user).length > 0 ? user.address.zipcode : '',
      phone_no_1: Object.keys(user).length > 0 ? user.user_detail.phone_no_1 : '',
      phone_no_2: Object.keys(user).length > 0 ? user.user_detail.phone_no_2 : '',
      mobile_no: Object.keys(user).length > 0 ? user.user_detail.mobile_no : '',
      fax_no: Object.keys(user).length > 0 ? user.user_detail.fax_no : '',
      website_1: Object.keys(user).length > 0 ? user.user_detail.website_1 : '',
      website_2: Object.keys(user).length > 0 ? user.user_detail.website_2 : '',
      website_3: Object.keys(user).length > 0 ? user.user_detail.website_3 : '',
      website_4: Object.keys(user).length > 0 ? user.user_detail.website_4 : '',
      email_2: Object.keys(user).length > 0 ? user.user_detail.email_2 : '',
      email_3: Object.keys(user).length > 0 ? user.user_detail.email_3 : '',
      email_4: Object.keys(user).length > 0 ? user.user_detail.email_4 : '',
      facebook: Object.keys(user).length > 0 ? user.user_detail.facebook : '',
      fiverr: Object.keys(user).length > 0 ? user.user_detail.fiverr : '',
      instagram: Object.keys(user).length > 0 ? user.user_detail.instagram : '',
      linkedin: Object.keys(user).length > 0 ? user.user_detail.linkedin : '',
      pinterest: Object.keys(user).length > 0 ? user.user_detail.pinterest : '',
      tiktok: Object.keys(user).length > 0 ? user.user_detail.tiktok : '',
      twitter: Object.keys(user).length > 0 ? user.user_detail.twitter : '',
      youtube: Object.keys(user).length > 0 ? user.user_detail.youtube : '',
      whatsapp: Object.keys(user).length > 0 ? user.user_detail.whatsapp : '',
      paypal: Object.keys(user).length > 0 ? user.user_detail.paypal : '',
      venmo: Object.keys(user).length > 0 ? user.user_detail.venmo : '',
      profession: Object.keys(user).length > 0 ? user.user_detail.profession : '',
      about_me: Object.keys(user).length > 0 ? user.user_detail.about_me : '',
      tagline: Object.keys(user).length > 0 ? user.user_detail.tagline : '',
      // slogan: Object.keys(user).length > 0 ? user.user_detail.slogan : "",
      category_main_id:
        Object.keys(user).length > 0
          ? user.categories && user.categories.length && user.categories[0].category_id
          : '',
      sub_of_sub_category_id: null,
      sub_category_id: null,
      area: Object.keys(user).length > 0 ? user.address.area : '',
      firstname: Object.keys(user).length > 0 ? user.firstname : '',
      lastname: Object.keys(user).length > 0 ? user.lastname : '',
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditBusinessProfile)
