import * as type from './const'

export const setSearchData = (data) => {
  return {
    type: type.SET_SEARCH_DATA,
    data: data,
  }
}

export const setIsSearching = (data) => {
  return {
    type: type.SET_IS_SEARCHING,
    data: data,
  }
}

export const setSearchDistance = (data) => {
  return {
    type: type.SET_SEARCH_DISTANCE,
    data: data,
  }
}
