import React, { Component } from 'react'
import AppLayout from '../AppLayout'
class MyProfile extends Component {
  render() {
    return (
      <AppLayout>
        <section className='cover_details pro_business my_profile'>
          <div className='container'>
            <div className='cover_inner'>
              <div className='pro_name_detail d-flex'>
                <div className='pro_1'>
                  <img src={process.env.PUBLIC_URL + '/img/ed.png'} alt='' />
                </div>
                <div className='pro_name'>
                  <h3 className='blue'>Mangrove Realty</h3>
                  <p>The power of positive real estate - Best of the beach!</p>
                  <a href={null} className='text-uppercase edit_profile'>
                    {' '}
                    Edit profile
                  </a>
                </div>
              </div>
              <div className='badge_blk'>
                <div className='badge_content'>
                  <div className='badge yellow'>
                    <div className='b-triangle'></div>
                    <div className='b-inner'>
                      <h4>Trust Me</h4>
                      <div className='d1'>
                        <div className='digits_cat'>
                          <div className='digit'>20</div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='badge_star'>
                    <a href={null}>100 Reviews</a>
                    <ul className='d-flex'>
                      <li>
                        <img src={process.env.PUBLIC_URL + '/img/star_top.png'} alt=' ' />
                      </li>
                      <li>
                        <img src={process.env.PUBLIC_URL + '/img/star_top.png'} alt=' ' />
                      </li>
                      <li>
                        <img src={process.env.PUBLIC_URL + '/img/star_top.png'} alt=' ' />
                      </li>
                      <li>
                        <img src={process.env.PUBLIC_URL + '/img/star_top.png'} alt=' ' />
                      </li>
                      <li>
                        <img src={process.env.PUBLIC_URL + '/img/star_top.png'} alt=' ' />
                      </li>
                    </ul>
                  </div>
                </div>
                <div className='badge_content'>
                  <div className='badge'>
                    <div className='b-triangle'></div>
                    <div className='b-inner'>
                      <h4>iTRUST</h4>
                      <div className='d1'>
                        <div className='digits_cat'>
                          <div className='digit'>180</div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='badge_star'>
                    <a href={null}>78 Reviews</a>
                    <ul className='d-flex'>
                      <li>
                        <img
                          src={process.env.PUBLIC_URL + '/img/star_purple.png'}
                          alt='star_purple'
                        />
                      </li>
                      <li>
                        <img
                          src={process.env.PUBLIC_URL + '/img/star_purple.png'}
                          alt='star_purple'
                        />
                      </li>
                      <li>
                        <img
                          src={process.env.PUBLIC_URL + '/img/star_purple.png'}
                          alt='star_purple'
                        />
                      </li>
                      <li>
                        <img
                          src={process.env.PUBLIC_URL + '/img/star_purple.png'}
                          alt='star_purple'
                        />
                      </li>
                      <li>
                        <img
                          src={process.env.PUBLIC_URL + '/img/star_purple.png'}
                          alt='star_purple'
                        />
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className='cover_bottom'>
                <div className='left_link'>
                  <ul className='upper-tabs nav nav-tabs'>
                    <li className='nav-item'>
                      <a className='nav-link' data-bs-toggle='tab' href='#overview'>
                        Overview
                      </a>
                    </li>
                    <li className='nav-item'>
                      <a className='nav-link' data-bs-toggle='tab' href='#trust'>
                        {' '}
                        Trust List (40)
                      </a>
                    </li>
                    <li className='nav-item'>
                      <a className='nav-link' data-bs-toggle='tab' href='#gallery'>
                        Gallery(40)
                      </a>
                    </li>
                    <li className='nav-item'>
                      <a className='nav-link' data-bs-toggle='tab' href='#friend'>
                        Friends (25)
                      </a>
                    </li>
                    <li className='nav-item'>
                      <a className='nav-link' data-bs-toggle='tab' href='#activity'>
                        Activity{' '}
                      </a>
                    </li>
                    <li className='nav-item'>
                      <a className='nav-link active' data-bs-toggle='tab' href='#invite_center'>
                        Invite Center
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='listing_block'>
          <div className='container'>
            <div className='tab-content'>
              <div id='overview' className='tab-pane fade'>
                <div className='row'>
                  <div className='col-md-4 overview-left'>
                    <div className='bg-white curvey mb-4 p-15'>
                      <div className='listing_blk'>
                        <h4 className='varela'>Improve Your Profile</h4>
                        <div className='progress'>
                          <div
                            className='progress-bar'
                            role='progressbar'
                            aria-valuenow='70'
                            aria-valuemin='0'
                            aria-valuemax='100'
                          >
                            65%
                          </div>
                        </div>
                        <a href={null} className='d-block mb-2 improve'>
                          {' '}
                          Add your Social Media Links ( +10% )
                        </a>
                        <a href={null} className='d-block mb-2 improve'>
                          Add your About Me ( +10% )
                        </a>
                        <a href={null} className='d-block mb-2 improve'>
                          Add Profile Picture ( +10% )
                        </a>
                        <a href={null} className='d-block mb-2 improve'>
                          Review a Business/Organization ( +5% ){' '}
                        </a>
                      </div>
                    </div>
                    <div className='bg-white curvey mb-4 p-15'>
                      <h4 className='varela'>
                        {' '}
                        Friends
                        <span className='purple'>248</span>
                      </h4>
                      <div className='friends_green mt-2'>
                        <div className='d-flex headers justify-content-between green'>
                          <h6>People - 357</h6>
                          <h6>44 mutual</h6>
                        </div>

                        <div className='row profiles'>
                          <div className='col-md-6'>
                            <h6 className='varela'>Eddy Jones</h6>
                            <div className='frnd_box'>
                              <div className='img_block green_border'></div>
                              <div className=''>
                                <div className='mb-2'>
                                  <i>
                                    <img
                                      src={process.env.PUBLIC_URL + '/img/purple_tag.png'}
                                      className='me-2'
                                      alt=''
                                    />
                                  </i>
                                  142
                                </div>
                                <div className='mb-2'>
                                  <i>
                                    <img
                                      src={process.env.PUBLIC_URL + '/img/frandss.png'}
                                      className='me-2'
                                      alt=''
                                    />
                                  </i>
                                  149
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='col-md-6'>
                            <h6 className='varela'>Eddy Jones </h6>
                            <div className='frnd_box'>
                              <div className='img_block green_border'></div>
                              <div className=''>
                                <div className='mb-2'>
                                  <i>
                                    <img
                                      src={process.env.PUBLIC_URL + '/img/purple_tag.png'}
                                      className='me-2'
                                      alt=''
                                    />
                                  </i>
                                  142
                                </div>
                                <div className='mb-2'>
                                  <i>
                                    <img
                                      src={process.env.PUBLIC_URL + '/img/frandss.png'}
                                      className='me-2'
                                      alt=''
                                    />
                                  </i>
                                  149
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='col-md-6'>
                            <h6 className='varela'>Eddy Jones </h6>
                            <div className='frnd_box'>
                              <div className='img_block green_border'></div>
                              <div className=''>
                                <div className='mb-2'>
                                  <i>
                                    <img
                                      src={process.env.PUBLIC_URL + '/img/purple_tag.png'}
                                      className='me-2'
                                      alt=''
                                    />
                                  </i>
                                  142
                                </div>
                                <div className='mb-2'>
                                  <i>
                                    <img
                                      src={process.env.PUBLIC_URL + '/img/frandss.png'}
                                      className='me-2'
                                      alt=''
                                    />
                                  </i>
                                  149
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='col-md-6'>
                            <h6 className='varela'>Eddy Jones </h6>
                            <div className='frnd_box'>
                              <div className='img_block green_border'></div>
                              <div className=''>
                                <div className='mb-2'>
                                  <i>
                                    <img
                                      src={process.env.PUBLIC_URL + '/img/purple_tag.png'}
                                      className='me-2'
                                      alt=''
                                    />
                                  </i>
                                  142
                                </div>
                                <div className='mb-2'>
                                  <i>
                                    <img
                                      src={process.env.PUBLIC_URL + '/img/frandss.png'}
                                      className='me-2'
                                      alt=''
                                    />
                                  </i>
                                  149
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* friends green over */}

                      <div className='business_blue mt-4'>
                        <div className='d-flex headers justify-content-between'>
                          <h6>business - 37</h6>
                          <h6>24 mutual</h6>
                        </div>

                        <div className='row profiles'>
                          <div className='col-md-6'>
                            <h6 className='varela'>Eddy Jones</h6>
                            <div className='frnd_box'>
                              <div className='img_block   blue_border'></div>
                              <div className=''>
                                <div className='mb-2'>
                                  <i>
                                    <img
                                      src={process.env.PUBLIC_URL + '/img/orange_tag.png'}
                                      className='me-2'
                                      alt=''
                                    />
                                  </i>
                                  142
                                </div>
                                <div className='mb-2'>
                                  <i>
                                    <img
                                      src={process.env.PUBLIC_URL + '/img/frandss.png'}
                                      className='me-2'
                                      alt=''
                                    />
                                  </i>
                                  149
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='col-md-6'>
                            <h6 className='varela'>Eddy Jones</h6>
                            <div className='frnd_box'>
                              <div className='img_block   blue_border'></div>
                              <div className=''>
                                <div className='mb-2'>
                                  <i>
                                    <img
                                      src={process.env.PUBLIC_URL + '/img/orange_tag.png'}
                                      className='me-2'
                                      alt=''
                                    />
                                  </i>
                                  142
                                </div>
                                <div className='mb-2'>
                                  <i>
                                    <img
                                      src={process.env.PUBLIC_URL + '/img/frandss.png'}
                                      className='me-2'
                                      alt=''
                                    />
                                  </i>
                                  149
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='col-md-6'>
                            <h6 className='varela'>Eddy Jones</h6>
                            <div className='frnd_box'>
                              <div className='img_block   blue_border'></div>
                              <div className=''>
                                <div className='mb-2'>
                                  <i>
                                    <img
                                      src={process.env.PUBLIC_URL + '/img/orange_tag.png'}
                                      className='me-2'
                                      alt=''
                                    />
                                  </i>
                                  142
                                </div>
                                <div className='mb-2'>
                                  <i>
                                    <img
                                      src={process.env.PUBLIC_URL + '/img/frandss.png'}
                                      className='me-2'
                                      alt=''
                                    />
                                  </i>
                                  149
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='col-md-6'>
                            <h6 className='varela'>Eddy Jones</h6>
                            <div className='frnd_box'>
                              <div className='img_block  blue_border'></div>
                              <div className=''>
                                <div className='mb-2'>
                                  <i>
                                    <img
                                      src={process.env.PUBLIC_URL + '/img/orange_tag.png'}
                                      className='me-2'
                                      alt=''
                                    />
                                  </i>
                                  142
                                </div>
                                <div className='mb-2'>
                                  <i>
                                    <img
                                      src={process.env.PUBLIC_URL + '/img/frandss.png'}
                                      className='me-2'
                                      alt=''
                                    />
                                  </i>
                                  149
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* business blue over */}
                      <div className='organization_orange mt-4'>
                        <div className='d-flex headers justify-content-between'>
                          <h6>ORGANIZATIONS - 89</h6>
                          <h6>12 mutual</h6>
                        </div>

                        <div className='row profiles'>
                          <div className='col-md-6'>
                            <h6 className='varela'>Eddy Jones</h6>
                            <div className='frnd_box'>
                              <div className='img_block orange_border'></div>
                              <div className=''>
                                <div className='mb-2'>
                                  <i>
                                    <img
                                      src={process.env.PUBLIC_URL + '/img/orange_tag.png'}
                                      className='me-2'
                                      alt=''
                                    />
                                  </i>
                                  142
                                </div>
                                <div className='mb-2'>
                                  <i>
                                    <img
                                      src={process.env.PUBLIC_URL + '/img/frandss.png'}
                                      className='me-2'
                                      alt=''
                                    />
                                  </i>
                                  149
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='col-md-6'>
                            <h6 className='varela'>Eddy Jones</h6>
                            <div className='frnd_box'>
                              <div className='img_block orange_border'></div>
                              <div className=''>
                                <div className='mb-2'>
                                  <i>
                                    <img
                                      src={process.env.PUBLIC_URL + '/img/orange_tag.png'}
                                      className='me-2'
                                      alt=''
                                    />
                                  </i>
                                  142
                                </div>
                                <div className='mb-2'>
                                  <i>
                                    <img
                                      src={process.env.PUBLIC_URL + '/img/frandss.png'}
                                      className='me-2'
                                      alt=''
                                    />
                                  </i>
                                  149
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* organization over */}
                    </div>

                    <div className='bg-white curvey mb-4 p-15'>
                      <img
                        src={process.env.PUBLIC_URL + '/img/image.png'}
                        className='img-fluid'
                        alt=''
                      />
                    </div>
                  </div>
                  {/* overview left over */}
                  <div className='col-md-8 overview-right'>
                    <div className='bg-white'>
                      <div className='row contact_blk'>
                        <div className='col-md-7 contact_content'>
                          <div className=''>
                            <h4 className='varela'>Company Details</h4>
                            <div className='contact'>
                              150 Briarwood Drive
                              <br /> Goldsboro, NC 27530
                            </div>
                            <div className='phone'>
                              <a href={null}>(954)555-5555 (Phone Day)</a>
                              <a href={null}>(954)555-5555 (Phone Evening)</a>
                              <a href={null}>(954)555-5555 (Mobile)</a>
                            </div>
                            <div className='email'>
                              <a href={null}>ed@edmachomes.com</a>
                              <a href={null}>edmac@gmail.com</a>
                            </div>
                            <div className='site'>
                              <a href={null}>www.edmachomes.com</a>
                            </div>
                          </div>
                        </div>
                        <div className='col-md-5 map'>
                          <div className='map_blk'>
                            <h4 className='varela'>Map</h4>
                            <div id='googlemap'></div>
                          </div>
                        </div>
                      </div>

                      <div className='contact_cat'>
                        <p>
                          <strong>Categories</strong> Real Estate, Real Estate Services, Realtor,
                          Real Estate Consultants... and 3 more
                        </p>
                        <p>
                          <strong>Cities</strong> Hermosa Beach, Rodondo Beach, Los Angeles
                        </p>
                      </div>

                      <div className='page_info'>
                        <h3 className='varela'>About Us</h3>
                        <p>
                          Fusce eget quam urna. Aliquam at lobortis velit. Pellentesque habitant
                          morbi tristique senectus et netus et malesuada fames ac turpis egestas.
                          Sed non rutrum purus. Aliquam at mollis neque. Nunc dapibus venenatis est
                          id tincidunt. Curabitur consectetur rutrum vehicula. Morbi vitae nibh
                          libero. Phasellus malesuada dui massa, vel ornare nulla congue cursus.
                          Proin eget metus vestibulum lacus cursus vehicula. Etiam posuere augue
                          risus, et varius enim aliquet id. Interdum et malesuada fames ac ante
                          ipsum primis in faucibus. Nunc congue, orci nec ultrices rutrum, urna enim
                          molestie sem, a porta nunc lectus eu quam. Vestibulum a est id orci
                          finibus maximus. Aliquam suscipit leo quis elit bibendum maximus. Nam quis
                          est dapibus, rhoncus nulla eget, mattis dui.
                        </p>
                      </div>
                      <div className='page_info'>
                        <h3 className='varela'>Gallery</h3>
                        <p></p>
                      </div>
                      <div className='page_info'>
                        <h3 className='varela'>
                          Reviews
                          <a href={null} className='blue'>
                            178
                          </a>
                        </h3>
                        <div className='review_blk'>
                          <div className='img_blk blue_border'></div>
                          <div className='name_blk'>
                            <h5 className='varela'>Jacob Holmes</h5>
                            <ul>
                              <li className='friends_review'>100 friends</li>
                              <li className='star_review'>25 reviews</li>
                              <li className='trust_review'>180 iTRUST</li>
                            </ul>
                          </div>
                          <div className='comment_blk'>
                            <div className='right_link'>
                              <div className='star_ratings'>
                                <img src={process.env.PUBLIC_URL + '/img/star_yellow.png'} alt='' />
                                <img src={process.env.PUBLIC_URL + '/img/star_yellow.png'} alt='' />
                                <img src={process.env.PUBLIC_URL + '/img/star_yellow.png'} alt='' />
                                <img src={process.env.PUBLIC_URL + '/img/star_yellow.png'} alt='' />
                                <img src={process.env.PUBLIC_URL + '/img/star_yellow.png'} alt='' />
                              </div>
                              <div className='review_digit varela'>
                                <a href={null}>November 6, 2015</a>
                              </div>
                            </div>
                            <p>
                              Mauris non urna eu nisi vulputate fringilla sit amet vel velit. Ut
                              tempus id magna nec finibus. Suspendisse eget lorem ut nunc congue
                              ultrices. Nullam quis porttitor massa, sit amet gravida
                            </p>
                          </div>
                        </div>
                        <div className='review_blk'>
                          <div className='img_blk'></div>
                          <div className='name_blk'>
                            <h5 className='varela'>Jacob Holmes</h5>
                            <ul>
                              <li className='friends_review'>100 friends</li>
                              <li className='star_review'>25 reviews</li>
                              <li className='trust_review'>180 iTRUST</li>
                            </ul>
                          </div>
                          <div className='comment_blk'>
                            <div className='right_link'>
                              <div className='star_ratings'>
                                <img src={process.env.PUBLIC_URL + '/img/star_yellow.png'} alt='' />
                                <img src={process.env.PUBLIC_URL + '/img/star_yellow.png'} alt='' />
                                <img src={process.env.PUBLIC_URL + '/img/star_yellow.png'} alt='' />
                                <img src={process.env.PUBLIC_URL + '/img/star_yellow.png'} alt='' />
                                <img src={process.env.PUBLIC_URL + '/img/star_yellow.png'} alt='' />
                              </div>
                              <div className='review_digit varela'>
                                <a href={null}>November 6, 2015</a>
                              </div>
                            </div>
                            <p>
                              Mauris non urna eu nisi vulputate fringilla sit amet vel velit. Ut
                              tempus id magna nec finibus. Suspendisse eget lorem ut nunc congue
                              ultrices. Nullam quis porttitor massa, sit amet gravida
                            </p>
                          </div>
                        </div>
                        <div className='review_blk'>
                          <div className='img_blk'></div>
                          <div className='name_blk'>
                            <h5 className='varela'>Jacob Holmes</h5>
                            <ul>
                              <li className='friends_review'>100 friends</li>
                              <li className='star_review'>25 reviews</li>
                              <li className='trust_review'>180 iTRUST</li>
                            </ul>
                          </div>
                          <div className='comment_blk'>
                            <div className='right_link'>
                              <div className='star_ratings'>
                                <img src={process.env.PUBLIC_URL + '/img/star_yellow.png'} alt='' />
                                <img src={process.env.PUBLIC_URL + '/img/star_yellow.png'} alt='' />
                                <img src={process.env.PUBLIC_URL + '/img/star_yellow.png'} alt='' />
                                <img src={process.env.PUBLIC_URL + '/img/star_yellow.png'} alt='' />
                                <img src={process.env.PUBLIC_URL + '/img/star_yellow.png'} alt='' />
                              </div>
                              <div className='review_digit varela'>
                                <a href={null}>November 6, 2015</a>
                              </div>
                            </div>
                            <p>
                              Mauris non urna eu nisi vulputate fringilla sit amet vel velit. Ut
                              tempus id magna nec finibus. Suspendisse eget lorem ut nunc congue
                              ultrices. Nullam quis porttitor massa, sit amet gravida
                            </p>
                          </div>
                        </div>

                        <a href={null} className='blue reviewlink varela'>
                          {' '}
                          Read all reviews{' '}
                        </a>
                      </div>
                    </div>
                  </div>
                  {/* overview right over */}
                </div>
              </div>
              <div id='trust' className='tab-pane fade'>
                <div className='trust_blk_head'>
                  <h4 className='varela'>Trust List (334)</h4>
                  <div className='trust_right'>
                    <div>View:</div>
                    <ul className='views'>
                      <li>
                        <a href={null}>
                          <i className='list-view'></i>List{' '}
                        </a>
                      </li>
                      <li>
                        <a href={null} className='active'>
                          <i className='full-view '></i>Full{' '}
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className='bg-white'>
                  <div className='friend_list_block purple'>
                    <div className='f-left'>
                      <ul className='inner-navs nav nav-tabs'>
                        <li className='nav-item'>
                          <a className='nav-link active' data-bs-toggle='tab' href='#itrust'>
                            <span>iTRUST</span>
                          </a>
                        </li>
                        <li className='nav-item'>
                          <a className='nav-link' data-bs-toggle='tab' href='#itrust_business'>
                            <span>300 BUSINESSES</span>
                          </a>
                        </li>
                        <li className='nav-item'>
                          <a className='nav-link' data-bs-toggle='tab' href='#itrust_prganization'>
                            <span>40 ORGANIZATIONS</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className='f-right'>
                      <form>
                        <input
                          type='text'
                          className='input1 form-control'
                          placeholder='Search for a person,  business, or organization...'
                        />
                        <input type='submit' className='search' value='' />
                      </form>
                    </div>
                  </div>

                  {/* inner tab start */}
                  <div className='inner-tab tab-content it-mobile-space'>
                    <div id='itrust' className='tab-pane active'>
                      <div className='d-flex blocks'>
                        <div className='col-lg-8'>
                          <div className='itrust-tab-1'>
                            <div className='pro_name_detail'>
                              <div className='p-4 d-flex'>
                                <div className='pro_1'>
                                  <img src={process.env.PUBLIC_URL + '/img/userimg.jpg'} alt='' />
                                </div>
                                <div className='pro_name'>
                                  <h3 className='blue'>Mangrove Realty</h3>
                                  <div>
                                    <ul className='contact'>
                                      <li className='address'>
                                        150 Briarwood Drive
                                        <br /> Goldsboro, NC 27530
                                      </li>
                                      <li className='phone'>
                                        <a href={null}>(954)555-5555</a>
                                      </li>
                                    </ul>
                                  </div>
                                  <div className='design_action'>
                                    <a href={null} className='orange'>
                                      iTRUSTit
                                    </a>
                                    <a href={null} className='green'>
                                      {' '}
                                      + Friend
                                    </a>
                                  </div>
                                </div>
                              </div>
                              <div className='d-flex trust_sec_bottom'>
                                <div className='categories'>
                                  <p>
                                    <strong>Categories</strong>&nbsp;
                                    <a href={null}>Real Estate</a>,
                                    <a href={null}>Real Estate Services</a>,
                                    <a href={null}>Realtor</a>,
                                    <a href={null}> Real Estate Consultants</a>
                                  </p>
                                  <p>
                                    <strong>Cities</strong>&nbsp;Hermosa Beach, Rodondo Beach, Los
                                    Angeles
                                  </p>
                                </div>
                                <div className='star_ratings'>
                                  <div className='stars d-flex'>
                                    <div>
                                      <img
                                        src={process.env.PUBLIC_URL + '/img/review_star_yellow.png'}
                                        alt=''
                                      />
                                    </div>
                                    <div>
                                      <img
                                        src={process.env.PUBLIC_URL + '/img/review_star_grey.png'}
                                        alt=''
                                      />
                                    </div>
                                    <div>
                                      <img
                                        src={process.env.PUBLIC_URL + '/img/review_star_grey.png'}
                                        alt=''
                                      />
                                    </div>
                                    <div>
                                      <img
                                        src={process.env.PUBLIC_URL + '/img/review_star_grey.png'}
                                        alt=''
                                      />
                                    </div>
                                    <div>
                                      <img
                                        src={process.env.PUBLIC_URL + '/img/review_star_grey.png'}
                                        alt=''
                                      />
                                    </div>
                                    <a href={null} className='varela'>
                                      178 reviews
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className='badge'>
                              <div className='b-triangle'></div>
                              <div className='b-inner'>
                                <h4>Trusted by</h4>
                                <div className='d-flex d1'>
                                  <div className='col-4 digits_cat'>
                                    <div className='digit'>20</div>
                                    <div className='digit_name'>friends</div>
                                  </div>
                                  <div className='col-4 digits_cat'>
                                    <div className='digit'>20</div>
                                    <div className='digit_name'>friends of friends</div>
                                  </div>
                                  <div className='col-4 digits_cat'>
                                    <div className='digit'>68</div>
                                    <div className='digit_name'>total</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='itrust-tab-1 sponsered'>
                            <div className='pro_name_detail'>
                              <div className='p-4 d-flex'>
                                <div className='pro_1'>
                                  <img src={process.env.PUBLIC_URL + '/img/userimg.jpg'} alt='' />
                                </div>
                                <div className='pro_name'>
                                  <h3 className='blue'>Mangrove Realty</h3>
                                  <div>
                                    <ul className='contact'>
                                      <li className='address'>
                                        150 Briarwood Drive
                                        <br /> Goldsboro, NC 27530
                                      </li>
                                      <li className='phone'>
                                        <a href={null}>(954)555-5555</a>
                                      </li>
                                    </ul>
                                  </div>
                                  <div className='design_action'>
                                    <a href={null} className='orange'>
                                      iTRUSTit
                                    </a>
                                    <a href={null} className='green'>
                                      {' '}
                                      + Friend
                                    </a>
                                  </div>
                                </div>
                              </div>
                              <div className='d-flex trust_sec_bottom'>
                                <div className='categories'>
                                  <p>
                                    <strong>Categories</strong>&nbsp;
                                    <a href={null}>Real Estate</a>,
                                    <a href={null}>Real Estate Services</a>,
                                    <a href={null}>Realtor</a>,
                                    <a href={null}> Real Estate Consultants</a>
                                  </p>
                                  <p>
                                    <strong>Cities</strong>&nbsp;Hermosa Beach, Rodondo Beach, Los
                                    Angeles
                                  </p>
                                </div>
                                <div className='star_ratings'>
                                  <div className='stars d-flex'>
                                    <div>
                                      <img
                                        src={process.env.PUBLIC_URL + '/img/review_star_yellow.png'}
                                        alt=''
                                      />
                                    </div>
                                    <div>
                                      <img
                                        src={process.env.PUBLIC_URL + '/img/review_star_grey.png'}
                                        alt=''
                                      />
                                    </div>
                                    <div>
                                      <img
                                        src={process.env.PUBLIC_URL + '/img/review_star_grey.png'}
                                        alt=''
                                      />
                                    </div>
                                    <div>
                                      <img
                                        src={process.env.PUBLIC_URL + '/img/review_star_grey.png'}
                                        alt=''
                                      />
                                    </div>
                                    <div>
                                      <img
                                        src={process.env.PUBLIC_URL + '/img/review_star_grey.png'}
                                        alt=''
                                      />
                                    </div>
                                    <a href={null} className='varela'>
                                      178 reviews
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className='badge'>
                              <div className='b-triangle'></div>
                              <div className='b-inner'>
                                <h4>Trusted by</h4>
                                <div className='d-flex d1'>
                                  <div className='col-4 digits_cat'>
                                    <div className='digit'>20</div>
                                    <div className='digit_name'>friends</div>
                                  </div>
                                  <div className='col-4 digits_cat'>
                                    <div className='digit'>20</div>
                                    <div className='digit_name'>friends of friends</div>
                                  </div>
                                  <div className='col-4 digits_cat'>
                                    <div className='digit'>68</div>
                                    <div className='digit_name'>total</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* ------------------------------------------- itrust over------------------------------------------- */}
                    <div id='itrust_business' className=' tab-pane fade'></div>
                    {/* ------------------------------------------- itrust business over------------------------------------------- */}
                    <div id='itrust_organization' className=' tab-pane fade'></div>
                    {/* ------------------------------------------- gallery_videos over------------------------------------------- */}
                  </div>
                  {/* ------------------------------------------- inner tab over------------------------------------------- */}
                </div>
              </div>
              {/* trust over */}
              <div id='gallery' className='tab-pane fade'>
                <h4 className='varela'>Gallery for Gallie for Kids</h4>
                <div className='bg-white'>
                  <div className='friend_list_block'>
                    <div className='f-left'>
                      <ul className='gallery_tab inner-navs nav nav-tabs'>
                        <li className='nav-item'>
                          <a className='nav-link active' data-bs-toggle='tab' href='#gallery_all'>
                            <span>ALL (50)</span>
                          </a>
                        </li>
                        <li className='nav-item'>
                          <a className='nav-link' data-bs-toggle='tab' href='#gallery_pictures'>
                            <span>PICTURES (40)</span>
                          </a>
                        </li>
                        <li className='nav-item'>
                          <a className='nav-link' data-bs-toggle='tab' href='#gallery_videos'>
                            <span>VIDEOS (10)</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/* inner tab start */}
                  <div className=' inner-tab tab-content'>
                    <div id='gallery_all' className='tab-pane active'>
                      <ul className='gallery_list'>
                        <li>
                          <div className='gallery_inner'>
                            <a href={null}>
                              <img src={process.env.PUBLIC_URL + '/img/pic.jpg'} alt='' />
                            </a>
                          </div>
                        </li>
                        <li>
                          <div className='gallery_inner'>
                            <a href={null}>
                              <img src={process.env.PUBLIC_URL + '/img/pic.jpg'} alt='' />
                            </a>
                          </div>
                        </li>
                        <li>
                          <div className='gallery_inner'>
                            <a href={null}>
                              <img src={process.env.PUBLIC_URL + '/img/pic.jpg'} alt='' />
                              <span className='play'></span>
                            </a>
                          </div>
                        </li>
                        <li>
                          <div className='gallery_inner'>
                            <a href={null}>
                              <img src={process.env.PUBLIC_URL + '/img/pic.jpg'} alt='' />
                            </a>
                          </div>
                        </li>
                        <li>
                          <div className='gallery_inner'>
                            <a href={null}>
                              <img src={process.env.PUBLIC_URL + '/img/pic.jpg'} alt='' />
                            </a>
                          </div>
                        </li>
                        <li>
                          <div className='gallery_inner'>
                            <a href={null}>
                              <img src={process.env.PUBLIC_URL + '/img/pic.jpg'} alt='' />
                            </a>
                          </div>
                        </li>
                        <li>
                          <div className='gallery_inner'>
                            <a href={null}>
                              <img src={process.env.PUBLIC_URL + '/img/pic.jpg'} alt='' />
                              <span className='play'></span>
                            </a>
                          </div>
                        </li>
                        <li>
                          <div className='gallery_inner'>
                            <a href={null}>
                              <img src={process.env.PUBLIC_URL + '/img/pic.jpg'} alt='' />
                            </a>
                          </div>
                        </li>
                        <li>
                          <div className='gallery_inner'>
                            <a href={null}>
                              <img src={process.env.PUBLIC_URL + '/img/pic.jpg'} alt='' />
                            </a>
                          </div>
                        </li>
                        <li>
                          <div className='gallery_inner'>
                            <a href={null}>
                              <img src={process.env.PUBLIC_URL + '/img/pic.jpg'} alt='' />
                            </a>
                          </div>
                        </li>
                        <li>
                          <div className='gallery_inner'>
                            <a href={null}>
                              <img src={process.env.PUBLIC_URL + '/img/pic.jpg'} alt='' />
                              <span className='play'></span>
                            </a>
                          </div>
                        </li>
                        <li>
                          <div className='gallery_inner'>
                            <a href={null}>
                              <img src={process.env.PUBLIC_URL + '/img/pic.jpg'} alt='' />
                            </a>
                          </div>
                        </li>
                        <li>
                          <div className='gallery_inner'>
                            <a href={null}>
                              <img src={process.env.PUBLIC_URL + '/img/pic.jpg'} alt='' />
                            </a>
                          </div>
                        </li>
                        <li>
                          <div className='gallery_inner'>
                            <a href={null}>
                              <img src={process.env.PUBLIC_URL + '/img/pic.jpg'} alt='' />
                              <span className='play'></span>
                            </a>
                          </div>
                        </li>
                        <li>
                          <div className='gallery_inner'>
                            <a href={null}>
                              <img src={process.env.PUBLIC_URL + '/img/pic.jpg'} alt='' />
                            </a>
                          </div>
                        </li>
                        <li>
                          <div className='gallery_inner'>
                            <a href={null}>
                              <img src={process.env.PUBLIC_URL + '/img/pic.jpg'} alt='' />
                            </a>
                          </div>
                        </li>
                      </ul>
                    </div>
                    {/* gallery_all over */}
                    <div id='gallery_pictures' className=' tab-pane fade'>
                      <ul className='gallery_list'>
                        <li>
                          <div className='gallery_inner'>
                            <a href={null}>
                              <img src={process.env.PUBLIC_URL + '/img/pic.jpg'} alt='' />
                            </a>
                          </div>
                        </li>
                        <li>
                          <div className='gallery_inner'>
                            <a href={null}>
                              <img src={process.env.PUBLIC_URL + '/img/pic.jpg'} alt='' />
                            </a>
                          </div>
                        </li>
                        <li>
                          <div className='gallery_inner'>
                            <a href={null}>
                              <img src={process.env.PUBLIC_URL + '/img/pic.jpg'} alt='' />
                            </a>
                          </div>
                        </li>
                        <li>
                          <div className='gallery_inner'>
                            <a href={null}>
                              <img src={process.env.PUBLIC_URL + '/img/pic.jpg'} alt='' />
                            </a>
                          </div>
                        </li>
                        <li>
                          <div className='gallery_inner'>
                            <a href={null}>
                              <img src={process.env.PUBLIC_URL + '/img/pic.jpg'} alt='' />
                            </a>
                          </div>
                        </li>
                        <li>
                          <div className='gallery_inner'>
                            <a href={null}>
                              <img src={process.env.PUBLIC_URL + '/img/pic.jpg'} alt='' />
                            </a>
                          </div>
                        </li>
                      </ul>
                    </div>
                    {/* gallery_pictures over */}
                    <div id='gallery_videos' className=' tab-pane fade'>
                      <ul className='gallery_list'>
                        <li>
                          <div className='gallery_inner'>
                            <a href={null}>
                              <img src={process.env.PUBLIC_URL + '/img/pic.jpg'} alt='' />
                              <span className='play'></span>
                            </a>
                          </div>
                        </li>
                        <li>
                          <div className='gallery_inner'>
                            <a href={null}>
                              <img src={process.env.PUBLIC_URL + '/img/pic.jpg'} alt='' />
                              <span className='play'></span>
                            </a>
                          </div>
                        </li>
                        <li>
                          <div className='gallery_inner'>
                            <a href={null}>
                              <img src={process.env.PUBLIC_URL + '/img/pic.jpg'} alt='' />
                              <span className='play'></span>
                            </a>
                          </div>
                        </li>
                        <li>
                          <div className='gallery_inner'>
                            <a href={null}>
                              <img src={process.env.PUBLIC_URL + '/img/pic.jpg'} alt='' />
                              <span className='play'></span>
                            </a>
                          </div>
                        </li>
                        <li>
                          <div className='gallery_inner'>
                            <a href={null}>
                              <img src={process.env.PUBLIC_URL + '/img/pic.jpg'} alt='' />
                              <span className='play'></span>
                            </a>
                          </div>
                        </li>
                        <li>
                          <div className='gallery_inner'>
                            <a href={null}>
                              <img src={process.env.PUBLIC_URL + '/img/pic.jpg'} alt='' />
                              <span className='play'></span>
                            </a>
                          </div>
                        </li>
                        <li>
                          <div className='gallery_inner'>
                            <a href={null}>
                              <img src={process.env.PUBLIC_URL + '/img/pic.jpg'} alt='' />
                              <span className='play'></span>
                            </a>
                          </div>
                        </li>
                        <li>
                          <div className='gallery_inner'>
                            <a href={null}>
                              <img src={process.env.PUBLIC_URL + '/img/pic.jpg'} alt='' />
                              <span className='play'></span>
                            </a>
                          </div>
                        </li>
                        <li>
                          <div className='gallery_inner'>
                            <a href={null}>
                              <img src={process.env.PUBLIC_URL + '/img/pic.jpg'} alt='' />
                              <span className='play'></span>
                            </a>
                          </div>
                        </li>
                        <li>
                          <div className='gallery_inner'>
                            <a href={null}>
                              <img src={process.env.PUBLIC_URL + '/img/pic.jpg'} alt='' />
                              <span className='play'></span>
                            </a>
                          </div>
                        </li>
                      </ul>
                    </div>
                    {/* gallery_videos over */}
                  </div>
                  {/* inner tab over */}
                </div>
              </div>
              {/* gallery over */}
              <div id='activity' className='tab-pane fade'>
                <h4 className='varela'>Recent activity</h4>
                <div className='bg-white'>
                  <div className='activity_blk'>
                    <ul>
                      <li>
                        {' '}
                        You accepted
                        <a href={null}>Crossroads Motor Lodge</a> as a friend.
                      </li>
                      <li>
                        You reviewed
                        <a href={null}>Ed MacLaughlin</a> RE/MAX Estate Properties.
                      </li>
                      <li>
                        You added
                        <a href={null}>Gaslight Porperties GMAC</a> Real Estate to your Trust
                        Directory.
                      </li>
                      <li>
                        You followed
                        <a href={null}>Ed MacLaughlin</a>.
                      </li>
                      <li>
                        You Added Hyperspace Ventures to the iTRUSTit Unclaimed Business database.
                        Thank You!
                      </li>
                      <li>You Added zach hunter to the iTRUSTit Person database. Thank You!</li>
                      <li>You accepted Crossroads Motor Lodge as a friend.</li>
                      <li>You reviewed Ed MacLaughlin RE/MAX Estate Properties.</li>
                      <li>You added Gaslight Porperties GMAC Real Estate to your Trust List.</li>
                      <li>You followed Ed MacLaughlin.</li>
                      <li>
                        You Added Hyperspace Ventures to the iTRUSTit Unclaimed Business database.
                        Thank You!
                      </li>
                      <li>You Added zach hunter to the iTRUSTit Person database. Thank You!</li>
                      <li>You accepted Crossroads Motor Lodge as a friend.</li>
                      <li>You reviewed Ed MacLaughlin RE/MAX Estate Properties.</li>
                      <li>You added Gaslight Porperties GMAC Real Estate to your Trust List.</li>
                      <li>You followed Ed MacLaughlin.</li>
                      <li>
                        You Added Hyperspace Ventures to the iTRUSTit Unclaimed Business database.
                        Thank You!
                      </li>
                      <li>You Added zach hunter to the iTRUSTit Person database. Thank You!</li>
                      <li>You accepted Crossroads Motor Lodge as a friend.</li>
                      <li>You reviewed Ed MacLaughlin RE/MAX Estate Properties.</li>
                      <li>You added Gaslight Porperties GMAC Real Estate to your Trust List.</li>
                      <li>You followed Ed MacLaughlin.</li>
                      <li>
                        You Added Hyperspace Ventures to the iTRUSTit Unclaimed Business database.
                        Thank You!
                      </li>
                      <li>You Added zach hunter to the iTRUSTit Person database. Thank You!</li>
                      <li>You accepted Crossroads Motor Lodge as a friend.</li>
                      <li>You reviewed Ed MacLaughlin RE/MAX Estate Properties.</li>
                      <li>You added Gaslight Porperties GMAC Real Estate to your Trust List.</li>
                      <li>You followed Ed MacLaughlin.</li>
                      <li>
                        You Added Hyperspace Ventures to the iTRUSTit Unclaimed Business database.
                        Thank You!
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* activity over */}
              <div id='friend' className='tab-pane fade'>
                <h4 className='varela'>Friends (400)</h4>
                <div className='bg-white'>
                  <div className='friend_list_block'>
                    <div className='f-left'>
                      <ul className='inner-navs nav nav-tabs'>
                        <li className='nav-item'>
                          <a className='nav-link active' data-bs-toggle='tab' href='#friends'>
                            <span>400 FRIENDS</span>
                            <span className='mutual'>100 mutual</span>
                          </a>
                        </li>
                        <li className='nav-item'>
                          <a className='nav-link' data-bs-toggle='tab' href='#people'>
                            <span>300 PEOPLE</span>
                            <span className='mutual'>75 mutual</span>
                          </a>
                        </li>
                        <li className='nav-item'>
                          <a className='nav-link' data-bs-toggle='tab' href='#business'>
                            <span>50 BUSINESSES </span>
                            <span className='mutual'>15 mutual</span>
                          </a>
                        </li>
                        <li className='nav-item'>
                          <a className='nav-link' data-bs-toggle='tab' href='#organization'>
                            <span>50 ORGANIZATIONS</span>
                            <span className='mutual'>100 mutual</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className='f-right'>
                      <form>
                        <input
                          type='text'
                          className='input1 form-control'
                          placeholder='Search for a person,  business, or organization...'
                        />
                        <input type='submit' className='search' value='' />
                      </form>
                    </div>
                  </div>
                  {/* inner tab start */}
                  <div className='inner-tab tab-content'>
                    <div id='friends' className='tab-pane active'></div>
                    <div id='people' className=' tab-pane fade'>
                      <div className='friend_lists_1'>
                        <div className='row frnd_1'>
                          <div className='col-md-6 '>
                            <div className='frnd_box'>
                              <div className='img_block green_border'></div>
                              <div className=''>
                                <div className='tag'>142</div>
                                <div className='frandss'>149</div>
                              </div>
                              <div className='text_frnd'>
                                <h5>Eddy Jones</h5>
                                <div className='design_action'>
                                  <a href={null} className='green'>
                                    + Friend
                                  </a>
                                  <a href={null} className='chatgrey'></a>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='col-md-6 '>
                            <div className='frnd_box'>
                              <div className='img_block green_border'></div>
                              <div className=''>
                                <div className='tag'>142</div>
                                <div className='frandss'>149</div>
                              </div>
                              <div className='text_frnd'>
                                <h5>Eddy Jones</h5>
                                <div className='design_action'>
                                  <a href={null} className='green'>
                                    + Friend
                                  </a>
                                  <a href={null} className='chatgrey'></a>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='col-md-6 '>
                            <div className='frnd_box'>
                              <div className='img_block green_border'></div>
                              <div className=''>
                                <div className='tag'>142</div>
                                <div className='frandss'>149</div>
                              </div>
                              <div className='text_frnd'>
                                <h5>Eddy Jones</h5>
                                <div className='design_action'>
                                  <a href={null} className='green'>
                                    + Friend
                                  </a>
                                  <a href={null} className='chatgrey'></a>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='col-md-6 '>
                            <div className='frnd_box'>
                              <div className='img_block green_border'></div>
                              <div className=''>
                                <div className='tag'>142</div>
                                <div className='frandss'>149</div>
                              </div>
                              <div className='text_frnd'>
                                <h5>Eddy Jones</h5>
                                <div className='design_action'>
                                  <a href={null} className='green'>
                                    + Friend
                                  </a>
                                  <a href={null} className='chatgrey'></a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div id='business' className=' tab-pane fade'>
                      <div className='row frnd_1'>
                        <div className='col-md-6 '>
                          <div className='frnd_box'>
                            <div className='img_block blue_border'></div>
                            <div className=''>
                              <div className='tag'>142</div>
                              <div className='frandss'>149</div>
                            </div>
                            <div className='text_frnd'>
                              <h5>Mangrove Realty</h5>
                              <div className='design_action'>
                                <a href={null} className='orange'>
                                  + iTRUSTit
                                </a>
                                <a href={null} className='green'>
                                  + Friend
                                </a>
                                <a href={null} className='chatgrey'></a>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='col-md-6 '>
                          <div className='frnd_box'>
                            <div className='img_block blue_border'></div>
                            <div className=''>
                              <div className='tag'>142</div>
                              <div className='frandss'>149</div>
                            </div>
                            <div className='text_frnd'>
                              <h5>Sals Auto Care</h5>
                              <div className='design_action'>
                                <a href={null} className='orange'>
                                  + iTRUSTit
                                </a>
                                <a href={null} className='green'>
                                  + Friend
                                </a>
                                <a href={null} className='chatgrey'></a>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='col-md-6 '>
                          <div className='frnd_box'>
                            <div className='img_block blue_border'></div>
                            <div className=''>
                              <div className='tag'>142</div>
                              <div className='frandss'>149</div>
                            </div>
                            <div className='text_frnd'>
                              <h5>Mangrove Realty</h5>
                              <div className='design_action'>
                                <a href={null} className='orange'>
                                  + iTRUSTit
                                </a>
                                <a href={null} className='green'>
                                  + Friend
                                </a>
                                <a href={null} className='chatgrey'></a>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='col-md-6 '>
                          <div className='frnd_box'>
                            <div className='img_block blue_border'></div>
                            <div className=''>
                              <div className='tag'>142</div>
                              <div className='frandss'>149</div>
                            </div>
                            <div className='text_frnd'>
                              <h5>Sals Auto Care</h5>
                              <div className='design_action'>
                                <a href={null} className='orange'>
                                  + iTRUSTit
                                </a>
                                <a href={null} className='green'>
                                  + Friend
                                </a>
                                <a href={null} className='chatgrey'></a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* business over */}
                    <div id='organization' className=' tab-pane fade'>
                      <div className='row frnd_1'>
                        <div className='col-md-6 '>
                          <div className='frnd_box'>
                            <div className='img_block orange_border'></div>
                            <div className=''>
                              <div className='tag'>142</div>
                              <div className='frandss'>149</div>
                            </div>
                            <div className='text_frnd'>
                              <h5>Child Net</h5>
                              <div className='design_action'>
                                <a href={null} className='orange'>
                                  + iTRUSTit
                                </a>
                                <a href={null} className='green'>
                                  + Friend
                                </a>
                                <a href={null} className='chatgrey'></a>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='col-md-6 '>
                          <div className='frnd_box'>
                            <div className='img_block orange_border'></div>
                            <div className=''>
                              <div className='tag'>142</div>
                              <div className='frandss'>149</div>
                            </div>
                            <div className='text_frnd'>
                              <h5>Child Net</h5>
                              <div className='design_action'>
                                <a href={null} className='orange'>
                                  + iTRUSTit
                                </a>
                                <a href={null} className='green'>
                                  + Friend
                                </a>
                                <a href={null} className='chatgrey'></a>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='col-md-6 '>
                          <div className='frnd_box'>
                            <div className='img_block orange_border'></div>
                            <div className=''>
                              <div className='tag'>142</div>
                              <div className='frandss'>149</div>
                            </div>
                            <div className='text_frnd'>
                              <h5>Child Net</h5>
                              <div className='design_action'>
                                <a href={null} className='orange'>
                                  + iTRUSTit
                                </a>
                                <a href={null} className='green'>
                                  + Friend
                                </a>
                                <a href={null} className='chatgrey'></a>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='col-md-6 '>
                          <div className='frnd_box'>
                            <div className='img_block orange_border'></div>
                            <div className=''>
                              <div className='tag'>142</div>
                              <div className='frandss'>149</div>
                            </div>
                            <div className='text_frnd'>
                              <h5>Child Net</h5>
                              <div className='design_action'>
                                <a href={null} className='orange'>
                                  + iTRUSTit
                                </a>
                                <a href={null} className='green'>
                                  + Friend
                                </a>
                                <a href={null} className='chatgrey'></a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* organization over */}
                  </div>
                  {/* inner tab over */}
                </div>
              </div>
              {/* members over */}
              <div id='invite_center' className='tab-pane active'>
                <h4 className='varela'>Invite Center</h4>
                <div className='bg-white'>
                  <div className='invite_blk'>
                    <p className='big_1 varela'>iTRUSTit is better with your friends!</p>
                    <div className='social_blk_invite'>
                      <div className='invite_list'>
                        <p>
                          Find friends already using iTRUSTit via other networks around the web.
                        </p>
                        <ul className='list_of_social row'>
                          <li className='col-md-4'>
                            <div className='social_item'>
                              <a href={null}>
                                <img src={process.env.PUBLIC_URL + '/img/fb_invite.png'} alt='' />
                              </a>
                            </div>
                          </li>
                          <li className='col-md-4'>
                            <div className='social_item'>
                              <a href={null}>
                                <img
                                  src={process.env.PUBLIC_URL + '/img/linkedin_invite.png'}
                                  alt=''
                                />
                              </a>
                            </div>
                          </li>
                          <li className='col-md-4'>
                            <div className='social_item'>
                              <a href={null}>
                                <img src={process.env.PUBLIC_URL + '/img/twitter.png'} alt='' />
                              </a>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div className='invite_list'>
                        <p>Share iTRUSTit via other networks around the web.</p>
                        <ul className='list_of_social row'>
                          <li className='col-md-4'>
                            <div className='social_item'>
                              <a href={null}>
                                <img src={process.env.PUBLIC_URL + '/img/fb_invite.png'} alt='' />
                              </a>
                            </div>
                          </li>
                          <li className='col-md-4'>
                            <div className='social_item'>
                              <a href={null}>
                                <img
                                  src={process.env.PUBLIC_URL + '/img/linkedin_invite.png'}
                                  alt=''
                                />
                              </a>
                            </div>
                          </li>
                          <li className='col-md-4'>
                            <div className='social_item'>
                              <a href={null}>
                                <img src={process.env.PUBLIC_URL + '/img/twitter.png'} alt='' />
                              </a>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div className='invite_list'>
                        <p>Or invite your friends using their email address.</p>
                        <ul className='list_of_social row'>
                          <li className='col-md-3'>
                            <div className='social_item'>
                              <a href={null}>
                                <img src={process.env.PUBLIC_URL + '/img/gmail.png'} alt='' />
                              </a>
                            </div>
                          </li>
                          <li className='col-md-3'>
                            <div className='social_item'>
                              <a href={null}>
                                <img src={process.env.PUBLIC_URL + '/img/yahoo.png'} alt='' />
                              </a>
                            </div>
                          </li>
                          <li className='col-md-3'>
                            <div className='social_item'>
                              <a href={null}>
                                <img src={process.env.PUBLIC_URL + '/img/email.png'} alt='' />
                              </a>
                            </div>
                          </li>
                          <li className='col-md-3'>
                            <div className='social_item'>
                              <a href={null}>
                                <img src={process.env.PUBLIC_URL + '/img/joker.png'} alt='' />
                              </a>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div className='invite_list'>
                        <p>Or invite your friends using their email address.</p>
                        <ul className='list_of_social row'>
                          <li className='col-md-12'>
                            <div className='social_item bottom'>
                              <p>
                                Join me on iTRUSTit to see all the businesses and organizations I
                                trust to refer to my friends! www.itrustit.com/ed
                              </p>
                              <div>
                                <a href={null} className='blue_btn'>
                                  Click to play
                                </a>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                {/*--------------share of facebook, twitter and linkedin start-----------------*/}
                <h4 className='varela'>Invite Center</h4>
                <div className='bg-white'>
                  <div className='invite_blk'>
                    <div className='social_upper'>
                      <div>
                        <a href={null}>
                          {' '}
                          <strong>&lt;</strong> back
                        </a>
                      </div>
                      <div>
                        <p className='varela'>Add people from your LinkedIn to iTRUSTit.</p>
                      </div>
                      <div>
                        {' '}
                        <img src={process.env.PUBLIC_URL + '/img/linkedin_invite.png'} alt='' />
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-md-4'>
                        <div className='frnd_box'>
                          <div className='img_block green_border'></div>
                          <div className=''>
                            <div className='tag'>142</div>
                            <div className='frandss'>149</div>
                          </div>
                          <div className='text_frnd'>
                            <h5>Eddy Jones</h5>
                            <div className='design_action'>
                              <a className='green'>+ Friend</a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='col-md-4'>
                        <div className='frnd_box'>
                          <div className='img_block green_border'></div>
                          <div className=''>
                            <div className='tag'>142</div>
                            <div className='frandss'>149</div>
                          </div>
                          <div className='text_frnd'>
                            <h5>Eddy Jones</h5>
                            <div className='design_action'>
                              <a className='green'>+ Friend</a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='col-md-4'>
                        <div className='frnd_box'>
                          <div className='img_block green_border'></div>
                          <div className=''>
                            <div className='tag'>142</div>
                            <div className='frandss'>149</div>
                          </div>
                          <div className='text_frnd'>
                            <h5>Eddy Jones</h5>
                            <div className='design_action'>
                              <a className='green'>+ Friend</a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='col-md-4'>
                        <div className='frnd_box'>
                          <div className='img_block green_border'></div>
                          <div className=''>
                            <div className='tag'>142</div>
                            <div className='frandss'>149</div>
                          </div>
                          <div className='text_frnd'>
                            <h5>Eddy Jones</h5>
                            <div className='design_action'>
                              <a className='green'>+ Friend</a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='col-md-4'>
                        <div className='frnd_box'>
                          <div className='img_block green_border'></div>
                          <div className=''>
                            <div className='tag'>142</div>
                            <div className='frandss'>149</div>
                          </div>
                          <div className='text_frnd'>
                            <h5>Eddy Jones</h5>
                            <div className='design_action'>
                              <a className='green'>+ Friend</a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='col-md-4'>
                        <div className='frnd_box'>
                          <div className='img_block green_border'></div>
                          <div className=''>
                            <div className='tag'>142</div>
                            <div className='frandss'>149</div>
                          </div>
                          <div className='text_frnd'>
                            <h5>Eddy Jones</h5>
                            <div className='design_action'>
                              <a className='green'>+ Friend</a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/*--------------share of facebook, twitter and linkedin- over----------------*/}
              </div>
            </div>
            {/* outer tab over */}
          </div>
        </section>

        <section className='footer_top_blk'>
          <div className='container'>
            <div className='footer_container'>
              <p className='varela'>
                Not here? Tell us what we're missing. If the business you're looking for isn't here,
                add it!
              </p>
              <a href={null} className='blue_btn'>
                ADD BUSINESS
              </a>
            </div>
          </div>
        </section>
      </AppLayout>
    )
  }
}
export default MyProfile
