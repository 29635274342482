import { toast } from 'react-toastify'
//import moment from "moment";
import * as moment from 'moment'
import { customLogger } from '../utils/CustomLogger'
import { ENABLE_SOCIAL_MEDIA } from '../services/environment'

const defaultMomentLocale = {
  future: 'in %s',
  past: '%s ago',
  s: 'a few seconds',
  ss: '%d seconds',
  m: 'a minute',
  mm: '%d minutes',
  h: 'an hour',
  hh: '%d hours',
  d: 'a day',
  dd: '%d days',
  w: 'a week',
  ww: '%d weeks',
  M: 'a month',
  MM: '%d months',
  y: 'a year',
  yy: '%d years',
}
const shorthandMomentLocal = {
  future: 'in %s',
  past: '%s ago',
  s: '1s',
  ss: '%ss',
  m: '1m',
  mm: '%dm',
  h: '1h',
  hh: '%dh',
  d: '1d',
  dd: '%dd',
  M: '1mo',
  MM: '%dmo',
  y: '1y',
  yy: '%dy',
}
export const toaster = (toastType, message) => {
  if (toastType === 'success') {
    toast.success(message, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 15000,
    })
  } else if (toastType === 'error') {
    toast.error(message, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 15000,
    })
  }
}

export const isUserLogin = () => {
  //var user = JSON.parse(window.localStorage.getItem("user"));
  var user = window.localStorage.getItem('user')
  if (user) {
    try {
      user = JSON.parse(user)
    } catch (error) {
      customLogger(error)
      user = false
    }
  }
  if (user) return true
  else return false
}

export const userToken = () => {
  return window.localStorage.getItem('itrustit_token')
}

export const otpToken = () => {
  return window.localStorage.getItem('otp_token')
}

export const setOtpToken = (data) => {
  return window.localStorage.setItem('otp_token', data)
}

export const setToken = (data) => {
  return window.localStorage.setItem('itrustit_token', data)
}

export const setUser = (data) => {
  setInviteMatrixId(data?.imatrix_id) //set the imatrix_id
  return window.localStorage.setItem('user', JSON.stringify(data))
}

export const removeUser = () => {
  return window.localStorage.removeItem('user')
}

export const removeOtpToken = () => {
  return window.localStorage.removeItem('otp_token')
}
export const removeToken = () => {
  return window.localStorage.removeItem('itrustit_token')
}

export const getUser = () => {
  return JSON.parse(window.localStorage.getItem('user'))
}

export const isAdmin = () => {
  return getUser()
    ?.roles?.filter?.((role) => role?.name === 'Admin')
    ?.filter?.(Boolean)?.length
}

export const getUserHistory = () => {
  return JSON.parse(window.localStorage.getItem('profile_history'))
}

export const dateStringToTimeSince = (date_str, short = false) => {
  moment.locale('en', { relativeTime: short ? shorthandMomentLocal : defaultMomentLocale })
  if (date_str) {
    return moment(date_str, 'YYYY-MM-DD HH:mm:ss').from(moment.utc().format('YYYY-MM-DD HH:mm:ss'))
  } else {
    return ''
  }
}

export const getInviteMatrixId = () => {
  return window.localStorage.getItem('invite_profile_imatrix_id')
}

export const setInviteMatrixId = (matrixId) => {
  if (matrixId) return window.localStorage.setItem('invite_profile_imatrix_id', matrixId)
}

export const removeInviteMatrixId = () => {
  return window.localStorage.removeItem('invite_profile_imatrix_id')
}

export const dateStringToFormatForActivity = (date_str) => {
  if (date_str) {
    return moment(date_str).format('MMMM D, YYYY, h:mm A')
  } else {
    return ''
  }
}

/**
 * Function that makes distinction between Customer and Owner adding business or organizations
 * @author   Amalia
 * @task      913,914
 * @param    {String} usertype  Customer or Owner or undefined
 * @return   {Object}         an objext that contains ditinct labels to be used for  Owner or Customer. If in future request we need need once that can just be simple added here
 */
export const getUserLabels = (usertype) => {
  let userTypeLabels = {}
  userTypeLabels.type = 'Owner'
  userTypeLabels.text = 'If you see your business here Click it'
  //do same check because of the render function whenadd_bussiness_or_organization is undefined.
  if (typeof usertype !== 'undefined' && usertype !== null) {
    if (usertype === 'Customer') {
      userTypeLabels.type = 'Customer'
      userTypeLabels.text = 'If you see the business here Click it'
    }
  }
  return userTypeLabels
}

/**
 * Function that makes distinction between first SignUp redirect SignUp of a business or organizations
 * @author   Amalia
 * @task      913,914
 * @param    {String} matrixId  SponsorID (empty when no rediect sign up)
 * @return   {Object}         an object that contains distinct labels for 1st or redirect Sign up
 */
export const getSignUpLabels = (matrixId, business_Type) => {
  let signUpLabels = {}
  if (typeof matrixId !== 'undefined' && matrixId !== null && matrixId === '') {
    signUpLabels.title = <h3 className='panel-title'>{business_Type} Sign Up</h3>
    signUpLabels.iTeamId = 'Sponsor'
  } else {
    signUpLabels.title = <h3 className='panel-title'>Add {business_Type} as Owner Step-4</h3>
    signUpLabels.iTeamId = 'Sponsor'
  }
  return signUpLabels
}

/**
 * Function that return the user details JSX (email)
 * @author   Amalia
 * @task     210716-8
 * @param    {Object} user    user object
 * @return   {JSX}            user details
 */
export const getSponsorDetails = (user) => {
  const sponsorDetails = (
    <>
      <p>
        {user.business_organization_name
          ? user.business_organization_name
          : user.firstname + ' ' + user.lastname}
      </p>
      <p>My Sponsor iTeam ID# is: {user.imatrix_id} </p>
    </>
  )
  return sponsorDetails
}
/**
 * Function that return the user details JSX (email)
 * @author   Amalia
 * @task     924
 * @param    {Object} user    user object
 * @param    {String} trustdImgUrl trust image(icon) location
 * @param    {String} friendImgUrl friend image(icon) location
 * @return   {JSX}            user details
 */
export const getFriendProfile = (user, trustdImgUrl, friendImgUrl) => {
  const sponsorDetails = (
    <table className='table_width50'>
      <tr>
        <td className='mb-2 table_width20'>
          <i>
            <img src={trustdImgUrl} className='me-2' alt='' />
          </i>
        </td>
        <td className='mb-2'>
          {user.user_type === 'individual' ? user.total_trusted_by_me : user.total_trusted_by}
        </td>
      </tr>
      <tr>
        <td className='mb-2 table_width20'>
          <i>
            <img src={friendImgUrl} className='me-2' alt='' />
          </i>
        </td>
        <td className='mb-2'>{user.total_friends}</td>
      </tr>
    </table>
  )
  return sponsorDetails
}
/**
 * Function validates the fieldName(undefined, null or less than 2chars)
 * returns true if valid, false if invalid and shows the given toaster message
 * @author   Amalia
 * @task     210913_em-01
 * @param    {String} fieldName     fieldName to be validated
 * @param    {String} errorMessage  errorMessage to be shown
 * @return   {boolean}              true if valid, false if invalid
 */
export const validateSearchInput = (fieldName, errorMessage) => {
  if (typeof fieldName === 'undefined' || fieldName === null || fieldName.trim().length < 2) {
    toaster('error', errorMessage)
    return false
  }
  return true
}

export const kFormatter = (num, fallback = '') => {
  const parsedNum = (Math.sign(num) * (Math.abs(num) / 1000)).toString().split('.')
  return num > 0
    ? Math.abs(num) > 999
      ? parsedNum[0] + (!!parsedNum?.[1] ? `.${parsedNum[1].substring(0, 2)}` : '') + 'k'
      : Math.sign(num) * Math.abs(num)
    : fallback
}

/**
 * Function will check if there is a user login or a default user set on windows.localstorge
 * returns true if valid, false if invalid and shows the given toaster message
 * @author   Amalia
 * @task     220103_em-01
 * @return   {string}         logged user, default sponsor or empty string
 */
export const getDefaultSponsor = () => {
  let defaultSponsor = ''
  if (getUser() && getUser().imatrix_id) defaultSponsor = getUser().imatrix_id
  else if (getInviteMatrixId()) defaultSponsor = getInviteMatrixId()
  return defaultSponsor
}

//groups elements by specific property
/**
 * Function will group elements by specific property
 * returns an object
 * @author   Amalia
 * @task     220118_ama-01
 * @param    {Array} array     array of objects
 * @param    {function} classifier  if not provide, simply counts different elements
 * @exemple1  no clasifier provided 
 * myArray=[1,2,2,2,3,1], 
 * call count(myArray), no clasifier provided => returns object
  {
   "1": 2,
   "2": 3,
   "3": 1
  }
*@exemple2 clasifier provided
reaction = [
    { user_id: 1, reaction_type: "curious", created_at: "" },
    { user_id: 2, reaction_type: "insightful", created_at: "" },
    { user_id: 3, reaction_type: "love", created_at: "" },
    { user_id: 4, reaction_type: "support", created_at: "" },
    { user_id: 5, reaction_type: "celebrate", created_at: "" },
    { user_id: 7, reaction_type: "like", created_at: "" },
    { user_id: 8, reaction_type: "like", created_at: "" },
    { user_id: 9, reaction_type: "like", created_at: "" },
    { user_id: 10, reaction_type: "like", created_at: "" },
  ];
  call: count(reaction, function(item) { return item.reaction_type }), clasifier is function(item) { return item.reaction_type } => returns object
  {
    celebrate: 1
    curious: 1
    insightful: 1
    like: 4
    love: 1
    support: 1
  }
 * @return   {array}         array of objects
 */
export const countOccurance = function (array, classifier) {
  console.log('array:', array)
  return array?.reduce(function (counter, item) {
    var p = (classifier || String)(item)
    counter[p] = counter.hasOwnProperty(p) ? counter[p] + 1 : 1
    return counter
  }, {})
}
/**
 * Function will return the appropiate className for a FB image grid, based on the number of files
 * and their index
 * @author   Amalia
 * @task     211108_ama-01
 * @param    {number} length    number of files
 * @param    {number} index     position of the file
 * @return   {string}           className
 */
export const getClassNameForGrid = function (length, index) {
  let className = ''
  length === 1
    ? (className = 'postImg postImg1 col')
    : length === 2
    ? (className = 'postImg postImg2 col')
    : length === 3
    ? index === 0
      ? (className = 'postImg postImg2 col')
      : (className = 'postImg postImg3 col')
    : length === 4
    ? (className = 'postImg postImg3 col')
    : index === 0 || index === 1
    ? (className = 'postImg postImg3 col')
    : (className = 'postImg postImg4 col')

  return className
}
/**
 * Function will replace URL with the HTML link if any URL on the text
 * @author   Amalia
 * @task     220118_ama-01
 * @param    {string} text   text to be checked
 * @return   {string}        same text with the HTML link
 */
export const replaceURLWithHTMLLinks = function (text) {
  var exp = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi
  let result = text.includes("<a href='$1'>$1</a>")
  console.log('exp:', exp, 'result:', result)
  let newstring = text.replace(exp, "<a href='$1'>$1</a>")
  console.log('newstring:', newstring)
  return newstring
}
/**
 * Function will check if URL is vld
 * @author   Amalia
 * @task     220118_ama-01
 * @param    {string} url     URL string
 * @return   {boolean}        true if URL valid, false otherwise
 */

export const validateURLWithoutSpace = (str) => {
  let result = false
  var pattern = new RegExp(
    '^((https?|ftp|file):\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i',
  ) // fragment locator
  if (str && str.trim() && !str?.includes(' ')) result = !!pattern.test(str)
  //console.log('!!pattern.test(str):', result)
  return result
}
/**
 * Function will check if user type and business type supports Post and for the time being also dible for PROD
 * @author   Amalia
 * @task     220515_em-01
 * @param    {user} user      user
 * @return   {boolean}        true if URL valid, false otherwise
 */

export const isPostTabVisible = (user) => {
  let result = true
  if (
    !ENABLE_SOCIAL_MEDIA ||
    (user?.user_type !== 'individual' && user?.is_business_claimed === '0')
  )
    result = false
  return result
}
export const setOverFlow = (value) => {
  if (value) {
    if (typeof window != 'undefined' && window.document) {
      document.body.style.overflow = 'hidden'
    }
  } else {
    document.body.style.overflow = 'unset'
  }
}
