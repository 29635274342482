import React, { useEffect } from 'react'
import $ from 'jquery'
import { Link } from 'react-router-dom'
import { LOGIN_ROUTE, PRIVACY_ROUTE, TERMS_ROUTE } from '../../services/routeConst'
import classNames from 'classnames'
import { Field, formValueSelector, reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import renderInputField from '../Form/Input'
import { required } from '../../services/formValidation'

export const showConfirmSocialSignupModal = () => {
  $('#confirm_social_signup_modal')?.modal?.('show')
}
export const hideConfirmSocialSignupModal = () => {
  $('#confirm_social_signup_modal')?.modal?.('hide')
}

let ConfirmSocialSignupModal = (props) => {
  const {
    isClaim,
    isAdd,
    data = {},
    handleSubmit,
    submitFailed,
    invalid,
    business_organization_name,
    userTypeOverride,
    busOrgName, //come from Claim
  } = props
  const { firstname, lastname, email, avatar, user_type } = data ?? {}
  const userType = userTypeOverride ?? user_type

  useEffect(() => {
    if (busOrgName) {
      props.change('business_organization_name', busOrgName)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const canSubmit =
    props?.status === 'idle' && (userType === 'individual' || !!business_organization_name)
  return (
    <div
      className='modal fade confirm_social_signup_modal'
      id='confirm_social_signup_modal'
      role='dialog'
    >
      <div className='modal-dialog'>
        <div className='modal-content'>
          <div className='modal-header align-items-start'>
            <h5>
              {isClaim || isAdd
                ? `${isClaim ? 'Claim your' : 'Add a'} ${
                    userType === 'business'
                      ? 'Business'
                      : userType === 'organization'
                      ? 'Organization'
                      : 'Individual'
                  }`
                : 'Sign Up'}{' '}
              on iTRUSTit
            </h5>
            <button type='button' className='btn-close' data-bs-dismiss='modal'></button>
          </div>
          <div className='modal-body'>
            <div className='d-flex'>
              <div className='d-flex'>
                <img
                  src={avatar ?? process.env.PUBLIC_URL + '/img/iti_bug.png'}
                  alt=''
                  style={{
                    borderRadius: '50%',
                    width: 42,
                    marginRight: '0.5rem',
                    objectFit: 'cover',
                  }}
                />
              </div>
              <div className='d-flex flex-column'>
                <div>
                  {firstname} {lastname}
                </div>
                <div>{email}</div>
              </div>
              <div className='d-flex align-items-center loginblk m-0 w-auto ms-auto'>
                <button
                  className='purple nohover'
                  onClick={hideConfirmSocialSignupModal}
                  style={{ outline: 'none', border: 'none', background: 'none' }}
                >
                  Not You?
                </button>
              </div>
            </div>
            {['business', 'organization'].includes(userType) ? (
              <div className='loginblk m-0 mt-4 mw-100 w-100'>
                <div className='row fieldflex form-group'>
                  <div className='col-md-12'>
                    <Field
                      name='business_organization_name'
                      type='text'
                      className='form-control'
                      component={renderInputField}
                      validate={[required]}
                      placeholder={
                        (userType === 'organization' ? 'Organization' : 'Business') + ' Name*'
                      }
                      autoComplete='off'
                    />
                  </div>
                </div>
              </div>
            ) : null}
            <div className='loginblk m-0 mt-4 mw-100 w-100'>
              <div className='text-start signupbottom ms-0 mt-2'>
                <div className='d-flex remember m-0 justify-content-center'>
                  <div className='radio1'>
                    <label className='radioblk text-center p-0 mb-1' style={{ maxWidth: 400 }}>
                      By clicking Agree
                      {isClaim
                        ? ', Sign Up & Claim'
                        : `${isAdd ? ', Sign Up & Add' : ' & Sign Up'}`}{' '}
                      I acknowledge that I{' '}
                      {['business', 'organization']?.includes(userType)
                        ? `am the Authorized Representative of this ${
                            userType === 'organization' ? 'Organization' : 'Business'
                          } and I`
                        : ''}{' '}
                      have read and agree to the
                      <Link className='purple' target='_blank' to={TERMS_ROUTE}>
                        Terms of Service
                      </Link>
                      &nbsp;and
                      <Link className='purple' to={PRIVACY_ROUTE} target='_blank'>
                        Privacy Policy
                      </Link>
                      .
                    </label>
                  </div>
                </div>
              </div>
              {submitFailed && invalid ? (
                <small className='text-danger text-center mb-0'>
                  Please correct the errors above.
                </small>
              ) : props.anyTouched && invalid ? (
                <small className='text-danger text-center mb-0'>
                  Please fill in required fields above.
                </small>
              ) : null}
              <button
                disabled={!canSubmit}
                onClick={() =>
                  canSubmit ? handleSubmit({ ...data, business_organization_name }) : null
                }
                style={{ fontSize: 18 }}
                className={classNames(
                  'btn',
                  'btn-lg',
                  'btn-success',
                  'btn-block',
                  'loginbtn',
                  'signup-submit',
                  'mt-1',
                  !canSubmit ? 'disabled' : '',
                )}
              >
                Agree{isClaim ? ', Sign Up & Claim' : `${isAdd ? ', Sign Up & Add' : ' & Sign Up'}`}
              </button>
            </div>
            <small className='text-center m-0 mt-3 d-block signupblk1'>
              Already part of the iTRUSTit Community?
              <Link
                className='purple'
                onClick={hideConfirmSocialSignupModal}
                to={LOGIN_ROUTE}
                style={{ marginLeft: 5 }}
              >
                Log In
              </Link>
            </small>
          </div>
        </div>
      </div>
    </div>
  )
}
ConfirmSocialSignupModal = reduxForm({
  form: 'signupFormConfirm',
  enableReinitialize: true,
})(ConfirmSocialSignupModal)

const selector = formValueSelector('signupFormConfirm')
ConfirmSocialSignupModal = connect((state) => {
  const business_organization_name = selector(state, 'business_organization_name')

  return {
    business_organization_name,
    initialValues: {
      business_organization_name:
        business_organization_name ?? localStorage.getItem('bus_org_name_from_presearch'),
    },
  }
})(ConfirmSocialSignupModal)

export default ConfirmSocialSignupModal
