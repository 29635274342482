import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { profileInfo, profileInfoReset } from '../../actions/profile'
import { userStoreAction } from '../../actions/auth'
import Profile from '../../components/Profile/Profile'
import cleanDeep from 'clean-deep'

function mapDispatchToProps(dispatch) {
  console.log('pass here')
  return bindActionCreators({ profileInfo, userStoreAction, profileInfoReset }, dispatch)
  // return {
  //     loginAction: bindActionCreators(loginAction, dispatch),
  // };
}

function mapStateToProps(state) {
  let user = cleanDeep(state?.UserReducer?.userdetail)
  return {
    userdetail: state?.UserReducer?.userdetail,
    initialValues: {
      business_organization_name:
        Object.keys(user).length > 0 ? user?.business_organization_name : '',
      sponsors_iteam_id: Object.keys(user).length > 0 ? user?.imatrix_id : '',
      username: Object.keys(user).length > 0 ? user?.username : '',
      firstname: Object.keys(user).length > 0 ? user?.firstname : '',
      lastname: Object.keys(user).length > 0 ? user?.lastname : '',
      email: Object.keys(user).length > 0 ? user?.email : '',
      relationship_type: Object.keys(user).length > 0 ? user?.relationship_type : null,
      address_line_1: Object.keys(user).length > 0 ? user?.address?.address_line_1 : '',
      address_line_2: Object.keys(user).length > 0 ? user?.address?.address_line_2 : '',
      country_id: Object.keys(user).length > 0 ? user?.address?.country_id : null,
      state_id: Object.keys(user).length > 0 ? user?.address?.state_id : null,
      city_id: Object.keys(user).length > 0 ? user?.address?.city_id : null,
      zipcode: Object.keys(user).length > 0 ? user?.address?.zipcode : '',
      phone_no_1: Object.keys(user).length > 0 ? user?.user_detail?.phone_no_1 : '',
      phone_no_2: Object.keys(user).length > 0 ? user?.user_detail?.phone_no_2 : '',
      mobile_no: Object.keys(user).length > 0 ? user?.user_detail?.mobile_no : '',
      fax_no: Object.keys(user).length > 0 ? user?.user_detail?.fax_no : '',
      website_1: Object.keys(user).length > 0 ? user?.user_detail?.website_1 : '',
      website_2: Object.keys(user).length > 0 ? user?.user_detail?.website_2 : '',
      website_3: Object.keys(user).length > 0 ? user?.user_detail?.website_3 : '',
      website_4: Object.keys(user).length > 0 ? user?.user_detail?.website_4 : '',
      email_2: Object.keys(user).length > 0 ? user?.user_detail?.email_2 : '',
      email_3: Object.keys(user).length > 0 ? user?.user_detail?.email_3 : '',
      email_4: Object.keys(user).length > 0 ? user?.user_detail?.email_4 : '',
      profession: Object.keys(user).length > 0 ? user?.user_detail?.profession : '',
      about_me: Object.keys(user).length > 0 ? user?.user_detail?.about_me : '',
      tagline: Object.keys(user).length > 0 ? user?.user_detail?.tagline : '',
      gender: Object.keys(user).length > 0 ? user?.user_detail?.gender : '',
      birth_date: Object.keys(user).length > 0 ? user?.user_detail?.birth_date : '',
      area: Object.keys(user).length > 0 ? user?.address?.area : '',
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile)
