import React from 'react'
import CropperCover from '../../Cropper/CropperCover'
import ProfileHeader from '../../Profile/ProfileHeader/ProfileHeader'
import BusOrgBadge from './BusOrgBadge'
import HeaderTabs from './HeaderTabs'
import IndividualBadge from './IndividualBadge'

const EditProfileHeader = ({ userDetail, userCoverImg, userCoverImgHandler }) => {
  return (
    <>
      <section
        className='cover_details pro_business cover_img'
        style={{
          background: 'url(' + userCoverImg + ') no-repeat',
        }}
      >
        <div className='test'>
          <div className='container'>
            <div className='i_trust_badge'>
              <div className='container'>
                {userDetail?.user_type === 'individual' ? (
                  <IndividualBadge userDetail={userDetail} />
                ) : (
                  <BusOrgBadge userDetail={userDetail} />
                )}
              </div>
            </div>
          </div>
        </div>
        <button
          data-bs-toggle='modal'
          className='croppercovermodal'
          data-bs-target='#cropperCoverModal'
        >
          <i>
            <img src='img/camera.png' alt='' />
          </i>
          <span>Update cover photo </span>
        </button>
        <CropperCover srcData={userCoverImgHandler} imgcoversrc={userCoverImg} />
      </section>
      <div className='container profile-info-container'>
        <div className='cover-inner-wrapper'>
          <div className='cover_inner'>
            <ProfileHeader user={userDetail ?? {}} />
            <HeaderTabs userDetail={userDetail} />
          </div>
        </div>
      </div>
    </>
  )
}

export default EditProfileHeader
