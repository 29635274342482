import React, { useEffect } from 'react'
import $J from 'jquery'
import { ROOT_ROUTE } from '../../services/routeConst'
import { useHistory } from 'react-router-dom'

export const showAddBusOrgSuccessModal = () =>
  $J('#add_business_or_organization_footer_added').modal('show')

export const hideAddBusOrgSuccessModal = () =>
  $J('#add_business_or_organization_footer_added').modal('hide')

const AddBusOrgSuccessModal = ({ user }) => {
  const history = useHistory()

  const viewProfile = () => {
    hideAddBusOrgSuccessModal()
    history.push({ pathname: ROOT_ROUTE + user?.slug })
  }

  useEffect(() => {
    return () => {
      hideAddBusOrgSuccessModal()
    }
  }, [])

  return (
    <div
      className='modal fade login_blk reset_password_blk it-add-business '
      id='add_business_or_organization_footer_added'
      role='dialog'
      data-bs-backdrop='static'
      data-bs-keyboard='false'
    >
      <div className='modal-dialog'>
        <div className='modal-content'>
          <div className='modal-header'>
            <div className='claim_steps'>
              <h3 className='add_title'>
                <span className='other_txt'>
                  {user?.user_type === 'business' ? 'Business' : 'Organization'} Added Successfully
                </span>
              </h3>
              <div className='mt-md-4 mt-2 mb-1'>Thank You!</div>
              <div className='mt-md-4 mt-2 mb-1'>
                {user?.business_organization_name} has been added to the iTRUSTit system.
              </div>
              <div className='add_bottom'>
                <div className='text-center'>
                  <button href={null} onClick={viewProfile} className='continue'>
                    Ok
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddBusOrgSuccessModal
