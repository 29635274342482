import React, { Component } from 'react'
import AppLayout from '../AppLayout'
import { TERMS_AND_CONDITIONS_TITLE } from '../../services/titleConst'
import LoginModal from '../LoginModal/LoginModal'
import * as route from '../../services/routeConst'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
class Terms extends Component {
  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {
    return (
      <AppLayout routeActive={this.props.location.pathname}>
        <Helmet>
          <title>{TERMS_AND_CONDITIONS_TITLE}</title>
        </Helmet>
        <section className='bg-grey it-privacy-terms-page it-terms-page'>
          <div className='container'>
            <h3 className='varela mb-3'>Terms of Service (including Acceptable Use)</h3>
            <h3 className='varela mb-3'>Last Updated March 12, 2019</h3>
            <h3 className='varela mb-3'>
              THESE TERMS OF SERVICE CONTAIN A BINDING ARBITRATION PROVISION, A PROVISION EXCLUDING
              IN MOST CASES YOUR ABILITY TO BRING A CLAIM IN COURT, AND A PROVISION EXCLUDING YOUR
              RIGHT TO A JURY TRIAL.
            </h3>
            <h3 className='varela mb-3'>
              THESE TERMS OF SERVICE ALSO CONTAIN A CONSENT FROM YOU AS TO THE RECEIPT OF EMAILS AND
              SMS TEXT MESSAGES, INCLUDING, WITHOUT LIMITATION, TO PURCHASE PRODUCTS OR SERVICES.
            </h3>
            <h3 className='varela mb-3'>Terms of Service</h3>
            <div className='white-pane privacy'>
              <ol type='A'>
                <li>
                  Introduction
                  <p>
                    This iTRUSTit.com and iTRUSTit Social Inc. Terms of Service (“TOS”) sets out the
                    terms and conditions governing the relationship between iTRUSTit Social, Inc.
                    (““iTRUSTit” or “Company”) and its registered users (“Registered Users”) and
                    other non-registered users (“Non-Registered Users”) including, without
                    limitation, yourself and other persons or entities who may use, access and
                    interact with the iTRUSTit.com platform or website (collectively, “Web Site”)
                    and/or any of the products and services of the Company (collectively with the
                    Web Site, “Services”) as further described herein or in the Services. All such
                    Registered Users and Non-Registered Users are collectively referred to herein as
                    “Users”, and individually as a “User”. You either as an individual, as an
                    individual representing an entity, and/or the entity you are representing, are
                    referred to as “you”, “yours” or “yourself”. These TOS are controlling as to any
                    access, browsing or use of the Services, including without limitation, any open
                    or closed preliminary alpha and beta testing of the Web Site.{' '}
                  </p>
                  <p>
                    You accept and agree to be bound by these TOS by clicking “ACCEPT” to this TOS
                    or any amended TOS, by browsing, accessing or using the Web Site or any
                    Services, by setting up an iTRUSTit User account, by posting any content with or
                    referencing iTRUSTit on your web sites, web pages, or social media, or by using
                    your email in connection with the Services.
                  </p>
                  <p>
                    These TOS also incorporate and include other terms, conditions, rules,
                    guidelines and requirements governing your use of any particular Services,
                    including, without limitation the Acceptable Use Policy (“AUP”) included in this
                    document, the iTRUSTit Privacy Policy (“Privacy Policy”) at
                    https://www.itrustit.com/Privacy, or at such other location as Company may
                    designate, as well as any other referenced or posted guidelines by Company with
                    respect to the Service or any content.
                  </p>
                  <p>
                    If you do not agree to these TOS, do not use or access (or continue to use or
                    access) the Web Site or any Service or participate in any Web Site or Service
                    activities. Continual use of the Web Site or any Service is deemed your
                    agreement with, and continued agreement with, and acceptance of these TOS.
                  </p>
                  <p>
                    Company reserves the right to amend, update or revise these TOS at any time at
                    its sole discretion. This includes, without limitation, that these TOS may be
                    updated frequently during the preliminary alpha and beta testing periods of the
                    Web Site, including, without limitation, to add, modify or remove certain Web
                    Site functionality and/or to add, modify or remove iTRUSTit products and
                    services. Your continued use of any Services or the Web Site following the
                    posting and/or notice via email or any other method, of any amendments, updates
                    or revisions of these TOS, shall constitute your acceptance of and agreement to
                    be bound by all such changes. Your access and use of the Web Site or Service
                    will be subject to the then-current TOS. It is your responsibility to check back
                    and review frequently these TOS for any changes, and incorporated terms,
                    conditions and guidelines.
                  </p>
                </li>
                <li>
                  Must Be 18 Years Of Age.
                  <p>
                    The Web Site and Services are intended for Users 18 years of age or older who,
                    either as an individual or as a designated representative of a business, group
                    or organization, Any registration, use of, or access to the Web Site or Services
                    by anyone under 18 is not permitted and is in violation of these TOS. By
                    registering or using any of the Services or accessing or browsing the Web Site,
                    you represent and warrant that you are over 18 and that you agree to be bound by
                    and to abide by all of the terms and conditions of these TOS.
                  </p>
                </li>
                <li>
                  Participation In iTRUSTit Beta Program
                  <p>
                    Subject to these TOS, at the sole discretion of Company, you may participate in
                    a confidential “Beta” version of the Web Site or Services prior to it being made
                    generally available to the public. As to such Beta version, the Web Site and
                    Services may be operating in preliminary or prerelease versions, may not operate
                    correctly (including, without limitation, loss of User data) and may be modified
                    or removed at any time prior to being made generally available. As a condition
                    of your participation in the Beta program, you agree to (a) keep your use of the
                    Web Site and Services confidential and not to tell third parties about the Web
                    Site, Services, and/or their operation without Company’s prior written
                    permission, (b) not use the Web Site or Services for the purpose of examining,
                    comparing or benchmarking the Web Site or Services against other products or
                    services, or to gain knowledge of the Web Site or Services for use in other
                    products or services, and (c) provide Company, from time to time upon request,
                    with feedback about your experience in using the Web Site and Services (‘Beta
                    Feedback”).{' '}
                  </p>
                </li>
                <li>
                  Feedback.
                  <p>
                    In addition to Beta Feedback, you may also provide feedback in connection with
                    production and/or other versions of the Web Site and/or Services (collectively
                    with Beta Feedback, “Feedback”). You hereby grant iTRUSTit an exclusive,
                    perpetual, irrevocable, worldwide fully-paid up license to any Feedback for any
                    purpose, without any compensation to you. You agree that any Feedback is free
                    from any liens and obligations, including, without limitation, confidentiality
                    obligations.{' '}
                  </p>
                </li>
                <li>
                  Participation In The Web Site And Services (Non-Beta Or Otherwise).
                  <p>
                    Subject to the TOS, and at Company’s sole discretion, you may have access to and
                    participate in the Website and Services, and, subject to these TOS, Company
                    grants you a non-exclusive, limited, revocable and non-transferable license to
                    use the Website and Services. This license and your use of the Web Site and
                    Services and benefits derived therefrom (including, without limitation any
                    revenue derived therefrom), may be suspended, revoked and/or terminated in whole
                    or in part by Company, with or without notice, at any time.{' '}
                  </p>
                </li>
                <li>
                  Products and Services
                  <p>
                    iTRUSTit is a social, business and personal referral directory network, and
                    iTRUSTit may provide one or more of the following Services (in addition to other
                    Services) to its Registered Users and/or Non-registered Users, as further
                    described below for each category of User and type of registered account:
                  </p>
                  <ul>
                    <li>
                      Registration and referral services, allowing individuals, businesses and
                      groups/organizations to register themselves, their members and their patrons
                      for support and referrals, including the building of referral networks;
                    </li>
                    <li>
                      Business directory resources, permitting review, search, identification,
                      rating and ranking of promoted and referred businesses so as to identify
                      quality service providers;
                    </li>
                    <li>
                      Product, music, TV, and movie directory resources, permitting review, search,
                      identification, rating and ranking of promoted and referred product, music,
                      TV, and movies so as to identify quality product, music, TV, and movies;
                    </li>
                    <li>
                      Personal and business directory management tools, including customized
                      iTRUSTit web page(s); email, messaging (including but not limited to chat and
                      SMS), postings functionality; “friends” listings (public or private), and
                      promotion and advertising options; and
                    </li>
                    <li>
                      When and if available, a revenue sharing program. Subject to change, it is
                      anticipated this revenue sharing program provides Registered Users with the
                      ability, depending upon their status, to earn money for referring Users who
                      register with iTRUSTit and purchase Services.{' '}
                    </li>
                  </ul>
                  <p>
                    Certain Services are available in individual or business packages (from basic to
                    professional), while other Services are available on an “a la carte” or
                    selective basis. iTRUSTit will provide the Services in accordance with its then
                    applicable specifications, requirements, limits and qualifications, and on the
                    applicable web pages for each Service and type of User. There may be more
                    information on each of the iTRUSTit Services and options on the Web Site.
                  </p>
                </li>
                <li>
                  Change in Services
                  <p>
                    Company may amend, modify, change, suspend or terminate all or part(s) of the
                    Service and/or Website at any time without notice to you.
                  </p>
                </li>
                <li>
                  Categories of Users and Accounts; Registration and Security
                  <p>
                    iTRUSTit has three types of Registered User and one type of Non-Registered User.
                  </p>
                  <h5>Registered Users</h5>
                  <p>
                    Registered Users may be Individuals, businesses or non-business organizations,
                    with each having different registration requirements and qualifications, and
                    having access to alternative Service options, as may be further described below,
                    and as may be more fully set out in the account package descriptions on the Web
                    Site:
                  </p>
                  <ul>
                    <li>
                      Individual Users may register for basic (free) or premium (monthly fee)
                      accounts, each such account requiring basic registration (including but not
                      limited to name, country, zip/postal code and email address, as well as full
                      address and payment information for premium accounts), and which entitle
                      Individual Users, amongst other items and depending upon the type of account,
                      to have a personal iTRUSTit directory page and static or video profile pages,
                      to build friend and business lists, directories and networks, to create and
                      join public/private groups, search the iTRUSTit directory, leave and view
                      comments and ratings, see calendar events, send and receive notices, and, when
                      and if available, have the potential to earn money under the Company’s revenue
                      sharing program(s).
                    </li>
                    <li>
                      Business Users may, if eligible, register or “claim” as further described
                      below) their business for basic (free), or startup or professional (monthly
                      fee) accounts, with the latter being either packages of Services or “a la
                      carte” selection of Services; each account requiring the same basic
                      registration as Individual Users, but which entitle Business Users, amongst
                      other items and depending upon the type of account, in addition to Individual
                      User activities, to create a customized directory page (including, utilizing
                      premium placement and ability to hide or make private client lists), view and
                      post testimonials and be reviewed and rated by other Registered Users, and to
                      view their rating history, utilize more terms/categories for searching the
                      iTRUSTit directory, post and publish events to the calendar, utilize SMS text
                      notification features, and, when available, have the potential to earn
                      additional monies under the Company’s and if sharing program(s).
                    </li>
                    <li>
                      Organization Users may, if eligible, register or claim (as further described
                      below) their organization for basic (free) or startup or professional (monthly
                      fee) accounts, with the latter being either set packages of Services or “a la
                      carte” selection of Services, each account requiring the same basic
                      registration as Individual Users, but which entitle Organization Users,
                      amongst other items and depending upon the type of account, in addition to
                      Individual User activities, to create a customized directory page (including
                      utilizing premium placement and ability to hide or make private client lists),
                      view and post testimonials and be reviewed and rated by other Registered
                      Users, and view their rating history, utilize more terms/categories for
                      searching the iTRUSTit directory, post and publish events to the calendar,
                      utilize SMS text notification features, and, when and if available, have the
                      potential to earn additional monies under the Company’s revenue sharing
                      program(s). Organization Users will also have a ‘members only’ directory.
                    </li>
                  </ul>
                  <p>
                    Each Registered User account comes with an online profile webpage and an account
                    maintenance or “dashboard” webpage through which the Registered User can
                    populate its (or his/her) profile and administer its (or his/her).own account
                    settings, respectively. A Registered User may have Individual, Business and/or
                    Organization Accounts and all such accounts may be managed by the Registered
                    User.
                  </p>
                  <p>
                    Business/Organization Registration, Claim Eligibility and Restrictions:  A User
                    may only register a business or organization, or claim any business or
                    organization, from the lists of same on the Web Site for which the User is an
                    authorized representative. If a User registers or claims a business or
                    organization for which the User is not the authorized representative, the User’s
                    account may be suspended or terminated (as further described below) at the sole
                    discretion of the Company, and in such case the User shall forfeit all rights in
                    and to the applicable account or content or monies therein or associated
                    therewith, and the account may be transferred to the appropriate authorized
                    representative of such business or organization.
                  </p>
                  <h5>Non-Registered Users</h5>
                  <p>
                    Users who elect not to register with iTRUSTit may still search Individual,
                    Business and/or Organization Registered Users from the iTRUSTit directory search
                    function, including to view information on Registered Businesses and Registered
                    Organizations, such as location, contact information, non-detailed ratings and
                    affiliations, but only limited information on Registered Individuals. To access
                    additional functionality and information, Users must register and purchase one
                    or more applicable Services.
                  </p>
                </li>
                <li>
                  Use of Information and Content You Provide.
                  <p>
                    Subject to applicable law, you hereby grant iTRUSTit a non-exclusive, perpetual,
                    irrevocable, worldwide fully-paid up license, to use for any purpose, any
                    information and/or content you upload or provide to or through the Services or
                    Website (“Provided Content”), including, but not limited to comments, ratings,
                    testimonials, event information, photos, videos, trademarks, logos and other
                    intellectual property. Subject to applicable law, you agree that any Provided
                    Content is free from any liens and obligations, including, without limitation,
                    confidentiality obligations, or obligations that would or could be placed on
                    Company, including without limitation, to pay usage rights.
                  </p>
                </li>
                <li>
                  {' '}
                  Account Registration, Personal Information
                  <p>
                    Each type of Registered User (Individual, Business and Organization) may have
                    different registration requirements, as further described in the account
                    information on the Web Site. In registering with iTRUSTit, you agree to provide
                    and keep current (update) accurate and complete information about you, your
                    business and/or your group/organization as may be required for registration,
                    including, without limitation, valid contact information (e.g., email address,
                    telephone number(s), name and address (including street, city, state, country
                    and zip/postal code) and, if applicable, valid payment information (e.g., credit
                    card number, billing address with expiration date and security code, Paypal,
                    ApplePay, SamsungPay, GooglePay, Bank Account information), revenue sharing
                    information (when and if the revenue sharing program becomes available, and if
                    you decide to participate -- such as your social security number, employment
                    identification number, and other tax related information, including, but not
                    limited to information you provide on or for tax forms, such as a W-4 and W-9),
                    as well as any other information you provide to iTRUSTit. Each User hereby
                    represents and warrants that any such account information provided to iTRUSTit
                    is and will be true, accurate and complete.
                  </p>
                  <p>
                    <strong>
                      For information on how iTRUSTit handles personal information, please review
                      the iTRUSTit Privacy Policy{' '}
                      <Link to={route.PRIVACY_ROUTE}>(http://www.itrustit.com/privacy)</Link>.
                    </strong>
                  </p>
                  <ul>
                    <li>
                      {' '}
                      <strong>Text Messages</strong>
                      <p>
                        The Service includes SMS text messaging to (a) allow the User to submit
                        their personal information and confirm their identity by initiating a
                        targeted text message(s) to the telephone number provided to the Company by
                        the User, and replying to such text message to confirm the User’s identity,
                        and to (b) allow the User to communicate with the Company and for the
                        Company to communicate with the User, regarding relevant account, use,
                        marketing and offer information between both parties. Nevertheless, by using
                        the Service, you agree to receive text messages from an automatic telephone
                        dialing system and you acknowledge that consent is not required to make any
                        purchase from the Company or any of its partners.{' '}
                      </p>
                    </li>
                  </ul>
                  <p>
                    Upon suspension or termination, all licenses provided by Company to you, in
                    relation to the Service or Website (or portion thereof) that is suspended or
                    terminated, are immediately suspended or terminated (as the case may be). In
                    addition, in such an instance, all materials downloaded and/or procured by you
                    from the suspended or terminated Services or Web Site (or part thereof) shall be
                    immediately destroyed by you.
                  </p>
                </li>
                <li>
                  Notices
                  <p>
                    Each User agrees that iTRUSTit may send any notices (including, without
                    limitation, administrative and account renewal and termination notices) and
                    otherwise communicate with Users, by email to the email address provided by the
                    each User, by SMS per the SMS contact information provided by the particular
                    user, or by such other method as determined by Company in its sole discretion.
                    User acknowledges and agrees that one such method is any notice posted on the
                    Web Site, which shall be deemed received by User when posted, and User agrees it
                    is User’s responsibility to check frequently for posted notices on the Web Site.
                    iTRUSTit shall not be responsible or liable if a User does not receive any
                    communication or notices from iTRUSTit due to incorrect or outdated email
                    address or other information provided by the User, or due to a failure outside
                    of Company’s control, such as internet failure, failure of communication
                    providers, or failure of User to maintain an internet or cellular connection or
                    to check the Web Site,.
                  </p>
                </li>
                <li>
                  Account Use
                  <p>
                    You acknowledge and agree that you are responsible for any activity on or misuse
                    of your Web Site or Services account, even if the activity was committed by a
                    friend, family member, colleague, guest or employee, or any other authorized or
                    unauthorized user of your account. It is your responsibility to ensure that your
                    access to your account is properly protected. It is also your responsibility
                    that your access to your account is configured in a secure manner and that you
                    have adopted appropriate security measures and take appropriate steps to ensure
                    that others do not gain unauthorized access to your account. Your password
                    provides access to your account. It is your responsibility to keep your password
                    secure and you may not share your password and account access with unauthorized
                    users.
                  </p>
                  <p>
                    Without limiting the above, the following account-related activities are a
                    violation of these TOS and are prohibited:
                  </p>
                  <ul>
                    <li>
                      using your account to gain unauthorized access, or attempt to breach the
                      security of, another account (including, without limitation, to obtain another
                      User's password without permission), or the Company’s network or server(s), or
                      to penetrate, or attempt to penetrate security measures of Company's or
                      another entity's computer software or hardware, electronic communications
                      system, or telecommunications system;
                    </li>
                    <li>
                      circumventing or attempting to circumvent authentication or security of any
                      other account, including accessing data not intended for you, logging into or
                      making use of an account you are not expressly authorized to access, or
                      probing the security of other accounts, including, without limitation, by use
                      of tools designed for compromising security, such as password guessing
                      programs, cracking tools or system probing tools;
                    </li>
                    <li>
                      registering an account for another person, business, organization, group, or
                      other third party ,{' '}
                    </li>
                    <li>creating a false identity for Services or on the Web Site; and/or</li>
                    <li>
                      allowing others to use your account, or you using your account, for illegal or
                      inappropriate activity, as further described in the AUP below.
                    </li>
                  </ul>
                </li>
                <li>
                  Representations and Warranties
                  <p>
                    Without limiting the foregoing, you represent, warrant, and covenant that: (i)
                    your use of the Web Site and/or Service is solely for lawful purposes, (ii) all
                    information you provide to the Company, and/or through the Web Site or Service,
                    in whatever media or medium, is free of viruses, Trojan horses, trap doors,
                    backdoors, Easter eggs, logic bombs, worms, time bombs, cancelbots, and/or other
                    computer programming routines that may potentially damage, interfere with,
                    intercept, or expropriate any data, information, systems and/or equipment, (iii)
                    submission of information by you will not: cause damage to a User’s computer,
                    download a software application(s), change a User’s settings, or create a series
                    of multiple, sequential, stand-alone advertisements (including by pop-up window
                    or pop-under window), (iv) the information you provide to and/or any act or
                    omission by you relating to the Services and/or Web Site: (a) do not violate any
                    applicable law, statute, directive, ordinance, treaty, contract, or regulation,
                    or Company policy or guideline, (b) do not infringe any copyright, patent,
                    trademark, trade secret, or other intellectual property right of any person or
                    entity, (c) do not breach any duty toward, or rights of, any person or entity,
                    including rights of publicity and/or privacy, (d) are not false, deceptive,
                    misleading, unethical, defamatory, libelous, or threatening, and (e) do not (as
                    determined by Company) reflect poorly on or tarnish the reputation or goodwill
                    of Company or any User, (v) you will not reverse engineer, disassemble,
                    reconstruct, decompile, copy, or create derivative works of any Service, or any
                    aspect or portion thereof, (vi) you will not alter or remove any identification,
                    trademark, copyright, or other notice from any aspect of the Service or Web
                    Site, and (vi) you will comply with any trade sanction, and/or import or export
                    regulation that applies to your use of the Services and/or Web Site, and obtain
                    all necessary licenses to use, export, re-export, or import the Services, as
                    applicable.
                  </p>
                </li>
                <li>
                  Charges and Payments
                  <p>
                    Fees. Certain types of accounts and/or Services for Registered Users require
                    payment of fees. These fees are set out on the Web Site, or available via email
                    or other delivery. All fees are payable in advance of each applicable
                    registration period (e.g., monthly or annually). All fees are non-refundable.
                    All fees will be charged to the credit card or other payment method you provide
                    and agree to as part of the account registration/update process. All periodic
                    and a la carte fees renew automatically at the end of the applicable period. All
                    renewal fees will be charged to your credit card or other payment method on the
                    first day of each renewal period until your account is cancelled by you or your
                    account is terminated in accordance with these TOS. In the event any such fees
                    or renewal fees are denied by the applicable credit card or other payment
                    method, or not paid, Company may immediately suspend or terminate your
                    account(s) as described below in these TOS.
                  </p>
                  <p>
                    You understand and agree that SMS texting may result in fees to you or others
                    from carriers or providers unrelated to Company. Company is not responsible for
                    such fees and you or the party receiving such texts are responsible for the
                    payment of such fees.{' '}
                  </p>
                  <p>
                    Payments. You agree to pay all applicable fees for your account and other
                    Services, plus any applicable taxes or other charges (if any) as may be
                    required, when due and payable. All fees, taxes and charges are non-refundable,
                    including after cancellation or termination of your account by you or Company.
                    Any failure by you for any or no reason to pay fees, by credit card or any other
                    payment method, or any suspension of termination of Services, has no effect on
                    your liability for charges, fees and taxes that you have incurred in connection
                    with your use of the Services.
                  </p>
                  <p>
                    Credit Cards. When you utilize a credit card accepted by Company to pay for any
                    Service, you authorize Company to bill your credit card for any and all charges
                    and fees you incur in connection with the Services including, but not limited to
                    recurring payments. You shall keep your credit card information on file with
                    Company current (such as, but not limited to address, card number, expiration
                    date, and security code), and you authorize Company to update your credit card
                    information with data Company obtains from your financial institution, the
                    issuer of your credit card, and/or from MasterCard, Visa, American Express,
                    Discover, or similar entity.  Unless and until Company discontinues accepting
                    your credit card, you preauthorize for billing by Company your credit card and
                    you authorizes Company to retain your credit card information. Company reserves
                    the right at any time to modify, suspend, or terminate your right to pay by
                    credit card. Any such modification, suspension, or termination, or your failure
                    for any or no reason to pay by credit card, has no effect on your liability for
                    charges and fees that you have incurred in connection with your use of the
                    Services.
                  </p>
                  <p>
                    {' '}
                    Offset. Company may offset amounts owed by User to Company against amounts owed
                    by Company to User (if any).
                  </p>
                  <p>
                    Free Trials. Company may from time to time introduce free trials of certain
                    Services that, if you elect to utilize, require you to register with a valid
                    credit card or other payment method to commence. Users that sign up for a free
                    trial membership and/or Services will be automatically renewed at the monthly
                    rate for the respective plan (as set out on the Web Site) that they are trialing
                    at the end of the trial period, unless you cancel the trial at least 7 days
                    before the end of the trial. You can cancel a free trial online or by contacting
                    the Company in the customer service section of the Web Site. Only one free trial
                    is allowed per User. Subsequent registrations do not qualify for free trials.
                    Unless the terms of a promotion state otherwise, for promotions that include
                    “free” periods with “paid” periods, paid periods will elapse first.
                  </p>
                  <p>
                    Promotions. From time to time, Company, at its sole discretion, may make coupons
                    or other promotions available. Company may, for example, provide Services at a
                    special discounted price for a limited period, followed by a higher price after
                    the initial promotional period. By subscribing to such promotions, you agree
                    that you will be responsible for all payments as specified in the promotion, and
                    you further agree that we may adjust the pricing of such plans, as specified in
                    the offer, promotional materials or coupon, without further notice or other
                    communication to you, and charge your credit card or other payment method
                    accordingly.
                  </p>
                  <p>
                    New Services. We may, from time to time, at our sole discretion, introduce new
                    or additional features, services or materials to the Web Site which may be a
                    part of, or priced separately from, existing products and services, or levels of
                    registration.
                  </p>
                </li>
                <li>
                  Cancellation
                  <p>
                    If you wish to cancel your account or any Services, please visit the customer
                    service information on the Web Site. Cancellation by you of any account or
                    Services will take effect at the end of the then-current billing period for
                    which payment has been made in advance, and you will be able to continue to use
                    your account for the remainder of such period. Cancellation or termination by
                    Company may be immediate, including, without limitation, immediate denial of
                    access to your account(s).{' '}
                  </p>
                </li>
                <li>
                  Intellectual Property
                  <p>
                    Nothing contained in these TOS grants to you or any third party any right, title
                    or interest in or to the intellectual property of Company, its licensors, its
                    Users or any third party, and nor shall these TOS be construed as conferring any
                    right or license to any of same, whether by estoppel, implication or otherwise.
                    All such intellectual property remains the sole property of Company, its
                    licensors, its Users, or any third party, as applicable.
                  </p>
                </li>
                <li>
                  Content and Licenses
                  <p>
                    The copyrights, trademarks and other intellectual property rights in and to the
                    content and materials on or made available through the Web Site (“Content”) are
                    the property of their respective owners, including Company, its licensors and
                    Users. As a User, you may only use the Content, outside of your user Content, as
                    set forth in these TOS. These TOS provides you a non-exclusive, limited,
                    revocable right to access and use the Content (outside of your user Content),
                    including to download a copy of any portion or the Content (outside of your User
                    Content), solely for your personnel use or for the internal use of your business
                    or organization, as further described in these TOS or on the Web Site.
                  </p>
                  <p>
                    As part of certain Services, and subject to the terms of these TOS, you may
                    upload, publish, post, list, store, transmit and share with other Users
                    (collectively, “Submit”) materials such as profile information, including your
                    name, image, and likeness), photos, messages, notes, text, reviews, chat, music,
                    video, advertisements, listings, etc. (collectively, "User Content").
                  </p>
                  <p>
                    Without limiting the general restrictions set forth elsewhere in these TOS, you
                    may not submit any User Content on the Web Site or as part of the Services for
                    which you do not have permission to post or that infringes the intellectual
                    property or other rights of any party, including, without limitation,
                    third-parties. You are solely responsible for the User Content you submit and/or
                    is located within your account, including, without limitation, maintaining at
                    your own cost and expense any backup copies and replacing any User Content you
                    submit, as may be required. Company is not responsible for the loss of such
                    data.
                  </p>
                  <p>
                    {' '}
                    In Submitting User Content to the Web Site, you hereby grant to Company an
                    irrevocable, perpetual, non-exclusive, transferable, fully paid, worldwide
                    license and right of sublicense to use, copy, publicly perform, publicly
                    display, reformat, translate, excerpt (in whole or in part), to prepare
                    derivative works of, or incorporate into other works, and distribute all User
                    Content for any purpose, commercial or otherwise, on or in connection with the
                    Web Site and/or the Services, or any other purpose, including, without
                    limitation, for the promotion thereof, and including, without limitation, such
                    copies thereof as Company deems necessary, including, without limitation, in
                    order to facilitate the Submission (i.e., publishing, posting, listing, storage,
                    sharing, etc.) of such User Content on the Web Site and/or as part of the
                    Services. You may remove your User Content from the Site at any time.
                    Irrespective of your removing your User Content, the license granted above will
                    continue indefinitely, and without limitation, Company may retain archived
                    copies of your User Content, including, without limitation, for purposes of
                    resolving any disputes. Company has no obligation to retain or provide you with
                    copies of User Content, nor does Company guarantee any confidentiality with
                    respect to User Content. Without limiting the foregoing license, and subject to
                    the any other rights granted to Company in these TOS to User Content or
                    otherwise, you retain ownership of all of your User Content and any intellectu
                  </p>
                </li>
                <li>
                  Non-endorsement
                  <p>
                    {' '}
                    Your provisioning, use or display of Content or User Content, and/or Company’s
                    acceptance thereof, does not necessarily reflect the opinion of Company, and
                    does not suggest or imply that Company promotes or endorses any of your or any
                    third party’s cause, idea, website, person, entity, product or service.{' '}
                  </p>
                </li>
                <li>
                  Third Party Web Sites and Content
                  <p>
                    The Web Site, including, without limitation, its related Services, may contain
                    links to web sites managed or controlled by third parties. Such web sites,
                    including, without limitation, the operation, availability and content thereof,
                    and your use of such web sites, including, without limitation, any products or
                    services made available through such websites, are governed by the applicable
                    policies of the respective third parties. Company does not endorse these third
                    parties, their web sites, or their products or services or the content of their
                    web sites, nor is Company acting as an agent thereof, and COMPANY IS NOT
                    RESPONSIBLE FOR THE POLICIES (INCLUDING, WITHOUT LIMITATION ANY WEB SITE TERMS
                    OF USE AND PRIVACY POLICY) OR ACTIONS OR INACTIONS OF THESE THIRD PARTIES, THEIR
                    WEB SITES, OR ANY CONTENT FOUND OR APPEARING THEREIN. COMPANY DOES NOT WARRANT
                    IN ANY WAY THE PRODUCTS, SERVICES OR BUSINESS PRACTICES OF ANY SUCH THIRD
                    PARTIES OR THE CONTENT OF THEIR WEB SITES. Company encourages you to review the
                    privacy and other policies of these third parties and their web sites. Company
                    reserves the right to eliminate or change any such link without prior notice. In
                    addition, COMPANY DOES NOT ENDORSE OR WARRANT, AND IS NOT RESPONSIBLE FOR, ANY
                    THIRD PARTY CONTENT SUBMITTED BY OR FOR ANY USER.
                  </p>
                </li>
                <li>
                  Infringement Notices And Intellectual Property Complaints
                  <p>
                    If you believe any information on the Web Site or in any Service is inaccurate
                    or contains material errors or omissions, please contact Company via the
                    Customer Service sections of the Web Site or at any other location the company
                    may designate.
                  </p>
                </li>
                <li>
                  Copyright Or Intellectual Property Complaints
                  <p>
                    If you believe that anywhere on the Services, including without limitation the
                    Web Site, your work has been copied in a way that constitutes copyright
                    infringement, or your intellectual property rights have been violated, please
                    provide us with the following information:  
                  </p>
                  <ul>
                    <li>
                      a physical signature of the person authorized to act on behalf of the owner of
                      the copyright or other intellectual property right;
                    </li>
                    <li>
                      a description of the copyrighted work or other intellectual property that you
                      claim has been infringed or violated;
                    </li>
                    <li>
                      a description of where the material that you claim is infringing is located on
                      our Web Site or within the Service;
                    </li>
                    <li>your address, telephone number, and email address;</li>
                    <li>
                      a statement by you that you have a good faith belief that the disputed use is
                      not authorized by the copyright or intellectual property owner, its agent, or
                      the law;
                    </li>
                    <li>
                      a statement by you, made under penalty of perjury, that the above information
                      in your notice is accurate and that you are the copyright or intellectual
                      property owner or authorized to act on the copyright or intellectual property
                      owner's behalf.
                    </li>
                  </ul>
                  <p>
                    Please send the foregoing information to our agent for notice of claims of
                    copyright or other intellectual property infringement at:
                  </p>
                  <h3>iTRUSTit</h3>
                  <p>
                    Agent for Notice of Claims of Copyright or Other Intellectual Property
                    Infringement
                  </p>
                  <p>703 Pier Avenue Suite B691 </p>
                  <p> Hermosa Beach, CA 90254</p>
                </li>
                <li>
                  No reverse engineering / No data harvesting.
                  <p>
                    You agree not to reverse engineer, reverse assemble, reverse compile, decompile,
                    disassemble, translate or otherwise alter any executable code, tool or Content
                    on the Web Site or any Service. Further, you agree not to attempt to harvest,
                    collect or reproduce any Content, including, without limitation, databases,
                    links, email addresses or other information, in whole or in part, or to extract,
                    scrap data mine or otherwise copy any Content of the Web Site, or any Service,
                    and any part thereof, or to incorporate any Content in any other database or
                    compilation, either electronically, manually or otherwise.
                  </p>
                </li>
                <li>
                  Acceptable Use Policy (“AUP”)
                  <p>
                    As a User of Services, you agree to use the Services responsibly and in
                    compliance with law. This AUP sets out certain general and specific permissions,
                    restrictions and limitations with respect to access to, and use of any Service
                    and/or the Web Site. All Users, including, without limitation, any Registered
                    Users and Non-Registered Users, must comply with this AUP. If you have any
                    questions regarding this AUP, please contact Company via the customer support
                    contact information on the Web Site.
                  </p>
                  <h5>General Restrictions</h5>
                  <p>
                    In addition to any other restriction set forth in this TOS, you shall not copy,
                    reproduce, modify, distribute, transmit, republish, display, perform, re-host,
                    tamper, frame, and/or embed the Web Site, its Content, any Service, or tools
                    related thereto, except as may be permitted in this TOS as part of the Services.
                  </p>
                  <p>
                    Any commercial use whatsoever of the Web Site, its Content (outside of your own
                    User Content), any Service, or its tools, including, without limitation,
                    charging others for access to the Web Site, any Service, or Content, or selling
                    any Content gathered from the Web Site or any Service, is strictly prohibited
                    without the prior written consent of Company.
                  </p>
                  <p>
                    Any use of the Web Site, Content, or Service other than as specifically
                    authorized in these TOS, without the prior written permission of Company, is
                    strictly prohibited and will result in suspension and/or termination of all
                    rights granted to you in these TOS, including, without limitation, the
                    non-payment of any accrued revenue sharing, and or your being required to pay
                    back to Company monies paid to you.
                  </p>
                  <h5>Impermissible Activities/Conduct</h5>
                  <p>
                    It is a violation of this AUP, and therefore this TOS, to use the Web Site or
                    Services for any following types of activities:
                  </p>
                  <ul>
                    <li>
                      for unlawful purposes, including, without limitation, in violation of any law,
                      including, without limitation, the Telephone Consumer Protection Act, and/or
                      in support of criminal activities of any kind whatsoever, such as, without
                      limitation, online or offline predatory or stalking conduct, or encouraging or
                      providing instructions for a criminal offense
                    </li>
                    <li>
                      to make unsolicited or unauthorized offers to sell or buy products, items or
                      services (other than general inquiries about business and/or organization
                      services in the normal course of business), or to advance any type of improper
                      financial scheme, such as, without limitation, "pyramid schemes," "ponzi
                      schemes," and "chain letters" or any other forms of fraudulent solicitation,
                      including, without limitation, adding, removing or modifying any identifying
                      network header information in an effort to deceive or mislead, or to attempt
                      to impersonate any person by using forged headers or other identifying
                      information;
                    </li>
                    <li>
                      to not use only the Web Site to contact current or potential Users, businesses
                      or organizations, including, but not limited to, as part of, the invite
                      process to the Service, which requires all invite and communication processes
                      to be initiated, managed and transacted exclusively through the Web Site,{' '}
                    </li>
                    <li>
                      to transmit or cause to be transmitted (by Company or others) any unauthorized
                      solicited or unsolicited, individually generated or commercially generated,
                      individual, bulk or spam: (i) e-mail, (ii) SMS text message or bulk SMS
                      message, (iii) online posting or social media posting or communication of any
                      kind, and/or (iv) any phone calls or voice mails;
                    </li>
                    <li>to cause or participate in any in-person visits to potential Users;</li>
                    <li>
                      to cause any of Company’s internet protocols to be listed in any spam
                      database;
                    </li>
                    <li>
                      to intentionally impede another person's use of electronic mail services or
                      news;
                    </li>
                    <li>
                      to use of the Services for the posting of messages or commercial
                      advertisements that violate this AUP or the rules, regulations, or charter of
                      any newsgroups, mailing list, industry trade group, or government entity
                      including but not limited to the Federal Communications Commission and/or the
                      Federal Trade Commission;
                    </li>
                    <li>
                      to transmit any material (including by e-mail, text, uploading, posting or
                      otherwise) that infringes any copyright, trademark, patent, trade secret or
                      other intellectual proprietary, publicity and/or privacy rights of Company,
                      any User, or any third party, including, without limitation, the unauthorized
                      copying of copyrighted material, the unauthorized digitization and
                      distribution of photographs from magazines, books, or other copyrighted
                      sources, or the unauthorized transmittal of copyrighted software, or material
                      that contains libelous, defamatory or other unlawful content.
                    </li>
                    <li>
                      to submit, attempt to submit, collect, or attempt to collect, personal
                      identifiable information of or about other persons or third parties
                      (including, without limitation, addresses, phone numbers, email addresses,
                      Social Security numbers or credit card numbers) without their knowledge or
                      consent, including, without limitation, as required by law.
                    </li>
                    <li>
                      to interfere with or disrupt the ability of Company, other Users or systems to
                      use the Services or the Web Site, including, without limitation, "denial of
                      service" attacks against other Users or Company, or any of their equipment,
                      such as "flooding" of networks, deliberate attempts to overload a service, and
                      attempts to "crash" a host.
                    </li>
                    <li>
                      to Submit User Content or any other content that:
                      <ul>
                        <li>
                          may be harmful, threatening, unlawful, defamatory, infringing, abusive,
                          inflammatory, intimidating, harassing, vulgar, obscene, fraudulent,
                          pornographic, sexually assaultive, inappropriate for minors, invasive of
                          privacy or publicity rights, hateful, racially, ethnically or otherwise
                          objectionable, threatens or encourages bodily harm or damage or
                          destruction of property, or restricts or inhibits any other person from
                          using or enjoying the Web Site or any Service, or which may expose Company
                          or Users to any harm or liability of any type, as solely determined by
                          Company;
                        </li>
                        <li>may violate the rights of Company, any User, or any third party, </li>
                        <li>may create liability to Company, any User, or any third party; or</li>
                        <li>
                          contain software or code (including viruses) or any other computer files
                          or programs designed to damage, interrupt, destroy or limit the
                          functionality of any computer software or systems, hardware or
                          telecommunications equipment, or data.
                        </li>
                      </ul>
                    </li>
                    <li>
                      to use any of the Services or Web Site in any manner that may damage, disable
                      or impair the provision of Services or the Web Site.{' '}
                    </li>
                    <li>
                      to impersonate any person or entity, including, but not limited to, Company,
                      another person or entity utilizing (or allegedly utilizing) Services,
                      including, but not limited to any other business entity or organization listed
                      on the Web Site, and/or representative of the foregoing, and/or falsely state
                      or otherwise misrepresent your affiliation with a person or entity or that you
                      are a person or entity which you are not.;
                    </li>
                    <li>
                      to forge headers or otherwise manipulate identifiers in order to disguise the
                      origin of any Content transmitted through Service;
                    </li>
                    <li>
                      to upload, post, email, text message, transmit or otherwise make available any
                      Content that you do not have a right to make available under any law,
                      contract, fiduciary relationships, and/or otherwise;
                    </li>
                  </ul>
                  <h5>Reviews/Support.</h5>
                  <p>
                    When reviewing or supporting a person, entity, product or service, User shall:
                  </p>
                  <ul>
                    <li>not write a fake or defamatory review</li>
                    <li>
                      only support a person, entity, organization, product or service they truly
                      support{' '}
                    </li>
                    <li>
                      not trade reviews or support with another person(s) or entity(ies), and{' '}
                    </li>
                    <li>
                      not compensate a person or entity, or be compensated, to write or remove a
                      review, or to support or not support a person entity, organization, product or
                      service.
                    </li>
                  </ul>
                  <h5>Monitoring and Reporting Violations of the AUP</h5>
                  <p>
                    If you believe that there is or has been a violation of this AUP, please email
                    iTRUSTit at the customer support contact on the Web Site. If available, please
                    provide the following information:
                  </p>
                  <ul>
                    <li>The IP address used to commit the alleged violation</li>
                    <li>The date and time of the alleged violation</li>
                    <li>Information about the alleged violation</li>
                    <li>Any other relevant information</li>
                  </ul>
                  <h5>Violations of the AUP.</h5>
                  <p>
                    In the event of a violation of this AUP, or any other terms of the TOS, Company,
                    in its sole discretion, in addition to any other rights it may have, may
                    restrict, suspend or terminate an account without prior notice, forever disallow
                    a person or entity from utilizing the Service or the Website, not pay any monies
                    that are due or will be due you, and if applicable, transfer any Service
                    benefits improperly obtained to the rightful person and entity, and recover any
                    monies or other benefits from you
                  </p>
                  <p>
                    Company is under no obligation to review any User's account activities to
                    determine if a violation of the AUP has occurred.
                  </p>
                  <p>
                    COMPANY IS NOT RESPONSIBLE OR LIABLE FOR ANY VIOLATION(S) OF THE AUP, OR TOS, BY
                    ANY USER.{' '}
                  </p>
                </li>
                <li>
                  Geographic Limitation
                  <p>
                    Company reserves the right to limit the use of the Web Site, or any of its
                    Services, to any location without prior notice to you. You may not, either
                    directly or indirectly, through the use of any device, software, service or
                    other means remove, alter, bypass, avoid, interfere with or circumvent any
                    geo-filtering technology employed by Company.
                  </p>
                </li>
                <li>
                  Suspension and Termination
                  <p>
                    In the event of a violation or breach of any provision of these TOS, or where
                    permitted by these TOS, in addition to all other rights the Company may have,
                    the Company reserves the right to immediately suspend and/or terminate some or
                    all account(s), all or part of the Services, and/or access thereto, without
                    prior notice, at any time. . Without waiving its right to suspension,
                    termination or denial of access, Company may notify User of any observed
                    inappropriate activity that violates the AUP or these TOS, including, without
                    limitation, any necessary corrective action by User, and such notification shall
                    not be construed as a waiver of Company’s right to suspend, terminate or deny
                    access at any time.{' '}
                  </p>
                  <p>
                    Without limiting the foregoing, and in addition to any rights Company may have,
                    Company may take any one or more of the following actions in its sole
                    discretion:
                  </p>
                  <ul>
                    <li>
                      Issue written warnings to the User (including, without limitation via email or
                      text message).
                    </li>
                    <li>Suspend the User's account(s).</li>
                    <li>Deny access to User’s account(s).</li>
                    <li>Terminate the User's account(s).</li>
                    <li>Invoice the User for administrative costs and/or reactivation charges.</li>
                    <li>
                      Initiate legal action to prevent further violations and/or to seek monetary
                      damages caused by any violations.
                    </li>
                    <li>
                      Not pay User for any monies owed, or to be owed, to User or any person or
                      entity associated with the User.
                    </li>
                    <li>Forever ban User from the Service or the Website.</li>
                    <li>
                      Transfer all benefits of Service to the person or entity who should rightly
                      have such benefits.{' '}
                    </li>
                  </ul>
                  <p>
                    Upon suspension or termination, all licenses provided by Company to you, in
                    relation to the Service or Website (or portion thereof) that is suspended or
                    terminated, are immediately suspended or terminated (as the case may be). In
                    addition, in such an instance, all materials downloaded and/or procured by you
                    from the suspended or terminated Services or Web Site (or part thereof) shall be
                    immediately destroyed by you.
                  </p>
                </li>
                <li>
                  No Commercial Reuse Of The Website Or Services
                  <p>
                    Except as specifically set forth in this TOS, you agree not to reproduce,
                    duplicate, copy, sell, trade, resell or exploit for any commercial purposes, any
                    portion or use of, or access to, the Services and/or Web Site (including but not
                    limited to Content, advertisements, software, web design, and/or your ID).
                  </p>
                </li>
                <li>
                  General Practices Regarding Use And Storage
                  <p>
                    You acknowledge that Company may establish general practices and limits
                    concerning use of the Services and/or Web Site, including without limitation the
                    maximum number of days that your information, email messages, message board
                    postings or other uploaded Content will be retained by the Services and/or Web
                    Site, the maximum number of email messages that may be sent from or received by
                    an account or otherwise on the Services and/or Web Site, the maximum size of any
                    email or text message that may be sent from or received by an account or
                    otherwise on the Services, the maximum disk space, if any, that will be allotted
                    on Company’s servers on your or anyone else’s behalf, and the maximum number of
                    times (and the maximum duration for which) you or anyone may access the Services
                    and/or Web Site in a given period of time. You agree that Company has no
                    responsibility or liability for the deletion or failure to store any
                    information, messages and other communications or other Content maintained or
                    transmitted by or to Company and/or the Services and/or Web Site. You
                    acknowledge that Company reserves the right to log off accounts, or the
                    equivalent of accounts, that are inactive. You further acknowledge that Company
                    reserves the right to modify these general practices and limits at any time
                    without notice.{' '}
                  </p>
                  <ol type='AA'>
                    <li>
                      DISCLAIMERS; LIMITATION OF LIABILITY.
                      <p>
                        Company assumes no responsibility to monitor or police activities by Users
                        or their accounts, including, without limitation, User Content. Company does
                        not actively monitor User activity, nor does Company exercise editorial
                        control over the content of any account, submission, transmission,
                        newsgroups, or other material created or accessible over or through the
                        Services and/or the Web Site. Without limiting the foregoing, Company
                        reserves the right at all times to monitor, review, delete, remove or block
                        any User Content that, in Company’s sole discretion, may be illegal,
                        offensive or may violate the rights, harm, or threaten the safety of users
                        or others, may subject Company to civil or criminal liability, and/or may
                        violate the AUP and or these TOS, including, without limitation by complying
                        with any notice of infringement pursuant to the Digital Millennium Copyright
                        Act of 1988. You understand and agree that the Company may, but is not
                        obligated to, review the Web Site and iTRUSTit directory and may delete or
                        remove, without prior notice, any User Content in its sole discretion, for
                        any reason or no reason, including, without limitation, User Content that in
                        the sole judgment of the Company violates this TOS. User acknowledges that
                        Company may disclose to and cooperate with governmental, regulatory and
                        legal authorities and/or other third parties in the investigation of any
                        suspected or alleged crime or wrongdoing (civil or otherwise) by any User.
                      </p>
                      <p>
                        YOU SPECIFICALLY ACKNOWLEDGE AND AGREE THAT COMPANY SHALL NOT BE LIABLE FOR
                        ANY DEFAMATORY, LIBELOUS, OFFENSIVE OR ILLEGAL CONDUCT OF ANY USER OR ANY
                        THIRD PARTY OR OF ANY USER CONTENT OR MATERIAL AND/OR OTHER THIRD PARTY
                        CONTENT OR MATERIALS ACCESSED BY OR THROUGH THE WEB SITE OR SERVICES,
                        INCLUDING, WITHOUT LIMITATION, ANY OF SAME ON ANY THIRD PARTY WEB SITE, AND
                        THAT THE RISK OF INJURY FROM ANY OF THE FOREGOING RESTS ENTIRELY WITH YOU.{' '}
                      </p>
                      <p>
                        You agree that Company has no responsibility or liability for the deletion
                        or failure to store any information, messages and other communications or
                        other Content maintained or transmitted by or to Company and/or the Services
                        and/or Web Site.
                      </p>
                      <p>
                        THE WEB SITE, AND SERVICES, AND ALL CONTENT MADE AVALABLE ON OR THROUGH THE
                        WEB SITE OR SERVICES (INCLUDING, WITHOUT LIMITATION USER CONTENT), IS
                        PROVIDED ON AN "AS IS" AND “AS AVAILABLE” BASIS WITHOUT WARRANTIES OF ANY
                        KIND, AND YOU EXPRESSLY AGREE THAT USE OF THE WEB SITE, AND/OR SERVICE,
                        AND/OR CONTENT IS ENTIRELY AT YOUR OWN RISK. TO THE MAXIMUM EXTENT ALLOWABLE
                        BY LAW, COMPANY DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING,
                        WITHOUT LIMITATION, IMPLIED WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A
                        PARTICULAR PURPOSE AND WARRANTIES OF TITLE AND NON-INFRINGEMENT. WITH
                        RESPECT TO THE WEB SITE, THE SERVICES, AND/OR CONTENT MADE AVAILABLE ON OR
                        THROUGH THE WEB SITE OR OTHERWISE (INCLUDING, WITHOUT LIMITATION, USER
                        CONTENT). NEITHER COMPANY, NOR ANY OF ITS AFFILIATES, AND IT AND THEIR
                        OWNERS, EMPLOYEES, CONTRACTORS, AGENTS, REPRESENTATIVES, THIRD PARTY SERVICE
                        OR CONTENT PROVIDERS AND/OR LICENSORS, WARRANT THAT THE USE OF THE WEB
                        SITE.THE SERVICES, AND/OR CONTENT WILL BE UNINTERRUPTED OR ERROR FREE, NOR
                        DO ANY OF SAME MAKE ANY WARRANTY AS TO THE ACCURACY OR RELIABILITY OF ANY
                        CONTENT THAT MAY BE OBTAINED FROM USE OF THE WEB SITE OR THE SERVICES,
                        INCLUDING, WITHOUT LIMITATION, ANY AND ALL THIRD PARTY CONTENT OR MATERIALS
                        ON THIRD PARTY WEBSITES ACCESSED USING THE SERVICES.{' '}
                      </p>
                      <p>
                        IN NO EVENT SHALL COMPANY BE LIABLE, WHETHER IN CONTRACT OR IN TORT
                        (INCLUDING, WITHOUT LIMITATION, BREACH OF WARRANTY, NEGLIGENCE OR STRICT
                        LIABILITY), FOR ANY LOSS OR DAMAGES OF ANY KIND, INCLUDING, WITHOUT
                        LIMITTAION, LOSS OF DATA, LOSS OF BUSINESS, LOSS OF EQUIPMENT OR LOST
                        PROFITS, OR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, PUNITIVE,
                        CONSEQUENTIAL OR OTHER SIMILAR DAMAGES, ARISING OUT OF OR RELATING TO THE
                        PERFORMANCE OR NON-PERFORAMNCE OF OR THE USE OF OR INABILITY TO USE
                        (INCLUDING, WITHOUT LIMITATION, THE INTERRUPTION, SUSPENSION OR TERMINATION
                        OF) THE WEB SITE OR SERVICES, OR ANY PART THEREOF, EVEN IF COMPANY HAS BEEN
                        ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. YOUR SOLE REMEDY FOR
                        DISSATISFACTION WITH THE WEB SITE OR SERVICES, OR WITH CONTENT ASSOCIATED
                        THERETO AND/OR WITH THIRD PARTY CONTENT, MATERIALS AND/OR WEBSITES LINKED
                        THEREFROM, IS TO DISCONTINUE USE OF THE WEB SITE OR THE APPLICABLE SERVICE,
                        CONTENT OR MATERIAL.
                      </p>
                      <p>
                        THE ABOVE DISCLAIMER OF WARRANTIES AND LIMITIATIONS OF LIABILITY APPLY TO
                        ANY DAMAGES ARISING OUT OF OR RELATED TO ANY PERFORMANCE, NON-PERFORMANCE,
                        ERROR, OMISSION, INACCURACY, INTERRUPTION, DOWNTIME, DELETION, DEFECT, DELAY
                        IN OPERATION OR TRANSMISSION, COMPUTER VIRUS, COMMUNICATION LINE FAILURE,
                        THEFT OR DESTRUCTION IN, OR UNAUTHORIZED ACCESS, ALTERATION OR USE OF OR TO,
                        THE WEB SITE, SERVICES, OR THE CONTENT AND MATERIALS ACCESSED BY OR THROUGH
                        THE WEB SITE OR ANY PRODUCTS OR SERVICES.{' '}
                      </p>
                      <p>
                        WITHOUT LIMITING THE FOREGOING, IN NO EVENT SHALL THE LIABILITY OF COMPANY
                        EXCEED THE GREATER Of (I) $100 AND (II) THE AMOUNT (IF ANY) PAID BY YOU FOR
                        THE APPLICABLE SERVICES, DURING THE THREE (3) MONTHS PERIOD PRIOR TO THE
                        EVENT(S), FROM WHICH ANY ALLEGED DIRECT DAMAGES AROSE.{' '}
                      </p>
                      <p>
                        APPLICABLE LAW MAY LIMIT THE DISCLAIMERS OF WARRANTIES AND LIMITATIONS OR
                        EXCLUSIONS OF LIABILITY HEREUNDER AND PROVIDE YOU WITH ALTERNATIVE OR
                        ADDITIONAL RIGHTS. IN SUCH EVENT, COMPANY’S DISCLAIMERS AND LIABILITY SHALL
                        BE LIMITED TO THE MINIMUM EXTENT PERMITTED BY APPPLICABLE LAW.
                      </p>
                    </li>
                    <li>
                      Indemnification
                      <p>
                        You agree to defend, indemnify and hold harmless Company and its affiliates,
                        and it and their owners, directors, officers, employees, representatives,
                        contractors and agents (each an “Indemnitee”), from and against any and all
                        claims, actions, demands, damages, costs, liabilities, losses and expenses
                        (including, without limitation, reasonable attorneys' fees and costs),
                        alleged or otherwise, arising out of or resulting from any beach by you, or
                        anyone acting on your behalf, of these TOS, (including, without limitation,
                        the AUP) or from your use of the Web Site or any Service (collectively,
                        “Claim”). Company will (at your sole expense) reasonably cooperate to
                        facilitate the settlement or defense of any Claim. You are solely
                        responsible for defending any Claim against an Indemnitee (y) subject to
                        such Indemnitee's right to participate with counsel of its own choosing at
                        its own expense, and (z) subject to Company’s right at any time to take over
                        such defense, in which case, in addition to all your other obligations
                        pursuant to this Section AA, you will fully cooperate at your expense with
                        Company in the defense of any claim, and you remain responsible and liable
                        to Company for all costs and expenses relating to the defense of such Claim,
                        which costs and expenses shall be paid by you to Company ten (10) days after
                        date of invoice from Company. You will not agree to any settlement that
                        imposes any obligation or liability on an Indemnitee without such
                        Indemnitee's prior express written consent.{' '}
                      </p>
                    </li>
                    <li>
                      Force Majeure
                      <p>
                        Without limiting the disclaimers of Company and the limitation of liability
                        provisions set forth above, Company shall not be liable for any loss or
                        damage, or be deemed to be in breach of these TOS, if its failure to perform
                        or failure to cure any of its obligations under these TOS results from any
                        event or circumstance beyond its reasonable control, including, without
                        limitation, any natural disaster, fire, flood, earthquake or other acts of
                        god; shortage of equipment, materials, supplies or transportation
                        facilities; strike or other industrial dispute; war, acts of terrorism or
                        rebellion; shutdown or delay in power, telephone, Internet, and/or other
                        essential service due to the failure of computer or communications
                        equipment, or otherwise (each a “Force Majeure”). Without limiting the
                        foregoing, the time for any such performance or cure will be extended, at a
                        minimum, for a period equal to the duration of the Force Majeure, including,
                        without limitation, as may be described in any notice of such event or
                        circumstance to User by or on behalf of Company. You acknowledge that any
                        such event or cause will not excuse the payment by User of any sums owed to
                        Company for Services rendered prior to, during or after the occurrence of
                        any such Force Majeure.
                      </p>
                    </li>
                    <li>
                      Confidentiality
                      <p>
                        “Confidential Information” means any and all information disclosed to you by
                        Company, either directly or indirectly, in writing, orally, or by inspection
                        of tangible objects, other than information that you can establish: (i) was
                        publicly known and made generally available in the public domain prior to
                        the time of disclosure to you by Company, (ii) becomes publicly known and
                        made generally available after disclosure to you by Company other than
                        through your action or inaction, or (iii) is in your possession, without
                        confidentiality restrictions, prior to the time of disclosure by Company, as
                        shown by the competent evidence of your files and records. You will not at
                        any time: (a) sell, license, and/or transfer any Confidential Information,
                        (bi) disclose or otherwise make available to any person or entity any
                        Confidential Information, and/or (c) access, use, reproduce, or copy any
                        Confidential Information, except as necessary in connection with the purpose
                        for which such Confidential Information is disclosed to you and in
                        accordance with these TOS. You will take all measures to protect the secrecy
                        of, and to avoid disclosure and/or unauthorized use of, the Confidential
                        Information. If required by law to disclose Confidential Information, you
                        may do so provided that: (1) you give Company prompt written notice of such
                        requirement prior to such disclosure, (2) at Company’s request, you assist
                        Company in obtaining an order protecting the Confidential Information from
                        public disclosure, and (3) any such disclosure is limited to the minimum
                        extent necessary to comply with the legal requirement. All Confidential
                        Information will remain Company’s personal property, and all documents,
                        electronic media, and other items containing or relating to any Confidential
                        Information must be delivered to Company, destroyed, or uninstalled
                        immediately upon company’s request, or upon termination of your use or
                        access to the Services and/or Web Site.
                      </p>
                    </li>
                    <li>
                      Publicity / Press Release.
                      <p>
                        You may not issue any press release or other public statement regarding
                        these TOS, the Web Site, Service, Company, and/or a Company Indemnitee,
                        without Company’s prior written consent.
                      </p>
                    </li>
                    <li>
                      Binding Arbitration and Class Action Waiver
                      <p>
                        You and Company agree to arbitrate all disputes between (i) you and (ii)
                        Company and/or its affiliates, and it and their owners, directors, officers,
                        employees, representatives, contractors and/or agents, relating to these
                        TOS, the Website and/or the Services between you and Company.  “Dispute”
                        includes any of the foregoing dispute, action or other controversy, whether
                        in contract, tort, warranty, statute or regulation, or other legal or
                        equitable basis, subject to Company’s right to seek equitable, injunctive
                        and/or preliminary relief in a court outside of arbitration, and subject to
                        Company’s right to bring any dispute relating to Company’s intellectual
                        property to a court outside of arbitration. You and Company empower the
                        arbitrator with the exclusive authority to resolve any Dispute relating to
                        the (y) interpretation, applicability or enforceability of these TOS or
                        formation of these TOS, including, without limitation, the arbitrability of
                        any dispute and any claim that all or any part of these TOS are void or
                        voidable, and (z) the Website and the Services.
                      </p>
                      <p>
                        In the event of a Dispute, you or Company must send to the other party a
                        notice of Dispute, which is a written statement that sets forth the name,
                        address and contact information of the party giving the notice, the facts
                        giving rise to the dispute and the relief requested.  You must send any
                        notice of dispute to Company at: iTRUSTit, 703 Pier Avenue Suite B691,
                        Hermosa Beach, CA 90254, Attention: Arbitration Notice.  Company will send
                        any notice of dispute to you at the contact information Company has for
                        you.  You and Company will attempt to resolve a Dispute through informal
                        negotiation within sixty (60) days from the date the notice of dispute is
                        sent.  After sixty (60) days, you or Company may commence arbitration.  If
                        you are utilizing the Web Site or the Services as an individual consumer and
                        you are not an entity(“Consumer’),you may also litigate a dispute in small
                        claims court if the dispute meets the requirements to be heard in small
                        claims court, whether or not you negotiated informally first.
                      </p>
                      <p>
                        If you and Company do not resolve a dispute by informal negotiation, or if
                        you are not a Consumer in small claims court, the dispute shall be settled
                        by binding arbitration before a neutral arbitrator whose decision will be
                        final except for a limited right of appeal under the U.S. Federal
                        Arbitration Act. {' '}
                      </p>
                      <p>
                        YOU ARE GIVING UP THE RIGHT TO LITIGATE A DISPUTE IN COURT BEFORE A JUDGE OR
                        JURY. {' '}
                      </p>
                      <p>
                        Arbitration will be administered by the American Arbitration Association
                        (the “AAA”) under its Commercial Arbitration Rules, and if you are a
                        Consumer, under its Supplementary Procedures for Consumer Related Disputes. 
                        For more information, visit www.adr.org or, in the United States, call
                        800-778-7879.  Arbitration may be conducted in person, through the
                        submission of documents, by phone or online.  Subject to the provisions of
                        these TOS, including without limitation, the limitations of liability set
                        forth in these TOS, the arbitrator may award damages to you individually or
                        to Company, as a court could, including declaratory or injunctive relief,
                        but only to the extent of damages or relief permitted by these TOS to
                        satisfy your individual claim. The award of the arbitrator shall be in
                        writing, based on the law and the facts. The substantive law controlling the
                        arbitration shall be the law of the State of California, USA, without regard
                        to conflict of law principles.{' '}
                      </p>
                      <p>
                        If you are a Consumer, unless the arbitrator finds the arbitration was
                        frivolous or brought for an improper purpose, Company will pay all filing,
                        AAA, and arbitrator’s fees and expenses.  If you are a Consumer, if the
                        arbitrator issues you an award that is greater than the value of Company’s
                        last written settlement offer made before an arbitrator was selected (or if
                        Company did not make a settlement offer before an arbitrator was selected),
                        then we will pay you the amount of the award or US $100, whichever is
                        greater, and pay your attorney, if any, the amount of attorneys’ fees, and
                        reimburse any expenses (including expert witness fees and costs) that you or
                        your attorney reasonably accrue for investigating, preparing and pursuing
                        your claim in arbitration. If you are a Consumer, Company waives any right
                        to seek an award of attorneys’ fees and expenses in connection with any
                        non-frivolous arbitration between you and us.
                      </p>
                      <p>
                        If you are not a Consumer, the parties shall split and be responsible evenly
                        for all filing, AAA, and arbitrator’s fees and expenses.  If you are not a
                        Consumer, the arbitrator may award reasonable attorneys fees and costs to
                        the prevailing party.{' '}
                      </p>
                      <p>
                        If you are a Consumer who is a resident of the United States, arbitration
                        will take place at any reasonable location convenient for you.  For
                        Consumers who reside outside the United States, of if you are not a
                        Consumer, arbitration shall be take place in the City of Los Angeles,
                        California, United States of America, You and Company agree to submit to the
                        personal jurisdiction of the federal or state courts in the applicable
                        location set forth above, in order to compel arbitration, stay proceedings
                        pending arbitration, confirm, modify, vacate or enter judgment on the award
                        entered by the arbitrator, or to litigate the Company seeking equitable,
                        injunctive and/or preliminary relief, or any intellectual property claim of
                        the Company.
                      </p>
                      <p>
                        PROCEEDINGS TO RESOLVE OR LITIGATE A DISPUTE IN ANY FORUM WILL BE CONDUCTED
                        ON AN INDIVIDUAL BASIS.  Neither you nor Company will seek to have a dispute
                        heard as a class action, private attorney general action, or in any other
                        proceeding in which either party acts or proposes to act in a representative
                        capacity.  No arbitration or proceeding can be combined with another without
                        the prior written consent of all parties to the arbitration or proceeding. 
                        If the class action waiver is found to be illegal or unenforceable as to all
                        or some parts of a Dispute, those parts will be severed and proceed in a
                        court of law at the applicable location set forth in the immediately prior
                        paragraph, with the remaining parts proceeding in arbitration.
                      </p>
                      <p>
                        You and Company will maintain the confidential nature of the arbitration
                        proceeding, the hearing and the award of the arbitrator (“Award”), except as
                        may be necessary to prepare for or conduct the arbitration hearing on the
                        merits, or except as may be necessary in connection with a court application
                        for a preliminary remedy, or confirmation of an Award or its enforcement, or
                        unless otherwise required by any applicable law. Any documentary or other
                        evidence produced in any arbitration hereunder will be treated as
                        confidential by you and Company, witnesses and arbitrators, and will not be
                        disclosed to any third person (other than witnesses or experts), except as
                        required by any applicable law, or except if such evidence was obtained from
                        the public domain, or is otherwise obtained independently of the
                        arbitration, and is not otherwise subject to confidentiality.
                      </p>
                    </li>
                    <li>
                      Survival
                      <p>
                        Any provision of these TOS (including, without limitation, all other
                        incorporated terms, conditions, rules, guidelines and requirements) intended
                        by its terms to survive the expiration, termination or cancellation of your
                        account(s), any use of any Service, or these TOS, or of the expiration,
                        termination or cancellation Company’s operations, including any of its
                        services and activities, which intended surviving terms include, without
                        limitation, Sections D, G, I, J, K, L, M, O, P, Q, R, T, U, W, X, Y, AA, BB,
                        CC, DD, EE, FF, GG. HH, and II of these TOS, shall survive any such
                        expiration, termination or cancellation. Any such survival does not imply or
                        create any right for you to continue to use your account(s), the Website,
                        any Service(s) or activities after any such expiration, cancellation or
                        termination.
                      </p>
                    </li>
                  </ol>
                </li>
                <li>
                  General Provisions
                  <p>
                    These TOS, including, without limitation, the AUP, Privacy Policy and any other
                    document referenced herein or therein, and which are all incorporated herein and
                    made a part of these TOS, constitute the entire agreement between User and
                    Company with respect to the subject matter herein, and supersedes any and all
                    prior agreements and understandings, whether written or oral. A waiver by the
                    Company of any breach of any provision of these TOS by User shall not operate as
                    or be construed as a continuing or subsequent waiver thereof or as a waiver of
                    any breach of any other provision thereof. These TOS are personal to User and
                    you may not assign it, or your rights or obligations hereunder, to any third
                    party, without Company's prior written consent. Any attempted assignment in
                    contravention of this TOS will be null and void. Company may assign and/or
                    delegate this TOS, the Web Site, and/or the Service, and its rights and
                    obligations thereto, in whole or in part, at any time without consent from or
                    notice to User. There are no third-party beneficiaries to these TOS, except as
                    may be expressly stated in these TOS. These TOS do not establish any
                    relationship of partnership, joint venture, employment, franchise, or agency
                    between Company and you. You do not have the power to bind Company or incur
                    obligations on the Company’s behalf without Company’s prior written consent,
                    except as otherwise may be expressly provided in these TOS. The section and
                    subsection headings appearing in these TOS are inserted only as a matter of
                    convenience and in no way define, govern, limit, modify or construe the scope or
                    extent of the provisions of these TOS. As used herein, the words “including”,
                    “for example” and derivatives such as “e.g.,” shall mean “including without
                    limitation”. If a court of competent jurisdiction, or arbitrator with authority
                    as set forth in these TOS, finds any provision of these TOS, or portion thereof,
                    to be invalid or unenforceable, the remainder of these TOS will continue in full
                    force and effect, and such provision will (a) be enforced to the maximum extent
                    permissible so as to effect the intent of the Company, and (b) will be replaced
                    by a valid and enforceable provision that has a similar effect. No modification
                    of or amendment to these TOS will be effective unless in a writing by an
                    authorized representative of Company. 
                  </p>
                </li>
              </ol>
            </div>
          </div>
        </section>
        <LoginModal
          isLogin={(e) =>
            function (data) {
              this.props.isLogin(data)
            }
          }
          {...this.props}
        />
      </AppLayout>
    )
  }
}

export default Terms
