import axios from 'axios'
import { customLogger } from '../utils/CustomLogger'
import { userToken } from './customFunction'
import * as environment from './environment'

export const listPromotions = async (request) => {
  try {
    const response = await axios.post(environment.BASE_API + 'promotions', request)
    return response.data
  } catch (error) {
    customLogger(error)
    return error.response
  }
}

export const getCurrentPromotion = async (request) => {
  try {
    const response = await axios.post(environment.BASE_API + 'promotions/current', request)
    return response.data
  } catch (error) {
    customLogger(error)
    return error.response
  }
}

export const listEntries = async (request) => {
  try {
    const response = await axios.post(
      environment.BASE_API + 'promotions/' + request.promotionId + '/entries/' + request.userId,
      request,
      {
        headers: { Authorization: userToken(), Accept: 'application/x.iTRUSTit.v1+json' },
      },
    )
    return response.data
  } catch (error) {
    customLogger(error)
    return error.response
  }
}

export const agreeToPromoTerms = async (request) => {
  try {
    const response = await axios.post(
      environment.BASE_API + 'promotions/' + request.promotionId + '/agree',
      request,
      {
        headers: { Authorization: userToken(), Accept: 'application/x.iTRUSTit.v1+json' },
      },
    )
    return response.data
  } catch (error) {
    customLogger(error)
    return error.response
  }
}
