export const NETWORK_ERROR_MSG = 'Network Error'

export const REQUIRED_MESSAGE_FOR_SEARCH =
  'Please enter City, State or Zip/Postal Code to search(minimum 2 charcters)'
export const REQUIRED_MESSAGE_FOR_SEARCH_NO_ORG = 'Please enter a search term(minimum 2 characters)'
export const REQUIRED_VALID_EMAIL =
  'A message with instructions on how to reset your password has been sent to the above email address provided it is registered with iTRUSTit.' //Asana  #210914_ja-01  858a
export const DUPLICATE_USER = 'The username has already been taken.'
export const DUPLICATE_EMAIL = 'The email has already been taken.'
export const LIST_VIEW_ITEMS_PER_PAGE = 10
export const LIST_VIEW_PAGE_RANGE = 12
export const GALLERY_MEDIA_ITEMS_PER_PAGE = 25
export const GALLERY_MEDIA_PAGE_RANGE = 10
export const ACTIVITY_ITEMS_PER_PAGE = 25
export const ACTIVITY_PAGE_RANGE = 10
export const NOTIFICATION_ITEMS_PER_PAGE = 10
export const NOTIFICATION_PAGE_RANGE = 10
export const SEARCH_AMT = 10
export const DEFAULT_PROFILE_PICTURE = process.env.PUBLIC_URL + '/img/default_profile.png'
export const DEFAULT_COVER_PICTURE = process.env.PUBLIC_URL + '/img/default_cover_img_sky.png'
export const NO_COVER_PICTURE = process.env.PUBLIC_URL + '/img/default_cover_img_sky.png'

export const STAR_RATING_iTRUST_ACTIVE_COLOR = '#7F51C2'
export const STAR_RATING_iTRUST_INACTIVE_COLOR = '#CDD2DE'
export const STAR_RATING_TRUST_ME_ACTIVE_COLOR = '#FEBF00'
export const STAR_RATING_TRUST_ME_INACTIVE_COLOR = '#CDD2DE'
export const STAR_RATING_DEFAULT_ACTIVE_COLOR = '#FEBF00'
export const STAR_RATING_DEFAULT_INACTIVE_COLOR = '#CDD2DE'

export const NUMBER_OF_RATING_STAR = 5
export const RATING_STAR_SPACING = '3px'
export const STAR_RATING_PROFILE_HEADER_SIZE = 25
export const STAR_RATING_REVIEW_SECTION_SIZE = 15
export const STAR_RATING_REVIEW_SECTION_SIZE_RELATED = 8
export const STAR_RATING_REVIEW_SECTION_SIZE_PX = '15px'
export const STAR_RATING_REVIEW_CREATE_SECTION_SIZE = 20

export const DEFAULT_LATITUDE = 33.86468
export const DEFAULT_LONGITUDE = -118.39526
//210824-1 dashes surrounded by characters
export const DASHESANDCHARACTERS = /^[A-Za-z][A-Za-z0-9]*(?:-[A-Za-z0-9]+)*$/
export const ONLYCHARACTERS = /^[a-zA-Z0-9-]*$/
export const ONLYCHARACTERS_WITHSPACE = /^[a-zA-Z 0-9-]*$/
export const FILE_MAX = 9
export const FILE_MAX_MSG =
  'Please reduce the number of files you are attaching to ' + FILE_MAX + ' or fewer'

export const GOOGLE_MAP_SEARCH_URL = 'https://www.google.com/maps/search/'
//Social Media const
export const POST_AMT = 20 //amount of posts at the time
export const COMMENT_AMT = 10 //amount of comments at the time
export const COMMENT_START_AMT = 0 //amount of comments first time
export const REPLY_AMT = 1 //amount of replies at the time
export const REPLY_START_AMT = 10 //amount of replies first time
export const IMG_PATH = process.env.PUBLIC_URL + '/img/wall_icons/'
export const PDF_PATH = process.env.PUBLIC_URL + '/pdf/'
export const POST_TEXT_SIZE = 10000
export const TINYMCE_KEY = '9c02npowy44212esjfteh330l48ouwzskadadziu3e4vurgv'
export const AUTHOR = 'Author'
export const DISCARD_POST =
  'You haven’t finished your post yet. Are you sure you want to leave and discard your draft?'
export const WALL_DELETE_POST = 'Are you sure you want to delete this post?'
export const WALL_DELETE_COMMENT = 'Are you sure you want to delete this comment?'
export const WALL_DELETE_REPLY = 'Are you sure you want to delete this reply?'

export const relationshipList = [
  { value: 0, label: 'Select...' },
  { value: 'board_member', label: 'Board Member' },
  { value: 'ceo', label: 'CEO' },
  { value: 'cfo', label: 'CFO' },
  { value: 'cmo', label: 'CMO' },
  { value: 'coo', label: 'COO' },
  { value: 'manager', label: 'Manager' },
  { value: 'member', label: 'Member' },
  { value: 'owner', label: 'Owner' },
  { value: 'other', label: 'Other' },
  { value: 'partner', label: 'Partner' },
  { value: 'president', label: 'President' },
  { value: 'secretary', label: 'Secretary' },
  { value: 'social_media_manager', label: 'Social Media Manager' },
  { value: 'social_media_contractor', label: 'Social Media Contractor' },
  { value: 'treasurer', label: 'Treasurer' },
  { value: 'vice_president', label: 'Vice President' },
]

export const PROFILE_OTHER_ROUTE_CONST = 'invite/'
export const PROFILE_ROUTE_CONST = ''

export const MAX_FILE_SIZE_MB = 2
export const MAX_VIDEO_SIZE_MB = 5000
export const MAX_VIDEO_DURATION_S = 600
