import React from 'react'
import QRCode from '../../CommonComponents/QRCodeModal/QRCode'

const PromotionShareIcon = ({ img, text, isBasic, onClickCallback = () => {}, isQR }) => {
  return (
    <div
      className={`promotion-share-icon-wrapper d-flex flex-column justify-content-between align-items-center ${
        isBasic ? '' : 'h-100'
      }`}
      onClick={onClickCallback}
    >
      <div className='promotion-share-icon my-auto'>
        {isQR ? (
          <QRCode
            value={'/'}
            logoImage={process.env.PUBLIC_URL + '/img/iti_qr_logo.png'}
            width={42}
            height={42}
            qrStyle='dots'
            // fgColor='#006699'
            fgColor='black'
            ecLevel='H'
            eyeRadius={[
              { outer: 10 }, // top/left eye
              { outer: 10 }, // top/right eye
              { outer: 10 }, // bottom/left eye
            ]}
            quietZone={1}
          />
        ) : (
          <img width='100%' src={img} alt='' />
        )}
      </div>
      <small className='mont'>{text}</small>
    </div>
  )
}

export default PromotionShareIcon
