import React, { useState } from 'react'
import $ from 'jquery'
import { toaster } from '../../../services/customFunction'
import { Field, reduxForm } from 'redux-form'
import * as validation from '../../../services/formValidation'
import renderInputField from '../../Form/Input'
import { checkEmail } from '../../../services/userApiService'
import { changeSignupEmail } from '../../../services/authService'
import { customLogger } from '../../../utils/CustomLogger'

export const showChangeSignupEmailModal = () => {
  $('#change_signup_email_modal').modal('show')
}
export const hideChangeSignupEmailModal = () => {
  $('#change_signup_email_modal').modal('hide')
}

const ChangeSignupEmailModal = (props) => {
  const [status, setStatus] = useState('idle')
  const [apiError, setApiError] = useState('')
  const [email, setEmail] = useState('')

  const handleChange = (e) => {
    !!apiError && setApiError('')
    setEmail(e?.target?.value ?? '')
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    setStatus('submitting')
    const postData = { email }
    try {
      const res = await checkEmail(postData)
      if (res?.data?.is_email_exists === '0') {
        const changeResponse = await changeSignupEmail({
          user_id: props.user?.id,
          registered_email: props.user?.email,
          new_email: email,
        })
        if (changeResponse?.data?.data?.email === email) {
          hideChangeSignupEmailModal()
          $('body').removeClass('modal-open')
          $('.modal-backdrop').remove()
          $('body').css({ 'padding-right': '' })
          setEmail('')
          toaster('success', changeResponse?.data?.message)
          props.refreshUserData?.()
          setStatus('idle')
        } else if (changeResponse?.data?.data?.error || changeResponse?.data?.error) {
          setApiError(changeResponse?.data.message)
          setStatus('idle')
        } else {
          setApiError('Something went wrong.')
          setStatus('idle')
        }
      } else if (res?.data?.is_email_exists === '1') {
        setApiError(
          'This email is already in our system. Try another email or use this email to log into your account.',
        )
        setStatus('idle')
      } else if (res?.data?.data?.error || res?.data?.error) {
        setApiError(res?.data.message)
        setStatus('idle')
      } else {
        setApiError('Something went wrong.')
        setStatus('idle')
      }
    } catch (error) {
      customLogger(error)
      console.log(error)
      setStatus('idle')
      toaster(
        'error',
        'Something went wrong. Please try again or contact an Admin if the issue persists.',
      )
    }
  }

  const isLoading = status === 'submitting'
  const isDisabled = status === 'submitting' || !!apiError || !email || props?.invalid
  return (
    <div
      className='modal fade change_signup_email_modal'
      id='change_signup_email_modal'
      role='dialog'
    >
      <div className='modal-dialog'>
        <div className='modal-content'>
          <div className='modal-header align-items-start pb-0'>
            <h5>Change Registered Email</h5>
            <button type='button' className='btn-close' data-bs-dismiss='modal'></button>
          </div>
          <div className='modal-body' style={{ textAlign: 'center' }}>
            <h6 className='text-start'>
              Please provide a valid email address that you have access to, so we can resend your
              activation link there.
            </h6>
            <div
              className='que4 mt-3'
              style={{
                cursor: isDisabled ? 'not-allowed' : 'pointer',
              }}
            >
              <div>
                <Field
                  name='otp'
                  type='text'
                  className='form-control password1'
                  component={renderInputField}
                  customError={apiError}
                  onChange={handleChange}
                  value={email}
                  valueKey={email}
                  validate={[validation.required, validation.email]}
                  label='Email Address'
                  placeholder='Enter your email address'
                  autoComplete='off'
                />
                <button
                  onClick={isDisabled ? () => {} : handleSubmit}
                  className='btn btn-lg btn-success submit_btn mt-2 w-100'
                  disabled={isDisabled}
                  style={{
                    background: '#544cf9',
                    color: '#fff',
                    borderRadius: 30,
                    opacity: isDisabled ? 0.5 : 1,
                    cursor: isDisabled ? 'not-allowed' : 'pointer',
                    fontWeight: 600,
                    lineHeight: 0,
                    maxWidth: '100%',
                    fontSize: 14,
                    padding: '15px 20px',
                    height: 48,
                  }}
                >
                  {isLoading ? 'Loading...' : 'Submit'}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default reduxForm({
  form: 'resendVerificationEmailForm',
  enableReinitialize: true,
})(ChangeSignupEmailModal)
