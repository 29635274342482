import { motion } from 'framer-motion'
import React from 'react'
import parse from 'html-react-parser'
import { CDN_URL, PROFILE_PICTURE_SMALL_URL_PATH } from '../../../services/environment'
import { DEFAULT_PROFILE_PICTURE } from '../../../services/const'

const catVariants = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  closed: {
    y: 0,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
}

const Suggestion = ({ suggestion, handleClick }) => {
  const isCat = suggestion?.highlight
  const isUser = suggestion?._index === 'live-users-new'
  const { _source: sugData } = isCat || isUser ? suggestion : {}

  const renderAddress = (data) => {
    const {
      address_line_1,
      address_line_2,
      // loc,
      city,
      state,
      country,
      zipcode,
    } = data
    const address = [address_line_1 ?? null, address_line_2 ?? null].filter(Boolean)?.join(' ')
    const location = [city ?? null, !!state ? state + ', ' : null, country ?? null, zipcode ?? null]
      .filter(Boolean)
      ?.join(' ')
    return data.user_type !== 'individual' && (address?.length > 0 || location?.length > 0)
      ? `${!!address?.length ? address + ' ' : ''}${location}`
      : 'Tap to view profile'
  }
  const fullname = ['business', 'organization'].includes(sugData?.user_type)
    ? sugData?.business_organization_name
    : sugData?.name
  return (
    <motion.div
      className='col position-relative px-0'
      // key={!!isCat || isUser ? suggestion?._id : suggestion}
      variants={catVariants}
      onMouseDown={() =>
        handleClick(isUser ? sugData : isCat ? parse(sugData?.name) : suggestion, 'suggestion')
      }
    >
      <div className={`d-flex flex-column justify-content-center mobile-search-suggestion`}>
        <div
          className={`d-flex ${isUser ? 'user-suggestion' : ''}`}
          style={{ marginLeft: '0.75rem' }}
        >
          {isUser ? (
            <div
              className='user-suggestion-avatar'
              onMouseDown={() =>
                handleClick(
                  isUser ? sugData : isCat ? parse(sugData?.name) : suggestion,
                  'suggestion',
                )
              }
            >
              {!!sugData?.image ? (
                <img
                  onError={(e) => (e.target.src = DEFAULT_PROFILE_PICTURE)}
                  className='imageTag'
                  alt=''
                  src={CDN_URL + 'profile_picture/small/' + sugData?.image}
                />
              ) : (
                <img className='imageTag' alt='' src={DEFAULT_PROFILE_PICTURE} />
              )}
            </div>
          ) : (
            <i
              className='fa fa-search'
              aria-hidden='true'
              style={{
                color: '#544CF9',
                fontSize: 16,
              }}
            ></i>
          )}
          <div className='d-flex flex-column search-suggestion-label'>
            <span className={`${isUser ? 'user-suggestion-name' : ''}`}>
              {isCat
                ? parse(suggestion?.highlight?.name?.[0]) ?? fullname ?? ''
                : isUser
                ? sugData?.name
                : suggestion}
            </span>
            {!!isUser ? (
              <span className='user-suggestion-address'>{renderAddress(sugData)}</span>
            ) : null}
          </div>
        </div>
        <hr
          className='mx-1'
          style={{
            marginTop: '0.5rem',
            marginBottom: '0.5rem',
            border: 0,
            height: 0,
            borderTop: '1px solid #544cf9',
            borderBottom: '1px solid rgba(255, 255, 255, 0.3)',
          }}
        />
      </div>
    </motion.div>
  )
}

export default Suggestion
