import React from 'react'

const CityOverride = ({ cityOverride, isSuccess }) => (
  <div>
    <small className='search-message-expanded-results'>
      We couldn't find any results in your initial search, so we expanded it to{' '}
      {isSuccess ? 'show all ' : 'try finding '}
      results in <strong style={{ color: '#544cf9' }}>{cityOverride}</strong>
    </small>
  </div>
)

const SearchHeader = ({
  isSearching,
  isGlobalSearch,
  searchStr,
  searchAddress,
  searchResultsData = {},
}) => {
  const { totalRecords, from, to, cityOverride } = searchResultsData
  return (
    <>
      <div className='d-flex search_top_header'>
        <h2 className='varela search-title'>
          {isSearching ? (
            <>
              <strong>
                {isGlobalSearch ? 'Finding all results for' : 'Searching for'} {searchStr}
              </strong>
            </>
          ) : totalRecords > 0 ? (
            <>
              {searchStr ? (
                <span>
                  Showing {isGlobalSearch ? 'all ' : ''}results for <strong>{searchStr}</strong>
                </span>
              ) : null}
            </>
          ) : (
            <>{searchStr ? <strong>No Results for {searchStr}</strong> : null}</>
          )}
          {!isGlobalSearch && searchAddress && searchStr ? (
            <span> near {searchAddress}</span>
          ) : null}
          {isSearching ? <strong> ...</strong> : null}
        </h2>
        {totalRecords > 0 ? (
          <span className='showing'>
            Showing {from} - {to} of {totalRecords}
          </span>
        ) : null}
      </div>
      {cityOverride ? (
        <CityOverride cityOverride={cityOverride} isSuccess={totalRecords > 0} />
      ) : null}
      {!searchAddress ? (
        <div>
          <small className='search-message-expanded-results'>
            Try entering a location above to help narrow down your search results
          </small>
        </div>
      ) : null}
    </>
  )
}

export default SearchHeader
