import * as api from '../services/authService'
import * as type from './const'
// import { toaster } from "../services/customFunction";

export const profileInfo = (token) => {
  return (dispatch) => {
    dispatch(editUserStore([]))
    api.userProfile(token).then(function (response) {
      if (response.status === true) {
        dispatch(editUserStore(response.profileInfo))
      }
    })
  }
}
export const profileInfoReset = () => {
  return (dispatch) => {
    dispatch(editUserStore([]))
  }
}
export const editUserStore = (data) => {
  return {
    type: type.EDIT_USER_ACTION,
    data: data,
  }
}

export const coverImageAction = (data) => {
  return (dispatch) => {
    dispatch(coverImage(data))
  }
}

export const coverImage = (data) => {
  return {
    type: type.COVER_IMAGE,
    data: data,
  }
}

export const headerSearchBarValue = (data) => {
  return {
    type: type.HEADER_SEARCHBAR,
    data: data,
  }
}

export const hideUserHeader = (data) => {
  return {
    type: type.HIDE_HEADER,
    data: data,
  }
}

export const headerSearchBarValOnChange = (data) => {
  return {
    type: type.HEADER_SEARCHBAR_VAL,
    data: data,
  }
}

export const headerSearchBarLocChange = (data) => {
  return {
    type: type.HEADER_SEARCHBAR_LOC,
    data: data,
  }
}

export const headerSearchBarClicked = (data) => {
  return {
    type: type.HEADER_SEARCHBAR_CLICKED,
    data: data,
  }
}

export const headerSearchBarSuggestionClicked = (data) => {
  return {
    type: type.HEADER_SEARCHBAR_SUGGESTION_CLICKED,
    data: data,
  }
}

export const headerSearchBarElasticResults = (data) => {
  return {
    type: type.HEADER_SEARCHBAR_ELASTIC,
    data: data,
  }
}

export const setEchoConnected = (data) => {
  return {
    type: type.SET_ECHO_CONNECTED,
    data: data,
  }
}

export const headerSearchBarReset = (data) => {
  return {
    type: type.HEADER_SEARCHBAR_RESET,
    data: data,
  }
}

export const headerSearchBarSet = (data, fromModal) => {
  return {
    type: type.HEADER_SEARCHBAR_SET,
    data: data,
    fromModal,
  }
}

export const reviewUser = (data) => {
  return {
    type: type.REVIEW_USER,
    data: data,
  }
}
