import * as api from '../services/authService'
import * as type from './const'
import { toaster } from '../services/customFunction'
import { NETWORK_ERROR_MSG } from '../services/const'

export const authenticateAction = (data) => {
  return (dispatch) => {
    dispatch(isLoadingAction(true))
    dispatch(errorMsg({}))

    api.authenticate(data).then(function (response) {
      if (response === undefined) {
        toaster('error', NETWORK_ERROR_MSG)
        dispatch(isLoadingAction(false))
      } else if (response.status === true) {
        if (response.authUserData?.user) {
          // return
          dispatch(userStore({ ...response.authUserData, otp_token: data?.otp_token }))
        } else {
          dispatch(userAuthenticate(response))
        }
      } else {
        //toaster("error", response.data.message);
        dispatch(errorMsg(response.data))
        dispatch(isLoadingAction(false))
      }
    })
  }
}

export const resetAuthDataAction = () => {
  return (dispatch) => {
    dispatch(userAuthenticate({}))
  }
}

export const loginAction = (data) => {
  return (dispatch) => {
    const { otp_token, ...rest } = data
    dispatch(isLoadingAction(true))
    dispatch(errorMsg({}))

    api.login(rest).then(function (response) {
      if (response === undefined) {
        toaster('error', NETWORK_ERROR_MSG)
        dispatch(isLoadingAction(false))
      } else if (response.status === true) {
        dispatch(userStore({ ...response, otp_token }))
      } else {
        //toaster("error", response.data.message);
        dispatch(errorMsg(response.data))
        dispatch(isLoadingAction(false))
      }
    })
  }
}

export const socialLoginAction = (data, promptWhenExists = false) => {
  return (dispatch) => {
    dispatch(errorMsg({}))
    dispatch(authResponse(null))
    api.socialLogin(data).then(function (response) {
      if (response === undefined) {
        toaster('error', NETWORK_ERROR_MSG)
        //dispatch(networkError());
      } else if (response?.status === true) {
        if (promptWhenExists) {
          dispatch(userExistsPrompt(response))
        } else {
          dispatch(userStore(response))
        }
      } else if (response.status === 422) {
        toaster('error', response.data?.message)
      } else if (response?.status === 401 && response?.data?.status === false) {
        dispatch(authResponse(response))
      } else {
        //toaster("error", response.data.message);
        dispatch(errorMsg(response.data.message))
      }
    })
  }
}
export const linkedInLoginAction = (data, promptWhenExists = false) => {
  return (dispatch) => {
    dispatch(errorMsg({}))
    dispatch(authResponse(null))
    api.linkedInAuth(data).then(function (response) {
      if (response === undefined) {
        toaster('error', NETWORK_ERROR_MSG)
        //dispatch(networkError());
      } else if (response.status === true) {
        if (promptWhenExists) {
          dispatch(userExistsPrompt(response))
        } else {
          dispatch(userStore(response))
        }
      } else if (response.status === 422) {
        toaster('error', response.data?.message)
      } else {
        //toaster("error", response.data.message);
        dispatch(authResponse(response))
        dispatch(errorMsg(response?.data?.message ?? response?.message))
      }
    })
  }
}

export const signupAction = (data, cb) => {
  return (dispatch) => {
    dispatch(isLoadingAction(true))
    api.signup(data).then(function (response) {
      if (response === undefined) {
        toaster('error', NETWORK_ERROR_MSG)
        dispatch(isLoadingAction(false))
        //dispatch(networkError());
      } else if (response.status === 422) {
        dispatch(errorMsg(response.data.errors))
        dispatch(isLoadingAction(false))
      } else if (response.status === 403) {
        dispatch(
          errorMsg(
            'There was a problem creating your account. Sorry for the inconvenience, Please contact iTRUSTit customer service.',
          ),
        )
        dispatch(isLoadingAction(false))
      } else if (!!response?.token) {
        toaster('success', response.message)
        dispatch(userStore(response))
        dispatch(isLoadingAction(false))
      } else if (response.status === true) {
        toaster('success', response.message)
        dispatch(signupStore({ ...response, email: data?.email, userId: data?.user_id }))
        dispatch(isLoadingAction(false))
      }
      cb && cb()
      // return
    })
    // cb && cb()
  }
}

export const userAuthenticate = (data) => {
  return {
    type: type.USER_AUTHENTICATE,
    data: data,
  }
}

export const userStore = (data) => {
  return {
    type: type.USER_DETAIL,
    data: data,
  }
}

export const userStoreAction = (data) => {
  return (dispatch) => {
    dispatch(userStore(data))
  }
}

export const userExistsPrompt = (data) => {
  return {
    type: type.USER_EXISTS,
    data: data,
  }
}

export const userExistsPromptAction = (data) => {
  return (dispatch) => {
    dispatch(userExistsPrompt(data))
  }
}

export const signupStoreAction = () => {
  return (dispatch) => {
    dispatch(signupStore([]))
  }
}

export const signupStore = (data) => {
  return {
    type: type.SIGNUP_STORE,
    data: data.message,
    slug: data.slug,
    email: data?.email,
    userId: data?.userId,
  }
}
export const networkError = () => {
  return { type: type.NETWORK_ERROR, data: [] }
}

export const errorMsg = (data) => {
  return {
    type: type.ERROR_MSG,
    data: data,
  }
}

export const authResponse = (data) => {
  return {
    type: type.AUTH_RESPONSE,
    data: data,
  }
}

export const authResponseAction = () => {
  return (dispatch) => {
    dispatch(authResponse(null))
  }
}

export const signupType = (data) => {
  return {
    type: type.SIGNUP_TYPE,
    data: data,
  }
}

export const errorMsgAction = (data) => {
  return (dispatch) => {
    dispatch(errorMsg({}))
  }
}

export const isLoadingAction = (data) => {
  return {
    type: type.IS_LOADING,
    data: data,
  }
}

export const loginHistoryEmail = (data) => {
  return {
    type: type.LOGIN_HISTORY_EMAIL,
    data: data,
  }
}

export const socialLoginFillForm = (data) => {
  return {
    type: type.SOCIAL_LOGIN_FILL_FORM,
    data: data,
  }
}
