import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { reviewUser } from '../../actions/profile'

import AppLayout from '../AppLayout'

import { PROFILE_TITLE } from '../../services/titleConst'
import * as route from '../../services/routeConst'
import * as customFunction from '../../services/customFunction'
import { NO_COVER_PICTURE } from '../../services/const'
import { getProfileFromId, getProfileFromIdOnlyProfile } from '../../services/userApiService'

import {
  STAR_RATING_iTRUST_ACTIVE_COLOR,
  STAR_RATING_iTRUST_INACTIVE_COLOR,
  STAR_RATING_TRUST_ME_ACTIVE_COLOR,
  STAR_RATING_TRUST_ME_INACTIVE_COLOR,
  STAR_RATING_PROFILE_HEADER_SIZE,
  DEFAULT_PROFILE_PICTURE,
} from '../../services/const'
import ReactStars from 'react-stars'

import ProfileHeader from './ProfileHeader/ProfileHeader'
import OverviewGenericTab from './OverviewGenerictTab/OverviewGenerictTab '
import TrustDirectoryTab from './TrustDirectoryTab/TrustDirectoryTab'
import GalleryTab from './GalleryTab/GalleryTab'
import FriendsTab from './FriendsTab/FriendsTab'
import MemberTab from './MemberTab/MemberTab'

import ReviewTab from './ReviewTab/ReviewTab'
import ReviewCreate from '../CommonComponents/ReviewCreate/ReviewCreate'
import $J from 'jquery'
import OrgsTotalTab from './OrgsTotalTab/OrgsTotalTab'
import OrgsCommonTab from './OrgsCommonTab/OrgsCommonTab'
import { makeCommonMemberCall } from '../../services/trustApiService'
import ConfirmUntrustPopupModal from './ConfirmUntrustModal/ConfirmUntrustPopupModal'
import ConfirmUnfriendPopupModal from './ConfirmUnfriendModal/ConfirmUnfriendPopupModal'
import { formatMemberDirectoryName, formatMembersName } from '../../services/formatService'
import { userProfile } from '../../services/authService'
import { Helmet } from 'react-helmet'
import { removeMemberCall } from '../../services/memberApiService'
import ConfirmRemoveFromOrgPopupModal from './ConfirmRemoveFromOrgModal/ConfirmRemoveFromOrgPopupModal'
import InvitePopupModal from './InvitePopupModal/InvitePopupModal'
import MemberPopupModal from './MemberPopupModal'
import ConfirmLeaveOrgPopupModal from './ConfirmLeaveOrgModal/ConfirmLeaveOrgPopupModal'
import UnclaimedDisclaimer from '../CommonComponents/UnclaimedDisclaimer/UnclaimedDisclaimer'
import { isMobile } from 'react-device-detect'
import ProfileReviewStars from '../CommonComponents/ProfileReviewStars/ProfileReviewStars'
import ConfirmDeleteReviewModal, {
  hideConfirmDeleteReviewModal,
  showConfirmDeleteReviewModal,
} from '../CommonComponents/ConfirmDeleteReviewModal/ConfirmDeleteReviewModal'
import { deleteReviewCall } from '../../services/reviewApiService'
import ISupport from '../CommonComponents/ISupport/ISupport'
class ProfileOtherUser extends Component {
  constructor(props) {
    super(props)

    this.TrustDirectoryTabRef = React.createRef()
    this.friendTabRef = React.createRef()
    this.memberTabRef = React.createRef()
    this.galleryTabRef = React.createRef()
    this.overviewGenericTabRef = React.createRef()
    this.reviewTabRef = React.createRef()
    this.orgsTotalTabRef = React.createRef()
    this.orgsCommonTabRef = React.createRef()

    this.state = {
      logged_in_user: customFunction.getUser(),
      user_id: '',
      user: null,
      userslug: props.match.params.id,
      trust_directory_total_records: 0,
      friend_total_records: 0,
      member_total_records: 0,
      gallery_total_records: 0,
      orgs_total_records: 0,
      mutual_orgs_total_records: 0,
      post_id: props.match.params.post_id ? props.match.params.post_id : '',
      overall_reviews: {
        total_reviews: 0,
        total_received_reviews: 0,
        total_given_reviews: 0,
      },
      avg_reviews: {
        given_reviews: 0,
        received_reviews: 0,
      },
      isLoginUserClick: true,
      userOther: {},
      loginUserType: customFunction.getUser().user_type,
      user_settings: {},
      isSettingToShowProfilePage: false,
      commonMemberList: [],
      totalCommonMember: 0,
      itrustitRemoveData: {},
      friendRemoveData: {},
      friendHandler: null,
      itrustitHandler: null,
      loadingFriendStatusChange: false,
      loadingitrustitStatusChange: false,
      documentTitle: PROFILE_TITLE,
      confirmModalProps: null,
      inviteModalProps: null,
      is_member_user: 0,
    }
    this.fetchUserData()
    window.onscroll = () => {
      this.overallInfiniteScroll()
    }
    this.scrollRef = React.createRef()
  }
  fetchUserData = async () => {
    let token = customFunction.userToken()
    if (customFunction.isUserLogin() && customFunction.userToken()) {
      const response = await userProfile(token)
      if (response.status === true) {
        const { profileInfo } = response
        customFunction.setUser(profileInfo)
        if (profileInfo) {
          this.setState({ logged_in_user: profileInfo })
        }
      } else {
        this.props.history.push({ pathname: route.LOGIN_ROUTE })
      }
    }
  }

  overallInfiniteScroll() {
    // console.log(this.state.activeTab);
    switch (this.state.activeTab) {
      case '#friend':
        this.friendTabRef.current && this.friendTabRef.current.infiniteScroll()
        break
      case '#trust':
        this.TrustDirectoryTabRef.current && this.TrustDirectoryTabRef.current.infiniteScroll()
        break
      case '#member':
        this.memberTabRef.current && this.memberTabRef.current.infiniteScroll()
        break
      case '#orgs_total':
        this.orgsTotalTabRef.current && this.orgsTotalTabRef.current.infiniteScroll()
        break
      case '#orgs_common':
        this.orgsCommonTabRef.current && this.orgsCommonTabRef.current.infiniteScroll()
        break

      default:
        break
    }
  }

  setLoadingFriendStatusChange = (value = false) => {
    //NEEDS WORK
    //this.setState({ loadingFriendStatusChange: value });
  }

  setLoadingitrustitStatusChange = (value = false) => {
    //console.log('setting to ', value);
    //NEEDS WORK
    //this.setState({ loadingitrustitStatusChange: value });
  }

  confirmitrustitAddRemove = (needsConfirmation = false, data, tab) => {
    // console.log(needsConfirmation, data);
    let handler
    switch (tab) {
      case 'friend':
        handler = this.friendTabRef.current.itrustitHandle
        break
      case 'member':
        handler = this.memberTabRef.current.itrustitHandle
        break
      case 'trust':
        handler = this.TrustDirectoryTabRef.current.itrustitHandle
        break
      case 'header':
        handler = this.itrustitTrustHandle
        break
      case 'orgs_total':
        handler = this.orgsTotalTabRef.current.itrustitHandle
        // console.log(handler);
        break
      case 'orgs_common':
        handler = this.orgsCommonTabRef.current.itrustitHandle
        // console.log(handler);
        break
      default:
        handler = this.friendTabRef.current.itrustitHandle
        break
    }
    this.setState({
      itrustitRemoveData: data,
      itrustitHandler: handler,
    })
    needsConfirmation && $J('#confirm_untrust_popup_modal').modal('show')
  }

  confirmFriendAddRemove = (needsConfirmation = false, data, tab) => {
    // console.log(needsConfirmation, data);
    let handler
    switch (tab) {
      case 'friend':
        handler = this.friendTabRef.current.friendAddRemoveHandle
        break
      case 'member':
        handler = this.memberTabRef.current.friendAddRemoveHandle
        break
      case 'trust':
        handler = this.TrustDirectoryTabRef.current.friendAddRemoveHandle
        break
      case 'header':
        handler = this.friendAddRemoveFriendHandle
        break
      case 'orgs_total':
        handler = this.orgsTotalTabRef.current.friendAddRemoveHandle
        break
      case 'orgs_common':
        handler = this.orgsCommonTabRef.current.friendAddRemoveHandle
        break
      default:
        handler = this.friendTabRef.current.friendAddRemoveHandle
        break
    }
    this.setState({
      friendRemoveData: data,
      friendHandler: handler,
    })
    needsConfirmation && $J('#confirm_unfriend_popup_modal').modal('show')
  }

  componentDidMount() {
    if (this.props.location.state) {
      this.showTrustDirectoryTab(this.props.location.state.type)
    }
    this.state.activeTab !== '#overview'
      ? setTimeout(() => {
          window.scrollTo({
            top: ($J(this.state.activeTab)?.offset()?.top ?? 125) - 125,
            behavior: 'smooth',
          })
        }, 1000)
      : window.scrollTo(0, 0)

    this.getProfileFromId()
  }

  componentWillReceiveProps(nextProps) {
    // alert(nextProps.location.hash);
    const tabs = [
      '#friend',
      '#activity',
      '#trust',
      '#invite_center',
      '#orgs_total',
      '#orgs_common',
      '#gallery',
      '#privacy-settings',
      '#notifications',
      '#itrustittree',
      '#activity',
      '#review',
      '#posts',
    ]
    let activeTab = customFunction.isPostTabVisible(this?.state?.user) ? '#posts' : '#overview'
    if (tabs.includes(nextProps.location.hash)) {
      activeTab = nextProps.location.hash
    }
    //fix only for post bc I'm not sure about other tabs
    if (tabs.includes(window.location.hash) && window.location.hash === '#posts') {
      activeTab = window.location.hash
    }

    if (this.state.activeTab === '#review' && !nextProps.location.hash) {
      activeTab = '#review'
    }

    this.setState({ activeTab: activeTab }, () =>
      window.scrollTo({
        top: $J(this.state.activeTab)?.offset()?.top - 125 ?? 0,
        behavior: 'smooth',
      }),
    )

    if (nextProps.location.state && nextProps.location.state.type) {
      this.showTrustDirectoryTab(nextProps.location.state.type)
    }

    let user_id_new = nextProps.match.params.id
    let user_id_old = this.state.userslug

    if (user_id_new !== user_id_old) {
      window.location.reload()
      this.props.reviewUser({})
      this.setState({ userslug: user_id_new }, () => {
        window.scrollTo(0, 0)
        //add line
        this.overviewGenericTabRef.current.profileHeader_overview_tab_click_Handle()
        this.getProfileFromId()
      })
    }

    if (Object.keys(nextProps?.reviewuser)?.length > 0 || nextProps?.reviewuser?.reset) {
      this.setState(
        {
          userOther: nextProps?.reviewuser?.reset ? this.state.user : nextProps.reviewuser,
          isLoginUserClick: nextProps.reviewuser.isLoginUserClick ? true : false,
        },
        () => {
          $J('#reviewCreateModal').modal('show')
        },
      )
    }
  }

  // CALL USER DETAIL
  getProfileFromId() {
    let getProfileFromIdReqData = {
      userslug: this.state.userslug,
    }
    let self = this

    getProfileFromId(getProfileFromIdReqData).then(function (response) {
      if (response.data.length === 0) {
        self.props.history.push({
          pathname: route.ROOT_ROUTE,
        })
        return false
      }

      let profileData = response.data
      let user_settings = profileData.user_settings

      if (profileData.is_business_claimed === '1' && profileData.is_email_confirmed === '0') {
        self.props.history.push({
          pathname: route.ROOT_ROUTE,
        })
      }

      if (profileData.id === customFunction.getUser().id) {
        self.props.history.push({
          pathname: route.PROFILE_ROUTE,
        })
      }
      user_settings['is_friend_user'] = profileData.is_friend_user
      user_settings['is_friend_of_friends_user'] = profileData.is_friend_of_friends_user
      user_settings['is_member_user'] = profileData.is_member_user
      let documentTitle =
        PROFILE_TITLE +
        ' - ' +
        (profileData.business_organization_name
          ? profileData.business_organization_name
          : profileData.fullname)
      profileData.total_friends_of_friend_trusted = null //So it will show loading spinner until updated in next call
      let stateData = {
        user: profileData,
        userOther: profileData,
        user_id: profileData.id,
        activeTab: window.location.hash
          ? window.location.hash
          : customFunction.isPostTabVisible(profileData)
          ? '#posts'
          : '#overview',
        friend_total_records: profileData.total_friends,
        trust_directory_total_records:
          profileData.total_trusted_by + profileData.total_trusted_by_me,
        gallery_total_records: profileData.total_galleries,
        member_total_records: profileData.total_members,
        orgs_total_records: profileData.total_records_total_orgs,
        mutual_orgs_total_records: profileData.total_records_mutual_orgs,
        user_settings,
        documentTitle,
        is_member_user: profileData?.is_member_user,
      }

      if (
        user_settings.profile_view_by === 'Everybody including non-logged in users' ||
        (user_settings.profile_view_by === 'All logged in users' && customFunction.getUser().id) ||
        (user_settings.profile_view_by === 'Friends and friends of friends' &&
          (user_settings.is_friend_user || user_settings.is_friend_of_friends_user)) ||
        (user_settings.profile_view_by === 'Friends' && user_settings.is_friend_user) ||
        (user_settings.profile_view_by === 'Members only' && user_settings.is_member_user)
      ) {
        if (!self.state.isSettingToShowProfilePage) {
          self.setState({
            isSettingToShowProfilePage: true,
          })
        }
      } else {
        if (self.state.isSettingToShowProfilePage) {
          self.setState({
            isSettingToShowProfilePage: false,
          })
        }
      }

      self.setState(stateData, () => {
        self.callCommonMemberList()
        self.getProfileFromIdOnlyProfile()
      })
    })
  }

  getProfileFromIdOnlyProfile() {
    //console.log("this.state.userslug", this.state.userslug);

    let getProfileFromIdReqData = {
      userslug: this.state.userslug,
    }
    let self = this
    getProfileFromIdOnlyProfile(getProfileFromIdReqData).then(function (response) {
      if (response.data.length === 0) {
        self.props.history.push({
          pathname: route.ROOT_ROUTE,
        })
        return false
      }
      let profileData = response.data
      let user_settings = profileData.user_settings

      if (profileData.is_business_claimed === '1' && profileData.is_email_confirmed === '0') {
        self.props.history.push({
          pathname: route.ROOT_ROUTE,
        })
      }

      if (profileData.id === customFunction.getUser().id) {
        self.props.history.push({
          pathname: route.PROFILE_ROUTE,
        })
      }
      user_settings['is_friend_user'] = profileData.is_friend_user
      user_settings['is_friend_of_friends_user'] = profileData.is_friend_of_friends_user
      user_settings['is_member_user'] = profileData.is_member_user
      let documentTitle =
        PROFILE_TITLE +
        ' - ' +
        (profileData.business_organization_name
          ? profileData.business_organization_name
          : profileData.fullname)
      let stateData = {
        user: profileData,
        userOther: profileData,
        user_id: profileData.id,
        friend_total_records: profileData.total_friends,
        trust_directory_total_records:
          profileData.total_trusted_by + profileData.total_trusted_by_me,
        gallery_total_records: profileData.total_galleries,
        member_total_records: profileData.total_members,
        user_settings,
        documentTitle,
      }

      if (
        user_settings.profile_view_by === 'Everybody including non-logged in users' ||
        (user_settings.profile_view_by === 'All logged in users' && customFunction.getUser().id) ||
        (user_settings.profile_view_by === 'Friends and friends of friends' &&
          (user_settings.is_friend_user || user_settings.is_friend_of_friends_user)) ||
        (user_settings.profile_view_by === 'Friends' && user_settings.is_friend_user) ||
        (user_settings.profile_view_by === 'Members only' && user_settings.is_member_user)
      ) {
        if (!self.state.isSettingToShowProfilePage) {
          self.setState({
            isSettingToShowProfilePage: true,
          })
        }
      } else {
        if (self.state.isSettingToShowProfilePage) {
          self.setState({
            isSettingToShowProfilePage: false,
          })
        }
      }
      self.setState(stateData, () => {})
    })
  }

  itrustitHandle = (data) => {
    //this.getProfileFromId();
    this.setState({
      totalCommonMember: 0,
    })
    //if( window.innerWidth < 992 ){
    this.callCommonMemberList()
    //}
  }

  itrustitTrustHandle = (data) => {
    let userList = this.state.user
    let trust_directory_total_records_count = this.state.trust_directory_total_records

    if (data.is_trusted_user === 1) {
      userList['is_trusted_user'] = 0
      trust_directory_total_records_count = trust_directory_total_records_count - 1
    } else {
      userList['is_trusted_user'] = 1
      trust_directory_total_records_count = trust_directory_total_records_count + 1
    }

    this.setState({
      user: userList,
      trust_directory_total_records: trust_directory_total_records_count,
    })

    this.getProfileFromId()
    this.setState({
      totalCommonMember: 0,
    })
    this.callCommonMemberList()
  }

  friendAddRemoveFriendHandle = (data) => {
    let userList = this.state.user
    let user_settings = userList.user_settings
    let friend_total_records_count = this.state.friend_total_records

    if (data.is_friend_user === 1) {
      userList['is_friend_user'] = 0
      user_settings['is_friend_user'] = 0
      friend_total_records_count = friend_total_records_count - 1
    } else {
      userList['is_friend_user'] = 1
      user_settings['is_friend_user'] = 1
      friend_total_records_count = friend_total_records_count + 1
    }

    this.setState({
      user: userList,
      user_settings,
      friend_total_records: friend_total_records_count,
    })

    this.getProfileFromId()
  }

  friendAddRemoveHandle = (data) => {
    this.getProfileFromId()
  }

  memberAddRemoveHandle = (data) => {
    let userList = this.state.user
    let user_settings = userList.user_settings
    let member_total_records_count = this.state.member_total_records

    if (data.is_member_user === 1) {
      userList['is_member_user'] = 0
      user_settings['is_member_user'] = 0
      member_total_records_count = member_total_records_count - 1
    } else {
      userList['is_member_user'] = 1
      user_settings['is_member_user'] = 1
      member_total_records_count = member_total_records_count + 1
    }

    this.setState({
      user: userList,
      user_settings,
      member_total_records: member_total_records_count,
    })

    this.getProfileFromId()
  }

  showiTrustitTab = (data) => (event) => {
    $J(`#profile-tabs a[href="trust"]`).tab('show')
    window.scrollTo({
      top: $J('#trust').offset().top - 125,
      behavior: 'smooth',
    })

    window.history.pushState(null, null, '#trust')
    this.TrustDirectoryTabRef.current.profileHeader_itrustit_tab_click_Handle(data)
  }

  showFriendsTab = (data, mutual) => (event) => {
    window.scrollTo({
      top: $J('#friend').offset().top - 125,
      behavior: 'smooth',
    })

    this.setState({
      activeTab: '#friend',
    })
    window.history.pushState(null, null, '#friend')
    this.friendTabRef.current.profileHeader_friend_tab_click_Handle(data, mutual)
  }

  showTrustDirectoryTab = (data = '') => {
    $J(`#profile-tabs a[href="trust"]`).tab('show')
    window.scrollTo({
      top: $J('#trust').offset().top - 125,
      behavior: 'smooth',
    })

    window.history.pushState(null, null, '#trust')
    this.setState({
      activeTab: '#trust',
    })
    this.TrustDirectoryTabRef.current.profileHeader_trustmeby_tab_click_Handle(data)
  }

  changeActiveTab = (data) => (event) => {
    //console.log('changeActiveTab:', data)
    this.setState({
      activeTab: data,
    })
    window.history.pushState(null, null, data)

    window.scrollTo({
      top: $J(data)?.offset()?.top - 125,
      behavior: 'smooth',
    })

    if (data === '#trust') {
      //this.TrustDirectoryTabRef.current.profileHeader_itrustit_tab_click_Handle()
    } else if (data === '#friend') {
      //this.friendTabRef.current.profileHeader_friend_tab_click_Handle()
    } else if (data === '#member') {
      //this.memberTabRef.current.profileHeader_member_tab_click_Handle()
    } else if (data === '#gallery') {
      this.galleryTabRef.current.profileHeader_gallery_tab_click_Handle()
    } else if (data === '#overview') {
      this.overviewGenericTabRef.current.profileHeader_overview_tab_click_Handle()
    } else if (data === '#review') {
      //this.reviewTabRef.current.profileHeader_review_tab_click_Handle();
    } else if (data === '#orgs_total') {
      //this.orgsTotalTabRef.current.profileHeader_orgs_total_tab_click_Handle()
    } else if (data === '#orgs_common') {
      //this.orgsCommonTabRef.current.profileHeader_orgs_common_tab_click_Handle()
    }
  }

  showReviewTab = (data) => {
    window.scrollTo({
      top: $J('#review').offset().top - 125,
      behavior: 'smooth',
    })

    this.setState({
      activeTab: '#review',
    })
    this.reviewTabRef.current.profileHeader_review_tab_click_Handle(data)
    window.history.pushState(null, null, '#review')
  }

  showReviewTab2 = (data) => {
    window.scrollTo({
      top: $J('#review').offset().top - 125,
      behavior: 'smooth',
    })

    this.setState({
      activeTab: '#review',
    })
    this.reviewTabRef.current.profileHeader_review_tab_click_Handle(data)
    window.history.pushState(null, null, '#review')
  }

  profileHeader_itrustit_Handle = (total_records) => {
    this.setState({
      trust_directory_total_records: total_records,
    })
  }
  profileHeader_itrustit_recall_Handle = () => {
    this.TrustDirectoryTabRef.current.profileHeader_itrustit_tab_click_Handle()
    this.getProfileFromId()
  }

  profileHeader_friend_Handle = (total_records) => {
    // console.log('profileHeader_friend_Handle :', total_records);
    this.setState({
      friend_total_records: total_records,
    })
  }
  profileHeader_friend_recall_Handle = () => {
    this.friendTabRef.current.profileHeader_friend_tab_click_Handle()
    this.getProfileFromId()
  }

  profileHeader_gallery_Handle = (total_records) => {
    this.setState({
      gallery_total_records: total_records,
    })
  }

  profileHeader_member_Handle = (total_records) => {
    this.setState({
      member_total_records: total_records,
    })
  }

  profileHeader_mutual_orgs_Handle = (total_records) => {
    if (total_records && total_records.total_records) {
      this.setState({
        mutual_orgs_total_records: total_records.total_records,
      })
    }
  }

  profileHeader_total_orgs_Handle = (total_records) => {
    if (total_records && total_records.total_records) {
      this.setState({
        orgs_total_records: total_records.total_records,
      })
    }
  }

  profileHeader_review_Handle = (overall_reviews, avg_reviews) => {
    this.setState({
      overall_reviews: overall_reviews,
      avg_reviews: avg_reviews,
    })
  }

  reviewCreatedSuccessfullyHandler = () => {
    this.reviewTabRef.current.profileHeader_review_tab_click_Handle('received')
    this.overviewGenericTabRef.current.profileHeader_overview_tab_review_section_Handle()
  }

  callCommonMemberList() {
    const self = this

    if (this.state.user.id) {
      makeCommonMemberCall(this.state.user.id, 'common_member', 1).then(function (response) {
        if (response.data.user_list.length === 0) {
          self.setState({
            commonMemberList: response.data.user_list,
          })
        } else {
          self.setState({
            commonMemberList: response.data.user_list,
            totalCommonMember: response.data.total_records,
          })
        }
      })
    }
  }

  showOrgsCommonDirectoryTab = (data) => {
    window.history.pushState(null, null, '#orgs_common')
    if (data !== '') {
      $J('.nav-tabs a[href="' + data + '"]').tab('show')
      this.changeActiveTab(data)
    }
    window.scrollTo({
      top: $J('#orgs_common').offset().top - 125,
      behavior: 'smooth',
    })
  }

  setModalProps = (modalProps, needsConfirmation, cleanup = () => {}) => {
    this.setState(
      { confirmModalProps: modalProps.confirm, inviteModalProps: modalProps.invite },
      () => {
        needsConfirmation
          ? $J('#confirm_remove_from_org').modal('show')
          : this.memberAddRemoveYour(
              {
                is_your_member_user: modalProps.confirm.dataYour?.is_your_member_user,
                id: modalProps.confirm.user_id,
                flag: 'member_of_your_organization',
              },
              cleanup,
            )
      },
    )
  }

  memberAddRemoveYour = async (data, cleanup) => {
    let memberAddRemoveReqData = {
      id: data.id,
      member_type: data.flag,
    }
    if (data.is_your_member_user !== 0) {
      const res = await removeMemberCall(memberAddRemoveReqData)
      if (res?.status === 200) {
        cleanup?.()
        // let apiResponseData = response.data
        // self.setState({ is_your_member_user: 0 })
      }
    } else if (data.is_your_member_user !== 2) {
      $J('#invite_modal').modal('show')
    }
  }

  setStatusOfMemberUser = (status) => {
    let newStatus = 0
    if (status === 'pending') {
      newStatus = 2
    } else if (status === 'accepted') {
      newStatus = 1
    } else {
      newStatus = 0
    }
    this.setState({ is_member_user: newStatus }, () => this.getProfileFromId())
  }

  confirmMemberAddRemove = (needsConfirmation) => {
    needsConfirmation
      ? $J('#confirm_leave_org').modal('show')
      : this.memberAddRemove({
          is_member_user: this.state.is_member_user,
          id: this.state.user.id,
          flag: 'member_of_their_organization',
        })
  }
  memberAddRemove = async (data) => {
    const self = this
    // this.setState({ memberType: data.flag, })
    let request = { id: data.id, member_type: 'member_of_their_organization' }
    if (this.state.is_member_user === 0) {
      // this.setLoadingMemberChangeResponse(false)
      $J('#member_modal').modal('show')
    } else if ([1, 2].includes(this.state.is_member_user)) {
      const response = await removeMemberCall(request)
      response.status === 200 && self.setState({ is_member_user: 0 }, () => this.getProfileFromId())
      // self.setLoadingMemberChangeResponse(false)
    }
  }

  confirmDeleteReview = (reviewId) => {
    this.setState({ reviewIdToDelete: reviewId }, () => showConfirmDeleteReviewModal())
  }

  deleteReview = async () => {
    const response = await deleteReviewCall({ id: this.state.reviewIdToDelete })
    if (response?.status === 200) {
      this.setState({
        user: { ...this.state.user, total_given_review: this.state.user.total_given_review - 1 },
      })
      hideConfirmDeleteReviewModal()
      customFunction.toaster('success', response?.data?.message)
    }
    this.reviewTabRef.current.profileHeader_review_tab_click_Handle('received')
  }

  render() {
    // console.log(this.friendTabRef.current);
    //   console.log("user", this.state.user);
    let login_user_id = customFunction.getUser().id

    const { user, overall_reviews, avg_reviews } = this.state
    if (!user) {
      return null
    }

    let userHeaderTrustSection = null
    let noPaddingContainer =
      isMobile && this.state.activeTab?.includes('posts') ? 'container body-container' : 'container'
    if (user.user_type === 'individual') {
      userHeaderTrustSection = (
        <div className='i_trust_badge'>
          <div className='container'>
            <div className={'badge ' + user.user_type + '_profile'}>
              <div className='b-triangle' />
              <div className='b-inner'>
                <h4>iTRUST</h4>
                <div className='d-flex d1'>
                  <div className='col-6 digits_cat'>
                    <a
                      onClick={this.showiTrustitTab('business')}
                      className={
                        ' ' +
                        (this.state.activeTab === '#trust' ? 'active digit_name' : 'digit_name')
                      }
                      // data-bs-toggle='tab'
                      href='#trust'
                    >
                      <div className='digit'>{user.total_trusted_business}</div>
                      business
                    </a>
                  </div>
                  <div className='col-6 digits_cat'>
                    <a
                      onClick={this.showiTrustitTab('organization')}
                      className={
                        ' ' +
                        (this.state.activeTab === '#trust' ? 'active digit_name' : 'digit_name')
                      }
                      // data-bs-toggle='tab'
                      href='#trust'
                    >
                      <div className='digit'>{user.total_trusted_organizations}</div>
                      organization
                    </a>
                  </div>
                </div>

                {this.state.totalCommonMember > 0 && (
                  <div className='it-person-list'>
                    <div className='d-flex'>
                      <div className='col-12 col-md-12'>
                        <strong>Mutual Organizations</strong>
                      </div>
                    </div>
                    <div className='d-flex'>
                      <div
                        className='col-12 col-md-12 it-person'
                        onClick={(e) => this.showOrgsCommonDirectoryTab('#orgs_common')}
                      >
                        {this.state.commonMemberList.map(
                          (obj, index) =>
                            index < 3 && (
                              <div key={obj.id} className='it-person-img'>
                                <img
                                  src={
                                    obj.profile_picture_medium != null
                                      ? obj.profile_picture_medium
                                      : DEFAULT_PROFILE_PICTURE
                                  }
                                  width='50'
                                  alt=''
                                />
                              </div>
                            ),
                        )}
                        {this.state.totalCommonMember > 3 && (
                          <div className='it-person-number'>
                            <span>+{this.state.totalCommonMember - 3}</span>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )
    } else {
      userHeaderTrustSection = (
        <div className='i_trust_badge'>
          <div className='container'>
            <div className={'badge ' + user.user_type + '_profile'}>
              <div className='b-triangle' />
              <div className='b-inner'>
                <h4>Trusted by</h4>
                <div className='d-flex d1'>
                  <div className='col-4 digits_cat'>
                    <a
                      onClick={(e) => this.showTrustDirectoryTab('friends')}
                      className={
                        '' +
                        (this.state.activeTab === '#trust' ? 'active digit_name' : 'digit_name')
                      }
                      // data-bs-toggle='tab'
                      href='#trust'
                    >
                      <div className='digit' style={{ color: '#136eb7' }}>
                        {user.total_friends_trusted ?? (
                          <div className='search-page-loading-icon orange'>
                            <i style={{ width: 27, height: 27, backgroundSize: 40 }} />
                          </div>
                        )}
                      </div>
                      <div className='digit_name'>friends</div>
                    </a>
                  </div>
                  <div className='col-4 digits_cat'>
                    <a
                      onClick={(e) => this.showTrustDirectoryTab('friends_of_friends')}
                      className={
                        ' ' +
                        (this.state.activeTab === '#trust' ? 'active digit_name' : 'digit_name')
                      }
                      // data-bs-toggle='tab'
                      href='#trust'
                    >
                      <div className='digit' style={{ color: '#136eb7' }}>
                        {user.total_friends_of_friend_trusted ?? (
                          <div className='search-page-loading-icon'>
                            <i style={{ width: 27, height: 27, backgroundSize: 40 }} />
                          </div>
                        )}
                      </div>
                      <div className='digit_name'>friends of friends</div>
                    </a>
                  </div>
                  <div className='col-4 digits_cat'>
                    <a
                      onClick={(e) => this.showTrustDirectoryTab('')}
                      className={
                        '' +
                        (this.state.activeTab === '#trust' ? 'active digit_name' : 'digit_name')
                      }
                      // data-bs-toggle='tab'
                      href='#trust'
                    >
                      <div className='digit' style={{ color: '#136eb7' }}>
                        {user.total_trusted}
                      </div>
                      <div className='digit_name'>total</div>
                    </a>
                  </div>
                </div>
                {this.state.totalCommonMember > 0 && (
                  <div className='it-person-list'>
                    <div className='d-flex'>
                      <div className='col-12 col-md-12'>
                        <strong>Mutual Organizations</strong>
                      </div>
                    </div>
                    <div className='d-flex'>
                      <div
                        className='col-12 col-md-12 it-person'
                        onClick={(e) => this.showOrgsCommonDirectoryTab('#orgs_common')}
                      >
                        {this.state.commonMemberList.map(
                          (obj, index) =>
                            index < 3 && (
                              <div key={obj.id} className='it-person-img'>
                                <img
                                  src={
                                    obj.profile_picture_medium != null
                                      ? obj.profile_picture_medium
                                      : DEFAULT_PROFILE_PICTURE
                                  }
                                  width='50'
                                  alt=''
                                />
                              </div>
                            ),
                        )}
                        {this.state.totalCommonMember > 3 && (
                          <div className='it-person-number'>
                            <span>+{this.state.totalCommonMember - 3}</span>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )
    }

    return (
      <AppLayout>
        <Helmet>
          <title>{this.state.documentTitle}</title>
        </Helmet>
        {this.state.isSettingToShowProfilePage ? (
          <div style={{ background: '#f6f6f6' }}>
            <section
              className='cover_details pro_business my_profile'
              style={{
                background:
                  'url(' +
                  (this.state.user &&
                  typeof this.state.user.cover_picture_medium !== 'undefined' &&
                  this.state.user.cover_picture_medium
                    ? this.state.user.cover_picture_medium
                    : NO_COVER_PICTURE) +
                  ') no-repeat',
              }}
            >
              {userHeaderTrustSection}
            </section>
            <div className='container profile-info-container'>
              <div
                className={`cover-inner-wrapper${
                  this.state.user?.show_unclaimed_disclaimer === '1' ? ' unclaimed-user' : ''
                } ${this.state.user.is_business_claimed === '0' ? 'unclaimed' : ''}`}
              >
                <div
                  className={`cover_inner ${
                    this.state.user.is_business_claimed !== '0' &&
                    this.state.user?.show_unclaimed_disclaimer === '1'
                      ? 'mb-3 mb-md-0'
                      : ''
                  }`}
                >
                  <ProfileHeader
                    user={this.state.user}
                    confirmitrustitAddRemove={this.confirmitrustitAddRemove}
                    confirmFriendAddRemove={this.confirmFriendAddRemove}
                    confirmMemberAddRemove={this.confirmMemberAddRemove}
                    itrustitTrustHandle={this.itrustitTrustHandle}
                    friendAddRemoveFriendHandle={this.friendAddRemoveFriendHandle}
                    memberAddRemove={this.memberAddRemove}
                    memberAddRemoveHandle={this.memberAddRemoveHandle}
                  />
                  <div className='cover_bottom_wrapper position-relative'>
                    <div className='cover_bottom' ref={this.scrollRef}>
                      <div className='left_link'>
                        <div
                          className='position-absolute profile-scroll-btn'
                          style={{ top: 9, left: 4, zIndex: 2000 }}
                        >
                          <button
                            className='promotion-back-button'
                            onClick={() => (this.scrollRef.current.scrollLeft -= 100)}
                          >
                            <i
                              className='fas fa-chevron-left'
                              aria-hidden='true'
                              style={{ color: '#999' }}
                            ></i>
                          </button>
                        </div>
                        <ul id='profile-tabs' className='upper-tabs nav nav-tabs'>
                          <li className='nav-item'>
                            <a
                              onClick={this.changeActiveTab('#overview')}
                              className={
                                'nav-link ' + (this.state.activeTab === '#overview' ? 'active' : '')
                              }
                              data-bs-toggle='tab'
                              href='#overview'
                            >
                              Overview
                            </a>
                          </li>
                          {customFunction.isPostTabVisible(this?.state?.user) ? (
                            <li className='nav-item'>
                              <a
                                onClick={this.changeActiveTab('#posts')}
                                className={
                                  'nav-link ' + (this.state.activeTab === '#posts' ? 'active' : '')
                                }
                                data-bs-toggle='tab'
                                href='#posts'
                              >
                                Posts
                              </a>
                            </li>
                          ) : (
                            ''
                          )}
                          <li className='nav-item'>
                            <a
                              onClick={this.changeActiveTab('#trust')}
                              className={
                                'nav-link ' + (this.state.activeTab === '#trust' ? 'active' : '')
                              }
                              data-bs-toggle='tab'
                              href='#trust'
                            >
                              {' '}
                              Trust List ({this.state.trust_directory_total_records})
                            </a>
                          </li>
                          {user.user_type === 'organization' && (
                            <li className='nav-item'>
                              <a
                                onClick={this.changeActiveTab('#member')}
                                className={
                                  'nav-link ' + (this.state.activeTab === '#member' ? 'active' : '')
                                }
                                data-bs-toggle='tab'
                                href='#member'
                              >
                                {formatMemberDirectoryName(
                                  this.state.user_settings?.member_directory_name,
                                  this.state.user_settings?.custom_member_directory_name,
                                ) ?? 'Member Directory'}{' '}
                                ({this.state.member_total_records})
                              </a>
                            </li>
                          )}
                          <li className='nav-item'>
                            <a
                              onClick={this.changeActiveTab('#friend')}
                              className={
                                'nav-link ' + (this.state.activeTab === '#friend' ? 'active' : '')
                              }
                              data-bs-toggle='tab'
                              href='#friend'
                            >
                              Friends ({this.state.friend_total_records})
                            </a>
                          </li>

                          <li className='nav-item me-2'>
                            <a
                              onClick={this.changeActiveTab('#orgs_total')}
                              className={
                                'nav-link ' +
                                (this.state.activeTab === '#orgs_total' ? 'active' : '')
                              }
                              data-bs-toggle='tab'
                              href='#orgs_total'
                            >
                              {/* Orgs Total */}
                              Orgs ({this.state.orgs_total_records})
                            </a>
                          </li>

                          <li className='nav-item'>
                            <a
                              onClick={this.changeActiveTab('#orgs_common')}
                              className={
                                'nav-link ' +
                                (this.state.activeTab === '#orgs_common' ? 'active' : '')
                              }
                              data-bs-toggle='tab'
                              href='#orgs_common'
                            >
                              {/* Orgs In Common */}
                              Mutual ({this.state.mutual_orgs_total_records})
                            </a>
                          </li>

                          <li className='nav-item me-0'>
                            <a
                              onClick={this.changeActiveTab('#gallery')}
                              className={
                                'nav-link ' + (this.state.activeTab === '#gallery' ? 'active' : '')
                              }
                              data-bs-toggle='tab'
                              href='#gallery'
                            >
                              Gallery ({this.state.gallery_total_records})
                            </a>
                          </li>
                        </ul>
                        <div
                          className='position-absolute profile-scroll-btn'
                          style={{ top: 9, right: 4, zIndex: 2000 }}
                        >
                          <button
                            className='promotion-back-button'
                            onClick={() => (this.scrollRef.current.scrollLeft += 100)}
                          >
                            <i
                              className='fas fa-chevron-right'
                              aria-hidden='true'
                              style={{ color: '#999' }}
                            ></i>
                          </button>
                        </div>
                      </div>

                      <ProfileReviewStars
                        user={user}
                        avg_reviews={avg_reviews}
                        overall_reviews={overall_reviews}
                        showReviewTab={this.showReviewTab}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <section
              className={`listing_block${
                this.state.user?.show_unclaimed_disclaimer === '1' ? ' unclaimed-user' : ''
              } ${this.state.user.is_business_claimed === '0' ? 'unclaimed' : ''} padding-reset`}
            >
              <div
                className={`${noPaddingContainer} ${
                  this.state.user?.show_unclaimed_disclaimer === '1' ||
                  (isMobile && this.state.activeTab === '#overview')
                    ? 'mt-2'
                    : 'mt-4'
                }`}
              >
                {this.state.user?.show_unclaimed_disclaimer === '1' ? (
                  <UnclaimedDisclaimer fullname={this.state.user?.fullname} />
                ) : isMobile && this.state.activeTab === '#overview' ? (
                  <ISupport user={this.state.user} className='ms-2' />
                ) : null}
                <div
                  className={`tab-content ${
                    this.state.user?.show_unclaimed_disclaimer === '1' ||
                    (isMobile && this.state.activeTab === '#overview')
                      ? 'mt-2'
                      : 'mt-4'
                  }`}
                >
                  <OverviewGenericTab
                    setModalProps={this.setModalProps}
                    user_id={this.state.user_id}
                    user={this.state.user}
                    logged_in_user={this.state.logged_in_user}
                    activeTab={this.state.activeTab}
                    ref={this.overviewGenericTabRef}
                    showReviewTab2={this.showReviewTab2}
                    changeActiveTab={this.changeActiveTab}
                    showFriendsTab={this.showFriendsTab}
                    loginUserType={this.state.loginUserType}
                    user_settings={this.state.user_settings}
                    post_id={this.state.post_id}
                  />
                  <TrustDirectoryTab
                    setModalProps={this.setModalProps}
                    user_id={this.state.user_id}
                    user={this.state.user}
                    user_type={this.state.user.user_type}
                    activeTab={this.state.activeTab}
                    ref={this.TrustDirectoryTabRef}
                    confirmitrustitAddRemove={this.confirmitrustitAddRemove}
                    confirmFriendAddRemove={this.confirmFriendAddRemove}
                    setLoadingFriendStatusChange={this.setLoadingFriendStatusChange}
                    loadingFriendStatusChange={this.state.loadingFriendStatusChange}
                    loadingitrustitStatusChange={this.state.loadingitrustitStatusChange}
                    setLoadingitrustitStatusChange={this.setLoadingitrustitStatusChange}
                    profileHeader_itrustit_Handle={this.profileHeader_itrustit_Handle}
                    profileHeader_friend_recall_Handle={this.profileHeader_friend_recall_Handle}
                    itrustitHandle={this.itrustitHandle}
                    user_settings={this.state.user_settings}
                    changeActiveTab={this.changeActiveTab}
                  />
                  <GalleryTab
                    user_id={this.state.user_id}
                    loginUserId={login_user_id}
                    user={this.state.user}
                    activeTab={this.state.activeTab}
                    ref={this.galleryTabRef}
                    profileHeader_gallery_Handle={this.profileHeader_gallery_Handle}
                    user_settings={this.state.user_settings}
                  />
                  <FriendsTab
                    setModalProps={this.setModalProps}
                    user_id={this.state.user_id}
                    user={this.state.user}
                    activeTab={this.state.activeTab}
                    ref={this.friendTabRef}
                    confirmitrustitAddRemove={this.confirmitrustitAddRemove}
                    confirmFriendAddRemove={this.confirmFriendAddRemove}
                    setLoadingFriendStatusChange={this.setLoadingFriendStatusChange}
                    loadingFriendStatusChange={this.state.loadingFriendStatusChange}
                    loadingitrustitStatusChange={this.state.loadingitrustitStatusChange}
                    setLoadingitrustitStatusChange={this.setLoadingitrustitStatusChange}
                    profileHeader_friend_Handle={this.profileHeader_friend_Handle}
                    profileHeader_itrustit_recall_Handle={this.profileHeader_itrustit_recall_Handle}
                    user_settings={this.state.user_settings}
                  />
                  {user.user_type === 'organization' ? (
                    <MemberTab
                      setModalProps={this.setModalProps}
                      user_id={this.state.user_id}
                      user={this.state.user}
                      activeTab={this.state.activeTab}
                      ref={this.memberTabRef}
                      confirmitrustitAddRemove={this.confirmitrustitAddRemove}
                      confirmFriendAddRemove={this.confirmFriendAddRemove}
                      loadingFriendStatusChange={this.state.loadingFriendStatusChange}
                      loadingitrustitStatusChange={this.state.loadingitrustitStatusChange}
                      setLoadingFriendStatusChange={this.setLoadingFriendStatusChange}
                      setLoadingitrustitStatusChange={this.setLoadingitrustitStatusChange}
                      profileHeader_member_Handle={this.profileHeader_member_Handle}
                      profileHeader_friend_recall_Handle={this.profileHeader_friend_recall_Handle}
                      profileHeader_itrustit_recall_Handle={
                        this.profileHeader_itrustit_recall_Handle
                      }
                      profileHeader_member_recall_Handle={this.profileHeader_member_recall_Handle}
                      user_settings={this.state.user_settings}
                    />
                  ) : (
                    ''
                    // <Permission />
                  )}
                  {/* <ActivityTab user_id={this.state.user_id} activeTab={this.state.activeTab} /> */}
                  {/* <InviteCenterTab user_id={this.state.user_id} activeTab={this.state.activeTab} /> */}

                  <OrgsTotalTab
                    setModalProps={this.setModalProps}
                    user_id={this.state.user_id}
                    user={this.state.user}
                    activeTab={this.state.activeTab}
                    ref={this.orgsTotalTabRef}
                    profileHeader_friend_recall_Handle={this.profileHeader_friend_recall_Handle}
                    profileHeader_itrustit_recall_Handle={this.profileHeader_itrustit_recall_Handle}
                    profileHeader_total_orgs_Handle={this.profileHeader_total_orgs_Handle}
                    confirmitrustitAddRemove={this.confirmitrustitAddRemove}
                    confirmFriendAddRemove={this.confirmFriendAddRemove}
                    loadingFriendStatusChange={this.state.loadingFriendStatusChange}
                    loadingitrustitStatusChange={this.state.loadingitrustitStatusChange}
                    setLoadingFriendStatusChange={this.setLoadingFriendStatusChange}
                    setLoadingitrustitStatusChange={this.setLoadingitrustitStatusChange}
                    user_settings={this.state.user_settings}
                  />

                  <OrgsCommonTab
                    setModalProps={this.setModalProps}
                    user_id={this.state.user_id}
                    user={this.state.user}
                    activeTab={this.state.activeTab}
                    ref={this.orgsCommonTabRef}
                    profileHeader_friend_recall_Handle={this.profileHeader_friend_recall_Handle}
                    profileHeader_itrustit_recall_Handle={this.profileHeader_itrustit_recall_Handle}
                    profileHeader_mutual_orgs_Handle={this.profileHeader_mutual_orgs_Handle}
                    confirmitrustitAddRemove={this.confirmitrustitAddRemove}
                    confirmFriendAddRemove={this.confirmFriendAddRemove}
                    loadingFriendStatusChange={this.state.loadingFriendStatusChange}
                    loadingitrustitStatusChange={this.state.loadingitrustitStatusChange}
                    setLoadingFriendStatusChange={this.setLoadingFriendStatusChange}
                    setLoadingitrustitStatusChange={this.setLoadingitrustitStatusChange}
                    user_settings={this.state.user_settings}
                  />

                  <ReviewTab
                    user_id={this.state.user_id}
                    user={this.state.user}
                    activeTab={this.state.activeTab}
                    ref={this.reviewTabRef}
                    profileHeader_review_Handle={this.profileHeader_review_Handle}
                    deleteReview={this.confirmDeleteReview}
                  />
                </div>
              </div>
            </section>
            {this.state?.userOther?.id ? (
              <ReviewCreate
                user_id={this.state.user_id}
                user={this.state.userOther}
                isLoginUserClick={this.state.isLoginUserClick}
                reviewCreatedSuccessfullyHandler={this.reviewCreatedSuccessfullyHandler}
                otherUser={false}
                user_type={this.state.user.user_type}
              />
            ) : null}
          </div>
        ) : (
          <div className='it-no-access'>
            <div className='bg-white'>
              <div className=' inner-tab tab-content'>
                <div className='search-section friend_list_block'>
                  <div className='no_search_result'>
                    <img src='/img/hide-frd.svg' className='pt-5 m-auto d-block' alt='' />
                    <h2>You Don't Have Access</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <ConfirmDeleteReviewModal deleteReview={this.deleteReview} />
        <ConfirmUntrustPopupModal
          username={this.state.user.fullname}
          data={this.state.itrustitRemoveData}
          handler={this.state.itrustitHandler}
          setLoadingitrustitStatusChange={this.setLoadingitrustitStatusChange}
          // key={this.props.index}
          // data={{
          //   is_trusted_user: this.props.user.is_trusted_user,
          //   id: this.props.user.id,
          //   index: this.props.index,
          // }}
          // itrustitRemove={this.itrustitRemove}
        />
        <ConfirmUnfriendPopupModal
          username={this.state.user.fullname}
          data={this.state.friendRemoveData}
          handler={this.state.friendHandler}
          setLoadingFriendStatusChange={this.setLoadingFriendStatusChange}
          // username={this.props.user.business_organization_name}
          // key={this.props.index}
          // data={{
          //   is_friend_user: this.props.user.is_friend_user,
          //   user_id: this.props.user.id,
          //   friendship_id: this.props.user.friendship_id,
          //   index: this.props.index,
          // }}
          // friendAddRemoveList={this.friendAddRemoveList}
        />
        <MemberPopupModal
          Orgname={this.state.user.business_organization_name}
          user_id={this.state.user.id}
          // memberType={'member_of_their_organization'}
          membersName={
            formatMembersName(
              this.state?.user_settings?.members_name,
              this.state?.user_settings?.custom_members_name,
            ) ?? 'Members'
          }
          // setLoadingMemberChangeResponse={this.setLoadingMemberChangeResponse}
          setStatusOfMemberUser={this.setStatusOfMemberUser}
        />
        <ConfirmLeaveOrgPopupModal
          Orgname={this.state.user.fullname}
          user_id={this.state.user.id}
          // memberType={'member_of_their_organization'}
          // setLoadingMemberChangeResponse={this.setLoadingMemberChangeResponse}
          data={{
            is_member_user: this.state.is_member_user,
            id: this.state.user.id,
            flag: 'member_of_their_organization',
          }}
          memberAddRemove={this.memberAddRemove}
          setStatusOfMemberUser={this.setStatusOfMemberUser}
        />
        <ConfirmRemoveFromOrgPopupModal {...this.state.confirmModalProps} />
        <InvitePopupModal {...this.state.inviteModalProps} />
      </AppLayout>
    )
  }
}

function mapStateToProps(state) {
  return {
    reviewuser: state.UserReducer.reviewuser,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ reviewUser }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(ProfileOtherUser)
