import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import * as route from '../../services/routeConst'
import { LOGOUT_TITLE } from '../../services/titleConst'
import * as customFunction from '../../services/customFunction'
import { userStoreAction, isLoadingAction } from '../../actions/auth'
import { coverImageAction, editUserStore } from '../../actions/profile'
import { resetSocialMediaData } from '../../actions/socialmedia'
import { Helmet } from 'react-helmet'

class Logout extends Component {
  constructor(props) {
    super(props)
    //console.log("props", props);

    customFunction.removeUser()
    customFunction.removeToken()

    props.userStoreAction({ user: [] })
    props.coverImageAction([])
    props.editUserStore({})
    props.isLoadingAction(false)
    props.resetSocialMediaData()
    this.state = {
      isRedirect: true,
      //Asana 925 - Forgot Password Bug
      redirectTo: props.location?.state?.redirectTo ?? route.LOGIN_ROUTE,
      redirectState: props.location?.state?.redirectState ?? {
        email: props.location?.state?.email ?? '',
        type: props.location?.state?.type ?? '',
        autoLoggedOut: props?.location?.search?.includes?.('auto=true'),
      },
    }

    window?.Echo?.leave('Online')
  }
  render() {
    if (this.state.isRedirect) {
      this.props.isLogin(false)
      return (
        <>
          <Helmet>
            <title>{LOGOUT_TITLE}</title>
          </Helmet>
          <Redirect
            to={{
              pathname: this.state.redirectTo,
              state: this.state.redirectState,
            }}
          />
        </>
      )
    }
    return <div />
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { userStoreAction, coverImageAction, editUserStore, isLoadingAction, resetSocialMediaData },
    dispatch,
  )
  // return {
  //     loginAction: bindActionCreators(loginAction, dispatch),
  // };
}

function mapStateToProps(state, props) {
  return {
    user: state.UserReducer.userdetail,
    networkerror: state.UserReducer.networkerror,
    errormsg: state.UserReducer.errormsg,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Logout)
