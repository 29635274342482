import React, { useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import ReactStars from 'react-stars'
import {
  STAR_RATING_iTRUST_ACTIVE_COLOR,
  STAR_RATING_iTRUST_INACTIVE_COLOR,
  STAR_RATING_REVIEW_SECTION_SIZE,
  STAR_RATING_TRUST_ME_ACTIVE_COLOR,
  STAR_RATING_TRUST_ME_INACTIVE_COLOR,
} from '../../../services/const'

const ReviewDetail = ({ review, reviewUserHandler, isMobile }) => {
  const [showMore, setShowMore] = useState(false)
  const star_rating_color1 =
    review?.review_type === 'given'
      ? STAR_RATING_iTRUST_INACTIVE_COLOR
      : STAR_RATING_TRUST_ME_INACTIVE_COLOR
  const star_rating_color2 =
    review?.review_type === 'given'
      ? STAR_RATING_iTRUST_ACTIVE_COLOR
      : STAR_RATING_TRUST_ME_ACTIVE_COLOR

  return (
    <>
      <div className={`right_link ${!review?.id ? 'text-start justify-content-start' : ''}`}>
        {review?.id ? (
          <a href={null} onClick={reviewUserHandler}>
            <ReactStars
              edit={false}
              size={STAR_RATING_REVIEW_SECTION_SIZE}
              color1={star_rating_color1}
              color2={star_rating_color2}
              value={review?.average_review}
            />
          </a>
        ) : (
          <Skeleton width='82.5px' />
        )}
        <div className='review_digit varela cursor-pointer' onClick={reviewUserHandler}>
          <small>{review?.review_added_date_string}</small>
        </div>
      </div>
      {review?.id ? (
        <p onClick={reviewUserHandler} className='review-detail-desc'>
          {review?.description
            ? showMore
              ? review?.description
              : review?.description.substring(0, 325) +
                (review?.description?.length > 325 ? '...' : '')
            : 'N/A'}
          {review?.description?.length > 325 ? (
            <button className='btn see-more-btn ms-2' onClick={() => setShowMore((st) => !st)}>
              See {showMore ? 'less' : 'more'}
            </button>
          ) : null}
        </p>
      ) : (
        <>
          <Skeleton width={isMobile ? '60%' : '80%'} />
          <Skeleton width={isMobile ? '40%' : '60%'} />
          <Skeleton width={isMobile ? '10%' : '20%'} />
        </>
      )}
    </>
  )
}

export default ReviewDetail
