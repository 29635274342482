import React, { useEffect, useState } from 'react'
import { Redirect, useParams } from 'react-router-dom'
import { getSlugFromId } from '../../services/userApiService'

const ProfileFromQRCode = () => {
  let { id } = useParams()
  const [nextPath, setNextPath] = useState('')

  useEffect(() => {
    const getSlug = async () => {
      try {
        const { data } = await getSlugFromId(id)
        if (!!data?.data?.slug) {
          setNextPath('/' + data?.data?.slug)
        } else {
          setNextPath('/')
        }
      } catch (error) {
        setNextPath('/')
      }
    }

    !!id && getSlug()
  }, [id])

  return !!nextPath ? <Redirect to={{ pathname: nextPath }} /> : null
}

export default ProfileFromQRCode
