import React, { Component, Fragment } from 'react'
// import { PROFILE_TRUST_DIRECTORY_TITLE } from "../../../services/titleConst";
import * as memberApiService from '../../../services/memberApiService'
import * as trustApiService from '../../../services/trustApiService'
import * as profileSearchService from '../../../services/profileSearchService'
import { LIST_VIEW_ITEMS_PER_PAGE, LIST_VIEW_PAGE_RANGE, SEARCH_AMT } from '../../../services/const'
import { getUser } from '../../../services/customFunction'
import UserListDetail from '../../CommonComponents/UserListDetail/UserListDetail'
import TrustedUserList from '../../CommonComponents/TrustedUserList/TrustedUserList'
import $ from 'jquery'
class OrgsTotalTab extends Component {
  constructor(props) {
    super(props)

    this.state = {
      login_user_id: getUser().id,

      user_id: this.props.user_id,
      viewType: 'full_view', // full_view | list_view
      userType: '', // individual, business, organization
      activePage: 1,
      totalPages: 0,
      totalRecords: 0,
      orgsTotalList: [],
      searchString: '',

      overall: {
        total_records: 0,
        total_organizations: 0,
        total_businesses: 0,
      },
      search_total: {
        total_records: 0,
        total_organizations: 0,
        total_businesses: 0,
      },
      isTrustedUserModalShow: false,
      trusteUserData: {
        type: '',
        id: 0,
        user_type: '',
        total_common_members: 0,
      },
      isSearching: true,
      endOfPage: SEARCH_AMT,
      hasMoreData: false,
      noSearchResults: false,
      loadingMoreData: false,
      updateFunc: null,
      loadingFunc: null,
    }
    this.handlePageChange = this.handlePageChange.bind(this)
  }

  componentDidMount() {
    this.orgsTotalListRender()
    window.scrollTo({
      top: $('#orgs_total').offset().top - 125,
      behavior: 'smooth',
    })
  }

  componentWillReceiveProps(nextProps) {
    let user_id_new = nextProps.user_id
    let user_id_old = this.state.user_id

    if (user_id_new !== user_id_old) {
      this.setState({ user_id: user_id_new }, () => {
        this.orgsTotalListRender()
      })
    }
  }

  async orgsTotalListRender() {
    let orgsTotalListReqData = {
      other_user_id: this.state.user_id,
      page: this.state.activePage ? this.state.activePage : 1,
      user_type: this.state.userType,
      searchString: this.state.searchString,
    }

    const self = this
    const response = await memberApiService.orgsTotalListCall(orgsTotalListReqData)

    if (typeof response.status !== 'undefined' && response.status === 200) {
      // console.log(response);
      let apiResponseData = response.data.data

      if (orgsTotalListReqData.searchString !== '') {
        const res = await profileSearchService.elasticSearch({
          ids: apiResponseData.ids,
          searchStr: orgsTotalListReqData.searchString,
        })

        if (res.length) {
          apiResponseData.ids = res.map((searchedUser) => searchedUser['_id'])
          apiResponseData.total_records = res.length
        } else {
          self.setState({
            totalRecords: 0,
            isSearching: false,
            noSearchResults: true,
          })
          return
        }
      }
      const currentIDs = apiResponseData.ids.slice(0, SEARCH_AMT)
      let stateData = {
        totalPages: apiResponseData.total_pages,
        totalRecords: apiResponseData.total_records,
        commonOrgIDs: apiResponseData.ids,

        overall: apiResponseData.overall,
        search_total: apiResponseData.search_total,
        hasMoreData: apiResponseData.total_records >= 1,
        endOfPage: SEARCH_AMT,
        noSearchResults: apiResponseData.total_records <= 0,
      }

      if (self.state.activePage <= 0) {
        stateData['activePage'] = 1
      }

      self.props.profileHeader_total_orgs_Handle(apiResponseData.search_total)
      self.setState(stateData, () => {
        if (currentIDs.length) {
          self.searchForCommonOrgs(currentIDs)
        } else {
          self.setState({ isSearching: false })
        }
      })
    }
  }

  searchForCommonOrgs = (currentIDs) => {
    //console.log(currentIDs);
    const self = this
    trustApiService
      .getTrustListFromIDs({
        ids: currentIDs,
      })
      .then(function (response) {
        self.setState(
          (st) => ({
            orgsTotalList: [...st.orgsTotalList, ...response.data.trusts],
            hasMoreData: st.endOfPage < st.totalRecords,
            isSearching: false,
            loadingMoreData: false,
          }),
          () => self.state.hasMoreData && self.searchAgain(),
        )
      })
  }

  viewTypeChangeHandler = (viewType) => (event) => {
    this.setState(
      {
        viewType: viewType,
      },
      () => {},
    )
  }

  handlePageChange(pageNumber) {
    $('html, body').animate(
      {
        scrollTop: $('#orgs_total').offset().top - 100,
      },
      1000,
    )
    this.setState(
      {
        activePage: pageNumber,
        isSearching: true,
        orgsTotalList: [],
      },
      () => {
        this.orgsTotalListRender()
      },
    )
  }

  profileHeader_orgs_total_tab_click_Handle() {
    // this.setState({ isSearching: true, orgsTotalList: [] }, () =>
    //   this.orgsTotalListRender()
    // );
    // this.orgsTotalListRender();
  }

  orgsCommonAddRemoveHandle = (data) => {
    this.orgsTotalListRender()
  }

  onHoverTrustBy = (
    id,
    type,
    user_type,
    total_common_members,
    updateFunc = null,
    loadingFunc = null,
  ) => {
    this.setState({
      isTrustedUserModalShow: true,
      trusteUserData: {
        type: type,
        id: id,
        user_type: user_type,
        total_common_members: total_common_members,
      },
      updateFunc,
      loadingFunc,
    })
  }

  setStatusOfMemberUserYour = (id, status) => {
    this.setState(
      (st) => {
        let orgsTotalList = st.orgsTotalList
        orgsTotalList.filter((user) => user.id === id)[0]['is_your_member_user'] =
          status === 'pending' ? 2 : status === 'accepted' ? 1 : 0
        return {
          orgsTotalList,
        }
      },
      () => {},
    )
  }

  itrustitHandle = (data) => {
    this.setState(
      (st) => {
        let orgsTotalList = st.orgsTotalList
        orgsTotalList.filter((user) => user.id === data.id)[0]['is_trusted_user'] =
          data.is_trusted_user === 1 ? 0 : 1
        return {
          orgsTotalList,
        }
      },
      () => {
        //console.log(this.state.friendList);
        //this.friendListRender();
      },
    )
    this.props.profileHeader_itrustit_recall_Handle()
  }

  friendAddRemoveHandle = (data) => {
    // console.log(data);
    this.props.profileHeader_friend_recall_Handle()
    this.setState(
      (st) => {
        let orgsTotalList = st.orgsTotalList
        orgsTotalList.filter((user) => user.id === data.id)[0]['is_friend_user'] =
          data.is_friend_user === 1 ? 0 : 1
        orgsTotalList.filter((user) => user.id === data.id)[0]['friendship_id'] =
          data.is_friend_user === 1 ? null : data.friendship_id
        return {
          orgsTotalList,
        }
      },
      () => {
        //console.log(this.state.friendList);
        //this.friendListRender();
      },
    )
  }

  infiniteScroll() {
    if (this.state.hasMoreData) {
      const winScroll = document.body.scrollTop || document.documentElement.scrollTop

      const height = document.documentElement.scrollHeight - document.documentElement.clientHeight

      const scrolled = winScroll / height
      // console.log(scrolled);
      if (scrolled >= 0.7) {
        this.searchAgain()
      }
    }
  }

  searchAgain() {
    this.setState(
      (st) => ({
        hasMoreData: false,
        endOfPage: st.endOfPage + SEARCH_AMT,
        loadingMoreData: true,
      }),
      () => {
        let currentIDs = this.state.commonOrgIDs.slice(
          this.state.endOfPage - SEARCH_AMT,
          this.state.endOfPage,
        )
        this.searchForCommonOrgs(currentIDs)
      },
    )
  }

  render() {
    const {
      totalPages,
      totalRecords,
      orgsTotalList,
      viewType,
      isSearching,
      noSearchResults = false,
    } = this.state
    let showingFrom = this.props.itemsCountPerPage * (this.state.activePage - 1) + 1
    let showingTo = orgsTotalList.length || showingFrom
    if (showingFrom > totalRecords) {
      showingFrom = totalRecords
    }
    if (showingTo > this.state.totalRecords) {
      showingTo = this.state.totalRecords
    }

    if (totalPages < this.state.activePage && totalPages !== 0) {
      this.setState(
        {
          activePage: totalPages,
        },
        () => {
          this.orgsTotalListRender()
        },
      )
    }

    return (
      <div
        id='orgs_total'
        className={'tab-pane ' + (this.props.activeTab === '#orgs_total' ? 'active' : 'fade')}
      >
        <div className='trust_blk_head'>
          <h4 className='varela'>
            {/* Organizations in Common  */}
            Organizations ({this.state.search_total.total_records})
          </h4>
          <div className='trust_right'>
            <div>View:</div>
            <ul className='views'>
              <li onClick={this.viewTypeChangeHandler('list_view')}>
                <a
                  href={null}
                  className={'' + (this.state.viewType === 'list_view' ? 'active' : '')}
                >
                  <i className='list-view'></i>List
                </a>
              </li>
              <li onClick={this.viewTypeChangeHandler('full_view')}>
                <a
                  href={null}
                  className={'' + (this.state.viewType === 'full_view' ? 'active' : '')}
                >
                  <i className='full-view'></i>Full
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className='bg-white'>
          <div className='friend_list_block purple'></div>
          <div className='i-showing'>
            Showing {showingFrom} - {showingTo} of {this.state.totalRecords}
          </div>
          {/* inner tab start */}
          <div className='inner-tab tab-content it-mobile-space'>
            {totalRecords > 0 && !noSearchResults && !isSearching ? (
              <Fragment>
                {viewType === 'full_view' ? (
                  <div className='d-flex blocks'>
                    <div className='col-lg-8 position-relative'>
                      {orgsTotalList.map(
                        (user, index) =>
                          index < this.state.endOfPage && (
                            <UserListDetail
                              setModalProps={this.props.setModalProps}
                              setStatusOfMemberUserYour={this.setStatusOfMemberUserYour}
                              key={user.id}
                              index={index}
                              login_user_id={this.state.login_user_id}
                              user={user}
                              confirmitrustitAddRemove={this.props.confirmitrustitAddRemove}
                              confirmFriendAddRemove={this.props.confirmFriendAddRemove}
                              setLoadingFriendStatusChange={this.props.setLoadingFriendStatusChange}
                              setLoadingitrustitStatusChange={
                                this.props.setLoadingitrustitStatusChange
                              }
                              loadingFriendStatusChange={this.props.loadingFriendStatusChange}
                              loadingitrustitStatusChange={this.props.loadingitrustitStatusChange}
                              tab={'orgs_total'}
                              viewType={this.state.viewType}
                              itrustitHandle={this.itrustitHandle}
                              friendAddRemoveHandle={this.friendAddRemoveHandle}
                              loginUserId={this.props.loginUserId}
                              orgsCommonAddRemoveHandle={this.orgsCommonAddRemoveHandle}
                              onHoverTrustBy={this.onHoverTrustBy}
                            />
                          ),
                      )}
                      <TrustedUserList
                        isBlockShowSetFalse={(e) => {
                          this.setState({ isTrustedUserModalShow: false })
                        }}
                        isBlockShow={this.state.isTrustedUserModalShow}
                        trusteUserData={this.state.trusteUserData}
                        updateFunc={this.state.updateFunc}
                        loadingFunc={this.state.loadingFunc}
                      ></TrustedUserList>
                    </div>
                  </div>
                ) : (
                  <div className='row frnd_1'>
                    {orgsTotalList.map(
                      (user, index) =>
                        index < this.state.endOfPage && (
                          <UserListDetail
                            setModalProps={this.props.setModalProps}
                            setStatusOfMemberUserYour={this.setStatusOfMemberUserYour}
                            key={user.id}
                            index={index}
                            login_user_id={this.state.login_user_id}
                            user={user}
                            confirmitrustitAddRemove={this.props.confirmitrustitAddRemove}
                            confirmFriendAddRemove={this.props.confirmFriendAddRemove}
                            setLoadingFriendStatusChange={this.props.setLoadingFriendStatusChange}
                            setLoadingitrustitStatusChange={
                              this.props.setLoadingitrustitStatusChange
                            }
                            loadingFriendStatusChange={this.props.loadingFriendStatusChange}
                            loadingitrustitStatusChange={this.props.loadingitrustitStatusChange}
                            tab={'orgs_total'}
                            viewType={this.state.viewType}
                            itrustitHandle={this.itrustitHandle}
                            friendAddRemoveHandle={this.friendAddRemoveHandle}
                            loginUserId={this.props.loginUserId}
                            orgsCommonAddRemoveHandle={this.orgsCommonAddRemoveHandle}
                            onHoverTrustBy={this.onHoverTrustBy}
                          />
                        ),
                    )}
                    <TrustedUserList
                      isBlockShowSetFalse={(e) => {
                        this.setState({ isTrustedUserModalShow: false })
                      }}
                      isBlockShow={this.state.isTrustedUserModalShow}
                      trusteUserData={this.state.trusteUserData}
                      updateFunc={this.state.updateFunc}
                      loadingFunc={this.state.loadingFunc}
                    ></TrustedUserList>
                  </div>
                )}
                {this.state.loadingMoreData ? (
                  <div className='no_search_result'>
                    <div className='search-page-loading-icon'>
                      {' '}
                      <i />{' '}
                    </div>
                  </div>
                ) : (
                  !this.state.hasMoreData && (
                    <div
                      onClick={() => {
                        window.scrollTo({
                          top: $('#friend').offset().top - 100,
                          behavior: 'smooth',
                        })
                      }}
                      className='no_search_result back-to-top text-muted'
                    >
                      Back to Top
                    </div>
                  )
                )}
                {/* <div>
                  <Pagination
                    activePage={activePage}
                    itemsCountPerPage={this.props.itemsCountPerPage}
                    totalItemsCount={totalRecords}
                    pageRangeDisplayed={this.props.pageRangeDisplayed}
                    onChange={this.handlePageChange}
                  />
                </div> */}
              </Fragment>
            ) : noSearchResults || !isSearching ? (
              <div className='search-section'>
                <div className='no_search_result'>
                  <img
                    src={process.env.PUBLIC_URL + '/img/no-mutual-organizations.png'}
                    className='pt-5 pb-5 m-auto d-block'
                    alt=''
                  />
                </div>
              </div>
            ) : (
              <div className='search-section'>
                <div className='no_search_result'>
                  <div className='search-page-loading-icon'>
                    {' '}
                    <i />{' '}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }
}

OrgsTotalTab.defaultProps = {
  itemsCountPerPage: LIST_VIEW_ITEMS_PER_PAGE,
  pageRangeDisplayed: LIST_VIEW_PAGE_RANGE,
}

export default OrgsTotalTab
