import 'cropperjs/dist/cropper.css'
import React, { useEffect, useState, useRef } from 'react'
import { DefaultPlayer as Video } from 'react-html5video'
import 'react-html5video/dist/styles.css'
import { connect } from 'react-redux'
import ReactTooltip from 'react-tooltip'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'
import SelectionModal from '../SelectionModal'
import { DISCARD_POST, POST_TEXT_SIZE, TINYMCE_KEY } from '../../../services/const'
import SocialShowMoreText from '../SocialShowMoreText'
import ManagePhotosOrVideos from '../ManagePhotosOrVideos'

import { Editor } from '@tinymce/tinymce-react'

import {
  updatePostAttachments,
  updatePostBody,
  updateSelectionTitle,
  resetCurrentPost,
  updateCommentType,
  updateShareType,
  postPost,
  updatePost,
} from '../../../actions/socialmedia'
import { DEFAULT_PROFILE_PICTURE, IMG_PATH } from '../../../services/const'
import {
  getClassNameForGrid,
  toaster,
  getUser,
  dateStringToTimeSince,
  setOverFlow,
} from '../../../services/customFunction'
import { postCreateCall, postUpdateCall } from '../../../services/socialMediaApiService'
import SearchPageLoadingIcon from '../../Search/SearchPageLoadingIcon'
import AddPhotosOrVideos from '../AddPhotosOrVideos'
import Dialogs from '../Dialogs'
import './CreatePost.scss'

const CreatePost = ({
  showDirectAddPhoto = false,
  showDirectAddVideo = false,
  onCloseButtonClick,
  ...props
}) => {
  const login_user = getUser()
  let profile_picture_small = login_user?.user_detail?.profile_picture_small
    ? login_user?.user_detail?.profile_picture_small
    : DEFAULT_PROFILE_PICTURE
  let fullname = login_user?.fullname || ''
  const [isUpdate, setIsUpdate] = useState(false)
  const dialogTitle = props?.postData?.id !== 0 ? 'Edit post' : 'Create post'
  const [postBody, setPostBody] = useState('')
  const [initPostBody, setInitPostBody] = useState('')
  const [status, setStatus] = useState('idle')

  const [showModifyPostDialog, setShowModifyPostDialog] = useState(false)
  const [showCommentOnSelection, setShowCommentOnSelection] = useState(false)
  const [showSeeSelection, setShowSeeSelection] = useState(false)
  const [showAddPhoto, setShowAddPhoto] = useState(showDirectAddPhoto)
  const [showAddVideo, setShowAddVideo] = useState(showDirectAddVideo)
  const [sendAttachments, setSendAttachments] = useState(true)
  const [embeddedLinks, setEmbeddedLinks] = useState([])
  const [embeddedLinkId, setEmbeddedLinkId] = useState(0)
  const [textSize, setTextSize] = useState(0)
  const [original_post, setOriginalPost] = useState(
    props?.postData?.original_post_id
      ? props?.postsData?.filter((p) => p.id === props?.postData?.original_post_id)[0]
      : null,
  )

  const editorRef = useRef(null)

  const onChangeHandler = (value) => {
    //console.log(value)
    setTextSize(editorRef?.current.getContent({ format: 'text' })?.trim()?.length)
    if (editorRef?.current) {
      //console.log('TEXT length:', textSize, 'HTML length:', value.length)
      extratcURL(value)
      setPostBody(value)
      //console.log(value1)
    }
  }
  const extratcURL = (rawHTML) => {
    var doc = document?.createElement('html')
    doc.innerHTML = rawHTML
    var urls = []
    var links = doc.getElementsByTagName('a')
    for (var i = 0; i < links.length; i++) {
      let validUrl = links[i].getAttribute('href').replace('watch?v=', 'embed/')
      if (validUrl.includes('embed') > 0) urls.push(validUrl)
    }
    setEmbeddedLinks(urls)
  }

  useEffect(() => {
    setOverFlow(true)
    setIsUpdate(props?.isUpdate)
    if (props?.isUpdate && props?.postData?.attachments?.length > 0) setSendAttachments(false)
    if (!props?.isUpdate) {
      props?.updateShareType(props?.posts_default_share_type)
      props?.updateCommentType(props?.posts_default_comment_type)
    }

    setPostBody(props?.postData?.post_body)
    setInitPostBody(props?.postData?.post_body)
  }, [])

  const onNoModifyPostDialog = () => {
    setShowModifyPostDialog(false)
  }

  const onYesModifyPostDialog = () => {
    setShowModifyPostDialog(false)

    onCloseButtonClick()
    props?.resetCurrentPost()
  }
  const onCloseSelection = () => {
    setShowCommentOnSelection(false)
    setShowSeeSelection(false)
  }
  const createPostFormSubmitHandler = async () => {
    setStatus('loading')
    const containsVideo = props?.postData?.attachments?.filter(
      (p) => p.fileType === 'video',
    )?.length

    const formData = new FormData()
    formData.append('user_id', login_user?.id)
    formData.append('post_body', postBody)
    formData.append('comment_type_id', props?.postData?.comment_type_id)
    formData.append('share_type_id', props?.postData?.share_type_id)
    formData.append('original_post_id', props?.postData?.original_post_id)
    formData.append(
      'share_group_id',
      props?.postData?.share_group_id ? props?.postData?.share_group_id : '1',
    )
    formData.append('containsVideo', containsVideo)
    formData.append('wasDeleted', sendAttachments)
    sendAttachments &&
      props?.postData?.attachments?.forEach(({ file, fileType }, idx) => {
        formData.append('file_' + idx, file)
        formData.append('file_type_' + idx, fileType)
      })
    const response = isUpdate
      ? await postUpdateCall({ id: props?.postData?.id, formData: formData })
      : await postCreateCall(formData)
    if (response?.status === 200) {
      setTimeout(function () {
        setStatus('idle')
        onCloseButtonClick()
        props?.resetCurrentPost()
        isUpdate ? props?.updatePost(response.data.data) : props?.postPost(response.data.data)
        toaster(
          'success',
          `${response.data.message}${
            containsVideo || props?.postData?.attachments?.length > 1
              ? '. Please check back shortly if you do not see your media, as it may take a few moments to process.'
              : ''
          }`,
        )
      }, 1000)
    } else {
      setStatus('idle')
    }
  }
  const addButtonToEmbedded = () => {
    return (
      <>
        <div className='input-group-btn cornerGroupBtn'>
          <button
            class='btn btn-default'
            type='button'
            data-tip={'Delete '}
            data-for='embeddedButtons'
            onClick={() => removeEmbedded()}
          >
            <img className='imgCornerBtn likeIcon' src={IMG_PATH + 'delete.svg'} alt='' />
          </button>
        </div>{' '}
        <ReactTooltip
          //data-class='reactionButton'
          id='embeddedButtons'
          type='dark'
          effect='float'
        />
      </>
    )
  }
  const postHeader = (post, menu_action = true, big_picture = true) => {
    let img_blockClassname = big_picture
      ? 'img_block orange_border'
      : 'img_block_medium orange_border'

    return (
      <div className='postTop'>
        <div className='postTopLeft'>
          <a
            href={process.env.PUBLIC_URL + '/' + post?.user_details?.slug}
            className='postSmallPicture'
          >
            <img
              className={img_blockClassname}
              src={post?.user_details?.profile_picture_small ?? DEFAULT_PROFILE_PICTURE}
              alt=''
            />
          </a>
          <div className='wallFlexColumnLeft overFlowHidden'>
            <a
              className='postUsername'
              href={process.env.PUBLIC_URL + '/' + post?.user_details?.slug}
            >
              {post?.user_details?.fullname}
            </a>
            <div className='postDate'>
              {dateStringToTimeSince(post?.shared_updated_at, true)?.replace('ago', '')}
              {post?.created_at === post?.updated_at ? '' : 'Edited'}
            </div>
          </div>
        </div>
      </div>
    )
  }
  const addButtonToAttachments = (fileType = 'images') => {
    //console.log('isUpdate:', isUpdate)
    let editbutton = isUpdate ? 'btn btn-default editbuttonoff' : 'btn btn-defaulteditbuttonon'
    return (
      <>
        <div className='input-group-btn cornerGroupBtn'>
          <div className=''>
            <button
              className={editbutton}
              type='button'
              data-tip={(fileType.includes('image') ? 'See ' : 'Play ') + fileType}
              data-for='attachmentButtons'
              onClick={() => {
                fileType.includes('video') ? setShowAddVideo(true) : setShowAddPhoto(true)
              }}
            >
              <img className='imgCornerBtn likeIcon' src={IMG_PATH + 'edit.svg'} alt='' />
            </button>
            <button
              class='btn btn-default'
              type='button'
              data-tip={'Delete ' + (fileType.includes('image') ? 'all ' : '') + fileType}
              data-for='attachmentButtons'
              onClick={removeAttachments}
            >
              <img className='imgCornerBtn likeIcon' src={IMG_PATH + 'delete.svg'} alt='' />
            </button>
          </div>
        </div>

        <ReactTooltip
          //data-class='reactionButton'
          id='attachmentButtons'
          type='dark'
          effect='float'
        />
      </>
    )
  }
  const renderPostDetails = (files) => {
    //console.log('renderPostDetails:', props?.postData)
    let showAttachmentButtons = props?.postData?.attachments?.length > 0
    if (files?.length === 1 && files[0].file_type === 'video') if (files?.length < 1) return ''
    if (files?.length === 1 && (files[0].fileType === 'video' || files[0].file_type === 'video'))
      return (
        <div className='postImgContainer'>
          {showAttachmentButtons ? addButtonToAttachments('video') : ''}
          <div className='postImages'>
            <Video
              controls={['PlayPause', 'Seek', 'Time', 'Volume', 'Fullscreen']}
              onCanPlayThrough={() => {
                // Do stuff
              }}
            >
              <source src={files[0]?.original || files[0]?.vidSrc} type='video/mp4' />
            </Video>
          </div>
        </div>
      )
    else
      return (
        <div className='postImgContainer'>
          {showAttachmentButtons ? addButtonToAttachments() : ''}
          <div className='postImages'>
            {files
              ? files?.map((file, index) => {
                  let srcFile = file?.original || file?.imgSrc
                  let className = getClassNameForGrid(files?.length, index)
                  return index < 5 ? <img className={className} src={srcFile} alt='' /> : ''
                })
              : ''}
          </div>
          {files?.length - 5 > 0 ? <span className='postImgOver'>+{files?.length - 5}</span> : ''}
        </div>
      )
  }

  const onClosePost = () => {
    if (postBody?.length > 0 || props?.postData?.attachments?.length > 0) {
      setShowModifyPostDialog(true)
    } else {
      onCloseButtonClick()
      props?.resetCurrentPost()
    }
  }

  const removeAttachments = () => {
    setSendAttachments(true)
    props?.updatePostAttachments([])
  }

  const removeEmbedded = () => {
    setEmbeddedLinkId(embeddedLinkId + 1)
  }

  const onCloseAddPhotoAndVideos = () => {
    setShowAddPhoto(false)
    setShowAddVideo(false)
  }
  let hasContain = postBody?.length > 0 || props?.postData?.attachments?.length > 0

  let disableButtons = props?.postData?.attachments?.length > 0 //|| isUpdate
  let isMaximSize = POST_TEXT_SIZE - textSize < 0
  return (
    <div className='postDialogs'>
      <div
        className='modal modal-fullscreen overlay-top-layer createpostmodal'
        id='wall_create_post_modal'
        role='dialog'
      >
        <div className='modal-dialog'>
          <div name='postForm' className='choose_file postForm'>
            {' '}
            <div className='modal-content'>
              <div className='modal-header'>
                <h5>{dialogTitle}</h5>
                <button
                  type='button'
                  className='btn-close'
                  onClick={onClosePost}
                  disabled={status === 'loading'}
                ></button>
              </div>
              <div className='modal-body overflow-auto createPostBody'>
                <div className='userTopHeader'>
                  <div className='img_block orange_border userTopImg'>
                    <img src={profile_picture_small} alt='' />
                  </div>
                  <div className='wallFlexColumnLeft'>
                    <div
                      style={{
                        fontWeight: 600,
                      }}
                      className='wallUserFullName'
                    >
                      {fullname}
                    </div>
                    <button
                      className='btn wallBorderButton'
                      onClick={() => {
                        setShowSeeSelection(true)
                      }}
                    >
                      <img
                        className='likeIcon'
                        src={
                          IMG_PATH +
                          props?.metadata?.share_types?.filter(
                            (share_type) => share_type.id == props?.postData?.share_type_id,
                          )[0]?.icon
                        }
                        alt=''
                      />
                      <span className='shareOptionText'>
                        {
                          props?.metadata?.share_types?.filter(
                            (share_type) => share_type.id == props?.postData?.share_type_id,
                          )[0]?.type
                        }
                      </span>{' '}
                    </button>
                  </div>
                </div>
                <div className='body-input'>
                  <Editor
                    //apiKey='9c02npowy44212esjfteh330l48ouwzskadadziu3e4vurgv'
                    apiKey={TINYMCE_KEY}
                    selector='textarea'
                    onInit={(evt, editor) => {
                      editorRef.current = editor
                      editor.focus()
                    }}
                    initialValue={initPostBody}
                    init={{
                      height: 200,
                      menubar: false,
                      plugins: 'autolink autoresize link emoticons',
                      statusbar: false,
                      toolbar: 'emoticons',
                      toolbar_location: 'bottom',
                      default_link_target: '_blank',
                      content_style:
                        'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                    }}
                    onEditorChange={(newText) => onChangeHandler(newText)}
                  />

                  {embeddedLinks?.length > 0 &&
                    embeddedLinks.map(function (embedObj, index) {
                      return index === embeddedLinkId ? (
                        embedObj.includes('embed') ? (
                          <>
                            <div className='embeddedContainer'>
                              {addButtonToEmbedded()}
                              <iframe
                                id='i_frame'
                                className='embeddedObject'
                                src={embedObj}
                                title='YouTube video player'
                                frameborder='0'
                                sandbox='allow-same-origin allow-forms allow-scripts'
                                //={() => onIframeErrorHandler()}
                                //onLoad={() => onIframeLoadHandler(index)}
                                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                                //onLoad={checkIframeLoaded200()}
                                //allowfullscreen
                              ></iframe>
                            </div>
                          </>
                        ) : (
                          ''
                        )
                      ) : (
                        ''
                      )
                    })}
                  {disableButtons ? (
                    <div id=' post_details' name='post_details' className='tab-pane active'>
                      <div className='row post_list'>
                        {renderPostDetails(props?.postData?.attachments)}
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                  {props?.postData?.original_post_id && (
                    <div className='postCenterOriginal'>
                      {postHeader(original_post, false, false)}
                      <div className='postCenter'>
                        <SocialShowMoreText
                          textToShow={original_post?.post_body}
                        ></SocialShowMoreText>
                        {renderPostDetails(original_post?.attachments)}
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className='modal-footer'>
                {isMaximSize && (
                  <div class='alert alert-danger' role='alert'>
                    You have exceeded the maximum character limit:
                    {POST_TEXT_SIZE - textSize}
                  </div>
                )}
                <div className='modal-footer_left col-md-6'>
                  <ManagePhotosOrVideos isUpdate={isUpdate}></ManagePhotosOrVideos>
                </div>
                {/*  <SelectionTypeButton type='comment' id={comment_type_id} /> */}
                <button
                  className='btn btn-light'
                  onClick={() => {
                    setShowCommentOnSelection(true)
                  }}
                >
                  <img
                    className='likeIcon'
                    src={
                      IMG_PATH +
                      props?.metadata?.comment_types?.filter(
                        (share_type) => share_type.id == props?.postData?.comment_type_id,
                      )[0]?.icon
                    }
                    alt=''
                  />
                  <span className='shareOptionText'>
                    {
                      props?.metadata?.comment_types?.filter(
                        (share_type) => share_type.id == props?.postData?.comment_type_id,
                      )[0]?.type
                    }
                  </span>{' '}
                </button>
                <div className='modal-footer_right'>
                  {status === 'loading' ? (
                    <SearchPageLoadingIcon
                      className='pagination-loading'
                      styles={{
                        height: 'auto',
                      }}
                      iconStyles={{ height: 38, transform: 'scale(1.5)' }}
                    />
                  ) : (
                    <button
                      type='submit'
                      class='btn btn btn-primary'
                      data-dismiss='modal'
                      disabled={status === 'loading' || !hasContain || isMaximSize}
                      onClick={() =>
                        status === 'loading' || !hasContain ? null : createPostFormSubmitHandler()
                      }
                    >
                      {isUpdate ? 'Update' : 'Post'}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showAddPhoto && (
        <AddPhotosOrVideos
          title={isUpdate ? 'Modify photos' : 'Add photos'}
          onCloseButtonClick={onCloseAddPhotoAndVideos}
          onBackButtonClick={onCloseAddPhotoAndVideos}
        />
      )}
      {showAddVideo && (
        <AddPhotosOrVideos
          title={isUpdate ? 'Modify video' : 'Add video'}
          onCloseButtonClick={onCloseAddPhotoAndVideos}
          onBackButtonClick={onCloseAddPhotoAndVideos}
        />
      )}
      {showSeeSelection && (
        <SelectionModal title='see' save={false} onCloseSelection={onCloseSelection} />
      )}
      {showCommentOnSelection && (
        <SelectionModal title='comment on' save={false} onCloseSelection={onCloseSelection} />
      )}

      {showModifyPostDialog && (
        <Dialogs
          title={'Discard post'}
          question={DISCARD_POST}
          onYesCloseDialog={onYesModifyPostDialog}
          onNoCloseDialog={onNoModifyPostDialog}
        />
      )}
    </div>
  )
}

function mapStateToProps(state) {
  return {
    postData: state?.SocialMediaReducer?.post,
    postsData: state.SocialMediaReducer.posts,
    metadata: state?.SocialMediaReducer?.metadata,
    isUpdate: state?.SocialMediaReducer?.isUpdate,
    posts_default_share_type:
      state?.UserReducer?.userdetail?.user_settings?.posts_default_share_type,
    posts_default_comment_type:
      state?.UserReducer?.userdetail?.user_settings?.posts_default_comment_type,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updatePostAttachments,
      updatePostBody,
      updateSelectionTitle,
      resetCurrentPost,
      updateCommentType,
      updateShareType,
      postPost,
      updatePost,
    },
    dispatch,
  )
}

export default reduxForm({
  form: 'postForm',
  enableReinitialize: true,
})(connect(mapStateToProps, mapDispatchToProps)(CreatePost))
