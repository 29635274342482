import React, { Component } from 'react'

class UsernameModal extends Component {
  constructor(props) {
    super(props)
  }
  render() {
    return (
      <div className='modal fade reset_password_blk' id='change_username_modal' role='dialog'>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header'>
              {/* <button type="button" className="btn-close" data-bs-dismiss="modal">
                                &times;
                            </button> */}
            </div>
            <div className='modal-body'>
              <p className='text-start'>
                Warning, if you edit your username, it will change your iTRUSTit Web Address, do you
                want to continue?
              </p>
              <button
                className='btn btn-primary'
                type='button'
                onClick={this.props.usernameModel('yes')}
              >
                Yes
              </button>{' '}
              &nbsp;
              <button
                className='btn btn-primary'
                type='button'
                onClick={this.props.usernameModel('no')}
              >
                No
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default UsernameModal
