import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { getUser, kFormatter } from '../../../services/customFunction'
import { ROOT_ROUTE } from '../../../services/routeConst'
import {
  getFoFTrusted,
  getProfileFromSlug_v2,
  userProfileById,
} from '../../../services/userApiService'
import HeaderLogo from '../VCard/Components/HeaderLogo'
import ITeamId from './Components/ITeamId'
import VCardModal, { showVCard } from '../VCardModal/VCardModal'
import './VCard_New.scss'
import html2canvas from 'html2canvas'
import MutualOrgs from './Components/MutualOrgs'
import { SITE_ADDRESS } from '../../../services/environment'
import VCardQRCode from './Components/VCardQRCode'
import AdSpace from './Components/AdSpace'
import $J from 'jquery'
import { formatMembersName, toTitleCase } from '../../../services/formatService'
import SaveToHomescreenModal, {
  showSaveToHomescreenModal,
} from '../VCard/Components/SaveToHomescreenModal/SaveToHomescreenModal'
import { browserName, isAndroid, isIOS, isMobile } from 'react-device-detect'
import { Helmet } from 'react-helmet'
import logoBasic from '../../../styles/img/logo_basic-lg.png'
import { friendCreateCall } from '../../../services/friendApiService'
import { makeTrustCall } from '../../../services/trustApiService'
import { removeMemberCall } from '../../../services/memberApiService'
import LoginModal from '../../LoginModal/LoginModal'
import MemberPopupModal from '../../ProfileOtherUser/MemberPopupModal'
import ConfirmUntrustPopupModal from '../../ProfileOtherUser/ConfirmUntrustModal/ConfirmUntrustPopupModal'
import ConfirmUnfriendPopupModal from '../../ProfileOtherUser/ConfirmUnfriendModal/ConfirmUnfriendPopupModal'
import ConfirmLeaveOrgPopupModal from '../../ProfileOtherUser/ConfirmLeaveOrgModal/ConfirmLeaveOrgPopupModal'
import ShareModal, { showShareModal } from '../ShareModal/ShareModal'
import { DEFAULT_COVER_PICTURE } from '../../../services/const'
import CTAButton from './Components/CTAButton'
import SocialButton from './Components/SocialButton'
import WhatsAppNumberModal, {
  showWhatsAppNumberModal,
} from '../WhatsAppNumberModal/WhatsAppNumberModal'

export const saveToContacts = (user) => {
  const {
    vc_phone_no_1_show,
    vc_phone_no_2_show,
    vc_mobile_no_show,
    vc_fax_no_show,
    vc_email_show,
    vc_email_2_show,
    vc_email_3_show,
    vc_email_4_show,
    vc_website_1_show,
    vc_website_2_show,
    vc_address_show,
    vc_address_1_show,
    vc_address_2_show,
    vc_city_state_zip_show,
    vc_facebook_show = '1',
    vc_fiverr_show = '1',
    vc_instagram_show = '1',
    vc_linkedin_show = '1',
    vc_pinterest_show = '1',
    vc_tiktok_show = '1',
    vc_twitter_show = '1',
    vc_youtube_show = '1',
    vc_whatsapp_show = '1',
    vc_paypal_show,
    vc_venmo_show,
  } = user.user_settings
  const emailVal =
    vc_email_show === '1'
      ? user.email
      : vc_email_2_show === '1'
      ? user.email_2
      : vc_email_3_show === '1'
      ? user.email_3
      : vc_email_4_show === '1'
      ? user.email_4
      : null
  const vcardText = `BEGIN:VCARD
VERSION:3.0
N:${user.lastname};${user.firstname};;;
FN:${user.fullname}
${user?.user_type !== 'individual' ? `ORG:${user.business_organization_name}` : ''}

${vc_email_show === '1' && !!user.email ? `EMAIL;type=INTERNET;type=pref:${user.email}` : ''}
${vc_email_2_show === '1' && !!user.email_2 ? `EMAIL;2;type=INTERNET:${user.email_2}` : ''}
${vc_email_3_show === '1' && !!user.email_3 ? `EMAIL;3;type=INTERNET:${user.email_3}` : ''}
${vc_email_4_show === '1' && !!user.email_4 ? `EMAIL;4;type=INTERNET:${user.email_4}` : ''}

${vc_phone_no_1_show === '1' && !!user.phone_no_1 ? `TEL;type=MAIN:${user.phone_no_1}` : ''}
${vc_phone_no_2_show === '1' && !!user.phone_no_2 ? `TEL;type=WORK:${user.phone_no_2}` : ''}
${vc_whatsapp_show === '1' && !!user?.whatsapp ? `TEL;type=WhatsApp:${user.whatsapp}` : ''}

${
  vc_mobile_no_show === '1' && !!user.mobile_no
    ? `TEL;type=CELL;type=VOICE;type=pref:${user.mobile_no}`
    : ''
}
${vc_fax_no_show === '1' && !!user.fax_no ? `TEL;type=FAX:${user.fax_no}` : ''}

${
  vc_address_show === '1'
    ? `ADR;type=pref:;;${vc_address_1_show === '1' ? `${user.address_line_1} ` : ''}${
        vc_address_2_show === '1' ? `${user.address_line_2 ?? ''}` : ''
      };${
        vc_city_state_zip_show === '1'
          ? `${user.city_name};${user.state_short_name ?? user.state_name};${user.zipcode};`
          : ';;;'
      }`
    : ''
}
URL;type=iTRUSTit:${SITE_ADDRESS}${user.slug}
${vc_facebook_show === '1' && !!user?.facebook ? `URL;type=Facebook:${user.facebook}` : ''}
${vc_fiverr_show === '1' && !!user?.fiverr ? `URL;type=Fiverr:${user.fiverr}` : ''}
${vc_instagram_show === '1' && !!user?.instagram ? `URL;type=Instagram:${user.instagram}` : ''}
${vc_linkedin_show === '1' && !!user?.linkedin ? `URL;type=Linkedin:${user.linkedin}` : ''}
${vc_pinterest_show === '1' && !!user?.pinterest ? `URL;type=Pinterest:${user.pinterest}` : ''}
${vc_tiktok_show === '1' && !!user?.tiktok ? `URL;type=TikTok:${user.tiktok}` : ''}
${vc_twitter_show === '1' && !!user?.twitter ? `URL;type=Twitter:${user.twitter}` : ''}
${vc_youtube_show === '1' && !!user?.youtube ? `URL;type=YouTube:${user.youtube}` : ''}
${vc_paypal_show === '1' && !!user?.paypal ? `URL;type=Paypal:${user.paypal}` : ''}
${vc_venmo_show === '1' && !!user?.venmo ? `URL;type=Venmo:${user.venmo}` : ''}
${vc_website_1_show === '1' && !!user?.website_1 ? `URL;type=Website1:${user.website_1}` : ''}
${vc_website_2_show === '1' && !!user?.website_2 ? `URL;type=Website2:${user.website_2}` : ''}
END:VCARD
`
  const file = new Blob([vcardText], { type: 'text/vcard;charset=utf-8' })
  const a = document.createElement('a')
  a.download = `${user?.firstname}${user?.lastname}.vcf`
  a.href = URL.createObjectURL(file)
  var reader = new FileReader()

  if (navigator.userAgent.match('CriOS')) {
    reader.onloadend = (e) => {
      window.location.href = reader.result
    }
    reader.readAsDataURL(file)
  } else if (navigator.userAgent.match(/iPad|iPhone|Android/i)) {
    reader.onload = (e) => {
      // a.click()
      window.location.href = reader.result
    }
    reader.readAsDataURL(file)
  } else {
    window.location.href = a.href
  }
}

const VCard = (props) => {
  const { match, slug, inModal } = props

  const userSlug = slug ?? match?.params?.id ?? 0
  const [user, setUser] = useState()
  const [sponsorUser, setSponsorUser] = useState()
  const [userImg, setUserImg] = useState(process.env.PUBLIC_URL + '/img/iti_bug.png')
  const [userCoverImg, setUserCoverImg] = useState(DEFAULT_COVER_PICTURE)
  const [homescreenIcon, setHomescreenIcon] = useState()
  const history = useHistory()
  const vcardLink = `${SITE_ADDRESS}vcard/${user?.slug}`

  const iconRef = React.useRef()
  const logoRef = React.useRef()

  const loggedInUserId = getUser()?.id ?? 0

  const fetchUserData = React.useCallback(async () => {
    const { data } = await getProfileFromSlug_v2({
      slug: userSlug,
      loginUserId: loggedInUserId,
      getTotalTrusted: true,
    })
    if (!data?.data?.user_details) {
      history.push({ pathname: ROOT_ROUTE })
    } else {
      setUser(data?.data?.user_details)
      data?.data?.user_details?.profile_picture_medium &&
        setUserImg(data?.data?.user_details?.profile_picture_medium)
      data?.data?.user_details?.cover_picture_medium &&
        setUserCoverImg(data?.data?.user_details?.cover_picture_medium)
    }
  }, [userSlug])

  useEffect(() => {
    try {
      !!userSlug && !user && fetchUserData()
    } catch (error) {
      history.push({ pathname: ROOT_ROUTE })
    }
  }, [userSlug, fetchUserData])

  useEffect(() => {
    const fetchSponsorData = async () => {
      const { data } = await userProfileById(user?.sponsors_user_id)
      setSponsorUser(data?.data?.user_details)
    }
    user?.sponsors_user_id > 0 && fetchSponsorData()
  }, [user?.sponsors_user_id])

  useEffect(() => {
    const updateFoF = async () => {
      const { data } = await getFoFTrusted({
        id: user?.id,
        loginUserId: loggedInUserId,
      })
      setUser((st) => ({
        ...st,
        total_friends_of_friend_trusted: data?.data?.total_friends_of_friend_trusted ?? 0,
      }))
    }
    if (!user?.id) return
    updateFoF()
    $J('#address-collapse')?.collapse('hide') //For allowing bootstrap to add the 'collapsed' class onto the buttons on initial render - for rotation purposes
    $J('#contact-info-collapse')?.collapse('hide')
  }, [user?.id])

  // const handleShareBtn = () => {
  //   if (navigator.share) {
  //     navigator.share({ title: user?.fullname ?? document.title, url: vcardLink })
  //   } else {
  //     showVCard()
  //   }
  // }

  const htmlToCanv = () =>
    !!iconRef.current &&
    html2canvas(iconRef.current, { allowTaint: true, useCORS: true, logging: true }).then(
      (canvas) => {
        let imgData = canvas?.toDataURL('image/png')
        if (!imgData?.split('base64,')?.[1]) return
        while (imgData.split('base64,')[1].length % 4 > 0) {
          imgData += '='
        }
        var img = new Image()
        img.src = imgData
        img.crossOrigin = 'anonymous'
        img.onload = () => {
          setHomescreenIcon(imgData)
        }
      },
    )

  const confirmFriendAddRemove = (needsConfirmation = false) => {
    needsConfirmation
      ? needsConfirmation && $J('#confirm_unfriend_popup_modal').modal('show')
      : friendAddRemove()
  }

  const friendAddRemove = async () => {
    if (!user?.is_friend_user) {
      const request = { user_id: user.id }
      const response = await friendCreateCall(request)
      setUser((st) => ({
        ...st,
        is_friend_user: 1,
        friendship_id: response?.data?.friendship_id,
      }))
    }
  }

  const removeFriendHandler = () => {
    setUser((st) => ({
      ...st,
      is_friend_user: 0,
      friendship_id: null,
    }))
  }

  const confirmTrustAddRemove = (needsConfirmation = false) => {
    needsConfirmation
      ? needsConfirmation && $J('#confirm_untrust_popup_modal').modal('show')
      : itrustitAddRemove()
  }

  const itrustitAddRemove = async () => {
    let request = { id: user.id }
    if (user.is_trusted_user !== 1) {
      await makeTrustCall(request)
      setUser((st) => ({ ...st, is_trusted_user: 1 }))
    }
  }

  const removeTrustHandler = () => {
    setUser((st) => ({ ...st, is_trusted_user: 0 }))
  }

  const confirmMemberAddRemove = (needsConfirmation = false) => {
    needsConfirmation
      ? needsConfirmation && $J('#confirm_leave_org').modal('show')
      : memberAddRemove()
  }

  const memberAddRemove = async () => {
    let request = { id: user.id, member_type: 'member_of_their_organization' }
    if ([1, 2].includes(user.is_member_user)) {
      await removeMemberCall(request)
      setUser((st) => ({ ...st, is_member_user: 0 }))
    } else {
      $J('#member_modal').modal('show')
    }
  }

  const setStatusOfMemberUser = (status) => {
    if (status === 'pending') {
      setUser((st) => ({ ...st, is_member_user: 2 }))
    } else if (status === 'accepted') {
      setUser((st) => ({ ...st, is_member_user: 1 }))
    } else {
      setUser((st) => ({ ...st, is_member_user: 0 }))
    }
  }

  const loginModalProps = !loggedInUserId
    ? {
        'data-bs-toggle': 'modal',
        'data-bs-target': '#login_modal',
      }
    : {}

  if (!user) return null

  const {
    address_line_1,
    address_line_2,
    city_name,
    state_short_name,
    state_name,
    zipcode,
    country_name,
    fullname,
    phone_no_1,
    phone_no_2,
    mobile_no,
    fax_no,
    email,
    email_2,
    email_3,
    email_4,
    website_1,
    website_2,
    website_3,
    website_4,
    is_friend_user,
    is_member_user,
    is_trusted_user,
  } = user
  const {
    vc_address_show,
    vc_address_1_show,
    vc_address_2_show,
    vc_city_state_zip_show,
    vc_country_show,
    vc_phone_no_1_show,
    vc_phone_no_2_show,
    vc_mobile_no_show,
    vc_fax_no_show,
    vc_email_show,
    vc_email_2_show,
    vc_email_3_show,
    vc_email_4_show,
    vc_website_1_show,
    vc_website_2_show,
    vc_website_3_show,
    vc_website_4_show,
    vc_phone_one_tap = 'mobile_no',
    vc_email_one_tap = 'email',
    vc_facebook_show = '1',
    vc_fiverr_show = '1',
    vc_instagram_show = '1',
    vc_linkedin_show = '1',
    vc_pinterest_show = '1',
    vc_tiktok_show = '1',
    vc_twitter_show = '1',
    vc_youtube_show = '1',
    vc_whatsapp_show = '1',
    vc_paypal_show,
    vc_venmo_show,
    members_name,
    custom_members_name,
  } = user.user_settings
  const membersName = formatMembersName(members_name, custom_members_name) ?? 'Member'
  const stateName = state_short_name ?? state_name
  const emailSettings = {
    email: { show: vc_email_show === '1', val: email },
    email_2: { show: vc_email_2_show === '1', val: email_2 },
    email_3: { show: vc_email_3_show === '1', val: email_3 },
    email_4: { show: vc_email_4_show === '1', val: email_4 },
  }

  const hasCount = (settingNames, count = 1) =>
    settingNames?.filter(
      (setting) =>
        user?.user_settings?.[setting] === '1' &&
        (setting === 'vc_city_state_zip_show'
          ? user?.city_name?.length || user?.state_name?.length || user?.zipcode?.length
          : !!user?.[setting?.replace('vc_', '')?.replace('_show', '')]),
    )?.length > count

  const getFriendBtnClassName = () =>
    is_friend_user === 1
      ? ' vcard-friends-btn'
      : is_friend_user === 2
      ? ' vcard-friends-btn pending'
      : ''

  const formatAddressForLink = () => {
    let query = ''
    if (vc_address_1_show === '1' && !!address_line_1) {
      query += address_line_1
    }
    if (vc_address_2_show === '1' && !!address_line_2) {
      query += ' ' + address_line_2
    }
    if (vc_city_state_zip_show === '1') {
      if (city_name?.length) {
        query += ' ' + city_name
      }
      if (state_name?.length) {
        query += ' ' + state_name
      }
      if (zipcode?.length) {
        query += ' ' + zipcode
      }
    }
    if (vc_country_show === '1' && !!country_name) {
      query += ' ' + country_name
    }
    return query?.length ? encodeURIComponent(query) : null
    // return '210+Louise+Ave,+Nashville,+TN+37203'
  }

  const isCriOS = navigator.userAgent.match('CriOS')
  const isAndroidOrMobileSafari = !!isMobile && (!!isAndroid || browserName.includes('Safari'))

  return !!user ? (
    <>
      <div style={{ position: 'absolute', top: -9999, left: -9999, zIndex: -1 }}>
        <div
          ref={iconRef}
          className='vcard-app-icon my-2 d-flex flex-column align-items-center border-radius-0'
          style={{ maxWidth: 72, backgroundColor: 'white' }}
        >
          <div className={`vcard-avatar ${user.user_type} in-modal homescreen-icon`}>
            <img
              src={userImg ?? process.env.PUBLIC_URL + '/img/iti_bug.png'}
              height='auto'
              onError={({ currentTarget }) => {
                setUserImg(process.env.PUBLIC_URL + '/img/iti_bug.png')
              }}
              alt=''
              className='vcard-avatarImg'
            />
          </div>
          <img
            ref={logoRef}
            onLoad={() => {
              htmlToCanv()
            }}
            src={logoBasic}
            style={{ marginTop: 2 }}
            width={42}
            height='auto'
            alt=''
          />
        </div>
      </div>
      <Helmet>
        <meta charSet='utf-8' />
        <meta name='mobile-web-app-capable' content='yes' />
        <title>{user?.fullname}</title>
        <meta name='apple-mobile-web-app-title' content={user?.fullname} />
        <meta name='application-name' content={user?.fullname} />
        <link
          crossOrigin='anonymous'
          sizes='108x109'
          rel='apple-touch-icon'
          href={homescreenIcon ?? process.env.PUBLIC_URL + '/img/iti_bug.png'}
        />
        <link
          crossOrigin='anonymous'
          sizes='108x109'
          rel='icon'
          type='image/png'
          href={homescreenIcon ?? process.env.PUBLIC_URL + '/img/iti_bug.png'}
        />
        <link
          crossOrigin='anonymous'
          sizes='108x109'
          rel='shortcut icon'
          type='image/png'
          href={homescreenIcon ?? process.env.PUBLIC_URL + '/img/iti_bug.png'}
        />
        <meta property='og:url' content={vcardLink} />
        <meta property='og:title' content={user?.fullname} />
        <meta property='og:description' content={user?.fullname + "'s vCard on iTRUSTit!"} />
        <meta
          property='og:image'
          content={homescreenIcon ?? process.env.PUBLIC_URL + '/img/iti_bug.png'}
        />
      </Helmet>
      <ShareModal user={user} onVCard />
      <div className='vcard-container'>
        <button className='vcard-floating-share-btn' onClick={() => showShareModal()}>
          <i className='fa fa-share-alt'></i>
        </button>
        {/* AD SPACE */}

        <div className='vcard-wrapper d-flex flex-column align-items-center mx-auto px-2'>
          {/* {!!sponsorUser ? <AdSpace user={sponsorUser} /> : null} */}
          {/* LOGO AND SPONSOR ID */}
          <div
            className='d-flex align-items-center justify-content-between w-100 px-2'
            style={{ backgroundColor: 'white' }}
          >
            <ITeamId iTeamId={user?.imatrix_id} />
            <HeaderLogo width={65} className='mb-0 py-1' />
            <ITeamId iTeamId={user?.imatrix_id} hidden />
          </div>

          {/* COVER IMAGE */}
          <div className='w-100'>
            <img
              className='vcard-cover-image'
              src={userCoverImg ?? process.env.PUBLIC_URL + '/img/banner-img.png'}
              alt=''
              onError={() => setUserCoverImg(process.env.PUBLIC_URL + '/img/banner-img.png')}
            />
          </div>

          {/* AVATAR IMAGE */}
          <div style={{ marginTop: -63 }}>
            <a
              className='vcard-avatar-image-wrapper'
              target='_blank'
              rel='noopener noreferrer'
              href={`${SITE_ADDRESS}${user?.slug}`}
            >
              <img
                className={`vcard-avatar-image ${user?.user_type}`}
                src={userImg ?? process.env.PUBLIC_URL + '/img/iti_bug.png'}
                onError={() => setUserImg(process.env.PUBLIC_URL + '/img/iti_bug.png')}
                alt=''
              />
            </a>
          </div>

          {/* TRUSTED BY BOXES */}
          {/* <div className='d-flex justify-content-between w-100' style={{ marginTop: -55 }}>
          <div className='vcard-trusted-by-box d-flex flex-column align-items-center'>
            <div className='mb-3'>Trusted By:</div>
            <div className='trusted-by-amt'>{user?.total_trusted_by}</div>
            <div className='mb-2'>Total</div>
          </div>
          <div className='vcard-mutual-orgs d-flex flex-column align-items-center justify-content-end'>
            {user?.total_common_members_list?.length ? (
              <MutualOrgs user={user} login_user_id={loggedInUserId} />
            ) : null}
          </div>
          <div className='vcard-trusted-by-box d-flex flex-column align-items-center'>
            <div className='mb-3'>Trusted By:</div>
            <div className='trusted-by-amt'>{user?.total_trusted_by}</div>
            <div className='mb-2'>Friends</div>
          </div>
        </div> */}

          {/* TOTAL FRIENDS COUNT */}
          <div className='d-flex justify-content-end w-100' style={{ marginTop: -55 }}>
            <a
              className='vcard-friends-count-box d-flex flex-column align-items-center'
              target='_blank'
              rel='noopener noreferrer'
              href={`${SITE_ADDRESS}${user?.id === loggedInUserId ? 'profile' : user?.slug}#friend`}
            >
              <div className='total-friends-count'>{kFormatter(user?.total_friends, 0)}</div>
              <small>Friend{user?.total_friends > 1 ? 's' : ''}</small>
            </a>
          </div>

          {/* USER NAME */}
          <div className='vcard-fullname mt-3 mb-2' style={{ wordBreak: 'break-word' }}>
            {user?.fullname}
          </div>
          {!!user?.tagline ? (
            <em className='vcard-tagline' style={{ wordBreak: 'break-word', color: '#eaeaea' }}>
              {user?.tagline}
            </em>
          ) : null}

          {/* VCARD BADGE */}
          <div
            className={`vcard-trust-badge d-flex flex-column mt-4 mb-5 ${
              !user?.total_common_members_list?.length ? 'justify-content-center' : ''
            } ${user?.user_type}-badge-type`}
          >
            <div
              className={`vcard-badge-title ${
                !user?.total_common_members_list?.length ? 'mb-3' : 'mb-1'
              }`}
            >
              {user?.user_type === 'individual' ? 'iTrust' : 'Trusted By'}
            </div>
            <div className='d-flex justify-content-evenly mb-2'>
              {user?.user_type === 'individual' ? (
                <>
                  <a
                    className={`vcard-trusted-by d-flex flex-column align-items-center w-25`}
                    target='_blank'
                    rel='noopener noreferrer'
                    href={`${SITE_ADDRESS}${
                      user?.id === loggedInUserId ? 'profile' : user?.slug
                    }?trusted_type=i_trust&userType=business#trust`}
                  >
                    <div className='trusted-by-amt'>
                      {user?.total_trusted?.find((data) => data?.user_type === 'business')?.total ??
                        0}
                    </div>
                    <div>Businesses</div>
                  </a>
                  <div className='vcard-trust-badge-vertical-divider'></div>
                  <a
                    className='vcard-trusted-by d-flex flex-column align-items-center w-25'
                    target='_blank'
                    rel='noopener noreferrer'
                    href={`${SITE_ADDRESS}${
                      user?.id === loggedInUserId ? 'profile' : user?.slug
                    }?trusted_type=i_trust&userType=organization#trust`}
                  >
                    <div className='trusted-by-amt'>
                      {user?.total_trusted?.find((data) => data?.user_type === 'organization')
                        ?.total ?? 0}
                    </div>
                    <div>Organizations</div>
                  </a>
                </>
              ) : (
                <>
                  <a
                    {...loginModalProps}
                    className={`vcard-trusted-by d-flex flex-column align-items-center w-25`}
                    target='_blank'
                    rel='noopener noreferrer'
                    href={`${SITE_ADDRESS}${
                      user?.id === loggedInUserId ? 'profile' : user?.slug
                    }?trusted_type=trusted_me&trusted_me_type=friends#trust`}
                  >
                    <div className={`trusted-by-amt ${!loggedInUserId ? 'login-msg' : ''}`}>
                      {!loggedInUserId ? 'Login or Sign Up' : user?.total_friends_trusted ?? 0}
                    </div>
                    <div>Friends</div>
                  </a>
                  <div className='vcard-trust-badge-vertical-divider'></div>
                  <a
                    {...loginModalProps}
                    className='vcard-trusted-by d-flex flex-column align-items-center w-50'
                    target='_blank'
                    rel='noopener noreferrer'
                    href={`${SITE_ADDRESS}${
                      user?.id === loggedInUserId ? 'profile' : user?.slug
                    }?trusted_type=trusted_me&trusted_me_type=friends_of_friends#trust`}
                  >
                    <div className={`trusted-by-amt ${!loggedInUserId ? 'login-msg' : ''}`}>
                      {!loggedInUserId
                        ? 'Login or Sign Up'
                        : user?.total_friends_of_friend_trusted ?? (
                            <div className='search-page-loading-icon orange'>
                              <i style={{ width: 27, height: 27, backgroundSize: 40 }} />
                            </div>
                          )}
                    </div>
                    <div>Friends of Friends</div>
                  </a>
                  <div className='vcard-trust-badge-vertical-divider'></div>
                  <a
                    className='vcard-trusted-by d-flex flex-column align-items-center w-25'
                    target='_blank'
                    rel='noopener noreferrer'
                    href={`${SITE_ADDRESS}${
                      user?.id === loggedInUserId ? 'profile' : user?.slug
                    }?trusted_type=trusted_me#trust`}
                  >
                    <div className='trusted-by-amt'>{user?.total_trusted_by ?? 0}</div>
                    <div>Total</div>
                  </a>
                </>
              )}
            </div>
            <div className='d-flex'></div>
            <div className='vcard-mutual-orgs d-flex flex-column align-items-center justify-content-end'>
              {!loggedInUserId || user?.total_common_members_list?.length ? (
                <MutualOrgs user={user} login_user_id={loggedInUserId} />
              ) : null}
            </div>
          </div>

          {/* DIVIDER */}
          {/* <div className='vcard-divider my-3'></div> */}

          {/* ACTION BUTTONS */}
          {user?.id !== loggedInUserId ? (
            <div className='d-flex w-100 justify-content-center mt-4'>
              <button
                {...loginModalProps}
                onClick={() =>
                  !!loggedInUserId &&
                  confirmFriendAddRemove(is_friend_user === 1 || user?.friendship_id > 0)
                }
                className={`vcard-action-btn${getFriendBtnClassName()} me-1`}
                style={{
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                }}
              >
                <i
                  aria-hidden='true'
                  className={`fas fa-${is_friend_user ? 'check' : 'plus'} me-1`}
                />
                {is_friend_user ? 'Friends' : 'Friend'}
              </button>
              {user?.user_type !== 'individual' ? (
                <>
                  <button
                    {...loginModalProps}
                    onClick={() => !!loggedInUserId && confirmTrustAddRemove(is_trusted_user)}
                    className={`vcard-action-btn${
                      is_trusted_user ? ' vcard-trusted-btn' : ''
                    } me-1`}
                    style={{
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    <i
                      aria-hidden='true'
                      className={`fas fa-${is_trusted_user ? 'check' : 'plus'} me-1`}
                    />
                    {is_trusted_user ? 'Trusted' : 'Trust'}
                  </button>
                  {user?.user_type === 'organization' ? (
                    <button
                      {...loginModalProps}
                      onClick={() =>
                        !!loggedInUserId && confirmMemberAddRemove([1, 2].includes(is_member_user))
                      }
                      className={`mw-100-mobile vcard-action-btn${
                        is_member_user === 1
                          ? ' vcard-member-btn'
                          : is_member_user === 2
                          ? ' vcard-member-btn pending'
                          : ''
                      }`}
                      style={{
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      <i
                        aria-hidden='true'
                        className={`fas fa-${
                          is_member_user === 1
                            ? 'check'
                            : is_member_user === 2
                            ? 'parking-circle'
                            : 'plus'
                        } me-1`}
                      />
                      {formatMembersName(membersName, null, false).toUpperCase()}
                    </button>
                  ) : null}
                </>
              ) : null}
            </div>
          ) : (
            <div className='mt-3'></div> //Hackily added some spacing
          )}

          {/* CTA Section */}
          <div className='vcard-cta-section-wrapper d-flex w-100 flex-column my-3'>
            <div className='vcard-cta-section d-flex justify-content-around p-1'>
              <CTAButton
                disabled={
                  !(
                    user?.user_settings?.[`vc_${vc_phone_one_tap ?? 'mobile_no'}_show`] === '1' &&
                    !!user?.[vc_phone_one_tap ?? 'mobile_no']
                  )
                }
                label='Mobile'
                icon='phone-alt'
                position='left'
                href={`tel:${user?.[vc_phone_one_tap ?? 'mobile_no']}`}
                labelPadding='10px 30px 9px 20px'
              />
              <CTAButton
                disabled={!(vc_mobile_no_show === '1' && !!mobile_no)}
                label='Text'
                icon='sms'
                position='right'
                href={`sms:${user?.mobile_no}`}
                labelPadding='10px 34px 9px 34px'
              />
            </div>
            <div className='vcard-cta-section d-flex justify-content-around p-1'>
              <CTAButton
                disabled={
                  !(
                    user?.user_settings?.[`vc_${vc_email_one_tap ?? 'email'}_show`] === '1' &&
                    !!user?.[vc_email_one_tap ?? 'email']
                  )
                }
                label='Email'
                icon='envelope'
                position='left'
                href={`mailto:${user?.email}`}
                labelPadding='10px 32px 9px 25px'
              />
              <CTAButton
                disabled={!(vc_website_1_show === '1' && !!website_1)}
                label='Website'
                icon='globe'
                position='right'
                href={user?.website_1}
                labelPadding='10px 15px 9px 20px'
              />
            </div>
            <div className='vcard-cta-section d-flex justify-content-around p-1'>
              <CTAButton
                label='Profile'
                icon={
                  <img
                    width={24}
                    style={{ verticalAlign: 'initial' }}
                    src={process.env.PUBLIC_URL + '/img/iti_bug-white.png'}
                    alt=''
                  />
                }
                position='left'
                href={`${SITE_ADDRESS}${user?.slug}`}
                labelPadding='10px 6px 9px 25px'
              />
              <CTAButton
                label='Join'
                icon={
                  <img
                    width={24}
                    style={{ verticalAlign: 'initial' }}
                    src={process.env.PUBLIC_URL + '/img/iti_bug-white.png'}
                    alt=''
                  />
                }
                position='right'
                href={`${SITE_ADDRESS}invite/${user?.imatrix_id}`}
                labelPadding='10px 30px 9px 25px'
              />
            </div>
            <div className='vcard-cta-section d-flex justify-content-around p-1'>
              <div
                className={`vcard-cta-btn w-50 m-2 d-flex me-auto ${isCriOS ? ' is-disabled' : ''}`}
                onClick={() => (isCriOS ? null : saveToContacts(user))}
                style={{
                  cursor: isCriOS ? 'not-allowed' : 'pointer',
                  opacity: isCriOS ? 0.5 : 1,
                }}
                title={
                  isCriOS
                    ? `Your device does not support this feature`
                    : `Save ${user.fullname}'s contact information to your device!`
                }
              >
                <span className='vcard-cta-btn-icon-wrapper d-flex align-items-center justify-content-center'>
                  <span className='vcard-cta-btn-icon'>
                    <i className='fas fa-id-card' aria-hidden='true'></i>
                  </span>
                </span>
                <span className='text-center' style={{ padding: 0 }}>
                  Save Contact
                </span>
              </div>

              <>
                <div
                  className={`vcard-cta-btn w-50 m-2 d-flex ms-auto ${
                    isAndroidOrMobileSafari ? '' : ' is-disabled'
                  }`}
                  onClick={() => (isAndroidOrMobileSafari ? showSaveToHomescreenModal() : null)}
                  title={
                    isAndroidOrMobileSafari
                      ? `Save a shortcut to ${user.fullname}'s VCard to your device's home screen!`
                      : `Your device does not support this feature`
                  }
                >
                  <span className='vcard-cta-btn-icon-wrapper d-flex align-items-center justify-content-center'>
                    <span className='vcard-cta-btn-icon'>
                      <i className='fas fa-plus-square' aria-hidden='true'></i>
                    </span>
                  </span>
                  <span
                    className='text-center'
                    style={{ padding: 0, alignSelf: 'center', fontSize: 12 }}
                  >
                    Add to Home Screen
                  </span>
                </div>
                <SaveToHomescreenModal user={user} />
              </>
            </div>
          </div>

          {/* SOCIAL BUTTONS */}
          <div className='d-flex align-items-center justify-content-evenly flex-wrap mb-5'>
            <SocialButton
              href={user?.facebook}
              icon='facebook'
              disabled={vc_facebook_show === '0' || !user?.facebook}
            />
            <SocialButton
              href={user?.fiverr}
              icon={
                <img
                  className='vcard-social-icon-icon'
                  src={process.env.PUBLIC_URL + '/img/fiverr.svg'}
                  alt=''
                />
              }
              disabled={vc_fiverr_show === '0' || !user?.fiverr}
            />
            <SocialButton
              href={user?.instagram}
              icon='instagram'
              disabled={vc_instagram_show === '0' || !user?.instagram}
            />
            <SocialButton
              href={user?.linkedin}
              icon='linkedin-in'
              disabled={vc_linkedin_show === '0' || !user?.linkedin}
            />
            <SocialButton
              href={user?.pinterest}
              icon='pinterest'
              disabled={vc_pinterest_show === '0' || !user?.pinterest}
            />
            <SocialButton
              href={user?.tiktok}
              icon='tiktok'
              disabled={vc_tiktok_show === '0' || !user?.tiktok}
            />
            <SocialButton
              href={user?.twitter}
              icon='twitter'
              disabled={vc_twitter_show === '0' || !user?.twitter}
            />
            <SocialButton
              href={user?.youtube}
              icon='youtube'
              disabled={vc_youtube_show === '0' || !user?.youtube}
              buttonStyles={{ paddingLeft: '0.45em', paddingRight: '0.55em' }}
            />
            <SocialButton
              icon='whatsapp'
              disabled={vc_whatsapp_show === '0' || !user?.whatsapp}
              onClick={showWhatsAppNumberModal}
            />
            <SocialButton
              href={user?.paypal}
              icon='paypal'
              disabled={vc_paypal_show === '0' || !user?.paypal}
            />
            <SocialButton
              href={user?.venmo}
              icon={
                <img
                  className='vcard-social-icon-icon'
                  src={process.env.PUBLIC_URL + '/img/venmo-icon.svg'}
                  alt=''
                />
              }
              disabled={vc_venmo_show === '0' || !user?.venmo}
            />
          </div>

          {/* SHARE VCARD BUTTON */}
          <div className='d-flex justify-content-center-align-items-center mb-3'>
            <button className='vcard-add-to-homescreen-button' onClick={() => showShareModal()}>
              Share this Virtual Card
            </button>
          </div>

          {/* VCARD QR CODE */}
          <VCardQRCode vcardLink={`${SITE_ADDRESS}qr/vcard/${user?.id}`} size={185} />
          <div className='d-flex mt-2 mb-4'>
            <button className='vcard-qr-code-print-btn me-2'>Print QR Code</button>
            <button className='vcard-qr-code-download-btn'>Download</button>
          </div>

          {/* ADDRESS AND CONTACT INFO */}
          <div
            className='vcard-address-contact-info d-flex flex-column align-items-center justify-content-center w-100 p-2'
            id='address-contact-info'
          >
            {vc_address_show === '1' &&
            hasCount(
              [
                'vc_address_1_show',
                'vc_address_2_show',
                'vc_city_state_zip_show',
                'vc_country_show',
              ],
              0,
            ) ? (
              <>
                <button
                  className={`vcard-address-dropdown w-100 d-flex  justify-content-between`}
                  data-bs-toggle='collapse'
                  href='#address-collapse'
                  aria-expanded='false'
                  aria-controls='address-collapse'
                  data-bs-parent='#address-contact-info'
                >
                  <span>Address</span>
                  <div className='vcard-address-toggle d-inline-flex' style={{ color: 'black' }}>
                    <i className='fas fa-angle-double-down'></i>
                  </div>
                </button>
                <div className='collapse multi-collapse w-100' id='address-collapse'>
                  <a
                    href={`${
                      isIOS ? 'maps:' : 'https:'
                    }//maps.google.com/maps?q=${formatAddressForLink()}`}
                    className={`vcard-address-info px-3 py-2 d-flex flex-column`}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    {vc_address_1_show === '1' && !!address_line_1 ? (
                      <span>{address_line_1}</span>
                    ) : null}
                    {vc_address_2_show === '1' && !!address_line_2 ? (
                      <span>{address_line_2}</span>
                    ) : null}
                    {vc_city_state_zip_show === '1' ? (
                      <span>
                        {!!city_name ? city_name + ', ' : null}
                        {!!stateName ? stateName + ', ' : null}
                        {!!zipcode ? zipcode + ' ' : null}
                      </span>
                    ) : null}
                    {vc_country_show === '1' && !!country_name ? (
                      <span>{country_name + ' '}</span>
                    ) : null}
                  </a>
                </div>
              </>
            ) : null}
            <button
              className={`vcard-address-dropdown w-100 d-flex  justify-content-between`}
              data-bs-toggle='collapse'
              href='#contact-info-collapse'
              aria-expanded='false'
              aria-controls='contact-info-collapse'
              data-bs-parent='#address-contact-info'
            >
              <span>Contact Info</span>
              <div className='vcard-address-toggle d-inline-flex' style={{ color: 'black' }}>
                <i className='fas fa-angle-double-down'></i>
              </div>
            </button>
            <div className='collapse multi-collapse w-100' id='contact-info-collapse'>
              <div className={`vcard-address-info px-3 py-2 d-flex flex-column`}>
                <span className='mt-2'>{user?.fullname}</span>
                <span className='mb-4 ms-2'>
                  {user?.relationship_type ? toTitleCase(user?.relationship_type) : ''}
                </span>
                {hasCount(
                  [
                    'vc_phone_no_1_show',
                    'vc_phone_no_2_show',
                    'vc_mobile_no_show',
                    'vc_fax_no_show',
                  ],
                  0,
                ) ? (
                  <div className='mb-4'>
                    <span>
                      Phone number
                      {hasCount([
                        'vc_phone_no_1_show',
                        'vc_phone_no_2_show',
                        'vc_mobile_no_show',
                        'vc_fax_no_show',
                      ])
                        ? 's'
                        : ''}
                    </span>
                    <div className='d-flex flex-column ms-2'>
                      {vc_phone_no_1_show === '1' && !!phone_no_1 ? (
                        <span>
                          Phone Day:{' '}
                          <a
                            href={`tel:${user?.phone_no_1}`}
                            target='_blank'
                            rel='noopener noreferrer'
                          >
                            {user?.phone_no_1}
                          </a>
                        </span>
                      ) : null}
                      {vc_phone_no_2_show === '1' && !!phone_no_2 ? (
                        <span>
                          Phone Evening:{' '}
                          <a
                            href={`tel:${user?.phone_no_2}`}
                            target='_blank'
                            rel='noopener noreferrer'
                          >
                            {user?.phone_no_2}
                          </a>
                        </span>
                      ) : null}
                      {vc_mobile_no_show === '1' && !!mobile_no ? (
                        <span>
                          Mobile:{' '}
                          <a
                            href={`tel:${user?.mobile_no}`}
                            target='_blank'
                            rel='noopener noreferrer'
                          >
                            {user?.mobile_no}
                          </a>
                        </span>
                      ) : null}
                      {vc_fax_no_show === '1' && !!fax_no ? (
                        <a href={`fax:${user?.fax_no}`} target='_blank' rel='noopener noreferrer'>
                          Fax: {user?.fax_no}
                        </a>
                      ) : null}
                    </div>
                  </div>
                ) : null}
                {hasCount(
                  ['vc_email_show', 'vc_email_2_show', 'vc_email_3_show', 'vc_email_4_show'],
                  0,
                ) ? (
                  <div className='mb-4'>
                    <span>
                      Email
                      {hasCount([
                        'vc_email_show',
                        'vc_email_2_show',
                        'vc_email_3_show',
                        'vc_email_4_show',
                      ])
                        ? 's'
                        : ''}
                    </span>
                    <div className='d-flex flex-column ms-2'>
                      {['email', 'email_2', 'email_3', 'email_4'].map((email) =>
                        !!emailSettings[email]?.show && !!emailSettings[email]?.val ? (
                          <a
                            key={email}
                            href={`mailto:${emailSettings[email]?.val}`}
                            target='_blank'
                            rel='noopener noreferrer'
                          >
                            {emailSettings[email]?.val}
                          </a>
                        ) : null,
                      )}
                    </div>
                  </div>
                ) : null}
                {hasCount(
                  [
                    'vc_website_1_show',
                    'vc_website_2_show',
                    'vc_website_3_show',
                    'vc_website_4_show',
                  ],
                  0,
                ) ? (
                  <div className='mb-2'>
                    <span>
                      Website
                      {hasCount([
                        'vc_website_1_show',
                        'vc_website_2_show',
                        'vc_website_3_show',
                        'vc_website_4_show',
                      ])
                        ? 's'
                        : ''}
                    </span>
                    <div className='d-flex flex-column ms-2'>
                      {vc_website_1_show === '1' && !!website_1 ? (
                        <a href={user?.website_1} target='_blank' rel='noopener noreferrer'>
                          {user?.website_1}
                        </a>
                      ) : null}
                      {vc_website_2_show === '1' && !!website_2 ? (
                        <a href={user?.website_2} target='_blank' rel='noopener noreferrer'>
                          {user?.website_2}
                        </a>
                      ) : null}
                      {/* {vc_website_3_show === '1' && !!website_3 ? (
                        <a href={user?.website_3} target="_blank" rel="noopener noreferrer">{user?.website_3}</a>
                      ) : null}
                      {vc_website_4_show === '1' && !!website_4 ? (
                        <a href={user?.website_4} target="_blank" rel="noopener noreferrer">{user?.website_4}</a>
                      ) : null} */}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>

          {/* GET A VCARD BUTTON */}
          <div className='d-flex justify-content-center-align-items-center mt-4 mb-3'>
            <a
              className='vcard-get-vcard-button'
              href={`${SITE_ADDRESS}invite/${user?.imatrix_id}`}
              target='_blank'
              rel='noopener noreferrer'
            >
              Limited Time
              <span>CLICK HERE to get a free vCard like this</span>
            </a>
          </div>

          {/* ITI GIF */}
          <div style={{ overflow: 'hidden' }} className='my-3'>
            <img
              src={`${process.env.PUBLIC_URL}/img/vcard-video.gif`}
              alt=''
              width='100%'
              style={{ transform: 'scale(1.25)', zIndex: -1 }}
            />
          </div>

          {/* ITI VIDEO */}
          <video
            controls
            className='mb-3'
            // autoPlay
            src={`${process.env.PUBLIC_URL}/img/vcard-video.mp4`}
            width='100%'
            type='video/mp4'
          />

          {/* ADD TO HOMESCREEN BUTTON */}

          <div className='d-flex justify-content-center-align-items-center mb-3'>
            <button
              onClick={() => (isAndroidOrMobileSafari ? showSaveToHomescreenModal() : null)}
              className={`vcard-add-to-homescreen-button ${
                isAndroidOrMobileSafari ? '' : ' is-disabled'
              }`}
              style={{
                cursor: isAndroidOrMobileSafari ? 'pointer' : 'not-allowed',
                opacity: isAndroidOrMobileSafari ? 1 : 0.5,
              }}
              title={
                isAndroidOrMobileSafari
                  ? `Save a shortcut to ${user.fullname}'s VCard to your device's home screen!`
                  : `Your device does not support this feature`
              }
            >
              Add to Home Screen
            </button>
          </div>
          {/* {!!sponsorUser ? <AdSpace user={sponsorUser} /> : null} */}
        </div>

        {/* AD SPACE */}
      </div>
      <div className='vcard-share-text d-flex justify-content-center py-3'>
        Share this Virtual Card{' '}
        <img
          draggable='false'
          className='ms-2'
          width={18}
          alt='👉'
          src='https://s.w.org/images/core/emoji/13.1.0/svg/1f449.svg'
        />
      </div>
      <WhatsAppNumberModal whatsapp={user?.whatsapp} />
      {!inModal ? <VCardModal user={user} initialModalState='share' inModal={false} /> : null}
      <LoginModal
        imatrix_id={user?.imatrix_id}
        isLogin={(e) =>
          function (data) {
            props.isLogin(data)
          }
        }
        {...props}
      />
      <MemberPopupModal
        Orgname={user.business_organization_name}
        user_id={user.id}
        memberType='member_of_their_organization'
        membersName={
          formatMembersName(
            user?.user_settings?.members_name,
            user?.user_settings?.custom_members_name,
          ) ?? 'Members'
        }
        setLoadingMemberChangeResponse={() => {}}
        setStatusOfMemberUser={setStatusOfMemberUser}
      />
      <ConfirmUntrustPopupModal
        username={user?.fullname}
        data={{
          is_trusted_user: user?.is_trusted_user,
          id: user?.id,
        }}
        handler={removeTrustHandler}
      />
      <ConfirmUnfriendPopupModal
        username={user?.fullname}
        data={{
          is_friend_user: user?.is_friend_user,
          user_id: user?.id,
          friendship_id: user?.friendship_id,
        }}
        handler={removeFriendHandler}
      />
      <ConfirmLeaveOrgPopupModal
        Orgname={user?.fullname}
        user_id={user?.id}
        memberType='member_of_their_organization'
        data={{
          is_member_user: user?.is_member_user,
          id: user?.id,
          flag: 'member_of_their_organization',
        }}
        memberAddRemove={memberAddRemove}
      />
    </>
  ) : null
}

export default VCard
