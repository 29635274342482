import React, { useState } from 'react'
import { IMG_PATH } from '../../../services/const'
import { getUser } from '../../../services/customFunction'

import { DISABLE_SOCIAL_MEDIA_REPORT } from '../../../services/environment'

const PostActions = ({
  post,
  copyToClipboard,
  onOpenReportContentButtonClick,
  dontSeeHandler,
  onOpenDeleteButtonClick,
  onClickCommentSelection,
  onClickSeeSelection,
  editPost,
}) => {
  const login_user = getUser()
  const login_user_id = login_user?.id
  const adminUser = login_user?.roles?.filter((role) => role.id === 1)?.length > 0 ? true : false

  const [isLoggedUser, setIsLoggedUser] = useState(login_user_id !== undefined)
  const [loginModalProps, setLoginModalProps] = useState(
    !isLoggedUser
      ? {
          'data-bs-toggle': 'modal',
          'data-bs-target': '#login_modal',
        }
      : {},
  )
  return (
    <>
      <div className='postTopRight'>
        <div>
          <div class='dropdown'>
            <button
              class='btn dropdown-toggle'
              type='button'
              id='dropdownPostMenu'
              data-bs-toggle='dropdown'
              aria-expanded='false'
            >
              ...
            </button>
            <ul class='dropdown-menu' aria-labelledby='dropdownPostMenu'>
              {/*we can report any entry except ours*/}
              <li>
                <button
                  class='dropdown-item'
                  {...loginModalProps}
                  onClick={() => copyToClipboard()}
                >
                  <img src={IMG_PATH + 'copy.svg'} className='likeIcon link' alt='' />
                  Copy link to post
                </button>
              </li>
              {login_user_id !== post?.user_id ? (
                <>
                  <li>
                    <button
                      class='dropdown-item'
                      {...loginModalProps}
                      onClick={() => isLoggedUser && onOpenReportContentButtonClick()}
                      disabled={DISABLE_SOCIAL_MEDIA_REPORT}
                    >
                      <img src={IMG_PATH + 'report.svg'} className='likeIcon link' alt='' />
                      Report this post
                    </button>
                  </li>
                  <li>
                    <button
                      class='dropdown-item'
                      {...loginModalProps}
                      onClick={() => isLoggedUser && dontSeeHandler()}
                      disabled={DISABLE_SOCIAL_MEDIA_REPORT}
                    >
                      <img src={IMG_PATH + 'nosee.svg'} className='likeIcon link' alt='' />
                      Don't want to see this
                    </button>
                  </li>
                </>
              ) : (
                ''
              )}
              {/*we can deleteour own entry*/}
              {login_user_id === post?.user_id || adminUser ? (
                <>
                  {post?.post_type_id != '1' && (
                    <li>
                      <button
                        class='dropdown-item'
                        {...loginModalProps}
                        onClick={() => isLoggedUser && editPost()}
                      >
                        <img src={IMG_PATH + 'edit.svg'} className='likeIcon link' alt='' />
                        Edit this post
                      </button>
                    </li>
                  )}
                  <li>
                    <button
                      class='dropdown-item'
                      {...loginModalProps}
                      onClick={() => isLoggedUser && onOpenDeleteButtonClick()}
                    >
                      <img src={IMG_PATH + 'delete.svg'} className='likeIcon link' alt='' />
                      Delete this post
                    </button>
                  </li>
                  <li>
                    <button
                      class='dropdown-item'
                      {...loginModalProps}
                      onClick={() => isLoggedUser && onClickCommentSelection()}
                    >
                      <img src={IMG_PATH + 'comment.svg'} className='likeIcon link' alt='' />
                      Who can comment on this post
                    </button>
                  </li>
                  <li>
                    <button
                      class='dropdown-item'
                      {...loginModalProps}
                      onClick={() => isLoggedUser && onClickSeeSelection(true)}
                    >
                      <img src={IMG_PATH + 'see.svg'} className='likeIcon link' alt='' />
                      Who can see this post
                    </button>
                  </li>
                </>
              ) : (
                ''
              )}
            </ul>
          </div>
        </div>
      </div>
    </>
  )
}

export default PostActions
