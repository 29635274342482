import React, { useEffect, useRef, useState } from 'react'
import { bindActionCreators } from 'redux'
import { Field, reduxForm } from 'redux-form'
import SocialLogin from '../SocialLogin/SocialLogin'
import {
  loginAction,
  signupAction,
  authenticateAction,
  resetAuthDataAction,
  authResponseAction,
  errorMsgAction,
  socialLoginAction,
  isLoadingAction,
  linkedInLoginAction,
} from '../../actions/auth'
import { connect } from 'react-redux'
import $ from 'jquery'
import { Link, useHistory } from 'react-router-dom'
import { PROFILE_ROUTE, SIGNUP_ROUTE } from '../../services/routeConst'
import classNames from 'classnames'
import renderInputField from '../Form/Input'
import { required } from '../../services/formValidation'
import { USE_OTP } from '../../services/environment'
import { getInviteMatrixId, setToken, setUser } from '../../services/customFunction'
import ConfirmSocialSignupModal, {
  hideConfirmSocialSignupModal,
  showConfirmSocialSignupModal,
} from '../SignupNew/ConfirmSocialSignupModal'
import SelectUserTypeModal, {
  hideSelectUserTypeModal,
  showSelectUserTypeModal,
} from '../SignupNew/SelectUserTypeModal'
import { DEFAULT_PROFILE_PICTURE } from '../../services/const'

export const showLoginOptionsModal = () => {
  $('#login_options_modal').modal('show')
}
export const hideLoginOptionsModal = () => {
  $('#login_options_modal').modal('hide')
}

let LoginOptionsModal = (props) => {
  const { status, setStatus, socialData, setSocialData, selectedUserType, setSelectedUserType } =
    props
  const [showEmailLogin, setShowEmailLogin] = useState(false)
  const [loginData, setLoginData] = useState({ username: null, password: null, loginError: null })
  const [otpToken, setOtpToken] = useState()
  const [socialSignupData, setSocialSignupData] = useState({})
  const bHistory = useHistory()
  // const [userId, setUserId] = useState(0)

  useEffect(() => {
    return () => {
      hideLoginOptionsModal()
    }
  }, [])

  useEffect(() => {
    if ($('#login_options_modal')?.hasClass?.('show') || status === 'submitting') {
      const { isLoading, user = {} } = props ?? {}
      handleLogin(isLoading, user)
    }
  }, [props?.user?.userdetail?.id, props?.user?.errormsg, props?.user?.authresponse])

  const handleLogin = (isLoading, user) => {
    if (!!user?.userdetail?.id) {
      props.resetAuthDataAction()
      loginAfterAuth(user)
    } else if (user?.authUserData) {
      // OTP Implementation
      // const { phone } = user?.authUserData
      // const otpType = !!phone ? 'sms' : 'email'
      // setState({ user, user_id: user?.authUserData?.user_id, otpType }, async () => {
      //   const { data } = await sendOtp({ user_id: user?.authUserData?.user_id, type: otpType })
      //   if (data?.sent_to) {
      //     showVerifyOtpModal(otpType)
      //     setState({ authUserData: user?.authUserData, otpSentTo: data?.sent_to })
      //   }
      // })
    }
    const socialRespData = user?.authresponse?.data?.data ?? user?.authresponse?.data
    const currentSocialData = socialData?.social_id
    if (
      Object.keys(user.errormsg).length > 0 ||
      user.errormsg.length > 0 ||
      (!!socialRespData?.social_id && socialRespData?.social_id !== currentSocialData?.social_id)
    ) {
      if (!!user?.authresponse?.data?.data?.social_id || !!user?.authresponse?.data?.social_id) {
        setStatus({ status: 'idle' })
        setSocialData({ socialData: socialRespData }, () => {
          props.authResponseAction(null)
          props.errorMsgAction({})
          hideLoginOptionsModal()
          if (!selectedUserType) {
            showSelectUserTypeModal()
          } else {
            showConfirmSocialSignupModal()
          }
        })
      }

      if (Object.keys(socialSignupData).length === 0) {
        if (user.errormsg.user_id) {
          setStatus({ status: 'idle' }, () => {
            // setUserId(user.errormsg.user_id)
            // setLoginEmailDisplay(user.errormsg.email)
            //After update
            hideLoginOptionsModal()
            $('#active_email_modal').modal('show')
            props.authResponseAction(null)
            props.errorMsgAction({})
          })
        } else {
          if (props.anyTouched) setStatus({ status: 'idle' })
          setLoginData((st) => ({ ...st, loginError: true }))
          //customFunction.toaster("error", user.errormsg.message);
          props.authResponseAction(null)
          props.errorMsgAction({})
        }
      }
    }
  }

  const loginAfterAuth = (user) => {
    setUser(user.userdetail)
    setToken(user.token)
    setOtpToken(user.otp_token)
    let profile_history = JSON.parse(window.localStorage.getItem('profile_history'))
    let history = {}

    history = {
      id: user.userdetail.id,
      profile_img: user.userdetail.profile_picture_medium
        ? user.userdetail.profile_picture_medium
        : DEFAULT_PROFILE_PICTURE,
      email: user.userdetail.email,
      type: user.userdetail.login_type,
      user_type: user.userdetail.user_type,
    }
    if (user.userdetail.user_type === 'individual') history.name = user.userdetail.fullname
    else history.name = user.userdetail.business_organization_name

    if (profile_history == null) {
      window.localStorage.setItem('profile_history', JSON.stringify([history]))
    } else {
      let userFind = profile_history.filter((profile) => {
        return profile.id === user.userdetail.id
      })
      if (userFind.length === 0) {
        profile_history.unshift(history)
        window.localStorage.setItem('profile_history', JSON.stringify(profile_history))
      } else {
        const profileIndex = profile_history.findIndex(
          (profile) => profile.id === user.userdetail.id,
        )
        profile_history[profileIndex] = history
        profile_history.push(...profile_history.splice(0, profileIndex))

        window.localStorage.setItem('profile_history', JSON.stringify(profile_history))
      }
    }

    if (!props?.setPromotionStep) {
      hideLoginOptionsModal()
      bHistory.push({
        pathname: PROFILE_ROUTE,
        //pathname: HOME_ROUTE,
      })
    } else {
      props?.setPromotionStep?.('prizes')
    }
    props?.isLogin?.(true)
  }

  const socialLoginHandler = (registerData) => {
    setStatus({ status: 'loading' })
    if (getInviteMatrixId()) {
      registerData.sponsors_iteam_id = getInviteMatrixId()
    }

    registerData.user_type = selectedUserType
    setSelectedUserType(null)
    setStatus({ status: 'submitting' }, () => {
      hideLoginOptionsModal()
      setSocialSignupData(registerData)
      props.socialLoginAction(registerData)
    })
  }

  const linkedInLoginHandler = (registerData) => {
    setStatus({ status: 'loading' })
    if (getInviteMatrixId()) {
      registerData.sponsors_iteam_id = getInviteMatrixId()
    }
    registerData.user_type = selectedUserType
    registerData.login_type = 'linkedin'
    registerData.social_login_type = 'linkedin'
    setSelectedUserType(null)
    setStatus({ status: 'submitting' }, () => {
      hideLoginOptionsModal()
      setSocialSignupData(registerData)
      props.linkedInLoginAction(registerData)
    })
  }
  const loginSubmitHandler = (event) => {
    event.preventDefault()
    setStatus({ status: 'loading' }, () => {
      USE_OTP
        ? props.authenticateAction({ ...loginData, otp_token: otpToken })
        : props.loginAction(loginData)
    })
  }

  const handleChange = (e) => {
    setLoginData((st) => ({ ...st, loginError: null, [e?.target?.name]: e?.target?.value }))
  }

  const { invalid } = props
  const loginBtnText = status === 'loading' ? 'Loading...' : 'Log In'
  const loginBtnDisabled = invalid || status === 'loading'
  return (
    <div className='modal fade login_options_modal' id='login_options_modal' role='dialog'>
      <div className='modal-dialog'>
        <div className='modal-content'>
          <div className='modal-header align-items-start pb-0'>
            <h5>
              <strong>LOG IN</strong>
            </h5>
            <button type='button' className='btn-close' data-bs-dismiss='modal'></button>
          </div>
          <div className='modal-body' style={{ textAlign: 'center' }}>
            <div className='loginblk row my-2'>
              {showEmailLogin ? (
                <>
                  {!!loginData?.loginError ? (
                    <span className='text-danger it-login-error-msg'>
                      The username, email or password <br />
                      you entered is incorrect.
                    </span>
                  ) : (
                    ''
                  )}

                  <fieldset id='login-fields'>
                    <div className='form-group'>
                      <Field
                        name='email'
                        id='email-input'
                        type='text'
                        className='form-control username-input'
                        component={renderInputField}
                        validate={[required]}
                        label='Email'
                        placeholder='Login with Username or Email '
                        autoComplete='off'
                        onChange={handleChange}
                        isUsernameInput
                      />
                    </div>
                    <div className='form-group'>
                      <Field
                        name='password'
                        id='password-input'
                        type='password'
                        className='form-control'
                        component={renderInputField}
                        validate={[required]}
                        label='Password'
                        placeholder='Password'
                        autoComplete='off'
                        onChange={handleChange}
                      />
                    </div>
                    <button
                      onClick={loginSubmitHandler}
                      className={classNames(
                        loginBtnDisabled && 'disabled',
                        'btn',
                        'btn-lg',
                        'btn-success',
                        'btn-block',
                        'loginbtn',
                        'signup-submit',
                      )}
                      disabled={loginBtnDisabled}
                    >
                      {loginBtnText}
                    </button>
                  </fieldset>
                  <div className='d-flex remember'>
                    <button
                      className='link btn forgot_pass_btn p-0'
                      onClick={() => {
                        hideLoginOptionsModal()
                        $('#reset_password_email_modal').modal('show')
                      }}
                    >
                      Forgot password?
                    </button>
                  </div>
                  <p className='f_14'>
                    Don't have an account?
                    <Link onClick={hideLoginOptionsModal} className='purple' to={SIGNUP_ROUTE}>
                      Sign up now!
                    </Link>
                  </p>
                </>
              ) : (
                <>
                  <div className='col-12'>
                    <div className='btnss'>
                      <button
                        onClick={() => setShowEmailLogin(true)}
                        style={{
                          backgroundColor: 'rgb(255, 255, 255)',
                          display: 'inline-flex',
                          alignItems: 'center',
                          color: 'rgba(0, 0, 0, 0.54)',
                          boxShadow:
                            'rgb(0 0 0 / 24%) 0px 2px 2px 0px, rgb(0 0 0 / 24%) 0px 0px 1px 0px',
                          padding: 0,
                          border: '1px solid #cbd4db',
                          fontSize: 14,
                          fontWeight: 500,
                          fontFamily: "'Roboto', 'sans-serif'",
                          width: '100%',
                          margin: '0 0 12px',
                          borderRadius: 30,
                        }}
                        className='apple-auth-btn'
                      >
                        <img
                          width={24}
                          src={process.env.PUBLIC_URL + '/img/email_invite.svg'}
                          alt='Log In with Username or Email'
                          style={{
                            marginRight: 10,
                            background: 'rgb(255, 255, 255)',
                            marginLeft: 15,
                          }}
                        />
                        <span style={{ padding: '10px 10px 10px 0px', fontWeight: 500 }}>
                          Log In with Username or Email
                        </span>
                      </button>
                    </div>
                  </div>
                  <SocialLogin
                    socialLogin={socialLoginHandler}
                    linkedInLogin={linkedInLoginHandler}
                    hideAppLayout
                    showSeparator={false}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

LoginOptionsModal = reduxForm({
  form: 'login_options_form',
})(LoginOptionsModal)

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      loginAction,
      signupAction,
      authenticateAction,
      resetAuthDataAction,
      authResponseAction,
      errorMsgAction,
      socialLoginAction,
      linkedInLoginAction,
      isLoadingAction,
    },
    dispatch,
  )
}

function mapStateToProps(state) {
  return {
    user: state.UserReducer,
    networkerror: state.UserReducer?.networkerror,
    errormsg: state.UserReducer?.errormsg,
    isLoading: state.UserReducer?.isLoading,
    initialValues: {
      email: state.UserReducer?.email?.length > 0 ? state?.UserReducer?.email : '',
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginOptionsModal)
