import React from 'react'

const CTAButton = ({
  disabled,
  label,
  icon,
  position,
  href,
  labelPadding,
  disabledTitle = 'This feature is not enabled by the vCard owner for this device.',
}) => {
  const content = (
    <>
      <span className='vcard-cta-btn-icon-wrapper d-flex align-items-center justify-content-center'>
        <span className='vcard-cta-btn-icon'>
          {typeof icon === 'string' ? (
            <i className={`fas fa-${icon}`} aria-hidden='true'></i>
          ) : (
            icon
          )}
        </span>
      </span>
      <span className='text-center' style={{ padding: labelPadding }}>
        {label}
      </span>
    </>
  )

  const wrapperProps = {
    className: `vcard-cta-btn w-50 m-2 d-flex m${position === 'left' ? 'e' : 's'}-auto ${
      disabled ? 'is-disabled' : ''
    }`,
  }

  return disabled ? (
    <div {...wrapperProps} title={disabledTitle}>
      {content}
    </div>
  ) : (
    <a target='_blank' rel='noopener noreferrer' {...wrapperProps} href={href}>
      {content}
    </a>
  )
}

export default CTAButton
