import axios from 'axios'
import { customLogger } from '../utils/CustomLogger'
import * as environemnt from './environment'
import { userToken, toaster } from './customFunction'

export const getProfile = (request) => {
  return axios
    .get(environemnt.BASE_API + environemnt.USER_PROFILE, {
      headers: {
        Authorization: userToken(),
        Accept: 'application/x.iTRUSTit.v1+json',
      },
    })
    .then(function (response) {
      return response
    })
    .catch(function (error) {
      if (
        typeof error.response !== 'undefined' &&
        typeof error.response.data !== 'undefined' &&
        typeof error.response.data.message !== 'undefined' &&
        error.response.data.message
      ) {
        toaster('error', error.response.data.message)
      }
      customLogger(error)
      return error.response
    })
}

export const getSlugFromId = async (id) => {
  try {
    const response = await axios.post(environemnt.BASE_API + environemnt.GET_SLUG_BY_ID + id, {
      headers: {
        Authorization: userToken(),
        Accept: 'application/x.iTRUSTit.v1+json',
      },
    })
    return response
  } catch (error) {
    if (error?.response?.data?.message) {
      toaster('error', error?.response.data.message)
    }
    customLogger(error)
    return error?.response
  }
}

export const allUsersList = (request) => {
  return axios
    .post(environemnt.BASE_API + environemnt.ALL_USERS_LIST_API, request, {
      headers: {
        Authorization: userToken(),
        Accept: 'application/x.iTRUSTit.v1+json',
      },
    })
    .then(function (response) {
      return response.data
    })
    .catch(function (error) {
      if (
        typeof error.response !== 'undefined' &&
        typeof error.response.data !== 'undefined' &&
        typeof error.response.data.message !== 'undefined' &&
        error.response.data.message
      ) {
        toaster('error', error.response.data.message)
      }
      customLogger(error)
      return error.response
    })
}

export const profileInfoList = (request) => {
  // console.log(request);
  return axios
    .post(environemnt.BASE_API + environemnt.PROFILE_INFO_LIST || 'profile-info-list', request, {
      headers: {
        Authorization: userToken(),
        Accept: 'application/x.iTRUSTit.v1+json',
      },
    })
    .then(function (response) {
      return response.data
    })
    .catch(function (error) {
      if (
        typeof error.response !== 'undefined' &&
        typeof error.response.data !== 'undefined' &&
        typeof error.response.data.message !== 'undefined' &&
        error.response.data.message
      ) {
        toaster('error', error.response.data.message)
      }
      customLogger(error)
      return error.response
    })
}

export const sortSearchResults = (request) => {
  // console.log(request);
  return axios
    .post(
      environemnt.BASE_API + environemnt.SORT_SEARCH_RESULTS || 'sort-search-results',
      request,
      {
        headers: {
          Authorization: userToken(),
          Accept: 'application/x.iTRUSTit.v1+json',
        },
      },
    )
    .then(function (response) {
      return response.data
    })
    .catch(function (error) {
      if (
        typeof error.response !== 'undefined' &&
        typeof error.response.data !== 'undefined' &&
        typeof error.response.data.message !== 'undefined' &&
        error.response.data.message
      ) {
        toaster('error', error.response.data.message)
      }
      customLogger(error)
      return error.response
    })
}

export const getProfileFromId = (request) => {
  return axios
    .post(
      //environemnt.BASE_API + "get-profile-from-id/" + request.user_id,
      environemnt.BASE_API + environemnt.GET_PROFILE_WITH_SLUG + request.userslug,

      {},
      {
        headers: {
          Authorization: userToken(),
          Accept: 'application/x.iTRUSTit.v1+json',
        },
      },
    )
    .then(function (response) {
      return response.data
    })
    .catch(function (error) {
      if (
        typeof error.response !== 'undefined' &&
        typeof error.response.data !== 'undefined' &&
        typeof error.response.data.message !== 'undefined' &&
        error.response.data.message
      ) {
        toaster('error', error.response.data.message)
      }
      customLogger(error)
      return error.response
    })
}

export const getProfileFromIdByMsg = (request) => {
  return axios
    .get(
      environemnt.BASE_API + 'get-profile-from-id/' + request.user_id,
      {},
      {
        headers: {
          Authorization: userToken(),
          Accept: 'application/x.iTRUSTit.v1+json',
        },
      },
    )
    .then(function (response) {
      return response.data
    })
    .catch(function (error) {
      if (
        typeof error.response !== 'undefined' &&
        typeof error.response.data !== 'undefined' &&
        typeof error.response.data.message !== 'undefined' &&
        error.response.data.message
      ) {
        toaster('error', error.response.data.message)
      }
      customLogger(error)
      return error.response
    })
}

export const getProfileOverviewUserInfo = (request) => {
  let api_url = environemnt.BASE_API + 'overview/user-info'
  if (typeof request.other_user_id !== 'undefined' && request.other_user_id > 0) {
    api_url = environemnt.BASE_API + 'overview/user-info/' + request.other_user_id
  }
  return axios
    .post(
      api_url,
      {},
      {
        headers: {
          Authorization: userToken(),
          Accept: 'application/x.iTRUSTit.v1+json',
        },
      },
    )
    .then(function (response) {
      return response
    })
    .catch(function (error) {
      if (
        typeof error.response !== 'undefined' &&
        typeof error.response.data !== 'undefined' &&
        typeof error.response.data.message !== 'undefined' &&
        error.response.data.message
      ) {
        toaster('error', error.response.data.message)
      }
      customLogger(error)
      return error.response
    })
}

export const getProfileOverviewFriends = (request) => {
  let api_url = environemnt.BASE_API + 'overview/friend'
  if (typeof request.other_user_id !== 'undefined' && request.other_user_id > 0) {
    api_url = environemnt.BASE_API + 'overview/friend/' + request.other_user_id
  }
  return axios
    .post(
      api_url,
      {},
      {
        headers: {
          Authorization: userToken(),
          Accept: 'application/x.iTRUSTit.v1+json',
        },
      },
    )
    .then(function (response) {
      return response
    })
    .catch(function (error) {
      if (
        typeof error.response !== 'undefined' &&
        typeof error.response.data !== 'undefined' &&
        typeof error.response.data.message !== 'undefined' &&
        error.response.data.message
      ) {
        toaster('error', error.response.data.message)
      }
      customLogger(error)
      return error.response
    })
}

export const getProfileOverviewReviews = (request) => {
  let api_url = environemnt.BASE_API + 'overview/reviews'
  if (typeof request.other_user_id !== 'undefined' && request.other_user_id > 0) {
    api_url = environemnt.BASE_API + 'overview/reviews/' + request.other_user_id
  }
  return axios
    .post(
      api_url,
      {},
      // {
      //     headers: { Authorization: userToken(), Accept: "application/x.iTRUSTit.v1+json" },
      // }
    )
    .then(function (response) {
      return response
    })
    .catch(function (error) {
      if (
        typeof error.response !== 'undefined' &&
        typeof error.response.data !== 'undefined' &&
        typeof error.response.data.message !== 'undefined' &&
        error.response.data.message
      ) {
        toaster('error', error.response.data.message)
      }
      customLogger(error)
      return error.response
    })
}

export const relatedProfileOther = (user_id) => {
  return axios
    .post(
      environemnt.BASE_API + environemnt.RELATED_OTHER_PROFILE + user_id,
      {},
      {
        headers: {
          Authorization: userToken(),
          Accept: 'application/x.iTRUSTit.v1+json',
        },
      },
    )
    .then(function (response) {
      return response
    })
    .catch(function (error) {
      customLogger(error)
      return error.response
    })
}

export const uniqueEmailCheck = (request) => {
  return axios
    .post(
      environemnt.BASE_API +
        environemnt.UNIQUE_EMAIL_CHECK1 +
        request.user_id +
        environemnt.UNIQUE_EMAIL_CHECK2,
      { email: request.email },
      {
        headers: {
          Authorization: userToken(),
          Accept: 'application/x.iTRUSTit.v1+json',
        },
      },
    )
    .then(function (response) {
      return response
    })
    .catch(function (error) {
      customLogger(error)
      return error.response
    })
}

export const userProfileById = (user_id) => {
  return axios
    .post(
      environemnt.BASE_API + environemnt.USER_PROFILE_ID + user_id,
      {},
      {
        headers: {
          Authorization: userToken(),
          Accept: 'application/x.iTRUSTit.v1+json',
        },
      },
    )
    .then(function (response) {
      return response
    })
    .catch(function (error) {
      customLogger(error)
      return error.response
    })
}

export const getProfileOverviewGallery = (request) => {
  let api_url = environemnt.BASE_API + 'overview/gallery'
  if (typeof request.other_user_id !== 'undefined' && request.other_user_id > 0) {
    api_url = environemnt.BASE_API + 'overview/gallery/' + request.other_user_id
  }
  return axios
    .post(
      api_url,
      {},
      {
        headers: {
          Authorization: userToken(),
          Accept: 'application/x.iTRUSTit.v1+json',
        },
      },
    )
    .then(function (response) {
      return response
    })
    .catch(function (error) {
      if (
        error &&
        typeof error !== 'undefined' &&
        typeof error.response !== 'undefined' &&
        typeof error.response.data !== 'undefined' &&
        typeof error.response.data.message !== 'undefined' &&
        error.response.data.message
      ) {
        toaster('error', error.response.data.message)
      }
      customLogger(error)
      return error.response
    })
}

export const sharedProfile = (request) => {
  return axios
    .post(environemnt.BASE_API + environemnt.SHARED_PROFILE_API, request, {
      headers: {
        Authorization: userToken(),
        Accept: 'application/x.iTRUSTit.v1+json',
      },
    })
    .then(function (response) {
      return response
    })
    .catch(function (error) {
      if (
        error &&
        typeof error !== 'undefined' &&
        typeof error.response !== 'undefined' &&
        typeof error.response.data !== 'undefined' &&
        typeof error.response.data.message !== 'undefined' &&
        error.response.data.message
      ) {
        toaster('error', error.response.data.message)
      }
      customLogger(error)
      return error.response
    })
}

export const getProfileFromSlug = (request) => {
  return axios
    .post(environemnt.BASE_API + environemnt.USER_DETAIL_BY_SLUG + request.slug, {})
    .then(function (response) {
      return response.data
    })
    .catch(function (error) {
      if (
        typeof error.response !== 'undefined' &&
        typeof error.response.data !== 'undefined' &&
        typeof error.response.data.message !== 'undefined' &&
        error.response.data.message
      ) {
        toaster('error', error.response.data.message)
      }
      customLogger(error)
      return error.response
    })
}

export const getProfileFromSlugAndImatrix = (request) => {
  return axios
    .post(
      environemnt.BASE_API + environemnt.GET_INVITE_USER_DETAILS + request.slug,
      request?.idToClaim ? { idToClaim: request.idToClaim } : {},
    )
    .then(function (response) {
      return response.data
    })
    .catch(function (error) {
      if (
        typeof error.response !== 'undefined' &&
        typeof error.response.data !== 'undefined' &&
        typeof error.response.data.message !== 'undefined' &&
        error.response.data.message
      ) {
        toaster('error', error.response.data.message)
      }
      customLogger(error)
      return error.response
    })
}

export const getProfileFromSlug_v2 = (request) => {
  const { slug, ...payload } = request
  return axios
    .post(environemnt.BASE_API + environemnt.USER_DETAIL_BY_SLUG + slug, payload)
    .then(function (response) {
      return response
    })
    .catch(function (error) {
      if (
        typeof error.response !== 'undefined' &&
        typeof error.response.data !== 'undefined' &&
        typeof error.response.data.message !== 'undefined' &&
        error.response.data.message
      ) {
        toaster('error', error.response.data.message)
      }
      customLogger(error)
      return error.response
    })
}

export const getFoFTrusted = (request) => {
  const { id, ...payload } = request
  return axios
    .post(environemnt.BASE_API + environemnt.GET_FOF_TRUSTED + id, payload)
    .then(function (response) {
      return response
    })
    .catch(function (error) {
      if (
        typeof error.response !== 'undefined' &&
        typeof error.response.data !== 'undefined' &&
        typeof error.response.data.message !== 'undefined' &&
        error.response.data.message
      ) {
        toaster('error', error.response.data.message)
      }
      customLogger(error)
      return error.response
    })
}

export const checkUsername = (request) => {
  return axios
    .post(environemnt.BASE_API + environemnt.CHECK_USERNAME, request)
    .then(function (response) {
      // console.log(response.data);
      return response.data
    })
    .catch(function (error) {
      if (
        typeof error.response !== 'undefined' &&
        typeof error.response.data !== 'undefined' &&
        typeof error.response.data.message !== 'undefined' &&
        error.response.data.message
      ) {
        toaster('error', error.response.data.message)
      }
      customLogger(error)
      return error.response
    })
}

export const checkEmail = (request) => {
  return axios
    .post(environemnt.BASE_API + environemnt.CHECK_UEMAIL, request)
    .then(function (response) {
      // console.log(response.data);
      return response.data
    })
    .catch(function (error) {
      if (
        typeof error.response !== 'undefined' &&
        typeof error.response.data !== 'undefined' &&
        typeof error.response.data.message !== 'undefined' &&
        error.response.data.message
      ) {
        toaster('error', error.response.data.message)
      }
      customLogger(error)
      console.log(error.response)
      return error.response
    })
}
export const checkSponsorId = (request) => {
  return axios
    .post(environemnt.BASE_API + environemnt.CHECK_SPONSOR_ID, request)
    .then(function (response) {
      return response.data
    })
    .catch(function (error) {
      customLogger(error)
      return error.response
    })
}

export const getTrustedUserListByStatus = (id, type, page) => {
  // get-trusted-users-list-by-friends-status
  var data = {
    type: type,
    page: page,
  }
  return axios
    .post(environemnt.BASE_API + environemnt.TRUSTED_USER_LIST_BY_STATUS + '/' + id, data, {
      headers: {
        Authorization: userToken(),
        Accept: 'application/x.iTRUSTit.v1+json',
      },
    })
    .then(function (response) {
      return response.data
    })
    .catch(function (error) {
      customLogger(error)
      return error.response
    })
}

export const getProfileSetting = (request) => {
  return axios
    .get(environemnt.BASE_API + environemnt.USER_PROFILE_SETTING, {
      headers: {
        Authorization: userToken(),
        Accept: 'application/x.iTRUSTit.v1+json',
      },
    })
    .then(function (response) {
      return response
    })
    .catch(function (error) {
      if (
        typeof error.response !== 'undefined' &&
        typeof error.response.data !== 'undefined' &&
        typeof error.response.data.message !== 'undefined' &&
        error.response.data.message
      ) {
        toaster('error', error.response.data.message)
      }
      customLogger(error)
      return error.response
    })
}

export const getOtherUsersProfileSetting = (request) => {
  return axios
    .get(environemnt.BASE_API + environemnt.OTHER_USER_PROFILE_SETTING + `${request.id}`, {
      headers: {
        // Authorization: userToken(),
        Accept: 'application/x.iTRUSTit.v1+json',
      },
    })
    .then(function (response) {
      return response
    })
    .catch(function (error) {
      if (
        typeof error.response !== 'undefined' &&
        typeof error.response.data !== 'undefined' &&
        typeof error.response.data.message !== 'undefined' &&
        error.response.data.message
      ) {
        toaster('error', error.response.data.message)
      }
      customLogger(error)
      return error.response
    })
}

export const setProfileSetting = (request) => {
  return axios
    .post(environemnt.BASE_API + environemnt.USER_PROFILE_SETTING_SET, request, {
      headers: {
        Authorization: userToken(),
        Accept: 'application/x.iTRUSTit.v1+json',
      },
    })
    .then(function (response) {
      return response
    })
    .catch(function (error) {
      if (
        typeof error.response !== 'undefined' &&
        typeof error.response.data !== 'undefined' &&
        typeof error.response.data.message !== 'undefined' &&
        error.response.data.message
      ) {
        toaster('error', error.response.data.message)
      }
      customLogger(error)
      return error.response
    })
}

export const sendContactEmail = (request) => {
  return axios
    .post(environemnt.BASE_API + 'send-contact-email', request, {})
    .then(function (response) {
      return response
    })
    .catch(function (error) {
      if (
        error &&
        typeof error !== 'undefined' &&
        typeof error.response !== 'undefined' &&
        typeof error.response.data !== 'undefined' &&
        typeof error.response.data.message !== 'undefined' &&
        error.response.data.message
      ) {
        toaster('error', error.response.data.message)
      }
      customLogger(error)
      return error.response
    })
}

export const getProfileOverviewFriendsMutual = (request) => {
  let api_url = environemnt.BASE_API + 'overview/mutual-friend'
  if (typeof request.other_user_id !== 'undefined' && request.other_user_id > 0) {
    api_url = environemnt.BASE_API + 'overview/mutual-friend/' + request.other_user_id
  }
  return axios
    .post(
      api_url,
      {},
      {
        headers: {
          Authorization: userToken(),
          Accept: 'application/x.iTRUSTit.v1+json',
        },
      },
    )
    .then(function (response) {
      return response
    })
    .catch(function (error) {
      if (
        typeof error.response !== 'undefined' &&
        typeof error.response.data !== 'undefined' &&
        typeof error.response.data.message !== 'undefined' &&
        error.response.data.message
      ) {
        toaster('error', error.response.data.message)
      }
      customLogger(error)
      return error.response
    })
}

export const submitQuestionEmail = (request) => {
  return axios
    .post(environemnt.BASE_API + 'submit-faq-question-email', request, {
      headers: {
        Authorization: userToken(),
        Accept: 'application/x.iTRUSTit.v1+json',
      },
    })
    .then(function (response) {
      return response
    })
    .catch(function (error) {
      if (
        error &&
        typeof error !== 'undefined' &&
        typeof error.response !== 'undefined' &&
        typeof error.response.data !== 'undefined' &&
        typeof error.response.data.message !== 'undefined' &&
        error.response.data.message
      ) {
        toaster('error', error.response.data.message)
      }
      customLogger(error)
      return error.response
    })
}

export const getProfileOverviewFriendsIndividual = (request) => {
  let api_url = environemnt.BASE_API + 'overview/friend-individual'
  if (typeof request.other_user_id !== 'undefined' && request.other_user_id > 0) {
    api_url = environemnt.BASE_API + 'overview/friend-individual/' + request.other_user_id
  }
  return axios
    .post(
      api_url,
      {},
      {
        headers: {
          Authorization: userToken(),
          Accept: 'application/x.iTRUSTit.v1+json',
        },
      },
    )
    .then(function (response) {
      return response
    })
    .catch(function (error) {
      if (
        typeof error.response !== 'undefined' &&
        typeof error.response.data !== 'undefined' &&
        typeof error.response.data.message !== 'undefined' &&
        error.response.data.message
      ) {
        toaster('error', error.response.data.message)
      }
      customLogger(error)
      return error.response
    })
}

export const getProfileOverviewFriendsOrganization = (request) => {
  let api_url = environemnt.BASE_API + 'overview/friend-organization'
  if (typeof request.other_user_id !== 'undefined' && request.other_user_id > 0) {
    api_url = environemnt.BASE_API + 'overview/friend-organization/' + request.other_user_id
  }
  return axios
    .post(
      api_url,
      {},
      {
        headers: {
          Authorization: userToken(),
          Accept: 'application/x.iTRUSTit.v1+json',
        },
      },
    )
    .then(function (response) {
      return response
    })
    .catch(function (error) {
      if (
        typeof error.response !== 'undefined' &&
        typeof error.response.data !== 'undefined' &&
        typeof error.response.data.message !== 'undefined' &&
        error.response.data.message
      ) {
        toaster('error', error.response.data.message)
      }
      customLogger(error)
      return error.response
    })
}

export const getProfileOverviewFriendsBusiness = (request) => {
  let api_url = environemnt.BASE_API + 'overview/friend-business'
  if (typeof request.other_user_id !== 'undefined' && request.other_user_id > 0) {
    api_url = environemnt.BASE_API + 'overview/friend-business/' + request.other_user_id
  }
  return axios
    .post(
      api_url,
      {},
      {
        headers: {
          Authorization: userToken(),
          Accept: 'application/x.iTRUSTit.v1+json',
        },
      },
    )
    .then(function (response) {
      return response
    })
    .catch(function (error) {
      if (
        typeof error.response !== 'undefined' &&
        typeof error.response.data !== 'undefined' &&
        typeof error.response.data.message !== 'undefined' &&
        error.response.data.message
      ) {
        toaster('error', error.response.data.message)
      }
      customLogger(error)
      return error.response
    })
}

export const getProfileFromIdOnlyProfile = (request) => {
  return axios
    .post(
      environemnt.BASE_API + 'get-profile-with-slug-only-profile/' + request.userslug,
      {},
      {
        headers: {
          Authorization: userToken(),
          Accept: 'application/x.iTRUSTit.v1+json',
        },
      },
    )
    .then(function (response) {
      return response.data
    })
    .catch(function (error) {
      if (
        typeof error.response !== 'undefined' &&
        typeof error.response.data !== 'undefined' &&
        typeof error.response.data.message !== 'undefined' &&
        error.response.data.message
      ) {
        toaster('error', error.response.data.message)
      }
      customLogger(error)
      return error.response
    })
}

export const updateUserProfilePicture = async (request) => {
  try {
    const response = await axios.post(environemnt.BASE_API + 'profile-picture-update', request, {
      headers: { Authorization: userToken(), Accept: 'application/x.iTRUSTit.v1+json' },
    })
    return response.data
  } catch (error) {
    if (error?.response?.data?.message) {
      toaster('error', error.response.data.message)
    }
    customLogger(error)
    return error.response
  }
}

export const updateISupportUser = async (request) => {
  try {
    const response = await axios.post(environemnt.BASE_API + 'update-isupport-user', request, {
      headers: { Authorization: userToken(), Accept: 'application/x.iTRUSTit.v1+json' },
    })
    return response.data
  } catch (error) {
    if (error?.response?.data?.message) {
      toaster('error', error.response.data.message)
    }
    customLogger(error)
    return error.response
  }
}
