import axios from 'axios'
import { customLogger } from '../utils/CustomLogger'
import * as environemnt from './environment'
import { userToken, toaster } from './customFunction'
import { NETWORK_ERROR_MSG } from './const'

export const createReviewCall = (request, other_user_id) => {
  //  console.log('req: ', request, other_user_id);
  return axios
    .post(environemnt.BASE_API + 'reviews/' + other_user_id + '/store', request, {
      headers: { Authorization: userToken(), Accept: 'application/x.iTRUSTit.v1+json' },
    })
    .then(function (response) {
      return response
    })
    .catch(function (error) {
      if (error === undefined || error.response === undefined) {
        toaster('error', NETWORK_ERROR_MSG)
      } else if ([401, 403, 500].indexOf(error.response.status) >= 0) {
        if (
          typeof error.response !== 'undefined' &&
          typeof error.response.data !== 'undefined' &&
          typeof error.response.data.message !== 'undefined' &&
          error.response.data.message
        ) {
          toaster('error', error.response.data.message)
        } else {
          toaster('error', 'Something went wrong!!!')
        }
      }
      customLogger(error)
      return error.response
    })
}
//
//export const removeMemberCall = request => {
//    return axios
//        .post(environemnt.BASE_API + 'remove-member/' + request.id, {}, {
//            headers: { Authorization: userToken(), Accept: "application/x.iTRUSTit.v1+json" },
//        })
//        .then(function(response) {
//            return response;
//        })
//        .catch(function(error) {
//            if(typeof error.response != 'undefined' && typeof error.response.data != 'undefined' && typeof error.response.data.message != 'undefined' && error.response.data.message) {
//                toaster('error', error.response.data.message);
//            }
//            return error.response;
//        });
//};

export const reviewListCall = (request) => {
  let api_url = environemnt.BASE_API + 'reviews-list'
  if (typeof request.other_user_id !== 'undefined' && request.other_user_id > 0) {
    api_url = environemnt.BASE_API + 'reviews-list/' + request.other_user_id
  }
  return axios
    .post(api_url, request, {
      headers: { Authorization: userToken(), Accept: 'application/x.iTRUSTit.v1+json' },
    })
    .then(function (response) {
      return response
    })
    .catch(function (error) {
      if (
        typeof error.response !== 'undefined' &&
        typeof error.response.data !== 'undefined' &&
        typeof error.response.data.message !== 'undefined' &&
        error.response.data.message
      ) {
        toaster('error', error.response.data.message)
      }
      customLogger(error)
      return error.response
    })
}

export const loginUserReview = (request) => {
  return axios
    .post(
      environemnt.BASE_API +
        environemnt.REVIEW +
        '/' +
        request.other_user_id +
        '/' +
        environemnt.MY_REVIEW,
      request,
      {
        headers: { Authorization: userToken(), Accept: 'application/x.iTRUSTit.v1+json' },
      },
    )
    .then(function (response) {
      return response
    })
    .catch(function (error) {
      if (
        typeof error.response !== 'undefined' &&
        typeof error.response.data !== 'undefined' &&
        typeof error.response.data.message !== 'undefined' &&
        error.response.data.message
      ) {
        toaster('error', error.response.data.message)
      }
      customLogger(error)
      return error.response
    })
}

export const otherUserReview = (request) => {
  return axios
    .post(
      environemnt.BASE_API +
        environemnt.REVIEW +
        '/' +
        request.review_id +
        '/' +
        environemnt.REVIEW_SHOW,
      request,
      // {
      //     headers: { Authorization: userToken(), Accept: "application/x.iTRUSTit.v1+json" },
      // }
    )
    .then(function (response) {
      return response
    })
    .catch(function (error) {
      if (
        typeof error.response !== 'undefined' &&
        typeof error.response.data !== 'undefined' &&
        typeof error.response.data.message !== 'undefined' &&
        error.response.data.message
      ) {
        toaster('error', error.response.data.message)
      }
      customLogger(error)
      return error.response
    })
}

export const deleteReviewCall = async (request) => {
  let api_url = environemnt.BASE_API + 'reviews/delete/' + request.id
  try {
    const response = await axios.post(
      api_url,
      {},
      {
        headers: { Authorization: userToken(), Accept: 'application/x.iTRUSTit.v1+json' },
      },
    )
    return response
  } catch (error) {
    if (error?.response?.data?.message) {
      toaster('error', error.response.data.message)
    }
    customLogger(error)
    return error?.response
  }
}
