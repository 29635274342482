import React from 'react'
import { Field } from 'redux-form'
import ReactSelect from '../../../../services/ReactSelect'
import SortOptionsButtons from './SortOptionsButtons'

const sortByOptions = [
  { value: 'relevance', label: 'Relevance' },
  { value: 'name', label: 'Name' },
  { value: 'number_of_friends_trusted', label: '# Friend Trusts' },
  {
    value: 'number_of_friends_of_friends_trusted',
    label: '# Friends of Friends Trusts',
  },
  { value: 'number_of_total_trusted', label: 'Total Trusts' },
]

const showByOptions = [
  { value: '', label: 'All' },
  { value: 'individual', label: 'People' },
  { value: 'business', label: 'Business' },
  { value: 'organization', label: 'Organizations' },
]

export default function SearchResultsSortOptions({
  isMobile,
  showBy,
  sortBy,
  sortOrder,
  handleChange,
  handleSortOrderChange,
}) {
  return (
    <div className='all_selection'>
      <div className='sel1'>
        <div className={`sort_sel ${isMobile ? 'me-0' : ''}`}>
          <span
            className='varela d-block d-sm-inline-block'
            style={isMobile ? { fontSize: 12 } : {}}
          >
            Show by:
          </span>
          <Field
            type='text'
            className='sel_box for-select'
            component={ReactSelect}
            isSelect='false'
            name='showBy'
            options={showByOptions}
            onChange={handleChange}
            valueKey={showBy}
          />
        </div>
      </div>
      <div className='sel1'>
        <div className={`sort_sel ${isMobile ? 'me-1' : ''}`}>
          <span
            className='varela d-block d-sm-inline-block'
            style={isMobile ? { fontSize: 12 } : {}}
          >
            Sort by:
          </span>
          <Field
            type='text'
            className='sel_box for-select'
            component={ReactSelect}
            isSelect='false'
            name='sortBy'
            options={sortByOptions}
            onChange={handleChange}
            valueKey={sortBy}
          />
        </div>
      </div>
      <div className='sel1 sort-buttons flex-column align-items-start'>
        <span className='varela'>Order:</span>
        <div className='d-flex'>
          <SortOptionsButtons
            isMobile={isMobile}
            handleChange={handleSortOrderChange}
            sortOrder={sortOrder}
          />
        </div>
      </div>
    </div>
  )
}
