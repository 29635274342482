import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import * as userApiService from '../../../services/userApiService'
import {
  NOTIFICATION_ITEMS_PER_PAGE,
  NOTIFICATION_PAGE_RANGE,
  // DEFAULT_PROFILE_PICTURE
} from '../../../services/const'
import { getUser } from '../../../services/customFunction'
// import Pagination from "react-js-pagination";
// import { Link } from "react-router-dom";
// import * as route from "../../../services/routeConst";
import { toaster } from '../../../services/customFunction'
import { NETWORK_ERROR_MSG } from '../../../services/const'
// import * as customFunction from "../../../services/customFunction";
// import AppLayout from "../../AppLayout";
import $ from 'jquery'
import { Field, reduxForm } from 'redux-form'
import ReactSelect from '../../../services/ReactSelect'
import * as validation from '../../../services/formValidation'
import renderInputField from '../../Form/Input'
import VCardSettings from './VCardSettings/VCardSettings'

const MEMBER_NAMES = [
  { label: 'Members', value: 'Members' },
  { label: 'Alumni', value: 'Alumni' },
  { label: 'Brothers', value: 'Brothers' },
  { label: 'Fans', value: 'Fans' },
  { label: 'Kiwanians', value: 'Kiwanians' },
  { label: 'Parishioners', value: 'Parishioners' },
  { label: 'Rotarians', value: 'Rotarians' },
  { label: 'Enter custom name: ', value: 'Other' },
]

class PrivacySettings extends Component {
  constructor(props) {
    super(props)
    const searchParams = new URLSearchParams(window?.location?.search)
    const settingsType = searchParams.get('type') ?? this.props.settingsType
    this.state = {
      login_user_id: getUser() ? getUser().id : '',
      user: getUser() ? getUser() : '',
      activePage: 1,
      total_pages: 0,
      total_records: 0,
      is_saving_settings: false,
      is_loading_page: true,
      show_vcard_settings: settingsType === 'vcard',
      user_notifications: [],
      no_notifications_message: 'Loading...',
      setting: {
        show_email: 0,
        show_phone: 0,
        show_street_address: 0,
        show_city_state: 0,
        show_zipcode: 0,
        profile_view_by: 'Everybody including non-logged in users',
        friends_view_by: 'Everybody including non-logged in users',
        trusted_directory_view_by: 'Everybody including non-logged in users',
        orgs_list_view_by: 'Everybody including non-logged in users',
        members_view_by: 'Everybody including non-logged in users',
        gallery_view_by: 'Everybody including non-logged in users',
        can_add_as_member_of_their_organization: 1,
        is_allow_automatically_accept_membership_requests: 1,
        personal_message_notification_email: 1,
        friend_request_notification_email: 1,
        follow_request_notification_email: 1,
        organization_add_as_member_notification_email: 1,
        user_join_your_organization_notification_email: 1,
        user_trusted_you_notification_email: 1,
        review_receive_notification_email: 1,
        sponsored_new_user_receive_notification_email: 1,
        is_allow_automatically_accept_friend_requests: 1,
        is_allow_automatically_accept_follow_requests: 1,
        posts_default_share_type: 1,
        posts_default_comment_type: 1,
      },
      vcard_setting: {
        vc_phone_no_1_show: '0',
        vc_phone_no_2_show: '0',
        vc_mobile_no_show: '1',
        vc_fax_no_show: '0',
        vc_phone_one_tap: 'mobile_no',
        vc_email_show: '1',
        vc_email_2_show: '0',
        vc_email_3_show: '0',
        vc_email_4_show: '0',
        vc_email_one_tap: 'email',
        vc_website_1_show: '1',
        vc_website_2_show: '0',
        vc_website_3_show: '0',
        vc_website_4_show: '0',
        vc_address_show: '0',
        vc_address_1_show: '0',
        vc_address_2_show: '0',
        vc_city_state_zip_show: '0',
        vc_country_show: '0',
        vc_facebook_show: '1',
        vc_fiverr_show: '1',
        vc_instagram_show: '1',
        vc_linkedin_show: '1',
        vc_pinterest_show: '1',
        vc_tiktok_show: '1',
        vc_twitter_show: '1',
        vc_youtube_show: '1',
        vc_whatsapp_show: '1',
        vc_paypal_show: '0',
        vc_venmo_show: '0',
      },
      fortext: {
        business: 'Business',
        individual: 'Individual',
        organization: 'Organizations',
      },
      membersNameValue: MEMBER_NAMES[0].value,
      customMembersName: '',
      showCustomMembersNameField: false,
      memberDirectoryNameValue: 'member_directory',
      customMemberDirectoryName: '',
      showCustomMemberDirectoryNameField: false,
    }
  }

  changeEventHandler = (event) => {
    let inputName = event.target.name
    let inputValue = event.target.value
    let stateCopy = { ...this.state }
    stateCopy.setting[inputName] = inputValue
    this.setState(stateCopy)
  }

  vcardSettingChange = (event, one_tap_type) => {
    let inputName = event.target.name
    let inputValue = event.target.value
    console.log({ inputName, inputValue })
    let stateCopy = { ...this.state }
    if (!!one_tap_type) {
      stateCopy.vcard_setting[`vc_${one_tap_type}_one_tap`] = inputName.replace('vc_', '')
    } else {
      if (inputName.includes('vc_email')) {
        stateCopy.vcard_setting.vc_email_show = '0'
        stateCopy.vcard_setting.vc_email_2_show = '0'
        stateCopy.vcard_setting.vc_email_3_show = '0'
        stateCopy.vcard_setting.vc_email_4_show = '0'
      }
      if (inputName.includes('vc_website')) {
        stateCopy.vcard_setting.vc_website_1_show = '0'
        stateCopy.vcard_setting.vc_website_2_show = '0'
        stateCopy.vcard_setting.vc_website_3_show = '0'
        stateCopy.vcard_setting.vc_website_4_show = '0'
      }
      stateCopy.vcard_setting[inputName] = inputValue
    }
    console.log({ one_tap_type, stateCopy })
    this.setState(stateCopy)
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.settingsType === 'vcard' && !this.state.show_vcard_settings) {
      this.setState({ show_vcard_settings: true })
    }
  }

  componentDidMount() {
    this.getSetting()
    window.scrollTo({
      top: $('#privacy-settings').offset().top - 20,
      behavior: 'smooth',
    })
  }

  getSetting() {
    const self = this
    userApiService.getProfileSetting().then(function (response) {
      self.setState({ is_loading_page: false })
      if (
        response &&
        typeof response !== 'undefined' &&
        typeof response.status !== 'undefined' &&
        response.status === 200
      ) {
        if (response.data.data.user_settings) {
          delete response.data.data.user_settings.id
          delete response.data.data.user_settings.user_id
          delete response.data.data.user_settings.updated_at
          const membersNameValue = response.data.data.user_settings.members_name
          const customMembersName = response.data.data.user_settings.custom_members_name
          const memberDirectoryNameValue = response.data.data.user_settings.member_directory_name
          const customMemberDirectoryName =
            response.data.data.user_settings.custom_member_directory_name
          const userSettings = Object.fromEntries(
            Object.entries(response.data.data.user_settings).filter(
              ([key]) => !key.includes('vc_'),
            ),
          )
          const vcardSettings = Object.fromEntries(
            Object.entries(response.data.data.user_settings).filter(([key]) => key.includes('vc_')),
          )
          self.setState({
            setting: userSettings,
            vcard_setting: vcardSettings,
            membersNameValue,
            customMembersName,
            showCustomMembersNameField: membersNameValue === 'Other',
            memberDirectoryNameValue:
              memberDirectoryNameValue === 'Other'
                ? memberDirectoryNameValue
                : memberDirectoryNameValue?.toLowerCase().replace(' ', '_'),
            customMemberDirectoryName,
            showCustomMemberDirectoryNameField: memberDirectoryNameValue === 'Other',
          })
        }
      }
    })
  }

  updateSetting = async (event) => {
    if (!this.state.is_saving_settings) {
      this.setState({ is_saving_settings: true })
      const response = await userApiService.setProfileSetting({
        ...this.state.setting,
        members_name: this.state.membersNameValue,
        custom_members_name: this.state.customMembersName,
        member_directory_name: this.state.memberDirectoryNameValue,
        custom_member_directory_name: this.state.customMemberDirectoryName,
      })
      this.setState({ is_saving_settings: false })
      if (response?.status === 200) {
        if (response === undefined) {
          toaster('error', NETWORK_ERROR_MSG)
        } else if (response.status === 200) {
          let post_user_settings = {
            posts_default_share_type: this.state?.setting?.posts_default_share_type,
            posts_default_comment_type: this.state?.setting?.posts_default_comment_type,
          }
          this.props.updatePostUserSettings(post_user_settings)
          toaster('success', response.data.message)
        } else {
          toaster('error', response.data.message)
        }
      }
    }
  }

  updateVCardSettings = async (event) => {
    if (!this.state.is_saving_settings) {
      this.setState({ is_saving_settings: true })
      const response = await userApiService.setProfileSetting(this.state.vcard_setting)
      this.setState({ is_saving_settings: false })
      if (response?.status === 200) {
        if (response === undefined) {
          toaster('error', NETWORK_ERROR_MSG)
        } else if (response.status === 200) {
          toaster('success', response.data.message)
        } else {
          toaster('error', response.data.message)
        }
      }
    }
  }

  handleMembersNameChange = (data) => {
    const membersNameValue = data.value

    this.setState({
      membersNameValue,
      customMembersName: '',
      showCustomMembersNameField: membersNameValue === 'Other',
    })
  }

  handleCustomMembersNameChange = (event) => {
    this.setState({ customMembersName: event.target?.value })
  }

  handleMemberDirectoryNameChange = (data) => {
    const memberDirectoryNameValue = data.label

    this.setState({
      memberDirectoryNameValue,
      customMemberDirectoryName: '',
      showCustomMemberDirectoryNameField: memberDirectoryNameValue === 'Other',
    })
  }

  handleCustomMemberDirectoryNameChange = (event) => {
    this.setState({
      customMemberDirectoryName: event.target?.value,
    })
  }

  render() {
    return (
      <div>
        <div
          id='privacy-settings'
          className={
            'tab-pane ' + (this.props.activeTab === '#privacy-settings' ? 'active' : 'd-none')
          }
        >
          <div className='it-setting-page listing_block edit_profile padding-reset'>
            <h4 className='varela mt-4'>Settings</h4>
            <div
              className={
                'bg-white activity_blk ' +
                (this.state.user.user_type === 'individual'
                  ? 'green-upper-line'
                  : this.state.user.user_type === 'organization'
                  ? 'orange-upper-line'
                  : 'blue-upper-line')
              }
            >
              <div className='fields'>
                <div className='d-flex settings-tabs mt-2'>
                  <h5
                    onClick={() => this.setState({ show_vcard_settings: false })}
                    className={`settings-tab me-2 ${
                      this.state.show_vcard_settings ? '' : 'settings-tab-active'
                    }`}
                  >
                    Privacy Settings
                  </h5>
                  <h5
                    onClick={() => this.setState({ show_vcard_settings: true })}
                    className={`settings-tab ${
                      this.state.show_vcard_settings ? 'settings-tab-active' : ''
                    }`}
                  >
                    VCard Settings
                  </h5>
                </div>

                {this.state.is_loading_page ? (
                  <div className='row'>
                    <div className='col-sm-12 text-center'>
                      {' '}
                      <h4>Loading...</h4>
                    </div>
                  </div>
                ) : this.state.show_vcard_settings ? (
                  <fieldset style={{ userSelect: 'none' }}>
                    <VCardSettings
                      user={this.state.user}
                      settings={this.state.vcard_setting}
                      handleChange={this.vcardSettingChange}
                    />

                    <div className='row'>
                      <div className='col-md-12 text-center mt-4'>
                        <input
                          type='button'
                          className='btn blue_btn'
                          value={this.state.is_saving_settings ? 'Please Wait' : 'submit'}
                          onClick={this.updateVCardSettings}
                        />
                      </div>
                    </div>
                  </fieldset>
                ) : (
                  <fieldset>
                    <div className='row fieldflex form-group'>
                      <div className='col-md-12'>
                        <label>
                          Hide {this.state.user.user_type === 'individual' ? 'my' : ''}
                          {this.state.user.user_type === 'business' ? 'this business' : ''}
                          {this.state.user.user_type === 'organization'
                            ? 'this Organizations'
                            : ''}{' '}
                          email addresses from displaying on the profile page
                        </label>
                      </div>
                      <div className='col-md-12'>
                        <div>
                          <input
                            type='radio'
                            id='show_email_true'
                            onChange={this.changeEventHandler}
                            name='show_email'
                            value='0'
                            checked={this.state.setting.show_email === '0' ? 'checked' : ''}
                          />{' '}
                          <label htmlFor='show_email_true'>Yes</label>
                        </div>
                        <div>
                          <input
                            type='radio'
                            id='show_email_false'
                            onChange={this.changeEventHandler}
                            name='show_email'
                            value='1'
                            checked={this.state.setting.show_email === '1' ? 'checked' : ''}
                          />{' '}
                          <label htmlFor='show_email_false'>No</label>
                        </div>
                      </div>
                    </div>
                    <div className='row fieldflex form-group'>
                      <div className='col-md-12'>
                        {' '}
                        <label>
                          Hide the Phone numbers
                          {this.state.user.user_type !== 'business'
                            ? ' from displaying on the profile page'
                            : ''}{' '}
                        </label>{' '}
                      </div>
                      <div className='col-md-12'>
                        <div>
                          <input
                            type='radio'
                            id='show_phone_true'
                            name='show_phone'
                            value='0'
                            onChange={this.changeEventHandler}
                            checked={this.state.setting.show_phone === '0' ? 'checked' : ''}
                          />{' '}
                          <label htmlFor='show_phone_true'>Yes</label>
                        </div>
                        <div>
                          <input
                            type='radio'
                            id='show_phone_false'
                            name='show_phone'
                            value='1'
                            onChange={this.changeEventHandler}
                            checked={this.state.setting.show_phone === '1' ? 'checked' : ''}
                          />{' '}
                          <label htmlFor='show_phone_false'>No</label>
                        </div>
                      </div>
                    </div>

                    <div className='row fieldflex form-group'>
                      <div className='col-md-12'>
                        {' '}
                        <label>Automatically accept friend requests </label>{' '}
                      </div>
                      <div className='col-md-12'>
                        <div>
                          <input
                            type='radio'
                            id='is_allow_automatically_accept_friend_requests_true'
                            name='is_allow_automatically_accept_friend_requests'
                            value='1'
                            onChange={this.changeEventHandler}
                            checked={
                              this.state.setting.is_allow_automatically_accept_friend_requests ===
                              '1'
                                ? 'checked'
                                : ''
                            }
                          />{' '}
                          <label htmlFor='is_allow_automatically_accept_friend_requests_true'>
                            Yes
                          </label>
                        </div>
                        <div>
                          <input
                            type='radio'
                            id='is_allow_automatically_accept_friend_requests_false'
                            name='is_allow_automatically_accept_friend_requests'
                            value='0'
                            onChange={this.changeEventHandler}
                            checked={
                              this.state.setting.is_allow_automatically_accept_friend_requests ===
                              '0'
                                ? 'checked'
                                : ''
                            }
                          />{' '}
                          <label htmlFor='is_allow_automatically_accept_friend_requests_false'>
                            No
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className='row fieldflex form-group'>
                      <div className='col-md-12'>
                        {' '}
                        <label>Automatically accept follow requests </label>{' '}
                      </div>
                      <div className='col-md-12'>
                        <div>
                          <input
                            type='radio'
                            id='is_allow_automatically_accept_follow_requests_true'
                            name='is_allow_automatically_accept_follow_requests'
                            value='1'
                            onChange={this.changeEventHandler}
                            checked={
                              this.state.setting.is_allow_automatically_accept_follow_requests ===
                              '1'
                                ? 'checked'
                                : ''
                            }
                          />{' '}
                          <label htmlFor='is_allow_automatically_accept_follow_requests_true'>
                            Yes
                          </label>
                        </div>
                        <div>
                          <input
                            type='radio'
                            id='is_allow_automatically_accept_follow_requests_false'
                            name='is_allow_automatically_accept_follow_requests'
                            value='0'
                            onChange={this.changeEventHandler}
                            checked={
                              this.state.setting.is_allow_automatically_accept_follow_requests ===
                              '0'
                                ? 'checked'
                                : ''
                            }
                          />{' '}
                          <label htmlFor='is_allow_automatically_accept_follow_requests_false'>
                            No
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className='row fieldflex form-group'>
                      <div className='col-md-12'>
                        {' '}
                        <label>
                          Automatically accept membership requests from organizations to be a member
                          of THEIR organization
                        </label>{' '}
                      </div>
                      <div className='col-md-12'>
                        <div>
                          <input
                            type='radio'
                            id='can_add_as_member_of_their_organization_true'
                            onChange={this.changeEventHandler}
                            name='can_add_as_member_of_their_organization'
                            value='1'
                            checked={
                              this.state.setting.can_add_as_member_of_their_organization === '1'
                                ? 'checked'
                                : ''
                            }
                          />{' '}
                          <label htmlFor='can_add_as_member_of_their_organization_true'>Yes</label>
                        </div>
                        <div>
                          <input
                            type='radio'
                            id='can_add_as_member_of_their_organization_false'
                            onChange={this.changeEventHandler}
                            name='can_add_as_member_of_their_organization'
                            value='0'
                            checked={
                              this.state.setting.can_add_as_member_of_their_organization === '0'
                                ? 'checked'
                                : ''
                            }
                          />{' '}
                          <label htmlFor='can_add_as_member_of_their_organization_false'>No</label>
                        </div>
                      </div>
                    </div>

                    {this.state.user.user_type === 'organization' ? (
                      <div className='row fieldflex form-group'>
                        <div className='col-md-12'>
                          {' '}
                          <label>
                            Automatically accept membership requests from logged-in users to join
                            THIS organization
                          </label>{' '}
                        </div>
                        <div className='col-md-12'>
                          <div>
                            <input
                              type='radio'
                              id='is_allow_automatically_accept_membership_requests_true'
                              onChange={this.changeEventHandler}
                              name='is_allow_automatically_accept_membership_requests'
                              value='1'
                              checked={
                                this.state.setting
                                  .is_allow_automatically_accept_membership_requests === '1'
                                  ? 'checked'
                                  : ''
                              }
                            />{' '}
                            <label htmlFor='is_allow_automatically_accept_membership_requests_true'>
                              Yes
                            </label>
                          </div>
                          <div>
                            <input
                              type='radio'
                              id='is_allow_automatically_accept_membership_requests_false'
                              onChange={this.changeEventHandler}
                              name='is_allow_automatically_accept_membership_requests'
                              value='0'
                              checked={
                                this.state.setting
                                  .is_allow_automatically_accept_membership_requests === '0'
                                  ? 'checked'
                                  : ''
                              }
                            />{' '}
                            <label htmlFor='is_allow_automatically_accept_membership_requests_false'>
                              No
                            </label>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ''
                    )}

                    {/* {this.state.user.user_type == "organization"
                            ?
                                (<div className="row fieldflex form-group">
                                    <div className="col-md-12"> <label>Automatically accept membership requests from logged-in user to join THEIR organization</label> </div>
                                    <div className="col-md-12">
                                        <div>
                                            <input type="radio" value="1" /> Yes
                                        </div>
                                        <div>
                                            <input type="radio" value="0" /> No
                                        </div>
                                    </div>
                                </div>)
                            : ""
                        } */}

                    {this.state.user.user_type === 'individual' ? (
                      <Fragment>
                        <div className='row fieldflex form-group'>
                          <div className='col-md-12'>
                            {' '}
                            <label>
                              Hide my street address from displaying on my profile page
                            </label>{' '}
                          </div>
                          <div className='col-md-12'>
                            <div>
                              <input
                                type='radio'
                                id='show_street_address_true'
                                onChange={this.changeEventHandler}
                                name='show_street_address'
                                value='0'
                                checked={
                                  this.state.setting.show_street_address === '0' ? 'checked' : ''
                                }
                              />{' '}
                              <label htmlFor='show_street_address_true'>Yes</label>
                            </div>
                            <div>
                              <input
                                type='radio'
                                id='show_street_address_false'
                                onChange={this.changeEventHandler}
                                name='show_street_address'
                                value='1'
                                checked={
                                  this.state.setting.show_street_address === '1' ? 'checked' : ''
                                }
                              />{' '}
                              <label htmlFor='show_street_address_false'>No</label>
                            </div>
                          </div>
                          {/* <div>
                                                                <input type="radio" id="show_street_address_false" onChange={this.changeEventHandler} name="show_street_address" value="0" checked={this.state.setting.show_street_address == '0' ? "checked" : ""} /> <label htmlFor="show_street_address_false">No</label>
                                                            </div> */}
                        </div>
                        <div className='row fieldflex form-group'>
                          <div className='col-md-12'>
                            {' '}
                            <label>
                              Hide my city and state from displaying on my profile page
                            </label>{' '}
                          </div>
                          <div className='col-md-12'>
                            <div>
                              <input
                                type='radio'
                                id='show_city_state_true'
                                onChange={this.changeEventHandler}
                                name='show_city_state'
                                value='0'
                                checked={
                                  this.state.setting.show_city_state === '0' ? 'checked' : ''
                                }
                              />{' '}
                              <label htmlFor='show_city_state_true'>Yes</label>
                            </div>
                            <div>
                              <input
                                type='radio'
                                id='show_city_state_false'
                                onChange={this.changeEventHandler}
                                name='show_city_state'
                                value='1'
                                checked={
                                  this.state.setting.show_city_state === '1' ? 'checked' : ''
                                }
                              />{' '}
                              <label htmlFor='show_city_state_false'>No</label>
                            </div>
                          </div>
                        </div>
                        <div className='row fieldflex form-group'>
                          <div className='col-md-12'>
                            {' '}
                            <label>Hide my zip code from displaying on my profile page</label>{' '}
                          </div>
                          <div className='col-md-12'>
                            <div>
                              <input
                                type='radio'
                                id='show_zipcode_true'
                                onChange={this.changeEventHandler}
                                name='show_zipcode'
                                value='0'
                                checked={this.state.setting.show_zipcode === '0' ? 'checked' : ''}
                              />{' '}
                              <label htmlFor='show_zipcode_true'>Yes</label>
                            </div>
                            <div>
                              <input
                                type='radio'
                                id='show_zipcode_false'
                                onChange={this.changeEventHandler}
                                name='show_zipcode'
                                value='1'
                                checked={this.state.setting.show_zipcode === '1' ? 'checked' : ''}
                              />{' '}
                              <label htmlFor='show_zipcode_false'>No</label>
                            </div>
                          </div>
                        </div>
                        <div className='row fieldflex form-group'>
                          <div className='col-md-12'>
                            {' '}
                            <label>My profile can be viewed by:</label>{' '}
                          </div>
                          <div className='col-md-12'>
                            <div>
                              <input
                                type='radio'
                                id='profile_view_by_1'
                                onChange={this.changeEventHandler}
                                name='profile_view_by'
                                value='Everybody including non-logged in users'
                                checked={
                                  this.state.setting.profile_view_by ===
                                  'Everybody including non-logged in users'
                                    ? 'checked'
                                    : ''
                                }
                              />
                              <label htmlFor='profile_view_by_1'>
                                {' '}
                                Everybody including non-logged in users
                              </label>
                            </div>
                            <div>
                              <input
                                type='radio'
                                id='profile_view_by_2'
                                onChange={this.changeEventHandler}
                                name='profile_view_by'
                                value='All logged in users'
                                checked={
                                  this.state.setting.profile_view_by === 'All logged in users'
                                    ? 'checked'
                                    : ''
                                }
                              />{' '}
                              <label htmlFor='profile_view_by_2'> All logged in users </label>
                            </div>
                            <div>
                              <input
                                type='radio'
                                id='profile_view_by_3'
                                onChange={this.changeEventHandler}
                                name='profile_view_by'
                                value='Friends and friends of friends '
                                checked={
                                  this.state.setting.profile_view_by ===
                                  'Friends and friends of friends'
                                    ? 'checked'
                                    : ''
                                }
                              />{' '}
                              <label htmlFor='profile_view_by_3'>
                                {' '}
                                Friends and friends of friends{' '}
                              </label>
                            </div>
                            <div>
                              <input
                                type='radio'
                                id='profile_view_by_4'
                                onChange={this.changeEventHandler}
                                name='profile_view_by'
                                value='Friends'
                                checked={
                                  this.state.setting.profile_view_by === 'Friends' ? 'checked' : ''
                                }
                              />
                              <label htmlFor='profile_view_by_4'> Friends</label>
                            </div>
                          </div>
                        </div>
                      </Fragment>
                    ) : (
                      ''
                    )}

                    <div className='row fieldflex form-group'>
                      <div className='col-md-12'>
                        {' '}
                        <label>
                          {' '}
                          {this.state.user.user_type !== 'individual'
                            ? 'This ' + this.state.fortext[this.state.user.user_type]
                            : 'My'}{' '}
                          friends list can be viewed by:{' '}
                        </label>{' '}
                      </div>
                      <div className='col-md-12'>
                        <div>
                          <input
                            type='radio'
                            id='friends_view_by_1'
                            onChange={this.changeEventHandler}
                            name='friends_view_by'
                            value='Everybody including non-logged in users'
                            checked={
                              this.state.setting.friends_view_by ===
                              'Everybody including non-logged in users'
                                ? 'checked'
                                : ''
                            }
                          />
                          <label htmlFor='friends_view_by_1'>
                            {' '}
                            Everybody including non-logged in users
                          </label>
                        </div>
                        <div>
                          <input
                            type='radio'
                            id='friends_view_by_2'
                            onChange={this.changeEventHandler}
                            name='friends_view_by'
                            value='All logged in users'
                            checked={
                              this.state.setting.friends_view_by === 'All logged in users'
                                ? 'checked'
                                : ''
                            }
                          />
                          <label htmlFor='friends_view_by_2'> All logged in users</label>
                        </div>
                        <div>
                          <input
                            type='radio'
                            id='friends_view_by_3'
                            onChange={this.changeEventHandler}
                            name='friends_view_by'
                            value='Friends and friends of friends'
                            checked={
                              this.state.setting.friends_view_by ===
                              'Friends and friends of friends'
                                ? 'checked'
                                : ''
                            }
                          />
                          <label htmlFor='friends_view_by_3'> Friends and friends of friends</label>
                        </div>
                        <div>
                          <input
                            type='radio'
                            id='friends_view_by_4'
                            onChange={this.changeEventHandler}
                            name='friends_view_by'
                            value='Friends'
                            checked={
                              this.state.setting.friends_view_by === 'Friends' ? 'checked' : ''
                            }
                          />{' '}
                          <label htmlFor='friends_view_by_4'> Friends</label>
                        </div>
                        {this.state.user.user_type === 'organization' ? (
                          <div>
                            <input
                              type='radio'
                              id='friends_view_by_5'
                              onChange={this.changeEventHandler}
                              name='friends_view_by'
                              value='Members only'
                              checked={
                                this.state.setting.friends_view_by === 'Members only'
                                  ? 'checked'
                                  : ''
                              }
                            />
                            <label htmlFor='friends_view_by_5'> Members only</label>
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                    </div>

                    {this.state.user.user_type === 'organization' ? (
                      <div className='row fieldflex form-group'>
                        <div className='col-md-12'>
                          {' '}
                          <label> This Organizations member list can be viewed by:</label>{' '}
                        </div>
                        <div className='col-md-12'>
                          <div>
                            <input
                              type='radio'
                              id='members_view_by_1'
                              onChange={this.changeEventHandler}
                              name='members_view_by'
                              value='Everybody including non-logged in users'
                              checked={
                                this.state.setting.members_view_by ===
                                'Everybody including non-logged in users'
                                  ? 'checked'
                                  : ''
                              }
                            />
                            <label htmlFor='members_view_by_1'>
                              {' '}
                              Everybody including non-logged in users
                            </label>
                          </div>
                          <div>
                            <input
                              type='radio'
                              id='members_view_by_2'
                              onChange={this.changeEventHandler}
                              name='members_view_by'
                              value='All logged in users'
                              checked={
                                this.state.setting.members_view_by === 'All logged in users'
                                  ? 'checked'
                                  : ''
                              }
                            />
                            <label htmlFor='members_view_by_2'> All logged in users</label>
                          </div>
                          <div>
                            <input
                              type='radio'
                              id='members_view_by_3'
                              onChange={this.changeEventHandler}
                              name='members_view_by'
                              value='Friends and friends of friends'
                              checked={
                                this.state.setting.members_view_by ===
                                'Friends and friends of friends'
                                  ? 'checked'
                                  : ''
                              }
                            />
                            <label htmlFor='members_view_by_3'>
                              {' '}
                              Friends and friends of friends{' '}
                            </label>
                          </div>
                          <div>
                            <input
                              type='radio'
                              id='members_view_by_4'
                              onChange={this.changeEventHandler}
                              name='members_view_by'
                              value='Friends'
                              checked={
                                this.state.setting.members_view_by === 'Friends' ? 'checked' : ''
                              }
                            />
                            <label htmlFor='members_view_by_4'> Friends</label>
                          </div>
                          <div>
                            <input
                              type='radio'
                              id='members_view_by_5'
                              onChange={this.changeEventHandler}
                              name='members_view_by'
                              value='Members only'
                              checked={
                                this.state.setting.members_view_by === 'Members only'
                                  ? 'checked'
                                  : ''
                              }
                            />
                            <label htmlFor='members_view_by_5'> Members only </label>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ''
                    )}

                    <div className='row fieldflex form-group'>
                      <div className='col-md-12'>
                        {' '}
                        <label>
                          {' '}
                          {this.state.user.user_type !== 'individual'
                            ? 'This ' + this.state.fortext[this.state.user.user_type]
                            : 'My '}{' '}
                          gallery can be viewed by:
                        </label>{' '}
                      </div>
                      <div className='col-md-12'>
                        <div>
                          <input
                            type='radio'
                            id='gallery_view_by_1'
                            onChange={this.changeEventHandler}
                            name='gallery_view_by'
                            value='Everybody including non-logged in users'
                            checked={
                              this.state.setting.gallery_view_by ===
                              'Everybody including non-logged in users'
                                ? 'checked'
                                : ''
                            }
                          />
                          <label htmlFor='gallery_view_by_1'>
                            {' '}
                            Everybody including non-logged in users
                          </label>
                        </div>
                        <div>
                          <input
                            type='radio'
                            id='gallery_view_by_2'
                            onChange={this.changeEventHandler}
                            name='gallery_view_by'
                            value='All logged in users'
                            checked={
                              this.state.setting.gallery_view_by === 'All logged in users'
                                ? 'checked'
                                : ''
                            }
                          />
                          <label htmlFor='gallery_view_by_2'> All logged in users</label>
                        </div>
                        <div>
                          <input
                            type='radio'
                            id='gallery_view_by_3'
                            onChange={this.changeEventHandler}
                            name='gallery_view_by'
                            value='Friends and friends of friends'
                            checked={
                              this.state.setting.gallery_view_by ===
                              'Friends and friends of friends'
                                ? 'checked'
                                : ''
                            }
                          />
                          <label htmlFor='gallery_view_by_3'> Friends and friends of friends</label>
                        </div>
                        <div>
                          <input
                            type='radio'
                            id='gallery_view_by_4'
                            onChange={this.changeEventHandler}
                            name='gallery_view_by'
                            value='Friends'
                            checked={
                              this.state.setting.gallery_view_by === 'Friends' ? 'checked' : ''
                            }
                          />
                          <label htmlFor='gallery_view_by_4'> Friends</label>
                        </div>
                        {this.state.user.user_type === 'organization' ? (
                          <div>
                            <input
                              type='radio'
                              id='gallery_view_by_5'
                              onChange={this.changeEventHandler}
                              name='gallery_view_by'
                              value='Members only'
                              checked={
                                this.state.setting.gallery_view_by === 'Members only'
                                  ? 'checked'
                                  : ''
                              }
                            />
                            <label htmlFor='gallery_view_by_5'> Members only</label>
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                    </div>

                    <div className='row fieldflex form-group'>
                      <div className='col-md-12'>
                        {' '}
                        <label>
                          {' '}
                          {this.state.user.user_type !== 'individual'
                            ? 'This ' + this.state.fortext[this.state.user.user_type]
                            : 'My '}{' '}
                          trust list can be viewed by:
                        </label>{' '}
                      </div>
                      <div className='col-md-12'>
                        <div>
                          <input
                            type='radio'
                            id='trusted_directory_view_by_1'
                            onChange={this.changeEventHandler}
                            name='trusted_directory_view_by'
                            value='Everybody including non-logged in users'
                            checked={
                              this.state.setting.trusted_directory_view_by ===
                              'Everybody including non-logged in users'
                                ? 'checked'
                                : ''
                            }
                          />
                          <label htmlFor='trusted_directory_view_by_1'>
                            {' '}
                            Everybody including non-logged in users
                          </label>
                        </div>
                        <div>
                          <input
                            type='radio'
                            id='trusted_directory_view_by_2'
                            onChange={this.changeEventHandler}
                            name='trusted_directory_view_by'
                            value='All logged in users'
                            checked={
                              this.state.setting.trusted_directory_view_by === 'All logged in users'
                                ? 'checked'
                                : ''
                            }
                          />
                          <label htmlFor='trusted_directory_view_by_2'> All logged in users</label>
                        </div>
                        <div>
                          <input
                            type='radio'
                            id='trusted_directory_view_by_3'
                            onChange={this.changeEventHandler}
                            name='trusted_directory_view_by'
                            value='Friends and friends of friends'
                            checked={
                              this.state.setting.trusted_directory_view_by ===
                              'Friends and friends of friends'
                                ? 'checked'
                                : ''
                            }
                          />
                          <label htmlFor='trusted_directory_view_by_3'>
                            {' '}
                            Friends and friends of friends
                          </label>
                        </div>
                        <div>
                          <input
                            type='radio'
                            id='trusted_directory_view_by_4'
                            onChange={this.changeEventHandler}
                            name='trusted_directory_view_by'
                            value='Friends'
                            checked={
                              this.state.setting.trusted_directory_view_by === 'Friends'
                                ? 'checked'
                                : ''
                            }
                          />
                          <label htmlFor='trusted_directory_view_by_4'> Friends</label>
                        </div>
                        {this.state.user.user_type === 'organization' ? (
                          <div>
                            <input
                              type='radio'
                              id='trusted_directory_view_by_5'
                              onChange={this.changeEventHandler}
                              name='trusted_directory_view_by'
                              value='Members only'
                              checked={
                                this.state.setting.trusted_directory_view_by === 'Members only'
                                  ? 'checked'
                                  : ''
                              }
                            />
                            <label htmlFor='trusted_directory_view_by_5'> Members only</label>
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                    </div>

                    <div className='row fieldflex form-group'>
                      <div className='col-md-12'>
                        {' '}
                        <label>
                          {' '}
                          The list of Organizations{' '}
                          {this.state.user.user_type !== 'individual'
                            ? 'this ' +
                              (this.state.user.user_type === 'organization'
                                ? 'Organization'
                                : 'Business') +
                              ' is'
                            : 'I am '}{' '}
                          a member of can be viewed by:
                        </label>{' '}
                      </div>
                      <div className='col-md-12'>
                        <div>
                          <input
                            type='radio'
                            id='orgs_list_view_by_1'
                            onChange={this.changeEventHandler}
                            name='orgs_list_view_by'
                            value='Everybody including non-logged in users'
                            checked={
                              this.state.setting.orgs_list_view_by ===
                              'Everybody including non-logged in users'
                                ? 'checked'
                                : ''
                            }
                          />
                          <label htmlFor='orgs_list_view_by_1'>
                            {' '}
                            Everybody including non-logged in users
                          </label>
                        </div>
                        <div>
                          <input
                            type='radio'
                            id='orgs_list_view_by_2'
                            onChange={this.changeEventHandler}
                            name='orgs_list_view_by'
                            value='All logged in users'
                            checked={
                              this.state.setting.orgs_list_view_by === 'All logged in users'
                                ? 'checked'
                                : ''
                            }
                          />
                          <label htmlFor='orgs_list_view_by_2'> All logged in users</label>
                        </div>
                        <div>
                          <input
                            type='radio'
                            id='orgs_list_view_by_3'
                            onChange={this.changeEventHandler}
                            name='orgs_list_view_by'
                            value='Friends and friends of friends'
                            checked={
                              this.state.setting.orgs_list_view_by ===
                              'Friends and friends of friends'
                                ? 'checked'
                                : ''
                            }
                          />
                          <label htmlFor='orgs_list_view_by_3'>
                            {' '}
                            Friends and friends of friends
                          </label>
                        </div>
                        <div>
                          <input
                            type='radio'
                            id='orgs_list_view_by_4'
                            onChange={this.changeEventHandler}
                            name='orgs_list_view_by'
                            value='Friends'
                            checked={
                              this.state.setting.orgs_list_view_by === 'Friends' ? 'checked' : ''
                            }
                          />
                          <label htmlFor='orgs_list_view_by_4'> Friends</label>
                        </div>
                        {this.state.user.user_type === 'organization' ? (
                          <div>
                            <input
                              type='radio'
                              id='orgs_list_view_by_5'
                              onChange={this.changeEventHandler}
                              name='orgs_list_view_by'
                              value='Members only'
                              checked={
                                this.state.setting.orgs_list_view_by === 'Members only'
                                  ? 'checked'
                                  : ''
                              }
                            />
                            <label htmlFor='orgs_list_view_by_5'> Members only</label>
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                    </div>

                    {this.state.user.user_type === 'organization' ? (
                      <>
                        <div className='row fieldflex form-group'>
                          <div className='col-md-12'>
                            <label> What do you call your members?</label>
                          </div>
                          <div className='col-md-6'>
                            <div className='for-select'>
                              <Field
                                type='text'
                                component={ReactSelect}
                                name='members_name'
                                className='form-control settings-page-select'
                                options={MEMBER_NAMES}
                                value={this.state.membersNameValue}
                                onChange={this.handleMembersNameChange}
                                validate={[
                                  validation.required,
                                  validation.minValue1,
                                  validation.noUsernameSpace,
                                  validation.onylCharacter,
                                  validation.maxLength20,
                                  validation.isBeginLatter,
                                  validation.isDashed,
                                  validation.isDashedStart,
                                ]}
                                isSelect='true'
                                valueKey={this.state.membersNameValue}
                              />
                            </div>
                          </div>
                          {this.state.showCustomMembersNameField ? (
                            <div className='col-md-12 mt-2 ml-2'>
                              <div className='row'>
                                <div className='col-md-5 settings-page-select'>
                                  <Field
                                    name='custom_members_name'
                                    type='text'
                                    className='form-control ps-4'
                                    component={renderInputField}
                                    label='Enter custom name'
                                    placeholder='Enter custom name*'
                                    autoComplete='off'
                                    validate={[
                                      validation.required,
                                      validation.minValue1,
                                      validation.noUsernameSpace,
                                      validation.onylCharacter,
                                      validation.maxLength20,
                                      validation.isBeginLatter,
                                      validation.isDashed,
                                      validation.isDashedStart,
                                    ]}
                                    valueKey={this.state.customMembersName}
                                    onChange={this.handleCustomMembersNameChange}
                                  />
                                </div>
                              </div>
                            </div>
                          ) : null}
                        </div>
                        <div className='row fieldflex form-group'>
                          <div className='col-md-12'>
                            <label> What do you call your member directory?</label>
                          </div>
                          <div className='col-md-6'>
                            <div className='for-select'>
                              <Field
                                type='text'
                                component={ReactSelect}
                                name='member_directory_name'
                                className='form-control settings-page-select'
                                options={MEMBER_NAMES.map(({ value: name }) => {
                                  const newName =
                                    name === 'Other'
                                      ? name
                                      : (name.endsWith('s') ? name.slice(0, -1) : name) +
                                        ' Directory'
                                  return {
                                    label: name === 'Other' ? 'Enter custom name: ' : newName,
                                    value:
                                      name === 'Other'
                                        ? newName
                                        : newName.toLowerCase().replace(' ', '_'),
                                  }
                                })}
                                value={this.state.memberDirectoryNameValue}
                                onChange={this.handleMemberDirectoryNameChange}
                                validate={[
                                  validation.required,
                                  validation.minValue1,
                                  validation.onlyCharacterWithSpace,
                                  validation.maxLength20,
                                  validation.isBeginLatter,
                                ]}
                                isSelect='true'
                                valueKey={
                                  this.state.memberDirectoryNameValue === 'Other'
                                    ? this.state.memberDirectoryNameValue
                                    : this.state.memberDirectoryNameValue
                                        .toLowerCase()
                                        .replace(' ', '_')
                                }
                              />
                            </div>
                          </div>
                          {this.state.showCustomMemberDirectoryNameField ? (
                            <div className='col-md-12 mt-2 ml-2'>
                              <div className='row'>
                                <div className='col-md-5 settings-page-select'>
                                  <Field
                                    name='custom_member_directory_name'
                                    type='text'
                                    className='form-control ps-4'
                                    component={renderInputField}
                                    label='Other'
                                    placeholder='Enter custom name*'
                                    autoComplete='off'
                                    validate={[
                                      validation.required,
                                      validation.minValue1,
                                      validation.onlyCharacterWithSpace,
                                      validation.maxLength20,
                                      validation.isBeginLatter,
                                    ]}
                                    valueKey={this.state.customMemberDirectoryName}
                                    onChange={this.handleCustomMemberDirectoryNameChange}
                                  />
                                </div>
                              </div>
                            </div>
                          ) : null}
                        </div>
                      </>
                    ) : null}
                    <div className='row fieldflex form-group'>
                      <div className='col-md-12'>
                        <label>Who can comment on your posts:</label>{' '}
                      </div>
                      <div className='col-md-12'>
                        {this.props?.metadata?.comment_types?.map((selection, index) => {
                          let inputId = 'posts_default_comment_type_' + index
                          return (
                            <div>
                              <input
                                type='radio'
                                name='posts_default_comment_type'
                                id={inputId}
                                value={selection.id}
                                checked={
                                  this.state.setting.posts_default_comment_type == selection.id
                                    ? 'checked'
                                    : ''
                                } //intentionally done for "1" or 1
                                onChange={this.changeEventHandler}
                              ></input>
                              <label htmlFor={inputId}>{selection.type}</label>
                            </div>
                          )
                        })}
                      </div>
                    </div>
                    <div className='row fieldflex form-group'>
                      <div className='col-md-12'>
                        <label>Who can see your posts:</label>{' '}
                      </div>
                      <div className='col-md-12'>
                        {this.props?.metadata?.share_types?.map((selection, index) => {
                          let inputId = 'posts_default_share_type_' + index
                          return (
                            <div>
                              <input
                                type='radio'
                                name='posts_default_share_type'
                                id={inputId}
                                value={selection.id}
                                checked={
                                  this.state.setting.posts_default_share_type == selection.id
                                    ? 'checked'
                                    : ''
                                } //intentionally done for "1" or 1
                                onChange={this.changeEventHandler}
                              ></input>
                              <label htmlFor={inputId}>{selection.type}</label>
                            </div>
                          )
                        })}
                      </div>
                    </div>
                    <div className='row fieldflex form-group'>
                      <div className='col-md-12'>
                        {' '}
                        <label> Who can you send you iTi Messages:</label>{' '}
                      </div>
                      <div className='col-md-12'>
                        <div>
                          <input
                            type='radio'
                            id='orgs_list_view_by_2'
                            onChange={this.changeEventHandler}
                            name='allow_messages_from'
                            value='All logged in users'
                            checked={
                              this.state.setting.allow_messages_from === 'All logged in users'
                                ? 'checked'
                                : ''
                            }
                          />
                          <label htmlFor='orgs_list_view_by_2'> All logged in users</label>
                        </div>
                        <div>
                          <input
                            type='radio'
                            id='orgs_list_view_by_4'
                            onChange={this.changeEventHandler}
                            name='allow_messages_from'
                            value='Friends'
                            checked={
                              this.state.setting.allow_messages_from === 'Friends' ? 'checked' : ''
                            }
                          />
                          <label htmlFor='orgs_list_view_by_4'> Friends</label>
                        </div>
                      </div>
                    </div>
                    <div className='row fieldflex form-group'>
                      <div className='col-md-12'>
                        <h6 className='fw-bold'>Email notifications for:</h6>
                      </div>
                      <div className='col-md-3'>
                        <div className='row'>
                          <div className='col-md-12'>
                            {' '}
                            <label>New friend requests </label>{' '}
                          </div>
                          <div className='col-md-12'>
                            <div>
                              <input
                                type='radio'
                                id='friend_request_notification_email_true'
                                onChange={this.changeEventHandler}
                                name='friend_request_notification_email'
                                value='1'
                                checked={
                                  this.state.setting.friend_request_notification_email === '1'
                                    ? 'checked'
                                    : ''
                                }
                              />{' '}
                              <label htmlFor='friend_request_notification_email_true'> Yes</label>
                            </div>
                            <div>
                              <input
                                type='radio'
                                id='friend_request_notification_email_false'
                                onChange={this.changeEventHandler}
                                name='friend_request_notification_email'
                                value='0'
                                checked={
                                  this.state.setting.friend_request_notification_email === '0'
                                    ? 'checked'
                                    : ''
                                }
                              />
                              <label htmlFor='friend_request_notification_email_false'> No</label>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <div className="col-md-3">
                                                        <div className="row">
                                                            <div className="col-md-12"> <label>Friend request reminder</label> </div>
                                                            <div className="col-md-12">
                                                                <div>
                                                                    <input type="radio" value="yes"/> Yes
                                                                </div>
                                                                <div>
                                                                    <input  type="radio" value="no"/> No
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div> */}
                      {/* <div className="col-md-3">
                                                        <div className="row">
                                                            <div className="col-md-12"> <label>when you have a new follower</label> </div>
                                                            <div className="col-md-12">
                                                                <div>
                                                                    <input type="radio" value="yes"/> Yes
                                                                </div>
                                                                <div>
                                                                    <input  type="radio" value="no"/> No
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div> */}
                      <div className='col-md-3'>
                        <div className='row'>
                          <div className='col-md-12'>
                            {' '}
                            <label>When you get a private message</label>{' '}
                          </div>
                          <div className='col-md-12'>
                            <div>
                              <input
                                type='radio'
                                id='personal_message_notification_email_true'
                                onChange={this.changeEventHandler}
                                name='personal_message_notification_email'
                                value='1'
                                checked={
                                  this.state.setting.personal_message_notification_email === '1'
                                    ? 'checked'
                                    : ''
                                }
                              />
                              <label htmlFor='personal_message_notification_email_true'> Yes</label>
                            </div>
                            <div>
                              <input
                                type='radio'
                                id='personal_message_notification_email_false'
                                onChange={this.changeEventHandler}
                                name='personal_message_notification_email'
                                value='0'
                                checked={
                                  this.state.setting.personal_message_notification_email === '0'
                                    ? 'checked'
                                    : ''
                                }
                              />
                              <label htmlFor='personal_message_notification_email_false'> No</label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='col-md-3'>
                        <div className='row'>
                          <div className='col-md-12'>
                            {' '}
                            <label>When you get a private group message</label>{' '}
                          </div>
                          <div className='col-md-12'>
                            <div>
                              <input
                                type='radio'
                                id='personal_group_message_notification_email_true'
                                onChange={this.changeEventHandler}
                                name='personal_group_message_notification_email'
                                value='1'
                                checked={
                                  this.state.setting.personal_group_message_notification_email ===
                                  '1'
                                    ? 'checked'
                                    : ''
                                }
                              />
                              <label htmlFor='personal_group_message_notification_email_true'>
                                {' '}
                                Yes
                              </label>
                            </div>
                            <div>
                              <input
                                type='radio'
                                id='personal_group_message_notification_email_false'
                                onChange={this.changeEventHandler}
                                name='personal_group_message_notification_email'
                                value='0'
                                checked={
                                  this.state.setting.personal_group_message_notification_email ===
                                  '0'
                                    ? 'checked'
                                    : ''
                                }
                              />
                              <label htmlFor='personal_group_message_notification_email_false'>
                                {' '}
                                No
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      {this.state.user.user_type !== 'individual' && (
                        <div className='col-md-3'>
                          <div className='row'>
                            <div className='col-md-12'>
                              <label>When you receive a review</label>
                            </div>
                            <div className='col-md-12'>
                              <div>
                                <input
                                  type='radio'
                                  id='review_receive_notification_email_true'
                                  onChange={this.changeEventHandler}
                                  name='review_receive_notification_email'
                                  value='1'
                                  checked={
                                    this.state.setting.review_receive_notification_email === '1'
                                      ? 'checked'
                                      : ''
                                  }
                                />
                                <label htmlFor='review_receive_notification_email_true'>Yes</label>
                              </div>
                              <div>
                                <input
                                  type='radio'
                                  id='review_receive_notification_email_false'
                                  onChange={this.changeEventHandler}
                                  name='review_receive_notification_email'
                                  value='0'
                                  checked={
                                    this.state.setting.review_receive_notification_email === '0'
                                      ? 'checked'
                                      : ''
                                  }
                                />
                                <label htmlFor='review_receive_notification_email_false'> No</label>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      <div className='col-md-3'>
                        <div className='row'>
                          <div className='col-md-12'>
                            <label>
                              When you sponsor a new iTRUSTit user (You have a new 1st tier iTeam
                              member)
                            </label>
                          </div>
                          <div className='col-md-12'>
                            <div>
                              <input
                                type='radio'
                                id='sponsored_new_user_receive_notification_email_true'
                                onChange={this.changeEventHandler}
                                name='sponsored_new_user_receive_notification_email'
                                value='1'
                                checked={
                                  this.state.setting
                                    .sponsored_new_user_receive_notification_email === '1'
                                    ? 'checked'
                                    : ''
                                }
                              />
                              <label htmlFor='sponsored_new_user_receive_notification_email_true'>
                                Yes
                              </label>
                            </div>
                            <div>
                              <input
                                type='radio'
                                id='sponsored_new_user_receive_notification_email_false'
                                onChange={this.changeEventHandler}
                                name='sponsored_new_user_receive_notification_email'
                                value='0'
                                checked={
                                  this.state.setting
                                    .sponsored_new_user_receive_notification_email === '0'
                                    ? 'checked'
                                    : ''
                                }
                              />
                              <label htmlFor='sponsored_new_user_receive_notification_email_false'>
                                No
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='col-md-3'>
                        <div className='row'>
                          <div className='col-md-12'>
                            <label>
                              When an organization invites you to join
                              {this.state.user.user_type === 'organization'
                                ? ' THEIR organization '
                                : ' it '}
                              as a member on iTRUSTit
                            </label>
                          </div>
                          <div className='col-md-12'>
                            <div>
                              <input
                                type='radio'
                                id='organization_add_as_member_notification_email_true'
                                onChange={this.changeEventHandler}
                                name='organization_add_as_member_notification_email'
                                value='1'
                                checked={
                                  this.state.setting
                                    .organization_add_as_member_notification_email === '1'
                                    ? 'checked'
                                    : ''
                                }
                              />
                              <label htmlFor='organization_add_as_member_notification_email_true'>
                                Yes
                              </label>
                            </div>
                            <div>
                              <input
                                type='radio'
                                id='organization_add_as_member_notification_email_false'
                                onChange={this.changeEventHandler}
                                name='organization_add_as_member_notification_email'
                                value='0'
                                checked={
                                  this.state.setting
                                    .organization_add_as_member_notification_email === '0'
                                    ? 'checked'
                                    : ''
                                }
                              />
                              <label htmlFor='organization_add_as_member_notification_email_false'>
                                No
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='col-md-3'>
                        <div className='row'>
                          <div className='col-md-12'>
                            {' '}
                            <label>New follow requests </label>{' '}
                          </div>
                          <div className='col-md-12'>
                            <div>
                              <input
                                type='radio'
                                id='follow_request_notification_email_true'
                                onChange={this.changeEventHandler}
                                name='follow_request_notification_email'
                                value='1'
                                checked={
                                  this.state.setting.follow_request_notification_email === '1'
                                    ? 'checked'
                                    : ''
                                }
                              />{' '}
                              <label htmlFor='follow_request_notification_email_true'> Yes</label>
                            </div>
                            <div>
                              <input
                                type='radio'
                                id='follow_request_notification_email_false'
                                onChange={this.changeEventHandler}
                                name='follow_request_notification_email'
                                value='0'
                                checked={
                                  this.state.setting.follow_request_notification_email === '0'
                                    ? 'checked'
                                    : ''
                                }
                              />
                              <label htmlFor='follow_request_notification_email_false'> No</label>
                            </div>
                          </div>
                        </div>
                      </div>
                      {this.state.user.user_type === 'business' ? (
                        <div className='col-md-3'>
                          <div className='row'>
                            <div className='col-md-12'>
                              <label>When this business gets trusted by a user</label>
                            </div>
                            <div className='col-md-12'>
                              <div>
                                <input
                                  type='radio'
                                  id='user_trusted_you_notification_email_true'
                                  onChange={this.changeEventHandler}
                                  name='user_trusted_you_notification_email'
                                  value='1'
                                  checked={
                                    this.state.setting.user_trusted_you_notification_email === '1'
                                      ? 'checked'
                                      : ''
                                  }
                                />
                                <label htmlFor='user_trusted_you_notification_email_true'>
                                  Yes
                                </label>
                              </div>
                              <div>
                                <input
                                  type='radio'
                                  id='user_trusted_you_notification_email_false'
                                  onChange={this.changeEventHandler}
                                  name='user_trusted_you_notification_email'
                                  value='0'
                                  checked={
                                    this.state.setting.user_trusted_you_notification_email === '0'
                                      ? 'checked'
                                      : ''
                                  }
                                />
                                <label htmlFor='user_trusted_you_notification_email_false'>
                                  No
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      {/* <div className="col-md-3">
                                                        <div className="row">
                                                                <div className="col-md-12"> <label>When user sends {this.state.user.user_type == "individual" ? "your" : "this "+this.state.user.user_type } information to another user </label> </div>
                                                            <div className="col-md-12">
                                                                <div>
                                                                    <input type="radio" value="yes"/> Yes
                                                                </div>
                                                                <div>
                                                                    <input  type="radio" value="no"/> No
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div> */}
                      {/* <div className="col-md-3">
                                                        <div className="row">
                                                                <div className="col-md-12"> <label>{this.state.user.user_type != "individual" ? "when this "+this.state.user.user_type+"gets a new 1st " : ""}Tier iTeam partner and friend</label> </div>
                                                            <div className="col-md-12">
                                                                <div>
                                                                    <input type="radio" value="yes"/> Yes
                                                                </div>
                                                                <div>
                                                                    <input  type="radio" value="no"/> No
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>  */}
                      {/* <div className="col-md-3">
                                                        <div className="row">
                                                                <div className="col-md-12"> <label> When {this.state.user.user_type != "individual" ? "this "+this.state.user.user_type : "you "} gets a new iTeam partner(s) on tier 2-7</label> </div>
                                                            <div className="col-md-12">
                                                                <div>
                                                                    <input type="radio" value="yes"/> Yes
                                                                </div>
                                                                <div>
                                                                    <input  type="radio" value="no"/> No
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div> */}
                      {this.state.user.user_type === 'organization' ? (
                        <Fragment>
                          <div className='col-md-3'>
                            <div className='row'>
                              <div className='col-md-12'>
                                {' '}
                                <label> When this organization gets trusted by a user</label>{' '}
                              </div>
                              <div className='col-md-12'>
                                <div>
                                  <input
                                    type='radio'
                                    id='user_trusted_you_notification_email_true'
                                    onChange={this.changeEventHandler}
                                    name='user_trusted_you_notification_email'
                                    value='1'
                                    checked={
                                      this.state.setting.user_trusted_you_notification_email === '1'
                                        ? 'checked'
                                        : ''
                                    }
                                  />
                                  <label htmlFor='user_trusted_you_notification_email_true'>
                                    Yes
                                  </label>
                                </div>
                                <div>
                                  <input
                                    type='radio'
                                    id='user_trusted_you_notification_email_false'
                                    onChange={this.changeEventHandler}
                                    name='user_trusted_you_notification_email'
                                    value='0'
                                    checked={
                                      this.state.setting.user_trusted_you_notification_email === '0'
                                        ? 'checked'
                                        : ''
                                    }
                                  />
                                  <label htmlFor='user_trusted_you_notification_email_false'>
                                    {' '}
                                    No
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='col-md-3'>
                            <div className='row'>
                              <div className='col-md-12'>
                                {' '}
                                <label>
                                  {' '}
                                  When a user asks to join THIS organization as a member on iTRUSTit
                                </label>{' '}
                              </div>
                              <div className='col-md-12'>
                                <div>
                                  <input
                                    type='radio'
                                    id='user_join_your_organization_notification_email_true'
                                    onChange={this.changeEventHandler}
                                    name='user_join_your_organization_notification_email'
                                    value='1'
                                    checked={
                                      this.state.setting
                                        .user_join_your_organization_notification_email === '1'
                                        ? 'checked'
                                        : ''
                                    }
                                  />
                                  <label htmlFor='user_join_your_organization_notification_email_true'>
                                    Yes
                                  </label>
                                </div>
                                <div>
                                  <input
                                    type='radio'
                                    id='user_join_your_organization_notification_email_false'
                                    onChange={this.changeEventHandler}
                                    name='user_join_your_organization_notification_email'
                                    value='0'
                                    checked={
                                      this.state.setting
                                        .user_join_your_organization_notification_email === '0'
                                        ? 'checked'
                                        : ''
                                    }
                                  />
                                  <label htmlFor='user_join_your_organization_notification_email_false'>
                                    {' '}
                                    No
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Fragment>
                      ) : (
                        ''
                      )}
                    </div>
                    <div className='row'>
                      <div className='col-md-12 text-center mt-4'>
                        <input
                          type='button'
                          className='btn blue_btn'
                          value={this.state.is_saving_settings ? 'Please Wait' : 'submit'}
                          onClick={this.updateSetting}
                        />
                      </div>
                    </div>
                  </fieldset>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

PrivacySettings.defaultProps = {
  itemsCountPerPage: NOTIFICATION_ITEMS_PER_PAGE,
  pageRangeDisplayed: NOTIFICATION_PAGE_RANGE,
}

export default connect(null)(
  reduxForm({
    form: 'privacySettingsForm',
    enableReinitialize: true,
  })(PrivacySettings),
)
