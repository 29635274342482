import React, { useEffect, useState } from 'react'
import { Field } from 'redux-form'

import * as validation from '../../services/formValidation'
import renderInputField from '../Form/Input'
import ReactSelect from '../../services/ReactSelect'
import { cityList, countryList, stateList } from '../../services/businessService'
import { BASE_API, UNITED_STATE_TEXT } from '../../services/environment'
import { AnimatePresence, motion } from 'framer-motion'
import axios from 'axios'

const parseData = (data) => data?.map?.(({ id: value, name: label }) => ({ value, label })) ?? []

let AddBusOrgAddressSection = (props) => {
  const { formValues } = props
  const [locationData, setLocationData] = useState()

  useEffect(() => {
    getCountryData()
  }, [])

  useEffect(() => {
    if (!!formValues?.country_id?.value) {
      if (!!formValues?.zipcode && formValues?.country_id?.label === UNITED_STATE_TEXT) {
        getDataUsingZip()
      }
      getStateData(formValues?.country_id?.value)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues?.country_id?.value])

  useEffect(() => {
    if (!!locationData?.fromZip) {
      locationData?.states?.length && props.change('state_id', locationData?.states[0])
      locationData?.cities?.length && props.change('city_id', locationData?.cities[0])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationData?.fromZip])

  useEffect(() => {
    if (!!formValues?.state_id?.value) {
      if (!!locationData?.fromZip) {
        setLocationData((st) => ({ ...st, fromZip: false }))
      } else {
        getCityData(formValues?.state_id?.value)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues?.state_id?.value])

  useEffect(() => {
    if (!!formValues?.city_id?.value && !!locationData?.fromZip) {
      setLocationData((st) => ({ ...st, fromZip: false }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues?.city_id?.value])

  useEffect(() => {
    formValues.zipcode?.length === 5 &&
      formValues?.country_id?.label === UNITED_STATE_TEXT &&
      getDataUsingZip()
    if (formValues?.zipcode?.length > 5 && !formValues?.zipcode?.includes('-')) {
      getStateData(formValues?.country_id?.value)
    }
  }, [formValues?.zipcode])

  const getCountryData = async () => {
    const response = await countryList()
    const countries = parseData(response?.countries)
    // console.log({ countries })
    setLocationData((st) => ({ ...st, countries }))
  }

  const getStateData = async (country_id) => {
    props?.anyTouched && props.change('state_id', null)
    props?.anyTouched && props.change('city_id', null)
    props?.anyTouched && props.change('area', null)
    const response = await stateList(country_id)
    const states = parseData(response?.states)
    setLocationData((st) => ({ ...st, states, cities: [] }))
  }

  const getCityData = async (state_id) => {
    props?.anyTouched && props.change('city_id', null)
    props?.anyTouched && props.change('area', null)
    const response = await cityList(state_id)
    const cities = parseData(response?.cities)
    setLocationData((st) => ({ ...st, cities, cityFlag: !cities?.length }))
  }

  const handleCountryChange = (e) => {
    setLocationData((st) => ({ ...st, states: [], cities: [] }))
  }

  const getDataUsingZip = async () => {
    // console.log('getting data from zip')
    let api_url = BASE_API + 'state-cities-from-zipcode'
    var params = {
      zipcode: formValues?.zipcode,
      country_id: formValues?.country_id?.value,
    }
    const response = await axios.post(api_url, params, {
      headers: { 'Content-Type': 'application/json' },
    })

    const responseData = response?.data?.data
    if (!responseData?.state_list?.length) {
      getStateData(formValues?.country_id?.value)
    } else {
      const states = parseData(responseData?.state_list)
      const cities = parseData(responseData?.city_list)
      setLocationData((st) => ({ ...st, states, cities, fromZip: props.anyTouched }))
    }
  }
  return (
    <>
      <div className='row fieldflex form-group'>
        <div className='col-md-12'>
          <div className='for-select'>
            <Field
              type='text'
              component={ReactSelect}
              name='country_id'
              options={locationData?.countries}
              onChange={handleCountryChange}
              validate={[validation.required]}
              placeholder='*Select a Country...'
              isSelect='false'
            />
          </div>
        </div>
      </div>
      <AnimatePresence>
        {formValues?.country_id ? (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className='row fieldflex form-group'
          >
            <div className='col-md-12'>
              <Field
                name='zipcode'
                type='text'
                className='form-control'
                component={renderInputField}
                // onFocus={singupHandler}
                label='zipcode'
                placeholder='Zipcode'
                autoComplete='off'
                // onChange={singupHandler}
              />
            </div>
          </motion.div>
        ) : null}
      </AnimatePresence>
      <AnimatePresence>
        {locationData?.states?.length > 0 ? (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className='row fieldflex form-group'
          >
            <div className='col-md-12'>
              <div className='for-select'>
                <Field
                  type='text'
                  className='form-control'
                  component={ReactSelect}
                  placeholder='Select a State...'
                  name='state_id'
                  options={locationData?.states}
                  isSelect='false'
                />
              </div>
            </div>
          </motion.div>
        ) : null}
      </AnimatePresence>
      <AnimatePresence>
        {locationData?.cities?.length > 0 ? (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className='row fieldflex form-group'
          >
            <div className='col-md-12'>
              <div className='for-select'>
                <Field
                  type='text'
                  className='form-control'
                  component={ReactSelect}
                  name='city_id'
                  placeholder='Select a City...'
                  options={locationData?.cities}
                  isSelect='false'
                />
              </div>
            </div>
          </motion.div>
        ) : locationData?.cityFlag ? (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className='row fieldflex form-group'
          >
            <div className='col-md-12'>
              <Field
                name='area'
                type='text'
                className='form-control'
                component={renderInputField}
                label='City/Area'
                placeholder='City/Area'
                autoComplete='off'
              />
            </div>
          </motion.div>
        ) : null}
      </AnimatePresence>
      <AnimatePresence>
        {formValues?.country_id ? (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className='row fieldflex form-group'
          >
            <div className='col-md-12'>
              <Field
                name='address_line_1'
                type='text'
                className='form-control'
                component={renderInputField}
                label='address_line_1'
                placeholder='Address 1'
                autoComplete='off'
              />
            </div>
          </motion.div>
        ) : null}
      </AnimatePresence>
      <AnimatePresence>
        {formValues?.country_id ? (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className='row fieldflex form-group'
          >
            <div className='col-md-12'>
              <Field
                name='address_line_2'
                type='text'
                className='form-control'
                component={renderInputField}
                label='address_line_2'
                placeholder='Address 2'
                autoComplete='off'
              />
            </div>
          </motion.div>
        ) : null}
      </AnimatePresence>
    </>
  )
}

export default AddBusOrgAddressSection
