import React, { Component, Fragment } from 'react'
// import { PROFILE_GALLERY_TITLE } from "../../../services/titleConst";
import ErrorMsgRenderer from '../../ErrorMsgRenderer/ErrorMsgRenderer'
import { toaster } from '../../../services/customFunction'
import {
  GALLERY_MEDIA_ITEMS_PER_PAGE,
  GALLERY_MEDIA_PAGE_RANGE,
  MAX_FILE_SIZE_MB,
  MAX_VIDEO_DURATION_S,
  MAX_VIDEO_SIZE_MB,
} from '../../../services/const'
import Pagination from 'react-js-pagination'
import GalleyMediaListDetail from '../../CommonComponents/GalleyMediaListDetail/GalleyMediaListDetail'
import * as galleryApiService from '../../../services/galleryApiService'
import $ from 'jquery'
import { setTimeout } from 'timers'
import { DefaultPlayer as Video } from 'react-html5video'
import 'react-html5video/dist/styles.css'
import ImageGallery from 'react-image-gallery'
import { getObjectUrl } from '../../Cropper/cropperHelpers'
import Skeleton from 'react-loading-skeleton'
import ReportIcon from '../../CommonComponents/ReportIcon/ReportIcon'
import ReportModal from '../../CommonComponents/ReportModal/ReportModal'
class GalleryTab extends Component {
  constructor(props) {
    super(props)
    //document.title = PROFILE_GALLERY_TITLE;

    this.state = {
      galleryFormErr: {
        file: [],
      },
      fileType: '',
      galleryFile: null,
      galleryFiles: [],
      galleryFilesSize: 0,
      isLoading: false,
      submitBtnText: 'Submit',
      submitBtnLoadingText: 'Uploading...',
      mediaType: '',
      activePage: 1,
      totalPages: 0,
      totalRecords: 0,
      // totalImageRecords: 0,
      // totalVideoRecords: 0,
      galleryList: [],
      overall_count: {
        total_videos: 0,
        total_images: 0,
        total_records: 0,
      },
      filter_total_count: {
        total_videos: 0,
        total_images: 0,
        total_records: 0,
      },
      videoMediaObj: null,
      imageMediaObj: null,
      galleryItems: [],
      showIndex: false,
      showBullets: false,
      infinite: true,
      showNav: true,
    }

    this.handlePageChange = this.handlePageChange.bind(this)
  }

  componentDidMount() {
    //document.title = PROFILE_GALLERY_TITLE;
    this.galleryListRender()
  }

  galleryListRender = () => {
    let galleryListReqData = {
      page: this.state.activePage ? this.state.activePage : 1,
      file_type: this.state.mediaType,
    }

    const self = this
    galleryApiService.galleryListCall(galleryListReqData).then(function (response) {
      let galleryListRes = response.data
      let stateData = {
        totalPages: galleryListRes.total_pages,
        totalRecords: galleryListRes.total_records,
        galleryList: galleryListRes.gallery_list,
        overall_count: galleryListRes.overall_count,
        filter_total_count: galleryListRes.filter_total_count,
      }

      if (self.state.activePage <= 0) {
        stateData['activePage'] = 1
      }

      self.props.profileHeader_gallery_Handle(galleryListRes.overall_count.total_records)
      self.setState(stateData, () => {})

      //const self = this;
      let galleryItemsRes = galleryListRes.gallery_list
      let galleryItemsResLength = galleryItemsRes.length
      let galleryItems = []

      if (galleryItemsResLength > 0) {
        for (var i = 0; i < galleryItemsResLength; i++) {
          let galleryItemRes = galleryItemsRes[i]
          if (galleryItemRes.file_type === 'image') {
            var tempObj = {
              original: galleryItemRes.gallery_picture,
              thumbnail: galleryItemRes.gallery_picture_thumb,
              id: galleryItemRes.id,
            }
            galleryItems.push(tempObj)
          }
        }
      }
      self.setState({
        galleryItems: galleryItems,
      })
    })
  }

  handlePageChange(pageNumber) {
    this.setState(
      {
        activePage: pageNumber,
      },
      () => {
        this.galleryListRender()
      },
    )
  }

  changeGalleryActiveTab = (data) => (event) => {
    let mediaType = ''
    if (data === 'gallery_pictures') {
      mediaType = 'image'
    } else if (data === 'gallery_videos') {
      mediaType = 'video'
    }
    this.setState(
      {
        mediaType: mediaType,
      },
      () => {
        this.galleryListRender()
      },
    )
  }

  profileHeader_gallery_tab_click_Handle() {
    //document.title = PROFILE_GALLERY_TITLE;
    this.galleryListRender()
  }

  validateFiles(stateCopy) {
    let isFormValid = true
    if (!stateCopy.galleryFiles.length) {
      //let stateCopy = { ...this.state };
      stateCopy.galleryFormErr['file'] = ['file is required']
      isFormValid = false
    } else {
      stateCopy.galleryFiles = stateCopy.galleryFiles.map(({ file }) => {
        if (
          typeof file.name !== 'undefined' &&
          !file.name
            .toLowerCase()
            .match(
              /(\.gif|\.heic|\.heif|\.jpg|\.png|\.JPG|\.PNG|\.jpeg|\.JPEG|\.mp4|\.MP4|\.flv|\.FLV|\.mkv|\.MKV|\.m4v|\.M4V|\.avi|\.AVI|\.3gp|\.3GP|\.mpeg|\.MPEG|\.mov|\.MOV)$/,
            )
        ) {
          // /(\.jpg|\.png|\.JPG|\.PNG|\.jpeg|\.JPEG|\.mp4|\.MP4|\.flv|\.FLV|\.mkv|\.MKV)$/
          // |\.m4v|\.M4V
          // |\.avi|\.AVI
          // |\.3gp|\.3GP
          // |\.mpeg|\.MPEG
          // |\.mov|\.MOV
          stateCopy.galleryFormErr['file'] = ['One of the selected files is invalid']
          isFormValid = false
          return { file, fileType: '' }
        }
        if (
          file &&
          typeof file.name !== 'undefined' &&
          file.name
            .toLowerCase()
            .match(/(\.gif|\.heic|\.heif|\.jpg|\.png|\.JPG|\.PNG|\.jpeg|\.JPEG)$/)
        ) {
          if (file?.size / 1024 / 1024 > MAX_FILE_SIZE_MB || file?.size <= 0) {
            stateCopy.galleryFormErr['file'] = [`File size cannot exceed ${MAX_FILE_SIZE_MB}MB`]
            isFormValid = false
            return { file, fileType: '' }
          }
          return { file, fileType: 'image' }
        } else if (
          file &&
          typeof file.name !== 'undefined' &&
          file.name
            .toLowerCase()
            .match(
              /(\.mp4|\.MP4|\.flv|\.FLV|\.mkv|\.MKV|\.m4v|\.M4V|\.avi|\.AVI|\.3gp|\.3GP|\.mpeg|\.MPEG|\.mov|\.MOV)$/,
            )
        ) {
          if (file?.size / 1024 / 1024 > MAX_VIDEO_SIZE_MB || file?.size <= 0) {
            stateCopy.galleryFormErr['file'] = [
              `File size cannot exceed ${MAX_VIDEO_SIZE_MB / 1000}GB`,
            ]
            isFormValid = false
            return { file, fileType: '' }
          }
          var video = document.createElement('video')
          video.preload = 'metadata'
          const self = this
          video.onloadedmetadata = function () {
            window.URL.revokeObjectURL(video.src)
            var duration = video.duration
            if (duration > MAX_VIDEO_DURATION_S) {
              self.setState({
                galleryFormErr: {
                  file: [`Videos cannot be longer than ${MAX_VIDEO_DURATION_S / 60} minutes.`],
                },
              })
            }
          }

          video.src = URL.createObjectURL(file)
          return { file, fileType: 'video' }
        }
        return { file, fileType: '' }
      })
    }

    return { stateCopy, isFormValid }
  }

  galleryFormValidate() {
    let stateCopy = { ...this.state }

    const { stateCopy: updatedStateCopy, isFormValid } = this.validateFiles(stateCopy)
    this.setState(updatedStateCopy)
    return isFormValid
  }

  galleryFormSubmitHandler = (event) => {
    event.preventDefault()
    //  console.log('in galleryFormSubmitHandler');
    let isFormValid = this.galleryFormValidate()
    if (isFormValid) {
      this.setState({ isLoading: true }, () => {
        this.uploadImages()
      })
    }
  }

  uploadImages() {
    const galleryFilesLength = this.state.galleryFiles?.length ?? 0
    const self = this
    let containsVideo = false
    self.state.galleryFiles?.forEach(({ file, fileType }, idx) => {
      // let galleryCreateReqData = {
      //   file_type: fileType,
      //   file: file,
      // }
      if (fileType === 'video') {
        containsVideo = true
      }
      //     console.log('galleryCreateReqData: ', galleryCreateReqData);

      const formData = new FormData()
      formData.append('file_type', fileType)
      formData.append('file', file)

      galleryApiService.galleryCreateCall(formData).then(function (response) {
        //  console.log('response: ', response);
        if (idx === galleryFilesLength - 1) {
          if (response?.status === 422) {
            self.setState({ isLoading: false, galleryFormErr: response.data.data })
          } else if (response?.status === 200) {
            self.galleryListRender()
            setTimeout(function () {
              $('#add_image_video_modal').modal('hide')
              toaster(
                'success',
                `${response.data.message}${
                  containsVideo || galleryFilesLength > 1
                    ? '. Please check back shortly if you do not see your media, as it may take a few moments to process.'
                    : ''
                }`,
              )
              //Additional setTimeout because when uploading multiple images, they take a second to upload...
              setTimeout(() => {
                self.galleryListRender()
              }, 2000)
            }, 1000)
          } else {
            self.setState({ isLoading: false })
          }
        }
      })
    })
  }

  galleryMediaDeleteHandler = (id) => {
    this.setState(
      {
        isLoading: true,
      },
      () => {
        const self = this
        galleryApiService.galleryDeleteCall(id).then(function (response) {
          //  console.log('response: ', response);
          if (response !== undefined && response.status === 422) {
            self.setState({
              isLoading: false,
            })
          } else if (response !== undefined && response.status === 200) {
            self.galleryListRender()
            $('#view_image_modal').modal('hide')
            $('#play_video_modal').modal('hide')
            toaster('success', response.data.message)
            self.setState({
              isLoading: false,
            })
          } else {
            self.setState({
              isLoading: false,
            })
          }
        })
      },
    )
  }

  handleGalleryChange = async (event) => {
    event.preventDefault()
    //  console.log('in handleGalleryChange');
    // console.log(event.target.files[0]);

    let stateCopy = { ...this.state }
    stateCopy.galleryFormErr['file'] = []
    this.setState({ galleryFiles: [], galleryFilesSize: [...event.target.files].length })
    let finalFiles = await Promise.all(
      [...event.target.files].map((file) => {
        if (file?.type) {
          return { file, fileType: '' }
        } else {
          return getObjectUrl(file).then(({ imageFile }) => {
            return { file: imageFile, fileType: '' }
          })
        }
      }),
    )
    stateCopy.galleryFile = finalFiles[0]
    stateCopy.galleryFiles = finalFiles
    this.setState(stateCopy, () => {
      this.galleryFormValidate()
    })
  }

  gallery_videoPlay_handle = (mediaObj) => {
    this.setState(
      {
        videoMediaObj: mediaObj,
      },
      () => {
        $('#play_video_modal').modal('show')
      },
    )
    //  console.log('in gallery_videoPlay_handle: GalleryTab:  ', mediaObj);
  }

  gallery_imageDisplay_handle = (mediaObj) => {
    this.setState(
      {
        imageMediaObj: mediaObj,
      },
      () => {
        $('#view_image_modal').modal('show')
      },
    )
  }

  renderGalleryUploadPreviews = () => {
    return (
      this.state.galleryFiles.length
        ? this.state.galleryFiles
        : [...Array(this.state.galleryFilesSize)]
    ).map((file, idx) => (
      <span key={idx}>
        {file?.file ? (
          file?.fileType === 'video' ? (
            <video
              style={{ maxWidth: 300, maxHeight: 250 }}
              src={URL.createObjectURL(file.file)}
              alt=''
            />
          ) : (
            <img
              style={{ maxWidth: 300, maxHeight: 250 }}
              src={URL.createObjectURL(file.file)}
              alt=''
            />
          )
        ) : (
          <Skeleton width={300} height={250} />
        )}
      </span>
    ))
  }

  render() {
    // const { invalid, galleryFile } = this.state
    const { activePage, totalPages, totalRecords, galleryList, videoMediaObj } = this.state

    let showingFrom = this.props.itemsCountPerPage * (this.state.activePage - 1) + 1
    let showingTo = this.props.itemsCountPerPage * this.state.activePage
    if (showingFrom > totalRecords) {
      showingFrom = totalRecords
    }
    if (showingTo > this.state.totalRecords) {
      showingTo = this.state.totalRecords
    }

    if (totalPages < this.state.activePage && totalPages !== 0) {
      this.setState(
        {
          activePage: totalPages,
        },
        () => {
          this.galleryListRender()
        },
      )
    }

    let self = this
    $('#add_image_video_modal').on('hidden.bs.modal', function (e) {
      let stateCopy = { ...self.state }
      stateCopy.galleryFormErr = {}
      stateCopy.fileType = ''
      stateCopy.galleryFile = null
      stateCopy.galleryFiles = []
      stateCopy.galleryFilesSize = 0
      stateCopy.isLoading = false
      self.setState(stateCopy)
    })

    $('#remove_image_video_modal').on('hidden.bs.modal', function (e) {
      let stateCopy = { ...self.state }
      stateCopy.galleryFormErr = {}
      stateCopy.fileType = ''
      stateCopy.galleryFile = null
      stateCopy.galleryFiles = []
      stateCopy.galleryFilesSize = 0
      stateCopy.isLoading = false
      self.setState(stateCopy)
    })

    $('#play_video_modal').on('hidden.bs.modal', function (e) {
      self.setState({
        videoMediaObj: null,
      })
    })

    $('#view_image_modal').on('hidden.bs.modal', function (e) {
      self.setState({
        imageMediaObj: null,
      })
    })

    return (
      <div
        id='gallery'
        className={'tab-pane ' + (this.props.activeTab === '#gallery' ? 'active' : 'fade')}
      >
        {/* <h4 className="varela">Gallery for Gallie for Kids</h4> */}
        <h4 className='varela'>Gallery</h4>
        <div className='bg-white'>
          <div className='friend_list_block'>
            <div className='f-left'>
              <ul className='gallery_tab inner-navs nav nav-tabs'>
                <li className='nav-item'>
                  <a
                    className='nav-link active'
                    data-bs-toggle='tab'
                    href='#gallery_all'
                    onClick={this.changeGalleryActiveTab('gallery_all')}
                  >
                    <span>ALL ({this.state.overall_count.total_records})</span>
                  </a>
                </li>
                <li className='nav-item'>
                  <a
                    className='nav-link'
                    data-bs-toggle='tab'
                    href='#gallery_pictures'
                    onClick={this.changeGalleryActiveTab('gallery_pictures')}
                  >
                    <span>PICTURES ({this.state.overall_count.total_images})</span>
                  </a>
                </li>
                <li className='nav-item'>
                  <a
                    className='nav-link'
                    data-bs-toggle='tab'
                    href='#gallery_videos'
                    onClick={this.changeGalleryActiveTab('gallery_videos')}
                  >
                    <span>VIDEOS ({this.state.overall_count.total_videos})</span>
                  </a>
                </li>
              </ul>
            </div>

            <div className='f-right'>
              <a
                href={null}
                className='blue_btn'
                data-bs-toggle='modal'
                data-bs-target='#add_image_video_modal'
              >
                Add photo / video
              </a>
              {/* modal start */}
              <div
                className='modal fade review_modal add_video'
                id='add_image_video_modal'
                role='dialog'
              >
                <div className='modal-dialog'>
                  <div className='modal-content'>
                    <div className='modal-header'>
                      <h5>Add New Photo/Video</h5>
                      <button type='button' className='btn-close' data-bs-dismiss='modal'></button>
                    </div>
                    <div className='modal-body'>
                      {this.state.galleryFiles?.length || this.state.galleryFilesSize ? (
                        <div className='mb-2' style={{ overflowX: 'auto' }}>
                          <div
                            className='d-flex align-items-center'
                            style={{ whiteSpace: 'nowrap', padding: '10px 0', gap: 8 }}
                          >
                            {this.renderGalleryUploadPreviews()}
                          </div>
                        </div>
                      ) : null}
                      <form
                        name='galleryForm'
                        className='choose_file mt-4'
                        onSubmit={this.galleryFormSubmitHandler}
                      >
                        {/* <div>
                          <label>Choose photo / video:</label>
                        </div> */}
                        {/* <div>
                                                    <label className="choosefile">
                                                        <span className="choose_btn">Choose file:</span>
                                                        <span className="nofile">No file chosen</span>
                                                        <input type="file" />
                                                    </label>
                                                </div>
                                                <input type="submit" className="blue_btn m-0" /> */}
                        <div className='file_msg'>
                          <label className='choosefile pt-0'>
                            {this.state.galleryFiles?.length ? (
                              <span className='choose_btn'>
                                {this.state.galleryFiles?.length} File
                                {this.state.galleryFiles?.length === 1 ? '' : 's'} selected
                              </span>
                            ) : (
                              <Fragment>
                                <span className='choose_btn'>Choose files:</span>
                                <span className='nofile'>
                                  {this.state.galleryFilesSize
                                    ? `Processing ${this.state.galleryFilesSize} File${
                                        this.state.galleryFilesSize === 1 ? '' : 's'
                                      }...`
                                    : 'No files chosen'}
                                </span>
                              </Fragment>
                            )}
                            <input
                              type='file'
                              name='galleryFile'
                              multiple
                              style={{ width: 1, height: 1 }}
                              onChange={this.handleGalleryChange}
                            />
                            <ErrorMsgRenderer
                              errors={
                                typeof this.state.galleryFormErr.file !== 'undefined' &&
                                Array.isArray(this.state.galleryFormErr.file) &&
                                this.state.galleryFormErr.file.length > 0
                                  ? this.state.galleryFormErr.file
                                  : []
                              }
                            />
                          </label>
                        </div>
                        <input
                          type='submit'
                          value={
                            this.state.isLoading
                              ? this.state.submitBtnLoadingText
                              : this.state.submitBtnText
                          }
                          className={`blue_btn m-0 ${
                            this.state.isLoading || this.state.galleryFormErr?.file?.length
                              ? 'disabled'
                              : ''
                          }`}
                          disabled={this.state.isLoading || this.state.galleryFormErr?.file?.length}
                        />
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='i-showing'>
            Showing {showingFrom} - {showingTo} of {this.state.totalRecords}
          </div>

          {/* inner tab start */}
          <div className=' inner-tab tab-content'>
            {totalRecords > 0 ? (
              <Fragment>
                <div id='gallery_all' className='tab-pane active'>
                  <ul className='gallery_list'>
                    {galleryList.map((media, index) => (
                      <GalleyMediaListDetail
                        key={media.id}
                        index={index}
                        media={media}
                        gallery_videoPlay_handle={this.gallery_videoPlay_handle}
                        gallery_imageDisplay_handle={this.gallery_imageDisplay_handle}
                      />
                    ))}
                  </ul>
                </div>

                <div>
                  <Pagination
                    activePage={activePage}
                    itemsCountPerPage={this.props.itemsCountPerPage}
                    totalItemsCount={totalRecords}
                    pageRangeDisplayed={this.props.pageRangeDisplayed}
                    onChange={this.handlePageChange}
                  />
                </div>
              </Fragment>
            ) : (
              <div className='search-section'>
                <div className='no_search_result'>
                  <img
                    src={process.env.PUBLIC_URL + '/img/empty_gallery.png'}
                    className='pt-5 pb-5 m-auto d-block'
                    alt=''
                  />
                </div>
              </div>
            )}
          </div>
        </div>

        <div className='modal fade review_modal add_video' id='play_video_modal' role='dialog'>
          <div className='modal-dialog'>
            <div className='modal-content'>
              <div
                className='modal-header pt-3'
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <h5 style={{ marginBottom: 0 }}>Video</h5>
                {/* <ReportIcon closeModal={() => $('#play_video_modal').modal('hide')} /> */}
                <h6
                  style={{ marginBottom: 0, marginRight: '0.5rem' }}
                  onClick={(e) => {
                    e.preventDefault()
                    this.galleryMediaDeleteHandler(videoMediaObj.id)
                  }}
                >
                  Delete
                </h6>
                <button
                  type='button'
                  className='btn-close'
                  data-bs-dismiss='modal'
                  style={{ margin: '-1rem -1rem -1rem -0.5rem' }}
                ></button>
              </div>
              <div className='modal-body'>
                {videoMediaObj && (
                  <Video
                    autoPlay
                    controls={['PlayPause', 'Seek', 'Time', 'Volume', 'Fullscreen']}
                    poster={videoMediaObj.gallery_video_thumb}
                    onCanPlayThrough={() => {
                      // Do stuff
                    }}
                  >
                    <source src={videoMediaObj.gallery_video} type='video/mp4' />
                  </Video>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className='modal fade review_modal add_video' id='view_image_modal' role='dialog'>
          <div className='modal-dialog'>
            <div className='modal-content'>
              <div
                className='modal-header pt-3'
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <h5 style={{ marginBottom: 0 }}>Image</h5>
                {/* <ReportIcon closeModal={() => $('#view_image_modal').modal('hide')} /> */}
                <h6
                  style={{ marginBottom: 0, marginRight: '0.5rem' }}
                  onClick={(e) => {
                    e.preventDefault()
                    this.galleryMediaDeleteHandler(this.state.imageMediaObj.media.id)
                  }}
                >
                  Delete
                </h6>
                <button
                  type='button'
                  className='btn-close'
                  data-bs-dismiss='modal'
                  style={{ margin: '-1rem -1rem -1rem -0.5rem' }}
                ></button>
              </div>
              <div className='modal-body' style={{ textAlign: 'center' }}>
                {this.state.imageMediaObj && (
                  <ImageGallery
                    ref={(i) => (this._imageGallery = i)}
                    items={this.state.galleryItems}
                    lazyLoad={false}
                    infinite={false}
                    showBullets={this.state.showBullets}
                    additionalClass='app-image-gallery'
                    showFullscreenButton={false}
                    showPlayButton={false}
                    showThumbnails={false}
                    showIndex={false}
                    showNav={this.state.showNav}
                    isRTL={false}
                    startIndex={this.state.imageMediaObj.index}
                  />
                )}
              </div>
            </div>
          </div>
        </div>

        {/* <ReportModal
          user_id={this.props?.user_id}
          user_id_reported_by={this.props?.loginUserId}
          media={this.state?.imageMediaObj?.media}
          galleryListRender={this.galleryListRender}
        /> */}
      </div>
    )
  }
}

GalleryTab.defaultProps = {
  itemsCountPerPage: GALLERY_MEDIA_ITEMS_PER_PAGE,
  pageRangeDisplayed: GALLERY_MEDIA_PAGE_RANGE,
}

export default GalleryTab
