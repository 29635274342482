import React, { Component } from 'react'
import { Redirect, Link } from 'react-router-dom'
import { Field, reduxForm } from 'redux-form'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import AppLayout from '../AppLayout'
import './Login.scss'
import * as validation from '../../services/formValidation'
import renderInputField from '../Form/Input'
import SocialLogin from '../SocialLogin/SocialLogin'
import * as customFunction from '../../services/customFunction'
import * as route from '../../services/routeConst'
import ForgotPassword from '../ForgotPassword/ForgotPassword'
import { LOGIN_TITLE } from '../../services/titleConst'
import { sendEmailConfirmationCall, sendOtp } from '../../services/authService'
import { DEFAULT_PROFILE_PICTURE } from '../../services/const'

import $ from 'jquery'
import { customLogger } from '../../utils/CustomLogger'
import VerifyOtpModal, {
  showVerifyOtpModal,
} from '../CommonComponents/VerifyOtpModal/VerifyOtpModal'
import { USE_OTP } from '../../services/environment'
import { Helmet } from 'react-helmet'
import ConfirmSocialSignupModal, {
  hideConfirmSocialSignupModal,
  showConfirmSocialSignupModal,
} from '../SignupNew/ConfirmSocialSignupModal'
import LoadingOverlay from '../CommonComponents/LoadingOverlay'
import SelectUserTypeModal, {
  hideSelectUserTypeModal,
  showSelectUserTypeModal,
} from '../SignupNew/SelectUserTypeModal'
import { isMobile } from 'react-device-detect'

class Login extends Component {
  constructor(props) {
    super(props)
    const userToken = customFunction.userToken()
    this.state = {
      login: {
        email: props.location.state ? props.location.state.email : '',
        password: '',
        rememberme: '0',
      },
      isRedirect: false,
      isToken: userToken,
      otpToken: customFunction.otpToken(),
      loginErrorMsg: '',
      isDisabled: false,
      resetText: 'Log In',
      usertype: props.location.state ? props.location.state.type : '',
      socialSingup: {},
      isRedirectIndividualSignup: false,
      user_id: 0,
      isDisplaySendActivationText: false,
      isDisplayLoginErrorMsg: false,
      loginEmailDisplay: '',
      user: null,
      otpType: null,
      otpSentTo: null,
      status: 'idle',
    }
  }

  componentDidMount() {
    if (this.props?.location?.state?.autoLoggedOut) {
      customFunction.toaster('error', 'You have been automatically logged out. Please login again.')
    }
    if (this.state.isToken != null) {
      this.props.history.push({
        pathname: route.HOME_ROUTE,
      })
    }
    this.state.login.email.length > 0 &&
      this.state.usertype.length > 0 &&
      this.state.usertype === 'normal' &&
      this.props.loginHistoryEmail(this.state.login.email)

    this.setState({ isDisplayLoginErrorMsg: false })

    window.scrollTo(0, 0)
  }

  loginHandler = (event) => {
    let inputName = event.target.name
    let inputValue = event.target.value
    let loginCopy = { ...this.state }

    loginCopy.login[inputName] = inputValue
    this.setState(loginCopy)

    this.setState({ isDisplayLoginErrorMsg: false })
  }

  loginSubmitHandler = (event) => {
    event.preventDefault()
    USE_OTP
      ? this.props.authenticateAction({ ...this.state.login, otp_token: this.state.otpToken })
      : this.props.loginAction(this.state.login)
  }

  socialLoginHandler = (registerData) => {
    if (customFunction.getInviteMatrixId()) {
      registerData.sponsors_iteam_id = customFunction.getInviteMatrixId()
    }

    registerData.user_type = this.state.selectedUserType
    this.setState(
      { selectedUserType: null, status: 'submitting', socialSingup: registerData },
      () => {
        this.props.socialLoginAction(registerData)
      },
    )
  }

  linkedInLoginHandler = (registerData) => {
    if (customFunction.getInviteMatrixId()) {
      registerData.sponsors_iteam_id = customFunction.getInviteMatrixId()
    }
    registerData.user_type = this.state.selectedUserType
    registerData.login_type = 'linkedin'
    registerData.social_login_type = 'linkedin'
    this.setState(
      { selectedUserType: null, status: 'submitting', socialSingup: registerData },
      () => {
        this.props.linkedInLoginAction(registerData)
      },
    )
  }

  componentWillUnmount() {
    this.props.loginHistoryEmail('')
    this.props.isLoadingAction(false)
    this.props.authResponseAction(null)
    this.props.errorMsgAction({})
  }

  componentWillReceiveProps = (nextProps) => {
    let resetText = 'Log In'
    if (nextProps.isLoading === true) resetText = 'Loading...'
    const { isLoading, user = {} } = nextProps ?? {}
    this.handleLogin(resetText, isLoading, user)
  }

  handleLogin = (resetText, isLoading, user) => {
    // console.log({ user, socialSingup: this.state.socialSingup })
    this.setState({ resetText: resetText, isDisabled: isLoading })
    //console.log(", user);
    if (!!user?.userdetail?.id) {
      this.props.resetAuthDataAction()
      this.loginAfterAuth(user)
    } else if (user?.authUserData) {
      const { phone } = user?.authUserData
      const otpType = !!phone ? 'sms' : 'email'
      const self = this
      this.setState({ user, user_id: user?.authUserData?.user_id, otpType }, async () => {
        const { data } = await sendOtp({ user_id: user?.authUserData?.user_id, type: otpType })
        if (data?.sent_to) {
          showVerifyOtpModal(otpType)
          self.setState({ authUserData: user?.authUserData, otpSentTo: data?.sent_to })
        }
      })
    }
    const socialData = user?.authresponse?.data?.data ?? user?.authresponse?.data
    const currentSocialData =
      this.props?.user?.authresponse?.data?.data ?? this.props?.user?.authresponse?.data
    if (
      Object.keys(user.errormsg).length > 0 ||
      user.errormsg.length > 0 ||
      (!!socialData?.social_id && socialData?.social_id !== currentSocialData?.social_id)
    ) {
      if (!!user?.authresponse?.data?.data?.social_id || !!user?.authresponse?.data?.social_id) {
        this.setState({ status: 'idle', social_data: socialData }, () => {
          this.props.authResponseAction(null)
          this.props.errorMsgAction({})
          if (!this.state.selectedUserType) {
            showSelectUserTypeModal()
          } else {
            showConfirmSocialSignupModal()
          }
        })
      }

      if (Object.keys(this.state.socialSingup).length === 0) {
        if (user.errormsg.user_id) {
          this.setState(
            {
              status: 'idle',
              user_id: user.errormsg.user_id,
              loginEmailDisplay: user.errormsg.email,
            },
            () => {
              $('#active_email_modal').modal('show')
              this.props.authResponseAction(null)
              this.props.errorMsgAction({})
            },
          )
        } else {
          this.props.anyTouched &&
            this.setState({
              status: 'idle',
              isDisplayLoginErrorMsg: true,
            })
          //customFunction.toaster("error", user.errormsg.message);
          this.props.authResponseAction(null)
          this.props.errorMsgAction({})
        }
      }
    }
  }

  loginAfterOtpVerification = (otp_token) => {
    this.props.loginAction({ ...this.state.login, otp_token })
  }

  loginAfterAuth = (user) => {
    customFunction.setUser(user.userdetail)
    customFunction.setToken(user.token)
    customFunction.setOtpToken(user.otp_token)
    let profile_history = JSON.parse(window.localStorage.getItem('profile_history'))
    let history = {}

    history = {
      id: user.userdetail.id,
      profile_img: user.userdetail.profile_picture_medium
        ? user.userdetail.profile_picture_medium
        : DEFAULT_PROFILE_PICTURE,
      email: user.userdetail.email,
      type: user.userdetail.login_type,
      user_type: user.userdetail.user_type,
    }
    if (user.userdetail.user_type === 'individual') history.name = user.userdetail.fullname
    else history.name = user.userdetail.business_organization_name

    if (profile_history == null) {
      window.localStorage.setItem('profile_history', JSON.stringify([history]))
    } else {
      let userFind = profile_history.filter((profile) => {
        return profile.id === user.userdetail.id
      })
      if (userFind.length === 0) {
        profile_history.unshift(history)
        window.localStorage.setItem('profile_history', JSON.stringify(profile_history))
      } else {
        const profileIndex = profile_history.findIndex(
          (profile) => profile.id === user.userdetail.id,
        )
        profile_history[profileIndex] = history
        profile_history.push(...profile_history.splice(0, profileIndex))

        window.localStorage.setItem('profile_history', JSON.stringify(profile_history))
      }
    }

    if (!this.props?.setPromotionStep) {
      this.props.history.push({
        pathname: route.PROFILE_ROUTE,
        //pathname: route.HOME_ROUTE,
      })
    } else {
      this.props?.setPromotionStep?.('prizes')
    }
    this.props?.isLogin?.(true)
  }

  resendActivationLink = () => {
    let self = this

    let sendEmailConfirmationCallReqData = {
      id: this.state.user_id,
    }

    sendEmailConfirmationCall(sendEmailConfirmationCallReqData).then(function (response) {
      if (
        response &&
        typeof response !== 'undefined' &&
        typeof response.status !== 'undefined' &&
        response.status === 200
      ) {
        self.setState({ isDisplaySendActivationText: true })
        //customFunction.toaster("success", response.data.message);
      }
    })
  }

  handleSocialSubmit = (formData) => {
    const signupData = formData
    signupData.user_type = this.state.selectedUserType ?? 'individual'
    const self = this
    this.setState({ status: 'submitting' }, () => {
      localStorage.removeItem('bus_org_name_from_presearch')
      self.props.signupAction(signupData)
      self.props.authResponseAction(null)
      self.props.errorMsgAction(null)
      hideConfirmSocialSignupModal()
    })
  }

  handleSelectUserType = (userType) => {
    this.setState({ selectedUserType: userType }, () => {
      hideSelectUserTypeModal()
      showConfirmSocialSignupModal()
    })
  }

  handleFocus = () => {
    isMobile &&
      window.scrollTo({
        top: $('#login-fields').offset().top,
        behavior: 'smooth',
      })
  }

  render = () => {
    let self = this

    const { invalid } = this.props

    $('#active_email_modal').on('hidden.bs.modal', function (e) {
      self.setState({ isDisplaySendActivationText: false, loginEmailDisplay: '', user_id: 0 })
    })

    const content = (
      <>
        <Helmet>
          <title>{LOGIN_TITLE}</title>
        </Helmet>
        <LoadingOverlay show={this.state.status === 'submitting'} />
        <ConfirmSocialSignupModal
          status={this.state.status}
          data={this.state?.social_data}
          userTypeOverride={this.state.selectedUserType}
          handleSubmit={this.handleSocialSubmit}
        />
        <SelectUserTypeModal status={this.state.status} handleSubmit={this.handleSelectUserType} />
        <div className='container'>
          <div className='row'>
            <div className='loginblk'>
              <div className='panel panel-default'>
                <div className='panel-heading'>
                  <h3 className='panel-title'>Log In</h3>
                  <p className='subtitle'>Log in with:</p>
                </div>
                <div className='panel-body'>
                  <div className='row socialmedia'>
                    <SocialLogin
                      {...this.state}
                      socialLogin={this.socialLoginHandler}
                      linkedInLogin={this.linkedInLoginHandler}
                      hideAppLayout
                    />
                  </div>
                  {this.state.isDisplayLoginErrorMsg ? (
                    <span className='text-danger it-login-error-msg'>
                      The username, email or password <br />
                      you entered is incorrect.
                    </span>
                  ) : (
                    ''
                  )}
                  <form method='post' id='login' onSubmit={this.loginSubmitHandler}>
                    <fieldset id='login-fields'>
                      <div className='form-group'>
                        <Field
                          name='email'
                          id='email-input'
                          type='text'
                          className='form-control username-input'
                          component={renderInputField}
                          validate={[validation.required]}
                          label='Email'
                          placeholder='Login with Username or Email '
                          autoComplete='off'
                          onChange={this.loginHandler}
                          onFocus={this.handleFocus}
                          isUsernameInput
                        />
                      </div>
                      <div className='form-group'>
                        <Field
                          name='password'
                          id='password-input'
                          type='password'
                          className='form-control'
                          component={renderInputField}
                          validate={[validation.required]}
                          label='Password'
                          placeholder='Password'
                          autoComplete='off'
                          onChange={this.loginHandler}
                          onFocus={this.handleFocus}
                        />
                      </div>
                      <input
                        type='submit'
                        value={this.state.resetText}
                        className={classNames(
                          invalid && 'disabled',
                          'btn',
                          'btn-lg',
                          'btn-success',
                          'btn-block',
                          'loginbtn',
                        )}
                        disabled={invalid || this.state.isDisabled}
                      />
                    </fieldset>
                    <div className='d-flex remember'>
                      {/* <div className='radio1'>
                        <label className='radioblk'>
                          Remember me
                          <input
                            type='radio'
                            name='rememberme'
                            onChange={this.loginHandler}
                            value='1'
                          />
                          <span className='checkmark' />
                        </label>
                      </div> */}
                      <a
                        className='link'
                        data-bs-toggle='modal'
                        data-bs-target='#reset_password_email_modal'
                      >
                        Forgot password?
                      </a>
                    </div>
                  </form>
                  <p className='f_14'>
                    Don't have an account?
                    <Link className='purple' to={route.SIGNUP_ROUTE}>
                      Sign up now!
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ForgotPassword />

        {/* ACTIVATION MODAL */}
        <div className='modal fade reset_password_blk' id='active_email_modal' role='dialog'>
          <div className='modal-dialog'>
            <div className='modal-content'>
              <div className='modal-header'>
                <h4 className='modal-title'>Activate your account</h4>
                <button type='button' className='btn-close' data-bs-dismiss='modal'></button>
              </div>
              <div className='modal-body'>
                {this.state.isDisplaySendActivationText ? (
                  <p>
                    We sent your activation email to <b>{this.state.loginEmailDisplay}</b> please
                    click the activation link there.
                  </p>
                ) : (
                  <p className='text-start'>
                    This account has not been activated. please check your email or{' '}
                    <a href={null} onClick={this.resendActivationLink}>
                      <b>Click Here </b>
                    </a>
                    to re-send your activation email.
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* ACTIVATION MODAL OVER */}
        <VerifyOtpModal
          authUserData={this.state.authUserData}
          type={this.state.otpType}
          onVerify={this.loginAfterOtpVerification}
          otpSentTo={this.state.otpSentTo}
        />
      </>
    )

    return !this.props?.hideAppLayout ? (
      <AppLayout isDisplayFooter={false}>{content}</AppLayout>
    ) : (
      <>{content}</>
    )
  }
}

Login.propTypes = {
  user: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  loginAction: PropTypes.func,
  authenticateAction: PropTypes.func,
  socialLoginAction: PropTypes.func,
}

Login = reduxForm({
  form: 'loginForm',
  enableReinitialize: true,
})(Login)
export default Login
