import React from 'react'
import { dateStringToTimeSince } from '../../services/customFunction'

const ChatListContent = ({ chat, displayName }) => {
  return (
    <>
      <div className='user_content'>
        <div className='user_content_wrapper d-flex justify-content-between'>
          <div className='d-flex flex-column w-100'>
            <div
              className={
                'username d-flex justify-content-between align-items-center w-100'
                // + (chatUsersOnlineList.indexOf(user.id) >= 0 ? 'user_online' : '')
              }
              style={{ gap: 2 }}
            >
              {chat?.total_unread > 0 ? (
                <strong className='chat-list-content-name'>{displayName}</strong>
              ) : (
                <span className='chat-list-content-name'>{displayName}</span>
              )}
            </div>
            <div className='d-none d-md-block time'>{dateStringToTimeSince(chat.last_chat_at)}</div>
            <div className='d-block d-md-none time'>
              {dateStringToTimeSince(chat.last_chat_at, true)}
            </div>
          </div>
        </div>
      </div>
      {chat?.total_unread > 0 ? (
        <span
          style={{
            alignSelf: 'center',
            height: 12,
            width: 12,
            backgroundColor: '#544cf9',
            borderRadius: '50%',
          }}
        />
      ) : null}
    </>
  )
}

export default ChatListContent
