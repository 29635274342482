import * as type from '../actions/const'

const initialState = {
  authUserData: null,
  userdetail: [],
  networkerror: [],
  errormsg: {},
  authresponse: null,
  signupmsg: [],
  editUser: {},
  isLoading: false,
  searchbar: {},
  searchbarclicked: { value: false, search: '' },
  searchbarelasticresults: [],
  coverImage: '',
  email: '',
  social_login_form: {},
  slug: [],
  searchbarreset: false,
  hideHeader: false,
  reviewuser: {},
  otp_token: window.localStorage.getItem('otp_token'),
  signup_type: localStorage.getItem('signupUserType') ?? 'individual',
  socialLoginResponse: null,
}

export default function UserReducer(state = initialState, action) {
  const data = action.data
  switch (action.type) {
    case type.USER_AUTHENTICATE:
      return {
        ...state,
        authUserData: data.authUserData,
      }
    case type.USER_DETAIL:
      return {
        ...state,
        userdetail: data.user,
        token: data.token,
        otp_token: data.otp_token ?? state.otp_token,
      }
    case type.USER_EXISTS:
      return {
        ...state,
        socialLoginResponse: data,
      }
    case type.SIGNUP_STORE:
      return {
        ...state,
        signupmsg: data,
        slug: action.slug,
        email: action.email,
        userId: action.userId,
      }
    //        case type.NETWORK_ERROR:
    //            return {
    //                ...state,
    //                networkerror: "Network Error",
    //            };
    case type.ERROR_MSG:
      return {
        ...state,
        errormsg: data,
      }
    case type.SIGNUP_TYPE:
      return {
        ...state,
        signup_type: data,
      }
    case type.AUTH_RESPONSE:
      return {
        ...state,
        authresponse: data,
      }
    case type.IS_LOADING:
      return {
        ...state,
        isLoading: data,
      }
    case type.EDIT_USER_ACTION:
      return {
        ...state,
        editUser: data,
      }

    case type.COVER_IMAGE:
      return {
        ...state,
        coverImage: data,
      }

    case type.HEADER_SEARCHBAR:
      return {
        ...state,
        searchbar: data,
      }

    case type.HIDE_HEADER:
      return {
        ...state,
        hideHeader: data,
      }

    case type.HEADER_SEARCHBAR_VAL:
      return {
        ...state,
        searchbarval: data,
      }

    case type.HEADER_SEARCHBAR_LOC:
      return {
        ...state,
        searchbarlocchanged: data,
      }

    case type.HEADER_SEARCHBAR_CLICKED:
      return {
        ...state,
        searchbarclicked: data,
      }

    case type.HEADER_SEARCHBAR_SUGGESTION_CLICKED:
      return {
        ...state,
        searchbarsuggestionclicked: data,
      }

    case type.HEADER_SEARCHBAR_ELASTIC:
      return {
        ...state,
        searchbarelasticresults: data,
      }

    case type.SET_ECHO_CONNECTED:
      return {
        ...state,
        echoconnected: data,
      }

    case type.LOGIN_HISTORY_EMAIL:
      return {
        ...state,
        email: data,
      }
    case type.SOCIAL_LOGIN_FILL_FORM:
      return {
        ...state,
        social_login_form: data,
      }
    case type.HEADER_SEARCHBAR_RESET:
      return {
        ...state,
        searchbarreset: data,
      }
    case type.HEADER_SEARCHBAR_SET:
      return {
        ...state,
        searchbarset: data,
        searchbarsetfrommodal: action?.fromModal,
      }
    case type.REVIEW_USER:
      return {
        ...state,
        reviewuser: data,
      }
    case type.UPDATE_POST_USER_SETTINGS:
      return {
        ...state,
        userdetail: {
          ...state.userdetail,
          user_settings: {
            ...state.userdetail.user_settings,
            posts_default_share_type: data.posts_default_share_type,
            posts_default_comment_type: data.posts_default_comment_type,
          },
        },
      }
    default:
      return state
  }
}
