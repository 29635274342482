import React from 'react'
import ReactStars from 'react-stars'
import {
  STAR_RATING_iTRUST_ACTIVE_COLOR,
  STAR_RATING_iTRUST_INACTIVE_COLOR,
  STAR_RATING_PROFILE_HEADER_SIZE,
  STAR_RATING_TRUST_ME_ACTIVE_COLOR,
  STAR_RATING_TRUST_ME_INACTIVE_COLOR,
} from '../../../services/const'

const ProfileReviewStars = ({ user, avg_reviews, overall_reviews, showReviewTab }) => {
  const star_rating_color1 =
    user.user_type === 'individual'
      ? STAR_RATING_iTRUST_INACTIVE_COLOR
      : STAR_RATING_TRUST_ME_INACTIVE_COLOR
  const star_rating_color2 =
    user.user_type === 'individual'
      ? STAR_RATING_iTRUST_ACTIVE_COLOR
      : STAR_RATING_TRUST_ME_ACTIVE_COLOR

  return (
    <div className='right_link'>
      <div className='review_digit nav'>
        <a
          onClick={() => showReviewTab(user.user_type === 'individual' ? 'given' : 'received')}
          className={'nav-link py-0'}
          data-bs-toggle='tab'
          href='#review'
        >
          <div className='d-flex' style={{ gap: 15 }}>
            <div className='star_ratings'>
              <ReactStars
                edit={false}
                size={STAR_RATING_PROFILE_HEADER_SIZE}
                color1={star_rating_color1}
                color2={star_rating_color2}
                value={
                  user.user_type === 'individual'
                    ? avg_reviews.given_reviews
                    : avg_reviews.received_reviews
                }
              />
            </div>
            {user.user_type === 'individual'
              ? `${overall_reviews.total_given_reviews} Reviews Given`
              : `${overall_reviews.total_received_reviews} Reviews Received`}
          </div>
        </a>
      </div>
    </div>
  )
}

export default ProfileReviewStars
