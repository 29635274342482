import React, { useState } from 'react'
import $ from 'jquery'
import { SITE_ADDRESS } from '../../../services/environment'
import QRCode from '../QRCodeModal/QRCode'
import ShareOptions from './ShareOptions'
import { isMobile } from 'react-device-detect'
import { getUser } from '../../../services/customFunction'

export const showShareModal = (id = '0') => {
  $('#share_profile_modal-' + id).modal('show')
}

export const hideShareModal = (id = '0') => {
  $('#share_profile_modal-' + id).modal('hide')
}

const ShareModal = ({
  id = '0',
  user,
  initialModalState = 'share',
  onVCard = false,
  isInviteToClaim = false,
}) => {
  const profileLink = `${SITE_ADDRESS}${user?.slug}`
  const vcardLink = `${SITE_ADDRESS}vcard/${user?.slug}`
  const [modalState, setModalState] = useState(initialModalState)
  const [shareType, setShareType] = useState('profile')

  const closeBtnProps = modalState.includes('qr_code')
    ? { onClick: () => setModalState('share') }
    : { 'data-bs-dismiss': 'modal' }
  const IsUserClaimed = user?.is_business_claimed === '1'

  return (
    <div className='modal fade share_profile_modal' id={'share_profile_modal-' + id} role='dialog'>
      <div className='modal-dialog' style={IsUserClaimed ? { maxWidth: 650 } : {}}>
        <div className='modal-content'>
          <div
            className={`modal-header align-items-start ${
              modalState.includes('qr_code') ? '' : 'border-0'
            }`}
          >
            {modalState.includes('qr_code') ? <h5>{user.fullname}</h5> : ''}
            <button type='button' className='btn-close' {...closeBtnProps}></button>
          </div>
          <div
            className='modal-body pt-0'
            style={modalState.includes('qr_code') ? { textAlign: 'center' } : {}}
          >
            {modalState === 'share' ? (
              <>
                <h5 className='mb-4 text-center'>
                  {isInviteToClaim ? (
                    `Invite the Owner/Rep of this ${user?.user_type} to claim this page`
                  ) : (
                    <>
                      Share <strong>{user?.fullname}'s</strong> {onVCard ? 'VCard' : 'page'}
                    </>
                  )}
                </h5>
                {isMobile && !onVCard && !isInviteToClaim ? (
                  <>
                    <div className='d-flex justify-content-evenly w-50 mx-auto mb-4'>
                      <button
                        className={`share-type-btn ${shareType === 'profile' ? 'active' : ''}`}
                        onClick={() => setShareType('profile')}
                      >
                        Profile
                      </button>
                      <button
                        className={`share-type-btn ${shareType === 'vcard' ? 'active' : ''}`}
                        onClick={() => setShareType('vcard')}
                      >
                        vCard
                      </button>
                    </div>
                    <div className='d-flex'>
                      {shareType === 'profile' ? (
                        <ShareOptions
                          modalId={id}
                          loginUserData={getUser()}
                          isInviteToClaim={isInviteToClaim}
                          user={user}
                          setModalState={setModalState}
                          shareLink={profileLink}
                        />
                      ) : shareType === 'vcard' ? (
                        <ShareOptions
                          modalId={id}
                          loginUserData={getUser()}
                          isInviteToClaim={isInviteToClaim}
                          user={user}
                          setModalState={setModalState}
                          type='vcard'
                          shareLink={vcardLink}
                        />
                      ) : null}
                    </div>
                  </>
                ) : (
                  (!isMobile || onVCard || isInviteToClaim) && (
                    <div className='d-flex'>
                      {!onVCard ? (
                        <ShareOptions
                          modalId={id}
                          loginUserData={getUser()}
                          isInviteToClaim={isInviteToClaim}
                          user={user}
                          setModalState={setModalState}
                          shareLink={profileLink}
                        />
                      ) : null}
                      {onVCard || (IsUserClaimed && !isMobile) ? (
                        <ShareOptions
                          modalId={id}
                          loginUserData={getUser()}
                          isInviteToClaim={isInviteToClaim}
                          user={user}
                          setModalState={setModalState}
                          type='vcard'
                          shareLink={vcardLink}
                        />
                      ) : null}
                    </div>
                  )
                )}
              </>
            ) : modalState.includes('qr_code') ? (
              <div className='d-flex flex-column align-items-center'>
                <span className='vcard-cta-text mb-2'>
                  {modalState === 'qr_code_vcard' ? 'vCard' : 'Profile Page'}
                </span>
                <QRCode
                  value={
                    modalState === 'qr_code_vcard'
                      ? `${SITE_ADDRESS}qr/vcard/${user?.id}`
                      : `${SITE_ADDRESS}qr/${user?.id}`
                  }
                  logoImage={process.env.PUBLIC_URL + '/img/iti_qr_logo.png'}
                  size={300}
                  qrStyle='dots'
                  // fgColor='#006699'
                  fgColor='black'
                  ecLevel='H'
                  eyeRadius={[
                    { outer: 10 }, // top/left eye
                    { outer: 10 }, // top/right eye
                    { outer: 10 }, // bottom/left eye
                  ]}
                />
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  )
}

// export default React.memo(
//   ShareModal,
//   (prev, next) => prev.user.id === next.user.id || prev.isInviteToClaim !== next.isInviteToClaim,
// )
export default ShareModal
