import React, { Component } from 'react'
import AppLayout from '../AppLayout'
class ReviewSuccess extends Component {
  render() {
    return (
      <AppLayout>
        <section className='msg_bar'>
          <div className='container'>
            <div className='msg_blk'>
              <p>
                Your review for Mangrove Realty is now live! Keep it up! Every review benefits great
                local finds like Mangrove Realty.
              </p>
              <div className='close btn-close'></div>
            </div>
          </div>
        </section>
        <section className='business_section review_sucess'>
          <section className='cover_details'>
            <div className='container'>
              <div className='cover-inner-wrapper'>
                <div className='cover_inner'>
                  <div className='pro_name_detail'>
                    <div className='d-flex'>
                      <div className='pro_1'>
                        <img src={process.env.PUBLIC_URL + '/img/userimg.jpg'} alt='' />
                      </div>
                      <div className='pro_name'>
                        <h3 className='blue varela'>Mangrove Realty</h3>
                        <div>
                          <ul className='contact'>
                            <li className='address'>
                              150 Briarwood Drive
                              <br /> Goldsboro, NC 27530
                            </li>
                            <li className='phone'>
                              <a href={null}>(954)555-5555</a>
                            </li>
                          </ul>
                        </div>
                        <div className='design_action'>
                          <a href={null} className='orange'>
                            iTRUSTit
                          </a>
                          <a href={null} className='green'>
                            {' '}
                            + Friend
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='badge'>
                    <div className='b-triangle'></div>
                    <div className='b-inner'>
                      <h4>Trusted by</h4>
                      <div className='d-flex d1'>
                        <div className='col-4 digits_cat'>
                          <div className='digit'>20</div>
                          <div className='digit_name'>friends</div>
                        </div>
                        <div className='col-4 digits_cat'>
                          <div className='digit'>20</div>
                          <div className='digit_name'>friends of friends</div>
                        </div>
                        <div className='col-4 digits_cat'>
                          <div className='digit'>68</div>
                          <div className='digit_name'>total</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='cover_bottom'>
                    <div className='left_link'>
                      <div className='contact_cat'>
                        <p>
                          <strong>Categories</strong>
                          <a href={null}>Real Estate</a>,<a href={null}>Real Estate Services</a>,
                          <a href={null}>Realtor</a>,<a href={null}>Real Estate Consultants</a>...
                          and 3 more
                        </p>
                        <p>
                          <strong>Cities</strong> Hermosa Beach, Rodondo Beach, Los Angeles
                        </p>
                      </div>
                    </div>
                    <div className='right_link'>
                      <div className='star_ratings'>
                        <img src={process.env.PUBLIC_URL + '/img/star_top.png'} alt=' ' />
                        <img src={process.env.PUBLIC_URL + '/img/star_top.png'} alt=' ' />
                        <img src={process.env.PUBLIC_URL + '/img/star_top.png'} alt=' ' />
                        <img src={process.env.PUBLIC_URL + '/img/star_top.png'} alt=' ' />
                        <img src={process.env.PUBLIC_URL + '/img/star_top.png'} alt=' ' />
                      </div>
                      <div className='review_digit'>178 Reviews</div>
                    </div>
                  </div>
                  <div className='cover_bottom_of_bottom'>
                    <ul className='d-flex'>
                      <li>
                        <a href={null}>
                          <i>
                            <img src={process.env.PUBLIC_URL + '/img/overview_icon.png'} alt='' />
                          </i>
                          <span>Overview</span>
                        </a>
                      </li>
                      <li>
                        <a href={null}>
                          <i>
                            <img src={process.env.PUBLIC_URL + '/img/itrsut_icon.png'} alt='' />
                          </i>
                          <span>Trust List</span>
                        </a>
                      </li>
                      <li>
                        <a href={null} data-bs-toggle='modal' data-bs-target='#send_msg'>
                          <i>
                            <img src={process.env.PUBLIC_URL + '/img/send_icon.png'} alt='' />
                          </i>
                          <span>Send Message</span>
                        </a>
                        {/* -------------------------------------------share_modal modal start ------------------------------------------- */}
                        <div
                          className='modal fade review_modal send_msg'
                          id='send_msg'
                          role='dialog'
                        >
                          <div className='modal-dialog'>
                            <div className='modal-content'>
                              <div className='modal-header'>
                                <h5>Send Message to Mangrove Reality</h5>
                                <button
                                  type='button'
                                  className='btn-close'
                                  data-bs-dismiss='modal'
                                ></button>
                              </div>
                              <div className='modal-body'>
                                <div className='share_modal_form'>
                                  <form className=''>
                                    <div className='form-group'>
                                      <input type='text' placeholder='Subject...' />
                                    </div>
                                    <div className='form-group'>
                                      <textarea placeholder='Send message..'></textarea>
                                    </div>
                                    <div className='form-group'>
                                      <input
                                        type='submit'
                                        value='Send message'
                                        className='share_submit_btn'
                                      />
                                    </div>
                                  </form>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* -------------------------------------------share_modal modal over------------------------------------------- */}
                      </li>
                      <li>
                        <a href={null}>
                          <i>
                            <img src={process.env.PUBLIC_URL + '/img/download-vcard.png'} alt='' />
                          </i>
                          <span>Download V-card</span>
                        </a>
                      </li>
                      <li>
                        <a href={null} data-bs-toggle='modal' data-bs-target='#share_modal'>
                          <i>
                            <img src={process.env.PUBLIC_URL + '/img/share_icon.png'} alt='' />
                          </i>
                          <span>Share</span>
                        </a>
                        {/* -------------------------------------------share_modal modal start ------------------------------------------- */}
                        <div
                          className='modal fade review_modal share_modal'
                          id='share_modal'
                          role='dialog'
                        >
                          <div className='modal-dialog'>
                            <div className='modal-content'>
                              <div className='modal-header'>
                                <h5>Share Mangrove Realty’s Page</h5>
                                <button
                                  type='button'
                                  className='btn-close'
                                  data-bs-dismiss='modal'
                                ></button>
                              </div>
                              <div className='modal-body'>
                                <div className='social_icon_btns'>
                                  <a href={null}>
                                    <img
                                      src={process.env.PUBLIC_URL + '/img/facebook-btn.png'}
                                      alt=' '
                                    />
                                  </a>
                                  <a href={null}>
                                    <img
                                      src={process.env.PUBLIC_URL + '/img/twitter-btn.png'}
                                      alt=' '
                                    />
                                  </a>
                                </div>
                                <div className='share_modal_form'>
                                  <form className=''>
                                    <div className='form-group'>
                                      <input
                                        type='text'
                                        placeholder='https://www.itrustit.com/mangroverealty'
                                      />
                                    </div>
                                    <div className='or'>
                                      <p> OR</p>
                                    </div>

                                    <div className='form-group'>
                                      <label>Your name</label>
                                      <input type='text' />
                                    </div>
                                    <div className='form-group'>
                                      <label>Your email</label>
                                      <input type='text' />
                                    </div>
                                    <div className='form-group'>
                                      <label>
                                        To
                                        <span className='inner_grey'>
                                          Email addresses (separate with a comma)
                                        </span>
                                      </label>
                                      <input type='text' />
                                    </div>
                                    <div className='form-group'>
                                      <input
                                        type='submit'
                                        value='share'
                                        className='share_submit_btn'
                                      />
                                    </div>
                                  </form>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* -------------------------------------------share_modal modal over------------------------------------------- */}
                      </li>
                      <li>
                        <a href={null}>
                          <i>
                            <img src={process.env.PUBLIC_URL + '/img/bookmark_icon.png'} alt='' />
                          </i>
                          <span>Bookmark</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className='listing_block'>
            <div className='container'>
              <div id='overview' className='tab-pane active'>
                <div className='row'>
                  <div className='col-md-8 overview-right'>
                    <div className='page_info'>
                      <h3 className='varela'>About Us</h3>
                      <p className='varela f_14'>
                        Fusce eget quam urna. Aliquam at lobortis velit. Pellentesque habitant morbi
                        tristique senectus et netus et malesuada fames ac turpis egestas. Sed non
                        rutrum purus. Aliquam at mollis neque. Nunc dapibus venenatis est id
                        tincidunt. Curabitur consectetur rutrum vehicula. Morbi vitae nibh libero.
                        Phasellus malesuada dui massa, vel ornare nulla congue cursus. Proin eget
                        metus vestibulum lacus cursus vehicula. Etiam posuere augue risus, et varius
                        enim aliquet id. Interdum et malesuada fames ac ante ipsum primis in
                        faucibus. Nunc congue, orci nec ultrices rutrum, urna enim molestie sem, a
                        porta nunc lectus eu quam. Vestibulum a est id orci finibus maximus. Aliquam
                        suscipit leo quis elit bibendum maximus. Nam quis est dapibus, rhoncus nulla
                        eget, mattis dui.
                      </p>
                    </div>
                    <div className='page_info'>
                      <h3 className='varela'>Gallery</h3>
                      <p></p>
                    </div>
                    <div className='page_info'>
                      <h3 className='varela'>
                        Reviews
                        <a href={null} className='blue'>
                          {' '}
                          &nbsp;178
                        </a>
                      </h3>
                      <div className='review_blk'>
                        <div className='img_blk'></div>
                        <div className='name_blk'>
                          <h5 className='varela'>Jacob Holmes</h5>
                          <ul>
                            <li className='friends_review'>100 friends</li>
                            <li className='star_review'>25 reviews</li>
                            <li className='trust_review'>180 iTRUST</li>
                          </ul>
                        </div>
                        <div className='comment_blk'>
                          <div className='right_link'>
                            <div className='star_ratings'>
                              <img src={process.env.PUBLIC_URL + '/img/star_yellow.png'} alt=' ' />
                              <img src={process.env.PUBLIC_URL + '/img/star_yellow.png'} alt=' ' />
                              <img src={process.env.PUBLIC_URL + '/img/star_yellow.png'} alt=' ' />
                              <img src={process.env.PUBLIC_URL + '/img/star_yellow.png'} alt=' ' />
                              <img src={process.env.PUBLIC_URL + '/img/star_yellow.png'} alt=' ' />
                            </div>
                            <div className='review_digit varela'>
                              <a href={null}>November 6, 2015</a>
                            </div>
                          </div>
                          <p>
                            Mauris non urna eu nisi vulputate fringilla sit amet vel velit. Ut
                            tempus id magna nec finibus. Suspendisse eget lorem ut nunc congue
                            ultrices. Nullam quis porttitor massa, sit amet gravida
                          </p>
                        </div>
                      </div>
                      <div className='review_blk'>
                        <div className='img_blk'></div>
                        <div className='name_blk'>
                          <h5 className='varela'>Jacob Holmes</h5>
                          <ul>
                            <li className='friends_review'>100 friends</li>
                            <li className='star_review'>25 reviews</li>
                            <li className='trust_review'>180 iTRUST</li>
                          </ul>
                        </div>
                        <div className='comment_blk'>
                          <div className='right_link'>
                            <div className='star_ratings'>
                              <img src={process.env.PUBLIC_URL + '/img/star_yellow.png'} alt=' ' />
                              <img src={process.env.PUBLIC_URL + '/img/star_yellow.png'} alt=' ' />
                              <img src={process.env.PUBLIC_URL + '/img/star_yellow.png'} alt=' ' />
                              <img src={process.env.PUBLIC_URL + '/img/star_yellow.png'} alt=' ' />
                              <img src={process.env.PUBLIC_URL + '/img/star_yellow.png'} alt=' ' />
                            </div>
                            <div className='review_digit varela'>
                              <a href={null}>November 6, 2015</a>
                            </div>
                          </div>
                          <p>
                            Mauris non urna eu nisi vulputate fringilla sit amet vel velit. Ut
                            tempus id magna nec finibus. Suspendisse eget lorem ut nunc congue
                            ultrices. Nullam quis porttitor massa, sit amet gravida
                          </p>
                        </div>
                      </div>
                      <div className='review_blk'>
                        <div className='img_blk'></div>
                        <div className='name_blk'>
                          <h5 className='varela'>Jacob Holmes</h5>
                          <ul>
                            <li className='friends_review'>100 friends</li>
                            <li className='star_review'>25 reviews</li>
                            <li className='trust_review'>180 iTRUST</li>
                          </ul>
                        </div>
                        <div className='comment_blk'>
                          <div className='right_link'>
                            <div className='star_ratings'>
                              <img src={process.env.PUBLIC_URL + '/img/star_yellow.png'} alt=' ' />
                              <img src={process.env.PUBLIC_URL + '/img/star_yellow.png'} alt=' ' />
                              <img src={process.env.PUBLIC_URL + '/img/star_yellow.png'} alt=' ' />
                              <img src={process.env.PUBLIC_URL + '/img/star_yellow.png'} alt=' ' />
                              <img src={process.env.PUBLIC_URL + '/img/star_yellow.png'} alt=' ' />
                            </div>
                            <div className='review_digit varela'>
                              <a href={null}>November 6, 2015</a>
                            </div>
                          </div>
                          <p>
                            Mauris non urna eu nisi vulputate fringilla sit amet vel velit. Ut
                            tempus id magna nec finibus. Suspendisse eget lorem ut nunc congue
                            ultrices. Nullam quis porttitor massa, sit amet gravida
                          </p>
                        </div>
                      </div>

                      <a href={null} className='blue reviewlink varela'>
                        {' '}
                        Read all reviews{' '}
                      </a>
                      <a href={null} className='claim_link'>
                        Write Review{' '}
                      </a>
                    </div>
                  </div>{' '}
                  {/* overview left over */}
                  <div className='col-md-4 overview-left'>
                    <div className='map'>
                      <div className='map_blk'>
                        <h4 className='varela'>Map</h4>
                        <div id='googlemap'></div>
                      </div>
                    </div>

                    <div className='bg-white curvey mb-4 p-15'>
                      <h4 className='varela'>
                        {' '}
                        Friends
                        <span className='purple'>248</span>
                      </h4>
                      <div className='friends_green mt-2'>
                        <div className='d-flex headers justify-content-between green'>
                          <h6>People - 357</h6>
                          <h6>44 mutual</h6>
                        </div>

                        <div className='row profiles'>
                          <div className='col-md-6'>
                            <h6 className='varela'>Eddy Jones</h6>
                            <div className='frnd_box'>
                              <div className='img_block green_border'></div>
                              <div className=''>
                                <div className='mb-2'>
                                  <i>
                                    <img
                                      src={process.env.PUBLIC_URL + '/img/purple_tag.png'}
                                      className='me-2'
                                      alt=' '
                                    />
                                  </i>
                                  142
                                </div>
                                <div className='mb-2'>
                                  <i>
                                    <img
                                      src={process.env.PUBLIC_URL + '/img/frandss.png'}
                                      className='me-2'
                                      alt=' '
                                    />
                                  </i>
                                  149
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='col-md-6'>
                            <h6 className='varela'>Eddy Jones </h6>
                            <div className='frnd_box'>
                              <div className='img_block green_border'></div>
                              <div className=''>
                                <div className='mb-2'>
                                  <i>
                                    <img
                                      src={process.env.PUBLIC_URL + '/img/purple_tag.png'}
                                      className='me-2'
                                      alt=' '
                                    />
                                  </i>
                                  142
                                </div>
                                <div className='mb-2'>
                                  <i>
                                    <img
                                      src={process.env.PUBLIC_URL + '/img/frandss.png'}
                                      className='me-2'
                                      alt=' '
                                    />
                                  </i>
                                  149
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='col-md-6'>
                            <h6 className='varela'>Eddy Jones </h6>
                            <div className='frnd_box'>
                              <div className='img_block orange_border'></div>
                              <div className=''>
                                <div className='mb-2'>
                                  <i>
                                    <img
                                      src={process.env.PUBLIC_URL + '/img/purple_tag.png'}
                                      className='me-2'
                                      alt=' '
                                    />
                                  </i>
                                  142
                                </div>
                                <div className='mb-2'>
                                  <i>
                                    <img
                                      src={process.env.PUBLIC_URL + '/img/frandss.png'}
                                      className='me-2'
                                      alt=' '
                                    />
                                  </i>
                                  149
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='col-md-6'>
                            <h6 className='varela'>Eddy Jones </h6>
                            <div className='frnd_box'>
                              <div className='img_block orange_border'></div>
                              <div className=''>
                                <div className='mb-2'>
                                  <i>
                                    <img
                                      src={process.env.PUBLIC_URL + '/img/purple_tag.png'}
                                      className='me-2'
                                      alt=' '
                                    />
                                  </i>
                                  142
                                </div>
                                <div className='mb-2'>
                                  <i>
                                    <img
                                      src={process.env.PUBLIC_URL + '/img/frandss.png'}
                                      className='me-2'
                                      alt=' '
                                    />
                                  </i>
                                  149
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* friends green over */}

                      <div className='business_blue mt-4'>
                        <div className='d-flex headers justify-content-between'>
                          <h6>business - 37</h6>
                          <h6>24 mutual</h6>
                        </div>

                        <div className='row profiles'>
                          <div className='col-md-6'>
                            <h6 className='varela'>Eddy Jones</h6>
                            <div className='frnd_box'>
                              <div className='img_block   blue_border'></div>
                              <div className=''>
                                <div className='mb-2'>
                                  <i>
                                    <img
                                      src={process.env.PUBLIC_URL + '/img/orange_tag.png'}
                                      className='me-2'
                                      alt=' '
                                    />
                                  </i>
                                  142
                                </div>
                                <div className='mb-2'>
                                  <i>
                                    <img
                                      src={process.env.PUBLIC_URL + '/img/frandss.png'}
                                      className='me-2'
                                      alt=' '
                                    />
                                  </i>
                                  149
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='col-md-6'>
                            <h6 className='varela'>Eddy Jones</h6>
                            <div className='frnd_box'>
                              <div className='img_block   blue_border'></div>
                              <div className=''>
                                <div className='mb-2'>
                                  <i>
                                    <img
                                      src={process.env.PUBLIC_URL + '/img/orange_tag.png'}
                                      className='me-2'
                                      alt=' '
                                    />
                                  </i>
                                  142
                                </div>
                                <div className='mb-2'>
                                  <i>
                                    <img
                                      src={process.env.PUBLIC_URL + '/img/frandss.png'}
                                      className='me-2'
                                      alt=' '
                                    />
                                  </i>
                                  149
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='col-md-6'>
                            <h6 className='varela'>Eddy Jones</h6>
                            <div className='frnd_box'>
                              <div className='img_block   blue_border'></div>
                              <div className=''>
                                <div className='mb-2'>
                                  <i>
                                    <img
                                      src={process.env.PUBLIC_URL + '/img/orange_tag.png'}
                                      className='me-2'
                                      alt=' '
                                    />
                                  </i>
                                  142
                                </div>
                                <div className='mb-2'>
                                  <i>
                                    <img
                                      src={process.env.PUBLIC_URL + '/img/frandss.png'}
                                      className='me-2'
                                      alt=' '
                                    />
                                  </i>
                                  149
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='col-md-6'>
                            <h6 className='varela'>Eddy Jones</h6>
                            <div className='frnd_box'>
                              <div className='img_block  blue_border'></div>
                              <div className=''>
                                <div className='mb-2'>
                                  <i>
                                    <img
                                      src={process.env.PUBLIC_URL + '/img/orange_tag.png'}
                                      className='me-2'
                                      alt=' '
                                    />
                                  </i>
                                  142
                                </div>
                                <div className='mb-2'>
                                  <i>
                                    <img
                                      src={process.env.PUBLIC_URL + '/img/frandss.png'}
                                      className='me-2'
                                      alt=' '
                                    />
                                  </i>
                                  149
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* business blue over */}
                      <div className='organization_orange mt-4'>
                        <div className='d-flex headers justify-content-between'>
                          <h6>ORGANIZATIONS - 89</h6>
                          <h6>12 mutual</h6>
                        </div>

                        <div className='row profiles'>
                          <div className='col-md-6'>
                            <h6 className='varela'>Eddy Jones</h6>
                            <div className='frnd_box'>
                              <div className='img_block orange_border'></div>
                              <div className=''>
                                <div className='mb-2'>
                                  <i>
                                    <img
                                      src={process.env.PUBLIC_URL + '/img/orange_tag.png'}
                                      className='me-2'
                                      alt=' '
                                    />
                                  </i>
                                  142
                                </div>
                                <div className='mb-2'>
                                  <i>
                                    <img
                                      src={process.env.PUBLIC_URL + '/img/frandss.png'}
                                      className='me-2'
                                      alt=' '
                                    />
                                  </i>
                                  149
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='col-md-6'>
                            <h6 className='varela'>Eddy Jones</h6>
                            <div className='frnd_box'>
                              <div className='img_block orange_border'></div>
                              <div className=''>
                                <div className='mb-2'>
                                  <i>
                                    <img
                                      src={process.env.PUBLIC_URL + '/img/orange_tag.png'}
                                      className='me-2'
                                      alt=' '
                                    />
                                  </i>
                                  142
                                </div>
                                <div className='mb-2'>
                                  <i>
                                    <img
                                      src={process.env.PUBLIC_URL + '/img/frandss.png'}
                                      className='me-2'
                                      alt=' '
                                    />
                                  </i>
                                  149
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* organization over */}
                    </div>
                    <div className='bg-white curvey mb-4 p-15'>
                      <div className='listing_blk'>
                        <h4 className='varela'>Is This Your Listing?</h4>
                        <p className='f_14'>
                          If you own or manage this business claim your listing for free to respond
                          to reviews, update your profile and much more.
                        </p>
                        <a href={null} className='claim_link'>
                          Claim Your Listing
                        </a>
                      </div>
                    </div>
                    <div className='bg-white curvey mb-4 p-15'>
                      <img
                        src={process.env.PUBLIC_URL + '/img/image.png'}
                        className='img-fluid'
                        alt=' '
                      />
                    </div>
                    <div className='bg-white curvey mb-4 p-15'>
                      <h4 className='varela'>Related Listings</h4>
                      <div className='listing_blk'>
                        <div className='row proitems'>
                          <div className='col-md-4'>
                            <div className='img_block'></div>
                          </div>
                          <div className='col-md-8'>
                            <div className='detail_blk'>
                              <h5 className='varela'>Capizzi </h5>
                              <div className='right_link'>
                                <div className='star_ratings'>
                                  <img
                                    src={process.env.PUBLIC_URL + '/img/star_yellow.png'}
                                    alt=' '
                                  />
                                  <img
                                    src={process.env.PUBLIC_URL + '/img/star_yellow.png'}
                                    alt=' '
                                  />
                                  <img
                                    src={process.env.PUBLIC_URL + '/img/star_yellow.png'}
                                    alt=' '
                                  />
                                  <img
                                    src={process.env.PUBLIC_URL + '/img/star_yellow.png'}
                                    alt=' '
                                  />
                                  <img
                                    src={process.env.PUBLIC_URL + '/img/star_yellow.png'}
                                    alt=' '
                                  />
                                </div>
                                <div className='review_digit varela'>
                                  <a href={null}> 178 Reviews</a>
                                </div>
                              </div>
                              <p>
                                317 West 14th Street
                                <br /> New York City, NY 1001
                              </p>
                            </div>
                          </div>
                        </div>

                        <div className='row proitems'>
                          <div className='col-md-4'>
                            <div className='img_block'></div>
                          </div>
                          <div className='col-md-8'>
                            <div className='detail_blk'>
                              <h5 className='varela'>Morningside Park </h5>
                              <div className='right_link'>
                                <div className='star_ratings'>
                                  <img
                                    src={process.env.PUBLIC_URL + '/img/star_yellow.png'}
                                    alt=' '
                                  />
                                  <img
                                    src={process.env.PUBLIC_URL + '/img/star_yellow.png'}
                                    alt=' '
                                  />
                                  <img
                                    src={process.env.PUBLIC_URL + '/img/star_yellow.png'}
                                    alt=' '
                                  />
                                  <img
                                    src={process.env.PUBLIC_URL + '/img/star_yellow.png'}
                                    alt=' '
                                  />
                                  <img
                                    src={process.env.PUBLIC_URL + '/img/star_yellow.png'}
                                    alt=' '
                                  />
                                </div>
                                <div className='review_digit varela'>
                                  <a href={null}> 178 Reviews</a>
                                </div>
                              </div>
                              <p>
                                317 West 14th Street
                                <br /> New York City, NY 1001
                              </p>
                            </div>
                          </div>
                        </div>

                        <div className='row proitems'>
                          <div className='col-md-4'>
                            <div className='img_block'></div>
                          </div>
                          <div className='col-md-8'>
                            <div className='detail_blk'>
                              <h5 className='varela'>Capizzi </h5>
                              <div className='right_link'>
                                <div className='star_ratings'>
                                  <img
                                    src={process.env.PUBLIC_URL + '/img/star_yellow.png'}
                                    alt=' '
                                  />
                                  <img
                                    src={process.env.PUBLIC_URL + '/img/star_yellow.png'}
                                    alt=' '
                                  />
                                  <img
                                    src={process.env.PUBLIC_URL + '/img/star_yellow.png'}
                                    alt=' '
                                  />
                                  <img
                                    src={process.env.PUBLIC_URL + '/img/star_yellow.png'}
                                    alt=' '
                                  />
                                  <img
                                    src={process.env.PUBLIC_URL + '/img/star_yellow.png'}
                                    alt=' '
                                  />
                                </div>
                                <div className='review_digit varela'>
                                  <a href={null}> 178 Reviews</a>
                                </div>
                              </div>
                              <p>
                                317 West 14th Street
                                <br /> New York City, NY 1001
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* overview right over */}
                </div>
              </div>
            </div>
          </section>

          {/* <section className="footer_top_blk">
            <div className="container">
                <div className="footer_container">
                    <p className="varela">Not here? Tell us what we're missing. If the business you're looking for isn't here, add it!</p>
                    <a href={null} className="blue_btn">ADD BUSINESS</a>
                </div>
            </div>
        </section> */}
        </section>
      </AppLayout>
    )
  }
}
export default ReviewSuccess
