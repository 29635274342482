/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component, Fragment } from 'react'
import './UserListDetail.scss'
import { Link } from 'react-router-dom'

import PropTypes from 'prop-types'

// import ReactStars from 'react-stars'
// import StarRatingComponent from 'react-star-rating-component';
import StarRatings from 'react-star-ratings'

import $J from 'jquery'
import { DEFAULT_PROFILE_PICTURE } from '../../../services/const'
import { makeTrustCall, removeTrustCall } from '../../../services/trustApiService'
import { friendCreateCall, friendChangeStatusCall } from '../../../services/friendApiService'
import { removeMemberCall } from '../../../services/memberApiService'
// import ConfirmUntrustPopupModal from '../ConfirmUntrustModal/ConfirmUntrustPopupModal';
// import ConfirmUnfriendPopupModal from '../ConfirmUnfriendModal/ConfirmUnfriendPopupModal';
import {
  STAR_RATING_iTRUST_ACTIVE_COLOR,
  STAR_RATING_iTRUST_INACTIVE_COLOR,
  STAR_RATING_TRUST_ME_ACTIVE_COLOR,
  STAR_RATING_TRUST_ME_INACTIVE_COLOR,
  NUMBER_OF_RATING_STAR,
  RATING_STAR_SPACING,
  STAR_RATING_REVIEW_SECTION_SIZE_PX,
} from '../../../services/const'
import * as route from '../../../services/routeConst'
import * as customFunction from '../../../services/customFunction'
import { confirmAlert } from 'react-confirm-alert' // Import

import { formatMembersName, toTitleCase } from '../../../services/formatService'
import ConfirmRemoveFromOrgPopupModal from '../../ProfileOtherUser/ConfirmRemoveFromOrgModal/ConfirmRemoveFromOrgPopupModal'
import InvitePopupModal from '../../ProfileOtherUser/InvitePopupModal/InvitePopupModal'
import SignupOrLoginText from '../SignupOrLoginText/SignupOrLoginText'
// import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
class UserListDetail extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isDisplayMemberOrItrustBtn:
        this.props.isDisplayMemberOrItrustBtn !== undefined
          ? this.props.isDisplayMemberOrItrustBtn
          : this.props.login_user_id
          ? this.props.login_user_id !== this.props.user.user_id
          : true,
      currentUserId: customFunction?.getUser() ? customFunction?.getUser().id : '',
      currentUser: customFunction?.getUser(),
      type: '',
      slug: '',
      total_friends_of_friend_trusted: -1,
      loadingTFOFData: false,
      membersName: props?.membersName ?? 'Members',
    }
  }

  setLoadingTFOFData = (value = false) => {
    // console.log(value);
    this.setState({ loadingTFOFData: value })
  }

  updateFofFVal = (value = -1) => {
    this.setLoadingTFOFData(false)
    this.setState({ total_friends_of_friend_trusted: value })
  }

  componentDidMount() {}

  confirmItrustitAddRemove = (needsConfirmation = false) => {
    const data = {
      is_trusted_user: this.props.user.is_trusted_user,
      id: this.props.user.id,
      index: this.props.index,
      username:
        this.props.user.user_type === 'individual'
          ? this.props.user.firstname + ' ' + this.props.user.lastname ?? ''
          : this.props.user.business_organization_name,
    }
    needsConfirmation
      ? this.props.confirmitrustitAddRemove(needsConfirmation, data, this.props.tab)
      : this.itrustitAddRemove(data)
  }

  itrustitAddRemove = (data) => {
    const self = this
    // this.props.setLoadingitrustitStatusChange(true);
    // console.log(data);
    let itrustitAddRemoveReqData = {
      id: data.id,
    }
    if (data.is_trusted_user === 0) {
      makeTrustCall(itrustitAddRemoveReqData).then(function (response) {
        self?.props?.itrustitHandle?.(data)
        // self.props.setLoadingitrustitStatusChange(false);
      })
    } else if (data.is_trusted_user === 1) {
      removeTrustCall(itrustitAddRemoveReqData).then(function (response) {
        self?.props?.itrustitHandle?.(data)
        // self.props.setLoadingitrustitStatusChange(false);
      })
    }
  }

  confirmFriendAddRemove = (needsConfirmation = false) => {
    const data = {
      is_friend_user: this.props.user.is_friend_user,
      user_id: this.props.user.id,
      friendship_id: this.props.user.friendship_id,
      index: this.props.index,
      username:
        this.props.user.user_type === 'individual'
          ? this.props.user.firstname + ' ' + this.props.user.lastname ?? ''
          : this.props.user.business_organization_name,
    }
    needsConfirmation
      ? this.props.confirmFriendAddRemove(needsConfirmation, data, this.props.tab)
      : this.friendAddRemove(data)
  }

  friendAddRemove = (data) => {
    const self = this

    if (data.is_friend_user === 0) {
      let friendCreateReqData = {
        user_id: data.user_id,
      }
      friendCreateCall(friendCreateReqData).then(function (response) {
        if (typeof response.status === 'undefined' || response.status === 200) {
          if (typeof self.props.friendAddRemoveHandle === 'function') {
            // console.log(data);
            self.props.friendAddRemoveHandle({
              ...data,
              id: data.user_id,
              friendship_id: response.data.friendship_id || null,
            })
          }
        }
      })
    } else if (
      data.is_friend_user === 1 ||
      (this.props.user.friendship_id && this.props.user.friendship_id > 0)
    ) {
      let friendChangeStatusReqData = {
        friendship_id: data.friendship_id,
        status: 'unfriend',
      }
      friendChangeStatusCall(friendChangeStatusReqData).then(function (response) {
        // console.log(data);
        if (typeof response.status === 'undefined' || response.status === 200) {
          if (typeof self.props.friendAddRemoveHandle === 'function') {
            self.props.friendAddRemoveHandle({ ...data, id: data.user_id })
          }
        }
      })
    }
  }

  onClickTrustedBy = (slug, type) => {
    if (this.state.currentUserId) {
      this.props.history.push({
        pathname: route.ROOT_ROUTE + slug,
        state: { slug: slug, type: type },
      })
    }
  }

  memberAddRemove = (data) => (event) => {
    // const self = this;
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui'>
            <p>
              Do you want to remove this {formatMembersName(this.state.membersName, null, false)}{' '}
              from your organization?
            </p>
            <button className='btn-no' onClick={onClose}>
              No
            </button>
            <button
              className='btn-yes'
              onClick={() => {
                this.memberAddRemoveHandle(data)
                onClose()
              }}
            >
              Yes
            </button>
          </div>
        )
      },
    })
  }

  memberAddRemoveHandle = (data) => {
    const self = this
    let memberAddRemoveReqData = {
      id: data.id,
      member_type: 'member_of_your_organization',
    }

    removeMemberCall(memberAddRemoveReqData).then(function (response) {
      if (typeof response.status !== 'undefined' && response.status === 200) {
        // let apiResponseData = response.data;
        if (typeof self.props.memberAddRemoveHandle === 'function') {
          self.props.memberAddRemoveHandle(data)
        }
      }
    })
  }

  resetSearchBoxValues = (data) => (event) => {
    localStorage.removeItem('businessOrganizationNameSearch')
    //localStorage.removeItem("citySearch");
  }

  handleClickOnCategoryItem = (data) => (event) => {
    let categoryname = data.name.replace('>>', '')
    localStorage.setItem('businessOrganizationNameSearch', categoryname)
    this.setState({ businessOrganizationNameSearch: categoryname })

    this.props.headerSearchBarValue?.({
      businessOrganizationNameSearch: categoryname,
      isSearch: true,
    })
  }

  confirmMemberAddRemoveYour = (needsConfirmation) => {
    const modalProps = {
      confirm: {
        Orgname: this.props.user.fullname,
        user_id: this.props.user.id,
        memberType: 'member_of_your_organization',
        membersName:
          formatMembersName(
            this.state.currentUser?.user_settings?.members_name,
            this.state.currentUser?.user_settings?.custom_members_name,
          ) ?? 'Members',
        loadingMemberChangeResponse: false,
        setLoadingMemberChangeResponse: () => {},
        dataYour: {
          is_your_member_user: this.props.user?.is_your_member_user,
          id: this.props.user.id,
          flag: 'member_of_your_organization',
        },
        memberAddRemoveYour: this.memberAddRemoveYour,
        setStatusOfMemberUserYour: (data) => {
          this.props.setStatusOfMemberUserYour(this.props.user.id, data)
        },
      },
      invite: {
        Orgname: this.props.user.fullname,
        loadingMemberChangeResponse: false,
        setLoadingMemberChangeResponse: () => {},
        user_id: this.props.user.id,
        memberType: 'member_of_your_organization',
        membersName:
          formatMembersName(
            this.state.currentUser?.user_settings?.members_name,
            this.state.currentUser?.user_settings?.custom_members_name,
          ) ?? 'Members',
        setStatusOfMemberUserYour: (data) => {
          this.props.setStatusOfMemberUserYour(this.props.user.id, data)
        },
      },
    }
    this.props.setModalProps?.(modalProps, needsConfirmation, () =>
      this.props.setStatusOfMemberUserYour(this.props.user.id),
    )
  }

  memberAddRemoveYour = async (data) => {
    let memberAddRemoveReqData = {
      id: data.id,
      member_type: data.flag,
    }
    if (this.props.user.is_your_member_user !== 0) {
      const res = await removeMemberCall(memberAddRemoveReqData)
      if (res?.status === 200) {
        this.props.setStatusOfMemberUserYour(this.props.user.id)
        // let apiResponseData = response.data
        // self.setState({ is_your_member_user: 0 })
      }
    } else if (this.props.user.is_your_member_user !== 2) {
      $J('#invite_modal').modal('show')
    }
  }

  render() {
    let viewType = typeof this.props.viewType !== 'undefined' ? this.props.viewType : 'full_view'
    // console.log(this.props.index);
    // console.log(this.props.user);
    // console.log("this.state.isDisplayMemberOrItrustBtn", this.state.isDisplayMemberOrItrustBtn);

    const { user } = this.props
    // console.log(user);
    let city_name = 'N/A'
    if (this.props.user.city_name) {
      city_name = this.props.user.city_name
    }

    let categoriesSection = 'N/A'
    const loginModalProps = !this.state.currentUserId
      ? {
          'data-bs-toggle': 'modal',
          'data-bs-target': '#login_modal',
        }
      : {}

    if (
      this.props.user.user_type !== 'individual' &&
      typeof this.props.user.categories !== 'undefined' &&
      this.props.user.categories.length > 0
    ) {
      categoriesSection = (
        <Fragment>
          <Link
            className='category-name-item'
            to={{
              pathname: route.SEARCH_ROUTE,
              state: {
                category_id: this.props.user.categories[0].search_id,
                category_name: this.props.user.categories[0].category_name,
              },
            }}
            /*onClick={this.resetSearchBoxValues(this.props.user.categories[0].id)}*/
            onClick={this.handleClickOnCategoryItem({
              id: this.props.user.categories[0].id,
              name: this.props.user.categories[0].category_name,
            })}
          >
            {this.props.user.categories[0].category_name}
          </Link>

          {this.props.user.categories.length > 1 && (
            <Fragment>
              <span>...</span> and
              <div className='dropdown show it-more-link'>
                <a href=''> {this.props.user.categories.length - 1} more</a>
                <div className='dropdown-menu show' aria-labelledby='dropdownMenuButton'>
                  {this.props.user.categories.map(
                    (category, key) =>
                      key !== 0 && (
                        <Link
                          key={key}
                          className='dropdown-item'
                          to={{
                            pathname: route.SEARCH_ROUTE,
                            state: {
                              category_id: category.search_id,
                              category_name: category.category_name,
                            },
                          }}
                          onClick={this.resetSearchBoxValues(category.id)}
                        >
                          {category.category_name}
                        </Link>
                      ),
                  )}
                </div>
              </div>
            </Fragment>
          )}
        </Fragment>
      )
    } else if (
      this.props.user.user_type !== 'individual' &&
      typeof this.props.user.categories !== 'undefined' &&
      this.props.user.category_name &&
      this.props.user.category_name !== ''
    ) {
      categoriesSection = <span>{toTitleCase(this.props.user.category_name, true)}</span>
    }

    const addRemoveOrgBtn =
      this.props.user.id !== this.state.currentUserId &&
      this.state.currentUser?.user_type === 'organization' &&
      this.state.isDisplayMemberOrItrustBtn ? (
        <button
          style={{ background: 'none', border: 'none' }}
          className='me-auto'
          //Add onClick trigger to handle adding/removing/accepting members
          onClick={() =>
            this.confirmMemberAddRemoveYour(this.props.user?.is_your_member_user !== 0)
          }
        >
          <i>
            {this.props.user.is_your_member_user !== 2 ? (
              <img
                src={`${process.env.PUBLIC_URL}/img/${
                  this.props.user.is_your_member_user === 1 ? 'check' : 'plus'
                }-solid.svg`}
                width={15}
                style={{ fill: '#777677', marginRight: 2 }}
                alt=' '
              />
            ) : null}
            <img
              src={`${process.env.PUBLIC_URL}/img/member${
                this.props.user.is_your_member_user === 2 ? '-p' : ''
              }.svg`}
              width={this.props.user.is_your_member_user === 2 ? 38 : 25}
              alt=' '
            />
          </i>{' '}
        </button>
      ) : null

    let friendTrustMemberSection = null
    // console.log(this.props.user.user_type === 'individual', this.state.isDisplayMemberOrItrustBtn);
    if (this.props.user.user_type === 'individual' && this.state.isDisplayMemberOrItrustBtn) {
      // console.log("withlogin1");

      friendTrustMemberSection = [
        <div key='trust-div-key-3'>
          <a
            href={null}
            key='friend-key-1'
            onClick={() =>
              this.confirmFriendAddRemove(
                this.props.user.is_friend_user === 1 ||
                  (this.props.user.friendship_id && this.props.user.friendship_id > 0),
              )
            }
            style={
              this.props.loadingFriendStatusChange
                ? {
                    backgroundImage: `url('${process.env.PUBLIC_URL + '/img/loading.svg'}')`,
                    backgroundSize: 30,
                    backgroundPosition: 'center left -2px',
                  }
                : {}
            }
            className={
              'green' +
              (((this.props.user.friendship_id && this.props.user.friendship_id > 0) ||
                this.props.user.is_friend_user === 1) &&
              this.props.user.is_friend_user !== 2
                ? ' green-fill'
                : '') +
              (this.props.user.is_friend_user === 2 ? ' green-pending' : '')
            }
          >
            {' '}
            Friend
          </a>
          {viewType === 'full_view' ? addRemoveOrgBtn : null}
        </div>,
        this.props.loginUserId === this.state.currentUserId ? (
          <div key='member-div-key-1' className='d-flex'>
            <a
              href={null}
              key='member-key-1'
              onClick={this.memberAddRemove({
                id: this.props.user.id,
              })}
              className='green green-fill it-delet-member'
              title={` Remove ${formatMembersName(this.state.membersName, null, false)}`}
            >
              {' '}
            </a>
          </div>
        ) : (
          ''
        ),
      ]
    }

    if (
      this.props.user.user_type === 'individual' &&
      this.state.isDisplayMemberOrItrustBtn === false
    ) {
      //  console.log("withoutlogin1");
      friendTrustMemberSection = [
        <div key='trust-div-key-6'>
          <a
            {...loginModalProps}
            href={null}
            key='friend-key-2'
            style={
              this.props.loadingFriendStatusChange
                ? {
                    backgroundImage: `url('${process.env.PUBLIC_URL + '/img/loading.svg'}')`,
                    backgroundSize: 30,
                    backgroundPosition: 'center left -2px',
                  }
                : {}
            }
            className={
              'green' +
              (((this.props.user.friendship_id && this.props.user.friendship_id > 0) ||
                this.props.user.is_friend_user === 1) &&
              this.props.user.is_friend_user !== 2
                ? ' green-fill'
                : '') +
              (this.props.user.is_friend_user === 2 ? ' green-pending' : '')
            }
          >
            {' '}
            Friend
          </a>
          {viewType === 'full_view' ? addRemoveOrgBtn : null}
        </div>,
        this.props.loginUserId === this.state.currentUserId ? (
          <div key='member-div-key-2' className='d-flex'>
            <a
              href={null}
              {...loginModalProps}
              key='member-key-2'
              onClick={this.memberAddRemove({
                id: this.props.user.id,
              })}
              className='green green-fill it-delet-member'
              title={` Remove ${formatMembersName(this.state.membersName, null, false)}`}
            >
              {' '}
            </a>
          </div>
        ) : (
          ''
        ),
      ]
    }
    if (this.props.user.user_type === 'business' && this.state.isDisplayMemberOrItrustBtn) {
      //  console.log("withlogin2");
      friendTrustMemberSection = [
        <div key='trust-div-key-2'>
          <a
            href={null}
            {...loginModalProps}
            key='friend-key-3'
            onClick={() =>
              this.state.currentUserId &&
              this.confirmFriendAddRemove(
                this.props.user.is_friend_user === 1 ||
                  (this.props.user.friendship_id && this.props.user.friendship_id > 0),
              )
            }
            style={
              this.props.loadingFriendStatusChange
                ? {
                    backgroundImage: `url('${process.env.PUBLIC_URL + '/img/loading.svg'}')`,
                    backgroundSize: 30,
                    backgroundPosition: 'center left -2px',
                  }
                : {}
            }
            className={
              'green' +
              (((this.props.user.friendship_id && this.props.user.friendship_id > 0) ||
                this.props.user.is_friend_user === 1) &&
              this.props.user.is_friend_user !== 2
                ? ' green-fill'
                : '') +
              (this.props.user.is_friend_user === 2 ? ' green-pending' : '')
            }
          >
            {' '}
            Friend
          </a>
          <a
            href={null}
            {...loginModalProps}
            key='trust-key-1'
            onClick={() =>
              this.state.currentUserId &&
              this.confirmItrustitAddRemove(this.props.user.is_trusted_user === 1)
            }
            style={
              this.props.loadingitrustitStatusChange
                ? {
                    backgroundImage: `url('${process.env.PUBLIC_URL + '/img/loading.svg'}')`,
                    backgroundSize: 30,
                    backgroundPosition: 'center left -2px',
                  }
                : {}
            }
            className={'orange ' + (this.props.user.is_trusted_user ? 'orange-fill' : '')}
          >
            iTRUSTit
          </a>
          {viewType === 'full_view' ? addRemoveOrgBtn : null}
        </div>,
        this.props.loginUserId === this.state.currentUserId ? (
          <div key='member-div-key-3' className='d-flex'>
            <a
              href={null}
              {...loginModalProps}
              key='member-key-3'
              onClick={this.memberAddRemove({
                id: this.props.user.id,
              })}
              className='green green-fill it-delet-member'
              title={` Remove ${formatMembersName(this.state.membersName, null, false)}`}
            >
              {' '}
            </a>
          </div>
        ) : (
          ''
        ),
      ]
    }
    if (
      this.props.user.user_type === 'business' &&
      this.state.isDisplayMemberOrItrustBtn === false
    ) {
      // console.log("withoutlogin2");
      friendTrustMemberSection = [
        <div key='trust-div-key-1'>
          <a
            {...loginModalProps}
            href={null}
            key='friend-key-4'
            style={
              this.props.loadingFriendStatusChange
                ? {
                    backgroundImage: `url('${process.env.PUBLIC_URL + '/img/loading.svg'}')`,
                    backgroundSize: 30,
                    backgroundPosition: 'center left -2px',
                  }
                : {}
            }
            className={
              'green' +
              (((this.props.user.friendship_id && this.props.user.friendship_id > 0) ||
                this.props.user.is_friend_user === 1) &&
              this.props.user.is_friend_user !== 2
                ? ' green-fill'
                : '') +
              (this.props.user.is_friend_user === 2 ? ' green-pending' : '')
            }
          >
            Friend
          </a>
          <a
            {...loginModalProps}
            href={null}
            key='trust-key-2'
            style={
              this.props.loadingitrustitStatusChange
                ? {
                    backgroundImage: `url('${process.env.PUBLIC_URL + '/img/loading.svg'}')`,
                    backgroundSize: 30,
                    backgroundPosition: 'center left -2px',
                  }
                : {}
            }
            className={'orange ' + (this.props.user.is_trusted_user ? 'orange-fill' : '')}
          >
            iTRUSTit
          </a>
          {viewType === 'full_view' ? addRemoveOrgBtn : null}
        </div>,
        this.props.loginUserId === this.state.currentUserId ? (
          <div key='member-div-key-4' className='d-flex'>
            <a
              href={null}
              {...loginModalProps}
              key='member-key-4'
              onClick={this.memberAddRemove({
                id: this.props.user.id,
              })}
              className='green green-fill it-delet-member'
              title={` Remove ${formatMembersName(this.state.membersName, null, false)}`}
            >
              {' '}
            </a>
          </div>
        ) : (
          ''
        ),
      ]
    }

    if (this.props.user.user_type === 'organization' && this.state.isDisplayMemberOrItrustBtn) {
      // console.log("withlogin3");
      friendTrustMemberSection = [
        <div key='trust-div-key-4'>
          <a
            {...loginModalProps}
            href={null}
            key='friend-key-5'
            style={
              this.props.loadingFriendStatusChange
                ? {
                    backgroundImage: `url('${process.env.PUBLIC_URL + '/img/loading.svg'}')`,
                    backgroundSize: 30,
                    backgroundPosition: 'center left -2px',
                  }
                : {}
            }
            onClick={() =>
              this.state.currentUserId &&
              this.confirmFriendAddRemove(
                this.props.user.is_friend_user === 1 ||
                  (this.props.user.friendship_id && this.props.user.friendship_id > 0),
              )
            }
            className={
              'green' +
              (((this.props.user.friendship_id && this.props.user.friendship_id > 0) ||
                this.props.user.is_friend_user === 1) &&
              this.props.user.is_friend_user !== 2
                ? ' green-fill'
                : '') +
              (this.props.user.is_friend_user === 2 ? ' green-pending' : '')
            }
          >
            Friend
          </a>
          <a
            href={null}
            {...loginModalProps}
            key='trust-key-3'
            onClick={() =>
              this.state.currentUserId &&
              this.confirmItrustitAddRemove(this.props.user.is_trusted_user === 1)
            }
            style={
              this.props.loadingitrustitStatusChange
                ? {
                    backgroundImage: `url('${process.env.PUBLIC_URL + '/img/loading.svg'}')`,
                    backgroundSize: 30,
                    backgroundPosition: 'center left -2px',
                  }
                : {}
            }
            className={'orange ' + (this.props.user.is_trusted_user ? 'orange-fill' : '')}
          >
            iTRUSTit
          </a>
          {viewType === 'full_view' ? addRemoveOrgBtn : null}
        </div>,
        this.props.loginUserId === this.state.currentUserId ? (
          <div key='member-div-key-5' className='d-flex'>
            <a
              href={null}
              {...loginModalProps}
              key='member-key-5'
              onClick={this.memberAddRemove({
                id: this.props.user.id,
              })}
              className='green green-fill it-delet-member'
              title={` Remove ${formatMembersName(this.state.membersName, null, false)}`}
            >
              {' '}
            </a>
          </div>
        ) : (
          ''
        ),
      ]
    }
    if (
      this.props.user.user_type === 'organization' &&
      this.state.isDisplayMemberOrItrustBtn === false
    ) {
      //  console.log("withoutlogin3");
      friendTrustMemberSection = [
        <div key='trust-div-key-5'>
          <a
            {...loginModalProps}
            href={null}
            key='friend-key-7'
            style={
              this.props.loadingFriendStatusChange
                ? {
                    backgroundImage: `url('${process.env.PUBLIC_URL + '/img/loading.svg'}')`,
                    backgroundSize: 30,
                    backgroundPosition: 'center left -2px',
                  }
                : {}
            }
            className={
              'green' +
              (((this.props.user.friendship_id && this.props.user.friendship_id > 0) ||
                this.props.user.is_friend_user === 1) &&
              this.props.user.is_friend_user !== 2
                ? ' green-fill'
                : '') +
              (this.props.user.is_friend_user === 2 ? ' green-pending' : '')
            }
          >
            Friend
          </a>
          <a
            {...loginModalProps}
            href={null}
            key='friend-key-6'
            style={
              this.props.loadingitrustitStatusChange
                ? {
                    backgroundImage: `url('${process.env.PUBLIC_URL + '/img/loading.svg'}')`,
                    backgroundSize: 30,
                    backgroundPosition: 'center left -2px',
                  }
                : {}
            }
            className={'orange ' + (this.props.user.is_trusted_user ? 'orange-fill' : '')}
          >
            iTRUSTit
          </a>
          {viewType === 'full_view' ? addRemoveOrgBtn : null}
        </div>,
        this.props.loginUserId === this.state.currentUserId ? (
          <div key='member-div-key-6' className='d-flex'>
            <a
              href={null}
              {...loginModalProps}
              key='member-key-6'
              onClick={this.memberAddRemove({
                id: this.props.user.id,
              })}
              className='green green-fill it-delet-member'
              title={` Remove ${formatMembersName(this.state.membersName, null, false)}`}
            >
              {' '}
            </a>
          </div>
        ) : (
          ''
        ),
      ]
    }

    //let chatSection = <a href={null} className="chatgrey"></a>;
    let chatSection = null
    if (this.state.isDisplayMemberOrItrustBtn)
      chatSection = (
        <Link className='chatgrey' to={process.env.PUBLIC_URL + '/message/' + this.props.user.id} />
      )
    else chatSection = <a className='chatgrey' {...loginModalProps} />

    let profileUrl = process.env.PUBLIC_URL + '/' + this.props.user.slug

    if (
      typeof this.props.login_user_id !== 'undefined' &&
      this.props.login_user_id === this.props.user.id
    ) {
      friendTrustMemberSection = null
      chatSection = null
      profileUrl = process.env.PUBLIC_URL + '/profile'
    }

    let badgeTrustSection = null

    let mutualOrgsSection =
      this.props.user.total_common_members > 0 || !this.props.login_user_id ? (
        <div className='d-flex justify-content-center mt-3'>
          <div className='it-person-list-new it-person-list-main'>
            <p
              className='d-md-none d-block mutual-orgs-detail'
              style={{
                color: '#666666',
                fontSize: 14,
                fontFamily: 'Poppins',
                fontWeight: 500,
                marginBottom: 0,
              }}
            >
              Mutual Orgs
            </p>
            <p
              className='d-none d-md-block mutual-orgs-detail'
              style={{
                color: '#666666',
                fontSize: 14,
                fontFamily: 'Poppins',
                fontWeight: 500,
                marginBottom: 0,
              }}
            >
              Mutual Organizations
            </p>
            <div className='it-person mt-1 p-1'>
              {this.props.login_user_id ? (
                this.props.user?.total_common_members_list?.map(
                  (obj, index) =>
                    index < 3 && (
                      <div
                        onClick={(e) =>
                          this.props.onHoverTrustBy(
                            this.props.user.id,
                            'common_member',
                            this.props.user.user_type,
                            this.props.user.total_common_members,
                          )
                        }
                        key={obj.id}
                        className='it-person-img'
                      >
                        <img
                          className='it-person-img-avatar'
                          src={
                            obj.profile_picture_medium !== null
                              ? obj.profile_picture_medium
                              : DEFAULT_PROFILE_PICTURE
                          }
                          width='50'
                        />
                      </div>
                    ),
                )
              ) : (
                <div
                  className='digit pb-2'
                  onClick={(e) =>
                    this.props.onHoverTrustBy(
                      this.props.user.id,
                      'friends',
                      this.props.user.user_type,
                      this.props.user.total_common_members,
                    )
                  }
                >
                  <a
                    {...loginModalProps}
                    href={null}
                    className='digit d-none d-md-block'
                    style={{
                      color: this.props.user.user_type === 'individual' ? '#7e57c2' : '#fbb56c',
                    }}
                  >
                    Login or Sign up to see
                  </a>
                  <a
                    {...loginModalProps}
                    href={null}
                    className='digit d-block d-md-none'
                    style={{
                      color: this.props.user.user_type === 'individual' ? '#7e57c2' : '#fbb56c',
                    }}
                  >
                    Login or Sign Up
                  </a>
                </div>
              )}
              {this.props.user?.total_common_members_list?.length > 3 ? (
                <div
                  onClick={(e) =>
                    this.props.onHoverTrustBy(
                      this.props.user.id,
                      'common_member',
                      this.props.user.user_type,
                      this.props.user.total_common_members,
                    )
                  }
                  className='it-person-number'
                >
                  <span>+{this.props.user.total_common_members_list.length - 3}</span>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      ) : null

    if (this.props.user.user_type === 'individual') {
      badgeTrustSection = (
        <div className='badge badge-border-blue'>
          <div className='b-triangle' />
          <div className='b-inner i-trust'>
            <h4>iTRUST</h4>
            <div className='d-flex d1'>
              <div className='col-6 digits_cat'>
                <div className='digit'>{this.props.user.total_trusted_business}</div>
                <div className='digit_name'>business</div>
              </div>
              <div className='col-6 digits_cat'>
                <div className='digit'>{this.props.user.total_trusted_organizations}</div>
                <div className='digit_name d-block d-sm-none'>orgs</div>
                <div className='digit_name d-none d-sm-block'>organizations</div>
              </div>
            </div>
            {mutualOrgsSection}
          </div>
        </div>
      )
    } else {
      badgeTrustSection = (
        <div className='badge'>
          <div className='b-triangle' />
          <div className='b-inner'>
            <h4>Trusted by</h4>
            <div className='d-flex d1 row justify-content-center m-0'>
              <div className='col-4 digits_cat'>
                {/* onClick={(e) => this.onClickTrustedBy(this.props.user.slug, '#trusted_by_friend')} */}
                <div
                  className='digit'
                  onClick={(e) =>
                    this.props.onHoverTrustBy(
                      this.props.user.id,
                      'friends',
                      this.props.user.user_type,
                      this.props.user.total_common_members,
                    )
                  }
                >
                  {this.state.isDisplayMemberOrItrustBtn && this.state.currentUserId ? (
                    this.props.user.total_friends_trusted
                  ) : (
                    <a {...loginModalProps} href={null} className='digit login-msg'>
                      <SignupOrLoginText />
                    </a>
                  )}
                </div>
                <div className='digit_name'>
                  {this.state.isDisplayMemberOrItrustBtn && this.state.currentUserId ? (
                    <span>friends</span>
                  ) : (
                    <a {...loginModalProps} href={null} className='digit_name'>
                      friends
                    </a>
                  )}
                </div>
              </div>
              <div className='col-4 digits_cat'>
                {/* onClick={(e) => this.onClickTrustedBy(this.props.user.slug, '#trusted_by_friends_of_friends')} */}
                <div
                  className='digit'
                  onClick={(e) =>
                    this.props.onHoverTrustBy(
                      this.props.user.id,
                      'friends_of_friends',
                      this.props.user.user_type,
                      this.props.user.total_common_members,
                      this.updateFofFVal,
                      this.setLoadingTFOFData,
                    )
                  }
                >
                  {this.state.isDisplayMemberOrItrustBtn && this.state.currentUserId ? (
                    this.state.total_friends_of_friend_trusted > -1 ? (
                      this.state.total_friends_of_friend_trusted
                    ) : this.state.loadingTFOFData ? (
                      <div className='search-page-loading-icon'>
                        <i
                          className='search-icon-mobile-compat'
                          style={{
                            width: '50%',
                            backgroundSize: 'cover',
                          }}
                        />
                      </div>
                    ) : (
                      <Fragment>
                        <span>View</span>
                      </Fragment>
                    )
                  ) : (
                    <a {...loginModalProps} href={null} className='digit login-msg'>
                      <SignupOrLoginText />
                    </a>
                  )}
                </div>
                <div className='digit_name'>
                  {this.state.isDisplayMemberOrItrustBtn && this.state.currentUserId ? (
                    <span>friends of friends</span>
                  ) : (
                    <a {...loginModalProps} href={null} className='digit_name'>
                      friends of friends
                    </a>
                  )}
                </div>
              </div>
              <div className='col-4 digits_cat'>
                {/* onClick={(e) => this.onClickTrustedBy(this.props.user.slug, '#trusted_by_all')} */}
                <div
                  className='digit'
                  onClick={(e) =>
                    this.props.onHoverTrustBy(
                      this.props.user.id,
                      'total',
                      this.props.user.user_type,
                      this.props.user.total_common_members,
                    )
                  }
                >
                  {this.props.user.total_trusted}
                </div>
                <div className='digit_name'>total</div>
              </div>
            </div>
            {mutualOrgsSection}
          </div>
        </div>
      )
    }

    const star_rating_color1 =
      user.user_type === 'individual'
        ? STAR_RATING_iTRUST_INACTIVE_COLOR
        : STAR_RATING_TRUST_ME_INACTIVE_COLOR
    const star_rating_color2 =
      user.user_type === 'individual'
        ? STAR_RATING_iTRUST_ACTIVE_COLOR
        : STAR_RATING_TRUST_ME_ACTIVE_COLOR

    let reviewCountSection = null
    let reviewStarSection = null
    if (
      typeof user.total_given_review !== 'undefined' &&
      typeof user.average_given_review !== 'undefined' &&
      typeof user.total_review !== 'undefined' &&
      typeof user.average_review !== 'undefined'
    ) {
      if (user.user_type === 'individual') {
        reviewCountSection = (
          <a href={'/' + this.props.user?.slug + '#review'} className={'varela'}>
            {user.total_given_review} Reviews
          </a>
        )

        // reviewStarSection = <ReactStars
        //     edit={false}
        //     size={STAR_RATING_REVIEW_SECTION_SIZE}
        //     color1={star_rating_color1}
        //     color2={star_rating_color2}
        //     value={user.average_given_review}
        // />;

        // reviewStarSection = <StarRatingComponent
        //     name={"reviewStarSection_" + user.id}
        //     className={"reviewStarSectionIn"}
        //     starCount={5}
        //     editing={false}
        //     value={user.average_given_review + 0.5}
        //     renderStarIcon={(index, value) => {
        //         return (
        //           <span>
        //             <i className={index <= value ? 'fas fa-star' : 'far fa-star'} />
        //           </span>
        //         );
        //     }}
        //     renderStarIconHalf={() => {
        //         return (
        //           <span>
        //             <span style={{position: 'absolute'}}><i className="far fa-star" /></span>
        //             <span><i className="fas fa-star-half" /></span>
        //           </span>
        //         );
        //     }}
        //     emptyStarColor={star_rating_color1}
        //     starColor={star_rating_color2}
        // />;

        reviewStarSection = (
          <StarRatings
            name={'reviewStarSection_' + user.id}
            starDimension={STAR_RATING_REVIEW_SECTION_SIZE_PX}
            starSpacing={RATING_STAR_SPACING}
            isSelectable={false}
            // starHoverColor={null}
            starEmptyColor={star_rating_color1}
            starRatedColor={star_rating_color2}
            numberOfStars={NUMBER_OF_RATING_STAR}
            rating={user.average_given_review}
          />
        )
      } else {
        reviewCountSection = (
          <a href={'/' + this.props.user?.slug + '#review'} className={'varela'}>
            {user.total_review} Reviews
          </a>
        )

        // reviewStarSection = <ReactStars
        //     edit={false}
        //     size={STAR_RATING_REVIEW_SECTION_SIZE}
        //     color1={star_rating_color1}
        //     color2={star_rating_color2}
        //     value={user.average_review}
        // />;

        // reviewStarSection = <StarRatingComponent
        //     name={"reviewStarSection_" + user.id}
        //     className={"reviewStarSectionInList"}
        //     starCount={5}
        //     editing={false}
        //     value={user.average_review + 0.5}
        //     renderStarIcon={(index, value) => {
        //         return (
        //           <span>
        //             <i className={index <= value ? 'fas fa-star' : 'far fa-star'} />
        //           </span>
        //         );
        //     }}
        //     renderStarIconHalf={() => {
        //         return (
        //           <span>
        //             <span style={{position: 'absolute'}}><i className="far fa-star" /></span>
        //             <span><i className="fas fa-star-half" /></span>
        //           </span>
        //         );
        //     }}
        //     emptyStarColor={star_rating_color1}
        //     starColor={star_rating_color2}
        // />;

        reviewStarSection = (
          <StarRatings
            name={'reviewStarSection_' + user.id}
            starDimension={STAR_RATING_REVIEW_SECTION_SIZE_PX}
            starSpacing={RATING_STAR_SPACING}
            isSelectable={false}
            // starHoverColor={null}
            starEmptyColor={star_rating_color1}
            starRatedColor={star_rating_color2}
            numberOfStars={NUMBER_OF_RATING_STAR}
            rating={user.average_review}
          />
        )
      }
    }

    const friendStatsSection = (
      <div className='d-flex flex-column user-list-detail-action-wrapper'>
        <div className='design_action it-delet-blog'>{friendTrustMemberSection}</div>
        <div className='d-flex mb-2 align-items-center friend-stats'>
          <div
            className='d-flex'
            style={{
              fontSize: 13,
              color: '#7d7d7d',
              gap: 4,
            }}
          >
            <img src={process.env.PUBLIC_URL + '/img/user-icon.png'} width={16} alt='' />
            <div>
              {user?.total_friends ?? 0} friend{user?.total_friends === 1 ? '' : 's'}
            </div>
          </div>
          <div
            className='d-flex'
            style={{
              fontSize: 13,
              color: '#7d7d7d',
              gap: 4,
            }}
          >
            <div>
              {this.props.login_user_id ? user.total_mutual_friends ?? 0 : '?'} mutual friend
              {user.total_mutual_friends === 1 ? '' : 's'}
            </div>
          </div>
          {user?.user_type === 'organization' ? (
            <div
              className='d-flex'
              style={{
                fontSize: 13,
                color: '#7d7d7d',
                gap: 4,
              }}
            >
              <img src={process.env.PUBLIC_URL + '/img/member.svg'} width={20} alt='' />
              <div>
                {user?.total_members ?? 0}{' '}
                {formatMembersName(
                  user?.user_settings?.members_name,
                  user?.user_settings?.custom_members_name,
                  user?.total_members !== 1,
                ) ?? 'Members'}
              </div>
            </div>
          ) : null}
        </div>
      </div>
    )

    let nameToDisplay =
      user.user_type === 'individual' ? user.fullname : user.business_organization_name

    return (
      <Fragment>
        {viewType === 'full_view' ? (
          <div className={'itrust-tab-1 ' + this.props.user.user_type + '_profile'}>
            <div className={'pro_name_detail ' + this.props.user.user_type + '_profile'}>
              <div className='d-flex flex-column'>
                <div className='p-4 pb-3 pt-md-5 pt-xl-4 d-flex'>
                  <div className='pro_1'>
                    <Link className='' to={profileUrl}>
                      <img
                        src={
                          this.props.user.profile_picture_medium
                            ? this.props.user.profile_picture_medium
                            : DEFAULT_PROFILE_PICTURE
                        }
                        alt=''
                      />
                    </Link>
                    {/* <img onError={(e)=>{e.target.src=DEFAULT_USER_PROFILE_PICTURE}} src={this.props.user.profile_picture_medium} alt="" /> */}
                    {/* <img src={this.props.user.profile_picture_medium ? this.props.user.profile_picture_medium : DEFAULT_PROFILE_PICTURE} alt="" /> */}
                  </div>
                  <div className='pro_name'>
                    <h3 className='blue'>
                      <Link className='' to={profileUrl}>
                        {/* {this.props.user.fullname} */}
                        {nameToDisplay}
                      </Link>
                    </h3>
                    <div>
                      <ul className='contact'>
                        <li className='address'>
                          {(user.user_type !== 'individual' ||
                            (user.user_type === 'individual' &&
                              user.user_settings?.show_street_address > 0)) &&
                            user.address_line_1 !== '' &&
                            !!user.address_line_1 && (
                              <div className='d-none d-md-block'>
                                {user.address_line_1}
                                <br />
                              </div>
                            )}

                          {(user.user_type !== 'individual' ||
                            (user.user_type === 'individual' &&
                              user.user_settings?.show_street_address > 0)) &&
                            user.address_line_2 !== '' &&
                            !!user.address_line_2 && (
                              <div className='d-none d-md-block'>
                                {user.address_line_2}
                                <br />
                              </div>
                            )}

                          {(user.user_type !== 'individual' ||
                            (user.user_type === 'individual' &&
                              user.user_settings?.show_city_state > 0)) &&
                            user.city_name !== '' &&
                            !!user.city_name &&
                            user.city_name + ', '}
                          {(user.user_type !== 'individual' ||
                            (user.user_type === 'individual' &&
                              user.user_settings?.show_city_state > 0)) &&
                          user.state_short_name !== '' &&
                          user.state_short_name !== null
                            ? user.state_short_name + ' '
                            : (user.user_type !== 'individual' ||
                                (user.user_type === 'individual' &&
                                  user.user_settings &&
                                  user.user_settings.show_city_state > 0)) &&
                              user.state_name !== '' &&
                              !!user.state_name &&
                              user.state_name + ' '}
                          {(user.user_type !== 'individual' ||
                            (user.user_type === 'individual' &&
                              user.user_settings?.show_zipcode > 0)) &&
                            user.zipcode !== '' &&
                            !!user.zipcode &&
                            user.zipcode}
                        </li>
                        {(user.user_type !== 'individual' ||
                          (user.user_type === 'individual' &&
                            user.user_settings &&
                            user.user_settings.show_phone > 0)) && (
                          <li className='phone'>
                            <a href={''}>{user.phone_no_1}</a>
                          </li>
                        )}
                      </ul>
                    </div>
                    <div className='d-none d-sm-block'>{friendStatsSection}</div>
                  </div>
                </div>

                <div className='d-block d-sm-none'>{friendStatsSection}</div>
              </div>
              <div className='d-flex trust_sec_bottom'>
                <div className='categories'>
                  {categoriesSection && this.props.user.user_type !== 'individual' && (
                    <div>
                      <strong>Categories</strong>
                      &nbsp;
                      {categoriesSection}
                    </div>
                  )}
                  <p>
                    <strong>Cities</strong>
                    &nbsp; {city_name}
                  </p>
                </div>

                <div className='star_ratings'>
                  <div className='stars d-flex'>
                    {reviewStarSection}
                    {reviewCountSection}
                  </div>
                </div>

                {/* <div className="star_ratings">
                                    <div className="stars d-flex">
                                        <div>
                                            <img src={process.env.PUBLIC_URL + "/img/review_star_yellow.png"} alt="" />
                                        </div>
                                        <div>
                                            <img src={process.env.PUBLIC_URL + "/img/review_star_grey.png"} alt="" />
                                        </div>
                                        <div>
                                            <img src={process.env.PUBLIC_URL + "/img/review_star_grey.png"} alt="" />
                                        </div>
                                        <div>
                                            <img src={process.env.PUBLIC_URL + "/img/review_star_grey.png"} alt="" />
                                        </div>
                                        <div>
                                            <img src={process.env.PUBLIC_URL + "/img/review_star_grey.png"} alt="" />
                                        </div>
                                        <a href={null} className="varela">178 reviews</a>
                                    </div>
                                </div> */}
              </div>
            </div>
            {badgeTrustSection}
          </div>
        ) : (
          <div className='col-md-6 '>
            <div className={'frnd_box ' + this.props.user.user_type + '_profile'}>
              <div className='text_frnd d-sm-none'>
                <h5>
                  <Link className='' to={profileUrl}>
                    {/* {this.props.user.fullname} */}
                    {nameToDisplay}
                  </Link>
                </h5>
              </div>
              <div className='img_block orange_border'>
                <Link className='' to={profileUrl}>
                  <img
                    src={
                      this.props.user.profile_picture_small
                        ? this.props.user.profile_picture_small
                        : DEFAULT_PROFILE_PICTURE
                    }
                    alt=''
                  />
                </Link>
              </div>
              <div className=''>
                {/* {console.log(this.props.user)} */}
                <div className='tag'>
                  {this.props.user.user_type === 'individual'
                    ? this.props.user.total_trusted_by_me
                    : this.props.user.total_trusted_by}
                </div>
                <div className='frandss'>{this.props.user.total_friends}</div>
              </div>
              <div className='text_frnd'>
                <h5 className='d-none d-sm-block'>
                  <Link className='' to={profileUrl}>
                    {/* {this.props.user.fullname} */}
                    {nameToDisplay}
                  </Link>
                </h5>
                <div className='design_action it-delet-blog'>
                  {friendTrustMemberSection}
                  {/* {console.log(friendTrustMemberSection)} */}
                  {chatSection}
                  {this.props.user.id !== this.state.currentUserId &&
                  this.state.currentUser?.user_type === 'organization' &&
                  this.state.isDisplayMemberOrItrustBtn ? (
                    <button
                      style={{ background: 'none', border: 'none' }}
                      className='ms-3'
                      //Add onClick trigger to handle adding/removing/accepting members
                      onClick={() =>
                        this.confirmMemberAddRemoveYour(this.props.user?.is_your_member_user !== 0)
                      }
                    >
                      <i className='d-flex'>
                        {this.props.user.is_your_member_user !== 2 ? (
                          <img
                            src={`${process.env.PUBLIC_URL}/img/${
                              this.props.user.is_your_member_user === 1 ? 'check' : 'plus'
                            }-solid.svg`}
                            width={15}
                            style={{ fill: '#777677', marginRight: 2 }}
                            alt=' '
                          />
                        ) : null}
                        <img
                          src={`${process.env.PUBLIC_URL}/img/member${
                            this.props.user.is_your_member_user === 2 ? '-p' : ''
                          }.svg`}
                          width={this.props.user.is_your_member_user === 2 ? 38 : 25}
                          alt=' '
                        />
                      </i>{' '}
                    </button>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        )}
        {/* <ConfirmRemoveFromOrgPopupModal
          Orgname={this.props.user.fullname}
          user_id={this.props.user.id}
          memberType='member_of_your_organization'
          membersName={
            formatMembersName(
              this.state.currentUser?.user_settings?.members_name,
              this.state.currentUser?.user_settings?.custom_members_name,
            ) ?? 'Members'
          }
          loadingMemberChangeResponse={false}
          setLoadingMemberChangeResponse={() => {}}
          dataYour={{
            is_member_user: this.props.user?.is_member_user,
            id: this.props.user.id,
            flag: 'member_of_your_organization',
          }}
          memberAddRemoveYour={this.memberAddRemoveYour}
          setStatusOfMemberUserYour={(data) => console.log(data)}
        />
        <InvitePopupModal
          Orgname={this.props.user.fullname}
          loadingMemberChangeResponse={false}
          setLoadingMemberChangeResponse={() => {}}
          user_id={this.props.user.id}
          memberType='member_of_your_organization'
          membersName={
            formatMembersName(
              this.state.currentUser?.user_settings?.members_name,
              this.state.currentUser?.user_settings?.custom_members_name,
            ) ?? 'Members'
          }
          setStatusOfMemberUserYour={(data) => console.log(data)}
        /> */}
      </Fragment>
    )
  }
}
UserListDetail.propTypes = {
  onHoverTrustBy: PropTypes.func, // this should control the on hover event of trust by
}
UserListDetail.defaultProps = {
  onHoverTrustBy: (e) => function () {},
}
export default UserListDetail
