import React, { Component } from 'react'
import './ComingSoon.scss'
import $J from 'jquery'
import axios from 'axios'
import { customLogger } from '../../utils/CustomLogger'
import * as route from '../../services/routeConst'
import * as environemnt from '../../services/environment'

class ComingSoon extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loginCode: '',
      showErrorMessage: false,
      name: '',
      phone: '',
      email: '',
      forgotText: '',
      forgotTextSuccess: '',
    }
  }
  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  handleSubmit = (event) => {
    event.preventDefault()
    const data = {
      name: this.state.name,
      phone: this.state.phone,
      email: this.state.email,
    }
    this.setState({
      forgotText: '',
      forgotTextSuccess: '',
    })
    $J('#btn-forgot-code-submit').prop('disabled', true).text('Please Wait...')
    let api_url = environemnt.BASE_API + environemnt.COMMING_SOON_FORGOT_CODE
    var self = this
    axios
      .post(api_url, data, {
        headers: { 'Content-Type': 'application/json' },
      })
      .then(function (response) {
        if (response.data.data.message) {
          self.setState({
            forgotTextSuccess: response.data.data.message,
          })
        }
        $J('#btn-forgot-code-submit').prop('disabled', false).text('Submit')
      })
      .catch(function (error) {
        customLogger(error)
        var message = 'Something went wrong , Please try again later.'
        // if (xhr.status == 401) {
        //     message = "You are not logged in. please login and try again";
        // } else if (xhr.status == 403) {
        //     message = "You have not permission for perform this operations";
        // } else if (xhr.responseJSON && xhr.responseJSON.message != "") {
        //     message = xhr.responseJSON.message;
        // }
        self.setState({
          forgotText: message,
        })
        $J('#btn-forgot-code-submit').prop('disabled', false).text('Submit')
      })
  }
  componentDidMount() {
    var self = this
    $J('#it-login').on('hidden.bs.modal', function (e) {
      self.setState({
        showErrorMessage: false,
        loginCode: '',
      })
    })

    $J('#it-forgot').on('hidden.bs.modal', function (e) {
      $J('#form-forgot-code')[0].reset()
      self.setState({
        forgotText: '',
        forgotTextSuccess: '',
      })
    })

    if (this.isIE()) {
      document.body.classList.add('it-ie-brower')
    }
  }
  btnLogin = (event) => {
    event.preventDefault()
    this.setState({
      showErrorMessage: false,
    })
    if (this.state.loginCode.trim() == 'trust789' || this.state.loginCode.trim() == 'Trust789') {
      localStorage.setItem('isEnterCode', true)
      // window.location.href = "https://itrustit.com";
      //window.location.href = route.ROOT_ROUTE;
      window.location.reload()
    } else {
      this.setState({
        showErrorMessage: true,
      })
    }
  }
  isIE() {
    let ua = navigator.userAgent
    /* MSIE used to detect old browsers and Trident used to newer ones*/
    var is_ie = ua.indexOf('MSIE ') > -1 || ua.indexOf('Trident/') > -1

    return is_ie
  }
  render() {
    return (
      <div>
        <header className='it-header'>
          <nav className='navbar navbar-expand-lg'>
            <a className='navbar-brand' href='javascript:void(0);'>
              {' '}
              <img src='img/logo.png' alt='' />
            </a>
          </nav>
        </header>
        <section className='it-banner'>
          <div className='container'>
            <div className='row align-items-center'>
              <div className='col-md-12 mb-0'>
                <div className='it-banner-top'>
                  <h1>iTRUSTit</h1>
                  <p>Connecting people you know to trusted businesses.</p>
                  <a href='https://www.youtube.com/watch?v=wphwmnD0ymQ' target='_blank'>
                    HOW IT WORKS
                  </a>
                </div>
                <div className='it-banner-bottom d-none'>
                  <p>To enter iTRUSTit you need a Beta Test code. Enter it here for access.</p>
                  <form action='#'>
                    <div className='form-group'>
                      <input
                        type='email'
                        className='form-control'
                        id='exampleInputEmail1'
                        aria-describedby='emailHelp'
                        placeholder='Enter email'
                      />
                    </div>
                    <button type='submit' className='btn btn-primary'>
                      ENTER
                    </button>
                  </form>
                </div>
                <h2 className='text-center d-none'>
                  Forgot your code? <a href=''>Contact us.</a>{' '}
                </h2>
              </div>
            </div>
            <div className='it-comming-form'>
              <form className='it-form ' onSubmit={this.btnLogin}>
                <div className='form-group'>
                  <input
                    type='text'
                    className='form-control'
                    id='login-code'
                    value={this.state.loginCode}
                    name='loginCode'
                    onChange={this.handleChange}
                    placeholder='Access Code'
                  />
                  {this.state.showErrorMessage && (
                    <span id='login-code-error-msg' className='it-er'>
                      Invalid Login Code
                    </span>
                  )}
                </div>
                <div>
                  <button type='submit' id='btn-login' className='btn'>
                    Enter iTRUSTit
                  </button>
                </div>
                <div className='text-center'>
                  <a href='' data-bs-toggle='modal' data-bs-target='#it-forgot'>
                    Forgot code?
                  </a>
                </div>
              </form>
            </div>
          </div>
        </section>

        {/* <div className="modal fade it-comming-form-open show" id="it-login" tabIndex="-1" role="dialog" aria-labelledby="it-login"
                    aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-body p-0">

                            </div>
                        </div>
                    </div>
                </div> */}
        <div
          className='modal fade it-comming-form-modal'
          id='it-forgot'
          tabIndex='-1'
          role='dialog'
          aria-labelledby='exampleModalLabel'
          aria-hidden='true'
        >
          <div className='modal-dialog' role='document'>
            <div className='modal-content'>
              <div className='modal-header'>
                <h5 className='modal-title' id='exampleModalLabel'>
                  Forgot code?
                </h5>
                <button
                  type='button'
                  className='btn-close'
                  data-bs-dismiss='modal'
                  aria-label='Close'
                >
                  <span aria-hidden='true'>&times;</span>
                </button>
              </div>
              <div className='modal-body'>
                <form
                  className='it-form'
                  id='form-forgot-code'
                  autoComplete='off'
                  onSubmit={this.handleSubmit}
                >
                  <div className='form-group'>
                    <input
                      id='name'
                      name='name'
                      type='text'
                      className='form-control'
                      placeholder='Enter Name'
                      onChange={this.handleChange}
                      required
                    />
                  </div>
                  <div className='form-group'>
                    <input
                      id='phone'
                      name='phone'
                      type='text'
                      className='form-control'
                      placeholder='Enter Phone number'
                      onChange={this.handleChange}
                      required
                    />
                  </div>
                  <div className='form-group'>
                    <input
                      id='email'
                      name='email'
                      type='email'
                      className='form-control'
                      placeholder='Enter Email'
                      onChange={this.handleChange}
                      required
                    />
                  </div>
                  <div className='form-group text-center'>
                    <span className='text-danger' id='forgot-code-error'>
                      {this.state.forgotText}
                    </span>
                    <span className='text-success' id='forgot-code-success'>
                      {this.state.forgotTextSuccess}
                    </span>
                  </div>
                  <div className='d-flex justify-content-between'>
                    <button type='button' className='btn' data-bs-dismiss='modal'>
                      Cancel
                    </button>
                    <button type='submit' id='btn-forgot-code-submit' className='btn'>
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ComingSoon
