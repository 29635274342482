import React from 'react'

const FormCheckBox = ({ name = '', value = '', label = '', onChange, disabled = false }) => {
  // console.log({ value })
  return (
    <div className='form-check text-start'>
      <input
        className='form-check-input'
        type='checkbox'
        checked={value}
        // value={value}
        disabled={disabled}
        id={name}
        name={name}
        onChange={(e) => {
          onChange({ name: e.target.name, value: e.target.checked })
        }}
      />
      <label className='form-check-label' htmlFor={name}>
        {label}
      </label>
    </div>
  )
}

export default FormCheckBox
