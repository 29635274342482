import React from 'react'
import $ from 'jquery'
import VCardAppIcon from '../VCardAppIcon'
import { useDeviceSelectors } from 'react-device-detect'

export const showSaveToHomescreenModal = () => {
  $('#savetohomescreen_modal').modal('show')
  document.body.style.overflow = null
}

const ShareIcon = () => (
  <svg
    version='1.0'
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 38 50'
    style={{
      stroke: '#007aff',
      display: 'inline',
      marginBottom: 4,
      width: 15,
      height: 20,
      verticalAlign: 'text-bottom',
    }}
  >
    <polyline fill='none' strokeWidth='2' points='12,8 19,1 26,8 '></polyline>
    <line fill='none' strokeWidth='2' x1='19' y1='1' x2='19' y2='34'></line>
    <polyline fill='none' strokeWidth='2' points='24,15 37,15 37,49 1,49 1,15 14,15 '></polyline>
  </svg>
)

const MenuIcon = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='16'
    height='16'
    fill='currentColor'
    viewBox='0 0 16 16'
  >
    <path d='M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z'></path>
  </svg>
)

const SaveToHomescreenModal = ({ user }) => {
  const [selectors] = useDeviceSelectors(window.navigator.userAgent)
  const { isAndroid } = selectors
  return (
    <div className='modal fade savetohomescreen_modal' id='savetohomescreen_modal' role='dialog'>
      <div
        className={`modal-dialog ${
          isAndroid ? 'ms-auto' : 'modal-dialog-centered mx-auto align-items-end'
        }`}
        style={{ maxWidth: '90%' }}
      >
        <div className={`modal-tooltip-arrow ${isAndroid ? 'android-pos' : ''}`}></div>
        <div className={`modal-tooltip-arrow inner ${isAndroid ? 'android-pos' : ''}`}></div>
        <div className={`modal-content ${isAndroid ? 'mt-3' : 'mb-3'}`}>
          <div className={`modal-header align-items-start border-0 pb-0`}>
            {/* <h5>Hi</h5> */}
            <button type='button' className='btn-close' data-bs-dismiss='modal'></button>
          </div>
          <div
            className={`modal-body pt-0 ${isAndroid ? 'px-0' : ''}`}
            style={{ textAlign: 'center' }}
          >
            <div className='d-flex flex-column align-items-center'>
              <h5 style={{ fontWeight: 'bold', marginBottom: 0, maxWidth: 200 }}>
                To add this vCard to your home screen
              </h5>
              <VCardAppIcon user={user} />
              {isAndroid ? (
                <>
                  <h5>Tap the {<MenuIcon />} icon in the top corner of your Browser</h5>
                  <h5>Tap "Add to Home Screen"</h5>
                  <h5>Tap "Add" and you're done</h5>
                </>
              ) : (
                <>
                  {' '}
                  <h5 className='mb-0'>
                    Tap the <strong>itrustit.com</strong> address bar below,
                  </h5>
                  <h5 className='my-1'>Tap the {<ShareIcon />} icon, and</h5>
                  <h5 className='fw-bold'>"Add to Home Screen"</h5>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SaveToHomescreenModal
