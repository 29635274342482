import React, { Component, Fragment } from 'react'
import { FacebookShareButton, TwitterShareButton } from 'react-share'
import OverviewTab from '../OverviewTab/OverviewTab'
import PostsTab from '../PostsTab/PostsTab'
import { connect } from 'react-redux'
import Friends from '../Friends/Friends'
import { relatedProfileOther, sharedProfile } from '../../../services/userApiService'
import { PROFILE_ROUTE_CONST } from '../../../services/const'
import { Link } from 'react-router-dom'
import { CLAIM_BUSINESS_PROFILE_ROUTE, MESSAGE_ROUTE_SINGLE } from '../../../services/routeConst'
import { SITE_ADDRESS } from '../../../services/environment'
import { toaster } from '../../../services/customFunction'
import { removeMemberCall } from '../../../services/memberApiService'
import $J from 'jquery'
import { formatMemberDirectoryName, formatMembersName } from '../../../services/formatService'
import { saveToContacts } from '../../CommonComponents/VCard_New/VCard'
import ShareModal, { showShareModal } from '../../CommonComponents/ShareModal/ShareModal'
import { followCreateCall } from '../../../services/followApiService'
import ConfirmUnfollowModal from '../ConfirmUnfollowModal'
var $ = window.$

class OverviewGenericTab extends Component {
  constructor(props) {
    super(props)
    //user_id, user
    console.log('user:', this.props.user_id, this.props.user?.id)
    this.overviewTabFriendSectionRef = React.createRef()
    this.overviewTabRef = React.createRef()
    this.postsTabRef = React.createRef()

    this.state = {
      relatedUser: [],
      isDisplayError: false,
      sharedFields: {},
      sharedErrors: {},
      loadingMemberChangeResponse: false,
      is_member_user: this.props.user.is_member_user,
      is_following_user: this.props.user.is_following_user,
      follow_id: this.props.user.follow_id,
      memberType: '',
      is_your_member_user: this.props.user.is_your_member_user,
      myMembersName:
        formatMembersName(
          props?.logged_in_user?.user_settings?.members_name,
          props?.logged_in_user?.user_settings?.custom_members_name,
        ) ?? 'Members',
      membersName:
        formatMembersName(
          props?.user_settings?.members_name,
          props?.user_settings?.custom_members_name,
        ) ?? 'Members',
      memberDirectoryName:
        formatMemberDirectoryName(
          props?.user_settings?.member_directory_name,
          props?.user_settings?.custom_member_directory_name,
        ) ?? 'Member Directory',
    }
  }

  componentWillReceiveProps(_nextProps) {
    console.log({ props: this.props, _nextProps })
    this.setState({
      is_member_user: this.props.user.is_member_user,
      is_your_member_user: this.props.user.is_your_member_user,
      is_following_user: this.props?.user?.is_following_user,
      follow_id: this.props?.user?.follow_id,
    })
  }

  confirmFollowAddRemove = (needsConfirmation) => {
    needsConfirmation
      ? $J('#confirm_unfollow').modal('show')
      : this.followAddRemove({
          is_following_user: this.state.is_following_user,
          id: this.props.user.id,
          follow_id: this.props.user.follow_id,
        })
  }

  confirmMemberAddRemove = (needsConfirmation) => {
    needsConfirmation
      ? $J('#confirm_leave_org').modal('show')
      : this.memberAddRemove({
          is_member_user: this.state.is_member_user,
          id: this.props.user.id,
          index: this.props.index,
          flag: 'member_of_their_organization',
        })
  }

  confirmMemberAddRemoveYour = (needsConfirmation) => {
    const modalProps = {
      confirm: {
        Orgname: this.props.user.business_organization_name,
        user_id: this.props.user.id,
        memberType: 'member_of_your_organization',
        membersName: this.state.myMembersName,
        loadingMemberChangeResponse: this.state.loadingMemberChangeResponse,
        setLoadingMemberChangeResponse: this.setLoadingMemberChangeResponse,
        dataYour: {
          is_your_member_user: this.state.is_your_member_user,
          id: this.props.user.id,
          index: this.props.index,
          flag: 'member_of_your_organization',
        },
        memberAddRemoveYour: this.memberAddRemoveYour,
        setStatusOfMemberUser: this.setStatusOfMemberUser,
      },
      invite: {
        Orgname: this.props.user.business_organization_name,
        loadingMemberChangeResponse: this.state.loadingMemberChangeResponse,
        setLoadingMemberChangeResponse: this.setLoadingMemberChangeResponse,
        user_id: this.props.user.id,
        memberType: 'member_of_your_organization',
        membersName: this.state.myMembersName,
        setStatusOfMemberUserYour: this.setStatusOfMemberUserYour,
      },
    }
    this.props.setModalProps(modalProps, needsConfirmation, () => {
      this.setState({
        memberType: 'member_of_your_organization',
        is_your_member_user: 0,
        loadingMemberChangeResponse: false,
      })
    })
  }

  memberAddRemoveYour = async (data) => {
    let memberAddRemoveReqData = {
      id: data.id,
      member_type: data.flag,
    }
    if (data.is_your_member_user !== 0) {
      const res = await removeMemberCall(memberAddRemoveReqData)
      if (res?.status === 200) {
        this.setState({
          memberType: 'member_of_your_organization',
          is_your_member_user: 0,
          loadingMemberChangeResponse: false,
        })
      }
    } else if (data.is_your_member_user !== 2) {
      $J('#invite_modal').modal('show')
    }
  }

  setLoadingMemberChangeResponse = (value = false) => {
    this.setState({ loadingMemberChangeResponse: value })
  }

  setLoadingFollowChangeResponse = (value = false) => {
    this.setState({ loadingFollowChangeResponse: value })
  }

  memberAddRemove = (data) => {
    const self = this
    this.setState({
      memberType: data.flag,
    })
    let memberAddRemoveReqData = {
      id: data.id,
      member_type: data.flag,
    }
    if (this.state.is_member_user === 0) {
      this.setLoadingMemberChangeResponse(false)
      $J('#member_modal').modal('show')
    } else if (this.state.is_member_user === 1) {
      removeMemberCall(memberAddRemoveReqData).then(function (response) {
        if (typeof response.status !== 'undefined' && response.status === 200) {
          self.setState({ is_member_user: 0 })
        }

        self.setLoadingMemberChangeResponse(false)
      })
    }
  }

  followAddRemove = (data) => {
    if (data.is_following_user === 0) {
      this.setLoadingFollowChangeResponse(true)
      const self = this
      let followCreateReqData = {
        user_id: data.id,
      }
      followCreateCall(followCreateReqData).then(function (response) {
        if (!!response?.data?.follow_id) {
          self.setState({
            is_following_user: response?.data?.is_following_user,
            follow_id: response?.data?.follow_id,
          })
        }
        self.setLoadingFollowChangeResponse(false)
      })
    }
    // else if (data.is_following_user !== 0 || this.props.user.follow_id > 0) {
    //   let followChangeStatusReqData = {
    //     follow_id: data.follow_id,
    //     status: 'unfollow',
    //   }
    //   followChangeStatusCall(followChangeStatusReqData).then(function (response) {
    //     if (response?.status === 200) {
    //       this.unfollowHandler()
    //     }
    //   })
    // }
  }

  unfollowHandler = () => {
    this.setLoadingFollowChangeResponse(false)
    this.setState({
      is_following_user: 0,
      follow_id: null,
    })
  }

  setStatusOfMemberUser = (status) => {
    if (status === 'pending') {
      this.setState({ is_member_user: 2 })
    } else if (status === 'accepted') {
      this.setState({ is_member_user: 1 })
    } else {
      this.setState({ is_member_user: 0 })
    }
  }
  setStatusOfMemberUserYour = (status) => {
    if (status === 'pending') {
      this.setState({ is_your_member_user: 2 })
    } else if (status === 'accepted') {
      this.setState({ is_your_member_user: 1 })
    } else {
      this.setState({ is_your_member_user: 0 })
    }
  }

  //comment remove
  profileHeader_overview_tab_click_Handle() {
    this.overviewTabFriendSectionRef.current.profileHeader_overview_tab_click_Handle()
    this.overviewTabRef?.current?.profileHeader_overview_tab_click_Handle()
  }

  //comment remove
  profileHeader_overview_tab_review_section_Handle() {
    this.overviewTabRef?.current?.profileHeader_overview_tab_review_section_Handle()
  }

  showReviewTab2 = (data) => {
    this.props.showReviewTab2(data)
  }

  componentDidMount() {
    // this.relatedProfileOthers();
    // $("#toemail").multipleInput();
  }

  relatedProfileOthers = () => {
    let self = this
    // console.log("overviewtab=>", this.props.user_id);

    relatedProfileOther(this.props.user_id).then(function (response) {
      if (response.status === 200) {
        let users = response.data.data.related_users
        let obj = {
          relatedUser: users,
        }
        if (response.data.data.related_users.length === 0) obj.isDisplayError = true

        self.setState(obj)
      } else self.setState({ isDisplayError: true })
    })
  }

  shareHandleChange = (e) => {
    let sharedFields = this.state.sharedFields
    sharedFields[e.target.name] = e.target.value
    this.setState({
      sharedFields,
    })
  }

  submitShareForm = (e) => {
    e.preventDefault()
    if (this.validateForm()) {
      let params = this.state.sharedFields
      params.url = SITE_ADDRESS + PROFILE_ROUTE_CONST + this.props.user.slug

      // var toEmails = $.map($("span[rel]"), function(el) {
      //     return el.getAttribute("rel");
      // });

      // if (toEmails.length == 0) {
      //     let errorCopy = { ...this.state.sharedErrors };
      //     errorCopy["toemail"] = "To email id required";
      //     this.setState({ sharedErrors: errorCopy });
      //     return false;
      // }

      // toEmails = toEmails.join(",");

      // params.to_emails = toEmails;
      // console.log(params);
      sharedProfile(params).then(function (response) {
        if (response.status === 200) {
          toaster('success', response.data.message)
          $('#sharedForm').get(0).reset()
          $J('#profileInfoShareModal').modal('hide')
        }
      })
    }
  }

  validateForm = () => {
    let fields = this.state.sharedFields
    let errors = {}
    let formIsValid = true

    if (!fields['name']) {
      formIsValid = false
      errors['name'] = 'Please enter your username.'
    }
    if (!fields['to_emails']) {
      formIsValid = false
      errors['toemail'] = 'Please enter To email.'
    }

    if (!fields['from_email']) {
      formIsValid = false
      errors['fromemail'] = 'Please enter your email-ID.'
    }

    if (typeof fields['from_email'] !== 'undefined') {
      //regular expression for email validation
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i,
      )
      if (!pattern.test(fields['from_email'])) {
        formIsValid = false
        errors['fromemail'] = 'Please enter valid email-ID.'
      }
    }

    this.setState({
      sharedErrors: errors,
    })
    return formIsValid
  }
  render() {
    // console.log(this.props.user)
    return (
      <div
        id='overviewgeneric'
        className={
          'tab-pane ' +
          (this.props.activeTab === '#overview' ||
          this.props.activeTab === '#posts' ||
          this.props.activeTab === '#myposts'
            ? 'active'
            : 'fade')
        }
      >
        <div className='row'>
          <div className='col-md-4 overview-left'>
            <div className='bg-white curvey mb-4 p-15'>
              <div className='d-flex buttons justify-content-center'>
                {this.props.user?.user_settings?.allow_messages_from === 'All logged in users' ||
                (this.props.user?.user_settings?.allow_messages_from === 'Friends' &&
                  this.props.user?.is_friend_user) ? (
                  <div className='col-4 col-md-4'>
                    {this.props.user.user_type !== 'individual' &&
                    this.props.user.is_business_claimed === '0' ? (
                      <a
                        href={null}
                        className='sendmsg'
                        data-bs-toggle='modal'
                        data-bs-target='#sendMessageNoticeModal'
                      >
                        <i>
                          <img src={process.env.PUBLIC_URL + '/img/chat_icon_green.png'} alt=' ' />
                        </i>
                        iTi Message
                      </a>
                    ) : (
                      <Link to={MESSAGE_ROUTE_SINGLE + this.props.user_id} className='sendmsg'>
                        <i>
                          <img src={process.env.PUBLIC_URL + '/img/chat_icon_green.png'} alt=' ' />
                        </i>
                        iTi Message
                      </Link>
                    )}
                  </div>
                ) : null}
                <div className='col-4 col-md-4'>
                  <ShareModal user={this.props.user} />
                  <a
                    href={null}
                    className='share'
                    onClick={() => showShareModal()}
                    // data-bs-toggle='modal'
                    // data-bs-target='#profileInfoShareModal'
                    style={{ boxShadow: 'none' }}
                  >
                    <i>
                      <img src={process.env.PUBLIC_URL + '/img/share.png'} alt=' ' />
                    </i>
                    Share
                  </a>
                </div>
                <div className='col-4 col-md-4'>
                  <a
                    href={
                      this.props.user?.is_business_claimed === '1'
                        ? `/vcard/${this.props.user.slug}`
                        : null
                    }
                    className={`share ${
                      this.props.user?.is_business_claimed === '0' ? 'disabled' : ''
                    }`}
                    target='_blank'
                    rel='noopener noreferrer'
                    style={{ boxShadow: 'none' }}
                  >
                    <i>
                      <img
                        src={process.env.PUBLIC_URL + '/img/vcard-black.svg'}
                        width={21}
                        alt=' '
                      />
                    </i>
                    VCard
                  </a>
                </div>
              </div>
              <div className='alllinks'>
                {/* && this.props.user_settings.can_add_as_member_of_their_organization === "1" */}
                {this.props.user.user_type !== 'organization' &&
                this.props.loginUserType === 'organization' ? (
                  <a
                    id='OrgToOtherYour'
                    href={null}
                    className={
                      // (this.state.is_your_member_user === 2 ? 'disabled-text ' : '') +
                      'd-block mb-3'
                    }
                    onClick={() =>
                      !this.state.loadingMemberChangeResponse &&
                      this.confirmMemberAddRemoveYour(this.state.is_your_member_user !== 0)
                    }
                  >
                    <i>
                      {this.state.is_your_member_user === 0 ||
                      this.state.is_your_member_user === 1 ? (
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            '/img/' +
                            (this.state.loadingMemberChangeResponse
                              ? 'loading.svg'
                              : (this.state.is_your_member_user === 0 ? 'follow_icon' : 'check') +
                                '.png')
                          }
                          style={
                            this.state.loadingMemberChangeResponse
                              ? { width: 25, marginLeft: -5 }
                              : {}
                          }
                          alt=' '
                        />
                      ) : (
                        <span>P</span>
                      )}
                    </i>{' '}
                    {formatMembersName(this.state.myMembersName, null, false)} of Your Organization
                  </a>
                ) : (
                  ''
                )}

                {/* {this.props.user.user_type === 'organization' &&
                this.props.loginUserType !== 'organization' ? (
                  <a
                    id='OtherToOrgTheir'
                    href={null}
                    className={
                      (this.state.is_member_user === 2 ? 'disabled-text ' : '') + 'd-block mb-3'
                    }
                    onClick={() =>
                      !this.state.loadingMemberChangeResponse &&
                      this.confirmMemberAddRemove(this.state.is_member_user === 1)
                    }
                  >
                    <i>
                      {this.state.is_member_user === 0 || this.state.is_member_user === 1 ? (
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            '/img/' +
                            (this.state.loadingMemberChangeResponse
                              ? 'loading.svg'
                              : (this.state.is_member_user === 0 ? 'follow_icon' : 'check') +
                                '.png')
                          }
                          style={
                            this.state.loadingMemberChangeResponse
                              ? { width: 25, marginLeft: -5 }
                              : {}
                          }
                          alt=' '
                        />
                      ) : (
                        <span>P</span>
                      )}
                    </i>{' '}
                    {formatMembersName(this.state.membersName, null, false)} of This Organization
                  </a>
                ) : (
                  ''
                )} */}

                {this.props.user.user_type === 'organization' &&
                this.props.loginUserType === 'organization' ? (
                  <Fragment>
                    {/* {this.props.user_settings.can_add_as_member_of_their_organization === "1" && ( */}
                    <a
                      id='OrgToOrgYour'
                      href={null}
                      className={
                        // (this.state.is_your_member_user === 2 ? 'disabled-text ' : '') +
                        'd-block mb-3'
                      }
                      onClick={() =>
                        !this.state.loadingMemberChangeResponse &&
                        this.confirmMemberAddRemoveYour(this.state.is_your_member_user !== 0)
                      }
                    >
                      <i>
                        {this.state.is_your_member_user === 0 ||
                        this.state.is_your_member_user === 1 ? (
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              '/img/' +
                              (this.state.loadingMemberChangeResponse
                                ? 'loading.svg'
                                : (this.state.is_your_member_user === 0 ? 'follow_icon' : 'check') +
                                  '.png')
                            }
                            style={
                              this.state.loadingMemberChangeResponse
                                ? { width: 25, marginLeft: -5 }
                                : {}
                            }
                            alt=' '
                          />
                        ) : (
                          <span>P</span>
                        )}
                      </i>{' '}
                      {formatMembersName(this.state.myMembersName, null, false)} of Your
                      Organization
                    </a>
                    {/* )} */}

                    {/* <a
                      id='OrgToOrgTheir'
                      href={null}
                      className={
                        (this.state.is_member_user === 2 ? 'disabled-text ' : '') + 'd-block mb-3'
                      }
                      onClick={() => {
                        !this.state.loadingMemberChangeResponse &&
                          this.confirmMemberAddRemove(this.state.is_member_user === 1)
                      }}
                    >
                      <i>
                        {this.state.is_member_user === 0 || this.state.is_member_user === 1 ? (
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              '/img/' +
                              (this.state.loadingMemberChangeResponse
                                ? 'loading.svg'
                                : (this.state.is_member_user === 0 ? 'follow_icon' : 'check') +
                                  '.png')
                            }
                            style={
                              this.state.loadingMemberChangeResponse
                                ? { width: 25, marginLeft: -5 }
                                : {}
                            }
                            alt=' '
                          />
                        ) : (
                          <span>P</span>
                        )}
                      </i>{' '}
                      {formatMembersName(this.state.membersName, null, false)} of This Organization
                    </a> */}
                  </Fragment>
                ) : null}

                <a
                  href={null}
                  className='d-block mb-3'
                  onClick={() => {
                    !this.state.loadingFollowChangeResponse &&
                      this.confirmFollowAddRemove(this.state.is_following_user !== 0)
                  }}
                >
                  <i>
                    {!this.state.is_following_user || this.state.is_following_user === 1 ? (
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          '/img/' +
                          (this.state.loadingFollowChangeResponse
                            ? 'loading.svg'
                            : (!this.state.is_following_user ? 'follow_icon' : 'check') + '.png')
                        }
                        style={
                          this.state.loadingFollowChangeResponse
                            ? { transform: 'scale(1.5)', width: 25, marginLeft: -5 }
                            : {}
                        }
                        alt=' '
                      />
                    ) : (
                      <span>P</span>
                    )}
                  </i>{' '}
                  Follow{this.state.is_following_user === 1 ? 'ing' : ''}
                </a>
                <a
                  href={null}
                  className='d-block mb-3'
                  onClick={() => saveToContacts(this.props.user)}
                >
                  <i>
                    <img src={process.env.PUBLIC_URL + '/img/download-vcard.png'} alt=' ' />
                  </i>{' '}
                  Save vCard Contact Info
                </a>
                <a href={null} className='d-block'>
                  <i>
                    <img src={process.env.PUBLIC_URL + '/img/bookmark.png'} alt=' ' />
                  </i>{' '}
                  Bookmark
                </a>
              </div>
            </div>
            <Friends
              ref={this.overviewTabFriendSectionRef}
              user_id={this.props.user_id}
              changeActiveTab={this.props.changeActiveTab}
              showFriendsTab={this.props.showFriendsTab}
            />

            {this.props.user.user_type !== 'individual' &&
              this.props.user.is_business_claimed === '0' && (
                <div className='bg-white curvey mb-4 p-15'>
                  <div className='listing_blk'>
                    <h4 className='varela'>Is This Your Listing?</h4>
                    <p className='f_14'>
                      If you own or manage this business claim your listing for free to respond to
                      reviews, update your profile and much more.
                    </p>
                    <Link
                      to={{
                        pathname: CLAIM_BUSINESS_PROFILE_ROUTE,
                        state: { user: this.props.user },
                      }}
                      className='claim_link'
                    >
                      Claim Your Listing
                    </Link>
                    {/* {this.props.user.is_business_claimed == "0" &&
                                        (this.props.user.user_type == "business" ||
                                            this.props.user.user_type == "organization") && (
                                            <Link
                                                to={{
                                                    pathname: CLAIM_BUSINESS_PROFILE_ROUTE,
                                                    state: { user: this.props.user },
                                                }}
                                                className="claim_link"
                                            >
                                                Claim Your Listing
                                            </Link>
                                        )} */}
                  </div>
                </div>
              )}

            <div className='bg-white curvey mb-4 p-15 related-listings-show'>
              <img src={process.env.PUBLIC_URL + '/img/image.png'} className='img-fluid' alt=' ' />
            </div>

            {/* Put related listings here */}
          </div>
          {/* ------------------------------------------- overview left over-------------------------------------------  */}
          <div className='col-md-8 overview-right tab-content'>
            <OverviewTab
              setModalProps={this.setModalProps}
              user={this.props.user}
              activeTab={this.props.activeTab}
              ref={this.overviewTabRef}
              showReviewTab2={this.showReviewTab2}
              changeActiveTab={this.changeActiveTab}
              showFriendsTab={this.showFriendsTab}
            />
            <PostsTab
              activeTab={this.props.activeTab}
              ref={this.postsTabRef}
              user={this.props.user}
              changeActiveTab={this.props.changeActiveTab}
              showFriendsTab={this.showFriendsTab}
              post_id={this.props.post_id}
            />
          </div>
          {/* ------------------------------------------- overview right over------------------------------------------- */}
        </div>

        <div
          className='modal fade it-sendmodal'
          id='sendMsgModal'
          tabIndex='-1'
          role='dialog'
          aria-labelledby='exampleModalCenterTitle'
          aria-hidden='true'
        >
          <div className='modal-dialog modal-dialog-centered' role='document'>
            <div className='modal-content'>
              <button
                type='button'
                className='btn-close'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <span aria-hidden='true'>&times;</span>
              </button>
              <div className='modal-body'>
                <h5>Send iTi Message to Manfrove Reality</h5>
                <form>
                  <div className='form-group'>
                    <input type='text' className='form-control' placeholder='Subject' />
                  </div>
                  <div className='form-group'>
                    <textarea className='form-control' rows='3' placeholder='Send message...' />
                  </div>
                  <button type='button' className='btn btn-primary'>
                    Send iTi Message
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>

        <div
          className='modal fade it-sendmodal it-sharemodal'
          id='profileInfoShareModal'
          tabIndex='-1'
          role='dialog'
          aria-labelledby='exampleModalCenterTitle'
          aria-hidden='true'
        >
          <div className='modal-dialog modal-dialog-centered' role='document'>
            <div className='modal-content'>
              <button
                type='button'
                className='btn-close'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                {/* <span aria-hidden='true'>&times;</span> */}
              </button>
              <div className='modal-body'>
                <h5>Share {this.props.user.fullname}'s Page</h5>
                <div className='mt-2 mb-4'>
                  <button type='button' className='btn fb-btn'>
                    <FacebookShareButton
                      url={SITE_ADDRESS + PROFILE_ROUTE_CONST + this.props.user.slug}
                    >
                      Share on facebook
                    </FacebookShareButton>
                  </button>
                  <button type='button' className='btn tw-btn'>
                    <TwitterShareButton
                      url={SITE_ADDRESS + PROFILE_ROUTE_CONST + this.props.user.slug}
                    >
                      Share on Twitter
                    </TwitterShareButton>
                  </button>
                </div>
                <form method='post' onSubmit={this.submitShareForm} id='sharedForm'>
                  <div className='form-group'>
                    <input
                      type='text'
                      className='form-control'
                      value={SITE_ADDRESS + PROFILE_ROUTE_CONST + this.props.user.slug}
                      disabled
                    />
                  </div>
                  <div className='it-or-form'>OR </div>

                  <div className='form-group'>
                    <label>Your Name</label>
                    <input
                      type='text'
                      name='name'
                      className='form-control'
                      onChange={this.shareHandleChange}
                    />
                    <span style={{ color: 'red' }}>
                      {this.state.sharedErrors && this.state.sharedErrors.name}
                    </span>
                  </div>
                  <div className='form-group'>
                    <label>Your Email</label>
                    <input
                      type='email'
                      className='form-control'
                      name='from_email'
                      onChange={this.shareHandleChange}
                    />
                    <span style={{ color: 'red' }}>
                      {this.state.sharedErrors && this.state.sharedErrors.fromemail}
                    </span>
                  </div>
                  <div className='form-group'>
                    <label>
                      To <span>Email address(separate with a comma)</span>
                    </label>
                    <input
                      type='email'
                      className='form-control'
                      name='to_emails'
                      multiple
                      onChange={this.shareHandleChange}
                    />
                    <span style={{ color: 'red' }}>
                      {this.state.sharedErrors && this.state.sharedErrors.toemail}
                    </span>
                  </div>

                  <button type='submit' className='btn btn-primary'>
                    Share
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>

        <div
          className='modal fade it-sendmodal'
          id='sendMessageNoticeModal'
          tabIndex='-1'
          role='dialog'
          aria-labelledby='exampleModalCenterTitle'
          aria-hidden='true'
        >
          <div className='modal-dialog modal-dialog-centered' role='document'>
            <div className='modal-content'>
              <button
                type='button'
                className='btn-close'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <span aria-hidden='true'>&times;</span>
              </button>
              <div className='modal-body'>
                <h5>This business has not enabled messaging.</h5>

                <button
                  type='button'
                  className='btn btn-primary'
                  data-bs-dismiss='modal'
                  aria-label='Close'
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
        <ConfirmUnfollowModal
          username={this.props.user?.fullname}
          data={{
            is_following_user: this.state?.is_following_user,
            user_id: this.props.user?.id,
            follow_id: this.state.follow_id,
          }}
          setLoadingFollowStatusChange={(val) => this.setLoadingFollowChangeResponse(val)}
          handler={this.unfollowHandler}
        />
      </div>
    )
  }
}

export default connect(null, null, null, { forwardRef: true })(OverviewGenericTab)
