import log from './logger'

export const customLogger = (error) => {
  handleTokenError(error?.response)
  console.log(error.response)
  log.error(error)
}

export const handleTokenError = (error) => {
  if (checkInvalidToken(error)) {
    window.location = '/auth/logout?auto=true'
  }
}

const checkInvalidToken = (error) => {
  const message = error?.data?.message?.toLowerCase()
  return ['provide token', 'invalid token', 'token invalid'].some((err) => message?.includes(err))
}
