import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import classNames from 'classnames'
import { Field, reduxForm } from 'redux-form'
import moment from 'moment'
import { Link } from 'react-router-dom'
import AppLayout from '../AppLayout'
import * as validation from '../../services/formValidation'
import * as api from '../../services/businessService'
import { updateUserProfile, updateUserCoverImage } from '../../services/authService'
import ReactSelect from '../../services/ReactSelect'
import renderInputField from '../Form/Input'
import renderTextAreaField from '../Form/Textarea'
import Cropperjs from '../Cropper/Cropper'
import * as customFunction from '../../services/customFunction'
import './editbusinessprofile.scss'
import { SITE_ADDRESS } from '../../services/environment'
import CropperCover from '../Cropper/CropperCover'
// import TreeSelect from "../Form/TreeSelect";

import { PROFILE_ROUTE } from '../../services/routeConst'
import ReactStars from 'react-stars'
import { checkUsername } from '../../services/userApiService'
import $ from 'jquery'
import * as environemnt from '../../services/environment'
import axios from 'axios'
import { customLogger } from '../../utils/CustomLogger'
import ChangePassword from '../ChangePassword/ChangePassword'

import {
  DEFAULT_PROFILE_PICTURE,
  DEFAULT_COVER_PICTURE,
  // NO_COVER_PICTURE,
  STAR_RATING_PROFILE_HEADER_SIZE,
  STAR_RATING_iTRUST_ACTIVE_COLOR,
  STAR_RATING_iTRUST_INACTIVE_COLOR,
  STAR_RATING_TRUST_ME_ACTIVE_COLOR,
  STAR_RATING_TRUST_ME_INACTIVE_COLOR,
  PROFILE_ROUTE_CONST,
  relationshipList,
  DUPLICATE_USER,
} from '../../services/const'
import UsernameModal from '../UsernameModal/UsernameModal'
import { galleryCreateCall, validateGalleryFileType } from '../../services/galleryApiService'

let categoryIds = []
class EditBusinessProfile extends Component {
  constructor(props) {
    super(props)
    this.state = {
      userImg: DEFAULT_PROFILE_PICTURE,
      countryList: [],
      stateList: [],
      cityList: [],
      userDetail: [],
      categoryList: [],
      subCategoryList: [],
      subOfSubCategoryList: [],
      subCategoryValue: null,
      subOfSubCategoryValue: null,
      stateValue: null,
      cityValue: null,
      categoryValue: null,
      countryIndex: null,
      zipValue: '',
      userImgFile: '',
      countryObject: '',
      categoryIndex: null,
      relationshipValue: { value: 0, label: 'Select...' },
      gender: '',
      birth_date: '',
      stateLoadingFirstTime: true,
      categoryLoadingFirstTime: true,
      isProfileChange: false,
      userCoverImg: DEFAULT_COVER_PICTURE,
      loadingText: 'submit',
      error: [],
      isUsernamePopupDisplay: false,
      usernameValidation: {
        isMaxLength: false,
        isBeginLatter: false,
        isCharacter: false,
        isDashed: false,
        isSpaceValidation: false,
      },
      is_username_exists: '0',
      username: '',
      cityFlag: false,
      areaFlag: false,
      cityChangedFlag: false,
      is_email_exists: '0',
    }
  }

  areaChangedHandler = (event) => {
    this.setState({ areaFlag: false, cityChangedFlag: true })
  }

  componentWillMount() {
    //this.props.profileInfo(customFunction.userToken());
    this.props.profileInfoReset()
    let user = customFunction.getUser()
    if (user != null && user.user_detail) {
      let obj = {}
      if (user.user_detail.cover_picture != null)
        obj['userImg'] = user.user_detail.profile_picture_large

      if (user.user_detail.cover_picture_medium != null)
        obj['userCoverImg'] = user.user_detail.cover_picture_medium

      this.setState(obj)
    }
  }

  componentDidMount() {
    //$('.it_bus_org_name').focus()
    window.scrollTo({
      top: $('#edit_profile').offset().top - 20,
      behavior: 'smooth',
    })
    this.country()
    //this.categoryList();
    this.categoryListById('category', '0')
  }

  zipcodeChange = (event) => {
    if (
      event.target.value !== '' &&
      this.state.countryObject !== '' &&
      this.state.countryObject.label === environemnt.UNITED_STATE_TEXT
    ) {
      this.setState(
        {
          zipValue: event.target.value,
        },
        this.getDataUsingZip,
      )
    }
  }

  countrySelectHandler = (event) => {
    let countryIndex = this.state.countryList.findIndex((x) => x.value === event.value)
    this.setState(
      {
        stateList: [],
        cityList: [],
        stateValue: null,
        cityValue: null,
        countryIndex: countryIndex,
        stateLoadingFirstTime: false,
      },
      () => {
        this.setState({ countryObject: event })
        if (
          event.value !== '' &&
          this.state.zipValue !== '' &&
          event.label === environemnt.UNITED_STATE_TEXT
        ) {
          this.setState(this.getDataUsingZip)
        } else {
          this.stateHandler(event.value)
        }
      },
    )
    this.setState({ countryObject: event })
    /*210824-1*/
    this.updateCountryDepencency()
  }
  /**
   * Function that return nothing and when country is selected reset all: state,zip, * city (area) and mark them as tocuhed for error. address may be included
   * @author   Amalia
   * @task     210824-1
   */
  updateCountryDepencency = () => {
    this.props.change('state_id', null)
    this.props.touch('state_id', null)
    this.props.change('zipcode', null)
    this.props.touch('zipcode', null)
    this.props.change('city_id', null)
    this.props.touch('city_id', null)
    this.props.change('area', null)
    this.props.touch('area', null)
  }

  // get city and state data using zip code when country is united state
  getDataUsingZip = () => {
    let api_url = environemnt.BASE_API + 'state-cities-from-zipcode'
    var params = {
      zipcode: this.state.zipValue,
      country_id: this.state.countryObject.value,
    }
    var self = this
    axios
      .post(api_url, params, {
        headers: { 'Content-Type': 'application/json' },
      })
      .then(function (response) {
        // set option in state dropdown and select first option as a state value
        var resultData = response.data.data
        if (resultData.state_list.length === 0) {
          self.stateHandler(self.state.countryObject.value)
        } else {
          var stateCopy = []
          resultData.state_list.map((state, index) => {
            let objState = { value: state.id, label: state.name }
            stateCopy.push(objState)
          })
          self.setState(
            {
              stateList: stateCopy,
            },
            () => {
              if (self.state.stateList.length > 0) {
                self.setState({
                  stateValue: self.state.stateList[0].value,
                })
                self.props.change('state_id', self.state.stateList[0])
              }
            },
          )

          var cityCopy = []
          resultData.city_list.map((state, index) => {
            let objCity = { value: state.id, label: state.name }
            cityCopy.push(objCity)
          })
          self.setState(
            {
              cityList: cityCopy,
            },
            () => {
              if (self.state.cityList.length > 0) {
                self.setState({
                  cityValue: self.state.cityList[0].value,
                  cityFlag: true,
                  areaFlag: true,
                })
                // for redux form state update
                self.props.change('city_id', self.state.cityList[0])
              } else {
                self.setState({ cityFlag: false })
              }
            },
          )
        }
      })
      .catch(function (error) {
        customLogger(error)
        //return error.response;
      })
  }

  stateSelectHandler = (event) => {
    this.setState({
      stateValue: event.value,
      cityValue: null,
      stateLoadingFirstTime: false,
    })
    this.city(event.value)
    /*210824-1 when state is selected reset city and mark isas touched for error*/
    this.props.change('city_id', null)
    this.props.touch('city_id')
  }

  citySelectHandler = (event) => {
    this.setState({
      cityValue: event.value,
      areaFlag: true,
      cityChangedFlag: true,
    })
  }

  country = () => {
    let self = this
    let countryArr = []
    api.countryList().then(function (response) {
      if (response.status === true) {
        countryArr = response.countries.map((country) => {
          return { value: country.id, label: country.name }
        })
      }
      self.setState({ countryList: countryArr }, () => {
        self.props.profileInfo(customFunction.userToken())
      })
    })
  }

  async stateHandler(id) {
    var self = this

    await api.stateList(id).then(function (response) {
      if (response.status === true) {
        let stateArr = []
        stateArr = response.states.map((state, index) => {
          if (index === 0) return { value: 0, label: 'Select...' }
          return { value: state.id, label: state.name }
        })

        self.setState({ stateList: stateArr }, () => {
          if (self.state.stateList.length > 0) {
            if (
              Object.keys(self.state.userDetail).length > 0 &&
              self.state.userDetail.address.state_id &&
              self.state.stateLoadingFirstTime
            ) {
              self.setState({
                stateValue: self.state.userDetail.address.state_id,
              })
            } else {
              self.setState({
                stateValue: self.state.stateList[0].value,
              })
            }
          }
        })
      }
    })
  }

  city(id) {
    let self = this
    this.setState({ cityList: [] })

    api.cityList(id).then(function (response) {
      if (response.status === true) {
        let cityArr = []
        response.cities.map((city, index) => {
          if (index === 0) cityArr.push({ value: 0, label: 'Select...' })
          let objCity = { value: city.id, label: city.name }
          cityArr.push(objCity)
        })

        self.setState({ cityList: cityArr }, () => {
          if (self.state.cityList.length > 0) {
            if (
              Object.keys(self.state.userDetail).length > 0 &&
              self.state.userDetail.address.city_id &&
              self.state.stateLoadingFirstTime
            ) {
              self.setState({
                cityValue: self.state.userDetail.address.city_id,
              })
            } else {
              self.setState({
                cityValue: self.state.cityList[0].value,
              })
            }
          }
        })

        if (response.cities.length === 0) {
          self.setState({ cityFlag: true })
        } else {
          self.setState({ cityFlag: false })
        }
      }
    })
  }

  editProfileChangeHandler = (data) => {
    this.setState({
      relationshipValue: { label: data.label, value: data.label },
    })
  }

  userProfileImg = (data, imgFile) => {
    this.setState({
      userImg: data,
      isProfileChange: true,
      userImgFile: imgFile,
    })
  }

  userCoverImgHandler = (data, imgFile) => {
    let self = this
    let token = customFunction.userToken()
    this.setState({ userCoverImg: data })
    let coverObj = { cover_picture: data, token: token }
    updateUserCoverImage(coverObj).then(function (response) {
      if (response.status === true) {
        self.setState({ userCoverImg: response.data.cover_picture_medium })
        const { error, isFormValid, fileType } = validateGalleryFileType(imgFile)
        if (fileType !== '' && error === '' && isFormValid) {
          const formData = new FormData()
          formData.append('file_type', fileType)
          formData.append('file', imgFile)
          galleryCreateCall(formData)
        }
        self.props.coverImageAction(response.data.cover_picture_medium)
      }
    })
  }

  editProfileSubmitHandler = (data) => {
    let self = this
    this.setState({ loadingText: 'Loading...' })

    if (typeof data.city_id === 'object' && data.city_id !== null) data.city_id = data.city_id.value
    if (typeof data.state_id === 'object') data.state_id = data.state_id ? data.state_id.value : ''
    if (typeof data.country_id === 'object')
      data.country_id = data.country_id ? data.country_id.value : ''
    if (typeof data.relationship_type === 'object')
      data.relationship_type = data.relationship_type.value
    if (typeof data.firstname === 'object') data.firstname = data.firstname.value
    if (typeof data.lastname === 'object') data.lastname = data.lastname.value
    if (!this.state.areaFlag && this.state.cityChangedFlag) {
      data.area = data.area
      data.city_id = ''
    }
    if (this.state.areaFlag && this.state.cityChangedFlag) {
      data.area = ''
    }
    if (data.birth_date) data.birth_date = moment(data.birth_date).format('YYYY-MM-DD')
    if (this.state.isProfileChange) data.profile_picture = this.state.userImg

    if (typeof data.category_main_id === 'object' && data.category_main_id.value !== undefined)
      data.category_id = [data.category_main_id.value]
    else data.category_id = [this.state.categoryValue]

    if (
      data.sub_category_id != null &&
      this.state.subCategoryValue != null &&
      this.state.subCategoryValue !== 0 &&
      data.sub_category_id.value !== undefined
    )
      data.category_id.push(data.sub_category_id.value)
    else
      this.state.subCategoryValue != null &&
        this.state.subCategoryValue !== 0 &&
        data.category_id.push(this.state.subCategoryValue)

    if (
      data.sub_of_sub_category_id != null &&
      this.state.subOfSubCategoryValue != null &&
      this.state.subOfSubCategoryValue !== 0 &&
      data.sub_of_sub_category_id.value !== undefined
    )
      data.category_id.push(data.sub_of_sub_category_id.value)
    else
      this.state.subOfSubCategoryValue != null &&
        this.state.subOfSubCategoryValue !== 0 &&
        data.category_id.push(this.state.subOfSubCategoryValue)

    data.token = customFunction.userToken()
    delete data.sponsors_iteam_id

    updateUserProfile(data).then(function (response) {
      self.setState({ isProfileChange: false })
      if (response.status === true) {
        customFunction.toaster('success', response.message)
        customFunction.setUser(response.profileInfo)
        self.setState({
          userImg: response.profileInfo.user_detail.profile_picture_large,
          loadingText: 'Submit',
          userDetail: response.profileInfo,
        })
        const { error, isFormValid, fileType } = validateGalleryFileType(self.state.userImgFile)
        if (fileType !== '' && error === '' && isFormValid) {
          const formData = new FormData()
          formData.append('file_type', fileType)
          formData.append('file', self.state.userImgFile)
          galleryCreateCall(formData)
          self.setState({ userImgFile: '' })
        }
        self.props.userStoreAction({ user: response.profileInfo })
      } else {
        if (
          response.status === 403 &&
          response.data !== undefined &&
          response.data.message !== undefined &&
          response.data.message !== ''
        ) {
          customFunction.toaster('error', response.data.message)
        }
        if (response.status === 422) {
          var errorMessage = 'Please check all required fields'
          var errorKey = ''
          $.each(response.data.errors, function (key, val) {
            errorMessage = val[0]
            errorKey = key
          })
          if (errorKey === 'email') {
            self.setState({ is_email_exists: '1' })
          }
          customFunction.toaster('error', errorMessage)
        } else {
          customFunction.toaster('error', 'Something went wrong')
        }
        self.setState({ loadingText: 'Submit' })
      }
    })
  }

  componentWillReceiveProps = (nextProps) => {
    let userObj = {}

    if (nextProps.editUser.user_detail && nextProps.editUser.user_detail.profile_picture_large)
      userObj['userImg'] = nextProps.editUser.user_detail.profile_picture_large

    if (nextProps.editUser.user_detail && nextProps.editUser.user_detail.cover_picture_medium)
      userObj['userCoverImg'] = nextProps.editUser.user_detail.cover_picture_medium

    if (nextProps.editUser.user_detail && nextProps.editUser.user_detail.birth_date)
      userObj['birth_date'] = nextProps.editUser.user_detail.birth_date

    if (nextProps.editUser.relationship_type && this.state.relationshipValue.value === 0) {
      let relationship = relationshipList.filter(
        (x) => x.value === nextProps.editUser.relationship_type,
      )
      userObj['relationshipValue'] = relationship
    }
    if (nextProps.editUser) userObj['username'] = nextProps.editUser.username

    userObj['userDetail'] = nextProps.editUser

    if (nextProps.editUser.categories && this.state.categoryIndex == null) {
      let categoryIndex = this.state.categoryList.findIndex((stateIndex) =>
        nextProps.editUser.categories.find(
          (stateIndexFilter) => stateIndex.value === stateIndexFilter.category_id,
        ),
      )

      if (categoryIndex !== -1) {
        this.setState(
          {
            categoryIndex: categoryIndex,
            categoryValue: this.state.categoryList[categoryIndex].value,
          },
          () => {
            this.categoryListById(
              'subcategory',
              this.state.categoryList[categoryIndex].value,
              'sub_category_id',
            )
          },
        )
      }
    }
    this.setState(userObj, () => {
      if (
        nextProps.editUser.address &&
        nextProps.editUser.address.country_id &&
        this.state.countryIndex == null
      ) {
        let country = this.state.countryList
        let countryIndex = country.findIndex(
          (x) => x.value === nextProps.editUser.address.country_id,
        )

        let selectedCountryObj = {
          value: nextProps.editUser.address.country.id,
          label: nextProps.editUser.address.country.name,
        }

        this.setState(
          {
            zipValue: nextProps.editUser.address.zipcode,
            countryObject: selectedCountryObj,
            countryIndex: countryIndex,
          },
          () => {
            this.stateHandler(nextProps.editUser.address.country_id)
            this.city(nextProps.editUser.address.state_id)
          },
        )
      }
    })
  }

  categoryList = () => {
    let self = this
    let token = customFunction.userToken()

    api.categoryListSelected(token).then(function (response) {
      // console.log("response", response);
      if (response.status === true) {
        self.setState({ categoryList: response.categories })
      }
    })
  }

  categoryHandler = (currentNode, selectedNodes) => {
    // this.setState({ categoryIds: this.state.categoryIds.concat([data.value]) });
    categoryIds = []

    selectedNodes.map((category) => {
      categoryIds.push(category.value)
    })
    if (categoryIds.length > 0) {
      if (this.state.error.category) this.setState({ error: { category: [''] } })
    }
  }

  changeHandler = (event) => {
    let inputName = event.target.name
    let inputValue = event.target.value
    this.setState({
      [inputName]: inputValue,
    })
    if (inputName === 'username') {
      this.setState({
        is_username_exists: '0',
        usernameValidation: validation.userNameValidation(
          inputValue,
          this.state.usernameValidation,
        ),
      })
    } else if (inputName === 'email') {
      this.setState({ is_email_exists: '0' })
    }
  }

  usernamePopup = () => {
    setTimeout(() => {
      this.setState({
        isUsernamePopupDisplay: !this.state.isUsernamePopupDisplay,
      })
    }, 200)
  }
  checkUser = () => {
    let self = this
    if (validation.percentageOfValidation(this.state.usernameValidation) === 100) {
      checkUsername({
        username: this.state.username,
        id: customFunction.getUser().id,
      }).then(function (response) {
        if (response) self.setState({ is_username_exists: response.data.is_username_exists })
      })
    }
  }
  userNameModel = (data) => () => {
    $('#change_username_modal').modal('hide')
    if (data === 'yes') ReactDOM.findDOMNode(this.usernameRef).focus()
  }
  userDialog = (event) => {
    $('#change_username_modal').modal('show')
  }

  categorySelectHandler = (event) => {
    let categoryIndex = this.state.categoryList.findIndex((x) => x.value === event.value)

    this.setState({
      categoryIndex: categoryIndex,
      categoryValue: event.value,
      subCategoryValue: null,
      subOfSubCategoryValue: null,
      categoryLoadingFirstTime: false,
    })
    this.categoryListById('subcategory', event.value, 'sub_category_id')
  }

  subCategoryChangeHandler = (event) => {
    this.setState({
      subCategoryValue: event.value,
      subOfSubCategoryValue: null,
      categoryLoadingFirstTime: false,
    })
    this.categoryListById('subofsubcategory', event.value, 'sub_of_sub_category_id')
  }

  subOfSubCategoryChangeHandler = (event) => {
    this.setState({
      subOfSubCategoryValue: event.value,
      categoryLoadingFirstTime: false,
    })
  }

  async categoryListById(type, id, subtype = null) {
    let self = this
    let valueKeyList = null
    await api.singleCategoryList(id).then(function (response) {
      if (response.data) {
        let categories = response.data.categories
        let categorylist = []
        categorylist = categories.map((category) => {
          return { value: category.id, label: category.category_name }
        })

        let obj = {}

        if (type === 'category') {
          obj.categoryList = categorylist
          obj.subCategoryList = []
          obj.subOfSubCategoryList = []
          obj.subCategoryValue = null
          obj.subOfSubCategoryValue = null
          self.setState(obj)
        } else if (type === 'subcategory') {
          categorylist.unshift({ value: 0, label: 'Select...' })
          obj.subCategoryList = categorylist
          obj.subOfSubCategoryList = []
          obj.subCategoryValue = categorylist[0].value
          obj.subOfSubCategoryValue = null
          self.setState(obj, () => {
            if (self.state.categoryLoadingFirstTime && self.state.subCategoryList.length > 0) {
              let subCategoryValue = self.state.subCategoryList.filter((stateIndex) => {
                return self.props.editUser.categories.find((stateIndexFilter) => {
                  return stateIndex.value === stateIndexFilter.category_id
                })
              })

              valueKeyList = subCategoryValue
              console.log(valueKeyList)
              if (subCategoryValue.length > 0) {
                self.setState({ subCategoryValue: subCategoryValue[0].value }, () => {
                  self.categoryListById(
                    'subofsubcategory',
                    subCategoryValue[0].value,
                    'sub_of_sub_category_id',
                  )
                })
              }
            }
          })
        } else if (type === 'subofsubcategory') {
          categorylist.unshift({ value: 0, label: 'Select...' })
          obj.subOfSubCategoryList = categorylist
          obj.subOfSubCategoryValue = categorylist[0].value
          self.setState(obj, () => {
            if (self.state.categoryLoadingFirstTime && self.state.subOfSubCategoryList.length > 0) {
              let subOfSubCategoryValue = self.state.subOfSubCategoryList.filter((stateIndex) =>
                self.props.editUser.categories.find(
                  (stateIndexFilter) => stateIndex.value === stateIndexFilter.category_id,
                ),
              )
              valueKeyList = subOfSubCategoryValue
              if (subOfSubCategoryValue.length > 0)
                self.setState({
                  subOfSubCategoryValue: subOfSubCategoryValue[0].value,
                })
            }
          })
        }
      }
    })
    if (subtype) {
      self.state.categoryLoadingFirstTime
        ? this.props.change(subtype, valueKeyList)
        : this.props.change(subtype, null) && this.props.touch(subtype, null)
    }
  }

  subOfSubCategoryProps = (id) => {
    this.categoryListById('subofsubcategory', id, 'sub_of_sub_category_id')
  }

  render() {
    this.props.touch('state_id', null)
    this.props.touch('city_id', null)
    this.props.touch('state_id', null)

    const {
      countryList,
      stateList,
      cityList,
      userDetail,
      categoryList,
      userImg,
      subCategoryList,
      subOfSubCategoryList,
      countryObject,
      subCategoryValue,
      subOfSubCategoryValue,
    } = this.state
    const { invalid, handleSubmit } = this.props
    // console.log(invalid, this.props);
    let self = this
    let usernamenKey = Object.keys(this.state.usernameValidation)
    let usernameFilter = usernamenKey.filter(function (key) {
      return self.state.usernameValidation[key]
    })

    let usernameValidationPercentage = usernameFilter.length * 25

    return (
      <AppLayout>
        <section
          className='cover_details pro_business cover_img'
          style={{
            background: 'url(' + this.state.userCoverImg + ') no-repeat',
          }}
        >
          <div className='badge_blk editprofile1 d-lg-none'>
            <div className='badge_content'>
              <div className='badge yellow'>
                <div className='b-triangle' />
                <div className='b-inner'>
                  <h4>Trust Me</h4>
                  <div className='d1'>
                    <div className='digits_cat'>
                      <div className='digit'>{userDetail.total_trust_me}</div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='badge_star'>
                <Link to={PROFILE_ROUTE + '#review'} className='nav-link'>
                  {userDetail.total_review} Reviews
                </Link>
                <ul className='d-flex'>
                  <ReactStars
                    edit={false}
                    size={STAR_RATING_PROFILE_HEADER_SIZE}
                    color1={STAR_RATING_TRUST_ME_INACTIVE_COLOR}
                    color2={STAR_RATING_TRUST_ME_ACTIVE_COLOR}
                    value={userDetail.average_review}
                  />
                </ul>
              </div>
            </div>
            <div className='badge_content'>
              <div className='badge'>
                <div className='b-triangle' />
                <div className='b-inner'>
                  <h4>iTRUST</h4>
                  <div className='d1'>
                    <div className='digits_cat'>
                      <div className='digit'>{userDetail.total_trusted}</div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='badge_star'>
                <Link to={PROFILE_ROUTE + '#review'} className='nav-link'>
                  {userDetail.total_given_review} Reviews
                </Link>
                <ul className='d-flex'>
                  <ReactStars
                    edit={false}
                    size={STAR_RATING_PROFILE_HEADER_SIZE}
                    color1={STAR_RATING_iTRUST_INACTIVE_COLOR}
                    color2={STAR_RATING_iTRUST_ACTIVE_COLOR}
                    value={userDetail.average_given_review}
                  />
                </ul>
              </div>
            </div>
          </div>
          <button
            data-bs-toggle='modal'
            className='croppercovermodal'
            data-bs-target='#cropperCoverModal'
          >
            <i>
              <img src='img/camera.png' />
            </i>
            <span>Update cover photo </span>
          </button>
          <CropperCover srcData={this.userCoverImgHandler} imgcoversrc={this.state.userCoverImg} />
          <div className='container'>
            <div className='cover_inner'>
              <div className={'pro_name_detail d-flex ' + userDetail.user_type + '_profile'}>
                <div className='pro_1'>
                  <img src={userImg} alt='' />
                </div>
                <div className='pro_name'>
                  <h3 className='blue'>
                    {userDetail && userDetail.user_type === 'individual'
                      ? userDetail.fullname
                      : userDetail.business_organization_name}
                  </h3>
                  {/* <p>The power of positive real estate - Best of the beach!</p> */}
                  {/* <a href={null} className="text-uppercase edit_profile">
                                        Edit profile
                                    </a> */}
                </div>
              </div>
              <div className='badge_blk editprofile1 d-none d-lg-flex'>
                <div className='badge_content'>
                  <div className='badge yellow'>
                    <div className='b-triangle' />
                    <div className='b-inner'>
                      <h4>Trust Me</h4>
                      <div className='d1'>
                        <div className='digits_cat'>
                          <div className='digit'>{userDetail.total_trust_me}</div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='badge_star'>
                    <Link to={PROFILE_ROUTE + '#review'} className='nav-link'>
                      {userDetail.total_review} Reviews
                    </Link>
                    <ul className='d-flex'>
                      <ReactStars
                        edit={false}
                        size={STAR_RATING_PROFILE_HEADER_SIZE}
                        color1={STAR_RATING_TRUST_ME_INACTIVE_COLOR}
                        color2={STAR_RATING_TRUST_ME_ACTIVE_COLOR}
                        value={userDetail.average_review}
                      />
                    </ul>
                  </div>
                </div>
                <div className='badge_content'>
                  <div className='badge'>
                    <div className='b-triangle' />
                    <div className='b-inner'>
                      <h4>iTRUST</h4>
                      <div className='d1'>
                        <div className='digits_cat'>
                          <div className='digit'>{userDetail.total_trusted}</div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='badge_star'>
                    <Link to={PROFILE_ROUTE + '#review'} className='nav-link'>
                      {userDetail.total_given_review} Reviews
                    </Link>
                    <ul className='d-flex'>
                      <ReactStars
                        edit={false}
                        size={STAR_RATING_PROFILE_HEADER_SIZE}
                        color1={STAR_RATING_iTRUST_INACTIVE_COLOR}
                        color2={STAR_RATING_iTRUST_ACTIVE_COLOR}
                        value={userDetail.average_given_review}
                      />
                    </ul>
                  </div>
                </div>
              </div>
              <div className='cover_bottom'>
                <div className='left_link'>
                  <ul className='upper-tabs nav nav-tabs'>
                    <li className='nav-item'>
                      <Link className='nav-link' to={PROFILE_ROUTE + '#overview'}>
                        Overview
                      </Link>
                    </li>
                    <li className='nav-item'>
                      <Link className='nav-link' to={PROFILE_ROUTE + '#trust'}>
                        Trust List ({userDetail.total_trust_me + userDetail.total_trusted})
                      </Link>
                    </li>
                    <li className='nav-item'>
                      <Link className='nav-link' to={PROFILE_ROUTE + '#gallery'}>
                        Gallery(
                        {userDetail.total_galleries})
                      </Link>
                    </li>
                    <li className='nav-item'>
                      <Link className='nav-link' to={PROFILE_ROUTE + '#friend'}>
                        Friends ({userDetail.total_friends})
                      </Link>
                    </li>
                    <li className='nav-item'>
                      <Link className='nav-link' to={PROFILE_ROUTE + '#activity'}>
                        Activity
                      </Link>
                    </li>
                    <li className='nav-item'>
                      <Link className='nav-link' to={PROFILE_ROUTE + '#invite_center'}>
                        Invite Center
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='listing_block edit_profile'>
          <div className='container' id='edit_profile'>
            <h4 className='varela'>Edit profile</h4>
            <form method='post' onSubmit={handleSubmit(this.editProfileSubmitHandler).bind(this)}>
              <div className='bg-white blue-upper-line'>
                <div className='fields'>
                  <h5>GENERAL INFORMATION</h5>
                  <label className='upload' data-bs-toggle='modal' data-bs-target='#cropperModal'>
                    {/* <input type="file" className="file" name="zip_file_import" /> */}
                    <div className={'pro_1 ' + userDetail.user_type + '_profile'}>
                      <span>Update profile picture</span>
                      <img
                        src={
                          this.state.userImg != null ? this.state.userImg : DEFAULT_PROFILE_PICTURE
                        }
                        alt=''
                      />
                    </div>
                  </label>
                  <Cropperjs
                    srcData={this.userProfileImg}
                    imgsrc={this.state.userImg}
                    updateProfile={handleSubmit(this.editProfileSubmitHandler).bind(this)}
                  />
                  <fieldset>
                    <div className='row fieldflex form-group'>
                      <div className='col-md-4'>
                        {console.log(
                          this.props,
                          this.props.submitFailed,
                          this.state.is_username_exists,
                          this.props.invalid,
                        )}
                        {/*210824-1*/}
                        {validation.isFormValid(
                          this.props.submitFailed,
                          this.state.is_username_exists,
                          invalid,
                          ['text-danger'],
                          '',
                          true,
                          this.state.is_email_exists,
                        )}
                        <input
                          type='submit'
                          className={classNames('blue_btn mt-0 mb-3')}
                          value={this.state.loadingText}
                          disabled={this.state.is_username_exists === '1' || invalid}
                        />
                      </div>
                    </div>
                    <div className='row fieldflex form-group'>
                      <div className='col-md-3 control-required'>iTeam ID #:</div>
                      <div className='col-md-9'>
                        <input
                          name='sponsors_iteam_id'
                          type='text'
                          placeholder=''
                          className='form-control'
                          autoComplete='off'
                          readOnly
                          value={userDetail.imatrix_id != null ? userDetail.imatrix_id : ''}
                        />
                      </div>
                    </div>
                    <div className='row fieldflex form-group'>
                      <div className='col-md-3 control-required'>Business Name:</div>
                      <div className='col-md-9'>
                        <Field
                          name='business_organization_name'
                          type='text'
                          component={renderInputField}
                          placeholder=''
                          className='form-control it_bus_org_name'
                          validate={[validation.required]}
                        />
                      </div>
                    </div>
                    <div className='row fieldflex form-group'>
                      <div className='col-md-3'>Tagline:</div>
                      <div className='col-md-9'>
                        <Field
                          name='tagline'
                          component={renderInputField}
                          type='text'
                          className='form-control'
                          maxLength={80}
                        />
                      </div>
                    </div>
                    <div className='row fieldflex form-group'>
                      <div className='col-md-3'></div>
                      <div className='col-md-9'>
                        <a
                          className='link'
                          data-bs-toggle='modal'
                          data-bs-target='#change_password_email_modal'
                        >
                          Change Password
                        </a>
                      </div>
                    </div>
                  </fieldset>
                </div>
              </div>
              <div className='bg-white blue-upper-line'>
                <div className='fields'>
                  <h5>USER INFO</h5>

                  <fieldset>
                    <div className='row fieldflex form-group'>
                      <div className='col-md-3 control-required'>First name:</div>
                      <div className='col-md-9'>
                        <Field
                          name='firstname'
                          type='text'
                          className='form-control'
                          component={renderInputField}
                          validate={[validation.required]}
                          label='Firstname'
                          placeholder='Enter first name'
                          autoComplete='off'
                        />
                      </div>
                    </div>
                    <div className='row fieldflex form-group'>
                      <div className='col-md-3 control-required'>Last name:</div>
                      <div className='col-md-9'>
                        <Field
                          name='lastname'
                          type='text'
                          className='form-control'
                          component={renderInputField}
                          validate={[validation.required]}
                          label='Lastname'
                          placeholder='Enter last name'
                          autoComplete='off'
                        />
                      </div>
                    </div>
                    <div className='row fieldflex form-group'>
                      <div className='col-md-3 control-required'>Relationship to Business:</div>
                      <div className='col-md-9'>
                        <div className='for-select'>
                          <Field
                            type='text'
                            component={ReactSelect}
                            name='relationship_type'
                            options={relationshipList}
                            validate={[validation.required]}
                            isSelect='false'
                            valueKey={this.state.relationshipValue}
                            onChange={this.editProfileChangeHandler}
                          />
                        </div>
                      </div>
                    </div>
                    <div className='row fieldflex form-group'>
                      <div className='col-md-3 control-required'>Email Address:</div>
                      <div className='col-md-9'>
                        <Field
                          name='email'
                          component={renderInputField}
                          type='text'
                          className='form-control'
                          onChange={this.changeHandler}
                          validate={[validation.required]}
                        />
                        {this.state.is_email_exists === '1' && (
                          <span className='input_error text-danger'>
                            Email already used for a registered account, please use a different
                            email.
                          </span>
                        )}
                      </div>
                    </div>
                    <div className='row fieldflex form-group'>
                      <div className='col-md-3'>
                        <label className='control-required'>Username:</label>
                      </div>
                      <div className='col-md-9 password_criteria'>
                        <Field
                          name='username'
                          type='text'
                          className='form-control password1'
                          component={renderInputField}
                          onFocus={(event) => {
                            this.usernamePopup(event)
                            this.userDialog(event)
                          }}
                          onChange={this.changeHandler}
                          onBlur={(event) => {
                            this.usernamePopup()
                            this.checkUser()
                          }}
                          validate={[
                            validation.required,
                            validation.minValue1,
                            validation.noUsernameSpace,
                            validation.onylCharacter,
                            validation.maxLength20,
                            validation.isBeginLatter,
                            validation.isDashed,
                            validation.isDashedStart,
                          ]}
                          ref={(ref) => {
                            this.usernameRef = ref
                          }}
                          label='Password'
                          placeholder='Enter your username'
                          autoComplete='off'
                        />
                        {this.state.is_username_exists === '1' && (
                          <span className='input_error text-danger'>{DUPLICATE_USER}</span>
                        )}
                        {this.state.isUsernamePopupDisplay && (
                          <div className='criteria'>
                            <div className='progress'>
                              <div
                                className='progress-bar'
                                role='progressbar'
                                aria-valuenow='0'
                                aria-valuemin='0'
                                aria-valuemax='100'
                                style={{
                                  width: usernameValidationPercentage + '%',
                                }}
                              >
                                <span className='sr-only'>70% Complete</span>
                              </div>
                            </div>
                            <h3> Criteria: </h3>
                            <ul>
                              <li
                                className={
                                  this.state.usernameValidation.isSpaceValidation ? 'success' : ''
                                }
                              >
                                No Spaces, cannot start or end with a space
                              </li>
                              <li
                                className={
                                  this.state.usernameValidation.isMaxLength ? 'success' : ''
                                }
                              >
                                Username MUST be between 1 and 20 characters
                              </li>
                              <li
                                className={
                                  this.state.usernameValidation.isBeginLatter ? 'success' : ''
                                }
                              >
                                Username MUST begin with a LETTER
                              </li>
                              <li
                                className={
                                  this.state.usernameValidation.isCharacter ? 'success' : ''
                                }
                              >
                                Only Characters A-Z a-z and 0-9 are allowed
                              </li>
                              <li
                                className={this.state.usernameValidation.isDashed ? 'success' : ''}
                              >
                                Dash is allowed BUT MUST be surrounded by letters
                              </li>
                            </ul>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className='row fieldflex form-group'>
                      <div className='col-md-3'>iTRUSTit Web Address:</div>
                      <div className='col-md-9'>
                        <input
                          name='webaddress'
                          type='text'
                          placeholder={SITE_ADDRESS}
                          className='form-control'
                          value={
                            SITE_ADDRESS +
                            PROFILE_ROUTE_CONST +
                            (userDetail.slug != null ? userDetail.slug : '')
                          }
                          readOnly
                        />
                      </div>
                    </div>
                  </fieldset>
                </div>
              </div>
              <div className='bg-white blue-upper-line'>
                <div className='fields'>
                  <h5>ADDRESS INFORMATION</h5>

                  <fieldset>
                    <div className='row fieldflex form-group'>
                      <div className='col-md-3 control-required'>Country:</div>
                      <div className='col-md-9'>
                        <div className='for-select'>
                          <Field
                            type='text'
                            component={ReactSelect}
                            name='country_id'
                            options={countryList}
                            onChange={this.countrySelectHandler}
                            validate={[validation.required]}
                            isSelect='false'
                            valueKey={
                              countryList.length > 0 && countryList[this.state.countryIndex]
                            }
                          />
                        </div>
                      </div>
                    </div>
                    {countryObject ? (
                      <div className='row fieldflex form-group'>
                        <div className='col-md-3 control-required'>Zip / Postal Code:</div>
                        <div className='col-md-9'>
                          <Field
                            name='zipcode'
                            component={renderInputField}
                            type='text'
                            className='form-control'
                            validate={[validation.required]}
                            onBlur={this.zipcodeChange}
                          />
                        </div>
                      </div>
                    ) : (
                      ''
                    )}
                    {stateList.length > 0 ? (
                      <div className='row fieldflex form-group'>
                        <div className='col-md-3 control-required'>State:</div>
                        <div className='col-md-9'>
                          <div className='for-select'>
                            <Field
                              type='text'
                              className='form-control'
                              component={ReactSelect}
                              name='state_id'
                              options={stateList}
                              value={this.state.stateValue}
                              onChange={this.stateSelectHandler}
                              isSelect='true'
                              valueKey={this.state.stateValue}
                              validate={[validation.required]}
                            />
                          </div>
                        </div>
                      </div>
                    ) : (
                      ''
                    )}
                    {cityList.length > 0 ? (
                      <div className='row fieldflex form-group'>
                        <div className='col-md-3 control-required'>City:</div>
                        <div className='col-md-9'>
                          <div className='for-select'>
                            <Field
                              type='text'
                              className='form-control'
                              component={ReactSelect}
                              name='city_id'
                              options={cityList}
                              value={this.state.cityValue}
                              onChange={this.citySelectHandler}
                              valueKey={this.state.cityValue}
                              isSelect='true'
                              validate={[validation.required]}
                            />
                          </div>
                        </div>
                      </div>
                    ) : this.state.cityFlag ? (
                      <div className='row fieldflex form-group'>
                        <div className='col-md-3'>
                          <label className='control-required'>City/Area:</label>
                        </div>
                        <div className='col-md-9'>
                          <Field
                            name='area'
                            type='text'
                            className='form-control'
                            component={renderInputField}
                            label='City/Area'
                            placeholder='Enter city/area'
                            autoComplete='off'
                            validate={[validation.required]}
                            onChange={this.areaChangedHandler}
                          />
                        </div>
                      </div>
                    ) : (
                      ''
                    )}
                    <div className='row fieldflex form-group'>
                      <div className='col-md-3 control-required'>Address 1:</div>
                      <div className='col-md-9'>
                        <Field
                          name='address_line_1'
                          component={renderInputField}
                          type='text'
                          className='form-control'
                          validate={[validation.required]}
                        />
                      </div>
                    </div>
                    <div className='row fieldflex form-group'>
                      <div className='col-md-3'>Address 2:</div>
                      <div className='col-md-9'>
                        <Field
                          name='address_line_2'
                          component={renderInputField}
                          type='text'
                          className='form-control'
                          //validate={[validation.required]}
                        />
                      </div>
                    </div>

                    <div className='row fieldflex form-group'>
                      <div className='col-md-3'>Main phone (Day):</div>
                      <div className='col-md-9'>
                        <Field
                          name='phone_no_1'
                          component={renderInputField}
                          type='text'
                          className='form-control'
                          // validate={[validation.phoneNumber]}
                        />
                      </div>
                    </div>
                    <div className='row fieldflex form-group'>
                      <div className='col-md-3'>Main phone (Evening):</div>
                      <div className='col-md-9'>
                        <Field
                          name='phone_no_2'
                          component={renderInputField}
                          type='text'
                          className='form-control'
                          // validate={[validation.phoneNumber]}
                        />
                      </div>
                    </div>
                    <div className='row fieldflex form-group'>
                      <div className='col-md-3'>Mobile:</div>
                      <div className='col-md-9'>
                        <Field
                          name='mobile_no'
                          component={renderInputField}
                          type='text'
                          className='form-control'
                          // validate={[validation.phoneNumber]}
                        />
                      </div>
                    </div>
                    <div className='row fieldflex form-group'>
                      <div className='col-md-3'>Fax:</div>
                      <div className='col-md-9'>
                        <Field
                          name='fax_no'
                          component={renderInputField}
                          type='text'
                          className='form-control'
                        />
                      </div>
                    </div>
                    <div className='row fieldflex form-group'>
                      <div className='col-md-3'>Email2:</div>
                      <div className='col-md-9'>
                        <Field
                          name='email_2'
                          component={renderInputField}
                          type='text'
                          className='form-control'
                          validate={[validation.email]}
                        />
                      </div>
                    </div>
                    <div className='row fieldflex form-group'>
                      <div className='col-md-3'>Email3:</div>
                      <div className='col-md-9'>
                        <Field
                          name='email_3'
                          component={renderInputField}
                          type='text'
                          className='form-control'
                          validate={[validation.email]}
                        />
                      </div>
                    </div>
                    <div className='row fieldflex form-group'>
                      <div className='col-md-3'>Email4:</div>
                      <div className='col-md-9'>
                        <Field
                          name='email_4'
                          component={renderInputField}
                          type='text'
                          className='form-control'
                          validate={[validation.email]}
                        />
                      </div>
                    </div>
                    <div className='row fieldflex form-group'>
                      <div className='col-md-3'>Website1:</div>
                      <div className='col-md-9'>
                        <Field
                          name='website_1'
                          component={renderInputField}
                          type='text'
                          className='form-control'
                          validate={[validation.urlValidate]}
                        />
                      </div>
                    </div>
                    <div className='row fieldflex form-group'>
                      <div className='col-md-3'>Website2:</div>
                      <div className='col-md-9'>
                        <Field
                          name='website_2'
                          component={renderInputField}
                          type='text'
                          className='form-control'
                          validate={[validation.urlValidate]}
                        />
                      </div>
                    </div>
                    {/* <div className='row fieldflex form-group'>
                      <div className='col-md-3'>Website3:</div>
                      <div className='col-md-9'>
                        <Field
                          name='website_3'
                          component={renderInputField}
                          type='text'
                          className='form-control'
                          validate={[validation.urlValidate]}
                        />
                      </div>
                    </div>
                    <div className='row fieldflex form-group'>
                      <div className='col-md-3'>Website4:</div>
                      <div className='col-md-9'>
                        <Field
                          name='website_4'
                          component={renderInputField}
                          type='text'
                          className='form-control'
                          validate={[validation.urlValidate]}
                        />
                      </div>
                    </div> */}
                    <div className='row fieldflex form-group'>
                      <div className='col-md-3'>Facebook:</div>
                      <div className='col-md-9'>
                        <Field
                          name='facebook'
                          component={renderInputField}
                          type='text'
                          className='form-control'
                          validate={[validation.urlValidate]}
                        />
                      </div>
                    </div>
                    <div className='row fieldflex form-group'>
                      <div className='col-md-3'>Fiverr:</div>
                      <div className='col-md-9'>
                        <Field
                          name='fiverr'
                          component={renderInputField}
                          type='text'
                          className='form-control'
                          validate={[validation.urlValidate]}
                        />
                      </div>
                    </div>
                    <div className='row fieldflex form-group'>
                      <div className='col-md-3'>Instagram:</div>
                      <div className='col-md-9'>
                        <Field
                          name='instagram'
                          component={renderInputField}
                          type='text'
                          className='form-control'
                          validate={[validation.urlValidate]}
                        />
                      </div>
                    </div>
                    <div className='row fieldflex form-group'>
                      <div className='col-md-3'>LinkedIn:</div>
                      <div className='col-md-9'>
                        <Field
                          name='linkedin'
                          component={renderInputField}
                          type='text'
                          className='form-control'
                          validate={[validation.urlValidate]}
                        />
                      </div>
                    </div>
                    <div className='row fieldflex form-group'>
                      <div className='col-md-3'>Pinterest:</div>
                      <div className='col-md-9'>
                        <Field
                          name='pinterest'
                          component={renderInputField}
                          type='text'
                          className='form-control'
                          validate={[validation.urlValidate]}
                        />
                      </div>
                    </div>
                    <div className='row fieldflex form-group'>
                      <div className='col-md-3'>TikTok:</div>
                      <div className='col-md-9'>
                        <Field
                          name='tiktok'
                          component={renderInputField}
                          type='text'
                          className='form-control'
                          validate={[validation.urlValidate]}
                        />
                      </div>
                    </div>
                    <div className='row fieldflex form-group'>
                      <div className='col-md-3'>Twitter:</div>
                      <div className='col-md-9'>
                        <Field
                          name='twitter'
                          component={renderInputField}
                          type='text'
                          className='form-control'
                          validate={[validation.urlValidate]}
                        />
                      </div>
                    </div>
                    <div className='row fieldflex form-group'>
                      <div className='col-md-3'>YouTube:</div>
                      <div className='col-md-9'>
                        <Field
                          name='youtube'
                          component={renderInputField}
                          type='text'
                          className='form-control'
                          validate={[validation.urlValidate]}
                        />
                      </div>
                    </div>
                    <div className='row fieldflex form-group'>
                      <div className='col-md-3'>WhatsApp:</div>
                      <div className='col-md-9'>
                        <Field
                          name='whatsapp'
                          component={renderInputField}
                          type='text'
                          className='form-control'
                          validate={[validation.urlValidate]}
                        />
                      </div>
                    </div>
                    <div className='row fieldflex form-group'>
                      <div className='col-md-3'>PayPal:</div>
                      <div className='col-md-9'>
                        <Field
                          name='paypal'
                          component={renderInputField}
                          type='text'
                          className='form-control'
                          validate={[validation.urlValidate]}
                        />
                      </div>
                    </div>
                    <div className='row fieldflex form-group'>
                      <div className='col-md-3'>Venmo:</div>
                      <div className='col-md-9'>
                        <Field
                          name='venmo'
                          component={renderInputField}
                          type='text'
                          className='form-control'
                          validate={[validation.urlValidate]}
                        />
                      </div>
                    </div>
                  </fieldset>
                </div>
              </div>
              <div className='bg-white blue-upper-line'>
                <div className='fields'>
                  <h5>ADDITIONAL INFORMATION</h5>

                  <fieldset>
                    <div className='row fieldflex form-group'>
                      <div className='col-md-3'>About Me</div>
                      <div className='col-md-9'>
                        <Field
                          name='about_me'
                          component={renderTextAreaField}
                          valueKey={
                            userDetail.user_detail &&
                            userDetail.user_detail.about_me &&
                            userDetail.user_detail.about_me
                          }
                        />
                      </div>
                    </div>
                  </fieldset>
                </div>
              </div>
              <div className='bg-white blue-upper-line '>
                <div className='row fieldflex form-group mt-4'>
                  <div className='col-md-3'>
                    <label className='control-required'>Category:</label>
                  </div>
                  <div className='col-md-6'>
                    <div className='for-select'>
                      <Field
                        type='text'
                        component={ReactSelect}
                        name='category_main_id'
                        options={categoryList}
                        validate={[validation.required]}
                        isSelect='true'
                        valueKey={this.state.categoryValue}
                        onChange={this.categorySelectHandler}
                      />
                    </div>
                  </div>
                </div>
                {subCategoryList.length > 0 ? (
                  <div className='row fieldflex form-group'>
                    <div className='col-md-3'></div>
                    <div className='col-md-6'>
                      <div className='for-select'>
                        <Field
                          type='text'
                          component={ReactSelect}
                          name='sub_category_id'
                          options={subCategoryList}
                          valueKey={subCategoryValue}
                          //210824-1 -not required
                          //validate={[validation.required]}
                          isSelect='true'
                          onChange={this.subCategoryChangeHandler}
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  ''
                )}
                {subOfSubCategoryList.length > 1 ? (
                  <div className='row fieldflex form-group'>
                    <div className='col-md-3'></div>
                    <div className='col-md-6'>
                      <div className='for-select'>
                        <Field
                          type='text'
                          component={ReactSelect}
                          name='sub_of_sub_category_id'
                          valueKey={subOfSubCategoryValue}
                          options={subOfSubCategoryList}
                          //210824-1 -not required
                          //validate={[validation.required]}
                          isSelect='true'
                          onChange={this.subOfSubCategoryChangeHandler}
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  ''
                )}
                {/*210824-1*/}
                {console.log(
                  this.props,
                  this.props.submitFailed,
                  this.state.is_username_exists,
                  this.props.invalid,
                )}
                {validation.isFormValid(
                  this.props.submitFailed,
                  this.state.is_username_exists,
                  invalid,
                  ['text-danger', 'text-center', 'mb-0'],
                  '',
                  true,
                  this.state.is_email_exists,
                )}
                <input
                  type='submit'
                  className={classNames('blue_btn')}
                  value={this.state.loadingText}
                  disabled={this.state.is_username_exists === '1' || invalid}
                />
              </div>
            </form>
          </div>
        </section>
        <UsernameModal usernameModel={this.userNameModel} />

        <ChangePassword />
        {/* <section className="footer_top_blk">
                    <div className="container">
                        <div className="footer_container">
                            <p className="varela">
                                Not here? Tell us what we're missing. If the business you're looking for isn't here, add
                                it!
                            </p>
                            <a href={null} className="blue_btn">
                                ADD BUSINESS
                            </a>
                        </div>
                    </div>
                </section> */}
      </AppLayout>
    )
  }
}

EditBusinessProfile = reduxForm({
  form: 'editBusinessProfileForm',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  updateUnregisteredFields: true,
})(EditBusinessProfile)
export default EditBusinessProfile
