import React from 'react'

const SearchResultsHeader = ({
  isMobile,
  totalRecords,
  totalFilteredRecords,
  isSearching,
  showingFrom,
  showingTo,
  businessOrganizationNameSearch,
  citySearch,
  searchedAddress,
  userIDs,
  useGlobalSearch,
  cityOverride,
}) => {
  const Wrapper = ({ children }) => (isMobile ? <h6>{children}</h6> : <h2>{children}</h2>)
  return (
    <>
      {totalRecords > 0 && totalFilteredRecords > 0 && isSearching === false ? (
        <>
          <div className='d-flex search_top_header'>
            <h2 className='varela search-title'>
              {businessOrganizationNameSearch && (
                <span>
                  Showing {useGlobalSearch ? 'all ' : ''}results for{' '}
                  <strong>{businessOrganizationNameSearch}</strong>
                </span>
              )}
              {!useGlobalSearch && citySearch && businessOrganizationNameSearch && (
                <span> near {searchedAddress ?? citySearch}</span>
              )}
            </h2>
            <span className='showing'>
              Showing {showingFrom} - {showingTo} of {userIDs.length || 0}
            </span>
          </div>
          {cityOverride ? (
            <div>
              <small className='search-message-expanded-results'>
                We couldn't find any results in your initial search, so we expanded it to show all
                results in <strong style={{ color: '#544cf9' }}>{cityOverride}</strong>
              </small>
            </div>
          ) : null}
        </>
      ) : isSearching ? (
        <div className='d-flex search_top_header'>
          {' '}
          <Wrapper>
            <strong>{useGlobalSearch ? 'Finding all results for' : 'Searching for'} </strong>
            {businessOrganizationNameSearch && <strong>{businessOrganizationNameSearch} </strong>}
            {!useGlobalSearch && citySearch && businessOrganizationNameSearch && (
              <span>near {searchedAddress ?? citySearch}</span>
            )}
            <strong> ....</strong>
          </Wrapper>
        </div>
      ) : (
        <>
          <div className='d-flex search_top_header'>
            <Wrapper className='varela search-title'>
              {businessOrganizationNameSearch && (
                <strong>No Results for {businessOrganizationNameSearch} </strong>
              )}
              {!useGlobalSearch && citySearch && businessOrganizationNameSearch && (
                <span>near {searchedAddress ?? citySearch}</span>
              )}
            </Wrapper>
          </div>
          {cityOverride ? (
            <div>
              <small className='search-message-expanded-results'>
                We couldn't find any results in your initial search, so we expanded it to try
                finding results in <strong style={{ color: '#544cf9' }}>{cityOverride}</strong>
              </small>
            </div>
          ) : null}
        </>
      )}
    </>
  )
}

export default SearchResultsHeader
