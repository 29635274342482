import React, { Fragment } from 'react'

const renderTextAreaField = ({ input, placeholder, meta: { touched, error, warning } }) => (
  <Fragment>
    <textarea {...input} placeholder={placeholder} />
    {touched && ((error && <span>{error}</span>) || (warning && <span>{warning}</span>))}
  </Fragment>
)

export default renderTextAreaField
