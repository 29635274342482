import React, { Component } from 'react'
import CompanyDetails from './CompanyDetails/CompanyDetails'
import Gallery from './Gallery/Gallery'
import Reviews from './Reviews/Reviews'

class OverviewTab extends Component {
  constructor(props) {
    super(props)

    this.overviewTabCompanyDetailsSectionRef = React.createRef()
  }

  profileHeader_overview_tab_click_Handle() {
    this.overviewTabCompanyDetailsSectionRef.current.profileHeader_overview_tab_click_Handle()
  }

  showReviewTab2 = (data) => {
    this.props.showReviewTab2(data)
  }

  render() {
    return (
      <div
        id='overview'
        className={'tab-pane ' + (this.props.activeTab === '#overview' ? 'active' : 'fade')}
      >
        <div className='bg-white'>
          <CompanyDetails ref={this.overviewTabCompanyDetailsSectionRef} />
          <Gallery />
          <Reviews showReviewTab2={this.showReviewTab2} user={this.props.user} />
        </div>{' '}
      </div>
    )
  }
}

export default OverviewTab
