import React from 'react'
import { friendCreateCall, friendChangeStatusCall } from '../../../services/friendApiService'

const FriendButton = ({
  confirmAddRemove,
  friendAddRemoveFriendHandle,
  user,
  isLoggedIn = true,
}) => {
  const confirmFriendAddRemove = async (needsConfirmation = false) => {
    const data = {
      is_friend_user: user.is_friend_user,
      id: user.id,
      friendship_id: user.friendship_id,
    }
    needsConfirmation
      ? confirmAddRemove(needsConfirmation, data, 'header')
      : await friendAddRemove(data)
  }

  const friendAddRemove = async (data) => {
    if (data.is_friend_user === 0) {
      let request = { user_id: data.id }
      const response = await friendCreateCall(request)
      response?.data?.friendship_id && friendAddRemoveFriendHandle(data)
    } else if (data.is_friend_user === 1 || user?.friendship_id > 0) {
      let request = { friendship_id: data.friendship_id, status: 'unfriend' }
      const response = await friendChangeStatusCall(request)
      response?.status === 200 && friendAddRemoveFriendHandle(data)
    }
  }

  const buttonProps = isLoggedIn
    ? {
        onClick: () =>
          confirmFriendAddRemove(user?.is_friend_user === 1 || user?.friendship_id > 0),
      }
    : {
        onClick: () => {},
        'data-bs-toggle': 'modal',
        'data-bs-target': '#login_modal',
      }

  return (
    <button
      {...buttonProps}
      key='friend-btn'
      className={`green
        ${
          user?.is_friend_user === 2
            ? ' green-pending'
            : user?.friendship_id > 0 || user?.is_friend_user === 1
            ? ' friend-fill'
            : ''
        }`}
    >
      <i
        aria-hidden='true'
        className={`fas fa-${user?.is_friend_user === 1 ? 'check' : 'plus'} me-1`}
      />
      Friend{user?.is_friend_user === 1 ? 's' : ''}
    </button>
  )
}

export default FriendButton
