import React from 'react'
import $ from 'jquery'
import QRCode from './QRCode'

export const showQRCode = () => {
  $('#qr_code_modal').modal('show')
}

const QRCodeModal = ({ user }) => {
  const baseURL = window?.location?.origin || 'https://www.itrustit.com'
  const inviteLink = `${baseURL}/invite/${user?.imatrix_id}`
  return (
    <div className='modal fade qr_code_modal' id='qr_code_modal' role='dialog'>
      <div className='modal-dialog'>
        <div className='modal-content'>
          <div className='modal-header align-items-start'>
            <h5>Your QR Code</h5>
            <button type='button' className='btn-close' data-bs-dismiss='modal'></button>
          </div>
          <div className='modal-body' style={{ textAlign: 'center' }}>
            <QRCode
              value={inviteLink}
              logoImage={process.env.PUBLIC_URL + '/img/iti_qr_logo.png'}
              size={300}
              qrStyle='dots'
              // fgColor='#006699'
              fgColor='black'
              ecLevel='H'
              eyeRadius={[
                { outer: 10 }, // top/left eye
                { outer: 10 }, // top/right eye
                { outer: 10 }, // bottom/left eye
              ]}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default QRCodeModal
