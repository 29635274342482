import React, { Component } from 'react'
import AppLayout from '../AppLayout'

class ProfileBusiness extends Component {
  render() {
    return (
      <AppLayout>
        <section className='cover_details pro_business'>
          <div className='container'>
            <div className='cover_inner'>
              <div className='pro_name_detail d-flex'>
                <div className='pro_1'>
                  <img src={process.env.PUBLIC_URL + '/img/profile1.png'} alt='' />
                </div>
                <div className='pro_name'>
                  <h3 className='blue'>Mangrove Realty</h3>
                  <p>The power of positive real estate - Best of the beach!</p>
                  <a href={null} className='text-uppercase edit_profile'>
                    {' '}
                    Edit profile
                  </a>
                </div>
              </div>
              <div className='badge_blk'>
                <div className='badge_content'>
                  <div className='badge yellow'>
                    <div className='b-triangle' />
                    <div className='b-inner'>
                      <h4>Trust Me</h4>
                      <div className='d1'>
                        <div className='digits_cat'>
                          <div className='digit'>20</div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='badge_star'>
                    <a href={null}>100 Reviews</a>
                    <ul className='d-flex'>
                      <li>
                        <img src={process.env.PUBLIC_URL + '/img/star_top.png'} alt=' ' />
                      </li>
                      <li>
                        <img src={process.env.PUBLIC_URL + '/img/star_top.png'} alt=' ' />
                      </li>
                      <li>
                        <img src={process.env.PUBLIC_URL + '/img/star_top.png'} alt=' ' />
                      </li>
                      <li>
                        <img src={process.env.PUBLIC_URL + '/img/star_top.png'} alt=' ' />
                      </li>
                      <li>
                        <img src={process.env.PUBLIC_URL + '/img/star_top.png'} alt=' ' />
                      </li>
                    </ul>
                  </div>
                </div>
                <div className='badge_content'>
                  <div className='badge'>
                    <div className='b-triangle' />
                    <div className='b-inner'>
                      <h4>iTRUST</h4>
                      <div className='d1'>
                        <div className='digits_cat'>
                          <div className='digit'>180</div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='badge_star'>
                    <a href={null}>78 Reviews</a>
                    <ul className='d-flex'>
                      <li>
                        <img
                          src={process.env.PUBLIC_URL + '/img/star_purple.png'}
                          alt='star_purple'
                        />
                      </li>
                      <li>
                        <img
                          src={process.env.PUBLIC_URL + '/img/star_purple.png'}
                          alt='star_purple'
                        />
                      </li>
                      <li>
                        <img
                          src={process.env.PUBLIC_URL + '/img/star_purple.png'}
                          alt='star_purple'
                        />
                      </li>
                      <li>
                        <img
                          src={process.env.PUBLIC_URL + '/img/star_purple.png'}
                          alt='star_purple'
                        />
                      </li>
                      <li>
                        <img
                          src={process.env.PUBLIC_URL + '/img/star_purple.png'}
                          alt='star_purple'
                        />
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className='cover_bottom'>
                <div className='left_link'>
                  <ul className='upper-tabs nav nav-tabs'>
                    <li className='nav-item'>
                      <a className='nav-link active' data-bs-toggle='tab' href='#overview'>
                        Overview
                      </a>
                    </li>
                    <li className='nav-item'>
                      <a className='nav-link' data-bs-toggle='tab' href='#trust'>
                        {' '}
                        Trust List (40)
                      </a>
                    </li>
                    <li className='nav-item'>
                      <a className='nav-link' data-bs-toggle='tab' href='#gallery'>
                        Gallery(40)
                      </a>
                    </li>
                    <li className='nav-item'>
                      <a className='nav-link' data-bs-toggle='tab' href='#friend'>
                        Friends (25)
                      </a>
                    </li>
                    <li className='nav-item'>
                      <a className='nav-link' data-bs-toggle='tab' href='#member'>
                        Activity{' '}
                      </a>
                    </li>
                    <li className='nav-item'>
                      <a className='nav-link' data-bs-toggle='tab' href='#more'>
                        Invite Center
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='listing_block'>
          <div className='container'>
            <div className='tab-content'>
              <div id='overview' className='tab-pane active'>
                <div className='row'>
                  <div className='col-md-4 overview-left'>
                    <div className='bg-white curvey mb-4 p-15'>
                      <div className='listing_blk'>
                        <h4 className='varela'>Improve Your Profile</h4>
                        <div className='progress'>
                          <div
                            className='progress-bar'
                            role='progressbar'
                            aria-valuenow='70'
                            aria-valuemin='0'
                            aria-valuemax='100'
                          >
                            65%
                          </div>
                        </div>
                        <a href={null} className='d-block mb-2 improve'>
                          {' '}
                          Add your Social Media Links ( +10% )
                        </a>
                        <a href={null} className='d-block mb-2 improve'>
                          Add your About Me ( +10% )
                        </a>
                        <a href={null} className='d-block mb-2 improve'>
                          Add Profile Picture ( +10% )
                        </a>
                        <a href={null} className='d-block mb-2 improve'>
                          Review a Business/Organization ( +5% ){' '}
                        </a>
                      </div>
                    </div>
                    <div className='bg-white curvey mb-4 p-15'>
                      <h4 className='varela'>
                        {' '}
                        Friends
                        <span className='purple'>248</span>
                      </h4>
                      <div className='friends_green mt-2'>
                        <div className='d-flex headers justify-content-between green'>
                          <h6>People - 357</h6>
                          <h6>44 mutual</h6>
                        </div>

                        <div className='row profiles'>
                          <div className='col-md-6'>
                            <h6 className='varela'>Eddy Jones</h6>
                            <div className='frnd_box'>
                              <div className='img_block green_border' />
                              <div className=''>
                                <div className='mb-2'>
                                  <i>
                                    <img
                                      src={process.env.PUBLIC_URL + '/img/purple_tag.png'}
                                      className='me-2'
                                      alt=''
                                    />
                                  </i>
                                  142
                                </div>
                                <div className='mb-2'>
                                  <i>
                                    <img
                                      src={process.env.PUBLIC_URL + '/img/frandss.png'}
                                      className='me-2'
                                      alt=''
                                    />
                                  </i>
                                  149
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='col-md-6'>
                            <h6 className='varela'>Eddy Jones </h6>
                            <div className='frnd_box'>
                              <div className='img_block green_border' />
                              <div className=''>
                                <div className='mb-2'>
                                  <i>
                                    <img
                                      src={process.env.PUBLIC_URL + '/img/purple_tag.png'}
                                      className='me-2'
                                      alt=''
                                    />
                                  </i>
                                  142
                                </div>
                                <div className='mb-2'>
                                  <i>
                                    <img
                                      src={process.env.PUBLIC_URL + '/img/frandss.png'}
                                      className='me-2'
                                      alt=''
                                    />
                                  </i>
                                  149
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='col-md-6'>
                            <h6 className='varela'>Eddy Jones </h6>
                            <div className='frnd_box'>
                              <div className='img_block green_border' />
                              <div className=''>
                                <div className='mb-2'>
                                  <i>
                                    <img
                                      src={process.env.PUBLIC_URL + '/img/purple_tag.png'}
                                      className='me-2'
                                      alt=''
                                    />
                                  </i>
                                  142
                                </div>
                                <div className='mb-2'>
                                  <i>
                                    <img
                                      src={process.env.PUBLIC_URL + '/img/frandss.png'}
                                      className='me-2'
                                      alt=''
                                    />
                                  </i>
                                  149
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='col-md-6'>
                            <h6 className='varela'>Eddy Jones </h6>
                            <div className='frnd_box'>
                              <div className='img_block green_border' />
                              <div className=''>
                                <div className='mb-2'>
                                  <i>
                                    <img
                                      src={process.env.PUBLIC_URL + '/img/purple_tag.png'}
                                      className='me-2'
                                      alt=''
                                    />
                                  </i>
                                  142
                                </div>
                                <div className='mb-2'>
                                  <i>
                                    <img
                                      src={process.env.PUBLIC_URL + '/img/frandss.png'}
                                      className='me-2'
                                      alt=''
                                    />
                                  </i>
                                  149
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* friends green over */}

                      <div className='business_blue mt-4'>
                        <div className='d-flex headers justify-content-between'>
                          <h6>business - 37</h6>
                          <h6>24 mutual</h6>
                        </div>

                        <div className='row profiles'>
                          <div className='col-md-6'>
                            <h6 className='varela'>Eddy Jones</h6>
                            <div className='frnd_box'>
                              <div className='img_block   blue_border' />
                              <div className=''>
                                <div className='mb-2'>
                                  <i>
                                    <img
                                      src={process.env.PUBLIC_URL + '/img/orange_tag.png'}
                                      className='me-2'
                                      alt=''
                                    />
                                  </i>
                                  142
                                </div>
                                <div className='mb-2'>
                                  <i>
                                    <img
                                      src={process.env.PUBLIC_URL + '/img/frandss.png'}
                                      className='me-2'
                                      alt=''
                                    />
                                  </i>
                                  149
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='col-md-6'>
                            <h6 className='varela'>Eddy Jones</h6>
                            <div className='frnd_box'>
                              <div className='img_block   blue_border' />
                              <div className=''>
                                <div className='mb-2'>
                                  <i>
                                    <img
                                      src={process.env.PUBLIC_URL + '/img/orange_tag.png'}
                                      className='me-2'
                                      alt=''
                                    />
                                  </i>
                                  142
                                </div>
                                <div className='mb-2'>
                                  <i>
                                    <img
                                      src={process.env.PUBLIC_URL + '/img/frandss.png'}
                                      className='me-2'
                                      alt=''
                                    />
                                  </i>
                                  149
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='col-md-6'>
                            <h6 className='varela'>Eddy Jones</h6>
                            <div className='frnd_box'>
                              <div className='img_block   blue_border' />
                              <div className=''>
                                <div className='mb-2'>
                                  <i>
                                    <img
                                      src={process.env.PUBLIC_URL + '/img/orange_tag.png'}
                                      className='me-2'
                                      alt=''
                                    />
                                  </i>
                                  142
                                </div>
                                <div className='mb-2'>
                                  <i>
                                    <img
                                      src={process.env.PUBLIC_URL + '/img/frandss.png'}
                                      className='me-2'
                                      alt=''
                                    />
                                  </i>
                                  149
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='col-md-6'>
                            <h6 className='varela'>Eddy Jones</h6>
                            <div className='frnd_box'>
                              <div className='img_block  blue_border' />
                              <div className=''>
                                <div className='mb-2'>
                                  <i>
                                    <img
                                      src={process.env.PUBLIC_URL + '/img/orange_tag.png'}
                                      className='me-2'
                                      alt=''
                                    />
                                  </i>
                                  142
                                </div>
                                <div className='mb-2'>
                                  <i>
                                    <img
                                      src={process.env.PUBLIC_URL + '/img/frandss.png'}
                                      className='me-2'
                                      alt=''
                                    />
                                  </i>
                                  149
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* business blue over */}
                      <div className='organization_orange mt-4'>
                        <div className='d-flex headers justify-content-between'>
                          <h6>ORGANIZATIONS - 89</h6>
                          <h6>12 mutual</h6>
                        </div>

                        <div className='row profiles'>
                          <div className='col-md-6'>
                            <h6 className='varela'>Eddy Jones</h6>
                            <div className='frnd_box'>
                              <div className='img_block orange_border' />
                              <div className=''>
                                <div className='mb-2'>
                                  <i>
                                    <img
                                      src={process.env.PUBLIC_URL + '/img/orange_tag.png'}
                                      className='me-2'
                                      alt=''
                                    />
                                  </i>
                                  142
                                </div>
                                <div className='mb-2'>
                                  <i>
                                    <img
                                      src={process.env.PUBLIC_URL + '/img/frandss.png'}
                                      className='me-2'
                                      alt=''
                                    />
                                  </i>
                                  149
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='col-md-6'>
                            <h6 className='varela'>Eddy Jones</h6>
                            <div className='frnd_box'>
                              <div className='img_block orange_border' />
                              <div className=''>
                                <div className='mb-2'>
                                  <i>
                                    <img
                                      src={process.env.PUBLIC_URL + '/img/orange_tag.png'}
                                      className='me-2'
                                      alt=''
                                    />
                                  </i>
                                  142
                                </div>
                                <div className='mb-2'>
                                  <i>
                                    <img
                                      src={process.env.PUBLIC_URL + '/img/frandss.png'}
                                      className='me-2'
                                      alt=''
                                    />
                                  </i>
                                  149
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* organization over */}
                    </div>

                    <div className='bg-white curvey mb-4 p-15'>
                      <img
                        src={process.env.PUBLIC_URL + '/img/image.png'}
                        className='img-fluid'
                        alt=''
                      />
                    </div>
                  </div>
                  {/* overview left over */}
                  <div className='col-md-8 overview-right'>
                    <div className='bg-white'>
                      <div className='row contact_blk'>
                        <div className='col-md-7 contact_content'>
                          <div className=''>
                            <h4 className='varela'>Company Details</h4>
                            <div className='contact'>
                              150 Briarwood Drive
                              <br /> Goldsboro, NC 27530
                            </div>
                            <div className='phone'>
                              <a href={null}>(954)555-5555 (Phone Day)</a>
                              <a href={null}>(954)555-5555 (Phone Evening)</a>
                              <a href={null}>(954)555-5555 (Mobile)</a>
                            </div>
                            <div className='email'>
                              <a href={null}>ed@edmachomes.com</a>
                              <a href={null}>edmac@gmail.com</a>
                            </div>
                            <div className='site'>
                              <a href={null}>www.edmachomes.com</a>
                            </div>
                          </div>
                        </div>
                        <div className='col-md-5 map'>
                          <div className='map_blk'>
                            <h4 className='varela'>Map</h4>
                            <div id='googlemap' />
                          </div>
                        </div>
                      </div>

                      <div className='contact_cat'>
                        <p>
                          <strong>Categories</strong> Real Estate, Real Estate Services, Realtor,
                          Real Estate Consultants... and 3 more
                        </p>
                        <p>
                          <strong>Cities</strong> Hermosa Beach, Rodondo Beach, Los Angeles
                        </p>
                      </div>

                      <div className='page_info'>
                        <h3 className='varela'>About Us</h3>
                        <p>
                          Fusce eget quam urna. Aliquam at lobortis velit. Pellentesque habitant
                          morbi tristique senectus et netus et malesuada fames ac turpis egestas.
                          Sed non rutrum purus. Aliquam at mollis neque. Nunc dapibus venenatis est
                          id tincidunt. Curabitur consectetur rutrum vehicula. Morbi vitae nibh
                          libero. Phasellus malesuada dui massa, vel ornare nulla congue cursus.
                          Proin eget metus vestibulum lacus cursus vehicula. Etiam posuere augue
                          risus, et varius enim aliquet id. Interdum et malesuada fames ac ante
                          ipsum primis in faucibus. Nunc congue, orci nec ultrices rutrum, urna enim
                          molestie sem, a porta nunc lectus eu quam. Vestibulum a est id orci
                          finibus maximus. Aliquam suscipit leo quis elit bibendum maximus. Nam quis
                          est dapibus, rhoncus nulla eget, mattis dui.
                        </p>
                      </div>
                      <div className='page_info'>
                        <h3 className='varela'>Gallery</h3>
                        <p />
                      </div>
                      <div className='page_info'>
                        <h3 className='varela'>
                          Reviews
                          <a href={null} className='blue'>
                            178
                          </a>
                        </h3>
                        <div className='review_blk'>
                          <div className='img_blk blue_border' />
                          <div className='name_blk'>
                            <h5 className='varela'>Jacob Holmes</h5>
                            <ul>
                              <li className='friends_review'>100 friends</li>
                              <li className='star_review'>25 reviews</li>
                              <li className='trust_review'>180 iTRUST</li>
                            </ul>
                          </div>
                          <div className='comment_blk'>
                            <div className='right_link'>
                              <div className='star_ratings'>
                                <img src={process.env.PUBLIC_URL + '/img/star_yellow.png'} alt='' />
                                <img src={process.env.PUBLIC_URL + '/img/star_yellow.png'} alt='' />
                                <img src={process.env.PUBLIC_URL + '/img/star_yellow.png'} alt='' />
                                <img src={process.env.PUBLIC_URL + '/img/star_yellow.png'} alt='' />
                                <img src={process.env.PUBLIC_URL + '/img/star_yellow.png'} alt='' />
                              </div>
                              <div className='review_digit varela'>
                                <a href={null}>November 6, 2015</a>
                              </div>
                            </div>
                            <p>
                              Mauris non urna eu nisi vulputate fringilla sit amet vel velit. Ut
                              tempus id magna nec finibus. Suspendisse eget lorem ut nunc congue
                              ultrices. Nullam quis porttitor massa, sit amet gravida
                            </p>
                          </div>
                        </div>
                        <div className='review_blk'>
                          <div className='img_blk' />
                          <div className='name_blk'>
                            <h5 className='varela'>Jacob Holmes</h5>
                            <ul>
                              <li className='friends_review'>100 friends</li>
                              <li className='star_review'>25 reviews</li>
                              <li className='trust_review'>180 iTRUST</li>
                            </ul>
                          </div>
                          <div className='comment_blk'>
                            <div className='right_link'>
                              <div className='star_ratings'>
                                <img src={process.env.PUBLIC_URL + '/img/star_yellow.png'} alt='' />
                                <img src={process.env.PUBLIC_URL + '/img/star_yellow.png'} alt='' />
                                <img src={process.env.PUBLIC_URL + '/img/star_yellow.png'} alt='' />
                                <img src={process.env.PUBLIC_URL + '/img/star_yellow.png'} alt='' />
                                <img src={process.env.PUBLIC_URL + '/img/star_yellow.png'} alt='' />
                              </div>
                              <div className='review_digit varela'>
                                <a href={null}>November 6, 2015</a>
                              </div>
                            </div>
                            <p>
                              Mauris non urna eu nisi vulputate fringilla sit amet vel velit. Ut
                              tempus id magna nec finibus. Suspendisse eget lorem ut nunc congue
                              ultrices. Nullam quis porttitor massa, sit amet gravida
                            </p>
                          </div>
                        </div>
                        <div className='review_blk'>
                          <div className='img_blk' />
                          <div className='name_blk'>
                            <h5 className='varela'>Jacob Holmes</h5>
                            <ul>
                              <li className='friends_review'>100 friends</li>
                              <li className='star_review'>25 reviews</li>
                              <li className='trust_review'>180 iTRUST</li>
                            </ul>
                          </div>
                          <div className='comment_blk'>
                            <div className='right_link'>
                              <div className='star_ratings'>
                                <img src={process.env.PUBLIC_URL + '/img/star_yellow.png'} alt='' />
                                <img src={process.env.PUBLIC_URL + '/img/star_yellow.png'} alt='' />
                                <img src={process.env.PUBLIC_URL + '/img/star_yellow.png'} alt='' />
                                <img src={process.env.PUBLIC_URL + '/img/star_yellow.png'} alt='' />
                                <img src={process.env.PUBLIC_URL + '/img/star_yellow.png'} alt='' />
                              </div>
                              <div className='review_digit varela'>
                                <a href={null}>November 6, 2015</a>
                              </div>
                            </div>
                            <p>
                              Mauris non urna eu nisi vulputate fringilla sit amet vel velit. Ut
                              tempus id magna nec finibus. Suspendisse eget lorem ut nunc congue
                              ultrices. Nullam quis porttitor massa, sit amet gravida
                            </p>
                          </div>
                        </div>

                        <a href={null} className='blue reviewlink varela'>
                          {' '}
                          Read all reviews{' '}
                        </a>
                      </div>
                    </div>
                  </div>
                  {/* overview right over */}
                </div>
              </div>
              <div id='trust' className='tab-pane fade'>
                <h3>Menu 1</h3>
                <p>
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip
                  ex ea commodo consequat.
                </p>
              </div>
              {/* trust over */}
              <div id='gallery' className='tab-pane fade'>
                <h4 className='varela'>Gallery for Gallie for Kids</h4>
                <div className='bg-white'>
                  <div className='friend_list_block'>
                    <div className='f-left'>
                      <ul className='gallery_tab inner-navs nav nav-tabs'>
                        <li className='nav-item'>
                          <a className='nav-link active' data-bs-toggle='tab' href='#gallery_all'>
                            <span>ALL (50)</span>
                          </a>
                        </li>
                        <li className='nav-item'>
                          <a className='nav-link' data-bs-toggle='tab' href='#gallery_pictures'>
                            <span>PICTURES (40)</span>
                          </a>
                        </li>
                        <li className='nav-item'>
                          <a className='nav-link' data-bs-toggle='tab' href='#gallery_videos'>
                            <span>VIDEOS (10)</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/* inner tab start */}
                  <div className=' inner-tab tab-content'>
                    <div id='gallery_all' className='tab-pane active'>
                      <ul className='gallery_list'>
                        <li>
                          <div className='gallery_inner'>
                            <a href={null}>
                              <img src={process.env.PUBLIC_URL + '/img/pic.jpg'} alt='' />
                            </a>
                          </div>
                        </li>
                        <li>
                          <div className='gallery_inner'>
                            <a href={null}>
                              <img src={process.env.PUBLIC_URL + '/img/pic.jpg'} alt='' />
                            </a>
                          </div>
                        </li>
                        <li>
                          <div className='gallery_inner'>
                            <a href={null}>
                              <img src={process.env.PUBLIC_URL + '/img/pic.jpg'} alt='' />
                              <span className='play' />
                            </a>
                          </div>
                        </li>
                        <li>
                          <div className='gallery_inner'>
                            <a href={null}>
                              <img src={process.env.PUBLIC_URL + '/img/pic.jpg'} alt='' />
                            </a>
                          </div>
                        </li>
                        <li>
                          <div className='gallery_inner'>
                            <a href={null}>
                              <img src={process.env.PUBLIC_URL + '/img/pic.jpg'} alt='' />
                            </a>
                          </div>
                        </li>
                        <li>
                          <div className='gallery_inner'>
                            <a href={null}>
                              <img src={process.env.PUBLIC_URL + '/img/pic.jpg'} alt='' />
                            </a>
                          </div>
                        </li>
                        <li>
                          <div className='gallery_inner'>
                            <a href={null}>
                              <img src={process.env.PUBLIC_URL + '/img/pic.jpg'} alt='' />
                              <span className='play' />
                            </a>
                          </div>
                        </li>
                        <li>
                          <div className='gallery_inner'>
                            <a href={null}>
                              <img src={process.env.PUBLIC_URL + '/img/pic.jpg'} alt='' />
                            </a>
                          </div>
                        </li>
                        <li>
                          <div className='gallery_inner'>
                            <a href={null}>
                              <img src={process.env.PUBLIC_URL + '/img/pic.jpg'} alt='' />
                            </a>
                          </div>
                        </li>
                        <li>
                          <div className='gallery_inner'>
                            <a href={null}>
                              <img src={process.env.PUBLIC_URL + '/img/pic.jpg'} alt='' />
                            </a>
                          </div>
                        </li>
                        <li>
                          <div className='gallery_inner'>
                            <a href={null}>
                              <img src={process.env.PUBLIC_URL + '/img/pic.jpg'} alt='' />
                              <span className='play' />
                            </a>
                          </div>
                        </li>
                        <li>
                          <div className='gallery_inner'>
                            <a href={null}>
                              <img src={process.env.PUBLIC_URL + '/img/pic.jpg'} alt='' />
                            </a>
                          </div>
                        </li>
                        <li>
                          <div className='gallery_inner'>
                            <a href={null}>
                              <img src={process.env.PUBLIC_URL + '/img/pic.jpg'} alt='' />
                            </a>
                          </div>
                        </li>
                        <li>
                          <div className='gallery_inner'>
                            <a href={null}>
                              <img src={process.env.PUBLIC_URL + '/img/pic.jpg'} alt='' />
                              <span className='play' />
                            </a>
                          </div>
                        </li>
                        <li>
                          <div className='gallery_inner'>
                            <a href={null}>
                              <img src={process.env.PUBLIC_URL + '/img/pic.jpg'} alt='' />
                            </a>
                          </div>
                        </li>
                        <li>
                          <div className='gallery_inner'>
                            <a href={null}>
                              <img src={process.env.PUBLIC_URL + '/img/pic.jpg'} alt='' />
                            </a>
                          </div>
                        </li>
                      </ul>
                    </div>
                    {/* gallery_all over */}
                    <div id='gallery_pictures' className=' tab-pane fade'>
                      <ul className='gallery_list'>
                        <li>
                          <div className='gallery_inner'>
                            <a href={null}>
                              <img src={process.env.PUBLIC_URL + '/img/pic.jpg'} alt='' />
                            </a>
                          </div>
                        </li>
                        <li>
                          <div className='gallery_inner'>
                            <a href={null}>
                              <img src={process.env.PUBLIC_URL + '/img/pic.jpg'} alt='' />
                            </a>
                          </div>
                        </li>
                        <li>
                          <div className='gallery_inner'>
                            <a href={null}>
                              <img src={process.env.PUBLIC_URL + '/img/pic.jpg'} alt='' />
                            </a>
                          </div>
                        </li>
                        <li>
                          <div className='gallery_inner'>
                            <a href={null}>
                              <img src={process.env.PUBLIC_URL + '/img/pic.jpg'} alt='' />
                            </a>
                          </div>
                        </li>
                        <li>
                          <div className='gallery_inner'>
                            <a href={null}>
                              <img src={process.env.PUBLIC_URL + '/img/pic.jpg'} alt='' />
                            </a>
                          </div>
                        </li>
                        <li>
                          <div className='gallery_inner'>
                            <a href={null}>
                              <img src={process.env.PUBLIC_URL + '/img/pic.jpg'} alt='' />
                            </a>
                          </div>
                        </li>
                      </ul>
                    </div>
                    {/* gallery_pictures over */}
                    <div id='gallery_videos' className=' tab-pane fade'>
                      <ul className='gallery_list'>
                        <li>
                          <div className='gallery_inner'>
                            <a href={null}>
                              <img src={process.env.PUBLIC_URL + '/img/pic.jpg'} alt='' />
                              <span className='play' />
                            </a>
                          </div>
                        </li>
                        <li>
                          <div className='gallery_inner'>
                            <a href={null}>
                              <img src={process.env.PUBLIC_URL + '/img/pic.jpg'} alt='' />
                              <span className='play' />
                            </a>
                          </div>
                        </li>
                        <li>
                          <div className='gallery_inner'>
                            <a href={null}>
                              <img src={process.env.PUBLIC_URL + '/img/pic.jpg'} alt='' />
                              <span className='play' />
                            </a>
                          </div>
                        </li>
                        <li>
                          <div className='gallery_inner'>
                            <a href={null}>
                              <img src={process.env.PUBLIC_URL + '/img/pic.jpg'} alt='' />
                              <span className='play' />
                            </a>
                          </div>
                        </li>
                        <li>
                          <div className='gallery_inner'>
                            <a href={null}>
                              <img src={process.env.PUBLIC_URL + '/img/pic.jpg'} alt='' />
                              <span className='play' />
                            </a>
                          </div>
                        </li>
                        <li>
                          <div className='gallery_inner'>
                            <a href={null}>
                              <img src={process.env.PUBLIC_URL + '/img/pic.jpg'} alt='' />
                              <span className='play' />
                            </a>
                          </div>
                        </li>
                        <li>
                          <div className='gallery_inner'>
                            <a href={null}>
                              <img src={process.env.PUBLIC_URL + '/img/pic.jpg'} alt='' />
                              <span className='play' />
                            </a>
                          </div>
                        </li>
                        <li>
                          <div className='gallery_inner'>
                            <a href={null}>
                              <img src={process.env.PUBLIC_URL + '/img/pic.jpg'} alt='' />
                              <span className='play' />
                            </a>
                          </div>
                        </li>
                        <li>
                          <div className='gallery_inner'>
                            <a href={null}>
                              <img src={process.env.PUBLIC_URL + '/img/pic.jpg'} alt='' />
                              <span className='play' />
                            </a>
                          </div>
                        </li>
                        <li>
                          <div className='gallery_inner'>
                            <a href={null}>
                              <img src={process.env.PUBLIC_URL + '/img/pic.jpg'} alt='' />
                              <span className='play' />
                            </a>
                          </div>
                        </li>
                      </ul>
                    </div>
                    {/* gallery_videos over */}
                  </div>
                  {/* inner tab over */}
                </div>
              </div>
              {/* gallery over */}
              <div id='member' className='tab-pane fade'>
                <h4 className='varela'>Members (50)</h4>
                <div className='bg-white'>
                  <div className='friend_list_block'>
                    <div className='f-left'>
                      <ul className='inner-navs nav nav-tabs'>
                        <li className='nav-item'>
                          <a className='nav-link active' data-bs-toggle='tab' href='#members_digit'>
                            <span>50 MEMBERS</span>
                            <span className='mutual'>10 friends</span>
                          </a>
                        </li>
                        <li className='nav-item'>
                          <a className='nav-link' data-bs-toggle='tab' href='#member_people'>
                            <span>20 PEOPLE</span>
                            <span className='mutual'>5 friends</span>
                          </a>
                        </li>
                        <li className='nav-item'>
                          <a className='nav-link' data-bs-toggle='tab' href='#member_business'>
                            <span>20 BUSINESSES </span>
                            <span className='mutual'>5 friends</span>
                          </a>
                        </li>
                        <li className='nav-item'>
                          <a className='nav-link' data-bs-toggle='tab' href='#member_organization'>
                            <span>10 ORGANIZATIONS</span>
                            <span className='mutual'>0 friends</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className='f-right'>
                      <form>
                        <input
                          type='text'
                          className='input1 form-control'
                          placeholder='Search for a person,  business, or organization...'
                        />
                        <input type='submit' className='search' value='' />
                      </form>
                    </div>
                  </div>
                  {/* inner tab start */}
                  <div className='inner-tab tab-content'>
                    <div id='members_digit' className='tab-pane active' />
                    <div id='member_people' className=' tab-pane fade'>
                      <div className='friend_lists_1'>
                        <div className='row frnd_1'>
                          <div className='col-md-6 '>
                            <div className='frnd_box'>
                              <div className='img_block blue_border' />
                              <div className=''>
                                <div className='tag'>142</div>
                                <div className='frandss'>149</div>
                              </div>
                              <div className='text_frnd'>
                                <h5>Eddy Jones</h5>
                                <div className='design_action'>
                                  <a href={null} className='green'>
                                    + Friend
                                  </a>
                                  <a href={null} className='chatgrey' />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='col-md-6 '>
                            <div className='frnd_box'>
                              <div className='img_block blue_border' />
                              <div className=''>
                                <div className='tag'>142</div>
                                <div className='frandss'>149</div>
                              </div>
                              <div className='text_frnd'>
                                <h5>Eddy Jones</h5>
                                <div className='design_action'>
                                  <a href={null} className='green'>
                                    + Friend
                                  </a>
                                  <a href={null} className='chatgrey' />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='col-md-6 '>
                            <div className='frnd_box'>
                              <div className='img_block blue_border' />
                              <div className=''>
                                <div className='tag'>142</div>
                                <div className='frandss'>149</div>
                              </div>
                              <div className='text_frnd'>
                                <h5>Eddy Jones</h5>
                                <div className='design_action'>
                                  <a href={null} className='green'>
                                    + Friend
                                  </a>
                                  <a href={null} className='chatgrey' />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='col-md-6 '>
                            <div className='frnd_box'>
                              <div className='img_block blue_border' />
                              <div className=''>
                                <div className='tag'>142</div>
                                <div className='frandss'>149</div>
                              </div>
                              <div className='text_frnd'>
                                <h5>Eddy Jones</h5>
                                <div className='design_action'>
                                  <a href={null} className='green'>
                                    + Friend
                                  </a>
                                  <a href={null} className='chatgrey' />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div id='member_business' className=' tab-pane fade'>
                      <div className='friend_lists_1'>
                        <div className='row frnd_1'>
                          <div className='col-md-6 '>
                            <div className='frnd_box'>
                              <div className='img_block' />
                              <div className=''>
                                <div className='tag'>142</div>
                                <div className='frandss'>149</div>
                              </div>
                              <div className='text_frnd'>
                                <h5>Mangrove Realty</h5>
                                <div className='design_action'>
                                  <a href={null} className='orange'>
                                    + iTRUSTit
                                  </a>
                                  <a href={null} className='green'>
                                    + Friend
                                  </a>
                                  <a href={null} className='chatgrey' />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='col-md-6 '>
                            <div className='frnd_box'>
                              <div className='img_block' />
                              <div className=''>
                                <div className='tag'>142</div>
                                <div className='frandss'>149</div>
                              </div>
                              <div className='text_frnd'>
                                <h5>Sals Auto Care</h5>
                                <div className='design_action'>
                                  <a href={null} className='orange'>
                                    + iTRUSTit
                                  </a>
                                  <a href={null} className='green'>
                                    + Friend
                                  </a>
                                  <a href={null} className='chatgrey' />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='col-md-6 '>
                            <div className='frnd_box'>
                              <div className='img_block' />
                              <div className=''>
                                <div className='tag'>142</div>
                                <div className='frandss'>149</div>
                              </div>
                              <div className='text_frnd'>
                                <h5>Mangrove Realty</h5>
                                <div className='design_action'>
                                  <a href={null} className='orange'>
                                    + iTRUSTit
                                  </a>
                                  <a href={null} className='green'>
                                    + Friend
                                  </a>
                                  <a href={null} className='chatgrey' />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='col-md-6 '>
                            <div className='frnd_box'>
                              <div className='img_block' />
                              <div className=''>
                                <div className='tag'>142</div>
                                <div className='frandss'>149</div>
                              </div>
                              <div className='text_frnd'>
                                <h5>Sals Auto Care</h5>
                                <div className='design_action'>
                                  <a href={null} className='orange'>
                                    + iTRUSTit
                                  </a>
                                  <a href={null} className='green'>
                                    + Friend
                                  </a>
                                  <a href={null} className='chatgrey' />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* business over */}
                    <div id='member_organization' className=' tab-pane fade'>
                      <div className='friend_lists_1'>
                        <div className='row frnd_1'>
                          <div className='col-md-6 '>
                            <div className='frnd_box'>
                              <div className='img_block orange_border' />
                              <div className=''>
                                <div className='tag'>142</div>
                                <div className='frandss'>149</div>
                              </div>
                              <div className='text_frnd'>
                                <h5>Child Net</h5>
                                <div className='design_action'>
                                  <a href={null} className='orange'>
                                    + iTRUSTit
                                  </a>
                                  <a href={null} className='green'>
                                    + Friend
                                  </a>
                                  <a href={null} className='chatgrey' />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='col-md-6 '>
                            <div className='frnd_box'>
                              <div className='img_block orange_border' />
                              <div className=''>
                                <div className='tag'>142</div>
                                <div className='frandss'>149</div>
                              </div>
                              <div className='text_frnd'>
                                <h5>Child Net</h5>
                                <div className='design_action'>
                                  <a href={null} className='orange'>
                                    + iTRUSTit
                                  </a>
                                  <a href={null} className='green'>
                                    + Friend
                                  </a>
                                  <a href={null} className='chatgrey' />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='col-md-6 '>
                            <div className='frnd_box'>
                              <div className='img_block orange_border' />
                              <div className=''>
                                <div className='tag'>142</div>
                                <div className='frandss'>149</div>
                              </div>
                              <div className='text_frnd'>
                                <h5>Child Net</h5>
                                <div className='design_action'>
                                  <a href={null} className='orange'>
                                    + iTRUSTit
                                  </a>
                                  <a href={null} className='green'>
                                    + Friend
                                  </a>
                                  <a href={null} className='chatgrey' />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='col-md-6 '>
                            <div className='frnd_box'>
                              <div className='img_block orange_border' />
                              <div className=''>
                                <div className='tag'>142</div>
                                <div className='frandss'>149</div>
                              </div>
                              <div className='text_frnd'>
                                <h5>Child Net</h5>
                                <div className='design_action'>
                                  <a href={null} className='orange'>
                                    + iTRUSTit
                                  </a>
                                  <a href={null} className='green'>
                                    + Friend
                                  </a>
                                  <a href={null} className='chatgrey' />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* organization over */}
                  </div>
                  {/* inner tab over */}
                </div>
              </div>
              {/* member over */}
              <div id='friend' className='tab-pane fade'>
                <h4 className='varela'>Friends (400)</h4>
                <div className='bg-white'>
                  <div className='friend_list_block'>
                    <div className='f-left'>
                      <ul className='inner-navs nav nav-tabs'>
                        <li className='nav-item'>
                          <a className='nav-link active' data-bs-toggle='tab' href='#friends'>
                            <span>400 FRIENDS</span>
                            <span className='mutual'>100 mutual</span>
                          </a>
                        </li>
                        <li className='nav-item'>
                          <a className='nav-link' data-bs-toggle='tab' href='#people'>
                            <span>300 PEOPLE</span>
                            <span className='mutual'>75 mutual</span>
                          </a>
                        </li>
                        <li className='nav-item'>
                          <a className='nav-link' data-bs-toggle='tab' href='#business'>
                            <span>50 BUSINESSES </span>
                            <span className='mutual'>15 mutual</span>
                          </a>
                        </li>
                        <li className='nav-item'>
                          <a className='nav-link' data-bs-toggle='tab' href='#organization'>
                            <span>50 ORGANIZATIONS</span>
                            <span className='mutual'>100 mutual</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className='f-right'>
                      <form>
                        <input
                          type='text'
                          className='input1 form-control'
                          placeholder='Search for a person,  business, or organization...'
                        />
                        <input type='submit' className='search' value='' />
                      </form>
                    </div>
                  </div>
                  {/* inner tab start */}
                  <div className='inner-tab tab-content'>
                    <div id='friends' className='tab-pane active' />
                    <div id='people' className=' tab-pane fade'>
                      <div className='friend_lists_1'>
                        <div className='row frnd_1'>
                          <div className='col-md-6 '>
                            <div className='frnd_box'>
                              <div className='img_block green_border' />
                              <div className=''>
                                <div className='tag'>142</div>
                                <div className='frandss'>149</div>
                              </div>
                              <div className='text_frnd'>
                                <h5>Eddy Jones</h5>
                                <div className='design_action'>
                                  <a href={null} className='green'>
                                    + Friend
                                  </a>
                                  <a href={null} className='chatgrey' />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='col-md-6 '>
                            <div className='frnd_box'>
                              <div className='img_block green_border' />
                              <div className=''>
                                <div className='tag'>142</div>
                                <div className='frandss'>149</div>
                              </div>
                              <div className='text_frnd'>
                                <h5>Eddy Jones</h5>
                                <div className='design_action'>
                                  <a href={null} className='green'>
                                    + Friend
                                  </a>
                                  <a href={null} className='chatgrey' />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='col-md-6 '>
                            <div className='frnd_box'>
                              <div className='img_block green_border' />
                              <div className=''>
                                <div className='tag'>142</div>
                                <div className='frandss'>149</div>
                              </div>
                              <div className='text_frnd'>
                                <h5>Eddy Jones</h5>
                                <div className='design_action'>
                                  <a href={null} className='green'>
                                    + Friend
                                  </a>
                                  <a href={null} className='chatgrey' />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='col-md-6 '>
                            <div className='frnd_box'>
                              <div className='img_block green_border' />
                              <div className=''>
                                <div className='tag'>142</div>
                                <div className='frandss'>149</div>
                              </div>
                              <div className='text_frnd'>
                                <h5>Eddy Jones</h5>
                                <div className='design_action'>
                                  <a href={null} className='green'>
                                    + Friend
                                  </a>
                                  <a href={null} className='chatgrey' />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div id='business' className=' tab-pane fade'>
                      <div className='row frnd_1'>
                        <div className='col-md-6 '>
                          <div className='frnd_box'>
                            <div className='img_block blue_border' />
                            <div className=''>
                              <div className='tag'>142</div>
                              <div className='frandss'>149</div>
                            </div>
                            <div className='text_frnd'>
                              <h5>Mangrove Realty</h5>
                              <div className='design_action'>
                                <a href={null} className='orange'>
                                  + iTRUSTit
                                </a>
                                <a href={null} className='green'>
                                  + Friend
                                </a>
                                <a href={null} className='chatgrey' />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='col-md-6 '>
                          <div className='frnd_box'>
                            <div className='img_block blue_border' />
                            <div className=''>
                              <div className='tag'>142</div>
                              <div className='frandss'>149</div>
                            </div>
                            <div className='text_frnd'>
                              <h5>Sals Auto Care</h5>
                              <div className='design_action'>
                                <a href={null} className='orange'>
                                  + iTRUSTit
                                </a>
                                <a href={null} className='green'>
                                  + Friend
                                </a>
                                <a href={null} className='chatgrey' />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='col-md-6 '>
                          <div className='frnd_box'>
                            <div className='img_block blue_border' />
                            <div className=''>
                              <div className='tag'>142</div>
                              <div className='frandss'>149</div>
                            </div>
                            <div className='text_frnd'>
                              <h5>Mangrove Realty</h5>
                              <div className='design_action'>
                                <a href={null} className='orange'>
                                  + iTRUSTit
                                </a>
                                <a href={null} className='green'>
                                  + Friend
                                </a>
                                <a href={null} className='chatgrey' />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='col-md-6 '>
                          <div className='frnd_box'>
                            <div className='img_block blue_border' />
                            <div className=''>
                              <div className='tag'>142</div>
                              <div className='frandss'>149</div>
                            </div>
                            <div className='text_frnd'>
                              <h5>Sals Auto Care</h5>
                              <div className='design_action'>
                                <a href={null} className='orange'>
                                  + iTRUSTit
                                </a>
                                <a href={null} className='green'>
                                  + Friend
                                </a>
                                <a href={null} className='chatgrey' />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* business over */}
                    <div id='organization' className=' tab-pane fade'>
                      <div className='row frnd_1'>
                        <div className='col-md-6 '>
                          <div className='frnd_box'>
                            <div className='img_block orange_border' />
                            <div className=''>
                              <div className='tag'>142</div>
                              <div className='frandss'>149</div>
                            </div>
                            <div className='text_frnd'>
                              <h5>Child Net</h5>
                              <div className='design_action'>
                                <a href={null} className='orange'>
                                  + iTRUSTit
                                </a>
                                <a href={null} className='green'>
                                  + Friend
                                </a>
                                <a href={null} className='chatgrey' />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='col-md-6 '>
                          <div className='frnd_box'>
                            <div className='img_block orange_border' />
                            <div className=''>
                              <div className='tag'>142</div>
                              <div className='frandss'>149</div>
                            </div>
                            <div className='text_frnd'>
                              <h5>Child Net</h5>
                              <div className='design_action'>
                                <a href={null} className='orange'>
                                  + iTRUSTit
                                </a>
                                <a href={null} className='green'>
                                  + Friend
                                </a>
                                <a href={null} className='chatgrey' />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='col-md-6 '>
                          <div className='frnd_box'>
                            <div className='img_block orange_border' />
                            <div className=''>
                              <div className='tag'>142</div>
                              <div className='frandss'>149</div>
                            </div>
                            <div className='text_frnd'>
                              <h5>Child Net</h5>
                              <div className='design_action'>
                                <a href={null} className='orange'>
                                  + iTRUSTit
                                </a>
                                <a href={null} className='green'>
                                  + Friend
                                </a>
                                <a href={null} className='chatgrey' />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='col-md-6 '>
                          <div className='frnd_box'>
                            <div className='img_block orange_border' />
                            <div className=''>
                              <div className='tag'>142</div>
                              <div className='frandss'>149</div>
                            </div>
                            <div className='text_frnd'>
                              <h5>Child Net</h5>
                              <div className='design_action'>
                                <a href={null} className='orange'>
                                  + iTRUSTit
                                </a>
                                <a href={null} className='green'>
                                  + Friend
                                </a>
                                <a href={null} className='chatgrey' />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* organization over */}
                  </div>
                  {/* inner tab over */}
                </div>
              </div>
              {/* members over */}
              <div id='more' className='  tab-pane fade'>
                <h3>Menu 1</h3>
                <p>
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip
                  ex ea commodo consequat.
                </p>
              </div>
            </div>
            {/* outer tab over */}
          </div>
        </section>
        //{' '}
        <section className='footer_top_blk'>
          //{' '}
          <div className='container'>
            //{' '}
            <div className='footer_container'>
              //{' '}
              <p className='varela'>
                Not here? Tell us what we're missing. If the business you're looking for isn't here,
                add it!
              </p>
              //{' '}
              <a href={null} className='blue_btn'>
                ADD BUSINESS
              </a>
              //{' '}
            </div>
            //{' '}
          </div>
          //{' '}
        </section>
      </AppLayout>
    )
  }
}

export default ProfileBusiness
