/* Local */
//➢      Backend server base URL for API calls
//export const BASE_URL = "http://adminitrustit.aaadev.info/";
////export const BASE_URL = "https://adminitrustit.aaadev.info/";
// export const GOOGLE_KEY = "732256642118-p6pgl5kjkkf73c3c9smta195tp67pka0.apps.googleusercontent.com";
////export const GOOGLE_KEY = "573183836172-bhbevljvnhs2k4vu4ne4c4p9kvoe8n81.apps.googleusercontent.com";
//FB: ankurgarach4@gmail.com / Garach@4
//export const FACEBOOK_KEY = "236720446965434";
// FB: zachhunter3@gmail.com
////export const FACEBOOK_KEY = "2171099623151587";
////export const SITE_ADDRESS = "https://frontitrustit.aaadev.info/";
////export const ELASTIC_SEARCH_BASE_URL =
////    "https://search-staging-itrustit-zkkwg3u32fqbx47vowiewutc5q.us-west-2.es.amazonaws.com/";
////export const ELASTIC_SEARCH_USERS = "staging-users";
////export const ELASTIC_SEARCH_CATEGORIES = "categories";
//export const SOCKET_URL = "https://chat.itrustit.com";
////export const SOCKET_URL = "https://chatitrustit.aaadev.info:6001/";

//export const SOCKET_URL = "https://149.28.39.149:6001/"

////export const SOCIAL_SHARE_URL = "https://frontitrustit.aaadev.info/" //"https://itrustitdev.com";
//Google Analytics dilip@logisticinfotech.com
////export const GOOGLE_ANALYTICS_ID = "UA-133826270-1";

/* Dev */
// export const BASE_URL = 'https://dev.laravel.itrustit.com/';
// // //itrustitsocial@gmail.com
// export const GOOGLE_KEY =
//   '573183836172-bhbevljvnhs2k4vu4ne4c4p9kvoe8n81.apps.googleusercontent.com';
// // // export const GOOGLE_KEY = "732256642118-p6pgl5kjkkf73c3c9smta195tp67pka0.apps.googleusercontent.com";
// export const FACEBOOK_KEY = '236720446965434';
// // //export const FACEBOOK_KEY = "2171099623151587";
// export const SITE_ADDRESS = 'https://dev.itrustit.com/';
// export const ELASTIC_SEARCH_BASE_URL =
//   'https://search-staging-itrustit-zkkwg3u32fqbx47vowiewutc5q.us-west-2.es.amazonaws.com/';
// export const ELASTIC_SEARCH_USERS = 'staging-users';
// export const ELASTIC_SEARCH_CATEGORIES = 'categories';
// export const SOCKET_URL = 'https://dev.laravel.itrustit.com';
// export const SOCIAL_SHARE_URL = 'https://dev.itrustit.com';
// // //Google Analytics //itrustitsocial@gmail.com  //iTrustit Dev
// export const GOOGLE_ANALYTICS_ID = 'UA-133826270-1';

/* Live */
export const BASE_URL =
  window.location.host.includes('dev.itrustit.com') ||
  window.location.host.includes('localhost') ||
  window.location.host.includes('192.168.')
    ? 'https://admindev.itrustit.com/' //'http://127.0.0.1:8000/' //
    : 'https://admin.itrustit.com/'
export const GOOGLE_KEY = '573183836172-bhbevljvnhs2k4vu4ne4c4p9kvoe8n81.apps.googleusercontent.com'
export const GOOGLE_GEOCODING_API_KEY = 'AIzaSyBl22drXnlaCIgOT0ySEKo7iK7PB3XVp_Y'
export const FACEBOOK_KEY = '236906341480256'
export const LINKEDIN_CLIENT_ID = '867tcvdjddacm4'
export const SITE_ADDRESS =
  window.location.host.includes('dev.itrustit.com') ||
  window.location.host.includes('localhost') ||
  window.location.host.includes('192.168.')
    ? 'https://dev.itrustit.com/'
    : 'https://itrustit.com/'
export const ELASTIC_SEARCH_BASE_URL =
  window.location.host.includes('dev.itrustit.com') ||
  window.location.host.includes('localhost') ||
  window.location.host.includes('192.168.')
    ? 'https://search-staging-itrustit-zkkwg3u32fqbx47vowiewutc5q.us-west-2.es.amazonaws.com/'
    : 'https://search-search-itrustit-4znzddrlvh4ulkrknmwfkpskni.us-west-2.es.amazonaws.com/'

// export const ELASTIC_SEARCH_USERS = 'live-users';
// export const ELASTIC_SEARCH_TYPE = 'live-users';
export const ELASTIC_SEARCH_USERS = 'live-users-new'
export const ELASTIC_SEARCH_TYPE = '_doc'
export const ELASTIC_SEARCH_CATEGORIES = 'live-categories'
export const SOCKET_URL = 'https://chat.itrustit.com'
export const SOCIAL_SHARE_URL = 'https://itrustit.com'
export const GOOGLE_ANALYTICS_ID = 'UA-133826270-2' // Google Analytics //itrustitsocial@gmail.com  //iTrustit Live

export const BASE_API = BASE_URL + 'api/'
export const SIGNUP = 'individual/register'
export const LOGIN = 'login'
export const LOGGER = 'logger'
export const FORGOT_PASSWORD = 'password/email'
export const SOCIAL_LOGIN = 'individual/social-login'
export const COUNTRY_LIST = 'countries/list'
export const STATE_LIST = 'states/list/country/'
export const CITY_LIST = 'cities/list/state/'
export const BUSINESS_SIGNUP = 'business/register'
export const CATEGORY_LIST = 'categories/list'
export const SINGLE_CATEGORY_LIST = 'main-categories-list'
export const ORGANIZATION_SIGNUP = 'organization/register'
export const RESET_PASSWORD = 'password/reset/'
export const PROFILE_INFO = 'login-info'
export const USER_PROFILE = 'profile'
export const UPDATE_USER_PROFILE = 'profile-update'
export const UPDATE_USER_COVER_IMAGE = 'cover-picture-update'
export const ALL_USERS_LIST_API = 'all-users-list'
export const PROFILE_INFO_LIST = 'profile-info-list'
export const SORT_SEARCH_RESULTS = 'sort-search-results'
export const UPDATE_USER_CATEGORY = 'categories/list-for-update'
export const RELATED_OTHER_PROFILE = 'overview/related-users/'
export const UNIQUE_EMAIL_CHECK1 = 'claim-business/'
export const UNIQUE_EMAIL_CHECK2 = '/check-email'
export const CLAIM_BUSINESS = 'claim-business/'
export const ACTIVATION_LINK_RESEND = '/activation-link-resend'
export const USER_PROFILE_ID = 'get-user-details-by-id/'
export const GET_SLUG_BY_ID = 'get-slug-by-id/'
export const SHARED_PROFILE_API = 'share-profile-by-email'
export const USER_DETAIL_BY_SLUG = 'get-user-details-by-slug/'
export const GET_FOF_TRUSTED = 'get-fof-trusted/'
export const GET_PROFILE_WITH_SLUG = 'get-profile-with-slug/'
export const CHECK_USERNAME = 'check-username-exists'
export const CHECK_UEMAIL = 'check-email-exists'
export const CHECK_SPONSOR_ID = 'check-sponser-id-exists'
export const REVIEW = 'reviews'
export const MY_REVIEW = 'my-review'
export const REVIEW_SHOW = 'show'
export const CATEGORY_BY_PARENT_ID = 'categories-list-by-parent-category-id/'
export const FIND_BUSINESS_OR_ORGANIZATION = 'find-business-or-organization'
export const ADD_UNCLAIMED_BUSINESS_OR_ORGANIZATION = 'add-unclaimed-business-or-organization'
export const GET_INVITE_USER_DETAILS = 'get-invite-user-details/'
export const LINKEDIN_AUTH = 'linkedin-auth'
export const UPDATE_ADDRESS = 'update-address'

export const CDN_URL = 'https://d214hmzxaadn3v.cloudfront.net/'
export const PROFILE_PICTURE_SMALL_URL_PATH = CDN_URL + 'profile_picture/small/'
export const TRUSTED_USER_LIST_BY_STATUS = 'get-trusted-users-list-by-friends-status'
export const USER_PROFILE_SETTING = 'get-user-settings'
export const OTHER_USER_PROFILE_SETTING = 'get-other-users-settings'
export const USER_PROFILE_SETTING_SET = 'update-user-settings'
export const COMMING_SOON_FORGOT_CODE = 'comming-soon/forgot-code'

export const UNITED_STATE_TEXT = 'United States'
export const USE_OTP = false
export const USE_GLOBAL_SEARCH = true
//220421_aa_01 -enable/disable social media
export const ENABLE_SOCIAL_MEDIA = true
// window.location.host.includes('localhost') ||
// window.location.host.includes('192.168.') ||
// window.location.host.includes('dev.itrustit.com')
//   ? true
//   : false
export const DISABLE_SOCIAL_MEDIA_REPORT = false
// window.location.host.includes('localhost') ||
// window.location.host.includes('192.168.') ||
// window.location.host.includes('dev.itrustit.com')
//   ? false
//   : true
