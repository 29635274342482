import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { reviewUser } from '../../actions/profile'
import { ENABLE_SOCIAL_MEDIA } from '../../services/environment'
import AppLayout from '../AppLayout'

import { PROFILE_TITLE } from '../../services/titleConst'
import * as customFunction from '../../services/customFunction'
import { NO_COVER_PICTURE } from '../../services/const'
import { getProfile } from '../../services/userApiService'
import {
  STAR_RATING_iTRUST_ACTIVE_COLOR,
  STAR_RATING_iTRUST_INACTIVE_COLOR,
  STAR_RATING_TRUST_ME_ACTIVE_COLOR,
  STAR_RATING_TRUST_ME_INACTIVE_COLOR,
  STAR_RATING_PROFILE_HEADER_SIZE,
} from '../../services/const'
import ReactStars from 'react-stars'

import ProfileHeader from './ProfileHeader/ProfileHeader'
import OverviewGenericTab from './OverviewGenericTab/OverviewGenericTab'
import TrustDirectoryTab from './TrustDirectoryTab/TrustDirectoryTab'
import GalleryTab from './GalleryTab/GalleryTab'
import FriendsTab from './FriendsTab/FriendsTab'
import MemberTab from './MemberTab/MemberTab'
import ActivityTab from './ActivityTab/ActivityTab'
import NotificationsTab from './NotificationsTab/NotificationsTab'
import ItrustittreeTab from './ItrustittreeTab/ItrustittreeTab'
import PrivacySettingsContainer from '../../containers/PrivacySettingsContainer'
import InviteCenterTab from './InviteCenterTab/InviteCenterTab'
import ReviewTab from './ReviewTab/ReviewTab'
import $ from 'jquery'
import ReviewCreate from '../CommonComponents/ReviewCreate/ReviewCreate'

import axios from 'axios'

import $J from 'jquery'
import OrgsTotalTab from './OrgsTotalTab/OrgsTotalTab'
import * as environemnt from '../../services/environment'
import { userToken } from '../../services/customFunction'
import ConfirmUntrustPopupModal from '../ProfileOtherUser/ConfirmUntrustModal/ConfirmUntrustPopupModal'
import ConfirmUnfriendPopupModal from '../ProfileOtherUser/ConfirmUnfriendModal/ConfirmUnfriendPopupModal'
import { formatMemberDirectoryName } from '../../services/formatService'
import { userProfile } from '../../services/authService'
import { LOGIN_ROUTE } from '../../services/routeConst'
import { customLogger } from '../../utils/CustomLogger'
import { Helmet } from 'react-helmet'
import ConfirmRemoveFromOrgPopupModal from '../ProfileOtherUser/ConfirmRemoveFromOrgModal/ConfirmRemoveFromOrgPopupModal'
import InvitePopupModal from '../ProfileOtherUser/InvitePopupModal/InvitePopupModal'
import { removeMemberCall } from '../../services/memberApiService'
import WhatsAppNumberModal from '../CommonComponents/WhatsAppNumberModal/WhatsAppNumberModal'
import { isMobile } from 'react-device-detect'
import { deleteReviewCall } from '../../services/reviewApiService'
import ConfirmDeleteReviewModal, {
  hideConfirmDeleteReviewModal,
  showConfirmDeleteReviewModal,
} from '../CommonComponents/ConfirmDeleteReviewModal/ConfirmDeleteReviewModal'
import SignupDisclaimerModal, { showSignupDisclaimerModal } from '../SignupNew/SignupDislaimerModal'
import ISupport from '../CommonComponents/ISupport/ISupport'
class Profile extends Component {
  constructor(props) {
    super(props)
    //console.log('Profile:', props)
    this.TrustDirectoryTabRef = React.createRef()
    this.friendTabRef = React.createRef()
    this.memberTabRef = React.createRef()
    this.galleryTabRef = React.createRef()
    this.overviewGenericTabRef = React.createRef()
    this.reviewTabRef = React.createRef()
    this.orgsTotalTabRef = React.createRef()
    this.inviteCenterTabRef = React.createRef()
    this.activityTabRef = React.createRef()
    this.notificationsTabRef = React.createRef()
    this.postsTabRef = React.createRef()
    this.mypostsTabRef = React.createRef()

    this.state = {
      user: customFunction.getUser(),
      activeTab: window.location.hash
        ? window.location.hash
        : ENABLE_SOCIAL_MEDIA
        ? '#posts'
        : '#overview',

      trust_directory_total_records: customFunction.getUser().total_trusted
        ? customFunction.getUser().total_trusted + customFunction.getUser().total_trust_me
        : 0,
      friend_total_records: customFunction.getUser().total_friends
        ? customFunction.getUser().total_friends
        : 0,
      orgs_total_records: customFunction.getUser().total_orgs
        ? customFunction.getUser().total_orgs
        : 0,
      member_total_records: customFunction.getUser().total_users_members
        ? customFunction.getUser().total_users_members
        : 0,
      gallery_total_records: customFunction.getUser().total_galleries
        ? customFunction.getUser().total_galleries
        : 0,

      userHeaderTrustSection: null,
      overall_reviews: {
        total_reviews: 0,
        total_received_reviews: 0,
        total_given_reviews: 0,
      },
      avg_reviews: {
        given_reviews: 0,
        received_reviews: 0,
      },
      loginUserId: customFunction.getUser().id,
      itrustitRemoveData: {},
      friendRemoveData: {},
      friendHandler: null,
      itrustitHandler: null,
      loadingFriendStatusChange: false,
      loadingitrustitStatusChange: false,
      settingsType: 'privacy',
      confirmModalProps: null,
      inviteModalProps: null,
    }
    this.fetchUserData()
    window.onscroll = () => {
      this.overallInfiniteScroll()
    }
    this.scrollRef = React.createRef()
  }

  fetchUserData = async () => {
    let token = customFunction.userToken()
    if (customFunction.isUserLogin() && customFunction.userToken()) {
      const response = await userProfile(token)
      if (response?.status === true) {
        const { profileInfo } = response
        customFunction.setUser(profileInfo)
        if (profileInfo) {
          this.setState({ user: profileInfo })
        }
      } else {
        this.props.history.push({ pathname: LOGIN_ROUTE })
      }
    }
  }

  overallInfiniteScroll() {
    // console.log(this.state.activeTab);
    switch (this.state.activeTab) {
      case '#friend':
        this.friendTabRef.current && this.friendTabRef.current.infiniteScroll()
        break
      case '#trust':
        this.TrustDirectoryTabRef.current && this.TrustDirectoryTabRef.current.infiniteScroll()
        break
      case '#member':
        this.memberTabRef.current && this.memberTabRef.current.infiniteScroll()
        break
      case '#orgs_total':
        this.orgsTotalTabRef.current && this.orgsTotalTabRef.current.infiniteScroll()
        break
      default:
        break
    }
  }

  setLoadingFriendStatusChange = (value = false) => {
    //this.setState({ loadingFriendStatusChange: value });
  }

  setLoadingitrustitStatusChange = (value = false) => {
    //console.log('setting to ', value);
    //this.setState({ loadingitrustitStatusChange: value });
  }

  confirmitrustitAddRemove = (needsConfirmation = false, data, tab) => {
    // console.log(needsConfirmation, data);
    let handler
    switch (tab) {
      case 'friend':
        handler = this.friendTabRef.current.itrustitHandle
        break
      case 'member':
        handler = this.memberTabRef.current.itrustitHandle
        break
      case 'trust':
        handler = this.TrustDirectoryTabRef.current.itrustitHandle
        break

      case 'orgs_total':
        handler = this.orgsTotalTabRef.current.itrustitHandle
        break
      default:
        handler = this.friendTabRef.current.itrustitHandle
        break
    }
    this.setState({
      itrustitRemoveData: data,
      handler,
    })
    needsConfirmation && $('#confirm_untrust_popup_modal').modal('show')
  }

  confirmFriendAddRemove = (needsConfirmation = false, data, tab) => {
    // console.log(needsConfirmation, data);
    let handler
    switch (tab) {
      case 'friend':
        handler = this.friendTabRef.current.friendAddRemoveHandle
        break
      case 'member':
        handler = this.memberTabRef.current.friendAddRemoveHandle
        break
      case 'trust':
        handler = this.TrustDirectoryTabRef.current.friendAddRemoveHandle
        break
      case 'orgs_total':
        handler = this.orgsTotalTabRef.current.friendAddRemoveHandle
        break

      default:
        handler = this.friendTabRef.current.friendAddRemoveHandle
        break
    }
    this.setState({
      friendRemoveData: data,
      friendHandler: handler,
    })
    needsConfirmation && $('#confirm_unfriend_popup_modal').modal('show')
  }

  componentDidMount() {
    //console.log(this.state.activeTab)
    const tabs = [
      '#friend',
      '#activity',
      '#trust',
      '#invite_center',
      '#orgs_total',
      '#orgs_common',
      '#gallery',
      '#posts',
      '#myposts',
      '#review',
    ]
    tabs.includes(this.state.activeTab)
      ? window.scrollTo({
          top: ($(this.state.activeTab)?.offset()?.top ?? 125) - 125,
          behavior: 'smooth',
        })
      : window.scrollTo(0, 0)
    if (localStorage.getItem('show_signup_disclaimer') === 'true') {
      localStorage.removeItem('show_signup_disclaimer')
      showSignupDisclaimerModal()
    }
    this.setState(
      {
        user: customFunction.getUser(),
      },
      () => {
        if (this.state.activeTab === '#review') {
          const searchParams = new URLSearchParams(window?.location?.search)
          const reviewType =
            searchParams.get('type') ?? this.state.user?.user_type === 'individual'
              ? 'given'
              : 'received'
          this.showReviewTab2(reviewType)
        }
        this.props.userStoreAction({ user: this.state.user })
      },
    )
  }

  displayAllTabCount() {
    const self = this
    var data = ''
    let api_url = environemnt.BASE_API + 'display-all-tab-count'
    axios
      .post(api_url, data, {
        headers: {
          Authorization: userToken(),
          Accept: 'application/x.iTRUSTit.v1+json',
        },
      })
      .then(function (response) {
        if (typeof response.status !== 'undefined' && response.status === 200) {
          let trustList = response.data.data
          let total_trust_me =
            trustList.trusted_type_count.total.trust_me + trustList.trusted_type_count.total.i_trust
          self.setState({
            trust_directory_total_records: total_trust_me,
            friend_total_records: trustList.overall.total_friends,
            member_total_records: trustList.overall.total_records,
          })
          //console.log("===total m==",trustList.overall.total_friends);
        }
      })
      .catch(function (error) {
        customLogger(error)
        //return error.response;
      })
  }

  componentWillReceiveProps(nextProps) {
    //console.log('Profile componentWillReceiveProps:', nextProps, this.state)
    // alert(nextProps.location.hash);
    const tabs = [
      '#friend',
      '#activity',
      '#trust',
      '#invite_center',
      '#orgs_total',
      '#orgs_common',
      '#gallery',
      '#privacy-settings',
      '#notifications',
      '#itrustittree',
      '#activity',
      '#posts',
      '#review',
      '#myposts',
    ]
    let activeTab = ENABLE_SOCIAL_MEDIA ? '#posts' : '#overview'
    if (tabs.includes(nextProps.location.hash)) {
      activeTab = nextProps.location.hash
    }
    //fix only for posts and userposts bc I'm not sure about other tabs
    if (tabs.includes(window.location.hash) && window?.location?.hash?.includes('posts')) {
      activeTab = window.location.hash
    }
    if (this.state.activeTab === '#review' && !nextProps.location.hash) {
      activeTab = '#review'
    }
    const prevTab = this.state.activeTab

    this.setState({ activeTab: activeTab }, () => {
      if (prevTab !== this.state.activeTab) {
        window.scrollTo({
          top: $(this.state.activeTab)?.offset()?.top - 125 ?? 0,
          behavior: 'smooth',
        })
      }
    })
    if (Object.keys(nextProps?.reviewuser)?.length > 0 || nextProps?.reviewuser?.reset) {
      this.setState(
        {
          userOther: nextProps?.reviewuser?.reset ? this.state.user : nextProps.reviewuser,
          isLoginUserClick: nextProps.reviewuser.isLoginUserClick ? true : false,
        },
        () => {
          $('#reviewCreateModal').modal('show')
        },
      )
    }
  }

  getProfile = () => {
    let getProfileReqData = {}
    let self = this
    getProfile(getProfileReqData).then(function (response) {
      if (typeof response.status !== 'undefined' && response.status === 200) {
        let apiResponseData = response.data
        let stateData = {
          user: apiResponseData.profileInfo,
          userOther: apiResponseData.profileInfo,
        }
        self.setState(stateData, () => {})
      }
    })
  }

  showFriendsTab = (data) => (event) => {
    window.scrollTo({
      top: $('#friend').offset().top - 125,
      behavior: 'smooth',
    })

    this.setState({
      activeTab: '#friend',
    })
    window.history.pushState(null, null, '#friend')
    this.friendTabRef.current.profileHeader_friend_tab_click_Handle(data)
  }

  showTrustDirectoryTab = (data) => (event) => {
    //console.log('showTrustDirectoryTab:', data)
    $(`#profile-tabs a[href="trust"]`).tab('show')
    window.scrollTo({
      top: $('#trust').offset().top - 125,
      behavior: 'smooth',
    })

    window.history.pushState(null, null, '#trust')
    this.setState({
      activeTab: '#trust',
    })
    this.TrustDirectoryTabRef.current.profileHeader_trustmeby_tab_click_Handle(data)
  }

  showiTrustitTab = (data) => (event) => {
    //console.log('showiTrustitTab:', data)
    $(`#profile-tabs a[href="trust"]`).tab('show')
    window.scrollTo({
      top: $('#trust').offset().top - 125,
      behavior: 'smooth',
    })

    window.history.pushState(null, null, '#trust')
    this.setState({
      activeTab: '#trust',
    })
    this.TrustDirectoryTabRef.current.profileHeader_itrustit_tab_click_Handle(data)
  }
  changeActiveTab = (data) => (event) => {
    this.setState({
      activeTab: data,
    })
    window.history.pushState(null, null, data)

    window.scrollTo({
      top: $(data)?.offset()?.top - 125,
      behavior: 'smooth',
    })

    if (data === '#trust') {
      //this.TrustDirectoryTabRef.current.profileHeader_itrustit_tab_click_Handle()
    } else if (data === '#friend') {
      //this.friendTabRef.current.profileHeader_friend_tab_click_Handle()
    } else if (data === '#member') {
      //this.memberTabRef.current.profileHeader_member_tab_click_Handle()
    } else if (data === '#gallery') {
      this.galleryTabRef.current.profileHeader_gallery_tab_click_Handle()
    } else if (data === '#overview') {
      this.overviewGenericTabRef.current.profileHeader_overview_tab_click_Handle()
    } else if (data === '#review') {
      //this.reviewTabRef.current.profileHeader_review_tab_click_Handle();
    } else if (data === '#orgs_total') {
      //this.orgsTotalTabRef.current.profileHeader_orgs_total_tab_click_Handle()
    } else if (data === '#activity') {
      this.activityTabRef.current.profileHeader_activity_tab_click_Handle()
    } else if (data === '#notifications') {
      this.notificationsTabRef.current.profileHeader_notifications_tab_click_Handle()
    } else if (data === '#privacy-settings') {
      // this.notificationsTabRef.current.profileHeader_notifications_tab_click_Handle();
    } else if (data === '#invite_center') {
      this.inviteCenterTabRef.current.profileHeader_invite_center_tab_click_Handle()
    } else if (data === '#itrustittree') {
    }
  }

  showReviewTab = (data) => (event) => {
    window.scrollTo({
      top: $('#review').offset().top - 125,
      behavior: 'smooth',
    })

    this.setState({
      activeTab: '#review',
    })
    this.reviewTabRef.current.profileHeader_review_tab_click_Handle(data)
    window.history.pushState(null, null, '#review')
  }

  showReviewTab2 = (data) => {
    window.scrollTo({
      top: $('#review').offset().top - 125,
      behavior: 'smooth',
    })

    this.setState({
      activeTab: '#review',
    })
    this.reviewTabRef.current.profileHeader_review_tab_click_Handle(data)
    window.history.pushState(null, null, '#review')
  }

  profileHeader_itrustit_Handle = (total_records) => {
    this.setState({
      trust_directory_total_records: total_records,
    })
    this.getProfile()
  }
  profileHeader_itrustit_recall_Handle = () => {
    this.TrustDirectoryTabRef.current.profileHeader_itrustit_tab_click_Handle()
    this.getProfile()
  }

  profileHeader_friend_Handle = (total_records) => {
    this.setState({
      friend_total_records: total_records,
    })
    this.getProfile()
  }

  profileHeader_friend_recall_Handle = () => {
    this.friendTabRef.current.profileHeader_friend_tab_click_Handle()
    this.getProfile()
  }

  profileHeader_gallery_Handle = (total_records) => {
    this.setState({
      gallery_total_records: total_records,
    })
  }

  profileHeader_member_Handle = (total_records) => {
    this.setState({
      member_total_records: total_records,
    })
  }

  profileHeader_review_Handle = (overall_reviews, avg_reviews) => {
    this.setState({
      overall_reviews: overall_reviews,
      avg_reviews: avg_reviews,
    })
  }

  profileHeader_total_orgs_Handle = (total_records) => {
    if (total_records && total_records.total_records) {
      this.setState({
        orgs_total_records: total_records.total_records,
      })
    }
  }
  setModalProps = (modalProps, needsConfirmation, cleanup = () => {}) => {
    this.setState(
      { confirmModalProps: modalProps.confirm, inviteModalProps: modalProps.invite },
      () => {
        needsConfirmation
          ? $J('#confirm_remove_from_org').modal('show')
          : this.memberAddRemoveYour(
              {
                is_your_member_user: modalProps.confirm.dataYour?.is_your_member_user,
                id: modalProps.confirm.user_id,
                flag: 'member_of_your_organization',
              },
              cleanup,
            )
      },
    )
  }

  memberAddRemoveYour = async (data, cleanup) => {
    let memberAddRemoveReqData = {
      id: data.id,
      member_type: data.flag,
    }
    if (data.is_your_member_user !== 0) {
      const res = await removeMemberCall(memberAddRemoveReqData)
      if (res?.status === 200) {
        cleanup?.()
        // let apiResponseData = response.data
        // self.setState({ is_your_member_user: 0 })
      }
    } else if (data.is_your_member_user !== 2) {
      $J('#invite_modal').modal('show')
    }
  }

  reviewCreatedSuccessfullyHandler = () => {
    this.setState({
      activeTab: '#review',
    })
    this.reviewTabRef.current.profileHeader_review_tab_click_Handle('given')
    window.history.pushState(null, null, '#review')
  }

  confirmDeleteReview = (reviewId) => {
    this.setState({ reviewIdToDelete: reviewId }, () => showConfirmDeleteReviewModal())
  }

  deleteReview = async () => {
    const response = await deleteReviewCall({ id: this.state.reviewIdToDelete })
    if (response?.status === 200) {
      this.setState({
        user: { ...this.state.user, total_given_review: this.state.user.total_given_review - 1 },
      })
      hideConfirmDeleteReviewModal()
      customFunction.toaster('success', response?.data?.message)
    }
    this.reviewTabRef.current.profileHeader_review_tab_click_Handle('given')
  }

  render() {
    const { user, overall_reviews, avg_reviews } = this.state
    let userHeaderTrustSection = null
    let noPaddingContainer =
      isMobile && this.state.activeTab?.includes('posts') ? 'container body-container' : 'container'
    if (user.user_type === 'individual') {
      userHeaderTrustSection = (
        <div className='i_trust_badge'>
          <div className='container'>
            <div className='badge_blk personal_profile'>
              <div className='badge_content'>
                <div className='badge'>
                  <div className='b-triangle' />
                  <div className='b-inner'>
                    <h4>iTRUST</h4>
                    <div className='d-flex d1'>
                      <div className='col-6 digits_cat'>
                        <a
                          onClick={this.showiTrustitTab('business')}
                          className={
                            ' ' +
                            (this.state.activeTab === '#trust' ? 'active digit_name' : 'digit_name')
                          }
                          data-bs-toggle='tab'
                          href='#trust'
                        >
                          <div className='digit'>{user.total_trusted_business}</div>
                          business
                          {/* <div className="digit_name">business</div> */}
                        </a>
                      </div>
                      <div className='col-6 digits_cat'>
                        <a
                          onClick={this.showiTrustitTab('organization')}
                          className={
                            ' ' +
                            (this.state.activeTab === '#trust' ? 'active digit_name' : 'digit_name')
                          }
                          data-bs-toggle='tab'
                          href='#trust'
                        >
                          <div className='digit'>{user.total_trusted_organizations}</div>
                          organization
                          {/* <div className="digit_name">organization</div> */}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='badge_star'>
                  <ul className='nav nav-review-tab'>
                    <a
                      onClick={this.showReviewTab('given')}
                      className={'nav-link ' + (this.state.activeTab === '#review' ? 'active' : '')}
                      data-bs-toggle='tab'
                      href='#review'
                    >
                      {overall_reviews.total_given_reviews} Reviews Given
                      {/* <a href={null}>78 Reviews</a> */}
                      <ul className='d-flex'>
                        <ReactStars
                          edit={false}
                          size={STAR_RATING_PROFILE_HEADER_SIZE}
                          color1={STAR_RATING_iTRUST_INACTIVE_COLOR}
                          color2={STAR_RATING_iTRUST_ACTIVE_COLOR}
                          value={avg_reviews.given_reviews}
                        />
                      </ul>
                    </a>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    } else {
      userHeaderTrustSection = (
        <div className='i_trust_badge'>
          <div className='container'>
            <div className='badge_blk editprofile1'>
              <div className='badge_content'>
                <div className='badge yellow'>
                  <div className='b-triangle' />
                  <div className='b-inner'>
                    <h4>Trusted By</h4>
                    <div className='d1'>
                      <div className='digits_cat'>
                        <div className='digit'>
                          <a
                            className={
                              'nav-link digit mb-0' +
                              (this.state.activeTab === '#trust' ? 'active' : '')
                            }
                            data-bs-toggle='tab'
                            href='#trust'
                            onClick={this.showTrustDirectoryTab('')}
                          >
                            {user.total_trust_me}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='badge_star text-center'>
                  <ul className='nav nav-review-tab'>
                    <a onClick={this.showReviewTab('received')} data-bs-toggle='tab' href='#review'>
                      {overall_reviews.total_received_reviews} Reviews Received
                      {/* <a href={null}>100 Reviews</a> */}
                      <ul className='d-flex'>
                        <ReactStars
                          edit={false}
                          size={STAR_RATING_PROFILE_HEADER_SIZE}
                          color1={STAR_RATING_TRUST_ME_INACTIVE_COLOR}
                          color2={STAR_RATING_TRUST_ME_ACTIVE_COLOR}
                          value={avg_reviews.received_reviews}
                        />
                      </ul>
                    </a>
                  </ul>
                </div>
              </div>
              <div className='badge_content'>
                <div className='badge'>
                  <div className='b-triangle' />
                  <div className='b-inner'>
                    <h4>iTRUST</h4>
                    <div className='d1'>
                      <div className='digits_cat'>
                        <div className='digit'>
                          {/* {user.total_trusted} */}
                          <a
                            className={
                              'nav-link digit mb-0' +
                              (this.state.activeTab === '#trust' ? 'active' : '')
                            }
                            data-bs-toggle='tab'
                            href='#trust'
                            onClick={this.showiTrustitTab('')}
                          >
                            {user.total_trusted}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='badge_star'>
                  <ul className='nav nav-review-tab'>
                    <a onClick={this.showReviewTab('given')} data-bs-toggle='tab' href='#review'>
                      {overall_reviews.total_given_reviews} Reviews Given
                      {/* <a href={null}>78 Reviews</a> */}
                      <ul className='d-flex'>
                        <ReactStars
                          edit={false}
                          size={STAR_RATING_PROFILE_HEADER_SIZE}
                          color1={STAR_RATING_iTRUST_INACTIVE_COLOR}
                          color2={STAR_RATING_iTRUST_ACTIVE_COLOR}
                          value={avg_reviews.given_reviews}
                        />
                      </ul>
                    </a>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }

    return (
      <AppLayout>
        <div style={{ background: '#f6f6f6' }}>
          <WhatsAppNumberModal whatsapp={user?.user_detail?.whatsapp} />
          <SignupDisclaimerModal user={user} />
          <Helmet>
            <title>{PROFILE_TITLE}</title>
          </Helmet>
          <section
            className='cover_details pro_business my_profile'
            style={{
              background:
                'url(' +
                (this.state.user.user_detail.cover_picture_medium
                  ? this.state.user.user_detail.cover_picture_medium
                  : NO_COVER_PICTURE) +
                ') no-repeat',
            }}
          >
            {userHeaderTrustSection}
          </section>

          <div className='container profile-info-container'>
            <div className='cover-inner-wrapper'>
              {' '}
              <div className='cover_inner'>
                <ProfileHeader
                  user={this.state.user}
                  profileHeader_userHeaderTrustSection_Handle={
                    this.profileHeader_userHeaderTrustSection_Handle
                  }
                  activeTab={this.state.activeTab}
                  setSettingsType={(type, cb) => this.setState({ settingsType: type }, cb?.())}
                  refreshUser={this.getProfile}
                />
                <div className='cover_bottom_wrapper position-relative'>
                  <div className='cover_bottom' ref={this.scrollRef}>
                    <div className='left_link'>
                      <div
                        className='position-absolute profile-scroll-btn'
                        style={{ top: 9, left: 4, zIndex: 2000 }}
                      >
                        <button
                          className='promotion-back-button'
                          onClick={() => (this.scrollRef.current.scrollLeft -= 100)}
                        >
                          <i
                            className='fas fa-chevron-left'
                            aria-hidden='true'
                            style={{ color: '#999' }}
                          ></i>
                        </button>
                      </div>
                      <ul className='upper-tabs nav nav-tabs'>
                        <li className='nav-item'>
                          <a
                            onClick={this.changeActiveTab('#overview')}
                            className={
                              'nav-link ' + (this.state.activeTab === '#overview' ? 'active' : '')
                            }
                            data-bs-toggle='tab'
                            href='#overview'
                          >
                            Overview
                          </a>
                        </li>
                        {ENABLE_SOCIAL_MEDIA ? (
                          <>
                            <li className='nav-item'>
                              <a
                                onClick={this.changeActiveTab('#myposts')}
                                className={
                                  'nav-link ' +
                                  (this.state.activeTab === '#myposts' ? 'active' : '')
                                }
                                data-bs-toggle='tab'
                                href='#myposts'
                              >
                                My posts
                              </a>
                            </li>
                            <li className='nav-item'>
                              <a
                                onClick={this.changeActiveTab('#posts')}
                                className={
                                  'nav-link ' + (this.state.activeTab === '#posts' ? 'active' : '')
                                }
                                data-bs-toggle='tab'
                                href='#posts'
                              >
                                Wall
                              </a>
                            </li>
                          </>
                        ) : (
                          ''
                        )}
                        <li className='nav-item'>
                          <a
                            onClick={this.changeActiveTab('#trust')}
                            className={
                              'nav-link ' + (this.state.activeTab === '#trust' ? 'active' : '')
                            }
                            data-bs-toggle='tab'
                            href='#trust'
                          >
                            {' '}
                            Trust List ({this.state.trust_directory_total_records})
                          </a>
                        </li>
                        {this.state.user.user_type === 'organization' && (
                          <li className='nav-item'>
                            <a
                              onClick={this.changeActiveTab('#member')}
                              className={
                                'nav-link ' + (this.state.activeTab === '#member' ? 'active' : '')
                              }
                              data-bs-toggle='tab'
                              href='#member'
                            >
                              {formatMemberDirectoryName(
                                this.state.user?.user_settings?.member_directory_name,
                                this.state.user?.user_settings?.custom_member_directory_name,
                              ) ?? 'Member Directory'}{' '}
                              ({this.state.member_total_records})
                            </a>
                          </li>
                        )}
                        <li className='nav-item'>
                          <a
                            onClick={this.changeActiveTab('#invite_center')}
                            className={
                              'nav-link ' +
                              (this.state.activeTab === '#invite_center' ? 'active' : '')
                            }
                            data-bs-toggle='tab'
                            href='#invite_center'
                          >
                            Invite Center
                          </a>
                        </li>
                        <li className='nav-item'>
                          <a
                            onClick={this.changeActiveTab('#friend')}
                            className={
                              'nav-link ' + (this.state.activeTab === '#friend' ? 'active' : '')
                            }
                            data-bs-toggle='tab'
                            href='#friend'
                          >
                            Friends ({this.state.friend_total_records})
                          </a>
                        </li>
                        <li className='nav-item'>
                          <a
                            onClick={this.changeActiveTab('#orgs_total')}
                            className={
                              'nav-link ' + (this.state.activeTab === '#orgs_total' ? 'active' : '')
                            }
                            data-bs-toggle='tab'
                            href='#orgs_total'
                          >
                            {/* Orgs Total */}
                            Orgs ({this.state.orgs_total_records})
                          </a>
                        </li>
                        <li className='nav-item me-0'>
                          <a
                            onClick={this.changeActiveTab('#gallery')}
                            className={
                              'nav-link ' + (this.state.activeTab === '#gallery' ? 'active' : '')
                            }
                            data-bs-toggle='tab'
                            href='#gallery'
                          >
                            Gallery ({this.state.gallery_total_records})
                          </a>
                        </li>
                        {/* <li className='nav-item'>
                      <a
                        onClick={this.changeActiveTab('#activity')}
                        className={
                          'nav-link ' + (this.state.activeTab === '#activity' ? 'active' : '')
                        }
                        data-bs-toggle='tab'
                        href='#activity'
                      >
                        Activity{' '}
                      </a>
                    </li> */}

                        {/* <li className="nav-item">
                                            <a onClick={this.changeActiveTab('#review')} className={"nav-link " + (this.state.activeTab == '#review' ? 'active' : '')} data-bs-toggle="tab" href="#review">Review</a>
                                        </li> */}
                        {/* <li className="nav-item">
                                            <a
                                                onClick={this.changeActiveTab("#notifications")}
                                                className={
                                                    "nav-link " + (this.state.activeTab == "#notifications" ? "active" : "")
                                                }
                                                data-bs-toggle="tab"
                                                href="#notifications"
                                            >
                                                Notifications{" "}
                                            </a>
                                        </li> */}
                      </ul>
                      <div
                        className='position-absolute profile-scroll-btn'
                        style={{ top: 9, right: 4, zIndex: 2000 }}
                      >
                        <button
                          className='promotion-back-button'
                          onClick={() => (this.scrollRef.current.scrollLeft += 100)}
                        >
                          <i
                            className='fas fa-chevron-right'
                            aria-hidden='true'
                            style={{ color: '#999' }}
                          ></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <section
            className={`listing_block ${user?.isupport ? 'isupport-visible' : ''} padding-reset`}
          >
            <div
              className={`${noPaddingContainer} ${
                this.state.user?.show_unclaimed_disclaimer === '1' ||
                (isMobile && this.state.activeTab === '#overview')
                  ? 'mt-2'
                  : 'mt-4'
              }`}
            >
              {isMobile && this.state.activeTab === '#overview' ? (
                <ISupport user={this.state.user} className='ms-2' />
              ) : null}
              <div
                className={`tab-content ${
                  this.state.user?.show_unclaimed_disclaimer === '1' ||
                  (isMobile && this.state.activeTab === '#overview')
                    ? 'mt-2'
                    : 'mt-4'
                }`}
              >
                <OverviewGenericTab
                  activeTab={this.state.activeTab}
                  ref={this.overviewGenericTabRef}
                  showReviewTab2={this.showReviewTab2}
                  user={this.state.user}
                  changeActiveTab={this.changeActiveTab}
                  showFriendsTab={this.showFriendsTab}
                />
                <TrustDirectoryTab
                  activeTab={this.state.activeTab}
                  ref={this.TrustDirectoryTabRef}
                  confirmitrustitAddRemove={this.confirmitrustitAddRemove}
                  confirmFriendAddRemove={this.confirmFriendAddRemove}
                  loadingFriendStatusChange={this.state.loadingFriendStatusChange}
                  loadingitrustitStatusChange={this.state.loadingitrustitStatusChange}
                  setLoadingFriendStatusChange={this.setLoadingFriendStatusChange}
                  setLoadingitrustitStatusChange={this.setLoadingitrustitStatusChange}
                  profileHeader_itrustit_Handle={this.profileHeader_itrustit_Handle}
                  profileHeader_friend_recall_Handle={this.profileHeader_friend_recall_Handle}
                  changeActiveTab={this.changeActiveTab}
                  setModalProps={this.setModalProps}
                />
                <GalleryTab
                  user_id={this.state?.user?.id}
                  loginUserId={this.state.loginUserId}
                  activeTab={this.state.activeTab}
                  ref={this.galleryTabRef}
                  profileHeader_gallery_Handle={this.profileHeader_gallery_Handle}
                />
                <FriendsTab
                  setModalProps={this.setModalProps}
                  activeTab={this.state.activeTab}
                  ref={this.friendTabRef}
                  confirmitrustitAddRemove={this.confirmitrustitAddRemove}
                  confirmFriendAddRemove={this.confirmFriendAddRemove}
                  loadingFriendStatusChange={this.state.loadingFriendStatusChange}
                  loadingitrustitStatusChange={this.state.loadingitrustitStatusChange}
                  setLoadingFriendStatusChange={this.setLoadingFriendStatusChange}
                  setLoadingitrustitStatusChange={this.setLoadingitrustitStatusChange}
                  profileHeader_friend_Handle={this.profileHeader_friend_Handle}
                  profileHeader_itrustit_recall_Handle={this.profileHeader_itrustit_recall_Handle}
                />
                <OrgsTotalTab
                  setModalProps={this.setModalProps}
                  user_id={this.state?.user?.id}
                  user={this.state.user}
                  activeTab={this.state.activeTab}
                  ref={this.orgsTotalTabRef}
                  profileHeader_friend_recall_Handle={this.profileHeader_friend_recall_Handle}
                  profileHeader_itrustit_recall_Handle={this.profileHeader_itrustit_recall_Handle}
                  profileHeader_total_orgs_Handle={this.profileHeader_total_orgs_Handle}
                  confirmitrustitAddRemove={this.confirmitrustitAddRemove}
                  confirmFriendAddRemove={this.confirmFriendAddRemove}
                  loadingFriendStatusChange={this.state.loadingFriendStatusChange}
                  loadingitrustitStatusChange={this.state.loadingitrustitStatusChange}
                  setLoadingFriendStatusChange={this.setLoadingFriendStatusChange}
                  setLoadingitrustitStatusChange={this.setLoadingitrustitStatusChange}
                />
                {this.state.user.user_type === 'organization' ? (
                  <MemberTab
                    setModalProps={this.setModalProps}
                    activeTab={this.state.activeTab}
                    ref={this.memberTabRef}
                    user={user}
                    confirmitrustitAddRemove={this.confirmitrustitAddRemove}
                    confirmFriendAddRemove={this.confirmFriendAddRemove}
                    loadingFriendStatusChange={this.state.loadingFriendStatusChange}
                    loadingitrustitStatusChange={this.state.loadingitrustitStatusChange}
                    setLoadingFriendStatusChange={this.setLoadingFriendStatusChange}
                    setLoadingitrustitStatusChange={this.setLoadingitrustitStatusChange}
                    profileHeader_member_Handle={this.profileHeader_member_Handle}
                    profileHeader_friend_recall_Handle={this.profileHeader_friend_recall_Handle}
                    profileHeader_itrustit_recall_Handle={this.profileHeader_itrustit_recall_Handle}
                    profileHeader_member_recall_Handle={this.profileHeader_member_recall_Handle}
                    loginUserId={this.state.loginUserId}
                  />
                ) : (
                  ''
                  // <Permission />
                )}
                <ActivityTab activeTab={this.state.activeTab} ref={this.activityTabRef} />
                <NotificationsTab activeTab={this.state.activeTab} ref={this.notificationsTabRef} />
                <ItrustittreeTab activeTab={this.state.activeTab} />
                <PrivacySettingsContainer
                  activeTab={this.state.activeTab}
                  settingsType={this.state.settingsType}
                />
                <InviteCenterTab
                  activeTab={this.state.activeTab}
                  user={this.state.user}
                  ref={this.inviteCenterTabRef}
                />

                <ReviewTab
                  activeTab={this.state.activeTab}
                  ref={this.reviewTabRef}
                  profileHeader_review_Handle={this.profileHeader_review_Handle}
                  user={this.state.user}
                  deleteReview={this.confirmDeleteReview}
                />
                {this.state.userOther?.id ? (
                  <ReviewCreate
                    reviewCreatedSuccessfullyHandler={this.reviewCreatedSuccessfullyHandler}
                    user_id={this.state?.user?.id}
                    user={this.state.userOther}
                    otherUser
                    user_type={this.state.user?.user_type}
                  />
                ) : null}
              </div>
            </div>
          </section>

          {/* <section className="footer_top_blk">
                    <div className="container">
                        <div className="footer_container">
                            <p className="varela">
                                Not here? Tell us what we're missing. If the business you're looking for isn't here, add
                                it!
                            </p>
                            <a href={null} className="blue_btn">
                                ADD BUSINESS
                            </a>
                        </div>
                    </div>
                </section> */}
          <ConfirmDeleteReviewModal deleteReview={this.deleteReview} />
          <ConfirmUntrustPopupModal
            username={this.state.user.business_organization_name}
            data={this.state.itrustitRemoveData}
            handler={this.state.handler}
            setLoadingitrustitStatusChange={this.setLoadingitrustitStatusChange}
            // key={this.props.index}
            // data={{
            //   is_trusted_user: this.props.user.is_trusted_user,
            //   id: this.props.user.id,
            //   index: this.props.index,
            // }}
            // itrustitRemove={this.itrustitRemove}
          />
          <ConfirmUnfriendPopupModal
            username={this.state.user.business_organization_name}
            data={this.state.friendRemoveData}
            handler={this.state.friendHandler}
            setLoadingFriendStatusChange={this.setLoadingFriendStatusChange}
            // username={this.props.user.business_organization_name}
            // key={this.props.index}
            // data={{
            //   is_friend_user: this.props.user.is_friend_user,
            //   user_id: this.props.user.id,
            //   friendship_id: this.props.user.friendship_id,
            //   index: this.props.index,
            // }}
            // friendAddRemoveList={this.friendAddRemoveList}
          />
          <ConfirmRemoveFromOrgPopupModal {...this.state.confirmModalProps} />
          <InvitePopupModal {...this.state.inviteModalProps} />
        </div>
      </AppLayout>
    )
  }
}
function mapStateToProps(state) {
  return {
    reviewuser: state.UserReducer.reviewuser,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ reviewUser }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(Profile)
