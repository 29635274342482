import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import { toaster } from '../../../../services/customFunction'
import renderInputField from '../../../Form/Input'

import * as environemnt from '../../../../services/environment'
import ReactSelect from '../../../../services/ReactSelect'
import * as api from '../../../../services/businessService'
import * as validation from '../../../../services/formValidation'
import { claimBusiness, claimActivationLinkResend } from '../../../../services/businessService'
import { LOGIN_ROUTE, CONTACT_ROUTE, TERMS_ROUTE } from '../../../../services/routeConst'
import axios from 'axios'
import { customLogger } from '../../../../utils/CustomLogger'

let formData = []
class ClaimBusiness2 extends Component {
  constructor(props) {
    super(props)

    this.state = {
      countryList: [],
      stateList: [],
      cityList: [],
      stateValue: null,
      cityValue: null,
      countryIndex: null,
      formData: [],
      error: [],
      isDisplaySuccessPage: false,
      relationship: {},
      relationshipValue: '',
      isLoading: false,
      signupText: 'Claim Business',
      resendLinkText: 'Click to Resend Verification Email',
      stateLoadingFirstTime: true,
      cityFlag: false,
      areaFlag: false,
      countryObject: '',
      zipValue: '',
      loadingText: 'Loading...',
      // address_line_1: this.props.user ? this.props.user.address_line_1 : "",
      // address_line_2: this.props.user ? this.props.user.address_line_2 : "",
    }
  }

  areaChangedHandler = (event) => {
    this.setState({ areaFlag: false })
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.initialValues) {
      if (!this.state.zipValue) {
        this.setState({
          zipValue: nextProps.initialValues.zipcode,
        })
      }
      setTimeout(() => {
        if (!this.state.countryObject) {
          let country_id = nextProps.initialValues.country_id
          let countryObject = this.state.countryList.filter((item) => {
            return item.value === country_id
          })
          if (countryObject.length > 0) {
            this.setState({
              countryObject: countryObject[0],
            })
          }
        }
      }, 100)
    }
  }
  componentDidMount = () => {
    window.scrollTo(0, 0)
    this.country()
  }
  zipcodeChange = (event) => {
    if (
      event.target.value != '' &&
      this.state.countryObject != '' &&
      this.state.countryObject.label == environemnt.UNITED_STATE_TEXT
    ) {
      this.setState(
        {
          zipValue: event.target.value,
        },
        this.getDataUsingZip,
      )
    }
  }
  claim2SubmitHandler = (data) => {
    this.setState({ isLoading: true, signupText: 'Loading...' })

    if (!data.is_confirm_authorized) data.is_confirm_authorized = '0'
    if (typeof data.city_id === 'object' && data.city_id !== null) data.city_id = data.city_id.value
    if (typeof data.state_id === 'object') data.state_id = data.state_id.value
    if (typeof data.country_id === 'object') data.country_id = data.country_id.value
    if (!this.state.areaFlag) {
      data.area = data.area
      data.city_id = ''
    }
    if (this.state.areaFlag) {
      data.area = ''
    }
    data.user_id = this.props.user.id
    data.user_type = this.props.user.user_type

    formData = { ...this.props.form1Data, ...data }

    let self = this

    claimBusiness(formData).then(function (response) {
      self.setState({ isLoading: false, signupText: 'Claim Business' })
      if (response.status === 422) {
        window.scrollTo(0, 0)
      } else if (response.status === 403) {
        toaster('error', response.data.message)
      } else {
        toaster('success', response.data.message)
        self.setState({ isDisplaySuccessPage: true })
      }
    })
  }
  categoryList = () => {
    let self = this

    api.categoryList().then(function (response) {
      if (response.status === true) {
        self.setState({ categoryList: response.categories })
      }
    })
  }

  relationshipHandler = (event) => {
    this.setState({
      relationship: { value: event.value, label: event.label },
      relationshipValue: event.value,
    })
  }

  countrySelectHandler = (event) => {
    let countryIndex = this.state.countryList.findIndex((x) => x.value === event.value)
    this.setState(
      {
        stateList: [],
        cityList: [],
        stateValue: null,
        cityValue: null,
        countryIndex: countryIndex,
        stateLoadingFirstTime: false,
        countryObject: event,
      },
      () => {
        this.stateHandler(event.value)
        if (
          event.value !== '' &&
          this.state.zipValue !== '' &&
          event.label === environemnt.UNITED_STATE_TEXT
        ) {
          this.getDataUsingZip()
        }
      },
    )
    this.setState({ countryObject: event })
    /*210824-1*/
    this.updateCountryDepencency()
  }
  /**
   * Function that return nothing and when country is selected reset all: state,zip, * city (area) and mark them as tocuhed for error. address may be included
   * @author   Amalia
   * @task     210824-1
   */
  updateCountryDepencency = () => {
    this.props.change('state_id', null)
    this.props.touch('state_id', null)
    this.props.change('zipcode', null)
    this.props.touch('zipcode', null)
    this.props.change('city_id', null)
    this.props.touch('city_id', null)
    this.props.change('area', null)
    this.props.touch('area', null)
  }
  // get city and state data using zip code when country is united state
  getDataUsingZip = () => {
    let api_url = environemnt.BASE_API + 'state-cities-from-zipcode'
    var params = {
      zipcode: this.state.zipValue,
      country_id: this.state.countryObject.value,
    }
    var self = this
    axios
      .post(api_url, params, {
        headers: { 'Content-Type': 'application/json' },
      })
      .then(function (response) {
        // set option in state dropdown and select first option as a state value
        var resultData = response.data.data
        if (resultData.state_list.length === 0) {
          self.stateHandler(self.state.countryObject.value)
        } else {
          var stateCopy = []
          resultData.state_list.map((state, index) => {
            let objState = { value: state.id, label: state.name }
            stateCopy.push(objState)
          })
          self.setState(
            {
              stateList: stateCopy,
            },
            () => {
              if (self.state.stateList.length > 0) {
                self.setState({
                  stateValue: self.state.stateList[0].value,
                })
                self.props.change('state_id', self.state.stateList[0])
              }
            },
          )

          var cityCopy = []
          resultData.city_list.map((state, index) => {
            let objCity = { value: state.id, label: state.name }
            cityCopy.push(objCity)
          })
          self.setState(
            {
              cityList: cityCopy,
            },
            () => {
              if (self.state.cityList.length > 0) {
                self.setState({
                  cityValue: self.state.cityList[0].value,
                  cityFlag: true,
                  areaFlag: true,
                })
                // for redux form state update
                self.props.change('city_id', self.state.cityList[0])
              } else {
                self.setState({ cityFlag: false })
              }
            },
          )
        }
      })
      .catch(function (error) {
        customLogger(error)
        //return error.response;
      })
  }
  // stateSelectHandler = event => {
  //     this.setState({ stateValue: event.value, cityValue: null });
  //     this.city(event.value);
  // };

  // citySelectHandler = event => {
  //     this.setState({ cityValue: event.value });
  // };
  stateSelectHandler = (event) => {
    this.setState({
      stateValue: event.value,
      cityValue: null,
      stateLoadingFirstTime: false,
    })
    this.city(event.value)
    /*210824-1 when state is selected reset city and mark isas touched for error*/
    this.props.change('city_id', null)
    this.props.touch('city_id')
  }

  citySelectHandler = (event) => {
    this.setState({ cityValue: event.value, areaFlag: true })
  }

  country = () => {
    let self = this
    let stateCopy = { ...this.state }
    api.countryList().then(function (response) {
      if (response.status === true) {
        response.countries.map((country) => {
          let objContry = { value: country.id, label: country.name }
          stateCopy['countryList'].push(objContry)
        })
        self.setState(stateCopy, () => {
          let countryIndex = self.state.countryList.findIndex(
            (x) => x.value === self.props.user.country_id,
          )
          self.setState({ countryIndex: countryIndex }, () => {
            self.stateHandler(self.props.user.country_id)
            self.city(self.props.user.state_id)
          })
        })
      }
    })
  }

  // stateHandler(id) {
  //     var self = this;
  //     this.setState({ stateList: [], cityList: [], stateValue: null, cityValue: null });

  //     api.stateList(id).then(function(response) {
  //         if (response.status == true) {
  //             let stateCopy = { ...self.state };
  //             response.states.map((state, index) => {
  //                 if (index == 0) stateCopy["stateList"].push({ value: 0, label: "Select..." });

  //                 let objState = { value: state.id, label: state.name };
  //                 stateCopy["stateList"].push(objState);
  //             });
  //             self.setState(stateCopy, () => {
  //                 if (self.state.stateList.length > 0) self.setState({ stateValue: self.state.stateList[0].value });
  //             });
  //         }
  //     });
  // }
  stateHandler(id) {
    var self = this

    api.stateList(id).then(function (response) {
      if (response.status === true) {
        let stateArr = []
        response.states.map((state, index) => {
          if (index === 0) stateArr.push({ value: 0, label: 'Select...' })
          let objState = { value: state.id, label: state.name }
          stateArr.push(objState)
        })

        self.setState({ stateList: stateArr }, () => {
          if (self.state.stateList.length > 0) {
            if (
              Object.keys(self.props.user).length > 0 &&
              self.props.user.state_id &&
              self.state.stateLoadingFirstTime
            ) {
              self.setState({
                stateValue: self.props.user.state_id,
              })
            } else {
              self.setState({
                stateValue: self.state.stateList[0].value,
              })
            }
          }
        })
      }
    })
  }

  // city(id) {
  //     let self = this;
  //     this.setState({ cityList: [] });

  //     api.cityList(id).then(function(response) {
  //         if (response.status == true) {
  //             let stateCopy = { ...self.state };
  //             response.cities.map((city, index) => {
  //                 if (index == 0) stateCopy["cityList"].push({ value: 0, label: "Select..." });
  //                 let objState = { value: city.id, label: city.name };
  //                 stateCopy["cityList"].push(objState);
  //             });
  //             self.setState(stateCopy, () => {
  //                 if (self.state.cityList.length > 0) self.setState({ cityValue: self.state.cityList[0].value });
  //             });
  //         }
  //     });
  // }

  city(id) {
    let self = this
    this.setState({ cityList: [] })

    api.cityList(id).then(function (response) {
      if (response.status === true) {
        let cityArr = []
        response.cities.map((city, index) => {
          if (index === 0) cityArr.push({ value: 0, label: 'Select...' })
          let objCity = { value: city.id, label: city.name }
          cityArr.push(objCity)
        })

        self.setState({ cityList: cityArr }, () => {
          if (self.state.cityList.length > 0) {
            if (
              Object.keys(self.props.user).length > 0 &&
              self.props.user.city_id &&
              self.state.stateLoadingFirstTime
            ) {
              self.setState({
                cityValue: self.props.user.city_id,
                areaFlag: true,
              })
            } else {
              self.setState({
                cityValue: self.state.cityList[0].value,
                areaFlag: true,
              })
            }
          }
        })

        if (response.cities.length === 0) {
          self.setState({ cityFlag: true })
        } else {
          self.setState({ cityFlag: false })
        }
      }
    })
  }

  resendActivationLink = () => {
    this.setState({ resendLinkText: 'Loading....' })
    let self = this
    claimActivationLinkResend(formData).then(function (response) {
      self.setState({ resendLinkText: 'Click to Resend Verification Email' })
      if (response.status === 403) {
        toaster('error', response.data.message)
        window.scrollTo(0, 0)
      } else {
        window.scrollTo(0, 0)
        toaster('success', response.data.message)
      }
    })
  }

  claimChangeHandler = (event) => {
    let inputName = event.target.name
    let inputValue = event.target.value

    this.setState({ [inputName]: inputValue })
  }

  render() {
    const { invalid, handleSubmit } = this.props
    const {
      countryList,
      stateList,
      cityList,
      isLoading,
      isDisplaySuccessPage,
      resendLinkText,
      loadingText,
      countryObject,
    } = this.state
    return (
      <div className='container'>
        {!isDisplaySuccessPage ? (
          <div className='row'>
            <div className='loginblk signupblk'>
              <div className='panel panel-default'>
                <div className='panel-heading'>
                  <h3 className='panel-title'>Claim Your Business - Address</h3>
                </div>
                <div className='panel-body'>
                  <form
                    method='post'
                    id='signupBusinessForm2'
                    name='signupBusinessForm2'
                    formkey='signupBusinessForm2'
                    onSubmit={handleSubmit(this.claim2SubmitHandler).bind(this)}
                  >
                    <fieldset>
                      <div className='row fieldflex form-group'>
                        <div className='col-md-3'>
                          <label className='control-required'>Country:</label>
                        </div>
                        <div className='col-md-9'>
                          <div className='for-select'>
                            <Field
                              type='text'
                              component={ReactSelect}
                              name='country_id'
                              options={countryList}
                              onChange={this.countrySelectHandler}
                              validate={[validation.required]}
                              isSelect='false'
                              valueKey={
                                countryList.length > 0 && countryList[this.state.countryIndex]
                              }
                            />
                          </div>
                        </div>
                      </div>
                      {countryObject ? (
                        <div className='row fieldflex form-group'>
                          <div className='col-md-3'>
                            <label className='control-required'>Zip / Postal Code:</label>
                          </div>
                          <div className='col-md-9'>
                            <Field
                              name='zipcode'
                              type='text'
                              className='form-control'
                              component={renderInputField}
                              validate={[validation.required]}
                              label='zipcode'
                              placeholder='Enter your zipcode'
                              autoComplete='off'
                              onBlur={this.zipcodeChange}
                            />
                          </div>
                        </div>
                      ) : (
                        ''
                      )}
                      {stateList.length > 0 ? (
                        <div className='row fieldflex form-group'>
                          <div className='col-md-3'>
                            <label className='control-required'>State/Province:</label>
                          </div>
                          <div className='col-md-9'>
                            <div className='for-select'>
                              <Field
                                type='text'
                                className='form-control'
                                component={ReactSelect}
                                name='state_id'
                                options={stateList}
                                value={this.state.stateValue}
                                valueKey={this.state.stateValue}
                                onChange={this.stateSelectHandler}
                                validate={[validation.required]}
                                isSelect='true'
                              />
                            </div>
                          </div>
                        </div>
                      ) : (
                        ''
                      )}
                      {cityList.length > 0 ? (
                        <div className='row fieldflex form-group'>
                          <div className='col-md-3 control-required'>City:</div>
                          <div className='col-md-9'>
                            <div className='for-select'>
                              <Field
                                type='text'
                                className='form-control'
                                component={ReactSelect}
                                name='city_id'
                                options={cityList}
                                value={this.state.cityValue}
                                onChange={this.citySelectHandler}
                                valueKey={this.state.cityValue}
                                isSelect='true'
                                validate={[validation.required]}
                              />
                            </div>
                          </div>
                        </div>
                      ) : this.state.cityFlag ? (
                        <div className='row fieldflex form-group'>
                          <div className='col-md-3'>
                            <label className='control-required'>City/Area:</label>
                          </div>
                          <div className='col-md-9'>
                            <Field
                              name='area'
                              type='text'
                              className='form-control'
                              component={renderInputField}
                              label='City/Area'
                              placeholder='Enter city/area'
                              autoComplete='off'
                              validate={[validation.required]}
                              onChange={this.areaChangedHandler}
                            />
                          </div>
                        </div>
                      ) : (
                        ''
                      )}
                      <div className='row fieldflex form-group'>
                        <div className='col-md-3'>
                          <label className='control-required'>Address 1:</label>
                        </div>
                        <div className='col-md-9'>
                          <Field
                            name='address_line_1'
                            type='text'
                            className='form-control'
                            component={renderInputField}
                            validate={[validation.required]}
                            label='address_line_1'
                            placeholder='Enter address'
                            autoComplete='off'
                            // onChange={this.claimChangeHandler}
                            //valueKey={this.state.address_line_1}
                            //input={{ disabled: true }}
                          />
                        </div>
                      </div>
                      <div className='row fieldflex form-group'>
                        <div className='col-md-3'>
                          <label>Address 2:</label>
                        </div>
                        <div className='col-md-9'>
                          <Field
                            name='address_line_2'
                            type='text'
                            className='form-control'
                            component={renderInputField}
                            //validate={[validation.required]}
                            label='address_line_2'
                            placeholder='Enter address'
                            autoComplete='off'
                            //onChange={this.claimChangeHandler}
                            // valueKey={this.state.address_line_2}
                            // input={{ disabled: true }}
                          />
                        </div>
                      </div>
                    </fieldset>
                    <div className='add_bottom'>
                      <div className='radio1'>
                        <label className='radioblk'>
                          <p>
                            {/* Confirm that you are the Authorized Representative of the
                                                        business */}
                            By clicking Claim Business I acknowledge that I am an Authorized
                            Representative of this Business and I have read and agree to the{' '}
                            <Link className='purple' to={TERMS_ROUTE} target='_blank'>
                              {' '}
                              Terms of Service{' '}
                            </Link>
                          </p>
                          <Field
                            type='radio'
                            component={renderInputField}
                            name='is_confirm_authorized'
                            value='1'
                            validate={[validation.required]}
                          />
                          <span className='checkmark' />
                        </label>
                      </div>
                      <div>
                        {console.log(
                          this.props,
                          this.props.submitFailed,
                          this.state.is_username_exists,
                          this.props.invalid,
                        )}
                        {/*210824-1*/}
                        {validation.isFormValid(this.props.submitFailed, '0', this.props.invalid)}
                        <input
                          type='submit'
                          value={this.state.signupText}
                          className={classNames(
                            'btn',
                            'btn-lg',
                            'btn-success',
                            'btn-block',
                            'loginbtn',
                          )}
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className='claim_steps'>
            <div className='success_blk'>
              {/* <h3 className="add_title">
                                    <span className="other_txt">Success!</span>
                                </h3>
                                <p className="text-center mt-3 mb-0">You’re almost done!</p>
                                <p className="text-center mt-1 mb-5">
                                    We just sent an email with a link to activate your account to:
                                </p>
                                <div className="success_text">
                                    <p>
                                        You’re almost done!
                                    <br />
                                        We just sent an email with a link to activate your account to: <br />
                                        {this.props.form1Data.email ? this.props.form1Data.email : ''}
                                    </p>
                                </div>
                                <div className="strong mt-2 mb-3">
                                    <p>1. Check your email now and follow the easy instructions.</p>
                                    <p>
                                        2. Click on the activation link in the email to verify your email address and
                                        activate your account!
                                </p>
                                </div>
                                <div className="success_text note">
                                    <p>NOTE:</p>
                                    <p className="mb-4">
                                        Be sure to check your “junk” and “spam” folders, since your email provider probably
                                        will not recognize email from iTRUSTit yet.
                                </p>
                                    <p className="mb-4">
                                        If you locate the email in your junk or spam folder, please remember to “white-list”
                                        us by adding us (the sender) to your “safe senders list”. This will insure that your
                                        message and notification emails get delivered to your inbox in the future.
                                </p>
                                    <p className="mb-4">
                                        {" "}
                                        Attention Gmail users! if you cannot find the iTRUSTit Activation email click here
                                        for a short video to find your GMail Junk-spam.
                                </p>
                                    <p className="mb-4 danger">
                                        {" "}
                                        Important: If you do not receive your activation email within 90 seconds, double
                                        check that your email is correct above, then
                                </p>
                                </div>

                                <div className="add_bottom">
                                    <div className="text-center">
                                        <a
                                            href={null}
                                            onClick={this.resendActivationLink}
                                            className="mt-3 mb-3 continue_linear"
                                        >
                                            {resendLinkText}
                                        </a>
                                    </div>
                                </div>

                                <div className="success_text note">
                                    <p>NOTE:</p>
                                    <p>
                                        After clicking the above button a few times, if you still have not received the
                                        activation email, your mail system or server is probably blocking us so you will
                                        need to WHITELIST iTRUSTit. To do this you need to contact your email provider or
                                        host and get them to add the following information to their whitelist for your
                                        account:
                                </p>
                                    <p>mg.itrustit.com</p>
                                    <p>209.61.151.226</p>
                                    <p>209.61.151.250</p>
                                    <p className="mt-4 mb-4 danger">
                                        IMPORTANT: DO NOT close this page until after you receive your activation email.
                                </p>
                                </div>

                                <div className="strong">
                                    <p>3. After you complete steps 1 and 2 then...Welcome to the iTRUSTit community!</p>
                                </div>
                                <div className="add_bottom">
                                    <div className="text-center">
                                        <Link to={LOGIN_ROUTE} className="continue">
                                            Finish
                                    </Link>
                                    </div>
                                </div> */}

              <p className='strong text-center mt-5 mb-5'>
                <h4>Sign Up Almost Complete</h4>
              </p>
              <div className='mt-3 mb-3'>
                <p>
                  We just sent your verification email to:{' '}
                  <strong>{this.props.form1Data.email ? this.props.form1Data.email : ''}</strong>
                </p>
              </div>
              <div className='strong mt-3 mb-3'>
                <p>Check your Inbox (or Spam/Junk Mail) for our Verification email.</p>
                <p>Click ACTIVATE in the email to complete your Sign Up.</p>
              </div>
              <div className='success_text note'>
                <p className='mb-4'>
                  If you did not receive our email within 90 seconds double check your email address
                  above is correct then:
                </p>
              </div>
              <div className='add_bottom'>
                <div className='text-center'>
                  <a href={null} onClick={this.resendActivationLink} className='mt-3 mb-3 continue'>
                    {isLoading ? loadingText : resendLinkText}
                  </a>
                </div>
              </div>
              <div className='success_text note'>
                <p className='mt-4 mb-4'>
                  If you try to resend a couple times and still did not receive the email then
                  <Link to={CONTACT_ROUTE} className='ms-2 strong'>
                    CONTACT US
                  </Link>
                </p>
              </div>
              <div className='success_text note'>
                <p className='mt-4 mb-4 danger'>
                  IMPORTANT: DO NOT close this page until after you receive your verification email.
                </p>
              </div>
              <div className='strong success_text note'>
                <p className='mt-4 mb-4'>Welcome to the iTRUSTit community!</p>
              </div>
            </div>
          </div>
        )}
      </div>
    )
  }
}

function mapStateToProps(state, props) {
  let user = props.user
  return {
    initialValues: {
      country_id: Object.keys(user).length > 0 ? user.country_id : null,
      state_id: Object.keys(user).length > 0 ? user.state_id : null,
      city_id: Object.keys(user).length > 0 ? user.city_id : null,
      address_line_1: Object.keys(user).length > 0 ? user.address_line_1 : '',
      address_line_2: Object.keys(user).length > 0 ? user.address_line_2 : '',
      zipcode: Object.keys(user).length > 0 ? user.zipcode : '',
    },
  }
}

export default connect(mapStateToProps)(
  reduxForm({
    form: 'signupBusinessForm2',
    enableReinitialize: true,
  })(ClaimBusiness2),
)
// ClaimBusiness2 = reduxForm({
//     form: "signupBusinessForm2",
// })(ClaimBusiness2);
// export default ClaimBusiness2;
