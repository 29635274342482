import React, { Component, Fragment } from 'react'
// import AppLayout from "../../AppLayout";
import './ReviewTab.scss'
import * as reviewApiService from '../../../services/reviewApiService'
import { LIST_VIEW_ITEMS_PER_PAGE, LIST_VIEW_PAGE_RANGE } from '../../../services/const'
import Pagination from 'react-js-pagination'
import ReviewListDetail from '../../CommonComponents/ReviewListDetail/ReviewListDetail'
import Skeleton from 'react-loading-skeleton'
import MenuTypeTabs from '../../CommonComponents/MenuTypeTabs.jsx/MenuTypeTabs.jsx'

class ReviewTab extends Component {
  constructor(props) {
    super(props)
    //document.title = SEARCH_TITLE;

    this.state = {
      sortBy: 'ratings',
      sortOrder: 'asc', // asc | desc
      reviewsReceivedOrGiven: this.props.user.user_type === 'individual' ? 'given' : 'received', // given | received
      searchString: '',
      reviewTypeSubTab: '',

      activePage: 1,
      totalPages: 0,
      totalRecords: 0,

      reviewList: [],
      overall_reviews: {
        total_reviews: 0,
        total_received_reviews: 0,
        total_given_reviews: 0,
      },
      without_type_reviews: {
        total_reviews: 0,
        total_received_reviews: 0,
        total_given_reviews: 0,
      },
      total_reviews: {
        total_reviews: 0,
        total_received_reviews: 0,
        total_given_reviews: 0,
      },
    }

    this.handlePageChange = this.handlePageChange.bind(this)
  }

  componentDidMount() {
    //window.scrollTo(0, 0);
    this.reviewListRender()
  }

  reviewListRender() {
    let reviewListReqData = {
      page: this.state.activePage ? this.state.activePage : 1,

      review_type: this.state.reviewsReceivedOrGiven,
      search_string: this.state.searchString,
    }

    const self = this
    reviewApiService.reviewListCall(reviewListReqData).then(function (response) {
      if (response?.status === 200) {
        let apiResponseData = response.data.data
        let stateData = {
          totalPages: apiResponseData.total_pages,
          //totalRecords: apiResponseData.total_reviews.length,
          reviewList: apiResponseData.review_list,

          overall_reviews: apiResponseData.overall_reviews,
          without_type_reviews: apiResponseData.without_type_reviews,
          total_reviews: apiResponseData.total_reviews,
        }

        if (self.state.reviewsReceivedOrGiven === 'given') {
          stateData['totalRecords'] = apiResponseData.without_type_reviews.total_given_reviews
        } else if (self.state.reviewsReceivedOrGiven === 'received') {
          stateData['totalRecords'] = apiResponseData.without_type_reviews.total_received_reviews
        }

        if (self.state.activePage <= 0) {
          stateData['activePage'] = 1
        }

        self.props.profileHeader_review_Handle(
          apiResponseData.overall_reviews,
          apiResponseData.avg_reviews,
        )
        self.setState(stateData, () => {})
      }
    })
  }

  handlePageChange(pageNumber) {
    this.setState(
      {
        activePage: pageNumber,
      },
      () => {
        this.reviewListRender()
      },
    )
  }

  profileHeader_review_tab_click_Handle(data) {
    // console.log('data : ', data);
    if (data) {
      this.setState(
        {
          reviewsReceivedOrGiven: data,
        },
        () => {
          this.reviewListRender()
        },
      )
    }
  }

  sortByChangeHandler = (event) => {
    // console.log('sortByChangeHandler: ', event.target.value);
    this.setState(
      {
        sortBy: event.target.value,
      },
      () => {
        this.reviewListRender()
      },
    )
  }

  reviewsReceivedOrGivenTypeHandler = (data) => {
    // console.log('reviewsReceivedOrGivenTypeHandler: ', data);
    this.setState(
      {
        reviewsReceivedOrGiven: data,
      },
      () => {
        this.reviewListRender()
      },
    )
  }

  reviewTypeSubTabHandler = (data) => (event) => {
    // console.log('reviewTypeSubTab: ', data);
    this.setState(
      {
        reviewTypeSubTab: data,
      },
      () => {
        this.reviewListRender()
      },
    )
  }
  /*910 - update the search value on change, so the right value is use when other tabs are clicked*/
  reviewSearchFormChange = (event) => {
    event.preventDefault()
    this.setState({ searchString: event.target.value })
  }
  reviewSearchFormSubmit = (event) => {
    //  console.log('reviewSearchFormSubmit: ', event.target.elements.reviewSearchTxt.value);
    event.preventDefault()
    this.setState(
      {
        searchString: event.target.elements.reviewSearchTxt.value,
      },
      () => {
        this.reviewListRender()
      },
    )
  }

  render() {
    const { activePage, totalPages, totalRecords, reviewList } = this.state
    let showingFrom = this.props.itemsCountPerPage * (this.state.activePage - 1) + 1
    let showingTo = this.props.itemsCountPerPage * this.state.activePage
    if (showingFrom > totalRecords) {
      showingFrom = totalRecords
    }
    if (showingTo > this.state.totalRecords) {
      showingTo = this.state.totalRecords
    }

    if (totalPages < this.state.activePage && totalPages !== 0) {
      this.setState(
        {
          activePage: totalPages,
        },
        () => {
          this.reviewListRender()
        },
      )
    }

    const loadingReviews =
      (this.state.reviewsReceivedOrGiven === 'given' &&
        this?.props?.user?.total_given_review > 0) ||
      (this.state.reviewsReceivedOrGiven === 'received' && this?.props?.user?.total_review > 0)

    let menus = []
    if (this.props.user && this.props.user.user_type !== 'individual') {
      menus = [
        ...menus,
        {
          type: 'received',
          label: `Reviews Received (${this.state.without_type_reviews.total_received_reviews})`,
        },
      ]
    }
    menus = [
      ...menus,
      {
        type: 'given',
        label: `Reviews Given (${this.state.without_type_reviews.total_given_reviews})`,
      },
    ]

    return (
      <div
        id='review'
        className={'tab-pane ' + (this.props.activeTab === '#review' ? 'active' : 'fade')}
      >
        <div className='container'>
          <div className='review_title_blk'>
            <h4 className='varela'>Reviews ({this.state.without_type_reviews.total_reviews})</h4>
            <div className='right-titles'>
              <MenuTypeTabs
                label='Review Type:'
                menus={menus}
                menuType={this.state.reviewsReceivedOrGiven}
                handleChange={this.reviewsReceivedOrGivenTypeHandler}
              />
            </div>
          </div>
          <div className='bg-white mb-4'>
            <div className='friend_list_block blue_line'>
              <div className='f-left review_sec'>
                <div>
                  <span className='varela'>Sort by:</span>
                  <select
                    className='selectbox'
                    value={this.state.sortBy}
                    onChange={this.sortByChangeHandler}
                  >
                    <option value='ratings'>Ratings</option>
                    <option value='date'>Date</option>
                    <option value='name'>Name</option>
                  </select>
                </div>
                {/* <div>
                                    <ul className="d-flex">
                                        <li className={"text-center " + (this.state.reviewTypeSubTab == 'friends' ? 'active' : '')}>
                                            <a href={null} onClick={this.reviewTypeSubTabHandler('friends')} >50 Friends</a>
                                            <div className="d-flex stars_lbl">
                                                <img src={process.env.PUBLIC_URL + "/img/star_yellow.png"} alt=" " />
                                                <img src={process.env.PUBLIC_URL + "/img/star_yellow.png"} alt=" " />
                                                <img src={process.env.PUBLIC_URL + "/img/star_yellow.png"} alt=" " />
                                                <img src={process.env.PUBLIC_URL + "/img/star_yellow.png"} alt=" " />
                                                <img src={process.env.PUBLIC_URL + "/img/star_yellow.png"} alt=" " />
                                            </div>
                                        </li>
                                        <li className={"text-center " + (this.state.reviewTypeSubTab == 'friendsoffriends' ? 'active' : '')}>
                                            <a href={null} onClick={this.reviewTypeSubTabHandler('friendsoffriends')}>50 Friends of Friends</a>
                                            <div className="d-flex stars_lbl">
                                                <img src={process.env.PUBLIC_URL + "/img/star_yellow.png"} alt=" " />
                                                <img src={process.env.PUBLIC_URL + "/img/star_yellow.png"} alt=" " />
                                                <img src={process.env.PUBLIC_URL + "/img/star_yellow.png"} alt=" " />
                                                <img src={process.env.PUBLIC_URL + "/img/star_yellow.png"} alt=" " />
                                                <img src={process.env.PUBLIC_URL + "/img/star_yellow.png"} alt=" " />
                                            </div>
                                        </li>
                                        <li className={"text-center " + (this.state.reviewTypeSubTab == '' ? 'active' : '')}>
                                            <a href={null} onClick={this.reviewTypeSubTabHandler('')} >100 Total</a>
                                            <div className="d-flex stars_lbl">
                                                <img src={process.env.PUBLIC_URL + "/img/star_yellow.png"} alt=" " />
                                                <img src={process.env.PUBLIC_URL + "/img/star_yellow.png"} alt=" " />
                                                <img src={process.env.PUBLIC_URL + "/img/star_yellow.png"} alt=" " />
                                                <img src={process.env.PUBLIC_URL + "/img/star_yellow.png"} alt=" " />
                                                <img src={process.env.PUBLIC_URL + "/img/star_yellow.png"} alt=" " />
                                            </div>
                                        </li>
                                    </ul>
                                </div> */}
              </div>
              <div className='f-right'>
                <form onSubmit={this.reviewSearchFormSubmit}>
                  <input
                    type='text'
                    name='reviewSearchTxt'
                    className='input1 form-control'
                    placeholder='Search for a person, business, or organization...'
                    /*910 - update the search value on change, so the right value is use when other tabs are clicked*/
                    onChange={this.reviewSearchFormChange}
                  />
                  <input type='submit' className='search' value='' />
                </form>
              </div>
            </div>
            <div className='i-showing'>
              Showing {showingFrom} - {showingTo} of {this.state.totalRecords}
            </div>
            <div className='review_main_section'>
              {totalRecords > 0 || loadingReviews ? (
                <Fragment>
                  {(reviewList?.length
                    ? reviewList
                    : loadingReviews
                    ? [
                        ...Array(
                          this.state.reviewsReceivedOrGiven === 'received'
                            ? this?.props?.user?.total_review
                            : this?.props?.user?.total_given_review,
                        ),
                      ]
                    : []
                  ).map((review, index) => (
                    <ReviewListDetail
                      key={review?.id ?? index}
                      index={index}
                      review={review}
                      deleteReview={this.props?.deleteReview}
                    />
                  ))}
                  <div>
                    <Pagination
                      activePage={activePage}
                      itemsCountPerPage={this.props.itemsCountPerPage}
                      totalItemsCount={totalRecords}
                      pageRangeDisplayed={this.props.pageRangeDisplayed}
                      onChange={this.handlePageChange}
                    />
                  </div>
                </Fragment>
              ) : (
                <div className='search-section'>
                  <div className='no_search_result'>
                    <img
                      src={process.env.PUBLIC_URL + '/img/no_reviews.png'}
                      className='pt-5 pb-5 m-auto d-block'
                      alt=''
                    />
                  </div>
                </div>
              )}

              {/* -------------------------------------------share_modal modal start ------------------------------------------- */}
              <div className='modal fade review_modal share_modal' id='share_modal' role='dialog'>
                <div className='modal-dialog'>
                  <div className='modal-content'>
                    <div className='modal-header'>
                      <h5>Share Mangrove Realty’s Page</h5>
                      <button type='button' className='btn-close' data-bs-dismiss='modal'></button>
                    </div>
                    <div className='modal-body'>
                      <div className='social_icon_btns'>
                        <a href={null}>
                          <img src={process.env.PUBLIC_URL + '/img/facebook-btn.png'} alt=' ' />
                        </a>
                        <a href={null}>
                          <img src={process.env.PUBLIC_URL + '/img/twitter-btn.png'} alt=' ' />
                        </a>
                      </div>
                      <div className='share_modal_form'>
                        <form className=''>
                          <div className='form-group'>
                            <input
                              type='text'
                              placeholder='https://www.itrustit.com/mangroverealty'
                            />
                          </div>
                          <div className='or'>
                            <p> OR</p>
                          </div>

                          <div className='form-group'>
                            <label>Your name</label>
                            <input type='text' />
                          </div>
                          <div className='form-group'>
                            <label>Your email</label>
                            <input type='text' />
                          </div>
                          <div className='form-group'>
                            <label>
                              To{' '}
                              <span className='inner_grey'>
                                Email addresses (separate with a comma)
                              </span>
                            </label>
                            <input type='text' />
                          </div>
                          <div className='form-group'>
                            <input type='submit' value='share' className='share_submit_btn' />
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* -------------------------------------------share_modal modal over------------------------------------------- */}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

ReviewTab.defaultProps = {
  itemsCountPerPage: LIST_VIEW_ITEMS_PER_PAGE,
  pageRangeDisplayed: LIST_VIEW_PAGE_RANGE,
}

export default ReviewTab
