import { motion } from 'framer-motion'
import React from 'react'
import parse from 'html-react-parser'
import { PROFILE_PICTURE_SMALL_URL_PATH } from '../../../services/environment'
import { DEFAULT_PROFILE_PICTURE } from '../../../services/const'

const SearchSuggestions = ({
  animate,
  sugVariants,
  suggestions,
  catVariants,
  handleClickOnSuggestion,
  useGlobalSearch,
}) => {
  const renderAddress = (data) => {
    const {
      address_line_1,
      address_line_2,
      // loc,
      city,
      state,
      country,
      zipcode,
    } = data
    const address = [address_line_1 ?? null, address_line_2 ?? null].filter(Boolean)?.join(' ')
    const location = [city ?? null, !!state ? state + ', ' : null, country ?? null, zipcode ?? null]
      .filter(Boolean)
      ?.join(' ')
    return data.user_type !== 'individual' && (address?.length > 0 || location?.length > 0)
      ? `${!!address?.length ? address + ' ' : ''}${location}`
      : 'Tap to view profile'
  }

  return (
    <div
      style={{
        width: '100%',
        paddingTop: !useGlobalSearch ? '2.5rem' : 0,
        height: 444,
      }}
    >
      <motion.div
        className='row m-0 justify-content-center flex-column'
        animate={animate}
        initial='closed'
        variants={sugVariants}
      >
        {suggestions.length
          ? suggestions.map((suggestion) => {
              const isCat = suggestion?.highlight
              const isUser = suggestion._index === 'live-users-new'
              const { _source: sugData } = isCat || isUser ? suggestion : {}
              return (
                <motion.div
                  className='col position-relative'
                  key={!!isCat || isUser ? suggestion._id : suggestion}
                  variants={catVariants}
                  onMouseDown={() =>
                    handleClickOnSuggestion(
                      isUser ? sugData : isCat ? parse(sugData?.name) : suggestion,
                    )
                  }
                >
                  <div
                    className={`d-flex flex-column justify-content-center mobile-search-suggestion`}
                  >
                    <div className={`d-flex ms-3  ${isUser ? 'user-suggestion' : ''}`}>
                      {isUser ? (
                        <div
                          className='user-suggestion-avatar'
                          onMouseDown={() =>
                            handleClickOnSuggestion(
                              isUser ? sugData : isCat ? parse(sugData?.name) : suggestion,
                            )
                          }
                        >
                          {!!sugData?.image ? (
                            <img
                              onError={(e) => (e.target.src = DEFAULT_PROFILE_PICTURE)}
                              className='imageTag'
                              alt=''
                              src={PROFILE_PICTURE_SMALL_URL_PATH + sugData?.image}
                            />
                          ) : (
                            <img className='imageTag' alt='' src={DEFAULT_PROFILE_PICTURE} />
                          )}
                        </div>
                      ) : (
                        <i
                          className='fa fa-search'
                          aria-hidden='true'
                          style={{
                            color: '#4741d4',
                            fontSize: 16,
                          }}
                        ></i>
                      )}
                      <div className='d-flex flex-column search-suggestion-label'>
                        <span className={`${isUser ? 'user-suggestion-name' : ''}`}>
                          {isCat
                            ? parse(suggestion?.highlight?.name?.[0])
                            : isUser
                            ? sugData?.name
                            : suggestion}
                        </span>
                        {!!isUser ? (
                          <span className='user-suggestion-address'>{renderAddress(sugData)}</span>
                        ) : null}
                      </div>
                    </div>
                    <hr
                      className='mx-1'
                      style={{
                        marginTop: '0.5rem',
                        marginBottom: '0.5rem',
                        border: 0,
                        height: 0,
                        borderTop: '1px solid #544cf9',
                        borderBottom: '1px solid rgba(255, 255, 255, 0.3)',
                      }}
                    />
                  </div>
                </motion.div>
              )
            })
          : null}
      </motion.div>
    </div>
  )
}

export default SearchSuggestions
