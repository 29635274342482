import axios from 'axios'
import { customLogger } from '../utils/CustomLogger'
import * as environemnt from './environment'
import { userToken, toaster } from './customFunction'

export const followCreateCall = async (request) => {
  try {
    const response = await axios.post(
      environemnt.BASE_API + 'follows/' + request.user_id + '/create',
      {},
      {
        headers: {
          Authorization: userToken(),
          Accept: 'application/x.iTRUSTit.v1+json',
        },
      },
    )
    return response.data
  } catch (error) {
    if (
      typeof error.response !== 'undefined' &&
      typeof error.response.data !== 'undefined' &&
      typeof error.response.data.message !== 'undefined' &&
      error.response.data.message
    ) {
      toaster('error', error.response.data.message)
    }
    customLogger(error)
    return error.response
  }
}

export const followChangeStatusCall = async (request) => {
  try {
    const response = await axios.post(
      environemnt.BASE_API + 'follows/' + request.follow_id + '/change-status',
      {
        status: request.status,
      },
      {
        headers: {
          Authorization: userToken(),
          Accept: 'application/x.iTRUSTit.v1+json',
        },
      },
    )
    return response.data
  } catch (error) {
    if (
      typeof error.response !== 'undefined' &&
      typeof error.response.data !== 'undefined' &&
      typeof error.response.data.message !== 'undefined' &&
      error.response.data.message
    ) {
      toaster('error', error.response.data.message)
    }
    customLogger(error)
    return error.response
  }
}
