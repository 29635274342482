export const AUTH = process.env.PUBLIC_URL + '/auth'
export const LOGIN_ROUTE = AUTH + '/login'
export const LOGOUT_ROUTE = AUTH + '/logout'
export const SIGNUP_ROUTE = AUTH + '/signup'
export const SIGNUP_NEW = AUTH + '/register'
export const SIGNUP_INDIVIDUAL_ROUTE = AUTH + '/signup-personal'
export const SIGNUP_BUSINESS_ROUTE = process.env.PUBLIC_URL + AUTH + '/signup-business'
export const SIGNUP_BUSINESS_STEP1_ROUTE = AUTH + '/signup-business-step1'
export const SIGNUP_BUSINESS_STEP2_ROUTE = AUTH + '/signup-business-step2'
export const SIGNUP_ORGANIZATION_ROUTE = AUTH + '/signup-organization'
export const SIGNUP_ORGANIZATION1_ROUTE = AUTH + '/signup-organization-step1'
export const PROFILEPERSON_ROUTE = process.env.PUBLIC_URL + '/profileperson'
export const PROFILE_BUSINESS_ROUTE = process.env.PUBLIC_URL + '/profilebusiness'
export const EDIT_PROFILE_ROUTE = process.env.PUBLIC_URL + '/edit-profile'
export const BUSINESS_REVIEW_ROUTE = process.env.PUBLIC_URL + '/business-review'
export const REVIEW_SUCCESS_ROUTE = process.env.PUBLIC_URL + '/review-success'
export const BUSINESS_ROUTE = process.env.PUBLIC_URL + '/business'
//export const MESSAGE_ROUTE = process.env.PUBLIC_URL + "/message(/:user_id)";
export const MESSAGE_ROUTE = process.env.PUBLIC_URL + '/message/:user_id?'
export const GROUP_MESSAGE_ROUTE = process.env.PUBLIC_URL + '/message/g/:group_chat_id'
export const MESSAGE_ROUTE_SINGLE = process.env.PUBLIC_URL + '/message/'
export const MESSAGE_BLANK_STATE_ROUTE = process.env.PUBLIC_URL + '/message-blankstate'
export const CLAIM_BUSINESS_ROUTE = process.env.PUBLIC_URL + '/claimbusiness'
export const QR_VCARD_ROUTE = process.env.PUBLIC_URL + '/qr/vcard/:id'
export const VCARD_ROUTE = process.env.PUBLIC_URL + '/vcard/:id'
export const FAQ_ROUTE = process.env.PUBLIC_URL + '/faq'
export const BETAREADME_ROUTE = process.env.PUBLIC_URL + '/beta-readme'
export const IPARTNER_ROUTE = process.env.PUBLIC_URL + '/ipartner'
export const ADD_ORGANIZATION_AFTER_LOGIN_ROUTE =
  process.env.PUBLIC_URL + '/add-organization-after-login'
export const ADD_BUSINESS_ROUTE = AUTH + '/signup-business-presearch'
export const ADD_ORGANIZATION_ROUTE = AUTH + '/signup-organization-presearch'
export const ABOUT_ROUTE = process.env.PUBLIC_URL + '/about'
export const JOBS_ROUTE = process.env.PUBLIC_URL + '/jobs'
export const PRIVACY_ROUTE = process.env.PUBLIC_URL + '/privacy'
export const PROMOTION_PRIVACY_ROUTE = process.env.PUBLIC_URL + '/promotion-privacy'
export const TERMS_ROUTE = process.env.PUBLIC_URL + '/terms'
export const PROMOTION_TERMS_ROUTE = process.env.PUBLIC_URL + '/promotion-terms'
export const SEARCH_ROUTE = process.env.PUBLIC_URL + '/search'
export const MYPROFILE_ROUTE = process.env.PUBLIC_URL + '/myprofile'
export const ADVERTISE_ROUTE = process.env.PUBLIC_URL + '/advertise'
export const CONTACT_ROUTE = process.env.PUBLIC_URL + '/contact'
export const COMMUNITY_ROUTE = process.env.PUBLIC_URL + '/community'
export const CHARTER_MEMBER_ROUTE = process.env.PUBLIC_URL + '/charter-member'
export const RESET_PASSWORD = AUTH + '/password/reset/:resetlink'
export const HOME_ROUTE = process.env.PUBLIC_URL + '/home'
export const ROOT_ROUTE = process.env.PUBLIC_URL + '/'
export const EDIT_BUSINESS_PROFILE_ROUTE = process.env.PUBLIC_URL + '/edit-business-profile'
export const EDIT_ORGANIZATION_PROFILE_ROUTE = process.env.PUBLIC_URL + '/edit-organization-profile'
export const PROFILE_ROUTE = process.env.PUBLIC_URL + '/profile'
export const QR_PROFILE_OTHER_USER_ROUTE = process.env.PUBLIC_URL + '/qr/:id'
export const PROFILE_OTHER_USER_ROUTE = process.env.PUBLIC_URL + '/:id'
export const CLAIM_BUSINESS_PROFILE_ROUTE = process.env.PUBLIC_URL + '/claim'
export const CLAIM_BUSINESS_FORM_PROFILE_ROUTE = process.env.PUBLIC_URL + '/claim-form'
//export const CLAIM_ACTIVATION_STATUS = process.env.PUBLIC_URL + "/business-claim/activation-status/:status/:message";
export const CLAIM_ACTIVATION_STATUS = process.env.PUBLIC_URL + '/welcomebeta'
export const SHARED_PROFILE_ROUTE = process.env.PUBLIC_URL + '/invite/:slug'
export const SHARED_CLAIM_ROUTE = process.env.PUBLIC_URL + '/invite-claim/:slug/:idToClaim'
export const REGISTER_SUCCESS_ROUTE = process.env.PUBLIC_URL + '/register-success/:slug'
export const EMAIL_CONFIRMATION_ROUTE = AUTH + '/email-confirmation/:token'
export const ADD_BUSINESS_OR_ORGANIZATION_FOOTER_ROUTE =
  AUTH + '/add-business-or-organization-footer'
export const ADD_BUSINESS_OR_ORGANIZATION_NAME_AND_ADDRESS_FOOTER_ROUTE =
  AUTH + '/add-business-or-organization-name-and-address-footer'
export const ADD_BUSINESS_OR_ORGANIZATION_SEARCH_FOOTER_ROUTE =
  AUTH + '/add-business-or-organization-search-footer'
export const ADD_BUSINESS_OR_ORGANIZATION_CATEGORY_FOOTER_ROUTE =
  AUTH + '/add-business-or-organization-category-footer'
export const ADD_MEMBER_POPUP = AUTH + '/add-member-popup'

export const PROFILE_SETTING_ROUTE = process.env.PUBLIC_URL + '/setting-profile'
export const ADD_CLAIM_ROUTE = AUTH + '/claim-presearch'
export const CLAIM_BUSINESS_ADDRESS_FORM_PROFILE_ROUTE =
  process.env.PUBLIC_URL + '/claim-business-address'
export const CLAIM_ORGANIZATION_ADDRESS_FORM_PROFILE_ROUTE =
  process.env.PUBLIC_URL + '/claim-organization-address'
export const VIDEOS_ROUTE = process.env.PUBLIC_URL + '/videos'
export const LINKEDIN_REDIRECT_ROUTE = '/linkedin-redirect'
export const UPDATE_ADDRESS = process.env.PUBLIC_URL + '/update-address'
export const MOREINFOHTML_ROUTE = process.env.PUBLIC_URL + '/moreinfo'
export const TRADEMARKHTML_ROUTE = process.env.PUBLIC_URL + '/trademark'
export const COPYRIGHTHTML_ROUTE = process.env.PUBLIC_URL + '/copyright'
export const FINDOUTMOREHTML_ROUTE = process.env.PUBLIC_URL + '/findoutmore'
export const GENERALGUIDELINESHTML_ROUTE = process.env.PUBLIC_URL + '/generalGuidelines'
export const COUNTERNOTICEINSTRUCTIONSHTML_ROUTE =
  process.env.PUBLIC_URL + '/counternoticeintruction'
export const COPYRIGHTINSTRUCTIONSHTML_ROUTE = process.env.PUBLIC_URL + '/copyrightintruction'
export const PROMOTION = process.env.PUBLIC_URL + '/promotion'
export const PROMOTION_SPONSOR = process.env.PUBLIC_URL + '/promotion/:id'
export const UNCLAIMED_MORE_INFO_ROUTE = process.env.PUBLIC_URL + '/unclaimedmoreinfo'
export const SINGLE_POST_ROUTE = process.env.PUBLIC_URL + '/:id/posts/:post_id'
export const MARKETING_CENTER_ROUTE = process.env.PUBLIC_URL + '/marketing-center'
export const MARKETING_CENTER_GUIDE_ROUTE = process.env.PUBLIC_URL + '/marketing-center-guide'
export const MARKETING_CENTER_CTA_EXAMPLES_ROUTE =
  process.env.PUBLIC_URL + '/marketing-center-cta-examples'
