import React, { Component, Fragment } from 'react'
// import { Link } from "react-router-dom";
import { getProfileOverviewReviews } from '../../../../services/userApiService'
import ReviewListDetail from '../../../CommonComponents/ReviewListDetail/ReviewListDetail'

class Reviews extends Component {
  constructor(props) {
    super(props)
    this.state = {
      total_reviews: 0,
      review_list: [],
    }
  }

  componentDidMount() {
    this.getProfileOverviewReviews()
  }

  getProfileOverviewReviews() {
    const self = this
    let getProfileOverviewReviewsReqData = {
      other_user_id: this.props.user.id,
    }
    getProfileOverviewReviews(getProfileOverviewReviewsReqData).then(function (response) {
      if (typeof response.status !== 'undefined' && response.status === 200) {
        let apiResponseData = response.data
        self.setState({
          total_reviews: apiResponseData.data.total_reviews,
          review_list: apiResponseData.data.review_list,
        })
      }
    })
  }

  showReviewTab2 = (data) => (event) => {
    this.props.showReviewTab2(data)
  }

  render() {
    //console.log('this.state: ', this.state);
    const { total_reviews, review_list } = this.state

    return (
      <div className='page_info'>
        <h3 className='varela'>
          Reviews{' '}
          <a href={null} className='blue'>
            {total_reviews}
          </a>
        </h3>
        {total_reviews > 0 ? (
          <Fragment>
            {review_list.map((review, index) => (
              <ReviewListDetail key={review.id} index={index} review={review} />
            ))}
            {/* <Link className="blue reviewlink varela" to={process.env.PUBLIC_URL + "/profile#review"}>Read all reviews</Link> */}
            <ul className='nav nav-review-tab'>
              <a
                onClick={this.showReviewTab2(
                  this.props.user.user_type === 'individual' ? 'given' : 'received',
                )}
                data-bs-toggle='tab'
                href='#review'
              >
                Read all reviews
              </a>
            </ul>
            {/* <a href={null} className="blue reviewlink varela"> Read all reviews </a> */}
          </Fragment>
        ) : (
          <div className='search-section'>
            <div className='no_search_result'>
              <img
                src={process.env.PUBLIC_URL + '/img/no_reviews.png'}
                className='pt-5 pb-5 m-auto d-block'
                alt=''
              />
            </div>
          </div>
        )}
      </div>
    )
  }
}

export default Reviews
