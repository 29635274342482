import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'
import classNames from 'classnames'
// import PropTypes from "prop-types";
// import { Redirect, Link } from "react-router-dom";

import renderInputField from '../../../Form/Input'
import * as validation from '../../../../services/formValidation'
import {
  uniqueEmailCheck,
  checkUsername,
  checkSponsorId,
} from '../../../../services/userApiService'
import ReactSelect from '../../../../services/ReactSelect'
// import { LOGIN_ROUTE } from "../../../../services/routeConst";
import ErrorMsgRenderer from '../../../ErrorMsgRenderer/ErrorMsgRenderer'
import { relationshipList, DUPLICATE_USER, DUPLICATE_EMAIL } from '../../../../services/const'
import * as customFunction from '../../../../services/customFunction'
import * as environemnt from '../../../../services/environment'

class ClaimOrganization1 extends Component {
  constructor(props) {
    super(props)
    this.state = {
      user: props.user,
      isDisplayForm2: false,
      formData: [],
      confirmemail: '',
      confirmpassword: '',
      isPasswordPopupDisplay: false,
      error: {
        email: [],
        username: [],
        sponsorId: [],
      },
      relationshipValue: '',
      validation: {
        isSpaceValidation: false,
        isMaxLength: false,
        isUppercase: false,
        isLowercase: false,
        isNumber: false,
      },
      usernameValidation: {
        isMaxLength: false,
        isBeginLatter: false,
        isCharacter: false,
        isDashed: false,
        isSpaceValidation: false,
      },
      is_username_exists: '0',
      is_email_exists: '0',
      sponsorOwnerName: '',
      sponsorErrorMsg: '',
      sponsorNotValidatedOnSubmit: false,
      sponsors_iteam_id: props?.location?.state?.imatrix_id || customFunction.getDefaultSponsor(),
      isUsernamePopupDisplay: false,
      username: '',
      Uname: '',
    }
  }
  componentDidMount() {
    window.scrollTo(0, 0)
    this.checkSponsoridOnLoad()
  }
  onSponsorChangeHandler = (event) => {
    let value = event.target.value
    this.setState(
      {
        imatrix_id: value,
        sponsors_iteam_id: value,
        sponsorOwnerName: '',
        sponsorErrorMsg: 'Sponsor id is invalid',
      },
      () => {
        this.validateSponsorid(value)
      },
    )
  }
  checkSponsoridOnLoad = () => {
    let inviteMatrixId = this.state.sponsors_iteam_id
    if (inviteMatrixId !== '' && inviteMatrixId != null) {
      this.validateSponsorid(inviteMatrixId)
    }
  }
  validateSponsorid = (value) => {
    let self = this
    if (value.length > 6) {
      let sponsorIdFound = false
      checkSponsorId({ sponser_id: value }).then(function (response) {
        if (response.data) {
          if (response.data.is_sponser_id_found === '1') {
            sponsorIdFound = true
            self.setState({
              sponsorOwnerName:
                response.data.user.user_type === 'individual'
                  ? response.data.user.fullname
                  : response.data.user.business_organization_name,
              sponsorErrorMsg: '',
            })
          }
        }
        if (!sponsorIdFound) {
          self.setState({
            sponsorOwnerName: '',
            sponsorErrorMsg: 'Sponsor id is invalid',
          })
        }
      })
    } else if (value !== '') {
      self.setState({
        sponsorOwnerName: '',
        sponsorErrorMsg: 'Sponsor id is invalid',
      })
    } else {
      self.setState({
        sponsorOwnerName: '',
        sponsorErrorMsg: '',
      })
    }
  }
  claimChangeHandler = (event) => {
    let inputName = event.target.name
    let inputValue = event.target.value
    this.setState({
      [inputName]: inputValue,
    })
    if (inputName === 'email' && this?.props?.errormsg) this.props.errormsg.email = []
    /* 210922_em-01take out the not empty condition for the validation to work when the user delete his input */
    if (inputName === 'password') {
      this.setState({
        validation: validation.passwordValidation(inputValue, this.state.validation),
      })
    } else if (inputName === 'username') {
      this.setState({
        is_username_exists: '0',
        usernameValidation: validation.userNameValidation(
          inputValue,
          this.state.usernameValidation,
        ),
        Uname: inputValue,
      })
    } else if (inputName === 'email') {
      this.setState({ is_email_exists: '0' })
    }
  }

  passwordPopup = () => {
    setTimeout(() => {
      this.setState({
        isPasswordPopupDisplay: !this.state.isPasswordPopupDisplay,
      })
    }, 20)
  }
  relationshipHandler = (event) => {
    this.setState({
      relationship: { value: event.value, label: event.label },
      relationshipValue: event.value,
    })
  }

  claimSubmitHandler = (data) => {
    setTimeout(() => {
      if (
        !this.props.submitFailed &&
        !this.props.invalid &&
        !this.state.sponsorNotValidatedOnSubmit &&
        this.state.sponsorErrorMsg.length === 0 &&
        this.state.is_username_exists === '0' &&
        this.state.is_email_exists === '0'
      ) {
        this.claimSubmitAfterHandler(data)
      }
    }, 1000) ///wait for the onBlur to finish in case user have corrected username or email
  }
  claimSubmitAfterHandler = (data) => {
    data.relationship_type = data.relationship_type.value
    if (this.state.sponsors_iteam_id.length > 0)
      data.sponsors_iteam_id = this.state.sponsors_iteam_id

    if (this.state.sponsorNotValidatedOnSubmit) return
    delete data.confirmpassword
    delete data.confirm_email
    this.props.isDisplayForm2(true)
    this.props.formData(data)
  }

  emailCheckHandler = (event) => {
    var obj = {
      user_id: this.state.user.id,
      email: event.target.value,
    }
    var self = this
    uniqueEmailCheck(obj).then(function (response) {
      if (response.status === 422) {
        self.setState({
          error: {
            ...self.state.error,
            email: [response.data.message],
          },
          is_email_exists: '1',
        })
      } else {
        //initialize mail error
        self.setState({
          error: {
            ...self.state.error,
            email: [],
          },
          is_email_exists: '0',
        })
      }
    })
  }

  checkSponsorid = (event) => {
    if (event.target.value !== '') {
      let self = this
      checkSponsorId({ sponser_id: event.target.value }).then(function (response) {
        if (response.data) {
          if (response.data.is_sponser_id_found === '1') {
            self.setState({
              sponsorOwnerName:
                response.data.user.user_type === 'individual'
                  ? response.data.user.fullname
                  : response.data.user.business_organization_name,
              sponsorErrorMsg: '',
              sponsorNotValidatedOnSubmit: false,
            })
          } else {
            // let signupCopy = { ...self.state.signup };
            // signupCopy.sponsors_iteam_id = "";
            self.setState({
              sponsorOwnerName: '',
              sponsorErrorMsg: 'Sponsor id is invalid',
              sponsorNotValidatedOnSubmit: true,
            })
            //self.props.clearFields("signupForm", false, false, "sponsors_iteam_id");
          }
        }
      })
    } else
      this.setState({
        sponsorErrorMsg: '',
        sponsorOwnerName: '',
        sponsorNotValidatedOnSubmit: false,
      })
  }

  usernamePopup = () => {
    setTimeout(() => {
      this.setState({
        isUsernamePopupDisplay: !this.state.isUsernamePopupDisplay,
      })
    }, 20)
  }

  checkUser = () => {
    let self = this
    if (validation.percentageOfValidation(this.state.usernameValidation) === 100) {
      checkUsername({ username: this.state.username }).then(function (response) {
        if (response) self.setState({ is_username_exists: response.data.is_username_exists })
      })
    }
  }

  render() {
    const { invalid, handleSubmit, submitFailed } = this.props
    const {
      sponsorOwnerName,
      sponsorErrorMsg,
      sponsorNotValidatedOnSubmit,
      is_email_exists,
      is_username_exists,
    } = this.state
    let validationPercentage = validation.percentageOfValidation(this.state.validation)

    let usernameValidationPercentage = validation.percentageOfValidation(
      this.state.usernameValidation,
    )
    return (
      <div className='container'>
        <div className='row'>
          <div className='loginblk signupblk'>
            <div className='panel panel-default'>
              <div className='panel-heading'>
                <h3 className='panel-title'>Claim Your Organization - Your Info</h3>
              </div>
              <div className='panel-body'>
                <form
                  method='post'
                  id='claimOrganizationForm1'
                  name='claimOrganizationForm1'
                  onSubmit={handleSubmit(this.claimSubmitHandler).bind(this)}
                >
                  <fieldset>
                    <div className='row fieldflex form-group'>
                      <div className='col-md-3'>
                        <label className='control'>Your iTeam ID#:</label>
                      </div>
                      <div className='col-md-9'>
                        <Field
                          type='text'
                          name='sponsors_iteam_id'
                          className='form-control'
                          component={renderInputField}
                          valueKey={
                            this.state.sponsors_iteam_id ? this.state.sponsors_iteam_id : ''
                          }
                          label='Firstname'
                          placeholder='Enter Your iTeam ID# Here'
                          autoComplete='off'
                          validate={[validation.noSpace, validation.minLength(6)]}
                          //disabled={this.state.loginSponsor ? true : false}
                          onChange={this.onSponsorChangeHandler}
                          id='sponsors_iteam_id'
                        />
                        {sponsorOwnerName.length > 0 && (
                          <span
                            className='text-success'
                            style={{ margin: '7px 0', display: 'block' }}
                          >
                            Sponsor: {sponsorOwnerName}
                          </span>
                        )}
                        {this.state.sponsors_iteam_id?.length >= 6 &&
                          sponsorErrorMsg.length > 0 && (
                            <span className='text-danger'>{sponsorErrorMsg}</span>
                          )}
                      </div>
                    </div>
                    <div className='row fieldflex form-group'>
                      <div className='col-md-3'>
                        <label className='control-required'>Your First Name:</label>
                      </div>
                      <div className='col-md-9'>
                        <Field
                          name='firstname'
                          type='text'
                          className='form-control'
                          component={renderInputField}
                          validate={[validation.required]}
                          placeholder='First Name'
                          autoComplete='off'
                          onChange={this.claimChangeHandler}
                        />
                      </div>
                    </div>
                    <div className='row fieldflex form-group'>
                      <div className='col-md-3'>
                        <label className='control-required'>Your Last Name:</label>
                      </div>
                      <div className='col-md-9'>
                        <Field
                          name='lastname'
                          type='text'
                          className='form-control'
                          component={renderInputField}
                          validate={[validation.required]}
                          placeholder='Last Name'
                          autoComplete='off'
                          onChange={this.claimChangeHandler}
                        />
                      </div>
                    </div>
                    <div className='row fieldflex form-group'>
                      <div className='col-md-3'>
                        <label className='control-required'>Relationship to Organization:</label>
                      </div>
                      <div className='col-md-9'>
                        <div className='for-select'>
                          <Field
                            type='text'
                            className='form-control'
                            component={ReactSelect}
                            name='relationship_type'
                            options={relationshipList}
                            validate={[validation.required]}
                            isSelect='false'
                            onChange={this.relationshipHandler}
                            value={this.state.relationshipValue}
                          />
                        </div>
                      </div>
                    </div>
                    <div className='row fieldflex form-group'>
                      <div className='col-md-3'>
                        <label className='control-required'>Organization Username:</label>
                      </div>
                      <div className='col-md-9 password_criteria'>
                        <Field
                          name='username'
                          type='text'
                          className='form-control password1'
                          component={renderInputField}
                          onFocus={this.usernamePopup}
                          onBlur={(event) => {
                            this.usernamePopup()
                            this.checkUser()
                          }}
                          validate={[
                            validation.required,
                            validation.minValue1,
                            validation.noUsernameSpace,
                            validation.onylCharacter,
                            validation.maxLength20,
                            validation.isBeginLatter,
                            validation.isDashed,
                            validation.isDashedStart,
                          ]}
                          label='Username'
                          placeholder='Enter your username'
                          autoComplete='new-password'
                          onChange={this.claimChangeHandler}
                        />
                        {this.state.is_username_exists === '1' && (
                          <span className='input_error text-danger'>{DUPLICATE_USER}</span>
                        )}
                        {this.state.isUsernamePopupDisplay && (
                          <div className='criteria'>
                            <div className='progress'>
                              <div
                                className='progress-bar'
                                role='progressbar'
                                aria-valuenow='0'
                                aria-valuemin='0'
                                aria-valuemax='100'
                                style={{
                                  width: usernameValidationPercentage + '%',
                                }}
                              >
                                <span className='sr-only'>70% Complete</span>
                              </div>
                            </div>
                            <h3> Criteria: </h3>
                            <ul>
                              <li
                                className={
                                  this.state.usernameValidation.isSpaceValidation ? 'success' : ''
                                }
                              >
                                No Spaces, cannot start or end with a space
                              </li>
                              <li
                                className={
                                  this.state.usernameValidation.isMaxLength ? 'success' : ''
                                }
                              >
                                Username MUST be between 1 and 20 characters
                              </li>
                              <li
                                className={
                                  this.state.usernameValidation.isBeginLatter ? 'success' : ''
                                }
                              >
                                Username MUST begin with a LETTER
                              </li>
                              <li
                                className={
                                  this.state.usernameValidation.isCharacter ? 'success' : ''
                                }
                              >
                                Only Characters A-Z a-z and 0-9 are allowed
                              </li>
                              <li
                                className={this.state.usernameValidation.isDashed ? 'success' : ''}
                              >
                                Dash is allowed BUT MUST be surrounded by letters
                              </li>
                            </ul>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className='row fieldflex form-group'>
                      <div className='col-md-5'>
                        <label>Organization iTRUSTit Web Site: </label>
                      </div>
                      <div className='col-md-7 text-start it-user-url'>
                        <label>{environemnt.SITE_ADDRESS + this.state.Uname}</label>
                      </div>
                    </div>

                    <div className='row fieldflex form-group'>
                      <div className='col-md-3'>
                        <label className='control-required'>Email Address:</label>
                      </div>
                      <div className='col-md-9'>
                        <Field
                          name='email'
                          type='text'
                          className='form-control'
                          component={renderInputField}
                          validate={[validation.required, validation.email]}
                          placeholder='Enter Email'
                          autoComplete='off'
                          onChange={this.claimChangeHandler}
                          onBlur={this.emailCheckHandler}
                        />
                      </div>
                      {this.state.is_email_exists === '1' && (
                        <span className='input_error text-danger'>{DUPLICATE_EMAIL}</span>
                      )}{' '}
                    </div>
                    <div className='row fieldflex form-group'>
                      <div className='col-md-3'>
                        <label className='control-required'>Confirm Email Address:</label>
                      </div>
                      <div className='col-md-9'>
                        <Field
                          name='confirm_email'
                          type='text'
                          className='form-control'
                          component={renderInputField}
                          validate={[validation.required, validation.confirmEmail]}
                          placeholder='Confirm Email'
                          autoComplete='off'
                          onChange={this.claimChangeHandler}
                        />
                      </div>
                    </div>

                    <div className='row fieldflex form-group password_criteria'>
                      <div className='col-md-3'>
                        <label className='control-required'>Password:</label>
                      </div>
                      <div className='col-md-9'>
                        <Field
                          name='password'
                          type='password'
                          className='form-control'
                          component={renderInputField}
                          validate={[
                            validation.required,
                            validation.minValue8,
                            validation.alphaNumeric,
                            validation.maxLength20,
                            validation.noSpace,
                          ]}
                          label='Password'
                          placeholder='Enter password'
                          autoComplete='new-password'
                          onFocus={this.passwordPopup}
                          onBlur={this.passwordPopup}
                          onChange={this.claimChangeHandler}
                        />

                        {this.state.isPasswordPopupDisplay && (
                          <div className='criteria'>
                            <div className='progress'>
                              <div
                                className='progress-bar'
                                role='progressbar'
                                aria-valuenow='0'
                                aria-valuemin='0'
                                aria-valuemax='100'
                                style={{
                                  width: validationPercentage + '%',
                                }}
                              >
                                <span className='sr-only'>70% Complete</span>
                              </div>
                            </div>
                            <h3> Criteria: </h3>
                            <ul>
                              <li
                                className={this.state.validation.isSpaceValidation ? 'success' : ''}
                              >
                                No Spaces, cannot start or end with a space
                              </li>
                              <li className={this.state.validation.isLowercase ? 'success' : ''}>
                                Include a lower case letter
                              </li>
                              <li className={this.state.validation.isUppercase ? 'success' : ''}>
                                Include a upper case letter
                              </li>
                              <li className={this.state.validation.isNumber ? 'success' : ''}>
                                Include number
                              </li>
                              <li className={this.state.validation.isMaxLength ? 'success' : ''}>
                                Be 8-20 characters long
                              </li>
                            </ul>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className='row fieldflex form-group'>
                      <div className='col-md-3'>
                        <label className='control-required'>Re-Type Password:</label>
                      </div>
                      <div className='col-md-9'>
                        <Field
                          name='confirmpassword'
                          type='password'
                          className='form-control'
                          component={renderInputField}
                          validate={[validation.required, validation.confirmPassword]}
                          label='Password'
                          placeholder='Re-enter your password'
                          autoComplete='off'
                        />
                      </div>
                    </div>
                    <div className='row fieldflex form-group'>
                      <div className='col-md-3'>
                        <label>Gender</label>
                      </div>
                      <div className='col-md-9 gender'>
                        <Field type='radio' component='input' name='gender' value='male' /> Male
                        &nbsp;
                        <Field type='radio' component='input' name='gender' value='female' /> Female
                        &nbsp;
                        <Field type='radio' component='input' name='gender' value='other' /> Other
                      </div>
                    </div>
                    {console.log(
                      submitFailed,
                      is_username_exists,
                      invalid,
                      sponsorErrorMsg,
                      sponsorNotValidatedOnSubmit,
                      is_email_exists,
                    )}
                    {/*210824-1*/}
                    {validation.isFormValid(
                      submitFailed,
                      is_username_exists,
                      invalid,
                      ['text-danger'],
                      sponsorErrorMsg,
                      sponsorNotValidatedOnSubmit,
                      is_email_exists,
                    )}
                    <input
                      type='submit'
                      value='Continue'
                      className={classNames(
                        'btn',
                        'btn-lg',
                        'btn-success',
                        'btn-block',
                        'loginbtn',
                      )}
                    />
                  </fieldset>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

ClaimOrganization1 = reduxForm({
  form: 'claimOrganizationForm1',
})(ClaimOrganization1)
export default ClaimOrganization1
