import React, { Component, Fragment } from 'react'
// import { PROFILE_TRUST_DIRECTORY_TITLE } from "../../../services/titleConst";
import * as trustApiService from '../../../services/trustApiService'
import * as profileSearchService from '../../../services/profileSearchService'
import { LIST_VIEW_ITEMS_PER_PAGE, LIST_VIEW_PAGE_RANGE, SEARCH_AMT } from '../../../services/const'
import { getUser } from '../../../services/customFunction'
import UserListDetail from '../../CommonComponents/UserListDetail/UserListDetail'
import TrustedUserList from '../../CommonComponents/TrustedUserList/TrustedUserList'
import $ from 'jquery'

const parseUrlSearchParams = (search) => {
  return new URLSearchParams(search)
}

class TrustDirectoryTab extends Component {
  constructor(props) {
    super(props)
    //document.title = PROFILE_TRUST_DIRECTORY_TITLE;

    const urlSearchParams = parseUrlSearchParams(window.location.search)
    const userData = getUser()
    this.state = {
      login_user_id: userData.id,
      user: userData,
      viewType: 'full_view', // full_view | list_view
      activePage: 1,
      totalPages: 0,
      trustList: [],
      trustIDs: [],
      searchString: '',

      // total_organizations: 0,
      // total_businesses: 0,
      overall: {
        total_records: 0,
        total_organizations: 0,
        total_businesses: 0,
        total_friend_trusted: 0,
        total_friend_of_friends_trusted: 0,
        total_individuals: 0,
      },
      search_total: {
        total_records: 0,
        total_organizations: 0,
        total_businesses: 0,
        total_friend_trusted: 0,
        total_friend_of_friends_trusted: 0,
        total_individuals: 0,
      },
      trusted_type_count: {
        total: {
          trust_me: 0,
          i_trust: 0,
        },
        total_with_search: {
          trust_me: 0,
          i_trust: 0,
        },
      },

      userType: urlSearchParams.get('userType') ?? '', //individual, organization, business for filter not interface
      trusted_type:
        urlSearchParams.get('trusted_type') ??
        ['business', 'organization'].includes(userData?.user_type)
          ? 'trusted_me'
          : 'i_trust', // trusted_me | i_trust
      trusted_me_type: urlSearchParams.get('trusted_me_type') ?? '', // business | organization | (individual) | all
      iTrust_type: urlSearchParams.get('userType') ?? '', // business | organization
      isTrustedUserModalShow: false,
      trusteUserData: {
        type: '',
        user_type: '',
        id: 0,
        total_common_members: 0,
      },
      totalIDs: [], //all the IDS queried
      totalFilterIDs: [], //includes IDs from search as well type
      trustData: [], //response for the trustList call
      isSearching: true,
      hasMoreData: false,
      noSearchResults: false,
      endOfPage: SEARCH_AMT,
      updateFunc: null,
      loadingFunc: null,
    }
    this.handlePageChange = this.handlePageChange.bind(this)
    this.itrustitHandle = this.itrustitHandle.bind(this)
    this.friendAddRemoveHandle = this.friendAddRemoveHandle.bind(this)
  }

  componentDidMount() {
    this.trustListRender()
  }

  componentWillReceiveProps(nextProps) {}

  async trustListRender() {
    // const self = this;
    this.setState(
      {
        trustList: [], //all the IDS queried
        totalFilterIDs: [], //includes IDs from search as well type
        trustData: [], //response for the trustList call
      },
      () => this.determineUserType(),
    )
    let searchString = this.state.searchString
    let trustListReqData = {
      other_user_id: this.state.user_id,

      //view_type: this.state.viewType,
      page: this.state.activePage ? this.state.activePage : 1,

      user_type: '',
      trusted_type: this.state.trusted_type,
      searchString: '', //this.state.searchString,
    }

    const self = this
    const response = await trustApiService.trustListCall(trustListReqData)
    let trustData = response.data
    // console.log('trustData:', trustData)
    let totalIDs = trustData ? trustData.ids_type || [] : []
    let totalFilterIDs = totalIDs
    // console.log('trustList:', totalFilterIDs)
    if (this.state.trusted_type.includes('trusted_me')) {
      //friends
      if (this.state.trusted_me_type === 'friends')
        totalFilterIDs = trustData ? trustData.ids_friends || [] : []
      //friends of friends
      if (this.state.trusted_me_type === 'friends_of_friends')
        totalFilterIDs = trustData ? trustData.ids_friends_of_friends || [] : []
      //filter based on type
      // console.log('totalFilterIDs:', totalFilterIDs)
    }
    //filter based on type
    if (self.state.userType !== '')
      //filter usertype
      totalFilterIDs = totalFilterIDs.filter((user) => user.user_type === self.state.userType)
    // console.log('totalFilterIDs:', totalFilterIDs)
    //do elastic search after type filter
    if (searchString !== '') {
      const idsToSearch = totalFilterIDs.map((user) => user.id)
      const res = await profileSearchService.elasticSearch({
        ids: idsToSearch,
        searchStr: searchString,
      })
      if (res.length) {
        totalFilterIDs = res.map((searchedUser) => ({
          id: searchedUser['_id'],
          user_type: searchedUser['_source'].user_type,
        }))
        //filter based on type
        // console.log('totalFilterIDs:', totalFilterIDs)
      } else {
        self.setState({ isSearching: false, resetSearch: false, noSearchResults: true })
        return
      }
    }
    const endOfPage = totalFilterIDs < SEARCH_AMT ? totalFilterIDs : SEARCH_AMT
    let stateData = {
      trustData,
      totalFilterIDs,
      endOfPage,
      totalRecords: totalFilterIDs.length,
      totalIDs,
      trusted_type_count: trustData.trusted_type_count,
      overall: trustData.overall,
      search_total: trustData.overall,
      hasMoreData: totalFilterIDs.length > 0,
      noSearchResults: totalFilterIDs.length <= 0,
      resetSearch: false,
    }

    if (self.state.activePage <= 0) {
      stateData['activePage'] = 1
    }

    //self.props.profileHeader_itrustit_Handle(trustList.overall.total_records);
    let total_trust_me =
      trustData.trusted_type_count.total.trust_me + trustData.trusted_type_count.total.i_trust
    self.props.profileHeader_itrustit_Handle(total_trust_me)
    // console.log('enofpage 1st:', endOfPage)
    const currentIDs = totalFilterIDs.slice(0, endOfPage).map((user) => user.id)
    // console.log('enofpage 1st currentIDs:', currentIDs)
    self.setState(stateData, () => {
      if (currentIDs.length) {
        self.searchForTrusts(currentIDs)
      } else {
        self.setState({ isSearching: false })
      }
    })
  }

  searchForTrusts = (currentIDs) => {
    //console.log(currentIDs);
    const self = this
    if (this.state.resetSearch) return

    const searchID = this.state.searchID
    trustApiService
      .getTrustListFromIDs({
        ids: currentIDs,
      })
      .then(function (response) {
        if (self.state.resetSearch || searchID !== self.state.searchID) return
        self.setState(
          (st) => ({
            trustList: [...st.trustList, ...(response?.data?.trusts ?? [])],
            hasMoreData: st.endOfPage < st.totalFilterIDs.length,
            isSearching: false,
            loadingMoreData: false,
          }),
          () =>
            self.state.hasMoreData &&
            !self.state.resetSearch &&
            searchID === self.state.searchID &&
            self.searchAgain(),
        )
      })
  }
  determineUserType() {
    let userType = ''
    let tempUserType = ''
    let trusted_type = this.state.trusted_type
    if (!trusted_type || trusted_type === '') {
      trusted_type = 'i_trust'
    }
    if (trusted_type?.includes('i_trust')) tempUserType = this.state.iTrust_type
    else if (this.state.trusted_type?.includes('trusted_me'))
      tempUserType = this.state.trusted_me_type

    if (!!tempUserType && tempUserType !== '') {
      if (tempUserType.includes('individual')) userType = 'individual'
      if (tempUserType.includes('business')) userType = 'business'
      if (tempUserType.includes('organization')) userType = 'organization'
    }
    this.setState({ userType: userType, trusted_type: trusted_type })
    // console.log('userType:', this.state.userType, ' trusted_type: ', trusted_type)
  }
  //called when TrustBy is clicked
  profileHeader_trustmeby_tab_click_Handle(data = '') {
    this.setState(
      {
        trusted_type: 'trusted_me',
        trusted_me_type: data,
        activePage: 1,
        isSearching: true,
        trustList: [],
      },
      () => {
        this.trustListRender()
      },
    )
  }

  onHoverTrustBy = (
    id,
    type,
    user_type,
    total_common_members,
    updateFunc = null,
    loadingFunc = null,
  ) => {
    this.setState({
      isTrustedUserModalShow: true,
      trusteUserData: {
        type: type,
        user_type: user_type,
        id: id,
        total_common_members: total_common_members,
      },
      updateFunc,
      loadingFunc,
    })
  }
  handlePageChange(pageNumber) {
    $('html, body').animate(
      {
        scrollTop: $('#trust').offset().top - 100,
      },
      1000,
    )
    this.setState(
      {
        activePage: pageNumber,
        isSearching: true,
        trustList: [],
      },
      () => {
        this.trustListRender()
      },
    )
  }

  itrustitHandle(data) {
    // console.log('itrustitHandle called')
    this.setState((st) => {
      let trustList = st.trustList
      trustList.filter((user) => user.id === data.id)[0]['is_trusted_user'] =
        data.is_trusted_user === 1 ? 0 : 1
      return {
        trustList,
      }
    })
  }
  /*910 - update the search value on change, so the right value is use when other tabs are clicked*/
  handleTrustDirectorySearchChange = (event) => {
    event.preventDefault()
    this.setState({ searchString: event.target.value })
  }

  handleTrustDirectorySearchSubmit = (event) => {
    event.preventDefault()
    // console.log(event.target.elements.trustDirectorySearchTxt.value) // from elements property
    // console.log(event.target.trustDirectorySearchTxt.value)          // or directly
    this.setState(
      {
        searchString: event.target.elements.trustDirectorySearchTxt.value,
        isSearching: true,
        resetSearch: true,
        trustList: [],
        searchID: `${event.target.elements.trustDirectorySearchTxt.value ?? ''}_${
          this.state.totalFilterIDs?.length ?? 0
        }`,
      },
      () => {
        this.trustListRender()
      },
    )
  }
  //lunched when people, business, organization clicked Itrustit
  iTrustitTypeChangeHandler = (data) => (event) => {
    this.profileHeader_itrustit_tab_click_Handle(data)
  }

  viewTypeChangeHandler = (viewType) => (event) => {
    this.setState(
      {
        viewType: viewType,
      },
      () => {
        //this.forceUpdate();
        //this.trustListRender();
      },
    )
  }
  //lunch when click the Trust List with Itrust default
  profileHeader_itrustit_tab_click_Handle = (data) => {
    this.setState(
      {
        trusted_type: 'i_trust', // trusted_me | i_trust
        iTrust_type: data, // all/ business | organization
        isSearching: true,
        trustList: [],
      },
      () => {
        this.trustListRender()
      },
    )
  }

  friendAddRemoveHandle(data) {
    this.props.profileHeader_friend_recall_Handle()
    //let trustList = this.state.trustList;
    //trustList.filter((user) => user.id === data.id)[0]['is_friend_user'] = data.is_friend_user === 1 ? 0 : 1;
    this.setState(
      (st) => {
        let trustList = st.trustList
        trustList.filter((user) => user.id === data.id)[0]['is_friend_user'] =
          data.is_friend_user === 1 ? 0 : 1
        trustList.filter((user) => user.id === data.id)[0]['friendship_id'] =
          data.is_friend_user === 1 ? null : data.friendship_id
        return {
          trustList,
        }
      },
      () => {
        //console.log(this.state.friendList);
        //this.friendListRender();
      },
    )
  }

  setStatusOfMemberUserYour = (id, status) => {
    this.setState(
      (st) => {
        let trustList = st.trustList
        trustList.filter((user) => user.id === id)[0]['is_your_member_user'] =
          status === 'pending' ? 2 : status === 'accepted' ? 1 : 0
        return {
          trustList,
        }
      },
      () => {},
    )
  }

  memberAddRemoveHandle = (data) => {
    let trustList = this.state.trustList
    trustList.filter((user) => user.id === data.id)[0]['is_member_user'] =
      data.is_member_user === 1 ? 0 : 1

    this.setState({
      trustList: trustList,
    })
  }
  //launched when iTRUST/trustedby tab is clicked
  trustedTypeChangeHandler = (data) => (event) => {
    // console.log('trustedTypeChangeHandler trusted_type:', data)
    this.setState(
      {
        trusted_type: data,
        isSearching: true,
        trustList: [],
      },
      () => {
        this.trustListRender()
      },
    )
  }
  //lunched when people, business, organization clicked under Trusted By
  trustedByTypeChangeHandler = (data) => (event) => {
    this.profileHeader_trustmeby_tab_click_Handle(data)
  }

  infiniteScroll() {
    if (this.state.hasMoreData) {
      const winScroll = document.body.scrollTop || document.documentElement.scrollTop

      const height = document.documentElement.scrollHeight - document.documentElement.clientHeight

      const scrolled = winScroll / height
      // console.log(scrolled);
      if (scrolled >= 0.7) {
        this.searchAgain()
      }
    }
  }

  searchAgain() {
    this.setState(
      (st) => ({
        hasMoreData: false,
        endOfPage: st.endOfPage + SEARCH_AMT,
        loadingMoreData: true,
      }),
      () => {
        // console.log('this.state.endOfPage: ', this.state.endOfPage)
        const currentIDs = this.state.totalFilterIDs
          .slice(this.state.endOfPage - SEARCH_AMT, this.state.endOfPage)
          .map((user) => user.id)

        // console.log('this.state.endOfPage currentIDs: ', currentIDs)
        this.searchForTrusts(currentIDs)
      },
    )
  }

  render() {
    // console.log('inside render')
    const {
      totalRecords,
      trustList,
      viewType,
      user,
      userType,
      trusted_type,
      trusted_type_count,
      noSearchResults = false,
      isSearching,
    } = this.state

    let listToShow =
      userType === '' ? trustList : trustList.filter((user) => user.user_type === userType)
    // console.log('listToShow:', listToShow.length)
    listToShow = listToShow.filter(
      (thing, index, self) => self.findIndex((t) => t.id === thing.id) === index,
    )
    let showingFrom = this.props.itemsCountPerPage * (this.state.activePage - 1) + 1
    let showingTo = listToShow.length || showingFrom
    if (showingFrom > listToShow.length) {
      showingFrom = listToShow.length
    }

    // console.log('showingFrom:', showingFrom, showingTo)
    // console.log('render trusted_type:', this.state.trusted_type)
    // console.log('render userType:', this.state.userType)
    // console.log('render trusted_me_type:', this.state.trusted_me_type)
    // console.log('render iTrust_type:', this.state.iTrust_type)
    return (
      <div
        id='trust'
        className={'tab-pane ' + (this.props.activeTab === '#trust' ? 'active' : 'fade')}
      >
        <div className='trust_blk_head'>
          <h4 className='varela'>Trust List </h4>
          {/*({this.state.search_total.total_records}) */}
          <div className='trust_right'>
            {/* {console.log("trust dir user type3: " ,user.user_type)} */}
            {user.user_type !== 'individual' && (
              <ul className='trustss_link'>
                <li onClick={this.trustedTypeChangeHandler('trusted_me')}>
                  <a
                    href={null}
                    className={'' + (this.state.trusted_type === 'trusted_me' ? 'active' : '')}
                  >
                    Trusted By ({trusted_type_count.total_with_search.trust_me})
                  </a>
                </li>
                <li onClick={this.trustedTypeChangeHandler('i_trust')}>
                  <a
                    href={null}
                    className={'' + (this.state.trusted_type === 'i_trust' ? 'active' : '')}
                  >
                    iTRUST ({trusted_type_count.total_with_search.i_trust})
                  </a>
                </li>
              </ul>
            )}
            <div>View:</div>
            <ul className='views'>
              <li onClick={this.viewTypeChangeHandler('list_view')}>
                <a
                  href={null}
                  className={'' + (this.state.viewType === 'list_view' ? 'active' : '')}
                >
                  <i className='list-view' />
                  List
                </a>
              </li>
              <li onClick={this.viewTypeChangeHandler('full_view')}>
                <a
                  href={null}
                  className={'' + (this.state.viewType === 'full_view' ? 'active' : '')}
                >
                  <i className='full-view' />
                  Full
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className='bg-white'>
          <div className='friend_list_block purple'>
            <div className='f-left'>
              <ul className='inner-navs nav nav-tabs'>
                {trusted_type === 'i_trust' ? (
                  <Fragment>
                    <li className='nav-item'>
                      <a
                        className={
                          'nav-link trust-directory-tabs ' +
                          (this.state.userType === '' ? 'active' : '')
                        }
                        data-bs-toggle='tab'
                        href='#itrust_total'
                        onClick={this.iTrustitTypeChangeHandler('')}
                      >
                        <span>iTRUST</span>
                      </a>
                    </li>
                    <li className='nav-item'>
                      <a
                        className={
                          'nav-link trust-directory-tabs ' +
                          (this.state.userType === 'business' ? 'active' : '')
                        }
                        data-bs-toggle='tab'
                        href='#itrust_business'
                        onClick={this.iTrustitTypeChangeHandler('business')}
                      >
                        <span>{this.state.search_total.total_businesses} BUSINESSES</span>
                      </a>
                    </li>
                    <li className='nav-item'>
                      <a
                        className={
                          'nav-link trust-directory-tabs ' +
                          (this.state.userType === 'organization' ? 'active' : '')
                        }
                        data-bs-toggle='tab'
                        href='#itrust_organization'
                        onClick={this.iTrustitTypeChangeHandler('organization')}
                      >
                        <span>{this.state.search_total.total_organizations} ORGANIZATIONS</span>
                      </a>
                    </li>
                  </Fragment>
                ) : (
                  <Fragment>
                    {/* {console.log('this.state.trusted_me_type:', this.state.trusted_me_type)} */}
                    <li className='nav-item'>
                      <a
                        className={
                          'nav-link trust-directory-tabs ' +
                          (this.state.trusted_me_type === '' ? 'active' : '')
                        }
                        data-bs-toggle='tab'
                        href='#trusted_by_all'
                        onClick={this.trustedByTypeChangeHandler('')}
                      >
                        <span>TRUSTED BY</span>
                      </a>
                    </li>
                    <li className='nav-item'>
                      <a
                        className={
                          'nav-link trust-directory-tabs ' +
                          (this.state.userType === 'individual' ? 'active' : '')
                        }
                        data-bs-toggle='tab'
                        href='#trusted_by_friend'
                        onClick={this.trustedByTypeChangeHandler('individual')}
                      >
                        <span>{this.state.search_total.total_individuals} PEOPLE</span>
                      </a>
                    </li>
                    <li className='nav-item'>
                      <a
                        className={
                          'nav-link trust-directory-tabs ' +
                          (this.state.userType === 'business' ? 'active' : '')
                        }
                        data-bs-toggle='tab'
                        href='#trusted_by_friends_of_friends'
                        onClick={this.trustedByTypeChangeHandler('business')}
                      >
                        <span>{this.state.search_total.total_businesses} BUSINESSES</span>
                      </a>
                    </li>
                    <li className='nav-item'>
                      <a
                        className={
                          'nav-link trust-directory-tabs ' +
                          (this.state.userType === 'organization' ? 'active' : '')
                        }
                        data-bs-toggle='tab'
                        href='#organization'
                        onClick={this.trustedByTypeChangeHandler('organization')}
                      >
                        <span>{this.state.search_total.total_organizations} ORGANIZATIONS</span>
                      </a>
                    </li>
                  </Fragment>
                )}
              </ul>
            </div>
            <div className='f-right'>
              <form onSubmit={this.handleTrustDirectorySearchSubmit}>
                <input
                  type='text'
                  name='trustDirectorySearchTxt'
                  className='input1 form-control'
                  placeholder='Search for a person,  business, or organization...'
                  /*910 - update the search value on change, so the right value is use when other tabs are clicked*/
                  onChange={this.handleTrustDirectorySearchChange}
                />
                <input type='submit' className='search' value='' />
              </form>
            </div>
          </div>
          <div className='i-showing'>
            Showing {showingFrom} - {showingTo} of {this.state.totalFilterIDs.length}
          </div>
          {/* inner tab start */}
          <div className='inner-tab tab-content it-mobile-space'>
            {totalRecords > 0 && !noSearchResults && !isSearching ? (
              <Fragment>
                {viewType === 'full_view' ? (
                  <div className='d-flex blocks'>
                    <div className='col-lg-8 position-relative'>
                      {listToShow.map(
                        (user, index) =>
                          index < this.state.endOfPage && (
                            <UserListDetail
                              setModalProps={this.props.setModalProps}
                              setStatusOfMemberUserYour={this.setStatusOfMemberUserYour}
                              key={user.id}
                              index={index}
                              login_user_id={this.state.login_user_id}
                              user={user}
                              confirmitrustitAddRemove={this.props.confirmitrustitAddRemove}
                              confirmFriendAddRemove={this.props.confirmFriendAddRemove}
                              loadingFriendStatusChange={this.props.loadingFriendStatusChange}
                              loadingitrustitStatusChange={this.props.loadingitrustitStatusChange}
                              tab={'trust'}
                              viewType={this.state.viewType}
                              itrustitHandle={this.itrustitHandle}
                              friendAddRemoveHandle={this.friendAddRemoveHandle}
                              memberAddRemoveHandle={this.memberAddRemoveHandle}
                              onHoverTrustBy={this.onHoverTrustBy}
                            />
                          ),
                      )}
                      <TrustedUserList
                        isBlockShowSetFalse={(e) => {
                          this.setState({ isTrustedUserModalShow: false })
                        }}
                        isBlockShow={this.state.isTrustedUserModalShow}
                        trusteUserData={this.state.trusteUserData}
                        updateFunc={this.state.updateFunc}
                        loadingFunc={this.state.loadingFunc}
                      ></TrustedUserList>
                    </div>
                  </div>
                ) : (
                  <div className='row frnd_1'>
                    {listToShow.map(
                      (user, index) =>
                        index < this.state.endOfPage && (
                          <UserListDetail
                            setModalProps={this.props.setModalProps}
                            setStatusOfMemberUserYour={this.setStatusOfMemberUserYour}
                            key={user.id}
                            index={index}
                            login_user_id={this.state.login_user_id}
                            confirmitrustitAddRemove={this.props.confirmitrustitAddRemove}
                            confirmFriendAddRemove={this.props.confirmFriendAddRemove}
                            loadingFriendStatusChange={this.props.loadingFriendStatusChange}
                            loadingitrustitStatusChange={this.props.loadingitrustitStatusChange}
                            tab={'trust'}
                            user={user}
                            viewType={this.state.viewType}
                            itrustitHandle={this.itrustitHandle}
                            friendAddRemoveHandle={this.friendAddRemoveHandle}
                            memberAddRemoveHandle={this.memberAddRemoveHandle}
                            onHoverTrustBy={this.onHoverTrustBy}
                          />
                        ),
                    )}
                    <TrustedUserList
                      isBlockShowSetFalse={(e) => {
                        this.setState({ isTrustedUserModalShow: false })
                      }}
                      isBlockShow={this.state.isTrustedUserModalShow}
                      trusteUserData={this.state.trusteUserData}
                      updateFunc={this.state.updateFunc}
                      loadingFunc={this.state.loadingFunc}
                    ></TrustedUserList>
                  </div>
                )}
                {this.state.loadingMoreData ? (
                  <div className='no_search_result'>
                    <div className='search-page-loading-icon'>
                      {' '}
                      <i />{' '}
                    </div>
                  </div>
                ) : (
                  !this.state.hasMoreData && (
                    <div
                      onClick={() => {
                        window.scrollTo({
                          top: $('#trust').offset().top - 100,
                          behavior: 'smooth',
                        })
                      }}
                      className='no_search_result back-to-top text-muted'
                    >
                      Back to Top
                    </div>
                  )
                )}
                {/* <div>
                  <Pagination
                    activePage={activePage}
                    itemsCountPerPage={this.props.itemsCountPerPage}
                    totalItemsCount={totalRecords}
                    pageRangeDisplayed={this.props.pageRangeDisplayed}
                    onChange={this.handlePageChange}
                  />
                </div> */}
              </Fragment>
            ) : noSearchResults || !isSearching ? (
              <div className='search-section'>
                <div className='no_search_result'>
                  <img
                    src={process.env.PUBLIC_URL + '/img/trust-directory-empty.png'}
                    className='pt-5 pb-5 m-auto d-block'
                    alt=''
                  />
                </div>
              </div>
            ) : (
              <div className='search-section'>
                <div className='no_search_result'>
                  <div className='search-page-loading-icon'>
                    <i />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }
}

TrustDirectoryTab.defaultProps = {
  itemsCountPerPage: LIST_VIEW_ITEMS_PER_PAGE,
  pageRangeDisplayed: LIST_VIEW_PAGE_RANGE,
}

export default TrustDirectoryTab
