import React, { Component } from 'react'
import AppLayout from '../AppLayout'
import '../Message/message.scss'
import { ABOUT_US_TITLE } from '../../services/titleConst'
import LoginModal from '../LoginModal/LoginModal'
import { Helmet } from 'react-helmet'
import './About.scss'

export const ItiText = ({ className = '', short = false }) => (
  <span className={'fw-bold ' + className}>
    <span style={{ color: '#14954b' }}>i</span>
    <span style={{ color: '#0877bd' }}>{short ? 'T' : 'TRUST'}</span>
    <span style={{ color: '#d85a27' }}>{short ? 'i' : 'it'}</span>
  </span>
)

class About extends Component {
  componentDidMount() {
    window.scrollTo(0, 0)
  }

  loginHandler = (data) => {
    this.props.isLogin(data)
  }

  render() {
    return (
      <AppLayout routeActive={this.props.location.pathname}>
        <Helmet>
          <title>{ABOUT_US_TITLE}</title>
        </Helmet>
        <section className='bg-grey'>
          <div className='container'>
            <h3 className='varela mb-3'>About us</h3>
            <div className='white-pane green_line'>
              <div className='about mb-5'>
                <h4 className='mx-auto text-center fw-bold'>
                  About <ItiText />
                </h4>
                <div className='d-flex flex-column'>
                  <div className='mb-4'>
                    <h6 className='fw-bold'>
                      What is <ItiText />?
                    </h6>
                    <p>
                      <ItiText /> is a free, socially-connected business directory built on the
                      trust in your friends and the members of your organizations
                    </p>
                  </div>
                  <div className='mt-1 mb-5'>
                    <h6 className='fw-bold'>Technically speaking...</h6>
                    <p>
                      <ItiText /> is a Patented, Word-of-Mouth and referral marketing aggregation
                      engine connecting People, Businesses and Organizations with 1-Click-Trust and
                      friend credibility.
                    </p>
                  </div>
                  <div className='mt-3'>
                    <h6 className='fw-bold mb-4' style={{ fontSize: '1.15rem' }}>
                      Let's break it down...
                    </h6>
                  </div>
                  <div>
                    <h6 className='fw-bold mb-0'>
                      <em>For People:</em>
                    </h6>
                    <p>
                      <ItiText /> makes it fast and easy for you to:
                    </p>
                    <ul className='mb-3'>
                      <li>Find businesses all your friends trust at once.</li>
                      <li>
                        Share the businesses and organizations you trust and support with your
                        friends and family using fast 1-click-trust
                      </li>
                      <li>Locate & support the businesses of your fellow organization members</li>
                      <li>
                        Save time. If you're too busy to write a great review, we make it easy to
                        support and share more businesses in less time with our 1 Click-to-Trust
                        button.
                      </li>
                    </ul>
                    <p>
                      We provide High-quality friend-based personal referrals from your friends and
                      members of the organizations you trust.
                    </p>
                  </div>
                  <div className='mt-5'>
                    <h6 className='fw-bold mb-0'>
                      <em>For All Businesses:</em>
                    </h6>
                    <ul>
                      <li className='mt-0'>
                        Leverage your social presence and organic trust in your community for free!
                      </li>
                      <li>
                        Grow your business when your friends, family and clients simply click +Trust
                        to share you with their friends and family.
                      </li>
                      <li>
                        <ItiText /> to make it easy for your friends, family, and clients to share
                        the trust they have in you.
                      </li>
                      <li>
                        If your business depends on Personal Referrals and Word-of-Mouth Marketing{' '}
                        <ItiText /> is for you! We have simplified and automated the personal
                        referral process with just 1 click!
                      </li>
                      <li className='mb-0 pb-0'>
                        Connect your business using <ItiText />
                        's organization directories with members of your organizations locally and
                        globally.
                      </li>
                    </ul>
                  </div>
                  <div className='mt-5'>
                    <h6 className='fw-bold mb-0'>
                      <em>For Realtors and Brokers:</em>{' '}
                      <span className='fw-normal' style={{ fontSize: 14 }}>
                        (all the above <em>Business</em> benefits, plus)
                      </span>
                    </h6>
                    <ul>
                      <li className='mt-0'>
                        Grow your business as your friends and clients promote you to their friends
                        and family as the Realtor they trust.
                      </li>
                      <li>
                        Serve your clients better - Find instant, mass realtor and friend-trusted
                        referrals to vendors and businesses.
                      </li>
                      <li>
                        Benefit when <ItiText />
                        's member directories automatically connect you to all other members of your
                        organizations, both locally and globally.
                      </li>
                      <li>
                        Make it easy to share businesses and vendors you trust with your clients,
                        friends, family and colleagues with 1-Click-to-Trust.
                      </li>
                    </ul>
                  </div>
                  <div className='mt-5'>
                    <h6 className='fw-bold mb-0'>
                      <em>
                        For Organizations, Associations, Chambers, Fraternities, Clubs, Groups,
                        Churches, etc. (if you have members, fans, alumni, brothers, etc.):
                      </em>
                    </h6>
                    <ul>
                      <li className='mt-0'>
                        <ItiText /> is a free member directory connecting your members and promoting
                        their businesses both locally and globally... It's a powerful Value-Add!
                      </li>
                      <li>
                        Provides massive awareness and exposure through your members and friends of
                        your organization.
                      </li>
                      <li>
                        Benefit from <ItiText />
                        's free, non-dues revenue stream*
                      </li>
                      <li>
                        The <ItiText /> I Give program gives back to all the 501C-3 charities in the{' '}
                        <ItiText /> platform proportionally based on their members participation.
                      </li>
                    </ul>
                  </div>
                  <div className='mt-5'>
                    <h6 className='fw-bold mb-0'>
                      <em>For Bands, Sport Teams, etc. (if you have Fans, Groupies, etc.):</em>
                    </h6>
                    <ul>
                      <li className='mt-0'>
                        We are a great place for your fans to connect and easily see that they have
                        YOU in common.
                      </li>
                      <li>
                        We help connect you to the world by creating Viral Awareness and Exposure
                        for you through your friends and fans to their connections.
                      </li>
                    </ul>
                  </div>
                  <div className='mt-5'>
                    <p className='fw-bold mb-0'>
                      Join <ItiText /> today and share the trust you have in your community with
                      your friends!
                    </p>
                    <p className='fw-bold mb-0'>
                      Which businesses do your friends trust? Join <ItiText /> and find out.
                    </p>
                  </div>
                  <div className='my-5'>
                    <p>
                      *<ItiText /> gives back to <em>ALL</em> our users! Our Otp-In{' '}
                      <u>affiliate revenue sharing program</u>* rewards users who help us grow!
                    </p>
                  </div>
                  <div>
                    <p className='mx-auto text-center fw-bold mt-3'>
                      <ItiText />
                      ... your personal blockchain of trust.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <LoginModal isLogin={this.loginHandler} {...this.props} />
      </AppLayout>
    )
  }
}

export default About
