import React, { Component } from 'react'
import ReactDOM from 'react-dom'
// import { Redirect } from "react-router-dom";
import { Field, reduxForm, change, untouch } from 'redux-form'
import classNames from 'classnames'
import renderInputField from '../Form/Input'
import * as validation from '../../services/formValidation'
import * as customFunction from '../../services/customFunction'
// import * as route from "../../services/routeConst";
import ErrorMsgRenderer from '../ErrorMsgRenderer/ErrorMsgRenderer'
import { changePassword } from '../../services/authService'
import { toaster } from '../../services/customFunction'
import { NETWORK_ERROR_MSG } from '../../services/const'
import $ from 'jquery'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { userStoreAction } from '../../actions/auth'

import { editUserStore } from '../../actions/profile'
class ChangePassword extends Component {
  constructor(props) {
    super(props)
    const isUserLogin = customFunction.isUserLogin()
    this.state = {
      changePasswordFormErr: {
        email: [],
      },
      isUserLogin: isUserLogin,
      isLoading: false,
      submitBtnText: (props.method === 'set' ? 'Set' : 'Change') + ' Password',
      isPasswordPopupDisplay: false,
      validation: {
        isSpaceValidation: false,
        isMaxLength: false,
        isUppercase: false,
        isLowercase: false,
        isNumber: false,
      },
      method: props.method,
    }

    this.changePasswordEmailFormSubmitHandler = this.changePasswordEmailFormSubmitHandler.bind(this)
  }

  passwordPopup = () => {
    setTimeout(() => {
      this.setState({ isPasswordPopupDisplay: !this.state.isPasswordPopupDisplay })
    }, 200)
  }

  changePasswordEmailFormHandler = (event) => {
    let inputName = event.target.name
    let inputValue = event.target.value

    if (inputName === 'password') {
      let stateCopy = { ...this.state }

      if (/^[^\s]+(\s+[^\s]+)*$/.test(inputValue)) {
        stateCopy.validation['isSpaceValidation'] = true
        this.setState(stateCopy)
      } else {
        stateCopy.validation['isSpaceValidation'] = false
        this.setState(stateCopy)
      }

      if (inputValue.length > 7 && inputValue.length < 21) {
        stateCopy.validation['isMaxLength'] = true
        this.setState(stateCopy)
      } else {
        stateCopy.validation['isMaxLength'] = false
        this.setState(stateCopy)
      }

      if (/[A-Z]/.test(inputValue)) {
        stateCopy.validation['isUppercase'] = true
        this.setState(stateCopy)
      } else {
        stateCopy.validation['isUppercase'] = false
        this.setState(stateCopy)
      }

      if (/[a-z]/.test(inputValue)) {
        stateCopy.validation['isLowercase'] = true
        this.setState(stateCopy)
      } else {
        stateCopy.validation['isLowercase'] = false
        this.setState(stateCopy)
      }

      if (inputValue.match(/\d+/g)) {
        stateCopy.validation['isNumber'] = true
        this.setState(stateCopy)
      } else {
        stateCopy.validation['isNumber'] = false
        this.setState(stateCopy)
      }
    }
  }

  changePasswordEmailFormSubmitHandler = (data) => {
    this.setState({ isLoading: true, submitBtnText: 'Loading...' })
    let self = this
    data.token = customFunction.userToken()

    changePassword(data).then(function (response) {
      self.setState({
        isLoading: false,
        submitBtnText: (self.state.method === 'set' ? 'Set' : 'Change') + ' Pasword',
      })
      if (response === undefined) {
        toaster('error', NETWORK_ERROR_MSG)
      } else if (response.status === 422) {
        self.setState({
          changePasswordFormErr: response.data.errors,
        })
      } else if (response.status === true) {
        toaster('success', response.message)
        $('#change_password_email_modal').modal('hide')
        if (self.state.method === 'set') {
          self.props.editUserStore(response.profileInfo)
        }
      } else {
        toaster('error', response.data.message)
      }
    })
  }

  componentWillReceiveProps = (nextProps) => {
    if (this.state.method !== nextProps.method) {
      this.setState({ method: nextProps.method })
    }
  }

  render() {
    const { invalid, handleSubmit } = this.props
    let self = this
    let validationKey = Object.keys(this.state.validation)
    let validationFilter = validationKey.filter(function (key) {
      return self.state.validation[key]
    })

    let validationPercentage = validationFilter.length * 20

    $('#change_password_email_modal').on('hidden.bs.modal', function (e) {
      const fields =
        self.state.method === 'set'
          ? ['password', 'confirmpassword']
          : ['old_password', 'password', 'confirmpassword']
      for (var i = 0; i < fields.length; i++) {
        self.props.dispatch(change('change_password_email_form', fields[i], null))
        self.props.dispatch(untouch('change_password_email_form', fields[i]))
      }

      let stateCopy = { ...self.state }
      if (self.state.method !== 'set') stateCopy.changePasswordFormErr['old_password'] = []
      stateCopy.changePasswordFormErr['password'] = []
      stateCopy.changePasswordFormErr['confirmpassword'] = []
      stateCopy.validation = []
      self.setState(stateCopy)
    })

    $('#change_password_email_modal').on('shown.bs.modal', function (e) {
      if (self.state.method !== 'set')
        ReactDOM.findDOMNode(self.refs.changePasswordOldPassword).focus()
    })

    return (
      <div className='modal fade reset_password_blk' id='change_password_email_modal' role='dialog'>
        <div className='modal-dialog'>
          <div className='modal-content it-modal-change-pwd-form'>
            <div className='modal-header'>
              <h4 className='modal-title'>
                {this.state.method === 'set' ? 'Set' : 'Change'} Your Password
              </h4>
              <button type='button' className='btn-close' data-bs-dismiss='modal'></button>
            </div>
            <div className='modal-body'>
              {/* <p className="text-start">
                                Enter the old password and new password.
                            </p> */}
              <form
                id='change_password_email_form'
                name='change_password_email_form'
                className='reset_password'
                method='post'
                onSubmit={handleSubmit(this.changePasswordEmailFormSubmitHandler).bind(this)}
              >
                <div className='form-group'>
                  {this.state.method === 'set' ? null : (
                    <div className='password_criteria'>
                      <Field
                        ref='changePasswordOldPassword'
                        name='old_password'
                        type='password'
                        className='form-control reset_pwd it-form-remove-icon'
                        component={renderInputField}
                        validate={[
                          validation.required,
                          validation.minValue8,
                          validation.alphaNumeric,
                          validation.maxLength20,
                          validation.noSpace,
                        ]}
                        label='Old Password'
                        placeholder='Enter your old password'
                        autoComplete='off'
                        onChange={this.changePasswordEmailFormHandler}
                      />
                    </div>
                  )}

                  <div className='password_criteria'>
                    <Field
                      ref='changePasswordNewPassword'
                      name='password'
                      type='password'
                      className='form-control reset_pwd it-form-remove-icon'
                      component={renderInputField}
                      onFocus={this.passwordPopup}
                      onBlur={this.passwordPopup}
                      validate={[
                        validation.required,
                        validation.minValue8,
                        validation.alphaNumeric,
                        validation.maxLength20,
                        validation.noSpace,
                      ]}
                      label='New Password'
                      placeholder='Enter new password'
                      autoComplete='off'
                      onChange={this.changePasswordEmailFormHandler}
                    />
                    {this.state.isPasswordPopupDisplay && (
                      <div className='criteria'>
                        <div className='progress'>
                          <div
                            className='progress-bar'
                            role='progressbar'
                            aria-valuenow='0'
                            aria-valuemin='0'
                            aria-valuemax='100'
                            style={{ width: validationPercentage + '%' }}
                          >
                            <span className='sr-only'>70% Complete</span>
                          </div>
                        </div>
                        <h3> Criteria: </h3>
                        <ul>
                          <li className={this.state.validation.isSpaceValidation ? 'success' : ''}>
                            No Spaces, cannot start or end with a space
                          </li>
                          <li className={this.state.validation.isLowercase ? 'success' : ''}>
                            Include a lower case letter
                          </li>
                          <li className={this.state.validation.isUppercase ? 'success' : ''}>
                            Include a upper case letter
                          </li>
                          <li className={this.state.validation.isNumber ? 'success' : ''}>
                            Include number
                          </li>
                          <li className={this.state.validation.isMaxLength ? 'success' : ''}>
                            Be 8-20 characters long
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>

                  <div className='password_criteria'>
                    <Field
                      ref='changePasswordNewConfirmPassword'
                      name='confirmpassword'
                      type='password'
                      className='form-control reset_pwd it-form-remove-icon'
                      component={renderInputField}
                      validate={[validation.required, validation.confirmPassword]}
                      label='Confirm Password'
                      placeholder='Enter confirm password'
                      autoComplete='off'
                      onChange={this.changePasswordEmailFormHandler}
                    />
                  </div>

                  <ErrorMsgRenderer
                    errors={
                      typeof this.state.changePasswordFormErr.password !== 'undefined' &&
                      Array.isArray(this.state.changePasswordFormErr.password) &&
                      this.state.changePasswordFormErr.email.password > 0
                        ? this.state.changePasswordFormErr.password
                        : []
                    }
                  />
                </div>
                <input
                  type='submit'
                  value={this.state.submitBtnText}
                  className={classNames(invalid && 'disabled', 'form-control resetbtn')}
                  disabled={invalid || this.state.isLoading}
                />
              </form>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

ChangePassword = reduxForm({
  form: 'change_password_email_form',
})(ChangePassword)

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ editUserStore, userStoreAction }, dispatch)
  // return {
  //     loginAction: bindActionCreators(loginAction, dispatch),
  // };
}

export default connect(null, mapDispatchToProps)(ChangePassword)
