import React, { useEffect } from 'react'
import $ from 'jquery'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { signupType } from '../../actions/auth'
import SignupUserTypeSelect from '../Signup/SignupUserTypeSelect'

export const showSelectUserTypeModal = () => {
  $('#select_user_type_modal')?.modal?.('show')
}
export const hideSelectUserTypeModal = () => {
  $('#select_user_type_modal')?.modal?.('hide')
}

let SelectUserTypeModal = (props) => {
  useEffect(() => {
    props.signupType('individual')
  }, [])

  const { handleSubmit } = props
  return (
    <div className='modal fade select_user_type_modal' id='select_user_type_modal' role='dialog'>
      <div className='modal-dialog modal-lg'>
        <div className='modal-content'>
          <div className='modal-header align-items-start'>
            <h5>Select an account type</h5>
            <button type='button' className='btn-close' data-bs-dismiss='modal'></button>
          </div>
          <div className='modal-body d-flex justify-content-center align-items-center'>
            <SignupUserTypeSelect
              imatrix_id={props.location?.state ? props.location?.state?.imatrix_id : ''}
              handleSubmit={handleSubmit}
              style={{ margin: 0 }}
              inModal
            />
          </div>
        </div>
      </div>
    </div>
  )
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ signupType }, dispatch)
}

export default connect(null, mapDispatchToProps)(SelectUserTypeModal)
