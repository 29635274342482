import React from 'react'
import { default as QR } from 'easyqrcodejs'

const QRCode = ({ value, width = 256, height = 256, quietZone = 20 }) => {
  const code = React.useRef()
  const qrInstance = React.useRef()
  React.useEffect(() => {
    qrInstance?.current?.clear()
    qrInstance.current = new QR(code.current, {
      text: value,
      logo: process.env.PUBLIC_URL + '/img/iti_qr_logo.png',
      PI_TL: '#0877bd',
      PI_TR: '#d85a27',
      PI_BL: '#14954b',
      dotScale: 1,
      timing_V: '#0096FF',
      correctLevel: QR.CorrectLevel.H,
      quietZone,
      width,
      height,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  return <div ref={code}></div>
}

export default React.memo(QRCode)
