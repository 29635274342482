import React, { useState } from 'react'
import './ReportContent.scss'

import { connect } from 'react-redux'
import { reportCreateCall } from '../../../services/socialMediaApiService'
import { IMG_PATH } from '../../../services/const'
import { Link } from 'react-router-dom'
import * as route from '../../../services/routeConst'
import * as environemnt from '../../../services/environment'
const ReportContent = ({ request, onCloseButtonClick, ...props }) => {
  const closeReport = () => {
    onCloseButtonClick()
  }

  const [reportContent, setReportContent] = useState(props?.report_content)
  const [details, setDetails] = useState('What seems to be the issue?')
  const [moreInfo, setMoreInfo] = useState(false)
  const [activeBack, setActiveBack] = useState(false)
  const [selectedValue, setSelectedValue] = useState()
  const subContentHandler = (content) => {
    console.log('content:', content)
    setReportContent([])
    setReportContent(content.subtitle)
    setActiveBack(true)
    setDetails(content.details)
    setMoreInfo(content.moreinfo)
  }
  const backButtonHandler = () => {
    setActiveBack(false)
    setReportContent(props?.report_content)
    setSelectedValue(undefined)
    setDetails('What seems to be the issue?')
  }
  const changeSelected = (data) => {
    setSelectedValue(data)
  }
  const submitHandler = () => {
    request.report_id = reportContent[selectedValue]?.id
    request.url = environemnt.SITE_ADDRESS
    console.log('submit request:', request)
    reportCreateCall(request) //-calls API
    closeReport()
  }
  return (
    <>
      <div
        className='modal modal-fullscreen overlay-top-layer createpostmodal reportmodal '
        data-bs-backdrop='static'
        data-bs-keyboard='false'
        data-bs-overflow='unset'
        role='dialog'
        id='wall_reportdata_modal'
      >
        <div className='modal-dialog'>
          {' '}
          <div className='modal-content'>
            <div name='postForm'>
              <div className='modal-header'>
                <h5>Report</h5>
                <button type='button' className='btn-close' onClick={closeReport}></button>
              </div>
              <div className='modal-body overflow-auto'>
                <div>
                  <div className='modalReportTitle'> {details}</div>
                  <ul className='modalReports'>
                    <div>
                      <>
                        <ul className='modalContents'>
                          {reportContent?.map((content, index) => {
                            return content?.subtitle ? (
                              <li className='modalContent'>
                                <button
                                  className='buttonReportContent'
                                  onClick={() => subContentHandler(content)}
                                >
                                  <div className=''>{content.text}</div>
                                  <div className=''>{'-->'}</div>
                                </button>
                              </li>
                            ) : !!moreInfo ? (
                              <div class='radio radio-danger body_button '>
                                <div className='subtitleheader'>
                                  <div>
                                    <input
                                      type='radio'
                                      name='selection'
                                      id='Radios1'
                                      value={selectedValue}
                                      checked={selectedValue == index} //intentionally done for "1" or 1
                                      onClick={() => changeSelected(index)}
                                    ></input>
                                    <label>{content.text}</label>
                                  </div>
                                </div>

                                <div className='subsubdetails'>{content?.details}</div>
                                {selectedValue == index &&
                                  (content?.policy_text || content?.subsubtitle) && (
                                    <div className='subsubtitle'>
                                      <div className=''>{content?.policy_text}</div>
                                      <div className='subsubcontain'>
                                        <ul>
                                          {content?.subsubtitle.map((subsubtitle) => (
                                            <li className='subsubtitlemap'>{subsubtitle?.text}</li>
                                          ))}
                                        </ul>
                                      </div>
                                    </div>
                                  )}
                              </div>
                            ) : (
                              <li className='modalContent subtitlecontent'>
                                <div className=''>{content.text}</div>
                                {!content?.moreinfo && (
                                  <Link to={route.FINDOUTMOREHTML_ROUTE} target='_blank'>
                                    <div className='subtitleinfo'>
                                      <div className='subtitleinfotext'>{'-> find out more'}</div>

                                      <img
                                        src={IMG_PATH + 'report.svg'}
                                        className='likeIcon'
                                        alt=''
                                      />
                                    </div>
                                  </Link>
                                )}
                              </li>
                            )
                          })}
                        </ul>
                      </>
                    </div>
                  </ul>
                </div>
                {activeBack && (
                  <div>
                    {!!moreInfo && (
                      <Link to={route.MOREINFOHTML_ROUTE} target='_blank'>
                        <img src={IMG_PATH + 'moreinformation.svg'} className='likeIcon' alt='' />
                        <button className='moreinfobutton'>More Information</button>
                      </Link>
                    )}
                    <div className='activeButton'>
                      <button
                        type='submit'
                        class='btn btn btn-primary'
                        onClick={() => backButtonHandler()}
                      >
                        Back
                      </button>
                      {!!moreInfo && (
                        <button
                          type='submit'
                          class='btn btn btn-primary'
                          onClick={() => submitHandler()}
                          disabled={selectedValue === undefined}
                        >
                          Submit
                        </button>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
function mapStateToProps(state) {
  return {
    report_content: state?.SocialMediaReducer?.reportdata,
  }
}
export default connect(mapStateToProps, null)(ReportContent)
