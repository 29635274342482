import React from 'react'

const LoadingOverlay = ({ show = false }) => {
  return show ? (
    <>
      <div className='modal-backdrop fade show'></div>
      <div
        className='search-page-loading-icon'
        style={{
          position: 'fixed',
          zIndex: 2000,
          top: '45%',
          left: '50%',
          marginLeft: -30,
          marginTop: -30,
        }}
      >
        <i style={{ backgroundSize: 95 }} />
      </div>
    </>
  ) : null
}

export default LoadingOverlay
