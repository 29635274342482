export const toTitleCase = (str, removeDashes = false) => {
  str = str.toLowerCase().trim()
  if (removeDashes) {
    str = str.replaceAll('-', ' ')
  }
  return str
    .split(/\s+|_+/g)
    .map((word) =>
      word.split('').reduce((final, next, idx) => (idx === 1 ? final.toUpperCase() : final) + next),
    )
    .join(' ')
}

export const formatMemberDirectoryName = (name, custom) =>
  name === 'Other' ? custom : toTitleCase(name)

export const formatMembersName = (name, custom, plural = true) => {
  const formattedName = (name === 'Other' ? custom : name) ?? 'Members'
  return plural
    ? formattedName
    : formattedName.endsWith('s')
    ? formattedName.endsWith('ies')
      ? formattedName.slice(0, -3) + 'y'
      : formattedName.slice(0, -1)
    : formattedName
}
