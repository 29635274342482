import axios from 'axios'
import { useState } from 'react'
import { GOOGLE_GEOCODING_API_KEY } from '../services/environment'
import { customLogger } from '../utils/CustomLogger'

export const useCurrentLocation = (callback = () => {}) => {
  const [currentLocation, setCurrentLocation] = useState()

  const getCurrentLocation = async () => {
    setCurrentLocation('')
    if (navigator.geolocation) {
      return navigator.geolocation.getCurrentPosition(
        async (position) => {
          const pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          }
          const REVERSE_GEOCODING_API_URL = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${pos.lat},${pos.lng}&result_type=political&key=${GOOGLE_GEOCODING_API_KEY}`
          try {
            const res = await axios.get(REVERSE_GEOCODING_API_URL)

            if (res.data.results && res.data.results[0] && res.data.results[0].formatted_address) {
              callback(res.data.results[0].formatted_address)
              setCurrentLocation(res.data.results[0].formatted_address)
            }
          } catch (error) {
            customLogger(error)
          }
        },
        () => {
          //Geolocator service failed
          setCurrentLocation('')
        },
      )
    } else {
      // Browser doesn't support Geolocation
      setCurrentLocation('')
    }
  }

  return { currentLocation, getCurrentLocation }
}
