import { ONLYCHARACTERS, ONLYCHARACTERS_WITHSPACE, DASHESANDCHARACTERS } from '../services/const'
export const required = (value, allValue, event) => {
  return !!value ? undefined : 'This field is required'
}
export const sponsorRequired = (value, allValue, event) => {
  return !!value
    ? undefined
    : 'A valid Sponsor ID# is required to join our Invite Only Beta.  Please use the invitation link / iTeam ID# of the person that told you about iTRUSTit to Sign Up.  Their iTeam ID# can be found in the invitation link they sent to you or above their profile picture.'
}

export const maxLength = (max) => (value) =>
  value?.length > max ? `Must be ${max} characters or less` : undefined

export const maxLength20 = maxLength(20)

export const minLength = (min) => (value) =>
  value?.length < min ? `Must be ${min} characters or more` : undefined

export const minValue6 = minLength(6)
export const minValue8 = minLength(8)
export const number = (value) => (value && isNaN(Number(value)) ? 'Must be a number' : undefined)

export const minValue1 = minLength(1)

export const minValue = (min) => (value) =>
  !!value && value < min ? `Must be at least ${min}` : undefined

/**
 * Function that validates email
 * @modified   Amalia -included trim for string
 * @task      914
 * @param    {String} value   Email
 * @return   {String}         Error message or undefined
 */
export const email = (value) => {
  let valueTrim = value
  if (typeof value === 'string') {
    valueTrim = value.trim()
  }
  return !!valueTrim && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,30}$/i.test(valueTrim)
    ? 'Invalid email address'
    : undefined
}
export const alphaNumeric = (value) =>
  !!value && !/^(?:(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).*)$/.test(value)
    ? 'Must be character include upper,lower and number'
    : undefined

export const phoneNumber1 = (value) =>
  !!value && !/^(0|[1-9][0-9]{9})$/i.test(value)
    ? 'Invalid phone number, must be 10 digits'
    : undefined

//export const startSpace = value => (value && !/^[^-\s]/i.test(value) ? "Not allow starting of space" : undefined);
export const noSpace = (value) =>
  !!value && !/^[^\s]+(\s+[^\s]+)*$/.test(value) ? 'Cannot include spaces' : undefined

export const noUsernameSpace = (value) =>
  !!value && (!/^[^\s]+(\s+[^\s]+)*$/.test(value) || /\s/g.test(value))
    ? 'Cannot include spaces'
    : undefined

export const confirmPassword = (value, allValues) => {
  return value !== allValues.password ? "Passwords don't match" : undefined
}

export const confirmEmail = (value, allValues) => {
  return value?.toLowerCase() !== allValues.email?.toLowerCase() ? "Emails don't match" : undefined
}

export const phoneNumber = (value) => {
  return !!value ? (/^\+?[0-9()-]+$/.test(value) ? undefined : 'Invalid phone number') : undefined
}

export const urlValidate = (value) => {
  return !!value &&
    !value
      .toLowerCase()
      .match(
        /(http(s)?:\/\/.)(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-z]{2,10}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g,
      )
    ? 'Invalid URL, Please use full url i.e. https://www.website.com'
    : undefined
}

export const onylCharacter = (value) => {
  return !!value && !isCharacter(value) ? 'Only character and 0-9 digit allowed' : undefined
}

export const onlyCharacterWithSpace = (value) => {
  let val = value
  if (!!value.value) {
    val = value.value
  }
  return !!val && !val.match(ONLYCHARACTERS_WITHSPACE) ? 'Value must be alphanumeric' : undefined
}

export const isDashed = (value) => {
  //return value && value.endsWith("-") ? "Dash is allowed BUT MUST be surrounded by letters" : undefined;
  return !!value && !isDahesSurrounded(value)
    ? 'Dash is allowed BUT MUST be surrounded by letters'
    : undefined
}

export const isDashedStart = (value) => {
  return value?.startsWith('-') ? 'Dash is allowed BUT MUST be surrounded by letters' : undefined
}

export const isBeginLatter = (value) => {
  return !!value && !/^[a-z]/i.test(value) ? 'MUST begin with a LETTER' : undefined
}

/**
 * Function that return the user details JXS (email)
 * @author   Amalia
 * @task     210824-1
 * @param    {Object} submitFailed                submit failed or not
 * @param    {String} sponsorErrorMsg             sponsor Error Message
 * @param    {String} sponsorNotValidatedOnSubmit sponsor not validate on Submit
 * @param    {String} is_username_exists          '0' or'1'
 * @param    {String} invalid                     any error
 * @param    {Array} classesSpan                     classes for fomating span.
 * @return   {String}                             error message or null
 */
export const isFormValid = (
  submitFailed,
  is_username_exists,
  invalid,
  classesSpan = ['text-danger'],
  sponsorErrorMsg = '',
  sponsorNotValidatedOnSubmit = true,
  is_email_exists = '0',
) => {
  return (submitFailed && invalid) ||
    sponsorErrorMsg.length > 0 ||
    is_username_exists === '1' ||
    is_email_exists === '1' ? (
    <small className={classesSpan.join(' ')}>Please correct the errors above.</small>
  ) : invalid ? (
    <small className={classesSpan.join(' ')}>Please fill in required fields above.</small>
  ) : null
}
/**
 * Function check if the value is character or 0-9 or dashes surrounded by characters, returns true or false
 * @author   Amalia
 * @task     210824-1
 * @param    {String} value   value to be validated
 * @return   {boolean}        true or false
 */
export const isDahesSurrounded = (value) => {
  return value?.match(DASHESANDCHARACTERS)
}
/**
 * Function check if the value is character or 0-9, returns true or false
 * @author   Amalia
 * @task     210824-1
 * @param    {String} value   value to be validated
 * @return   {boolean}        true or false
 */
export const isCharacter = (value) => {
  return value?.match(ONLYCHARACTERS)
}

/**
 * Function password username
 * @author   Amalia
 * @task     210922_em-01
 * @param    {String} inputValue    value
 * @param    {Object} validation    condition to be validated (criteria conditions)
 * @return   {Object}               object that includes criteria values(true/false)
 */
export const passwordValidation = (inputValue, validation) => {
  let tempValidation = { ...validation }
  if (/^[^\s]+(\s+[^\s]+)*$/.test(inputValue)) {
    tempValidation['isSpaceValidation'] = true
  } else {
    tempValidation['isSpaceValidation'] = false
  }

  if (inputValue.length > 7 && inputValue.length < 21) {
    tempValidation['isMaxLength'] = true
  } else {
    tempValidation['isMaxLength'] = false
  }

  if (/[A-Z]/.test(inputValue)) {
    tempValidation['isUppercase'] = true
  } else {
    tempValidation['isUppercase'] = false
  }

  if (/[a-z]/.test(inputValue)) {
    tempValidation['isLowercase'] = true
  } else {
    tempValidation['isLowercase'] = false
  }

  if (inputValue.match(/\d+/g)) {
    tempValidation['isNumber'] = true
  } else {
    tempValidation['isNumber'] = false
  }
  return tempValidation
}
/**
 * Function validates username
 * @author   Amalia
 * @task     210922_em-01
 * @param    {String} inputValue    value
 * @param    {Object} validation    condition to be validated (criteria conditions)
 * @return   {Object}               object that includes criteria values(true/false)
 */

export const userNameValidation = (inputValue, validation) => {
  let tempValidation = { ...validation }
  if (!/\s/g.test(inputValue)) {
    tempValidation['isSpaceValidation'] = true
  } else {
    tempValidation['isSpaceValidation'] = false
  }

  if (inputValue.length > 0 && inputValue.length < 21) {
    tempValidation['isMaxLength'] = true
  } else {
    tempValidation['isMaxLength'] = false
  }

  if (inputValue.match(/^[A-Z]/i)) {
    tempValidation['isBeginLatter'] = true
  } else {
    tempValidation['isBeginLatter'] = false
  }
  //210824-1 -fixing dash criteria validation
  tempValidation['isDashed'] = isDahesSurrounded(inputValue)
  tempValidation['isCharacter'] = isCharacter(inputValue)

  return tempValidation
}
/**
 * Function validates username
 * @author   Amalia
 * @task     211004_em-01
 * @param    {Object} toBeValidated    condition to be validated (criteria conditions)
 * @return   {Number}                  procentage of validation
 */

export const percentageOfValidation = (toBeValidated) => {
  let validationKey = Object.keys(toBeValidated)
  let validationFilter = validationKey.filter(function (key) {
    return toBeValidated[key]
  })

  let validationPercentage = validationFilter.length * 20
  return validationPercentage
}
/**
 * Function that return the user details JXS (email)
 * @author   Amalia
 * @task     210922_em-01
 * @param    {Object} submitFailed                submit failed or not
 * @param    {String} is_username_exists          '0' or'1'
 * @param    {String} invalid                     any error
 * @param    {Array} classesSpan                     classes for fomating span.
 * @param    {String} sponsorErrorMsg             sponsor Error Message
 * @param    {String} sponsorErrorMsg             errors occured during submit such as email, username, relationship_type validation
 * @return   {String}                             error message or null
 */
export const isFormValidWithError = (
  submitFailed,
  is_username_exists,
  invalid,
  classesSpan = ['text-danger'],
  sponsorErrorMsg = '',
  is_email_exists,
) => {
  /* 
  let errorFound = false
  if (error) {
    Object.keys(error).forEach((key) => {
      if (error[key].length > 0) {
        errorFound = true
      }
    })
  } */
  return (submitFailed && invalid) ||
    sponsorErrorMsg.length > 0 ||
    is_username_exists === '1' ||
    is_email_exists === '1' ? (
    <small className={classesSpan.join(' ')}>Please correct the errors above.</small>
  ) : invalid ? (
    <small className={classesSpan.join(' ')}>Please fill in required fields above.</small>
  ) : null
}

export const otp = (value) => {
  return value && !/^[0-9]{6}/g.test(value) ? 'Please enter a valid OTP' : undefined
}
