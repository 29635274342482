import React, { useState } from 'react'
import $ from 'jquery'
import { Cta, ItiBug } from '../VCard/Components/Components'
import { SITE_ADDRESS } from '../../../services/environment'
import { toaster } from '../../../services/customFunction'
import VCardQRCode from '../VCard/Components/VCardQRCode'

export const showVCard = () => {
  $('#vcard_modal').modal('show')
}

const VCardModal = ({ user, initialModalState = 'vcard', inModal = true }) => {
  const vcardLink = `${SITE_ADDRESS}vcard/${user?.slug}`
  const [modalState, setModalState] = useState(initialModalState)

  const closeBtnProps = ['share'].includes(modalState)
    ? !inModal
      ? { 'data-bs-dismiss': 'modal' }
      : { onClick: () => setModalState('vcard') }
    : modalState === 'qr_code'
    ? { onClick: () => setModalState('share') }
    : { 'data-bs-dismiss': 'modal' }

  const copyToClipboard = async (text) => {
    if ('clipboard' in navigator) {
      await navigator.clipboard.writeText(text)
      toaster('success', 'Copied successfully')
    } else {
      return document.execCommand('copy', true, text)
    }
  }

  return (
    <div className='modal fade vcard_modal' id='vcard_modal' role='dialog'>
      <div className='modal-dialog'>
        <div className='modal-content'>
          <div
            className={`modal-header align-items-start ${
              modalState === 'qr_code' ? '' : 'border-0'
            }`}
          >
            {modalState === 'qr_code' ? <h5>{user.fullname}</h5> : ''}
            <button type='button' className='btn-close' {...closeBtnProps}></button>
          </div>
          <div
            className='modal-body'
            style={modalState === 'qr_code' ? { textAlign: 'center' } : {}}
          >
            {modalState === 'share' ? (
              <div className='d-flex flex-column align-items-center'>
                <span className='vcard-cta-text'>
                  Share this <ItiBug /> vCard by:
                </span>
                <div className='d-flex flex-column w-50'>
                  <Cta
                    className='my-4'
                    text='QR Code'
                    onClickCallback={() => setModalState('qr_code')}
                  />
                  <a
                    className='vcard-cta vcard-cta-text my-4'
                    href={`sms:?&body=${encodeURIComponent(
                      vcardLink +
                        '\r\n\r\nSign up on iTRUSTit and you can get a vCard like this too!',
                    )}`}
                  >
                    Text
                  </a>
                  <a
                    className='vcard-cta vcard-cta-text my-4'
                    href={
                      'mailto:?subject=' +
                      encodeURIComponent('Here is my iTRUSTit vCard') +
                      '&body=' +
                      encodeURIComponent(
                        `Hi,\r\nClick here for my iTRUSTit vCard:\r\n${vcardLink}\r\n\r\nSign up on iTRUSTit and you can get a vCard like this too!\r\n\r\nThanks,\r\n${user?.fullname}\r\nMy Sponsor iTeam ID# is: ${user?.imatrix_id}`,
                      )
                    }
                  >
                    e-mail
                  </a>
                  <Cta
                    className='my-4'
                    text='Copy Link'
                    onClickCallback={() => copyToClipboard(vcardLink)}
                  />
                </div>
              </div>
            ) : modalState === 'qr_code' ? (
              <VCardQRCode vcardLink={`${SITE_ADDRESS}qr/vcard/${user?.id}`} />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  )
}

export default React.memo(VCardModal, (prev, next) => prev.user.id === next.user.id)
