import React from 'react'
import $ from 'jquery'
import { Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { userExistsPromptAction } from '../../actions/auth'

export const showSocialUserExistsModal = () => {
  $('#social_user_exists_modal')?.modal?.('show')
}
export const hideSocialUserExistsModal = () => {
  $('#social_user_exists_modal')?.modal?.('hide')
}

let SocialUserExistsModal = (props) => {
  const { firstname, lastname, email, user_type, user_detail } = props?.user ?? {}
  const { profile_picture_small: avatar } = user_detail ?? {}

  const hideModal = () => {
    props.userExistsPromptAction(null)
    hideSocialUserExistsModal()
  }

  return (
    <div
      className='modal fade social_user_exists_modal'
      id='social_user_exists_modal'
      role='dialog'
    >
      <div className='modal-dialog'>
        <div className='modal-content'>
          <div className='modal-header align-items-start'>
            <h5>
              {user_type === 'organization' ? 'An' : 'A'}{' '}
              {user_type === 'individual' ? 'user' : user_type ?? 'user'} already exists with this
              email address
            </h5>
            <button type='button' className='btn-close' data-bs-dismiss='modal'></button>
          </div>
          <div className='modal-body'>
            <div className='d-flex'>
              <Link to={'/' + props?.user?.slug} target='_blank' className='d-flex'>
                <img
                  src={avatar ?? process.env.PUBLIC_URL + '/img/iti_bug.png'}
                  alt=''
                  style={{
                    borderRadius: '50%',
                    width: 42,
                    marginRight: '0.5rem',
                    objectFit: 'cover',
                  }}
                />
              </Link>
              <div className='d-flex flex-column'>
                <Link to={'/' + props?.user?.slug} target='_blank'>
                  {firstname} {lastname}
                </Link>
                <div>{email}</div>
              </div>
              <div className='d-flex align-items-center loginblk m-0 w-auto ms-auto'>
                <button
                  className='purple nohover'
                  onClick={hideModal}
                  style={{ outline: 'none', border: 'none', background: 'none' }}
                >
                  Not You?
                </button>
              </div>
            </div>
            <div className='loginblk m-0 mt-4 mw-100 w-100'>
              <button
                onClick={hideModal}
                style={{ fontSize: 18 }}
                className={'btn btn-lg btn-success btn-block loginbtn signup-submit mt-1'}
              >
                Choose another method / email
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      userExistsPromptAction,
    },
    dispatch,
  )
}

export default connect(null, mapDispatchToProps)(SocialUserExistsModal)
