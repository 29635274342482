import { connect } from 'react-redux'
import Landing from '../../components/Landing'
//210930_aa-01 add isLogin prop
function mapStateToProps(state) {
  return {
    user: state.UserReducer.userdetail,
  }
}

export default connect(mapStateToProps)(Landing)
