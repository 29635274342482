import React, { Component } from 'react'
import { reduxForm } from 'redux-form'
import { makeMemberCall } from '../../../services/memberApiService'
import $J from 'jquery'
import './MemberPopup.scss'
import { formatMembersName } from '../../../services/formatService'

class MemberPopupModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      is_outside_member: '1',
      member_note: '',
    }
  }
  componentWillMount() {}
  componentDidMount() {}
  changeUserTypeHandler = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }
  submitMember = (event) => {
    let memberAddRemoveReqData = {
      id: this.props.user_id,
      member_type: this.props.memberType,
      is_outside_member: this.state.is_outside_member,
      member_note: this.state.member_note,
    }
    let self = this
    $J('#member_modal').modal('hide')
    self.props.setLoadingMemberChangeResponse?.(true)
    makeMemberCall(memberAddRemoveReqData).then(function (response) {
      // console.log(response);
      if (typeof response.status !== 'undefined' && response.status === 200) {
        let apiResponseData = response.data
        self.props.setStatusOfMemberUser(apiResponseData.data.member_status)
        self.setState({
          member_note: '',
          is_outside_member: '1',
        })
        self.props.setLoadingMemberChangeResponse?.(false)
      }
    })
  }
  render() {
    const { invalid } = this.props
    return (
      <div
        className='modal fade login_blk reset_password_blk it-member-modal'
        id='member_modal'
        role='dialog'
      >
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h2 className='text-start'>
                BECOME A {formatMembersName(this.props?.membersName, null, false).toUpperCase()} OF
                THIS ORGANIZATION
              </h2>
              <button type='button' className='btn-close' data-bs-dismiss='modal'></button>
            </div>
            <div className='modal-body'>
              <p>
                Are you a {formatMembersName(this.props?.membersName, null, false)} of this
                organization outside of iTRUSTit?
              </p>
              <div className='text-start'>
                <input
                  type='radio'
                  name='is_outside_member'
                  value='1'
                  checked={this.state.is_outside_member == '1'}
                  onChange={this.changeUserTypeHandler}
                />{' '}
                <label htmlFor='yes'>Yes</label> &nbsp;
                <input
                  type='radio'
                  name='is_outside_member'
                  value='0'
                  checked={this.state.is_outside_member == '0'}
                  onChange={this.changeUserTypeHandler}
                />{' '}
                <label htmlFor='no'>No</label>
              </div>

              <form id='login_form' name='login_form' className='reset_password' method='post'>
                <div className='form-group'>
                  <p>
                    You are requesting to be a{' '}
                    {formatMembersName(this.props?.membersName, null, false)} of the:{' '}
                    <b>{this.props.Orgname}</b> Organization. You may add a comment to be sent them
                    along with your request or just click "Submit".
                  </p>
                </div>
                <div className='form-group'>
                  <label> Comments:</label>
                  <textarea name='member_note' onChange={this.changeUserTypeHandler}></textarea>
                </div>
                <input
                  className='resetbtn'
                  type='button'
                  value='Submit'
                  onClick={this.submitMember}
                />
              </form>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

MemberPopupModal = reduxForm({
  form: 'member_modal',
})(MemberPopupModal)

export default MemberPopupModal

// export default connect(
//     mapStateToProps,
//     mapDispatchToProps
// )(MemberPopupModal);
