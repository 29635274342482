import React from 'react'
import { Link } from 'react-router-dom'
import { kFormatter } from '../../../../services/customFunction'
import {
  ABOUT_ROUTE,
  COMMUNITY_ROUTE,
  CONTACT_ROUTE,
  FAQ_ROUTE,
  LOGIN_ROUTE,
  PROFILE_ROUTE,
  ROOT_ROUTE,
  SIGNUP_ROUTE,
} from '../../../../services/routeConst'
import ITeamTreeIcon from './ITeamTreeIcon'
import MessagesDropdown from './MessagesDropdown'
import NotificationsDropdown from './NotificationsDropdown'
import UserDropdown from './UserDropdown'

const DropdownMenu = ({
  isMobile,
  chat_users_list,
  chat_users_online_list,
  user_notifications,
  userProfileAvatar,
  userFullname,
  userCoverImg,
  userName,
  readNotification,
  user,
  iteamTotalCount,
  userNotificationCount,
  isLoggedIn,
  routeActive,
}) => {
  const onSiteRoute = [ROOT_ROUTE, ABOUT_ROUTE, COMMUNITY_ROUTE, FAQ_ROUTE, CONTACT_ROUTE].includes(
    routeActive,
  )
  return (
    <div className='collapse navbar-collapse' id='navbarSupportedContent'>
      <div className='header-dropdown-content-wrapper'>
        <ul
          className={`navbar-nav ms-lg-auto mb-2 mb-lg-0 ${
            isLoggedIn ? ' align-items-center' : ''
          }`}
        >
          {isLoggedIn ? (
            <>
              <li className='nav-item'>
                <Link className='nav-link' to={PROFILE_ROUTE + '#overview'}>
                  <img
                    src={process.env.PUBLIC_URL + '/img/new_icons/user.svg  '}
                    alt='logo'
                    width={25}
                    height={25}
                  />
                </Link>
              </li>
              <ITeamTreeIcon
                readNotification={readNotification}
                user={user}
                iteamTotalCount={iteamTotalCount}
              />
              <MessagesDropdown
                chat_users_list={chat_users_list}
                chat_users_online_list={chat_users_online_list}
              />
              <NotificationsDropdown
                readNotification={readNotification}
                userNotificationCount={userNotificationCount}
                user_notifications={user_notifications}
              />
              {!!user ? (
                <UserDropdown
                  readNotification={readNotification}
                  userNotificationCount={userNotificationCount}
                  isMobile={isMobile}
                  user={user}
                  userProfileAvatar={userProfileAvatar}
                  userFullname={userFullname}
                  userCoverImg={userCoverImg}
                  userName={userName}
                />
              ) : null}
            </>
          ) : (
            <>
              <li
                className={`nav-item${!onSiteRoute ? ' site-route' : ''}${
                  routeActive === ABOUT_ROUTE ? ' active' : ''
                } my-2 my-lg-0`}
              >
                <Link className='nav-link' to={ABOUT_ROUTE}>
                  ABOUT
                </Link>
              </li>
              <li
                className={`nav-item${!onSiteRoute ? ' site-route' : ''}${
                  routeActive === COMMUNITY_ROUTE ? ' active' : ''
                } my-2 my-lg-0`}
              >
                <Link className='nav-link' to={COMMUNITY_ROUTE}>
                  COMMUNITY
                </Link>
              </li>
              <li
                className={`nav-item${!onSiteRoute ? ' site-route' : ''}${
                  routeActive === FAQ_ROUTE ? ' active' : ''
                } my-2 my-lg-0`}
              >
                <Link className='nav-link' to={FAQ_ROUTE}>
                  FAQs
                </Link>
              </li>
              <li
                className={`nav-item${!onSiteRoute ? ' site-route' : ''}${
                  routeActive === CONTACT_ROUTE ? ' active' : ''
                } my-2 my-lg-0`}
              >
                <Link className='nav-link' to={CONTACT_ROUTE}>
                  CONTACT
                </Link>
              </li>

              <li className='nav-item login_btn'>
                <Link className='nav-link ' to={LOGIN_ROUTE}>
                  Log in
                </Link>
              </li>

              <li className='nav-item login_btn signup'>
                <Link className='nav-link' to={SIGNUP_ROUTE}>
                  Sign Up
                </Link>
              </li>
            </>
          )}
        </ul>
      </div>
    </div>
  )
}

export default DropdownMenu
