import React from 'react'

const InviteEnvelope = ({ invite, handleClick }) => {
  // console.log("prop", props);
  return (
    <div
      style={{ position: 'relative' }}
      className='col-lg-3 col-md-4 col-6 px-1 mb-4'
      role='button'
      onClick={() =>
        handleClick(
          true,
          invite.type,
          invite.headerText ? invite.headerText : ` inviting ${invite.text}`,
        )
      }
    >
      <small
        className='text-center'
        style={{
          color: '#544cf9',
          position: 'absolute',
          maxWidth: 100,
          width: invite.text === 'Brokers:\nInvite Preferred\nProviders' ? '90%' : '75%',
          lineHeight: '125%',
          fontFamily: "'Varela Round', sans-serif",
          top: '55%',
          left: '50%',
          whiteSpace: 'pre-wrap',
          transform: 'translate(-50%, -50%)',
        }}
      >
        {invite.text}
      </small>
      <img
        src={process.env.PUBLIC_URL + '/img/envelope_hd.png'}
        alt=''
        width='115'
        className='d-sm-none d-block'
        style={{
          display: 'block',
          margin: 'auto',
          backgroundColor: '#f3f3f3',
          padding: '0.15rem',
          border: '1px solid #ebebeb',
        }}
      />
      <img
        src={process.env.PUBLIC_URL + '/img/envelope_hd.png'}
        alt=''
        className='d-sm-block d-none'
        width='150'
        style={{
          display: 'block',
          margin: 'auto',
          backgroundColor: '#f3f3f3',
          padding: '0.15rem',
          border: '1px solid #ebebeb',
        }}
      />
    </div>
  )
}

export default InviteEnvelope
