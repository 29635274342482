import React, { Component } from 'react'
import { friendListCallNew, friendListCallNewFromIDs } from '../../../services/friendApiService'
import {
  getProfileOverviewFriends,
  getProfileOverviewFriendsIndividual,
  getProfileOverviewFriendsOrganization,
} from '../../../services/userApiService'
import FriendsListDetail from '../FriendsListDetail/FriendsListDetail'
import { customLogger } from '../../../utils/CustomLogger'
// import $ from "jquery";

class Friends extends Component {
  constructor(props) {
    super(props)
    // user_id

    this.state = {
      user_id: this.props.user_id,
      total_count: {
        friends: 0,
        business: 0,
        individual: 0,
        organization: 0,
      },
      mutual_friends: {
        business: 0,
        individual: 0,
        organization: 0,
      },
      individual_list: [],
      business_list: [],
      organization_list: [],
      changeActiveTab: this.props.changeActiveTab(),
      is_friends_loading: true,
    }
  }

  componentDidMount() {
    this.getProfileOverviewFriends()
  }

  componentWillReceiveProps(nextProps) {
    let user_id_new = nextProps.user_id
    let user_id_old = this.state.user_id

    if (user_id_new !== user_id_old) {
      this.setState({ user_id: user_id_new }, () => {
        this.getProfileOverviewFriends()
      })
    }
  }

  profileHeader_overview_tab_click_Handle() {
    this.getProfileOverviewFriends()
  }

  getProfileOverviewFriends() {
    const self = this
    let getProfileOverviewFriendsReqData = {
      other_user_id: this.state.user_id,
    }
    self.setState({ is_friends_loading: true })
    getProfileOverviewFriends(getProfileOverviewFriendsReqData).then(function (response) {
      if (typeof response.status !== 'undefined' && response.status === 200) {
        let apiResponseData = response.data
        self.setState({
          total_count: apiResponseData.data.total_count,
          // individual_list: apiResponseData.data.individual_list,
          // business_list: apiResponseData.data.business_list,
          // organization_list: apiResponseData.data.organization_list,
          //mutual_friends: apiResponseData.data.mutual_friends,
        })

        if (apiResponseData.data.total_count.individual > 0) {
          self.getProfileOverviewFriendsByType('individual')
        }
        if (apiResponseData.data.total_count.organization > 0) {
          self.getProfileOverviewFriendsByType('organization')
        }
        if (apiResponseData.data.total_count.business > 0) {
          self.getProfileOverviewFriendsByType('business')
        }
      }
      self.setState({ is_friends_loading: false })
    })
  }

  getProfileOverviewFriendsIndividual() {
    const self = this
    let getProfileOverviewFriendsReqData = {
      other_user_id: this.state.user_id,
    }
    getProfileOverviewFriendsIndividual(getProfileOverviewFriendsReqData).then(function (response) {
      if (typeof response.status !== 'undefined' && response.status === 200) {
        let apiResponseData = response.data
        self.setState({
          individual_list: apiResponseData.data.individual_list,
        })
      }
    })
  }

  getProfileOverviewFriendsOrganization() {
    const self = this
    let getProfileOverviewFriendsReqData = {
      other_user_id: this.state.user_id,
    }
    getProfileOverviewFriendsOrganization(getProfileOverviewFriendsReqData).then(function (
      response,
    ) {
      if (typeof response.status !== 'undefined' && response.status === 200) {
        let apiResponseData = response.data
        self.setState({
          organization_list: apiResponseData.data.organization_list,
        })
      }
    })
  }

  getProfileOverviewFriendsByType(type = 'individual') {
    const self = this
    let getProfileOverviewFriendsReqData = {}
    let friendListReqData = {
      other_user_id: self.state.user_id,
      page: 1,
      firstCall: true,
      noMutual: true,
      endOfPage: 10,
      user_type: type,
      orderByFriendshipID: true,
      searchString: '',
    }
    friendListCallNew(friendListReqData)
      .then((response) => {
        const currentIDs = response.data.ids.slice(0, 4).map((user) => user.friend_user_id)
        const self = this
        friendListCallNewFromIDs({
          other_user_id: self.state.user_id,
          noMutual: true,
          endOfPage: 10,
          user_type: type,
          orderBy: 'friendship_id',
          orderType: 'desc',
          ids: currentIDs,
        }).then(function (response) {
          response.data.friends_list &&
            self.setState({ [`${type}_list`]: response.data.friends_list })
        })
      })
      .catch((error) => {
        customLogger(error)
        console.log(error)
      })
    // getProfileOverviewFriendsBusiness(getProfileOverviewFriendsReqData).then(
    //   function (response) {
    //     if (typeof response.status !== 'undefined' && response.status === 200) {
    //       let apiResponseData = response.data;
    //       self.setState({
    //         business_list: apiResponseData.data.business_list,
    //       });
    //     }
    //   }
    // );
  }

  render() {
    const { total_count, individual_list, business_list, organization_list, mutual_friends } =
      this.state

    if (this.state.is_friends_loading) {
      return (
        <div className='bg-white curvey mb-4 p-15 related-listings-show'>
          <p className='text-center'>Loading...</p>
        </div>
      )
    } else {
      return (
        <div className='bg-white curvey mb-4 p-15 related-listings-show'>
          <h4 className='varela'>
            {' '}
            {/* Friends <span className="purple">{total_count.friends}</span> */}
            <a onClick={this.props.changeActiveTab('#friend')} href='#friend'>
              Friends
            </a>
            <span className='purple'>&nbsp;{total_count.friends}</span>
          </h4>
          <div className='friends_green mt-2'>
            <div className='d-flex headers green'>
              <h6>
                {/* People - {total_count.individual} */}
                <a
                  onClick={this.props.showFriendsTab('#friend_individual')}
                  href='#friend'
                  className='mt-0 headers w-50 '
                >
                  <h6>People - {total_count.individual}</h6>
                </a>
              </h6>
              {/* <h6>{mutual_friends.individual} mutual</h6> */}
            </div>
            <div className='row profiles'>
              {total_count.individual > 0 ? (
                individual_list.map((user, index) => (
                  <FriendsListDetail key={user.id} index={index} user={user} />
                ))
              ) : (
                <div className='col-md-6'>No records</div>
              )}
            </div>
          </div>
          {/* friends green over */}

          <div className='business_blue mt-4'>
            <div className='d-flex headers'>
              <h6>
                {/* business - {total_count.business} */}
                <a
                  onClick={this.props.showFriendsTab('#friend_business')}
                  href='#friend'
                  className='mt-0 headers w-50 '
                >
                  <h6>businesses - {total_count.business}</h6>
                </a>
              </h6>
              {/* <h6>{mutual_friends.business} mutual</h6> */}
            </div>
            <div className='row profiles'>
              {total_count.business > 0 ? (
                business_list.map((user, index) => (
                  <FriendsListDetail key={user.id} index={index} user={user} />
                ))
              ) : (
                <div className='col-md-6'>No records</div>
              )}
            </div>
          </div>
          {/* business blue over */}

          <div className='organization_orange mt-4'>
            <div className='d-flex headers'>
              <h6>
                {/* ORGANIZATIONS - {total_count.organization} */}
                <a
                  onClick={this.props.showFriendsTab('#friend_organization')}
                  href='#friend'
                  className='mt-0 headers w-50 '
                >
                  <h6> ORGANIZATIONS - {total_count.organization}</h6>
                </a>
              </h6>
              {/* <h6>{mutual_friends.organization} mutual</h6> */}
            </div>
            <div className='row profiles'>
              {total_count.organization > 0 ? (
                organization_list.map((user, index) => (
                  <FriendsListDetail key={user.id} index={index} user={user} />
                ))
              ) : (
                <div className='col-md-6'>No records</div>
              )}
            </div>
          </div>
          {/* organization over */}
        </div>
      )
    }
  }
}

export default Friends
