import { isMobile } from 'react-device-detect'
import * as type from '../actions/const'

const initialState = {
  searchData: !isMobile
    ? {
        searchStr: localStorage.getItem('searchString'),
        searchAddress: localStorage.getItem('searchAddress'),
      }
    : null,
  isSearching: !isMobile,
  searchDistance: { value: '2mi', label: '2 miles' },
}

export default function SearchReducer(state = initialState, action) {
  const data = action.data
  switch (action.type) {
    case type.SET_SEARCH_DATA:
      return {
        ...state,
        searchData: data,
      }

    case type.SET_IS_SEARCHING:
      return {
        ...state,
        isSearching: data,
      }

    case type.SET_SEARCH_DISTANCE:
      return {
        ...state,
        searchDistance: data,
      }
    default:
      return state
  }
}
