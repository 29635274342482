import AppLayout from '../../../AppLayout'
import LoginModal from '../../../LoginModal/LoginModal'
import * as route from '../../../../services/routeConst'
import '../Reports/Report.scss'
import { Link } from 'react-router-dom'

const CopyRightHTML = (props) => {
  window.scrollTo(0, 0)

  return (
    <AppLayout routeActive={props.location.pathname}>
      <section className='bg-grey it-privacy-terms-page it-terms-page'>
        <div className='container'>
          <div className='white-pane privacy'>
            <b>
              <h1>
                <span className='colorblue dotunderline'>
                  <span>iTRUSTit</span>
                </span>
                <span className='colorblue'> Copyright Policy </span>
              </h1>
            </b>

            <br></br>
            <br></br>
            <h4>
              <b>Complaints about content on the iTRUSTit website</b>
            </h4>
            <br></br>
            <p>
              <span className='dotunderline'>iTRUSTit</span> aims to provide a platform that does
              not infringe on the intellectual property rights of others. It is a requirement of our
              User Agreement that any information provided by Members be truthful, legal, and not in
              violation of the rights of third-party parties. Complaints about material uploaded by
              iTRUSTit members can be submitted using a form on the site. Sections following discuss
              and/or reference our policies and practices.
            </p>
            <p>
              The complainant's contact information may be forwarded to the Member who posted the
              content, and/or iTRUSTit may take other reasonable steps to notify the Member that
              iTRUSTit has received notice of an alleged violation of intellectual property rights
              or other content violations, regardless of whether or not iTRUSTit disables access to
              or removes the content. For Members or organizations that persistently violate or
              infringe the rights of others, it is also our policy to deactivate and/or cancel their
              accounts. This is done at our discretion and in suitable situations.
            </p>
            <p>
              IMORTANT NOTE: Please keep in mind that any notice you send must be true and made
              under penalty of perjury. If a notification is false the person who submitted it may
              be held liable. This means that before sending a notice you should consult with legal
              counsel.
            </p>
            <p>
              We encourage iTRUSTit users to amicably resolve these maters first by contacting the{' '}
              <span className='dotunderline'>alleged</span> infringer directly to discuss a
              resolution.
            </p>
            <br></br>
            <h4>
              <b>Claims of copyright violation</b>
            </h4>
            <br></br>
            <br></br>
            <div className='nomargin'>
              <p>
                <b>Copyright infringement notice:</b>
              </p>
            </div>

            <p>
              When it comes to reporting infringements under the Digital Millennium Copyright Act
              (17 U.S.C. 512), iTRUSTit has established protocols. In addition, iTRUSTit has
              established an agent to accept reports of alleged copyright infringement. If you in
              good faith believe that your copyright has been infringed then the Notice of Copyright
              Infringement Reporting form on the{' '}
              <Link
                style={{ color: '#6684f2' }}
                to={route.COPYRIGHTINSTRUCTIONSHTML_ROUTE}
                target='_blank'
              >
                Copyright Infringement Reporting Instructions
              </Link>{' '}
              page may be completed and sent, or a written statement can be sent that includes the
              following:
              <ul>
                <li>The name, address and other contact information of the copyright owner; </li>
                <li>
                  The name, address and a digital or physical signature from the person who can act
                  on behalf of the person who owns the copyright interest;
                </li>
                <li>A description of the allegedly infringed-upon work;</li>
                <li>
                  A specific description of the allegedly illegal item, including the URL of the
                  page where you found it;
                </li>
                <li>If you have a postal address or telephone number;</li>
                <li>
                  In your own words, an affirmation that you, in good faith, believe that the
                  disputed use is not permitted by the copyright owner, its agent, or the law;
                </li>
                <li>
                  A declaration by you, under penalty of perjury, that the information in your
                  notification is correct and that you are the copyright owner or authorized to act
                  on the copyright owner's behalf.
                </li>
              </ul>
            </p>

            <p>
              Notice of copyright infringement should be sent to iTRUSTit Corporation's Copyright
              Agent using the following instructions:
            </p>
            <div className='nomargin'>
              <p>
                <span className='dotunderline'>iTRUSTit</span> Social, Inc.
              </p>
              <p>Attn: Legal Department</p>
              <p>703 Pier Ave Ste. B691</p>
              <p>Hermosa beach, CA 90254</p>
            </div>

            <br></br>
            <div className='nomargin'>
              <p>Alternatively, you can write to us at the following e-mail address:</p>
              <p>Legal@itrustit.com</p>
            </div>

            <br></br>
            <br></br>

            <h2>
              <b className='colorblue'>Counter-Notice:</b>
            </h2>
            <br></br>
            <p>
              If you think that a notice of copyright infringement has been filed against you in the
              wrong way, you can file a Counter-Notice under Sections 512(g)(2) and (3) of the
              Digital Millennium Copyright Act. You may submit a written letter that includes the
              following information in lieu of completing the Counter-Notice form on the{' '}
              <Link
                style={{ color: '#6684f2' }}
                to={route.COUNTERNOTICEINSTRUCTIONSHTML_ROUTE}
                target='_blank'
              >
                Counter-Notice of Copyright Infringement Claim Instructions
              </Link>{' '}
              page:
              <ul>
                <li>Either your physical or digital signature;</li>
                <li>A record of what has been deleted or has had access revoked;</li>
                <li>
                  Under penalty of perjury, a declaration that you believe the removal or disabling
                  of the item was a mistake or that the material was misidentified;
                </li>
                <li>
                  If you are located in the United States, please include your full name, email
                  address, and mailing address, and a statement that you accept service of process
                  from the Complainant or his/her author. If you are located outside of the United
                  States, please include your full name, email address, and mailing address, and a
                  statement that you accept service of process from the Complainant submitting the
                  notice or his/her author.
                </li>
              </ul>
            </p>

            <p>
              The copyright agent for iTRUSTit wants you to send them your counter-notice. You can
              do this by mail.
            </p>
            <br></br>
            <br></br>
            <h4 className='colorblue'>
              Complaints other than copyright infringement about the content of third-party websites
              or services
            </h4>
            <br></br>
            <p>
              See our{' '}
              <Link style={{ color: '#6684f2' }} to={route.FINDOUTMOREHTML_ROUTE} target='_blank'>
                Content on iTRUSTit,
              </Link>{' '}
              <Link style={{ color: '#6684f2' }} to={route.TERMS_ROUTE} target='_blank'>
                Terms of Service and Acceptable Use Policy
              </Link>{' '}
              for information on flagging and reporting different kinds of content violations if
              you're having problems with copyright infringement. To learn more, read this: iTRUSTit
              users should be aware of a policy prohibiting the use of any Associated Press content,
              including images, audio and video, in any form of publishing, broadcast, or
              redistribution, whether directly or indirectly. Except for personal and noncommercial
              use, these AP materials and any part of them may not be kept on a computer. No piece
              of the AP content on this website may be downloaded or reproduced without permission
              from the publisher. All or any portion of the information provided by AP will not be
              held accountable for any delays, mistakes, omissions, or delays in its transmission or
              delivery, or for any damages resulting from any of the aforementioned.
            </p>
            <br></br>
            <br></br>
            <br></br>
            <h4 className='colorblue'>Use the "Report this post" link or button.</h4>

            <p>
              If you see any anything on iTRUSTit that you feel is improper or hazardous, you can
              report it right there on the site.
            </p>

            <p>
              Only improper content, as defined by our{' '}
              <Link
                style={{ color: '#6684f2' }}
                to={route.GENERALGUIDELINESHTML_ROUTE}
                target='_blank'
              >
                General Guidelines,
              </Link>{' '}
              <Link style={{ color: '#6684f2' }} to={route.TERMS_ROUTE} target='_blank'>
                Terms of Service or Acceptable Use Policy,
              </Link>{' '}
              should be brought to our attention. To flag anything because of a disagreement in
              viewpoint isn't a valid justification.
            </p>

            <p>
              If required, we'll remove things that have been reported after examining them. To
              reveal the identity of someone who flags an item is against our Privacy Policy. There
              is nothing we can do about material that isn't directly related to our brand.
            </p>
          </div>
        </div>
      </section>
      <LoginModal
        isLogin={(e) =>
          function (data) {
            props.isLogin(data)
          }
        }
        {...props}
      />
    </AppLayout>
  )
}

export default CopyRightHTML
