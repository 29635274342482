import React, { useEffect, useState } from 'react'
import { getUser } from '../../../services/customFunction'
import {
  postListCall,
  postDeleteCall,
  metadataCall,
  reportdataCall,
} from '../../../services/socialMediaApiService'
import SearchPageLoadingIcon from '../../Search/SearchPageLoadingIcon'
import CurrentUser from '../../SocialMedia/CurrentUser/CurrentUser'
import Post from '../../SocialMedia/Post'

import './PostsTab.scss'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  setPosts,
  deletePost,
  setMetaData,
  setReportData,
  resetSocialMediaData,
} from '../../../actions/socialmedia'

const PostsTab = ({ activeTab, user, changeActiveTab, showFriendsTab, ...props }) => {
  //console.log('posttab:', changeActiveTab)
  const login_user_id = getUser()?.id
  const [apiData, setApiData] = useState({ currentPage: 1, lastPage: 1 })

  const [status, setStatus] = useState('idle')
  const sameuser = login_user_id === user.id

  const postsListRender = React.useCallback(async () => {
    setStatus('loading')
    const response = await postListCall({
      user_id: user.id,
      login_user_id: login_user_id,
      page: apiData.currentPage,
      is_feed: true,
    })
    if (response?.status === 200) {
      setApiData((st) => ({
        currentPage: response.data.data.current_page + 1,
        lastPage: response.data.data.last_page,
      }))
      //console.log(response?.data?.data.posts)
      props.setPosts(response?.data?.data)
    }
    setStatus('idle')
  }, [apiData.currentPage, login_user_id, user.id])

  const getMetaData = async () => {
    let response = await metadataCall()
    if (response?.status === 200) {
      props.setMetaData(response?.data?.data)
    }
  }
  const getReportData = async () => {
    let response = await reportdataCall()
    if (response?.status === 200) {
      props.setReportData(response?.data?.data)
    }
  }

  useEffect(() => {
    loadData()
  }, [])
  const loadData = () => {
    props.resetSocialMediaData()
    postsListRender()
    getMetaData()
    getReportData()
  }
  const deletePost = async (id) => {
    //console.log('posts:', props.posts)

    const response = await postDeleteCall({
      id: id,
    })
    if (response?.status === 200) props.deletePost(id)
    setStatus('idle')
  }

  const scrollToTop = () =>
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  let postsExist = props?.postsData?.filter(
    (p) => p?.user_id !== login_user_id && p?.is_shared !== 100,
  )
  return (
    <div id='posts' className={'tab-pane ' + (activeTab === '#posts' ? 'active' : 'fade')}>
      <div className='inner-tab tab-content it-mobile-space'>
        <div className='bg-white nopadding_mobile'>
          {sameuser ? <CurrentUser user={user} /> : ''}
          {postsExist?.length > 0 ? (
            props?.postsData
              ?.filter((p) => p?.user_id !== login_user_id && p?.is_shared !== 100)
              ?.map((p) => <Post postId={p?.id} user={user} deleteDBPost={deletePost} />)
          ) : status !== 'loading' ? (
            <div className='d-flex load-more-btns'>
              <button className='btn mx-auto fs-6' onClick={changeActiveTab('#overview')}>
                <div>No posts yet.</div>
                <div>Click here to go to Overview</div>
              </button>
            </div>
          ) : (
            ''
          )}
        </div>

        <div className='d-flex load-more-btns'>
          {status === 'loading' ? (
            <SearchPageLoadingIcon
              className='pagination-loading'
              styles={{
                height: 'auto',
                marginTop: props?.postsData?.length === 0 ? 30 : 0,
              }}
              iconStyles={{ height: 38, transform: 'scale(1.5)' }}
            />
          ) : apiData.currentPage <= apiData.lastPage && apiData.lastPage > 1 ? (
            <button className='btn mx-auto' onClick={postsListRender}>
              Load more
            </button>
          ) : props?.postsData?.length > 0 ? (
            <button className='btn mx-auto text-muted back-to-top' onClick={scrollToTop}>
              Back to top
            </button>
          ) : null}
        </div>
      </div>
    </div>
  )
}
function mapStateToProps(state) {
  return {
    postsData: state.SocialMediaReducer.posts,
    metadata: state.SocialMediaReducer.metadata,
  }
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setPosts,
      deletePost,
      setMetaData,
      setReportData,
      resetSocialMediaData,
    },
    dispatch,
  )
}
export default connect(mapStateToProps, mapDispatchToProps)(PostsTab)
