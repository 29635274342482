import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import * as route from '../../services/routeConst'
import AppLayout from '../AppLayout'
import { ADVERTISE_TITLE } from '../../services/titleConst'
import LoginModal from '../LoginModal/LoginModal'
import { Helmet } from 'react-helmet'

class Advertise extends Component {
  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {
    return (
      <AppLayout routeActive={this.props.location.pathname}>
        <Helmet>
          <title>{ADVERTISE_TITLE}</title>
        </Helmet>
        <section className='bg-grey'>
          <div className='container'>
            <h3 className='varela mb-3'>Advertise</h3>
            <div className='white-pane green_line privacy'>
              <h5 className='text-center'>Coming Soon</h5>
            </div>
          </div>
        </section>
        <LoginModal
          isLogin={(e) =>
            function (data) {
              this.props.isLogin(data)
            }
          }
          {...this.props}
        />
      </AppLayout>
    )
  }
}

export default Advertise
