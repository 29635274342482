import React from 'react'
import { reduxForm } from 'redux-form'
import $J from 'jquery'
import './LeaveOrgPopup.scss'

const ConfirmLeaveOrgPopupModal = ({
  data,
  Orgname,
  setLoadingMemberChangeResponse,
  memberAddRemove,
}) => {
  const submitMember = async () => {
    setLoadingMemberChangeResponse?.(true)
    $J('#confirm_leave_org').modal('hide')
    memberAddRemove?.(data)
  }

  return (
    <div
      className='modal fade login_blk reset_password_blk it-member-modal'
      id='confirm_leave_org'
      role='dialog'
    >
      <div className='modal-dialog'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h2 className='text-start'>{Orgname}</h2>
            <button type='button' className='btn-close' data-bs-dismiss='modal'></button>
          </div>
          <div className='modal-body loginblk'>
            <p className='text-center' style={{ marginBottom: 0 }}>
              Are you sure you want to{' '}
              {data?.is_member_user === 1 ? 'leave this organization?' : 'cancel this request?'}
            </p>

            <form
              id='login_form'
              name='login_form'
              className='reset_password'
              method='post'
              style={{ display: 'flex', justifyContent: 'space-between' }}
            >
              <input
                className='resetbtn'
                type='button'
                value={data?.is_member_user === 1 ? 'Cancel' : 'No'}
                data-bs-dismiss='modal'
                style={{ marginRight: '1rem' }}
              />
              <input
                className='resetbtn'
                type='button'
                value={data?.is_member_user === 1 ? 'Leave' : 'Yes'}
                onClick={submitMember}
                style={{ marginLeft: '1rem' }}
              />
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default reduxForm({
  form: 'confirm_leave_org',
})(ConfirmLeaveOrgPopupModal)
