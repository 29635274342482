import React, { Component } from 'react'
import AppLayout from '../AppLayout'
import { reduxForm } from 'redux-form'
// import ReactSelect from "../../services/ReactSelect";
import * as api from '../../services/businessService'
// import * as validation from "../../services/formValidation";
// import renderInputField from "../Form/Input";
import { Redirect } from 'react-router-dom'
import * as route from '../../services/routeConst'
import { Link } from 'react-router-dom'
import AddBusinessOrOrganizationOwnerLogoutPopup from '../AddBusinessOrOrganizationOwnerLogoutPopup'
import $J from 'jquery'
import { getUserLabels } from '../../services/customFunction' // #914 Distinct between Customer and Owner SignUp

class AddBusinessOrOrganizationSearch extends Component {
  constructor(props) {
    super(props)
    this.state = {
      countryList: [],
      stateList: [],
      cityList: [],
      searchBusiness: [],
      data: {},
      data1: {},
      user: {},
      signUpFlag: this.props.location.state ? this.props.location.state.signUpFlag : '',
      isProcessing: true,
    }
  }

  submitHandler = () => {
    this.setState(
      {
        data: {
          business_organization_name: this.props.location.state
            ? this.props.location.state.form1.name
            : '',
          country_id: this.props.location.state ? this.props.location.state.form1.country_id : '',
          state_id: this.props.location.state ? this.props.location.state.form1.state_id : '',
          city_id: this.props.location.state ? this.props.location.state.form1.city_id : '',
          address_line_1: this.props.location.state
            ? this.props.location.state.form1.address_line_1
            : '',
          address_line_2: this.props.location.state
            ? this.props.location.state.form1.address_line_2
            : '',
          phone_no_1: this.props.location.state ? this.props.location.state.form1.phone_no_1 : '',
          website_1: this.props.location.state ? this.props.location.state.form1.website_1 : '',
          zipcode: this.props.location.state ? this.props.location.state.form1.zipcode : '',
          user_type: this.props.location.state ? this.props.location.state.form1.user_type : '',
          area: this.props.location.state ? this.props.location.state.form1.area : '',
        },
      },
      this.redirectToCategory,
    )
    this.setState({ isRedirect: true })
  }
  redirectToCategory = () => {
    if (!this.props.location.state.signUpFlag) {
      this.props.history.push({
        pathname: route.ADD_BUSINESS_OR_ORGANIZATION_CATEGORY_FOOTER_ROUTE,
        state: {
          user_type: this.props.location.state.user_type,
          form1: this.props.location.state.form1,
          imatrix_id: this.props.location.state.imatrix_id,
          form_data: this.state.data,
        },
      })
    }
    if (
      this.props.location.state.signUpFlag &&
      this.props.location.state.user_type === 'Business'
    ) {
      $J('#add_business_owner_logout_popup').modal('show')
    }
    if (
      this.props.location.state.signUpFlag &&
      this.props.location.state.user_type === 'Organization'
    ) {
      $J('#add_business_owner_logout_popup').modal('show')
    }
  }
  componentDidMount() {
    let data = {
      business_organization_name: this.props.location.state
        ? this.props.location.state.form1.name
        : '',
      country_id: this.props.location.state ? this.props.location.state.form1.country_id : '',
      state_id: this.props.location.state ? this.props.location.state.form1.state_id : '',
      city_id: this.props.location.state ? this.props.location.state.form1.city_id : '',
      address_line_1: this.props.location.state
        ? this.props.location.state.form1.address_line_1
        : '',
      address_line_2: this.props.location.state
        ? this.props.location.state.form1.address_line_2
        : '',
      phone_no_1: this.props.location.state ? this.props.location.state.form1.phone_no_1 : '',
      website_1: this.props.location.state ? this.props.location.state.form1.website_1 : '',
      zipcode: this.props.location.state ? this.props.location.state.form1.zipcode : '',
      user_type: this.props.location.state ? this.props.location.state.form1.user_type : '',
      city_name: this.props.location.state ? this.props.location.state.city_name : '',
      area: this.props.location.state ? this.props.location.state.form1.area : '',
    }

    this.setState({ data: data })
    let self = this
    let stateCopy = { ...this.state }
    api.findBusinessOrOrganization(data).then(function (response) {
      self.setState({ isProcessing: false })
      if (response.status === 200) {
        response.data.data.map((result) => {
          let objContry = result
          stateCopy['searchBusiness'].push(objContry)
        })
        self.setState({ ...stateCopy, isProcessing: false })
      }
    })
  }

  claimBusinessOrOrganization = (data) => (event) => {
    this.setState({ user: data })
    this.props.history.push({
      pathname: route.CLAIM_BUSINESS_PROFILE_ROUTE,
      state: {
        user: data,
      },
    })
  }

  render() {
    if (!this.props.location.state) {
      return (
        <Redirect
          to={{
            pathname: route.ROOT_ROUTE,
          }}
        />
      )
    }
    //# 913 & 914
    const userTypeLabels = getUserLabels(
      this.props.location.state.form1.add_business_or_organization,
    )
    return (
      <AppLayout isDisplayFooter={false}>
        <div className='container it-before-login'>
          <div className='claim_steps loginblk signupblk'>
            <h3 className='add_title'>
              <span className='other_txt'>
                Add {this.props.location.state.user_type} as {userTypeLabels.type} - Step-3
              </span>
              <span className='other_txt d-block'>Search Results</span>
            </h3>
            <p className='pb-15'>{userTypeLabels.text}</p>
            {!this.state.isProcessing ? (
              <div className='add_bottom'>
                <div className='text-center'>
                  <a href={null} className='loginbtn' onClick={this.submitHandler}>
                    Can't find it? Click here to continue
                  </a>
                </div>
              </div>
            ) : null}
            <div className='text-center pt-5 pb-5 it-list-data'>
              {this.state.searchBusiness.length > 0 ? (
                this.state.searchBusiness.map((user, index) => (
                  <div className='claim_steps it-busi-search'>
                    <div className='claim_frnd_detail_box'>
                      <div className=' it-busi-search-left'>
                        <div className='image_blk'>
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              (user.profile_picture_medium != null
                                ? user.profile_picture_medium
                                : '/img/default_profile.png')
                            }
                            alt='food'
                          />
                        </div>
                        <div className='claim_frnd_right'>
                          <Link to={process.env.PUBLIC_URL + '/' + user.slug}>
                            <h5 className='varela'>{user.business_organization_name}</h5>
                          </Link>
                          <p className='address'>
                            {user.city_name != null && user.city_name + ', '}
                            {user.state_short_name != null
                              ? user.state_short_name + ' '
                              : user.state_name != null && user.state_name + ' '}
                            {user.zipcode != null && user.zipcode}
                          </p>
                        </div>
                      </div>
                      <div>
                        {this.state.signUpFlag
                          ? user.user_type !== 'individual' &&
                            user.is_business_claimed === '0' && (
                              <div className='bg-white curvey  add_bottom'>
                                <a
                                  href={null}
                                  className='loginbtn'
                                  onClick={this.claimBusinessOrOrganization(user)}
                                >
                                  Claim this {user.user_type}
                                </a>
                              </div>
                            )
                          : ''}
                      </div>
                    </div>
                  </div>
                ))
              ) : this.state.isProcessing ? (
                <div className='search-section'>
                  <div className='no_search_result'>
                    <div className='search-page-loading-icon'>
                      <small className='fade-in-text'>
                        Searching for{' '}
                        {this.props.location.state.user_type === 'Business'
                          ? 'businesses'
                          : 'organizations'}
                      </small>
                      <i />{' '}
                    </div>
                  </div>
                </div>
              ) : (
                'No records found'
              )}

              {/* No records found */}
            </div>

            {!this.state.isProcessing ? (
              <div className='add_bottom'>
                <div className='text-center'>
                  <a href={null} className='loginbtn' onClick={this.submitHandler}>
                    Can't find it? Click here to continue
                  </a>
                </div>
              </div>
            ) : null}
          </div>

          <AddBusinessOrOrganizationOwnerLogoutPopup {...this.props} form_data={this.state.data} />
        </div>
      </AppLayout>
    )
  }
}

AddBusinessOrOrganizationSearch = reduxForm({
  form: 'AddBusinessOrOrganizationSearch',
})(AddBusinessOrOrganizationSearch)
export default AddBusinessOrOrganizationSearch
// export default AddBusinessOrOrganizationNameAndAddress;
