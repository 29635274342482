import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { DEFAULT_PROFILE_PICTURE } from '../../../services/const'
import { getFriendProfile } from '../../../services/customFunction'

class FriendsListDetail extends Component {
  render() {
    const { user } = this.props
    //  console.log("FriendsListDetail", user);

    let userTypeColor = ''
    let trustdImgUrl = null
    let friendImgUrl = null
    if (user.user_type === 'individual') {
      userTypeColor = 'green_border'
      trustdImgUrl = process.env.PUBLIC_URL + '/img/purple_tag.png'
      friendImgUrl = process.env.PUBLIC_URL + '/img/frandss.png'
    } else if (user.user_type === 'business') {
      userTypeColor = 'blue_border'
      trustdImgUrl = process.env.PUBLIC_URL + '/img/orange_tag.png'
      friendImgUrl = process.env.PUBLIC_URL + '/img/frandss.png'
    } else if (user.user_type === 'organization') {
      userTypeColor = 'orange_border'
      trustdImgUrl = process.env.PUBLIC_URL + '/img/orange_tag.png'
      friendImgUrl = process.env.PUBLIC_URL + '/img/frandss.png'
    }

    let nameToDisplay =
      user.user_type === 'individual' ? user.fullname : user.business_organization_name

    return (
      <div className='col-md-6'>
        <h6 className='varela it-frd-name-height'>
          <Link className='mt-auto' to={process.env.PUBLIC_URL + '/' + user.slug}>
            {/* {user.fullname} */}
            {nameToDisplay}
          </Link>
        </h6>
        <div className={'frnd_box ' + user.user_type + '_profile'}>
          <div className={'img_block ' + userTypeColor}>
            <Link className='' to={process.env.PUBLIC_URL + '/' + user.slug}>
              <img
                src={
                  user.profile_picture_small ? user.profile_picture_small : DEFAULT_PROFILE_PICTURE
                }
                alt=''
              />
            </Link>
          </div>
          {/*924 - modify to table so we can control the sze of the itens being from 0 to 9999
          width of the table is 50%, the other 50 is picture
          width of the icon is 20% so the 80% is th numer (max 9999)*/}
          {getFriendProfile(user, trustdImgUrl, friendImgUrl)}
        </div>
      </div>
    )
  }
}

export default FriendsListDetail
