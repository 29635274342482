import React from 'react'
import { Link } from 'react-router-dom'
import { DEFAULT_PROFILE_PICTURE } from '../../../services/const'
import { PROFILE_PICTURE_SMALL_URL_PATH } from '../../../services/environment'
import parse from 'html-react-parser'

const SearchSuggestionUser = ({ isChatUser, userData, user, addressToDisplay }) => {
  const fullname = ['business', 'organization'].includes(userData?.user_type)
    ? userData.business_organization_name
    : userData.name
  const content = (
    <>
      <div className='i_photu'>
        <img
          className='imageTag'
          alt=''
          onError={(e) => (e.target.src = DEFAULT_PROFILE_PICTURE)}
          src={
            userData?.image !== '' && userData.image
              ? PROFILE_PICTURE_SMALL_URL_PATH + userData.image
              : DEFAULT_PROFILE_PICTURE
          }
        />
      </div>
      <div className='i_info'>
        <div className='i_name varela purple'>
          {parse(user?.highlight?.name?.[0] ?? fullname ?? '')}
        </div>
        <div className='i_address'>
          {userData.user_type !== 'individual' ? addressToDisplay : 'Click to view profile'}
        </div>
      </div>
    </>
  )

  const profileUrl = `${process.env.PUBLIC_URL}/${userData.slug}`
  return isChatUser ? (
    <div className='i_suggetion_link cursor-pointer' name='user-suggestion'>
      {content}
    </div>
  ) : (
    <Link to={profileUrl} className='i_suggetion_link' onClick={() => {}} name='user-suggestion'>
      {content}
    </Link>
  )
}

export default SearchSuggestionUser
