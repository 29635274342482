import 'cropperjs/dist/cropper.css'
import './PictureDialog.scss'
import React, { useState, useEffect, useRef } from 'react'

import { DefaultPlayer as Video } from 'react-html5video'
import 'react-html5video/dist/styles.css'
import ImageGallery from 'react-image-gallery'
import Post from '../Post'

const PictureDialog = ({ fileIndex, post, user, deletePost, onCloseButtonClick }) => {
  const [postAttachments, setPostAttachments] = useState([])
  const [currentIndex, setCurrentIndex] = useState(fileIndex)
  const _imageGallery = useRef(null)
  const isVideo = post?.attachments[0]?.file_type?.includes('video')

  const _onSlide = (index) => {
    setCurrentIndex(index)
  }

  useEffect(() => {
    handleGalleryChange()
  }, [])

  const handleGalleryChange = () => {
    let finalFiles = post?.attachments?.map((file, index) => {
      /* if (file?.type) {*/
      let fileObject = {
        file,
        fileType: file?.file_type,
        original: file.imgSrc,
        thumbnail: file.imgSrc_thumb,
        id: index,
      }
      return fileObject
    })

    if (finalFiles?.length > 0) {
      setPostAttachments(finalFiles)
    }
  }

  return (
    <div className='postDialogs'>
      {' '}
      <div
        className='modal modal-fullscreen overlay-top-layer createpostmodal addphotovideomodal'
        id='wall_picture_dialog_modal'
        role='dialog'
      >
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header'>
              <button type='button' className='btn-close' onClick={onCloseButtonClick}></button>
            </div>
            <div className={`modal-body d-flex flex-column`}>
              <div className='modalTop'>
                {postAttachments?.length > 0 ? (
                  isVideo ? (
                    <div className='videoGallery'>
                      <Video
                        controls={['PlayPause', 'Seek', 'Time', 'Volume', 'Fullscreen']}
                        //poster={files[0].vidSrc_thumb}
                        onCanPlayThrough={() => {
                          // Do stuff
                        }}
                        muted={true}
                      >
                        <source src={postAttachments[0]?.original} type='video/mp4' />
                      </Video>
                    </div>
                  ) : (
                    <ImageGallery
                      ref={_imageGallery}
                      items={postAttachments}
                      lazyLoad={false}
                      infinite={true}
                      //onClick={_onImageClick}
                      onSlide={_onSlide}
                      //onPause={_onPause}
                      //onImageLoad={_onImageLoad}
                      additionalClass='app-image-postAttachment'
                      useWindowKeyDown={true}
                      slideOnThumbnailOver={true}
                      showBullets={false}
                      showFullscreenButton={false}
                      showPlayButton={false}
                      showThumbnails={false}
                      showIndex={false}
                      showNav={true}
                      isRTL={false}
                      startIndex={currentIndex}
                    />
                    /*                   <div className='postAttachmentGallery'>

                  </div> */
                  )
                ) : (
                  ''
                )}
              </div>
              <div className='modalBottom'>
                <Post
                  key={post?.id}
                  post={post}
                  user={user}
                  deleteDBPost={deletePost}
                  showActionsMenu={false}
                  ignoreOverFlow={true}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PictureDialog
