import React, { Component, Fragment } from 'react'
// import { PROFILE_TRUST_DIRECTORY_TITLE } from "../../../services/titleConst";
import * as memberApiService from '../../../services/memberApiService'
import * as profileSearchService from '../../../services/profileSearchService'
import { LIST_VIEW_ITEMS_PER_PAGE, LIST_VIEW_PAGE_RANGE, SEARCH_AMT } from '../../../services/const'
import { getUser } from '../../../services/customFunction'
import Pagination from 'react-js-pagination'
import UserListDetail from '../../CommonComponents/UserListDetail/UserListDetail'
import TrustedUserList from '../../CommonComponents/TrustedUserList/TrustedUserList'
import $ from 'jquery'
import { formatMemberDirectoryName, formatMembersName } from '../../../services/formatService'

class MembersTab extends Component {
  constructor(props) {
    super(props)

    this.state = {
      login_user_id: getUser().id,

      user_id: this.props.user_id,
      viewType: 'full_view', // full_view | list_view
      userType: '', // individual, business, organization
      activePage: 1,
      totalPages: 0,
      totalRecords: 0,
      memberList: [],
      memberIDs: [],
      searchString: '',

      overall: {
        total_records: 0,
        total_organizations: 0,
        total_businesses: 0,
      },
      search_total: {
        total_records: 0,
        total_organizations: 0,
        total_businesses: 0,
      },

      isTrustedUserModalShow: false,
      trusteUserData: {
        type: '',
        user_type: '',
        id: 0,
        total_common_members: 0,
      },
      isSettingToShowMemberTab: false,
      isSearching: true,
      hasMoreData: false,
      noSearchResults: false,
      loadingMoreData: false,
      endOfPage: SEARCH_AMT,
      total_records: 0,
      updateFunc: null,
      loadingFunc: null,
      cachedListData: {},
      membersName:
        formatMembersName(
          props?.user_settings?.members_name,
          props?.user_settings?.custom_members_name,
        ) ?? 'Members',
      memberDirectoryName:
        formatMemberDirectoryName(
          props?.user_settings?.member_directory_name,
          props?.user_settings?.custom_member_directory_name,
        ) ?? 'Member Directory',
    }

    this.handlePageChange = this.handlePageChange.bind(this)
    this.itrustitHandle = this.itrustitHandle.bind(this)
    this.friendAddRemoveHandle = this.friendAddRemoveHandle.bind(this)
  }

  componentDidMount() {
    this.memberListRender()
  }

  componentWillReceiveProps(nextProps) {
    let user_id_new = nextProps.user_id
    let user_id_old = this.state.user_id

    if (user_id_new !== user_id_old) {
      this.setState({ user_id: user_id_new }, () => {
        this.memberListRender()
      })
    }

    // condition for show hide Member tab depends on user setting
    if (
      nextProps.user_settings.members_view_by === 'Everybody including non-logged in users' ||
      (nextProps.user_settings.members_view_by === 'All logged in users' &&
        this.state.login_user_id) ||
      (nextProps.user_settings.members_view_by === 'Friends and friends of friends' &&
        (nextProps.user_settings.is_friend_user ||
          nextProps.user_settings.is_friend_of_friends_user)) ||
      (nextProps.user_settings.members_view_by === 'Friends' &&
        nextProps.user_settings.is_friend_user) ||
      (nextProps.user_settings.members_view_by === 'Members only' &&
        nextProps.user_settings.is_member_user)
    ) {
      if (!this.state.isSettingToShowMemberTab) {
        this.setState(
          {
            isSettingToShowMemberTab: true,
          },
          () => this.memberListRender(),
        )
      }
    } else {
      if (this.state.isSettingToShowMemberTab) {
        this.setState({
          isSettingToShowMemberTab: false,
        })
      }
    }
  }

  async memberListRender() {
    let memberListReqData = {
      other_user_id: this.state.user_id,

      page: this.state.activePage ? this.state.activePage : 1,

      user_type: this.state.userType,
      searchString: this.state.searchString,
    }

    const self = this
    if (this.state.isSettingToShowMemberTab) {
      const response = await memberApiService.memberListCall(memberListReqData)

      if (typeof response.status !== 'undefined' && response.status === 200) {
        // console.log(response.data.data);
        let apiResponseData = response.data.data

        if (memberListReqData.searchString !== '') {
          const res = await profileSearchService.elasticSearch({
            ids: apiResponseData.ids,
            searchStr: memberListReqData.searchString,
          })
          // console.log(res);
          if (res.length) {
            apiResponseData.ids = res.map((searchedUser) => searchedUser['_id'])
            apiResponseData.total_records = res.length
          } else {
            self.setState({
              totalRecords: 0,
              isSearching: false,
              resetSeach: false,
              noSearchResults: true,
            })
            return
          }
        }

        const currentIDs = apiResponseData.ids.slice(0, SEARCH_AMT)
        let stateData = {
          totalPages: apiResponseData.total_pages,
          totalRecords: apiResponseData.total_records,
          memberIDs: apiResponseData.ids,

          overall: apiResponseData.overall,
          search_total: apiResponseData.search_total,
          total_records: apiResponseData.overall.total_records,
          hasMoreData: apiResponseData.overall.total_records >= 1,
          endOfPage: SEARCH_AMT,
          noSearchResults: apiResponseData.total_records <= 0,
          resetSeach: false,
          // isSearching: false,
        }

        if (self.state.activePage <= 0) {
          stateData['activePage'] = 1
        }

        self.props.profileHeader_member_Handle(apiResponseData.overall.total_records)
        self.setState(stateData, () => {
          if (currentIDs.length) {
            self.searchForMembers(currentIDs)
          } else {
            self.setState({ isSearching: false })
          }
        })
      }
    }
  }

  searchForMembers = (currentIDs) => {
    //console.log(currentIDs);
    const self = this
    if (this.state.resetSearch) return

    const searchID = this.state.searchID
    memberApiService
      .getMemberListFromIDs({
        ids: currentIDs,
      })
      .then(function (response) {
        //console.log(response);
        // console.log(self.state.memberList);
        if (self.state.resetSearch || searchID !== self.state.searchID) return
        self.setState(
          (st) => ({
            memberList: [...st.memberList, ...response.data.data.members],
            hasMoreData: st.endOfPage < st.totalRecords,
            isSearching: false,
            loadingMoreData: false,
          }),
          () =>
            self.state.hasMoreData &&
            !self.state.resetSearch &&
            searchID === self.state.searchID &&
            self.searchAgain(),
        )
      })
  }

  handlePageChange(pageNumber) {
    this.setState(
      {
        activePage: pageNumber,
        isSearching: true,
        memberList: [],
      },
      () => {
        this.memberListRender()
      },
    )
  }

  setStatusOfMemberUserYour = (id, status) => {
    this.setState(
      (st) => {
        let memberList = st.memberList
        memberList.filter((user) => user.id === id)[0]['is_your_member_user'] =
          status === 'pending' ? 2 : status === 'accepted' ? 1 : 0
        return {
          memberList,
        }
      },
      () => {},
    )
  }
  memberAddRemoveHandle = (data) => {
    //this.memberListRender();
    let memberList = this.state.memberList
    memberList.filter((user) => user.id === data.id)[0]['is_member_user'] =
      data.is_member_user === 1 ? 0 : 1

    this.setState({
      memberList: memberList,
    })
  }

  itrustitHandle(data) {
    // console.log(data);
    this.setState(
      (st) => {
        let memberList = st.memberList
        memberList.filter((user) => user.id === data.id)[0]['is_trusted_user'] =
          data.is_trusted_user === 1 ? 0 : 1
        return {
          memberList,
        }
      },
      () => {
        //console.log(this.state.friendList);
        //this.friendListRender();
      },
    )
    // this.props.profileHeader_itrustit_recall_Handle();
  }

  friendAddRemoveHandle(data) {
    this.props.profileHeader_friend_recall_Handle()
    // console.log(data);
    this.setState(
      (st) => {
        let memberList = st.memberList
        memberList.filter((user) => user.id === data.id)[0]['is_friend_user'] =
          data.is_friend_user === 1 ? 0 : 1
        memberList.filter((user) => user.id === data.id)[0]['friendship_id'] =
          data.is_friend_user === 1 ? null : data.friendship_id
        return {
          memberList,
        }
      },
      () => {
        //console.log(this.state.friendList);
        //this.friendListRender();
      },
    )
  }
  /*910 - update the search value on change, so the right value is use when other tabs are clicked*/
  handleMemberSearchChange = (event) => {
    event.preventDefault()
    this.setState({ searchString: event.target.value })
  }

  handleMemberSearchSubmit = (event) => {
    event.preventDefault()
    this.setState(
      {
        searchString: event.target.elements.memberSearchTxt.value,
        isSearching: true,
        resetSearch: true,
        memberList: [],
        searchID: `${event.target.elements.memberSearchTxt.value ?? ''}_${
          this.state.totalRecords ?? 0
        }`,
      },
      () => {
        this.memberListRender()
      },
    )
  }

  onHoverTrustBy = (
    id,
    type,
    user_type,
    total_common_members,
    updateFunc = null,
    loadingFunc = null,
  ) => {
    this.setState((st) => ({
      isTrustedUserModalShow: true,
      trusteUserData: {
        type: type,
        user_type: user_type,
        id: id,
        total_common_members: total_common_members,
        // cache: {
        //   [type]: st.cachedListData[type],
        // },
      },
      updateFunc,
      loadingFunc,
    }))
  }

  setCachedListData = (type, data) => {
    this.setState((st) => ({
      cachedListData: { ...st.cachedListData, [type]: data },
    }))
  }

  changeMemberActiveTab = (data) => (event) => {
    let userType = ''
    if (data === 'member_individual') {
      userType = 'individual'
    } else if (data === 'member_business') {
      userType = 'business'
    } else if (data === 'member_organization') {
      userType = 'organization'
    }
    this.setState(
      {
        isSearching: true,
        memberList: [],
        userType: userType,
      },
      () => {
        this.memberListRender()
      },
    )
  }

  viewTypeChangeHandler = (viewType) => (event) => {
    this.setState(
      {
        viewType: viewType,
      },
      () => {},
    )
  }

  profileHeader_member_tab_click_Handle() {
    // this.setState(
    //   {
    //     isSearching: true,
    //     memberList: [],
    //     //  userType: userType,
    //   },
    //   () => {
    //     this.memberListRender();
    //   }
    // );
  }

  infiniteScroll() {
    if (this.state.hasMoreData) {
      const winScroll = document.body.scrollTop || document.documentElement.scrollTop

      const height = document.documentElement.scrollHeight - document.documentElement.clientHeight

      const scrolled = winScroll / height
      // console.log(scrolled);
      if (scrolled >= 0.7) {
        this.searchAgain()
      }
    }
  }

  searchAgain() {
    this.setState(
      (st) => ({
        hasMoreData: false,
        endOfPage: st.endOfPage + SEARCH_AMT,
        loadingMoreData: true,
      }),
      () => {
        let currentIDs = this.state.memberIDs.slice(
          this.state.endOfPage - SEARCH_AMT,
          this.state.endOfPage,
        )
        this.searchForMembers(currentIDs)
      },
    )
  }

  render() {
    const {
      activePage,
      totalPages,
      totalRecords,
      memberList,
      viewType,
      userType,
      isSearching,
      noSearchResults = false,
    } = this.state
    let listToShow =
      userType === '' ? memberList : memberList.filter((user) => user.user_type === userType)
    listToShow = listToShow.filter(
      (thing, index, self) => self.findIndex((t) => t.id === thing.id) === index,
    )
    let showingFrom = this.props.itemsCountPerPage * (this.state.activePage - 1) + 1
    let showingTo = listToShow.length || showingFrom
    if (showingFrom > totalRecords) {
      showingFrom = totalRecords
    }
    if (showingTo > this.state.totalRecords) {
      showingTo = this.state.totalRecords
    }

    if (totalPages < this.state.activePage && totalPages !== 0) {
      this.setState(
        {
          isSearching: true,
          activePage: totalPages,
        },
        () => {
          this.memberListRender()
        },
      )
    }

    return (
      <div
        id='member'
        className={'tab-pane ' + (this.props.activeTab === '#member' ? 'active' : 'fade')}
      >
        {this.state.isSettingToShowMemberTab ? (
          <div>
            <div className='trust_blk_head'>
              <h4 className='varela'>
                {this.state.memberDirectoryName} ({this.state.search_total.total_records})
              </h4>
              <div className='trust_right'>
                <div>View:</div>
                <ul className='views'>
                  <li onClick={this.viewTypeChangeHandler('list_view')}>
                    <a
                      href={null}
                      className={'' + (this.state.viewType === 'list_view' ? 'active' : '')}
                    >
                      <i className='list-view'></i>List
                    </a>
                  </li>
                  <li onClick={this.viewTypeChangeHandler('full_view')}>
                    <a
                      href={null}
                      className={'' + (this.state.viewType === 'full_view' ? 'active' : '')}
                    >
                      <i className='full-view'></i>Full
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className='bg-white'>
              <div className='friend_list_block purple'>
                <div className='f-left'>
                  <ul className='inner-navs nav nav-tabs'>
                    <li className='nav-item'>
                      <a
                        className='nav-link trust-directory-tabs active'
                        data-bs-toggle='tab'
                        href='#itrust_total'
                        onClick={this.changeMemberActiveTab('member_total')}
                      >
                        <span>
                          {this.state.search_total.total_records}{' '}
                          {this.state.membersName?.toUpperCase()}
                        </span>
                      </a>
                    </li>
                    <li className='nav-item'>
                      <a
                        className='nav-link trust-directory-tabs'
                        data-bs-toggle='tab'
                        href='#itrust_business'
                        onClick={this.changeMemberActiveTab('member_individual')}
                      >
                        <span>{this.state.search_total.total_individuals} PEOPLE</span>
                      </a>
                    </li>
                    <li className='nav-item'>
                      <a
                        className='nav-link trust-directory-tabs'
                        data-bs-toggle='tab'
                        href='#itrust_business'
                        onClick={this.changeMemberActiveTab('member_business')}
                      >
                        <span>{this.state.search_total.total_businesses} BUSINESSES</span>
                      </a>
                    </li>
                    <li className='nav-item'>
                      <a
                        className='nav-link trust-directory-tabs'
                        data-bs-toggle='tab'
                        href='#itrust_organization'
                        onClick={this.changeMemberActiveTab('member_organization')}
                      >
                        <span>{this.state.search_total.total_organizations} ORGANIZATIONS</span>
                      </a>
                    </li>
                  </ul>
                </div>
                <div className='f-right'>
                  <form onSubmit={this.handleMemberSearchSubmit}>
                    <input
                      type='text'
                      name='memberSearchTxt'
                      className='input1 form-control'
                      placeholder='Search for a person,  business, or organization...'
                      /*910 - update the search value on change, so the right value is use when other tabs are clicked*/
                      onChange={this.handleMemberSearchChange}
                    />
                    <input type='submit' className='search' value='' />
                  </form>
                </div>
              </div>
              <div className='i-showing'>
                Showing {showingFrom} - {showingTo} of {this.state.totalRecords}
              </div>
              {/* inner tab start */}
              <div className='inner-tab tab-content it-mobile-space'>
                {totalRecords > 0 && !noSearchResults && !isSearching ? (
                  <Fragment>
                    {viewType === 'full_view' ? (
                      <div className='d-flex blocks'>
                        <div className='col-lg-8 position-relative'>
                          {listToShow.map(
                            (user, index) =>
                              index < this.state.endOfPage && (
                                <UserListDetail
                                  setModalProps={this.props.setModalProps}
                                  setStatusOfMemberUserYour={this.setStatusOfMemberUserYour}
                                  key={user.id}
                                  index={index}
                                  login_user_id={this.state.login_user_id}
                                  confirmitrustitAddRemove={this.props.confirmitrustitAddRemove}
                                  confirmFriendAddRemove={this.props.confirmFriendAddRemove}
                                  loadingFriendStatusChange={this.props.loadingFriendStatusChange}
                                  loadingitrustitStatusChange={
                                    this.props.loadingitrustitStatusChange
                                  }
                                  tab={'member'}
                                  user={user}
                                  viewType={this.state.viewType}
                                  itrustitHandle={this.itrustitHandle}
                                  friendAddRemoveHandle={this.friendAddRemoveHandle}
                                  memberAddRemoveHandle={this.memberAddRemoveHandle}
                                  onHoverTrustBy={this.onHoverTrustBy}
                                  membersName={this.state.membersName}
                                />
                              ),
                          )}
                          <TrustedUserList
                            isBlockShowSetFalse={(e) => {
                              this.setState({ isTrustedUserModalShow: false })
                            }}
                            isBlockShow={this.state.isTrustedUserModalShow}
                            trusteUserData={this.state.trusteUserData}
                            updateFunc={this.state.updateFunc}
                            setCachedListData={this.setCachedListData}
                            loadingFunc={this.state.loadingFunc}
                          ></TrustedUserList>
                        </div>
                      </div>
                    ) : (
                      <div className='row frnd_1'>
                        {listToShow.map(
                          (user, index) =>
                            index < this.state.endOfPage && (
                              <UserListDetail
                                setModalProps={this.props.setModalProps}
                                setStatusOfMemberUserYour={this.setStatusOfMemberUserYour}
                                key={user.id}
                                index={index}
                                login_user_id={this.state.login_user_id}
                                confirmitrustitAddRemove={this.props.confirmitrustitAddRemove}
                                confirmFriendAddRemove={this.props.confirmFriendAddRemove}
                                loadingFriendStatusChange={this.props.loadingFriendStatusChange}
                                loadingitrustitStatusChange={this.props.loadingitrustitStatusChange}
                                tab={'member'}
                                user={user}
                                viewType={this.state.viewType}
                                itrustitHandle={this.itrustitHandle}
                                friendAddRemoveHandle={this.friendAddRemoveHandle}
                                memberAddRemoveHandle={this.memberAddRemoveHandle}
                                onHoverTrustBy={this.onHoverTrustBy}
                                membersName={this.state.membersName}
                              />
                            ),
                        )}
                        <TrustedUserList
                          isBlockShowSetFalse={(e) => {
                            this.setState({ isTrustedUserModalShow: false })
                          }}
                          isBlockShow={this.state.isTrustedUserModalShow}
                          trusteUserData={this.state.trusteUserData}
                          updateFunc={this.state.updateFunc}
                          loadingFunc={this.state.loadingFunc}
                        ></TrustedUserList>
                      </div>
                    )}
                    {this.state.loadingMoreData ? (
                      <div className='no_search_result'>
                        <div className='search-page-loading-icon'>
                          {' '}
                          <i />{' '}
                        </div>
                      </div>
                    ) : (
                      !this.state.hasMoreData && (
                        <div
                          onClick={() => {
                            window.scrollTo({
                              top: $('#friend').offset().top - 100,
                              behavior: 'smooth',
                            })
                          }}
                          className='no_search_result back-to-top text-muted'
                        >
                          Back to Top
                        </div>
                      )
                    )}
                    {/* <div>
                      <Pagination
                        activePage={activePage}
                        itemsCountPerPage={this.props.itemsCountPerPage}
                        totalItemsCount={totalRecords}
                        pageRangeDisplayed={this.props.pageRangeDisplayed}
                        onChange={this.handlePageChange}
                      />
                    </div> */}
                  </Fragment>
                ) : noSearchResults || !isSearching ? (
                  <div className='search-section'>
                    <div className='no_search_result'>
                      <img
                        src={process.env.PUBLIC_URL + '/img/trust-directory-empty.png'}
                        className='pt-5 pb-5 m-auto d-block'
                        alt=''
                      />
                    </div>
                  </div>
                ) : (
                  <div className='search-section'>
                    <div className='no_search_result'>
                      <div className='search-page-loading-icon'>
                        {' '}
                        <i />{' '}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className='it-no-access'>
            <h4 className='varela'>{this.state.memberDirectoryName}</h4>
            <div className='bg-white'>
              <div className=' inner-tab tab-content'>
                <div className='search-section friend_list_block'>
                  <div className='no_search_result'>
                    <img src='/img/hide-frd.svg' className='pt-5 m-auto d-block' alt='' />
                    <h2>You Don't Have Access</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    )
  }
}

MembersTab.defaultProps = {
  itemsCountPerPage: LIST_VIEW_ITEMS_PER_PAGE,
  pageRangeDisplayed: LIST_VIEW_PAGE_RANGE,
}

export default MembersTab
