import React from 'react'

const PrizeItem = ({
  prizeType,
  rewardType,
  img = process.env.PUBLIC_URL + '/img/iti_bug.png',
  text,
  reward,
  isCompleted,
  sponsorUserSlug,
  onClick = () => {},
}) => {
  const rType = isCompleted ? 'check' : rewardType
  const content = (
    <>
      <div
        className={`promotion-prize-img promotion-prize-img-${prizeType} me-2`}
        style={{ maxWidth: 30, minWidth: 30, maxHeight: 30, minHeight: 30 }}
      >
        <img src={img} width={24} alt='' />
      </div>
      <small
        className='me-1'
        style={{
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          fontSize: 12,
        }}
      >
        {text}
      </small>
      {rType ? (
        <div
          className={`promotion-prize-reward-icon promotion-prize-reward-icon-${rType} mont fw-bold p-1 d-flex align-items-center justify-content-center ms-auto me-1`}
        >
          {rType === 'check' ? <i className='fas fa-check' aria-hidden='true'></i> : reward}
        </div>
      ) : null}
    </>
  )

  const wrapperProps = {
    onClick,
    className: `d-flex align-items-center promotion-prize-item p-1${
      isCompleted ? ' completed' : ''
    }`,
  }

  return !!sponsorUserSlug ? (
    <a {...wrapperProps} href={'/' + sponsorUserSlug} target='_blank' rel='noopener noreferrer'>
      {content}
    </a>
  ) : (
    <div {...wrapperProps}>{content}</div>
  )
}

export default PrizeItem
