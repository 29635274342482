import React, { Component, Fragment } from 'react'
import './CompanyDetails.scss'
import { getProfileOverviewUserInfo } from '../../../../services/userApiService'
import MapWithAMarker from '../../../Map'
import {
  DEFAULT_LATITUDE,
  DEFAULT_LONGITUDE,
  GOOGLE_MAP_SEARCH_URL,
} from '../../../../services/const'
import { Link } from 'react-router-dom'
import * as route from '../../../../services/routeConst'
import PropTypes from 'prop-types'
import { toTitleCase } from '../../../../services/formatService'
import axios from 'axios'
import { customLogger } from '../../../../utils/CustomLogger'
import { GOOGLE_GEOCODING_API_KEY } from '../../../../services/environment'
import WhatsAppNumberModal, {
  showWhatsAppNumberModal,
} from '../../../CommonComponents/WhatsAppNumberModal/WhatsAppNumberModal'
import { UserSiteLink } from '../../../Profile/OverviewTab/CompanyDetails/CompanyDetails'

class CompanyDetails extends Component {
  constructor(props) {
    super(props)
    this.state = {
      user_id: this.props.user_id,
      user_details: null,
      markers: [],
    }
  }

  componentDidMount() {
    this.getProfileOverviewUserInfo()
  }

  componentWillReceiveProps(nextProps) {
    let user_id_new = nextProps.user_id
    let user_id_old = this.state.user_id

    if (user_id_new !== user_id_old) {
      this.setState({ user_id: user_id_new }, () => {
        this.getProfileOverviewUserInfo()
      })
    }
  }

  profileHeader_overview_tab_click_Handle() {
    this.getProfileOverviewUserInfo()
  }

  async getGeometryFromSearchAddress(searchAddress) {
    let GEOCODING_API_URL = `https://maps.googleapis.com/maps/api/geocode/json?address=${searchAddress}&key=${GOOGLE_GEOCODING_API_KEY}`
    try {
      const res = await axios.get(GEOCODING_API_URL)
      return (
        res.data.results &&
        res.data.results[0] &&
        res.data.results[0].geometry &&
        res.data.results[0]
      )
    } catch (error) {
      customLogger(error)
    }
  }

  getProfileOverviewUserInfo() {
    const self = this
    let getProfileOverviewUserInfoReqData = {
      other_user_id: this.state.user_id,
    }
    getProfileOverviewUserInfo(getProfileOverviewUserInfoReqData).then(async function (response) {
      //console.log("response", response);

      if (typeof response.status !== 'undefined' && response.status === 200) {
        let { user_details } = response.data.data
        let lat = user_details.latitude
        let lng = user_details.longitude
        if (
          (lat === null || lat === '' || lng === null || lng === '') &&
          user_details.full_address &&
          user_details.full_address.trim() !== ''
        ) {
          const full_address = user_details.full_address
          const google_map_full_address = full_address
          const locationDetails = await self.getGeometryFromSearchAddress(google_map_full_address)
          if (locationDetails && locationDetails.geometry && locationDetails.geometry.location) {
            lat = locationDetails.geometry.location.lat
            lng = locationDetails.geometry.location.lng
          }
        }
        let markerObj = {
          latitude: lat != null ? parseFloat(lat) : DEFAULT_LATITUDE,
          longitude: lng != null ? parseFloat(lng) : DEFAULT_LONGITUDE,
          isOpen: false,
          userDetail: user_details,
          id: user_details.id,
        }
        console.log(markerObj)
        self.setState({
          user_details: user_details,
          markers: [markerObj],
        })
      }
    })
  }

  addressClickHandle = (google_map_full_address) => (event) => {
    if (google_map_full_address) {
      window.open(GOOGLE_MAP_SEARCH_URL + google_map_full_address)
    }
  }

  render() {
    const { user_details, markers } = this.state
    if (!user_details) {
      return null
    }

    let city_name = 'N/A'
    if (user_details.city_name) {
      city_name = user_details.city_name
    }

    let full_address = 'N/A'
    let google_map_full_address = null
    if (user_details.full_address && user_details.full_address.trim() !== '') {
      full_address = user_details.full_address
      google_map_full_address = full_address
    }

    let about_me = 'N/A'
    if (user_details.about_me) {
      about_me = user_details.about_me
    }

    let categoriesSection = 'N/A'

    // console.log("user_details.categories", user_details.categories);

    if (
      user_details.user_type !== 'individual' &&
      typeof user_details.categories !== 'undefined' &&
      user_details.categories.length > 0
    ) {
      categoriesSection = (
        <Fragment>
          <Link
            className='category-name-item'
            to={{
              pathname: route.SEARCH_ROUTE,
              state: {
                category_id: user_details.categories[0].search_id,
                category_name: user_details.categories[0].category_name,
              },
            }}
          >
            {user_details.categories[0].category_name}
          </Link>

          {user_details.categories.length > 1 && (
            <Fragment>
              <span>...</span> and
              <div className='dropdown show it-more-link'>
                <a href=''> {user_details.categories.length - 1} more</a>
                <div className='dropdown-menu show' aria-labelledby='dropdownMenuButton'>
                  {user_details.categories.map(
                    (category, id) =>
                      id !== 0 && (
                        <Link
                          key={id}
                          className='category-name-item'
                          to={{
                            pathname: route.SEARCH_ROUTE,
                            state: {
                              category_id: category.search_id,
                              category_name: category.category_name,
                            },
                          }}
                        >
                          {category.category_name}
                        </Link>
                      ),
                  )}
                </div>
              </div>
            </Fragment>
          )}
        </Fragment>
      )
    } else if (
      user_details.user_type !== 'individual' &&
      typeof user_details.categories !== 'undefined' &&
      user_details.category_name &&
      user_details.category_name !== ''
    ) {
      categoriesSection = <span>{toTitleCase(user_details.category_name, true)}</span>
    }

    return (
      <Fragment>
        <WhatsAppNumberModal whatsapp={user_details?.whatsapp} />
        <div className='row contact_blk it-ie-content-detail'>
          <div className='col-md-7 contact_content'>
            <div className=''>
              <h4 className='varela'>
                {/* {user_details.user_type === "individual" ? "About Me" : "Company Details"} */}
                Contact Details
              </h4>
              <a
                href='javascript:void(0);'
                className='contact'
                onClick={this.addressClickHandle(google_map_full_address)}
              >
                {user_details.user_type !== 'individual' &&
                  user_details.address_line_1 != null &&
                  user_details.address_line_1 !== '' &&
                  this.props.user_settings.show_street_address === '1' && (
                    <Fragment>
                      {user_details.address_line_1}
                      <br />
                    </Fragment>
                  )}
                {user_details.user_type !== 'individual' &&
                  user_details.address_line_2 != null &&
                  user_details.address_line_2 !== '' &&
                  this.props.user_settings.show_street_address === '1' && (
                    <Fragment>
                      {user_details.address_line_2}
                      <br />
                    </Fragment>
                  )}
                {user_details.user_type !== 'individual' ? (
                  <Fragment>
                    {user_details.city_name != null &&
                    user_details.city_name !== '' &&
                    this.props.user_settings.show_city_state === '1'
                      ? user_details.city_name + ', '
                      : ''}
                    {user_details.state_short_name != null &&
                    this.props.user_settings.show_city_state === '1'
                      ? user_details.state_short_name + ', '
                      : user_details.state_name != null &&
                        this.props.user_settings.show_city_state === '1' &&
                        user_details.state_name + ', '}
                    {user_details.zipcode != null &&
                      this.props.user_settings.show_zipcode === '1' &&
                      user_details.zipcode + ' '}
                    <br />
                    {user_details.country_name != null &&
                    user_details.country_name !== '' &&
                    this.props.user_settings.show_city_state === '1'
                      ? user_details.country_name + ' '
                      : ''}
                  </Fragment>
                ) : (
                  ''
                )}
              </a>
              {this.props.user_settings.show_phone === '1' && (
                <div className='phone'>
                  {user_details.phone_no_1 && (
                    <a href={'tel:' + user_details.phone_no_1}>
                      {user_details.phone_no_1} (Phone Day)
                    </a>
                  )}
                  {user_details.phone_no_2 && (
                    <a href={'tel:' + user_details.phone_no_2}>
                      {user_details.phone_no_2} (Phone Evening)
                    </a>
                  )}
                  {user_details.mobile_no && (
                    <a href={'tel:' + user_details.mobile_no}>{user_details.mobile_no} (Mobile)</a>
                  )}
                </div>
              )}
              <div className='fax'>
                {user_details.fax_no && (
                  <a target='_blank' rel='noopener noreferrer' href={'fax:' + user_details.fax_no}>
                    {user_details.fax_no}
                  </a>
                )}
              </div>
              {this.props.user_settings.show_email === '1' && (
                <div className='email'>
                  {user_details.user_type !== 'individual' && user_details.email && (
                    <a
                      target='_blank'
                      rel='noopener noreferrer'
                      href={'mailto:' + user_details.email}
                    >
                      {user_details.email}
                    </a>
                  )}
                  {user_details.user_type !== 'individual' && user_details.email_2 && (
                    <a
                      target='_blank'
                      rel='noopener noreferrer'
                      href={'mailto:' + user_details.email_2}
                    >
                      {user_details.email_2}
                    </a>
                  )}
                  {user_details.user_type !== 'individual' && user_details.email_3 && (
                    <a
                      target='_blank'
                      rel='noopener noreferrer'
                      href={'mailto:' + user_details.email_3}
                    >
                      {user_details.email_3}
                    </a>
                  )}
                  {user_details.user_type !== 'individual' && user_details.email_4 && (
                    <a
                      target='_blank'
                      rel='noopener noreferrer'
                      href={'mailto:' + user_details.email_4}
                    >
                      {user_details.email_4}
                    </a>
                  )}
                </div>
              )}
              <div className='site'>
                <UserSiteLink url={user_details.website_1} />
                <UserSiteLink url={user_details.website_2} />
                <UserSiteLink url={user_details.website_3} />
                <UserSiteLink url={user_details.website_4} />
              </div>
              <div className='socials'>
                {user_details.facebook && (
                  <a
                    className='profile-page-social-icon-wrapper'
                    target='_blank'
                    rel='noopener noreferrer'
                    href={user_details.facebook}
                  >
                    <i aria-hidden='true' className='profile-page-social-icon fab fa-facebook'></i>
                  </a>
                )}
                {user_details.fiverr && (
                  <a
                    className='profile-page-social-icon-wrapper'
                    target='_blank'
                    rel='noopener noreferrer'
                    href={user_details.fiverr}
                  >
                    <img
                      className={`profile-page-social-icon`}
                      src={process.env.PUBLIC_URL + '/img/fiverr-grey.svg'}
                      alt=''
                    />
                  </a>
                )}
                {user_details.instagram && (
                  <a
                    className='profile-page-social-icon-wrapper'
                    target='_blank'
                    rel='noopener noreferrer'
                    href={user_details.instagram}
                  >
                    <i aria-hidden='true' className='profile-page-social-icon fab fa-instagram'></i>
                  </a>
                )}
                {user_details.linkedin && (
                  <a
                    className='profile-page-social-icon-wrapper'
                    target='_blank'
                    rel='noopener noreferrer'
                    href={user_details.linkedin}
                  >
                    <i
                      aria-hidden='true'
                      className='profile-page-social-icon fab fa-linkedin-in'
                    ></i>
                  </a>
                )}
                {user_details.pinterest && (
                  <a
                    className='profile-page-social-icon-wrapper'
                    target='_blank'
                    rel='noopener noreferrer'
                    href={user_details.pinterest}
                  >
                    <i aria-hidden='true' className='profile-page-social-icon fab fa-pinterest'></i>
                  </a>
                )}
                {user_details.tiktok && (
                  <a
                    className='profile-page-social-icon-wrapper'
                    target='_blank'
                    rel='noopener noreferrer'
                    href={user_details.tiktok}
                  >
                    <i aria-hidden='true' className='profile-page-social-icon fab fa-tiktok'></i>
                  </a>
                )}
                {user_details.twitter && (
                  <a
                    className='profile-page-social-icon-wrapper'
                    target='_blank'
                    rel='noopener noreferrer'
                    href={user_details.twitter}
                  >
                    <i aria-hidden='true' className='profile-page-social-icon fab fa-twitter'></i>
                  </a>
                )}
                {user_details.youtube && (
                  <a
                    className='profile-page-social-icon-wrapper'
                    target='_blank'
                    rel='noopener noreferrer'
                    href={user_details.youtube}
                  >
                    <i
                      aria-hidden='true'
                      className='profile-page-social-icon fab fa-youtube'
                      style={{
                        fontSize: '1em',
                        width: '1.25em',
                        height: '1.25em',
                        lineHeight: 1.25,
                      }}
                    ></i>
                  </a>
                )}
                {user_details.whatsapp && (
                  <button
                    className='btn profile-page-social-icon-wrapper'
                    onClick={showWhatsAppNumberModal}
                  >
                    <i aria-hidden='true' className='profile-page-social-icon fab fa-whatsapp'></i>
                  </button>
                )}
                {user_details.paypal && (
                  <a
                    className='profile-page-social-icon-wrapper'
                    target='_blank'
                    rel='noopener noreferrer'
                    href={user_details.paypal}
                  >
                    <i aria-hidden='true' className='profile-page-social-icon fab fa-paypal'></i>
                  </a>
                )}
                {user_details.venmo && (
                  <a
                    className='profile-page-social-icon-wrapper'
                    target='_blank'
                    rel='noopener noreferrer'
                    href={user_details.venmo}
                    style={{ padding: '0.4em' }}
                  >
                    <img
                      className={`profile-page-social-icon`}
                      src={process.env.PUBLIC_URL + '/img/venmo-icon-grey.svg'}
                      alt=''
                      style={{ width: '0.8em', height: '0.8em' }}
                    />
                  </a>
                )}
              </div>
            </div>
            <div className='contact_cat mb-3'>
              {categoriesSection && user_details.user_type !== 'individual' && (
                <div className='d-flex'>
                  <strong>Categories</strong>
                  &nbsp; {categoriesSection}
                </div>
              )}
              {user_details.user_type !== 'individual' ? (
                <p>
                  <strong>Cities</strong>
                  &nbsp; {city_name}
                </p>
              ) : (
                ''
              )}
            </div>
          </div>
          <div className='col-md-5 map d-none d-md-block'>
            {user_details.user_type !== 'individual' ? (
              <div className='map_blk'>
                <h4 className='varela'>Map</h4>
                <div id='googlemap'>
                  <MapWithAMarker
                    markers={markers}
                    defaultOptions={{ mapTypeControl: false }}
                    loadingElement={<div style={{ height: `100%` }} />}
                    containerElement={<div style={{ height: `500px` }} />}
                    mapElement={<div style={{ height: `100%` }} />}
                  />
                </div>
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
        <div className='col-md-5 map d-md-none d-block mt-2 mt-md-0'>
          {user_details.user_type !== 'individual' ? (
            <div className='map_blk'>
              <h4 className='varela'>Map</h4>
              <div id='googlemap'>
                <MapWithAMarker
                  markers={markers}
                  defaultOptions={{ mapTypeControl: false }}
                  loadingElement={<div style={{ height: `100%` }} />}
                  containerElement={<div style={{ height: `500px` }} />}
                  mapElement={<div style={{ height: `100%` }} />}
                />
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
        <div className='page_info'>
          {user_details.user_type !== 'individual' ? (
            <h3 className='varela'>About Us</h3>
          ) : (
            <h3 className='varela'>About Me</h3>
          )}
          <pre className='it-about-pre'>{about_me}</pre>
        </div>
      </Fragment>
    )
  }
}
CompanyDetails.propTypes = {
  user_settings: PropTypes.object,
}
CompanyDetails.defaultProps = {
  user_settings: {},
}
export default CompanyDetails
