import { motion } from 'framer-motion'
import React from 'react'
import CategoryIcon from '../../CommonComponents/CategoryIcon/CategoryIcon'

const variants = {
  open: {
    transition: { staggerChildren: 0.1, delayChildren: 0 },
  },
  closed: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 },
  },
}

const Categories = ({ animate, catVariants, handleClickOnSuggestion, showCategoriesList }) => {
  return (
    <motion.div
      className='search-categories-list'
      animate={animate}
      initial='closed'
      variants={variants}
    >
      <motion.div className='row align-items-stretch pt-3 pb-5 mx-0' variants={catVariants}>
        <CategoryIcon
          image='restaurant.svg'
          label='Restaurants'
          onClick={handleClickOnSuggestion}
        />
        <CategoryIcon
          image='contractor.svg'
          label='Contractors'
          onClick={handleClickOnSuggestion}
        />
        <CategoryIcon
          image='real_estate.svg'
          label='Real Estate'
          onClick={handleClickOnSuggestion}
        />
        <CategoryIcon image='hotel.svg' label='Hotels' onClick={handleClickOnSuggestion} />
      </motion.div>
      <motion.div className='row align-items-stretch pb-5 mx-0' variants={catVariants}>
        <CategoryIcon image='landscape.svg' label='Landscaper' onClick={handleClickOnSuggestion} />
        <CategoryIcon image='plumbing.svg' label='Plumber' onClick={handleClickOnSuggestion} />
        <CategoryIcon image='auto.svg' label='Auto Repair' onClick={handleClickOnSuggestion} />
        <CategoryIcon image='bars.svg' label='Bars' onClick={handleClickOnSuggestion} />
      </motion.div>
      <motion.div className='row align-items-stretch pb-4 mx-0' variants={catVariants}>
        <CategoryIcon image='coffee.svg' label='Coffee' onClick={handleClickOnSuggestion} />
        <CategoryIcon image='gym.svg' label='Gyms' onClick={handleClickOnSuggestion} />
        <CategoryIcon image='shopping.svg' label='Shopping' onClick={handleClickOnSuggestion} />
        <CategoryIcon image='more.svg' label='See More' onClick={showCategoriesList} />
      </motion.div>
      <motion.hr
        className='mx-3'
        style={{
          border: 0,
          height: 0,
          borderTop: '1px solid #544cf9',
          borderBottom: '1px solid rgba(255, 255, 255, 0.3)',
        }}
        variants={catVariants}
      />
    </motion.div>
  )
}

export default Categories
