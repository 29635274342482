import React from 'react'
import { Link } from 'react-router-dom'
import { DEFAULT_PROFILE_PICTURE } from '../../../../services/const'
import { dateStringToTimeSince, getUser, kFormatter } from '../../../../services/customFunction'
import { PROFILE_ROUTE } from '../../../../services/routeConst'

const notifications = ({ type, data }) => {
  const labels = {
    friends: 'Added you as a friend.',
    follows: 'Followed you.',
    new_iteam_member: 'You have a new 1st tier iTeam Member!',
    friend_pending: 'Wants to be your friend.',
    friend_accepted: 'accepted your friend request.',
    follow_pending: 'Wants to follow you.',
    follow_accepted: 'accepted your follow request.',
    reviewed: `reviewed your ${data}.`,
    reviewed_update: 'Record not available. ( Review update )',
    trust: `Trusted your ${data}.`,
    member_pending: 'Is requesting to join your organization.',
    invite_member_pending: 'is inviting you to be a member of their organization on iTRUSTit.',
    invite_member_accepted:
      'has accepted your member request and is now a member of your Organization on iTRUSTit.',

    member_accepted:
      'has accepted your member request and is now a member of your Organization on iTRUSTit.',

    member: 'is now member of your organization.',
    invite_member: 'added you as a member.',
  }
  return labels[type] ?? 'Data not valid'
}

const NotificationsDropdown = ({ readNotification, userNotificationCount, user_notifications }) => {
  return (
    <li className='nav-item dropdown'>
      <div
        className='nav-link dropdown-toggle blue_dot_blk position-relative'
        href={null}
        data-bs-toggle='dropdown'
        aria-haspopup='true'
        id='navbarDropdownMenuLink'
        aria-expanded='false'
        onClick={readNotification}
      >
        <span className={userNotificationCount !== 0 ? 'iteam-notif-count' : ''}>
          {userNotificationCount !== 0 ? kFormatter(userNotificationCount) : ''}
        </span>
        <img src={process.env.PUBLIC_URL + '/img/new_icons/bell.svg'} width={25} alt='logo' />
      </div>
      <div
        className='dropdown-menu notify header-dropdown-content'
        aria-labelledby='navbarDropdownMenuLink'
      >
        <div className='dropdown-header'>NOTIFICATIONS</div>
        <ul className='msg_list mb-5'>
          {user_notifications.map(({ id, actor_user = {}, action, is_read, created_at }) => {
            const { user_type, fullname, business_organization_name, slug, user_detail } =
              actor_user

            return (
              <li
                key={id}
                style={{
                  background: is_read === 0 ? 'rgb(243, 243, 243)' : '',
                }}
              >
                <div href={null} className='msg_item'>
                  <div className='img_blk'>
                    <img
                      src={user_detail?.profile_picture_small ?? DEFAULT_PROFILE_PICTURE}
                      alt=''
                    />
                  </div>
                  <div className='msg_content'>
                    <h4>
                      <Link to={'/' + slug}>
                        {user_type === 'individual' ? fullname : business_organization_name}
                      </Link>
                    </h4>
                    <p>
                      {notifications({
                        type: action,
                        data: getUser().user_type === 'business' ? 'Business' : 'Organization',
                      })}
                    </p>
                    <p className='time'>{dateStringToTimeSince(created_at)}</p>
                  </div>
                </div>
              </li>
            )
          })}

          {user_notifications?.length === 0 && <div className='it-no-data'>No notifications</div>}
          <Link to={PROFILE_ROUTE + '#notifications'} className='more-data'>
            See All
          </Link>
        </ul>
      </div>
    </li>
  )
}

export default NotificationsDropdown
