import React, { Component, Fragment } from 'react'
// import { PROFILE_GALLERY_TITLE } from "../../../services/titleConst";
// import ErrorMsgRenderer from "../../ErrorMsgRenderer/ErrorMsgRenderer";
// import { toaster } from "../../../services/customFunction";
import { GALLERY_MEDIA_ITEMS_PER_PAGE, GALLERY_MEDIA_PAGE_RANGE } from '../../../services/const'
import Pagination from 'react-js-pagination'
import GalleyMediaListDetail from '../../CommonComponents/GalleyMediaListDetail/GalleyMediaListDetail'
import { getUser } from '../../../services/customFunction'
import * as galleryApiService from '../../../services/galleryApiService'
import $ from 'jquery'
// import { setTimeout } from "timers";
import { DefaultPlayer as Video } from 'react-html5video'
import 'react-html5video/dist/styles.css'
import ImageGallery from 'react-image-gallery'
import ReportModal from '../../CommonComponents/ReportModal/ReportModal'
import ReportIcon from '../../CommonComponents/ReportIcon/ReportIcon'

class GalleryTab extends Component {
  constructor(props) {
    super(props)
    //document.title = PROFILE_GALLERY_TITLE;

    this.state = {
      user_id: this.props.user_id,

      mediaType: '',
      activePage: 1,
      totalPages: 0,
      totalRecords: 0,
      galleryList: [],
      overall_count: {
        total_videos: 0,
        total_images: 0,
        total_records: 0,
      },
      filter_total_count: {
        total_videos: 0,
        total_images: 0,
        total_records: 0,
      },
      videoMediaObj: null,
      isSettingToShowGalleryTab: false,
      imageMediaObj: null,
      galleryItems: [],
      showIndex: false,
      showBullets: false,
      infinite: true,
      showNav: true,
    }

    this.handlePageChange = this.handlePageChange.bind(this)
  }

  componentDidMount() {
    //document.title = PROFILE_GALLERY_TITLE;
    this.galleryListRender()
  }

  componentWillReceiveProps(nextProps) {
    let user_id_new = nextProps.user_id
    let user_id_old = this.state.user_id

    if (user_id_new !== user_id_old) {
      this.setState({ user_id: user_id_new }, () => {
        this.galleryListRender()
      })
    }

    // condition for show hide Gallery tab depends on user setting
    if (
      nextProps.user_settings.gallery_view_by === 'Everybody including non-logged in users' ||
      (nextProps.user_settings.gallery_view_by === 'All logged in users' && getUser().id) ||
      (nextProps.user_settings.gallery_view_by === 'Friends and friends of friends' &&
        (nextProps.user_settings.is_friend_user ||
          nextProps.user_settings.is_friend_of_friends_user)) ||
      (nextProps.user_settings.gallery_view_by === 'Friends' &&
        nextProps.user_settings.is_friend_user) ||
      (nextProps.user_settings.gallery_view_by === 'Members only' &&
        nextProps.user_settings.is_member_user)
    ) {
      if (!this.state.isSettingToShowGalleryTab) {
        this.setState(
          {
            isSettingToShowGalleryTab: true,
          },
          this.galleryListRender,
        )
      }
    } else {
      if (this.state.isSettingToShowGalleryTab) {
        this.setState({
          isSettingToShowGalleryTab: false,
        })
      }
    }
  }

  galleryListRender = () => {
    let galleryListReqData = {
      other_user_id: this.state.user_id,

      page: this.state.activePage ? this.state.activePage : 1,
      file_type: this.state.mediaType,
    }

    const self = this
    if (this.state.isSettingToShowGalleryTab) {
      galleryApiService.galleryListCall(galleryListReqData).then(function (response) {
        let galleryListRes = response.data
        let stateData = {
          totalPages: galleryListRes.total_pages,
          totalRecords: galleryListRes.total_records,
          galleryList: galleryListRes.gallery_list,
          overall_count: galleryListRes.overall_count,
          filter_total_count: galleryListRes.filter_total_count,
        }

        if (self.state.activePage <= 0) {
          stateData['activePage'] = 1
        }

        self.props.profileHeader_gallery_Handle(galleryListRes.overall_count.total_records)
        self.setState(stateData, () => {})

        let galleryItemsRes = galleryListRes.gallery_list
        let galleryItemsResLength = galleryItemsRes.length
        let galleryItems = []

        if (galleryItemsResLength > 0) {
          for (var i = 0; i < galleryItemsResLength; i++) {
            let galleryItemRes = galleryItemsRes[i]
            if (galleryItemRes.file_type === 'image') {
              var tempObj = {
                original: galleryItemRes.gallery_picture,
                thumbnail: galleryItemRes.gallery_picture_thumb,
              }
              galleryItems.push(tempObj)
            }
          }
        }
        self.setState({
          galleryItems: galleryItems,
        })
      })
    }
  }

  handlePageChange(pageNumber) {
    this.setState(
      {
        activePage: pageNumber,
      },
      () => {
        this.galleryListRender()
      },
    )
  }

  changeGalleryActiveTab = (data) => (event) => {
    let mediaType = ''
    if (data === 'gallery_pictures') {
      mediaType = 'image'
    } else if (data === 'gallery_videos') {
      mediaType = 'video'
    }
    this.setState(
      {
        mediaType: mediaType,
      },
      () => {
        this.galleryListRender()
      },
    )
  }

  profileHeader_gallery_tab_click_Handle() {
    //document.title = PROFILE_GALLERY_TITLE;
    this.galleryListRender()
  }

  gallery_videoPlay_handle = (mediaObj) => {
    this.setState(
      {
        videoMediaObj: mediaObj,
      },
      () => {
        $('#play_video_modal').modal('show')
      },
    )
  }

  gallery_imageDisplay_handle = (mediaObj) => {
    this.setState(
      {
        imageMediaObj: mediaObj,
      },
      () => {
        $('#view_image_modal').modal('show')
      },
    )
  }

  render() {
    const { activePage, totalPages, totalRecords, galleryList, mediaType, videoMediaObj } =
      this.state

    let showingFrom = this.props.itemsCountPerPage * (this.state.activePage - 1) + 1
    let showingTo = this.props.itemsCountPerPage * this.state.activePage
    if (showingFrom > totalRecords) {
      showingFrom = totalRecords
    }
    if (showingTo > this.state.totalRecords) {
      showingTo = this.state.totalRecords
    }

    if (totalPages < this.state.activePage && totalPages !== 0) {
      this.setState(
        {
          activePage: totalPages,
        },
        () => {
          this.galleryListRender()
        },
      )
    }

    let self = this

    $('#play_video_modal').on('hidden.bs.modal', function (e) {
      self.setState({
        videoMediaObj: null,
      })
    })

    $('#view_image_modal').on('hidden.bs.modal', function (e) {
      self.setState({
        imageMediaObj: null,
      })
    })

    return (
      <div
        id='gallery'
        className={'tab-pane ' + (this.props.activeTab === '#gallery' ? 'active' : 'fade')}
      >
        {this.state.isSettingToShowGalleryTab ? (
          <div>
            {/* <h4 className="varela">Gallery for Gallie for Kids</h4> */}
            <h4 className='varela'>Gallery</h4>
            <div className='bg-white'>
              <div className='friend_list_block'>
                <div className='f-left'>
                  <ul className='gallery_tab inner-navs nav nav-tabs'>
                    <li className='nav-item'>
                      <a
                        className='nav-link active'
                        data-bs-toggle='tab'
                        href='#gallery_all'
                        onClick={this.changeGalleryActiveTab('gallery_all')}
                      >
                        <span>ALL ({this.state.overall_count.total_records})</span>
                      </a>
                    </li>
                    <li className='nav-item'>
                      <a
                        className='nav-link'
                        data-bs-toggle='tab'
                        href='#gallery_pictures'
                        onClick={this.changeGalleryActiveTab('gallery_pictures')}
                      >
                        <span>PICTURES ({this.state.overall_count.total_images})</span>
                      </a>
                    </li>
                    <li className='nav-item'>
                      <a
                        className='nav-link'
                        data-bs-toggle='tab'
                        href='#gallery_videos'
                        onClick={this.changeGalleryActiveTab('gallery_videos')}
                      >
                        <span>VIDEOS ({this.state.overall_count.total_videos})</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className='i-showing'>
                Showing {showingFrom} - {showingTo} of {this.state.totalRecords}
              </div>

              {/* inner tab start */}
              <div className=' inner-tab tab-content'>
                {totalRecords > 0 ? (
                  <Fragment>
                    <div id='gallery_all' className='tab-pane active'>
                      <ul className='gallery_list'>
                        {galleryList.map((media, index) => (
                          <GalleyMediaListDetail
                            key={media.id}
                            index={index}
                            media={media}
                            gallery_videoPlay_handle={this.gallery_videoPlay_handle}
                            gallery_imageDisplay_handle={this.gallery_imageDisplay_handle}
                          />
                        ))}
                      </ul>
                    </div>
                    <div>
                      <Pagination
                        activePage={activePage}
                        itemsCountPerPage={this.props.itemsCountPerPage}
                        totalItemsCount={totalRecords}
                        pageRangeDisplayed={this.props.pageRangeDisplayed}
                        onChange={this.handlePageChange}
                      />
                    </div>
                  </Fragment>
                ) : (
                  <div className='search-section'>
                    <div className='no_search_result'>
                      <img
                        src={process.env.PUBLIC_URL + '/img/empty_gallery.png'}
                        className='pt-5 pb-5 m-auto d-block'
                        alt=''
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className='modal fade review_modal add_video' id='play_video_modal' role='dialog'>
              <div className='modal-dialog'>
                <div className='modal-content'>
                  <div className='modal-header pt-3'>
                    <h5>Video</h5>
                    <ReportIcon
                      type='video'
                      closeModal={() => $('#play_video_modal').modal('hide')}
                    />
                    <button
                      type='button'
                      className='btn-close ms-0'
                      data-bs-dismiss='modal'
                    ></button>
                  </div>
                  <div className='modal-body'>
                    {videoMediaObj && (
                      <Video
                        autoPlay
                        controls={['PlayPause', 'Seek', 'Time', 'Volume', 'Fullscreen']}
                        poster={videoMediaObj.gallery_video_thumb}
                        onCanPlayThrough={() => {
                          // Do stuff
                        }}
                      >
                        <source src={videoMediaObj.gallery_video} type='video/mp4' />
                      </Video>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className='modal fade review_modal add_video' id='view_image_modal' role='dialog'>
              <div className='modal-dialog'>
                <div className='modal-content'>
                  <div className='modal-header pt-3'>
                    <h5>Image</h5>
                    <ReportIcon
                      type='image'
                      closeModal={() => $('#view_image_modal').modal('hide')}
                    />
                    <button
                      type='button'
                      className='btn-close ms-0'
                      data-bs-dismiss='modal'
                    ></button>
                  </div>
                  <div className='modal-body' style={{ textAlign: 'center' }}>
                    {this.state.imageMediaObj && (
                      <ImageGallery
                        ref={(i) => (this._imageGallery = i)}
                        items={this.state.galleryItems}
                        lazyLoad={false}
                        infinite={false}
                        showBullets={this.state.showBullets}
                        additionalClass='app-image-gallery'
                        showBullets={false}
                        showFullscreenButton={false}
                        showPlayButton={false}
                        showThumbnails={false}
                        showIndex={false}
                        showNav={this.state.showNav}
                        isRTL={false}
                        startIndex={this.state.imageMediaObj.index}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className='it-no-access'>
            {/* <h4 className="varela">Gallery for Gallie for Kids</h4> */}
            <h4 className='varela'>Gallery</h4>
            <div className='bg-white'>
              <div className=' inner-tab tab-content'>
                <div className='search-section friend_list_block'>
                  <div className='no_search_result'>
                    <img src='/img/hide-frd.svg' className='pt-5 m-auto d-block' alt='' />
                    <h2>You Don't Have Access</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <ReportModal
          user_id={this.state?.user_id}
          user_id_reported_by={this.props?.loginUserId}
          media={this.state?.videoMediaObj ?? this.state?.imageMediaObj?.media}
          galleryListRender={this.galleryListRender}
        />
      </div>
    )
  }
}

GalleryTab.defaultProps = {
  itemsCountPerPage: GALLERY_MEDIA_ITEMS_PER_PAGE,
  pageRangeDisplayed: GALLERY_MEDIA_PAGE_RANGE,
}

export default GalleryTab
