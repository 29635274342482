import React, { Component, Fragment } from 'react'

import * as friendApiService from '../../../services/friendApiService'
import * as profileSearchService from '../../../services/profileSearchService'
import { LIST_VIEW_ITEMS_PER_PAGE, LIST_VIEW_PAGE_RANGE, SEARCH_AMT } from '../../../services/const'
import { getUser } from '../../../services/customFunction'

import UserListDetail from '../../CommonComponents/UserListDetail/UserListDetail'
import $ from 'jquery'

const parseUrlSearchParams = (search) => {
  return new URLSearchParams(search)
}

class FriendsTab extends Component {
  constructor(props) {
    super(props)
    //document.title = PROFILE_FRIEND_TITLE;

    const urlSearchParams = parseUrlSearchParams(window.location.search)
    console.log({ trusted_type: urlSearchParams.get('userType') })
    this.state = {
      login_user_id: getUser().id,

      viewType: 'list_view',
      userType: urlSearchParams.get('userType') ?? '', //individual, organization, business
      activePage: 1,
      totalPages: 0,
      friendList: [],

      searchString: '',

      overall: {
        total_friends: 0,
        total_business: 0,
        total_individual: 0,
        total_organization: 0,
      },
      total_with_search: {
        total_friends: 0, //contains the total of the filter Ids - string search or/and usertype
        total_business: 0,
        total_individual: 0,
        total_organization: 0,
      },
      totalFriendIDs: [], //all the IDS queried
      totalFilterIDs: [], //includes IDs from search as well type
      isSearching: true,
      endOfPage: SEARCH_AMT,
      hasMoreData: false,
      noSearchResults: false,
      elasticSearchTotal: 0,
    }

    this.handlePageChange = this.handlePageChange.bind(this)
    this.itrustitHandle = this.itrustitHandle.bind(this)
    this.friendAddRemoveHandle = this.friendAddRemoveHandle.bind(this)
  }

  componentDidMount() {
    //console.log('friends mount friendstab')
    this.friendListRender()
  }
  componentWillReceiveProps(nextProps) {
    //console.log('friends props:', this.props)
    if (this.props && this.props.activeTab === '#friend') {
      let user_id_new = nextProps.user_id
      let user_id_old = this.state.user_id

      if (user_id_new !== user_id_old) {
        this.setState({ user_id: user_id_new }, () => {
          //console.log('pass 1')
          this.friendListRender()
        })
      }
    }
  }

  componentWillUnmount() {
    //console.log('friends unmount')
  }

  async friendListRender() {
    let searchString = this.state.searchString
    //console.log('Loading friends list...', this.props.activeTab, searchString)
    //don't include the type and string search on the DB rquest
    let friendListReqData = {
      other_user_id: this.state.login_user_id,
      page: 1,
      firstCall: true,
      noMutual: true,
      endOfPage: this.state.endOfPage || SEARCH_AMT,
      //please don't include the user_type o the request( info is filter later using elastic search)
      user_type: '', //this.state.userType,
      searchString: '', //this.state.searchString,//elimintae search fro Dn request
    }
    //console.log(',this.state.userType:', this.state.userType)
    const self = this
    //console.log('calling api 1')

    const response = await friendApiService.friendListCallNew(friendListReqData)

    //console.log(response.data)
    const totalFriendIDs = response.data.ids || []
    let overall = response.data.overall ? response.data.overall : self.state.overall
    //filter based on type
    let totalFilterIDs = totalFriendIDs
    if (self.state.userType !== '')
      totalFilterIDs = totalFriendIDs.filter((user) => user.user_type === self.state.userType)
    //console.log(totalFilterIDs)

    //do elastic search after fetch
    if (searchString !== '') {
      const idsToSearch = totalFilterIDs.map((user) => user.friend_user_id)
      const res = await profileSearchService.elasticSearch({
        ids: idsToSearch,
        searchStr: searchString,
      })

      if (res.length) {
        totalFilterIDs = res.map((searchedUser) => ({
          friend_user_id: searchedUser['_id'],
          user_type: searchedUser['_source'].user_type,
        }))
      } else {
        self.setState({ isSearching: false, resetSearch: false, noSearchResults: true })
        return
      }
    }

    let totalFilterFriends = totalFilterIDs.length
    if (self.state.userType !== '')
      totalFilterFriends = totalFilterIDs.filter(
        (user) => user.user_type === self.state.userType,
      ).length
    let total_with_search = {
      total_friends: totalFilterFriends,
    }
    const endOfPage = totalFilterFriends < SEARCH_AMT ? totalFilterFriends : SEARCH_AMT
    //console.log('endofPag', endOfPage)
    let stateData = {
      totalFriendIDs,
      totalFilterIDs,
      endOfPage,
      total_with_search,
      overall,
      noSearchResults: totalFilterIDs.length <= 0,
      resetSearch: false,
    }
    //console.log(stateData)

    const currentIDs = totalFilterIDs.slice(0, endOfPage).map((user) => user.friend_user_id)

    //console.log(currentIDs)
    self.setState(stateData, () => {
      //console.log('self.state:', self.state)
      if (currentIDs.length) {
        self.searchForFriends(currentIDs)
      } else {
        self.setState({ isSearching: false })
      }
      self.props.profileHeader_friend_Handle(overall.total_friends)
    })
  }

  searchForFriends = (currentIDs) => {
    //console.log('searchfor friends:', currentIDs)
    const self = this
    if (this.state.resetSearch) return

    const searchID = this.state.searchID
    friendApiService
      .friendListCallNewFromIDs({
        other_user_id: self.state.login_user_id,
        noMutual: true,
        endOfPage: self.state.endOfPage || SEARCH_AMT,
        user_type: self.state.userType,
        ids: currentIDs,
      })
      .then(function (response) {
        //console.log(response)
        if (self.state.resetSearch || searchID !== self.state.searchID) return
        self.setState(
          (st) => ({
            friendList: [...st.friendList, ...(response?.data?.friends_list ?? [])],
            hasMoreData: st.endOfPage < st.total_with_search.total_friends,
            isSearching: false,
            loadingMoreData: false,
          }),
          () =>
            self.state.hasMoreData &&
            !self.state.resetSearch &&
            searchID === self.state.searchID &&
            self.searchAgain(),
        )
      })
  }

  handlePageChange(pageNumber) {
    $('html, body').animate(
      {
        scrollTop: $('#friend').offset().top - 100,
      },
      1000,
    )
    this.setState(
      {
        activePage: pageNumber,
        isSearching: true,
        friendList: [],
      },
      () => {
        this.friendListRender()
      },
    )
  }

  setStatusOfMemberUserYour = (id, status) => {
    this.setState(
      (st) => {
        let friendList = st.friendList
        friendList.filter((user) => user.id === id)[0]['is_your_member_user'] =
          status === 'pending' ? 2 : status === 'accepted' ? 1 : 0
        return {
          friendList,
        }
      },
      () => {},
    )
  }

  itrustitHandle(data) {
    this.setState(
      (st) => {
        let friendList = st.friendList
        friendList.filter((user) => user.id === data.id)[0]['is_trusted_user'] =
          data.is_trusted_user === 1 ? 0 : 1
        return {
          friendList,
        }
      },
      () => {
        //console.log(this.state.friendList);
        //this.friendListRender();
      },
    )
    // this.friendListRender();
    // this.props.profileHeader_itrustit_recall_Handle();
  }

  memberAddRemoveHandle = (data) => {
    let friendList = this.state.friendList
    friendList.filter((user) => user.id === data.id)[0]['is_member_user'] =
      data.is_member_user === 1 ? 0 : 1

    this.setState({
      friendList: friendList,
    })
  }
  /*910 - update the search value on change, so the right value is use when other tabs are clicked*/
  handleFriendSearchChange = (event) => {
    event.preventDefault()
    this.setState({ searchString: event.target.value })
  }

  handleFriendSearchSubmit = (event) => {
    event.preventDefault()
    this.setState(
      {
        searchString: event.target.elements.friendSearchTxt.value,
        isSearching: true,
        resetSearch: true,
        friendList: [],
        searchID: `${event.target.elements.friendSearchTxt.value ?? ''}_${
          this.state.total_with_search.total_friends ?? 0
        }`,
      },
      () => {
        this.friendListRender()
      },
    )
  }

  changeFriendActiveTab = (data) => (event) => {
    //console.log('inside friend changeactivetab1 :', data)
    let userType = ''
    if (data !== '') {
      if (data.includes('individual')) userType = 'individual'
      else if (data.includes('business')) userType = 'business'
      else if (data.includes('organization')) userType = 'organization'
      else userType = ''
    }
    //console.log('inside friend changeactivetab:', userType)
    this.setState(
      {
        userType: userType,
        isSearching: true,
        endOfPage: SEARCH_AMT,
        friendList: [],
        // searchedIDs: [],
      },
      () => {
        //console.log('call pass 3')
        this.friendListRender()
      },
    )
  }

  profileHeader_friend_tab_click_Handle(data = '') {
    //document.title = PROFILE_FRIEND_TITLE;
    //console.log('profile friend tab data:', data)
    let userType = this.state.userType
    if (data !== '') {
      $('.nav-tabs a[href="' + data + '"]').tab('show')
      if (data.includes('individual')) userType = 'individual'
      else if (data.includes('business')) userType = 'business'
      else if (data.includes('organization')) userType = 'organization'
      else userType = ''
    }
    //console.log('inside friend changeactivetab:', data)
    this.setState(
      {
        userType: userType,
        isSearching: true,
        endOfPage: SEARCH_AMT,
        friendList: [],
        // searchedIDs: [],
      },
      () => {
        //console.log('call pass 3')
        this.friendListRender()
      },
    )
  }

  friendAddRemoveHandle(data) {
    // let friendList = this.state.friendList;
    // friendList.filter((user) => user.id === data.id)[0]['is_friend_user'] =
    //   data.is_friend_user === 1 ? 0 : 1;
    // //console.log(data);
    this.setState(
      (st) => {
        let friendList = st.friendList
        friendList.filter((user) => user.id === data.id)[0]['is_friend_user'] =
          data.is_friend_user === 1 ? 0 : 1
        friendList.filter((user) => user.id === data.id)[0]['friendship_id'] =
          data.is_friend_user === 1 ? null : data.friendship_id
        return {
          friendList,
        }
      },
      () => {
        //console.log(this.state.friendList);
        //this.friendListRender();
      },
    )
  }

  infiniteScroll() {
    if (this.state.hasMoreData) {
      const winScroll = document.body.scrollTop || document.documentElement.scrollTop

      const height = document.documentElement.scrollHeight - document.documentElement.clientHeight

      const scrolled = winScroll / height
      //console.log(scrolled);
      if (scrolled >= 0.7) {
        this.searchAgain()
      }
    }
  }

  searchAgain() {
    //console.log('searchagain endofpge:', this.state.endOfPage)
    this.setState(
      (st) => ({
        hasMoreData: false,
        endOfPage: st.endOfPage + SEARCH_AMT,
        loadingMoreData: true,
      }),
      () => {
        let currentIDs = this.state.totalFilterIDs
          .slice(this.state.endOfPage - SEARCH_AMT, this.state.endOfPage)
          .map((user) => user.friend_user_id)
        this.searchForFriends(currentIDs)
      },
    )
  }

  render() {
    //console.log('Friends render')
    const { friendList, isSearching, userType, noSearchResults = false } = this.state
    let listToShow =
      userType === '' ? friendList : friendList.filter((user) => user.user_type === userType)
    listToShow = listToShow.filter(
      (thing, index, self) => self.findIndex((t) => t.id === thing.id) === index,
    )
    //console.log(listToShow)
    let totalRecords = listToShow.length
    let showingFrom = this.props.itemsCountPerPage * (this.state.activePage - 1) + 1
    let showingTo = listToShow.length || showingFrom
    if (showingFrom > totalRecords) {
      showingFrom = totalRecords
    }
    if (showingTo > this.state.totalRecords) {
      showingTo = this.state.totalRecords
    }

    let total_with_search_count = 0

    total_with_search_count = this.state.total_with_search.total_friends
    return (
      <div
        id='friend'
        className={'tab-pane ' + (this.props.activeTab === '#friend' ? 'active' : 'fade')}
      >
        <h4 className='varela'>Friends ({this.state.overall.total_friends})</h4>
        <div className='bg-white'>
          <div className='friend_list_block'>
            <div className='f-left'>
              <ul className='inner-navs nav nav-tabs'>
                <li className='nav-item'>
                  <a
                    className={`nav-link ${
                      !['individual', 'business', 'organization'].includes(this.state.userType)
                        ? 'active'
                        : ''
                    }`}
                    data-bs-toggle='tab'
                    href='#friend_all'
                    onClick={this.changeFriendActiveTab('friend_all')}
                  >
                    <span>{this.state.overall.total_friends} FRIENDS</span>
                    {/* <span className="mutual">100 mutual</span> */}
                  </a>
                </li>
                <li className='nav-item'>
                  <a
                    className={`nav-link ${this.state.userType === 'individual' ? 'active' : ''}`}
                    data-bs-toggle='tab'
                    href='#friend_individual'
                    onClick={this.changeFriendActiveTab('friend_individual')}
                  >
                    <span>{this.state.overall.total_individual} PEOPLE</span>
                    {/* <span className="mutual">75 mutual</span> */}
                  </a>
                </li>
                <li className='nav-item'>
                  <a
                    className={`nav-link ${this.state.userType === 'business' ? 'active' : ''}`}
                    data-bs-toggle='tab'
                    href='#friend_business'
                    onClick={this.changeFriendActiveTab('friend_business')}
                  >
                    <span>{this.state.overall.total_business} BUSINESSES </span>
                    {/* <span className="mutual">15 mutual</span> */}
                  </a>
                </li>
                <li className='nav-item'>
                  <a
                    className={`nav-link ${this.state.userType === 'organization' ? 'active' : ''}`}
                    data-bs-toggle='tab'
                    href='#friend_organization'
                    onClick={this.changeFriendActiveTab('friend_organization')}
                  >
                    <span>{this.state.overall.total_organization} ORGANIZATIONS</span>
                    {/* <span className="mutual">100 mutual</span> */}
                  </a>
                </li>
              </ul>
            </div>
            <div className='f-right'>
              <form onSubmit={this.handleFriendSearchSubmit}>
                <input
                  type='text'
                  name='friendSearchTxt'
                  className='input1 form-control'
                  placeholder='Search for a person,  business, or organization...'
                  /*910 - update the search value on change, so the right value is use when other tabs are clicked*/
                  onChange={this.handleFriendSearchChange}
                />
                <input type='submit' className='search' value='' />
              </form>
            </div>
          </div>

          <div className='inner-tab tab-content'>
            {total_with_search_count > 0 && !noSearchResults && !isSearching ? (
              <Fragment>
                <div className='row frnd_1'>
                  {listToShow.map(
                    (user, index) =>
                      index < this.state.endOfPage && (
                        <UserListDetail
                          setModalProps={this.props.setModalProps}
                          setStatusOfMemberUserYour={this.setStatusOfMemberUserYour}
                          key={user.id}
                          index={index}
                          login_user_id={this.state.login_user_id}
                          confirmitrustitAddRemove={this.props.confirmitrustitAddRemove}
                          confirmFriendAddRemove={this.props.confirmFriendAddRemove}
                          loadingFriendStatusChange={this.props.loadingFriendStatusChange}
                          loadingitrustitStatusChange={this.props.loadingitrustitStatusChange}
                          tab={'friend'}
                          user={user}
                          viewType={this.state.viewType}
                          itrustitHandle={this.itrustitHandle}
                          friendAddRemoveHandle={this.friendAddRemoveHandle}
                          memberAddRemoveHandle={this.memberAddRemoveHandle}
                        />
                      ),
                  )}
                </div>
                {this.state.loadingMoreData ? (
                  <div className='no_search_result'>
                    <div className='search-page-loading-icon'>
                      {' '}
                      <i />{' '}
                    </div>
                  </div>
                ) : (
                  !this.state.hasMoreData && (
                    <div
                      onClick={() => {
                        window.scrollTo({
                          top: $('#friend').offset().top - 100,
                          behavior: 'smooth',
                        })
                      }}
                      className='no_search_result back-to-top text-muted'
                    >
                      Back to Top
                    </div>
                  )
                )}
                {/* <div>
                  <Pagination
                    activePage={activePage}
                    itemsCountPerPage={this.props.itemsCountPerPage}
                    totalItemsCount={total_with_search_count}
                    pageRangeDisplayed={this.props.pageRangeDisplayed}
                    onChange={this.handlePageChange}
                  />
                </div> */}
              </Fragment>
            ) : noSearchResults || !isSearching ? (
              <div className='search-section'>
                <div className='no_search_result'>
                  <img
                    src={process.env.PUBLIC_URL + '/img/no_friends.png'}
                    className='pt-5 pb-5 m-auto d-block'
                    alt=''
                  />
                </div>
              </div>
            ) : (
              <div className='search-section'>
                <div className='no_search_result'>
                  <div className='search-page-loading-icon'>
                    {' '}
                    <i />{' '}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }
}

FriendsTab.defaultProps = {
  itemsCountPerPage: LIST_VIEW_ITEMS_PER_PAGE,
  pageRangeDisplayed: LIST_VIEW_PAGE_RANGE,
}

export default FriendsTab
