import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { reviewUser } from '../../actions/profile'

import AppLayout from '../AppLayout'

import { PROFILE_TITLE } from '../../services/titleConst'
import * as route from '../../services/routeConst'
import {
  setInviteMatrixId,
  getInviteMatrixId,
  isPostTabVisible,
} from '../../services/customFunction'
import { NO_COVER_PICTURE } from '../../services/const'
import { getProfileFromId, getProfileFromIdOnlyProfile } from '../../services/userApiService'
import { ENABLE_SOCIAL_MEDIA } from '../../services/environment'
import {
  STAR_RATING_iTRUST_ACTIVE_COLOR,
  STAR_RATING_iTRUST_INACTIVE_COLOR,
  STAR_RATING_TRUST_ME_ACTIVE_COLOR,
  STAR_RATING_TRUST_ME_INACTIVE_COLOR,
  STAR_RATING_PROFILE_HEADER_SIZE,
} from '../../services/const'
import ReactStars from 'react-stars'

// import ProfileHeader from './ProfileHeader/ProfileHeader'
import OverviewGenericTab from './OverviewGenericTab/OverviewGenericTab'
import TrustDirectoryTab from './TrustDirectoryTab/TrustDirectoryTab'
import GalleryTab from './GalleryTab/GalleryTab'
import FriendsTab from './FriendsTab/FriendsTab'
import MemberTab from './MemberTab/MemberTab'
// import ActivityTab from "./ActivityTab/ActivityTab";
// import InviteCenterTab from "./InviteCenterTab/InviteCenterTab";
import ReviewTab from './ReviewTab/ReviewTab'
import ReviewCreate from '../CommonComponents/ReviewCreate/ReviewCreate'
import LoginModal from '../LoginModal/LoginModal'
// import Permission from "../Profile/Permission";
import $ from 'jquery'
import OrgsTotalTab from './OrgsTotalTab/OrgsTotalTab'
import { formatMemberDirectoryName } from '../../services/formatService'
import { Helmet } from 'react-helmet'
import ProfileHeader from '../ProfileOtherUser/ProfileHeader/ProfileHeader'
import SignupOrLoginText from '../CommonComponents/SignupOrLoginText/SignupOrLoginText'
import UnclaimedDisclaimer from '../CommonComponents/UnclaimedDisclaimer/UnclaimedDisclaimer'
import { isMobile } from 'react-device-detect'
import ProfileReviewStars from '../CommonComponents/ProfileReviewStars/ProfileReviewStars'
import ISupport from '../CommonComponents/ISupport/ISupport'
class ProfileOtherBeforeLogin extends Component {
  constructor(props) {
    super(props)

    this.TrustDirectoryTabRef = React.createRef()
    this.friendTabRef = React.createRef()
    this.memberTabRef = React.createRef()
    this.galleryTabRef = React.createRef()
    this.overviewGenericTabRef = React.createRef()
    this.reviewTabRef = React.createRef()
    this.orgsTotalTabRef = React.createRef()
    this.state = {
      user_id: '',
      user: null,
      userslug: props.match.params.id,
      urlSearchParams: window.location.search.substring(1)
        ? JSON.parse(
            '{"' +
              decodeURI(window.location.search.substring(1))
                .replace(/"/g, '\\"')
                .replace(/&/g, '","')
                .replace(/=/g, '":"') +
              '"}',
          )
        : {},

      trust_directory_total_records: 0,
      friend_total_records: 0,
      member_total_records: 0,
      gallery_total_records: 0,
      orgs_total_records: 0,

      overall_reviews: {
        total_reviews: 0,
        total_received_reviews: 0,
        total_given_reviews: 0,
      },
      avg_reviews: {
        given_reviews: 0,
        received_reviews: 0,
      },
      isLoginUserClick: true,
      userOther: {},
      user_settings: {},
      isClaimloginText: false,
      isSettingToShowProfilePage: false,
      documentTitle: PROFILE_TITLE,
      post_id: props.match.params.post_id ? props.match.params.post_id : '',
    }
    window.onscroll = () => {
      this.overallInfiniteScroll()
    }
    this.scrollRef = React.createRef()
  }

  overallInfiniteScroll() {
    // console.log(this.state.activeTab);
    switch (this.state.activeTab) {
      case '#friend':
        this.friendTabRef.current && this.friendTabRef.current.infiniteScroll()
        break
      case '#trust':
        this.TrustDirectoryTabRef.current && this.TrustDirectoryTabRef.current.infiniteScroll()
        break
      case '#member':
        this.memberTabRef.current && this.memberTabRef.current.infiniteScroll()
        break
      case '#orgs_total':
        this.orgsTotalTabRef.current && this.orgsTotalTabRef.current.infiniteScroll()
        break
      default:
        break
    }
  }

  loginModalOpenTab = (data) => {
    this.setState({ isClaimloginText: true })
  }

  componentDidMount() {
    //  console.log("beforelogin", window.location.href);
    if (this.state.urlSearchParams?.REFERRAL_ID) {
      localStorage.setItem('REFERRAL_ID', this.state.urlSearchParams.REFERRAL_ID)
    }
    this.state.activeTab !== '#overview'
      ? setTimeout(() => {
          window.scrollTo({
            top: ($(this.state.activeTab)?.offset()?.top ?? 125) - 125,
            behavior: 'smooth',
          })
        }, 1000)
      : window.scrollTo(0, 0)

    this.getProfileFromId()
  }

  componentWillReceiveProps(nextProps) {
    // alert(nextProps.location.hash);
    const tabs = [
      '#friend',
      '#activity',
      '#trust',
      '#invite_center',
      '#orgs_total',
      '#orgs_common',
      '#gallery',
      '#privacy-settings',
      '#notifications',
      '#itrustittree',
      '#activity',
      '#review',
      '#posts',
    ]
    let activeTab = isPostTabVisible(this?.state?.user) ? '#posts' : '#overview'
    if (tabs.includes(nextProps.location.hash)) {
      activeTab = nextProps.location.hash
    }
    //fix only for post bc I'm not sure about other tabs
    if (tabs.includes(window.location.hash) && window.location.hash === '#posts') {
      activeTab = window.location.hash
    }
    if (this.state.activeTab === '#review' && !nextProps.location.hash) {
      activeTab = '#review'
    }
    this.setState({ activeTab: activeTab }, () =>
      window.scrollTo({
        top: $(this.state.activeTab)?.offset()?.top - 125 ?? 0,
        behavior: 'smooth',
      }),
    )

    let user_id_new = nextProps.match.params.id
    let user_id_old = this.state.userslug

    if (user_id_new !== user_id_old) {
      window.location.reload()
      this.setState({ userslug: user_id_new }, () => {
        window.scrollTo(0, 0)
        //add line
        this.overviewGenericTabRef.current.profileHeader_overview_tab_click_Handle()
        this.getProfileFromId()
      })
    }

    if (
      typeof nextProps.reviewuser !== 'undefined' &&
      Object.keys(nextProps.reviewuser).length > 0
    ) {
      this.setState(
        {
          userOther: nextProps.reviewuser,
          isLoginUserClick: nextProps.reviewuser.isLoginUserClick ? true : false,
        },
        () => {
          $('#reviewCreateModal').modal('show')
        },
      )
    }
  }

  // CALL USER DETAIL
  getProfileFromId() {
    let getProfileFromIdReqData = {
      userslug: this.state.userslug,
    }
    let self = this

    getProfileFromId(getProfileFromIdReqData).then(function (response) {
      if (response.data.length === 0) {
        self.props.history.push({
          pathname: route.ROOT_ROUTE,
        })
        return false
      }

      let profileData = response.data
      let user_settings = profileData.user_settings

      if (profileData.is_business_claimed == '1' && profileData.is_email_confirmed == '0') {
        self.props.history.push({
          pathname: route.ROOT_ROUTE,
        })
      }

      user_settings['is_friend_user'] = profileData.is_friend_user
      user_settings['is_friend_of_friends_user'] = profileData.is_friend_of_friends_user
      user_settings['is_member_user'] = profileData.is_member_user
      let documentTitle =
        PROFILE_TITLE +
        ' - ' +
        (profileData.business_organization_name
          ? profileData.business_organization_name
          : profileData.fullname)
      let stateData = {
        user: profileData,
        activeTab: window.location.hash
          ? window.location.hash
          : isPostTabVisible(profileData)
          ? '#posts'
          : '#overview',
        userOther: profileData,
        user_id: profileData.id,
        friend_total_records: profileData.total_friends,
        trust_directory_total_records:
          profileData.total_trusted_by + profileData.total_trusted_by_me,
        gallery_total_records: profileData.total_galleries,
        member_total_records: profileData.total_members,
        orgs_total_records: profileData.total_records_total_orgs,
        user_settings,
        documentTitle,
      }
      if (user_settings.profile_view_by === 'Everybody including non-logged in users') {
        if (!self.state.isSettingToShowProfilePage) {
          self.setState({
            isSettingToShowProfilePage: true,
          })
        }
      } else {
        if (self.state.isSettingToShowProfilePage) {
          self.setState({
            isSettingToShowProfilePage: false,
          })
        }
      }
      self.setState(stateData, () => {
        self.getProfileFromIdOnlyProfile()
      })

      if (profileData.imatrix_id) {
        if (getInviteMatrixId() && getInviteMatrixId() !== undefined) {
        } else {
          setInviteMatrixId(profileData.imatrix_id)
        }
      }
    })
  }

  getProfileFromIdOnlyProfile() {
    //console.log("this.state.userslug", this.state.userslug);

    let getProfileFromIdReqData = {
      userslug: this.state.userslug,
    }
    let self = this
    getProfileFromIdOnlyProfile(getProfileFromIdReqData).then(function (response) {
      if (response.data.length === 0) {
        self.props.history.push({
          pathname: route.ROOT_ROUTE,
        })
        return false
      }
      let profileData = response.data
      let user_settings = profileData.user_settings

      if (profileData.is_business_claimed == '1' && profileData.is_email_confirmed == '0') {
        self.props.history.push({
          pathname: route.ROOT_ROUTE,
        })
      }

      user_settings['is_friend_user'] = profileData.is_friend_user
      user_settings['is_friend_of_friends_user'] = profileData.is_friend_of_friends_user
      user_settings['is_member_user'] = profileData.is_member_user
      let documentTitle =
        PROFILE_TITLE +
        ' - ' +
        (profileData.business_organization_name
          ? profileData.business_organization_name
          : profileData.fullname)
      let stateData = {
        user: profileData,
        userOther: profileData,
        user_id: profileData.id,
        friend_total_records: profileData.total_friends,
        trust_directory_total_records:
          profileData.total_trusted_by + profileData.total_trusted_by_me,
        gallery_total_records: profileData.total_galleries,
        member_total_records: profileData.total_members,
        orgs_total_records: profileData.total_records_total_orgs,
        user_settings,
        documentTitle,
      }
      if (user_settings.profile_view_by === 'Everybody including non-logged in users') {
        if (!self.state.isSettingToShowProfilePage) {
          self.setState({
            isSettingToShowProfilePage: true,
          })
        }
      } else {
        if (self.state.isSettingToShowProfilePage) {
          self.setState({
            isSettingToShowProfilePage: false,
          })
        }
      }
      self.setState(stateData, () => {})

      if (profileData.imatrix_id) {
        if (getInviteMatrixId() && getInviteMatrixId() !== undefined) {
        } else {
          setInviteMatrixId(profileData.imatrix_id)
        }
      }
    })
  }

  itrustitHandle = (data) => {
    this.getProfileFromId()
  }

  friendAddRemoveHandle = (data) => {
    this.getProfileFromId()
  }

  memberAddRemoveHandle = (data) => {
    if (data.is_member_user === 0) {
      let user = this.state.user
      user.is_member_user = 1
      this.setState({ user: user })
    } else if (data.is_member_user === 1) {
      let user = this.state.user
      user.is_member_user = 0
      this.setState({ user: user })
    }
  }

  showiTrustitTab = (data) => (event) => {
    $('#profile-tabs a[href="#trust"]').tab('show')
    window.scrollTo({
      top: $('#trust').offset().top - 125,
      behavior: 'smooth',
    })
    window.history.pushState(null, null, '#trust')
    this.setState({
      activeTab: '#trust',
    })
    this.TrustDirectoryTabRef.current.profileHeader_itrustit_tab_click_Handle(data)
  }

  showFriendsTab = (data) => (event) => {
    window.scrollTo({
      top: $('#friend').offset().top - 125,
      behavior: 'smooth',
    })

    this.setState({
      activeTab: '#friend',
    })
    window.history.pushState(null, null, '#friend')
    this.friendTabRef.current.profileHeader_friend_tab_click_Handle(data)
  }

  showTrustDirectoryTab = (data = '') => {
    $(`#profile-tabs a[href="trust"]`).tab('show')
    window.scrollTo({
      top: $('#trust').offset()?.top - 125,
      behavior: 'smooth',
    })

    window.history.pushState(null, null, '#trust')
    this.setState({
      activeTab: '#trust',
    })
    this.TrustDirectoryTabRef.current.profileHeader_trustmeby_tab_click_Handle(data)
  }
  changeActiveTab = (data) => (event) => {
    //console.log('changeActiveTab:', data)
    this.setState({
      activeTab: data,
    })
    window.history.pushState(null, null, data)

    window.scrollTo({
      top: $(data).offset().top - 125,
      behavior: 'smooth',
    })

    if (data === '#trust') {
      //this.TrustDirectoryTabRef.current.profileHeader_itrustit_tab_click_Handle()
    } else if (data === '#friend') {
      //this.friendTabRef.current.profileHeader_friend_tab_click_Handle()
    } else if (data === '#member') {
      //this.memberTabRef.current.profileHeader_member_tab_click_Handle()
    } else if (data === '#gallery') {
      this.galleryTabRef.current.profileHeader_gallery_tab_click_Handle()
    } else if (data === '#overview') {
      this.overviewGenericTabRef.current.profileHeader_overview_tab_click_Handle()
    } else if (data === '#review') {
      //this.reviewTabRef.current.profileHeader_review_tab_click_Handle();
    } else if (data === '#orgs_total') {
      //this.orgsTotalTabRef.current.profileHeader_orgs_total_tab_click_Handle()
    }
  }

  showReviewTab = (data) => {
    window.scrollTo({
      top: $('#review').offset().top - 125,
      behavior: 'smooth',
    })

    this.setState({
      activeTab: '#review',
    })
    this.reviewTabRef.current.profileHeader_review_tab_click_Handle(data)
    window.history.pushState(null, null, '#review')
  }

  showReviewTab2 = (data) => {
    window.scrollTo({
      top: $('#review').offset().top - 125,
      behavior: 'smooth',
    })

    this.setState({
      activeTab: '#review',
    })
    this.reviewTabRef.current.profileHeader_review_tab_click_Handle(data)
    window.history.pushState(null, null, '#review')
  }

  profileHeader_itrustit_Handle = (total_records) => {
    this.setState({
      trust_directory_total_records: total_records,
    })
  }
  profileHeader_itrustit_recall_Handle = () => {
    this.TrustDirectoryTabRef.current.profileHeader_itrustit_tab_click_Handle()
    this.getProfileFromId()
  }

  profileHeader_friend_Handle = (total_records) => {
    this.setState({
      friend_total_records: total_records,
    })
  }
  profileHeader_friend_recall_Handle = () => {
    this.friendTabRef.current.profileHeader_friend_tab_click_Handle()
    this.getProfileFromId()
  }

  profileHeader_gallery_Handle = (total_records) => {
    this.setState({
      gallery_total_records: total_records,
    })
  }

  profileHeader_member_Handle = (total_records) => {
    this.setState({
      member_total_records: total_records,
    })
  }

  profileHeader_total_orgs_Handle = (total_records) => {
    //console.log({ total_records })
    this.setState({
      orgs_total_records: total_records,
    })
  }

  profileHeader_review_Handle = (overall_reviews, avg_reviews) => {
    this.setState({
      overall_reviews: overall_reviews,
      avg_reviews: avg_reviews,
    })
  }

  isLoginHandler = (data) => {
    this.props.isLogin(data)
  }
  render() {
    let self = this
    $('#login_modal').on('hidden.bs.modal', function (e) {
      self.setState({ isClaimloginText: false })
    })
    const { user, overall_reviews, avg_reviews } = this.state
    //  console.log("avg", avg_reviews);

    if (!user) {
      return null
    }

    let noPaddingContainer =
      isMobile && this.state.activeTab?.includes('posts') ? 'container body-container' : 'container'
    const mutualOrgsSection = (
      <div className='d-flex justify-content-center mt-3'>
        <div className='it-person-list-new it-person-list-main'>
          <p
            className='d-md-none d-block mutual-orgs-detail'
            style={{
              color: '#666666',
              fontSize: 16,
              fontFamily: 'Poppins',
              fontWeight: 500,
              marginBottom: 0,
            }}
          >
            Mutual Orgs
          </p>
          <p
            className='d-none d-md-block mutual-orgs-detail'
            style={{
              color: '#666666',
              fontSize: 16,
              fontFamily: 'Poppins',
              fontWeight: 500,
              marginBottom: 0,
            }}
          >
            Mutual Organizations
          </p>
          <div className='it-person mt-1 p-1'>
            <div className='digit pb-2'>
              <a
                data-bs-toggle='modal'
                data-bs-target='#login_modal'
                href={null}
                className='digit'
                style={{ color: '#136eb7', fontSize: 14 }}
              >
                Login or Sign up to see
              </a>
            </div>
          </div>
        </div>
      </div>
    )

    let userHeaderTrustSection = null
    if (user.user_type === 'individual') {
      userHeaderTrustSection = (
        <div className='i_trust_badge'>
          <div className='container'>
            <div className={'badge ' + user.user_type + '_profile'}>
              <div className='b-triangle' />
              <div className='b-inner'>
                <h4>iTRUST</h4>
                <div className='d-flex d1'>
                  <div className='col-6 digits_cat'>
                    <a
                      onClick={this.showiTrustitTab('business')}
                      className={
                        'nav-link ' +
                        (this.state.activeTab === '#trust' ? 'active digit_name' : 'digit_name')
                      }
                      data-bs-toggle='tab'
                      href='#trust'
                    >
                      <div className='digit'>{user.total_trusted_business}</div>
                      business
                    </a>
                  </div>
                  <div className='col-6 digits_cat'>
                    <a
                      onClick={this.showiTrustitTab('organization')}
                      className={
                        'nav-link ' +
                        (this.state.activeTab === '#trust' ? 'active digit_name' : 'digit_name')
                      }
                      data-bs-toggle='tab'
                      href='#trust'
                    >
                      <div className='digit'>{user.total_trusted_organizations}</div>
                      organization
                    </a>
                  </div>
                </div>
                {mutualOrgsSection}
              </div>
            </div>
          </div>
        </div>
      )
    } else {
      userHeaderTrustSection = (
        <div className='i_trust_badge'>
          <div className='container'>
            <div className={'badge ' + user.user_type + '_profile'}>
              <div className='b-triangle' />
              <div className='b-inner'>
                <h4>Trusted by</h4>
                <div className='d-flex d1'>
                  <div className='col-4 digits_cat'>
                    <div className='digit'>
                      <a
                        data-bs-toggle='modal'
                        data-bs-target='#login_modal'
                        href={null}
                        style={{ color: '#136eb7' }}
                        className='digit login-msg'
                      >
                        <SignupOrLoginText />
                      </a>
                    </div>
                    <div className='digit_name'>
                      <a
                        data-bs-toggle='modal'
                        data-bs-target='#login_modal'
                        href={null}
                        className='digit_name'
                      >
                        friends
                      </a>
                    </div>
                  </div>
                  <div className='col-4 digits_cat'>
                    <div style={{ color: '#136eb7' }} className='digit'>
                      <a
                        data-bs-toggle='modal'
                        data-bs-target='#login_modal'
                        href={null}
                        style={{ color: '#136eb7' }}
                        className='digit login-msg'
                      >
                        <SignupOrLoginText />
                      </a>
                    </div>
                    <div className='digit_name'>
                      <a
                        data-bs-toggle='modal'
                        data-bs-target='#login_modal'
                        href={null}
                        className='digit_name'
                      >
                        friends of friends
                      </a>
                    </div>
                  </div>
                  <div className='col-4 digits_cat'>
                    <div style={{ color: '#136eb7' }} className='digit'>
                      <a
                        onClick={() => this.showTrustDirectoryTab('')}
                        style={{ color: '#136eb7' }}
                        className={'digit'}
                        // data-bs-toggle='tab'
                        // data-bs-target='#trust'
                        href='#trust'
                      >
                        {user.total_trusted}
                      </a>
                    </div>
                    <div className='digit_name'>
                      <a
                        onClick={() => this.showTrustDirectoryTab('')}
                        className={'digit_name'}
                        // data-bs-toggle='tab'
                        // data-bs-target='#trust'
                        href='#trust'
                      >
                        total
                      </a>
                    </div>
                  </div>
                </div>
                {mutualOrgsSection}
              </div>
            </div>
          </div>
        </div>
      )
    }

    return (
      <AppLayout>
        <Helmet>
          <title>{this.state.documentTitle}</title>
        </Helmet>
        {this.state.isSettingToShowProfilePage ? (
          <div style={{ background: '#f6f6f6' }}>
            <section
              className='cover_details pro_business my_profile'
              style={{
                background:
                  'url(' +
                  (this.state.user &&
                  typeof this.state.user.cover_picture_medium !== 'undefined' &&
                  this.state.user.cover_picture_medium
                    ? this.state.user.cover_picture_medium
                    : NO_COVER_PICTURE) +
                  ') no-repeat',
              }}
            >
              {userHeaderTrustSection}
            </section>
            <div className='container profile-info-container'>
              <div className='cover-inner-wrapper'>
                <div
                  className={`cover_inner ${
                    this.state.user.is_business_claimed !== '0' &&
                    this.state.user?.show_unclaimed_disclaimer === '1'
                      ? 'mb-3 mb-md-0'
                      : ''
                  }`}
                >
                  {/* <ProfileHeader
                    user={this.state.user}
                    itrustitHandle={this.itrustitHandle}
                    friendAddRemoveHandle={this.friendAddRemoveHandle}
                    memberAddRemoveHandle={this.memberAddRemoveHandle}
                    changeActiveTab={this.changeActiveTab}
                  /> */}
                  <ProfileHeader user={this.state.user} isLoggedIn={false} />
                  <div className='cover_bottom_wrapper position-relative'>
                    <div className='cover_bottom' ref={this.scrollRef}>
                      <div className='left_link'>
                        <div
                          className='position-absolute profile-scroll-btn'
                          style={{ top: 9, left: 4, zIndex: 2000 }}
                        >
                          <button
                            className='promotion-back-button'
                            onClick={() => (this.scrollRef.current.scrollLeft -= 100)}
                          >
                            <i
                              className='fas fa-chevron-left'
                              aria-hidden='true'
                              style={{ color: '#999' }}
                            ></i>
                          </button>
                        </div>
                        <ul id='profile-tabs' className='upper-tabs nav nav-tabs'>
                          <li className='nav-item'>
                            <a
                              onClick={this.changeActiveTab('#overview')}
                              className={
                                'nav-link ' + (this.state.activeTab === '#overview' ? 'active' : '')
                              }
                              data-bs-toggle='tab'
                              href='#overview'
                            >
                              Overview
                            </a>
                          </li>
                          {isPostTabVisible(this?.state?.user) ? (
                            <li className='nav-item'>
                              <a
                                onClick={this.changeActiveTab('#posts')}
                                className={
                                  'nav-link ' + (this.state.activeTab === '#posts' ? 'active' : '')
                                }
                                data-bs-toggle='tab'
                                href='#posts'
                              >
                                Posts
                              </a>
                            </li>
                          ) : (
                            ''
                          )}
                          <li className='nav-item'>
                            <a
                              onClick={this.changeActiveTab('#trust')}
                              className={
                                'nav-link ' + (this.state.activeTab === '#trust' ? 'active' : '')
                              }
                              data-bs-toggle='tab'
                              data-bs-target='#trust'
                              href='#trust'
                            >
                              {' '}
                              Trust List ({this.state.trust_directory_total_records})
                            </a>
                          </li>
                          {user.user_type === 'organization' && (
                            <li className='nav-item'>
                              <a
                                onClick={this.changeActiveTab('#member')}
                                className={
                                  'nav-link ' + (this.state.activeTab === '#member' ? 'active' : '')
                                }
                                data-bs-toggle='tab'
                                href='#member'
                              >
                                {formatMemberDirectoryName(
                                  this.state.user_settings?.member_directory_name,
                                  this.state.user_settings?.custom_member_directory_name,
                                ) ?? 'Member Directory'}{' '}
                                ({this.state.member_total_records})
                              </a>
                            </li>
                          )}
                          <li className='nav-item'>
                            <a
                              onClick={this.changeActiveTab('#friend')}
                              className={
                                'nav-link ' + (this.state.activeTab === '#friend' ? 'active' : '')
                              }
                              data-bs-toggle='tab'
                              href='#friend'
                            >
                              Friends ({this.state.friend_total_records})
                            </a>
                          </li>

                          <li className='nav-item'>
                            <a
                              onClick={this.changeActiveTab('#orgs_total')}
                              className={
                                'nav-link ' +
                                (this.state.activeTab === '#orgs_total' ? 'active' : '')
                              }
                              data-bs-toggle='tab'
                              href='#orgs_total'
                            >
                              {/* Orgs Total */}
                              Orgs ({this.state.orgs_total_records})
                            </a>
                          </li>

                          <li className='nav-item me-0'>
                            <a
                              onClick={this.changeActiveTab('#gallery')}
                              className={
                                'nav-link ' + (this.state.activeTab === '#gallery' ? 'active' : '')
                              }
                              data-bs-toggle='tab'
                              href='#gallery'
                            >
                              Gallery ({this.state.gallery_total_records})
                            </a>
                          </li>
                        </ul>
                        <div
                          className='position-absolute profile-scroll-btn'
                          style={{ top: 9, right: 4, zIndex: 2000 }}
                        >
                          <button
                            className='promotion-back-button'
                            onClick={() => (this.scrollRef.current.scrollLeft += 100)}
                          >
                            <i
                              className='fas fa-chevron-right'
                              aria-hidden='true'
                              style={{ color: '#999' }}
                            ></i>
                          </button>
                        </div>
                      </div>
                      <ProfileReviewStars
                        user={user}
                        avg_reviews={avg_reviews}
                        overall_reviews={overall_reviews}
                        showReviewTab={this.showReviewTab}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <section
              className={`listing_block ${
                this.state.user?.show_unclaimed_disclaimer === '1' ? ' unclaimed-user' : ''
              } ${this.state.user.is_business_claimed === '0' ? 'unclaimed' : ''} padding-reset`}
            >
              <div
                className={`${noPaddingContainer} ${
                  this.state.user?.show_unclaimed_disclaimer === '1' ||
                  (isMobile && this.state.activeTab === '#overview')
                    ? 'mt-2'
                    : 'mt-4'
                }`}
              >
                {this.state.user?.show_unclaimed_disclaimer === '1' ? (
                  <UnclaimedDisclaimer fullname={this.state.user?.fullname} />
                ) : isMobile && this.state.activeTab === '#overview' ? (
                  <ISupport user={this.state.user} className='ms-2' />
                ) : null}
                <div
                  className={`tab-content ${
                    this.state.user?.show_unclaimed_disclaimer === '1' ||
                    (isMobile && this.state.activeTab === '#overview')
                      ? 'mt-2'
                      : 'mt-4'
                  }`}
                >
                  <OverviewGenericTab
                    user_id={this.state.user_id}
                    user={this.state.user}
                    activeTab={this.state.activeTab}
                    ref={this.overviewGenericTabRef}
                    showReviewTab2={this.showReviewTab2}
                    changeActiveTab={this.changeActiveTab}
                    showFriendsTab={this.showFriendsTab}
                    loginModalOpen={this.loginModalOpenTab}
                    user_settings={this.state.user_settings}
                    post_id={this.state.post_id}
                  />
                  <TrustDirectoryTab
                    user_id={this.state.user_id}
                    user={this.state.user}
                    user_type={this.state.user.user_type}
                    activeTab={this.state.activeTab}
                    ref={this.TrustDirectoryTabRef}
                    profileHeader_itrustit_Handle={this.profileHeader_itrustit_Handle}
                    profileHeader_friend_recall_Handle={this.profileHeader_friend_recall_Handle}
                    itrustitHandle={this.itrustitHandle}
                    user_settings={this.state.user_settings}
                    changeActiveTab={this.changeActiveTab}
                  />
                  <GalleryTab
                    user_id={this.state.user_id}
                    user={this.state.user}
                    activeTab={this.state.activeTab}
                    ref={this.galleryTabRef}
                    profileHeader_gallery_Handle={this.profileHeader_gallery_Handle}
                    user_settings={this.state.user_settings}
                  />

                  <FriendsTab
                    user_id={this.state.user_id}
                    user={this.state.user}
                    activeTab={this.state.activeTab}
                    ref={this.friendTabRef}
                    profileHeader_friend_Handle={this.profileHeader_friend_Handle}
                    profileHeader_itrustit_recall_Handle={this.profileHeader_itrustit_recall_Handle}
                    user_settings={this.state.user_settings}
                  />
                  <OrgsTotalTab
                    user_id={this.state.user_id}
                    user={this.state.user}
                    activeTab={this.state.activeTab}
                    ref={this.orgsTotalTabRef}
                    profileHeader_total_orgs_Handle={this.profileHeader_total_orgs_Handle}
                    profileHeader_friend_Handle={this.profileHeader_friendfriend_Handle}
                    profileHeader_itrustit_recall_Handle={this.profileHeader_itrustit_recall_Handle}
                    user_settings={this.state.user_settings}
                  />
                  {user.user_type === 'organization' ? (
                    <MemberTab
                      user_id={this.state.user_id}
                      user={this.state.user}
                      activeTab={this.state.activeTab}
                      ref={this.memberTabRef}
                      profileHeader_member_Handle={this.profileHeader_member_Handle}
                      profileHeader_friend_recall_Handle={this.profileHeader_friend_recall_Handle}
                      profileHeader_itrustit_recall_Handle={
                        this.profileHeader_itrustit_recall_Handle
                      }
                      profileHeader_member_recall_Handle={this.profileHeader_member_recall_Handle}
                      user_settings={this.state.user_settings}
                    />
                  ) : (
                    ''
                    // <Permission />
                  )}

                  <ReviewTab
                    user_id={this.state.user_id}
                    user={this.state.user}
                    activeTab={this.state.activeTab}
                    ref={this.reviewTabRef}
                    profileHeader_review_Handle={this.profileHeader_review_Handle}
                  />
                </div>
              </div>
            </section>
            {this.state.user && this.state.user.user_type !== 'individual' && (
              <ReviewCreate
                user_id={this.state.user_id}
                user={this.state.userOther}
                reviewCreatedSuccessfullyHandler={this.reviewCreatedSuccessfullyHandler}
                otherUser={true}
                user_type={this.state.user.user_type}
              />
            )}
            <LoginModal
              imatrix_id={this.state.user?.imatrix_id}
              isLogin={this.isLoginHandler}
              {...this.props}
              isClaimloginText={this.state.isClaimloginText}
            />
          </div>
        ) : (
          <div className='it-no-access'>
            <div className='bg-white'>
              <div className=' inner-tab tab-content'>
                <div className='search-section friend_list_block'>
                  <div className='no_search_result'>
                    <img src='/img/hide-frd.svg' className='pt-5 m-auto d-block' alt='' />
                    <h2>You Don't Have Access</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </AppLayout>
    )
  }
}
function mapStateToProps(state) {
  return {
    reviewuser: state.UserReducer.reviewuser,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ reviewUser }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(ProfileOtherBeforeLogin)
