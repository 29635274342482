import React from 'react'

const VCardAppIcon = React.forwardRef(({ user }, ref) => {
  return (
    <div
      ref={ref}
      className='vcard-app-icon my-2 d-flex flex-column align-items-center'
      style={{ padding: '10px 16px' }}
    >
      <div className={`vcard-avatar ${user.user_type} in-modal`}>
        <img
          src={user?.profile_picture_medium ?? process.env.PUBLIC_URL + '/img/iti_bug.png'}
          height='auto'
          alt=''
          className='vcard-avatarImg'
        />
      </div>
      <img
        src={`${process.env.PUBLIC_URL}/img/logo_basic-lg.png`}
        style={{ marginTop: 2 }}
        width={64}
        height='auto'
        alt=''
      />
    </div>
  )
})

export default VCardAppIcon
