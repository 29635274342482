import React from 'react'
import { Link } from 'react-router-dom'
import { kFormatter } from '../../../../services/customFunction'
import {
  HOME_ROUTE,
  LOGIN_ROUTE,
  PROFILE_ROUTE,
  ROOT_ROUTE,
  SEARCH_ROUTE,
} from '../../../../services/routeConst'

const NavBarLink = ({ className = '', to, icon, label, children = null }) => (
  <li className={'nav-item ' + className}>
    <Link className='nav-link' to={to}>
      <img src={process.env.PUBLIC_URL + icon} alt={label} />
      <span>{label}</span>
    </Link>
    {children}
  </li>
)

const MobileNavBar = ({ isLoggedIn, chat_users_list, routeActive }) => {
  const totalUnreadMessages = chat_users_list?.reduce?.(
    (acc, curr) => (acc += curr?.total_unread),
    0,
  )
  return (
    <ul
      className={`mobile-header-nav navbar-nav${isLoggedIn ? '' : ' non-loggedin-nav'}${
        routeActive === SEARCH_ROUTE ? 'on-search-page' : ''
      }`}
    >
      {routeActive === SEARCH_ROUTE ? (
        <NavBarLink
          to={isLoggedIn ? HOME_ROUTE : ROOT_ROUTE}
          icon='/img/new_icons/home.svg'
          label='Home'
        />
      ) : null}
      <NavBarLink
        to={{ pathname: SEARCH_ROUTE, state: { resetSearch: true } }}
        icon='/img/new_icons/search.svg'
        label='Search'
      />
      {isLoggedIn ? (
        <NavBarLink
          to={process.env.PUBLIC_URL + '/message'}
          icon='/img/new_icons/message.svg'
          label='iTi Message'
          className='position-relative'
        >
          <span
            className={totalUnreadMessages !== 0 ? 'iteam-notif-count' : ''}
            style={{
              top: '50%',
              left: '50%',
              marginLeft: 3,
              marginTop: -26,
              right: 'unset',
            }}
          >
            {totalUnreadMessages !== 0 ? kFormatter(totalUnreadMessages) : ''}
          </span>
        </NavBarLink>
      ) : null}
      <NavBarLink
        className='p-0'
        to={isLoggedIn ? PROFILE_ROUTE : LOGIN_ROUTE}
        icon='/img/new_icons/user.svg'
        label={isLoggedIn ? 'Me' : 'Login'}
      />
    </ul>
  )
}

export default MobileNavBar
