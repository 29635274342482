import React from 'react'
import ShareOptions from '../CommonComponents/ShareModal/ShareOptions'

const InviteOwnerToClaim = ({ loginUserData, user, profileLink }) => {
  return (
    <ShareOptions
      loginUserData={loginUserData}
      user={user}
      setModalState={() => {}}
      shareLink={profileLink}
      isAdd
    />
  )
}

export default InviteOwnerToClaim
