import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  deleteReply,
  postReply,
  setCommentReplies,
  updateComment,
  updateCommentReaction,
} from '../../../actions/socialmedia'
import {
  AUTHOR,
  DEFAULT_PROFILE_PICTURE,
  IMG_PATH,
  WALL_DELETE_COMMENT,
} from '../../../services/const'
import { dateStringToTimeSince, getUser } from '../../../services/customFunction'
import {
  commentUpdateCall,
  replyCreateCall,
  replyDeleteCall,
  replyListCall,
} from '../../../services/socialMediaApiService'
import SearchPageLoadingIcon from '../../Search/SearchPageLoadingIcon'
import NewComment from '../NewComment'
import ReactionTypes from '../ReactionTypes'
import Reply from '../Reply'
import Reactions from '../Reactions'
import ReportContent from '../ReportContent'
import { setOverFlow } from '../../../services/customFunction'

import './Comment.scss'
import SocialShowMoreText from '../SocialShowMoreText'
import { DISABLE_SOCIAL_MEDIA_REPORT } from '../../../services/environment'
import Dialogs from '../Dialogs'

const Comment = ({ entry, post, deleteFunc, ...props }) => {
  //console.log('Comment:', entry, props)
  const login_user_id = getUser()?.id
  const [apiData, setApiData] = useState({
    currentPage: 1,
    lastPage: 1,
    order_type: 1,
  })

  const [status, setStatus] = useState('idle')
  const [addReply, setAddReply] = useState(false)
  const [editComment, setEditComment] = useState(false)
  const [getReplies, setGetReplies] = useState(true)
  const [showReactionsModal, setShowReactionsModal] = useState(false)
  const [showReportDataModal, setShowReportDataModal] = useState(false)
  const [showDeleteCommentDialog, setShowDeleteCommentDialog] = useState(false)
  const textInput = useRef(null)
  const [isLoggedUser, setIsLoggedUser] = useState(login_user_id !== undefined)
  const [loginModalProps, setLoginModalProps] = useState(
    !isLoggedUser
      ? {
          'data-bs-toggle': 'modal',
          'data-bs-target': '#login_modal',
        }
      : {},
  )
  const convertComment = entry?.comment_body
  const request = {
    user_id: login_user_id,
    entry_id: entry?.id,
    entry_type: 'comment',
  }
  const reactionTotals = entry?.reaction_data?.reaction_totals
  const [loginUserReaction, setLoginUserReaction] = useState(
    entry?.reaction_data?.relevant_reactions?.your_reaction,
  )

  useEffect(() => {
    if (textInput.current) textInput.current.focus()
  }, [textInput])

  const onCloseReactionButtonClick = () => {
    setShowReactionsModal(false)
  }
  const onOpenReportContentButtonClick = () => {
    setShowReportDataModal(true)
  }
  const onCloseReportContentButtonClick = () => {
    setShowReportDataModal(false)
  }
  const onNoModifyCommentDialog = () => {
    setShowDeleteCommentDialog(false)
  }

  const onYesModifyCommentDialog = () => {
    deleteFunc(entry?.id)
    setShowDeleteCommentDialog(false)
  }
  //
  //reply API's calls
  const repliesListRender = async (currentPage = 0) => {
    setStatus('loading')
    const response = await replyListCall({
      comment_id: entry?.id,
      page: currentPage !== 0 ? currentPage : apiData?.currentPage,
      login_user_id: login_user_id,
    })
    if (response?.status === 200) {
      props?.setCommentReplies({
        id: post?.id,
        comment_id: entry?.id,
        replies: response?.data?.data?.replies,
      })
      setApiData((st) => ({
        currentPage: response?.data?.data?.current_page + 1,
        lastPage: response?.data?.data?.last_page,
      }))
    }
    setGetReplies(false)
    setStatus('idle')
  }
  //reply delete API
  const deleteReply = async (id) => {
    setStatus('loading')
    const response = await replyDeleteCall({
      id: id,
    })
    if (response?.status === 200) {
      props?.deleteReply({ id: post?.id, data: response?.data?.data })
    }
    setStatus('idle')
  }
  const cancelSubmitHandler = () => {
    setEditComment(false)
  }
  const cancelAddReplyHandler = () => {
    setAddReply(false)
  }
  //comment API
  //click entry Handler
  const clickReplyHandler = (event) => {
    if (isLoggedUser && post?.comment_type_id !== 3) setAddReply(true)
    if (getReplies) repliesListRender() //don't reload if already loaded
  }

  const addReplyCommentHandler = async (comment_body) => {
    let response = await replyCreateCall({
      comment_body: comment_body,
      comment_id: entry?.id,
    })
    if (response?.status === 200) {
      props?.postReply({ id: post?.id, data: response?.data?.data })
      setApiData((st) => ({
        currentPage: st.currentPage,
        lastPage: st.lastPage,
      }))
      setAddReply(false)
    }
  }

  const clickReactionHandler = async (data) => {
    props?.updateCommentReaction({ post_id: post?.id, id: entry?.id, data })
    setLoginUserReaction(entry?.reaction_data?.relevant_reactions?.your_reaction)
  }

  const updateCommentHandler = async (comment_body) => {
    const response = await commentUpdateCall({
      id: entry?.id,
      comment_body: comment_body,
      post_id: post?.id,
    })
    if (response?.status === 200) {
      props?.updateComment(response?.data?.data)
      let comment = response?.data?.data?.comment
      entry.comment_body = comment_body
      entry.updated_at = comment?.updated_at
    }
    setEditComment(false)
  }

  const scrollToTop = () =>
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })

  let total_replies = entry?.total_replies
  setOverFlow(showReactionsModal || showReportDataModal || showDeleteCommentDialog)
  return (
    <div className='container entryComment'>
      <div className='commentTop'>
        <div className='d-flex'>
          <div className='postSmallPicture'>
            <img
              className='img_block'
              src={entry?.profile_picture_small ?? DEFAULT_PROFILE_PICTURE}
              alt=''
            />
          </div>

          <div className='wallFlexColumnLeft overFlowHidden'>
            <a className='postUsername' href={process.env.PUBLIC_URL + '/' + entry?.slug}>
              {entry?.fullname}
            </a>
            {console.log('entry:', entry?.created_at === entry?.updated_at, entry)}
            <span className='postDate'>
              {dateStringToTimeSince(entry?.updated_at, true)?.replace('ago', '')}
              {entry?.created_at === entry?.updated_at ? '' : 'Edited'}
            </span>
          </div>
        </div>

        {entry?.user_id === post?.user_id ? (
          <span className='postAuthor'>{AUTHOR}</span>
        ) : (
          <div></div>
        )}

        <div class='commentActionButton'>
          <button
            class='btn dropdown-toggle'
            type='button'
            id='dropdownCommentMenu'
            data-bs-toggle='dropdown'
            aria-expanded='false'
          >
            ...
          </button>
          <ul class='dropdown-menu' aria-labelledby='dropdownCommentMenu'>
            {/*we can report any entry except ours*/}
            {login_user_id !== entry?.user_id && (
              <li>
                <button
                  class='dropdown-item'
                  {...loginModalProps}
                  onClick={() => isLoggedUser && onOpenReportContentButtonClick()}
                  disabled={DISABLE_SOCIAL_MEDIA_REPORT}
                >
                  <img src={IMG_PATH + 'report.svg'} className='likeIcon link' alt='' />
                  Report this comment
                </button>
              </li>
            )}
            {/*if is the login user comment, s/he can edit it*/}
            {login_user_id === entry?.user_id && (
              <li>
                <button class='dropdown-item' onClick={() => setEditComment(true)}>
                  <img src={IMG_PATH + 'edit.svg'} className='likeIcon link' alt='' />
                  Edit Comment
                </button>
              </li>
            )}
            {/*if is login user post he can delete the comment or if it is login user comment*/}
            {(login_user_id === post?.user_id || login_user_id === entry?.user_id) && (
              <li>
                <button class='dropdown-item' onClick={() => setShowDeleteCommentDialog(true)}>
                  <img src={IMG_PATH + 'delete.svg'} className='likeIcon link' alt='' />
                  Delete Comment
                </button>
              </li>
            )}
          </ul>
        </div>
      </div>
      <div className='commentText'>
        {editComment ? (
          <>
            <div className='updateComment'>
              <div className='updateCommentText'>
                <NewComment
                  entry={entry}
                  addHandler={updateCommentHandler}
                  cancelSubmitHandler={cancelSubmitHandler}
                ></NewComment>
              </div>
            </div>
          </>
        ) : (
          <div className='grayContainer'>
            <SocialShowMoreText textToShow={convertComment}></SocialShowMoreText>
          </div>
        )}
      </div>
      <div className='commentBottom'>
        <div className='postBottomLeft'>
          {isLoggedUser ? (
            <>
              <ReactionTypes
                handleReaction={clickReactionHandler}
                loginUserReaction={loginUserReaction}
                loginModalProps={loginModalProps}
                reactionData={entry?.reaction_data?.relevant_reactions?.your_reaction}
                request={request}
                post_id={post?.id}
              />
            </>
          ) : (
            ''
          )}
          {entry?.total_reactions > 0 ? (
            <div className='postBottomCenter'>
              {/*console.log('reactionTypes:', reactionTypes)*/}
              <button
                className='postButton'
                type='button'
                onClick={() => {
                  setShowReactionsModal(true)
                }}
              >
                {reactionTotals?.map((r) => {
                  let img = IMG_PATH + r.icon
                  return <img src={img} className='likeSmallIcon link ' alt='' />
                })}
                {entry?.total_reactions > 0 ? (
                  <span className='postLikeCounter'>{entry?.total_reactions}</span>
                ) : (
                  ''
                )}
              </button>
            </div>
          ) : (
            <div></div>
          )}
        </div>
        <div className='commentBottomRight'>
          {!(total_replies === 0 && post?.comment_type_id === 3) ? (
            <>
              <button className='postButton' onClick={clickReplyHandler}>
                <span className='buttonSpan'>Reply</span>
              </button>
            </>
          ) : (
            ''
          )}{' '}
          {total_replies > 0 ? (
            <>
              <span className='replyButton'>
                {total_replies} Repl{total_replies > 1 ? 'ies' : 'y'}
              </span>
            </>
          ) : (
            <div></div>
          )}
        </div>
      </div>
      <div>
        {addReply ? (
          <NewComment
            id={entry?.id}
            submitBtnText='Reply'
            addHandler={addReplyCommentHandler}
            cancelSubmitHandler={cancelAddReplyHandler}
          />
        ) : (
          ''
        )}
      </div>
      <div className='entryReplies'>
        {entry?.replies?.map((r) => {
          return (
            <Reply
              key={r.id}
              entry={r}
              login_user_id={login_user_id}
              post_user_id={post?.user_id}
              post_id={post?.id}
              deleteFunc={deleteReply}
            />
          )
        })}
        <div className='d-flex load-more-btns'>
          {status === 'loading' ? (
            <SearchPageLoadingIcon
              className='pagination-loading'
              styles={{
                height: 'auto',
                marginTop: entry?.replies?.length === 0 ? 30 : 0,
              }}
              iconStyles={{ height: 38, transform: 'scale(1.5)' }}
            />
          ) : apiData?.currentPage <= apiData?.lastPage && apiData?.lastPage > 1 ? (
            <button className='btn mx-auto' onClick={() => repliesListRender()}>
              Load more replies
            </button>
          ) : apiData?.postsList?.length > 0 ? (
            <button className='btn mx-auto text-muted back-to-top' onClick={scrollToTop}>
              Back to top
            </button>
          ) : null}
        </div>
      </div>
      {showReactionsModal && (
        <Reactions request={request} onCloseButtonClick={onCloseReactionButtonClick} />
      )}
      {showReportDataModal && (
        <ReportContent request={request} onCloseButtonClick={onCloseReportContentButtonClick} />
      )}
      {showDeleteCommentDialog && (
        <Dialogs
          title={'Delete comment'}
          question={WALL_DELETE_COMMENT}
          onYesCloseDialog={onYesModifyCommentDialog}
          onNoCloseDialog={onNoModifyCommentDialog}
        />
      )}
    </div>
  )
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateComment,
      updateCommentReaction,
      setCommentReplies,
      postReply,
      deleteReply,
    },
    dispatch,
  )
}
export default connect(null, mapDispatchToProps)(Comment)
