import React, { Component, Fragment } from 'react'
import { Link, Redirect } from 'react-router-dom'
import * as route from '../../services/routeConst'
import { isUserLogin, userToken } from '../../services/customFunction'
import { withRouter } from 'react-router'
import classNames from 'classnames'
import './Header.scss'
import {
  PROFILE_PICTURE_SMALL_URL_PATH,
  GOOGLE_GEOCODING_API_KEY,
} from '../../services/environment'

import {
  DEFAULT_PROFILE_PICTURE,
  REQUIRED_MESSAGE_FOR_SEARCH,
  REQUIRED_MESSAGE_FOR_SEARCH_NO_ORG,
} from '../../services/const'
import GoogleAutocomplete from 'react-google-autocomplete'
import axios from 'axios'
import { customLogger } from '../../utils/CustomLogger'
import { runElasticSearch, runElasticSearchCategories } from '../../utils/elasticSearch'
import {
  headerSearchBarSet,
  headerSearchBarReset,
  headerSearchBarValue,
  headerSearchBarValOnChange,
  headerSearchBarLocChange,
  headerSearchBarClicked,
  headerSearchBarElasticResults,
} from '../../actions/profile'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { toaster } from '../../services/customFunction'
import { Transition } from 'react-transition-group'

/*eslint-disable no-script-url*/
var cityObj = []

class Header extends Component {
  constructor(props) {
    super(props)

    this.inputRef = React.createRef()
    this.locFieldRef = React.createRef()
    this.clickedLocSuggestionRef = React.createRef(false)
    this.state = {
      isRedirect: false,
      userImg: DEFAULT_PROFILE_PICTURE,
      user: [],
      routeToRedirect: '',
      businessOrganizationNameSearch: '',
      citySearch: '',
      isSearchPage: false,

      individualBusinessOrganization: '',
      isIndividualBusinessOrganizationDisplay: false,
      individualBusinessOrganizationData: [],

      chat_users_id: [],
      chat_users_list: [],
      chat_users_online_list: [],

      user_notifications: [],
      showLocationField: false,
    }
  }

  componentDidMount() {
    if (this.props.routeActive === '/search') {
      let businessName = localStorage.getItem('businessOrganizationNameSearch')
      let citySearch = localStorage.getItem('citySearch')
      citySearch = citySearch == null ? '' : citySearch
      businessName = businessName == null ? '' : businessName
      !citySearch && this.getCurrentLocationString()
      this.setState({
        businessOrganizationNameSearch: businessName,
        citySearch: citySearch,
        isSearchPage: true,
      })
    } else {
      //localStorage.removeItem("businessOrganizationNameSearch");
      //localStorage.removeItem("citySearch");
    }

    if (this.isIE()) {
      document.body.classList.add('it-ie-brower')
    }
  }

  isIE() {
    let ua = navigator.userAgent
    /* MSIE used to detect old browsers and Trident used to newer ones*/
    var is_ie = ua.indexOf('MSIE ') > -1 || ua.indexOf('Trident/') > -1

    return is_ie
  }

  setRedirect =
    (routeToRedirect, clickedSearchIconOnNav = false) =>
    (e) => {
      // console.log(clickedSearchIconOnNav)
      if (
        this.state.businessOrganizationNameSearch !== '' &&
        this.state.citySearch !== '' &&
        this.state.businessOrganizationNameSearch?.length >= 2
      ) {
        const businessSearch = this.state.businessOrganizationNameSearch
        const citySearch = this.state.citySearch
        setTimeout(() => {
          localStorage.setItem('businessOrganizationNameSearch', businessSearch)
          this.setState({ showLocationField: false })
          this.props.headerSearchBarClicked({
            value: false,
            search: businessSearch,
          })
          // console.log('SETREDIRECT', citySearch)
          localStorage.setItem('citySearch', citySearch)
          this.props.headerSearchBarValue({
            businessOrganizationNameSearch: businessSearch,
            citySearch: citySearch,
            isSearch: true,
          })
          if (!this.state.isSearchPage) {
            this.props.history.push({
              pathname: routeToRedirect,
            })
          }
          this.inputRef.current && this.inputRef.current.blur()
        }, 150)
      } else {
        if (!this.state.citySearch) {
          toaster('error', REQUIRED_MESSAGE_FOR_SEARCH)
        } else if (
          this.state.individualBusinessOrganization === '' ||
          this.state.businessOrganizationNameSearch?.length < 2
        ) {
          toaster('error', REQUIRED_MESSAGE_FOR_SEARCH_NO_ORG)
        }
      }
    }

  renderRedirect = () => {
    if (this.state.routeToRedirect) {
      return <Redirect to={this.state.routeToRedirect} />
    }
  }

  searchChangeHandler = (event) => {
    this.setState({ [event.target.name]: event.target.value })
  }

  individualBusinessOrganizationChangeHandler = (event) => {
    let searchStr = event.target.value
    if (searchStr.length >= 2) {
      searchStr = searchStr.replace(/[\u2018\u2019]/g, "'").replace(/[\u201C\u201D]/g, '"')
      searchStr = searchStr.replace(/(')\1{1,}/, "'")
      searchStr = searchStr.replace(/(")\1{1,}/, `"`)
      //   searchStr = searchStr.trim();
    }
    this.setState(
      {
        individualBusinessOrganization: searchStr,
        businessOrganizationNameSearch: searchStr,
      },
      () => {
        // this.getSearchResult();
        this.getSearchResultForCategory()
      },
    )
  }

  getSearchResultForCategory = async () => {
    runElasticSearchCategories(this.state.individualBusinessOrganization, (results) => {
      this.setState({ individualBusinessOrganizationData: results }, () => {
        this.syncLocalStorageWithSearchResults(results)
        this.getSearchResult()
      })
    })
  }

  async getSearchResult() {
    var searchStr = this.state.individualBusinessOrganization
    if (this.state.citySearch.length === 0) cityObj = []

    if (searchStr.length >= 2) {
      const response = await runElasticSearch(
        undefined,
        { search_string: searchStr, search_city_string: this.state.citySearch, maxResults: 25 },
        false,
        this.state.individualBusinessOrganizationData,
      )
      response?.error && toaster('error', response?.error)
      this.setState({
        individualBusinessOrganizationData:
          response?.status === 'failed' && !response?.error ? [] : response ?? [],
      })
    } else {
      this.setState({ individualBusinessOrganizationData: [] })
    }
  }

  onIndividualBusinessOrganizationFocus = (event, isMobile = true) => {
    if (isMobile) this.clickedLocSuggestionRef.current = false
    //console.log('in onIndividualBusinessOrganizationFocus');
    isMobile && this.props.headerSearchBarClicked({ value: true, search: '' })
    this.setState({
      showLocationField: isMobile,
      isIndividualBusinessOrganizationDisplay: true,
    })
  }

  onIndividualBusinessOrganizationBlur = (event, isMobile = true) => {
    const clickedLocField = event.relatedTarget
      ? event.relatedTarget.name === 'searchAddress'
      : false
    const nextState = { isIndividualBusinessOrganizationDisplay: false }
    if (!clickedLocField && isMobile) {
      this.props.headerSearchBarClicked({ value: false, search: '' })
      nextState.showLocationField = false
    }
    var self = this
    setTimeout(function () {
      self.setState(nextState)
    }, 150)
  }

  searchGoBack = () => {
    // console.log('hi');
    this.setState({
      showLocationField: false,
      isIndividualBusinessOrganizationDisplay: false,
      businessOrganizationNameSearch: '',
      citySearch: '',
    })
    this.props.headerSearchBarClicked({ value: false, search: '', reset: true })
    this.props.headerSearchBarReset({
      businessOrganizationNameSearch: true,
    })
    this.props.headerSearchBarLocChange('')
    localStorage.removeItem('businessOrganizationNameSearch')
    localStorage.removeItem('citySearch')
    this.props.headerSearchBarValue({})
    this.syncLocalStorageWithSearchResults([])
  }

  handleLocFieldBlur = (event) => {
    // console.log(
    //   event.currentTarget,
    //   event.relatedTarget,
    //   event.relatedTarget && event.relatedTarget.name === 'individualBusinessOrganization'
    // );
    const clickedLocField = event.relatedTarget
      ? event.relatedTarget.name === 'individualBusinessOrganization'
      : false
    const nextState = { isIndividualBusinessOrganizationDisplay: false }
    setTimeout(() => {
      // console.log(clickedLocField);
      if (!this.clickedLocSuggestionRef.current && !clickedLocField) {
        this.props.headerSearchBarClicked({ value: false, search: '' })
        nextState.showLocationField = false
      }
      const self = this
      setTimeout(function () {
        self?.inputRef?.current &&
          self.setState({
            ...nextState,
            businessOrganizationNameSearch: self.inputRef.current.value,
          })
      }, 150)
    }, 150)
  }

  individualBusinessOrganizationClicked = (data) => (event) => {
    //console.log('in individualBusinessOrganizationClicked: ', data);
    this.setState(
      {
        individualBusinessOrganization: data.name,
        businessOrganizationNameSearch: data.name,
      },
      () => {
        this.getSearchResult()
      },
    )
  }

  handleChangeGoogle = (event, isMobile = true) => {
    if (event.address_components) {
      let address_component = event.address_components

      let zipcode = address_component.filter((address) => {
        return address.types[0] === 'postal_code'
      })

      let cityName = address_component.filter((address) => {
        return address.types[0] === 'locality'
      })

      let stateName = address_component.filter((address) => {
        return address.types[0] === 'administrative_area_level_1'
      })

      cityObj = []
      if (zipcode.length > 0) {
        cityObj.push({ match: { zipcode: zipcode[0].long_name } })
      }
      if (cityName.length > 0) {
        cityObj.push({ match: { city: cityName[0].long_name } })
      }
      if (stateName.length > 0) {
        cityObj.push({ match: { state: stateName[0].long_name } })
      }
    }
    if (isMobile) {
      this.inputRef.current.focus()
      this.clickedLocSuggestionRef.current = true
      this.props.headerSearchBarLocChange(
        event.formatted_address ? event.formatted_address : event.name,
      )
      isMobile && this.props.headerSearchBarClicked({ value: true, search: '' })

      localStorage.setItem(
        'citySearch',
        event.formatted_address ? event.formatted_address : event.name,
      )
    }
    const self = this
    this.setState(
      {
        showLocationField: isMobile,
        citySearch: event.formatted_address ? event.formatted_address : event.name,
        isIndividualBusinessOrganizationDisplay: false,
        individualBusinessOrganizationData: [],
        businessOrganizationNameSearch: this.inputRef.current.value,
      },
      () => isMobile && self.syncLocalStorageWithSearchResults([]),
    )
  }

  changeHandler = (event) => {
    this.setState({ citySearch: event.target.value })
  }

  getCurrentLocationString = async () => {
    this.setState({
      citySearch: '',
    })
    if (navigator.geolocation) {
      return navigator.geolocation.getCurrentPosition(
        (position) => {
          const pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          }
          let REVERSE_GEOCODING_API_URL = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${pos.lat},${pos.lng}&result_type=political&key=${GOOGLE_GEOCODING_API_KEY}`
          axios
            .get(REVERSE_GEOCODING_API_URL)
            .then((res) => {
              if (
                res.data.results &&
                res.data.results[0] &&
                res.data.results[0].formatted_address
              ) {
                this.setState({
                  citySearch: res.data.results[0].formatted_address,
                })
              }
            })
            .catch((error) => {
              customLogger(error)
            })
        },
        () => {
          //Geolocator service failed
          this.setState({ citySearch: '' })
        },
      )
    } else {
      // Browser doesn't support Geolocation
      this.setState({ citySearch: '' })
    }
  }

  componentWillReceiveProps(nextProps) {
    // console.log('nextPropsheader', nextProps)

    if (nextProps.searchbarreset.businessOrganizationNameSearch) {
      this.syncLocalStorageWithSearchResults([])
      this.setState({ businessOrganizationNameSearch: '', citySearch: '' })
      localStorage.removeItem('businessOrganizationNameSearch')
      localStorage.removeItem('citySearch')

      this.props.headerSearchBarReset({})
    }

    let business = nextProps.searchbarreset.businessOrganizationNameSearch
      ? ''
      : nextProps?.searchbarclicked?.search
      ? nextProps.searchbarclicked.search
      : this.state.businessOrganizationNameSearch
    let citySearch = !!nextProps.searchbarset
      ? nextProps.searchbarset
      : nextProps.searchbarreset.citySearch
      ? ''
      : this.state.citySearch
    // console.log(
    //   { business, citySearch },
    //   nextProps.searchbarset,
    //   nextProps.searchbarreset.citySearch,
    //   this.state.citySearch,
    // )
    !business && this.syncLocalStorageWithSearchResults([])
    if (business !== this.state.businessOrganizationNameSearch && this?.inputRef?.current) {
      this.inputRef.current.value = business
    }
    this.setState(
      {
        showLocationField: nextProps.searchbarclicked.value,
        businessOrganizationNameSearch: business ? business : '',
        citySearch: citySearch ? citySearch : '',
      },
      () => !!nextProps.searchbarset && this.props.headerSearchBarSet(''),
    )
  }

  resetSearchBoxValues = (data) => (event) => {
    let self = this
    //localStorage.setItem("businessOrganizationNameSearch", self.state.businessOrganizationNameSearch);
    //localStorage.setItem("citySearch", self.state.citySearch);

    localStorage.removeItem('businessOrganizationNameSearch')
    if (!self.state.citySearch) {
      localStorage.removeItem('citySearch')
    }
    this.setState({ businessOrganizationNameSearch: '' })
  }

  handleClickOnCategoryList = (data) => (event) => {
    let self = this
    localStorage.setItem('businessOrganizationNameSearch', data.name)
    localStorage.setItem('citySearch', self.state.citySearch)
    this.setState({ businessOrganizationNameSearch: data.name })

    this.props.headerSearchBarValue({
      businessOrganizationNameSearch: data.name,
      citySearch: this.state.citySearch,
      isSearch: true,
    })
  }

  syncLocalStorageWithSearchResults = (data) => {
    this.props.headerSearchBarElasticResults(data)

    // localStorage.setItem('searchPageAutoCompleteResults', JSON.stringify(data));
  }

  render = () => {
    const defaultStyle = {
      transition: `opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms`,
      opacity: 0,
      paddingRight: '1.67rem',
      transform: 'translateY(0px)',
      zIndex: -999,
    }
    const transitionStyles = {
      entering: { opacity: 0 },
      entered: { opacity: 1, transform: 'translateY(40px)', zIndex: 1000 },
      exiting: { opacity: 0, transform: 'translateY(0px)', zIndex: 1000 },
      exited: { opacity: 0 },
    }
    const searchBtnDefaultStyle = {
      transition: `opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms`,
      opacity: 0,
      zIndex: -999,
    }
    const searchBtnTransitionStyles = {
      entering: { opacity: 0 },
      entered: { opacity: 1, zIndex: 1000 },
      exiting: { opacity: 0, zIndex: 1000 },
      exited: { opacity: 0 },
    }
    const {
      isIndividualBusinessOrganizationDisplay,
      individualBusinessOrganizationData,
      // individualBusinessOrganization,
      citySearch,
      showLocationField,
      // isSearchPage,
    } = this.state
    // isSearchPage = false;
    if (
      typeof this.props.routeActive !== 'undefined' &&
      route.SEARCH_ROUTE === this.props.routeActive
    ) {
      this.state.isSearchPage = true
    }
    return (
      //it-inner-search
      <React.Fragment>
        <header
          className={classNames(
            this.props.routeActive !== '/' && 'it-inner-search',
            'it-inner-header',
            this.state.isSearchPage === true ? 'it-search-page' : '',
            'd-block d-sm-none it-before-login',
          )}
        >
          {this.renderRedirect()}
          {/* userheader */}
          <nav
            className={classNames(
              this.props.routeActive !== '/' && 'userheader',
              this.props.hideHeader && 'p-0',
              'navbar',
              'navbar-expand-lg',
              'trust_menu',
              'non-loggedin-userheader',
            )}
            style={{ backgroundColor: '#f5f5f7' }}
          >
            {!this.props.hideHeader ? (
              <div
                className={`it-search-header-fixed it-search-beforelogin ${
                  this.state.isSearchPage ? 'w-100' : ''
                }`}
              >
                <Link className='navbar-brand' to={route.ROOT_ROUTE}>
                  <img src={process.env.PUBLIC_URL + '/img/logo.png'} alt='logo' />
                </Link>
                <div className='serachheader_blk'>
                  <form className='bannerform it-header-search-box' action='javascript:;'>
                    <div
                      className='bannerinner'
                      style={{
                        flexWrap: 'wrap',
                      }}
                    >
                      <div className='form-group in_dropdown col-12 px-0 mt-1'>
                        <input
                          type='text'
                          name='individualBusinessOrganization'
                          className='form-control zip2'
                          onChange={this.individualBusinessOrganizationChangeHandler}
                          onFocus={this.onIndividualBusinessOrganizationFocus}
                          onBlur={this.onIndividualBusinessOrganizationBlur}
                          value={this.state.businessOrganizationNameSearch || ''}
                          label='Firstname'
                          placeholder='Search iTRUSTit…'
                          autoComplete='off'
                          ref={this.inputRef}
                          style={{ paddingLeft: '2.25rem', zIndex: 1001 }}
                        />
                        <i
                          className={`fa fa-${showLocationField ? 'chevron-left' : 'search'}`}
                          aria-hidden='true'
                          style={{
                            position: 'absolute',
                            top: 11,
                            left: 11,
                            color: '#4741d4',
                            fontSize: 16,
                          }}
                          onClick={this.searchGoBack}
                        ></i>
                        <Transition in={showLocationField} timeout={150} unmountOnExit>
                          {(state) => {
                            return (
                              <div
                                style={{
                                  ...searchBtnDefaultStyle,
                                  ...searchBtnTransitionStyles[state],
                                  position: 'absolute',
                                  right: 4,
                                  top: 8,
                                }}
                              >
                                <button
                                  type='submit'
                                  style={{ border: 'none', outline: 'none', background: 'none' }}
                                  onClick={this.setRedirect(route.SEARCH_ROUTE, true)}
                                  tabIndex='-1'
                                >
                                  <i
                                    className='fa fa-search'
                                    aria-hidden='true'
                                    style={{ fontSize: 16, color: 'rgb(71, 65, 212)' }}
                                  ></i>
                                </button>
                              </div>
                            )
                          }}
                        </Transition>
                        {/* {isIndividualBusinessOrganizationDisplay &&
                        individualBusinessOrganizationData.length > 0 && (
                          <div className='dropdown i_drop_suggetion'>
                            <div className='dropdown-content'>
                              {individualBusinessOrganizationData.map((user, index) => {
                                let categoryORUsernameDisplay = null;

                                let businessOrOrganization = null;
                                let fullname = null;
                                if (user['_source'].user_type === 'business') {
                                  // businessOrOrganization = (
                                  //     <div className="i_business">
                                  //         <strong>Business Name:</strong>{" "}
                                  //         {user["_source"].business_organization_name}
                                  //     </div>
                                  // );
                                  fullname = user['_source'].business_organization_name;
                                } else if (user['_source'].user_type === 'organization') {
                                  // businessOrOrganization = (
                                  //     <div className="i_business">
                                  //         <strong>Organization Name:</strong>{" "}
                                  //         {user["_source"].business_organization_name}
                                  //     </div>
                                  // );
                                  fullname = user['_source'].business_organization_name;
                                } else {
                                  // fullname = <div className="i_name varela purple">
                                  //     <div
                                  //         dangerouslySetInnerHTML={{
                                  //             __html: user["_source"].name,
                                  //         }}
                                  //     />
                                  // </div>
                                  fullname = user['_source'].name;
                                }

                                let addressToDisplay = '';
                                if (
                                  typeof user['_source'].address_line_1 !== 'undefined' &&
                                  user['_source'].address_line_1
                                ) {
                                  addressToDisplay += '' + user['_source'].address_line_1;
                                }
                                if (
                                  typeof user['_source'].address_line_2 !== 'undefined' &&
                                  user['_source'].address_line_2
                                ) {
                                  addressToDisplay += ' ' + user['_source'].address_line_2;
                                }
                                if (
                                  typeof user['_source'].city !== 'undefined' &&
                                  user['_source'].city
                                ) {
                                  addressToDisplay += ' ' + user['_source'].city;
                                }
                                if (
                                  typeof user['_source'].state !== 'undefined' &&
                                  user['_source'].state
                                ) {
                                  addressToDisplay += ' ' + user['_source'].state;
                                }
                                if (
                                  typeof user['_source'].zipcode !== 'undefined' &&
                                  user['_source'].zipcode
                                ) {
                                  addressToDisplay += ' ' + user['_source'].zipcode;
                                }

                                if (user['_index'] !== 'live-categories') {
                                  let profileUrl = process.env.PUBLIC_URL + user['_source'].slug;
                                  let profile_picture = null;
                                  if (
                                    typeof user['_source'].image !== 'indefined' &&
                                    user['_source'].image !== '' &&
                                    user['_source'].image
                                  ) {
                                    profile_picture = (
                                      <img
                                        className='imageTag'
                                        alt=''
                                        src={PROFILE_PICTURE_SMALL_URL_PATH + user['_source'].image}
                                      />
                                    );
                                  } else {
                                    profile_picture = (
                                      <img
                                        className='imageTag'
                                        alt=''
                                        src={DEFAULT_PROFILE_PICTURE}
                                      />
                                    );
                                  }

                                  categoryORUsernameDisplay = (
                                    <Link
                                      to={profileUrl}
                                      className='i_suggetion_link'
                                      onClick={this.individualBusinessOrganizationClicked(
                                        user['_source']
                                      )}
                                    >
                                      <div className='i_photu'>{profile_picture}</div>
                                      <div className='i_info'>
                                        <div className='i_name varela purple'>{fullname}</div>
                                        <div className='i_address'>
                                          {user['_source'].user_type !== 'individual'
                                            ? addressToDisplay
                                            : 'Click to view profile'}
                                        </div>
                                      </div>
                                    </Link>
                                  );
                                } else {
                                  categoryORUsernameDisplay = (
                                    <Link
                                      className='i_suggetion_link_category'
                                      to={{
                                        pathname: route.SEARCH_ROUTE,
                                        state: {
                                          category_id: user['_source'].search_id,
                                          category_name: fullname,
                                        },
                                      }}
                                      onClick={this.handleClickOnCategoryList(user['_source'])}
                                    >
                                      <div className='i_info'>
                                        <div className='i_name varela purple'>{fullname}</div>
                                      </div>
                                    </Link>
                                  );
                                }

                                return (
                                  <div key={index} className='i_suggestion_list'>
                                    {categoryORUsernameDisplay}
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        )} */}
                      </div>
                      <Transition in={showLocationField} timeout={150} unmountOnExit>
                        {(state) => {
                          return (
                            <div
                              className='form-group col-12 ps-0 mt-1 position-absolute'
                              style={{ ...defaultStyle, ...transitionStyles[state] }}
                            >
                              <GoogleAutocomplete
                                type='text'
                                className='form-control zip1'
                                name='searchAddress'
                                onChange={this.changeHandler}
                                value={citySearch || ''}
                                placeholder='City, State, or Zip Code...'
                                onPlaceSelected={(place) => {
                                  this.handleChangeGoogle(place)
                                }}
                                onFocus={(event) => {
                                  this.clickedLocSuggestionRef.current = false
                                  event.target.setAttribute('autocomplete', 'off')
                                }}
                                onBlur={this.handleLocFieldBlur}
                                options={{ types: ['geocode'] }}
                                //componentRestrictions={{ country: "in" }}
                                style={{ paddingLeft: '2.25rem', fontSize: 14 }}
                                ref={this.locFieldRef}
                                tabIndex={0}
                              />
                              <i
                                className='fa fa-map-marker-alt'
                                aria-hidden='true'
                                style={{
                                  position: 'absolute',
                                  top: 11,
                                  left: 11,
                                  color: '#4741d4',
                                  fontSize: 16,
                                }}
                              ></i>
                            </div>
                          )
                        }}
                      </Transition>
                    </div>
                  </form>
                </div>
              </div>
            ) : null}
            <ul className='mobile-visible navbar-nav non-loggedin-nav'>
              <li className='nav-item'>
                <Link className='nav-link' to={route.ROOT_ROUTE}>
                  <img src={process.env.PUBLIC_URL + '/img/new_icons/home.svg'} alt='home' />
                  <span>Home</span>
                </Link>
                {/* <a className="nav-link" href={null}>
                                <img src={process.env.PUBLIC_URL + "/img/home.svg"} alt="home" />
                                <span>Home</span>
                            </a> */}
              </li>
              <li className='nav-item'>
                <Link
                  className='nav-link'
                  to={{
                    pathname: route.SEARCH_ROUTE,
                    state: {
                      resetSearch: true,
                    },
                  }}
                >
                  <img src={process.env.PUBLIC_URL + '/img/new_icons/search.svg'} alt='search' />
                  <span>Search</span>
                </Link>
                {/* <a className="nav-link" href={null} onClick={this.setRedirect(route.SEARCH_ROUTE)}>
                                <img src={process.env.PUBLIC_URL + "/img/new_icons/search.svg"} alt="search" />
                                <span>Search</span>
                            </a> */}
              </li>
              <li className='nav-item p-0'>
                <Link className='nav-link' to={route.LOGIN_ROUTE}>
                  <img src={process.env.PUBLIC_URL + '/img/new_icons/user.svg'} alt='user' />
                  <span>Login</span>
                </Link>
              </li>
            </ul>
            {!this.state.isSearchPage ? (
              <React.Fragment>
                <Link className='navbar-brand' to={route.ROOT_ROUTE}>
                  <img src={process.env.PUBLIC_URL + '/img/logo.png'} alt='logo' />
                </Link>

                <div className='serachheader_blk'>
                  <form className='bannerform it-header-search-box' action='javascript:;'>
                    <div className='bannerinner'>
                      <div className='form-group in_dropdown'>
                        <input
                          type='text'
                          name='individualBusinessOrganization'
                          className='form-control zip2'
                          onChange={this.individualBusinessOrganizationChangeHandler}
                          onFocus={this.onIndividualBusinessOrganizationFocus}
                          onBlur={this.onIndividualBusinessOrganizationBlur}
                          value={this.state.businessOrganizationNameSearch || ''}
                          label='Firstname'
                          placeholder='Search iTRUSTit…'
                          autoComplete='off'
                        />
                        {isIndividualBusinessOrganizationDisplay &&
                          individualBusinessOrganizationData.length > 0 && (
                            <div className='dropdown i_drop_suggetion'>
                              <div className='dropdown-content'>
                                {individualBusinessOrganizationData.map((user, index) => {
                                  let categoryORUsernameDisplay = null

                                  let businessOrOrganization = null
                                  let fullname = null
                                  if (user['_source'].user_type === 'business') {
                                    // businessOrOrganization = (
                                    //     <div className="i_business">
                                    //         <strong>Business Name:</strong>{" "}
                                    //         {user["_source"].business_organization_name}
                                    //     </div>
                                    // );
                                    fullname = user['_source'].business_organization_name
                                  } else if (user['_source'].user_type === 'organization') {
                                    // businessOrOrganization = (
                                    //     <div className="i_business">
                                    //         <strong>Organization Name:</strong>{" "}
                                    //         {user["_source"].business_organization_name}
                                    //     </div>
                                    // );
                                    fullname = user['_source'].business_organization_name
                                  } else {
                                    // fullname = <div className="i_name varela purple">
                                    //     <div
                                    //         dangerouslySetInnerHTML={{
                                    //             __html: user["_source"].name,
                                    //         }}
                                    //     />
                                    // </div>
                                    fullname = user['_source'].name
                                  }

                                  let addressToDisplay = ''
                                  if (
                                    typeof user['_source'].address_line_1 !== 'undefined' &&
                                    user['_source'].address_line_1
                                  ) {
                                    addressToDisplay += '' + user['_source'].address_line_1
                                  }
                                  if (
                                    typeof user['_source'].address_line_2 !== 'undefined' &&
                                    user['_source'].address_line_2
                                  ) {
                                    addressToDisplay += ' ' + user['_source'].address_line_2
                                  }
                                  if (
                                    typeof user['_source'].city !== 'undefined' &&
                                    user['_source'].city
                                  ) {
                                    addressToDisplay += ' ' + user['_source'].city
                                  }
                                  if (
                                    typeof user['_source'].state !== 'undefined' &&
                                    user['_source'].state
                                  ) {
                                    addressToDisplay += ' ' + user['_source'].state
                                  }
                                  if (
                                    typeof user['_source'].zipcode !== 'undefined' &&
                                    user['_source'].zipcode
                                  ) {
                                    addressToDisplay += ' ' + user['_source'].zipcode
                                  }

                                  if (user['_index'] !== 'live-categories') {
                                    let profileUrl = route.ROOT_ROUTE + user['_source'].slug
                                    let profile_picture = null
                                    if (
                                      typeof user['_source'].image !== 'indefined' &&
                                      user['_source'].image !== '' &&
                                      user['_source'].image
                                    ) {
                                      profile_picture = (
                                        <img
                                          className='imageTag'
                                          alt=''
                                          src={
                                            PROFILE_PICTURE_SMALL_URL_PATH + user['_source'].image
                                          }
                                        />
                                      )
                                    } else {
                                      profile_picture = (
                                        <img
                                          className='imageTag'
                                          alt=''
                                          src={DEFAULT_PROFILE_PICTURE}
                                        />
                                      )
                                    }

                                    categoryORUsernameDisplay = (
                                      <Link
                                        to={profileUrl}
                                        className='i_suggetion_link'
                                        onClick={this.individualBusinessOrganizationClicked(
                                          user['_source'],
                                        )}
                                      >
                                        <div className='i_photu'>{profile_picture}</div>
                                        <div className='i_info'>
                                          <div className='i_name varela purple'>{fullname}</div>
                                          <div className='i_address'>
                                            {user['_source'].user_type !== 'individual'
                                              ? addressToDisplay
                                              : 'Click to view profile'}
                                          </div>
                                        </div>
                                      </Link>
                                    )
                                  } else {
                                    categoryORUsernameDisplay = (
                                      <Link
                                        className='i_suggetion_link_category'
                                        to={{
                                          pathname: route.SEARCH_ROUTE,
                                          state: {
                                            category_id: user['_source'].search_id,
                                            category_name: fullname,
                                          },
                                        }}
                                        onClick={this.handleClickOnCategoryList(user['_source'])}
                                      >
                                        <div className='i_info'>
                                          <div className='i_name varela purple'>{fullname}</div>
                                        </div>
                                      </Link>
                                    )
                                  }

                                  return (
                                    <div key={user['_source'].id} className='i_suggestion_list'>
                                      {categoryORUsernameDisplay}
                                    </div>
                                  )
                                })}
                              </div>
                            </div>
                          )}
                      </div>

                      <div className='form-group'>
                        <GoogleAutocomplete
                          type='text'
                          className='form-control zip1'
                          name='searchAddress'
                          onChange={this.changeHandler}
                          value={citySearch || ''}
                          placeholder='City, State, or Zip Code...'
                          onPlaceSelected={(place) => {
                            this.handleChangeGoogle(place)
                          }}
                          onFocus={(event) => {
                            event.target.setAttribute('autocomplete', 'off')
                          }}
                          options={{ types: ['geocode'] }}
                          //componentRestrictions={{ country: "in" }}
                        />
                      </div>
                    </div>
                    <div className='form-group submitblk it-header-search'>
                      <button
                        type='submit'
                        className='form-control search'
                        onClick={this.setRedirect(route.SEARCH_ROUTE)}
                      >
                        <i className='fa fa-search' aria-hidden='true'></i>
                      </button>
                    </div>
                  </form>
                </div>
                <button
                  className='navbar-toggler'
                  type='button'
                  data-bs-toggle='collapse'
                  data-bs-target='#navbarSupportedContent'
                  aria-controls='navbarSupportedContent'
                  aria-expanded='false'
                  aria-label='Toggle navigation'
                >
                  <span className='navbar-toggler-icon' />
                </button>
                <div
                  className='collapse navbar-collapse justify-content-end'
                  id='navbarSupportedContent'
                >
                  <ul className='navbar-nav'>
                    {/* <li
                                className={classNames(
                                    "nav-item ",
                                    route.ROOT_ROUTE === this.props.routeActive && "active"
                                )}
                            >
                                <Link className="nav-link" to={route.ROOT_ROUTE}>
                                    HOME <span className="sr-only">(current)</span>
                                </Link>
                            </li> */}
                    <li
                      className={classNames(
                        'nav-item ',
                        route.ABOUT_ROUTE === this.props.routeActive && 'active',
                      )}
                    >
                      <Link className='nav-link' to={route.ABOUT_ROUTE}>
                        ABOUT
                      </Link>
                    </li>
                    <li
                      className={classNames(
                        'nav-item ',
                        route.COMMUNITY_ROUTE === this.props.routeActive && 'active',
                      )}
                    >
                      <Link className='nav-link' to={route.COMMUNITY_ROUTE}>
                        COMMUNITY
                      </Link>
                    </li>
                    <li
                      className={classNames(
                        'nav-item ',
                        route.FAQ_ROUTE === this.props.routeActive && 'active',
                      )}
                    >
                      <Link className='nav-link' to={route.FAQ_ROUTE}>
                        FAQs
                      </Link>
                    </li>
                    <li
                      className={classNames(
                        'nav-item ',
                        route.CONTACT_ROUTE === this.props.routeActive && 'active',
                      )}
                    >
                      <Link className='nav-link' to={route.CONTACT_ROUTE}>
                        CONTACT
                      </Link>
                    </li>
                    <li
                      className={classNames(
                        'nav-item d-none',
                        route.JOBS_ROUTE === this.props.routeActive && 'active',
                      )}
                    >
                      <Link className='nav-link' to={route.JOBS_ROUTE}>
                        JOBS
                      </Link>
                    </li>

                    <li className='nav-item d-none'>
                      <a className='nav-link' href='javascript:;'>
                        FEEDBACK
                      </a>
                    </li>
                    {isUserLogin() && userToken() != null ? (
                      <li className='nav-item login_btn'>
                        <Link className='nav-link ' to={route.LOGOUT_ROUTE}>
                          Log out
                        </Link>
                      </li>
                    ) : (
                      <Fragment>
                        <li className='nav-item login_btn'>
                          <Link className='nav-link ' to={route.LOGIN_ROUTE}>
                            Log in
                          </Link>
                        </li>
                        {this.props.imatrix_id ? (
                          <li className='nav-item login_btn signup'>
                            <Link
                              className='nav-link'
                              to={{
                                pathname: route.SIGNUP_ROUTE,
                                state: { imatrix_id: this.props.imatrix_id },
                              }}
                            >
                              Sign Up
                            </Link>
                          </li>
                        ) : (
                          <li className='nav-item login_btn signup'>
                            <Link className='nav-link' to={route.SIGNUP_ROUTE}>
                              Sign Up
                            </Link>
                          </li>
                        )}
                      </Fragment>
                    )}
                  </ul>
                </div>
              </React.Fragment>
            ) : null}
          </nav>
        </header>
        <header
          className={classNames(
            this.props.routeActive !== '/' && 'it-inner-search',
            'it-inner-header',
            this.state.isSearchPage === true ? 'it-search-page' : '',
            'd-none d-sm-block',
          )}
        >
          {this.renderRedirect()}
          {/* userheader */}
          <nav
            className={classNames(
              this.props.routeActive !== '/' && 'userheader',
              'navbar',
              'navbar-expand-lg',
              'trust_menu',
            )}
          >
            <div className='it-search-header-fixed it-search-beforelogin'>
              <Link className='navbar-brand' to={route.ROOT_ROUTE}>
                <img src={process.env.PUBLIC_URL + '/img/logo.png'} alt='logo' />
              </Link>
              <div className='serachheader_blk'>
                <form className='bannerform it-header-search-box' action='javascript:;'>
                  <div className='bannerinner'>
                    <div className='form-group in_dropdown'>
                      <input
                        type='text'
                        name='individualBusinessOrganization'
                        className='form-control zip2'
                        onChange={this.individualBusinessOrganizationChangeHandler}
                        onFocus={(event) =>
                          this.onIndividualBusinessOrganizationFocus(event, false)
                        }
                        onBlur={(event) => this.onIndividualBusinessOrganizationBlur(event, false)}
                        value={this.state.businessOrganizationNameSearch || ''}
                        label='Firstname'
                        placeholder='Search iTRUSTit…'
                        autoComplete='off'
                      />
                      {isIndividualBusinessOrganizationDisplay &&
                        individualBusinessOrganizationData.length > 0 && (
                          <div className='dropdown i_drop_suggetion'>
                            <div className='dropdown-content'>
                              {individualBusinessOrganizationData.map((user, index) => {
                                let categoryORUsernameDisplay = null

                                let businessOrOrganization = null
                                let fullname = null
                                if (user['_source'].user_type === 'business') {
                                  // businessOrOrganization = (
                                  //     <div className="i_business">
                                  //         <strong>Business Name:</strong>{" "}
                                  //         {user["_source"].business_organization_name}
                                  //     </div>
                                  // );
                                  fullname = user['_source'].business_organization_name
                                } else if (user['_source'].user_type === 'organization') {
                                  // businessOrOrganization = (
                                  //     <div className="i_business">
                                  //         <strong>Organization Name:</strong>{" "}
                                  //         {user["_source"].business_organization_name}
                                  //     </div>
                                  // );
                                  fullname = user['_source'].business_organization_name
                                } else {
                                  // fullname = <div className="i_name varela purple">
                                  //     <div
                                  //         dangerouslySetInnerHTML={{
                                  //             __html: user["_source"].name,
                                  //         }}
                                  //     />
                                  // </div>
                                  fullname = user['_source'].name
                                }

                                let addressToDisplay = ''
                                if (
                                  typeof user['_source'].address_line_1 !== 'undefined' &&
                                  user['_source'].address_line_1
                                ) {
                                  addressToDisplay += '' + user['_source'].address_line_1
                                }
                                if (
                                  typeof user['_source'].address_line_2 !== 'undefined' &&
                                  user['_source'].address_line_2
                                ) {
                                  addressToDisplay += ' ' + user['_source'].address_line_2
                                }
                                if (
                                  typeof user['_source'].city !== 'undefined' &&
                                  user['_source'].city
                                ) {
                                  addressToDisplay += ' ' + user['_source'].city
                                }
                                if (
                                  typeof user['_source'].state !== 'undefined' &&
                                  user['_source'].state
                                ) {
                                  addressToDisplay += ' ' + user['_source'].state
                                }
                                if (
                                  typeof user['_source'].zipcode !== 'undefined' &&
                                  user['_source'].zipcode
                                ) {
                                  addressToDisplay += ' ' + user['_source'].zipcode
                                }

                                if (user['_index'] !== 'live-categories') {
                                  let profileUrl = process.env.PUBLIC_URL + user['_source'].slug
                                  let profile_picture = null
                                  if (
                                    typeof user['_source'].image !== 'indefined' &&
                                    user['_source'].image !== '' &&
                                    user['_source'].image
                                  ) {
                                    profile_picture = (
                                      <img
                                        className='imageTag'
                                        alt=''
                                        src={PROFILE_PICTURE_SMALL_URL_PATH + user['_source'].image}
                                      />
                                    )
                                  } else {
                                    profile_picture = (
                                      <img
                                        className='imageTag'
                                        alt=''
                                        src={DEFAULT_PROFILE_PICTURE}
                                      />
                                    )
                                  }

                                  categoryORUsernameDisplay = (
                                    <Link
                                      to={profileUrl}
                                      className='i_suggetion_link'
                                      onClick={this.individualBusinessOrganizationClicked(
                                        user['_source'],
                                      )}
                                    >
                                      <div className='i_photu'>{profile_picture}</div>
                                      <div className='i_info'>
                                        <div className='i_name varela purple'>{fullname}</div>
                                        <div className='i_address'>
                                          {user['_source'].user_type !== 'individual'
                                            ? addressToDisplay
                                            : 'Click to view profile'}
                                        </div>
                                      </div>
                                    </Link>
                                  )
                                } else {
                                  categoryORUsernameDisplay = (
                                    <Link
                                      className='i_suggetion_link_category'
                                      to={{
                                        pathname: route.SEARCH_ROUTE,
                                        state: {
                                          category_id: user['_source'].search_id,
                                          category_name: fullname,
                                        },
                                      }}
                                      onClick={this.handleClickOnCategoryList(user['_source'])}
                                    >
                                      <div className='i_info'>
                                        <div className='i_name varela purple'>{fullname}</div>
                                      </div>
                                    </Link>
                                  )
                                }

                                return (
                                  <div key={user['_source'].id} className='i_suggestion_list'>
                                    {categoryORUsernameDisplay}
                                  </div>
                                )
                              })}
                            </div>
                          </div>
                        )}
                    </div>

                    <div className='form-group me-2 ms-2'>
                      <GoogleAutocomplete
                        type='text'
                        className='form-control zip1'
                        name='searchAddress'
                        onChange={this.changeHandler}
                        value={citySearch || ''}
                        placeholder='City, State, or Zip Code...'
                        onPlaceSelected={(place) => {
                          this.handleChangeGoogle(place, false)
                        }}
                        onFocus={(event) => {
                          event.target.setAttribute('autocomplete', 'off')
                        }}
                        options={{ types: ['geocode'] }}
                        //componentRestrictions={{ country: "in" }}
                      />
                    </div>
                  </div>
                  <div className='form-group submitblk it-header-search'>
                    <button
                      type='submit'
                      className='form-control search'
                      onClick={this.setRedirect(route.SEARCH_ROUTE)}
                    >
                      <i className='fa fa-search' aria-hidden='true'></i>
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <Link className='navbar-brand' to={route.ROOT_ROUTE}>
              <img src={process.env.PUBLIC_URL + '/img/logo.png'} alt='logo' />
            </Link>

            <div className='serachheader_blk'>
              <form className='bannerform it-header-search-box' action='javascript:;'>
                <div className='bannerinner'>
                  <div className='form-group in_dropdown'>
                    <input
                      type='text'
                      name='individualBusinessOrganization'
                      className='form-control zip2'
                      onChange={this.individualBusinessOrganizationChangeHandler}
                      onFocus={this.onIndividualBusinessOrganizationFocus}
                      onBlur={this.onIndividualBusinessOrganizationBlur}
                      value={this.state.businessOrganizationNameSearch || ''}
                      label='Firstname'
                      placeholder='Search iTRUSTit…'
                      autoComplete='off'
                    />
                    {isIndividualBusinessOrganizationDisplay &&
                      individualBusinessOrganizationData.length > 0 && (
                        <div className='dropdown i_drop_suggetion'>
                          <div className='dropdown-content'>
                            {individualBusinessOrganizationData.map((user, index) => {
                              let categoryORUsernameDisplay = null

                              let businessOrOrganization = null
                              let fullname = null
                              if (user['_source'].user_type === 'business') {
                                // businessOrOrganization = (
                                //     <div className="i_business">
                                //         <strong>Business Name:</strong>{" "}
                                //         {user["_source"].business_organization_name}
                                //     </div>
                                // );
                                fullname = user['_source'].business_organization_name
                              } else if (user['_source'].user_type === 'organization') {
                                // businessOrOrganization = (
                                //     <div className="i_business">
                                //         <strong>Organization Name:</strong>{" "}
                                //         {user["_source"].business_organization_name}
                                //     </div>
                                // );
                                fullname = user['_source'].business_organization_name
                              } else {
                                // fullname = <div className="i_name varela purple">
                                //     <div
                                //         dangerouslySetInnerHTML={{
                                //             __html: user["_source"].name,
                                //         }}
                                //     />
                                // </div>
                                fullname = user['_source'].name
                              }

                              let addressToDisplay = ''
                              if (
                                typeof user['_source'].address_line_1 !== 'undefined' &&
                                user['_source'].address_line_1
                              ) {
                                addressToDisplay += '' + user['_source'].address_line_1
                              }
                              if (
                                typeof user['_source'].address_line_2 !== 'undefined' &&
                                user['_source'].address_line_2
                              ) {
                                addressToDisplay += ' ' + user['_source'].address_line_2
                              }
                              if (
                                typeof user['_source'].city !== 'undefined' &&
                                user['_source'].city
                              ) {
                                addressToDisplay += ' ' + user['_source'].city
                              }
                              if (
                                typeof user['_source'].state !== 'undefined' &&
                                user['_source'].state
                              ) {
                                addressToDisplay += ' ' + user['_source'].state
                              }
                              if (
                                typeof user['_source'].zipcode !== 'undefined' &&
                                user['_source'].zipcode
                              ) {
                                addressToDisplay += ' ' + user['_source'].zipcode
                              }

                              if (user['_index'] !== 'live-categories') {
                                let profileUrl = route.ROOT_ROUTE + user['_source'].slug
                                let profile_picture = null
                                if (
                                  typeof user['_source'].image !== 'indefined' &&
                                  user['_source'].image !== '' &&
                                  user['_source'].image
                                ) {
                                  profile_picture = (
                                    <img
                                      className='imageTag'
                                      alt=''
                                      src={PROFILE_PICTURE_SMALL_URL_PATH + user['_source'].image}
                                    />
                                  )
                                } else {
                                  profile_picture = (
                                    <img
                                      className='imageTag'
                                      alt=''
                                      src={DEFAULT_PROFILE_PICTURE}
                                    />
                                  )
                                }

                                categoryORUsernameDisplay = (
                                  <Link
                                    to={profileUrl}
                                    className='i_suggetion_link'
                                    onClick={this.individualBusinessOrganizationClicked(
                                      user['_source'],
                                    )}
                                  >
                                    <div className='i_photu'>{profile_picture}</div>
                                    <div className='i_info'>
                                      <div className='i_name varela purple'>{fullname}</div>
                                      <div className='i_address'>
                                        {user['_source'].user_type !== 'individual'
                                          ? addressToDisplay
                                          : 'Click to view profile'}
                                      </div>
                                    </div>
                                  </Link>
                                )
                              } else {
                                categoryORUsernameDisplay = (
                                  <Link
                                    className='i_suggetion_link_category'
                                    to={{
                                      pathname: route.SEARCH_ROUTE,
                                      state: {
                                        category_id: user['_source'].search_id,
                                        category_name: fullname,
                                      },
                                    }}
                                    onClick={this.handleClickOnCategoryList(user['_source'])}
                                  >
                                    <div className='i_info'>
                                      <div className='i_name varela purple'>{fullname}</div>
                                    </div>
                                  </Link>
                                )
                              }

                              return (
                                <div key={user['_source'].id} className='i_suggestion_list'>
                                  {categoryORUsernameDisplay}
                                </div>
                              )
                            })}
                          </div>
                        </div>
                      )}
                  </div>

                  <div className='form-group'>
                    <GoogleAutocomplete
                      type='text'
                      className='form-control zip1'
                      name='searchAddress'
                      onChange={this.changeHandler}
                      value={citySearch || ''}
                      placeholder='City, State, or Zip Code...'
                      onPlaceSelected={(place) => {
                        this.handleChangeGoogle(place)
                      }}
                      onFocus={(event) => {
                        event.target.setAttribute('autocomplete', 'off')
                      }}
                      options={{ types: ['geocode'] }}
                      //componentRestrictions={{ country: "in" }}
                    />
                  </div>
                </div>
                <div className='form-group submitblk it-header-search'>
                  <button
                    type='submit'
                    className='form-control search'
                    onClick={this.setRedirect(route.SEARCH_ROUTE)}
                  >
                    <i className='fa fa-search' aria-hidden='true'></i>
                  </button>
                </div>
              </form>
            </div>
            <button
              className='navbar-toggler'
              type='button'
              data-bs-toggle='collapse'
              data-bs-target='#navbarSupportedContent'
              aria-controls='navbarSupportedContent'
              aria-expanded='false'
              aria-label='Toggle navigation'
            >
              <span className='navbar-toggler-icon' />
            </button>
            <div
              className='collapse navbar-collapse justify-content-end'
              id='navbarSupportedContent'
            >
              <ul className='navbar-nav'>
                {/* <li
                                className={classNames(
                                    "nav-item ",
                                    route.ROOT_ROUTE === this.props.routeActive && "active"
                                )}
                            >
                                <Link className="nav-link" to={route.ROOT_ROUTE}>
                                    HOME <span className="sr-only">(current)</span>
                                </Link>
                            </li> */}
                <li
                  className={classNames(
                    'nav-item ',
                    route.ABOUT_ROUTE === this.props.routeActive && 'active',
                  )}
                >
                  <Link className='nav-link' to={route.ABOUT_ROUTE}>
                    ABOUT
                  </Link>
                </li>
                <li
                  className={classNames(
                    'nav-item ',
                    route.COMMUNITY_ROUTE === this.props.routeActive && 'active',
                  )}
                >
                  <Link className='nav-link' to={route.COMMUNITY_ROUTE}>
                    COMMUNITY
                  </Link>
                </li>
                <li
                  className={classNames(
                    'nav-item ',
                    route.FAQ_ROUTE === this.props.routeActive && 'active',
                  )}
                >
                  <Link className='nav-link' to={route.FAQ_ROUTE}>
                    FAQs
                  </Link>
                </li>
                <li
                  className={classNames(
                    'nav-item ',
                    route.CONTACT_ROUTE === this.props.routeActive && 'active',
                  )}
                >
                  <Link className='nav-link' to={route.CONTACT_ROUTE}>
                    CONTACT
                  </Link>
                </li>
                <li
                  className={classNames(
                    'nav-item d-none',
                    route.JOBS_ROUTE === this.props.routeActive && 'active',
                  )}
                >
                  <Link className='nav-link' to={route.JOBS_ROUTE}>
                    JOBS
                  </Link>
                </li>

                <li className='nav-item d-none'>
                  <a className='nav-link' href='javascript:;'>
                    FEEDBACK
                  </a>
                </li>
                {isUserLogin() && userToken() != null ? (
                  <li className='nav-item login_btn'>
                    <Link className='nav-link ' to={route.LOGOUT_ROUTE}>
                      Log out
                    </Link>
                  </li>
                ) : (
                  <Fragment>
                    <li className='nav-item login_btn'>
                      <Link className='nav-link ' to={route.LOGIN_ROUTE}>
                        Log in
                      </Link>
                    </li>
                    {this.props.imatrix_id ? (
                      <li className='nav-item login_btn signup'>
                        <Link
                          className='nav-link'
                          to={{
                            pathname: route.SIGNUP_ROUTE,
                            state: { imatrix_id: this.props.imatrix_id },
                          }}
                        >
                          Sign Up
                        </Link>
                      </li>
                    ) : (
                      <li className='nav-item login_btn signup'>
                        <Link className='nav-link' to={route.SIGNUP_ROUTE}>
                          Sign Up
                        </Link>
                      </li>
                    )}
                  </Fragment>
                )}
              </ul>
            </div>
          </nav>
        </header>
      </React.Fragment>
    )
  }
}

function mapStateToProps(state) {
  return {
    searchbar: state.UserReducer.searchbar,
    searchbarreset: state.UserReducer.searchbarreset,
    searchbarset: state.UserReducer.searchbarset,
    searchbarclicked: state.UserReducer.searchbarclicked,
    searchbarsuggestionclicked: state.UserReducer.searchbarsuggestionclicked,
  }
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      headerSearchBarSet,
      headerSearchBarReset,
      headerSearchBarValue,
      headerSearchBarValOnChange,
      headerSearchBarLocChange,
      headerSearchBarClicked,
      headerSearchBarElasticResults,
    },
    dispatch,
  )
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header))
