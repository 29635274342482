import React from 'react'
import AppLayout from '../AppLayout'
import '../Message/message.scss'
import LoginModal from '../LoginModal/LoginModal'
import { Helmet } from 'react-helmet'
import './MarketingCenterCTAExamples.scss'

const MarketingCenterCTAExamples = (props) => {
  const loginHandler = (data) => {
    props?.isLogin?.(data)
  }
  return (
    <AppLayout routeActive={props.location.pathname}>
      <Helmet>
        <title>iTRUSTit Marketing Center - Call To Action Examples</title>
      </Helmet>
      <section className='bg-grey'>
        <div className='container mt-3'>
          <h3 className='varela mb-2'>Marketing Center - Call To Action Examples</h3>
          <div className='white-pane green_line'>
            <div className='marketing-center-guide mb-5'>
              <div className='d-flex flex-column mt-3'>
                <h3 className='mont fw-bold mb-4' style={{ color: '#136eb7' }}>
                  Examples of Calls To Action
                </h3>
                <div className='row' style={{ rowGap: 16 }}>
                  <div className='col-12 col-md-6'>
                    <h4 className='mont fw-bold mb-3' style={{ color: '#14954b' }}>
                      People
                    </h4>
                    <h6 className='mont'>See the organizations I support on iTRUSTit</h6>
                    <h6 className='mont'>See all the businesses I Trust on iTRUSTit</h6>
                    <h6 className='mont'>Help me support my charity on iTRUSTit</h6>
                    <h6 className='mont'>
                      See how many Organizations we have in common on iTRUSTit
                    </h6>
                  </div>
                  <div className='col-12 col-md-6'>
                    <h4 className='mont fw-bold mb-3' style={{ color: '#136eb7' }}>
                      Businesses
                    </h4>
                    <h6 className='mont'>See the organizations I support on iTRUSTit</h6>
                    <h6 className='mont'>See how many of your friends Trust US on iTRUSTit</h6>
                    <h6 className='mont'>See which of your friends Trust Us on iTRUSTit</h6>
                    <h6 className='mont'>Help Promote us to your friends on iTRUSTit</h6>
                    <h6 className='mont'>Help us support _________(charity/cause) on iTRUSTit</h6>
                  </div>
                  <div className='col-12 col-md-6'>
                    <h4 className='mont fw-bold mb-3' style={{ color: '#136eb7' }}>
                      Realtors & Real Estate Agents
                    </h4>
                    <h6 className='mont'>See the organizations I support on iTRUSTit</h6>
                    <h6 className='mont'>See all the businesses I Trust on iTRUSTit</h6>
                    <h6 className='mont'>Help me support _________ by joining iTRUSTit</h6>
                    <h6 className='mont'>
                      Help me/us support _________(charity/cause) on iTRUSTit
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <LoginModal isLogin={loginHandler} {...props} />
    </AppLayout>
  )
}

export default MarketingCenterCTAExamples
