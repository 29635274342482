import React, { Component, useEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import classNames from 'classnames'
import { Field, formValueSelector, getFormSyncErrors, reduxForm } from 'redux-form'
import moment from 'moment'
import { Link } from 'react-router-dom'
import AppLayout from '../AppLayout'
import * as validation from '../../services/formValidation'
import * as api from '../../services/businessService'
import { updateUserProfile, updateUserCoverImage } from '../../services/authService'
import { galleryCreateCall, validateGalleryFileType } from '../../services/galleryApiService'
import ReactSelect from '../../services/ReactSelect'
import renderInputField from '../Form/Input'
import renderTextAreaField from '../Form/Textarea'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import Cropperjs from '../Cropper/Cropper'
import * as customFunction from '../../services/customFunction'
import { SITE_ADDRESS } from '../../services/environment'
import CropperCover from '../Cropper/CropperCover'
import * as environemnt from '../../services/environment'
import axios from 'axios'
import { customLogger } from '../../utils/CustomLogger'
import ChangePassword from '../ChangePassword/ChangePassword'
import {
  DEFAULT_PROFILE_PICTURE,
  DEFAULT_COVER_PICTURE,
  // NO_COVER_PICTURE,
  STAR_RATING_PROFILE_HEADER_SIZE,
  STAR_RATING_iTRUST_ACTIVE_COLOR,
  STAR_RATING_iTRUST_INACTIVE_COLOR,
  // STAR_RATING_TRUST_ME_ACTIVE_COLOR,
  // STAR_RATING_TRUST_ME_INACTIVE_COLOR,
  relationshipList,
  PROFILE_ROUTE_CONST,
  DUPLICATE_USER, //Asana  #211004_em-01
} from '../../services/const'
import { PROFILE_ROUTE } from '../../services/routeConst'
import ReactStars from 'react-stars'
import { checkEmail, checkUsername } from '../../services/userApiService'
import $ from 'jquery'
import UsernameModal from '../UsernameModal/UsernameModal'
import HeaderIndividual from './Components/HeaderIndividual'
import HeaderBusOrg from './Components/HeaderBusOrg'
import { connect } from 'react-redux'
import LoadingOverlay from '../CommonComponents/LoadingOverlay'
import { AnimatePresence, motion } from 'framer-motion'
import EditProfileHeader from './Components/EditProfileHeader'
import DuplicateClaimRequestEmailError from '../CommonComponents/DuplicateClaimRequestEmailError/DuplicateClaimRequestEmailError'
import ResendVerificationEmailModal from '../CommonComponents/ResendVerificationEmailModal/ResendVerificationEmailModal'

const parseData = (data) => data?.map?.(({ id: value, name: label }) => ({ value, label })) ?? []

let EditProfileNew = (props) => {
  const [stateData, setStateData] = useState({
    userImg: DEFAULT_PROFILE_PICTURE,
    userDetail: null,
    categoryList: [],
    subCategoryList: [],
    subOfSubCategoryList: [],
    subCategoryValue: null,
    subOfSubCategoryValue: null,
    categoryValue: null,
    userImgFile: '',
    categoryIndex: null,
    gender: '',
    birth_date: '',
    stateLoadingFirstTime: true,
    categoryLoadingFirstTime: true,
    isProfileChange: false,
    userCoverImg: DEFAULT_COVER_PICTURE,
    loadingText: 'submit',
    error: [],
    isUsernamePopupDisplay: false,
    usernameValidation: {
      isMaxLength: false,
      isBeginLatter: false,
      isCharacter: false,
      isDashed: false,
      isSpaceValidation: false,
    },
    is_username_exists: '0',
    username: '',
    cityFlag: false,
    areaFlag: false,
    cityChangedFlag: false,
    is_email_exists: '0',
    is_claim_request_email: '0',
  })

  const [locationData, setLocationData] = useState()

  const { invalid, handleSubmit, formValues } = props
  // console.log({ props, formValues })
  let usernamenKey = Object.keys(stateData?.usernameValidation ?? {})
  let usernameFilter = usernamenKey.filter(function (key) {
    return stateData.usernameValidation[key]
  })
  const usernameRef = useRef()
  let usernameValidationPercentage = usernameFilter?.length * 25

  useEffect(() => {
    //$('.it_bus_org_name').focus()
    window.scrollTo({
      top: $('#edit_profile').offset().top - 20,
      behavior: 'smooth',
    })
    getCountryData()

    return () => {
      //props.profileInfo(customFunction.userToken());
      props.profileInfoReset()
      const user = customFunction.getUser()

      if (user?.user_detail) {
        let obj = {}
        if (!!user.user_detail.profile_picture_large)
          obj['userImg'] = user.user_detail.profile_picture_large

        if (!!user.user_detail.cover_picture_medium)
          obj['userCoverImg'] = user.user_detail.cover_picture_medium

        setStateData((st) => ({ ...st, obj }))
      }
    }
  }, [])

  useEffect(() => {
    if (!!formValues?.country_id?.value) {
      if (
        !!formValues?.zipcode &&
        formValues?.country_id?.label === environemnt.UNITED_STATE_TEXT
      ) {
        getDataUsingZip()
      }
      getStateData(formValues?.country_id?.value)
    }
  }, [formValues?.country_id?.value])

  useEffect(() => {
    if (!!locationData?.fromZip) {
      locationData?.states?.length && props.change('state_id', locationData?.states[0])
      locationData?.cities?.length && props.change('city_id', locationData?.cities[0])
    }
  }, [locationData?.fromZip])

  useEffect(() => {
    if (!!formValues?.state_id?.value) {
      if (!!locationData?.fromZip) {
        setLocationData((st) => ({ ...st, fromZip: false }))
      } else {
        getCityData(formValues?.state_id?.value)
      }
    }
  }, [formValues?.state_id?.value])

  useEffect(() => {
    if (!!formValues?.city_id?.value && !!locationData?.fromZip) {
      setLocationData((st) => ({ ...st, fromZip: false }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues?.city_id?.value])

  useEffect(() => {
    formValues.zipcode?.length === 5 &&
      formValues?.country_id?.label === environemnt.UNITED_STATE_TEXT &&
      getDataUsingZip()
    if (formValues?.zipcode?.length > 5 && !formValues?.zipcode?.includes('-')) {
      getStateData(formValues?.country_id?.value)
    }
  }, [formValues?.zipcode])

  useEffect(() => {
    if (!formValues?.zipcode && (formValues?.state_id || formValues?.city_id)) {
      props.change('state_id', null)
      props.change('city_id', null)
      formValues?.country_id?.value && getStateData(formValues?.country_id?.value)
    }
  }, [formValues?.zipcode])

  useEffect(() => {
    !!stateData?.userDetail?.id && categoryListById('category', '0')
  }, [stateData?.userDetail?.id])

  const getCountryData = async () => {
    const response = await api.countryList()
    const countries = parseData(response?.countries)
    setLocationData((st) => ({ ...st, countries }))
    props.profileInfo(customFunction.userToken())
  }

  const getStateData = async (country_id) => {
    props?.anyTouched && props.change('state_id', null)
    props?.anyTouched && props.change('city_id', null)
    props?.anyTouched && props.change('area', null)
    const response = await api.stateList(country_id)
    const states = parseData(response?.states)
    setLocationData((st) => ({ ...st, states, cities: [] }))
  }

  const getCityData = async (state_id) => {
    props?.anyTouched && props.change('city_id', null)
    props?.anyTouched && props.change('area', null)
    const response = await api.cityList(state_id)
    const cities = parseData(response?.cities)
    setLocationData((st) => ({ ...st, cities, cityFlag: !cities?.length }))
  }

  const handleCountryChange = (e) => {
    setLocationData((st) => ({ ...st, states: [], cities: [] }))
  }

  const getDataUsingZip = async () => {
    // console.log('getting data from zip')
    let api_url = environemnt.BASE_API + 'state-cities-from-zipcode'
    var params = {
      zipcode: formValues?.zipcode,
      country_id: formValues?.country_id?.value,
    }
    const response = await axios.post(api_url, params, {
      headers: { 'Content-Type': 'application/json' },
    })

    const responseData = response?.data?.data
    if (!responseData?.state_list?.length) {
      getStateData(formValues?.country_id?.value)
    } else {
      const states = parseData(responseData?.state_list)
      const cities = parseData(responseData?.city_list)
      setLocationData((st) => ({ ...st, states, cities, fromZip: props.anyTouched }))
    }
  }

  const areaChangedHandler = (event) => {
    setStateData((st) => ({ ...st, areaFlag: false, cityChangedFlag: true }))
  }

  const userProfileImg = (data, imgFile) => {
    setStateData((st) => ({ ...st, userImg: data, isProfileChange: true, userImgFile: imgFile }))
  }

  const userCoverImgHandler = async (data, imgFile) => {
    let token = customFunction.userToken()
    setStateData((st) => ({ ...st, userCoverImg: data }))
    let coverObj = { cover_picture: data, token: token }
    const response = await updateUserCoverImage(coverObj)
    if (response.status === true) {
      setStateData((st) => ({ ...st, userCoverImg: response.data.cover_picture_medium }))
      const { error, isFormValid, fileType } = validateGalleryFileType(imgFile)
      if (fileType !== '' && error === '' && isFormValid) {
        const formData = new FormData()
        formData.append('file_type', fileType)
        formData.append('file', imgFile)
        galleryCreateCall(formData)
      }
      props.coverImageAction(response.data.cover_picture_medium)
      let user = customFunction.getUser()
      user['user_detail']['cover_picture_medium'] = response.data.cover_picture_medium
      customFunction.setUser(user)
    }
  }

  const editProfileSubmitHandler = async () => {
    const data = { ...formValues }
    // console.log({ data })
    setStateData((st) => ({ ...st, loadingText: 'Loading...' }))
    data.city_id = data.city_id?.value
    data.state_id = data.state_id?.value
    data.country_id = data.country_id?.value
    data.relationship_type = data.relationship_type?.value
    data.firstname = data.firstname?.value ?? data.firstname
    data.lastname = data.lastname?.value ?? data.lastname
    if (!stateData.areaFlag && stateData.cityChangedFlag) {
      data.city_id = ''
    }
    if (stateData.areaFlag && stateData.cityChangedFlag) {
      data.area = ''
    }
    //if (data.birth_date) data.birth_date = moment(data.birth_date).format("YYYY-MM-DD");
    if (stateData.birth_date) {
      data.birth_date = moment(stateData.birth_date).format('YYYY-MM-DD')
    }

    if (stateData.isProfileChange) data.profile_picture = stateData.userImg

    if (userDetail?.user_type !== 'individual') {
      const cats = [
        data.category_main_id?.value,
        data.sub_category_id?.value,
        data.sub_of_sub_category_id?.value,
      ]?.filter(Boolean)
      if (cats?.length) data.category_id = cats
    }

    data.token = customFunction.userToken()
    delete data.sponsors_iteam_id

    const response = await updateUserProfile(data)
    // console.log({ response })
    setStateData((st) => ({ ...st, isProfileChange: false }))
    if (response.status === true) {
      window.scrollTo({
        top: $('#edit_profile').offset().top - 20,
        behavior: 'smooth',
      })
      customFunction.toaster('success', response.message)
      customFunction.setUser(response.profileInfo)

      setStateData((st) => ({
        ...st,
        userImg: response.profileInfo.user_detail.profile_picture_large,
        loadingText: 'Submit',
        userDetail: response.profileInfo,
      }))
      const { error, isFormValid, fileType } = validateGalleryFileType(stateData.userImgFile)
      if (fileType !== '' && error === '' && isFormValid) {
        const formData = new FormData()
        formData.append('file_type', fileType)
        formData.append('file', stateData.userImgFile)
        galleryCreateCall(formData)
        setStateData((st) => ({ ...st, userImgFile: '' }))
      }
      props.userStoreAction({ user: response.profileInfo })
    } else {
      if (
        response.status === 403 &&
        response.data !== undefined &&
        response.data.message !== undefined &&
        response.data.message !== ''
      ) {
        customFunction.toaster('error', response.data.message)
      }
      if (response.status === 422) {
        var errorMessage = 'Please check all required fields'
        var errorKey = ''
        $.each(response.data.errors, function (key, val) {
          errorMessage = val[0]
          errorKey = key
        })
        if (errorKey === 'email') {
          setStateData((st) => ({ ...st, is_email_exists: '1' }))
        }
        customFunction.toaster('error', errorMessage)
      } else {
        customFunction.toaster('error', 'Something went wrong')
      }
      setStateData((st) => ({ ...st, loadingText: 'Submit' }))
    }
  }

  useEffect(() => {
    // console.log({ props })
    let userObj = {}

    if (props?.editUser?.user_detail?.profile_picture_large)
      userObj['userImg'] = props.editUser.user_detail.profile_picture_large

    if (props?.editUser?.user_detail?.cover_picture_medium)
      userObj['userCoverImg'] = props.editUser.user_detail.cover_picture_medium

    if (props?.editUser?.user_detail?.birth_date) {
      if (stateData.birth_date) {
        userObj['birth_date'] = stateData.birth_date
      } else {
        userObj['birth_date'] = props.editUser.user_detail.birth_date
      }
    }

    if (props.editUser) userObj['username'] = props.editUser.username
    userObj['userDetail'] = props.editUser

    setStateData((st) => ({ ...st, ...userObj }))
  }, [props?.editUser?.user_detail])

  const datePickerHandler = (event) => {
    let new_date = moment(event).format('YYYY-MM-DD')

    setStateData((st) => ({ ...st, birth_date: new_date }))
  }

  const changeHandler = (event) => {
    let inputName = event.target.name
    let inputValue = event.target.value
    setStateData((st) => ({ ...st, [inputName]: inputValue }))
    if (inputName === 'username') {
      setStateData((st) => ({
        ...st,
        is_username_exists: '0',
        usernameValidation: validation.userNameValidation(inputValue, stateData.usernameValidation),
      }))
    } else if (inputName === 'email') {
      setStateData((st) => ({ ...st, is_email_exists: '0', is_claim_request_email: false }))
    }
  }

  const usernamePopup = () => {
    setTimeout(() => {
      setStateData((st) => ({ ...st, isUsernamePopupDisplay: !stateData.isUsernamePopupDisplay }))
    }, 200)
  }

  const checkUser = () => {
    if (validation.percentageOfValidation(stateData.usernameValidation) === 100) {
      checkUsername({
        username: stateData.username,
        id: customFunction.getUser().id,
      }).then(function (response) {
        if (response)
          setStateData((st) => ({ ...st, is_username_exists: response.data.is_username_exists }))
      })
    }
  }

  const userNameModel = (data) => () => {
    $('#change_username_modal').modal('hide')

    if (data === 'yes') usernameRef.current?.focus?.()
  }

  const userDialog = (event) => {
    $('#change_username_modal').modal('show')
  }

  useEffect(() => {
    !!formValues?.category_main_id &&
      categoryListById('subcategory', formValues?.category_main_id?.value)
  }, [formValues?.category_main_id])

  useEffect(() => {
    !!formValues?.sub_category_id &&
      categoryListById('subofsubcategory', formValues?.sub_category_id?.value)
  }, [formValues?.sub_category_id])

  const setCategoryValue = (categories, type) => {
    let matched
    if (userDetail?.categories?.length > 0) {
      userDetail?.categories?.every((cat) => {
        matched = categories?.find((c) => c?.value === cat?.category_id)
        if (matched) {
          props.change(type, { value: cat.category_id, label: cat.category_name })
          return false //exits loop
        } else return true
      })
    }
    return matched
  }

  const categoryListById = async (type, id = 0) => {
    const response = await api.singleCategoryList(id)
    if (response?.data?.categories) {
      const categories = response.data?.categories?.map(({ id: value, category_name: label }) => ({
        value,
        label,
      }))
      let obj = {}
      if (type === 'category') {
        setCategoryValue(categories, 'category_main_id')
        obj.categoryList = categories
        obj.subCategoryList = []
        obj.subOfSubCategoryList = []
        setStateData((st) => ({ ...st, ...obj }))
        props.change('sub_category_id', null)
        props.change('sub_of_sub_category_id', null)
      } else if (type === 'subcategory') {
        const hasValue = setCategoryValue(categories, 'sub_category_id')
        obj.subCategoryList = categories
        obj.subOfSubCategoryList = []
        setStateData((st) => ({ ...st, ...obj }))
        !hasValue && props.change('sub_category_id', null)
        props.change('sub_of_sub_category_id', null)
      } else if (type === 'subofsubcategory') {
        setCategoryValue(categories, 'sub_of_sub_category_id')
        obj.subOfSubCategoryList = categories
        setStateData((st) => ({ ...st, ...obj }))
      }
    }
  }

  const checkEmailExists = async () => {
    if (formValues?.email?.length > 0) {
      if (formValues?.email?.includes('@')) {
        try {
          const { data } = await checkEmail({
            id: customFunction.getUser().id,
            email: formValues.email,
          })
          setStateData((st) => ({
            ...st,
            is_email_exists: data.is_email_exists,
            is_claim_request_email: data.is_claim_request_email,
          }))
          return data?.is_email_exists
        } catch (error) {
          customFunction.toaster('error', 'An unexpected error occurred.')
          console.log({ error })
        }
      }
    }
  }
  const { userDetail, subCategoryList, subOfSubCategoryList } = stateData
  const canSubmit = !(props.anyTouched && invalid) && stateData.is_username_exists !== '1'
  return (
    <AppLayout>
      <div style={{ background: '#f6f6f6' }}>
        <EditProfileHeader
          userDetail={userDetail}
          userImg={stateData?.userImg}
          userCoverImg={stateData?.userCoverImg}
          userCoverImgHandler={userCoverImgHandler}
        />
        <LoadingOverlay show={!userDetail?.id} />
        <section className='listing_block edit_profile padding-reset'>
          <div className='container mt-4' id='edit_profile'>
            <h4 className='varela'>Edit profile</h4>

            {['business', 'organization'].includes(userDetail?.user_type) ? (
              <div className='bg-white blue-upper-line'>
                <div className='fields'>
                  <h5>GENERAL INFORMATION</h5>
                  {/* <label
                className='image-cropper-image'
                data-bs-toggle='modal'
                data-bs-target='#cropperModal'
                style={{ marginBottom: 15 }}
              >
                <div className={'pro_1 ' + userDetail?.user_type + '_profile'}>
                  <span>Update profile picture</span>
                  <img src={stateData?.userImg ?? DEFAULT_PROFILE_PICTURE} alt='' />
                </div>
              </label>
              <Cropperjs
                srcData={userProfileImg}
                imgsrc={stateData.userImg}
                updateProfile={editProfileSubmitHandler}
              /> */}
                  <fieldset>
                    {/* <div className='row fieldflex form-group'>
                  <div className='col-md-4'>
                    {!canSubmit ? (
                      <small className='text-danger text-center mb-2 mt-0'>
                        Please correct the errors below.
                      </small>
                    ) : null}
                    <button
                      onClick={editProfileSubmitHandler}
                      className={classNames(
                        `blue_btn submit-btn btn mt-0 mb-${
                          userDetail?.user_type === 'individual' ? '0' : '3'
                        }`,
                        !canSubmit ? 'disabled' : '',
                      )}
                      disabled={!canSubmit}
                    >
                      {stateData.loadingText}
                    </button>
                  </div>
                </div> */}

                    <div className='row fieldflex form-group'>
                      <div className='col-md-3 control-required'>iTeam ID #:</div>
                      <div className='col-md-9'>
                        <input
                          name='sponsors_iteam_id'
                          type='text'
                          placeholder=''
                          className='form-control'
                          autoComplete='off'
                          readOnly
                          value={userDetail?.imatrix_id != null ? userDetail?.imatrix_id : ''}
                        />
                      </div>
                    </div>
                    <div className='row fieldflex form-group'>
                      <div className='col-md-3 control-required'>
                        {userDetail?.user_type === 'organization' ? 'Organization' : 'Business'}{' '}
                        Name:
                      </div>
                      <div className='col-md-9'>
                        <Field
                          name='business_organization_name'
                          type='text'
                          component={renderInputField}
                          placeholder=''
                          className={`form-control ${
                            userDetail?.user_type !== 'individual' ? 'it_bus_org_name' : ''
                          }`}
                          validate={[validation.required]}
                        />
                      </div>
                    </div>
                    <div className='row fieldflex form-group'>
                      <div className='col-md-3'>Tagline:</div>
                      <div className='col-md-9'>
                        <Field
                          name='tagline'
                          component={renderInputField}
                          type='text'
                          className='form-control'
                          maxLength={80}
                        />
                      </div>
                    </div>
                    <div className='row fieldflex form-group'>
                      <div className='col-md-3'></div>
                      <div className='col-md-9'>
                        <a
                          className='link'
                          data-bs-toggle='modal'
                          data-bs-target='#change_password_email_modal'
                        >
                          {userDetail?.has_set_password ? 'Change' : 'Set'} Password
                        </a>
                      </div>
                    </div>
                  </fieldset>
                </div>
              </div>
            ) : null}
            <div className='bg-white blue-upper-line'>
              <div className='fields'>
                <h5>USER INFO</h5>

                <fieldset>
                  {userDetail?.user_type === 'individual' ? (
                    <div className='row fieldflex form-group'>
                      <div className='col-md-3 control-required'>iTeam ID #:</div>
                      <div className='col-md-9'>
                        <input
                          name='sponsors_iteam_id'
                          type='text'
                          placeholder=''
                          className='form-control'
                          autoComplete='off'
                          readOnly
                          value={userDetail?.imatrix_id != null ? userDetail?.imatrix_id : ''}
                        />
                      </div>
                    </div>
                  ) : null}

                  <div className='row fieldflex form-group'>
                    <div
                      className={`col-md-3 ${
                        userDetail?.user_type === 'individual' ? ' control-required' : ''
                      }`}
                    >
                      First name:
                    </div>
                    <div className='col-md-9'>
                      <Field
                        name='firstname'
                        type='text'
                        className={`form-control ${
                          userDetail?.user_type !== 'individual' ? 'it_bus_org_name' : ''
                        }`}
                        component={renderInputField}
                        {...(userDetail?.user_type === 'individual'
                          ? { validate: [validation.required] }
                          : {})}
                        label='Firstname'
                        placeholder='Enter first name'
                        autoComplete='off'
                      />
                    </div>
                  </div>
                  <div className='row fieldflex form-group'>
                    <div
                      className={`col-md-3 ${
                        userDetail?.user_type === 'individual' ? ' control-required' : ''
                      }`}
                    >
                      Last name:
                    </div>
                    <div className='col-md-9'>
                      <Field
                        name='lastname'
                        type='text'
                        className='form-control'
                        component={renderInputField}
                        {...(userDetail?.user_type === 'individual'
                          ? { validate: [validation.required] }
                          : {})}
                        label='Lastname'
                        placeholder='Enter last name'
                        autoComplete='off'
                      />
                    </div>
                  </div>
                  {userDetail?.user_type === 'individual' ? (
                    <div className='row fieldflex form-group'>
                      <div className='col-md-3'>Tagline:</div>
                      <div className='col-md-9'>
                        <Field
                          name='tagline'
                          component={renderInputField}
                          type='text'
                          className='form-control'
                          maxLength={80}
                        />
                      </div>
                    </div>
                  ) : (
                    <div className='row fieldflex form-group'>
                      <div className='col-md-3'>
                        Relationship to{' '}
                        {userDetail?.user_type === 'business' ? 'Business' : 'Organization'}:
                      </div>
                      <div className='col-md-9'>
                        <div className='for-select'>
                          <Field
                            type='text'
                            component={ReactSelect}
                            name='relationship_type'
                            options={relationshipList}
                            placeholder='Select...'
                            isSelect='false'
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  <div
                    className={`row fieldflex form-group ${
                      stateData?.is_claim_request_email ? 'align-items-start' : ''
                    }`}
                  >
                    <div
                      className={`col-md-3 control-required ${
                        stateData?.is_claim_request_email ? 'mt-1' : ''
                      }`}
                    >
                      Email Address:
                    </div>
                    <div className='col-md-9'>
                      <Field
                        name='email'
                        component={renderInputField}
                        type='text'
                        className='form-control'
                        onChange={changeHandler}
                        validate={[validation.required]}
                        onBlur={checkEmailExists}
                      />
                      {stateData.is_email_exists === '1' ? (
                        <span className='input_error text-danger'>
                          {stateData?.is_claim_request_email ? (
                            <DuplicateClaimRequestEmailError />
                          ) : (
                            'Email already used for a registered account, please use a different email'
                          )}
                        </span>
                      ) : null}
                    </div>
                  </div>
                  <div className='row fieldflex form-group'>
                    <div className='col-md-3'>
                      <label className='control-required'>Username:</label>
                    </div>
                    <div className='col-md-9 password_criteria'>
                      <Field
                        name='username'
                        type='text'
                        className='form-control password1'
                        component={renderInputField}
                        onFocus={(event) => {
                          usernamePopup(event)
                          // userDialog(event)
                        }}
                        onChange={changeHandler}
                        onBlur={(event) => {
                          usernamePopup()
                          checkUser()
                        }}
                        validate={[
                          validation.required,
                          validation.minValue1,
                          validation.noUsernameSpace,
                          validation.onylCharacter,
                          validation.maxLength20,
                          validation.isBeginLatter,
                          validation.isDashed,
                          validation.isDashedStart,
                        ]}
                        ref={(ref) => {
                          usernameRef.current = ref
                        }}
                        label='Password'
                        placeholder='Enter your username'
                        autoComplete='off'
                      />
                      {stateData.is_username_exists === '1' && (
                        <span className='input_error text-danger'>{DUPLICATE_USER}</span>
                      )}
                      {stateData.isUsernamePopupDisplay && (
                        <div className='criteria'>
                          <div className='progress'>
                            <div
                              className='progress-bar'
                              role='progressbar'
                              aria-valuenow='0'
                              aria-valuemin='0'
                              aria-valuemax='100'
                              style={{
                                width: usernameValidationPercentage + '%',
                              }}
                            >
                              <span className='sr-only'>70% Complete</span>
                            </div>
                          </div>
                          <h3> Criteria: </h3>
                          <ul>
                            <li
                              className={
                                stateData.usernameValidation.isSpaceValidation ? 'success' : ''
                              }
                            >
                              No Spaces, cannot start or end with a space
                            </li>
                            <li
                              className={stateData.usernameValidation.isMaxLength ? 'success' : ''}
                            >
                              Username MUST be between 1 and 20 characters
                            </li>
                            <li
                              className={
                                stateData.usernameValidation.isBeginLatter ? 'success' : ''
                              }
                            >
                              Username MUST begin with a LETTER
                            </li>
                            <li
                              className={stateData.usernameValidation.isCharacter ? 'success' : ''}
                            >
                              Only Characters A-Z a-z and 0-9 are allowed
                            </li>
                            <li className={stateData.usernameValidation.isDashed ? 'success' : ''}>
                              Dash is allowed BUT MUST be surrounded by letters
                            </li>
                          </ul>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className='row fieldflex form-group'>
                    <div className='col-md-3'>iTRUSTit Web Address:</div>
                    <div className='col-md-9'>
                      <input
                        name='webaddress'
                        type='text'
                        placeholder={SITE_ADDRESS}
                        className='form-control'
                        value={
                          SITE_ADDRESS +
                          PROFILE_ROUTE_CONST +
                          (userDetail?.slug != null ? userDetail?.slug : '')
                        }
                        readOnly
                      />
                    </div>
                  </div>
                  {userDetail?.user_type === 'individual' ? (
                    <div className='row fieldflex form-group'>
                      <div className='col-md-3'></div>
                      <div className='col-md-9'>
                        <a
                          className='link'
                          data-bs-toggle='modal'
                          data-bs-target='#change_password_email_modal'
                        >
                          {userDetail?.has_set_password ? 'Change' : 'Set'} Password
                        </a>
                      </div>
                    </div>
                  ) : null}
                </fieldset>
              </div>
            </div>
            <div className='bg-white blue-upper-line'>
              <div className='fields'>
                <h5>ADDRESS INFORMATION</h5>

                <fieldset>
                  <div className='row fieldflex form-group'>
                    <div
                      className={`col-md-3 ${
                        userDetail?.user_type !== 'individual' ? ' control-required' : ''
                      }`}
                    >
                      Country:
                    </div>
                    <div className='col-md-9'>
                      <div className='for-select'>
                        <Field
                          type='text'
                          component={ReactSelect}
                          name='country_id'
                          options={locationData?.countries}
                          onChange={handleCountryChange}
                          {...(userDetail?.user_type !== 'individual'
                            ? { validate: [validation.required] }
                            : {})}
                          placeholder='Select...'
                          isSelect='false'
                        />
                      </div>
                    </div>
                  </div>
                  <AnimatePresence>
                    {formValues?.country_id ? (
                      <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        className='row fieldflex form-group'
                      >
                        <div
                          className={`col-md-3 ${
                            userDetail?.user_type !== 'individual' ? ' control-required' : ''
                          }`}
                        >
                          Zip / Postal Code:
                        </div>
                        <div className='col-md-9'>
                          <Field
                            name='zipcode'
                            component={renderInputField}
                            type='text'
                            className='form-control'
                            {...(userDetail?.user_type !== 'individual'
                              ? { validate: [validation.required] }
                              : {})}
                          />
                        </div>
                        <div className='row mt-1 mt-md-0 ms-md-4'>
                          <div className='col'>
                            <small style={{ color: '#999' }}>
                              Hint: For United States users, enter ZIP CODE to populate State and
                              City fields
                            </small>
                          </div>
                        </div>
                      </motion.div>
                    ) : null}
                  </AnimatePresence>
                  <AnimatePresence>
                    {locationData?.states?.length > 0 ? (
                      <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        className='row fieldflex form-group'
                      >
                        <div
                          className={`col-md-3 ${
                            userDetail?.user_type !== 'individual' ? ' control-required' : ''
                          }`}
                        >
                          State:
                        </div>
                        <div className='col-md-9'>
                          <div className='for-select'>
                            <Field
                              type='text'
                              className='form-control'
                              component={ReactSelect}
                              name='state_id'
                              options={locationData?.states}
                              {...(userDetail?.user_type !== 'individual'
                                ? { validate: [validation.required] }
                                : {})}
                              placeholder='Select...'
                              isSelect='false'
                            />
                          </div>
                        </div>
                      </motion.div>
                    ) : null}
                  </AnimatePresence>
                  <AnimatePresence>
                    {locationData?.cities?.length > 0 ? (
                      <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        className='row fieldflex form-group'
                      >
                        <div
                          className={`col-md-3 ${
                            userDetail?.user_type !== 'individual' ? ' control-required' : ''
                          }`}
                        >
                          City:
                        </div>
                        <div className='col-md-9'>
                          <div className='for-select'>
                            <Field
                              type='text'
                              className='form-control'
                              component={ReactSelect}
                              name='city_id'
                              options={locationData?.cities}
                              {...(userDetail?.user_type !== 'individual'
                                ? { validate: [validation.required] }
                                : {})}
                              placeholder='Select...'
                              isSelect='false'
                            />
                          </div>
                        </div>
                      </motion.div>
                    ) : locationData?.cityFlag ? (
                      <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        className='row fieldflex form-group'
                      >
                        <div className='col-md-3'>
                          <label className=''>City/Area:</label>
                        </div>
                        <div className='col-md-9'>
                          <Field
                            name='area'
                            type='text'
                            className='form-control'
                            component={renderInputField}
                            label='City/Area'
                            placeholder='Enter city/area'
                            autoComplete='off'
                            // validate={[validation.required]}
                            onChange={areaChangedHandler}
                          />
                        </div>
                      </motion.div>
                    ) : null}
                  </AnimatePresence>
                  <div className='row fieldflex form-group'>
                    <div
                      className={`col-md-3 ${
                        userDetail?.user_type !== 'individual' ? ' control-required' : ''
                      }`}
                    >
                      Address 1:
                    </div>
                    <div className='col-md-9'>
                      <Field
                        name='address_line_1'
                        component={renderInputField}
                        type='text'
                        className='form-control'
                        {...(userDetail?.user_type !== 'individual'
                          ? { validate: [validation.required] }
                          : {})}
                      />
                    </div>
                  </div>
                  <div className='row fieldflex form-group'>
                    <div className='col-md-3'>Address 2:</div>
                    <div className='col-md-9'>
                      <Field
                        name='address_line_2'
                        component={renderInputField}
                        type='text'
                        className='form-control'
                        // validate={[validation.required]}
                      />
                    </div>
                  </div>
                  <div className='row fieldflex form-group'>
                    <div className='col-md-3'>Main phone (Day):</div>
                    <div className='col-md-9'>
                      <Field
                        name='phone_no_1'
                        component={renderInputField}
                        type='text'
                        className='form-control'
                        // validate={[validation.phoneNumber]}
                      />
                    </div>
                  </div>
                  <div className='row fieldflex form-group'>
                    <div className='col-md-3'>Main phone (Evening):</div>
                    <div className='col-md-9'>
                      <Field
                        name='phone_no_2'
                        component={renderInputField}
                        type='text'
                        className='form-control'
                        // validate={[validation.phoneNumber]}
                      />
                    </div>
                  </div>
                  <div className='row fieldflex form-group'>
                    <div className='col-md-3'>Mobile:</div>
                    <div className='col-md-9'>
                      <Field
                        name='mobile_no'
                        component={renderInputField}
                        type='text'
                        className='form-control'
                        // validate={[validation.phoneNumber]}
                      />
                    </div>
                  </div>
                  <div className='row fieldflex form-group'>
                    <div className='col-md-3'>Fax:</div>
                    <div className='col-md-9'>
                      <Field
                        name='fax_no'
                        component={renderInputField}
                        type='text'
                        className='form-control'
                      />
                    </div>
                  </div>
                  <div className='row fieldflex form-group'>
                    <div className='col-md-3'>Email2:</div>
                    <div className='col-md-9'>
                      <Field
                        name='email_2'
                        component={renderInputField}
                        type='text'
                        className='form-control'
                        validate={[validation.email]}
                      />
                    </div>
                  </div>
                  <div className='row fieldflex form-group'>
                    <div className='col-md-3'>Email3:</div>
                    <div className='col-md-9'>
                      <Field
                        name='email_3'
                        component={renderInputField}
                        type='text'
                        className='form-control'
                        validate={[validation.email]}
                      />
                    </div>
                  </div>
                  <div className='row fieldflex form-group'>
                    <div className='col-md-3'>Email4:</div>
                    <div className='col-md-9'>
                      <Field
                        name='email_4'
                        component={renderInputField}
                        type='text'
                        className='form-control'
                        validate={[validation.email]}
                      />
                    </div>
                  </div>
                  <div className='row fieldflex form-group'>
                    <div className='col-md-3'>Website1:</div>
                    <div className='col-md-9'>
                      <Field
                        name='website_1'
                        component={renderInputField}
                        type='text'
                        className='form-control'
                        validate={[validation.urlValidate]}
                      />
                    </div>
                  </div>
                  <div className='row fieldflex form-group'>
                    <div className='col-md-3'>Website2:</div>
                    <div className='col-md-9'>
                      <Field
                        name='website_2'
                        component={renderInputField}
                        type='text'
                        className='form-control'
                        validate={[validation.urlValidate]}
                      />
                    </div>
                  </div>
                  {/* <div className='row fieldflex form-group'>
                      <div className='col-md-3'>Website3:</div>
                      <div className='col-md-9'>
                        <Field
                          name='website_3'
                          component={renderInputField}
                          type='text'
                          className='form-control'
                          validate={[validation.urlValidate]}
                        />
                      </div>
                    </div>
                    <div className='row fieldflex form-group'>
                      <div className='col-md-3'>Website4:</div>
                      <div className='col-md-9'>
                        <Field
                          name='website_4'
                          component={renderInputField}
                          type='text'
                          className='form-control'
                          validate={[validation.urlValidate]}
                        />
                      </div>
                    </div> */}
                  <div className='row fieldflex form-group'>
                    <div className='col-md-3'>Facebook:</div>
                    <div className='col-md-9'>
                      <Field
                        name='facebook'
                        component={renderInputField}
                        type='text'
                        className='form-control'
                        validate={[validation.urlValidate]}
                      />
                    </div>
                  </div>
                  <div className='row fieldflex form-group'>
                    <div className='col-md-3'>Fiverr:</div>
                    <div className='col-md-9'>
                      <Field
                        name='fiverr'
                        component={renderInputField}
                        type='text'
                        className='form-control'
                        validate={[validation.urlValidate]}
                      />
                    </div>
                  </div>
                  <div className='row fieldflex form-group'>
                    <div className='col-md-3'>Instagram:</div>
                    <div className='col-md-9'>
                      <Field
                        name='instagram'
                        component={renderInputField}
                        type='text'
                        className='form-control'
                        validate={[validation.urlValidate]}
                      />
                    </div>
                  </div>
                  <div className='row fieldflex form-group'>
                    <div className='col-md-3'>LinkedIn:</div>
                    <div className='col-md-9'>
                      <Field
                        name='linkedin'
                        component={renderInputField}
                        type='text'
                        className='form-control'
                        validate={[validation.urlValidate]}
                      />
                    </div>
                  </div>
                  <div className='row fieldflex form-group'>
                    <div className='col-md-3'>Pinterest:</div>
                    <div className='col-md-9'>
                      <Field
                        name='pinterest'
                        component={renderInputField}
                        type='text'
                        className='form-control'
                        validate={[validation.urlValidate]}
                      />
                    </div>
                  </div>
                  <div className='row fieldflex form-group'>
                    <div className='col-md-3'>TikTok:</div>
                    <div className='col-md-9'>
                      <Field
                        name='tiktok'
                        component={renderInputField}
                        type='text'
                        className='form-control'
                        validate={[validation.urlValidate]}
                      />
                    </div>
                  </div>
                  <div className='row fieldflex form-group'>
                    <div className='col-md-3'>Twitter:</div>
                    <div className='col-md-9'>
                      <Field
                        name='twitter'
                        component={renderInputField}
                        type='text'
                        className='form-control'
                        validate={[validation.urlValidate]}
                      />
                    </div>
                  </div>
                  <div className='row fieldflex form-group'>
                    <div className='col-md-3'>YouTube:</div>
                    <div className='col-md-9'>
                      <Field
                        name='youtube'
                        component={renderInputField}
                        type='text'
                        className='form-control'
                        validate={[validation.urlValidate]}
                      />
                    </div>
                  </div>
                  <div className='row fieldflex form-group'>
                    <div className='col-md-3'>WhatsApp Number:</div>
                    <div className='col-md-9'>
                      <Field
                        name='whatsapp'
                        component={renderInputField}
                        type='text'
                        className='form-control'
                        // validate={[validation.urlValidate]}
                      />
                    </div>
                  </div>
                  <div className='row fieldflex form-group'>
                    <div className='col-md-3'>PayPal:</div>
                    <div className='col-md-9'>
                      <Field
                        name='paypal'
                        component={renderInputField}
                        type='text'
                        className='form-control'
                        validate={[validation.urlValidate]}
                      />
                    </div>
                  </div>
                  <div className='row fieldflex form-group'>
                    <div className='col-md-3'>Venmo:</div>
                    <div className='col-md-9'>
                      <Field
                        name='venmo'
                        component={renderInputField}
                        type='text'
                        className='form-control'
                        validate={[validation.urlValidate]}
                      />
                    </div>
                  </div>
                </fieldset>
              </div>
            </div>
            <div className='bg-white blue-upper-line'>
              <div className='fields'>
                <h5>ADDITIONAL INFORMATION</h5>

                <fieldset>
                  {['business', 'organization'].includes(userDetail?.user_type) ? (
                    <div className='row fieldflex form-group'>
                      <div className='col-md-3'>Profession:</div>
                      <div className='col-md-9'>
                        <Field
                          name='profession'
                          component={renderInputField}
                          type='text'
                          className='form-control'
                        />
                      </div>
                    </div>
                  ) : null}
                  <div className='row fieldflex form-group'>
                    <div className='col-md-3'>About Me</div>
                    <div className='col-md-9'>
                      <Field
                        name='about_me'
                        component={renderTextAreaField}
                        valueKey={
                          userDetail?.user_detail &&
                          userDetail?.user_detail.about_me &&
                          userDetail?.user_detail.about_me
                        }
                      />
                    </div>
                  </div>
                  {userDetail?.user_type === 'individual' ? (
                    <>
                      <div className='row fieldflex form-group'>
                        <div className='col-md-3'>Gender:</div>
                        <div className='col-md-9'>
                          <Field type='radio' component='input' name='gender' value='male' /> Male
                          &nbsp;
                          <Field type='radio' component='input' name='gender' value='female' />{' '}
                          Female &nbsp;
                          <Field type='radio' component='input' name='gender' value='other' /> Other
                        </div>
                      </div>
                      <div className='row fieldflex form-group'>
                        <div className='col-md-3'>Birthday:</div>
                        <div className='col-md-9 it-user-bdate'>
                          <DatePicker
                            name='birth_date'
                            selected={
                              stateData.birth_date !== ''
                                ? new Date(`${stateData.birth_date}T00:00:00`)
                                : new Date()
                            }
                            closeOnSelect={true}
                            closeOnTab={true}
                            onChange={datePickerHandler}
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode='select'
                            className='it-user-bdate-input'
                          />
                        </div>
                      </div>
                    </>
                  ) : null}
                </fieldset>
              </div>
              {userDetail?.user_type === 'individual' ? (
                <>
                  {!canSubmit ? (
                    <small className='text-danger text-center mb-0'>
                      Please correct the errors above.
                    </small>
                  ) : null}
                  <button
                    onClick={editProfileSubmitHandler}
                    className={classNames('blue_btn submit-btn btn', !canSubmit ? 'disabled' : '')}
                    disabled={!canSubmit}
                  >
                    {stateData.loadingText}
                  </button>
                </>
              ) : null}
            </div>
            {['business', 'organization'].includes(userDetail?.user_type) ? (
              <div className='bg-white blue-upper-line '>
                <div className='row fieldflex form-group mt-4'>
                  <div className='col-md-3'>
                    <label className='control-required'>Category:</label>
                  </div>
                  <div className='col-md-6'>
                    <div className='for-select'>
                      <Field
                        type='text'
                        component={ReactSelect}
                        name='category_main_id'
                        options={stateData?.categoryList}
                        placeholder='Select...'
                        isSelect='false'
                      />
                    </div>
                  </div>
                </div>
                {subCategoryList.length > 0 ? (
                  <div className='row fieldflex form-group'>
                    <div className='col-md-3'></div>
                    <div className='col-md-6'>
                      <div className='for-select'>
                        <Field
                          type='text'
                          component={ReactSelect}
                          name='sub_category_id'
                          options={subCategoryList}
                          placeholder='Select...'
                          isSelect='false'
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  ''
                )}
                {subOfSubCategoryList.length > 1 ? (
                  <div className='row fieldflex form-group'>
                    <div className='col-md-3'></div>
                    <div className='col-md-6'>
                      <div className='for-select'>
                        <Field
                          type='text'
                          component={ReactSelect}
                          name='sub_of_sub_category_id'
                          options={subOfSubCategoryList}
                          placeholder='Select...'
                          isSelect='false'
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  ''
                )}
                {!canSubmit ? (
                  <small className='text-danger text-center mb-0'>
                    Please correct the errors above.
                  </small>
                ) : null}
                <button
                  onClick={editProfileSubmitHandler}
                  className={classNames('blue_btn submit-btn btn', !canSubmit ? 'disabled' : '')}
                  disabled={!canSubmit}
                >
                  {stateData.loadingText}
                </button>
              </div>
            ) : null}
          </div>
        </section>
        <UsernameModal usernameModel={userNameModel} />
        <ResendVerificationEmailModal />
        <ChangePassword method={userDetail?.has_set_password ? 'change' : 'set'} />
      </div>
    </AppLayout>
  )
}

EditProfileNew = reduxForm({
  form: 'editProfileForm',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  updateUnregisteredFields: true,
})(EditProfileNew)

const selector = formValueSelector('editProfileForm')
EditProfileNew = connect((state) => {
  const formValues = selector(
    state,
    'sponsors_iteam_id',
    'business_organization_name',
    'firstname',
    'lastname',
    'tagline',
    'relationship_type',
    'email',
    'username',
    'webaddress',
    'country_id',
    'zipcode',
    'state_id',
    'city_id',
    'area',
    'address_line_1',
    'address_line_2',
    'phone_no_1',
    'phone_no_2',
    'mobile_no',
    'fax_no',
    'email_2',
    'email_3',
    'email_4',
    'website_1',
    'website_2',
    'facebook',
    'fiverr',
    'instagram',
    'linkedin',
    'pinterest',
    'tiktok',
    'twitter',
    'youtube',
    'whatsapp',
    'paypal',
    'venmo',
    'profession',
    'about_me',
    'gender',
    'birth_date',
    'category_main_id',
    'sub_category_id',
    'sub_of_sub_category_id',
  )
  return {
    formErrors: getFormSyncErrors('editProfileForm')(state),
    formValues,
  }
})(EditProfileNew)
export default EditProfileNew
