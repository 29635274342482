import React from 'react'
import { Link } from 'react-router-dom'
import { PROFILE_ROUTE } from '../../../../services/routeConst'

const CoverPhotoLink = ({ coverImg, avatarImg, label }) => {
  return (
    <Link to={PROFILE_ROUTE}>
      <div
        className='pro_coverpic'
        style={{
          background: 'url(' + coverImg + ') no-repeat',
        }}
      >
        <div
          className='pro_pic'
          style={{
            background: 'url(' + avatarImg + ') no-repeat',
          }}
        />
      </div>
      <div className='user_name text-center'>{label}</div>
    </Link>
  )
}

export default CoverPhotoLink
