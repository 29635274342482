import axios from 'axios'
import { customLogger } from '../utils/CustomLogger'
import * as environemnt from './environment'
import { userToken, toaster } from './customFunction'
import { NETWORK_ERROR_MSG } from './const'

export const activityListCall = (request) => {
  let api_url = environemnt.BASE_API + 'user-activities'

  return axios
    .post(api_url, request, {
      headers: { Authorization: userToken(), Accept: 'application/x.iTRUSTit.v1+json' },
    })
    .then(function (response) {
      return response
    })
    .catch(function (error) {
      if (error === undefined || error.response === undefined) {
        toaster('error', NETWORK_ERROR_MSG)
      } else if ([401, 403, 500].indexOf(error.response.status) >= 0) {
        if (
          typeof error.response !== 'undefined' &&
          typeof error.response.data !== 'undefined' &&
          typeof error.response.data.message !== 'undefined' &&
          error.response.data.message
        ) {
          toaster('error', error.response.data.message)
        } else {
          toaster('error', 'Something went wrong!!!')
        }
      }
      console.log('error:', error)
      customLogger(error)
      return error.response
    })
}

export const notificationListCall = (request) => {
  let api_url = environemnt.BASE_API + 'user-notifications'
  if (typeof request.page !== 'undefined' && request.page > 0) {
    api_url = environemnt.BASE_API + 'user-notifications/' + request.page
  }
  return axios
    .get(api_url, {
      headers: { Authorization: userToken(), Accept: 'application/x.iTRUSTit.v1+json' },
    })
    .then(function (response) {
      return response
    })
    .catch(function (error) {
      if (error === undefined || error.response === undefined) {
        toaster('error', NETWORK_ERROR_MSG)
      } else if ([401, 403, 500].indexOf(error.response.status) >= 0) {
        if (
          typeof error.response !== 'undefined' &&
          typeof error.response.data !== 'undefined' &&
          typeof error.response.data.message !== 'undefined' &&
          error.response.data.message
        ) {
          toaster('error', error.response.data.message)
        } else {
          toaster('error', 'Something went wrong!!!')
        }
      }
      customLogger(error)
      return error.response
    })
}

export const totalIteamCountListCall = (request) => {
  let api_url = environemnt.BASE_API + 'total-iteam-count'
  return axios
    .get(api_url, {
      headers: { Authorization: userToken(), Accept: 'application/x.iTRUSTit.v1+json' },
    })
    .then(function (response) {
      return response
    })
    .catch(function (error) {
      if (
        typeof error.response !== 'undefined' &&
        typeof error.response.data !== 'undefined' &&
        typeof error.response.data.message !== 'undefined' &&
        error.response.data.message
      ) {
        toaster('error', error.response.data.message)
      }
      customLogger(error)
      return error.response
    })
}

export const getImatrixSessionIdCall = (request) => {
  let api_url = environemnt.BASE_API + 'get-imatrix-session'
  return axios
    .get(api_url, {
      headers: { Authorization: userToken(), Accept: 'application/x.iTRUSTit.v1+json' },
    })
    .then(function (response) {
      return response
    })
    .catch(function (error) {
      if (
        typeof error.response !== 'undefined' &&
        typeof error.response.data !== 'undefined' &&
        typeof error.response.data.message !== 'undefined' &&
        error.response.data.message
      ) {
        toaster('error', error.response.data.message)
      }
      customLogger(error)
      return error.response
    })
}

export const sectionWiseNotificationListCall = () => {
  let api_url = environemnt.BASE_API + 'section-wise-notifications'

  return axios
    .get(api_url, {
      headers: { Authorization: userToken(), Accept: 'application/x.iTRUSTit.v1+json' },
    })
    .then(function (response) {
      return response
    })
    .catch(function (error) {
      if (error === undefined || error.response === undefined) {
        toaster('error', NETWORK_ERROR_MSG)
      } else if ([401, 403, 500].indexOf(error.response.status) >= 0) {
        if (
          typeof error.response !== 'undefined' &&
          typeof error.response.data !== 'undefined' &&
          typeof error.response.data.message !== 'undefined' &&
          error.response.data.message
        ) {
          toaster('error', error.response.data.message)
        } else {
          toaster('error', 'Something went wrong!!!')
        }
      }
      customLogger(error)
      return error.response
    })
}

export const notificationListBySectionCall = (request) => {
  let api_url = environemnt.BASE_API + 'notifications-by-section'

  return axios
    .post(api_url, request, {
      headers: { Authorization: userToken(), Accept: 'application/x.iTRUSTit.v1+json' },
    })
    .then(function (response) {
      return response
    })
    .catch(function (error) {
      if (error === undefined || error.response === undefined) {
        toaster('error', NETWORK_ERROR_MSG)
      } else if ([401, 403, 500].indexOf(error.response.status) >= 0) {
        if (
          typeof error.response !== 'undefined' &&
          typeof error.response.data !== 'undefined' &&
          typeof error.response.data.message !== 'undefined' &&
          error.response.data.message
        ) {
          toaster('error', error.response.data.message)
        } else {
          toaster('error', 'Something went wrong!!!')
        }
      }
      customLogger(error)
      return error.response
    })
}

export const acceptMemberRequestCall = (request) => {
  let api_url = environemnt.BASE_API + 'member-accepte'

  return axios
    .post(api_url, request, {
      headers: { Authorization: userToken(), Accept: 'application/x.iTRUSTit.v1+json' },
    })
    .then(function (response) {
      return response
    })
    .catch(function (error) {
      if (error === undefined || error.response === undefined) {
        toaster('error', NETWORK_ERROR_MSG)
      } else if ([401, 403, 500].indexOf(error.response.status) >= 0) {
        if (
          typeof error.response !== 'undefined' &&
          typeof error.response.data !== 'undefined' &&
          typeof error.response.data.message !== 'undefined' &&
          error.response.data.message
        ) {
          toaster('error', error.response.data.message)
        } else {
          toaster('error', 'Something went wrong!!!')
        }
      }
      customLogger(error)
      return error.response
    })
}

export const declineMemberRequestCall = (request) => {
  let api_url = environemnt.BASE_API + 'member-decline'

  return axios
    .post(api_url, request, {
      headers: { Authorization: userToken(), Accept: 'application/x.iTRUSTit.v1+json' },
    })
    .then(function (response) {
      return response
    })
    .catch(function (error) {
      if (error === undefined || error.response === undefined) {
        toaster('error', NETWORK_ERROR_MSG)
      } else if ([401, 403, 500].indexOf(error.response.status) >= 0) {
        if (
          typeof error.response !== 'undefined' &&
          typeof error.response.data !== 'undefined' &&
          typeof error.response.data.message !== 'undefined' &&
          error.response.data.message
        ) {
          toaster('error', error.response.data.message)
        } else {
          toaster('error', 'Something went wrong!!!')
        }
      }
      customLogger(error)
      return error.response
    })
}

export const deleteMemberNotificationCall = (request) => {
  let api_url = environemnt.BASE_API + 'delete-notification'

  return axios
    .post(api_url, request, {
      headers: { Authorization: userToken(), Accept: 'application/x.iTRUSTit.v1+json' },
    })
    .then(function (response) {
      return response
    })
    .catch(function (error) {
      if (error === undefined || error.response === undefined) {
        toaster('error', NETWORK_ERROR_MSG)
      } else if ([401, 403, 500].indexOf(error.response.status) >= 0) {
        if (
          typeof error.response !== 'undefined' &&
          typeof error.response.data !== 'undefined' &&
          typeof error.response.data.message !== 'undefined' &&
          error.response.data.message
        ) {
          toaster('error', error.response.data.message)
        } else {
          toaster('error', 'Something went wrong!!!')
        }
      }
      customLogger(error)
      return error.response
    })
}

export const deleteNotifications = async (request = {}) => {
  let api_url = environemnt.BASE_API + 'delete-notifications'

  try {
    const response = await axios.post(api_url, request, {
      headers: { Authorization: userToken(), Accept: 'application/x.iTRUSTit.v1+json' },
    })
    return response
  } catch (error) {
    if (error === undefined || error.response === undefined) {
      toaster('error', NETWORK_ERROR_MSG)
    } else if ([401, 403, 500].indexOf(error.response.status) >= 0) {
      if (
        typeof error.response !== 'undefined' &&
        typeof error.response.data !== 'undefined' &&
        typeof error.response.data.message !== 'undefined' &&
        error.response.data.message
      ) {
        toaster('error', error.response.data.message)
      } else {
        toaster('error', 'Something went wrong!!!')
      }
    }
    customLogger(error)
    return error.response
  }
}
