import React, { Component } from 'react'

//Google Analytics
import ReactGA from 'react-ga'
import { GOOGLE_ANALYTICS_ID } from '../../services/environment'

import Header from '../Header'
import Footer from '../Footer'
import UserHeader from '../UserHeader'
import { withRouter } from 'react-router-dom'
import * as route from '../../services/routeConst'
import { isUserLogin, userToken } from '../../services/customFunction'
import $J from 'jquery'

import './AppLayout.scss'
import AddBusinessOrOrganizationFooter from '../../components/AddBusinessOrOrganizationFooter'
import { connect } from 'react-redux'
class AppLayout extends Component {
  constructor(props) {
    super(props)
    this.state = {
      imatrix_id: this.props.imatrix_id,
    }
  }

  onlineUserListChanged = (chat_users_online_list) => {
    //console.log('in onlineUserListChanged : AppLayout: chat_users_online_list: ', chat_users_online_list);
    if (typeof this.props.onlineUserListChanged === 'function') {
      this.props.onlineUserListChanged(chat_users_online_list)
    }
  }

  addBusinessHandler = (event) => {
    $J('#add_business_or_organization_footer_modal_0').modal('show')
  }

  openLoginPopup = (event) => {
    $J('#login_modal').modal('show')
  }

  componentDidMount() {
    ReactGA.initialize(GOOGLE_ANALYTICS_ID)
    ReactGA.pageview(window.location.pathname + window.location.search)
  }

  goToClaimRoute = () => {
    this.props.history.push({ pathname: route.ADD_CLAIM_ROUTE, state: { isClaim: true } })
  }
  render() {
    return (
      <div>
        <AddBusinessOrOrganizationFooter />
        <UserHeader
          hideHeader={this.props.routeActive === '/search' && this.props.hideHeader}
          routeActive={this.props.routeActive}
          onlineUserListChanged={
            isUserLogin() && userToken() ? this.onlineUserListChanged : () => {}
          }
          isLoggedIn={isUserLogin() && userToken()}
        />
        {/* <Header
            hideHeader={this.props.routeActive === '/search' && this.props.hideHeader}
            routeActive={this.props.routeActive}
            imatrix_id={this.props.imatrix_id}
        /> */}
        <div className='App-intro'>{this.props.children}</div>
        {this.props.isDisplayFooter !== false && (
          <section className='footer_top_blk'>
            <div className='container'>
              <div className='footer_container'>
                <p className='varela'>
                  Didn't find the business or organization you're looking for?
                </p>
                <button
                  className='blue_btn it-home-add-bus-btn'
                  onClick={
                    isUserLogin() && userToken() ? this.addBusinessHandler : this.openLoginPopup
                  }
                >
                  ADD BUSINESS/ORG
                </button>
                <p className='varela'>Need to claim ownership of your business or organization?</p>
                <button className='blue_btn it-home-add-bus-btn' onClick={this.goToClaimRoute}>
                  CLAIM BUSINESS/ORG
                </button>
              </div>
            </div>
          </section>
        )}
        {!this.props.hideFooter ? <Footer /> : null}
      </div>
    )
  }
}
function mapStateToProps(state) {
  return {
    hideHeader: state.UserReducer.hideHeader,
  }
}

export default connect(mapStateToProps, null)(withRouter(AppLayout))
