import React, { useEffect, useState } from 'react'
import { Field, formValueSelector, reduxForm } from 'redux-form'
import { Link, useHistory } from 'react-router-dom'

import * as validation from '../../services/formValidation'
import renderInputField from '../Form/Input'
import AppLayout from '../AppLayout'
import SocialLogin from '../SocialLogin/SocialLogin'
import * as route from '../../services/routeConst'
import {
  ADD_BUSINESS_TITLE,
  ADD_ORGANIZATION_TITLE,
  CLAIM_BUSINESS_TITLE,
  CLAIM_ORGANIZATION_TITLE,
  SIGNUP_BUSINESS_TITLE,
  SIGNUP_ORGANIZATION_TITLE,
  SIGNUP_TITLE,
} from '../../services/titleConst'
import { checkSponsorId } from '../../services/userApiService'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import SignupWithEmail from './SignupWithEmail'
import ConfirmSocialSignupModal, {
  hideConfirmSocialSignupModal,
  showConfirmSocialSignupModal,
} from './ConfirmSocialSignupModal'
import { DEFAULT_PROFILE_PICTURE } from '../../services/const'
import {
  getInviteMatrixId,
  removeInviteMatrixId,
  setInviteMatrixId,
  setOtpToken,
  setToken,
  setUser,
  toaster,
  userToken,
} from '../../services/customFunction'
import LoadingOverlay from '../CommonComponents/LoadingOverlay'
import AddBusOrgAsCustomer from './AddBusOrgAsCustomer'
import SocialUserExistsModal, {
  hideSocialUserExistsModal,
  showSocialUserExistsModal,
} from './SocialUserExistsModal'
import ResendVerificationEmailModal from '../CommonComponents/ResendVerificationEmailModal/ResendVerificationEmailModal'

let Signup = (props) => {
  const [status, setStatus] = useState('idle')
  const { socialLoginAction, linkedInLoginAction, formValues, resetAuthDataAction } = props
  const locationState = props?.location?.state ?? {}
  const {
    signupType: lSignupType,
    isClaim,
    isAdd,
    addType,
    idToClaim,
    loginUserData,
    busOrgName,
  } = locationState
  const storedUserType =
    lSignupType ?? props?.user?.signup_type ?? localStorage.getItem('signupUserType')
  const signupUserType = storedUserType?.length <= 0 ? 'individual' : storedUserType
  const history = useHistory()
  const [signupType, setSignupType] = useState()
  const [data, setData] = useState({
    sponsorOwnerName: '',
    sponsorErrorMsg: '',
  })
  const [currentAddStep, setCurrentAddStep] = useState('name_address') //name_address, contact_info, invite_owner

  const socialLoginHandler = (registerData) => {
    if (formValues?.sponsors_iteam_id?.length > 0)
      registerData.sponsors_iteam_id = formValues?.sponsors_iteam_id

    registerData.isPromotionSignup = props?.onPromotionScreen
    registerData.promotion_sponsor_user_id = localStorage.getItem('promotion_sponsor_user_id')
    registerData.user_type = signupUserType?.length <= 0 ? 'individual' : signupUserType
    setStatus('submitting')
    socialLoginAction(registerData, true)
  }

  const linkedInLoginHandler = (registerData) => {
    if (formValues?.sponsors_iteam_id?.length > 0)
      registerData.sponsors_iteam_id = formValues?.sponsors_iteam_id

    registerData.isPromotionSignup = props?.onPromotionScreen
    registerData.promotion_sponsor_user_id = localStorage.getItem('promotion_sponsor_user_id')
    registerData.user_type = signupUserType?.length <= 0 ? 'individual' : signupUserType
    registerData.login_type = 'linkedin'
    registerData.social_login_type = 'linkedin'
    setStatus('submitting')
    linkedInLoginAction(registerData, true)
  }

  useEffect(() => {
    localStorage.setItem('signupUserType', signupUserType)
    props.userExistsPromptAction(null)
    return () => {
      hideConfirmSocialSignupModal()
      hideSocialUserExistsModal()
      props.authResponseAction(null)
      props.userExistsPromptAction(null)
    }
  }, [])

  useEffect(() => {
    setData((st) => ({
      ...st,
      sponsorOwnerName: '',
      sponsorErrorMsg: '',
    }))
    formValues?.sponsors_iteam_id?.length > 6 && validateSponsorid(formValues?.sponsors_iteam_id)
  }, [formValues?.sponsors_iteam_id])

  useEffect(() => {
    if (!!props?.user?.authresponse?.data?.data?.social_id) {
      setStatus('idle')
      showConfirmSocialSignupModal()
    }
  }, [props?.user?.authresponse?.data?.data?.social_id])

  useEffect(() => {
    if (!!props.errormsg?.length && !props?.errormsg?.includes('You are not registered yet')) {
      setStatus('idle')
      toaster(
        'error',
        'There was a problem creating your account. Sorry for the inconvenience, Please contact iTRUSTit customer service.',
      )
      props.errorMsgAction(null)
    }
  }, [props?.errormsg])

  useEffect(() => {
    if (!!props?.user?.authresponse?.data?.social_id) {
      setStatus('idle')
      showConfirmSocialSignupModal()
    }
  }, [props?.user?.authresponse?.data?.social_id])

  useEffect(() => {
    //
    const { user } = props
    if (addType !== 'customer' && !!user?.userdetail?.id) {
      resetAuthDataAction()
      setUser(user.userdetail)
      setToken(user.token)
      setOtpToken(user.otp_token)
      let profile_history = JSON.parse(window.localStorage.getItem('profile_history'))
      let hist = {}

      hist = {
        id: user.userdetail.id,
        profile_img: user.userdetail.profile_picture_medium
          ? user.userdetail.profile_picture_medium
          : DEFAULT_PROFILE_PICTURE,
        email: user.userdetail.email,
        type: user.userdetail.login_type,
        user_type: user.userdetail.user_type,
      }
      if (user.userdetail.user_type === 'individual') hist.name = user.userdetail.fullname
      else hist.name = user.userdetail.business_organization_name

      if (profile_history == null) {
        window.localStorage.setItem('profile_history', JSON.stringify([hist]))
      } else {
        let userFind = profile_history.filter((profile) => {
          return profile.id === user.userdetail.id
        })
        if (userFind.length === 0) {
          profile_history.unshift(hist)
          window.localStorage.setItem('profile_history', JSON.stringify(profile_history))
        } else {
          const profileIndex = profile_history.findIndex(
            (profile) => profile.id === user.userdetail.id,
          )
          profile_history[profileIndex] = hist
          profile_history.push(...profile_history.splice(0, profileIndex))

          window.localStorage.setItem('profile_history', JSON.stringify(profile_history))
        }
      }

      history.push({
        pathname: route.PROFILE_ROUTE,
        //pathname: route.HOME_ROUTE,
      })
      setStatus('idle')
    }
  }, [props?.user?.userdetail?.id])

  useEffect(() => {
    if (props?.signupmsg?.length && props?.slug) {
      removeInviteMatrixId()
      history.push({
        pathname: process.env.PUBLIC_URL + '/register-success/' + props?.slug,
        state: { user: { email: props?.user?.email, userId: props?.user?.userId }, isClaim },
      })
      props.signupStore({ message: [], slug: [] })
      props?.reset?.()
      setStatus('idle')
    } else if (props?.signupmsg?.length) {
      toaster('error', props?.signupmsg)
    }
  }, [props?.signupmsg])

  useEffect(() => {
    if (props?.user?.socialLoginResponse?.user?.id) {
      setStatus('idle')
      showSocialUserExistsModal()
    }
  }, [props?.user?.socialLoginResponse?.user?.id])

  const validateSponsorid = async (value) => {
    if (value?.length > 6) {
      try {
        let sponsorIdFound = false
        const { data } = await checkSponsorId({ sponser_id: value })
        if (data) {
          if (data.is_sponser_id_found === '1') {
            sponsorIdFound = true
            setData((st) => ({
              ...st,
              sponsorOwnerName:
                data?.user?.user_type === 'individual'
                  ? data?.user?.fullname
                  : !data?.user?.business_organization_name
                  ? data?.user?.fullname
                  : data?.user?.business_organization_name,
              sponsorErrorMsg: '',
            }))
          }
        }
        if (!sponsorIdFound) {
          removeInviteMatrixId()
          setData((st) => ({
            ...st,
            sponsorOwnerName: '',
            sponsorErrorMsg: 'Invalid Sponsor ID',
          }))
        }
      } catch (error) {}
    } else {
      removeInviteMatrixId()
      setData((st) => ({
        ...st,
        sponsorOwnerName: '',
        sponsorErrorMsg: !!value ? 'Invalid Sponsor ID' : '',
      }))
    }
  }

  const handleSubmit = (formData) => {
    const signupData = formData
    if (data?.sponsorErrorMsg?.length <= 0) {
      signupData.sponsors_iteam_id = formValues.sponsors_iteam_id ?? getInviteMatrixId()
      setInviteMatrixId(signupData.sponsors_iteam_id)
    }
    signupData.isPromotionSignup = props?.onPromotionScreen
    signupData.promotion_sponsor_user_id = localStorage.getItem('promotion_sponsor_user_id')
    signupData.user_type = signupUserType?.length <= 0 ? 'individual' : signupUserType
    if (Object.keys(props.social_login_form)?.length > 0) {
      signupData.login_type = props.social_login_form.login_type
      signupData.social_login_type = props.social_login_form.login_type
      signupData.social_id = props.social_login_form.social_id
    }
    setStatus('submitting')
    localStorage.removeItem('bus_org_name_from_presearch')
    props.signupAction({ ...signupData, isClaim, isAdd, addType, idToClaim })
    hideConfirmSocialSignupModal()
  }

  const handleSignupTypeChange = () => {
    formValues?.sponsors_iteam_id?.length > 0 && setInviteMatrixId(formValues?.sponsors_iteam_id)
    setSignupType('email')
  }

  const content = (
    <>
      <Helmet>
        <title>
          {isClaim
            ? signupType === 'business'
              ? CLAIM_BUSINESS_TITLE
              : CLAIM_ORGANIZATION_TITLE
            : isAdd
            ? signupType === 'business'
              ? ADD_BUSINESS_TITLE
              : ADD_ORGANIZATION_TITLE
            : signupType === 'business'
            ? SIGNUP_BUSINESS_TITLE
            : signupType === 'organization'
            ? SIGNUP_ORGANIZATION_TITLE
            : SIGNUP_TITLE}
        </title>
      </Helmet>
      <ConfirmSocialSignupModal
        status={status}
        data={props?.user?.authresponse?.data?.data ?? props.user?.authresponse?.data}
        handleSubmit={handleSubmit}
        isAdd={isAdd}
        isClaim={isClaim}
        busOrgName={busOrgName} //from Claim
      />
      <SocialUserExistsModal user={props?.user?.socialLoginResponse?.user} />
      <ResendVerificationEmailModal />
      <LoadingOverlay show={status === 'submitting'} />
      <div className='container'>
        <div className='row'>
          <div className='loginblk signupblk'>
            <div className='panel panel-default'>
              <div className='panel-heading'>
                <h3
                  className={`panel-title ${isAdd ? ' mb-1' : ''} ${
                    props?.onPromotionScreen ? 'on-promotion-screen' : ''
                  }`}
                >
                  {isAdd && currentAddStep === 'invite_owner' ? (
                    'Invite the owner/representative to claim this ' + signupUserType
                  ) : (
                    <>
                      {isClaim ? 'Claim Your ' : isAdd ? 'Add ' : ''}
                      {signupUserType === 'organization'
                        ? 'Organization'
                        : signupUserType === 'business'
                        ? 'Business'
                        : 'Personal'}{' '}
                      {isClaim
                        ? ''
                        : isAdd
                        ? `as ${addType === 'customer' ? 'Customer' : 'Owner'}`
                        : 'Sign Up'}
                    </>
                  )}
                </h3>
                {isAdd && currentAddStep !== 'invite_owner' ? (
                  <h6 className='mb-4'>
                    {currentAddStep === 'name_address'
                      ? 'Business name & address'
                      : currentAddStep === 'contact_info'
                      ? 'Please add more information on this ' + signupUserType
                      : ''}
                  </h6>
                ) : null}
              </div>
              <div className='panel-body'>
                {(isClaim && userToken()) || addType === 'customer' ? (
                  <AddBusOrgAsCustomer
                    signupType={signupUserType}
                    isClaim={isClaim}
                    busOrgName={busOrgName}
                    currentStep={currentAddStep}
                    setCurrentStep={setCurrentAddStep}
                    loginUserData={loginUserData}
                    onPromotionScreen={props?.onPromotionScreen}
                  />
                ) : signupType === 'email' ? (
                  <SignupWithEmail
                    status={status}
                    signupType={signupUserType}
                    handleSubmit={handleSubmit}
                    initialValues={{ sponsors_iteam_id: formValues.sponsors_iteam_id }}
                    isAdd={isAdd}
                    isClaim={isClaim}
                    loginUserData={loginUserData}
                    busOrgName={busOrgName}
                    onPromotionScreen={props?.onPromotionScreen}
                  />
                ) : (
                  <>
                    <div className='row fieldflex justify-content-center form-group'>
                      <div className='col-md-8 password_criteria' style={{ minHeight: 83 }}>
                        <Field
                          type='text'
                          name='sponsors_iteam_id'
                          className='form-control'
                          component={renderInputField}
                          validate={[validation.noSpace, validation.minValue6]}
                          label='Sponsor iTeam ID'
                          placeholder="Sponsor's iTeam ID#"
                          autoComplete='off'
                        />
                        {data.sponsorOwnerName?.length > 0 && (
                          <span
                            className='text-success'
                            style={{ margin: '7px 0', display: 'block' }}
                          >
                            Sponsor: {data.sponsorOwnerName}
                          </span>
                        )}
                        {formValues.sponsors_iteam_id?.length >= 6 &&
                          data.sponsorErrorMsg?.length > 0 && (
                            <span className='text-danger'>{data.sponsorErrorMsg}</span>
                          )}
                      </div>
                    </div>
                    <div className='row socialmedia'>
                      <SocialLogin
                        socialLogin={socialLoginHandler}
                        linkedInLogin={linkedInLoginHandler}
                        excludeApple={false}
                        btnPreText={isClaim ? 'Claim' : 'Sign Up'}
                      />
                    </div>
                    <div className='row socialmedia'>
                      <div className='col-12'>
                        <div className='btnss'>
                          <button
                            onClick={handleSignupTypeChange}
                            style={{
                              backgroundColor: 'rgb(255, 255, 255)',
                              display: 'inline-flex',
                              alignItems: 'center',
                              color: 'rgba(0, 0, 0, 0.54)',
                              boxShadow:
                                'rgb(0 0 0 / 24%) 0px 2px 2px 0px, rgb(0 0 0 / 24%) 0px 0px 1px 0px',
                              padding: 0,
                              border: '1px solid #cbd4db',
                              fontSize: 14,
                              fontWeight: 500,
                              fontFamily: "'Roboto', 'sans-serif'",
                              width: '100%',
                              margin: '0 0 12px',
                              borderRadius: 30,
                            }}
                            className='apple-auth-btn'
                          >
                            <img
                              width={24}
                              src={process.env.PUBLIC_URL + '/img/email_invite.svg'}
                              alt={`${isClaim ? 'Claim' : 'Sign Up'} with Email`}
                              style={{
                                marginRight: 10,
                                background: 'rgb(255, 255, 255)',
                                marginLeft: 16,
                              }}
                            />
                            <span style={{ padding: '10px 10px 10px 0px', fontWeight: 500 }}>
                              {isClaim ? 'Claim' : 'Sign Up'} with Email
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {!isAdd ? (
                  <small className='text-center mt-4 d-block'>
                    Already part of the iTRUSTit Community?
                    <Link className='purple' to={route.LOGIN_ROUTE}>
                      Log In
                    </Link>
                  </small>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )

  return !props?.hideAppLayout ? (
    <AppLayout isDisplayFooter={false}>{content}</AppLayout>
  ) : (
    <>{content}</>
  )
}

Signup = reduxForm({
  form: 'signupFormStart',
  enableReinitialize: true,
})(Signup)

const selector = formValueSelector('signupFormStart')
Signup = connect((state) => {
  const sponsors_iteam_id = selector(state, 'sponsors_iteam_id')
  const signup_type = selector(state, 'signup_type')

  return {
    formValues: { sponsors_iteam_id },
    signup_type,
    initialValues: {
      sponsors_iteam_id: sponsors_iteam_id ?? localStorage.getItem('invite_profile_imatrix_id'),
    },
  }
})(Signup)

export default Signup
