import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { hideUserHeader } from '../../../actions/profile'

const CategoriesList = ({
  categoryData,
  goBack = () => {},
  categoryLabel = 'More Categories',
  viewingCategory = 0,
  showCategoriesList,
  searchFunc = () => {},
  hideUserHeader,
}) => {
  useEffect(() => {
    hideUserHeader(true)
    return () => hideUserHeader(false)
  }, [hideUserHeader])

  // console.log(categoryData);
  return (
    <>
      <div className='my-3' style={{ backgroundColor: '#FFF', boxShadow: '0 3px 6px -6px black' }}>
        <div className='d-flex position-relative pb-2 align-items-center'>
          <div
            className='d-flex align-items-center justify-content-between position-absolute ms-2'
            onClick={goBack}
            style={{ cursor: 'pointer' }}
          >
            <i
              className='fa fa-chevron-left'
              aria-hidden='true'
              style={{
                fontSize: 24,
                cursor: 'pointer',
                fontWeight: 500,
              }}
            />
            <span className='ms-1'>Back</span>
          </div>
          <span
            className='mx-auto d-flex align-items-center'
            style={{
              maxWidth: '65%',
            }}
            onClick={() =>
              categoryLabel.includes(' >>')
                ? searchFunc(categoryLabel.replace(' >>', ''), 'categoryList')
                : null
            }
          >
            <span className='w-100'>
              <strong
                className='d-block'
                style={{
                  color: viewingCategory === 0 ? 'black' : '#4741d4',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                }}
              >
                {categoryLabel.replace(' >>', '')}
              </strong>
            </span>

            {categoryLabel.includes(' >>') ? (
              <i
                className='fa fa-search ms-2'
                aria-hidden='true'
                style={{
                  color: '#4741d4',
                  fontSize: 16,
                  cursor: 'pointer',
                }}
              ></i>
            ) : null}
          </span>
        </div>
      </div>
      <div className='px-2'>
        <small className='text-muted ms-3'>
          {viewingCategory === 0 ? 'All Categories' : 'Sub Categories'}
        </small>
        <ul className='list-group'>
          {categoryData?.categoryList?.map(({ value, label }) => (
            <li
              className='list-group-item d-flex justify-content-between align-items-center'
              key={value}
              onClick={() =>
                label.includes(' >>')
                  ? showCategoriesList({
                      id: value,
                      label,
                      previousCategories: categoryData.previousCategories,
                    })
                  : searchFunc(label.replace(' >>', ''), 'categoryList')
              }
            >
              <div className='me-auto'>
                <div className='fw-bold' style={{ fontSize: 12 }}>
                  {label.replace(' >>', '')}
                </div>
              </div>
              {label.includes(' >>') ? (
                <i
                  className='fa fa-chevron-right'
                  aria-hidden='true'
                  style={{
                    color: '#d3d3d3',
                    fontSize: 16,
                    cursor: 'pointer',
                  }}
                ></i>
              ) : (
                <i
                  className='fa fa-search'
                  aria-hidden='true'
                  style={{
                    color: '#4741d4',
                    fontSize: 16,
                    cursor: 'pointer',
                  }}
                ></i>
              )}
            </li>
          ))}
        </ul>
      </div>
    </>
  )
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      hideUserHeader,
    },
    dispatch,
  )
}
export default connect(null, mapDispatchToProps)(CategoriesList)
