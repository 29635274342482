import React, { Component } from 'react'

import { toaster } from '../../services/customFunction'
import { CLAIM_ACTIVATION_STATUS } from '../../services/routeConst'
import { verifyEmailConfirmationTokenCall } from '../../services/authService'

class EmailConfirmation extends Component {
  constructor(props) {
    super(props)

    this.state = {
      token: props.match.params.token,
      isRedirect: false,
    }
  }

  componentDidMount() {
    this.verifyEmailConfirmationToken()
  }

  verifyEmailConfirmationToken() {
    let self = this

    let request = {
      token: this.state.token,
    }
    verifyEmailConfirmationTokenCall(request, true).then(function (response) {
      if (response?.status === 200) {
        let apiResponse = response.data
        toaster('success', apiResponse.message)
      }

      self.props.history.push({
        //pathname: LOGIN_ROUTE,
        pathname: CLAIM_ACTIVATION_STATUS,
        state: { emailConfirmError: response?.response?.data },
      })
    })
  }

  render() {
    return <div />
  }
}

export default EmailConfirmation
