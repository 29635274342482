import React from 'react'
import ConfirmUnfriendPopupModal from '../ProfileOtherUser/ConfirmUnfriendModal/ConfirmUnfriendPopupModal'
import ConfirmUntrustPopupModal from '../ProfileOtherUser/ConfirmUntrustModal/ConfirmUntrustPopupModal'
import NoSearchResultsSuggestion from './NoSearchResultsSuggestion'
import SearchPageLoadingIcon from './SearchPageLoadingIcon'
import SearchResults from './SearchResults'
import SearchResultsHeader from './SearchResultsHeader'
import SearchResultsMap from './SearchResultsMap'
import SearchResultsSortOptions from './SortOptions/SearchResultsSortOptions'

const SearchResultsSection = ({
  isMobile,
  totalRecords,
  totalFilteredRecords,
  isSearching,
  showingFrom,
  showingTo,
  businessOrganizationNameSearch,
  citySearch,
  searchedAddress,
  userIDs,
  useGlobalSearch,
  cityOverride,
  showByOptions,
  sortByOptions,
  showBySelectedOption,
  sortBySelectedOption,
  sortOrder,
  showByChangeHandler,
  sortOrderChangeHandler,
  sortByChangeHandler,
  totalUsers,
  loadingData,
  isPageLoading,
  usersList,
  userIDsOffset,
  login_user_id,
  isTrustedUserModalShow,
  trusteUserData,
  updateFunc,
  loadingFunc,
  refresh,
  confirmitrustitAddRemove,
  confirmFriendAddRemove,
  loadingFriendStatusChange,
  loadingitrustitStatusChange,
  onHoverTrustBy,
  trustedUserListBlockShow,
  markers,
  itrustitRemoveData,
  friendRemoveData,
  friendAddRemoveHandle,
  itrustitHandle,
  setModalProps,
  setStatusOfMemberUserYour,
}) => {
  return (
    <>
      <section className='listing_block search_blk'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-8'>
              <i className='' />
              <SearchResultsHeader
                isMobile={isMobile}
                totalRecords={totalRecords}
                totalFilteredRecords={totalFilteredRecords}
                isSearching={isSearching}
                showingFrom={showingFrom}
                showingTo={showingTo}
                businessOrganizationNameSearch={businessOrganizationNameSearch}
                citySearch={citySearch}
                searchedAddress={searchedAddress}
                userIDs={userIDs}
                useGlobalSearch={useGlobalSearch}
                cityOverride={cityOverride}
              />
              <SearchResultsSortOptions
                isMobile={isMobile}
                showByOptions={showByOptions}
                sortByOptions={sortByOptions}
                showBySelectedOption={showBySelectedOption}
                sortBySelectedOption={sortBySelectedOption}
                sortOrder={sortOrder}
                showByChangeHandler={showByChangeHandler}
                sortOrderChangeHandler={sortOrderChangeHandler}
                sortByChangeHandler={sortByChangeHandler}
              />

              {totalRecords > 0 && totalFilteredRecords > 0 && isSearching === false ? (
                <SearchResults
                  totalUsers={totalUsers}
                  loadingData={loadingData}
                  isPageLoading={isPageLoading}
                  usersList={usersList}
                  userIDsOffset={userIDsOffset}
                  login_user_id={login_user_id}
                  isTrustedUserModalShow={isTrustedUserModalShow}
                  trusteUserData={trusteUserData}
                  updateFunc={updateFunc}
                  loadingFunc={loadingFunc}
                  refresh={refresh}
                  confirmitrustitAddRemove={confirmitrustitAddRemove}
                  confirmFriendAddRemove={confirmFriendAddRemove}
                  loadingFriendStatusChange={loadingFriendStatusChange}
                  loadingitrustitStatusChange={loadingitrustitStatusChange}
                  onHoverTrustBy={onHoverTrustBy}
                  trustedUserListBlockShow={trustedUserListBlockShow}
                  friendAddRemoveHandle={friendAddRemoveHandle}
                  itrustitHandle={itrustitHandle}
                  setModalProps={setModalProps}
                  setStatusOfMemberUserYour={setStatusOfMemberUserYour}
                />
              ) : isSearching === false ? (
                <NoSearchResultsSuggestion />
              ) : (
                <SearchPageLoadingIcon />
              )}
            </div>
            <SearchResultsMap markers={markers} />
          </div>
        </div>
      </section>
      <ConfirmUntrustPopupModal
        username={itrustitRemoveData?.username}
        data={itrustitRemoveData}
        handler={() =>
          itrustitHandle({
            id: itrustitRemoveData?.id,
            is_trusted_user: 0,
          })
        }
      />
      <ConfirmUnfriendPopupModal
        username={friendRemoveData?.username}
        data={friendRemoveData}
        handler={() =>
          friendAddRemoveHandle({
            user_id: friendRemoveData?.user_id,
            is_friend_user: 0,
            friendship_id: null,
          })
        }
      />
    </>
  )
}

export default SearchResultsSection
