import React, { Component } from 'react'
import { Redirect, Link } from 'react-router-dom'
import * as route from '../../services/routeConst'
import AppLayout from '../AppLayout'
// import "./temp.css";
import { HOME_TITLE } from '../../services/titleConst'
import GoogleAutocomplete from 'react-google-autocomplete'

import {
  DEFAULT_PROFILE_PICTURE,
  REQUIRED_MESSAGE_FOR_SEARCH,
  REQUIRED_MESSAGE_FOR_SEARCH_NO_ORG,
} from '../../services/const'
import {
  PROFILE_PICTURE_SMALL_URL_PATH,
  GOOGLE_GEOCODING_API_KEY,
} from '../../services/environment'
import { toaster } from '../../services/customFunction'
import * as userApiService from '../../services/userApiService'

import axios from 'axios'
import { customLogger } from '../../utils/CustomLogger'
import { runElasticSearch, runElasticSearchCategories } from '../../utils/elasticSearch'
import { Helmet } from 'react-helmet'

var cityObj = []

class Home extends Component {
  constructor(props) {
    super(props)
    props.isLogin(true)
    this.state = {
      routeToRedirect: '',
      searchAddress: '',
      individualBusinessOrganization: '',
      isIndividualBusinessOrganizationDisplay: false,
      individualBusinessOrganizationData: [],
    }
  }

  componentDidMount() {
    let businessName = localStorage.getItem('businessOrganizationNameSearch')
    let citySearch = localStorage.getItem('citySearch')
    // console.log({ citySearch, businessName });
    citySearch = citySearch == null ? '' : citySearch
    businessName = businessName == null ? '' : businessName

    this.setState({
      individualBusinessOrganization: businessName,
      searchAddress: citySearch,
    })
    !citySearch && this.getCurrentLocationString()
    // this.setState({ searchAddress });
    window.scrollTo(0, 0)
  }

  setRedirect = (routeToRedirect) => (e) => {
    if (this.state.individualBusinessOrganization !== '') {
      setTimeout(() => {
        localStorage.setItem(
          'businessOrganizationNameSearch',
          this.state.individualBusinessOrganization,
        )
        localStorage.setItem('citySearch', this.state.searchAddress)

        this.setState({
          routeToRedirect: routeToRedirect,
        })
      }, 1000)
    } else {
      if (this.state.searchAddress === '') {
        toaster('error', REQUIRED_MESSAGE_FOR_SEARCH)
      } else if (this.state.individualBusinessOrganization === '') {
        toaster('error', REQUIRED_MESSAGE_FOR_SEARCH_NO_ORG)
      }
    }
  }

  renderRedirect = () => {
    if (this.state.routeToRedirect) {
      return <Redirect to={this.state.routeToRedirect} />
    }
  }

  changeHandler = (event) => {
    let inputName = event.target.name
    let inputValue = event.target.value
    this.setState({ [inputName]: inputValue })

    //console.log("event.target.value : ", event.target.value);
  }

  handleChangeGoogle = (event) => {
    if (event.address_components) {
      let address_component = event.address_components

      let zipcode = address_component.filter((address) => {
        return address.types[0] === 'postal_code'
      })

      let cityName = address_component.filter((address) => {
        return address.types[0] === 'locality'
      })

      let stateName = address_component.filter((address) => {
        return address.types[0] === 'administrative_area_level_1'
      })

      cityObj = []
      if (zipcode.length > 0) {
        cityObj.push({ match: { zipcode: zipcode[0].long_name } })
      }
      if (cityName.length > 0) {
        cityObj.push({ match: { city: cityName[0].long_name } })
      }
      if (stateName.length > 0) {
        cityObj.push({ match: { state: stateName[0].long_name } })
      }
    }

    this.setState({
      searchAddress: event.formatted_address ? event.formatted_address : event.name,
      // individualBusinessOrganization: "",
      isIndividualBusinessOrganizationDisplay: false,
      individualBusinessOrganizationData: [],
    })
  }

  individualBusinessOrganizationChangeHandler = (event) => {
    let searchStr = event.target.value
    if (searchStr.length >= 2) {
      searchStr = searchStr.replace(/[\u2018\u2019]/g, "'").replace(/[\u201C\u201D]/g, '"')
      searchStr = searchStr.replace(/(')\1{1,}/, "'")
      searchStr = searchStr.replace(/(")\1{1,}/, `"`)
      //   searchStr = searchStr.trim();
    }
    this.setState({ individualBusinessOrganization: searchStr }, () => {
      //this.getSearchResult();
      this.getSearchResultForCategory()
    })
  }

  getSearchResultForCategory = async () => {
    runElasticSearchCategories(this.state.individualBusinessOrganization, (results) => {
      this.setState({ individualBusinessOrganizationData: results }, () => this.getSearchResult())
    })
  }

  getSearchResult = async () => {
    var searchStr = this.state.individualBusinessOrganization
    console.log({ searchStr })
    if (this.state.searchAddress.length === 0) cityObj = []

    if (searchStr.length >= 2) {
      const response = await runElasticSearch(
        undefined,
        { search_string: searchStr, search_city_string: this.state.searchAddress, maxResults: 25 },
        false,
        this.state.individualBusinessOrganizationData,
      )
      response?.error && toaster('error', response?.error)
      this.setState({
        individualBusinessOrganizationData:
          response?.status === 'failed' && !response?.error ? [] : response ?? [],
      })
    } else {
      this.setState({ individualBusinessOrganizationData: [] })
    }
  }

  onIndividualBusinessOrganizationFocus = (event) => {
    //console.log('in onIndividualBusinessOrganizationFocus');
    this.setState({
      isIndividualBusinessOrganizationDisplay: true,
    })
  }

  onIndividualBusinessOrganizationBlur = (event) => {
    //console.log('in onIndividualBusinessOrganizationBlur');
    var self = this
    setTimeout(function () {
      self.setState({
        isIndividualBusinessOrganizationDisplay: false,
      })
    }, 150)
  }

  individualBusinessOrganizationClicked = (data) => (event) => {
    //console.log('in individualBusinessOrganizationClicked: ', data);
    //console.log("data.name", data.name);
    this.setState({ individualBusinessOrganization: data.name }, () => {
      this.getSearchResult()
    })
  }

  changeEnterHandler = (event) => {
    // if (event.keyCode == 13) {
    //     console.log("enter");
    //     this.setRedirect(route.SEARCH_ROUTE);
    // }
  }

  handleClickOnCategoryList = (data) => (event) => {
    let self = this
    this.setState({ businessOrganizationNameSearch: data.name })
    localStorage.setItem('businessOrganizationNameSearch', data.name)
    localStorage.setItem('citySearch', this.state.searchAddress)
  }

  getCurrentLocationString = async () => {
    this.setState({
      searchAddress: '',
    })
    if (navigator.geolocation) {
      return navigator.geolocation.getCurrentPosition(
        (position) => {
          const pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          }
          let REVERSE_GEOCODING_API_URL = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${pos.lat},${pos.lng}&result_type=political&key=${GOOGLE_GEOCODING_API_KEY}`
          axios
            .get(REVERSE_GEOCODING_API_URL)
            .then((res) => {
              if (
                res.data.results &&
                res.data.results[0] &&
                res.data.results[0].formatted_address
              ) {
                this.setState({
                  searchAddress: res.data.results[0].formatted_address,
                })
              }
            })
            .catch((error) => {
              customLogger(error)
            })
        },
        () => {
          //Geolocator service failed
          this.setState({ searchAddress: '' })
        },
      )
    } else {
      // Browser doesn't support Geolocation
      this.setState({ searchAddress: '' })
    }
  }

  getSetting = (id) => {
    const self = this
    userApiService.getProfileSetting({ id }).then(function (response) {
      //console.log(response);
      if (
        response &&
        typeof response !== 'undefined' &&
        typeof response.status !== 'undefined' &&
        response.status === 200
      ) {
        if (response.data.data.user_settings) {
          // delete response.data.data.user_settings.id;
          // delete response.data.data.user_settings.user_id;
          delete response.data.data.user_settings.updated_at
          return response.data.data.user_settings
        }
      }
    })
  }

  render() {
    const {
      isIndividualBusinessOrganizationDisplay,
      individualBusinessOrganizationData,
      individualBusinessOrganization,
    } = this.state
    // console.log({ individualBusinessOrganization, searchAddress: this.state.searchAddress });

    const searchForm = (
      <form name='searchFormHome' className='bannerform it-header-search-box' action='javascript:;'>
        <div className='bannerinner'>
          {/* <div className="form-group">
                                    <input
                                        type="text"
                                        className="form-control zip1"
                                        placeholder="Search for person, business, or organization..."
                                    />
                                </div> */}
          <div className='form-group in_dropdown'>
            <input
              type='text'
              name='individualBusinessOrganization'
              className='form-control zip2'
              onChange={this.individualBusinessOrganizationChangeHandler}
              onFocus={this.onIndividualBusinessOrganizationFocus}
              onBlur={this.onIndividualBusinessOrganizationBlur}
              value={individualBusinessOrganization}
              label='Firstname'
              placeholder='Search iTRUSTit…'
              autoComplete='off'
            />
            {isIndividualBusinessOrganizationDisplay &&
              individualBusinessOrganizationData.length > 0 && (
                <div className='dropdown i_drop_suggetion'>
                  <div className='dropdown-content'>
                    {individualBusinessOrganizationData.map((user, index) => {
                      let categoryORUsernameDisplay = null

                      // let businessOrOrganization = null;
                      let fullname = null
                      if (user['_source'].user_type === 'business') {
                        // businessOrOrganization = (
                        //     <div className="i_business">
                        //         <strong>Business Name:</strong>{" "}
                        //         {user["_source"].business_organization_name}
                        //     </div>
                        // );
                        fullname = user['_source'].business_organization_name
                      } else if (user['_source'].user_type === 'organization') {
                        // businessOrOrganization = (
                        //     <div className="i_business">
                        //         <strong>Organization Name:</strong>{" "}
                        //         {user["_source"].business_organization_name}
                        //     </div>
                        // );
                        fullname = user['_source'].business_organization_name
                      } else {
                        // fullname = <div className="i_name varela purple">
                        //     <div
                        //         dangerouslySetInnerHTML={{
                        //             __html: user["_source"].name,
                        //         }}
                        //     />
                        // </div>
                        fullname = user['_source'].name
                      }

                      let addressToDisplay = ''
                      if (
                        typeof user['_source'].address_line_1 !== 'undefined' &&
                        user['_source'].address_line_1
                      ) {
                        addressToDisplay += '' + user['_source'].address_line_1
                      }
                      if (
                        typeof user['_source'].address_line_2 !== 'undefined' &&
                        user['_source'].address_line_2
                      ) {
                        addressToDisplay += ' ' + user['_source'].address_line_2
                      }
                      if (typeof user['_source'].city !== 'undefined' && user['_source'].city) {
                        addressToDisplay += ' ' + user['_source'].city
                      }
                      if (typeof user['_source'].state !== 'undefined' && user['_source'].state) {
                        addressToDisplay += ' ' + user['_source'].state
                      }
                      if (
                        typeof user['_source'].zipcode !== 'undefined' &&
                        user['_source'].zipcode
                      ) {
                        addressToDisplay += ' ' + user['_source'].zipcode
                      }

                      if (user['_index'] !== 'live-categories') {
                        let profileUrl = process.env.PUBLIC_URL + user['_source'].slug
                        let profile_picture = null
                        if (
                          typeof user['_source'].image !== 'indefined' &&
                          user['_source'].image !== '' &&
                          user['_source'].image
                        ) {
                          profile_picture = (
                            <img
                              className='imageTag'
                              alt=''
                              src={PROFILE_PICTURE_SMALL_URL_PATH + user['_source'].image}
                            />
                          )
                        } else {
                          profile_picture = (
                            <img className='imageTag' alt='' src={DEFAULT_PROFILE_PICTURE} />
                          )
                        }

                        categoryORUsernameDisplay = (
                          <Link
                            to={profileUrl}
                            className='i_suggetion_link'
                            onClick={this.individualBusinessOrganizationClicked(user['_source'])}
                          >
                            <div className='i_photu'>{profile_picture}</div>
                            <div className='i_info'>
                              <div className='i_name varela purple'>{fullname}</div>
                              <div className='i_address'>
                                {user['_source'].user_type !== 'individual'
                                  ? addressToDisplay
                                  : 'Click to view profile'}
                              </div>
                            </div>
                          </Link>
                        )
                      } else {
                        categoryORUsernameDisplay = (
                          <Link
                            className='i_suggetion_link_category'
                            to={{
                              pathname: route.SEARCH_ROUTE,
                              state: {
                                category_id: user['_source'].search_id,
                                category_name: fullname,
                              },
                            }}
                            onClick={this.handleClickOnCategoryList(user['_source'])}
                          >
                            <div className='i_info'>
                              <div className='i_name varela purple'>{fullname}</div>
                            </div>
                          </Link>
                        )
                      }

                      return (
                        <div key={user['_source'].id} className='i_suggestion_list'>
                          {categoryORUsernameDisplay}
                        </div>
                      )
                    })}
                  </div>
                </div>
              )}
          </div>
          <div className='form-group'>
            {/* <input
                                        type="text"
                                        className="form-control zip2"
                                        placeholder="City, State, or Zip Code..."
                                    /> */}
            <GoogleAutocomplete
              type='text'
              className='form-control zip1'
              name='searchAddress'
              onChange={this.changeHandler}
              value={this.state.searchAddress}
              placeholder={'City, State, or Zip Code...'}
              onPlaceSelected={(place) => {
                this.handleChangeGoogle(place)
              }}
              onFocus={(event) => {
                event.target.setAttribute('autocomplete', 'off')
              }}
              options={{ types: ['geocode'] }}

              //componentRestrictions={{ country: "in" }}
            />
          </div>
        </div>
        <div className='form-group submitblk it-header-search'>
          {/* <input
                                    type="submit"
                                    onClick={this.setRedirect(route.SEARCH_ROUTE)}
                                    className="form-control search"
                                    value="search"
                                /> */}
          <button
            type='submit'
            className='form-control search'
            onClick={this.setRedirect(route.SEARCH_ROUTE)}
          >
            <i className='fa fa-search' aria-hidden='true'></i>
          </button>
        </div>
      </form>
    )

    return (
      <AppLayout routeActive={this.props.location.pathname}>
        <Helmet>
          <title>{HOME_TITLE}</title>
        </Helmet>
        {this.renderRedirect()}
        <section className='banner text-center'>
          <div className='container'>
            <h1>Your Circle of Trust</h1>
            <p>iTRUSTit gives you all your friends recommendations in just one click!</p>

            {/* {searchForm} */}
          </div>
        </section>
        <section className='cat_list'>
          <div className='container'>
            <h2>Browse Businesses By Category</h2>
            <ul className='row cat_list_inner'>
              <li className='col-md-3'>
                <div className='cat_items'>
                  <Link
                    to={{
                      pathname: route.SEARCH_ROUTE,
                      state: {
                        category_id: '150000',
                        category_name: 'Restaurants',
                      },
                    }}
                    onClick={this.handleClickOnCategoryList({
                      id: '150000',
                      name: 'Restaurants',
                    })}
                  >
                    <span>
                      <img src={process.env.PUBLIC_URL + '/img/food.png'} alt='food' />
                    </span>
                    Restaurants
                  </Link>
                </div>
              </li>
              <li className='col-md-3'>
                <div className='cat_items'>
                  <Link
                    to={{
                      pathname: route.SEARCH_ROUTE,
                      state: {
                        category_id: '250600',
                        category_name: 'Contractors',
                      },
                    }}
                    onClick={this.handleClickOnCategoryList({
                      id: '250600',
                      name: 'Contractors',
                    })}
                  >
                    <span>
                      <img src={process.env.PUBLIC_URL + '/img/painter.png'} alt='Contractors' />
                    </span>
                    Contractors
                  </Link>
                </div>
              </li>
              <li className='col-md-3'>
                <div className='cat_items'>
                  <Link
                    to={{
                      pathname: route.SEARCH_ROUTE,
                      state: {
                        category_id: '140223',
                        category_name: 'Real Estate Agents',
                      },
                    }}
                    onClick={this.handleClickOnCategoryList({
                      id: '140223',
                      name: 'Real Estate Agents',
                    })}
                  >
                    <span>
                      <img
                        src={process.env.PUBLIC_URL + '/img/realtor.png'}
                        alt='Real Estate Agents'
                      />
                    </span>
                    Real Estate Agents
                  </Link>
                </div>
              </li>
              <li className='col-md-3'>
                <div className='cat_items'>
                  <Link
                    to={{
                      pathname: route.SEARCH_ROUTE,
                      state: { category_id: '180200', category_name: 'Hotels' },
                    }}
                    onClick={this.handleClickOnCategoryList({
                      id: '180200',
                      name: 'Hotels',
                    })}
                  >
                    <span>
                      <img src={process.env.PUBLIC_URL + '/img/hotel.png'} alt='Hotels' />
                    </span>
                    Hotels
                  </Link>
                </div>
              </li>
              <li className='col-md-3'>
                <div className='cat_items'>
                  <Link
                    to={{
                      pathname: route.SEARCH_ROUTE,
                      state: {
                        category_id: '251500',
                        category_name: 'Landscaper',
                      },
                    }}
                    onClick={this.handleClickOnCategoryList({
                      id: '251500',
                      name: 'Landscaper',
                    })}
                  >
                    <span>
                      <img src={process.env.PUBLIC_URL + '/img/landscaper.png'} alt='landscaper' />
                    </span>
                    Landscaper
                  </Link>
                </div>
              </li>
              <li className='col-md-3'>
                <div className='cat_items'>
                  <Link
                    to={{
                      pathname: route.SEARCH_ROUTE,
                      state: {
                        category_id: '250700',
                        category_name: 'Plumber',
                      },
                    }}
                    onClick={this.handleClickOnCategoryList({
                      id: '250700',
                      name: 'Plumber',
                    })}
                  >
                    <span>
                      <img src={process.env.PUBLIC_URL + '/img/plumber.png'} alt='Plumber' />
                    </span>
                    Plumber
                  </Link>
                </div>
              </li>
              <li className='col-md-3'>
                <div className='cat_items'>
                  <Link
                    to={{
                      pathname: route.SEARCH_ROUTE,
                      state: {
                        category_id: '110000',
                        category_name: 'Auto Repair',
                      },
                    }}
                    onClick={this.handleClickOnCategoryList({
                      id: '110000',
                      name: 'Auto Repair',
                    })}
                  >
                    <span>
                      <img src={process.env.PUBLIC_URL + '/img/automative.png'} alt='Auto Repair' />
                    </span>
                    Auto Repair
                  </Link>
                </div>
              </li>
              <li className='col-md-3'>
                <div className='cat_items'>
                  <Link
                    to={{
                      pathname: route.SEARCH_ROUTE,
                      state: { category_id: '152508', category_name: 'Bars' },
                    }}
                    onClick={this.handleClickOnCategoryList({
                      id: '152508',
                      name: 'Bars',
                    })}
                  >
                    <span>
                      <img src={process.env.PUBLIC_URL + '/img/nightlife.png'} alt='Bars' />
                    </span>
                    Bars
                  </Link>
                </div>
              </li>
              <li className='col-md-3'>
                <div className='cat_items'>
                  <Link
                    to={{
                      pathname: route.SEARCH_ROUTE,
                      state: { category_id: '152432', category_name: 'Coffee' },
                    }}
                    onClick={this.handleClickOnCategoryList({
                      id: '152432',
                      name: 'Coffee',
                    })}
                  >
                    <span>
                      <img src={process.env.PUBLIC_URL + '/img/Coffee.png'} alt='Coffee' />
                    </span>
                    Coffee
                  </Link>
                </div>
              </li>
              <li className='col-md-3'>
                <div className='cat_items'>
                  <Link
                    to={{
                      pathname: route.SEARCH_ROUTE,
                      state: {
                        category_id: '190000',
                        category_name: 'Nightlife',
                      },
                    }}
                    onClick={this.handleClickOnCategoryList({
                      id: '190000',
                      name: 'Nightlife',
                    })}
                  >
                    <span>
                      <img src={process.env.PUBLIC_URL + '/img/Nightlife_2.png'} alt='nightlife' />
                    </span>
                    Nightlife
                  </Link>
                </div>
              </li>
              <li className='col-md-3'>
                <div className='cat_items'>
                  <Link
                    to={{
                      pathname: route.SEARCH_ROUTE,
                      state: { category_id: '120600', category_name: 'Gyms' },
                    }}
                    onClick={this.handleClickOnCategoryList({
                      id: '120600',
                      name: 'Gyms',
                    })}
                  >
                    <span>
                      <img src={process.env.PUBLIC_URL + '/img/fitness.png'} alt='Gyms' />
                    </span>
                    Gyms
                  </Link>
                </div>
              </li>
              <li className='col-md-3'>
                <div className='cat_items'>
                  <Link
                    to={{
                      pathname: route.SEARCH_ROUTE,
                      state: {
                        category_id: '160000',
                        category_name: 'Shopping',
                      },
                    }}
                    onClick={this.handleClickOnCategoryList({
                      id: '160000',
                      name: 'Shopping',
                    })}
                  >
                    <span>
                      <img src={process.env.PUBLIC_URL + '/img/Shopping.png'} alt='Shopping' />
                    </span>
                    Shopping
                  </Link>
                </div>
              </li>
            </ul>
          </div>
        </section>
      </AppLayout>
    )
  }
}

export default Home
