import * as React from 'react'
import PropTypes from 'prop-types'
import { useAutocomplete } from '@mui/base/AutocompleteUnstyled'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import { styled } from '@mui/material/styles'
import { autocompleteClasses } from '@mui/material/Autocomplete'
import { PROFILE_PICTURE_SMALL_URL_PATH } from '../../services/environment'
import { DEFAULT_PROFILE_PICTURE } from '../../services/const'
import parse from 'html-react-parser'
import Skeleton from 'react-loading-skeleton'

const Root = styled('div')(
  ({ theme }) => `
  color: ${theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.65)' : 'rgba(0,0,0,.85)'};
  font-size: 14px;
  width: 100%;
`,
)

const Label = styled('label')`
  padding: 0 0 4px;
  line-height: 1.5;
  display: block;
`

const InputWrapper = styled('div')(
  ({ theme }) => `
  width: auto;
  border: 1px solid ${theme.palette.mode === 'dark' ? '#434343' : '#d9d9d9'};
  background-color: ${theme.palette.mode === 'dark' ? '#141414' : '#fff'};
  border-radius: 4px;
  padding: 1px;
  display: flex;
  flex-wrap: wrap;

  &:hover {
    border-color: ${theme.palette.mode === 'dark' ? '#177ddc' : '#40a9ff'};
  }

  &.focused {
    border-color: ${theme.palette.mode === 'dark' ? '#177ddc' : '#40a9ff'};
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }

  & input {
    background-color: ${theme.palette.mode === 'dark' ? '#141414' : '#fff'};
    color: ${theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.65)' : 'rgba(0,0,0,.85)'};
    height: 30px;
    box-sizing: border-box;
    padding: 4px 6px;
    width: 0;
    min-width: 30px;
    flex-grow: 1;
    border: 0;
    margin: 0;
    outline: 0;
  }
`,
)

function Tag(props) {
  const { label, onDelete, ...other } = props
  return (
    <div {...other}>
      <div className='avatar-base chat-tag-avatar'>
        <img
          className='imageTag'
          alt=''
          src={
            !!props?.avatar
              ? PROFILE_PICTURE_SMALL_URL_PATH + props.avatar
              : DEFAULT_PROFILE_PICTURE
          }
        />
      </div>
      <span>{label}</span>
      <CloseIcon onClick={onDelete} />
    </div>
  )
}

Tag.propTypes = {
  label: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
}

const StyledTag = styled(Tag)(
  ({ theme }) => `
  display: flex;
  align-items: center;
  height: 24px;
  margin: 2px;
  line-height: 22px;
  background-color: ${theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.08)' : '#fafafa'};
  border: 1px solid ${theme.palette.mode === 'dark' ? '#303030' : '#e8e8e8'};
  border-radius: 2px;
  box-sizing: content-box;
  padding: 0 4px 0 2px;
  outline: 0;
  overflow: hidden;
  gap: 4px;

  &:focus {
    border-color: ${theme.palette.mode === 'dark' ? '#177ddc' : '#40a9ff'};
    background-color: ${theme.palette.mode === 'dark' ? '#003b57' : '#e6f7ff'};
  }

  & span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  & svg {
    font-size: 12px;
    cursor: pointer;
    // padding: 4px;
  }
`,
)

const Listbox = styled('div')(
  ({ theme }) => `
  width: 100%;
  max-width: 100%;
  margin: 2px 0 0;
  padding: 0;
  position: absolute;
  list-style: none;
  background-color: ${theme.palette.mode === 'dark' ? '#141414' : '#fff'};
  overflow: auto;
  max-height: 300px;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 1;

  & .chat-suggestion-user {
    display: flex;
    align-items: center;

    & span {
      flex-grow: 1;
    }

    & svg {
      color: transparent;
    }
  }

  & li[aria-selected='true'] {
    background-color: ${theme.palette.mode === 'dark' ? '#2b2b2b' : '#fafafa'};
    font-weight: 600;

    & svg {
      color: #1890ff;
    }
  }

  & li.${autocompleteClasses.focused} {
    background-color: ${theme.palette.mode === 'dark' ? '#003b57' : '#e6f7ff'};
    cursor: pointer;

    & svg {
      color: currentColor;
    }
  }
`,
)

const NewMessageUserSearch = ({
  options,
  setOptions,
  searchData,
  handleUserSearchChange,
  isSearching,
  setChatUsers,
  multiple = true,
  placeholder = '',
  isISupportSearch,
}) => {
  const [open, setOpen] = React.useState(false)
  const loading = isSearching && open && options.length === 0
  const {
    getRootProps,
    getInputLabelProps,
    getInputProps,
    getTagProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    value,
    focused,
    setAnchorEl,
  } = useAutocomplete({
    id: 'chat-user-suggestions',
    // defaultValue: [options[1]],
    inputValue: searchData?.searchStr,
    isOptionEqualToValue: (option, value) => {
      return option['_id'] === value['_id']
    },
    onInputChange: (event, value, reason) => {
      !open && value?.length >= 2 && setOpen(true)
      handleUserSearchChange(event, value, reason)
    },
    filterOptions: (x) => x, //Used for iSupportSearch, allowing default 'add_org' option to show regardless of what input value is
    openOnFocus: true,
    multiple,
    options,
    open,
    onOpen: () => {
      setOpen(true)
    },
    onClose: () => {
      setOpen(false)
    },
    loading,

    getOptionLabel: (option) => option?.['_source']?.sortname ?? 'null',
  })

  // React.useEffect(() => {
  //   if (!open) {
  //     setOptions((st) => ({ searchStr: st.searchStr, results: [] }))
  //   }
  // }, [open])

  React.useEffect(() => {
    setChatUsers(value)
  }, [value])

  const inputProps = getInputProps()
  return (
    <Root>
      <div {...getRootProps()}>
        {multiple ? <Label {...getInputLabelProps()}>To:</Label> : null}
        <InputWrapper id='chat-search-input' ref={setAnchorEl} className={focused ? 'focused' : ''}>
          {value?.map?.((option, index) => (
            <StyledTag
              label={option['_source'].sortname}
              avatar={option['_source']?.image}
              {...getTagProps({ index })}
            />
          ))}

          <input
            {...inputProps}
            onFocus={(event) => {
              inputProps?.onFocus?.(event)
              if (searchData?.searchStr?.length < 2) {
                setOpen(false)
              }
            }}
            placeholder={placeholder}
          />
        </InputWrapper>
      </div>
      {(isISupportSearch && groupedOptions?.length > 1) ||
      (!isISupportSearch && groupedOptions.length !== 0) ? (
        <Listbox {...getListboxProps()}>
          {groupedOptions?.map?.((option, index) => {
            if (option?.isStatic) {
              return (
                <div
                  {...getOptionProps({ option, index })}
                  key={option['_id']}
                  className='chat-suggestion-user'
                >
                  <div className='chat-suggestion-user-info-wrapper w-100'>
                    <div className='d-flex flex-column chat-suggestion-user-info'>
                      <div className='chat-suggestion-name varela'>{option?.label}</div>
                      <div className='chat-suggestion-subtext varela'>{option?.subtext}</div>
                    </div>
                  </div>
                  {/* <CheckIcon fontSize='small' /> */}
                </div>
              )
            } else {
              const userData = option['_source']
              const fullname = ['business', 'organization'].includes(userData?.user_type)
                ? userData.business_organization_name
                : userData.name
              return (
                <div
                  {...getOptionProps({ option, index })}
                  key={option['_id']}
                  className='chat-suggestion-user'
                >
                  <div className='avatar-base'>
                    <img
                      className='imageTag'
                      alt=''
                      src={
                        !!userData?.image
                          ? PROFILE_PICTURE_SMALL_URL_PATH + userData.image
                          : DEFAULT_PROFILE_PICTURE
                      }
                    />
                  </div>
                  <div className='chat-suggestion-user-info-wrapper'>
                    <div className='d-flex flex-column chat-suggestion-user-info'>
                      <div className='chat-suggestion-name varela'>
                        {parse(option?.highlight?.name?.[0] ?? fullname ?? '')}
                      </div>
                      <div
                        className='chat-suggestion-subtext varela'
                        style={
                          !userData?.city && !userData?.zipcode
                            ? { opacity: 0, appearance: 'none' }
                            : {}
                        }
                      >
                        {userData?.city || userData?.zipcode
                          ? `${userData.city ?? userData.zipcode}${
                              userData?.country ? `, ${userData?.country}` : ''
                            }`
                          : 'NA'}
                      </div>
                    </div>
                  </div>
                  {/* <CheckIcon fontSize='small' /> */}
                </div>
              )
            }
          })}
        </Listbox>
      ) : open && isSearching && searchData?.searchStr?.length >= 2 ? (
        <Listbox {...getListboxProps()} style={{ width: 343 }}>
          {[...Array(5)].map((val, idx) => {
            return (
              <div key={'loading-option-' + idx} className='chat-suggestion-user'>
                <div className='avatar-base'>
                  <Skeleton
                    className='imageTag'
                    width={40}
                    height={40}
                    style={{ lineHeight: 'initial' }}
                  />
                </div>
                <div className='chat-suggestion-user-info-wrapper'>
                  <div className='d-flex flex-column chat-suggestion-user-info w-100'>
                    <Skeleton className='chat-suggestion-name varela' count={2} height={20} />
                  </div>
                </div>
              </div>
            )
          })}
        </Listbox>
      ) : null}
    </Root>
  )
}

export default NewMessageUserSearch
