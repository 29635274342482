import React from 'react'

export default function SortOptionsButtons({ isMobile, handleChange, sortOrder }) {
  const Button = (type) => (
    <button
      onClick={() => handleChange(type)}
      type='button'
      className={`btn btn-block ` + (sortOrder === type ? 'btn-primary' : '')}
      style={{ padding: '0.175rem 0.375rem' }}
    >
      <i className={type} />
    </button>
  )
  return (
    <>
      {Button('desc')}
      {Button('asc')}
    </>
  )
}
