import React, { Component } from 'react'
import AppLayout from '../AppLayout'
import '../Faq/Faq.scss'
import { FAQ_TITLE } from '../../services/titleConst'
import LoginModal from '../LoginModal/LoginModal'
import { isUserLogin, toaster, userToken } from '../../services/customFunction'
import $ from 'jquery'
import { submitQuestionEmail } from '../../services/userApiService'
import { Helmet } from 'react-helmet'

class faq extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isSubmitQuestionText: false,
      sharedFields: {},
      sharedErrors: {},
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    this.setState({ isSubmitQuestionText: false })
  }

  showSubmitQuestions = () => {
    this.setState({ isSubmitQuestionText: true })
  }

  hideSubmitQuestions = () => {
    this.setState({ isSubmitQuestionText: false })
  }

  shareHandleChange = (e) => {
    let sharedFields = this.state.sharedFields
    sharedFields[e.target.name] = e.target.value
    this.setState({
      sharedFields,
    })
  }

  questionSubmitHandler = (e) => {
    e.preventDefault()

    let params = this.state.sharedFields

    if (this.validateForm()) {
      submitQuestionEmail(params).then(function (response) {
        if (response.status === 200) {
          toaster('success', response.data.message)
          $('#submitQuestionForm').get(0).reset()
        } else {
          toaster('error', 'Something wrong! please try again later.')
        }
      })
    } else {
      toaster('error', 'Please fill your question')
    }
  }

  validateForm = () => {
    let fields = this.state.sharedFields
    let errors = {}
    let formIsValid = true

    if (!fields['name']) {
      formIsValid = false
      errors['name'] = 'Please enter your name.'
    }

    this.setState({
      sharedErrors: errors,
    })
    return formIsValid
  }

  loginContinueHandler = () => {}

  render() {
    $('#login_modal').on('hidden.bs.modal', function (e) {})

    return (
      <AppLayout routeActive={this.props.location.pathname}>
        <Helmet>
          <title>{FAQ_TITLE}</title>
        </Helmet>
        <section className='bg-grey'>
          <div className='container'>
            <h3 className='varela mb-3'>FAQ</h3>
            <div className='white-pane green_line faq'>
              {/* <h5 className="text-center">Coming Soon</h5> */}
              <h5 className='mb-2'>General Questions</h5>
              <div id='accordion' className='faq_accordian'>
                <div className='card'>
                  <div className='card-header'>
                    <a className='card-link' data-bs-toggle='collapse' href='#one'>
                      What is ITRUSTit?
                    </a>
                  </div>
                  <div id='one' className='collapse show' data-bs-parent='#accordion'>
                    <div className='card-body'>
                      <p>
                        <strong>For People</strong> iTRUSTit is for sharing businesses you trust
                        with your friends and getting mass referrals from your friends exactly when
                        you need them, no waiting.
                      </p>
                      <p>
                        <strong>For Businesses</strong>iTRUSTit is word of mouth marketing… online.{' '}
                      </p>
                      <p>
                        <strong>For Organizations</strong>iTRUSTit is your member business directory
                        and a simple and free way to build massive awareness for your organization.
                      </p>
                    </div>
                  </div>
                </div>
                <div className='card'>
                  <div className='card-header'>
                    <a className='collapsed card-link' data-bs-toggle='collapse' href='#two'>
                      Is ITRUSTit free?
                    </a>
                  </div>
                  <div id='two' className='collapse' data-bs-parent='#accordion'>
                    <div className='card-body'>
                      <p>
                        Yes, ITRUSTit is free to all users. People, businesses, and organizations.
                      </p>
                    </div>
                  </div>
                </div>
                <div className='card'>
                  <div className='card-header'>
                    <a className='collapsed card-link' data-bs-toggle='collapse' href='#three'>
                      How do I Sign Up?
                    </a>
                  </div>
                  <div id='three' className='collapse' data-bs-parent='#accordion'>
                    <div className='card-body'>
                      <p>
                        If you’ve received an invitation via email, follow the link and directions
                        listed. If you haven’t received an invitation from a friend, contact the
                        user who told you about iTRUSTit to get their unique invitation link or
                        iTeam ID sponsor #. Or you can go directly to ITRUSTit.com and register as a
                        "Person,", "Business," or "Organization.". If you want to register both
                        yourself and your business, sign up as a person first, and then invite your
                        business from your personal “Invite Center” page. You will need two separate
                        email addresses and user names for each account. Once you have completed the
                        registration form, you will automatically receive an Activation Email from
                        iTRUSTit. Click on the link provided to confirm your email address and
                        complete the signup process.
                      </p>
                    </div>
                  </div>
                </div>
                <div className='card'>
                  <div className='card-header'>
                    <a className='collapsed card-link' data-bs-toggle='collapse' href='#four'>
                      How do I invite friends and associates?
                    </a>
                  </div>
                  <div id='four' className='collapse' data-bs-parent='#accordion'>
                    <div className='card-body'>
                      <p>
                        You can easily invite friends and contacts to become part of your trusted
                        network. Go to your “Invite Center” page and be sure your unique invite code
                        is in your invitations.{' '}
                      </p>
                    </div>
                  </div>
                </div>
                <div className='card'>
                  <div className='card-header'>
                    <a className='collapsed card-link' data-bs-toggle='collapse' href='#five'>
                      Does each account need a unique email address?
                    </a>
                  </div>
                  <div id='five' className='collapse' data-bs-parent='#accordion'>
                    <div className='card-body'>
                      <p>
                        Yes, each iTRUSTit account has to have its own individual email address.
                      </p>
                    </div>
                  </div>
                </div>
                <div className='card'>
                  <div className='card-header'>
                    <a className='collapsed card-link' data-bs-toggle='collapse' href='#six'>
                      If I invite somebody to join iTRUSTit does that mean I have to be their friend
                      or trust them?
                    </a>
                  </div>
                  <div id='six' className='collapse' data-bs-parent='#accordion'>
                    <div className='card-body'>
                      <p>
                        No, the friend and Trust data is completely independent of the invitation
                        system.
                      </p>
                    </div>
                  </div>
                </div>
                <div className='card'>
                  <div className='card-header'>
                    <a className='collapsed card-link' data-bs-toggle='collapse' href='#seven'>
                      If I invite somebody to join iTRUSTit does that mean I have to trust them?
                    </a>
                  </div>
                  <div id='seven' className='collapse' data-bs-parent='#accordion'>
                    <div className='card-body'>
                      <p>
                        No, the trust, review and friend functions are completely independent from
                        the invitation system
                      </p>
                    </div>
                  </div>
                </div>
                <div className='card'>
                  <div className='card-header'>
                    <a className='collapsed card-link' data-bs-toggle='collapse' href='#eight'>
                      How do I find a friend, business, or organization on iTRUSTit?
                    </a>
                  </div>
                  <div id='eight' className='collapse' data-bs-parent='#accordion'>
                    <div className='card-body'>
                      <p>
                        Use the “Main Search” function at the very top of the site. Enter the
                        person, business, or organizations name or category and location.
                      </p>
                    </div>
                  </div>
                </div>
                <div className='card'>
                  <div className='card-header'>
                    <a className='collapsed card-link' data-bs-toggle='collapse' href='#nine'>
                      Can I invite businesses and organizations to join iTRUSTit?
                    </a>
                  </div>
                  <div id='nine' className='collapse' data-bs-parent='#accordion'>
                    <div className='card-body'>
                      <p>
                        Yes, iTRUSTit is better when you share businesses you trust and the
                        organizations you are a part of!
                      </p>
                    </div>
                  </div>
                </div>
                <div className='card'>
                  <div className='card-header'>
                    <a className='collapsed card-link' data-bs-toggle='collapse' href='#ten'>
                      How do I benefit from inviting people, businesses or organizations to join
                      iTRUSTit?
                    </a>
                  </div>
                  <div id='ten' className='collapse' data-bs-parent='#accordion'>
                    <div className='card-body'>
                      <ol type='1'>
                        <li>
                          <strong>- For People:</strong>
                          <ol type='a'>
                            <li>
                              <p>
                                The more friends you have on iTRUSTit the more relevant it is for
                                you.
                              </p>
                            </li>
                            <li>
                              <p> Promote businesses you Trust and share them with your friends</p>
                            </li>
                          </ol>
                        </li>
                        <li>
                          <strong>- For Businesses:</strong>
                          <ol type='a'>
                            <li>
                              <p>
                                The more friends, family, and clients ‘Trust’ your business, the
                                more your organic ranking increases on iTRUSTit and leverages the
                                power of word-of-mouth marketing as they promote your business to
                                all their friends for free.
                              </p>
                            </li>
                            <li>
                              <p>
                                {' '}
                                Organizations you are a part of on iTRUSTit promote your business to
                                other members.
                              </p>
                            </li>
                          </ol>
                        </li>
                        <li>
                          <strong>- For Realtors:</strong>
                          <ol type='a'>
                            <li>
                              <p>
                                Additionally, the more of your friends and realtor-friends who are
                                on iTRUSTit, the easier it is to find quality referrals for your
                                clients, friends and family.
                              </p>
                            </li>
                          </ol>
                        </li>
                        <li>
                          <strong>- For Organizations:</strong>
                          <ol type='a'>
                            <li>
                              <p>
                                When your members join iTRUSTit they promote your organization to
                                all their friends.
                              </p>
                            </li>
                          </ol>
                        </li>
                        <li>
                          <strong>- For All:</strong>
                          <ol type='a'>
                            <li>
                              <p>
                                When the businesses or organizations you sponsor use premium
                                services or advertise on the ITRUSTit platform, you’ll receive a
                                portion of the revenue that’s generated, if you opt into our
                                iPartner Program.{' '}
                              </p>
                            </li>
                          </ol>
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
                <h5 className='mb-2 mt-5'>iPartner Affiliate Program</h5>
                <div className='card'>
                  <div className='card-header'>
                    <a className='collapsed card-link' data-bs-toggle='collapse' href='#p-one'>
                      What is the iPartner Program?
                    </a>
                  </div>
                  <div id='p-one' className='collapse' data-bs-parent='#accordion'>
                    <div className='card-body'>
                      <p>
                        The iPartner program is an affiliate revenue sharing program developed by
                        iTRUSTit to reward its members for helping us grow the platform.
                      </p>
                    </div>
                  </div>
                </div>
                <div className='card'>
                  <div className=' card-header'>
                    <a className=' collapsed card-link' data-bs-toggle='collapse' href='#p-two'>
                      How does the iPartner Program work?
                    </a>
                  </div>
                  <div id='p-two' className='collapse' data-bs-parent='#accordion'>
                    <div className='card-body'>
                      <p>
                        Everyone you invite that joins iTRUSTit with you as their sponsor (and
                        anyone they invite out to the seventh degree of separation) is part of your
                        iTeam. When members of your iTeam purchase advertising or upgraded services,
                        when you opt in you can share in the revenue that iTRUSTit generates from
                        your iTeam.
                      </p>
                    </div>
                  </div>
                </div>
                <div className='card'>
                  <div className='card-header'>
                    <a className=' collapsed card-link' data-bs-toggle='collapse' href='#p-three'>
                      How do I join the iPartner Program?
                    </a>
                  </div>
                  <div id='p-three' className='collapse' data-bs-parent='#accordion'>
                    <div className='card-body'>
                      <p>
                        All users that meet the iPartner Program requirements can be eligible for
                        revenue sharing. Users do need to Opt-In and agree to be an Independent
                        Marketing Representative of iTRUSTit.
                      </p>
                    </div>
                  </div>
                </div>
                <div className='card'>
                  <div className='card-header'>
                    <a className=' collapsed card-link' data-bs-toggle='collapse' href='#p-four'>
                      Is the iPartner Affiliate Revenue Share Program free?
                    </a>
                  </div>
                  <div id='p-four' className='collapse' data-bs-parent='#accordion'>
                    <div className='card-body'>
                      <p>Yes, the iPartner Program is free.</p>
                    </div>
                  </div>
                </div>
                <div className='card'>
                  <div className='card-header'>
                    <a className=' collapsed card-link' data-bs-toggle='collapse' href='#p-five'>
                      How do I grow my iTRUSTit Network?
                    </a>
                  </div>
                  <div id='p-five' className='collapse' data-bs-parent='#accordion'>
                    <div className='card-body'>
                      <p>
                        You simply invite people, businesses, and organizations you know to become
                        part of the iTRUSTit platform. When others sign up using your unique invite
                        code which designates you as their sponsor, that will track their
                        registration to you. There is no limit to the number of new users you can
                        sponsor, ; however, if a person, organization or business is already
                        registered under someone else, they will not be able to sign up again. So
                        don’t wait to invite those in your social and business networks!
                      </p>
                    </div>
                  </div>
                </div>
                <div className='card'>
                  <div className='card-header'>
                    <a className=' collapsed card-link' data-bs-toggle='collapse' href='#p-six'>
                      What is iPartner Pro?
                    </a>
                  </div>
                  <div id='p-six' className='collapse' data-bs-parent='#accordion'>
                    <div className='card-body'>
                      <p>
                        You become a “iPartner Pro” by reaching a specific level of participation by
                        the businesses, organizations or members that you have introduced to
                        iTRUSTit: Your iTeam!
                      </p>
                    </div>
                  </div>
                </div>
                <div className='card'>
                  <div className='card-header'>
                    <a className=' collapsed card-link' data-bs-toggle='collapse' href='#p-six'>
                      What is a Charter Member?
                    </a>
                  </div>
                  <div id='p-six' className='collapse' data-bs-parent='#accordion'>
                    <div className='card-body'>
                      <p>
                        All users who sign up during our private beta will be Charter Members.
                        Charter Members get lifetime iPartner Pro status.
                      </p>
                    </div>
                  </div>
                </div>
                <div className='card'>
                  <div className='card-header'>
                    <a className=' collapsed card-link' data-bs-toggle='collapse' href='#p-seven'>
                      What are the Benefits of becoming an iPartner Pro?
                    </a>
                  </div>
                  <div id='p-seven' className='collapse' data-bs-parent='#accordion'>
                    <div className='card-body'>
                      <p>
                        iPartner Pros receive a higher percentage share of the revenue generated by
                        their “iTeam.”
                      </p>
                    </div>
                  </div>
                </div>
                <h5 className='mb-2 mt-5'>User Reviews</h5>
                <div className='card'>
                  <div className='card-header'>
                    <a className=' collapsed card-link' data-bs-toggle='collapse' href='#r-one'>
                      Who should I review on iTRUSTit?
                    </a>
                  </div>
                  <div id='r-one' className='collapse' data-bs-parent='#accordion'>
                    <div className='card-body'>
                      <p>
                        You should offer useful information you have from your experiences as a
                        member of organizations and from using businesses. This will help your
                        friends, business associates and all other ITRUSTit users to make better,
                        more efficient decisions. Whether it’s a local restaurant, church, auto
                        mechanic or national insurance carrier, your review can help everyone know
                        more about the business or organization and make informed decisions.
                      </p>
                    </div>
                  </div>
                </div>
                <div className='card'>
                  <div className='card-header'>
                    <a className=' collapsed card-link' data-bs-toggle='collapse' href='#r-two'>
                      How do I review a business?
                    </a>
                  </div>
                  <div id='r-two' className='collapse' data-bs-parent='#accordion'>
                    <div className='card-body'>
                      <p>
                        Once you have located the business using the general search at the top of
                        the page, you will see their business information and reviews. To the right,
                        there are stars for “Reviews.”. Click the reviews and you will be able to
                        write a review.
                      </p>
                    </div>
                  </div>
                </div>
                <div className='card'>
                  <div className='card-header'>
                    <a className=' collapsed card-link' data-bs-toggle='collapse' href='#r-three'>
                      Can the reviews be negative?
                    </a>
                  </div>
                  <div id='r-three' className='collapse' data-bs-parent='#accordion'>
                    <div className='card-body'>
                      <p>
                        We encourage our users to write reviews on all of the businesses they know,
                        good, bad, or indifferent so their trusted network will get the full
                        spectrum of ratings and reviews.
                      </p>
                    </div>
                  </div>
                </div>
                <div className='card'>
                  <div className='card-header'>
                    <a className=' collapsed card-link' data-bs-toggle='collapse' href='#r-four'>
                      Do businesses have the ability to answer or object to user reviews?
                    </a>
                  </div>
                  <div id='r-four' className='collapse' data-bs-parent='#accordion'>
                    <div className='card-body'>
                      <p>
                        Yes, we will have a system in place for businesses that have issues with the
                        accuracy or legitimacy of negative reviews they received.
                      </p>
                    </div>
                  </div>
                </div>
                <h5 className='mb-2 mt-5'>Business Information</h5>
                <div className='card'>
                  <div className='card-header'>
                    <a className=' collapsed card-link' data-bs-toggle='collapse' href='#i-one'>
                      Is iTRUSTit free for Businesses?
                    </a>
                  </div>
                  <div id='i-one' className='collapse' data-bs-parent='#accordion'>
                    <div className='card-body'>
                      <p>
                        Yes, and we have premium upgrade opportunities to improve business marketing
                        capabilities.
                      </p>
                    </div>
                  </div>
                </div>
                <div className='card'>
                  <div className='card-header'>
                    <a className=' collapsed card-link' data-bs-toggle='collapse' href='#i-two'>
                      Can I advertise my business on iTRUSTit?
                    </a>
                  </div>
                  <div id='i-two' className='collapse' data-bs-parent='#accordion'>
                    <div className='card-body'>
                      <p>
                        iTRUSTit will have a number of targeted advertising options for local and
                        national businesses.
                      </p>
                    </div>
                  </div>
                </div>
                <div className='card'>
                  <div className='card-header'>
                    <a className=' collapsed card-link' data-bs-toggle='collapse' href='#i-three'>
                      Can I review other businesses I work with?
                    </a>
                  </div>
                  <div id='i-three' className='collapse' data-bs-parent='#accordion'>
                    <div className='card-body'>
                      <p>
                        Absolutely! We encourage businesses to trust and review other businesses and
                        to invite affiliates, customers, service providers and any other entity they
                        see fit to join iTRUSTit.
                      </p>
                    </div>
                  </div>
                </div>
                <div className='card'>
                  <div className='card-header'>
                    <a className=' collapsed card-link' data-bs-toggle='collapse' href='#i-four'>
                      How did my business info get onto the iTRUSTit Site?
                    </a>
                  </div>
                  <div id='i-four' className='collapse' data-bs-parent='#accordion'>
                    <div className='card-body'>
                      <p>
                        We license a database with over 2.5 million business listings sourced from
                        public information. We also get business information directly from our
                        users, who supply and correct information on the platform.
                      </p>
                    </div>
                  </div>
                </div>
                <div className='card'>
                  <div className='card-header'>
                    <a className=' collapsed card-link' data-bs-toggle='collapse' href='#i-five'>
                      How do I add my business or organization to iTRUSTit?
                    </a>
                  </div>
                  <div id='i-five' className='collapse' data-bs-parent='#accordion'>
                    <div className='card-body'>
                      <p>
                        Your business may already be listed in our database. If you find your
                        business through a general search on ITRUSTit, you can claim your Business
                        or Organization. You must be able to prove you are the authorized
                        representative/owner.
                      </p>
                    </div>
                  </div>
                </div>
                <div className='card'>
                  <div className='card-header'>
                    <a className=' collapsed card-link' data-bs-toggle='collapse' href='#i-six'>
                      What if my business or organization is not listed?
                    </a>
                  </div>
                  <div id='i-six' className='collapse' data-bs-parent='#accordion'>
                    <div className='card-body'>
                      <p>
                        You can sign up your business or organization from the link in the
                        invitation you received.{' '}
                      </p>
                    </div>
                  </div>
                </div>
                <div className='card'>
                  <div className='card-header'>
                    <a className=' collapsed card-link' data-bs-toggle='collapse' href='#i-seven'>
                      How do I correct information for my business if it is listed incorrectly?
                    </a>
                  </div>
                  <div id='i-seven' className='collapse' data-bs-parent='#accordion'>
                    <div className='card-body'>
                      <p>
                        After you sign up or claim your business or organization, you can change
                        information on your “Edit Profile” page.
                      </p>
                    </div>
                  </div>
                </div>
                <h5 className='mb-2 mt-5'>Profile</h5>
                <div className='card'>
                  <div className='card-header'>
                    <a className=' collapsed card-link' data-bs-toggle='collapse' href='#pe-one'>
                      How do I edit my profile?
                    </a>
                  </div>
                  <div id='pe-one' className='collapse' data-bs-parent='#accordion'>
                    <div className='card-body'>
                      <p>
                        Log In, then you can access and adjust your profile settings by clicking on
                        the “Edit Profile” link in the upper right corner dropdown menu.
                      </p>
                    </div>
                  </div>
                </div>
                <div className='card'>
                  <div className='card-header'>
                    <a className=' collapsed card-link' data-bs-toggle='collapse' href='#pe-two'>
                      How do I change my email address?
                    </a>
                  </div>
                  <div id='pe-two' className='collapse' data-bs-parent='#accordion'>
                    <div className='card-body'>
                      <p>Go to the “Edit Profile” link in the upper right corner dropdown menu.</p>
                    </div>
                  </div>
                </div>
                <div className='card'>
                  <div className='card-header'>
                    <a className=' collapsed card-link' data-bs-toggle='collapse' href='#pe-three'>
                      How do I adjust my privacy settings?
                    </a>
                  </div>
                  <div id='pe-three' className='collapse' data-bs-parent='#accordion'>
                    <div className='card-body'>
                      <p>
                        You can access and adjust your privacy settings by clicking on the
                        “Settings” link in the upper right corner dropdown menu.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              {isUserLogin() && userToken() ? (
                <div className='it-faq-bottom'>
                  {this.state.isSubmitQuestionText && (
                    <div>
                      <a href={null} onClick={this.hideSubmitQuestions} className='blue_btn'>
                        ASK US A QUESTION
                      </a>
                      <div className='row'>
                        <form
                          className='col-md-8'
                          onSubmit={this.questionSubmitHandler}
                          id='submitQuestionForm'
                        >
                          <div className='form-group'>
                            <input
                              name='name'
                              type='text'
                              className='form-control'
                              placeholder='Enter your question here...'
                              onChange={this.shareHandleChange}
                            />
                          </div>
                          {/* <a href="javascript:void(0)" className="blue_btn mt-2 mb-0">Send</a> */}
                          <input type='submit' className='blue_btn mt-2 mb-0' value='Send' />
                        </form>
                      </div>
                    </div>
                  )}
                  {!this.state.isSubmitQuestionText && (
                    <a href={null} onClick={this.showSubmitQuestions} className='blue_btn'>
                      ASK US A QUESTION
                    </a>
                  )}
                </div>
              ) : (
                <div className='it-faq-bottom'>
                  <a
                    href={null}
                    onClick={this.loginContinueHandler}
                    data-bs-toggle='modal'
                    data-bs-target='#login_modal'
                    className='blue_btn'
                  >
                    ASK US A QUESTION
                  </a>
                </div>
              )}
            </div>
          </div>
        </section>
        <LoginModal
          isLogin={(e) =>
            function (data) {
              this.props.isLogin(data)
            }
          }
          {...this.props}
        />
      </AppLayout>
    )
  }
}

export default faq
