import React, { Component, Fragment } from 'react'
import ReactDOM from 'react-dom'
import { bindActionCreators } from 'redux'
import { Field, reduxForm } from 'redux-form'
import classNames from 'classnames'
import renderInputField from '../Form/Input'
import * as validation from '../../services/formValidation'
import * as customFunction from '../../services/customFunction'
import { connect } from 'react-redux'
import ErrorMsgRenderer from '../ErrorMsgRenderer/ErrorMsgRenderer'
// import { forgotPassword } from "../../services/authService";
// import { toaster } from "../../services/customFunction";
// import { NETWORK_ERROR_MSG } from "../../services/const";
import $ from 'jquery'
import SocialLogin from '../SocialLogin/SocialLogin'
import { DEFAULT_PROFILE_PICTURE } from '../../services/const'
import { SIGNUP_ROUTE, SIGNUP_INDIVIDUAL_ROUTE } from '../../services/routeConst'
import {
  loginAction,
  signupAction,
  socialLoginAction,
  errorMsgAction,
  authResponse,
  authResponseAction,
  isLoadingAction,
  loginHistoryEmail,
  socialLoginFillForm,
} from '../../actions/auth'
import * as route from '../../services/routeConst'
import './LoginModal.scss'
import ForgotPassword from '../ForgotPassword/ForgotPassword'
import LoginOptionsModal, {
  hideLoginOptionsModal,
  showLoginOptionsModal,
} from '../LoginOptionsModal/LoginOptionsModal'
import ConfirmSocialSignupModal, {
  hideConfirmSocialSignupModal,
  showConfirmSocialSignupModal,
} from '../SignupNew/ConfirmSocialSignupModal'
import SelectUserTypeModal, { hideSelectUserTypeModal } from '../SignupNew/SelectUserTypeModal'
import LoadingOverlay from '../CommonComponents/LoadingOverlay'

class LoginModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      forgotPasswordFormErr: {
        email: [],
      },
      isLoading: false,
      submitBtnText: 'Log In',
      socialSingup: {},
      isRedirectIndividualSignup: false,
      isDisplayLoginErrorMsg: false,
      status: 'idle',
      selectedUserType: null,
      socialData: null,
    }
  }

  // loginSubmitHandler = (data) => {
  //   this.setState({
  //     isLoading: true,
  //     submitBtnText: 'Loading...',
  //     isDisplayLoginErrorMsg: false,
  //   })
  //   this.props.loginAction(data)
  // }
  // componentWillMount() {
  //   //console.log("isLoadingAction");

  //   this.props.isLoadingAction(false)
  // }
  componentWillUnmount() {
    //console.log("isLoadingAction");

    $('#login_modal').modal('hide')
    this.props.isLoadingAction(false)
  }
  // componentWillReceiveProps = (nextProps) => {
  //   console.log('nextProps', nextProps)
  //   let resetText = 'Log In'
  //   this.setState({
  //     submitBtnText: resetText,
  //     isLoading: false,
  //     isDisplayLoginErrorMsg: false,
  //   })

  //   if (Object.keys(nextProps.user.userdetail).length > 0 || nextProps.user.userdetail.length > 0) {
  //     customFunction.setUser(nextProps.user.userdetail)
  //     if (nextProps.user.token === undefined) {
  //       customFunction.setToken(customFunction.userToken())
  //     } else {
  //       customFunction.setToken(nextProps.user.token)
  //     }

  //     let profile_history = JSON.parse(window.localStorage.getItem('profile_history'))
  //     let history = {}

  //     history = {
  //       id: nextProps.user.userdetail.id,
  //       profile_img: nextProps.user.userdetail.profile_picture_medium
  //         ? nextProps.user.userdetail.profile_picture_medium
  //         : DEFAULT_PROFILE_PICTURE,
  //       email: nextProps.user.userdetail.email,
  //       type: nextProps.user.userdetail.login_type,
  //       user_type: nextProps.user.userdetail.user_type,
  //     }
  //     if (nextProps.user.userdetail.user_type === 'individual')
  //       history.name = nextProps.user.userdetail.fullname
  //     else history.name = nextProps.user.userdetail.business_organization_name

  //     if (profile_history == null) {
  //       window.localStorage.setItem('profile_history', JSON.stringify([history]))
  //     } else {
  //       let userFind = profile_history.filter((profile) => {
  //         return profile.id === nextProps.user.userdetail.id
  //       })
  //       if (userFind.length === 0) {
  //         profile_history.unshift(history)
  //         window.localStorage.setItem('profile_history', JSON.stringify(profile_history))
  //       } else {
  //         const profileIndex = profile_history.findIndex(
  //           (profile) => profile.id === nextProps.user.userdetail.id,
  //         )
  //         profile_history[profileIndex] = history
  //         profile_history.push(...profile_history.splice(0, profileIndex))

  //         window.localStorage.setItem('profile_history', JSON.stringify(profile_history))
  //       }
  //     }
  //     $('#login_modal').modal('hide')
  //     hideLoginOptionsModal()
  //     nextProps?.isLogin?.(true)
  //   }
  //   if (Object.keys(nextProps.user.errormsg).length > 0 || nextProps.user.errormsg.length > 0) {
  //     if (Object.keys(this.state.socialSingup).length > 0) {
  //       hideLoginOptionsModal()
  //       this.props.history.push({
  //         pathname: route.SIGNUP_ROUTE,
  //       })
  //       // this.setState({ isRedirectIndividualSignup: true }, () => {
  //       //     this.props.socialLoginFillForm(this.state.socialSingup);
  //       // });
  //     }
  //     this.setState({ isDisplayLoginErrorMsg: true })
  //   }
  // }

  // loginHandler = (event) => {
  //   this.setState({ isDisplayLoginErrorMsg: false })
  // }

  // socialLoginHandler = (registerData) => {
  //   this.setState({ socialSingup: registerData }, () => {
  //     $('#login_modal').modal('hide')
  //     this.props.socialLoginAction(registerData)
  //   })
  // }
  signUpCalled = () => {
    //console.log("signUpCalled");
    $('#login_modal').modal('hide')
    this.props.history.push({
      pathname: SIGNUP_ROUTE,
    })
  }
  loginCalled = () => {
    //console.log("signUpCalled");
    $('#login_modal').modal('hide')
    showLoginOptionsModal()
    // this.props.history.push({
    //   pathname: route.LOGIN_ROUTE,
    // })
  }

  componentDidMount() {}
  handleSocialSubmit = (formData) => {
    const signupData = formData
    signupData.user_type = this.state.selectedUserType ?? 'individual'
    const self = this
    this.setState({ status: 'submitting' }, () => {
      localStorage.removeItem('bus_org_name_from_presearch')
      this.props.signupAction(signupData)
      this.props.authResponseAction(null)
      this.props.errorMsgAction(null)
      hideConfirmSocialSignupModal()
    })
  }

  handleSelectUserType = (userType) => {
    this.setState({ selectedUserType: userType }, () => {
      hideSelectUserTypeModal()
      showConfirmSocialSignupModal()
    })
  }

  render() {
    const { invalid, handleSubmit } = this.props
    let self = this
    $('#login_modal').on('hidden.bs.modal', function (e) {
      self.setState({ isDisplayLoginErrorMsg: false })
    })

    $('#login_modal').on('shown.bs.modal', function (e) {
      // ReactDOM.findDOMNode(self.refs.forgotPasswordEmail).focus();
      if (self?.props?.imatrix_id?.length) {
        customFunction.setInviteMatrixId(self?.props?.imatrix_id)
      }
      self.setState({ isDisplayLoginErrorMsg: false })
    })

    return (
      <div>
        <div className='modal fade login_blk reset_password_blk' id='login_modal' role='dialog'>
          <div className='modal-dialog'>
            <div className='modal-content'>
              <div className='modal-header row flex-column pb-0'>
                <small
                  className='col text-center mb-3 login-modal-header-text'
                  style={{ fontSize: 16 }}
                >
                  <span>
                    You must <strong>Sign Up</strong> or be <strong>Logged In</strong> to use these
                    functions.
                  </span>
                </small>
                <button type='button' className='btn-close' data-bs-dismiss='modal'></button>
              </div>
              <div className={'modal-body loginblk pt-0'}>
                <div className='d-flex flex-column' style={{ gap: 20 }}>
                  <div className='d-flex justify-content-center'>
                    <button
                      onClick={this.signUpCalled}
                      className='purple text-center btn btn-success resetbtn my-0'
                      style={{
                        padding: '25px 20px',
                        backgroundColor: '#198754',
                        transition: 'all 0.3s linear',
                      }}
                    >
                      Click Here to Sign Up
                    </button>
                  </div>
                  <div className='d-flex flex-column align-items-center' style={{ gap: 4 }}>
                    <strong style={{ color: '#6684f2' }}>Already have an iTRUSTit account?</strong>
                    <div
                      onClick={this.loginCalled}
                      className='purple text-center btn btn-success resetbtn my-0'
                      style={{
                        padding: '25px 20px',
                        backgroundColor: '#544cf9',
                        transition: 'all 0.3s linear',
                      }}
                    >
                      Click Here to Log In
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <LoadingOverlay show={this.state.status === 'submitting'} />
        <ForgotPassword />
        <LoginOptionsModal
          status={this.state.status}
          setStatus={(val, cb = () => {}) => this.setState(val, cb)}
          selectedUserType={this.state.selectedUserType}
          setSelectedUserType={(val, cb = () => {}) => this.setState(val, cb)}
          socialData={this.state.socialData}
          setSocialData={(val, cb = () => {}) => this.setState(val, cb)}
          isLogin={this.props.isLogin}
        />
        <ConfirmSocialSignupModal
          status={this.state.status}
          data={this.state.socialData}
          userTypeOverride={this.state.selectedUserType}
          handleSubmit={this.handleSocialSubmit}
        />
        <SelectUserTypeModal status={this.state.status} handleSubmit={this.handleSelectUserType} />
      </div>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      loginAction,
      signupAction,
      socialLoginAction,
      errorMsgAction,
      authResponseAction,
      isLoadingAction,
    },
    dispatch,
  )
  // return {
  //     loginAction: bindActionCreators(loginAction, dispatch),
  // };
}

function mapStateToProps(state) {
  return {
    user: state.UserReducer,
    networkerror: state.UserReducer?.networkerror,
    errormsg: state.UserReducer?.errormsg,
    isLoading: state.UserReducer?.isLoading,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginModal)
