import React, { Component } from 'react'
import ReactDOM from 'react-dom'
// import { Redirect } from "react-router-dom";
import { Field, reduxForm, change, untouch } from 'redux-form'
import classNames from 'classnames'
import renderInputField from '../Form/Input'
import * as validation from '../../services/formValidation'
import * as customFunction from '../../services/customFunction'
// import * as route from "../../services/routeConst";
import ErrorMsgRenderer from '../ErrorMsgRenderer/ErrorMsgRenderer'
import { forgotPassword } from '../../services/authService'
import { toaster } from '../../services/customFunction'
import { NETWORK_ERROR_MSG, REQUIRED_VALID_EMAIL } from '../../services/const'
import $ from 'jquery'

class ForgotPassword extends Component {
  constructor(props) {
    super(props)
    const isUserLogin = customFunction.isUserLogin()
    this.state = {
      forgotPasswordFormErr: {
        email: [],
      },
      isUserLogin: isUserLogin,
      isLoading: false,
      submitBtnText: 'Reset Pasword',
    }

    this.resetPasswordEmailFormSubmitHandler = this.resetPasswordEmailFormSubmitHandler.bind(this)
    //this.resetForm = this.resetForm.bind(this);
  }

  //    resetForm(){
  //        console.log('in resetForm');
  //        const fields = ['email']
  //        for (var i = 0; i < fields.length; i++) {
  //            this.props.dispatch(change('formName',fields[i],null))
  //            this.props.dispatch(untouch('formName',fields[i]))
  //        }
  //    }

  resetPasswordEmailFormHandler = (event) => {
    let inputName = event.target.name
    // let inputValue = event.target.value;

    if (inputName === 'email') {
      let stateCopy = { ...this.state }
      stateCopy.forgotPasswordFormErr[inputName] = []
      this.setState(stateCopy)
    }
  }

  resetPasswordEmailFormSubmitHandler = (data) => {
    this.setState({ isLoading: true, submitBtnText: 'Loading...' })
    let self = this
    forgotPassword(data).then(function (response) {
      self.setState({ isLoading: false, submitBtnText: 'Reset Pasword' })
      if (response === undefined) {
        toaster('error', NETWORK_ERROR_MSG)
      } else if (response.status === 422) {
        self.setState({
          //forgotPasswordFormErr: response.data.errors,
          forgotPasswordFormErr: {
            email: [REQUIRED_VALID_EMAIL], //Asana  #09142021_858a
          },
        })
      } else if (response.status === true) {
        toaster('success', response.message)
        $('#reset_password_email_modal').modal('hide')
      } else {
        toaster('error', response.data.message)
      }
    })
  }

  render() {
    const { invalid, handleSubmit } = this.props
    let self = this
    $('#reset_password_email_modal').on('hidden.bs.modal', function (e) {
      //console.log('on hidden.bs.modal');
      //            self.resetForm();
      //            self.props.reset();
      const fields = ['email']
      for (var i = 0; i < fields.length; i++) {
        self.props.dispatch(change('reset_password_email_form', fields[i], null))
        self.props.dispatch(untouch('reset_password_email_form', fields[i]))
      }

      let stateCopy = { ...self.state }
      stateCopy.forgotPasswordFormErr['email'] = []
      self.setState(stateCopy)
    })

    $('#reset_password_email_modal').on('shown.bs.modal', function (e) {
      ReactDOM.findDOMNode(self.refs.forgotPasswordEmail).focus()
    })

    return (
      <div className='modal fade reset_password_blk' id='reset_password_email_modal' role='dialog'>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h4 className='modal-title'>Reset password</h4>
              <button type='button' className='btn-close' data-bs-dismiss='modal'></button>
            </div>
            <div className='modal-body'>
              <p className='text-start'>
                Enter the email address associated with your account, and we’ll email you a link to
                reset your password.
              </p>
              <form
                id='reset_password_email_form'
                name='reset_password_email_form'
                className='reset_password'
                method='post'
                onSubmit={handleSubmit(this.resetPasswordEmailFormSubmitHandler).bind(this)}
              >
                <div className='form-group'>
                  <Field
                    ref='forgotPasswordEmail'
                    name='email'
                    type='text'
                    className='form-control reset_pwd'
                    component={renderInputField}
                    validate={[validation.required, validation.email]}
                    label='Email'
                    placeholder='Email'
                    autoComplete='on'
                    onChange={this.resetPasswordEmailFormHandler}
                  />
                  <ErrorMsgRenderer
                    errors={
                      typeof this.state.forgotPasswordFormErr.email !== 'undefined' &&
                      Array.isArray(this.state.forgotPasswordFormErr.email) &&
                      this.state.forgotPasswordFormErr.email.length > 0
                        ? this.state.forgotPasswordFormErr.email
                        : []
                    }
                  />
                </div>
                <input
                  type='submit'
                  value={this.state.submitBtnText}
                  className={classNames(invalid && 'disabled', 'form-control resetbtn')}
                  disabled={invalid || this.state.isLoading}
                />
              </form>

              {/* <form className="reset_password">
                                <div className="form-group">
                                    <input type="email" className="form-control reset_pwd" placeholder="Email" />
                                    <input type="submit" className="form-control resetbtn" value="Reset Pasword" />
                                </div>
                            </form> */}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

ForgotPassword = reduxForm({
  form: 'reset_password_email_form',
})(ForgotPassword)

export default ForgotPassword
