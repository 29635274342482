import React, { Component, createRef } from 'react'
import AppLayout from '../AppLayout'
import '../Message/message.scss'
import { MESSAGE_TITLE } from '../../services/titleConst'
import { DEFAULT_PROFILE_PICTURE } from '../../services/const'
import { Link } from 'react-router-dom'
// import * as route from "../../services/routeConst";
//import * as customFunction from "../../services/customFunction";
//import { NO_COVER_PICTURE } from "../../services/const";
import { getProfileFromIdByMsg } from '../../services/userApiService'
import * as messageApiService from '../../services/messageApiService'
import * as customFunction from '../../services/customFunction'
import axios from 'axios'
import { customLogger } from '../../utils/CustomLogger'
import SendIcon from './SendIcon'
import {
  CDN_URL,
  ELASTIC_SEARCH_BASE_URL,
  ELASTIC_SEARCH_TYPE,
  ELASTIC_SEARCH_USERS,
} from '../../services/environment'
import { Helmet } from 'react-helmet'
import ChatMessageBox from './ChatMessageBox'
import ChatListUser from './ChatListUser'
import ChatListGroup from './ChatListGroup'
import { isMobile } from 'react-device-detect'

//import Echo from "laravel-echo/dist/echo";
//import Echo from "laravel-echo";
//import Socketio from "socket.io-client";
//window.io = require('socket.io-client');

let listenForWhisperTimeout = null
let getChatUserListTimeout = null

class Message extends Component {
  constructor(props) {
    super(props)

    this.state = {
      user: customFunction.getUser(),
      userToken: customFunction.userToken(),
      chat_user_id: Number(props.match.params.user_id ? props.match.params.user_id : 0),
      group_chat_id: Number(
        props.match.params.group_chat_id ? props.match.params.group_chat_id : 0,
      ),
      chat_user: null,
      chat_user_title: null,
      chat_message: props?.location?.state?.messageContent ?? '',
      chat_messages_list: [],
      is_chat_user_typing: false,
      is_message_fetching: !props?.location?.state?.messageContent,
      chat_users_id: [],
      chat_users_list: [],
      chat_users_online_list: [],
      chat_user_search_str: '',
      userslug: '',
      searching: !props?.location?.state?.messageContent,
      documentTitle: MESSAGE_TITLE,
      newMessageUsers: [],
      is_new_message: !!props?.location?.state?.messageContent,
    }
    // this.sendMessage = this.sendMessage.bind(this)
    this.textInput = createRef()
    this.focus = this.focus.bind(this)
    window.history.replaceState(null, '')
  }

  focus() {
    this?.textInput?.current?.focus?.()
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    if (this.state?.chat_user_id > 0 || this.state?.group_chat_id > 0) {
      const id = this.state.group_chat_id || this.state.chat_user_id
      this.getChatUserList(id, !!this.state.group_chat_id, true)
    } else {
      this.getChatUserList()
      // if (getChatUserListTimeout) {
      //     clearTimeout(getChatUserListTimeout);
      // }
      // let self = this;
      // getChatUserListTimeout = setInterval(function () {
      //     self.getChatUserList();
      // }, 1000 * 60 * 1); //1000 * 60 * 1
    }
    //this.listenPrivateChannelForOnlineOffline();
  }

  componentWillUnmount() {
    if (getChatUserListTimeout) {
      clearTimeout(getChatUserListTimeout)
    }
  }

  componentWillReceiveProps(nextProps) {
    let chat_user_id_new = nextProps.match.params.user_id
    let chat_user_id_old = this.state.chat_user_id
    let group_chat_id_new = nextProps.match.params.group_chat_id
    let group_chat_id_old = this.state.group_chat_id
    if (group_chat_id_new && '' + group_chat_id_new !== '' + group_chat_id_old) {
      this.onGroupChatChange(Number(group_chat_id_new))
    } else if (chat_user_id_new && '' + chat_user_id_new !== '' + chat_user_id_old) {
      this.onChatUserChange(Number(chat_user_id_new))
    }
  }

  scrollToBottom = () => {
    let chat_panel_area_section = document.getElementById('chat_panel_area')
    if (!chat_panel_area_section?.scrollHeight) return
    chat_panel_area_section.scrollTop =
      chat_panel_area_section?.scrollHeight - chat_panel_area_section?.clientHeight
  }

  elasticSearchByID = (userID) => {
    var postData = {
      query: {
        bool: {
          must: [
            {
              match: {
                id: userID,
              },
            },
          ],
        },
      },
      size: 5,
      highlight: {
        pre_tags: ['<strong>'],
        post_tags: ['</strong>'],
        fields: {
          name: {},
        },
      },
    }
    // console.log(postData);
    var elastic_search_api =
      ELASTIC_SEARCH_BASE_URL +
      ELASTIC_SEARCH_USERS +
      '/' +
      ELASTIC_SEARCH_TYPE +
      '/_search?pretty=true'
    return axios
      .post(elastic_search_api, postData, {
        headers: { 'Content-Type': 'application/json' },
      })
      .then(function (response) {
        if (typeof response.status !== 'undefined' && response.status === 200) {
          return response.data.hits.hits
        } else console.log('NO RESULTS')
      })
      .catch(function (error) {
        customLogger(error)
        //return error.response;
      })
  }

  elasticSearchByIDs = (userIDs) => {
    var postData = {
      query: {
        ids: {
          values: userIDs,
        },
      },
      size: 5,
      highlight: {
        pre_tags: ['<strong>'],
        post_tags: ['</strong>'],
        fields: {
          name: {},
        },
      },
    }
    // console.log(postData);
    var elastic_search_api =
      ELASTIC_SEARCH_BASE_URL +
      ELASTIC_SEARCH_USERS +
      '/' +
      ELASTIC_SEARCH_TYPE +
      '/_search?pretty=true'
    return axios
      .post(elastic_search_api, postData, {
        headers: { 'Content-Type': 'application/json' },
      })
      .then(function (response) {
        if (typeof response.status !== 'undefined' && response.status === 200) {
          return response.data.hits.hits
        } else console.log('NO RESULTS')
      })
      .catch(function (error) {
        customLogger(error)
        //return error.response;
      })
  }

  getProfileFromId() {
    let getProfileFromIdReqData = {
      user_id: this.state.chat_user_id,
    }
    let self = this

    // getProfileFromIdByMsg(getProfileFromIdReqData).then(function (response) {
    this.elasticSearchByID(this.state.chat_user_id).then(function (response) {
      let profileData = response[0]['_source']
      const userListData = self.state.chat_users_list?.find((u) => u.id === profileData.id)
      // console.log(profileData);
      // return;
      // let chat_user_title = (profileData.business_organization_name
      //     ? profileData.business_organization_name
      //     : profileData.fullname);
      let chat_user_title =
        profileData.user_type === 'individual'
          ? profileData.fullname || profileData.name
          : profileData.business_organization_name
      let documentTitle = MESSAGE_TITLE + ' - ' + chat_user_title
      const chatUser = self.state.chat_users_list.filter((user) => user.id === profileData.id)
      const profilePicture =
        (chatUser[0] && chatUser[0].user_detail.profile_picture_small) ||
        (profileData.image ? `${CDN_URL}profile_picture/small/${profileData.image}` : null)
      let stateData = {
        chat_user_title: chat_user_title,
        chat_user: {
          ...profileData,
          profile_picture_small: profilePicture,
          user_detail: {
            id: profileData.id,
            user_id: profileData.user_id,
            profile_picture_small: profilePicture,
          },
          is_friend_user: userListData?.is_friend_user,
          user_settings: userListData?.user_settings,
        },
        documentTitle,
      }
      // console.log(stateData);

      self.setState(stateData, () => {
        if (self.state.chat_users_list.length === 0) {
          self.getChatUserList()
        }
      })
    })
  }

  getProfilesFromId() {
    let self = this
    let groupChat
    groupChat = this.state.chat_users_list?.find?.(
      (chatList) => chatList?.group_chat_id === this.state.group_chat_id,
    )
    const groupChatUsers = groupChat?.users
    const groupChatUserIds = groupChatUsers?.map?.((user) => user?.id)
    // getProfileFromIdByMsg(getProfileFromIdReqData).then(function (response) {
    this.elasticSearchByIDs(groupChatUserIds).then(function (response) {
      let chat_user_title = groupChatUsers?.map((u) => u?.fullname)?.join?.(', ')
      let documentTitle = MESSAGE_TITLE + ' - ' + chat_user_title
      let stateData = {
        chat_user_title: chat_user_title,
        chat_user: null,
        group_chat_selected: { ...groupChat, elasticData: response },
        documentTitle,
      }
      // console.log(stateData);

      self.setState(stateData, () => {
        if (self.state.chat_users_list.length === 0) {
          self.getChatUserList()
        }
      })
    })
  }

  setGroupChatId = (id) => {
    this.getChatUserList(id, true)
  }

  setChatUserId = (id) => {
    this.getChatUserList(id)
  }

  getAllMessages() {
    let getAllMessagesReqData = {
      chat_user_id: this.state.chat_user_id,
    }
    let self = this
    // self?.textAreaFocus?.setFocus?.()
    messageApiService.getAllMessagesCall(getAllMessagesReqData).then(function (response) {
      if (typeof response.status !== 'undefined' && response.status === 200) {
        let getAllMessagesData = response.data.chats

        let stateData = {
          chat_messages_list: getAllMessagesData,
          is_message_fetching: false,
        }

        self.setState(stateData, () => {
          const unreadMessageIds = getAllMessagesData
            ?.filter?.(
              (message) => message?.friend_id === self.state?.user?.id && !message?.is_read,
            )
            ?.map?.((msg) => msg?.id)
          unreadMessageIds?.length &&
            self.readMessages({
              chat_user_id: self.state.chat_user_id,
              messageIds: unreadMessageIds,
            })
          self.focus()
          self.scrollToBottom()
        })
      }
    })
  }

  getAllGroupMessages() {
    let request = {
      group_chat_id: this.state.group_chat_id,
    }
    let self = this
    // self?.textAreaFocus?.setFocus?.()
    messageApiService.getAllGroupMessagesCall(request).then(function (response) {
      if (response?.status === 200) {
        let getAllMessagesData = response.data.chats

        let stateData = {
          chat_messages_list: getAllMessagesData,
          is_message_fetching: false,
        }

        self.setState(stateData, () => {
          const unreadMessageIds = getAllMessagesData
            ?.filter?.((message) => message?.user_id !== self.state?.user?.id && !message?.is_read)
            ?.map?.((msg) => msg?.id)
          unreadMessageIds?.length &&
            self.readMessages({
              group_chat_id: self.state.group_chat_id,
              messageIds: unreadMessageIds,
            })
          self.focus()
          self.scrollToBottom()
        })
      }
    })
  }

  async readMessages(request) {
    let headers = {
      'X-Socket-ID': window.Echo.socketId(),
    }
    const response = await messageApiService.readMessages(request, headers)
    if (response?.data?.isRead) {
      this.setState({
        chat_users_list: this.state.chat_users_list.map((user) => ({
          ...user,
          total_unread: request?.group_chat_id
            ? user?.group_chat_id === request?.group_chat_id
              ? 0
              : user?.total_unread
            : user?.id === request?.chat_user_id
            ? 0
            : user?.total_unread,
        })),
      })
    }
  }

  getChatUserList(id, isGroupChat, initial = false) {
    let getChatUserListReqData = {}
    let self = this
    messageApiService.getChatUserListCall(getChatUserListReqData).then(function (response) {
      if (typeof response.status !== 'undefined' && response.status === 200) {
        let getChatUserListData = response.data
        // console.log(getChatUserListData)

        let chat_users_list = getChatUserListData.chat_users_list
        let chat_users_id = getChatUserListData.chat_users_id
        let chat_user_id = Number(self.state.chat_user_id)

        if (chat_users_id.indexOf(chat_user_id) === -1 && self.state.chat_user) {
          let userToPush = {
            id: self.state.chat_user.id,
            firstname: self.state.chat_user.firstname,
            lastname: self.state.chat_user.lastname,
            fullname: self.state.chat_user.fullname || self.state.chat_user.name,
            business_organization_name: self.state.chat_user.business_organization_name,
            user_type: self.state.chat_user.user_type,
            user_detail: {
              id: 0,
              user_id: self.state.chat_user.id,
              profile_picture_small: self.state.chat_user.profile_picture_small,
            },
            user_settings: self.state.chat_user.user_settings,
            slug: self.state.chat_user.slug,
          }
          chat_users_list.push(userToPush)
          chat_users_id.push(chat_user_id)
        }

        let stateData = {
          chat_users_list: chat_users_list,
          chat_users_id: chat_users_id,
          searching: false,
        }
        // console.log(stateData);
        self.setState(
          stateData,
          id
            ? () => {
                isGroupChat
                  ? self.onGroupChatChange(id, initial)
                  : self.onChatUserChange(id, initial)
              }
            : () => {},
        )
      }
    })
  }

  sendMessage = (event) => {
    let chat_message = this.state.chat_message.trim()

    this.setState({ chat_message: '' }, () => this.updateMessageBoxHeight(event))
    if (chat_message !== '') {
      let createMessageReqData = {
        friend_id: this.state.chat_user_id,
        chat: this.state.chat_message,
      }

      let createMessageReqheaders = {
        'X-Socket-ID': window.Echo.socketId(),
      }

      const self = this
      messageApiService
        .createMessageCall(createMessageReqData, createMessageReqheaders)
        .then(function (response) {
          if (typeof response.status !== 'undefined' && response.status === 200) {
            if (typeof response.status !== 'undefined' && response.status === 200) {
              let apiResponseData = response.data

              let chat_messages_list = self.state.chat_messages_list
              chat_messages_list.push(apiResponseData.chat)

              let stateData = {
                chat_messages_list: chat_messages_list,
              }
              self.setState(stateData, () => {
                self.scrollToBottom()
              })
            }
          }
        })
    }
  }

  listenPrivateChatChannel() {
    let self = this
    window.Echo?.private?.('Chat.' + this.state.chat_user_id + '.' + this.state.user.id)
      .listen('BroadcastChat', (e) => {
        let chat_messages_list = self.state.chat_messages_list
        chat_messages_list.push(e.chat)

        let stateData = {
          chat_messages_list: chat_messages_list,
        }
        self.setState(stateData, () => {
          self.scrollToBottom()
        })
      })
      .listenForWhisper('typing', (e) => {
        // console.log(e.name + ' is typing...')
        if (listenForWhisperTimeout) {
          clearTimeout(listenForWhisperTimeout)
        }
        self.setState({ is_chat_user_typing: true })
        listenForWhisperTimeout = setTimeout(function () {
          self.setState({ is_chat_user_typing: false })
        }, 3000)
      })

    window.Echo.private?.('Chat.' + this.state.user.id).listen('BroadcastChatOwn', (e) => {
      let chat_messages_list = self.state.chat_messages_list
      chat_messages_list.push(e.chat)

      let stateData = {
        chat_messages_list: chat_messages_list,
      }
      self.setState(stateData, () => {
        self.scrollToBottom()
      })
    })
  }

  listenPrivateChannelForOnlineOffline() {
    let self = this
    window.Echo.join('Online')
      .here((users) => {
        //console.log('in here :', users);
        let chat_users_online_list_length = users.length
        if (chat_users_online_list_length > 0) {
          let chat_users_online_list = []
          for (let i = 0; i < chat_users_online_list_length; i++) {
            chat_users_online_list.push(users[i].id)
          }

          let stateData = {
            chat_users_online_list: chat_users_online_list,
          }
          self.setState(stateData, () => {})
        }
      })
      .joining((user) => {
        //console.log('in joining :', user);
        let chat_users_online_list = self.state.chat_users_online_list
        chat_users_online_list.push(user.id)
        let stateData = {
          chat_users_online_list: chat_users_online_list,
        }
        self.setState(stateData, () => {})
      })
      .leaving((user) => {
        //console.log('in leaving :', user);
        let chat_users_online_list = self.state.chat_users_online_list
        var index = chat_users_online_list.indexOf(user.id)

        if (index > -1) {
          chat_users_online_list.splice(index, 1)
          let stateData = {
            chat_users_online_list: chat_users_online_list,
          }
          self.setState(stateData, () => {})
        }
      })
  }

  onlineUserListChanged = (chat_users_online_list) => {
    //console.log('in onlineUserListChanged : Message: chat_users_online_list: ', chat_users_online_list);
    this.setState({ chat_users_online_list: chat_users_online_list })
  }

  // messageFormSubmitHandler = (event) => {
  //   event.preventDefault()

  //   this.setState(
  //     {
  //       chat_message: event.target.elements.message_txt_input.value,
  //     },
  //     () => {
  //       this.sendMessage(event)
  //     },
  //   )
  // }

  setChatMessage = (value, cb) => {
    this.setState({ chat_message: value }, cb?.())
  }

  setChatMessagesList = (value, cb, scroll = false) => {
    this.setState(
      { chat_messages_list: value },
      scroll
        ? () => {
            this.scrollToBottom()
          }
        : cb?.(),
    )
  }

  onChangeUserSearchHandler = (event) => {
    this.setState({ chat_user_search_str: event.target.value })
  }

  goBackToMessageList = (isNewMessage = false) => {
    this.setState(
      {
        is_new_message: isNewMessage,
        chat_user_id: null,
        group_chat_id: null,
        is_message_fetching: false,
        chat_user_title: null,
        chat_user: null,
        group_chat_selected: null,
        chat_messages_list: [],
      },
      () => this.getChatUserList(),
    )
  }

  onChatUserChange = (chat_user_id, initial = false) => {
    if (chat_user_id !== this.state.chat_user_id || initial) {
      this.setState(
        {
          is_new_message: false,
          chat_user_title: null,
          chat_user: null,
          group_chat_selected: null,
          chat_messages_list: [],
          chat_user_id: chat_user_id,
          group_chat_id: null,
          is_message_fetching: true,
        },
        () => {
          this.state.group_chat_id ? this.getProfilesFromId() : this.getProfileFromId()
          this.state.group_chat_id ? this.getAllGroupMessages() : this.getAllMessages()
          !!window.Echo && this.listenPrivateChatChannel()
          window.scrollTo(0, 0)
          this.scrollToBottom()
        },
      )
    }
  }

  onGroupChatChange = (group_chat_id, initial = false) => {
    if (group_chat_id !== this.state.group_chat_id || initial) {
      this.setState(
        {
          is_new_message: false,
          chat_user_title: null,
          chat_user: null,
          group_chat_selected: null,
          chat_messages_list: [],
          chat_user_id: null,
          group_chat_id: group_chat_id,
          is_message_fetching: true,
        },
        () => {
          this.state.group_chat_id ? this.getProfilesFromId() : this.getProfileFromId()
          this.state.group_chat_id ? this.getAllGroupMessages() : this.getAllMessages()
          !!window.Echo && this.listenPrivateChatChannel()
          window.scrollTo(0, 0)
          this.scrollToBottom()
        },
      )
    }
  }

  composeNewMessage = () => {
    this.goBackToMessageList(true)
  }

  setNewMessageUsers = (newUsers) => {
    this?.setState?.({ newMessageUsers: newUsers })
  }

  render() {
    const {
      chat_user_title,
      user,
      chat_user_id,
      group_chat_id,
      chat_user,
      group_chat_selected,
      chat_message,
      chat_messages_list,
      is_chat_user_typing,
      is_message_fetching,
      chat_users_list,
      chat_users_online_list,
      chat_user_search_str,
    } = this.state
    //console.log('chat_user_search_str: ', chat_user_search_str);
    //return null;
    // console.log("chat_user", chat_user);

    return (
      <AppLayout
        routeActive={this.props.location.pathname}
        onlineUserListChanged={this.onlineUserListChanged}
      >
        <Helmet>
          <title>{this.state.documentTitle}</title>
        </Helmet>
        <section className='bg-grey for-msg'>
          <div
            className={`container ${
              isMobile
                ? chat_user || group_chat_selected || this.state.is_new_message
                  ? 'mt-0'
                  : 'mt-2'
                : 'mt-4'
            }`}
          >
            <div className='white-pane pb-3' style={{ minHeight: 500 }}>
              {isMobile && (chat_user || group_chat_selected || this.state.is_new_message) ? (
                <button
                  className='promotion-back-button'
                  onClick={() => this.goBackToMessageList(false)}
                >
                  <i className='fas fa-arrow-left' aria-hidden='true'></i>
                </button>
              ) : null}
              <div className='message_blk row'>
                <div
                  className={
                    !!chat_user_id || !!group_chat_id || this.state.is_new_message
                      ? 'd-none d-sm-block col-sm-5 p-0'
                      : 'col-12 col-sm-5 p-0'
                  }
                  style={{
                    border: '1px solid rgba(0,0,0,0.08)',
                    borderRadius: '0.8rem 0 0 0.8rem',
                  }}
                >
                  <div className='message_left'>
                    <div className='d-flex flex-column'>
                      <div
                        className='d-flex justify-content-between py-2 px-3'
                        style={{ borderBottom: '1px solid rgba(0,0,0,0.08)' }}
                      >
                        <strong
                          className='mont cursor-pointer'
                          onClick={() => this.goBackToMessageList(false)}
                        >
                          Messages ({chat_users_list.length}){' '}
                        </strong>
                        <i
                          onClick={this.composeNewMessage}
                          className='fa fa-edit cursor-pointer'
                          aria-hidden='true'
                          style={{
                            color: '#544CF9',
                            fontSize: 20,
                          }}
                        ></i>
                      </div>
                      <div className='d-flex position-relative my-2 mx-3'>
                        <i
                          className='fa fa-search'
                          aria-hidden='true'
                          style={{
                            color: '#544CF9',
                            fontSize: 16,
                            position: 'absolute',
                            top: '30%',
                            left: '3%',
                          }}
                        ></i>
                        <input
                          type='search'
                          placeholder='Search messages'
                          className='form-control w-100'
                          onChange={this.onChangeUserSearchHandler}
                        />
                      </div>
                    </div>
                    <ul
                      className='message_list d-block'
                      style={chat_users_list?.length >= 8 ? { overflowY: 'auto' } : {}}
                    >
                      {chat_users_list?.length ? (
                        chat_users_list.map((chat_user_of_list, index) =>
                          chat_user_of_list?.group_chat_id ? (
                            <ChatListGroup
                              key={'gc-' + chat_user_of_list.group_chat_id}
                              groupChat={chat_user_of_list}
                              groupChatId={group_chat_id}
                              onGroupChatChange={this.onGroupChatChange}
                            />
                          ) : (
                            <ChatListUser
                              key={chat_user_of_list.id}
                              user={chat_user_of_list}
                              chatUserId={chat_user_id}
                              chatUserSearchStr={chat_user_search_str}
                              chatUsersOnlineList={chat_users_online_list}
                              onChatUserChange={this.onChatUserChange}
                            />
                          ),
                        )
                      ) : this.state.searching ? (
                        <div className='search-page-loading-icon pt-2'>
                          <h4>
                            Loading messages <div className='dot-flashing'></div>
                          </h4>
                          <i />
                        </div>
                      ) : (
                        <div className='search-page-loading-icon pt-2'>
                          <h4>No Messages</h4>
                        </div>
                      )}
                    </ul>
                  </div>
                </div>

                <div
                  className={
                    !!chat_user_id || !!group_chat_id || this.state.is_new_message
                      ? 'col-12 col-sm-7 mb-2 mb-sm-0 ps-0'
                      : 'd-none d-sm-block col-sm-7 ps-0'
                  }
                  style={{
                    border: '1px solid rgba(0,0,0,0.08)',
                    borderRadius: '0 0.8rem 0.8rem 0',
                  }}
                >
                  {chat_user || group_chat_selected || this.state.is_new_message ? (
                    <ChatMessageBox
                      user={user}
                      chat_user={chat_user}
                      group_chat_selected={group_chat_selected}
                      chat_user_title={chat_user_title}
                      chat_messages_list={chat_messages_list}
                      is_chat_user_typing={is_chat_user_typing}
                      is_message_fetching={is_message_fetching}
                      chat_message={chat_message}
                      setChatMessage={this.setChatMessage}
                      setChatMessagesList={this.setChatMessagesList}
                      scrollToBottom={this.scrollToBottom}
                      isNewMessage={this.state.is_new_message}
                      chat_users_list={chat_users_list}
                      newMessageUsers={this.state.newMessageUsers}
                      setNewMessageUsers={this.setNewMessageUsers}
                      setGroupChatId={this.setGroupChatId}
                      setChatUserId={this.setChatUserId}
                    />
                  ) : !!chat_user_id || !!group_chat_id ? (
                    <div className='search-page-loading-icon'>
                      <h4>
                        Loading messages <div className='dot-flashing'></div>
                      </h4>
                      <i />
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </section>
      </AppLayout>
    )
  }
}
export default Message
