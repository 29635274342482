import React, { Component, Fragment } from 'react'
import AsyncSelect from 'react-select/async'
import Select from 'react-select'
import _ from 'lodash'
import { createRef } from 'react'
import { isMobile } from 'react-device-detect'

class ReactSelect extends Component {
  constructor(props) {
    //console.log({ props })
    super(props)
    this.debounceLoadOptions = _.debounce(this.loadOptions.bind(this), 500)
    this.mobileSelectRef = createRef()
  }

  loadOptions = (inputValue, callback) => callback(this.filterSelect(inputValue))

  filterSelect = (inputValue) =>
    this.props.options.filter((i) => i.label.toLowerCase().includes(inputValue.toLowerCase()))

  sanitize = () => {
    const exists = this.props.options.filter((option) => {
      return option.value === this.props.valueKey
    })

    return exists.length > 0 ? exists[0] : null
    //return null;
  }
  onMenuOpen = (event) => {
    const exists = this.props.options.filter((option) => {
      return option.value === this.props.valueKey
    })

    return exists.length > 0 ? exists[0] : null
  }
  render() {
    if (this.props.isSelect === 'true') {
      return (
        <Fragment>
          <Select
            onChange={this.props.input.onChange}
            // onBlur={this.props.input.onBlur}
            // onBlur={() => this.props.input.onBlur(this.props.input.value)}
            value={this.sanitize(this.props.valueKey)}
            options={this.props.options}
            focusedOption={this.props.options}
            onMenuOpen={this.onMenuOpen}
            className={this.props?.className}
          />
          <div>
            {this.props.meta.touched &&
              ((this.props.meta.error && (
                <span className='text-danger'>{this.props.meta.error}</span>
              )) ||
                (this.props.meta.warning && <span>{this.props.meta.warning}</span>))}
          </div>
        </Fragment>
      )
    } else {
      const { input, ...rest } = this.props
      const { ...inputProps } = input
      return (
        <Fragment>
          {/* The below components are here as a very hacky fix for the dynamically imported styles of the select component.. for mobile only */}
          <AsyncSelect
            name='mobile-fix-importclasses'
            value={{ value: '1', label: '' }}
            className='d-none hide-select-on-mobile'
            cacheOptions
            defaultOptions={[{ value: '1', label: '' }]}
          />
          <AsyncSelect
            name='mobile-fix-importclasses'
            value={''}
            className='d-none hide-select-on-mobile'
            cacheOptions
            defaultOptions={[{ value: '1', label: '' }]}
          />
          <AsyncSelect
            {...inputProps}
            {...rest}
            cacheOptions
            isDisabled={this.props?.disabled}
            //loadOptions={this.loadOptions}
            loadOptions={(inputText, callback) => this.debounceLoadOptions(inputText, callback)}
            focusedOption={this.props.options}
            onBlur={() => this.props.input.onBlur(this.props.input.value)}
            defaultOptions={this.props.options}
            //onBlur={() => this.props.input.onBlur(this.props.valueKey ? this.props.valueKey : this.props.input.value)}
            value={this.props.valueKey ? this.props.valueKey : this.props.input.value}
            style={{
              ...(this.props?.style ?? {}),
              display: isMobile ? 'none' : this.props?.style?.display ?? 'block',
              cursor: 'pointer',
            }}
            className={
              'cursor-pointer ' +
              (this.props?.className ?? '') +
              ' ' +
              (this.props?.input?.className ?? '') +
              ' ' +
              (isMobile ? 'hide-select-on-mobile' : '')
            }
          />
          {isMobile ? (
            <div
              className={
                (this.props?.className ?? '') +
                ' ' +
                (this.props?.input?.className ?? '') +
                ' ' +
                `css-${this.props.disabled ? '14jk2my' : '2b097c'}-container`
              }
              onClick={() => this.mobileSelectRef.current.focus()}
            >
              <span
                aria-live='polite'
                aria-atomic='false'
                aria-relevant='additions text'
                className='css-1f43avz-a11yText-A11yText'
              ></span>
              <div className={`css-${this.props.disabled ? '1fhf3k1' : 'yk16xz'}-control`}>
                <div className='css-g1d714-ValueContainer'>
                  <div
                    className={
                      this.props.valueKey?.label || this.props.valueKey || this.props.input?.value
                        ? `css-${this.props.disabled ? '107lb6w' : '1uccc91'}-singleValue`
                        : 'css-1wa3eu0-placeholder'
                    }
                  >
                    {this.props.valueKey?.label
                      ? this.props.valueKey.label
                      : this.props?.valueKey
                      ? this.props.valueKey
                      : this.props.input?.value
                      ? this.props.input.value?.label ??
                        this.props.options?.find(
                          (opt) => '' + opt?.value === '' + this.props.input.value,
                        )?.label
                      : this.props?.placeholder ?? 'Select...'}
                  </div>
                  <select
                    {...inputProps}
                    style={{ width: 1 }}
                    className='mobile-select'
                    ref={this.mobileSelectRef}
                    onBlur={() =>
                      this.props.input.onBlur(
                        this.props.valueKey?.label
                          ? this.props.valueKey?.value
                          : this.props.valueKey
                          ? this.props.valueKey
                          : this.props.input.value,
                      )
                    }
                    value={
                      this.props.valueKey?.label
                        ? this.props.valueKey?.value
                        : this.props.valueKey
                        ? this.props.valueKey
                        : this.props.input.value
                    }
                    onChange={(e) => {
                      let parsedValue = e.target.value
                      //Checks if string is number
                      if (!isNaN(parsedValue)) {
                        try {
                          parsedValue = parseInt(parsedValue)
                          if (isNaN(parsedValue)) {
                            parsedValue = e.target.value
                          }
                        } catch (error) {}
                      }
                      this.props.input?.onChange(
                        this.props.options?.find((opt) => opt?.value === parsedValue) ??
                          parsedValue,
                      )
                    }}
                  >
                    {[
                      { value: '', label: this.props.placeholder ?? 'Select...' },
                      ...(this.props?.options ?? []),
                    ]?.map((opt, idx) => (
                      <option key={idx + '-' + opt.value + '-' + opt.value} value={opt.value}>
                        {opt.label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          ) : null}
          {/* <Select
                        {...this.props}
                        value={this.props.input.value ? this.props.input.value : "0"}
                        onChange={value => this.props.input.onChange(value)}
                        onBlur={() => this.props.input.onBlur(this.props.input.value)}
                        options={this.props.options}
                        placeholder={this.props.placeholder}
                        selectedValue={this.props.selectedValue}
                    /> */}
          <div>
            {this.props.meta.touched &&
              ((this.props.meta.error && (
                <span className='text-danger'>{this.props.meta.error}</span>
              )) ||
                (this.props.meta.warning && <span>{this.props.meta.warning}</span>))}
          </div>
        </Fragment>
      )
    }
  }
}

export default ReactSelect
