import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { toaster } from '../services/customFunction'
import { runElasticSearch, runElasticSearchCategories } from '../utils/elasticSearch'

const defaultSuggestions = [
  'Business Services',
  'Restaurants',
  'Education',
  'Construction',
  'Automotive',
]

export const useSearchSuggestions = (initialState = { searchStr: '', searchAddress: '' }) => {
  const isSearching = useSelector((state) => state?.SearchReducer?.isSearching)
  const [searchData, setSearchData] = useState(initialState)
  const [searchingForSuggestions, setSearchingForSuggestions] = useState(false)
  const [searchSuggestions, setSearchSuggestions] = useState({ defaults: defaultSuggestions })
  const inputLengthRef = useRef(0)

  useEffect(() => {
    inputLengthRef.current = searchData?.searchStr?.length ?? 0
  }, [searchData?.searchStr])

  const searchForSuggestions = async (whileSearching) => {
    if (inputLengthRef?.current >= 2) {
      !searchingForSuggestions && setSearchingForSuggestions(true)
      const res = await runElasticSearchCategories(searchData?.searchStr)

      if ((!isSearching || whileSearching) && inputLengthRef?.current >= 2) {
        setSearchSuggestions((st) => ({
          ...st,
          defaults: [],
          categories: res ?? [],
          fetchedCategories: searchData?.searchStr,
          whileSearching,
        }))
      } else if (inputLengthRef?.current < 2) {
        setSearchingForSuggestions(false)
      }
    } else {
      setSearchingForSuggestions(false)
      setSearchSuggestions({ defaults: defaultSuggestions })
    }
  }

  useEffect(() => {
    if (!isSearching) {
      searchForSuggestions()
    } else {
      setSearchingForSuggestions(false)
      setSearchSuggestions({ defaults: defaultSuggestions })
    }
  }, [searchData?.searchStr])

  useEffect(() => {
    const fetchUserSuggestions = async () => {
      const response = await runElasticSearch(
        undefined,
        {
          search_string: searchData?.searchStr,
          // search_city_string: searchData?.searchAddress ?? '',
          maxResults: 25,
          isSuggestionSearch: true,
        },
        false,
        null,
        'suggestions',
      )

      response?.error && toaster('error', response?.error)
      response?.status !== 'CANCELLED' && setSearchingForSuggestions(false)

      if ((!isSearching || searchSuggestions?.whileSearching) && inputLengthRef?.current >= 2) {
        setSearchSuggestions((st) =>
          response?.status === 'failed' && !response?.error
            ? { defaults: defaultSuggestions }
            : response?.status === 'CANCELLED' && !response?.error
            ? { categories: st?.categories ?? [], defaults: [] }
            : {
                ...st,
                whileSearching: null,
                fetchedCategories: null,
                defaults: !response?.length && !st?.categories?.length ? defaultSuggestions : [],
                users: response ?? [],
              } ?? {
                defaults: defaultSuggestions,
              },
        )
      } else if (inputLengthRef?.current < 2) {
        setSearchingForSuggestions(false)
        setSearchSuggestions({ defaults: defaultSuggestions })
      }
    }

    !!searchSuggestions?.fetchedCategories &&
      (!isSearching || searchSuggestions?.whileSearching) &&
      fetchUserSuggestions()
  }, [searchSuggestions?.fetchedCategories])

  return {
    searchForSuggestions,
    searchingForSuggestions,
    searchSuggestions,
    setSearchSuggestions,
    inputLengthRef,
    searchData,
    setSearchData,
  }
}
