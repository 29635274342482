import React from 'react'
import { Link } from 'react-router-dom'
import { DEFAULT_PROFILE_PICTURE } from '../../../../services/const'
import { getUserHistory } from '../../../../services/customFunction'
import { LOGOUT_ROUTE, PROFILE_ROUTE } from '../../../../services/routeConst'

const SwitchAccountsModal = ({ userProfileAvatar, userFullname }) => {
  return (
    <div className='dropdown-menu show' aria-labelledby='switchMenuButton'>
      <h3>Log Into Another Account</h3>
      <p>You'll be logged out first</p>
      <div className='it-swith-img-list-data'>
        {getUserHistory()?.map?.((user, index) => (
          <div className='it-swith-img-list' key={user.id}>
            <div className={user.user_type + '_profile it-swith-img'}>
              {index !== 0 ? (
                <Link
                  to={{
                    pathname: LOGOUT_ROUTE,
                    state: {
                      email: user.email,
                      type: user.type,
                    },
                  }}
                >
                  <img
                    alt='Profile'
                    src={user.profile_img ? user.profile_img : DEFAULT_PROFILE_PICTURE}
                  />
                </Link>
              ) : (
                <Link to={PROFILE_ROUTE}>
                  <img src={userProfileAvatar} alt='' />
                </Link>
              )}
            </div>
            {index === 0 && <i className='i-green' />}
            {index !== 0 ? (
              <Link
                className='dropdown-item'
                to={{
                  pathname: LOGOUT_ROUTE,
                  state: {
                    email: user.email,
                    type: user.type,
                  },
                }}
              >
                {user.name}
              </Link>
            ) : (
              <Link className='dropdown-item' to={PROFILE_ROUTE}>
                {userFullname}
              </Link>
            )}
          </div>
        ))}
      </div>
    </div>
  )
}

export default SwitchAccountsModal
