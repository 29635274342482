import React, { useState, useEffect } from 'react'
import { DefaultPlayer as Video } from 'react-html5video'
import { DEFAULT_PROFILE_PICTURE, IMG_PATH } from '../../../services/const'
import CreatePost from '../CreatePost'
import RecreatePost from '../RecreatePost'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import SelectionModal from '../SelectionModal'
import PictureDialog from '../PictureDialog'
import ReactTooltip from 'react-tooltip'
import * as environment from '../../../services/environment'
import { toaster, setOverFlow } from '../../../services/customFunction'

import {
  dateStringToTimeSince,
  getClassNameForGrid,
  getUser,
} from '../../../services/customFunction'
import {
  commentListCall,
  commentDeleteCall,
  commentCreateCall,
  postRecreateCall,
  excludedPostCreateCall,
} from '../../../services/socialMediaApiService'
import SearchPageLoadingIcon from '../../Search/SearchPageLoadingIcon'
import Comment from '../Comment'
import NewComment from '../NewComment'
import ReactionTypes from '../ReactionTypes'
import Dialogs from '../Dialogs'
import './Post.scss'

import {
  setPostComments,
  deleteComment,
  deletePost,
  postPost,
  postComment,
  setCurrentPost,
  resetCurrentPost,
  updatePostReaction,
  setIsUpdate,
} from '../../../actions/socialmedia'

import Reactions from '../Reactions'
import ReportContent from '../ReportContent'
import { WALL_DELETE_POST } from '../../../services/const'

import SocialShowMoreText from '../SocialShowMoreText'
import { DISABLE_SOCIAL_MEDIA_REPORT } from '../../../services/environment'
import PostActions from '../PostActions/PostActions'

const Post = ({
  postId,
  user,
  deleteDBPost,
  showActionsMenu = true,
  ignoreOverFlow = false,
  ...props
}) => {
  const login_user = getUser()
  const login_user_id = login_user?.id
  let post = props?.postsData?.filter((p) => p.id === postId)[0]
  //console.log('post:', post, postId)
  //console.log(/<\/?[a-z][\s\S]*>/i.test(post?.post_body), post?.post_body)
  /*   const convertPost = /<\/?[a-z][\s\S]*>/i.test(post?.post_body)
    ? parse(post?.post_body)
    : post?.post_body */

  const [apiData, setApiData] = useState({
    currentPage: 1,
    lastPage: 1,
    order_type: 1,
  })

  const [status, setStatus] = useState('idle')
  let original_post = post?.original_post_id
    ? props?.postsData?.filter((p) => p.id === post?.original_post_id)[0]
    : null

  let main_post =
    !!post?.post_type_id && post?.post_type_id === 1
      ? props?.postsData?.filter((p) => p.id === post?.original_post_id)[0]
      : props?.postsData?.filter((p) => p.id === postId)[0]

  //console.log('post:', post, original_post, main_post)
  const [getComments, setGetComments] = useState(true)
  const [addComment, setaddComment] = useState(false)
  const [loginUserReaction, setLoginUserReaction] = useState(
    main_post?.reaction_data?.relevant_reactions?.your_reaction,
  )
  const [showReactionsModal, setShowReactionsModal] = useState(false)
  const [showCreatePostModal, setShowCreatePostModal] = useState(false)
  const [showReportDataModal, setShowReportDataModal] = useState(false)
  const [showDeletePostDialog, setShowDeletePostDialog] = useState(false)
  const [showCommentOnSelection, setShowCommentOnSelection] = useState(false)
  const [showSeeSelection, setShowSeeSelection] = useState(false)
  const [isLoggedUser, setIsLoggedUser] = useState(login_user_id !== undefined)

  const [loginModalProps, setLoginModalProps] = useState(
    !isLoggedUser
      ? {
          'data-bs-toggle': 'modal',
          'data-bs-target': '#login_modal',
        }
      : {},
  )
  const [showPictureDialog, setShowPictureDialog] = useState(false)
  const [fileIndex, setFileIndex] = useState(0)

  const [showRecreatePostModal, setShowRecreatePostModal] = useState(false)

  useEffect(() => {
    //console.log('useeffects postid:', post?.id)
    setLoginModalProps(
      login_user_id === undefined
        ? {
            'data-bs-toggle': 'modal',
            'data-bs-target': '#login_modal',
          }
        : {},
    )
    setIsLoggedUser(Object.keys(loginModalProps)?.length === 0)
    //setMainPost(!!post?.post_type_id && post?.post_type_id === 1 ? original_post : post)
    setLoginUserReaction(main_post?.reaction_data?.relevant_reactions?.your_reaction)
  }, [original_post, main_post, login_user_id])
  const deleteComment = async (id) => {
    setStatus('loading')
    const response = await commentDeleteCall({
      id: id,
    })
    if (response?.status === 200) {
      props?.deleteComment(response?.data?.data)
    }
    setStatus('idle')
  }
  const editPost = () => {
    props?.resetCurrentPost()
    props?.setIsUpdate(true)
    props?.setCurrentPost(post?.id)
    setShowCreatePostModal(true)
  }
  const recreatePost = () => {
    props?.resetCurrentPost()
    props?.setCurrentPost(main_post?.id)
    setShowRecreatePostModal(true)
  }
  const onCloseCreatePostButtonClick = () => {
    setShowCreatePostModal(false)
    setShowRecreatePostModal(false)
  }
  const onCloseReactionsButtonClick = () => {
    setShowReactionsModal(false)
  }
  const onReactionDialogButtonClick = () => {
    setShowReactionsModal(true)
  }
  const onOpenReportContentButtonClick = () => {
    setShowReportDataModal(true)
  }
  const onCloseReportContentButtonClick = () => {
    setShowReportDataModal(false)
  }
  const onClickCommentSelection = () => {
    props?.resetCurrentPost()
    props?.setCurrentPost(post?.id)
    setShowCommentOnSelection(true)
  }
  const onClickSeeSelection = () => {
    props?.resetCurrentPost()
    props?.setCurrentPost(post?.id)
    setShowSeeSelection(true)
  }
  const onCloseSelection = () => {
    setShowSeeSelection(false)
    setShowCommentOnSelection(false)
    props?.resetCurrentPost()
  }
  //Attachements GRID(picture/video)
  const renderPostDetails = (files) => {
    if (files?.length < 1 || !showActionsMenu) return ''
    if (files?.length === 1 && files[0].file_type === 'video')
      return (
        <div className='postVidContainer'>
          <Video
            controls={['PlayPause', 'Seek', 'Time', 'Volume', 'Fullscreen']}
            onCanPlayThrough={() => {
              // Do stuff
            }}
          >
            <source src={files[0].vidSrc} type='video/mp4' />
          </Video>
        </div>
      )
    else
      return (
        <div className='postImgContainer'>
          <div className='postImages'>
            {files
              ? files.map((file, index) => {
                  let className = getClassNameForGrid(files?.length, index)
                  return index < 5 ? (
                    <img
                      className={className}
                      src={file.imgSrc}
                      alt=''
                      onClick={handlePictureDialogButtonClick(file, index)}
                    />
                  ) : (
                    ''
                  )
                })
              : ''}
          </div>
          {files?.length - 5 > 0 ? <span className='postImgOver'>+{files?.length - 5}</span> : ''}
        </div>
      )
  }
  const getCommentsCall = () => {
    if (getComments) commentsListRender()
  }

  //comment API's calls
  const commentsListRender = React.useCallback(async () => {
    setStatus('loading')
    let request = {
      //user_id: user.id
      post_id: main_post?.id,
      page: apiData?.currentPage,
      login_user_id,
    }

    const response = await commentListCall(request)
    if (response?.status === 200) {
      props?.setPostComments({
        id: main_post.id,
        comments: response?.data?.data?.comments,
      })
      setApiData((st) => ({
        currentPage: response?.data?.data?.current_page + 1,
        lastPage: response?.data?.data?.last_page,
      }))
    }
    setGetComments(false)
    setStatus('idle')
  }, [apiData?.currentPage, post?.id, login_user_id])

  //click comment Handler
  const clickCommentHandler = (event) => {
    //console.log('commentClicked')
    setaddComment(true)
    getCommentsCall() //don't reload if already loaded
  }

  const addCommentPostHandler = async (comment_body) => {
    let request = {
      comment_body: comment_body,
      post_id: main_post?.id,
    }

    let response = await commentCreateCall(request)
    if (response?.status === 200) {
      //console.log('addCommentPostHandler:', response?.data?.data)
      props?.postComment(response?.data?.data)
      setApiData((st) => ({
        currentPage: st.currentPage,
        lastPage: st.lastPage,
      }))
      setaddComment(false)
    }
  }
  const cancelSubmitHandler = () => {
    setaddComment(false)
  }
  const sendHandler = (event) => {
    //todo
  }
  const repostHandler = async (event) => {
    let request = {
      user_id: login_user_id,
      original_post_id: main_post?.id,
      post_type_id: 1,
    }

    let response = await postRecreateCall(request)
    if (response?.status === 200) {
      props?.postPost(response.data.data)
      toaster('success', response.data.message)
    }
  }

  const dontSeeHandler = async (event) => {
    let request = {
      user_id: login_user_id,
      post_id: post?.id,
    }

    let response = await excludedPostCreateCall(request)
    if (response?.status === 200) {
      props?.deletePost(post?.id)
      toaster('success', response.data.message)
    }
  }
  const request = {
    user_id: login_user_id,
    entry_id: main_post?.id,
    entry_type: 'post',
  }

  const clickReactionHandler = async (data) => {
    props?.updatePostReaction({ id: main_post?.id, data })
    setLoginUserReaction(main_post?.reaction_data?.relevant_reactions?.your_reaction)
  }
  const onNoModifyPostDialog = () => {
    setShowDeletePostDialog(false)
  }

  const onYesModifyPostDialog = () => {
    deleteDBPost(post?.id)

    setShowDeletePostDialog(false)
  }
  const onOpenDeleteButtonClick = () => {
    setShowDeletePostDialog(true)
  }
  const onClosePictureDialogButtonClick = () => {
    setShowPictureDialog(false)
  }
  const handlePictureDialogButtonClick = (file, index) => (event) => {
    setFileIndex(index)
    setShowPictureDialog(true)
  }

  const scrollToTop = () =>
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  const copyToClipboard = () => {
    const shareLink = environment.SITE_ADDRESS + post?.user_details?.slug + '/posts/' + post?.id
    var textField = document.createElement('textarea')
    textField.className = 'it-ie-invite-copy-box'
    textField.value = shareLink
    document.body.appendChild(textField)
    textField.select()
    document.execCommand('copy')
    if (textField) {
      textField.remove()
    }
    toaster('success', 'Copied successfully')
  }
  const postHeader = (post, menu_action = true, big_picture = true) => {
    let img_blockClassname = big_picture
      ? 'img_block orange_border'
      : 'img_block_medium orange_border'

    return (
      <div className='postTop'>
        {post?.post_type_id == '1' ? (
          <div className='postTopLeft'>
            <div className='wallFlexColumnLeft overFlowHidden'>
              <a
                className='postUsername postUsernameSmall'
                href={process.env.PUBLIC_URL + '/' + post?.user_details?.slug}
              >
                {post?.user_details?.fullname} reposted this
              </a>
              {/*              <div className='postDate'>
                {dateStringToTimeSince(post?.shared_updated_at, true)?.replace('ago', '')}
                {post?.created_at === post?.updated_at ? '' : 'Edited'}
              </div> */}
            </div>
          </div>
        ) : (
          <div className='postTopLeft'>
            <a
              href={process.env.PUBLIC_URL + '/' + post?.user_details?.slug}
              className='postSmallPicture'
            >
              <img
                className={img_blockClassname}
                src={post?.user_details?.profile_picture_small ?? DEFAULT_PROFILE_PICTURE}
                alt=''
              />
            </a>
            <div className='wallFlexColumnLeft overFlowHidden'>
              <a
                className='postUsername'
                href={process.env.PUBLIC_URL + '/' + post?.user_details?.slug}
              >
                {post?.user_details?.fullname}
              </a>
              <div className='postDate'>
                {dateStringToTimeSince(post?.updated_at, true)?.replace('ago', '')}
                {post?.created_at === post?.updated_at ? '' : 'Edited'}
              </div>
            </div>
          </div>
        )}
        {menu_action && (
          <PostActions
            post={post}
            copyToClipboard={copyToClipboard}
            onOpenReportContentButtonClick={onOpenReportContentButtonClick}
            dontSeeHandler={dontSeeHandler}
            onOpenDeleteButtonClick={onOpenDeleteButtonClick}
            onClickCommentSelection={onClickCommentSelection}
            onClickSeeSelection={onClickSeeSelection}
            editPost={editPost}
          ></PostActions>
        )}
      </div>
    )
  }

  let total_comments = main_post?.total_comments
  let total_shared = main_post?.total_shared
  !ignoreOverFlow &&
    setOverFlow(
      showReactionsModal ||
        showReportDataModal ||
        showCreatePostModal ||
        showDeletePostDialog ||
        showSeeSelection ||
        showCommentOnSelection ||
        showPictureDialog ||
        showRecreatePostModal,
    )
  let reduceFullName =
    post?.user_details?.user_type === 'individual'
      ? post?.user_details?.firstname
      : post?.user_details?.fullname
  //setIsActionsMenu(sharingUser ? false : showActionsMenu)
  return (
    <>
      <article className='postMain'>
        <div className='postWrapper'>
          {postHeader(post, showActionsMenu)}
          {post?.post_type_id !== 1 && (
            <div className='postCenter'>
              <SocialShowMoreText textToShow={post?.post_body}></SocialShowMoreText>
              {renderPostDetails(post?.attachments)}
            </div>
          )}
          {original_post && (
            <div className='postCenterOriginal'>
              {postHeader(original_post, false, false)}
              <div className='postCenter'>
                <SocialShowMoreText textToShow={original_post?.post_body}></SocialShowMoreText>
                {renderPostDetails(original_post?.attachments)}
              </div>
            </div>
          )}
          <div className='postBottomAll'>
            <div className='postBottom'>
              <button
                {...loginModalProps}
                className='postButton'
                type='button'
                onClick={() => {
                  isLoggedUser && onReactionDialogButtonClick()
                }}
              >
                {main_post?.reaction_data?.reaction_totals?.map((r) => {
                  let img = IMG_PATH + r.icon
                  return <img src={img} className='likeSmallIcon link ' alt='' />
                })}
                {main_post?.total_reactions > 0 ? (
                  <span className='postLikeCounter'>{main_post?.total_reactions}</span>
                ) : (
                  ''
                )}
              </button>
              <div className='postBottomRight'>
                {total_comments > 0 ? (
                  <button
                    className='postButton'
                    {...loginModalProps}
                    onClick={isLoggedUser && getCommentsCall}
                  >
                    <span className='postCommentText'>
                      {total_comments} comment{total_comments > 1 ? 's' : ''}
                    </span>{' '}
                  </button>
                ) : (
                  <div></div>
                )}

                {total_shared > 0 && (
                  <span className='postCommentText'>
                    {total_shared} share{total_shared > 1 ? 's' : ''}
                  </span>
                )}
              </div>
            </div>
            {/*console.log('loginUserReaction:', post?.id, main_post?.id, main_post?.reaction_data)*/}
            <div className='postFooter'>
              <ReactionTypes
                handleReaction={clickReactionHandler}
                loginUserReaction={loginUserReaction}
                loginModalProps={loginModalProps}
                reactionData={main_post?.reaction_data?.relevant_reactions?.your_reaction}
                request={request}
              />
              <button
                className='postButtonColumn'
                {...loginModalProps}
                onClick={() => isLoggedUser && clickCommentHandler()}
                disabled={main_post?.comment_type_id === 3}
              >
                <img src={IMG_PATH + 'comment.svg'} className='likeIcon link' alt='' />
                <span className='buttonSpan'>Comment</span>
              </button>
              {login_user_id !== post?.user_id && (
                <>
                  <div class='dropdown'>
                    <button
                      class='btn dropdown-toggle  postButtonColumn'
                      type='button'
                      id='dropdownShare'
                      title=' Share this post'
                      data-bs-toggle='dropdown'
                      aria-expanded='false'
                      {...loginModalProps}
                      //data-tip={'Coming soon '}
                      data-for='postButtons'
                      disabled={post?.share_type_id === 3}
                    >
                      <img src={IMG_PATH + 'share.svg'} className='likeIcon link' alt='' />
                      <span className='buttonSpan'>Share</span>
                    </button>
                    <ul class='dropdown-menu' aria-labelledby='dropdownShare'>
                      <li className='repostItem'>
                        <button
                          class='dropdown-item repostButton'
                          {...loginModalProps}
                          onClick={() => isLoggedUser && repostHandler()}
                          disabled={DISABLE_SOCIAL_MEDIA_REPORT}
                        >
                          <img src={IMG_PATH + 'share.svg'} className='likeIcon link' alt='' />
                          <div className='repostText'>
                            <div>Repost</div>
                            <div className='repostSubtitle'>
                              Instantly bring {reduceFullName}'s to others feed
                            </div>
                          </div>
                        </button>
                      </li>
                      <li>
                        <button
                          class='dropdown-item repostButton'
                          {...loginModalProps}
                          onClick={() => isLoggedUser && recreatePost()}
                          disabled={DISABLE_SOCIAL_MEDIA_REPORT}
                        >
                          <img
                            src={IMG_PATH + 'sharenewpost.svg'}
                            className='likeIcon link'
                            alt=''
                          />
                          <div className='repostText'>
                            <div>Repost with your thoughts</div>
                            <div className='repostSubtitle'>
                              Create a new post with {reduceFullName}'s attached
                            </div>
                          </div>
                        </button>
                      </li>
                    </ul>
                  </div>
                  <button
                    className='postButtonColumn'
                    {...loginModalProps}
                    //data-tip={'Coming soon '}
                    data-for='postButtons'
                    onClick={() => isLoggedUser && sendHandler()}
                  >
                    <img src={IMG_PATH + 'send.svg'} className='likeIcon link' alt='' />
                    <span className='buttonSpan'>Send</span>
                  </button>
                </>
              )}
            </div>
          </div>
          <div>
            {addComment === true ? (
              <NewComment
                id={main_post}
                submitBtnText='Post'
                addHandler={addCommentPostHandler}
                cancelSubmitHandler={cancelSubmitHandler}
              />
            ) : (
              ''
            )}
          </div>
          <div>
            {main_post?.comments?.map((comment) => (
              <Comment
                key={comment.id}
                entry={comment}
                login_user_id={login_user_id}
                post={main_post}
                deleteFunc={deleteComment}
              />
            ))}
          </div>
          <div className='d-flex load-more-btns'>
            {status === 'loading' ? (
              <SearchPageLoadingIcon
                className='pagination-loading'
                styles={{
                  height: 'auto',
                  marginTop: main_post?.comments?.length === 0 ? 30 : 0,
                }}
                iconStyles={{ height: 38, transform: 'scale(1.5)' }}
              />
            ) : apiData?.currentPage <= apiData?.lastPage && apiData?.lastPage > 1 ? (
              <button
                {...loginModalProps}
                className='btn mx-auto'
                onClick={() => isLoggedUser && commentsListRender()}
              >
                Load more comments
              </button>
            ) : apiData?.postsList?.length > 0 ? (
              <button className='btn mx-auto text-muted back-to-top' onClick={scrollToTop}>
                Back to top
              </button>
            ) : null}
          </div>
        </div>
        <ReactTooltip
          //data-class='reactionButton'
          id='postButtons'
          type='dark'
          effect='float'
        />
        {showReactionsModal && (
          <Reactions request={request} onCloseButtonClick={onCloseReactionsButtonClick} />
        )}
        {showReportDataModal && (
          <ReportContent request={request} onCloseButtonClick={onCloseReportContentButtonClick} />
        )}
        {showCreatePostModal && <CreatePost onCloseButtonClick={onCloseCreatePostButtonClick} />}
        {showRecreatePostModal && (
          <RecreatePost onCloseButtonClick={onCloseCreatePostButtonClick} />
        )}

        {showDeletePostDialog && (
          <Dialogs
            title={'Delete post'}
            question={WALL_DELETE_POST}
            onYesCloseDialog={onYesModifyPostDialog}
            onNoCloseDialog={onNoModifyPostDialog}
          />
        )}
        {showSeeSelection && (
          <SelectionModal title='see' save={true} onCloseSelection={onCloseSelection} />
        )}
        {showCommentOnSelection && (
          <SelectionModal title='comment on' save={true} onCloseSelection={onCloseSelection} />
        )}
        {showPictureDialog && (
          <PictureDialog
            fileIndex={fileIndex}
            user={user}
            post={original_post ? original_post : post}
            onCloseButtonClick={onClosePictureDialogButtonClick}
          />
        )}
      </article>
    </>
  )
}
function mapStateToProps(state) {
  return {
    postsData: state.SocialMediaReducer.posts,
  }
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setPostComments,
      deleteComment,
      deletePost,
      postPost,
      postComment,
      setCurrentPost,
      resetCurrentPost,
      updatePostReaction,
      setIsUpdate,
    },
    dispatch,
  )
}
export default connect(mapStateToProps, mapDispatchToProps)(Post)
