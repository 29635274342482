import { parseInt } from 'lodash'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  updateCommentType,
  updateShareType,
  updatePostSettings,
} from '../../../actions/socialmedia'
import { IMG_PATH } from '../../../services/const'
import { postUpdateSettingsCall } from '../../../services/socialMediaApiService'
import SearchPageLoadingIcon from '../../Search/SearchPageLoadingIcon'
import './SelectionModal.scss'

const SelectionModal = ({ title, onCloseSelection, save, ...props }) => {
  const [selectedValue, setSelectedValue] = useState(
    title === 'see' ? props?.postData?.share_type_id : props?.postData?.comment_type_id,
  )
  const [status, setStatus] = useState('idle')
  const choices = title === 'see' ? props?.metadata?.share_types : props?.metadata?.comment_types

  const submitBtnText = 'Save'
  const cancelBtntext = 'Back'

  const changeSelected = (event) => {
    let value = event?.target?.value
    if (typeof event?.target?.value) value = parseInt(event?.target?.value)
    setSelectedValue(value)
  }
  const onSaveHandler = async (event) => {
    event.preventDefault()
    title === 'see'
      ? props?.updateShareType(selectedValue)
      : props?.updateCommentType(selectedValue)
    setStatus('loading')
    if (save) {
      let request = {
        id: props?.postData?.id,
        group_id: props?.postData?.group_id,
      }
      request.share_type_id = title === 'see' ? selectedValue : props?.postData?.share_type_id
      request.comment_type_id = title === 'see' ? props?.postData?.comment_type_id : selectedValue
      const response = await postUpdateSettingsCall(request)
      if (response?.status === 200) props?.updatePostSettings(response?.data?.data)
    }
    setStatus('idle')
    onCloseSelection()
  }

  return (
    <div
      className='modal selection_modal login_blk overlay-top-layer '
      id='wall_selection_modal'
      role='dialog'
    >
      <form className='selectionModal_form' onSubmit={onSaveHandler} id='selectionForm'>
        <div className='inner-tab tab-content it-mobile-space'>
          {' '}
          <div className='modal-dialog'>
            <div className='modal-content'>
              <div className='modal-header'>
                <h5>Who can {title} your post</h5>
                <button type='button' className='btn-close' onClick={onCloseSelection}></button>
              </div>
              <div className='modal-body d-flex flex-column justify-content-between'>
                {choices?.map((selection, index) => {
                  return (
                    <div class='radio radio-danger body_button selectionbutton'>
                      <label className='body_img' for={index}>
                        <img src={IMG_PATH + selection.icon} className='likeIcon link' alt='' />
                        <span>{selection.type}</span>
                      </label>
                      <input
                        type='radio'
                        name='selection'
                        id={index}
                        value={selection.id}
                        checked={selectedValue == selection.id} //intentionally done for "1" or 1
                        onClick={changeSelected}
                      ></input>
                    </div>
                  )
                })}
              </div>
              <div className='modal-footer justify-content-end'>
                {status === 'loading' ? (
                  <SearchPageLoadingIcon
                    className='pagination-loading'
                    styles={{
                      height: 'auto',
                    }}
                    iconStyles={{ height: 38, transform: 'scale(1.5)' }}
                  />
                ) : (
                  <>
                    <button type='button' class='btn btn-orange' onClick={onCloseSelection}>
                      {cancelBtntext}
                    </button>
                    <button type='submit' class='btn btn btn-primary'>
                      {submitBtnText}
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}
function mapStateToProps(state) {
  return {
    postData: state.SocialMediaReducer.post,
    metadata: state.SocialMediaReducer.metadata,
  }
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateCommentType,
      updateShareType,
      updatePostSettings,
    },
    dispatch,
  )
}
export default connect(mapStateToProps, mapDispatchToProps)(SelectionModal)
