import React from 'react'
import Skeleton from 'react-loading-skeleton'

const ReviewStats = ({ review }) => {
  return (
    <ul>
      <li className='friends_review'>
        {review?.id ? `${review?.total_friends} friends` : <Skeleton width='32px' />}
      </li>

      <li className='star_review'>
        {review?.id ? `${review?.total_reviews} reviews` : <Skeleton width='24px' />}
      </li>

      <li className='trust_review'>
        {review?.id ? `${review?.total_trusted} iTRUST` : <Skeleton width='24px' />}
      </li>
    </ul>
  )
}

export default ReviewStats
