import React, { Component, Fragment, useEffect, useState } from 'react'

import * as activityApiService from '../../../services/activityApiService'
import {
  NOTIFICATION_ITEMS_PER_PAGE,
  NOTIFICATION_PAGE_RANGE,
  DEFAULT_PROFILE_PICTURE,
} from '../../../services/const'
import { getUser } from '../../../services/customFunction'
import Pagination from 'react-js-pagination'
import { Link } from 'react-router-dom'
// import * as route from "../../../services/routeConst";
import * as customFunction from '../../../services/customFunction'
import NotificationSection from './NotificationSection'
import $ from 'jquery'
import { isMobile } from 'react-device-detect'
//import ActivityListDetail from "../../CommonComponents/ActivityListDetail/ActivityListDetail";

const NotificationsTab = (props) => {
  const [stateData, setStateData] = useState({
    login_user_id: getUser().id,
    section_wise_notifications: [],
    no_notifications_message: 'Loading...',
  })

  useEffect(() => {
    notificationsListRender()
    window.scrollTo({
      top: $('#notifications').offset().top - 125,
      behavior: 'smooth',
    })
  }, [])

  const notificationsListRender = async () => {
    const response = await activityApiService.sectionWiseNotificationListCall()
    if (
      response &&
      typeof response !== 'undefined' &&
      typeof response.status !== 'undefined' &&
      response.status === 200
    ) {
      let apiResponseData = response.data.data

      let data = {
        section_wise_notifications: apiResponseData,
      }
      setStateData((st) => ({ ...st, ...data, clearingAll: false }))
    } else if (response.status === 201) {
      setStateData((st) => ({
        ...st,
        section_wise_notifications: response?.data?.data,
        no_notifications_message: 'No Notifications',
        clearingAll: false,
      }))
    }
  }

  const profileHeader_notifications_tab_click_Handle = () => {
    notificationsListRender()
  }

  const clearAllNotifications = async () => {
    setStateData((st) => ({ ...st, clearingAll: true }))
    const response = await activityApiService.deleteNotifications()
    if (
      response &&
      typeof response !== 'undefined' &&
      typeof response.status !== 'undefined' &&
      response.status === 200
    ) {
      notificationsListRender()
    }
  }

  const { section_wise_notifications } = stateData

  return (
    <div
      id='notifications'
      className={
        'it-profile-noti tab-pane ' + (props.activeTab === '#notifications' ? 'active' : 'fade')
      }
    >
      <div className='d-flex justify-content-between align-items-center mb-1'>
        <h4 className='varela mb-0'>Recent Notifications</h4>
        <button
          className='btn it-ok-btn'
          onClick={clearAllNotifications}
          disabled={stateData?.clearingAll || section_wise_notifications?.length <= 0}
          style={{
            cursor:
              stateData?.clearingAll || section_wise_notifications?.length <= 0
                ? 'not-allowed'
                : 'pointer',
            pointerEvents: 'initial',
          }}
        >
          Clear{isMobile ? '' : ' All'}
        </button>
      </div>
      <div className='bg-white'>
        <div className='activity_blk'>
          {section_wise_notifications.length > 0 ? (
            section_wise_notifications.map((section_data, index) => {
              return (
                <div key={section_data.section}>
                  <NotificationSection sectionData={section_data} />
                </div>
              )
            })
          ) : (
            <li>
              {/* No Notifications */}
              {stateData.no_notifications_message}
            </li>
            // <div className="search-section">
            //     <div className="no_search_result">
            //         <img src={process.env.PUBLIC_URL + "/img/no_reviews.png"} className="pt-5 pb-5 m-auto d-block"  alt="" />
            //     </div>
            // </div>
          )}
        </div>
      </div>
    </div>
  )
}

NotificationsTab.defaultProps = {
  itemsCountPerPage: NOTIFICATION_ITEMS_PER_PAGE,
  pageRangeDisplayed: NOTIFICATION_PAGE_RANGE,
}

export default NotificationsTab
