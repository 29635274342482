import React from 'react'
import { useLinkedIn } from 'react-linkedin-login-oauth2'
import { LINKEDIN_CLIENT_ID } from '../../services/environment'

const LinkedInLogin = ({ linkedInLoginAction, preText }) => {
  const { linkedInLogin } = useLinkedIn({
    clientId: LINKEDIN_CLIENT_ID,
    redirectUri: `${window.location.origin}/linkedin-redirect`,
    scope: 'r_liteprofile r_emailaddress',
    onSuccess: (code) => {
      linkedInLoginAction({
        code,
        redirect_uri: `${window.location.origin}/linkedin-redirect`,
        login_type: 'linkedin',
      })
    },
    onError: (error) => {
      if (error?.error !== 'user_closed_popup') {
        console.log(error)
      }
    },
  })

  return (
    <button
      onClick={linkedInLogin}
      style={{
        backgroundColor: 'rgb(255, 255, 255)',
        display: 'inline-flex',
        alignItems: 'center',
        color: 'rgba(0, 0, 0, 0.54)',
        boxShadow: 'rgb(0 0 0 / 24%) 0px 2px 2px 0px, rgb(0 0 0 / 24%) 0px 0px 1px 0px',
        padding: 0,
        border: '1px solid #cbd4db',
        fontSize: 14,
        fontWeight: 500,
        fontFamily: "'Roboto', 'sans-serif'",
        width: '100%',
        margin: '0 0 12px',
        borderRadius: 30,
      }}
      className='apple-auth-btn'
    >
      <img
        width={24}
        src={process.env.PUBLIC_URL + '/img/linkedin-logo.svg'}
        alt='Continue with LinkedIn'
        style={{
          marginRight: 8,
          background: 'rgb(255, 255, 255)',
          marginLeft: 18,
        }}
      />
      <span style={{ padding: '10px 10px 10px 0px', fontWeight: 500 }}>
        {preText} with LinkedIn
      </span>
    </button>
  )
}

export default LinkedInLogin
