import axios from 'axios'

import {
  ELASTIC_SEARCH_BASE_URL,
  ELASTIC_SEARCH_TYPE,
  ELASTIC_SEARCH_USERS,
  ELASTIC_SEARCH_CATEGORIES,
  GOOGLE_GEOCODING_API_KEY,
} from '../services/environment'
import { customLogger } from './CustomLogger'

let cancelToken
let cancelTokenSug
let cancelTokenCat

export const runElasticSearchCategories = async (searchStr, callback) => {
  if (searchStr.length >= 2) {
    searchStr = searchStr.replace(/([\!\*\+\&\|\(\)\[\]\{\}\^\~\?\:\-\_\"])/g, ' ')
    searchStr = searchStr.replace(/  +/g, ' ')
    searchStr = searchStr.replace(/[\u2018\u2019]/g, "'").replace(/[\u201C\u201D]/g, '"')
    searchStr = searchStr.replace(/(')\1{1,}/, "'")
    searchStr = searchStr.replace(/(")\1{1,}/, `"`)
    searchStr = searchStr.replaceAll('/', '')
    searchStr = searchStr.replaceAll('>>', '')
    searchStr = searchStr.trim()

    var queryString = ''
    var searchStringArray = searchStr?.split?.(' ')
    var searchStringArrayLength = searchStringArray.length

    for (var i = 0; i < searchStringArrayLength; i++) {
      queryString += '(*' + searchStringArray[i] + '*)'
      if (i < searchStringArrayLength - 1) {
        queryString += ' AND ' // AND OR
      }
    }

    var postData = {
      query: {
        bool: {
          must: [
            {
              query_string: {
                query: queryString,
                fields: ['name'],
                // rewrite: 'top_terms_5',
              },
            },
          ],
        },
      },
      size: 5,
      sort: {
        _score: { order: 'desc' },
      },
      highlight: {
        pre_tags: ['<strong>'],
        post_tags: ['</strong>'],
        fields: {
          name: {},
        },
      },
    }

    var elastic_search_api =
      ELASTIC_SEARCH_BASE_URL + ELASTIC_SEARCH_CATEGORIES + '/_search?pretty=true'

    if (typeof cancelTokenCat != typeof undefined && cancelTokenCat?.source === 'categories') {
      cancelTokenCat.cancel('CANCELLED')
    }

    cancelTokenCat = axios.CancelToken.source()
    cancelTokenCat.source = 'categories'
    try {
      const response = await axios.post(elastic_search_api, postData, {
        headers: { 'Content-Type': 'application/json' },
        cancelToken: cancelTokenCat?.token,
      })
      if (response?.status === 200) {
        if (callback) {
          callback?.(response.data.hits.hits)
        } else {
          return response?.data?.hits?.hits
        }
      } else callback?.()
    } catch (error) {
      customLogger(error)
    }
  } else {
    callback?.([])
  }
}

export const getGeometryFromSearchAddress = async (searchAddress, useComponentQuery = true) => {
  let query = `address=${searchAddress}`
  if (searchAddress.length === 2 && useComponentQuery) {
    query = `components=administrative_area:${searchAddress}|country:US`
  }
  let GEOCODING_API_URL = `https://maps.googleapis.com/maps/api/geocode/json?${query}&key=${GOOGLE_GEOCODING_API_KEY}`
  try {
    let res = await axios.get(GEOCODING_API_URL)
    if (searchAddress.length === 2 && useComponentQuery && res?.data?.results?.length === 0) {
      return await getGeometryFromSearchAddress(searchAddress, false)
    }
    let results =
      res?.data?.results?.filter((address) =>
        address.types.some(
          (r) =>
            [
              'neighborhood',
              'locality',
              'political',
              'route',
              'country',
              'administrative_area_level_1',
              'sublocality',
              'postal_code',
            ].indexOf(r) >= 0,
        ),
      ) ?? []
    if (!results.length) {
      results = res.data.results
    }
    return results && results[0] && results[0].geometry && results[0]
  } catch (error) {
    customLogger(error)
  }
}

export const runElasticSearch = async (
  runUpdateFunction,
  request,
  canRetry = false,
  previousData,
  source = 'main-search',
) => {
  let searchStr = request.search_string
  const {
    search_city_string = '',
    category_id = '',
    category_name = '',
    sort_order = 'asc',
    user_type = '',
    extraMust,
    must_not = '',
    maxResults = 700,
    errorOnLocationFail = true,
    searchDistance = '2mi',
  } = request
  console.log('runElasticSearch', searchStr)
  if (searchStr.length >= 2) {
    const queryString = parseSearchString(searchStr)

    let postData = {
      query: {
        bool: {
          must: [
            {
              query_string: {
                query: queryString,
                fields: [
                  'fullname',
                  'name',
                  'slug',
                  'business_organization_name',
                  'categories.category_name',
                ],
                // rewrite: `top_terms_${maxResults}`, //to allow results to have scores when using wildcard queries that use constant_scoring
              },
            },
          ],
        },
      },
      size: maxResults,
      sort: {
        _score: {
          order: sort_order ?? 'desc',
        },
        sortname: {
          order: sort_order ?? 'asc',
        },
      },
      highlight: {
        pre_tags: ['<strong>'],
        post_tags: ['</strong>'],
        fields: {
          name: {},
        },
      },
    }
    if (!!category_id) {
      postData.query.bool = {
        must: [
          {
            bool: {
              should: [
                {
                  match: { 'categories.category_name': category_name },
                },
              ],
              minimum_should_match: 1,
            },
          },
        ],
        should: [
          {
            match: { 'categories.search_id': category_id },
          },
        ],
      }
    }
    let potentialZipCode

    if (!!search_city_string && search_city_string !== 'Global') {
      const locationDetails = await getGeometryFromSearchAddress(search_city_string)
      if ((!locationDetails?.types || !locationDetails?.geometry) && errorOnLocationFail) {
        return { error: 'Invalid location specified.' }
      }
      const { types } = locationDetails
      const { lat, lng } = locationDetails.geometry.location

      potentialZipCode = locationDetails?.address_components?.find((address) =>
        address.types.includes('postal_code'),
      )?.long_name

      const { northeast, southwest } =
        locationDetails.geometry.bounds || locationDetails.geometry.viewport
      const { formatted_address } = locationDetails

      runUpdateFunction?.(formatted_address)

      let filterObj
      let shouldObj
      let locMatch = false
      if (types[0] === 'administrative_area_level_1' && locationDetails.address_components[0]) {
        locMatch = true
        shouldObj = {
          match: {
            state: {
              query: locationDetails.address_components[0].long_name,
              operator: 'and',
            },
          },
        }
      } else if (types[0] === 'country' && locationDetails.address_components[0]) {
        locMatch = true
        shouldObj = {
          match: {
            country: {
              query: locationDetails.address_components[0].long_name,
              operator: 'and',
            },
          },
        }
      } else if (types[0] === 'postal_code' && locationDetails.address_components[0]) {
        locMatch = true
        shouldObj = {
          match: {
            zipcode: {
              query: locationDetails.address_components[0].long_name,
              operator: 'and',
            },
          },
        }
      } else {
        filterObj = {
          // geo_distance: {
          //   distance: distances[types[0] || 'locality'] || '75km',
          //   loc: {
          //     lat,
          //     lon: lng,
          //   },
          // },
          geo_bounding_box: {
            loc: {
              top_right: [northeast.lng + 0.2, northeast.lat + 0.2],
              bottom_left: [southwest.lng - 0.2, southwest.lat - 0.2],
            },
          },
        }
        postData.query.bool.filter
          ? postData.query.bool.filter.push(filterObj)
          : (postData.query.bool = {
              ...postData.query.bool,
              filter: [filterObj],
            })
      }

      if (!!shouldObj) {
        postData.query.bool = {
          ...postData.query.bool,
          should: shouldObj,
        }
      }
      // if (!locMatch) {
      postData.query.bool.filter = [
        ...(postData?.query?.bool?.filter ?? []),
        {
          geo_distance: {
            distance: searchDistance?.value ?? '2mi',
            loc: {
              lat,
              lon: lng,
            },
          },
        },
      ]
      // }

      postData.sort = {
        _score: {
          order: sort_order ?? 'desc',
        },
        _geo_distance: {
          loc: {
            lat,
            lon: lng,
          },
          order: 'desc',
          unit: 'km',
          mode: 'min',
          distance_type: 'arc',
          ignore_unmapped: true,
        },
      }
    }
    if (!!user_type) {
      let filterByTypeObj = { term: { user_type } }
      // console.log(postData);
      postData.query.bool.filter
        ? postData.query.bool.filter.push(filterByTypeObj)
        : (postData.query.bool = {
            ...postData.query.bool,
            filter: [filterByTypeObj],
          })
    }
    if (!!must_not) {
      postData.query.bool.must_not = must_not
    }
    if (!!extraMust) {
      postData.query.bool.must = [...postData.query.bool.must, extraMust]
    }
    var elastic_search_api =
      ELASTIC_SEARCH_BASE_URL +
      ELASTIC_SEARCH_USERS +
      '/' +
      ELASTIC_SEARCH_TYPE +
      '/_search?pretty=true'

    if (typeof cancelToken != typeof undefined && cancelToken?.source === source) {
      cancelToken.cancel('CANCELLED')
    }

    cancelToken = axios.CancelToken.source()
    cancelTokenSug = axios.CancelToken.source()
    cancelToken.source = source
    try {
      const response = await axios.post(elastic_search_api, postData, {
        headers: { 'Content-Type': 'application/json' },
        cancelToken: cancelToken?.token,
      })
      if (response?.status === 200) {
        let results
        if (previousData?.length > 0) {
          let mergeData = previousData.concat(response.data.hits.hits)
          mergeData = mergeData.filter(
            (thing, index, self) =>
              self.findIndex(
                (t) =>
                  t['_source'].id === thing['_source'].id &&
                  t['_source'].name === thing['_source'].name,
              ) === index,
          )
          results = mergeData
        } else results = response?.data?.hits?.hits
        return canRetry && potentialZipCode && !results.length ? { potentialZipCode } : results
      } else return { status: 'failed' }
    } catch (error) {
      customLogger(error)
      return { status: error.message === 'CANCELLED' ? 'CANCELLED' : 'failed' }
    }
  } else {
    return { status: 'failed' }
  }
}

export const runElasticSearchUsers = async ({ request, previousData, source = 'main-search' }) => {
  let searchStr = request.search_string
  const queryString = parseSearchString(searchStr)
  const { sort_order = 'asc', extraMust, must_not = '', maxResults = 20 } = request
  console.log('runElasticSearchUsers', searchStr)
  if (searchStr.length >= 2) {
    let postData = {
      query: {
        bool: {
          must: [
            {
              query_string: {
                query: queryString,
                fields: ['fullname', 'name', 'slug', 'business_organization_name'],
              },
            },
          ],
        },
      },
      size: maxResults,
      sort: {
        _score: {
          order: sort_order ?? 'desc',
        },
        sortname: {
          order: sort_order ?? 'asc',
        },
      },
      highlight: {
        pre_tags: ['<strong>'],
        post_tags: ['</strong>'],
        fields: {
          name: {},
        },
      },
    }

    if (!!must_not) {
      postData.query.bool.must_not = must_not?.filter?.(Boolean) ?? must_not
    }

    if (!!extraMust) {
      postData.query.bool.must = [...postData.query.bool.must, extraMust]
    }

    var elastic_search_api =
      ELASTIC_SEARCH_BASE_URL +
      ELASTIC_SEARCH_USERS +
      '/' +
      ELASTIC_SEARCH_TYPE +
      '/_search?pretty=true'

    if (typeof cancelToken != typeof undefined && cancelToken?.source === source) {
      cancelToken.cancel('CANCELLED')
    }

    cancelToken = axios.CancelToken.source()
    cancelToken.source = source
    try {
      const response = await axios.post(elastic_search_api, postData, {
        headers: { 'Content-Type': 'application/json' },
        cancelToken: cancelToken?.token,
      })
      if (response?.status === 200) {
        let results
        if (previousData?.length > 0) {
          let mergeData = previousData.concat(response.data.hits.hits)
          mergeData = mergeData.filter(
            (thing, index, self) =>
              self.findIndex(
                (t) =>
                  t['_source'].id === thing['_source'].id &&
                  t['_source'].name === thing['_source'].name,
              ) === index,
          )
          results = mergeData
        } else results = response?.data?.hits?.hits
        return results
      } else return { status: 'failed' }
    } catch (error) {
      customLogger(error)
      return { status: error.message === 'CANCELLED' ? 'CANCELLED' : 'failed' }
    }
  } else {
    return { status: 'failed' }
  }
}

export const parseSearchString = (searchString) => {
  searchString = searchString.replace(/([\!\*\+\&\|\(\)\[\]\{\}\^\~\?\:\-\_\"])/g, ' ')
  searchString = searchString.replace(
    /\b(a)\b|\b(an)\b|\b(and)\b|\b(are)\b|\b(as)\b|\b(at)\b|\b(be)\b|\b(but)\b|\b(by)\b|\b(for)\b|\b(if)\b|\b(in)\b|\b(into)\b|\b(is)\b|\b(it)\b|\b(no)\b|\b(not)\b|\b(of)\b|\b(on)\b|\b(or)\b|\b(such)\b|\b(that)\b|\b(the)\b|\b(their)\b|\b(then)\b|\b(there)\b|\b(these)\b|\b(they)\b|\b(this)\b|\b(to)\b|\b(was)\b|\b(will)\b|\b(with)\b/g,
    '',
  )
  searchString = searchString.replace(/  +/g, ' ')
  searchString = searchString.replace(/[\u2018\u2019]/g, "'").replace(/[\u201C\u201D]/g, '"')
  searchString = searchString.replace(/(')\1{1,}/, "'")
  searchString = searchString.replace(/(")\1{1,}/, `"`)
  searchString = searchString.replaceAll('/', '')
  searchString = searchString.replaceAll('>>', '')
  searchString = searchString.replace(/\s\s+/g, ' ')
  searchString = searchString.trim()

  var queryString = ''
  var searchStringArray = searchString?.split?.(' ')
  var searchStringArrayLength = searchStringArray.length

  for (var i = 0; i < searchStringArrayLength; i++) {
    queryString += '(*' + searchStringArray[i] + '*)'
    if (i < searchStringArrayLength - 1) {
      queryString += ' AND ' // AND OR
    }
  }
  return queryString
}
