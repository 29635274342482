import React, { useRef } from 'react'
import { Link } from 'react-router-dom'
import { ENABLE_SOCIAL_MEDIA } from '../../../services/environment'
import { formatMemberDirectoryName } from '../../../services/formatService'
import { PROFILE_ROUTE } from '../../../services/routeConst'

const HeaderTabs = ({ userDetail }) => {
  console.log({ userDetail })
  const scrollRef = useRef()
  return (
    <div className='cover_bottom_wrapper position-relative'>
      <div className='cover_bottom' ref={scrollRef}>
        <div className='left_link'>
          <div
            className='position-absolute profile-scroll-btn'
            style={{ top: 9, left: 4, zIndex: 2000 }}
          >
            <button
              className='promotion-back-button'
              onClick={() => (scrollRef.current.scrollLeft -= 100)}
            >
              <i className='fas fa-chevron-left' aria-hidden='true' style={{ color: '#999' }}></i>
            </button>
          </div>
          <ul className='upper-tabs nav nav-tabs'>
            <li className='nav-item'>
              <Link className='nav-link' to={PROFILE_ROUTE + '#overview'}>
                Overview
              </Link>
            </li>
            {ENABLE_SOCIAL_MEDIA ? (
              <>
                <li className='nav-item'>
                  <Link className={'nav-link'} to={PROFILE_ROUTE + '#myposts'}>
                    My posts
                  </Link>
                </li>
                <li className='nav-item'>
                  <Link className={'nav-link'} to={PROFILE_ROUTE + '#posts'}>
                    Wall
                  </Link>
                </li>
              </>
            ) : null}
            <li className='nav-item'>
              <Link className='nav-link' to={PROFILE_ROUTE + '#trust'}>
                Trust List ({userDetail?.total_trust_me + userDetail?.total_trusted})
              </Link>
            </li>
            <li className='nav-item'>
              <Link className='nav-link' to={PROFILE_ROUTE + '#invite_center'}>
                Invite Center
              </Link>
            </li>
            <li className='nav-item'>
              <Link className='nav-link' to={PROFILE_ROUTE + '#friend'}>
                Friends ({userDetail?.total_friends})
              </Link>
            </li>
            {userDetail?.user_type === 'organization' ? (
              <li className='nav-item'>
                <Link className='nav-link' to={PROFILE_ROUTE + '#member'}>
                  {formatMemberDirectoryName(
                    userDetail?.user_settings?.member_directory_name,
                    userDetail?.user_settings?.custom_member_directory_name,
                  ) ?? 'Member Directory'}{' '}
                  ({userDetail?.total_users_members})
                </Link>
              </li>
            ) : null}
            {/* <li className='nav-item'>
              <Link className='nav-link' to={PROFILE_ROUTE + '#activity'}>
                Activity
              </Link>
            </li> */}
            <li className='nav-item me-0'>
              <Link className='nav-link' to={PROFILE_ROUTE + '#gallery'}>
                Gallery ({userDetail?.total_galleries})
              </Link>
            </li>
          </ul>
          <div
            className='position-absolute profile-scroll-btn'
            style={{ top: 9, right: 4, zIndex: 2000 }}
          >
            <button
              className='promotion-back-button'
              onClick={() => (scrollRef.current.scrollLeft += 100)}
            >
              <i className='fas fa-chevron-right' aria-hidden='true' style={{ color: '#999' }}></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HeaderTabs
