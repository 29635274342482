import ShowMoreText from 'react-show-more-text'
import parse from 'html-react-parser'
import './SocialShowMoreText.scss'
const SocialShowMoreText = ({ textToShow }) => {
  const executeOnClick = (isExpanded) => {
    //console.log(isExpanded)
  }
  //add attribute target='_blank' to href
  let addTargetToText = textToShow?.replace(/href/g, "target='_blank' href")
  const convertedText = /<\/?[a-z][\s\S]*>/i.test(textToShow)
    ? parse(addTargetToText)
    : addTargetToText
  return (
    <ShowMoreText
      /* Default options */
      lines={4}
      more='Show more'
      less='Show less'
      className='showMoreText'
      anchorClass='my-anchor-css-class'
      onClick={() => executeOnClick}
      expanded={false}
      //width={280}
      truncatedEndingComponent={'... '}
    >
      {convertedText}
    </ShowMoreText>
  )
}
export default SocialShowMoreText
