import React, { Component } from 'react'
import { reduxForm } from 'redux-form'
import { removeMemberCall } from '../../../services/memberApiService'
import $J from 'jquery'
import './RemoveFromOrgPopup.scss'
import { formatMembersName } from '../../../services/formatService'

class ConfirmRemoveFromOrgPopupModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      is_outside_member: '0',
      member_note: '',
    }
  }
  componentWillMount() {}
  componentDidMount() {}
  changeUserTypeHandler = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }
  submitMember = (event) => {
    this.props.setLoadingMemberChangeResponse(true)
    $J('#confirm_remove_from_org').modal('hide')
    this.props.memberAddRemoveYour(this.props.dataYour)
    // let memberAddRemoveReqData = {
    //   id: this.props.user_id,
    //   member_type: this.props.memberType,
    // };
    // let self = this;
    // removeMemberCall(memberAddRemoveReqData).then(function (response) {
    //   // console.log(response);
    //   if (typeof response.status !== 'undefined' && response.status === 200) {
    //     let apiResponseData = response.data;
    //     self.props.setStatusOfMemberUser('removed');
    //     $J('#confirm_remove_from_org').modal('hide');
    //   }
    // });
  }
  render() {
    const { invalid } = this.props
    return this.props.user_id ? (
      <div
        className='modal fade login_blk reset_password_blk it-member-modal'
        id='confirm_remove_from_org'
        role='dialog'
      >
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h2 className='text-start'>{this.props.Orgname}</h2>
              <button type='button' className='btn-close' data-bs-dismiss='modal'></button>
            </div>
            <div className='modal-body loginblk'>
              <p className='text-center' style={{ marginBottom: 0 }}>
                Are you sure you want to remove this{' '}
                {formatMembersName(this.props?.membersName, null, false)} from you organization?
              </p>

              <form
                id='login_form'
                name='login_form'
                className='reset_password'
                method='post'
                style={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <input
                  className='resetbtn'
                  type='button'
                  value='Cancel'
                  data-bs-dismiss='modal'
                  style={{ marginRight: '1rem' }}
                />
                <input
                  className='resetbtn'
                  type='button'
                  value='Remove'
                  onClick={this.submitMember}
                  style={{ marginLeft: '1rem' }}
                />
              </form>
            </div>
          </div>
        </div>
      </div>
    ) : null
  }
}

ConfirmRemoveFromOrgPopupModal = reduxForm({
  form: 'confirm_remove_from_org',
})(ConfirmRemoveFromOrgPopupModal)

export default ConfirmRemoveFromOrgPopupModal

// export default connect(
//     mapStateToProps,
//     mapDispatchToProps
// )(ConfirmRemoveFromOrgPopupModal);
