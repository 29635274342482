import React, { Component } from 'react'
import AppLayout from '../AppLayout'
import { TERMS_AND_CONDITIONS_TITLE } from '../../services/titleConst'
import LoginModal from '../LoginModal/LoginModal'
import * as route from '../../services/routeConst'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
class PromotionTerms extends Component {
  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {
    return (
      <AppLayout routeActive={this.props.location.pathname}>
        <Helmet>
          <title>Terms &amp; Conditions</title>
        </Helmet>
        <section className='bg-grey it-privacy-terms-page it-terms-page'>
          <div className='container'>
            <h3 className='varela mb-3'>Terms &amp; Conditions</h3>
            <h3 className='varela mb-3'>iTRUSTit Social's "Memorial Week Giveaway"</h3>
            <h3 className='varela mb-3'>OFFICIAL RULES</h3>
            <div className='white-pane privacy promotion-terms'>
              <ol>
                <li>
                  <p>
                    NO PURCHASE IS NECESSARY TO ENTER OR WIN. PURCHASE WILL NOT IMPROVE YOUR CHANCES
                    OF WINNING.
                  </p>
                </li>
                <li>
                  Eligibility.
                  <p>
                    The iTRUSTit Social Giveaway ("Memorial Week Giveaway" or "Promotion") is open
                    to all individuals over the age of 21 and who are legal residents of the United
                    States at the time of entry, except as follows are ineligible to participate or
                    win. Employees of iTRUSTit Social, Inc, ("Sponsor") and its subsidiaries,
                    affiliates, partners, distributors and advertising or promotion agencies and the
                    immediate families of such persons (spouse, parents, siblings and children and
                    each of their spouses) and persons living in the households of each of these
                    individuals whether related or not are ineligible to participate or win. The
                    term "affiliate" includes companies donating prizes to Sponsor for this
                    Promotion. All eligibility is subject to applicable federal, state, and local
                    laws and regulations.
                  </p>
                </li>
              </ol>
              <ol>
                <li>
                  Entry:
                  <p>To enter, simply +Friend the Sponsor's iTRUSTit profile page:</p>
                  <ul className='ms-3'>
                    <li>
                      <p>iTRUSTit Social, Inc. (https://itrustit.com/itrustitsocial)</p>
                    </li>
                    <li>
                      <p>Emporium Thai (https://itrustit.com/EmporiumThai)</p>
                    </li>
                    <li>
                      <p>The UPS Store #0943 (https://itrustit.com/upsstore0943)</p>
                    </li>
                    <li>
                      <p>Uncorked (https://itrustit.com/Uncorkedwineshops)</p>
                    </li>
                    <li>
                      <p>Women In Technology International (https://itrustit.com/witi)</p>
                    </li>
                    <li>
                      <p>Texas Coastal Budz (https://itrustit.com/cbd)</p>
                    </li>
                    <li>
                      <p>King Harbor Yacht Club (https://itrustit.com/KHYC)</p>
                    </li>
                    <li>
                      <p>Leadership Manhattan Beach (https://itrustit.com/LMB)</p>
                    </li>
                    <li>
                      <p>Fritto Misto (https://itrustit.com/fritto-misto)</p>
                    </li>
                    <li>
                      <p>Becker Surfboards (https://itrustit.com/beckersurf)</p>
                    </li>
                    <li>
                      <p>Kurtis Lee Thomas - Breathwork Detox (https://itrustit.com/Kurtis)</p>
                    </li>
                    <li>
                      <p>
                        Pedone's Pizza &amp; Italian Food (https://itrustit.com/PedonesPizzaHermosa)
                      </p>
                    </li>
                    <li>
                      <p>Trick Electric Bikes Hermosa Beach (https://itrustit.com/trickebikes)</p>
                    </li>
                  </ul>
                  <p>
                    at https://www.itrustit.com/ or otherwise indicated. The Promotion begins at
                    6:01 a.m. on May 29, 2022 and ends June 5th, 2022 at 5:59 a.m. Pacific Time (the
                    "Contest Period"). iTRUSTit.com's computer is the official time keeping device
                    for this Promotion. You may submit additional entries by doing any of the
                    following, each of which is optional, and following posted additional entry
                    instructions:
                  </p>
                  <p>
                    You must follow or complete all required steps. Enter only on a Facebook, Apple,
                    LinkedIn, or email account you own. Comments left on Facebook, Instagram, or
                    Twitter, via email or other sources, are not acceptable entries. Anyone found to
                    use multiple accounts on Facebook, Instagram or Twitter will be disqualified.
                    Multiple posts on Instagram or retweets on Twitter in a single day will not be
                    accepted as additional entries. All entries must be received by close of the
                    Contest Period.
                  </p>
                  <p>
                    Follow the directions to complete each entry. If you are not logged into
                    Facebook, Instagram or Twitter, as applicable, you may receive a prompt to do
                    so.
                  </p>
                  <p>
                    Entries become the property of Sponsor and will not be returned. Proof of
                    submission does not constitute proof of receipt.
                  </p>
                  <p>
                    <strong>
                      By entering this promotion, you acknowledge and agree iTRUSTit Social, Inc.
                      may share personal information you provide to us, including your name and
                      email address, with one or more of our promotion partners (if any) and other
                      companies with whom iTRUSTit Social, Inc. has a business relationship. Please
                      see our Privacy Policies for further information.
                    </strong>
                  </p>
                </li>
              </ol>
              <ol>
                <li>
                  <p>
                    The Promotion is hosted on the iTRUSTit website, which is owned and operated by
                    iTRUSTit Social, Inc. ("iTRUSTit"). You will be entering your information on the
                    iTRUSTit website. You may review iTRUSTit's Privacy Policy at
                    https://iTRUSTit.com/privacy and its Terms of Use at https://iTRUSTit.com/terms.
                    By entering this Promotion, you agree to these Official Rules, iTRUSTit's
                    Privacy Policy, and iTRUSTit's Terms of Service.
                  </p>
                </li>
              </ol>
              <ol>
                <li>
                  Odds.
                  <p>Odds of winning depend on the number of entries.</p>
                </li>
                <li>
                  Limit:
                  <p>
                    Each entrant must have his or her own personal email address, or Facebook,
                    Google, LinkedIn, Apple, Instagram or Twitter account and may use only one of
                    each such account to enter. Entrants may not share the same email address. Using
                    multiple/different email addresses, identities, registrations, logins, or any
                    other method to attempt to enter the Promotion more than the allowed number of
                    times will void all of an entrant's entries, and that entrant may be
                    disqualified from participation in the Promotion. Use of any automated system to
                    enter the Promotion is prohibited and will result in disqualification. Sponsor
                    and its affiliates are not responsible for lost, late, incomplete, invalid,
                    unintelligible or misdirected registrations, which will be disqualified. If
                    there is a dispute as to any registration, the authorized account holder of the
                    email address used to register will be deemed to be the registrant. The
                    "authorized account holder" is the natural person assigned an email address by
                    an Internet access provider, online service provider or other organization
                    responsible for assigning email addresses for the domain associated with the
                    submitted address, or the person who is assigned to a Facebook, Instagram,
                    Google, Apple, LinkedIn or Twitter account by Facebook, Instagram or Twitter and
                    associated with the entry. Each entrant may be required to show proof of being
                    the authorized account holder.
                  </p>
                </li>
              </ol>
              <p>One (1) Winner will be selected to receive each the following items: -</p>
              <ol>
                <li>
                  <p>
                    Uncorked Wine Shops – Wine Tasting for 4 (valid only at Uncorked), Valued at $80
                  </p>
                </li>
                <li>
                  <p>
                    Women in Technology International (WITI) – 1 Lifetime Membership, (valid only
                    for membership credit with WITI) Valued at $1,000.00
                  </p>
                </li>
                <li>
                  <p>
                    Fritto Misto – Gift Certificate for 2 Lunch Specials + 2 Soft Drinks, (valid
                    only at Fritto Misto Hermosa Beach), Valued at $36
                  </p>
                </li>
                <li>
                  <p>
                    Fritto Misto – Gift Certificate for 2 Lunch Specials + 2 Soft Drinks, (valid
                    only at Fritto Misto Hermosa Beach), Valued at $36
                  </p>
                </li>
                <li>
                  <p>
                    Kurtis Lee Thomas 'Man From The Stars' - 2 Breathwork Detox Virtual Adventure
                    Tickets (valid for the Breathwork Detox Virtual Adventure on 6-1-2022), Value
                    $70.00
                  </p>
                </li>
                <li>
                  <p>
                    Kurtis Lee Thomas 'Man From The Stars' - 2 Breathwork Detox Virtual Adventure
                    Tickets (valid for the Breathwork Detox Virtual Adventure on 6-1-2022), Value
                    $70.00
                  </p>
                </li>
                <li>
                  <p>
                    Kurtis Lee Thomas 'Man From The Stars' - 1 Breathwork Detox Virtual Adventure
                    Ticket (valid for the Breathwork Detox Virtual Adventure on 6-1-2022), Value
                    $35.00
                  </p>
                </li>
                <li>
                  <p>
                    The UPS Store #0943 – Gift Certificate (valid only at The UPS Store #0943),
                    Value $75.00
                  </p>
                </li>
                <li>
                  <p>
                    iTRUSTit Social (KHYC) – Sunset Happy Hour for 4 at King Harbor Yacht Club (with
                    member), Value $200.00
                  </p>
                </li>
                <li>
                  <p>
                    iTRUSTit Social (KHYC) – Dinner or Brunch for 2 at King Harbor Yacht Club (with
                    member) (Date and times must be scheduled with designated member), Value $150.00
                  </p>
                </li>
                <li>
                  <p>
                    Emporium Thai - Gift Card (valid only at Emporium Thai and emporiumthai.com),
                    Valued at $100
                  </p>
                </li>
                <li>
                  <p>
                    Pedone's Pizza &amp; Italian Food - Gift Certificate (Valid only at Pedone's
                    Hermosa Beach), Value $20
                  </p>
                </li>
                <li>
                  <p>
                    Becker Surfboards – Beach Pack (Backpack, T-Shirt, Surfboard leash), Value $100
                  </p>
                </li>
                <li>
                  <p>
                    Trick Electric Bikes Hermosa Beach (Thousand brand Bike Helmet), Value $90.00
                  </p>
                </li>
                <li>
                  <p>
                    Leadership Manhattan Beach - $500.00 off the 2022-2023 class tuition. (Valid
                    only for LMB class tuition for accepted student. Restrictions apply. Good only
                    for the 2022-2023 Leadership Manhattan Beach class. No cash value. Winner must
                    apply and be accepted into the class. If winner/applicant is not accepted into
                    the class this certificate is null and void. This certificate is transferrable.)
                    Value $500.00
                  </p>
                </li>
                <li>
                  <p>
                    The Winner will be randomly selected by Sponsor on June 6th 2022. Each Winner
                    will be notified by email at the email address or other account address provided
                    with the entries in the Promotion. If a Winner cannot be reached within 7 days
                    (7) days from the first notification attempt, then that Winner shall be
                    disqualified and an alternative Winner will be randomly selected. Winners of
                    prizes valued at $600 or more will be issued an IRS 1099 Form for the value of
                    the prize. The Winner will be required to sign and return an Affidavit of
                    Eligibility, Liability Release, where lawful, Publicity Release, where lawful,
                    including Social Security Number or Taxpayer Identification Number for tax
                    reporting, and other authorizations required by Sponsor or applicable law or the
                    prize may be forfeited. All prizes are awarded "AS IS," "AS AVAILABLE," and
                    without warranty of any kind, express or implied, including without limitation
                    any implied warranty of merchantability or fitness for a particular purpose. All
                    federal, state and local taxes and duties are the Winner's responsibility. A
                    prize may not be assigned, transferred, substituted or redeemed for cash, except
                    that Sponsor or an affiliate provided a prize may substitute a prize of equal or
                    greater value in the event the listed prize is not available or cannot be
                    awarded. The Winner will be required to provide his/her personal details and
                    mailing address before the prize can be released. The prizes will be delivered
                    by regular U.S. mail or private courier. Unclaimed prizes will not be awarded.
                  </p>
                </li>
                <li>
                  <p>
                    Giveaways: Notwithstanding the other rules set forth herein, prior to transfer
                    of any prize subject to any state, federal or other laws, Taxes on prizes and
                    all expenses related to acceptance and use of any prize and not specified are
                    the sole responsibility of the Winner.
                  </p>
                </li>
              </ol>
              <p>
                As a condition of being awarded certain prizes, except where prohibited by law, the
                Winner may be required to complete, sign (where applicable) and submit an Affidavit
                and Required Documents, which consist of:
              </p>
              <ol>
                <li>
                  <p>
                    A notarized affidavit certifying eligibility and compliance with the rules
                    contained herein, including a declaration of status as a person who is not
                    prohibited from possessing firearms;
                  </p>
                </li>
                <li>
                  <p>A true and correct IRS form W-9;</p>
                </li>
                <li>
                  <p>
                    If prize is a firearm - A copy of a licensed dealer's federal firearm license
                    through whom the firearm will be transferred;
                  </p>
                </li>
                <li>
                  <p>
                    Any and all other documents required by law or regulation to effectuate the
                    transfer of the prize;
                  </p>
                </li>
                <li>
                  <p>Proof of Identity; and</p>
                </li>
                <li>
                  <p>
                    And any other legal, regulatory, or tax-related documents required by Sponsor or
                    its applicable prize-awarding affiliate in its or their sole discretion.
                  </p>
                  <p>
                    Failure to provide all required signatures and documents will disqualify the
                    Winner from further participation and any awards. In the event a new law or
                    legislation goes into effect that will make any listed firearm illegal, Sponsor
                    will stop the contest early prior to the law going into effect and draw a
                    winner. Any winner of a firearm must be able to pass background checks and
                    comply with all required Federal, State and Local laws in order to take
                    possession of the prize. No person other than the Winner may take possession of
                    the prize. Failure to comply with all Federal, State and Local laws will result
                    in the forfeiture of the prize and another winner will be selected. Due to
                    regulations in different states, cities and other areas, certain prize winners
                    may be provided alternative prizes of similar value.
                  </p>
                </li>
                <li>
                  Decisions:
                  <p>
                    y entering into the Promotion, entrants agree to abide by and be bound by these
                    official rules, and to accept the decisions of Sponsor or an applicable
                    affiliate as final. Entry in this Promotion does not constitute entry into any
                    other promotion, contest or sweepstakes of Sponsor or any affiliate. Neither
                    Sponsor nor any affiliate is responsible for stolen, lost, late, misdirected,
                    damaged, incomplete, or illegible entries, whether due to system errors or
                    failures, faulty transmission or other telecommunications malfunctions, entries
                    not received resulting from any hardware or software failures of any kind, lost
                    or unavailable network connections, failed, incomplete or garbled computer or
                    telephone transmissions, typographical or system errors and failures, technical
                    malfunctions, spam filters, or otherwise. Sponsor may prohibit an entrant from
                    participating in the Promotion or winning a prize if, in its sole discretion, it
                    determines that said entrant is attempting to undermine the legitimate operation
                    of the Promotion by cheating, hacking, deception, or other unfair playing
                    practices (including the use of automated quick entry programs or any other
                    entries generated by a script, macro, or other automated means) or intending to
                    annoy, abuse, threaten or harass any other entrants or Sponsor. Social media
                    content that, in Sponsor's opinion, is inappropriate, incomplete, garbled,
                    corrupted, or unintelligible for any reason, including, but not limited to,
                    computer or network malfunction or congestion, is void and will not be accepted.
                    In case of a dispute over the identity of a participant, the authorized account
                    holder of the Facebook, Instagram, Apple, Google, LinkedIn or Twitter account
                    used to enter will be deemed to be the participant. By participating in this
                    Promotion, you understand and agree that information you provide in your
                    entry(ies) or on Facebook, Instagram or Twitter in connection with this
                    Promotion will be used by Sponsor and its affiliates in accordance with the
                    official rules of this Promotion, as well as Sponsor's or the applicable
                    affiliate's Privacy Policies. If for any reason this Promotion is not able to be
                    conducted as planned, including, but not limited to, by reason of infection by
                    computer virus, bugs, tampering, unauthorized intervention, fraud or any other
                    causes beyond the reasonable control of Sponsor which corrupt or affect the
                    administration, security, fairness, integrity or proper conduct of the
                    Promotion, then Sponsor reserves the right at its sole discretion to cancel,
                    terminate, modify or suspend the Promotion and randomly draw from those entries
                    received up to the cancellation/suspension date. Sponsor reserves the right to
                    disqualify any participant or winner and may refuse to award any prize to a
                    person who fails to follow these official rules or who is ineligible or has
                    violated any rule, gained unfair advantage in participating in the Promotion,
                    obtained winner status using fraudulent means or made any misrepresentations
                    relative to the Promotion and prize redemption. CAUTION: ANY ATTEMPT BY AN
                    ENTRANT TO DELIBERATELY DAMAGE OR UNDERMINE THE LEGITIMATE OPERATION OF THE
                    PROMOTION MAY BE IN VIOLATION OF CRIMINAL AND CIVIL LAWS AND, SHOULD SUCH AN
                    ATTEMPT BE MADE, SPONSOR RESERVES THE RIGHT TO SEEK REMEDIES AND DAMAGES
                    (INCLUDING ATTORNEYS' FEES) FROM ANY SUCH ENTRANT TO THE FULLEST EXTENT OF THE
                    LAW, INCLUDING SEEKING CRIMINAL PROSECUTION. By participating, entrants
                    acknowledge and represent that they unconditionally agree to be bound by these
                    Official Rules and the decisions of Sponsor, which are binding and final in all
                    matters relating to this Promotion and waive any right to claim ambiguity in the
                    Promotion or these Official Rules.
                  </p>
                </li>
                <li>
                  Apple Entry Terms:
                  <p>
                    Entrants who enter the Promotion using Apple must be members of good standing of
                    Apple in order to participate in the Promotion. Apple is not a sponsor of this
                    Promotion and does not endorse or otherwise have anything to do with this
                    Promotion. By entering this Promotion, entrants expressly and completely release
                    Apple from any liability whatsoever relative to the Promotion. In the case of a
                    dispute over a Apple entry, the authorized account holder of the Apple account
                    used to enter will be deemed to be the entrant. "Authorized account holder" is
                    defined as the person who is assigned a Apple account by Apple.
                  </p>
                </li>
                <li>
                  Facebook Entry Terms:
                  <p>
                    Entrants who enter the Promotion using Facebook must be members of good standing
                    of Facebook in order to participate in the Promotion. Facebook is not a sponsor
                    of this Promotion and does not endorse or otherwise have anything to do with
                    this Promotion. By entering this Promotion, entrants expressly and completely
                    release Facebook from any liability whatsoever relative to the Promotion. In the
                    case of a dispute over a Facebook entry, the authorized account holder of the
                    Facebook account used to enter will be deemed to be the entrant. "Authorized
                    account holder" is defined as the person who is assigned a Facebook account by
                    Facebook.
                  </p>
                </li>
                <li>
                  LinkedIn Entry Terms:
                  <p>
                    Entrants who enter the Promotion using LinkedIn must be members of good standing
                    of LinkedIn in order to participate in the Promotion. LinkedIn is not a sponsor
                    of this Promotion and does not endorse or otherwise have anything to do with
                    this Promotion. By entering this Promotion, entrants expressly and completely
                    release LinkedIn from any liability whatsoever relative to the Promotion. In the
                    case of a dispute over a LinkedIn entry, the authorized account holder of the
                    LinkedIn account used to enter will be deemed to be the entrant. "Authorized
                    account holder" is defined as the person who is assigned a LinkedIn account by
                    LinkedIn.
                  </p>
                </li>
                <li>
                  Google Entry Terms:
                  <p>
                    Entrants who enter the Promotion using Google must be members of good standing
                    of Google in order to participate in the Promotion. Google is not a sponsor of
                    this Promotion and does not endorse or otherwise have anything to do with this
                    Promotion. By entering this Promotion, entrants expressly and completely release
                    Google from any liability whatsoever relative to the Promotion. In the case of a
                    dispute over a Google entry, the authorized account holder of the Google account
                    used to enter will be deemed to be the entrant. "Authorized account holder" is
                    defined as the person who is assigned a Google account by Google.
                  </p>
                </li>
                <li>
                  Publicity:
                  <p>
                    Each Winner agrees to permit Sponsor to use his/her name and likeness in
                    promotional and other Sponsor materials, without additional compensation or
                    permission, except where prohibited by law. Each Winner also agrees that their
                    name and prize value will be given to other people upon request.
                  </p>
                </li>
                <li>
                  Additional Terms:
                  <p>
                    YOU ACKNOWLEDGE AND AGREE THAT ANY ACCEPTANCE, PARTICIPATION IN OR WITH, AND/OR
                    USE OF ANY PRIZE ARE SOLELY AT YOUR OWN RISK. YOU, ON BEHALF OF YOURSELF AND
                    YOUR ASSIGNS, HEIRS, DEVISEES AND ESTATE (COLLECTIVELY, "SUCCESSORS"), HEREBY
                    UNCONDITIONALLY AND FOREVER RELEASE, DISCHARGE, AND AGREE TO INDEMNIFY, DEFEND,
                    AND HOLD HARMLESS SPONSOR, ITS AFFILIATES AND OTHER PROVIDERS OF ANY PRIZE AND
                    EACH OF THEIR RESPECTIVE PARENT COMPANIES, SUBSIDIARIES, AFFILIATES, OFFICERS,
                    DIRECTORS, EMPLOYEES, AGENTS, PROFESSIONAL ADVISORS, AND CONTRACTORS
                    (COLLECTIVELY, "RELEASED PARTIES"), FROM ANY AND ALL CLAIMS, JUDGMENTS, COSTS,
                    DAMAGES, LOSSES, EXPENSES, AND LIABILITIES (WHETHER UNDER CONTRACT, WARRANTY,
                    TORT (INCLUDING NEGLIGENCE (WHETHER ACTIVE, PASSIVE, OR IMPUTED), STRICT
                    LIABILITY, PRODUCT LIABILITY, OR ANY OTHER THEORY) RELATING TO ANY CLAIM YOU MAY
                    NOW OR LATER HAVE WITH RESPECT TO ANY DEATH, PERSONAL INJURY, PROPERTY DAMAGE,
                    PECUNIARY, OR OTHER LOSS, DAMAGE, COST, OR EXPENSE (COLLECTIVELY, "HARM") THAT
                    MAY BE SUFFERED BY YOU OR ANY THIRD PARTY AS A RESULT OF THE PROMOTION, ANY USE
                    BY THE RELEASED PARTIES OF YOUR NAME, LIKENESS (INCLUDING, WITHOUT LIMITATION,
                    AURAL AND VISUAL LIKENESSES OF YOU), OR BIOGRAPHICAL DATA, OR IN CONNECTION WITH
                    YOUR ACCEPTANCE, PARTICIPATION IN OR WITH, USE OF, AND/OR INABILITY TO USE ANY
                    PRIZE AWARDED TO YOU, EVEN IF SUCH HARM IS CAUSED SOLELY BY THE RECKLESSNESS,
                    NEGLIGENCE, OR FAULT OF ONE OR MORE RELEASED PARTIES (COLLECTIVELY, THE
                    "RELEASED CLAIMS"). YOU UNDERSTAND AND AGREE THAT THIS RELEASE WILL PREVENT YOU
                    AND YOUR SUCCESSORS FROM BRINGING ANY ACTION AGAINST ANY RELEASED PARTY AND FROM
                    RECOVERING ANY MONEY DAMAGES OR OTHER RELIEF FROM ANY RELEASED PARTY IN
                    CONNECTION WITH ANY OF THE RELEASED CLAIMS.
                  </p>
                  <p>
                    All issues and questions concerning the construction, validity, interpretation,
                    and enforceability of these official rules, or the rights and obligations of the
                    entrant and Sponsor in connection with the Promotion, shall be governed by, and
                    construed in accordance with, the substantive laws of the State of California,
                    without regard to its choice of law provisions. You irrevocably consent to the
                    exclusive jurisdiction and venue of the federal and state courts located in Los
                    Angeles County, California, with respect to any claim or suit arising out of or
                    in connection with these Official rules, the Promotion, any delivery,
                    mis-delivery, or non-delivery of any prize or your acceptance, participation in,
                    use of, or inability to use any prize, and agree not to commence or prosecute
                    any such claim or suit other than in the aforementioned courts. EACH OF THE
                    PARTIES HEREBY IRREVOCABLY WAIVES ANY AND ALL RIGHT TO TRIAL BY JURY IN ANY
                    LEGAL PROCEEDING ARISING OUT OF OR RELATING TO THESE OFFICIAL RULES, THE
                    PROMOTION OR ANY OF THE RELEASED CLAIMS.
                  </p>
                  <p>
                    You understand and agree that if any provision of these official rules is found
                    to be unenforceable in any respect by a court of competent jurisdiction, it is
                    your intention and understanding that these official rules shall nonetheless be
                    enforced to the maximum extent to which they are found to be legally
                    enforceable. TO THE EXTENT PERMITTED BY LAW, YOU WAIVE THE BENEFIT OF ANY
                    PROVISIONS OF ANY STATUTE OR OTHER LAW THAT MIGHT ADVERSELY AFFECT THE RIGHTS OF
                    ANY RELEASED PARTY UNDER THESE OFFICIAL RULES.
                  </p>
                  <p>
                    You understand and agree that your consent to these official rules set forth
                    above are a material inducement for Sponsor and its affiliates to award a prize
                    to you and that Sponsor and its affiliates would not do so in the absence of
                    your consent as evidenced by your participation in the Promotion.
                  </p>
                  <p>
                    These official rules are the entire agreement between Sponsor and its affiliates
                    and you with respect to the matters described herein, and supersedes any and all
                    other agreements and communications, oral or written, between you and Sponsor or
                    any other Released Party with respect to such matters. These official rules may
                    not be amended or supplemented except by a writing signed by Sponsor
                    specifically referencing these official rules.
                  </p>
                  <p>
                    Except where prohibited by law, as a condition of participating in the
                    Promotion, each entrant agrees that (1) any and all disputes and causes of
                    action arising out of or connected with the Promotion drawing or any prizes
                    awarded, shall be resolved individually, without resort to any form of class
                    action, and exclusively by final and binding arbitration under the rules of the
                    American Arbitration Association and held in Chicago, Illinois; (2) the laws of
                    the State of California will govern the enforcement and all proceedings at such
                    arbitration; and (3) judgment upon such arbitration award may be entered in any
                    court having jurisdiction. Under no circumstances will any participant be
                    permitted to obtain awards for, and each participant hereby waives all rights to
                    claim, punitive, incidental or consequential damages, or any other damages,
                    including attorneys' fees, other than actual out-of-pocket expenses (i.e., costs
                    associated with entering the Promotion), and waives all rights to have damages
                    multiplied or increased under any federal or state law.
                  </p>
                  <p>
                    This Promotion is void where prohibited by law or regulation. Sponsor may
                    substitute prizes of equivalent value, amend the rules or discontinue the
                    Promotion at any time. Sponsor disclaims any responsibility to notify
                    participants of any aspect related to the conduct of this Promotion. The name of
                    the Winner and written copies of these rules are available by mail upon written
                    request to Sponsor, 703 Pier Ave. Ste. B691 Hermosa Beach, CA 90254, with a
                    stamped, self-addressed return envelope. All requests must be received by July
                    30th, 2022.
                  </p>
                  <p>
                    This Promotion is promoted and sponsored by iTRUSTit Social, Inc. 703 Pier Ave.
                    Ste. B691 Hermosa Beach, CA 90254 This Promotion is in no way sponsored,
                    endorsed or administered by, or associated with Facebook, Google, Apple,
                    Twitter, Instagram, or LinkedIn.
                  </p>
                </li>
              </ol>
              <p>&copy;2022 iTRUSTit Social, Inc. All Rights Reserved.</p>
              <p className='mt-4'>Platform Disclaimer</p>
              <p>
                This Sweepstakes and/or Promotion is powered by iTRUSTit Social, Inc. ("iTRUSTit").
                iTRUSTit does not sponsor, administer, endorse or bear liability for this promotion.
                By entering the Sweepstakes and/or Promotion, you agree to iTRUSTit Social, Inc.'s
                <a href='/privacy' target='_blank' rel='noopener noreferrer'>
                  Privacy Policy
                </a>{' '}
                and{' '}
                <a href='/privacy' target='_blank' rel='noopener noreferrer'>
                  Privacy Policy
                </a>
                .
              </p>
            </div>
          </div>
        </section>
        <LoginModal
          isLogin={(e) =>
            function (data) {
              this.props.isLogin(data)
            }
          }
          {...this.props}
        />
      </AppLayout>
    )
  }
}

export default PromotionTerms
