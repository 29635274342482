import React, { Component } from 'react'
import AppLayout from '../AppLayout'
import '../BetaReadme/BetaReadme.scss'
import { BETAREADME_TITLE } from '../../services/titleConst'
import { SITE_ADDRESS } from '../../services/environment'
import { COMMUNITY_ROUTE, FAQ_ROUTE, BETAREADME_ROUTE } from '../../services/routeConst'
import LoginModal from '../LoginModal/LoginModal'
import { isUserLogin, toaster, userToken } from '../../services/customFunction'
import $ from 'jquery'
import { submitQuestionEmail } from '../../services/userApiService'
import { Helmet } from 'react-helmet'

class BetaReadme extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isSubmitQuestionText: false,
      sharedFields: {},
      sharedErrors: {},
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    this.setState({ isSubmitQuestionText: false })
  }

  showSubmitQuestions = () => {
    this.setState({ isSubmitQuestionText: true })
  }

  hideSubmitQuestions = () => {
    this.setState({ isSubmitQuestionText: false })
  }

  downloadInstructionsAsPDF = (e) => {
    console.log('Download PDF!')
  }

  shareHandleChange = (e) => {
    let sharedFields = this.state.sharedFields
    sharedFields[e.target.name] = e.target.value
    this.setState({
      sharedFields,
    })
  }

  questionSubmitHandler = (e) => {
    e.preventDefault()

    let params = this.state.sharedFields

    if (this.validateForm()) {
      submitQuestionEmail(params).then(function (response) {
        if (response.status === 200) {
          toaster('success', response.data.message)
          $('#submitQuestionForm').get(0).reset()
        } else {
          toaster('error', 'Something wrong! please try again later.')
        }
      })
    } else {
      toaster('error', 'Please fill your question')
    }
  }

  validateForm = () => {
    let fields = this.state.sharedFields
    let errors = {}
    let formIsValid = true

    if (!fields['name']) {
      formIsValid = false
      errors['name'] = 'Please enter your name.'
    }

    this.setState({
      sharedErrors: errors,
    })
    return formIsValid
  }

  loginContinueHandler = () => {}

  render() {
    $('#login_modal').on('hidden.bs.modal', function (e) {})

    return (
      <AppLayout routeActive={this.props.location.pathname}>
        <Helmet>
          <title>{BETAREADME_TITLE}</title>
        </Helmet>
        <section className='bg-grey'>
          <div className='container'>
            <h3 className='varela mb-3'>Beta README</h3>
            <div className='white-pane green_line faq'>
              <img src='img/logo.png' style={{ margin: 'auto', display: 'block' }} alt='' />
              <h5 className='text-center' style={{ marginBottom: 0, color: '#242424' }}>
                Beta README & Next Steps
              </h5>
              <h5 className='text-center mb-4' style={{ fontStyle: 'italic' }}>
                Important! Please Read!
              </h5>
              <div className='mb-4' style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.15)' }}>
                <p style={{ marginBottom: 0 }}>
                  Thank you for being part of the official iTRUSTit Beta Launch!
                </p>
                <p>Please take a minute to read these helpful instructions.</p>
                <p style={{ marginBottom: 0 }}>
                  The goal is to help us build iTRUSTit's user base.
                </p>
                <p>
                  The Beta is targeted on gaining as many members as possible in the Los Angeles
                  area over the next 30-60 days. Once that goal is met, we will expand to other
                  markets.
                </p>
              </div>
              <h4 className='mb-2'>WHAT TO DO:</h4>
              <div id='accordion' className='faq_accordian'>
                <div className='card'>
                  <div className='card-header'>
                    <a className='card-link' style={{ borderTop: 'none' }}>
                      <strong>Sign Up</strong> - If you already have an account go to{' '}
                      <strong>Log In</strong>
                    </a>
                  </div>
                  <div className='collapse show' data-bs-parent='#accordion'>
                    <div className='card-body'>
                      <ol type='i'>
                        <li>
                          <p>Use the invite link from the person who invited you.</p>
                          <ol>
                            <li>
                              <p>
                                NOTE: This is an INVITE ONLY Beta. You must have an invitation to
                                join.
                              </p>
                            </li>
                            <li>
                              <p>
                                If you need an invite, contact us here:{' '}
                                <a href='mailto:admin@itrustit.com'>admin@itrustit.com</a>
                              </p>
                            </li>
                          </ol>
                        </li>
                        <li>
                          <p>
                            Select your Account Type - Person, Business, or Organization{' '}
                            <em>-Note: Realtors are Businesses</em>
                          </p>
                          <p style={{ marginLeft: 25 }}>
                            Hint: You may want to sign up a personal account first, then invite your
                            business.
                          </p>
                        </li>
                        <li>
                          <p>Be sure the Sponsor iTeam ID# is filled in the Sponsor section.</p>
                          <p style={{ marginLeft: 25 }}>
                            Hint: Their iTeam ID# can be found in the invitation link or above their
                            profile picture.
                          </p>
                        </li>
                        <li>
                          <p>
                            <strong>Sign Up</strong> then <strong>check your email</strong> and
                            verify your email
                          </p>
                          <p style={{ marginLeft: 25 }}>
                            - Check your Junk and Spam mail folders for emails from our system.
                          </p>
                          <p style={{ marginLeft: 50 }}>
                            - Adjust your email settings to approve emails from{' '}
                            <a href={SITE_ADDRESS}>iTRUSTIT.com</a> and{' '}
                            <a href={SITE_ADDRESS}>postmaster@mg.itrustit.com</a> as a safe sender.
                          </p>
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
                <div className='card'>
                  <div className='card-header'>
                    <a className='card-link'>
                      <strong>Log In</strong>
                    </a>
                  </div>
                  <div className='collapse show' data-bs-parent='#accordion'>
                    <div className='card-body'>
                      <ol type='i'>
                        <li>
                          <p>
                            Go to your <strong>Dashboard page</strong>. (Click your icon in the
                            upper right corner)
                          </p>
                          <ol>
                            <li>
                              <p>
                                Click "<strong>Edit Profile</strong>"
                              </p>
                              <ol type='a'>
                                <li>
                                  <p>Upload your Profile Picture (optional) Click SUBMIT</p>
                                </li>
                                <li>
                                  <p>Upload a Cover Photo (optional) Click SUBMIT</p>
                                </li>
                              </ol>
                            </li>
                          </ol>
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
                <div className='card'>
                  <div className='card-header'>
                    <a className='card-link'>
                      <strong>CONNECT</strong>
                    </a>
                  </div>
                  <div className='collapse show' data-bs-parent='#accordion'>
                    <div className='card-body'>
                      <ol type='i'>
                        <li>
                          <p>
                            <strong>+FRIEND</strong> People, Businesses, and Organizations you know.
                          </p>
                          <p style={{ marginLeft: 25 }}>
                            - On iTRUSTit, you can be FRIENDS with Businesses and Organizations too.
                          </p>
                        </li>
                        <li>
                          <p>
                            <strong>+TRUST</strong> the Businesses you recommend to your friends and
                            the Organizations you support.
                          </p>
                          <p style={{ marginLeft: 25 }}>
                            - Click on "<strong>+ Trust</strong>" to add a Business/Org to your
                            Trust List and share it with your friends.
                          </p>
                        </li>
                        <li>
                          <p>
                            <strong>+MEMBER</strong> all the Organizations you are a member of.
                          </p>
                        </li>
                      </ol>
                      <p style={{ marginBottom: 15 }}>
                        ** Friending, Trusting and being a Member of an organization on iTRUSTit are
                        all separate functions.
                      </p>
                      <div style={{ marginLeft: 25 }}>
                        <p>
                          <strong>Hint:</strong> Easy ways to find connections:
                        </p>
                        <ol type='a'>
                          <li>
                            <p>Go to a friend's page</p>
                          </li>
                          <li>
                            <p>Go to your Organization's page</p>
                          </li>
                          <li>
                            <p>
                              If you can't find them in iTRUSTit, <strong>Invite them!</strong>
                            </p>
                          </li>
                        </ol>
                        <p>
                          Although you can find over 2.5 million businesses n iTRUSTit, there are
                          not many people in the site yet. We are starting the Beta in phases, and
                          you are one of the first users in the system so there won't be many of
                          your friends in iTRUSTit yet… so invite them!
                        </p>
                        <p style={{ marginLeft: 25 }}>
                          - The more Friends and Organizations you have, the better your iTRUSTit
                          experience works for you!
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='card'>
                  <div className='card-header'>
                    <a className='card-link'>
                      <strong>If you have a Business or Organization REGISTER or CLAIM it:</strong>
                    </a>
                  </div>
                  <div className='collapse show' data-bs-parent='#accordion'>
                    <div className='card-body'>
                      <p>Note: For most users, this will be a second account.</p>
                      <ol type='i'>
                        <li>
                          <p>
                            If you find your Un-Claimed business in our database, be sure to claim
                            it!
                          </p>
                          <ol type='a'>
                            <li>
                              <p>
                                When Claiming your business, be sure your iTeam ID# is in the
                                Sponsor field.
                              </p>
                              <p style={{ marginLeft: 25 }}>
                                - Find your iTeam ID# on your Edit Profile page and on your Invite
                                Center page.
                              </p>
                            </li>
                          </ol>
                        </li>
                        <li>
                          <p>If you already have an iTRUSTit account and you have a business:</p>
                          <ol type='a'>
                            <li>
                              <p>Please ADD your business (or CLAIM it if it is already there)</p>
                            </li>
                            <li>
                              <p>
                                When you are logged in to your account: Click the ADD BUSINESS
                                button at the bottom of most pages and follow the ADD BUSINESS
                                process.
                              </p>
                            </li>
                            <li>
                              <p>Follow steps 2-7 again.</p>
                            </li>
                          </ol>
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
                <div className='card'>
                  <div className='card-header'>
                    <a className='card-link'>
                      <strong>INVITE People, Businesses and Organizations</strong>
                    </a>
                  </div>
                  <div className='collapse show' data-bs-parent='#accordion'>
                    <div className='card-body'>
                      <ol type='i'>
                        <li>
                          <p>
                            Go to your <strong>INVITE CENTER</strong> and use your invite links
                          </p>
                          <ul
                            style={{
                              paddingLeft: 40,
                              marginBottom: 16,
                              listStyle: 'initial',
                            }}
                          >
                            <li>
                              <p>
                                <a href={SITE_ADDRESS}>www.itrustit.com/invite/#####</a> where #####
                                is your unique iTeam ID
                              </p>
                            </li>

                            <li>
                              <p>
                                Or use <a href={SITE_ADDRESS}>www.itrustit.com/username</a> with
                                your username as this is a direct link to your profile page.
                              </p>
                            </li>

                            <li>
                              <p>Both will track new signups to you as their iTRUSTit sponsor</p>
                            </li>
                          </ul>
                        </li>
                        <li>
                          <p>
                            <strong>
                              Invite by Text, Email, Private Message (Facebook, LinkedIn, etc.)
                            </strong>
                          </p>
                          <p style={{ marginLeft: 25 }}>
                            - Don't mass invite by posting on social media (yet).
                          </p>
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
                <div className='card'>
                  <div className='card-header'>
                    <a className='card-link'>
                      <strong>Walk New Users In</strong>
                    </a>
                  </div>
                  <div className='collapse show' data-bs-parent='#accordion'>
                    <div className='card-body'>
                      <p>
                        When people accept your invitation to join, you will receive an email
                        notification.
                      </p>
                      <p>Take a minute, welcome them to iTRUSTit, and show them around!</p>
                      <div style={{ marginLeft: 25 }}>
                        <p>Here are a few helpful things to show them:</p>
                        <ul
                          style={{
                            paddingLeft: 40,
                            marginBottom: 16,
                            listStyle: 'unset',
                          }}
                        >
                          <li>
                            <p>How and where to find Friends.</p>
                          </li>
                          <li>
                            <p>How to invite others to join.</p>
                          </li>
                          <li>
                            <p>Share iTRUSTit on Facebook, LinkedIn, and Twitter.</p>
                          </li>
                          <li>
                            <p>
                              Find and become a 'Member' of the organizations they are a part of on
                              iTRUSTit.
                            </p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='card mb-5'
                  style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.15)' }}
                >
                  <div className='card-header'>
                    <a className='card-link'>
                      <strong>ADD Businesses and Organizations to our Database</strong>
                    </a>
                  </div>
                  <div className='collapse show' data-bs-parent='#accordion'>
                    <div className='card-body'>
                      <p style={{ marginLeft: 25 }}>
                        - Help us build iTRUSTit's database by ADDing other businesses and
                        organizations that are not in our system yet.
                      </p>
                      <ol>
                        <li>
                          <p>
                            <strong>To ADD a Business/Organization to the database:</strong>
                          </p>
                          <ol type='a'>
                            <li>
                              <p>
                                SEARCH for them in the Main Search at the top of the pages first...
                                (they may already be in the system)
                              </p>
                              <ol type='i'>
                                <li>
                                  <p>If you can't find them on the Main Search Results page:</p>
                                </li>
                                <li>
                                  <p>
                                    Click on the "<strong>ADD Business/Org</strong>" button and
                                    follow the instructions.
                                  </p>
                                </li>
                              </ol>
                            </li>
                          </ol>
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
              <h4 className='mb-2'>ADDITIONAL NOTES</h4>
              <div id='accordion2' className='faq_accordian'>
                <div className='card'>
                  <div className='card-header'>
                    <a className='card-link' style={{ borderTop: 'none' }}>
                      <strong>Feedback & Bug Reporting:</strong>
                    </a>
                  </div>
                  <div className='collapse show' data-bs-parent='#accordion'>
                    <div className='card-body'>
                      <p style={{ marginLeft: 25 }}>
                        Give us feedback on how we can improve the user experience!
                      </p>
                      <p style={{ marginLeft: 50 }}>
                        ScreenShots and videos of issues can be very helpful if you can get them.
                        Please email to <a href='mailto:admin@itrustit.com'>admin@itrustit.com</a>{' '}
                        with a description.
                      </p>
                    </div>
                  </div>
                </div>
                <div className='card'>
                  <div className='card-header'>
                    <a className='card-link'>
                      <strong>Limited Functionality</strong>
                    </a>
                  </div>
                  <div className='collapse show' data-bs-parent='#accordion'>
                    <div className='card-body'>
                      <p style={{ marginLeft: 25 }}>
                        It is iTRUSTit's intention to add more functionality on an on-going basis
                        approximately every 14 days or less. We have purposely limited our rollout
                        to focus on getting as many members as we can sign up in the next 30-60
                        days.
                      </p>
                    </div>
                  </div>
                </div>
                <div className='card'>
                  <div className='card-header'>
                    <a className='card-link'>
                      <strong>Coming Soon:</strong>
                    </a>
                  </div>
                  <div className='collapse show' data-bs-parent='#accordion'>
                    <div className='card-body'>
                      <ul
                        style={{
                          paddingLeft: 40,
                          marginBottom: 16,
                          listStyle: 'unset',
                        }}
                      >
                        <li>
                          <p>
                            Google Search API / enhanced database so there will be plenty of results
                            on all searches.
                          </p>
                        </li>
                        <li>
                          <p>Mobile APP</p>
                        </li>
                        <li>
                          <p>
                            Wall/Feed & Comments will be coming soon added to your page where your
                            posts and comments will be published out to your friends, groups and
                            members that follow you.
                          </p>
                        </li>
                        <li>
                          <p>
                            Socially Relevant Searches - When searching for a business or
                            organization, the ones that your friends have trusted should display
                            first.
                          </p>
                        </li>
                        <li>
                          <p>Improved Messaging system.</p>
                        </li>
                        <li>
                          <p>New photo/video uploading interface.</p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className='card'>
                  <div className='card-header'>
                    <a className='card-link'>
                      <strong>Current System Status</strong>
                    </a>
                  </div>
                  <div className='collapse show' data-bs-parent='#accordion'>
                    <div className='card-body'>
                      <p>
                        Give us your feedback on the iTRUSTit site and services and let us know of
                        any issues that might arise as you are testing. Below, we have provided a
                        list of known issues, bugs and enhancements that are currently being
                        addressed or under development, so please review the list and note that we
                        are aware of these issues:
                      </p>
                      <ol>
                        <li>
                          <p>
                            <strong>Main Search:</strong> We are currently upgrading our main
                            database so there will be plenty of results for whatever and wherever
                            you search. The new database and search engine will include more
                            categories, return extremely fast results and provide socially relevant
                            information tailored to you.
                          </p>
                        </li>
                        <li>
                          <p>
                            <strong>Mobile:</strong> Some mobile screens are still being worked on.
                          </p>
                        </li>
                        <li>
                          <p>
                            <strong>Follow:</strong> Follow will work when the Wall/Feed comes
                            online.
                          </p>
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
                <div className='card'>
                  <div className='card-header'>
                    <a className='card-link'>
                      <strong>You may experience one or more of the following:</strong>
                    </a>
                  </div>
                  <div className='collapse show' data-bs-parent='#accordion'>
                    <div className='card-body'>
                      <ul
                        style={{
                          paddingLeft: 40,
                          marginBottom: 16,
                          listStyle: 'unset',
                        }}
                      >
                        <li>
                          <p>
                            In some areas, you may receive slow or inaccurate searches relevant to
                            your zip code or city. We will keep you updated on the release date of
                            the new search engine.
                          </p>
                        </li>
                        <li>
                          <p>Invite and other email copy will be updated from time to time</p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className='card'>
                  <div className='card-header'>
                    <a className='card-link'>
                      <strong>Affiliate Revenue Sharing & Charter Member:</strong>
                    </a>
                  </div>
                  <div className='collapse show' data-bs-parent='#accordion'>
                    <div className='card-body'>
                      <p>
                        By inviting now, you can take full advantage of iTRUSTit's Affiliate Revenue
                        Sharing program.
                      </p>
                      <p>
                        The Affiliate Revenue Sharing System is a sales tool to increase the number
                        of users, and continually give back to the users who help iTRUSTit grow.
                      </p>
                      <ul
                        style={{
                          paddingLeft: 40,
                          marginBottom: 16,
                          listStyle: 'unset',
                        }}
                      >
                        <li>
                          <p>
                            For taking part in our beta launch you will be a '
                            <strong>Charter Member</strong>' of iTRUSTit. As we grow, this means
                            additional benefits for you and the people that sign up during Beta!
                          </p>
                        </li>
                        <li>
                          <p>
                            '<strong>Charter Members</strong>' will get a special Charter Member
                            badge on their profile and are locked in at up to a total of 18%
                            Affiliate Revenue Share*. Post-Beta this will be 11%*.
                          </p>
                        </li>
                        <li>
                          <p>
                            **IMPORTANT** - You may opt-in to iTRUSTit's Affiliate Revenue Sharing
                            "iPartner" Program and get rewarded for helping iTRUSTit grow. Inviting
                            a user to join iTRUSTit does not mean that you have to Trust them or be
                            their Friend.
                          </p>
                          <p style={{ marginLeft: 25 }}>
                            - On a different and independent level, you may choose to TRUST and
                            FRIEND businesses and organizations that you would refer to your
                            friends.
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div
                  className='card mb-5'
                  style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.15)' }}
                >
                  <div className='card-header'>
                    <a className='card-link'>
                      <strong>Learn more about iTRUSTit here:</strong>
                    </a>
                  </div>
                  <div className='collapse show' data-bs-parent='#accordion'>
                    <div className='card-body'>
                      <div style={{ marginLeft: 25 }}>
                        <p>
                          <a
                            href={`${SITE_ADDRESS.slice(0, -1)}${COMMUNITY_ROUTE}`}
                          >{`${SITE_ADDRESS.slice(0, -1)}${COMMUNITY_ROUTE}`}</a>
                        </p>
                        <p>
                          <a
                            href={`${SITE_ADDRESS.slice(0, -1)}${FAQ_ROUTE}`}
                          >{`${SITE_ADDRESS.slice(0, -1)}${FAQ_ROUTE}`}</a>
                        </p>
                        <p>
                          <a
                            href={`${SITE_ADDRESS.slice(0, -1)}${BETAREADME_ROUTE}`}
                          >{`${SITE_ADDRESS.slice(0, -1)}${BETAREADME_ROUTE}`}</a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <p className='finalP' style={{ marginBottom: 0 }}>
                  Thanks Again! We really appreciate your help and feedback!
                </p>
                <p className='finalP' style={{ marginBottom: 0 }}>
                  Wishing you a warm welcome,
                </p>
                <p className='finalP' style={{ marginBottom: 0 }}>
                  The iTRUSTit Team
                </p>
                <p className='finalP' style={{ marginBottom: 0 }}>
                  <a href='mailto:admin@itrustit.com'>admin@itrustit.com</a>
                </p>
              </div>
              <div className='it-faq-bottom'>
                <a
                  href={process.env.PUBLIC_URL + '/iTRUSTit_Beta_README.pdf'}
                  // onClick={this.downloadInstructionsAsPDF}
                  className='blue_btn'
                  download
                  target='_blank'
                >
                  Download README as PDF
                </a>
              </div>
            </div>
          </div>
        </section>
        <LoginModal
          isLogin={(e) =>
            function (data) {
              this.props.isLogin(data)
            }
          }
          {...this.props}
        />
      </AppLayout>
    )
  }
}

export default BetaReadme
