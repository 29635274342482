import axios from 'axios'
import { customLogger } from '../utils/CustomLogger'
import { GOOGLE_GEOCODING_API_KEY } from './environment'

export const getCurrentLocationString = async (cb = () => {}) => {
  cb('')
  if (navigator.geolocation) {
    return navigator.geolocation.getCurrentPosition(
      (position) => {
        const pos = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        }
        let REVERSE_GEOCODING_API_URL = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${pos.lat},${pos.lng}&result_type=political&key=${GOOGLE_GEOCODING_API_KEY}`
        axios
          .get(REVERSE_GEOCODING_API_URL)
          .then((res) => {
            if (res.data.results && res.data.results[0] && res.data.results[0].formatted_address) {
              cb(res.data.results[0].formatted_address)
            }
          })
          .catch((error) => {
            customLogger(error)
          })
      },
      () => {
        //Geolocator service failed
        cb('')
      },
    )
  } else {
    // Browser doesn't support Geolocation
    cb('')
  }
}
