import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import {
  profileInfo,
  editUserStore,
  coverImageAction,
  profileInfoReset,
} from '../../actions/profile'
import { userStoreAction } from '../../actions/auth'
import EditProfileNew from '../../components/EditProfileNew/EditProfile'
import cleanDeep from 'clean-deep'
import { toTitleCase } from '../../services/formatService'

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { profileInfo, editUserStore, userStoreAction, coverImageAction, profileInfoReset },
    dispatch,
  )
  // return {
  //     loginAction: bindActionCreators(loginAction, dispatch),
  // };
}

function mapStateToProps(state) {
  let user = cleanDeep(state.UserReducer.editUser)
  return {
    editUser: state.UserReducer.editUser,
    initialValues: {
      business_organization_name: user?.business_organization_name,
      sponsors_iteam_id: user?.imatrix_id,
      username: user?.username,
      firstname: user?.firstname,
      lastname: user?.lastname,
      email: user?.email,
      relationship_type: user?.relationship_type
        ? {
            value: user?.relationship_type,
            label: ['ceo', 'cmo', 'cfo', 'coo'].includes(user?.relationship_type)
              ? user?.relationship_type?.toUpperCase()
              : toTitleCase(user?.relationship_type),
          }
        : undefined,
      address_line_1: user?.address?.address_line_1,
      address_line_2: user?.address?.address_line_2,
      country_id: user?.address?.country_id
        ? { value: user?.address?.country_id, label: user?.address?.country?.name }
        : undefined,
      state_id: user?.address?.state_id
        ? { value: user?.address?.state_id, label: user?.address?.state?.name }
        : undefined,
      city_id: user?.address?.city_id
        ? { value: user?.address?.city_id, label: user?.address?.city?.name }
        : undefined,

      zipcode: user?.address?.zipcode,
      phone_no_1: user?.user_detail?.phone_no_1,
      phone_no_2: user?.user_detail?.phone_no_2,
      mobile_no: user?.user_detail?.mobile_no,
      fax_no: user?.user_detail?.fax_no,
      website_1: user?.user_detail?.website_1,
      website_2: user?.user_detail?.website_2,
      website_3: user?.user_detail?.website_3,
      website_4: user?.user_detail?.website_4,
      email_2: user?.user_detail?.email_2,
      email_3: user?.user_detail?.email_3,
      email_4: user?.user_detail?.email_4,
      facebook: user?.user_detail?.facebook,
      fiverr: user?.user_detail?.fiverr,
      instagram: user?.user_detail?.instagram,
      linkedin: user?.user_detail?.linkedin,
      pinterest: user?.user_detail?.pinterest,
      tiktok: user?.user_detail?.tiktok,
      twitter: user?.user_detail?.twitter,
      youtube: user?.user_detail?.youtube,
      whatsapp: user?.user_detail?.whatsapp,
      paypal: user?.user_detail?.paypal,
      venmo: user?.user_detail?.venmo,
      profession: user?.user_detail?.profession,
      about_me: user?.user_detail?.about_me,
      tagline: user?.user_detail?.tagline,
      gender: user?.user_detail?.gender,
      birth_date: user?.user_detail?.birth_date,
      area: user?.address?.area,
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditProfileNew)
