import React, { Component } from 'react'
import AppLayout from '../AppLayout'
import './ipartner.scss'

class iPartner extends Component {
  render() {
    return (
      <AppLayout>
        <section className='bg-grey'>
          <div className='container'>
            <h3 className='varela mb-3'>iPARTNER - iTRUSTit's AFFILIATE PROGRAM</h3>
            <div className='white-pane green_line'>
              <div className='white_pane_content'>
                <p className='mb-2 orange'> Take our Thanks to the Bank!</p>
                <p>
                  Our goal is to grow iTRUSTit into the best and most trusted source of information
                  on local businesses... around the world! You're a critical part of that and we
                  want to show our thanks.
                </p>
                <p>
                  Sure, having this fabulous resource is a cool reward. And helping your friends and
                  favorite local businesses find each other feels great. But we want to do more.
                </p>
                <p>
                  That's where our iPartner program comes in: we give a share of our revenue back to
                  you - the members who make it all possible.
                </p>
              </div>
            </div>
            {/* -----------------------------------------iBETA over-------------------------------------- */}

            <h3 className='varela mb-3 mt-5'>BUILD YOUR iTEAM</h3>
            <div className='white-pane green_line'>
              <div className='white_pane_content'>
                <p className='mb-2 orange'>Here's how it works:</p>
                <p>
                  Everyone you invite who joins iTRUSTit with you as their sponsor (and anyone they
                  invite ... out to seven degrees of separation!) is part of your iTeam.{' '}
                </p>
                <p>
                  {' '}
                  When any member of your iTeam (whether they're People, Businesses or
                  Organizations) purchases advertising or upgraded services from iTRUSTit (e.g.
                  coupon and rewards programs, premium marketing services, etc.) you share in that
                  revenue!*
                </p>
                <p>lt's that simple. No Strings Attached!*</p>
                <p>Because when we say thanks, you can take it to the bank!*</p>
                <p>
                  *iPARTNER is iTRUSTit's optional opt-in Affiliate Program that all registered
                  users are elegible to participate in for free. Like we said, No Strings
                  Attached!**
                </p>

                <p>
                  iTRUSTit also gives back to your favorite Charities, Associations and
                  Organizations,{' '}
                  <a href={null} className='blue'>
                    Click here to learn more!
                  </a>
                </p>

                <div className='img_footer'>
                  <img src={process.env.PUBLIC_URL + '/img/partner_list.png'} alt='food' />
                </div>
              </div>
            </div>
            {/* -----------------------------------------join business over-------------------------------------- */}
          </div>
        </section>
      </AppLayout>
    )
  }
}

export default iPartner
