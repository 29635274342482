import React from 'react'
import { Link } from 'react-router-dom'
import {
  EDIT_BUSINESS_PROFILE_ROUTE,
  EDIT_ORGANIZATION_PROFILE_ROUTE,
  EDIT_PROFILE_ROUTE,
  MARKETING_CENTER_ROUTE,
  PROFILE_ROUTE,
} from '../../../../services/routeConst'
import { showQRCode } from '../../../CommonComponents/QRCodeModal/QRCodeModal'
import SwitchAccountsModal from './SwitchAccountsModal'
import UserDropdownWrapper from './UserDropdownWrapper'

const userTypeRoutes = {
  individual: EDIT_PROFILE_ROUTE,
  business: EDIT_BUSINESS_PROFILE_ROUTE,
  organization: EDIT_ORGANIZATION_PROFILE_ROUTE,
}

const UserDropdown = ({
  readNotification,
  userNotificationCount,
  isMobile,
  user,
  userProfileAvatar,
  userFullname,
  userCoverImg,
  userName,
}) => {
  return (
    <UserDropdownWrapper
      isMobile={isMobile}
      readNotification={readNotification}
      userNotificationCount={userNotificationCount}
      userName={userName}
      userProfileAvatar={userProfileAvatar}
      userCoverImg={userCoverImg}
    >
      <li>
        <Link
          to={`/vcard/${user?.slug}`}
          className='vcard-btn'
          target='_blank'
          rel='noopener noreferrer'
        >
          My vCard
        </Link>
      </li>
      <li onClick={showQRCode}>
        <button className='share-btn text-center d-block'>Share / Invite</button>
      </li>
      <li>
        <Link className='edit' to={userTypeRoutes[user?.user_type ?? 'individual']}>
          Edit Profile
        </Link>
      </li>
      <li>
        <Link to={PROFILE_ROUTE + '#privacy-settings'} className='settings'>
          Settings
        </Link>
      </li>
      <li>
        <Link to={PROFILE_ROUTE + '#activity'} className='activity'>
          Activity
        </Link>
      </li>
      <li>
        <Link to={MARKETING_CENTER_ROUTE} className='marketing-center-dropdown-item'>
          Marketing Center
        </Link>
      </li>
      <li className='dropdown it-switch-account show'>
        <button className='switch dropdown-toggle' id='switchMenuButton' data-bs-toggle='dropdown'>
          Switch Accounts
        </button>
        <SwitchAccountsModal userProfileAvatar={userProfileAvatar} userFullname={userFullname} />
      </li>
    </UserDropdownWrapper>
  )
}

export default UserDropdown
