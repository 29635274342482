import React, { Component, Fragment } from 'react'

class ErrorMsgRenderer extends Component {
  render() {
    if (
      typeof this.props.errors === 'undefined' ||
      this.props.errors === null ||
      this.props.errors.length === 0
    ) {
      return null
    }
    const listErrors = this.props.errors.map((error, index) => (
      <span className='input_error text-danger' key={index}>
        {error}
      </span>
    ))

    return <Fragment>{listErrors}</Fragment>
  }
}

export default ErrorMsgRenderer
