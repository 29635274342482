import { motion } from 'framer-motion'
import React from 'react'
import Skeleton from 'react-loading-skeleton'

const catVariants = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  closed: {
    y: 0,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
}

const LoadingSuggestion = ({ isCat }) => {
  return (
    <motion.div className='col position-relative px-0' variants={catVariants}>
      <div className={`d-flex flex-column justify-content-center mobile-search-suggestion`}>
        <div
          className={`d-flex ${!isCat ? 'user-suggestion' : ''}`}
          style={{ marginLeft: '0.75rem' }}
        >
          {!isCat ? (
            <Skeleton className='user-suggestion-avatar' width={50} height={50} />
          ) : (
            <i
              className='fa fa-search'
              aria-hidden='true'
              style={{
                color: '#544CF9',
                fontSize: 16,
              }}
            ></i>
          )}

          <div className='d-flex flex-column search-suggestion-label'>
            <Skeleton count={isCat ? 1 : 3} />
          </div>
        </div>
        <hr
          className='mx-1'
          style={{
            marginTop: '0.5rem',
            marginBottom: '0.5rem',
            border: 0,
            height: 0,
            borderTop: '1px solid #544cf9',
            borderBottom: '1px solid rgba(255, 255, 255, 0.3)',
          }}
        />
      </div>
    </motion.div>
  )
}

export default LoadingSuggestion
