import React, { Fragment, useState } from 'react'
import ShowPasswordButton from '../CommonComponents/ShowPasswordButton/ShowPasswordButton'

export const RenderInputField = ({
  input,
  valueKey,
  label,
  placeholder,
  className,
  disabled,
  checked,
  defaultValue,
  type,
  customError,
  meta: { touched, error, warning },
  maxLength = null,
  isUsernameInput,
}) => {
  const [showPassword, setShowPassword] = useState(false)
  const hasError = !!touched || !!customError
  const renderedError = !!customError ? customError : error
  const extra = maxLength ? { maxLength } : {}
  return (
    <div className='position-relative'>
      {isUsernameInput ? (
        <span
          style={{
            position: 'absolute',
            top: hasError && renderedError ? '16%' : '25%',
            left: '4%',
            cursor: 'pointer',
            color: '#544cf9',
          }}
        >
          <img width='20' src={process.env.PUBLIC_URL + '/img/email_invite.svg'} alt='' />
        </span>
      ) : null}
      {valueKey !== undefined ? (
        <input
          {...input}
          autoComplete='off'
          placeholder={placeholder}
          value={valueKey}
          disabled={disabled}
          defaultValue={defaultValue}
          className={className}
          type={showPassword ? 'text' : type}
          {...extra}
        />
      ) : (
        <input
          {...input}
          autoComplete='off'
          placeholder={placeholder}
          className={className}
          type={showPassword ? 'text' : type}
          {...extra}
        />
      )}
      {type === 'password' ? (
        <ShowPasswordButton
          togglePassword={() => setShowPassword((st) => !st)}
          top={hasError && renderedError ? 20 : 32}
        />
      ) : null}
      {hasError &&
        ((renderedError && (
          <span className='text-danger' style={{ fontSize: '0.875rem' }}>
            {renderedError}
          </span>
        )) ||
          (warning && <span style={{ fontSize: '0.875rem' }}>{warning}</span>))}
    </div>
  )
}

export default RenderInputField
