import React from 'react'
import $ from 'jquery'
import { ItiText } from '../About/About'
import * as route from '../../services/routeConst'
import { Link } from 'react-router-dom'

export const showSignupDisclaimerModal = () => {
  $('#signup_disclaimer_modal')?.modal?.('show')
}
export const hideSignupDisclaimerModal = () => {
  $('#signup_disclaimer_modal')?.modal?.('hide')
}

let SignupDisclaimerModal = ({ user }) => {
  const editProfileRoute =
    route[
      `EDIT_${
        user.user_type === 'individual' ? '' : user.user_type?.toUpperCase() + '_'
      }PROFILE_ROUTE`
    ]
  return (
    <div className='modal fade signup_disclaimer_modal' id='signup_disclaimer_modal' role='dialog'>
      <div className='modal-dialog modal-md'>
        <div className='modal-content'>
          <div className='modal-header align-items-start'>
            <h2 className='varela ms-auto fw-bold' style={{ color: '#2c7dbc' }}>
              Welcome to <ItiText />!
            </h2>
            <button type='button' className='btn-close' data-bs-dismiss='modal'></button>
          </div>
          <div className='modal-body'>
            <div className='d-flex flex-column'>
              <p style={{ marginBottom: 16 }}>
                We just sent you a welcome email from{' '}
                <a href='mailto:noreply@mg.itrustitmail.com'>noreply@mg.itrustitmail.com</a> with
                some great tips and pointers to help you get the most out of your iTRUSTit
                experience!
              </p>
              <p style={{ marginBottom: 16 }}>
                If you cant find it check your junk and spam and be sure to whitelist us!
              </p>
              <p style={{ marginBottom: 16 }}>
                If you signed up with Apple, LinkedIn, Facebook, or Google, your privacy settings
                may have not let us access your Name, Address, Phone Number and/or Email. Please go
                to your Edit Profile Page and check that's all there and correct!
              </p>
              <p>You may find some other cool info you want to share too....</p>
              <p style={{ marginBottom: 16 }}>-Like your custom username... {':-)'}</p>
              <div className='d-flex align-self-center'>
                <Link
                  className='mont'
                  onClick={() => hideSignupDisclaimerModal()}
                  style={{
                    background: '#544cf9',
                    color: '#fff',
                    borderRadius: 4,
                    boxShadow: '0px -3px 0px inset #4741d4',
                    border: 'none',
                    fontSize: 12,
                    textTransform: 'uppercase',
                    fontWeight: 600,
                    display: 'inline-block',
                    padding: '14px 36px',
                    height: 'auto',
                  }}
                  to={editProfileRoute}
                >
                  Edit Profile
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SignupDisclaimerModal
