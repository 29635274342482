import axios from 'axios'
import { customLogger } from '../utils/CustomLogger'
import * as environemnt from './environment'
import { userToken, toaster } from './customFunction'
import { NETWORK_ERROR_MSG } from './const'

export const createMessageCall = (request, createMessageReqheaders) => {
  return axios
    .post(environemnt.BASE_API + 'chat/send', request, {
      headers: {
        Authorization: userToken(),
        Accept: 'application/x.iTRUSTit.v1+json',
        'X-Socket-ID': createMessageReqheaders['X-Socket-ID'],
      },
    })
    .then(function (response) {
      return response
    })
    .catch(function (error) {
      if (error === undefined || error.response === undefined) {
        toaster('error', NETWORK_ERROR_MSG)
      } else if ([401, 403, 500].indexOf(error.response.status) >= 0) {
        if (
          typeof error.response !== 'undefined' &&
          typeof error.response.data !== 'undefined' &&
          typeof error.response.data.message !== 'undefined' &&
          error.response.data.message
        ) {
          toaster('error', error.response.data.message)
        } else {
          toaster('error', 'Something went wrong!!!')
        }
      }
      customLogger(error)
      return error.response
    })
}

export const createGroupMessageCall = (request, createMessageReqheaders) => {
  return axios
    .post(environemnt.BASE_API + 'chat/send-to-group', request, {
      headers: {
        Authorization: userToken(),
        Accept: 'application/x.iTRUSTit.v1+json',
        'X-Socket-ID': createMessageReqheaders['X-Socket-ID'],
      },
    })
    .then(function (response) {
      return response
    })
    .catch(function (error) {
      if (error === undefined || error.response === undefined) {
        toaster('error', NETWORK_ERROR_MSG)
      } else if ([401, 403, 500].indexOf(error.response.status) >= 0) {
        if (error?.response?.data?.message) {
          toaster('error', error.response.data.message)
        } else {
          toaster('error', 'Something went wrong!!!')
        }
      }
      customLogger(error)
      return error.response
    })
}

export const getAllMessagesCall = (request) => {
  let api_url = environemnt.BASE_API + 'chat/get-all-messages/' + request.chat_user_id
  return axios
    .get(api_url, {
      headers: { Authorization: userToken(), Accept: 'application/x.iTRUSTit.v1+json' },
    })
    .then(function (response) {
      return response
    })
    .catch(function (error) {
      if (
        typeof error.response !== 'undefined' &&
        typeof error.response.data !== 'undefined' &&
        typeof error.response.data.message !== 'undefined' &&
        error.response.data.message
      ) {
        toaster('error', error.response.data.message)
      }
      customLogger(error)
      return error.response
    })
}

export const getAllGroupMessagesCall = (request) => {
  let api_url = environemnt.BASE_API + 'chat/get-all-group-messages/' + request.group_chat_id
  return axios
    .post(api_url, request, {
      headers: { Authorization: userToken(), Accept: 'application/x.iTRUSTit.v1+json' },
    })
    .then(function (response) {
      return response
    })
    .catch(function (error) {
      if (
        typeof error.response !== 'undefined' &&
        typeof error.response.data !== 'undefined' &&
        typeof error.response.data.message !== 'undefined' &&
        error.response.data.message
      ) {
        toaster('error', error.response.data.message)
      }
      customLogger(error)
      return error.response
    })
}

export const readMessages = (request) => {
  return axios
    .post(
      environemnt.BASE_API +
        `chat/read-${request?.group_chat_id ? 'group-' : ''}messages/` +
        (request?.group_chat_id ?? request.chat_user_id),
      request,
      {
        headers: {
          Authorization: userToken(),
          Accept: 'application/x.iTRUSTit.v1+json',
        },
      },
    )
    .then(function (response) {
      return response
    })
    .catch(function (error) {
      if (error === undefined || error.response === undefined) {
        toaster('error', NETWORK_ERROR_MSG)
      } else if ([401, 403, 500].indexOf(error.response.status) >= 0) {
        if (
          typeof error.response !== 'undefined' &&
          typeof error.response.data !== 'undefined' &&
          typeof error.response.data.message !== 'undefined' &&
          error.response.data.message
        ) {
          toaster('error', error.response.data.message)
        } else {
          toaster('error', 'Something went wrong!!!')
        }
      }
      customLogger(error)
      return error.response
    })
}

export const getChatUserListCall = (request) => {
  let api_url = environemnt.BASE_API + 'chat/get-user-list'
  return axios
    .get(api_url, {
      headers: { Authorization: userToken(), Accept: 'application/x.iTRUSTit.v1+json' },
    })
    .then(function (response) {
      return response
    })
    .catch(function (error) {
      if (
        typeof error.response !== 'undefined' &&
        typeof error.response.data !== 'undefined' &&
        typeof error.response.data.message !== 'undefined' &&
        error.response.data.message
      ) {
        toaster('error', error.response.data.message)
      }
      customLogger(error)
      return error.response
    })
}

export const readUserNotification = (request) => {
  let api_url = environemnt.BASE_API + 'read-user-notifications'
  return (
    axios
      .post(api_url, request, {
        headers: {
          Authorization: userToken(),
          Accept: 'application/x.iTRUSTit.v1+json',
        },
      })
      // .post(api_url, {
      //     headers: { Authorization: userToken(), Accept: "application/x.iTRUSTit.v1+json" },
      // })
      .then(function (response) {
        return response
      })
      .catch(function (error) {
        if (
          typeof error.response !== 'undefined' &&
          typeof error.response.data !== 'undefined' &&
          typeof error.response.data.message !== 'undefined' &&
          error.response.data.message
        ) {
          toaster('error', error.response.data.message)
        }
        customLogger(error)
        return error.response
      })
  )
}

export const checkITeamCount = (request) => {
  let api_url = environemnt.BASE_API + 'check-iteam-count'
  return (
    axios
      .post(api_url, request, {
        headers: {
          Authorization: userToken(),
          Accept: 'application/x.iTRUSTit.v1+json',
        },
      })
      // .post(api_url, {
      //     headers: { Authorization: userToken(), Accept: "application/x.iTRUSTit.v1+json" },
      // })
      .then(function (response) {
        return response
      })
      .catch(function (error) {
        if (
          typeof error.response !== 'undefined' &&
          typeof error.response.data !== 'undefined' &&
          typeof error.response.data.message !== 'undefined' &&
          error.response.data.message
        ) {
          toaster('error', error.response.data.message)
        }
        customLogger(error)
        return error.response
      })
  )
}
