import React, { Component, Fragment } from 'react'
import ErrorMsgRenderer from '../../ErrorMsgRenderer/ErrorMsgRenderer'
// import Datetime from "react-datetime";
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

import moment from 'moment'
import 'react-datetime/css/react-datetime.css'
import ReactStars from 'react-stars'
import {
  STAR_RATING_DEFAULT_ACTIVE_COLOR,
  STAR_RATING_DEFAULT_INACTIVE_COLOR,
  STAR_RATING_REVIEW_CREATE_SECTION_SIZE,
} from '../../../services/const'
import * as reviewApiService from '../../../services/reviewApiService'
import { toaster, getUser } from '../../../services/customFunction'
import $, { get } from 'jquery'
import MenuTypeTabs from '../MenuTypeTabs.jsx/MenuTypeTabs.jsx'

class ReviewCreate extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: false,
      submitBtnText: 'SUBMIT REVIEW',
      submitBtnLoadingText: 'Loading...',
      reviewCreateFormErr: {
        is_used: [],
        used_date: [],
        review_question_1: [],
        review_question_2: [],
        review_question_3: [],
        review_question_4: [],
        review_question_5: [],
        description: [],
      },
      is_used: null,
      used_date: '',
      review_question_average_rating: 0,
      review_question_1: 0,
      review_question_2: 0,
      review_question_3: 0,
      review_question_4: 0,
      review_question_5: 0,
      description: '',
      description_min_length: 30,
      description_max_length: 2500,
      description_characters_left: 2500,
      reviewData: [],
      isDisabled: '0',
      review_id: '',
      otherUser: props.otherUser ? props.otherUser : false,
      user_type: '',
    }
  }

  componentDidMount() {
    //if (this.state.otherUser == true) this.otherUserReview();
    if (this.state.otherUser === false) this.userLoginReview()
    //console.log('this.props.user_id : ', this.props.user_id);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.user_type) {
      this.setState({
        user_type: nextProps.user_type,
      })
    }

    if (nextProps.otherUser === false) {
      if (nextProps.isLoginUserClick === false) {
        let reviewData = nextProps.user

        if (reviewData && reviewData.description) {
          // console.log("nextProps  11");

          this.setState({
            isDisabled: getUser().id !== reviewData.user_id ? '1' : '0',
            review_question_1: reviewData.review_question_1,
            review_question_2: reviewData.review_question_2,
            review_question_3: reviewData.review_question_3,
            review_question_4: reviewData.review_question_4,
            review_question_5: reviewData.review_question_5,
            description: reviewData.description,
            is_used: reviewData.is_used,
            used_date: moment(reviewData.used_date).format('MM/DD/YYYY'),
            review_question_average_rating: reviewData.average_review,
            description_characters_left:
              this.state.description_max_length -
              reviewData.description.substring(0, this.state.description_max_length).length,
          })
        }
      }

      if (nextProps.isLoginUserClick === true) {
        this.userLoginReview()
      }
    }

    if (nextProps.otherUser === true) {
      let reviewData = nextProps.user
      // console.log("reviewData72", reviewData);

      if (reviewData && reviewData.description) {
        // console.log("nextProps  22", reviewData);

        this.setState({
          isDisabled: '1',
          review_question_1: reviewData.review_question_1,
          review_question_2: reviewData.review_question_2,
          review_question_3: reviewData.review_question_3,
          review_question_4: reviewData.review_question_4,
          review_question_5: reviewData.review_question_5,
          description: reviewData.description,
          is_used: reviewData.is_used,
          used_date: moment(reviewData.used_date).format('MM/DD/YYYY'),
          review_question_average_rating: reviewData.average_review,
          description_characters_left:
            this.state.description_max_length -
            reviewData.description.substring(0, this.state.description_max_length).length,
        })
      }
    }
  }

  userLoginReview = () => {
    //console.log("call userLoginReview==>", this.props);

    let self = this
    reviewApiService
      .loginUserReview({ other_user_id: this.props.user_id })
      .then(function (response) {
        // console.log("response", response);

        if (response.status === 201) {
          let reviewData = response.data.data
          // console.log("reviewData.user_id", reviewData);
          // console.log("user_id", getUser().id);
          // console.log("nextProps 33", reviewData)
          if (reviewData) {
            self.setState({
              isDisabled: getUser().id !== reviewData.user_id ? '1' : '0',
              review_question_1: reviewData.review_question_1,
              review_question_2: reviewData.review_question_2,
              review_question_3: reviewData.review_question_3,
              review_question_4: reviewData.review_question_4,
              review_question_5: reviewData.review_question_5,
              description: reviewData.description,
              is_used: reviewData.is_used,
              used_date: moment(reviewData.used_date).format('MM/DD/YYYY'),
              review_question_average_rating: reviewData.average_review,
              description_characters_left:
                self.state.description_max_length -
                reviewData.description.substring(0, self.state.description_max_length).length,
            })
          }
          if (reviewData == null) {
            self.setState({ isDisabled: '0' }, () => {
              self.resetState()
            })
          }
        }
      })
  }

  otherUserReview = () => {}

  resetState() {
    this.setState({
      isLoading: false,
      reviewCreateFormErr: {
        is_used: [],
        used_date: [],
        review_question_1: [],
        review_question_2: [],
        review_question_3: [],
        review_question_4: [],
        review_question_5: [],
        description: [],
      },
      is_used: null,
      used_date: '',
      review_question_average_rating: 0,
      review_question_1: 0,
      review_question_2: 0,
      review_question_3: 0,
      review_question_4: 0,
      review_question_5: 0,
      description: '',
      description_characters_left: 2500,
    })
  }

  reviewCreateFormValidate(elementName = '') {
    let isFormValid = true
    let stateCopy = { ...this.state }
    //console.log("stateCopy", stateCopy);

    // stateCopy.reviewCreateFormErr['is_used'] = [];
    // stateCopy.reviewCreateFormErr['used_date'] = [];
    // stateCopy.reviewCreateFormErr['review_question_1'] = [];
    // stateCopy.reviewCreateFormErr['review_question_2'] = [];
    // stateCopy.reviewCreateFormErr['review_question_3'] = [];

    if (elementName === '' || elementName === 'is_used') {
      stateCopy.reviewCreateFormErr['is_used'] = []
    }
    if (elementName === '' || elementName === 'used_date' || elementName === 'is_used') {
      stateCopy.reviewCreateFormErr['used_date'] = []
    }
    if (elementName === '' || elementName === 'review_question_1') {
      stateCopy.reviewCreateFormErr['review_question_1'] = []
    }
    if (elementName === '' || elementName === 'review_question_2') {
      stateCopy.reviewCreateFormErr['review_question_2'] = []
    }
    if (elementName === '' || elementName === 'review_question_3') {
      stateCopy.reviewCreateFormErr['review_question_3'] = []
    }
    if (elementName === '' || elementName === 'description') {
      stateCopy.reviewCreateFormErr['description'] = []
    }

    if (this.state.is_used === null && (elementName === '' || elementName === 'is_used')) {
      stateCopy.reviewCreateFormErr['is_used'] = ['Please select Yes or No']
      isFormValid = false
    }
    if (
      this.state.is_used === 1 &&
      this.state.used_date === '' &&
      (elementName === '' || elementName === 'used_date' || elementName === 'is_used')
    ) {
      stateCopy.reviewCreateFormErr['used_date'] = ['Please select date']
      isFormValid = false
    } else if (
      this.state.is_used === 1 &&
      typeof this.state.used_date !== 'object' &&
      this.state.used_date === ''
    ) {
      // console.log("elementName", elementName);

      stateCopy.reviewCreateFormErr['used_date'] = ['Please select valid date']
      isFormValid = false
    }
    if (
      this.state.review_question_1 === 0 &&
      (elementName === '' || elementName === 'review_question_1')
    ) {
      stateCopy.reviewCreateFormErr['review_question_1'] = ['Please select atleast 1']
      isFormValid = false
    }
    if (
      this.state.review_question_2 === 0 &&
      (elementName === '' || elementName === 'review_question_2')
    ) {
      stateCopy.reviewCreateFormErr['review_question_2'] = ['Please select atleast 1']
      isFormValid = false
    }
    if (
      this.state.review_question_3 === 0 &&
      (elementName === '' || elementName === 'review_question_3')
    ) {
      stateCopy.reviewCreateFormErr['review_question_3'] = ['Please select atleast 1']
      isFormValid = false
    }

    if (this.state.description === '' && (elementName === '' || elementName === 'description')) {
      stateCopy.reviewCreateFormErr['description'] = ['Please write a review']
      isFormValid = false
    }
    if (
      this.state.description !== '' &&
      this.state.description.length < this.state.description_min_length &&
      (elementName === '' || elementName === 'description')
    ) {
      stateCopy.reviewCreateFormErr['description'] = [
        'Please write review atleast ' + this.state.description_min_length + ' characters',
      ]
      isFormValid = false
    }

    this.setState(stateCopy)
    return isFormValid
  }

  reviewCreateFormSubmitHandler = (event) => {
    event.preventDefault()
    let isFormValid = this.reviewCreateFormValidate()
    if (isFormValid) {
      this.setState(
        {
          isLoading: true,
        },
        () => {
          this.setState({
            isLoading: false,
          })
          let reviewCreateReqData = {
            is_used: this.state.is_used,
            //used_date: ( typeof this.state.used_date == 'object' ? this.state.used_date.format("YYYY-MM-DD") : null ),
            review_question_1: this.state.review_question_1,
            review_question_2: this.state.review_question_2,
            review_question_3: this.state.review_question_3,
            review_question_4: this.state.review_question_4,
            review_question_5: this.state.review_question_5,
            description: this.state.description,
          }
          // console.log("this.state.used_date :",this.state.used_date)
          if (typeof this.state.used_date === 'object') {
            reviewCreateReqData['used_date'] = moment(this.state.used_date).format('YYYY-MM-DD')
          }

          const isUpdate = this.state.isEditing && getUser()?.id === this.props.user?.user_id
          if (isUpdate) {
            reviewCreateReqData['review_id'] = this.props.user?.id
          }

          const self = this
          let other_user_id = isUpdate ? this.props.user.review_user_id : this.props.user_id
          reviewApiService
            .createReviewCall(reviewCreateReqData, other_user_id)
            .then(function (response) {
              if (response !== undefined && response.status === 422) {
                self.setState({
                  isLoading: false,
                })
                self.setState({
                  reviewCreateFormErr: response.data.data,
                })
              } else if (response !== undefined && response.status === 200) {
                $('#reviewCreateModal').modal('hide')
                toaster('success', response.data.message)
                if (typeof self.props.reviewCreatedSuccessfullyHandler === 'function') {
                  self.props.reviewCreatedSuccessfullyHandler()
                }
                // self.galleryListRender();
                // setTimeout(function(){
                //     $('#reviewCreateModal').modal('hide');
                //     toaster("success", response.data.message);
                // }, 1000);
              } else {
                self.setState({
                  isLoading: false,
                })
              }
            })
        },
      )
    }
  }

  isUsedChanged = (event) => {
    this.setState(
      {
        is_used: event.currentTarget.value,
      },
      () => {
        this.reviewCreateFormValidate('is_used')
      },
    )
  }

  descriptionChanged = (event) => {
    let description_characters_left =
      this.state.description_max_length -
      event.target.value.substring(0, this.state.description_max_length).length
    this.setState(
      {
        description: event.target.value.substring(0, this.state.description_max_length),
        description_characters_left: description_characters_left,
      },
      () => {
        this.reviewCreateFormValidate('description')
      },
    )
  }

  datePickerHandler = (event) => {
    if (this.state.isDisabled === '0') {
      if (typeof event === 'object') {
        this.setState({ used_date: event }, () => {
          this.reviewCreateFormValidate('used_date')
        })
      } else {
        this.setState({ used_date: event }, () => {
          this.reviewCreateFormValidate('used_date')
        })
      }
    }
  }

  review_question_1_rating_changed = (newRating) => {
    let review_question_average_rating =
      (newRating +
        this.state.review_question_2 +
        this.state.review_question_3 +
        this.state.review_question_4 +
        this.state.review_question_5) /
      5
    this.setState(
      {
        review_question_1: newRating,
        review_question_average_rating: review_question_average_rating,
      },
      () => {
        this.reviewCreateFormValidate('review_question_1')
      },
    )
  }

  review_question_2_rating_changed = (newRating) => {
    let review_question_average_rating =
      (this.state.review_question_1 +
        newRating +
        this.state.review_question_3 +
        this.state.review_question_4 +
        this.state.review_question_5) /
      5
    this.setState(
      {
        review_question_2: newRating,
        review_question_average_rating: review_question_average_rating,
      },
      () => {
        this.reviewCreateFormValidate('review_question_2')
      },
    )
  }

  review_question_3_rating_changed = (newRating) => {
    let review_question_average_rating =
      (this.state.review_question_1 +
        this.state.review_question_2 +
        newRating +
        this.state.review_question_4 +
        this.state.review_question_5) /
      5
    this.setState(
      {
        review_question_3: newRating,
        review_question_average_rating: review_question_average_rating,
      },
      () => {
        this.reviewCreateFormValidate('review_question_3')
      },
    )
  }

  review_question_4_rating_changed = (newRating) => {
    let review_question_average_rating =
      (this.state.review_question_1 +
        this.state.review_question_2 +
        this.state.review_question_3 +
        newRating +
        this.state.review_question_5) /
      5
    this.setState(
      {
        review_question_4: newRating,
        review_question_average_rating: review_question_average_rating,
      },
      () => {
        this.reviewCreateFormValidate('review_question_4')
      },
    )
  }

  review_question_5_rating_changed = (newRating) => {
    let review_question_average_rating =
      (this.state.review_question_1 +
        this.state.review_question_2 +
        this.state.review_question_3 +
        this.state.review_question_4 +
        newRating) /
      5
    this.setState(
      {
        review_question_5: newRating,
        review_question_average_rating: review_question_average_rating,
      },
      () => {
        this.reviewCreateFormValidate('review_question_5')
      },
    )
  }

  handleEditClick = () => {
    this.setState({ isEditing: 'edit', isDisabled: '0' })
  }

  handleDisputeClick = () => {
    this.setState({
      isDisputing: 'dispute',
      showDisputeComingSoonText: !this.state.showDisputeComingSoonText,
    })
  }

  render() {
    const {
      review_question_1,
      review_question_2,
      review_question_3,
      review_question_4,
      review_question_5,
      review_question_average_rating,
      description,
      description_min_length,
      // description_max_length,
      description_characters_left,
      // reviewData,
      isDisabled,
    } = this.state

    // let self = this;

    const isGivenReview = this.props?.user?.user_id === getUser()?.id //when clicked on existing review, this.props.user is actually a review object (Check ReviewListDetail.reviewUser())
    const isRecievedReview = this.props?.user?.review_user_id === getUser()?.id
    return (
      <Fragment>
        {/* <a href={null} className="claim_link" data-bs-toggle="modal" data-bs-target="#reviewCreateModal">Write Review </a> */}
        {/* ------------------------------------------- modal start ------------------------------------------- */}
        <div className='modal fade review_modal' id='reviewCreateModal' role='dialog'>
          <div className='modal-dialog'>
            <div className='modal-content'>
              <div className='modal-header'>
                <h5>
                  {isGivenReview ? 'Your review for' : 'Review'} {this.props.user.fullname}
                </h5>
                <button type='button' className='btn-close' data-bs-dismiss='modal'></button>
              </div>
              <div className='modal-body pt-0'>
                {isGivenReview ? (
                  <div className='d-flex justify-content-end'>
                    <MenuTypeTabs
                      menus={[{ type: 'edit', label: 'Edit' }]}
                      menuType={this.state.isEditing}
                      handleChange={this.handleEditClick}
                      viewsClass='justify-content-end'
                      buttonClass='no-color iti-btn'
                    />
                  </div>
                ) : isRecievedReview ? (
                  <div className='d-flex flex-column'>
                    <div className='d-flex justify-content-end'>
                      <MenuTypeTabs
                        menus={[{ type: 'dispute', label: 'Dispute' }]}
                        menuType={this.state.isDisputing}
                        handleChange={this.handleDisputeClick}
                        viewsClass='justify-content-end'
                        buttonClass='no-color iti-btn'
                      />
                    </div>

                    {this.state.showDisputeComingSoonText ? (
                      <div className='d-flex justify-content-end'>
                        <small className='text-danger'>This feature is coming soon</small>
                      </div>
                    ) : null}
                  </div>
                ) : null}
                <form name='reviewCreateForm' onSubmit={this.reviewCreateFormSubmitHandler}>
                  <div className='que1'>
                    <div className='q_inside d-flex'>
                      <span>
                        Did you use this{' '}
                        {this.state.user_type === 'business' ? 'business' : 'organization'}?
                      </span>
                      <div className='yesno'>
                        <label className='radioblk'>
                          <input
                            type='radio'
                            name='is_used'
                            value='1'
                            onChange={this.isUsedChanged}
                            checked={this.state.is_used === '1'}
                            disabled={isDisabled === '0' ? false : true}
                          />
                          <span className='checkmark' /> Yes
                        </label>
                        <label className='radioblk'>
                          <input
                            type='radio'
                            name='is_used'
                            value='0'
                            onChange={this.isUsedChanged}
                            checked={this.state.is_used === '0'}
                            disabled={isDisabled === '0' ? false : true}
                          />
                          <span className='checkmark' /> No
                        </label>
                      </div>
                    </div>
                    <div>
                      <ErrorMsgRenderer
                        errors={
                          typeof this.state.reviewCreateFormErr.is_used !== 'undefined' &&
                          Array.isArray(this.state.reviewCreateFormErr.is_used) &&
                          this.state.reviewCreateFormErr.is_used.length > 0
                            ? this.state.reviewCreateFormErr.is_used
                            : []
                        }
                      />
                    </div>
                  </div>
                  <div className='que2'>
                    <div className='q_inside d-flex'>
                      <span>
                        When did you use this{' '}
                        {this.state.user_type === 'business' ? 'business' : 'organization'}?
                      </span>
                      <div className='inputright'>
                        {/* <Datetime
                                                    name="used_date"
                                                    timeFormat={false}
                                                    dateFormat="MM/DD/YYYY"
                                                    // defaultValue={moment()}
                                                    value={this.state.used_date}
                                                    closeOnSelect={true}
                                                    closeOnTab={true}
                                                    onChange={this.datePickerHandler}
                                                    // to={new Date()}
                                                    inputProps={{
                                                        disabled: isDisabled == "0" ? false : true,
                                                    }}
                                                /> */}
                        <DatePicker
                          name='used_date'
                          selected={
                            this.state.used_date !== '' ? new Date(this.state.used_date) : null
                          }
                          closeOnSelect={true}
                          closeOnTab={true}
                          onChange={this.datePickerHandler}
                          inputProps={{
                            disabled: isDisabled === '0' ? false : true,
                          }}
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode='select'
                          maxDate={new Date()}
                        />
                      </div>
                    </div>
                    <div>
                      <ErrorMsgRenderer
                        errors={
                          typeof this.state.reviewCreateFormErr.used_date !== 'undefined' &&
                          Array.isArray(this.state.reviewCreateFormErr.used_date) &&
                          this.state.reviewCreateFormErr.used_date.length > 0
                            ? this.state.reviewCreateFormErr.used_date
                            : []
                        }
                      />
                    </div>
                  </div>
                  <div className='que3'>
                    <ul>
                      <li>
                        <div className='q_inside d-flex'>
                          <div className='que_text'>
                            How strongly would you recommend this{' '}
                            {this.state.user_type === 'business' ? 'business' : 'organization'} to
                            your friends?
                          </div>
                          {isDisabled === '0' && (
                            <ReactStars
                              edit={true}
                              half={false}
                              className='review-create-section-star'
                              size={STAR_RATING_REVIEW_CREATE_SECTION_SIZE}
                              color1={STAR_RATING_DEFAULT_INACTIVE_COLOR}
                              color2={STAR_RATING_DEFAULT_ACTIVE_COLOR}
                              value={review_question_1}
                              onChange={this.review_question_1_rating_changed}
                            />
                          )}

                          {isDisabled === '1' && (
                            <ReactStars
                              edit={false}
                              half={false}
                              className='review-create-section-star'
                              size={STAR_RATING_REVIEW_CREATE_SECTION_SIZE}
                              color1={STAR_RATING_DEFAULT_INACTIVE_COLOR}
                              color2={STAR_RATING_DEFAULT_ACTIVE_COLOR}
                              value={review_question_1}
                              onChange={this.review_question_1_rating_changed}
                            />
                          )}
                          {/* <div className="stars">
                                                        <p className="varela">Very strongly</p>
                                                    </div> */}
                        </div>
                        <div>
                          <ErrorMsgRenderer
                            errors={
                              typeof this.state.reviewCreateFormErr.review_question_1 !==
                                'undefined' &&
                              Array.isArray(this.state.reviewCreateFormErr.review_question_1) &&
                              this.state.reviewCreateFormErr.review_question_1.length > 0
                                ? this.state.reviewCreateFormErr.review_question_1
                                : []
                            }
                          />
                        </div>
                      </li>
                      <li>
                        <div className='q_inside d-flex'>
                          <div className='que_text'>
                            How well did their products or services meet your expectations?
                          </div>
                          {isDisabled === '0' && (
                            <ReactStars
                              edit={true}
                              half={false}
                              className='review-create-section-star'
                              size={STAR_RATING_REVIEW_CREATE_SECTION_SIZE}
                              color1={STAR_RATING_DEFAULT_INACTIVE_COLOR}
                              color2={STAR_RATING_DEFAULT_ACTIVE_COLOR}
                              value={review_question_2}
                              onChange={this.review_question_2_rating_changed}
                            />
                          )}
                          {isDisabled === '1' && (
                            <ReactStars
                              edit={false}
                              half={false}
                              className='review-create-section-star'
                              size={STAR_RATING_REVIEW_CREATE_SECTION_SIZE}
                              color1={STAR_RATING_DEFAULT_INACTIVE_COLOR}
                              color2={STAR_RATING_DEFAULT_ACTIVE_COLOR}
                              value={review_question_2}
                              onChange={this.review_question_2_rating_changed}
                            />
                          )}
                          {/* <div className="stars">
                                                        <p className="varela">Very strongly</p>
                                                    </div> */}
                        </div>
                        <div>
                          <ErrorMsgRenderer
                            errors={
                              typeof this.state.reviewCreateFormErr.review_question_2 !==
                                'undefined' &&
                              Array.isArray(this.state.reviewCreateFormErr.review_question_2) &&
                              this.state.reviewCreateFormErr.review_question_2.length > 0
                                ? this.state.reviewCreateFormErr.review_question_2
                                : []
                            }
                          />
                        </div>
                      </li>
                      <li>
                        <div className='q_inside d-flex'>
                          <div className='que_text'>
                            How professional was this{' '}
                            {this.state.user_type === 'business' ? 'business' : 'organization'}?{' '}
                          </div>
                          {isDisabled === '0' && (
                            <ReactStars
                              edit={true}
                              half={false}
                              className='review-create-section-star'
                              size={STAR_RATING_REVIEW_CREATE_SECTION_SIZE}
                              color1={STAR_RATING_DEFAULT_INACTIVE_COLOR}
                              color2={STAR_RATING_DEFAULT_ACTIVE_COLOR}
                              value={review_question_3}
                              onChange={this.review_question_3_rating_changed}
                            />
                          )}

                          {isDisabled === '1' && (
                            <ReactStars
                              edit={false}
                              half={false}
                              className='review-create-section-star'
                              size={STAR_RATING_REVIEW_CREATE_SECTION_SIZE}
                              color1={STAR_RATING_DEFAULT_INACTIVE_COLOR}
                              color2={STAR_RATING_DEFAULT_ACTIVE_COLOR}
                              value={review_question_3}
                              onChange={this.review_question_3_rating_changed}
                            />
                          )}
                          {/* <div className="stars">
                                                        <p className="varela">Very strongly</p>
                                                    </div> */}
                        </div>
                        <div>
                          <ErrorMsgRenderer
                            errors={
                              typeof this.state.reviewCreateFormErr.review_question_3 !==
                                'undefined' &&
                              Array.isArray(this.state.reviewCreateFormErr.review_question_3) &&
                              this.state.reviewCreateFormErr.review_question_3.length > 0
                                ? this.state.reviewCreateFormErr.review_question_3
                                : []
                            }
                          />
                        </div>
                      </li>
                      <li>
                        <div className='q_inside d-flex'>
                          <div className='que_text'>
                            How would you rate the employees who served or helped you? (optional)
                          </div>
                          {isDisabled === '0' && (
                            <ReactStars
                              edit={true}
                              half={false}
                              className='review-create-section-star'
                              size={STAR_RATING_REVIEW_CREATE_SECTION_SIZE}
                              color1={STAR_RATING_DEFAULT_INACTIVE_COLOR}
                              color2={STAR_RATING_DEFAULT_ACTIVE_COLOR}
                              value={review_question_4}
                              onChange={this.review_question_4_rating_changed}
                            />
                          )}

                          {isDisabled === '1' && (
                            <ReactStars
                              edit={false}
                              half={false}
                              className='review-create-section-star'
                              size={STAR_RATING_REVIEW_CREATE_SECTION_SIZE}
                              color1={STAR_RATING_DEFAULT_INACTIVE_COLOR}
                              color2={STAR_RATING_DEFAULT_ACTIVE_COLOR}
                              value={review_question_4}
                              onChange={this.review_question_4_rating_changed}
                            />
                          )}
                          {/* <div className="stars">
                                                        <p className="varela">Very strongly</p>
                                                    </div> */}
                        </div>
                        <div>
                          <ErrorMsgRenderer
                            errors={
                              typeof this.state.reviewCreateFormErr.review_question_4 !==
                                'undefined' &&
                              Array.isArray(this.state.reviewCreateFormErr.review_question_4) &&
                              this.state.reviewCreateFormErr.review_question_4.length > 0
                                ? this.state.reviewCreateFormErr.review_question_4
                                : []
                            }
                          />
                        </div>
                      </li>
                      <li>
                        <div className='q_inside d-flex'>
                          <div className='que_text'>
                            How clean and well-maintained was the{' '}
                            {this.state.user_type === 'business' ? 'business' : 'organization'}?
                            (optional)
                          </div>
                          {isDisabled === '0' && (
                            <ReactStars
                              edit={true}
                              half={false}
                              className='review-create-section-star'
                              size={STAR_RATING_REVIEW_CREATE_SECTION_SIZE}
                              color1={STAR_RATING_DEFAULT_INACTIVE_COLOR}
                              color2={STAR_RATING_DEFAULT_ACTIVE_COLOR}
                              value={review_question_5}
                              onChange={this.review_question_5_rating_changed}
                            />
                          )}

                          {isDisabled === '1' && (
                            <ReactStars
                              edit={false}
                              half={false}
                              className='review-create-section-star'
                              size={STAR_RATING_REVIEW_CREATE_SECTION_SIZE}
                              color1={STAR_RATING_DEFAULT_INACTIVE_COLOR}
                              color2={STAR_RATING_DEFAULT_ACTIVE_COLOR}
                              value={review_question_5}
                              onChange={this.review_question_5_rating_changed}
                            />
                          )}
                          {/* <div className="stars">
                                                        <p className="varela">Very strongly</p>
                                                    </div> */}
                        </div>
                        <div>
                          <ErrorMsgRenderer
                            errors={
                              typeof this.state.reviewCreateFormErr.review_question_5 !==
                                'undefined' &&
                              Array.isArray(this.state.reviewCreateFormErr.review_question_5) &&
                              this.state.reviewCreateFormErr.review_question_5.length > 0
                                ? this.state.reviewCreateFormErr.review_question_5
                                : []
                            }
                          />
                        </div>
                      </li>
                    </ul>
                    <div className='average'>Average Rating: {review_question_average_rating}</div>
                  </div>
                  <div className='que4'>
                    <div className='d-flex justify-content-between'>
                      <h5>
                        Write a Review.
                        <span> {description_min_length} character minimum please</span>
                      </h5>
                      <div className='char_left'>{description_characters_left} characters Left</div>
                    </div>
                    <div>
                      <textarea
                        name='description'
                        value={description}
                        onChange={this.descriptionChanged}
                        placeholder={
                          'Your review helps others learn about great local ' +
                          (this.state.user_type === 'business' ? 'businesses' : 'organizations') +
                          '...'
                        }
                        className=''
                        disabled={isDisabled === '0' ? false : true}
                      />
                      <ErrorMsgRenderer
                        errors={
                          typeof this.state.reviewCreateFormErr.description !== 'undefined' &&
                          Array.isArray(this.state.reviewCreateFormErr.description) &&
                          this.state.reviewCreateFormErr.description.length > 0
                            ? this.state.reviewCreateFormErr.description
                            : []
                        }
                      />
                      {/* <button type="submit" className="submit_btn">SUBMIT REVIEW</button> */}
                      {isDisabled === '0' && (
                        <button
                          type='submit'
                          className='submit_btn'
                          disabled={this.state.isLoading}
                        >
                          {this.state.isLoading
                            ? this.state.submitBtnLoadingText
                            : this.state.isEditing
                            ? 'UPDATE REVIEW'
                            : this.state.submitBtnText}
                        </button>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {/* ------------------------------------------- modal over------------------------------------------- */}
      </Fragment>
    )
  }
}

export default ReviewCreate
