const ITRUST_PREFIX = 'iTRUSTit - '
export const INDEX_TITLE = ITRUST_PREFIX + 'Trusted Referrals'
export const HOME_TITLE = ITRUST_PREFIX + 'Home'
export const LOGIN_TITLE = ITRUST_PREFIX + 'Login'
export const SIGNUP_TITLE = ITRUST_PREFIX + 'Personal Signup'
export const SIGNUP_BUSINESS_TITLE_PS = ITRUST_PREFIX + 'Business Signup - Presearch'
export const SIGNUP_ORGANIZATION_TITLE_PS = ITRUST_PREFIX + 'Organization Signup - Presearch'
export const ADD_BUSINESS_TITLE_PS = ITRUST_PREFIX + 'Add Business - Presearch'
export const ADD_ORGANIZATION_TITLE_PS = ITRUST_PREFIX + 'Add Organization - Presearch'
export const CLAIM_BUS_ORG_TITLE = ITRUST_PREFIX + 'Claim Business / Organization - Presearch'
export const SIGNUP_BUSINESS_TITLE = ITRUST_PREFIX + 'Business Signup'
export const SIGNUP_ORGANIZATION_TITLE = ITRUST_PREFIX + 'Organization Signup'
export const ADD_BUSINESS_TITLE = ITRUST_PREFIX + 'Add Business'
export const ADD_ORGANIZATION_TITLE = ITRUST_PREFIX + 'Add Organization'
export const CLAIM_BUSINESS_TITLE = ITRUST_PREFIX + 'Claim Business'
export const CLAIM_ORGANIZATION_TITLE = ITRUST_PREFIX + 'Claim Organization'
export const RESET_PASSWORD_TITLE = ITRUST_PREFIX + 'Reset password'
export const ABOUT_US_TITLE = ITRUST_PREFIX + 'About us'
export const COMMUNITY_TITLE = ITRUST_PREFIX + 'Community'
export const CONTACT_TITLE = ITRUST_PREFIX + 'Contact'
export const JOBS_TITLE = ITRUST_PREFIX + 'Jobs'
export const FAQ_TITLE = ITRUST_PREFIX + 'FAQ'
export const BETAREADME_TITLE = ITRUST_PREFIX + 'Beta README & Next Steps'
export const ADVERTISE_TITLE = ITRUST_PREFIX + 'Advertise'
export const PRIVACY_TITLE = ITRUST_PREFIX + 'Privacy'
export const TERMS_AND_CONDITIONS_TITLE = ITRUST_PREFIX + 'Terms of Service'
export const LOGOUT_TITLE = ITRUST_PREFIX + 'Logout'
export const SEARCH_TITLE = ITRUST_PREFIX + 'Search'
export const PROFILE_TITLE = ITRUST_PREFIX + 'Profile'
export const PROFILE_TRUST_DIRECTORY_TITLE = ITRUST_PREFIX + 'Profile - trust list'
export const PROFILE_FRIEND_TITLE = ITRUST_PREFIX + 'Profile - Friend'
export const PROFILE_GALLERY_TITLE = ITRUST_PREFIX + 'Profile - Gallery'
export const MESSAGE_TITLE = ITRUST_PREFIX + 'Messages'
export const SHAERD_PROFILE = ITRUST_PREFIX + 'You were invited to Join'
export const SHARED_CLAIM = ITRUST_PREFIX + 'You were invited to Claim'
export const ADD_CLAIM_TITLE = ITRUST_PREFIX + 'Claim Business / Organization - Presearch'
export const VIDEOS_TITLE = ITRUST_PREFIX + 'Videos'
