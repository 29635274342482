import React from 'react'
import { Link } from 'react-router-dom'
import ReactStars from 'react-stars'
import {
  STAR_RATING_iTRUST_ACTIVE_COLOR,
  STAR_RATING_iTRUST_INACTIVE_COLOR,
  STAR_RATING_PROFILE_HEADER_SIZE,
} from '../../../services/const'
import { PROFILE_ROUTE } from '../../../services/routeConst'

const TotalGivenReviews = ({ userDetail }) => {
  return (
    <div className='badge_star'>
      <ul className='nav nav-review-tab'>
        <Link to={PROFILE_ROUTE + '?type=given#review'} className='nav-link'>
          {userDetail?.total_given_review} Reviews Given
        </Link>
      </ul>
      <ul className='d-flex'>
        <ReactStars
          edit={false}
          size={STAR_RATING_PROFILE_HEADER_SIZE}
          color1={STAR_RATING_iTRUST_INACTIVE_COLOR}
          color2={STAR_RATING_iTRUST_ACTIVE_COLOR}
          value={userDetail?.average_given_review}
        />
      </ul>
    </div>
  )
}

export default TotalGivenReviews
