import React, { Component } from 'react'
import { getUser } from '../../../services/customFunction'
import { friendListCallNew, friendListCallNewFromIDs } from '../../../services/friendApiService'
import {
  getProfileOverviewFriends,
  getProfileOverviewFriendsIndividual,
  getProfileOverviewFriendsOrganization,
  getProfileOverviewFriendsBusiness,
} from '../../../services/userApiService'
import FriendsListDetail from '../FriendsListDetail/FriendsListDetail'

class Friends extends Component {
  constructor(props) {
    super(props)
    this.state = {
      total_count: {
        friends: 0,
        business: 0,
        individual: 0,
        organization: 0,
      },
      individual_list: [],
      business_list: [],
      organization_list: [],
      // changeActiveTab: this.props.changeActiveTab(),
      is_friends_loading: true,
    }
  }

  componentDidMount() {
    this.getProfileOverviewFriends()
  }

  profileHeader_overview_tab_click_Handle() {
    this.getProfileOverviewFriends()
  }

  getProfileOverviewFriends() {
    const self = this
    let getProfileOverviewFriendsReqData = {}
    getProfileOverviewFriends(getProfileOverviewFriendsReqData).then(function (response) {
      self.setState({ is_friends_loading: false })
      if (typeof response.status !== 'undefined' && response.status === 200) {
        let apiResponseData = response.data
        self.setState({
          total_count: apiResponseData.data.total_count,
          // individual_list: apiResponseData.data.individual_list,
          // business_list: apiResponseData.data.business_list,
          // organization_list: apiResponseData.data.organization_list
        })
        if (apiResponseData.data.total_count.individual > 0) {
          self.getProfileOverviewFriendsByType('individual')
        }
        if (apiResponseData.data.total_count.organization > 0) {
          self.getProfileOverviewFriendsByType('organization')
        }
        if (apiResponseData.data.total_count.business > 0) {
          self.getProfileOverviewFriendsByType('business')
        }
      }
    })
  }

  getProfileOverviewFriendsIndividual() {
    const self = this
    let getProfileOverviewFriendsReqData = {}
    getProfileOverviewFriendsIndividual(getProfileOverviewFriendsReqData).then(function (response) {
      if (typeof response.status !== 'undefined' && response.status === 200) {
        let apiResponseData = response.data
        // console.log(apiResponseData);
        self.setState({
          individual_list: apiResponseData.data.individual_list,
        })
      }
    })
  }

  getProfileOverviewFriendsOrganization() {
    const self = this
    let getProfileOverviewFriendsReqData = {}
    getProfileOverviewFriendsOrganization(getProfileOverviewFriendsReqData).then(function (
      response,
    ) {
      if (typeof response.status !== 'undefined' && response.status === 200) {
        let apiResponseData = response.data
        // console.log(apiResponseData);
        self.setState({
          organization_list: apiResponseData.data.organization_list,
        })
      }
    })
  }

  getProfileOverviewFriendsByType(type = 'individual') {
    const self = this
    let getProfileOverviewFriendsReqData = {}
    let friendListReqData = {
      other_user_id: getUser().id,
      page: 1,
      firstCall: true,
      noMutual: true,
      endOfPage: 10,
      user_type: type,
      orderByFriendshipID: true,
      searchString: '',
    }
    friendListCallNew(friendListReqData).then((response) => {
      const currentIDs = response.data.ids.slice(0, 4).map((user) => user.friend_user_id)
      const self = this
      friendListCallNewFromIDs({
        other_user_id: getUser()?.id,
        noMutual: true,
        endOfPage: 10,
        user_type: type,
        orderBy: 'friendship_id',
        orderType: 'desc',
        ids: currentIDs,
      }).then(function (response) {
        response.data.friends_list &&
          self.setState({ [`${type}_list`]: response.data.friends_list })
      })
    })

    // getProfileOverviewFriendsBusiness(getProfileOverviewFriendsReqData).then(
    //   function (response) {
    //     if (typeof response.status !== 'undefined' && response.status === 200) {
    //       let apiResponseData = response.data;
    //       self.setState({
    //         business_list: apiResponseData.data.business_list,
    //       });
    //     }
    //   }
    // );
  }

  render() {
    const { total_count, individual_list, business_list, organization_list } = this.state

    if (this.state.is_friends_loading) {
      return (
        <div className='bg-white curvey mb-4 p-15 related-listings-show'>
          <p className='text-center'>Loading...</p>
        </div>
      )
    } else {
      return (
        <div className='bg-white curvey mb-4 p-15 related-listings-show'>
          <h4 className='varela'>
            {/* Friends  */}
            <a onClick={this.props.changeActiveTab('#friend')} href='#friend'>
              Friends
            </a>
            <span className='purple'>&nbsp;{total_count.friends}</span>
          </h4>
          <div className='friends_green mt-2'>
            <div className='d-flex headers justify-content-between green'>
              <h6>
                {/* People - {total_count.individual} */}
                <a
                  onClick={this.props.showFriendsTab('#friend_individual')}
                  href='#friend'
                  className='mt-0 headers '
                >
                  <h6>People - {total_count.individual}</h6>
                </a>
              </h6>
              {/* <h6>44 mutual</h6> */}
            </div>
            <div className='row profiles'>
              {total_count.individual > 0 ? (
                individual_list.map((user, index) => (
                  <FriendsListDetail key={user.id} index={index} user={user} />
                ))
              ) : (
                <div className='col-md-6'>No records</div>
              )}

              {/* <div className="col-md-6">
                            <h6 className="varela">Eddy Jones</h6>
                            <div className="frnd_box">
                                <div className="img_block green_border"></div>
                                <div className="">
                                    <div className="mb-2">
                                        <i>
                                            <img src={process.env.PUBLIC_URL + "/img/purple_tag.png"} className="me-2" alt="" />
                                        </i>142
                                    </div>
                                    <div className="mb-2">
                                        <i>
                                            <img src={process.env.PUBLIC_URL + "/img/frandss.png"} className="me-2" alt="" />
                                        </i>149
                                    </div>
                                </div>
                            </div>
                        </div> */}
            </div>
          </div>
          {/* friends green over */}

          <div className='business_blue mt-4'>
            <div className='d-flex headers justify-content-between'>
              {/* <h6>business - {total_count.business}</h6> */}
              <h6>
                <a
                  onClick={this.props.showFriendsTab('#friend_business')}
                  href='#friend'
                  className='mt-0 headers '
                >
                  <h6>businesses - {total_count.business}</h6>
                </a>
              </h6>
              {/* <h6>24 mutual</h6> */}
            </div>
            <div className='row profiles'>
              {total_count.business > 0 ? (
                business_list.map((user, index) => (
                  <FriendsListDetail key={user.id} index={index} user={user} />
                ))
              ) : (
                <div className='col-md-6'>No records</div>
              )}

              {/* <div className="col-md-6">
                            <h6 className="varela">Eddy Jones</h6>
                            <div className="frnd_box">
                                <div className="img_block blue_border"></div>
                                <div className="">
                                    <div className="mb-2">
                                        <i>
                                            <img src={process.env.PUBLIC_URL + "/img/orange_tag.png"} className="me-2" alt="" />
                                        </i>142
                                    </div>
                                    <div className="mb-2">
                                        <i>
                                            <img src={process.env.PUBLIC_URL + "/img/frandss.png"} className="me-2" alt="" />
                                        </i>149
                                    </div>
                                </div>
                            </div>
                        </div> */}
            </div>
          </div>
          {/* business blue over */}

          <div className='organization_orange mt-4'>
            <div className='d-flex headers justify-content-between'>
              {/* <h6>ORGANIZATIONS - {total_count.organization}</h6> */}
              <h6>
                <a
                  onClick={this.props.showFriendsTab('#friend_organization')}
                  href='#friend'
                  className='mt-0 headers '
                >
                  <h6>ORGANIZATIONS - {total_count.organization}</h6>
                </a>
              </h6>
              {/* <h6>12 mutual</h6> */}
            </div>
            <div className='row profiles'>
              {total_count.organization > 0 ? (
                organization_list.map((user, index) => (
                  <FriendsListDetail key={user.id} index={index} user={user} />
                ))
              ) : (
                <div className='col-md-6'>No records</div>
              )}

              {/* <div className="col-md-6">
                            <h6 className="varela">Eddy Jones</h6>
                            <div className="frnd_box">
                                <div className="img_block orange_border">
                                </div>
                                <div className="">
                                    <div className="mb-2">
                                        <i>
                                            <img src={process.env.PUBLIC_URL + "/img/orange_tag.png"} className="me-2" alt="" />
                                        </i>142
                                    </div>
                                    <div className="mb-2">
                                        <i>
                                            <img src={process.env.PUBLIC_URL + "/img/frandss.png"} className="me-2" alt="" />
                                        </i>149
                                    </div>
                                </div>
                            </div>
                        </div> */}
            </div>
          </div>
          {/* organization over */}
        </div>
      )
    }
  }
}

export default Friends
