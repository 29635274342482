import React, { Component } from 'react'
import AppLayout from '../AppLayout'
import '../Message/message.scss'
import { ABOUT_US_TITLE } from '../../services/titleConst'
import LoginModal from '../LoginModal/LoginModal'
import { Helmet } from 'react-helmet'
import './UnclaimedMoreInfo.scss'
import { PRIVACY_ROUTE, TERMS_ROUTE } from '../../services/routeConst'
import { ItiText } from '../About/About'

class UnclaimedMoreInfo extends Component {
  componentDidMount() {
    window.scrollTo(0, 0)
  }

  loginHandler = (data) => {
    this.props.isLogin(data)
  }

  render() {
    return (
      <AppLayout routeActive={this.props.location.pathname}>
        <Helmet>
          <title>Claim a page on iTRUSTit</title>
        </Helmet>
        <section className='bg-grey'>
          <div className='container mt-3'>
            <h3 className='varela mb-0'>
              Claim a page on <ItiText />
            </h3>
            <p className='mb-2'>Last Updated: 8/1/2022</p>
            <div className='white-pane green_line'>
              <div className='unclaimed-more-info mb-5'>
                <div className='d-flex flex-column'>
                  <p className='varela mb-4'>
                    iTRUSTit has a database of people, business and/or organizations and/or creates
                    or automatically creates pages to help users develop and grow their presence on
                    the iTRUSTit platform.
                  </p>
                  <p className='varela mb-4'>
                    Unclaimed pages have text that says 'Claim this page' and/or a button that says
                    'CLAIM YOUR LISTING' and/or says 'reserved' and/or 'unclaimed' and/or has text
                    that references that it was created by iTRUSTit.
                  </p>
                  <p className='varela'>If you meet the requirements to claim the page:</p>
                  <ul>
                    <li className='mt-0'>
                      <p className='varela'>
                        Go to the page and click on "Claim this page" or the "CLAIM YOUR LISTING"
                        button
                      </p>
                    </li>
                    <li>
                      <p className='varela mb-4'>
                        If these links/buttons do not show, email us at{' '}
                        <a href='mailto:info@contact-itrustit.com' target='_top'>
                          info@contact-itrustit.com
                        </a>{' '}
                        Attn: CLAIMS, and include the URL of the page you are inquiring about.
                      </p>
                    </li>
                  </ul>
                  <p className='varela'>
                    If you do not meet the requirements to claim the page you can:
                  </p>
                  <ul>
                    <li className='mt-0'>
                      <p className='varela'>+Friend, +Trust, +Member, and/or Share the page</p>
                    </li>
                    <li>
                      <p className='varela'>
                        Invite the owner/Rep or Send the page to someone that meets the claim
                        requirements
                      </p>
                    </li>
                    <li style={{ listStyle: 'none' }}>
                      <ul>
                        <li className='mt-0' style={{ listStyle: 'circle' }}>
                          <p className='varela'>
                            Click the button that says 'INVITE OWNER/REP TO CLAIM THIS PAGE' and
                            follow the prompts
                          </p>
                        </li>
                        <li style={{ listStyle: 'circle' }}>
                          <p className='varela'>
                            If this page does not have this button and says 'reserved' or
                            'unclaimed', you can help us and the unclaimed business or organization
                            by sending them their iTRUSTit page URL and copying us at{' '}
                            <a href='mailto:info@contact-itrustit.com' target='_top'>
                              info@contact-itrustit.com
                            </a>
                          </p>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <p className='varela'>Do nothing</p>
                    </li>
                    <li>
                      <p className='varela mb-4'>
                        Report this page <a href='/'>Here</a>
                      </p>
                    </li>
                  </ul>
                  <p className='varela'>
                    For more information, please see our{' '}
                    <a href='/' target='_blank' rel='noopener noreferrer'>
                      General Guidelines
                    </a>
                    ,{' '}
                    <a href={TERMS_ROUTE} target='_blank' rel='noopener noreferrer'>
                      Terms of Service
                    </a>{' '}
                    and{' '}
                    <a href={PRIVACY_ROUTE} target='_blank' rel='noopener noreferrer'>
                      Acceptable Use Policy
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <LoginModal isLogin={this.loginHandler} {...this.props} />
      </AppLayout>
    )
  }
}

export default UnclaimedMoreInfo
