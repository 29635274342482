import React from 'react'
import { Link } from 'react-router-dom'
import { DEFAULT_PROFILE_PICTURE } from '../../services/const'

const UserChatHeader = ({ chat_user, chat_user_title }) => {
  return (
    <>
      <div className='user_image'>
        <Link to={chat_user ? chat_user.slug : ''}>
          <img src={chat_user?.profile_picture_small ?? DEFAULT_PROFILE_PICTURE} alt='' />
        </Link>
      </div>
      <div className='user_content'>
        <div className='d-flex'>
          <div className='username w-100'>
            <Link to={chat_user ? '/' + chat_user.slug : ''}>{chat_user_title}</Link>
          </div>
        </div>
      </div>
    </>
  )
}

export default UserChatHeader
