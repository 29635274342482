import React, { Fragment, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { CLAIM_BUSINESS_PROFILE_ROUTE } from '../../../services/routeConst'
import { DEFAULT_PROFILE_PICTURE } from '../../../services/const'
import FriendButton from '../../CommonComponents/FTMButtons/FriendButton'
import TrustButton from '../../CommonComponents/FTMButtons/TrustButton'
import MemberButton from '../../CommonComponents/FTMButtons/MemberButton'
import ShareModal, {
  hideShareModal,
  showShareModal,
} from '../../CommonComponents/ShareModal/ShareModal'
import { ItiText } from '../../About/About'
import PromotionButton from '../../Promotion/PromotionButton'
import { getCurrentPromotion } from '../../../services/promotionService'
import useProfilePictureUpdate from '../../../hooks/useProfilePictureUpdate'
import Cropperjs from '../../Cropper/Cropper'
import { isAdmin } from '../../../services/customFunction'
import ISupport from '../../CommonComponents/ISupport/ISupport'
import { isMobile } from 'react-device-detect'

const ProfileHeader = (props) => {
  const [isInviteToClaim, setIsInviteToClaim] = useState(false)
  const [promotionData, setPromotionData] = useState(false)
  const { user, isLoggedIn = true } = props
  const { validation, userImg, handleImageChange, updateProfilePicture, setImgFile } =
    useProfilePictureUpdate({
      ...user,
      user_detail: {
        ...user?.user_detail,
        profile_picture_large:
          user?.user_detail?.profile_picture_large ?? user?.profile_picture_large,
      },
    })
  const {
    user_type,
    fullname,
    business_organization_name,
    tagline,
    imatrix_id,
    profile_picture_medium,
    is_business_claimed,
  } = user ?? {}

  useEffect(() => {
    const getPromotionData = async () => {
      const promotions = await getCurrentPromotion({ userId: user?.id })
      const promotionId = promotions?.data?.id
      if (promotionId) {
        setPromotionData(promotions?.data)
      }
    }
    getPromotionData()
    return () => {
      hideShareModal()
    }
  }, [])

  const friendBtn = (
    <FriendButton
      friendAddRemoveFriendHandle={props.friendAddRemoveFriendHandle}
      confirmAddRemove={props.confirmFriendAddRemove}
      user={user}
      isLoggedIn={isLoggedIn}
    />
  )

  const trustBtn = (
    <TrustButton
      itrustitTrustHandle={props.itrustitTrustHandle}
      confirmitrustitAddRemove={props.confirmitrustitAddRemove}
      user={user}
      isLoggedIn={isLoggedIn}
    />
  )

  const memberBtn = (
    <MemberButton
      memberAddRemove={props?.memberAddRemove}
      confirmAddRemove={props.confirmMemberAddRemove}
      user={user}
      isLoggedIn={isLoggedIn}
    />
  )

  const buttonSection = {
    individual: [friendBtn],
    business: [friendBtn, trustBtn],
    organization: [friendBtn, trustBtn, memberBtn],
  }

  const nameToDisplay = user_type === 'individual' ? fullname : business_organization_name
  const buttonProps = isLoggedIn
    ? {
        onClick: () => showShareModal('1'),
      }
    : {
        onClick: () => {},
        'data-bs-toggle': 'modal',
        'data-bs-target': '#login_modal',
      }
  return (
    <Fragment>
      <div className={'pro_name_detail d-flex position-relative ' + user_type + '_profile'}>
        {promotionData?.id ? <PromotionButton userId={user?.id} /> : null}
        <div className='it-profile-id'>
          {imatrix_id !== '' && <p>iTeam ID # : {imatrix_id}</p>}
          {isAdmin() ? (
            <>
              <label
                className='image-cropper-image my-0'
                data-bs-toggle='modal'
                data-bs-target='#cropperModal'
              >
                {/* <input type="file" className="file" name="zip_file_import" /> */}
                <div className={'pro_1'}>
                  <span>Update profile picture</span>
                  <img src={userImg ?? DEFAULT_PROFILE_PICTURE} alt='' />
                </div>
              </label>
              <Cropperjs
                srcData={handleImageChange}
                imgsrc={userImg}
                updateProfile={updateProfilePicture}
                errors={validation?.errors}
                setImgFile={setImgFile}
              />
            </>
          ) : (
            <div className='pro_1'>
              <img src={userImg ?? DEFAULT_PROFILE_PICTURE} alt='' />
            </div>
          )}
        </div>

        <div className='pro_name d-flex flex-column'>
          <h3 style={{ wordBreak: 'break-word' }} className='blue'>
            {nameToDisplay}
          </h3>
          {tagline ? <p style={{ wordBreak: 'break-word' }}>{tagline ?? ''}</p> : null}
          {user_type !== 'individual' && is_business_claimed === '0' ? (
            <div className='d-flex flex-column mb-2'>
              <div className='it-profile-claim-btn'>
                <Link
                  to={{
                    pathname: CLAIM_BUSINESS_PROFILE_ROUTE,
                    state: { user: user },
                  }}
                  className='claim_link_text'
                >
                  Claim this page
                </Link>
              </div>
              <button className='btn invite_to_claim_btn my-3 mx-auto mx-sm-0' {...buttonProps}>
                Invite Owner/Rep to Claim this page
              </button>
            </div>
          ) : null}
          {buttonSection[user_type] ? (
            <div className='design_action'>{buttonSection[user_type]}</div>
          ) : null}

          {!isMobile && user?.isupport ? <ISupport user={user} /> : null}
        </div>
      </div>
      <ShareModal user={user} isInviteToClaim id='1' />
    </Fragment>
  )
}

export default ProfileHeader
