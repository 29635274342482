import React, { Component } from 'react'
import ModalImage from 'react-modal-image'
import './GalleyMediaListDetail.scss'

class GalleyMediaListDetail extends Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  gallery_videoPlay_handle = (mediaObj) => (event) => {
    this.props.gallery_videoPlay_handle(mediaObj)
  }

  gallery_imageDisplay_handle = (mediaObj) => (event) => {
    this.props.gallery_imageDisplay_handle(mediaObj)
  }

  render() {
    return (
      <li>
        {this.props.media.file_type === 'image' ? (
          <div className='gallery_inner'>
            {/* <ModalImage
                        small={this.props.media.gallery_picture_thumb}
                        large={this.props.media.gallery_picture}
                        //alt="Hello World!"
                    /> */}
            {/* <a href={null}>
                        <img src={this.props.media.gallery_picture_thumb} alt=" " />
                    </a> */}
            <a
              href={null}
              onClick={this.gallery_imageDisplay_handle(this.props)}
              data-index={this.props.index}
            >
              <img src={this.props.media.gallery_picture_thumb} alt=' ' />
            </a>
          </div>
        ) : (
          <div className='gallery_inner'>
            <a
              href={null}
              onClick={this.gallery_videoPlay_handle(this.props.media)}
              data-index={this.props.index}
            >
              <img src={this.props.media.gallery_video_thumb} alt=' ' />
              <span className='play'></span>
            </a>
          </div>
        )}
      </li>
    )
  }
}

export default GalleyMediaListDetail
