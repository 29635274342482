import AppLayout from '../../../AppLayout'
import LoginModal from '../../../LoginModal/LoginModal'
import * as route from '../../../../services/routeConst'
import '../Reports/Report.scss'
import { Link } from 'react-router-dom'
const ItiText = () => (
  <span className='fw-bold'>
    <span style={{ color: '#14954b' }}>i</span>
    <span style={{ color: '#0877bd' }}>TRUST</span>
    <span style={{ color: '#d85a27' }}>it</span>
  </span>
)
const MoreInfoHTML = (props) => {
  window.scrollTo(0, 0)

  return (
    <AppLayout routeActive={props.location.pathname}>
      <section className='bg-grey it-privacy-terms-page it-terms-page'>
        <div className='container'>
          <div className='white-pane privacy'>
            <h3>
              iTRUSTit seeks to be a place where people, businesses, bands, teams, stars, charities,
              clubs, groups, communities, associations and organizations, etc. connect to share and
              promote what they trust and care about with their friends, family, clients, members,
              fans and associates.{' '}
            </h3>

            <p>
              We see iTRUSTit as a place where personal rights are respected and where people are
              kind to one another. Our{' '}
              <Link
                style={{ color: '#6684f2' }}
                to={route.GENERALGUIDELINESHTML_ROUTE}
                target='_blank'
              >
                General Guidelines,
              </Link>{' '}
              <Link style={{ color: '#6684f2' }} to={route.TERMS_ROUTE} target='_blank'>
                Terms of Service and Acceptable Use Policy
              </Link>{' '}
              requires that content on our site is not in violation of another user’s personal
              rights.
            </p>

            <h2>
              <b>
                Rules, Regulations and Policies for the <ItiText /> Platform
              </b>
            </h2>

            <p>
              Thank you for joining iTRUSTit, where professionals from across the globe come
              together to find jobs, keep up to date, learn new skills, and establish meaningful
              connections. You should only post information to iTRUSTit that is useful to your
              professional network and that you want to share with the rest of the iTRUSTit
              community. See our Publishing Platform Guidelines for more details on what that
              implies. When everyone in our community is polite, empathetic, and honest, it provides
              economic opportunity for everyone. This is possible when we work together.
            </p>

            <p>
              In order to provide a secure, trustworthy, and professional experience for our
              members, we are constantly updating these Community Policies.
            </p>

            <h2>
              <b>
                Let us know if you come across anything that seems to be untrustworthy,
                unprofessional, or otherwise suspect.
              </b>
            </h2>

            <p>
              Please let us know if you spot anything that could violate our rules, whether it's in
              a profile, a post, a message, a comment, or anywhere else. These reports assist us
              detect and prevent abuse and misconduct when paired with our automatic defenses. The
              reporting tools should only be used for what they were designed for, so please do so
              responsibly. Visit our Safety Center to learn more about reporting harmful material,
              behavior, or members.
            </p>

            <h2>
              <b>If you violate our community guidelines, you may be penalized for your actions.</b>
            </h2>

            <p>
              All members are bound by these guidelines. According on how serious the breach is we
              may either delete or restrict access to particular material in question. It is
              possible to file an appeal if you feel a piece of material was deleted in mistake by
              following the link given in the notice of removal. iTRUSTit may ban you for a period
              of time or permanently if you commit repeated or serious crimes. A request for appeal
              may be submitted if you consider that an action made on your account was done
              incorrectly.
            </p>

            <h3>
              <b>Make sure you're protected</b>
            </h3>

            <p>
              We expect all iTRUSTit members to conduct themselves in a respectful and courteous
              manner.
            </p>

            <p>
              In order to maintain a safe and welcoming environment, we do not allow any material
              that attacks, degrades or intimidates people or groups based on their ethnicity;
              national origin; caste; gender; sexual orientation; religious affiliation; or handicap
              status. iTRUSTit does not allow hate organizations. Do not use racial, religious, or
              other insults that instigate or promote hate, or any other material that is designed
              to cause a rift in the community.
            </p>

            <p>
              Unwanted attention, desire, requests for a relationship, marriage proposals; sexual
              advances or innuendo; lewd remarks are not welcome. iTRUSTit should not be used to
              initiate personal relationships, solicit romantic dates, or make sexually explicit
              comments about the looks of others.
            </p>

            <p>
              Be respectful: Bullying and harassment are not tolerated here. Inciting or enlisting
              others to engage in the same behavior is also included in
            </p>

            <p>
              There is absolutely no tolerance for threatening, instigating or promoting violence in
              any form. Violence, property destruction, and organized criminal behavior will not be
              tolerated in our community. Using iTRUSTit to show support for people or organizations
              that engage in violence is not permitted.
            </p>

            <p>
              Be careful about what you post: We don't accept gory or disturbing stuff on our site.
              Sadistic or violent material, such as depictions of extreme physical violence, falls
              under this category. In our community, we do not tolerate information or behaviors
              that encourage or support criminal conduct. Content portraying or supporting the
              manufacturing of drugs, or theft threats is likewise prohibited. Do not do or promote
              things like revenge porn, escort services, prostitution, exploiting children, or human
              trafficking. Suicide and self-injury, including self-mutilation and eating disorders,
              are not acceptable topics for discussion on social media. Please alert authorities if
              you see indicators that someone is contemplating harming themselves.
            </p>

            <p>
              We don't accept any terrorist or violent extremist groups on our site, so please don't
              publish anything that could incite violence. Anyone associated with such organizations
              or groups is prohibited from promoting their activities. Content depicting terrorist
              conduct, recruiting for terrorist groups, or threatening, promoting, or supporting
              terrorism in any way is not permitted by the United States.
            </p>

            <h3>
              <span className='bullteTypes'>v</span>
              <b> Be a Person of Integrity</b>
            </h3>

            <p>
              In order to use iTRUSTit effectively, you must utilize your actual identity, give
              correct information about yourself or your company, and only share information that is
              genuine and authentic with us.
            </p>

            <p>
              If you know or suspect that a piece of information you are sharing is wrong or
              deceptive, don't share it with the public. Please refrain from disseminating
              information with the intent of interfering with or influencing an election or other
              civic process. We have the right to restrict your ability to upload material from
              sites that are known to spread false information or generate it themselves. Don't post
              anything that goes against the advice of renowned global health organizations and
              public health authorities. A historical event such as the Holocaust or slavery, both
              of which have ample evidence supporting their validity, should not be misrepresented
              in your posts. False stuff, including news items, should not be shared, and should not
              be considered true or even likely to be accurate. No "deepfake" pictures or videos of
              other people, no material that has been modified to fool. Personal advantage
              (including familial ties, monetary reward, free goods or services, or other value)
              must be clearly and conspicuously stated when you disseminate material or support
              someone or anything in return for personal gain.
            </p>

            <p>
              False information about yourself, such as creating a phony profile, is not permitted.
              Maintain the integrity of your reputation by avoiding the use of fraudulent and
              misleading information in regards to yourself and/or your company. For your profile
              picture, do not use an image of someone else, or any other image that does not
              represent you. Never use iTRUSTit to represent a company or organization with which
              you are not affiliated. No one else should be able to use your iTRUSTit account or
              establish a member profile under your name. Also, do not allow anybody else to use
              your iTRUSTit account.
            </p>

            <p>
              Messages that are untargeted, irrelevant, manifestly unwelcome, unlicensed, improperly
              commercial or promotional, or gratuitously repetitious will not be allowed on our
              site. For commercial objectives, do not use iTRUSTit to sensationalize or exploit
              unfortunate situations. Don't use our invitation tool to send promotional messages to
              individuals you don't know, or to spam them in any other way. Instead of attempting to
              boost the number of views, re-shares, likes, or comments in an unnatural manner,
              please make an effort to develop unique, professional, relevant, and fascinating
              material. Don't agree ahead of time to like or reshare each other's stuff; instead, be
              genuine in your response to the content of others.
            </p>

            <h3>
              <span className='bullteTypes'>v</span>
              <b> You must treat people with respect and abide by the law.</b>
            </h3>

            <p>
              Make sure you have the legal permission to share or use another person's intellectual
              works, trademarks, private information, or trade secrets before you do so. Legal
              actions, marketing unlawful items, and infringing the rights of others should not be
              carried out using iTRUSTit. This software should not be used in any way that could
              compromise the security of other people's computers or data. This includes
              distributing viruses, worms, and other malicious software.
            </p>

            <p>
              Do not use, disclose, or distribute on iTRUSTit any data obtained in violation of any
              of our policies or agreements; do not disclose information to which you do not have
              the right to disclose (such as personal or confidential information about others,
              including your employer); or do not post or distribute content that depicts children
              without the consent of their parents or guardians. Make sure that you have the
              permission of the individual before using their name, image, or likeness in any way
              that implies endorsement of a certain product, service, or investment.
            </p>

            <p>
              If you don't comply with all relevant regulations (such as anti-spam or export control
              rules), you might face fines or even criminal prosecution. Be careful about what you
              post on iTRUSTit. iTRUSTit is not a place to run a pyramid scheme or advertise illicit
              items like narcotics.
            </p>

            <p>
              If you violate someone else's intellectual property rights, you're breaking the law.
              We are sensitive to the rights of others to their intellectual property. Be careful
              not to infringe on others' copyrights or patents or trademarks or other proprietary
              rights. A Creative Commons license may allow you to publish and share other people's
              posts and other material without their express permission, but you should not
              reproduce or distribute their stuff without their permission. Visit iTRUSTit's{' '}
              <Link style={{ color: '#6684f2' }} to={route.COPYRIGHTHTML_ROUTE} target='_blank'>
                Copyright Policy
              </Link>{' '}
              to learn more about how to report copyright infringement. See our{' '}
              <Link style={{ color: '#6684f2' }} to={route.TRADEMARKHTML_ROUTE} target='_blank'>
                Trademark Policy
              </Link>{' '}
              for additional details on trademark regulations.
            </p>

            <h3>
              <span className='bullteTypes'>v</span>
              <b> Respect iTRUSTit's legal rights</b>
            </h3>

            <p>
              Do not infringe on iTRUSTit's rights. This is explained in further detail in our{' '}
              <Link
                style={{ color: '#6684f2' }}
                to={route.GENERALGUIDELINESHTML_ROUTE}
                target='_blank'
              >
                General Guidelines,
              </Link>{' '}
              <Link style={{ color: '#6684f2' }} to={route.TERMS_ROUTE} target='_blank'>
                Terms of Service and Acceptable Use Policy
              </Link>{' '}
              If you claim to be associated with or sponsored by iTRUSTit but aren't, that's a red
              flag. Avoid violating our intellectual property or disrupting or disrupting the
              platform.
            </p>

            <p>
              If you're using iTRUSTit for anything other than what we've approved, you're violating
              our terms of service. Don't use iTRUSTit outside of the interfaces provided by us,
              such as mobile apps and itrustit.com.
            </p>

            <p>
              Interference with the iTRUSTit service: Do not interfere with the iTRUSTit service's
              functioning or our rights. Remove any copyright, trademark or other proprietary rights
              notices from any iTRUSTit service that you have access to.
            </p>

            <p>
              You must abide by our rules, agreements, and policies in their entirety. When you join
              up for or begin using certain iTRUSTit services, you agree not to breach any extra
              agreements relating to such services.
            </p>
          </div>
        </div>
      </section>
      <LoginModal
        isLogin={(e) =>
          function (data) {
            props.isLogin(data)
          }
        }
        {...props}
      />
    </AppLayout>
  )
}

export default MoreInfoHTML
