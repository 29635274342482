import React from 'react'
import { SEARCH_AMT } from '../../../services/const'
import { userToken } from '../../../services/customFunction'
import UserListDetail from '../../CommonComponents/UserListDetail/UserListDetail'
import SearchPageLoadingIcon from '../../Search/SearchPageLoadingIcon'

const SearchResults = ({
  searchAgain,
  login_user_id,
  searchResultsData = {},
  onHoverTrustBy,
  friendAddRemoveHandle,
  itrustitHandle,
  confirmFriendAddRemove,
  confirmitrustitAddRemove,
  setStatusOfMemberUserYour,
  setModalProps,
}) => {
  const { totalRecords, usersList, userIDsOffset } = searchResultsData
  return (
    <div className='search-section'>
      {usersList?.map((user, index) => {
        if (index < userIDsOffset) {
          return (
            <React.Fragment key={user.id}>
              <UserListDetail
                setModalProps={setModalProps}
                setStatusOfMemberUserYour={setStatusOfMemberUserYour}
                user={user}
                login_user_id={login_user_id}
                onHoverTrustBy={onHoverTrustBy}
                friendAddRemoveHandle={friendAddRemoveHandle}
                itrustitHandle={() =>
                  itrustitHandle({
                    id: user?.id,
                    is_trusted_user: 1,
                  })
                }
                // memberAddRemoveHandle={refresh}
                confirmitrustitAddRemove={confirmitrustitAddRemove}
                confirmFriendAddRemove={confirmFriendAddRemove}
                // loadingFriendStatusChange={loadingFriendStatusChange}
                // loadingitrustitStatusChange={loadingitrustitStatusChange}
                // onHoverTrustBy={onHoverTrustBy}
              />
              {index === searchResultsData.totalRecords - 1 ? (
                <h6
                  className='text-center text-muted back-to-top'
                  onClick={() =>
                    window.scrollTo({
                      top: 0,
                      behavior: 'smooth',
                    })
                  }
                >
                  Back to top
                </h6>
              ) : null}
            </React.Fragment>
          )
        } else return null
      })}
      {searchResultsData?.loadingData && userIDsOffset < totalRecords ? (
        <SearchPageLoadingIcon
          label={
            <h4>
              Loading more results <div className='dot-flashing'></div>
            </h4>
          }
        />
      ) : userIDsOffset < searchResultsData?.totalRecords ? (
        <button onClick={searchAgain}>Load more</button>
      ) : null}
    </div>
  )
}

export default SearchResults
