import React, { useEffect, useRef, useState } from 'react'
import $ from 'jquery'
import FormCheckBox from '../FormCheckBox/FormCheckBox'
import ErrorMsgRenderer from '../../ErrorMsgRenderer/ErrorMsgRenderer'
import { submitGalleryReport } from '../../../services/galleryApiService'
import { toaster } from '../../../services/customFunction'
import { customLogger } from '../../../utils/CustomLogger'
const descriptionCharacterLimit = 250
const defaultReasons = {
  nudity: false,
  violence: false,
  harassment: false,
  suicide_or_self_injury: false,
  false_information: false,
  spam: false,
  unauthorized_sales: false,
  hate_speech: false,
  terrorism: false,
  something_else: false,
}

export const showReportModal = (type, isLoggedIn = true) => {
  $(isLoggedIn ? '#report_modal' : '#login_modal')
    .modal('show')
    .attr('mediaType', type)
}

const ReportModal = ({
  user_id,
  user_id_reported_by,
  media,
  galleryListRender,
  isLoggedIn = true,
}) => {
  const [reasons, setReasons] = useState(defaultReasons)
  const [description, setDescription] = useState('')
  const [status, setStatus] = useState('idle')
  const descriptionCharactersLeftRef = useRef(descriptionCharacterLimit)
  const mediaRef = useRef(null)

  useEffect(() => {
    console.log(media)
    if (media && mediaRef.current?.id !== media?.id) {
      mediaRef.current = media
      setReasons(defaultReasons)
      setDescription('')
    }
  }, [media])

  const handleChange = ({ name, value }) => {
    setReasons((st) => ({ ...st, [name]: value }))
  }

  const handleDescriptionChange = (e) => {
    descriptionCharactersLeftRef.current =
      descriptionCharacterLimit - e.target.value.substring(0, descriptionCharacterLimit).length
    setDescription(e.target.value.substring(0, descriptionCharacterLimit))
  }

  const onSubmitReport = async (e) => {
    e.preventDefault()
    setStatus('submitting')
    const hasAnyReasonsSelected = Object.keys(reasons)?.filter((reason) => reasons[reason])?.length
    if (!hasAnyReasonsSelected && !description) {
      setStatus('idle')
      toaster('error', 'Please provide atleast one reason or a description for your report.')
      return
    }
    const postData = {
      user_id,
      user_id_reported_by,
      gallery_id: mediaRef.current?.id,
      reasons,
      description,
    }
    try {
      const { data } = await submitGalleryReport(postData)
      galleryListRender()
      if (data?.id) {
        $(isLoggedIn ? '#report_modal' : '#login_modal').modal('hide')
        setReasons(defaultReasons)
        setDescription('')
        setStatus('idle')
        toaster(
          'success',
          'Your report has been submitted. It is currently being reviewed the the Admins.',
        )
      } else {
        setStatus('idle')
      }
    } catch (error) {
      customLogger(error)
      console.log(error)
      setStatus('idle')
      toaster(
        'error',
        'Something went wrong. Please try again or contact an Admin if the issue persists.',
      )
    }
  }

  const isLoading = status === 'submitting'
  const maxReasonsChosen = Object.keys(reasons)?.filter((reason) => reasons[reason])?.length >= 4
  return (
    <div className='modal fade report_modal' id='report_modal' role='dialog'>
      <div className='modal-dialog'>
        <div className='modal-content'>
          <div className='modal-header align-items-start pb-0'>
            <h5>Submit a Report</h5>
            <button type='button' className='btn-close' data-bs-dismiss='modal'></button>
          </div>
          <div className='modal-body' style={{ textAlign: 'center' }}>
            <h6 className='text-start'>Select your reasons for reporting this media</h6>
            <form action='POST' onSubmit={onSubmitReport}>
              <FormCheckBox
                onChange={handleChange}
                name='nudity'
                value={reasons['nudity']}
                label='Nudity'
                disabled={maxReasonsChosen && !reasons?.['nudity']}
              />
              <FormCheckBox
                onChange={handleChange}
                name='violence'
                value={reasons['violence']}
                label='Violence'
                disabled={maxReasonsChosen && !reasons?.['violence']}
              />
              <FormCheckBox
                onChange={handleChange}
                name='harassment'
                value={reasons['harassment']}
                label='Harassment'
                disabled={maxReasonsChosen && !reasons?.['harassment']}
              />
              <FormCheckBox
                onChange={handleChange}
                name='suicide_or_self_injury'
                value={reasons['suicide_or_self_injury']}
                label='Suicide or Self Injury'
                disabled={maxReasonsChosen && !reasons?.['suicide_or_self_injury']}
              />
              <FormCheckBox
                onChange={handleChange}
                name='false_information'
                value={reasons['false_information']}
                label='False Information'
                disabled={maxReasonsChosen && !reasons?.['false_information']}
              />
              <FormCheckBox
                onChange={handleChange}
                name='spam'
                value={reasons['spam']}
                label='Spam'
                disabled={maxReasonsChosen && !reasons?.['spam']}
              />
              <FormCheckBox
                onChange={handleChange}
                name='unauthorized_sales'
                value={reasons['unauthorized_sales']}
                label='Unauthorized Sales'
                disabled={maxReasonsChosen && !reasons?.['unauthorized_sales']}
              />
              <FormCheckBox
                onChange={handleChange}
                name='hate_speech'
                value={reasons['hate_speech']}
                label='Hate Speech'
                disabled={maxReasonsChosen && !reasons?.['hate_speech']}
              />
              <FormCheckBox
                onChange={handleChange}
                name='terrorism'
                value={reasons['terrorism']}
                label='Terrorism'
                disabled={maxReasonsChosen && !reasons?.['terrorism']}
              />
              <FormCheckBox
                onChange={handleChange}
                name='something_else'
                value={reasons['something_else']}
                label='Something Else'
                disabled={maxReasonsChosen && !reasons?.['something_else']}
              />
              <div className='que4 mt-3'>
                <div className='d-flex justify-content-between'>
                  <div className='d-flex flex-column mb-1'>
                    <h5 className='mb-0'>Add more details.</h5>
                    <span
                      className='ms-2'
                      style={{
                        fontSize: 9,
                      }}
                    >
                      {' '}
                      30 character minimum please
                    </span>
                  </div>
                  <div
                    className={`char_left m-0 ${
                      descriptionCharactersLeftRef.current <= 15 &&
                      descriptionCharactersLeftRef.current > 5
                        ? 'text-warning'
                        : descriptionCharactersLeftRef.current <= 5
                        ? 'text-danger'
                        : ''
                    }`}
                  >
                    {descriptionCharactersLeftRef.current} characters Left
                  </div>
                </div>
                <div>
                  <textarea
                    name='description'
                    value={description}
                    onChange={handleDescriptionChange}
                    placeholder='More details...'
                    className=''
                    // disabled={isDisabled === '0' ? false : true}
                  />
                  <ErrorMsgRenderer
                    errors={
                      // typeof reviewCreateFormErr.description !== 'undefined' &&
                      // Array.isArray(reviewCreateFormErr.description) &&
                      // reviewCreateFormErr.description.length > 0
                      //   ? reviewCreateFormErr.description
                      //   :
                      []
                    }
                  />
                  {/* <button type="submit" className="submit_btn">SUBMIT REVIEW</button> */}

                  <button type='submit' className='submit_btn' disabled={isLoading}>
                    {isLoading ? 'Loading...' : 'Submit'}
                  </button>
                </div>
              </div>
              {/* <input type='submit' className='blue_btn m-0 report-submit-btn' /> */}
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ReportModal
