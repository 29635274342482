import React from 'react'

const SignupOrLoginText = () => {
  return (
    <div className='d-flex flex-column'>
      <span>Sign Up</span>
      <small>or</small>
      <span>Login</span>
    </div>
  )
}

export default SignupOrLoginText
