import React, { useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  ADD_BUSINESS_ROUTE,
  ADD_ORGANIZATION_ROUTE,
  LOGIN_ROUTE,
  SIGNUP_INDIVIDUAL_ROUTE,
} from '../../services/routeConst'
import { signupType } from '../../actions/auth'
import { toaster } from '../../services/customFunction'
import { Link, useHistory } from 'react-router-dom'

const getSignupType = (type) =>
  type === ADD_BUSINESS_ROUTE
    ? 'business'
    : type === ADD_ORGANIZATION_ROUTE
    ? 'organization'
    : 'individual'

const SignupUserTypeSelect = (props) => {
  const [signupType, setSignupType] = useState()
  const history = useHistory()

  const changeHandler = (event) => {
    let inputValue = event.target.value
    props.signupType(getSignupType(inputValue))
    setSignupType(inputValue)
  }

  const goToSignup = () => {
    if (!signupType) {
      toaster('error', 'Please select an account type!')
    } else {
      props?.handleSubmit
        ? props?.handleSubmit(getSignupType(signupType))
        : history.push({
            pathname: signupType,
            state: {
              signupType: getSignupType(signupType),
              imatrix_id: props.imatrix_id,
            },
          })
    }
  }

  return (
    <div className='signupblk1' style={props.style ?? {}}>
      <div className='signup_box'>
        <h3 className={`text-center ${props?.onPromotionScreen ? 'on-promotion-screen' : ''}`}>
          {props?.inModal ? 'Your account type' : 'Sign Up for Free'}
        </h3>
        <h4 className={`subtitle ${props?.onPromotionScreen ? 'on-promotion-screen' : ''}`}>
          {'I want to join iTRUSTit as a(n):'}
        </h4>
        <div className='your_choice'>
          <div className='choice_list' style={{ marginTop: 15 }}>
            <div className='radio1'>
              <label className='radioblk'>
                Business
                <input
                  type='radio'
                  name='radio'
                  value={ADD_BUSINESS_ROUTE}
                  onChange={changeHandler}
                />
                <span className='checkmark' />
              </label>
            </div>
            <p>
              Realtors, Contractors, Restaurants, Service Providers, Corporations, Independent Sales
              Reps.
            </p>
          </div>
          <div className='choice_list'>
            <div className='radio1'>
              <label className='radioblk'>
                Person
                <input
                  type='radio'
                  name='radio'
                  value={SIGNUP_INDIVIDUAL_ROUTE}
                  onChange={changeHandler}
                />
                <span className='checkmark' />
              </label>
            </div>
          </div>
          <div className='choice_list'>
            <div className='radio1'>
              <label className='radioblk'>
                Organization
                <input
                  type='radio'
                  name='radio'
                  value={ADD_ORGANIZATION_ROUTE}
                  onChange={changeHandler}
                />
                <span className='checkmark' />
              </label>
            </div>
            <p>
              Clubs, Groups, Associations, Chambers, Fraternities, Churches, if you have 'Members'.
            </p>
          </div>
          <div className='choice_list'>
            <div className='radio1'>
              <label className='radioblk'>
                Band, Sports Team, etc.
                <input
                  type='radio'
                  name='radio'
                  value={ADD_ORGANIZATION_ROUTE}
                  onChange={changeHandler}
                />
                <span className='checkmark' />
              </label>
            </div>
            <p>If you have 'Fans'.</p>
          </div>
          <div className='next'>
            <button
              onClick={signupType ? () => goToSignup() : () => {}}
              disabled={!signupType}
              className={!signupType ? 'btn disabled' : 'btn'}
            >
              Next
            </button>
          </div>
        </div>
      </div>
      <p className='text-center p_1'>
        Already part of the iTRUSTit Community?
        <Link className='purple' to={LOGIN_ROUTE}>
          Log In
        </Link>
      </p>
    </div>
  )
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ signupType }, dispatch)
}

export default connect(null, mapDispatchToProps)(SignupUserTypeSelect)
