import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { userProfile } from '../../services/authService'
import { LOGIN_ROUTE } from '../../services/routeConst'
import { Link } from 'react-router-dom'
import * as route from '../../services/routeConst'
import * as customFunction from '../../services/customFunction'
import * as environment from '../../services/environment'
import {
  DEFAULT_PROFILE_PICTURE,
  NO_COVER_PICTURE,
  REQUIRED_MESSAGE_FOR_SEARCH_NO_ORG,
} from '../../services/const'
import { setEchoConnected } from '../../actions/profile'
import { setSearchData, setIsSearching, setSearchDistance } from '../../actions/search'
import * as messageApiService from '../../services/messageApiService'
import * as activityApiService from '../../services/activityApiService'
import { withRouter } from 'react-router'
import Echo from 'laravel-echo'
import QRCodeModal from '../CommonComponents/QRCodeModal/QRCodeModal'
import MobileNavBar from './Components/MobileView/MobileNavBar'
import DropdownMenu from './Components/Dropdown/DropdownMenu'
import ReactGoogleAutocomplete from 'react-google-autocomplete'
import UserDropdown from './Components/Dropdown/UserDropdown'
import ITeamTreeIcon from './Components/Dropdown/ITeamTreeIcon'
import NotificationsDropdown from './Components/Dropdown/NotificationsDropdown'
import { isMobile } from 'react-device-detect'
import UserHeaderSearch from './Components/UserHeaderSearch'
import { useSearchSuggestions } from '../../hooks/useSearchSuggestions'
import { formValueSelector, reduxForm } from 'redux-form'
import { useCurrentLocation } from '../../hooks/useCurrentLocation'
window.io = require('socket.io-client')

let UserHeader = (props) => {
  const [stateData, setStateData] = useState({
    userImg: DEFAULT_PROFILE_PICTURE,
    user: {},
    searchAddressComponents: [],
    isSearchPage: false,

    chat_users_id: [],
    chat_users_list: [],
    chat_users_online_list: [],

    user_notifications: [],
    userNotificationCount: 0,
    iteamTotalCount: 0,
    showLocationField: true,
  })

  const {
    searchForSuggestions,
    searchingForSuggestions,
    searchSuggestions: suggestions,
    inputLengthRef,
    searchData: mobileSearchData,
    setSearchData: setMobileSearchData,
  } = useSearchSuggestions({
    searchStr: props?.searchData?.searchStr ?? '',
    searchAddress: props?.searchData?.searchAddress ?? '',
  })
  const { defaults, fetchedCategories, whileSearching, ...searchSuggestions } = suggestions

  const { currentLocation, getCurrentLocation } = useCurrentLocation((searchAddress) => {
    props.setSearchData({ ...props.searchData, searchAddress })
    setMobileSearchData((st) => ({ ...st, searchAddress }))
  })

  useEffect(() => {
    !isMobile && routeActive !== route.SEARCH_ROUTE && props.setIsSearching(false)
    !props.echoconnected && props.isLoggedIn && connectEcho()
    !mobileSearchData?.searchAddress &&
      !currentLocation &&
      !props?.isSearching &&
      getCurrentLocation()
    if (props.isLoggedIn) {
      getChatUserList()
      getUserNotificationList()
      getTotalIteamCount()
    }
  }, [])

  useEffect(() => {
    !!props?.isLoggedIn && fetchUserData()
  }, [props.isLoggedIn])

  useEffect(() => {
    setMobileSearchData((st) => ({ ...st, searchStr: props?.searchData?.searchStr }))
  }, [props?.searchData?.searchStr])

  const fetchUserData = async () => {
    let token = customFunction.userToken()
    if (customFunction.isUserLogin() && customFunction.userToken()) {
      const response = await userProfile(token)
      if (response?.status === true) {
        const { profileInfo } = response
        customFunction.setUser(profileInfo)

        if (profileInfo) {
          const { profile_picture_medium } = profileInfo?.user_detail
          setStateData((st) => ({ ...st, userImg: profile_picture_medium, user: profileInfo }))
        }
      } else {
        props.history.push({ pathname: LOGIN_ROUTE })
      }
    }
  }

  const connectEcho = () => {
    console.log('connecting echo')
    window.Echo = new Echo({
      broadcaster: 'socket.io',
      host: environment.SOCKET_URL,
      //client: Socketio,
      auth: {
        headers: {
          Accept: 'application/json',
          Authorization: customFunction.userToken(),
        },
      },
    })
    props.setEchoConnected(true)
    window.Echo.join('Online')
    // .here((users) => {
    //   let chatListLength = users.length
    //   let chatList = []
    //   if (chatListLength > 0) {
    //     for (let i = 0; i < chatListLength; i++) {
    //       chatList.push(users[i].id)
    //     }

    //       props?.onlineUserListChanged?.(chatList)

    //   }
    //   setStateData(st => ({...st, chatList}))
    // })
    // .joining((user) => {
    //   chatList.push(user.id)

    //     props?.onlineUserListChanged?.(chatList)

    //   setStateData((st) => ({ ...st, chatList }))
    // })
    // .leaving((user) => {
    //   const index = chatList.indexOf(user.id)
    //   if (index > -1) {
    //     chatList.splice(index, 1)

    //       props?.onlineUserListChanged?.(chatList)

    //     setStateData((st) => ({ ...st, chatList }))
    //   }
    // })
  }

  const getChatUserList = async () => {
    const response = await messageApiService.getChatUserListCall({})
    if (response?.status === 200) {
      const { data } = response ?? {}
      setStateData((st) => ({
        ...st,
        chat_users_list: data?.chat_users_list,
        chat_users_id: data?.chat_users_id,
      }))
    }
  }

  const getTotalIteamCount = async () => {
    const response = await activityApiService.totalIteamCountListCall({})
    if (response?.status === 200) {
      const { data } = response?.data ?? {}
      setStateData((st) => ({
        ...st,
        iteamTotalCount:
          data?.total_iteam_count > 0 ? data?.total_iteam_count - 1 : data?.total_iteam_count,
      }))
    }
  }

  const getUserNotificationList = async () => {
    const response = await activityApiService.notificationListCall({})
    if (response?.status === 200) {
      const { data } = response?.data ?? {}
      setStateData((st) => ({
        ...st,
        user_notifications: data?.user_notifications,
        userNotificationCount: data?.unread_notifications,
      }))
    }
  }

  const readNotification = async (data) => {
    if (data === 'iteam') {
      if (stateData.iteamTotalCount !== 0 && !stateData?.user?.checked_iteam_count) {
        messageApiService.checkITeamCount()
        fetchUserData()
      }
    } else {
      if (stateData.userNotificationCount !== 0) {
        await messageApiService.readUserNotification()
        setStateData((st) => ({ ...st, userNotificationCount: 0 }))
      }
    }
  }

  const handleSubmit = () => {
    if (mobileSearchData?.searchStr === '' || mobileSearchData?.searchStr?.length < 2) {
      customFunction.toaster('error', REQUIRED_MESSAGE_FOR_SEARCH_NO_ORG)
    } else {
      if (routeActive !== route.SEARCH_ROUTE) {
        !props.isSearching && props.setIsSearching(true)
      }
      props.setSearchData({
        searchStr: mobileSearchData?.searchStr,
        searchAddress: mobileSearchData?.searchAddress,
        searchAddressComponents: mobileSearchData?.searchAddressComponents,
        searchDistance: props.searchDistance,
      })
      if (routeActive !== route.SEARCH_ROUTE) {
        props.history.push({
          pathname: route.SEARCH_ROUTE,
        })
      }
    }
  }

  const {
    iteamTotalCount,
    userNotificationCount,
    chat_users_list,
    chat_users_online_list,
    user_notifications,
    userImg,
    user: stateUser,
  } = stateData

  const { user, coverimage, routeActive } = props ?? {}
  const isSearchPage = routeActive === route.SEARCH_ROUTE

  const userHistory = customFunction.getUserHistory()
  const userFullname =
    user?.user_type === 'individual'
      ? user?.username
      : user?.business_organization_name ?? userHistory?.[0]?.name ?? ''

  if (!!userHistory) {
    userHistory[0].profile_img = user?.user_detail?.profile_picture_medium
    userHistory[0].name = userFullname
    window.localStorage.setItem('profile_history', JSON.stringify(userHistory))
  }

  const {
    user_type,
    fullname,
    business_organization_name,
    user_detail: loginUserDetail,
  } = customFunction.getUser() ?? {}

  const dropdownProps = {
    chat_users_list,
    chat_users_online_list,
    user_notifications,
    userProfileAvatar:
      user?.user_detail?.profile_picture_medium ?? userImg ?? DEFAULT_PROFILE_PICTURE,
    userFullname,
    userCoverImg: coverimage
      ? coverimage
      : loginUserDetail?.cover_picture_medium ?? NO_COVER_PICTURE,
    userName: user_type === 'individual' ? fullname : business_organization_name,
    readNotification: readNotification,
    user: stateUser,
    iteamTotalCount,
    userNotificationCount,
    isLoggedIn: props.isLoggedIn,
    routeActive: props.routeActive,
  }

  return (
    <>
      <header className={`user-header-wrapper ${props.isLoggedIn ? ' logged-in-user-header' : ''}`}>
        {!(isMobile && isSearchPage) ? (
          <nav
            className={`navbar navbar-expand-lg user_header ${
              props.isLoggedIn ||
              ![
                route.ROOT_ROUTE,
                route.ABOUT_ROUTE,
                route.COMMUNITY_ROUTE,
                route.FAQ_ROUTE,
                route.CONTACT_ROUTE,
                route.MARKETING_CENTER_ROUTE,
              ].includes(routeActive)
                ? 'pb-2'
                : ''
            }`}
          >
            <div
              className='container-fluid align-items-center align-items-lg-start'
              style={{ maxWidth: 1790 }}
            >
              <Link className={'navbar-brand '} to={route.ROOT_ROUTE}>
                <img src={process.env.PUBLIC_URL + '/img/logo.png'} alt='logo' />
              </Link>

              {props.isLoggedIn ||
              ![
                route.ROOT_ROUTE,
                route.ABOUT_ROUTE,
                route.COMMUNITY_ROUTE,
                route.FAQ_ROUTE,
                route.CONTACT_ROUTE,
                route.MARKETING_CENTER_ROUTE,
              ].includes(routeActive) ? (
                <UserHeaderSearch
                  searchStr={mobileSearchData?.searchStr}
                  searchAddress={mobileSearchData?.searchAddress}
                  stateData={mobileSearchData}
                  setStateData={setMobileSearchData}
                  searchData={props.searchData}
                  setSearchData={props.setSearchData}
                  handleSubmit={handleSubmit}
                  inputLengthRef={inputLengthRef}
                  searchSuggestions={searchSuggestions}
                  searchForSuggestions={searchForSuggestions}
                  setIsSearching={props?.setIsSearching}
                  searchDistance={props.searchDistance}
                  setSearchDistance={props.setSearchDistance}
                />
              ) : null}
              {!!user && props.isLoggedIn ? (
                <div className='header-dropdown-content-wrapper d-lg-none mobile-user-dropdown'>
                  <ul className='navbar-nav ms-lg-auto align-items-center flex-row'>
                    <ITeamTreeIcon
                      className=' me-2'
                      readNotification={readNotification}
                      user={user}
                      iteamTotalCount={iteamTotalCount}
                    />

                    <NotificationsDropdown
                      readNotification={readNotification}
                      userNotificationCount={userNotificationCount}
                      user_notifications={user_notifications}
                    />
                    <UserDropdown
                      readNotification={readNotification}
                      userNotificationCount={userNotificationCount}
                      user={user}
                      userProfileAvatar={dropdownProps.userProfileAvatar}
                      userFullname={dropdownProps.userFullname}
                      userCoverImg={dropdownProps.userCoverImg}
                      userName={dropdownProps.userName}
                    />
                  </ul>
                </div>
              ) : null}

              <button
                className={`navbar-toggler ${!!user && props.isLoggedIn ? ' d-none' : ''}`}
                type='button'
                data-bs-toggle='collapse'
                data-bs-target='#navbarSupportedContent'
                aria-controls='navbarSupportedContent'
                aria-expanded='false'
                aria-label='Toggle navigation'
              >
                <span className='navbar-toggler-icon'></span>
              </button>
              <DropdownMenu {...dropdownProps} />
            </div>
          </nav>
        ) : null}
        <MobileNavBar
          isLoggedIn={props.isLoggedIn}
          chat_users_list={chat_users_list}
          routeActive={routeActive}
        />
      </header>
      <QRCodeModal user={customFunction.getUser()} />
    </>
  )
}

UserHeader = reduxForm({
  form: 'userHeaderForm',
  enableReinitialize: true,
})(UserHeader)

const selector = formValueSelector('userHeaderForm')

function mapStateToProps(state) {
  const searchDistance = state?.SearchReducer?.searchDistance
  return {
    user: state.UserReducer.userdetail,
    coverimage: state.UserReducer.coverImage,
    echoconnected: state.UserReducer.echoconnected,
    searchData: state.SearchReducer.searchData,
    isSearching: state.SearchReducer.isSearching,
    searchDistance,
    initialValues: {
      searchDistance: { value: '2mi', label: '2 miles' },
    },
  }
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { setEchoConnected, setSearchData, setIsSearching, setSearchDistance },
    dispatch,
  )
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserHeader))
