import AppLayout from '../../../AppLayout'
import LoginModal from '../../../LoginModal/LoginModal'
import * as route from '../../../../services/routeConst'
import '../Reports/Report.scss'
import { Link } from 'react-router-dom'

import { PDF_PATH } from '../../../../services/const'
const TradeMarkHTML = (props) => {
  window.scrollTo(0, 0)
  const Pdf = PDF_PATH + 'iTRUSTit Trademark Infringement Reporting FORM.pdf'
  return (
    <AppLayout routeActive={props.location.pathname}>
      <section className='bg-grey it-privacy-terms-page it-terms-page'>
        <div className='container'>
          <div className='white-pane privacy'>
            <b>
              <h1>
                <span className='colorblue dotunderline'>
                  <span>iTRUSTit</span>
                </span>
                <span className='colorblue'> Trademark Policy </span>
              </h1>
            </b>

            <br></br>
            <br></br>
            <h4>
              <b>Complaints about content on the iTRUSTit website</b>
            </h4>
            <br></br>
            <p>
              <span className='dotunderline'>iTRUSTit</span> aims to provide a platform that does
              not infringe on the intellectual property rights of others. It is a requirement of our
              User Agreement that any information provided by Members be truthful, legal, and not in
              violation of the rights of third-party parties. Complaints about material uploaded by
              iTRUSTit members can be submitted using a form on the site. Sections following discuss
              and/or reference our policies and practices.
            </p>
            <p>
              The complainant's contact information may be forwarded to the Member who posted the
              content, and/or iTRUSTit may take other reasonable steps to notify the Member that
              iTRUSTit has received notice of an alleged violation of intellectual property rights
              or other content violations, regardless of whether or not iTRUSTit disables access to
              or removes the content. For Members or organizations that persistently violate or
              infringe the rights of others, it is also our policy to deactivate and/or cancel their
              accounts. This is done at our discretion and in suitable situations.
            </p>
            <p>
              IMORTANT NOTE: Please keep in mind that any notice you send must be true and made
              under penalty of perjury. If a notification is false the person who submitted it may
              be held liable. This means that before sending a notice you should consult with legal
              counsel.
            </p>
            <p>
              We encourage iTRUSTit users to amicably resolve these maters first by contacting the{' '}
              <span className='dotunderline'>alleged</span> infringer directly to discuss a
              resolution.
            </p>
            <br></br>
            <h4>
              <b>Claims of trademark violation</b>
            </h4>
            <br></br>
            <p>
              If you, in good faith, believe that content posted by a member on our website
              infringes your trademark rights, please complete the Notice of{' '}
              <a style={{ color: '#6684f2' }} href={Pdf} rel='noreferrer' target='_blank'>
                Trademark Infringement Reporting Form (PDF).
              </a>
            </p>
            <p>
              Notice of trademark infringement should be sent to iTRUSTit Corporation's Trademark
              Agent using the following instructions:
            </p>
            <div className='nomargin'>
              <p>
                <span className='dotunderline'>iTRUSTit</span> Social, Inc.
              </p>
              <p>Attn: Legal Department</p>
              <p>703 Pier Ave Ste. B691</p>
              <p>Hermosa beach, CA 90254</p>
            </div>

            <br></br>
            <div className='nomargin'>
              <p>Alternatively, you can write to us at the following e-mail address:</p>
              <p>Legal@itrustit.com</p>
            </div>

            <br></br>
            <br></br>

            <h4 className='colorblue'>
              Complaints other than trademark infringement about the content of third-party websites
              or services
            </h4>
            <br></br>
            <p>
              See our{' '}
              <Link style={{ color: '#6684f2' }} to={route.FINDOUTMOREHTML_ROUTE} target='_blank'>
                Content on iTRUSTit,
              </Link>{' '}
              <Link style={{ color: '#6684f2' }} to={route.TERMS_ROUTE} target='_blank'>
                Terms of Service and Acceptable Use Policy
              </Link>{' '}
              for information on flagging and reporting different kinds of content violations if
              you're having problems with copyright infringement. To learn more, read this: iTRUSTit
              users should be aware of a policy prohibiting the use of any Associated Press content,
              including images, audio and video, in any form of publishing, broadcast, or
              redistribution, whether directly or indirectly. Except for personal and noncommercial
              use, these AP materials and any part of them may not be kept on a computer. No piece
              of the AP content on this website may be downloaded or reproduced without permission
              from the publisher. All or any portion of the information provided by AP will not be
              held accountable for any delays, mistakes, omissions, or delays in its transmission or
              delivery, or for any damages resulting from any of the aforementioned.
            </p>
            <br></br>
            <br></br>
            <br></br>
            <h4 className='colorblue'>Use the "Report this post" link or button.</h4>

            <p>
              If you see any anything on iTRUSTit that you feel is improper or hazardous, you can
              report it right there on the site.
            </p>

            <p>
              Only improper content, as defined by our{' '}
              <Link
                style={{ color: '#6684f2' }}
                to={route.GENERALGUIDELINESHTML_ROUTE}
                target='_blank'
              >
                General Guidelines,
              </Link>{' '}
              <Link style={{ color: '#6684f2' }} to={route.TERMS_ROUTE} target='_blank'>
                Terms of Service or Acceptable Use Policy,
              </Link>{' '}
              should be brought to our attention. To flag anything because of a disagreement in
              viewpoint isn't a valid justification.
            </p>

            <p>
              If required, we'll remove things that have been reported after examining them. To
              reveal the identity of someone who flags an item is against our Privacy Policy. There
              is nothing we can do about material that isn't directly related to our brand.
            </p>
          </div>
        </div>
      </section>
      <LoginModal
        isLogin={(e) =>
          function (data) {
            props.isLogin(data)
          }
        }
        {...props}
      />
    </AppLayout>
  )
}

export default TradeMarkHTML
