import React, { Component } from 'react'
import AppLayout from '../AppLayout'
import '../Message/message.scss'

class MessageBlankState extends Component {
  render() {
    return (
      <AppLayout>
        <section className='bg-grey for-msg'>
          <div className='container'>
            <div className='white-pane'>
              <div className='no_record1'>
                <img src={process.env.PUBLIC_URL + '/img/no_message.png'} alt='' />
              </div>
            </div>
          </div>
        </section>
      </AppLayout>
    )
  }
}

export default MessageBlankState
