import React from 'react'
import { DEFAULT_PROFILE_PICTURE } from '../../services/const'
import ChatListContent from './ChatListContent'

const ChatListUser = ({ user, chatUserId, chatUserSearchStr, onChatUserChange }) => {
  let nameToDisplay =
    user.user_type === 'individual' ? user.fullname : user.business_organization_name

  return (
    <li
      className={
        'message_item ' +
        (user.id === chatUserId ? ' message_item_open ' : '') +
        (chatUserSearchStr !== '' &&
        !user.fullname.toLowerCase().includes(chatUserSearchStr.toLowerCase())
          ? ' d-none '
          : '')
      }
      onClick={() => onChatUserChange(user.id)}
    >
      <div className='user_image'>
        <img src={user?.user_detail?.profile_picture_small ?? DEFAULT_PROFILE_PICTURE} alt='' />
      </div>
      <ChatListContent chat={user} displayName={nameToDisplay} />
    </li>
  )
}

export default ChatListUser
