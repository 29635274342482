import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk' //USING THUNK

import rootReducer from './reducers/index'
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const store = createStore(
  rootReducer,
  /* preloadedState, */ composeEnhancers(applyMiddleware(thunk)),
)

export default store
