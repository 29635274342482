import React, { useEffect } from 'react'
import AppLayout from '../AppLayout'
import './signup.scss'
import { SIGNUP_TITLE } from '../../services/titleConst'
import { Helmet } from 'react-helmet'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { signupType } from '../../actions/auth'
import SignupUserTypeSelect from './SignupUserTypeSelect'
const Signup = (props) => {
  useEffect(() => {
    props.signupType('individual')
    window.scrollTo(0, 0)
  }, [])

  const content = (
    <>
      <Helmet>
        <title>{SIGNUP_TITLE}</title>
      </Helmet>
      <div className='container'>
        <SignupUserTypeSelect
          imatrix_id={props.location.state ? props.location.state.imatrix_id : ''}
          handleSubmit={props?.handleSignupSelectSubmit}
          onPromotionScreen={props?.onPromotionScreen}
        />
      </div>
    </>
  )
  return !props?.hideAppLayout ? (
    <AppLayout isDisplayFooter={false}>{content}</AppLayout>
  ) : (
    <>{content}</>
  )
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ signupType }, dispatch)
}

export default connect(null, mapDispatchToProps)(Signup)
