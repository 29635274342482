import React from 'react'
import $ from 'jquery'

export const showConfirmDeleteReviewModal = () => {
  $('#confirm_delete_review_modal').modal('show')
}

export const hideConfirmDeleteReviewModal = () => {
  $('#confirm_delete_review_modal').modal('hide')
}

const ConfirmDeleteReviewModal = (props) => {
  const deleteReview = () => {
    props?.deleteReview()
  }

  return (
    <div
      className='modal fade confirm_delete_review_modal'
      id='confirm_delete_review_modal'
      role='dialog'
    >
      <div className='modal-dialog'>
        <div className='modal-content'>
          <div className='modal-header align-items-start pb-0'>
            <h5>Are you sure?</h5>
            <button type='button' className='btn-close' data-bs-dismiss='modal'></button>
          </div>
          <div className='modal-body d-flex flex-column align-items-center'>
            <h5 className='text-center varela fw-bold mb-2'>This action can not be undone.</h5>

            <div className='d-flex' style={{ gap: 8 }}>
              <button
                className='blue_btn copy-btn btn-danger mt-3'
                onClick={hideConfirmDeleteReviewModal}
              >
                Cancel
              </button>
              <button className='blue_btn copy-btn mt-3' onClick={deleteReview}>
                Delete Review
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ConfirmDeleteReviewModal
