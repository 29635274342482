import axios from 'axios'
import { customLogger } from '../utils/CustomLogger'
import * as environemnt from './environment'
import { userToken, toaster } from './customFunction'
import { NETWORK_ERROR_MSG } from './const'
//post APIs -

const generateUserError = (error) => {
  if (error === undefined || error.response === undefined) {
    toaster('error', NETWORK_ERROR_MSG)
  } else if ([401, 403, 422, 500].indexOf(error.response.status) >= 0) {
    if (error?.response?.data?.message) {
      toaster('error', error.response.data.message)
    } else {
      toaster('error', 'Something went wrong!!!')
    }
  }
}
const axiosRequest = async (url, method, request, contentType = 'application/json') => {
  // console.log('request : ', request)
  //config
  let config = {
    url: url,
    method: method,
    data: request,
    headers: {
      Authorization: userToken(),
      Accept: 'application/x.iTRUSTit.v1+json',
      'content-type': contentType,
    },
  }
  //call
  // console.log({ config })
  return axios(config)
    .then(function (response) {
      console.log('API response:', response)
      return response
    })
    .catch(function (error) {
      generateUserError(error)
      customLogger(error)
      return error.response
    })
}

//create  report record on the reports table
/*sample request ={entry_id: 173
entry_type: "post"
report_id: 4
user_id: 21066370}*/
export const reportCreateCall = async (request) => {
  const api_url = environemnt.BASE_API + 'report/create'
  const method = 'post'
  return axiosRequest(api_url, method, request)
}
//getReportData structure -ideally it should be included on the metdataCall
export const reportdataCall = async (request) => {
  const api_url = environemnt.BASE_API + 'report/reportdata'
  const method = 'get'
  return axiosRequest(api_url, method, request)
}
//getMetaData
export const metadataCall = async (request) => {
  const api_url = environemnt.BASE_API + 'metadata'
  const method = 'get'
  return axiosRequest(api_url, method, request)
}
//get posts
export const postListCall = async (request) => {
  const api_url = environemnt.BASE_API + 'posts'
  const method = 'post'
  return axiosRequest(api_url, method, request)
}
//create post
export const postCreateCall = async (request) => {
  let api_url = environemnt.BASE_API + 'posts/create'
  let method = 'post'
  return axiosRequest(api_url, method, request, 'multipart/form-data')
}
//repost post
export const postRecreateCall = async (request) => {
  let api_url = environemnt.BASE_API + 'posts/recreate'
  let method = 'post'
  return axiosRequest(api_url, method, request)
}
//delete post based on ID
export const postDeleteCall = async (request) => {
  let api_url = environemnt.BASE_API + 'posts/'
  if (request?.id > 0) {
    api_url = api_url + request.id
  }
  let method = 'delete'
  return axiosRequest(api_url, method, request)
}
//update post based on ID
export const postUpdateCall = async (request) => {
  console.log(request)
  let api_url = environemnt.BASE_API + 'posts/'
  if (request?.id > 0) {
    api_url = api_url + request.id + '/'
  }
  api_url = api_url + 'update'
  let method = 'post'
  return axiosRequest(api_url, method, request.formData, 'multipart/form-data')
}
//update Post settings
export const postUpdateSettingsCall = async (request) => {
  let api_url = environemnt.BASE_API + 'posts/settings'
  let method = 'post'
  return axiosRequest(api_url, method, request)
} //retrieve post based on ID
export const postRetrieveCall = async (request) => {
  let api_url = environemnt.BASE_API + 'posts/'
  if (request?.id > 0) {
    api_url = api_url + request.id
  }
  let method = 'post'
  return axiosRequest(api_url, method, request)
}

//comment APIs
//get comments
export const commentListCall = async (request) => {
  const api_url = environemnt.BASE_API + 'comments'
  const method = 'post'
  return axiosRequest(api_url, method, request)
}
//create comment
export const commentCreateCall = async (request) => {
  let api_url = environemnt.BASE_API + 'comments/create'
  let method = 'post'
  return axiosRequest(api_url, method, request)
}
//delete comment based on ID
export const commentDeleteCall = async (request) => {
  let api_url = environemnt.BASE_API + 'comments/'
  if (request?.id > 0) {
    api_url = api_url + request.id
  }
  let method = 'delete'
  return axiosRequest(api_url, method, request)
}
//update comment
export const commentUpdateCall = async (request) => {
  let api_url = environemnt.BASE_API + 'comments/'
  if (request?.id > 0) {
    api_url = api_url + request.id
  }
  let method = 'patch'
  return axiosRequest(api_url, method, request)
}
//retrieve comment based on ID
export const commentRetrieveCall = async (request) => {
  let api_url = environemnt.BASE_API + 'comments/'
  if (request?.id > 0) {
    api_url = api_url + request.id
  }
  let method = 'post'
  return axiosRequest(api_url, method, request)
}

//reply APIs
//get replies
export const replyListCall = async (request) => {
  const api_url = environemnt.BASE_API + 'replies'
  const method = 'post'
  return axiosRequest(api_url, method, request)
}
//create reply
export const replyCreateCall = async (request) => {
  let api_url = environemnt.BASE_API + 'replies/create'
  let method = 'post'
  return axiosRequest(api_url, method, request)
}
//delete reply based on ID
export const replyDeleteCall = async (request) => {
  let api_url = environemnt.BASE_API + 'replies/'
  if (request?.id > 0) {
    api_url = api_url + request.id
  }
  let method = 'delete'
  return axiosRequest(api_url, method, request)
}
//update reply
export const replyUpdateCall = async (request) => {
  let api_url = environemnt.BASE_API + 'replies/'
  if (request?.id > 0) {
    api_url = api_url + request.id
  }
  let method = 'patch'
  return axiosRequest(api_url, method, request)
}
//retrieve reply based on ID
export const replyRetrieveCall = async (request) => {
  let api_url = environemnt.BASE_API + 'replies/'
  if (request?.id > 0) {
    api_url = api_url + request.id
  }
  let method = 'post'
  return axiosRequest(api_url, method, request)
}
//reaction APIs
export const reactionListCall = async (request) => {
  const api_url = environemnt.BASE_API + 'reactions'
  const method = 'post'
  return axiosRequest(api_url, method, request)
}
//create reaction
export const reactionCreateCall = async (request) => {
  let api_url = environemnt.BASE_API + 'reactions/create'
  let method = 'post'
  return axiosRequest(api_url, method, request)
}
//delete reaction based on ID
export const reactionDeleteCall = async (request) => {
  let api_url = environemnt.BASE_API + 'reactions/'
  if (request?.id > 0) {
    api_url = api_url + request.id
  }
  let method = 'delete'
  return axiosRequest(api_url, method, request)
}
//update reaction
export const reactionUpdateCall = async (request) => {
  let api_url = environemnt.BASE_API + 'reactions/'
  if (request?.id > 0) {
    api_url = api_url + request.id
  }
  let method = 'patch'
  return axiosRequest(api_url, method, request)
}
//retrieve reaction based on ID
export const reactionRetrieveCall = async (request) => {
  let api_url = environemnt.BASE_API + 'reactions/'
  if (request?.id > 0) {
    api_url = api_url + request.id
  }
  let method = 'post'
  return axiosRequest(api_url, method, request)
}

export const excludedPostCreateCall = async (request) => {
  const api_url = environemnt.BASE_API + 'excludedpost/create'
  const method = 'post'
  return axiosRequest(api_url, method, request)
}
export const validatePostFileType = (file) => {
  let error = ''
  let isFormValid = true
  let fileType = ''
  if (!file) {
    //let stateCopy = { ...this.state };
    error = ['file is required']
    isFormValid = false
  } else if (
    typeof file.name !== 'undefined' &&
    !file.name
      .toLowerCase()
      .match(
        /(\.heic|\.heif|\.jpg|\.png|\.JPG|\.PNG|\.jpeg|\.JPEG|\.mp4|\.MP4|\.flv|\.FLV|\.mkv|\.MKV|\.m4v|\.M4V|\.avi|\.AVI|\.3gp|\.3GP|\.mpeg|\.MPEG|\.mov|\.MOV)$/,
      )
  ) {
    // /(\.jpg|\.png|\.JPG|\.PNG|\.jpeg|\.JPEG|\.mp4|\.MP4|\.flv|\.FLV|\.mkv|\.MKV)$/
    // |\.m4v|\.M4V
    // |\.avi|\.AVI
    // |\.3gp|\.3GP
    // |\.mpeg|\.MPEG
    // |\.mov|\.MOV
    error = ['file is invalid']
    isFormValid = false
  }
  if (
    file &&
    typeof file.name !== 'undefined' &&
    file.name.toLowerCase().match(/(\.heic|\.heif|\.jpg|\.png|\.JPG|\.PNG|\.jpeg|\.JPEG)$/)
  ) {
    fileType = 'image'
  } else if (
    file &&
    typeof file.name !== 'undefined' &&
    file.name
      .toLowerCase()
      .match(
        /(\.mp4|\.MP4|\.flv|\.FLV|\.mkv|\.MKV|\.m4v|\.M4V|\.avi|\.AVI|\.3gp|\.3GP|\.mpeg|\.MPEG|\.mov|\.MOV)$/,
      )
  ) {
    fileType = 'video'
  }
  return { error, isFormValid, fileType }
}

export const submitPostReport = async (request) => {
  let api_url = environemnt.BASE_API + 'submit-post-report'
  return axios
    .post(api_url, request, {
      headers: {
        Authorization: userToken(),
        Accept: 'application/x.iTRUSTit.v1+json',
      },
    })
    .then(function (response) {
      return response.data
    })
    .catch(function (error) {
      generateUserError(error)
      customLogger(error)
      return error.response
    })
}
