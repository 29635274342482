import React, { Component } from 'react'
import AppLayout from '../AppLayout'
import { Field, reduxForm } from 'redux-form'
import ReactSelect from '../../services/ReactSelect'
import * as api from '../../services/businessService'
import * as validation from '../../services/formValidation'
import renderInputField from '../Form/Input'
import { Redirect } from 'react-router-dom'
import * as route from '../../services/routeConst'
import * as environemnt from '../../services/environment'
import { getUserLabels } from '../../services/customFunction' //# 913 & 914 distinct between customer and owner
import axios from 'axios'
import { customLogger } from '../../utils/CustomLogger'

class AddBusinessOrOrganizationNameAndAddress extends Component {
  constructor(props) {
    super(props)
    this.state = {
      signup: {
        name: '',
        country_id: '',
        zipcode: '',
        state_id: '',
        city_id: '',
        address_line_2: '',
        address_line_1: '',
        user_type: this.props.location.state ? this.props.location.state.user_type : '',
        add_business_or_organization: this.props.location.state
          ? this.props.location.state.add_business_or_organization
          : '',
        phone_no_1: '',
        website_1: '',
        area: '',
      },
      countryList: [],
      stateList: [],
      cityList: [],
      stateValue: null,
      countryObject: '',
      cityValue: null,
      zipValue: '',
      imatrix_id: this.props.location.state ? this.props.location.state.imatrix_id : '',
      signUpFlag: this.props.location.state ? this.props.location.state.signUpFlag : '',
      city_name: '',
      cityFlag: false,
      areaFlag: false,
    }
  }

  componentDidMount() {
    this.country()
    window.scrollTo(0, 0)
    // if (this.props.location.state && this.props.location.state.signUpData) {
    //     setTimeout(() => {
    //         this.setState({
    //             signup: this.props.location.state.signUpData
    //         })
    //     }, 300)
    // }
  }
  singupHandler = (event) => {
    let inputName = event.target.name
    let inputValue = event.target.value

    let stateCopy = { ...this.state }
    stateCopy.signup[inputName] = inputValue
    // let oldHistoryState = this.props.location.state;
    // oldHistoryState["signUpData"] = stateCopy.signup;
    // this.props.history.replace({
    //     pathname: this.props.location.pathname,
    //     state: oldHistoryState
    // });
    this.setState(stateCopy)
  }

  country = () => {
    let self = this
    let stateCopy = { ...this.state }
    api.countryList().then(function (response) {
      if (response.status === true) {
        stateCopy['countryList'] = response.countries.map((country) => {
          return { value: country.id, label: country.name }
        })
        self.setState(stateCopy)
      }
    })
  }

  zipcodeChange = (event) => {
    if (
      event.target.value !== '' &&
      this.state.countryObject !== '' &&
      this.state.countryObject.label === environemnt.UNITED_STATE_TEXT
    ) {
      this.setState(
        {
          zipValue: event.target.value,
        },
        this.getDataUsingZip,
      )
    }
  }
  countrySelectHandler = (event) => {
    this.setState({ stateValue: null, cityValue: null })
    this.setState({ countryObject: event })
    if (
      event.value !== '' &&
      this.state.zipValue !== '' &&
      event.label === environemnt.UNITED_STATE_TEXT
    ) {
      this.setState(this.getDataUsingZip)
    } else {
      this.stateHandler(event.value)
    }
    this.setState({ countryObject: event })
    /*210824-1*/
    this.updateCountryDepencency()
  }
  /**
   * Function that return nothing and when country is selected reset all: state,zip, * city (area) and mark them as tocuhed for error. address may be included
   * @author   Amalia
   * @task     210824-1
   */
  updateCountryDepencency = () => {
    this.props.change('state_id', null)
    this.props.touch('state_id', null)
    this.props.change('zipcode', null)
    this.props.touch('zipcode', null)
    this.props.change('city_id', null)
    this.props.touch('city_id', null)
    this.props.change('area', null)
    this.props.touch('area', null)
  }
  // get city and state data using zip code when country is united state
  getDataUsingZip = () => {
    let api_url = environemnt.BASE_API + 'state-cities-from-zipcode'
    var params = {
      zipcode: this.state.zipValue,
      country_id: this.state.countryObject.value,
    }
    var self = this
    axios
      .post(api_url, params, {
        headers: { 'Content-Type': 'application/json' },
      })
      .then(function (response) {
        // set option in state dropdown and select first option as a state value
        var resultData = response.data.data
        if (resultData.state_list.length === 0) {
          self.stateHandler(self.state.countryObject.value)
        } else {
          var stateCopy = []
          stateCopy = resultData.state_list.map((state, index) => {
            return { value: state.id, label: state.name }
          })
          self.setState(
            {
              stateList: stateCopy,
            },
            () => {
              if (self.state.stateList.length > 0) {
                self.setState({
                  stateValue: self.state.stateList[0].value,
                })
                self.props.change('state_id', self.state.stateList[0])
              }
            },
          )

          var cityCopy = []
          cityCopy = resultData.city_list.map((state, index) => {
            return { value: state.id, label: state.name }
          })
          self.setState(
            {
              cityList: cityCopy,
            },
            () => {
              if (self.state.cityList.length > 0) {
                self.setState({
                  cityValue: self.state.cityList[0].value,
                  city_name: self.state.cityList[0].label,
                  cityFlag: true,
                  areaFlag: true,
                })
                // for redux form state update
                self.props.change('city_id', self.state.cityList[0])
              } else {
                self.setState({ cityFlag: false })
              }
            },
          )
        }
      })
      .catch(function (error) {
        customLogger(error)
        //return error.response;
      })
  }
  stateSelectHandler = (event) => {
    this.setState({ stateValue: event.value, cityValue: null })
    this.city(event.value)
    /*210824-1 when state is selected reset city and mark isas touched for error*/
    this.props.change('city_id', null)
    this.props.touch('city_id')
  }

  citySelectHandler = (event) => {
    let city = this.state.cityList
    let cityIndex = city.findIndex((x) => x.value === event.value)
    this.setState({ cityValue: event.value, city_name: city[cityIndex].label, areaFlag: true })
  }

  areaChangedHandler = (event) => {
    this.setState({ areaFlag: false, city_name: event.target.value })
  }

  stateHandler(id) {
    var self = this
    this.setState({ stateList: [], cityList: [], stateValue: null, cityValue: null })

    api.stateList(id).then(function (response) {
      if (response.status === true) {
        let stateCopy = { ...self.state }
        stateCopy['stateList'] = response.states.map((state, index) => {
          if (index === 0) return { value: 0, label: 'Select...' }
          return { value: state.id, label: state.name }
        })
        self.setState(stateCopy, () => {
          if (self.state.stateList.length > 0)
            self.setState({ stateValue: self.state.stateList[0].value })
        })
      }
    })
  }

  city(id) {
    let self = this
    this.setState({ cityList: [] })

    api.cityList(id).then(function (response) {
      if (response.status === true) {
        let stateCopy = { ...self.state }
        stateCopy['cityList'] = response.cities.map((city, index) => {
          if (index === 0) return { value: 0, label: 'Select...' }
          return { value: city.id, label: city.name }
        })
        self.setState(stateCopy, () => {
          if (self.state.cityList.length > 0)
            self.setState({ cityValue: self.state.cityList[0].value })
        })
        if (response.cities.length === 0) {
          self.setState({ cityFlag: true })
        } else {
          self.setState({ cityFlag: false })
        }
      }
    })
  }

  continueHandler = (data) => {
    this.state.signup.country_id = data.country_id ? data.country_id.value : ''
    this.state.signup.state_id = data.state_id ? data.state_id.value : ''
    if (window.innerWidth < 992 && this.state.cityValue && this.state.cityValue > 0) {
      this.state.signup.city_id = this.state.cityValue
    } else {
      this.state.signup.city_id = data.city_id ? data.city_id.value : ''
    }

    if (!this.state.areaFlag) {
      this.state.signup.area = data.area
      this.state.signup.city_id = 0
    }
    if (this.state.areaFlag) {
      this.state.signup.area = ''
    }

    this.state.signup.name = data.name
    this.state.signup.address_line_1 = data.address_line_1
    this.state.signup.address_line_2 = data.address_line_2
    this.state.signup.phone_no_1 = data.phone_no_1
    this.state.signup.website_1 = data.website_1
    this.props.history.push({
      pathname: route.ADD_BUSINESS_OR_ORGANIZATION_SEARCH_FOOTER_ROUTE,
      state: {
        city_name: this.state.city_name,
        user_type: this.props.location.state.user_type,
        form1: this.state.signup,
        imatrix_id: this.state.imatrix_id,
        signUpFlag: this.state.signUpFlag,
      },
    })
  }

  render() {
    const { countryList, stateList, cityList, countryObject } = this.state
    const { handleSubmit } = this.props
    // it's not effected on back button because it's only when we direct enter url on browser so no previous route

    if (!this.props.location.state) {
      return (
        <Redirect
          to={{
            pathname: route.ROOT_ROUTE,
          }}
        />
      )
    }
    //# 913 & 914
    const userTypeLabels = getUserLabels(this.props.location.state.add_business_or_organization)
    let customerOnlyRender
    if (userTypeLabels.type === 'Customer') {
      customerOnlyRender = (
        <div>
          <div className='row fieldflex form-group'>
            <div className='col-md-3'>
              <label>Phone number:</label>
            </div>
            <div className='col-md-9'>
              <Field
                name='phone_no_1'
                type='text'
                className='form-control'
                component={renderInputField}
                label='phone_no_1'
                placeholder='Enter Phone number'
                autoComplete='off'
                onChange={this.singupHandler}
              />
            </div>
          </div>
          <div className='row fieldflex form-group'>
            <div className='col-md-3'>
              <label>Website:</label>
            </div>
            <div className='col-md-9'>
              <Field
                name='website_1'
                type='text'
                className='form-control'
                component={renderInputField}
                validate={[validation.urlValidate]}
                label='website_1'
                placeholder='Enter Website'
                autoComplete='off'
                onChange={this.singupHandler}
              />
            </div>
          </div>
        </div>
      )
    }
    const usertype = this.props.location.state.user_type
    return (
      <AppLayout isDisplayFooter={false}>
        <form
          method='post'
          id='login'
          name='AddBusinessOrOrganizationNameAndAddress'
          onSubmit={handleSubmit(this.continueHandler).bind(this)}
        >
          <div className='container it-before-login'>
            <div className='claim_steps loginblk signupblk'>
              <h3 className='add_title'>
                <span className='other_txt'>
                  Add {usertype} as {userTypeLabels.type} Step-2
                </span>
                <span className='other_txt d-block'>Name & Address</span>
              </h3>
              <div className='row fieldflex form-group'>
                <div className='col-md-3'>
                  <label className='control-required'>{usertype} name:</label>
                </div>
                <div className='col-md-9'>
                  <Field
                    name='name'
                    type='text'
                    className='form-control'
                    component={renderInputField}
                    validate={[validation.required]}
                    label='Lastname'
                    placeholder={`Enter ${usertype} name`}
                    autoComplete='off'
                    valueKey={this.state.signup.name}
                    onChange={this.singupHandler}
                  />
                </div>
              </div>
              <div className='row fieldflex form-group'>
                <div className='col-md-3'>
                  <label className='control-required'>Country:</label>
                </div>
                <div className='col-md-9'>
                  <div className='for-select'>
                    <Field
                      type='text'
                      component={ReactSelect}
                      name='country_id'
                      options={countryList}
                      onChange={this.countrySelectHandler}
                      validate={[validation.required]}
                      isSelect='false'
                    />
                  </div>
                </div>
              </div>
              {countryObject ? (
                <div className='row fieldflex form-group'>
                  <div className='col-md-3'>
                    <label className='control-required'>Zip / Postal Code:</label>
                  </div>
                  <div className='col-md-9'>
                    <Field
                      name='zipcode'
                      type='text'
                      className='form-control'
                      component={renderInputField}
                      validate={[validation.required]}
                      label='zipcode'
                      placeholder='Enter zipcode'
                      autoComplete='off'
                      onChange={this.singupHandler}
                      onBlur={this.zipcodeChange}
                    />
                  </div>
                </div>
              ) : (
                ''
              )}
              {stateList.length > 0 ? (
                <div className='row fieldflex form-group'>
                  <div className='col-md-3'>
                    <label className='control-required'>State/Province:</label>
                  </div>
                  <div className='col-md-9'>
                    <div className='for-select'>
                      <Field
                        type='text'
                        className='form-control'
                        component={ReactSelect}
                        name='state_id'
                        options={stateList}
                        value={this.state.stateValue}
                        valueKey={this.state.stateValue}
                        onChange={this.stateSelectHandler}
                        validate={[validation.required]}
                        isSelect='true'
                      />
                    </div>
                  </div>
                </div>
              ) : (
                ''
              )}
              {cityList.length > 0 ? (
                <div className='row fieldflex form-group'>
                  <div className='col-md-3'>
                    <label className='control-required'>City:</label>
                  </div>
                  <div className='col-md-9'>
                    <div className='for-select'>
                      <Field
                        type='text'
                        className='form-control'
                        component={ReactSelect}
                        name='city_id'
                        options={cityList}
                        onChange={this.citySelectHandler}
                        value={this.state.cityValue}
                        valueKey={this.state.cityValue}
                        validate={[validation.required]}
                        isSelect='true'
                      />
                    </div>
                  </div>
                </div>
              ) : this.state.cityFlag ? (
                <div className='row fieldflex form-group'>
                  <div className='col-md-3'>
                    <label className='control-required'>City/Area:</label>
                  </div>
                  <div className='col-md-9'>
                    <Field
                      name='area'
                      type='text'
                      className='form-control'
                      component={renderInputField}
                      label='City/Area'
                      placeholder='Enter city/area'
                      autoComplete='off'
                      validate={[validation.required]}
                      onChange={this.areaChangedHandler}
                    />
                  </div>
                </div>
              ) : (
                ''
              )}
              <div className='row fieldflex form-group'>
                <div className='col-md-3'>
                  <label className='control-required'>Address 1:</label>
                </div>
                <div className='col-md-9'>
                  <Field
                    name='address_line_1'
                    type='text'
                    className='form-control'
                    component={renderInputField}
                    validate={[validation.required]}
                    label='address_line_1'
                    placeholder='Enter Address Line 1'
                    autoComplete='off'
                    onChange={this.singupHandler}
                  />
                </div>
              </div>
              <div className='row fieldflex form-group'>
                <div className='col-md-3'>
                  <label>Address 2:</label>
                </div>
                <div className='col-md-9'>
                  <Field
                    name='address_line_2'
                    type='text'
                    className='form-control'
                    component={renderInputField}
                    // validate={[validation.required]}
                    label='address_line_2'
                    placeholder='Enter Address Line 2'
                    autoComplete='off'
                    onChange={this.singupHandler}
                  />
                </div>
              </div>
              <div>{customerOnlyRender}</div>

              <div className='add_bottom'>
                <div className='text-center'>
                  {/* <a href={null} onClick={this.continueHandler} className="continue"> */}
                  {console.log(this.props, this.props.submitFailed, this.props.invalid)}
                  {/*210824-1*/}
                  {validation.isFormValid(this.props.submitFailed, '0', this.props.invalid, [
                    'text-danger',
                  ])}

                  <input type='submit' className='loginbtn' value='Continue' />

                  {/* </a> */}
                </div>
              </div>
            </div>
          </div>
        </form>
      </AppLayout>
    )
  }
}

AddBusinessOrOrganizationNameAndAddress = reduxForm({
  form: 'AddBusinessOrOrganizationNameAndAddress',
})(AddBusinessOrOrganizationNameAndAddress)
export default AddBusinessOrOrganizationNameAndAddress
// export default AddBusinessOrOrganizationNameAndAddress;
