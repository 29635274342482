import React, { useEffect } from 'react'
import $J from 'jquery'
import { ItiText } from '../../About/About'
import { PROFILE_ROUTE, ROOT_ROUTE } from '../../../services/routeConst'
import { DEFAULT_PROFILE_PICTURE } from '../../../services/const'
import PromotionShareIcon from './PromotionShareIcon'
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton } from 'react-share'
import { toaster } from '../../../services/customFunction'
import QRCode from '../../CommonComponents/QRCodeModal/QRCode'
import { SITE_ADDRESS } from '../../../services/environment'

export const showPromotionModal = () => $J('#promotion_modal').modal('show')

export const hidePromotionModal = () => $J('#promotion_modal').modal('hide')

const PromotionModal = ({ promotionData, user, promotionType, setPromotionType }) => {
  useEffect(() => {
    return () => {
      hidePromotionModal()
    }
  }, [])

  const copyToClipboard = async (text) => {
    if ('clipboard' in navigator) {
      await navigator.clipboard.writeText(text)
      toaster('success', 'Link copied successfully')
    } else {
      return document.execCommand('copy', true, text)
    }
  }

  // const shareLink = `${SITE_ADDRESS}promotion/${promotionData?.promotion?.id}/${user?.id}`
  const shareLink = `${SITE_ADDRESS}promotion/${user?.id}`

  return (
    <div className='modal fade login_blk reset_password_blk' id='promotion_modal' role='dialog'>
      <div className='modal-dialog'>
        <div className='modal-content'>
          <div className='modal-header position-relative'>
            <div className='position-absolute' style={{ top: 8, left: 8 }}>
              {promotionType === 'qrcode' ? (
                <button
                  className='promotion-back-button'
                  onClick={() => setPromotionType('invite')}
                >
                  <i className='fas fa-arrow-left' aria-hidden='true'></i>
                </button>
              ) : null}
            </div>
            <button type='button' className='btn-close' data-bs-dismiss='modal'></button>
            <div className='claim_steps'>
              {promotionType === 'friend' ? (
                <>
                  <h3 style={{ fontSize: 20, lineHeight: 1.15 }}>
                    <div className='d-flex promotion-prize-item p-1 in-modal'>
                      <div
                        className={`promotion-prize-img promotion-prize-img-individual me-2`}
                        style={{ maxWidth: 48, width: 48, height: 48, flexShrink: 0 }}
                      >
                        <img src={DEFAULT_PROFILE_PICTURE} width={48} alt='' />
                      </div>
                      <span className='other_txt'>
                        +Friend more People, Businesses &amp; Organizations, on <ItiText /> for
                        Extra Entries
                      </span>
                    </div>
                  </h3>
                  <div className='text-center mx-auto mt-md-4 mt-2 mb-1' style={{ maxWidth: 450 }}>
                    Get <strong>1 extra entry</strong> for every <strong>5 NEW friends</strong> you
                    add on <ItiText />.
                  </div>
                  <div className='text-center mx-auto mt-2 mb-1'>
                    A friend on <ItiText /> can be a Person, Business or Organization.
                  </div>
                  <div className='text-center mx-auto mt-4 mb-1' style={{ maxWidth: 450 }}>
                    To start,{' '}
                    <a
                      style={{ textDecoration: 'underline', color: '#000' }}
                      href={PROFILE_ROUTE}
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      <strong>Click Here</strong> to go to your profile page
                    </a>
                    , or go to any of our sponsors pages and check out their{' '}
                    <strong>Friend Lists, Trust Directories,</strong> and{' '}
                    <strong>Member Directories</strong> for other people, businesses or
                    organizations you may know and{' '}
                    <strong style={{ color: '#57d371' }}>+Friend</strong> them!
                  </div>
                </>
              ) : promotionType === 'invite' ? (
                <>
                  <h3 style={{ fontSize: 20, lineHeight: 1.15 }}>
                    <div className='d-flex promotion-prize-item align-items-center p-1 in-modal'>
                      <div
                        className={`promotion-prize-img promotion-prize-img-individual me-2`}
                        style={{ maxWidth: 48, width: 48, height: 48, flexShrink: 0 }}
                      >
                        <img src={DEFAULT_PROFILE_PICTURE} width={48} alt='' />
                      </div>
                      <span className='other_txt'>Invite Friends for Extra Entries</span>
                    </div>
                  </h3>
                  <div className='text-center mx-auto mt-md-4 mt-2 mb-1' style={{ maxWidth: 450 }}>
                    Get 10 extra entries for any Person, Business, or Organization that{' '}
                    <strong>Signs Up</strong> or <strong>Claims</strong> their page on <ItiText />{' '}
                    with this link
                  </div>
                  <a
                    className='text-center mx-auto mt-2 mb-1 d-block'
                    href={shareLink}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    {shareLink}
                  </a>
                  <div className='text-center mx-auto mt-2 mb-1'>Share this link via:</div>
                  <div
                    className='d-flex flex-wrap justify-content-center mx-auto mt-2 mb-1'
                    style={{ gap: 20 }}
                  >
                    <a
                      href={
                        'mailto:?subject=' +
                        encodeURIComponent('Come sign up on iTRUSTit!') +
                        '&body=' +
                        encodeURIComponent(shareLink)
                      }
                      style={{ color: 'black' }}
                    >
                      <PromotionShareIcon
                        img={process.env.PUBLIC_URL + '/img/email_invite.svg'}
                        text='Email'
                      />
                    </a>
                    <a href={`sms:?&body=${shareLink}`} style={{ color: 'black' }}>
                      <PromotionShareIcon
                        img={process.env.PUBLIC_URL + '/img/sms.svg'}
                        text='Text'
                      />
                    </a>
                    <PromotionShareIcon
                      img={process.env.PUBLIC_URL + '/img/copy.svg'}
                      text='Copy'
                      onClickCallback={() => copyToClipboard(shareLink)}
                      isBasic
                    />
                    <FacebookShareButton
                      url={shareLink}
                      quote={shareLink}
                      hashtag='#itrustit-fiesta-hermosa-promotion'
                    >
                      <PromotionShareIcon
                        img={process.env.PUBLIC_URL + '/img/fb_invite.svg'}
                        text='Facebook'
                      />
                    </FacebookShareButton>
                    <LinkedinShareButton
                      url={shareLink}
                      title={shareLink}
                      summary={shareLink}
                      source='iTRUSTit'
                    >
                      <PromotionShareIcon
                        img={process.env.PUBLIC_URL + '/img/linkedin_invite.svg'}
                        text='LinkedIn'
                      />
                    </LinkedinShareButton>

                    <TwitterShareButton
                      url={shareLink}
                      hashtags={['itrustit-fiesta-hermosa-promotion']}
                      // title={linkToShare}
                    >
                      <PromotionShareIcon
                        img={process.env.PUBLIC_URL + '/img/twitter_share.svg'}
                        text='Twitter'
                      />
                    </TwitterShareButton>
                    <PromotionShareIcon
                      img={process.env.PUBLIC_URL + '/img/email_invite.svg'}
                      text='QR code'
                      onClickCallback={() => setPromotionType('qrcode')}
                      isQR
                      isBasic
                    />
                  </div>
                  <div className='text-center mx-auto mt-2 mb-1' style={{ maxWidth: 410 }}>
                    NOTE: To be credited with an entry, your referral must complete at least 1{' '}
                    <strong style={{ color: '#57d371' }}>+Friend</strong> on <ItiText />
                  </div>
                </>
              ) : promotionType === 'qrcode' ? (
                <div className='d-flex flex-column align-items-center'>
                  <h3>Share this promotion</h3>
                  <QRCode
                    value={shareLink}
                    logoImage={process.env.PUBLIC_URL + '/img/iti_qr_logo.png'}
                    size={250}
                    qrStyle='dots'
                    // fgColor='#006699'
                    fgColor='black'
                    ecLevel='H'
                    eyeRadius={[
                      { outer: 10 }, // top/left eye
                      { outer: 10 }, // top/right eye
                      { outer: 10 }, // bottom/left eye
                    ]}
                  />
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PromotionModal
