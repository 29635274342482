import React from 'react'
import { UNCLAIMED_MORE_INFO_ROUTE } from '../../../services/routeConst'

const UnclaimedDisclaimer = ({ fullname }) => {
  return (
    <small className='ms-2 varela' style={{ color: '#89828c', fontSize: 12 }}>
      This page was generated by iTRUSTit. This page is not presently affiliated with, endorsed by
      or maintained by {fullname}.{' '}
      <a href={UNCLAIMED_MORE_INFO_ROUTE} target='_blank' rel='noopener noreferrer'>
        More information
      </a>
    </small>
  )
}

export default UnclaimedDisclaimer
