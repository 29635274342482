import React from 'react'

const Card = ({ header, body, onClick = () => {}, className = '' }) => {
  return (
    <div className={`d-flex flex-column mc-card ${className} h-100`} onClick={onClick}>
      <div className='mc-card-header mont'>
        <p style={!header ? { opacity: 0 } : {}}>{!header ? 'NA' : header}</p>
      </div>
      <div className='mc-card-body mont d-flex flex-column'>
        {body.map((item) => (
          <p style={!item ? { opacity: 0 } : {}}>{!item ? 'NA' : item}</p>
        ))}
      </div>
    </div>
  )
}

export default Card
