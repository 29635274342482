import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import $J from 'jquery'
import * as customFunction from '../../services/customFunction'
import { ADD_BUSINESS_ROUTE, ADD_ORGANIZATION_ROUTE, LOGOUT_ROUTE } from '../../services/routeConst'

const LogoutPopup = (props) => {
  const {
    user_type,
    busOrgName,
    redirectTo,
    isClaim = false,
    isAdd = true,
    idToClaim,
    modalId = '0',
  } = props
  const history = useHistory()

  useEffect(() => {
    return () => {
      $J('#add_as_owner_logout_popup_' + modalId).modal('hide')
    }
  }, [modalId])

  const handleSubmit = () => {
    const loginUser = customFunction.getUser()

    $J('#add_as_owner_logout_popup_' + modalId).modal('hide')

    history.push({
      pathname: LOGOUT_ROUTE,
      state: {
        imatrix_id: loginUser?.imatrix_id,
        redirectTo:
          redirectTo ??
          (user_type.toLowerCase() === 'business' ? ADD_BUSINESS_ROUTE : ADD_ORGANIZATION_ROUTE),
        redirectState: {
          signupType: user_type?.toLowerCase(),
          isAdd,
          isClaim,
          idToClaim,
          loginUserData: loginUser,
          busOrgName,
        },
      },
    })
  }

  return (
    <div>
      <div
        className='modal fade login_blk reset_password_blk it-add-business it-add-modal-sign'
        id={'add_as_owner_logout_popup_' + modalId}
        role='dialog'
      >
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header'>
              <div className='ownner_logout'>
                <h4 className='title'>
                  We need to log you out to continue {isClaim ? 'claiming' : 'adding'} your{' '}
                  {user_type?.toLowerCase() ?? 'business'}.
                </h4>
              </div>
              <div className='it-btn'>
                <div className='yes-bottom'>
                  <div className='text-center'>
                    <button href={null} onClick={handleSubmit} className='btn-yes'>
                      OK
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LogoutPopup
