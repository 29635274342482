import React, { Component } from 'react'
import './Gallery.scss'
import { DefaultPlayer as Video } from 'react-html5video'
import { getProfileOverviewGallery } from '../../../../services/userApiService'

import ImageGallery from 'react-image-gallery'

import 'react-image-gallery/styles/css/image-gallery.css'

class Gallery extends Component {
  constructor(props) {
    super(props)

    this.state = {
      showIndex: false,
      showBullets: false,
      infinite: true,
      showThumbnails: true,
      showFullscreenButton: false,
      showGalleryFullscreenButton: true,
      showPlayButton: false,
      showGalleryPlayButton: false,
      showNav: true,
      isRTL: false,
      slideDuration: 450,
      slideInterval: 2000,
      thumbnailPosition: 'bottom',
      showVideo: {},

      lazyLoad: true,
      thumbnailClass: 'thumbnailGalleryItem',
      originalClass: 'originalGalleryItem',

      user_id: this.props.user_id,
      galleryItems: [],
    }

    this.images = []
  }

  componentDidMount() {
    this.getProfileOverviewGallery()
  }

  componentWillReceiveProps(nextProps) {
    let user_id_new = nextProps.user_id
    let user_id_old = this.state.user_id

    if (user_id_new !== user_id_old) {
      this.setState({ user_id: user_id_new }, () => {
        this.getProfileOverviewGallery()
      })
    }
  }

  profileHeader_overview_tab_click_Handle() {
    this.getProfileOverviewGallery()
  }

  getProfileOverviewGallery() {
    const self = this
    let getProfileOverviewGalleryReqData = {
      other_user_id: this.state.user_id,
    }
    getProfileOverviewGallery(getProfileOverviewGalleryReqData).then(function (response) {
      if (
        response &&
        typeof response !== 'undefined' &&
        typeof response.status !== 'undefined' &&
        response.status === 200
      ) {
        let apiResponseData = response.data

        let galleryItemsRes = apiResponseData.data.gallery
        let galleryItemsResLength = galleryItemsRes.length
        let galleryItems = []

        if (galleryItemsResLength > 0) {
          for (var i = 0; i < galleryItemsResLength; i++) {
            let galleryItemRes = galleryItemsRes[i]
            if (galleryItemRes.file_type === 'video') {
              var tempObj = {
                thumbnail: galleryItemRes.gallery_video_thumb,
                original: galleryItemRes.gallery_video_thumb,
                embedUrl: galleryItemRes.gallery_video,
                renderItem: self._renderVideo.bind(self),
              }
            } else if (galleryItemRes.file_type === 'image') {
              var tempObj = {
                original: galleryItemRes.gallery_picture,
                thumbnail: galleryItemRes.gallery_picture_thumb,
              }
            }
            galleryItems.push(tempObj)
          }
        }

        self.setState({
          galleryItems: galleryItems,
        })
      }
    })
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.slideInterval !== prevState.slideInterval ||
      this.state.slideDuration !== prevState.slideDuration
    ) {
      // refresh setInterval
      this._imageGallery.pause()
      this._imageGallery.play()
    }
  }

  _onImageClick(event) {
    //console.debug('clicked on image', event.target, 'at index', this._imageGallery.getCurrentIndex());
  }

  _onImageLoad(event) {
    //console.debug('loaded image', event.target.src);
  }

  _onSlide(index) {
    this._resetVideo()
    //console.debug('slid to index', index);
  }

  _onPause(index) {
    // console.debug('paused on index', index);
  }

  _onScreenChange(fullScreenElement) {
    // console.debug('isFullScreen?', !!fullScreenElement);
  }

  _onPlay(index) {
    //console.debug('playing from index', index);
  }

  _handleInputChange(state, event) {
    this.setState({ [state]: event.target.value })
  }

  _handleCheckboxChange(state, event) {
    this.setState({ [state]: event.target.checked })
  }

  _handleThumbnailPositionChange(event) {
    this.setState({ thumbnailPosition: event.target.value })
  }

  _resetVideo() {
    this.setState({ showVideo: {} })

    if (this.state.showPlayButton) {
      this.setState({ showGalleryPlayButton: true })
    }

    if (this.state.showFullscreenButton) {
      this.setState({ showGalleryFullscreenButton: true })
    }
  }

  _toggleShowVideo(url) {
    this.state.showVideo[url] = !Boolean(this.state.showVideo[url])
    this.setState({
      showVideo: this.state.showVideo,
    })

    if (this.state.showVideo[url]) {
      if (this.state.showPlayButton) {
        this.setState({ showGalleryPlayButton: false })
      }

      if (this.state.showFullscreenButton) {
        this.setState({ showGalleryFullscreenButton: false })
      }
    }
  }

  _renderVideo(item) {
    return (
      <div className='image-gallery-image'>
        {this.state.showVideo[item.embedUrl] ? (
          <div className='video-wrapper'>
            <a
              className='close-video'
              onClick={this._toggleShowVideo.bind(this, item.embedUrl)}
            ></a>

            <Video
              autoPlay
              controls={['PlayPause', 'Seek', 'Time', 'Volume', 'Fullscreen']}
              poster={item.thumbnail}
              onCanPlayThrough={() => {
                // Do stuff
              }}
            >
              <source src={item.embedUrl} type='video/mp4' />
            </Video>
          </div>
        ) : (
          <a onClick={this._toggleShowVideo.bind(this, item.embedUrl)}>
            <div className='play-button play'></div>
            <img src={item.original} />
            {item.description && (
              <span className='image-gallery-description' style={{ right: '0', left: 'initial' }}>
                {item.description}
              </span>
            )}
          </a>
        )}
      </div>
    )
  }

  render() {
    const { galleryItems } = this.state

    return (
      <div className='page_info'>
        <h3 className='varela'>Gallery</h3>
        <div>
          {galleryItems.length > 0 ? (
            <ImageGallery
              ref={(i) => (this._imageGallery = i)}
              items={galleryItems}
              lazyLoad={false}
              onClick={this._onImageClick.bind(this)}
              onImageLoad={this._onImageLoad}
              onSlide={this._onSlide.bind(this)}
              onPause={this._onPause.bind(this)}
              onScreenChange={this._onScreenChange.bind(this)}
              onPlay={this._onPlay.bind(this)}
              infinite={this.state.infinite}
              showBullets={this.state.showBullets}
              showFullscreenButton={
                this.state.showFullscreenButton && this.state.showGalleryFullscreenButton
              }
              showPlayButton={this.state.showPlayButton && this.state.showGalleryPlayButton}
              showThumbnails={this.state.showThumbnails}
              showIndex={this.state.showIndex}
              showNav={this.state.showNav}
              isRTL={this.state.isRTL}
              thumbnailPosition={this.state.thumbnailPosition}
              slideDuration={parseInt(this.state.slideDuration)}
              slideInterval={parseInt(this.state.slideInterval)}
              additionalClass='app-image-gallery'
            />
          ) : (
            <div className='search-section'>
              <div className='no_search_result'>
                <img
                  src={process.env.PUBLIC_URL + '/img/empty_gallery.png'}
                  className='pt-5 pb-5 m-auto d-block'
                  alt=''
                />
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default Gallery
