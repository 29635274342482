import React, { Component, Fragment, useEffect, useState } from 'react'
import { ToastContainer } from 'react-toastify'
import { BrowserRouter as Router, Route, Switch, Redirect, useLocation } from 'react-router-dom'
import ComingSoon from './components/ComingSoon'
import LoginContainer from './containers/Login/LoginContainer'
import LandingContainer from './containers/Landing/LandingContainer'
import SignupIndividualContainer from './containers/SignupIndividual/SignupIndividualContainer'
import Signup from './components/Signup'
import SignupBusiness from './components/SignupBusiness/SignupBusiness'
import SignupOrganization from './components/SignupOrganization'
import AddBusiness from './components/AddBusiness'
import Home from './components/Home/'
// import SignupOrganization1 from "./components/SignupOrganization1";
import ProfilePerson from './components/ProfilePerson'
import ProfileBusiness from './components/ProfileBusiness'
//import EditProfile from "./components/EditProfile";
import EditProfile from './containers/EditProfile'

import EditOrganizationProfile from './containers/EditOrganizationProfile'
import EditBusinessProfile from './containers/EditBusinessProfile'

import BusinessReview from './components/BusinessReview'
import ReviewSuccess from './components/ReviewSuccess'
import business from './components/business'
import Message from './components/Message'
import MessageBlankState from './components/MessageBlankState'
import * as route from './services/routeConst'
import AddOrganization from './components/AddOrganization'
import About from './components/About'
import Community from './components/Community'
import CharterMember from './components/CharterMember'
import Claimbusiness from './components/Claimbusiness'
import Contact from './components/Contact'
import Search from './components/Search'
import MyProfile from './components/MyProfile'
//import Profile from './components/Profile'
import Profile from './containers/Profile'
import ProfileOtherUser from './components/ProfileOtherUser'
import Faq from './components/Faq'
import BetaReadme from './components/BetaReadme'
import Jobs from './components/Jobs'
import iPartner from './components/iPartner'
import Privacy from './components/Privacy'
import Terms from './components/Terms'
import Advertise from './components/Advertise'
import AddOrganizationAfterLogin from './components/AddOrganizationAfterLogin'
import Logout from './components/Logout'
import ResetPassword from './components/ResetPassword/ResetPassword'
import * as customFunction from './services/customFunction'
import Claim from './components/Claim/Claim'
import Form from './components/Claim/Form/Form'
import ClaimSuccess from './components/Claim/ClaimSuccess'
import SharedProfile from './components/SharedProfile'
import RegisterSuccess from './components/RegisterSuccess'
import EmailConfirmation from './components/EmailConfirmation'
import ProfileOtherBeforeLogin from './components/ProfileOtherBeforeLogin'
import AddBusinessOrOrganizationFooter from './components/AddBusinessOrOrganizationFooter'
import AddBusinessOrOrganizationNameAndAddress from './components/AddBusinessOrOrganizationNameAndAddress'
import AddBusinessOrOrganizationSearch from './components/AddBusinessOrOrganizationSearch'
import AddBusinessOrOrganizationCategory from './components/AddBusinessOrOrganizationCategory'
import MemberPopupModal from './components/ProfileOtherUser/MemberPopupModal'
import PrivacySettingsContainer from './containers/PrivacySettingsContainer'
import AddClaim from './components/AddClaim'
//import { ErrorBoundary } from 'react-error-boundary'
//import { ErrorFallback } from './utils/ErrorFallback'
import ErrorBoundary from './utils/ErrorBoundary'
import SearchLocationModal from './components/CommonComponents/SearchLocationModal/SearchLocationModal'
import VCard from './components/CommonComponents/VCard_New/VCard'
import ProfileFromQRCode from './components/ProfileFromQRCode/ProfileFromQRCode'
import VCardFromQRCode from './components/CommonComponents/VCardFromQRCode/VCardFromQRCode'
import { LinkedInCallback } from 'react-linkedin-login-oauth2'
import AddAddress from './components/SignupNew/AddAddress'
import AddBusOrg from './components/AddBusOrgNew/AddBusOrg'
import MoreInfoHTML from './components/SocialMedia/ReportContent/Reports/MoreInfoHTML'
import FindOutMoreHTML from './components/SocialMedia/ReportContent/Reports/FindOutMoreHTML'
import TradeMarkHTML from './components/SocialMedia/ReportContent/Reports/TradeMarkHTML'
import CopyRightHTML from './components/SocialMedia/ReportContent/Reports/CopyRightHTML'
import GeneralGuidelinesHTML from './components/SocialMedia/ReportContent/Reports/GeneralGuidelinesHTML'
import CounteNoticeInstructionsHTML from './components/SocialMedia/ReportContent/Reports/CounteNoticeInstructionsHTML'
import CopyRightInstructionsHTML from './components/SocialMedia/ReportContent/Reports/CopyRightInstructionsHTML'
import Promotion from './components/Promotion/Promotion'
import PromotionTerms from './components/Terms/PromotionTerms'
import PromotionPrivacy from './components/Privacy/PromotionPrivacy'
import MainSearch from './components/SearchNew/MainSearch'
import UnclaimedMoreInfo from './components/UnclaimedMoreInfo/UnclaimedMoreInfo'
import MarketingCenter from './components/MarketingCenter/MarketingCenter'
import MarketingCenterGuide from './components/MarketingCenterGuide/MarketingCenterGuide'
import MarketingCenterCTAExamples from './components/MarketingCenterCTAExamples/MarketingCenterCTAExamples'

// USER AUTH CHECK
const checkAuth = () => {
  var token = customFunction.userToken()
  if (token) return true
  else return false
}

const checkNeedsAddress = () => {
  const user = customFunction.getUser()
  if (['business', 'organization'].includes(user?.user_type) && !user?.address) return true
  else return false
}

const checkForComingSoon = () => {
  if (
    !localStorage.getItem('isEnterCode') &&
    !window.location.pathname.startsWith('/auth/email-confirmation/')
  ) {
    return true
  } else {
    return true
  }
}

// Like Auth guard
const AfterLoginRoute = ({ component: Component, isLogin, ...rest }) => {
  return (
    <ErrorBoundary
    /*FallbackComponent={ErrorFallback}
        onReset={() => (
          {log.error(e))}
          <Redirect
            to={{
              pathname: route.HOME_ROUTE,
            }}
          />
        )}
 */
    >
      <Route
        {...rest}
        render={(props) => {
          return checkForComingSoon() ? (
            checkNeedsAddress() &&
            !['/update-address', '/auth/logout'].includes(props?.location?.pathname) ? (
              <Redirect
                to={{
                  pathname: route.UPDATE_ADDRESS,
                }}
              />
            ) : checkAuth() ? (
              <Component {...props} isLogin={isLogin} />
            ) : (
              <Redirect
                to={{
                  pathname: route.LOGIN_ROUTE,
                }}
              />
            )
          ) : (
            <ComingSoon></ComingSoon>
          )
        }}
      />
    </ErrorBoundary>
  )
}
const BeforeLoginRoute = ({ component: Component, isLogin, ...rest }) => {
  //Asana 925 - Forgot Password Bug
  const toPasswordReset = rest?.path?.includes('/auth/password/reset/')
  return (
    <Route
      {...rest}
      render={(props) =>
        checkForComingSoon() ? (
          checkNeedsAddress() && props?.location?.pathname !== '/update-address' ? (
            <Redirect
              to={{
                pathname: route.UPDATE_ADDRESS,
              }}
            />
          ) : checkAuth() &&
            !(
              props?.location?.state?.isAdd &&
              props?.location?.state?.addType?.toLowerCase() === 'customer'
            ) ? (
            /*Asana 925 -removed embbeded if/else*/
            toPasswordReset ? (
              <Redirect
                to={{
                  pathname: route.LOGOUT_ROUTE,
                  state: { redirectTo: rest?.location?.pathname },
                }}
              />
            ) : (
              <Redirect
                to={{
                  pathname: route.PROFILE_ROUTE, ///go to Profile instead of home
                }}
              />
            )
          ) : (
            <Component {...props} isLogin={isLogin} /> //210930_aa-01 set prop in properly
          )
        ) : (
          <ComingSoon></ComingSoon>
        )
      }
    />
  )
}
// ErrorBoundry Added
const ErrorBoundaryRoute = ({ component: Component, isLogin, isLoggedIn, ...rest }) => {
  return (
    <ErrorBoundary>
      <Route
        {...rest}
        render={
          (props) =>
            checkNeedsAddress() && props?.location?.pathname !== '/update-address' ? (
              <Redirect
                to={{
                  pathname: route.UPDATE_ADDRESS,
                }}
              />
            ) : (
              <Component {...props} isLogin={isLogin} isLoggedIn={isLoggedIn} />
            ) //210930_aa-01 set prop in properly
        }
      />
    </ErrorBoundary>
  )
}

const App = () => {
  const [isLogin, setIsLogin] = useState(checkAuth())

  useEffect(() => {
    localStorage.removeItem('searchCache')
  }, [])

  const isLoginCheckHandler = (data) => {
    setIsLogin(data)
  }

  // const isEnterCode = localStorage.getItem('isEnterCode')
  // if (!isEnterCode && !window.location.pathname.startsWith('/auth/email-confirmation/')) {
  //   //return <ComingSoon></ComingSoon>
  // }
  return (
    <Fragment>
      <ToastContainer autoClose={4000} />
      <Router>
        <SearchLocationModal />
        <Switch>
          <BeforeLoginRoute
            exact
            path={route.LOGIN_ROUTE}
            component={LoginContainer}
            isLogin={isLoginCheckHandler}
          />
          <BeforeLoginRoute
            exact
            path={route.LINKEDIN_REDIRECT_ROUTE}
            component={LinkedInCallback}
            isLogin={isLoginCheckHandler}
          />
          <BeforeLoginRoute
            exact
            path={route.SIGNUP_INDIVIDUAL_ROUTE}
            component={SignupIndividualContainer}
          />
          <BeforeLoginRoute exact path={route.SIGNUP_NEW} component={SignupIndividualContainer} />
          <BeforeLoginRoute exact path={route.SIGNUP_ROUTE} component={Signup} />
          {/*Asana 210716-7, 914  - since SIGNUP_... works similar with ADD_.. I have taken out the Before/AfterRouting. None of this components changes the isLogin state */}
          {/* <ErrorBoundaryRoute exact path={route.SIGNUP_BUSINESS_ROUTE} component={SignupBusiness} /> */}
          {/* <ErrorBoundaryRoute
            exact
            path={route.SIGNUP_ORGANIZATION_ROUTE}
            component={SignupOrganization}
          /> */}
          <ErrorBoundaryRoute exact path={route.ADD_BUSINESS_ROUTE} component={AddBusOrg} />
          <ErrorBoundaryRoute exact path={route.ADD_ORGANIZATION_ROUTE} component={AddBusOrg} />
          <ErrorBoundaryRoute exact path={route.ADD_CLAIM_ROUTE} component={AddBusOrg} />
          {/* <ErrorBoundaryRoute
            exact
            path={route.ADD_ORGANIZATION_AFTER_LOGIN_ROUTE}
            component={AddOrganizationAfterLogin}
          /> */}
          <BeforeLoginRoute
            exact
            path={route.ROOT_ROUTE}
            component={LandingContainer}
            isLogin={isLoginCheckHandler}
          />
          <BeforeLoginRoute exact path={route.RESET_PASSWORD} component={ResetPassword} />
          <ErrorBoundaryRoute
            exact
            path={route.UNCLAIMED_MORE_INFO_ROUTE}
            component={UnclaimedMoreInfo}
            isLogin={isLoginCheckHandler}
          />
          <AfterLoginRoute
            exact
            path={route.MARKETING_CENTER_ROUTE}
            component={MarketingCenter}
            isLogin={isLoginCheckHandler}
          />
          <AfterLoginRoute
            exact
            path={route.MARKETING_CENTER_GUIDE_ROUTE}
            component={MarketingCenterGuide}
            isLogin={isLoginCheckHandler}
          />
          <AfterLoginRoute
            exact
            path={route.MARKETING_CENTER_CTA_EXAMPLES_ROUTE}
            component={MarketingCenterCTAExamples}
            isLogin={isLoginCheckHandler}
          />
          <ErrorBoundaryRoute
            exact
            path={route.ABOUT_ROUTE}
            component={About}
            isLogin={isLoginCheckHandler}
          />
          <ErrorBoundaryRoute
            exact
            path={route.COMMUNITY_ROUTE}
            component={Community}
            isLogin={isLoginCheckHandler}
          />
          <ErrorBoundaryRoute
            exact
            path={route.CONTACT_ROUTE}
            component={Contact}
            isLogin={isLoginCheckHandler}
          />
          <ErrorBoundaryRoute
            exact
            path={route.JOBS_ROUTE}
            component={Jobs}
            isLogin={isLoginCheckHandler}
          />
          <ErrorBoundaryRoute
            exact
            path={route.FAQ_ROUTE}
            component={Faq}
            isLogin={isLoginCheckHandler}
          />
          <ErrorBoundaryRoute
            exact
            path={route.BETAREADME_ROUTE}
            component={BetaReadme}
            isLogin={isLoginCheckHandler}
          />
          <ErrorBoundaryRoute
            exact
            path={route.ADVERTISE_ROUTE}
            component={Advertise}
            isLogin={isLoginCheckHandler}
          />
          <ErrorBoundaryRoute
            exact
            path={route.PRIVACY_ROUTE}
            component={Privacy}
            isLogin={isLoginCheckHandler}
          />
          <ErrorBoundaryRoute
            exact
            path={route.PROMOTION_PRIVACY_ROUTE}
            component={PromotionPrivacy}
            isLogin={isLoginCheckHandler}
          />
          <ErrorBoundaryRoute
            exact
            path={route.TERMS_ROUTE}
            component={Terms}
            isLogin={isLoginCheckHandler}
          />
          <ErrorBoundaryRoute
            exact
            path={route.PROMOTION_TERMS_ROUTE}
            component={PromotionTerms}
            isLogin={isLoginCheckHandler}
          />
          <AfterLoginRoute
            exact
            path={route.HOME_ROUTE}
            component={Home}
            isLogin={isLoginCheckHandler}
          />
          <AfterLoginRoute exact path={route.MESSAGE_ROUTE} component={Message} />
          <AfterLoginRoute exact path={route.GROUP_MESSAGE_ROUTE} component={Message} />
          <AfterLoginRoute
            exact
            path={route.MESSAGE_BLANK_STATE_ROUTE}
            component={MessageBlankState}
          />
          <AfterLoginRoute exact path={route.PROFILEPERSON_ROUTE} component={ProfilePerson} />
          {/* <AfterLoginRoute exact path={route.CLAIM_BUSINESS_ROUTE} component={Claimbusiness} /> */}
          <AfterLoginRoute exact path={route.PROFILE_BUSINESS_ROUTE} component={ProfileBusiness} />
          <AfterLoginRoute exact path={route.BUSINESS_REVIEW_ROUTE} component={BusinessReview} />
          <AfterLoginRoute exact path={route.REVIEW_SUCCESS_ROUTE} component={ReviewSuccess} />
          <ErrorBoundaryRoute exact path={route.EDIT_PROFILE_ROUTE} component={EditProfile} />
          {/* <AfterLoginRoute exact path={route.BUSINESS_ROUTE} component={business} /> */}
          <AfterLoginRoute exact path={route.MYPROFILE_ROUTE} component={MyProfile} />
          <AfterLoginRoute exact path={route.PROFILE_ROUTE} component={Profile} isLogin={isLogin} />
          {isLogin ? (
            <AfterLoginRoute exact path={route.SEARCH_ROUTE} component={MainSearch} />
          ) : (
            <ErrorBoundaryRoute
              exact
              path={route.SEARCH_ROUTE}
              component={MainSearch}
              isLogin={isLoginCheckHandler}
            />
          )}
          {isLogin ? (
            <AfterLoginRoute
              exact
              path={route.VCARD_ROUTE}
              component={VCard}
              isLogin={isLoginCheckHandler}
            />
          ) : (
            <ErrorBoundaryRoute
              exact
              path={route.VCARD_ROUTE}
              component={VCard}
              isLogin={isLoginCheckHandler}
            />
          )}
          {isLogin ? (
            <AfterLoginRoute
              exact
              path={route.QR_VCARD_ROUTE}
              component={VCardFromQRCode}
              isLogin={isLoginCheckHandler}
            />
          ) : (
            <ErrorBoundaryRoute
              exact
              path={route.QR_VCARD_ROUTE}
              component={VCardFromQRCode}
              isLogin={isLoginCheckHandler}
            />
          )}
          <AfterLoginRoute exact path={route.UPDATE_ADDRESS} component={AddAddress} />
          <AfterLoginRoute exact path={route.IPARTNER_ROUTE} component={iPartner} />
          <AfterLoginRoute exact path={route.CHARTER_MEMBER_ROUTE} component={CharterMember} />
          <AfterLoginRoute
            exact
            path={route.LOGOUT_ROUTE}
            component={Logout}
            isLogin={isLoginCheckHandler}
          />
          <AfterLoginRoute exact path={route.EDIT_BUSINESS_PROFILE_ROUTE} component={EditProfile} />
          <AfterLoginRoute
            exact
            path={route.EDIT_ORGANIZATION_PROFILE_ROUTE}
            component={EditProfile}
          />
          {/* CLAIM ROUTE */}
          <ErrorBoundaryRoute
            exact
            path={route.CLAIM_BUSINESS_PROFILE_ROUTE}
            component={Claim}
            isLogin={isLoginCheckHandler}
          />
          {/* <AfterLoginRoute exact path={route.CLAIM_BUSINESS_FORM_PROFILE_ROUTE} component={Form} />
          <ErrorBoundaryRoute
            exact
            path={route.CLAIM_BUSINESS_ADDRESS_FORM_PROFILE_ROUTE}
            component={Form}
          />
          <ErrorBoundaryRoute
            exact
            path={route.CLAIM_ORGANIZATION_ADDRESS_FORM_PROFILE_ROUTE}
            component={Form}
          /> */}
          <ErrorBoundaryRoute exact path={route.CLAIM_ACTIVATION_STATUS} component={ClaimSuccess} />
          <ErrorBoundaryRoute
            path={route.SHARED_PROFILE_ROUTE}
            component={SharedProfile}
            isLogin={isLoginCheckHandler}
            isLoggedIn={isLogin}
          />
          <ErrorBoundaryRoute
            path={route.SHARED_CLAIM_ROUTE}
            component={SharedProfile}
            isLogin={isLoginCheckHandler}
            isLoggedIn={isLogin}
          />
          <ErrorBoundaryRoute
            exact
            path={route.REGISTER_SUCCESS_ROUTE}
            component={RegisterSuccess}
          />
          <ErrorBoundaryRoute path={route.EMAIL_CONFIRMATION_ROUTE} component={EmailConfirmation} />
          {/* <ErrorBoundaryRoute
            exact
            path={route.ADD_BUSINESS_OR_ORGANIZATION_FOOTER_ROUTE}
            component={AddBusinessOrOrganizationFooter}
          />
          <ErrorBoundaryRoute
            exact
            path={route.ADD_BUSINESS_OR_ORGANIZATION_NAME_AND_ADDRESS_FOOTER_ROUTE}
            component={AddBusinessOrOrganizationNameAndAddress}
          />
          <ErrorBoundaryRoute
            exact
            path={route.ADD_BUSINESS_OR_ORGANIZATION_SEARCH_FOOTER_ROUTE}
            component={AddBusinessOrOrganizationSearch}
          />
          <ErrorBoundaryRoute
            exact
            path={route.ADD_BUSINESS_OR_ORGANIZATION_CATEGORY_FOOTER_ROUTE}
            component={AddBusinessOrOrganizationCategory}
          /> */}
          <ErrorBoundaryRoute exact path={route.ADD_MEMBER_POPUP} component={MemberPopupModal} />
          <ErrorBoundaryRoute
            exact
            path={route.PROFILE_SETTING_ROUTE}
            component={PrivacySettingsContainer}
          />
          {/*ReportHTMl */}
          <ErrorBoundaryRoute
            exact
            path={route.MOREINFOHTML_ROUTE}
            component={MoreInfoHTML}
            isLogin={isLoginCheckHandler}
          />
          {/*ReportHTMl */}
          <ErrorBoundaryRoute
            exact
            path={route.FINDOUTMOREHTML_ROUTE}
            component={FindOutMoreHTML}
            isLogin={isLoginCheckHandler}
          />
          {/*TradeMarkHTML*/}
          <ErrorBoundaryRoute
            exact
            path={route.TRADEMARKHTML_ROUTE}
            component={TradeMarkHTML}
            isLogin={isLoginCheckHandler}
          />
          {/*TradeMarkHTML*/}
          <ErrorBoundaryRoute
            exact
            path={route.COPYRIGHTHTML_ROUTE}
            component={CopyRightHTML}
            isLogin={isLoginCheckHandler}
          />
          {/*GeneralGuidelinesHTML*/}
          <ErrorBoundaryRoute
            exact
            path={route.GENERALGUIDELINESHTML_ROUTE}
            component={GeneralGuidelinesHTML}
            isLogin={isLoginCheckHandler}
          />
          {/*CounteNoticeInstructionsHTML */}
          <ErrorBoundaryRoute
            exact
            path={route.COUNTERNOTICEINSTRUCTIONSHTML_ROUTE}
            component={CounteNoticeInstructionsHTML}
            isLogin={isLoginCheckHandler}
          />
          {/*CopyRightInstructionsHTML */}
          <ErrorBoundaryRoute
            exact
            path={route.COPYRIGHTINSTRUCTIONSHTML_ROUTE}
            component={CopyRightInstructionsHTML}
            isLogin={isLoginCheckHandler}
          />
          <ErrorBoundaryRoute exact path={route.PROMOTION} component={Promotion} />
          <ErrorBoundaryRoute exact path={route.PROMOTION_SPONSOR} component={Promotion} />
          {/* profile by username  */}
          {isLogin ? (
            <AfterLoginRoute
              exact
              path={route.PROFILE_OTHER_USER_ROUTE}
              component={ProfileOtherUser}
            />
          ) : (
            <ErrorBoundaryRoute
              exact
              path={route.PROFILE_OTHER_USER_ROUTE}
              component={ProfileOtherBeforeLogin}
              isLogin={isLoginCheckHandler}
            />
          )}
          {isLogin ? (
            <AfterLoginRoute exact path={route.SINGLE_POST_ROUTE} component={ProfileOtherUser} />
          ) : (
            <ErrorBoundaryRoute
              exact
              path={route.SINGLE_POST_ROUTE}
              component={ProfileOtherBeforeLogin}
              isLogin={isLoginCheckHandler}
            />
          )}
          {/* profile by id from qr code  */}
          {isLogin ? (
            <AfterLoginRoute
              exact
              path={route.QR_PROFILE_OTHER_USER_ROUTE}
              component={ProfileFromQRCode}
            />
          ) : (
            <ErrorBoundaryRoute
              exact
              path={route.QR_PROFILE_OTHER_USER_ROUTE}
              component={ProfileFromQRCode}
              isLogin={isLoginCheckHandler}
            />
          )}
          {/*Redirect home any rute that is not handled to avoid white pages. Nt implementing the PagenotFoudn yet.*/}
          <Redirect
            to={{
              pathname: route.ROOT_ROUTE,
            }}
          />
        </Switch>
      </Router>
    </Fragment>
  )
}

export default App
