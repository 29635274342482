import React from 'react'
import Skeleton from 'react-loading-skeleton'
import { Link } from 'react-router-dom'
import { DEFAULT_PROFILE_PICTURE } from '../../../services/const'

const ReviewAvatar = ({ review }) => {
  return (
    <div className={`img_block ${!review?.id ? 'border-0' : ''}`}>
      {review?.id ? (
        <Link className='' to={process.env.PUBLIC_URL + '/' + review?.slug}>
          <img
            src={
              review?.profile_picture_small
                ? review?.profile_picture_small
                : DEFAULT_PROFILE_PICTURE
            }
            alt=''
          />
        </Link>
      ) : (
        <Skeleton width='100%' height='100%' />
      )}
    </div>
  )
}

export default ReviewAvatar
