export default class CachedSearch {
  constructor(searchFunction, resultsHandler, initialCache) {
    this.searchFunction = searchFunction
    this.resultsHandler = resultsHandler

    this.query = ''
    this.queryCount = 0
    this.cache = initialCache || {}
    this.cacheHits = 0
    this.cacheHitsHistory = []
  }

  changeQuery({
    business = '',
    citySearch = '',
    sortBy = 'name',
    sortOrder = 'asc',
    userType = 'all',
    canRetry = false,
    cityOverride,
    useGlobalSearch,
  }) {
    let userTypeParsed = userType ? userType : 'all'
    // console.log(business, citySearch);
    // this.searchFunction({ busSearch: business, addrSearch: citySearch });
    if (business.length < 2) {
      // noop
      // this.resultsHandler([]);
      return
    }
    let searchLoc = useGlobalSearch ? 'Global' : cityOverride ?? citySearch
    if (
      this?.cache?.[business]?.[searchLoc]?.[sortBy]?.[sortOrder]?.[userTypeParsed] &&
      getMinutesBetweenDates?.(
        this.cache[business][searchLoc][sortBy][sortOrder][userTypeParsed].addedOn || new Date(),
        new Date(),
      ) < 5
    ) {
      this.cacheHits = this.cacheHits + 1
      this.queryCount = this.queryCount + 1
      this.cacheHitsHistory.concat(business + ' ' + searchLoc)
      console.log(
        'query retrieved from cache:',
        business,
        searchLoc,
        sortBy,
        sortOrder,
        userTypeParsed,
        this.cache[business][searchLoc][sortBy][sortOrder][userTypeParsed],
      )
      const cachedData = this.cache[business][searchLoc][sortBy][sortOrder][userTypeParsed]
      //ONLY DOING SET TIMEOUT FOR VISUAL PURPOSES... NOT BECAUSE I NEED TO WAIT FOR SOMETHING
      setTimeout(() => {
        this.resultsHandler({
          response: cachedData.data,
          fromCache: true,
          cache: {
            cachedBusiness: business,
            cachedAddress: searchLoc,
            cachedUsersList: cachedData.usersList,
            cachedMarkerArr: cachedData.markerArr,
            cachedInitial: cachedData.initial,
          },
        })
      }, 250)
    } else {
      this.searchFunction({
        busSearch: business,
        addrSearch: searchLoc,
        canRetry,
        cityOverride,
        useGlobalSearch,
      }).then((response) => {
        console.log({ searchResponse: response })
        if (!response) return
        const oldCityData =
          this.cache[business] && this.cache[business][searchLoc]
            ? { ...this.cache[business][searchLoc] }
            : null
        const oldSortByData =
          oldCityData && this.cache[business][searchLoc][sortBy]
            ? { ...this.cache[business][searchLoc][sortBy] }
            : null
        const oldSortOrderData =
          oldSortByData && this.cache[business][searchLoc][sortBy][sortOrder]
            ? { ...this.cache[business][searchLoc][sortBy][sortOrder] }
            : null
        this.cache[business] = {
          ...this.cache[business],
          [searchLoc]: {
            ...oldCityData,
            [sortBy]: {
              ...oldSortByData,
              [sortOrder]: {
                ...oldSortOrderData,
                [userTypeParsed]: {
                  addedOn: new Date().getTime(),
                  data: response,
                },
              },
            },
          },
        }
        this.queryCount = this.queryCount + 1
        console.log('query added to cache:', business, searchLoc, sortBy, sortOrder, userTypeParsed)
        this.resultsHandler({
          response,
          cache: { cachedBusiness: business, cachedAddress: searchLoc },
        })
      })
    }
  }

  updateCache({ data, options }) {
    const { business, searchLoc, usersList, markerArr, initial } = data
    const { sortBy, userType, sortOrder } = options ?? {}
    let userTypeParsed = userType ? userType : 'all'
    if (this?.cache?.[business]?.[searchLoc]?.[sortBy]?.[sortOrder]?.[userTypeParsed]) {
      console.log(
        'updating cache with usersList',
        business,
        searchLoc,
        sortBy,
        sortOrder,
        userTypeParsed,
      )
      const cachedData = this.cache[business][searchLoc][sortBy][sortOrder][userTypeParsed]
      const prevUsersList = cachedData.usersList ? [...cachedData.usersList.user_list] : []
      const prevMarkerArr = cachedData.markerArr ? [...cachedData.markerArr] : []
      const newUserList = [...prevUsersList, ...usersList.user_list].filter(
        (thing, index, self) => self.findIndex((t) => t.id === thing.id) === index,
      )
      const newMarkerArr = [...prevMarkerArr, ...markerArr].filter(
        (thing, index, self) => self.findIndex((t) => t.id === thing.id) === index,
      )
      this.cache[business] = {
        ...this.cache[business],
        [searchLoc]: {
          ...this.cache[business][searchLoc],
          [sortBy]: {
            ...this.cache[business][searchLoc][sortBy],
            [sortOrder]: {
              ...this.cache[business][searchLoc][sortBy][sortOrder],
              [userTypeParsed]: {
                ...cachedData,
                usersList: {
                  total_pages: 1,
                  total_records: newUserList.length,
                  user_list: newUserList,
                },
                markerArr: newMarkerArr,
                initial,
              },
            },
          },
        },
      }
    }
    console.log({ cache: this.cache })
  }
}

const getMinutesBetweenDates = (startDate, endDate) => {
  console.log({ startDate, endDate })
  const diff = endDate.getTime() - startDate
  return diff / 60000
}
