import * as type from '../actions/const'

const initialState = {
  metadata: [],
  reportdata: [],
  posts: [],
  post: {
    id: 0,
    comment_type_id: '1',
    share_type_id: '1',
    share_group_id: '1',
    post_body: '',
    attachments: [],
    total_comments: 0,
    total_reactions: 0,
  },
  isUpdate: false,
  loading: false,
}

function handleReply(state, data, action) {
  const index = state.posts.findIndex((post) => post.id === data.id)

  const replyData = data.data
  if (index > -1) {
    const post = state.posts[index]
    //console.log('post found:', post, replyData, action)
    //find comment
    const indexComment = post?.comments.findIndex(
      (comment) => comment.id === replyData?.reply.comment_id,
    )
    if (indexComment > -1) {
      const comment = post.comments[indexComment]
      let indexReply
      switch (action) {
        case type.POST_REPLY:
          if (comment?.replies) comment.replies = [replyData.reply, ...comment.replies]
          else comment.replies = [replyData.reply]
          break
        case type.DELETE_REPLY:
          indexReply = comment.replies.findIndex((reply) => reply.id === replyData.reply.id)
          if (indexReply > -1)
            comment.replies = comment?.replies?.filter((reply) => reply.id !== replyData?.reply?.id)
          break
        case type.UPDATE_REPLY:
          indexReply = comment.replies.findIndex((reply) => reply.id === replyData.reply.id)
          if (indexReply > -1) comment?.replies?.splice(indexReply, 1, replyData.reply)
          break

        default:
      }
      //console.log('set comment:', comment)
      post?.comments.splice(indexComment, 1, comment)
      comment.total_replies = replyData?.total_replies
      //console.log('set post:', post)
      state.posts.splice(index, 1, post)
    }
  }
  return {
    ...state,
    posts: state.posts,
    loading: false,
  }
}
function addNewPostPost (state,data)
{
  let existingPosts = [...state?.posts]
  let newState =existingPosts
  //console.log('data:',data,data?.posts?.length)
  for (let i=0;i<data?.posts?.length;i++)
  {
    let dataPost=data?.posts[i]
    let index =state.posts.findIndex((post) => post.id === dataPost?.id)
    //console.log('data post:',dataPost,dataPost?.id,index)
    //doesnt exist 
    if(index<0)
      //and the share part 
      if (dataPost?.is_shared===100)
      {
        existingPosts =[...existingPosts,dataPost]
        //console.log('toBeAddedPosts1:',dataPost,existingPosts)
      }
      else
        existingPosts =[dataPost,...existingPosts]
        //console.log('toBeAddedPosts1:',dataPost,existingPosts)
  }
  
  console.log('toBeAddedPosts:',data,existingPosts)
  return existingPosts
}
function addNewSetPost (state,data)
{
  let existingPosts = [...state?.posts]
  let toBeAddedPosts=[]
  //console.log('data:',data,data?.posts?.length)
  for (let i=0;i<data?.posts?.length;i++)
  {
    let dataPost=data?.posts[i]
    let index =state.posts.findIndex((post) => post.id === dataPost?.id)
    //console.log('data post:',dataPost,dataPost?.id,index)
    if(index>-1 )
    {
      if (dataPost?.is_shared!==100)
      {
        let findPost = state.post[index]
        if (!!findPost?.is_shared)
        {
          findPost.is_shared=null     
          existingPosts.splice(index, 1, findPost)
        }
      }
    }
    else
      toBeAddedPosts.push(dataPost)
  }
  
  //console.log('toBeAddedPosts:',data,toBeAddedPosts, [...toBeAddedPosts,...existingPosts])
  return [...toBeAddedPosts,...existingPosts]
}
export default function SocialMediaReducer(state = initialState, action) {
  const data = action.data
  let index
  let post = {}
  let indexComment
  let comments = []
  let comment = {}
  let indexReply
  let reactionData
  let existingPosts=[]
  switch (action.type) {
    case type.LOADING_DATA:
      return {
        ...state,
        loading: true,
      }
    case type.SET_POSTS:
      //console.log('state:',state, data)
      // Creates an array of objects with unique "id" property values.
/*       let uniquePosts = [
        ...new Map([...state.posts, ...data].map((item) => [item['id'], item])).values(),
      ] */
      let uniquePosts =addNewSetPost (state,data)

      return {
        ...state,
        posts: uniquePosts,
        loading: false,
      }
    case type.SET_METADATA:
      return {
        ...state,
        metadata: data,
        loading: false,
      }
    case type.SET_REPORTDATA:
      return {
        ...state,
        reportdata: data,
        loading: false,
      }
    //is is based on post id
    case type.SET_CURRENT_POST:
      return {
        ...state,
        post: state.posts.filter((post) => post.id === data)[0],
      }
    case type.RESET_CURRENT_POST:
      return {
        ...state,
        post: initialState.post,
        isUpdate: initialState.isUpdate,
        isLoading: initialState.isLoading,
      }

    case type.SET_ISUPDATE:
      return {
        ...state,
        isUpdate: data,
      }
    case type.DELETE_POST:
      return {
        ...state,
        posts: state.posts.filter((post) => post.id !== data),
      }
    case type.POST_POST:
      //includes new post and the original post if any
      existingPosts =addNewPostPost(state,data)
      return {
        ...state,
        posts: existingPosts
      }
    case type.UPDATE_POST:
      index = state.posts.findIndex((post) => post.id === data?.posts[0]?.id)
      existingPosts = [...state.posts]
      if (index > -1) {
        existingPosts.splice(index, 1, data?.posts[0])
      }
      return {
        ...state,
        posts: existingPosts,
        loading: false,
      }
    case type.UPDATE_POST_SETTINGS:
      index = state.posts.findIndex((post) => post.id === data?.id)
      if (index > -1) {
        post = state.posts[index]
        post.share_type_id = data.share_type_id
        post.comment_type_id = data.comment_type_id
        post.share_group_id = data.share_group_id
        state.posts.splice(index, 1, post)
      }
      return {
        ...state,
        posts: state.posts,
        loading: false,
      }
    case type.UPDATE_POST_REACTION:
      index = state.posts.findIndex((post) => post.id === data.id)
      //console.log('data here:', data)
      let posts =state.posts;
      if (index > -1) {
        post = state.posts[index]
        post.reaction_data = data?.data?.reaction_data
        post.total_reactions = data?.data?.total_reactions
        posts.splice(index, 1, post)
      }

      return {
        ...state,
        posts: posts,
        loading: false,
      }
    case type.SET_COMMENTS:
      //console.log('set_comments:', data)
      index = state.posts.findIndex((post) => post.id === data.id)

      if (state.posts[index].comments) comments = [...state.posts[index].comments, ...data.comments]
      else comments = [...data.comments]
      // Creates an array of objects with unique "id" property values.
      let uniqueComments = [...new Map(comments.map((item) => [item['id'], item])).values()]
      state.posts[index].comments = uniqueComments
      return {
        ...state,
        loading: false,
      }
    case type.DELETE_COMMENT:
      index = state.posts.findIndex((post) => post.id === data?.comment?.post_id)
      if (index > -1) {
        if (state.posts[index].comments) {
          state.posts[index].comments = [
            ...state.posts[index].comments.filter((comment) => comment.id !== data?.comment?.id),
          ]
          state.posts[index].total_comments = data.total_comments
        }
      }
      return {
        ...state,
        loading: false,
      }
    case type.POST_COMMENT:
      //console.log('post comment:', data)
      index = state.posts.findIndex((post) => post.id === data?.comment?.post_id)
      //console.log('post comment:', index, state?.posts[index])
      if (index > -1) {
        if (state.posts[index]?.comments)
          state.posts[index].comments = [data?.comment, ...state.posts[index].comments]
        else state.posts[index].comments = [data?.comment]
        state.posts[index].total_comments = data.total_comments
      }
      return {
        ...state,
        loading: false,
      }
    case type.UPDATE_COMMENT:
      index = state.posts.findIndex((post) => post.id === data?.comment?.post_id)
      //console.log('update comment:', data, data?.comment?.post_id, index)
      if (index > -1) {
        let indexComment = state.posts[index].comments.findIndex(
          (comment) => comment.id === data?.comment?.id,
        )
        if (indexComment > -1) {
          if (state.posts[index].comments)
            state.posts[index].comments.splice(indexComment, 1, data?.comment)
        }
      }
      return {
        ...state,
        loading: false,
      }
    case type.UPDATE_COMMENT_REACTION:
      index = state.posts.findIndex((post) => post.id === data.post_id)

      reactionData = data.data
      if (index > -1) {
        post = state.posts[index]
        //console.log('post found:', post, reactionData, action)
        //find comment
        indexComment = post?.comments.findIndex((comment) => comment.id === data.id)
        if (indexComment > -1) {
          const comment = post.comments[indexComment]
          comment.reaction_data = reactionData.reaction_data
          comment.total_reactions = reactionData.total_reactions
          //console.log('set comment:', comment)
          post?.comments.splice(indexComment, 1, comment)
          //console.log('set post:', post)
          state.posts.splice(index, 1, post)
        }
      }
      return {
        ...state,
        loading: false,
      }
    case type.SET_REPLIES:
      //console.log('set replies:', data)
      index = state.posts.findIndex((post) => post.id === data.id)
      post = state.posts[index]
      indexComment = post?.comments.findIndex((comment) => comment.id === data.comment_id)
      //console.log('set replies1:', post, indexComment)
      if (indexComment >= 0) {
        let replies
        if (post?.comments[indexComment]?.replies)
          replies = [...post.comments[indexComment].replies, ...data.replies]
        else replies = [...data.replies]
        // Creates an array of objects with unique "id" property values.
        let uniqueReplies = [...new Map(replies.map((item) => [item['id'], item])).values()]
        post.comments[indexComment].replies = uniqueReplies
      }
      //console.log('set replies:', post)
      state.posts.splice(index, 1, post)
      return {
        ...state,
        posts: state.posts,
        loading: false,
      }
    case type.POST_REPLY:
    case type.DELETE_REPLY:
    case type.UPDATE_REPLY:
      //find post
      return handleReply(state, data, action.type)
    case type.UPDATE_REPLY_REACTION:
      //console.log('data:', data, action)
      index = state.posts.findIndex((post) => post.id === data.post_id)
      reactionData = data.data
      if (index > -1) {
        post = state.posts[index]
        //console.log('post found:', post, reactionData, action)
        //find comment
        indexComment = post?.comments.findIndex((comment) => comment.id === data.comment_id)
        if (indexComment > -1) {
          comment = post.comments[indexComment]
          indexReply = comment.replies.findIndex((reply) => reply.id === data.id)
          if (indexReply > -1) {
            comment.replies[indexReply].reaction_data = reactionData.reaction_data
            comment.replies[indexReply].total_reactions = reactionData.total_reactions
          }
          //console.log('set comment:', comment)
          post?.comments.splice(indexComment, 1, comment)
          //console.log('set post:', post)
          state.posts.splice(index, 1, post)
        }
      }
      return {
        ...state,
        loading: false,
      }
    case type.UPDATE_POST_COMMENT:
      return {
        ...state,
        post: {
          ...state.post,
          comments: [data, ...state.post.comments],
        },
      }
    case type.UPDATE_POST_ATTACHMENTS:
      return {
        ...state,
        post: {
          ...state.post,
          attachments: data,
        },
      }
    case type.UPDATE_POST_COMMENT_TYPE:
      return {
        ...state,
        post: {
          ...state.post,
          comment_type_id: data,
        },
      }
    case type.UPDATE_POST_SHARE_TYPE:
      return {
        ...state,
        post: {
          ...state.post,
          share_type_id: data,
        },
      }
    case type.UPDATE_POST_SHARE_GROUP:
      return {
        ...state,
        post: {
          ...state.post,
          share_group_id: data,
        },
      }

    case type.UPDATE_SELECTION_TITLE:
      return {
        ...state,
        post: {
          ...state.post,
          selection_title: data,
        },
      }
    case type.UPDATE_POST_BODY:
      return {
        ...state,
        post: {
          ...state.post,
          post_body: data,
        },
      }
    case type.RESET_SOCIAL_MEDIA_DATA:
      return {
        ...initialState,
      }
    default:
      return state
  }
}
