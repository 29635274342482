import React, { useEffect } from 'react'
import './Promotion.scss'
import HeaderLogo from '../CommonComponents/VCard/Components/HeaderLogo'
import { ItiText } from '../About/About'
import { useState } from 'react'
import { getInviteMatrixId, getUser, setInviteMatrixId } from '../../services/customFunction'
import LoginContainer from '../../containers/Login/LoginContainer'
import SignupIndividualContainer from '../../containers/SignupIndividual/SignupIndividualContainer'
import Signup from '../Signup'
import PrizeItem from './Components/PrizeItem'
import { DEFAULT_PROFILE_PICTURE } from '../../services/const'
import {
  LOGOUT_ROUTE,
  PROFILE_ROUTE,
  PROMOTION_TERMS_ROUTE,
  ROOT_ROUTE,
  TERMS_ROUTE,
} from '../../services/routeConst'
import PromotionModal, { showPromotionModal } from './Components/PromotionModal'
import {
  agreeToPromoTerms,
  getCurrentPromotion,
  listEntries,
} from '../../services/promotionService'
import LoadingOverlay from '../CommonComponents/LoadingOverlay'
import moment from 'moment'
import { useParams } from 'react-router-dom'
import { userProfileById } from '../../services/userApiService'
import AddBusOrg from '../AddBusOrgNew/AddBusOrg'

const Promotion = (props) => {
  const user = getUser()
  const params = useParams()
  const [agreeChecked, setAgreeChecked] = useState(false)
  const [promotionData, setPromotionData] = useState()
  const [referralUser, setReferralUser] = useState()
  const [step, setStep] = useState('start')
  const [promotionType, setPromotionType] = useState()

  useEffect(() => {
    const getPromotionData = async () => {
      const promotions = await getCurrentPromotion({ userId: user?.id })
      const promotionId = promotions?.data?.id
      if (!promotionId) {
        if (params?.id) {
          localStorage.setItem('promotion_sponsor_user_id', params?.id)
          const { data } = await userProfileById(params?.id)
          props?.history.push({
            pathname: data?.data?.user_details?.slug
              ? '/' + data?.data?.user_details?.slug
              : ROOT_ROUTE,
          })
        } else {
          props?.history.push({
            pathname: params?.id ? '/' + params.id : ROOT_ROUTE,
          })
        }
      } else {
        let promotionEntries
        if (user?.id) {
          promotionEntries = await listEntries({
            promotionId,
            userId: user?.id,
          })
        }
        setPromotionData({
          promotion: promotions?.data,
          entries: promotionEntries?.data?.promotion_entries,
        })
        user?.id && promotions?.data?.user_promotion_agreements?.length && setStep('prizes')
      }
    }

    const fetchSponsorData = async () => {
      try {
        localStorage.setItem('promotion_sponsor_user_id', params?.id)
        const { data } = await userProfileById(params?.id)
        // console.log({ user_details: data?.data?.user_details })
        setInviteMatrixId(data?.data?.user_details?.imatrix_id)
      } catch (error) {
        console.error(error)
      }
    }

    getPromotionData()
    !!params?.id && fetchSponsorData()
  }, [])

  useEffect(() => {
    const getPromotionEntries = async () => {
      const entries = await listEntries({
        promotionId: promotionData?.promotion?.id,
        userId: user?.id,
      })
      setPromotionData((st) => ({ ...st, entries: entries?.data?.promotion_entries }))
    }
    !!user?.id && !!promotionData?.promotion?.id && getPromotionEntries()
  }, [user?.id])

  const handleSelectUserType = (userType) => {
    if (['business', 'organization'].includes(userType)) {
      setStep('search_' + userType)
    } else {
      setStep('signup_' + userType)
    }
  }

  const handleAddBusOrgSubmit = (userType) => {
    setStep('signup_' + userType)
  }

  const handleBack = () => {
    switch (step) {
      case 'signup_business':
      case 'signup_organization':
        setStep('search_' + step.replace('signup_', ''))
        break
      case 'search_business':
      case 'search_organization':
      case 'signup_individual':
        setStep('signup')
        break
      default:
        setAgreeChecked(false)
        setStep('start')
        break
    }
  }

  // console.log({ promotionData })
  const totalEntries = Math.floor(
    [
      ...(promotionData?.promotion?.promotion_sponsors
        ?.map?.((sponsor) =>
          sponsor?.user?.is_friend_user &&
          !promotionData?.entries?.find?.((e) => e?.other_user_id === sponsor?.user?.id)
            ? { points: 1 }
            : null,
        )
        ?.filter?.(Boolean) ?? []),
      ...(promotionData?.entries ?? []),
    ]?.reduce?.(
      (acc, curr) => {
        return acc + curr?.points
      },
      !!user?.id && promotionData?.promotion?.user_promotion_agreements?.length ? 1 : 0,
    ) ?? (!!user?.id && promotionData?.promotion?.user_promotion_agreements?.length ? 1 : 0),
  )
  const enterUser = async (nextStep) => {
    if (
      user?.id &&
      (!promotionData?.promotion?.user_promotion_agreements?.length || agreeChecked)
    ) {
      const res = await agreeToPromoTerms({ promotionId: promotionData?.promotion?.id })
      if (res?.data?.id) {
        setPromotionData((st) => ({
          ...st,
          promotion: {
            ...st?.promotion,
            user_promotion_agreements: [
              ...st.promotion?.user_promotion_agreements,
              { id: res?.data?.id },
            ],
          },
        }))
      }
    }
    setStep(nextStep)
  }

  const getTimeLeft = () => {
    const a = moment(promotionData?.promotion?.end_date)
    const b = moment()
    const daysRemaining = a.diff(b, 'days')
    if (daysRemaining > 0) {
      return [daysRemaining, `Day${daysRemaining === 1 ? '' : 's'}`]
    } else {
      const hoursRemaining = a.diff(b, 'hours')
      if (hoursRemaining > 0) {
        return [hoursRemaining, `Hour${hoursRemaining === 1 ? '' : 's'}`]
      } else {
        const minutesRemaining = a.diff(b, 'minutes')
        if (minutesRemaining > 0) {
          return [minutesRemaining, `Minute${minutesRemaining === 1 ? '' : 's'}`]
        }
      }
    }
  }
  const timeLeft = getTimeLeft() ?? []
  const canClickButtons =
    promotionData?.promotion?.user_promotion_agreements?.length || agreeChecked
  const startTime = moment(promotionData?.promotion?.start_date)
  const endTime = moment(promotionData?.promotion?.end_date)
  return !promotionData?.promotion?.id ? (
    <LoadingOverlay show={!promotionData?.promotion?.id} />
  ) : (
    <div className='promotion-container d-flex flex-column'>
      <LoadingOverlay show={!promotionData?.promotion?.id} />
      <PromotionModal
        promotionType={promotionType}
        setPromotionType={setPromotionType}
        promotionData={promotionData}
        user={user}
      />
      <div className='copyright-footer text-center w-100 py-2 d-flex justify-content-end align-items-center ms-auto'>
        <a
          href={PROMOTION_TERMS_ROUTE}
          target='_blank'
          rel='noopener noreferrer'
          style={{ color: '#fff' }}
        >
          <small className='mont d-block fw-bold text-uppercase me-4'>Terms &amp; Conditions</small>
        </a>
        <small
          className='mont fw-bold text-uppercase me-4 promotion-header-btn'
          onClick={() => setStep('prizes')}
        >
          Prize Details
        </small>
      </div>
      <div className='promotion-wrapper d-flex flex-column align-items-center mx-auto px-2 mb-5 pb-2'>
        <HeaderLogo width={320} className='mb-0 py-1' src='/img/logo_basic-lg.png' />
        {step === 'start' || step === 'prizes' ? (
          <>
            <h1
              className='mont text-center'
              style={{ color: '#2d2d2d', fontStyle: 'italic', fontWeight: 700 }}
            >
              Memorial Week
            </h1>
            <h2 className='mont' style={{ color: '#2d2d2d' }}>
              $2500 Giveaway!
            </h2>
          </>
        ) : null}
        <div className='promotion-content-background-wrapper d-flex flex-column w-100 align-items-center'>
          <div className='promotion-content-wrapper d-flex flex-column align-items-center'>
            <div className='d-flex justify-content-center w-100'>
              <div
                className='d-flex flex-column align-items-center w-100 pt-2 pb-1'
                style={{ borderRight: '1px solid #eaeaea' }}
              >
                <div className='mont entries-count'>{totalEntries}</div>
                <div className='mont entries-label'>Your Entries</div>
              </div>
              <div
                className='d-flex flex-column align-items-center w-100 pt-2 pb-1'
                style={{ borderLeft: '1px solid #eaeaea' }}
              >
                <div className='mont entries-count'>{timeLeft[0] ?? '0'}</div>
                <div className='mont entries-label'>{timeLeft[1] ?? 'Days'} Left</div>
              </div>
            </div>
            <div className='d-flex justify-content-center w-100 position-relative'>
              <div className='w-100' style={{ borderBottom: '4px solid #049648' }}></div>
              <div className='w-100' style={{ borderBottom: '4px solid #e95d26' }}></div>
              {step !== 'start' ? (
                <div className='position-absolute' style={{ top: 8, left: 8, zIndex: 2000 }}>
                  <button className='promotion-back-button' onClick={handleBack}>
                    <i className='fas fa-arrow-left' aria-hidden='true'></i>
                  </button>
                </div>
              ) : null}
            </div>
            {!!promotionData?.promotion?.id ? (
              <>
                {step === 'start' || step === 'prizes' ? (
                  <>
                    <div className='d-flex flex-column mb-4'>
                      {step === 'prizes' ? (
                        <></>
                      ) : (
                        <>
                          <strong className='mont my-2'>
                            Memorial Day Weekend $2500 Giveaway!
                          </strong>
                          <small className='text-center'>
                            <strong>Start: </strong>
                            {startTime?.format('h:mm a')} on {startTime?.format('LL')} (Pacific
                            Time)
                          </small>
                          <small className='text-center'>
                            <strong>End: </strong>
                            {endTime?.format('h:mm a')} on {endTime?.format('LL')} (Pacific Time)
                          </small>
                        </>
                      )}
                    </div>
                    <div className='d-flex flex-column px-4 mt-3 promotion-prize-details'>
                      <h6>
                        1. <strong>Uncorked Wine Shops</strong> - Wine Tasting for 4 (valid only at
                        Uncorked) - Value $80
                      </h6>
                      <HeaderLogo
                        width={300}
                        className='mb-5 py-1 mx-auto'
                        src='/img/promotions/fiesta_hermosa/prizes/uncorked.png'
                      />
                      <h6>
                        2. <strong>Women in Technology International (WITI)</strong> - 1 Lifetime
                        Membership, (valid only for membership credit with WITI) - Value $1,000
                      </h6>
                      <HeaderLogo
                        width={300}
                        className='mb-5 py-1 mx-auto'
                        src='/img/promotions/fiesta_hermosa/prizes/witi.png'
                      />
                      <h6>
                        3. <strong>Fritto Misto</strong> - Gift Certificate for 2 Lunch Specials + 2
                        Soft Drinks, (valid only at Fritto Misto Hermosa Beach) - Value $36
                      </h6>
                      <HeaderLogo
                        width={300}
                        className='mb-5 py-1 mx-auto'
                        src='/img/promotions/fiesta_hermosa/prizes/fritto-misto.png'
                      />
                      <h6>
                        4. <strong>Fritto Misto</strong> - Gift Certificate for 2 Lunch Specials + 2
                        Soft Drinks, (valid only at Fritto Misto Hermosa Beach) - Value $36
                      </h6>
                      <HeaderLogo
                        width={300}
                        className='mb-3 py-1 mx-auto'
                        src='/img/promotions/fiesta_hermosa/prizes/fritto-misto.png'
                      />
                      <h6>
                        5. <strong>Kurtis Lee Thomas 'Man From The Stars'</strong> - 2 Breathwork
                        Detox Virtual Adventure Tickets (valid for the Breathwork Detox Virtual
                        Adventure on 6-1-2022) - Value $70
                      </h6>
                      <HeaderLogo
                        width={300}
                        className='mb-5 py-1 mx-auto'
                        src='/img/promotions/fiesta_hermosa/prizes/kurtis-lee-thomas.png'
                      />
                      <h6>
                        6. <strong>Kurtis Lee Thomas 'Man From The Stars'</strong> - 2 Breathwork
                        Detox Virtual Adventure Tickets (valid for the Breathwork Detox Virtual
                        Adventure on 6-1-2022) - Value $70
                      </h6>
                      <HeaderLogo
                        width={300}
                        className='mb-5 py-1 mx-auto'
                        src='/img/promotions/fiesta_hermosa/prizes/kurtis-lee-thomas.png'
                      />
                      <h6>
                        7. <strong>Kurtis Lee Thomas 'Man From The Stars'</strong> - 1 Breathwork
                        Detox Virtual Adventure Ticket (valid for the Breathwork Detox Virtual
                        Adventure on 6-1-2022) - Value $35
                      </h6>
                      <HeaderLogo
                        width={300}
                        className='mb-5 py-1 mx-auto'
                        src='/img/promotions/fiesta_hermosa/prizes/kurtis-lee-thomas.png'
                      />
                      <h6>
                        8. <strong>The UPS Store #0943</strong> - Gift Certificate (valid only at
                        The UPS Store #0943) - Value $75
                      </h6>
                      <HeaderLogo
                        width={300}
                        className='mb-5 py-1 mx-auto'
                        src='/img/promotions/fiesta_hermosa/prizes/ups-store.png'
                      />
                      <h6>
                        9. <strong>iTRUSTit Social (KHYC)</strong> - Sunset Happy Hour for 4 at King
                        Harbor Yacht Club (with member) - Value $200
                      </h6>
                      <HeaderLogo
                        width={300}
                        className='mb-5 py-1 mx-auto'
                        src='/img/promotions/fiesta_hermosa/prizes/khyc-sunset.png'
                      />
                      <h6>
                        10. <strong>iTRUSTit Social (KHYC)</strong> - Dinner or Brunch for 2 at King
                        Harbor Yacht Club (with member) (Date and times must be scheduled with
                        designated member) - Value $150
                      </h6>
                      <HeaderLogo
                        width={300}
                        className='mb-5 py-1 mx-auto'
                        src='/img/promotions/fiesta_hermosa/prizes/khyc-brunch.png'
                      />
                      <h6>
                        11. <strong>Emporium Thai</strong> - Gift Card (valid only at Emporium Thai
                        and emporiumthai.com) - Value $100
                      </h6>
                      <HeaderLogo
                        width={300}
                        className='mb-5 py-1 mx-auto'
                        src='/img/promotions/fiesta_hermosa/prizes/emporium-thai.png'
                      />
                      <h6>
                        12. <strong>Pedone's Pizza &amp; Italian Food</strong> - Gift Certificate
                        (valid only at Pedone's Hermosa Beach) - Value $20
                      </h6>
                      <HeaderLogo
                        width={300}
                        className='mb-5 py-1 mx-auto'
                        src='/img/promotions/fiesta_hermosa/prizes/pedones.png'
                      />
                      <h6>
                        13. <strong>Becker Surfboards</strong> - Beach Pack (Backpack, T-Shirt,
                        Surfboard leash) - Value $90
                      </h6>
                      <HeaderLogo
                        width={180}
                        className='mb-5 py-1 mx-auto'
                        src='/img/promotions/fiesta_hermosa/prizes/becker.png'
                      />
                      <h6>
                        14. <strong>Trick Electric Bikes Hermosa Beach</strong> (Thousand brand Bike
                        Helmet) - Value $90
                      </h6>
                      <HeaderLogo
                        width={300}
                        className='mb-5 py-1 mx-auto'
                        src='/img/promotions/fiesta_hermosa/prizes/trick-e-bikes.png'
                      />
                      <h6>
                        15. <strong>Leadership Manhattan Beach</strong> - $500.00 Scholarship for
                        2022-2023 class tuition
                      </h6>
                      <HeaderLogo
                        width={300}
                        className='mb-3 py-1 mx-auto'
                        src='/img/promotions/fiesta_hermosa/prizes/lmb.png'
                      />
                      {!user?.id ? (
                        <h5 className='mont text-center fw-bold mb-0' style={{ color: '#7d7d7d' }}>
                          To enter this promotion
                        </h5>
                      ) : null}
                    </div>
                    <div className='promotion-login-footer d-flex flex-column justify-content-center px-3 w-100'>
                      {step === 'start' ? (
                        <>
                          {user?.id ? (
                            <>
                              <small
                                className='mont text-center my-2'
                                style={{ color: '#7d7d7d', fontWeight: 700 }}
                              >
                                You are logged into <ItiText /> as: {user?.fullname}
                              </small>
                              <h5
                                className='mont text-center fw-bold mb-2'
                                style={{ color: '#7d7d7d' }}
                              >
                                To enter this promotion
                              </h5>
                            </>
                          ) : null}
                          {!promotionData?.promotion?.user_promotion_agreements?.length ? (
                            <div
                              className={
                                'd-flex align-items-center agree-checkbox mb-2' +
                                (!user?.id ? ' mt-2' : '')
                              }
                            >
                              <div className='px-4 mx-auto'>
                                <label htmlFor='agree' style={{ lineHeight: 1.25 }}>
                                  <small
                                    className='mont'
                                    style={{ display: 'inline-block', maxWidth: 230 }}
                                  >
                                    I acknowledge that I have read and agree to the{' '}
                                    <a
                                      className='mont fw-bold'
                                      style={{ textDecoration: 'underline', color: '#000' }}
                                      href={PROMOTION_TERMS_ROUTE}
                                      rel='noopener noreferrer'
                                      target='_blank'
                                    >
                                      Terms and Conditions
                                    </a>
                                  </small>
                                  <input
                                    type='checkbox'
                                    id='agree'
                                    name='agree'
                                    value={canClickButtons}
                                    onChange={() => setAgreeChecked((st) => !st)}
                                    checked={canClickButtons}
                                  />
                                  <span className='checkmark' />
                                </label>
                              </div>
                            </div>
                          ) : null}
                          {!user?.id ? (
                            <small
                              className='mont text-center mx-auto'
                              style={{ color: '#7d7d7d', fontWeight: 700, maxWidth: 320 }}
                            >
                              For existing <ItiText /> users, use this button to Enter &amp; Log In
                              to your account.
                            </small>
                          ) : null}
                          <button
                            className={`login-button my-1 mx-auto ${user?.id ? 'mb-4' : ''} ${
                              canClickButtons ? '' : 'is-disabled'
                            }`}
                            onClick={
                              canClickButtons
                                ? () => (!!user?.id ? enterUser('prizes') : enterUser('login'))
                                : () => {}
                            }
                          >
                            {user?.id ? 'Click Here to Enter' : 'Click Here to LOG IN and Enter'}
                          </button>
                          {!user?.id ? (
                            <>
                              <h5
                                className='mont text-center fw-bold my-2'
                                style={{ color: '#7d7d7d' }}
                              >
                                OR
                              </h5>
                              <small
                                className='mont text-center mx-auto'
                                style={{ color: '#7d7d7d', fontWeight: 700, maxWidth: 200 }}
                              >
                                If you're not on <ItiText /> yet, SIGN UP and Enter here
                              </small>
                              <button
                                className={`signup-button mt-1 mx-auto mb-4 ${
                                  canClickButtons ? '' : 'is-disabled'
                                }`}
                                onClick={canClickButtons ? () => enterUser('signup') : () => {}}
                              >
                                Click Here to SIGN UP and Enter
                              </button>
                            </>
                          ) : null}
                        </>
                      ) : (
                        <>
                          <h5
                            className='mont text-center my-2'
                            style={{ color: '#7d7d7d', fontWeight: 700 }}
                          >
                            10+ Ways to Enter
                          </h5>
                          <div className='d-flex flex-column'>
                            <small
                              className='mont mb-2 promotion-prize-item ps-1'
                              style={{ color: '#7d7d7d', fontWeight: 700 }}
                            >
                              Logged in and Entering as: {user?.fullname}
                            </small>
                            <PrizeItem
                              prizeType='bus'
                              rewardType='check'
                              text='Login or Sign Up on iTRUSTit during this promotion'
                              isCompleted
                            />
                            <PrizeItem
                              prizeType='individual'
                              rewardType='multiplier'
                              text='Invite friends to iTRUSTit for extra entries'
                              reward='+10x'
                              img={DEFAULT_PROFILE_PICTURE}
                              onClick={() => {
                                setPromotionType('invite')
                                showPromotionModal()
                              }}
                            />
                            {promotionData?.promotion?.promotion_sponsors
                              ?.filter((s) => !!s?.user?.id)
                              ?.map((sponsor) => (
                                <PrizeItem
                                  key={sponsor?.user_id}
                                  prizeType={
                                    sponsor?.user?.user_type === 'organization'
                                      ? 'org'
                                      : sponsor?.user?.user_type === 'business'
                                      ? 'bus'
                                      : 'individual'
                                  }
                                  rewardType='add'
                                  text={`+Friend ${
                                    sponsor?.user?.user_type === 'individual'
                                      ? sponsor?.user?.firstname + ' ' + sponsor?.user?.lastname
                                      : sponsor?.user?.business_organization_name
                                  }`}
                                  reward='+1'
                                  sponsorUserSlug={sponsor?.user?.slug}
                                  isCompleted={
                                    !!sponsor?.user?.is_friend_user ||
                                    !!promotionData?.entries?.find(
                                      (e) => e?.other_user_id === sponsor?.user_id,
                                    )
                                  }
                                />
                              ))}
                            <PrizeItem
                              prizeType='individual'
                              rewardType='multiplier'
                              text='+Friend other People Businesses & Orgs on iTRUSTit'
                              reward='+1x'
                              img={DEFAULT_PROFILE_PICTURE}
                              onClick={() => {
                                setPromotionType('friend')
                                showPromotionModal()
                              }}
                            />
                            <PrizeItem
                              prizeType='org'
                              text='+Friend Leadership Hermosa Beach LHB'
                              sponsorUserSlug='leadershiphb'
                            />
                            <PrizeItem
                              prizeType='bus'
                              text='+Friend City of Hermosa Beach on iTRUSTit'
                              sponsorUserSlug='HermosaBeachCity'
                            />
                            <PrizeItem
                              prizeType='org'
                              text='+Friend Hermosa Beach Chamber on iTRUSTit'
                              sponsorUserSlug='hbchamber'
                            />
                            <PrizeItem
                              prizeType='bus'
                              text='+Friend Fiesta Hermosa on iTRUSTit'
                              sponsorUserSlug='FiestaHermosa'
                            />
                          </div>
                        </>
                      )}
                      <a
                        className='text-center'
                        href={PROMOTION_TERMS_ROUTE}
                        rel='noopener noreferrer'
                        target='_blank'
                      >
                        <small
                          className='mont text-center'
                          style={{ fontSize: 10, color: '#7d7d7d' }}
                        >
                          Terms &amp; Conditions | &copy; iTRUSTit Social, Inc.
                        </small>
                      </a>
                    </div>
                  </>
                ) : step === 'login' ? (
                  <LoginContainer
                    {...props}
                    hideAppLayout
                    onPromotionScreen
                    setPromotionStep={setStep}
                  />
                ) : step === 'signup' ? (
                  <Signup
                    {...props}
                    hideAppLayout
                    onPromotionScreen
                    handleSignupSelectSubmit={handleSelectUserType}
                  />
                ) : step.includes('search_') ? (
                  <AddBusOrg
                    hideAppLayout
                    onPromotionScreen
                    {...props}
                    promotionData={{
                      imatrix_id: getInviteMatrixId(),
                      signupType: step?.replace('search_', ''),
                    }}
                    handleSubmit={handleAddBusOrgSubmit}
                  />
                ) : step.includes('signup_') ? (
                  <SignupIndividualContainer hideAppLayout onPromotionScreen {...props} />
                ) : null}
              </>
            ) : (
              <div className='d-flex flex-column' style={{ height: 100 }}></div>
            )}
          </div>
        </div>
        {!!user?.id && step !== 'login' && !step?.includes('signup') ? (
          <div className='d-flex align-items-center justify-content-between w-100 mt-2'>
            <a className='promotion-footer-btn' href={LOGOUT_ROUTE}>
              <small className='mont' style={{ textDecoration: 'underline' }}>
                Log Out
              </small>
            </a>
            <a
              className='promotion-footer-btn'
              href={PROFILE_ROUTE}
              target='_blank'
              rel='noopener noreferrer'
            >
              <small className='mont' style={{ textDecoration: 'underline' }}>
                Go to my <ItiText /> profile
              </small>
            </a>
          </div>
        ) : null}
      </div>
      <div
        className='d-flex flex-column w-100 px-5 mx-auto mt-auto mb-5'
        style={{ maxWidth: 1080, gap: 30, flexWrap: 'wrap' }}
      >
        <div
          className='d-flex w-100 justify-content-evenly align-items-center'
          style={{ flexWrap: 'wrap', gap: 30 }}
        >
          <HeaderLogo
            width={150}
            className='my-3 my-md-0'
            src='/img/promotions/fiesta_hermosa/sponsors/beckersurf.png'
          />
          <HeaderLogo
            width={150}
            className='my-3 my-md-0'
            src='/img/promotions/fiesta_hermosa/sponsors/emporium-thai.png'
          />
          <HeaderLogo width={150} className='my-3 my-md-0' />
          <HeaderLogo
            width={150}
            className='my-3 my-md-0'
            src='/img/promotions/fiesta_hermosa/sponsors/kurtis.png'
          />
          <HeaderLogo
            width={150}
            className='my-3 my-md-0'
            src='/img/promotions/fiesta_hermosa/sponsors/pedones.png'
          />
          <HeaderLogo
            width={150}
            className='my-3 my-md-0'
            src='/img/promotions/fiesta_hermosa/sponsors/trickebikes.png'
          />
          <HeaderLogo
            width={150}
            className='my-3 my-md-0'
            src='/img/promotions/fiesta_hermosa/sponsors/uncorked.png'
          />
          <HeaderLogo
            width={150}
            className='my-3 my-md-0'
            src='/img/promotions/fiesta_hermosa/sponsors/frittomisto.png'
          />
          <HeaderLogo
            width={150}
            className='my-3 my-md-0'
            src='/img/promotions/fiesta_hermosa/sponsors/upsstore.png'
          />
          <HeaderLogo
            width={150}
            className='my-3 my-md-0'
            src='/img/promotions/fiesta_hermosa/sponsors/witi.png'
          />
        </div>
      </div>
      <div
        className='d-flex flex-column w-100 px-5 mx-auto mt-5 mb-5'
        style={{ maxWidth: 1080, gap: 30, flexWrap: 'wrap' }}
      >
        <div
          className='d-flex w-100 justify-content-evenly align-items-center'
          style={{ flexWrap: 'wrap', gap: 30 }}
        >
          <HeaderLogo
            width={150}
            className='my-3 my-md-0'
            src='/img/promotions/fiesta_hermosa/sponsors/lhb.png'
          />
          <HeaderLogo
            width={150}
            className='my-3 my-md-0'
            src='/img/promotions/fiesta_hermosa/sponsors/angrydonkey.png'
          />
          <HeaderLogo
            width={150}
            className='my-3 my-md-0'
            src='/img/promotions/fiesta_hermosa/sponsors/lmb.jpg'
          />
        </div>
      </div>
      <div className='mont mx-auto text-center mb-2' style={{ color: '#4d4d4d', maxWidth: 435 }}>
        Non-Sponsors (the below are not a sponsor of this contest) but we love our City, Chamber,
        and Fiesta Hermosa, so please support them as well to help keep these great events going!
      </div>
      <div
        className='d-flex flex-column mx-auto mb-4'
        style={{ maxWidth: 535, gap: 30, flexWrap: 'wrap' }}
      >
        <div
          className='d-flex w-100 justify-content-evenly align-items-center'
          style={{ flexWrap: 'wrap', gap: 30 }}
        >
          <HeaderLogo
            width={75}
            className='my-3 my-md-0'
            src='/img/promotions/fiesta_hermosa/sponsors/chb.png'
          />
          <HeaderLogo
            width={75}
            className='my-3 my-md-0'
            src='/img/promotions/fiesta_hermosa/sponsors/hermosachamber.png'
          />
          <HeaderLogo
            width={75}
            className='my-3 my-md-0'
            src='/img/promotions/fiesta_hermosa/sponsors/fiesta-hermosa.png'
          />
        </div>
      </div>
      <div className='copyright-footer text-center w-100 py-2'>
        <small>Copyright &copy; 2022 iTRUSTit Social, Inc. | All Rights Reserved</small>
      </div>
    </div>
  )
}

export default Promotion

//   const sponsors = {
//     514228: 'leadershiphb',
//     514274: 'LMB',
//     514281: 'KHYC',
//     514375: 'angrydonkeyband',
//     514392: 'upsstore0943',
//     514800: 'PedonesPizzaHermosa',
//     565455: 'Uncorkedwineshops',
//     585677: 'fritto-misto',
//     636087: 'EmporiumThai',
//     786785: 'Kurtis',
//     12319519: 'beckersurf',
//     21066255: 'WITI',
//     21321367: 'CBD',
//     21321651: 'trickebikes',
//   }
//   return (
//     <div className='d-flex flex-column m-5' style={{ gap: 50 }}>
//       {['', ...Object.keys(sponsors)].map((id) => (
//         <div className='d-flex flex-column'>
//           <QRCode
//             value={`https://itrustit.com/promotion/${id}`}
//             logoImage={process.env.PUBLIC_URL + '/img/iti_qr_logo.png'}
//             width={500}
//             height={500}
//             qrStyle='dots'
//             // fgColor='#006699'
//             fgColor='black'
//             ecLevel='H'
//             eyeRadius={[
//               { outer: 10 }, // top/left eye
//               { outer: 10 }, // top/right eye
//               { outer: 10 }, // bottom/left eye
//             ]}
//             quietZone={1}
//           />
//           <h3>
//             {id}: {sponsors[id] ?? 'General'}
//           </h3>
//         </div>
//       ))}
//     </div>
//   )
// }
