import React from 'react'

const CategoryIcon = ({ image = 'food.png', label = 'Restaurants', onClick }) => {
  return (
    <div className='col-3 search-category-icon' onClick={() => onClick(label)}>
      <img
        src={process.env.PUBLIC_URL + `/img/${image}`}
        alt={label}
        className='search-category-icon-icon'
      />
      <span className='search-category-cat-label'>{label}</span>
    </div>
  )
}

export default CategoryIcon
