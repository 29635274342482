import React, { Component } from 'react'
import AppLayout from '../AppLayout'
import { reduxForm } from 'redux-form'
// import ReactSelect from "../../services/ReactSelect";
import * as api from '../../services/businessService'
// import * as validation from "../../services/formValidation";
// import renderInputField from "../Form/Input";
import { Redirect } from 'react-router-dom'
import * as route from '../../services/routeConst'
import { Link } from 'react-router-dom'
import $J from 'jquery'
import * as customFunction from './../../services/customFunction'

class AddBusinessOrOrganizationOwnerLogoutPopup extends Component {
  constructor(props) {
    super(props)
  }

  yesHandler = () => {
    var loginUser = customFunction.getUser()
    customFunction.removeUser()
    customFunction.removeToken()

    $J('#add_business_owner_logout_popup').modal('hide')
    this.props.history.push({
      pathname:
        this.props.location.state.user_type === 'Organization'
          ? route.SIGNUP_ORGANIZATION_ROUTE
          : route.SIGNUP_BUSINESS_ROUTE,
      state: {
        // imatrix_id: this.props.location.state.imatrix_id,
        imatrix_id: loginUser ? loginUser.imatrix_id : this.props.location.state.imatrix_id,
        form_data: this.props.form_data,
      },
    })
  }

  noHandler = () => {
    $J('#add_business_owner_logout_popup').modal('hide')
    this.props.history.push({
      pathname: route.ROOT_ROUTE,
    })
  }

  render() {
    return (
      <div>
        <div
          className='modal fade login_blk reset_password_blk it-add-business it-add-modal-sign'
          id='add_business_owner_logout_popup'
          role='dialog'
        >
          <div className='modal-dialog'>
            <div className='modal-content'>
              <div className='modal-header'>
                <div className='ownner_logout'>
                  <h4 className='title'>
                    We need to log you out to continue ADDing your{' '}
                    {this.props.location.state.user_type == 'Business'
                      ? 'business'
                      : 'organization'}
                    .
                  </h4>
                </div>
                <div className='it-btn'>
                  <div className='yes-bottom'>
                    <div className='text-center'>
                      <a href={null} onClick={this.yesHandler} className='btn-yes'>
                        OK
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default AddBusinessOrOrganizationOwnerLogoutPopup
